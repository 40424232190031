import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService, trainTypeImages } from '../../trenitalia.service';
import * as _ from 'lodash';
import { RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { AppService } from '../../../../../../services/app.service';
import { TrenitaliaPostSaleChangeConfirmDialog } from '../trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component';
import { wrapArray } from 'app/helpers';
import { finalize, take } from 'rxjs/operators';
var PostSaleDialogComponent = /** @class */ (function () {
    function PostSaleDialogComponent(appService, dataDialog, _trnService, matDialog, _snackBar) {
        this.appService = appService;
        this.dataDialog = dataDialog;
        this._trnService = _trnService;
        this.matDialog = matDialog;
        this._snackBar = _snackBar;
        this.errors = {};
    }
    Object.defineProperty(PostSaleDialogComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    PostSaleDialogComponent.prototype.getEvent = function (e) {
        this.updateTotal();
    };
    PostSaleDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.solution = this.dataDialog.solution;
        /*const oldOffered = _.get(this.dataDialog,['travel','travelSolutions',0,'offeredServices'])
        if(oldOffered && _.isArray(oldOffered)) {
            const offer = oldOffered.filter(off => off.status == 'PURCHASED');
            let selectedOffer:any={};
            for ( let off of offer){
                const serviceId = _.get(off,'catalogServiceId');
                console.log(this.dataDialog);
                console.log("type",_.get(this.dataDialog,['serviceMap','catalog_services',serviceId,'type']));
                if(_.get(this.dataDialog,['serviceMap','catalog_services',serviceId,'type'])==='SEAT'){
                    if(!_.has(selectedOffer,off.solutionNodeRef))_.set(selectedOffer,off.solutionNodeRef,{});
                    if(!_.has(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller]))_.set(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller],{});
                    _.set(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller], {offer: off.offer.id,service: off.catalogServiceId.id});
                }
            }
            _.set(this.solution,['travelSolutions',0,'priceSelected'],selectedOffer);
        }*/
        this.solution.travelSolutions[0]['custom_off'] = {};
        this._trnService.loading.subscribe(function (l) {
            _this.load = l;
        });
    };
    PostSaleDialogComponent.prototype.updateTotal = function () {
        var _this = this;
        this.selectedOfferedServices = [];
        this.total = 0;
        this.solution.travelSolutions[0].trains.forEach(function (t) {
            _this.solution.travellers.forEach(function (tr) {
                _this.total += _.get(_this.solution.travelSolutions[0].custom_off, t.id + '.' + tr.xmlId + '.price');
                /* let offered =  _.get(this.solution.travelSolutions[0].offeredServices,_.get(
                     this.solution.travelSolutions[0].custom_off,
                     t.id + '.' + tr.xmlId + '.offer[0]'
                 ));*/
                var sel = _.get(_this.solution.travelSolutions[0].custom_off, [t.id, tr.xmlId]);
                if (!sel) {
                    console.warn('custom_off not found', _this.solution, t.id, tr.xmlId);
                    return;
                }
                var offer = { id: sel.offer[0] };
                if (sel.parameter)
                    offer.parameters = sel.parameter;
                _this.selectedOfferedServices.push(offer);
                /*this.selectedOfferedServices.push({
                    id: _.get(
                        this.solution.travelSolutions[0].custom_off,
                        t.id + '.' + tr.xmlId + '.offer[0]'
                    )
                });*/
            });
        });
    };
    PostSaleDialogComponent.prototype.updateTravel = function () {
        var _this = this;
        this.load = true;
        this._trnService.loading.next(true);
        this.dataDialog.travel.travellers = this.dataDialog.travellers;
        var data = {
            travel: this.dataDialog.travel,
            selectedPostSaleDetails: this.dataDialog.postSaleDetails,
            oldOfferedServices: this.dataDialog.oldOfferedServices,
            selectedOfferedServices: this.selectedOfferedServices,
            newTravelSolutionSelected: this.solution.travelSolutions[0].original
        };
        this._trnService.evaluateTravelPostSale(data).subscribe(function (res) {
            var e_1, _a;
            _this.load = false;
            if (res['validatedPostSaleRecord'].allowed == 'false') {
                var message = 'Soluzione non più disponibile';
                var travelSolutions = _.get(res, 'travel.travelSolutions');
                if (travelSolutions) {
                    if (!Array.isArray(travelSolutions))
                        travelSolutions = [travelSolutions];
                    var offeredServices = _.get(travelSolutions, [0, 'offeredServices']).filter(function (of) { return of.status == 'SELECTED'; });
                    if (offeredServices && !Array.isArray(offeredServices))
                        offeredServices = [offeredServices];
                    if (offeredServices && _.has(travelSolutions, [0, 'returnTravelSolution', 'offeredServices'])) {
                        var returnOfferedServices = _.get(travelSolutions, [0, 'returnTravelSolution', 'offeredServices']);
                        if (Array.isArray(returnOfferedServices))
                            offeredServices.push.apply(offeredServices, tslib_1.__spread(returnOfferedServices));
                        else
                            offeredServices.push(returnOfferedServices);
                    }
                    if (offeredServices) {
                        try {
                            for (var offeredServices_1 = tslib_1.__values(offeredServices), offeredServices_1_1 = offeredServices_1.next(); !offeredServices_1_1.done; offeredServices_1_1 = offeredServices_1.next()) {
                                var offeredService = offeredServices_1_1.value;
                                if (_.get(offeredService, 'status') != 'SELECTED')
                                    continue;
                                var messages = _.get(offeredService, 'bookingInfo.reportItem.messages');
                                if (messages && !Array.isArray(messages))
                                    messages = [messages];
                                var train = _.get(offeredService, 'solutionNodeRef');
                                var traveller = _.get(offeredService, 'bookingInfo.traveller');
                                if (!_.has(_this.errors, train))
                                    _this.errors[train] = {};
                                if (!_.has(_this.errors, [train, traveller]))
                                    _this.errors[train][traveller] = {};
                                _this.errors[train][traveller].messages = messages;
                                if (!_.isEmpty(messages))
                                    message = messages[1];
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (offeredServices_1_1 && !offeredServices_1_1.done && (_a = offeredServices_1.return)) _a.call(offeredServices_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                    }
                }
                _this.appService.showErrorMessage(message);
            }
            else {
                _this.matDialog
                    .open(TrenitaliaPostSaleChangeConfirmDialog, {
                    minWidth: '300px',
                    panelClass: 'create-dialog-container',
                    data: {
                        postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
                            missingAmount: res.missingAmount,
                            reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
                            reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
                            additionalCharges: res.additionalCharges,
                            postSaleFee: res.fee
                        }),
                        type: 'change-class',
                        title: 'Cambio classe viaggio',
                        message: 'Vuoi cambiare classe viaggio?',
                        icon: 'keyboard_double_arrow_up',
                    }
                })
                    .afterClosed()
                    .subscribe(function (result) {
                    if (!result)
                        return;
                    _this.load = true;
                    _this._trnService
                        .updateAndConfirm({
                        travel: res.travel,
                        type: 'change-class',
                        postSaleData: {
                            postSaleRecord: res.validatedPostSaleRecord,
                            missingAmount: res.missingAmount,
                            reversedPaymentRecords: res.reversedPaymentRecords,
                            reusedPaymentRecords: res.reusedPaymentRecords,
                            additionalCharges: res.additionalCharges
                        }
                    })
                        .pipe(take(1), finalize(function () {
                        _this.load = false;
                    }))
                        .subscribe({
                        next: function (s) {
                            _this._trnService.loading.next(false);
                            _this.matDialog.closeAll();
                            _this.appService.appEvent.next({
                                name: RELOAD_BOOKING_PACKAGES
                            });
                            _this.appService.showSuccessMessage('Cambio livello effettuato');
                        },
                        error: function (error) {
                            _this.appService.showErrorMessage('Errore nella procedura del cambio livello!');
                        }
                    });
                });
            }
        });
    };
    return PostSaleDialogComponent;
}());
export { PostSaleDialogComponent };
