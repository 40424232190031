import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('description')
		]
	}
];

export default {
	fields: detailFormFields 
};
