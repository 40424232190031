<!-- STEPPER CONFIGURATION -->
<ng-container *ngIf='datasetACS.datasetConfig.stepViewConfig'>
	<ng-container [ngSwitch]='datasetACS.datasetCode'>
		<!-- TRIP PACKAGES -->
		<ng-container *ngSwitchCase='"trip_packages"'>
			<ng-container [ngSwitch]='datasetACS.getValueFromKeyPath("domainTpl")'>
				<ng-container *ngSwitchCase='1'>
					<simp-temp-view-container
						[stepViewConfig]='stepViewConfig'
					></simp-temp-view-container>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<common-step-view-container
						[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
					></common-step-view-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<!-- PRODUCTS -->
		<ng-container *ngSwitchCase='"products"'>
			<simp-temp-view-container
				[stepViewConfig]='productStepViewConfig'
			></simp-temp-view-container>
		</ng-container>
		<!-- DEFAULT -->
		<ng-container *ngSwitchDefault>
			<common-step-view-container
				[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
			></common-step-view-container>
		</ng-container>
	</ng-container>
</ng-container>
<!-- NORMAL CONFIGURATION -->
<ng-container *ngIf='!datasetACS.datasetConfig.stepViewConfig'>
	<ng-container [ngSwitch]='datasetACS.datasetConfig.name'>

		<ng-container *ngSwitchDefault>
			<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
				<mat-card *ngIf='datasetACS.datasetConfig' class='p-24'>
					<mat-card-header *ngIf='showHeader'>
						<mat-card-title *ngIf='!formConfig || !formConfig.title'>Crea nuovo {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</mat-card-title>
						<mat-card-title *ngIf='formConfig && formConfig.title'>{{formConfig.title}}</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
						
						<div *ngIf='formConfig && !datasetACS.loading.value' class='mb-24'>
							<form-viewer #formViewer [dataContainerService]='datasetACS' [formConfig]='formConfig'></form-viewer>
						</div>
					</mat-card-content>
					<mat-card-actions>
						<button mat-flat-button [disabled]='datasetACS.loading.value' color='accent' (click)="onSave()">{{'Invia' | translate}}</button>
					</mat-card-actions>
				</mat-card>
			</div>
		</ng-container>
	</ng-container>
</ng-container>