import { Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackageService } from "../../trip-booking-package.service";
import { ITripBookingPackageRelatedDataDialogData } from "../../related-data/abstract-trip-booking-package-related-data.component";

@Component({
	selector: 'trip-booking-package-related-buttons',
	templateUrl: './related-buttons.component.html'
})
export class TripBookingPackageRelatedButtonsComponent extends DestroyableComponent{

	@Input() showResources = true;
	@Input() showServices = true;
	@Input() showParticipants = true;

	get tripBookingPackageRelatedDialogData(): ITripBookingPackageRelatedDataDialogData{
		return {
			datasetCode: '',
			actionCode: '',
			tripBookingPackage: this.datasetRS.record.value
		};
	}

	constructor(
		public datasetRS: DatasetRecordService,
		public tripBookingPackageService: TripBookingPackageService,
		public viewContainerRef: ViewContainerRef
	){
		super();
	}
}