import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import confirmAction from './confirm.action';
import {DomainPackageState} from './status.enum';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { IActionDialogData, ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ICustomAction } from 'app/interfaces';
import { IFormDialogData, FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import newCustomerForm from './forms/new-customer.form';
import setPlafond from './plafond.action';
import requirePlafondData from 'app/main/actions/require-plafond.action';

const CONFIRM_MESSAGES = {
	[DomainPackageState.REFUSED]: 'Sei sicuro di voler rifiutare la richiesta?',
	[DomainPackageState.PENDING]: 'Sei sicuro di voler riformulare la richiesta?',
	[DomainPackageState.RETIRED]: 'Sei sicuro di voler ritirare la richiesta?',
	[DomainPackageState.CANCELED]: 'Sei sicuro di voler annullare la rivendita?',
};

async function requireCustomerData(dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService, errors?: any): Promise<any>{
	const dialogRef = datasetNavigator.openDialog<IFormDialogData>(FormDialogComponent, {
		title: 'Seleziona un listino',
		dataContainerService: dataContainerService,
		formConfig: newCustomerForm,
		formErrors: errors
	});
	const res = await dialogRef.afterClosed().toPromise();
	if(!res) return;
	return {
		unified_list_id: res.unified_list_id,
		payment_type_id: res.payment_type_id
	};
}

function changeStatus(record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService, newStatus: DomainPackageState, customerData?: any): any{
	datasetNavigator.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
		title: 'Conferma',
		contentMessage: CONFIRM_MESSAGES[newStatus]
	})
	.afterClosed()
	.subscribe((res) => {
		if(!res) return;

		let addLog = true;
		if (!record.package) addLog = false;

		const dialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
			title: 'Attendere',
			contentMessage: ''
		});
		datasetNavigator.datasetService.post<any>('/dataset/domain_packages/command/set_state', Object.assign({
			'id': record.id,
			'status': newStatus,
			'add_log': addLog
		}, customerData))
		.subscribe({
			next: () => {
				dialogRef.close();

				if(dataContainerService instanceof DatasetActionContainerService){
					dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
				}
			},
			error: (e) => {
				if (dataContainerService instanceof DatasetActionContainerService 
					&& e.error 
					&& (
						e.error.message === 'seleziona-listino' 
						|| (
							e.error.action_required 
							&& e.error.action_required.code === 'new-customer-data'
						)
					)
				){
					requireCustomerData(dataContainerService, datasetNavigator, e.error.errors)
					.then(newCustomerData => {
						if(!newCustomerData) return; // cancel the operation
						changeStatus(record, dataContainerService, datasetNavigator, newStatus, newCustomerData);
					}, (error) => {
						// ignore
					});
				}else{
					const dialogInstance = dialogRef.componentInstance;
					if(dialogInstance instanceof ActionDialogComponent){
						dialogInstance.resultStatus = 'error';
						dialogInstance.setMessage('Si è verificato un errore imprevisto.');
						dialogInstance.loading = false;
					}
				}
			}
		});
	});
}

export default [
	{
		buttonIcon: 'done',
		actionCode: 'allow',
		buttonColor: 'accent',
		tooltip: 'Accetta',
		onClick(event, record, dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService): void {
			const data = {
				id: record.id,
				status: DomainPackageState.ACCEPTED,
			};
			confirmAction(data, dataContainerService, datasetNavigator)
			.then(result => {
				if (!result.success || !result.with_plafond_permission) return;
				const confirmDialog = datasetNavigator.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
					title: 'Plafond',
					contentMessage: 'Vuoi associare un plafond alla richiesta?'
				})
				.afterClosed()
				.subscribe({
					next: (confirm) => {
						if (!confirm) return;
						requirePlafondData(null, dataContainerService, datasetNavigator, null, {
							domainPackageId: record.id
						}).then(data => {
							setPlafond(datasetNavigator, data, record).then(resp => {
								if (!resp) return;
								if(dataContainerService instanceof DatasetActionContainerService){
									dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
								}
							});
						}, (error) => {
							// ignore
						});
					},
					error: (exc) => {
						console.error(exc);
					}
				});
			});
		},
		showIf(record): boolean {
			return true;
		}
	},
	{
		buttonIcon: 'block',
		actionCode: 'deny',
		buttonColor: 'warn',
		tooltip: 'Rifiuta',
		onClick(event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): void{
			changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.REFUSED);
		},
		showIf(record): boolean {
			return true;
		}
	},
	{
		buttonIcon: 'autorenew',
		actionCode: 'reformulates',
		buttonColor: 'warn',
		tooltip: 'Riformula richiesta',
		onClick(event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): void{
			changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.PENDING);
		},
		showIf(record): boolean {
			return true;
		}
	},
	{
		buttonIcon: 'remove_circle',
		actionCode: 'cancel',
		buttonColor: 'warn',
		tooltip: 'Cancella',
		onClick(event, record, dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService): void {
			changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.CANCELED);
		},
		showIf(): boolean{
			return true;
		}
	},
	{
		buttonIcon: 'remove_circle',
		actionCode: 'retire',
		buttonColor: 'warn',
		tooltip: 'Annulla',
		onClick(event, record, dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService): void {
			changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.RETIRED);
		},
		showIf(): boolean{
			return true;
		}
	},
	{
		buttonIcon: 'money',
		actionCode: 'plafond_actions',
		buttonColor: 'accent',
		tooltip: 'Imposta plafond',
		onClick(event, record, dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService): void {
			requirePlafondData(null, dataContainerService, datasetNavigator, null, {
				domainPackageId: record.id
			}).then(data => {
				setPlafond(datasetNavigator, data, record).then(resp => {
					if (!resp) return;
					if(dataContainerService instanceof DatasetActionContainerService){
						dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
					}
				});
			}, (error) => {
				// ignore
			});
		},
		showIf(record: any, dataContainerService: DatasetActionContainerService): boolean{
			if (record.plafond_id){
				return false;
			}
			return true;
		}
	}
] as ICustomAction[];
