import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { GenerateActualTripDialogComponent } from 'app/main/components/dataset/dialogs/generate-actual-trip-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import * as moment from 'moment';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
var ActualTripsSmartInterfaceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ActualTripsSmartInterfaceComponent, _super);
    function ActualTripsSmartInterfaceComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, dialog, stepService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.dialog = dialog;
        _this.stepService = stepService;
        _this.todayFilter = JSON.stringify(['>=', moment.utc().startOf('day')]);
        return _this;
    }
    ActualTripsSmartInterfaceComponent.prototype.ngOnInit = function () {
        this.stepService.setStepState({
            dataset_code: 'trip_packages',
            form_code: 'actual_trips',
            record_id: this.datasetRS.recordId,
            status: 1
        });
    };
    ActualTripsSmartInterfaceComponent.prototype.generate = function () {
        var _this = this;
        this.dialog.open(GenerateActualTripDialogComponent, {
            data: {
                trip_package: this.datasetRS.record.value,
                datasetACS: this.datasetACS,
                postData: {
                    trip_package_id: this.datasetRS.recordId
                }
            }
        }).afterClosed().subscribe(function (result) {
            if (result) {
                _this.tripList.reload();
            }
        });
    };
    return ActualTripsSmartInterfaceComponent;
}(BaseStepViewComponent));
export { ActualTripsSmartInterfaceComponent };
