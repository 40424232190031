<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<dataset-list
		[datasetCode]='"meeting_point_unified_list_items"'
		[showSearchBar]='false'
		[parentDatasetRS]='parentDatasetRS'
		[parentDatasetACS]='parentDatasetACS'
		[elevate]='false'
		[showTitle]='false'
	></dataset-list>
</div>