import * as tslib_1 from "tslib";
import { QueryList, OnInit, Injector } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { StepService } from '../../../services/step-state.service';
var CommonBlockStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonBlockStepComponent, _super);
    function CommonBlockStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, injector, stepService, datasetService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.injector = injector;
        _this.stepService = stepService;
        _this.datasetService = datasetService;
        _this.blockDatasetRecordServices = [];
        _this.blockDatasetActionContainerServices = [];
        return _this;
    }
    CommonBlockStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            var e_1, _a;
            _this.blockFilters = [];
            _this.enableBlocks = [];
            try {
                for (var _b = tslib_1.__values(_this.stepConfig.options.blocks), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var blockConfig = _c.value;
                    var blockFilter = {};
                    if (blockConfig.filters) {
                        blockFilter = blockConfig.filters(_this, _this.datasetRS.record.value);
                    }
                    _this.blockFilters.push(blockFilter);
                    if (blockConfig.recordAs && _this.datasetACS.hasValueInKeyPath(blockConfig.recordAs)) {
                        var injector = Injector.create({
                            parent: _this.injector,
                            providers: [{
                                    provide: DatasetActionContainerService,
                                    deps: []
                                }, {
                                    provide: DatasetRecordService,
                                    deps: []
                                }]
                        });
                        var recordService = injector.get(DatasetRecordService);
                        var containerService = injector.get(DatasetActionContainerService);
                        recordService.datasetACS = containerService;
                        recordService.record.next(_this.datasetACS.getValueFromKeyPath(blockConfig.recordAs));
                        containerService.init({
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: 'edit',
                            parentDatasetRS: _this.datasetRS,
                            parentDatasetACS: _this.datasetACS,
                            datasetRS: recordService,
                            fetchData: false
                        });
                        _this.blockDatasetRecordServices.push(recordService);
                        _this.blockDatasetActionContainerServices.push(containerService);
                    }
                    else {
                        _this.blockDatasetRecordServices.push(null);
                        _this.blockDatasetActionContainerServices.push(null);
                    }
                    if (blockConfig.showIf) {
                        _this.enableBlocks.push(blockConfig.showIf(record));
                    }
                    else {
                        _this.enableBlocks.push(true);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            // if step state active
            if (_this.stepConfig.options.stepService) {
                _this.stepService.setStepState({
                    dataset_code: 'products',
                    form_code: 'prices',
                    record_id: record.id,
                    status: 1
                });
            }
        });
    };
    CommonBlockStepComponent.prototype.onCreatedInBlock = function (blockConfig) {
        var _this = this;
        if (blockConfig.updateParent) {
            this.datasetRS.reload()
                .subscribe(function () {
                var block = _this.listBlocks.find(function (elementRef) {
                    return elementRef.datasetCode === blockConfig.datasetCode;
                });
                if (block)
                    block.reload();
            });
        }
    };
    CommonBlockStepComponent.prototype.onDatasetEvent = function (event) {
        if (event.eventName === DatasetEvents.CREATED)
            return;
        this.datasetRS.reload();
    };
    return CommonBlockStepComponent;
}(BaseStepViewComponent));
export { CommonBlockStepComponent };
