import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';

@Component({
	selector     : 'cache-utility',
	templateUrl  : './cache.component.html',
	encapsulation: ViewEncapsulation.None
})
export class CacheUtilityComponent{

	public loading = false;

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected http: HttpClient,
	){}

	clearCache(): void{
		this.loading = true;
		this.http.post(this.appService.getBaseServerAddress() + '/dataset/admin_commands/command/clear_cache', {})
		.subscribe({
			next: response => {
				this.appService.showSuccessMessage('Azione eseguita con successo.');
				this.loading = false;
			},
			error: response => {
				this.appService.showErrorMessage('Azione fallita.');
				this.loading = false;
			}
		});
	}

	optimizeCache(): void{
		this.loading = true;
		this.http.post(this.appService.getBaseServerAddress() + '/dataset/admin_commands/command/optimize_cache', {})
		.subscribe({
			next: response => {
				this.appService.showSuccessMessage('Azione eseguita con successo.');
				this.loading = false;
			},
			error: response => {
				this.appService.showErrorMessage('Azione fallita.');
				this.loading = false;
			}
		});
	}
}
