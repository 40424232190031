import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
var BaseStepChangerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseStepChangerComponent, _super);
    function BaseStepChangerComponent(bookingService) {
        var _this = _super.call(this) || this;
        _this.bookingService = bookingService;
        _this._unsubscribeAll = new Subject();
        return _this;
    }
    BaseStepChangerComponent.prototype.ngOnInit = function () {
        // go to next step
        this.bookingService.nextStep
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (stepper) {
            stepper.next();
        });
        // go to previous step
        this.bookingService.prevStep
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (stepper) {
            stepper.previous();
        });
    };
    return BaseStepChangerComponent;
}(DestroyableComponent));
export { BaseStepChangerComponent };
