import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { CalendarEventFormDialogComponent } from 'app/main/pages/calendar/event-form/event-form.component';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { IFieldDefinition, ValueTypes, InputTypes, ISocketEvent } from 'app/interfaces';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { AppService } from 'app/services/app.service';
import { WebSocketService } from 'app/services/websocket.service';
var FilterCategoriesFieldDefinition = {
    key: 'filter_categories',
    name: 'Categorie',
    appearance: 'legacy',
    valueType: ValueTypes.ARRAY,
    inputConfig: {
        type: InputTypes.DATASET_MULTISELECT,
        clearable: true
    },
    datasetCode: 'trip_categories',
    remoteOptions: {
        getLabel: function (item) {
            return item.description;
        },
        getValue: function (item) {
            return item.id;
        }
    }
};
var FilterPackagesFieldDefinition = {
    key: 'filter_packages',
    name: 'Pacchetti',
    appearance: 'legacy',
    valueType: ValueTypes.ARRAY,
    inputConfig: {
        type: InputTypes.DATASET_MULTISELECT,
        clearable: true
    },
    datasetCode: 'trip_packages',
    remoteOptions: {
        getLabel: function (item) {
            return item.description;
        },
        getValue: function (item) {
            return item.id;
        }
    }
};
var ɵ0 = { useUtc: true }, ɵ1 = MAT_MOMENT_DATE_FORMATS;
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(_matDialog, _calendarService, _datasetService, appService, datasetNavigatorService, fb, webSocketService, translate) {
        this._matDialog = _matDialog;
        this._calendarService = _calendarService;
        this._datasetService = _datasetService;
        this.appService = appService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.fb = fb;
        this.webSocketService = webSocketService;
        this.translate = translate;
        this.refresh = new Subject();
        this.separatorKeysCodes = [ENTER, COMMA];
        this.filterCategoriesFieldDefinition = FilterCategoriesFieldDefinition;
        this.filterPackagesFieldDefinition = FilterPackagesFieldDefinition;
        // Set the defaults
        this.view = 'month';
        this.viewDate = new Date();
        this.activeDayIsOpen = false;
        this._unsubscribeAll = new Subject();
        /**
         * Get events from service/server
         */
        this.setEvents();
    }
    Object.defineProperty(CalendarComponent.prototype, "localeId", {
        get: function () {
            return this.translate.currentLang;
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        /**
         * on screen size < 992 redirect to view day calendar
         */
        if (window.innerWidth < 992)
            this.view = 'day';
        if (this.isMobileOperatingSystem()) {
            this.isMobile = true;
        }
        /**
         * Watch re-render-refresh for updating db
         */
        this.refresh.subscribe(function (updateDB) {
        });
        /**
         * listen on web socket event
         */
        if (this.webSocketService.ioSocket)
            this.webSocketService.ioSocket.on('channel-event', this.onChannelEvent.bind(this));
        this._calendarService.onEventsUpdated
            .subscribe(function (events) {
            _this.setEvents();
            _this.refresh.next();
        });
        this.filterFormGroup = this.fb.group({
            filter_trip_categories: [this._calendarService.filterParams.filter_trip_categories],
            filter_trip_packages: [this._calendarService.filterParams.filter_trip_packages]
        });
        this.filterFormGroup
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(distinctUntilChanged())
            .subscribe(function (values) {
            _this._calendarService.filterParams = Object.assign(_this._calendarService.filterParams, values);
            _this._calendarService.getAllDaysEvents({
                start: moment.utc(_this.viewDate).startOf('month'),
                end: moment.utc(_this.viewDate).endOf('month'),
            }).subscribe(function () { return _this._calendarService._viewDateChanged.next(_this.viewDate); });
        });
        // initial load
        setTimeout(function () {
            _this._calendarService.getAllDaysEvents();
        }, 500);
    };
    CalendarComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        // remove socket listener
        if (this.webSocketService.ioSocket)
            this.webSocketService.ioSocket.off('channel-event');
    };
    CalendarComponent.prototype.onChannelEvent = function (broadcastEvent) {
        var _this = this;
        if (broadcastEvent.data.datasetCode != 'calendar' && broadcastEvent.data.eventName != 'change_allotment')
            return;
        // socket actual trip
        var actualTripEvent = broadcastEvent.data.payloadData;
        var tripPackageId = actualTripEvent.trip_package_id || null;
        var momentDate = moment(actualTripEvent.start_day);
        var dateStart = momentDate.format('DDMMYY');
        var date = new Date(actualTripEvent.start_day);
        var day = convertToUTCDay(date).format('YYYY-MM-DD');
        this._calendarService.dayLoading[day] = { loading: true };
        this.isDayLoading(date);
        // refresh allotment actual trips
        if (this.groupedEvents[dateStart] && this.groupedEvents[dateStart][tripPackageId]) {
            var events = this.groupedEvents[dateStart][tripPackageId].events;
            var intrestedTrip = events.find(function (trip) { return trip.meta.actual_trip.id == actualTripEvent.actual_trip_id; });
            var indexOf = events.indexOf(intrestedTrip);
            if (indexOf !== -1 && intrestedTrip.meta.actual_trip.pax_by_resource) {
                // update properties
                intrestedTrip.meta.actual_trip.pax_by_resource = actualTripEvent.pax_by_resource || null;
                intrestedTrip.meta.actual_trip.consumed_allotment = actualTripEvent.consumed_allotment || 0;
                intrestedTrip.meta.actual_trip.max_allotment = actualTripEvent.max_allotment || 0;
                intrestedTrip.cssClass = intrestedTrip.computeClass();
                // update event
                this.groupedEvents[dateStart][tripPackageId].events[indexOf] = intrestedTrip;
            }
        }
        setTimeout(function () {
            _this._calendarService.dayLoading[day] = { loading: false };
            _this.isDayLoading(date);
        }, 3000);
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone'.
     *
     * @returns {boolean}
     */
    CalendarComponent.prototype.isMobileOperatingSystem = function () {
        var userAgent = navigator.userAgent || navigator.vendor;
        if (/windows phone/i.test(userAgent)) {
            return true;
        }
        else if (/android/i.test(userAgent)) {
            return true;
        }
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
            return true;
        }
        return false;
    };
    /**
     * Set events
     */
    CalendarComponent.prototype.setEvents = function () {
        this.events = this._calendarService.events || [];
        this.groupedEvents = this._calendarService.groupedEvents || {};
        this.groupedEventsByHour = this._calendarService.groupedEventsByHour || [];
    };
    /**
     * Before View Renderer
     *
     * @param {any} header
     * @param {any} body
     */
    CalendarComponent.prototype.beforeMonthViewRender = function (event) {
        var _this = this;
        /**
         * Get the selected day
         */
        var _selectedDay = event.body.find(function (_day) {
            if (!_this.selectedDay)
                return false;
            return _day.date.getTime() === _this.selectedDay.date.getTime();
        });
        if (_selectedDay) {
            /**
             * Set selected day style
             * @type {string}
             */
            _selectedDay.cssClass = 'cal-selected';
        }
    };
    CalendarComponent.prototype.onViewDateChanged = function (date) {
        this.selectedDay = { date: date };
        /**
         * check cached data
         */
        var dateIdx = moment(date).format('DDMMYY');
        if (this.view === 'month' || this.view === 'week') {
            if (this._calendarService.groupedEvents[dateIdx])
                return;
        }
        else if (this.view === 'day' && this._calendarService.groupedEventsByHour[dateIdx]) {
            this._calendarService._viewDateChanged.next(date);
            return;
        }
        this._calendarService.getAllDaysEvents({
            start: moment.utc(this.viewDate).startOf('month'),
            end: moment.utc(this.viewDate).endOf('month'),
        });
    };
    /**
     * Day clicked
     *
     * @param {MonthViewDay} day
     */
    CalendarComponent.prototype.dayClicked = function (day) {
        var date = day.date;
        var events = day.events;
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
        else {
            this.activeDayIsOpen = false;
        }
        this.selectedDay = day;
        this._calendarService.activeDayIsOpenSubscriber.next({ opened: this.activeDayIsOpen });
        this.refresh.next();
    };
    CalendarComponent.prototype.sidebarClosed = function (event) {
        this.activeDayIsOpen = false;
    };
    /**
     * Event times changed
     * Event dropped or resized
     *
     * @param {CalendarEvent} event
     * @param {Date} newStart
     * @param {Date} newEnd
     */
    CalendarComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        // TODO
    };
    CalendarComponent.prototype.eventClick = function (event) {
        // TODO
    };
    /**
     * Delete Event
     *
     * @param event
     */
    CalendarComponent.prototype.deleteEvent = function (event) {
        var _this = this;
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var eventIndex = _this.events.indexOf(event);
                _this.events.splice(eventIndex, 1);
                _this.refresh.next(true);
            }
            _this.confirmDialogRef = null;
        });
    };
    /**
     * Edit Event
     *
     * @param {string} action
     * @param {CalendarEvent} event
     */
    CalendarComponent.prototype.editEvent = function (action, event) {
        var _this = this;
        var eventIndex = this.events.indexOf(event);
        this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
            panelClass: 'event-form-dialog',
            data: {
                event: event,
                action: action
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(function (response) {
            if (!response) {
                return;
            }
            var actionType = response[0];
            var formData = response[1];
            switch (actionType) {
                /**
                 * Save
                 */
                case 'save':
                    _this.events[eventIndex] = Object.assign(_this.events[eventIndex], formData.getRawValue());
                    _this.refresh.next(true);
                    break;
                /**
                 * Delete
                 */
                case 'delete':
                    _this.deleteEvent(event);
                    break;
            }
        });
    };
    CalendarComponent.prototype.openInfo = function (text) {
        this._matDialog.open(TextDialogComponent, {
            minWidth: '550px',
            data: {
                contentMessage: text
            }
        });
    };
    CalendarComponent.prototype.isDayLoading = function (date) {
        if (!date)
            return false;
        var day = convertToUTCDay(date).format('YYYY-MM-DD');
        if (!this._calendarService.dayLoading[day])
            return false;
        return this._calendarService.dayLoading[day].loading;
    };
    return CalendarComponent;
}());
export { CalendarComponent };
export { ɵ0, ɵ1 };
