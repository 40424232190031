import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TrenitaliaService, trainTypeImages } from '../trenitalia.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { wrapArray } from 'app/helpers';
var SearchResultsComponent = /** @class */ (function () {
    function SearchResultsComponent(trnService, _snackBar, dialog) {
        this.trnService = trnService;
        this._snackBar = _snackBar;
        this.dialog = dialog;
        this.priceTable = [];
        this.total = {};
        this.fee = 0;
        this.selectCarnet = [];
        this.showMore = false;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(SearchResultsComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    SearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.checkoutData = data;
            if (_this.checkoutData.returnSearch)
                _this.resetResults();
            if (_this.checkoutData.rebuild) {
                _this.validSolution = undefined;
                _this.checkoutData.seats = false;
                _this.checkoutData.rebuild = false;
                _this.selectedSol = -1;
                _this.details = -1;
            }
            _this.solutions = [];
            if (_this.validSolution === undefined) {
                var servicesMap_1 = _this.trnService.servicesMap.value;
                if (_.has(_this.checkoutData, 'searchSolutions.searchSimilarRecords')) {
                    _this.solutions = _this.checkoutData.searchSolutions.searchSimilarRecords.map(function (sol) {
                        var view = sol.travelSolution;
                        view.travellers = _this.checkoutData.travellers;
                        view.servicesMap = servicesMap_1;
                        view.validatedPostSaleRecord = sol.validatedPostSaleRecord;
                        view.reusedPaymentRecords = sol.reusedPaymentRecords;
                        view.missingAmount = sol.missingAmount;
                        return view;
                    });
                }
                else if (_.has(_this.checkoutData, 'searchSolutions.travelSolutions')) {
                    _this.solutions = _this.checkoutData.searchSolutions.travelSolutions.map(function (view) {
                        view.travellers = _this.checkoutData.searchSolutions.travellers;
                        view.servicesMap = servicesMap_1;
                        return view;
                    });
                }
                else {
                }
                if (!_this.checkoutData.dataAv) {
                    _this.solutions.forEach(function (s) {
                        s.trains.forEach(function (t) {
                            if (!_.has(s, 'priceGrid.' + t.id + '.best_price.row_key') || !s.bestPrice) {
                                if (_.has(s, 'priceGrid.' + t.id))
                                    s.priceGrid[t.id].priceSelected = {};
                                // warn only if train not found not if the priceGrid is empty
                                if (s.priceGrid)
                                    console.warn('not preselect price for train', t.id, s.bestPrice, _.get(s, 'priceGrid.' + t.id + '.best_price.row_key'));
                                return;
                            }
                            s.priceGrid[t.id].priceSelected = {
                                row: s.priceGrid[t.id].best_price.row_key,
                                col: s.priceGrid[t.id].best_price.col_key
                            };
                        });
                    });
                }
                _this.validSolution = _this.solutions;
                _this.travellers = _this.checkoutData.searchSolutions.travellers;
                _this.showMore = _this.checkoutData.mode != 'edit-date-time';
            }
            /*if(_.has(this.checkoutData,'selectedSolution'))
                this.selectedSol = this.validSolution.findIndex((sol: { xmlId: any; })=>sol.xmlId == this.checkoutData.selectedSolution.xmlId)*/
            _this.stationSearch = {
                start: _this.trnService.getStationDataFromId(_.get(_this.checkoutData, 'from')),
                end: _this.trnService.getStationDataFromId(_.get(_this.checkoutData, 'to'))
            };
            if (_this.checkoutData.ar) {
                _this.stationSearch.return = {
                    start: _this.trnService.getStationDataFromId(_.get(_this.checkoutData, 'return.from')),
                    end: _this.trnService.getStationDataFromId(_.get(_this.checkoutData, 'return.to'))
                };
            }
        });
        this.trnService.loading
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (load) {
            _this.loading = load;
        });
    };
    SearchResultsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    SearchResultsComponent.prototype.indexTrackBy = function (index, item) {
        if (_.has(item, 'id'))
            return item.id;
        return index;
    };
    SearchResultsComponent.prototype.onServiceSelected = function (i) {
        var _this = this;
        if (i == this.selectedSol) {
            // toggle?
            // return?
        }
        /*reset valori*/
        this.total = {
            value: 0,
            valueNet: 0,
            fee: 0
        };
        this.selectedLoad = i;
        this.details = -1;
        this.selectedSol = i;
        this.checkoutData.seatsSel = 0;
        this.selectedSolution = this.validSolution[i].original;
        this.checkoutData.selectedSolution = this.validSolution[i];
        this.departure = this.validSolution[i].departure;
        this.arrival = this.validSolution[i].arrival;
        this.checkoutData.stations = {
            start: this.validSolution[i].start,
            end: this.validSolution[i].end
        };
        this.checkoutData.travellers = this.travellers = wrapArray(this.checkoutData.selectedSolution.travellers);
        if (this.checkoutData.mode == 'carnet') {
            _.keys(this.checkoutData.selectedSolution.offeredServices).forEach(function (offer) {
                var off = _.get(_this.checkoutData.selectedSolution.offeredServices, offer);
                _this.selectCarnet.push({ id: offer, value: _.get(_this.trnService.servicesMap.value, 'catalog_services.' + off.catalogServiceId + '.display_name') });
            });
            this.changeServiceCarnet({ value: this.selectCarnet[0].id });
            if (this.selectedSolution.offeredServices && !_.isArray(this.selectedSolution.offeredServices)) {
                this.selectedSolution.offeredServices = [this.selectedSolution.offeredServices];
            }
        }
        this.updateTotal();
        this.trnService.loading.next(false);
        this.selectedSol = i;
    };
    SearchResultsComponent.prototype.onPriceSelected = function (e, i) {
        if (_.has(e, ['sol', 'travelSolutions', 0, 'custom_off']) &&
            Object.keys(e.sol.travelSolutions[0].custom_off).length ===
                e.sol.travelSolutions[0].trains.length) {
            this.checkoutData.selectedSolution = e.sol.travelSolutions[0];
            if (!_.has(this.checkoutData.selectedSolution, 'servicesMap')) {
                this.checkoutData.selectedSolution.servicesMap = e.sol.servicesMap;
            }
            else {
                this.checkoutData.selectedSolution.servicesMap.catalog_services = _.merge(this.checkoutData.selectedSolution.servicesMap.custom_services, e.sol.travelSolutions[0].servicesMap.catalog_services);
                this.checkoutData.selectedSolution.servicesMap.offers = _.merge(this.checkoutData.selectedSolution.servicesMap.offers, e.sol.travelSolutions[0].servicesMap.offers);
            }
            this.selectedSolution = e.sol.travelSolutions[0].original;
            this.travellers = e.sol.travellers;
        }
        else if (e.travelSolution) {
            this.checkoutData.selectedSolution = e.travelSolution;
            this.selectedSolution = e.travelSolution.original;
        }
        this.updateTotal();
    };
    SearchResultsComponent.prototype.getDiffDays = function (date) {
        var startDay = this.checkoutData.direction === 'RETURN' ? moment(moment(this.checkoutData.return.start).format('YYYY-MM-DD')) : moment(moment(this.checkoutData.start).format('YYYY-MM-DD'));
        var endDay = moment(date);
        return Math.abs(endDay.diff(startDay, 'days'));
    };
    SearchResultsComponent.prototype.editSearch = function () {
        this.trnService.step.next(1);
        this.checkoutData.dataAv = null;
        this.checkoutData.selectedSolution = null;
        this.trnService.checkoutData.next(this.checkoutData);
    };
    SearchResultsComponent.prototype.selectDetails = function (i) {
        this.details = i;
        this.selectedSol = -1;
    };
    SearchResultsComponent.prototype.updateTotal = function () {
        var _this = this;
        this.selectOffer = [];
        this.total = {
            value: 0,
            valueNet: 0,
            fee: 0
        };
        this.fee = 0;
        this.checkoutData.seats = false;
        this.checkoutData.seatsCount = 0;
        this.checkoutData.selectedSolution.trains.forEach(function (d) {
            if (!_this.checkoutData.selectedSolution.custom_off && _this.checkoutData.mode !== 'carnet') {
                var row = _.get(_this.checkoutData, 'selectedSolution.priceGrid.' + d.id + '.priceSelected.row');
                var col = _.get(_this.checkoutData, 'selectedSolution.priceGrid.' + d.id + '.priceSelected.col');
                if (!row || !col) {
                    console.warn('price not selected for train', d, _this.checkoutData.selectedSolution);
                    return;
                }
                _this.total['value'] += _this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].totalPrice;
                _this.total['valueNet'] += _this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].totalPriceNet;
                _this.total['fee'] += _this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].fee;
                _this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].offers.forEach(function (o) {
                    if (_this.checkoutData.selectedSolution.offeredServices[o]
                        .can_select_seatmap) {
                        _this.checkoutData.seats = true;
                        _this.checkoutData.seatsCount++;
                    }
                    _this.selectOffer.push(_this.checkoutData.selectedSolution.offeredServices[o]);
                });
            }
            else if (_this.checkoutData.mode == 'carnet') {
                _this.selectOffer.push({ id: _this.selectedServiceCarnet });
                _this.checkoutData.seats = _.get(_this.checkoutData.selectedSolution, 'offeredServices.' + _this.selectedServiceCarnet + '.can_select_seatmap');
                if (_this.checkoutData.seats)
                    _this.checkoutData.seatsCount++;
            }
            else {
                _this.travellers.forEach(function (tr) {
                    if (_this.checkoutData.selectedSolution.custom_off[d.id][tr.xmlId]) {
                        var sel = _this.checkoutData.selectedSolution.custom_off[d.id][tr.xmlId];
                        var offer = { id: sel.offer[0] };
                        if (sel.parameter)
                            offer.parameters = sel.parameter;
                        _this.selectOffer.push(offer);
                        _this.total['value'] += sel.price;
                        _this.total['valueNet'] += sel.netPrice;
                        _this.total['fee'] += sel.fee;
                        if (_.get(_this.checkoutData, ['selectedSolution', 'offeredServices', offer.id, 'can_select_seatmap'], false)) {
                            _this.checkoutData.seats = true;
                            _this.checkoutData.seatsCount++;
                        }
                    }
                });
                /*this.total = this.checkoutData.selectedSolution;*/
            }
        });
        this.checkoutData.dataAv = {
            travellers: this.travellers,
            selectedOffer: this.selectOffer,
            travelSolutions: this.selectedSolution
        };
        this.checkoutData.arrival = this.arrival;
        this.checkoutData.departure = this.departure;
        this.checkoutData.totalSelectedSolution = { value: this.total['value'], valueNet: this.total['valueNet'], fee: this.total['fee'] };
        this.trnService.checkoutData.next(this.checkoutData);
        this.total['value'] = this.checkoutData.direction !== 'RETURN' ? this.total['value'] : this.checkoutData.forward.total['value'] + this.total['value'];
        this.total['valueNet'] = this.checkoutData.direction !== 'RETURN' ? this.total['valueNet'] : this.checkoutData.forward.total['valueNet'] + this.total['valueNet'];
        this.total['fee'] = this.checkoutData.direction !== 'RETURN' ? this.total['fee'] : this.checkoutData.forward.total['fee'] + this.total['fee'];
        this.trnService.total.next(this.total);
    };
    SearchResultsComponent.prototype.moreRes = function () {
        var _this = this;
        this.trnService.loading.next(true);
        var s = this.validSolution[this.validSolution.length - 1];
        var date = s.departure;
        var r = {
            adult: this.checkoutData.adult || 0,
            children: this.checkoutData.children,
            from: this.checkoutData.from,
            to: this.checkoutData.to,
            direction: this.checkoutData.direction,
            start: date,
        };
        if (this.checkoutData.return) {
            r.from = this.checkoutData.return.from;
            r.to = this.checkoutData.return.to;
            r.start = _.get(this.validSolution, [this.validSolution.length - 1, 'departure']);
            r.returnTripDate = r.start;
            r.roundTrip = true;
            r.ar = this.checkoutData.ar;
            r.travellers = this.checkoutData.travellers;
            r.forwardTripDate = _.get(this.checkoutData, 'forward.selectedSolution.departure');
            r.forwardTrip = _.get(this.checkoutData, 'forward.solution');
        }
        this.trnService.getSolutions(r)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (sol) {
                if (_.isEmpty(sol.travelSolutions)) {
                    _this.showMore = false;
                    return; // no other solution found
                }
                if (_this.checkoutData.return) {
                    _this.checkoutData.returnSearchSolutions = tslib_1.__spread(_this.checkoutData.returnSearchSolutions, sol['travelSolutions']);
                    sol['travelSolutions'] = sol['travelSolutions'].map(function (s) { return s.returnTravelSolution; });
                }
                // check duplicate
                var lastSolutionIndex = sol.travelSolutions.findIndex(function (travelSolution) {
                    return s && travelSolution.solutionId == s.solutionId;
                });
                if (lastSolutionIndex >= 0)
                    sol.travelSolutions.splice(0, lastSolutionIndex + 1);
                _this.checkoutData.searchSolutions.travelSolutions = tslib_1.__spread(_this.checkoutData.searchSolutions.travelSolutions, sol['travelSolutions']);
                // @ts-ignore
                _this.trnService.updateServicesMap(sol['servicesMap'] || sol['serviceMap']);
                var servicesMap = _this.trnService.servicesMap.value;
                _this.checkoutData.searchSolutions.travelSolutions.forEach(function (s) {
                    s.trains.forEach(function (t) {
                        if (s.priceGrid[t.id] !== undefined &&
                            s.priceGrid[t.id].best_price !== undefined &&
                            s.bestPrice) {
                            s.priceGrid[t.id].priceSelected = {
                                row: s.priceGrid[t.id].best_price.row_key,
                                col: s.priceGrid[t.id].best_price.col_key
                            };
                        }
                    });
                });
                _this.validSolution = [];
                var newViewItems = _this.checkoutData.searchSolutions.travelSolutions.map(function (view) {
                    view.travellers = sol['travellers'];
                    view.servicesMap = servicesMap;
                    return view;
                });
                // @ts-ignore
                _this.validSolution = tslib_1.__spread(_this.validSolution, newViewItems);
            },
            error: function () {
                _this.trnService.loading.next(false);
            },
            complete: function () {
                _this.trnService.loading.next(false);
            }
        });
    };
    SearchResultsComponent.prototype.changeServiceCarnet = function (event) {
        this.selectedServiceCarnet = event.value;
        this.checkoutData.carnetData.selectedService = this.selectedServiceCarnet;
        this.updateTotal();
    };
    SearchResultsComponent.prototype.resetResults = function () {
        this.solutions = [];
        this.validSolution = undefined;
        this.selectedSol = -1;
        this.details = -1;
        this.checkoutData = _.omit(this.checkoutData, ['selectedSolution', 'selection', 'dataAv', 'seatMaps', 'seatsCount', 'seatsSel']);
        this.checkoutData.seats = false;
        this.checkoutData.returnSearch = false;
    };
    return SearchResultsComponent;
}());
export { SearchResultsComponent };
