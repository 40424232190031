import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { DatasetService } from '../../services/dataset.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import formConfig from './form.config';
import { IFormConfig } from 'app/interfaces';


export interface IDatasetCommandDialogData{
	datasetACS: DatasetActionContainerService;
	formConfig: IFormConfig;
	url: string;
	postData: any;
	initialValues?: any;
}

@Component({
	selector: 'dataset-command-dialog',
	templateUrl: 'dialog.component.html'
})
export class DatasetCommandDialogComponent implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;
	public formConfig: IFormConfig;
	public datasetACS: DatasetActionContainerService;
	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<DatasetCommandDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDatasetCommandDialogData,
		public datasetService: DatasetService
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS = this.data.datasetACS;
		this.formConfig = this.data.formConfig;
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onSave(): void {
		this.loading = true;
		this.formViewer.formGroup.disable();
		const formData = this.formViewer.getFormData();
		if(this.data.postData){
			for (const key in this.data.postData) {
				if (!Object.prototype.hasOwnProperty.call(this.data.postData, key)) continue;
				const value = this.data.postData[key];
				if(formData instanceof FormData){
					formData.set(key, value);
				}else{
					formData[key] = value;
				}
			}
		}
		
		this.datasetService.post(this.data.url, formData)
		.subscribe({
			next: () => {
				this.dialogRef.close(true);
			},
			error: response => {
				this.formViewer.formGroup.enable();
				if(response.error && response.error.errors){
					this.formViewer.setErrors(response.error.errors);
				}
				this.loading = false;
			}
		});
		
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
