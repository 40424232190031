import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { makeDrawValuePlugin } from '../chartjs.plugins';

@Component({
	selector   : 'reviews',
	templateUrl: './reviews.component.html',
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class ReviewsComponent extends DestroyableComponent implements OnInit{
	public showLoader: boolean;

	public barChartOptions: ChartOptions = {
		responsive: true,
		scales: { 
			xAxes: [{}], 
			yAxes: [
				{
					id: 'avg',
					type: 'linear',
					position: 'left',
					ticks: {
						max: 5,
						min: 0
					}
				}, 
				{
					id: 'percent',
					type: 'linear',
					position: 'right',
					ticks: {
						max: 100,
						min: 0
					}
				}
			]
		},
		legend: {
			position: 'right',
			align: 'start',
			fullWidth: true,
			labels: {
				usePointStyle: true,
				padding: 15
			}
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		},
	};
	public chartPlugins = [makeDrawValuePlugin()];
	public barChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
	public barChartType: ChartType = 'line';
	public barChartLegend = true;

	public reviews: ChartDataSets[];

	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.dashboardService.reviewResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				this.onData(response);
			}
		});
	}

	public onData(response: any): void{
		this.showLoader = true;
		
		if(!response) return;
		else if (response.length) this.showLoader = false;

		const data: ChartDataSets[] = [
			{ 
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], 
				label: 'negative',
				yAxisID: 'percent',
				lineTension: 0, 
				fill: false,
				backgroundColor: '#ff6384',
				hoverBackgroundColor: '#ff6384',
				borderColor: '#ff6384',
				hoverBorderColor: '#ff6384',
				pointBackgroundColor: '#ff6384',
				pointHoverBackgroundColor: '#ff6384',
				pointBorderColor: '#ff6384',
				pointHoverBorderColor: '#ff6384',
				pointRadius: 5
			},
			{
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], 
				label: 'positive', 
				yAxisID: 'avg',
				lineTension: 0, 
				fill: false,
				backgroundColor: '#4bc0c0',
				hoverBackgroundColor: '#4bc0c0',
				borderColor: '#4bc0c0',
				hoverBorderColor: '#4bc0c0',
				pointBackgroundColor: '#4bc0c0',
				pointHoverBackgroundColor: '#4bc0c0',
				pointBorderColor: '#4bc0c0',
				pointHoverBorderColor: '#4bc0c0',
				pointRadius: 5
			}
		];

		if(response.length > 0){
			const monthCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			const monthTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			for (const item of response) {
				const monthIndex = Number(item.month) - 1;
				monthCount[monthIndex]++;
				monthTotal[monthIndex] += item.total;
				data[1].data[monthIndex] = Number(item.avg_points) + Number(data[1].data[monthIndex]);
				data[0].data[monthIndex] += item.negative_points;
			}

			for (let index = 0; index < 12; index++) {
				const avg = Number(data[1].data[index]);
				const negPoints = Number(data[0].data[index]);
				data[1].data[index] =  Math.round((avg / monthCount[index]) * 100) / 100;
				data[0].data[index] =  Math.round((negPoints / monthTotal[index]) * 10000) / 100;
			}
		}
		this.reviews = data;
	}
	// -----------------------------------------------------------------------------------------------------
}
