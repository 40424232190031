import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('host', {
	key: 'host',
	name: 'Host',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('username', {
	key: 'username',
	name: 'Nome utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('password', {
	key: 'password',
	name: 'Password',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('root', {
	key: 'root',
	name: 'Percorso server',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('protocol', {
	key: 'protocol',
	name: 'Protocollo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'FTP',
			value: 'FTP'
		},
		{
			label: 'SFTP',
			value: 'SFTP'
		}
	]
});

export {fieldsMap as filesystemsFieldsMap};
