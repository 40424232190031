import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrenitaliaCarnetComponent } from './trenitalia-carnet.component';
import {TrenitaliaService} from '../trenitalia.service';
import {FormViewerService} from '../../../../../services/form-viewer.service';
import {BookingService} from '../../booking.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchCarnetComponent } from './search-carnet/search-carnet.component';
import { SelectCarnetComponent } from './select-carnet/select-carnet.component';
import { TravellersCarnetComponent } from './travellers-carnet/travellers-carnet.component';
import {FlexModule} from '@angular/flex-layout';
import {FormInputViewerModule} from '../../../../components/form-input-viewer/form-input-viewer.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PricesComponent} from '../prices/prices.component';
import {OtherSolutionComponent} from '../prices/other-solution/other-solution.component';
import {TrenitaliaDialogModule} from '../trenitalia-dialog.module';
import {TrenitaliaAuthComponent} from '../trenitalia-auth/trenitalia-auth.component';
import {BillingFormComponent} from '../search-travellers/billing-form/billing-form.component';
import {SearchTravellersModule} from '../search-travellers/search-travellers.module';


@NgModule({
	declarations: [
		TrenitaliaCarnetComponent,
		SearchCarnetComponent,
		SelectCarnetComponent,
		TravellersCarnetComponent
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressBarModule,
		MatStepperModule,
		MatTabsModule,
		MatButtonModule,
		FlexModule,
		FormInputViewerModule,
		ReactiveFormsModule,
		MatIconModule,
		MatCardModule,
		MatExpansionModule,
		MatTooltipModule,
		SearchTravellersModule
	],
	exports: [TrenitaliaCarnetComponent, SearchCarnetComponent],
	entryComponents: [
		TrenitaliaCarnetComponent,
		TrenitaliaAuthComponent,
		BillingFormComponent
	]
})
export class TrenitaliaCarnetModule {}
