import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatRadioModule} from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';


import { DocumentsPageComponent } from './documents.component';

import { TranslateModule } from '@ngx-translate/core';

import { ChartsModule } from 'ng2-charts';
@NgModule({
	declarations: [
		DocumentsPageComponent
	],
	imports     : [
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		MatRadioModule,
		FormsModule,
		MatDatepickerModule,
		ChartsModule,
		NgxChartsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatCardModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatCheckboxModule,

		TranslateModule.forChild(),
	]
})
export class DocumentsPageModule{}
