import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('meeting_point_list_description'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('start_day'),
			fieldsMap.get('start_time')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('min_allotment'),
			fieldsMap.get('default_max_allotment'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('participants_count'),
			fieldsMap.get('availability'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('type'),
			fieldsMap.get('start_status'),
		]
	},
	{
		config: {
			flexLayout: 'row',
			class: 'lang-details'
		},
		fields: [
			fieldsMap.get('guide_languages'),
			fieldsMap.get('audioguide_languages'),
		]
		
	},
	fieldsMap.get('note')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
