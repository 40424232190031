<ng-template #trainTitle let-travelSolution="travelSolution">
	<span *ngIf='travelId' class="trip_booking_package_description">
		{{ travelSolution.description }}
	</span>
	<span *ngIf='!travelId' class="trip_booking_package_description">
		{{ travelSolution.start.stationName }}
		<mat-icon style="vertical-align: middle">chevron_right</mat-icon>
		{{ travelSolution.end.stationName }}
	</span>
</ng-template>

<ng-template #trainSubTitle let-travelSolution="travelSolution">
	<span class='font-size-14 secondary-text' *ngIf="!isCarnet && travelSolution.departure">
		{{ travelSolution.departure | date: 'dd/MM/yyyy HH:mm'}} -
		{{ travelSolution.arrival| date:'HH:mm' }}
	</span>
	<span class='font-size-14 secondary-text' *ngIf="isCarnet">
		CARNET VIAGGI
	</span>
</ng-template>

<div class="position-relative trip-booking-package-card-container cursor-pointer" (click)="viewProduct()">
	<div class="p-16">
		<div *ngIf="dataTrain.returnTravelSolution" class="mb-8">
			<span class="ar-title">Viaggio A/R</span>
		</div>
		<ng-container *ngIf='!tripBookingPackage.parent_trip_booking_package_id'>
			<trip-booking-package-header
				icon="train"
				[showConfirm]="showHeaderActions && tripBookingStatus == 'mix'"
				[showEdit]="showHeaderActions"
				[canDelete]="showHeaderActions"
				(editClick)="editTripBookingPackage()"
				(confirmClick)="confirmTripBookingPackage()"
				(deleteClick)='deleteTripBookingPackage()'
			>
				<ng-container
					ngProjectAs="[title]" 
					[ngTemplateOutlet]='trainTitle' 
					[ngTemplateOutletContext]='{travelSolution:dataTrain}'>
				</ng-container>

				<ng-container 
					ngProjectAs="[sub-title-1]" [ngTemplateOutlet]='trainSubTitle' 
					[ngTemplateOutletContext]='{travelSolution:dataTrain}'>
				</ng-container>

				<ng-container ngProjectAs="[sub-title-2]">
					<strong *ngIf="status==='canceled'" style="color:red"> CANCELLATO </strong>
					<strong *ngIf="status==='refunded'" style="color:red"> RIMBORSATO </strong>
					<strong *ngIf="!status && !tripBookingPackage.tour_operator_booking_commit_status && tripBookingPackage.booking_status == 'canceled' " style="color:var(--color-dark-blue)"> DA CONFERMARE </strong>
				</ng-container>

				<!--TODO make actions header dinamics-->
				<!--<button mat-icon-button
						(click)='editTripBookingTravellers();$event.stopPropagation();'
						matTooltip="Modifica dati passeggeri">
					<mat-icon color='primary'>group</mat-icon>
				</button>-->

			</trip-booking-package-header>
		</ng-container>

		<!--Return -->
		<ng-container *ngIf='!tripBookingPackage.parent_trip_booking_package_id && dataTrain.returnTravelSolution'>
			<trip-booking-package-header
				icon="train"
				[showConfirm]="false"
				[showEdit]="false"
				[canDelete]="false"
				[hidePrice]="true"
				(editClick)="editTripBookingPackage()"
				(confirmClick)="confirmTripBookingPackage()"
				(deleteClick)='deleteTripBookingPackage()'
			>
				<ng-container
					ngProjectAs="[title]"
					[ngTemplateOutlet]='trainTitle'
					[ngTemplateOutletContext]='{travelSolution:dataTrain.returnTravelSolution}'>
				</ng-container>

				<ng-container
					ngProjectAs="[sub-title-1]" [ngTemplateOutlet]='trainSubTitle'
					[ngTemplateOutletContext]='{travelSolution:dataTrain.returnTravelSolution}'>
				</ng-container>

				<ng-container ngProjectAs="[sub-title-2]">
					<strong *ngIf="status==='canceled'" style="color:red"> CANCELLATO </strong>
					<strong *ngIf="status==='refunded'" style="color:red"> RIMBORSATO </strong>
					<strong *ngIf="!status && !tripBookingPackage.tour_operator_booking_commit_status && tripBookingPackage.booking_status == 'canceled' " style="color:var(--color-dark-blue)"> DA CONFERMARE </strong>

				</ng-container>

				<!--TODO make actions header dinamics-->
				<!--<button mat-icon-button
						(click)='editTripBookingTravellers();$event.stopPropagation();'
						matTooltip="Modifica dati passeggeri">
					<mat-icon color='primary'>group</mat-icon>
				</button>-->

			</trip-booking-package-header>
		</ng-container>
        <div *ngIf="participantsWarning.status" >
            <span  class="text-semibold text-color-tangerine"> Attenzione il numero dei partecipanti al viaggio sono diversi da quelli della prenotazione</span><br>
            <span>Partecipanti: {{participantsWarning.value.ADT}} ADT - {{participantsWarning.value.CHD}} RGZ</span>

        </div>
	</div>
	<ng-container *ngIf="tripBookingPackage.children_trip_booking_packages">
		<ng-container *ngFor="let children_trip_booking_package of tripBookingPackage.children_trip_booking_packages">
			<hr/>
			<trip-booking-package-trenitalia-post-sale
				*ngIf="children_trip_booking_package.parent_trip_booking_package_relation_type == 'post_sale'"
				[tripBookingPackage]="children_trip_booking_package"
			></trip-booking-package-trenitalia-post-sale>
		</ng-container>
		<ng-container *ngIf="tripBookingPackage.children_trip_booking_packages.length">
			<hr/>
			<div class="trip-booking-package-total-row flex-row justify-between">
				<div class="flex"></div>
				<span class="font-size-14 text-color-black"
					  matTooltip="{{totalFee>0?'Prezzo:'+totalRowLordo-totalFee+' € + Diritti agenzia:'+totalFee+' €':''}}"
				>Totale: {{totalRowLordo | currency:'EUR' }}</span>
			</div>
		</ng-container>
	</ng-container>
	<!-- error notification  Tour Operator Booking Data-->
	<mat-card *ngIf='tripBookingPackage.tour_operator_booking_data?.status_error; let statusError' class="warn" style="margin-top: 12px;">
		<mat-card-content>
			<span>{{statusError}}</span>
		</mat-card-content>
	</mat-card>
</div>