import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('domain_id', {
	key: 'domain_ID',
	name: 'Dominio',
	valueType: ValueTypes.STRING,
	datasetCode: 'domains',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		}
	}
});

export default fieldsMap;
