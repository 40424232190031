/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dataset-detail-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../base-actions/detail/dataset-detail.component.ngfactory";
import * as i8 from "../../../services/dataset-action-container.service";
import * as i9 from "../../../../../../services/app.service";
import * as i10 from "../../../../../pages/page-closure-container.service";
import * as i11 from "../../../../../../services/auth.service";
import * as i12 from "../../../services/dataset.service";
import * as i13 from "../../../../../../services/domain-filter.service";
import * as i14 from "../../../services/dataset-record.service";
import * as i15 from "../../../base-actions/detail/dataset-detail.component";
import * as i16 from "@angular/router";
import * as i17 from "../../../services/dataset-navigator.service";
import * as i18 from "../../../../../../../@fuse/services/translation-loader.service";
import * as i19 from "../../../../../../services/websocket.service";
import * as i20 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i21 from "@angular/material/button";
import * as i22 from "@angular/cdk/a11y";
import * as i23 from "@angular/common";
import * as i24 from "./dataset-detail-dialog.component";
var styles_DatasetDetailDialogComponent = [i0.styles];
var RenderType_DatasetDetailDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatasetDetailDialogComponent, data: {} });
export { RenderType_DatasetDetailDialogComponent as RenderType_DatasetDetailDialogComponent };
function View_DatasetDetailDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["Dettagli ", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.datasetACS.datasetConfig.singleTitle)))); _ck(_v, 2, 0, currVal_1); }); }
function View_DatasetDetailDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DatasetDetailDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dataset-detail", [], null, null, null, i7.View_DatasetDetailComponent_0, i7.RenderType_DatasetDetailComponent)), i1.ɵprd(512, null, i8.DatasetActionContainerService, i8.DatasetActionContainerService, [i9.AppService, i10.PageClosureContainerService, i11.AuthService, i12.DatasetService, i13.DomainFilterService, i3.TranslateService, i1.Injector]), i1.ɵprd(512, null, i14.DatasetRecordService, i14.DatasetRecordService, [i8.DatasetActionContainerService, i12.DatasetService]), i1.ɵdid(4, 245760, null, 0, i15.DatasetDetailComponent, [i16.ActivatedRoute, i12.DatasetService, i16.Router, i11.AuthService, i8.DatasetActionContainerService, i14.DatasetRecordService, i10.PageClosureContainerService, i17.DatasetNavigatorService, i18.FuseTranslationLoaderService, i19.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], inputRecord: [2, "inputRecord"], showActions: [3, "showActions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.datasetACS.datasetCode; var currVal_2 = _co.datasetACS.datasetData.record; var currVal_3 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DatasetDetailDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "ml-4"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_MatButton_0, i20.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i21.MatButton, [i1.ElementRef, i22.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Modifica")); _ck(_v, 2, 0, currVal_3); }); }
export function View_DatasetDetailDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i23.TitleCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetDetailDialogComponent_1)), i1.ɵdid(2, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetDetailDialogComponent_2)), i1.ɵdid(6, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetDetailDialogComponent_3)), i1.ɵdid(8, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["color", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_MatButton_0, i20.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i21.MatButton, [i1.ElementRef, i22.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetDetailDialogComponent_4)), i1.ɵdid(16, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.loading || _co.datasetACS.loading.value); _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.datasetACS.loading.value && _co.datasetACS.datasetData.record); _ck(_v, 8, 0, currVal_2); var currVal_5 = ""; _ck(_v, 12, 0, currVal_5); var currVal_7 = ((!_co.loading && _co.datasetACS.canEdit(_co.datasetACS.datasetData.record)) && _co.datasetACS.actionEnabled("edit")); _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Chiudi")); _ck(_v, 13, 0, currVal_6); }); }
export function View_DatasetDetailDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "dataset-detail-dialog", [], null, null, null, View_DatasetDetailDialogComponent_0, RenderType_DatasetDetailDialogComponent)), i1.ɵprd(512, null, i10.PageClosureContainerService, i10.PageClosureContainerService, [i9.AppService]), i1.ɵprd(512, null, i8.DatasetActionContainerService, i8.DatasetActionContainerService, [i9.AppService, i10.PageClosureContainerService, i11.AuthService, i12.DatasetService, i13.DomainFilterService, i3.TranslateService, i1.Injector]), i1.ɵdid(3, 245760, null, 0, i24.DatasetDetailDialogComponent, [i8.DatasetActionContainerService, i9.AppService, i12.DatasetService, i2.MatDialog, i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DatasetDetailDialogComponentNgFactory = i1.ɵccf("dataset-detail-dialog", i24.DatasetDetailDialogComponent, View_DatasetDetailDialogComponent_Host_0, {}, {}, []);
export { DatasetDetailDialogComponentNgFactory as DatasetDetailDialogComponentNgFactory };
