import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { IFormConfig } from 'app/interfaces';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export interface IUnifiedListDialogData {
	title: string;
	datasetACS: IDataContainerService;
	formConfig: IFormConfig;
}

@Component({
	selector: 'unified-list-dialog',
	templateUrl: 'dialog.component.html'
})
export class UnifiedListDialogComponent implements OnInit {

	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<UnifiedListDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IUnifiedListDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {}

	close(): void {
		this.dialogRef.close(false);
	}

	onAddClick(): void {
		this.dialogRef.close(true);
	}
}
