import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ]
});

fieldsMap.set('total_amount', {
	key: 'total_amount',
	name: 'Totale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ]
});

fieldsMap.set('threshold', {
	key: 'threshold',
	name: 'Threshold',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('reseller_notification_emails', {
	key: 'reseller_notification_emails',
	name: 'Email di notifica',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

export {fieldsMap};
