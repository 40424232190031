import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, IDatasetCallDefinition, IPaginationResponse, ValueTypes, InputTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import filterForm from './forms/filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { Subject, Observable, EMPTY, of } from 'rxjs';
import * as _ from 'lodash';
import { HttpHeaders } from '@angular/common/http';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';
import { AuthService } from 'app/services/auth.service';
import { ViewContainerRef } from '@angular/core';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/actual_trips/detail/:recordId',
	params: {
		with_relations: 'domain',
		with_attributes: 'is_owner,participant_overall,groups_overall,total_participants_confirmed'
	}
};

function askHandleCancelBookings(datasetActionContainerSerivice: IDataContainerService, viewContainerRef: ViewContainerRef, options: {isSingleTrip: boolean, knowHasBooking: boolean}){
	const datasetNavigatorService = datasetActionContainerSerivice.injector.get(DatasetNavigatorService);
	let message = 'Come vuoi che vengano modificate eventuali prenotazioni confermate su queste partenze?<br>Riceverai delle email con il riepilogo delle prenotazioni cancellate (se presenti) per ogni partenza cancellata.';
	if(options.isSingleTrip){
		message = 'Come vuoi che vengano modificate le prenotazioni confermate su questa partenza?<br>Riceverai una email con il riepilogo delle prenotazioni cancellate (se presenti).';
		if(options.knowHasBooking){
			message = 'Sono presenti prenotazioni confermate per questa partenza.<br>Seleziona come intervenire su queste prenotazioni?<br>Riceverai una email con il riepilogo delle prenotazioni cancellate.';
		}
	}else{
		if(options.knowHasBooking){
			message = 'Sono presenti prenotazioni confermate per queste partenze.<br>Seleziona come intervenire su queste prenotazioni?<br>Riceverai delle email con il riepilogo delle prenotazioni cancellate per ogni partenza cancellata.';
		}
	}
	
	const dialogData = {
		title: 'Prenotazioni',
		dataContainerService: datasetActionContainerSerivice,
		formConfig: {
			fields: [
				{
					key: 'action_on_bookings',
					name: 'Azione',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.SELECT,
						hint: 'Azione da eseguire sulle prenotazioni confermate',
					},
					defaultValue: () => {
						return 'nope'
					},
					options: [
						{
							label: 'Non modificare le prenotazioni',
							value: 'nope'
						},
						{
							label: 'Cancella',
							value: 'cancel'
						},
						{
							label: 'Cancella e Rimborsa (se possibile)',
							value: 'cancel_and_refund'
						}
					]
				},
				{
					key: 'email',
					name: 'Email',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.TEXT,
						hint: 'Email a cui inviare il recap delle prenotazioni cancellate',
						clearable: true
					},
					defaultValue: () => {
						return _.get(datasetActionContainerSerivice.injector.get(AuthService).userDataObserver.value, 'user.email');
					}
				},
				{
					key: 'cancel_reason_code',
					name: 'Motivazione',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.SELECT,
						hint: 'Messaggi precostruiti'
					},
					defaultValue: () => {
						return 'weather'
					},
					options: [
						{
							label: 'Maltempo',
							value: 'weather'
						},
						{
							label: 'Cause esterne',
							value: 'external_cause'
						},
						{
							label: 'Altro',
							value: 'other'
						}
					]
				},
				{
					key: 'cancel_reason_text',
					name: 'Motivazione Personalizzata',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.TEXT,
						hint: 'Lascia il campo vuoto se vuoi usare un messaggio precostruito',
						clearable: true
					},
					onValueChanged(form){
						if(form && form.get('cancel_reason_text') && form.get('cancel_reason_text').value){
							form.get('cancel_reason_code').setValue('other');
						}
					}
				}
			]
		},
		positiveText: 'Procedi',
		message
	};
	
	return datasetNavigatorService.openDialog<IFormDialogData>(FormDialogComponent, dialogData, {viewContainerRef}).afterClosed()
	.pipe(exhaustMap(res => {
		if(!res) return EMPTY;
		return of(res);
	}));
}

function beforeCancelSingleActualTrip(dataContainerService: IDataContainerService, actualTripId: string, viewContainerRef?: ViewContainerRef){
	const params = {
		actual_trip_id: actualTripId,
		booking_status: 'confirmed',
		only_parents: 1,
		perPage: 1
	};
	let headers = new HttpHeaders({});
	if(dataContainerService.hasValueInKeyPath('contextual_domain_id')){
		headers = headers.append('X-Domain-Id', dataContainerService.getValueFromKeyPath('contextual_domain_id'));
	}
	return new Observable((subscriber) => {
		dataContainerService.injector.get(DatasetService).get<IPaginationResponse<any>>("/dataset/trip_booking_packages", params, headers)
		.pipe(
			exhaustMap(response => {
				if(!response) return EMPTY;
				if(_.isEmpty(response.items)) return of(false);
				return of(response);
			}),
			exhaustMap((response) => {
				if(response === false) return of(response);
				return askHandleCancelBookings(dataContainerService, viewContainerRef, {isSingleTrip: true, knowHasBooking: true});
			})
		).subscribe({
			next: (askData)=>{
				if(askData === false){
					subscriber.next({});
				}else{
					subscriber.next({
						booking_cancellation: askData
					});
				}
			},
			error: () => {
				subscriber.complete();
			},
			complete: () => {
				subscriber.complete();
			}
		})
	});
};

export const supportedLanguages = [
	{
		label: 'Italiano',
		value: 'it'
	},
	{
		label: 'Inglese',
		value: 'en'
	},
	{
		label: 'Spagnolo',
		value: 'es'
	},
	{
		label: 'Francese',
		value: 'fr'
	},
	{
		label: 'Tedesco',
		value: 'de'
	},
	{
		label: 'Giapponese',
		value: 'ja'
	},
	{
		label: 'Cinese',
		value: 'zh'
	},
	{
		label: 'Russo',
		value: 'ru'
	},
	{
		label: 'Portoghese',
		value: 'pt'
	}
];

export const ActualTripConfig: IDatasetConfig = {
	name: 'actual_trips',
	title: 'Partenze',
	singleTitle: 'Partenza',
	ajaxDatasetCode: 'actual_trips',
	fieldsMap: fieldsMap,
	supportProperties: true,
	fetchConfig: {
		with_relations: 'domain',
		with_attributes: 'is_owner,participant_overall,groups_overall,total_participants_confirmed'
	},
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm,
			},
			beforeSave(dataContainerService: IDataContainerService, recordId: string, data: any, previousData: any, viewContainerRef?: ViewContainerRef){
				const currentStartStatus = _.get(previousData, 'start_status');
				const newStatus = _.get(data, 'start_status');
				if(newStatus != 'canceled' || currentStartStatus == 'canceled') return of(data);
				return beforeCancelSingleActualTrip(dataContainerService, recordId, viewContainerRef).pipe(map((result: any) => { return {...data, ...result } }));
			},
			beforeDelete(dataContainerService: IDataContainerService, currendRecord, viewContainerRef){
				return beforeCancelSingleActualTrip(dataContainerService, currendRecord.id, viewContainerRef);
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				filterForm: filterForm,
				defaultSortBy: 'start_day|start_time',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Tipo',
						key: 'icon_type',
						showIf(dataContainerService: IDataContainerService): boolean{
							const tripPackage = dataContainerService.getValueFromKeyPath('trip_packages.record');
							if(tripPackage && tripPackage.tourcms_channel_id_tour_id){
								return false;
							}
							return true;
						}
					},
					{
						title: 'Nome',
						key: 'meeting_point_list_description'
					},
					{
						title: 'Data Partenza',
						key: 'start_date_time'
					},
					{
						title: 'Disponibilità',
						key: 'availability_with_status'
					},
					{
						title: 'Lingua',
						key: 'languages'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
				massActions: [
					{
						actionCode: 'create',
						buttonIcon: 'delete',
						buttonColor: 'warn',
						tooltip: 'Elimina i record selezionati',
						onClick(ids: string[], datasetActionContainerSerivice: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService, viewContainerRef?: ViewContainerRef): Observable<string>{
							const observer = new Subject<string>();
							datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
								contentMessage: 'Vuoi eliminare i ' + ids.length + ' elementi selezionati?',
								positiveText: 'Si',
							}).afterClosed()
							.pipe(exhaustMap(result => {
								if(!result) return EMPTY;
								return askHandleCancelBookings(datasetActionContainerSerivice, viewContainerRef, {isSingleTrip: false, knowHasBooking: false});
							}), exhaustMap(result => {
								if(!result) return EMPTY;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									width: '550px',
									data: {
										title: 'Azione in corso'
									}
								});

								return datasetNavigatorService.datasetService.post('/dataset/actual_trips/command/mass_delete', {
									ids: ids,
									email: result.email,
									trip_package_id: datasetActionContainerSerivice.getValueFromKeyPath('trip_packages.recordId'),
									action_on_bookings: result.action_on_bookings,
									cancel_reason_code: result.cancel_reason_code,
									cancel_reason_text: result.cancel_reason_text
								}).pipe(
									tap(res => {
										dialogRef.close();
									}),
									catchError(e => {
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.setMessage('Si è verificato un errore imprevisto.');
										throw e;
									})
								);
							})).subscribe({
								next: response => {
									observer.next('clear-selection');
									observer.complete();
									
									datasetActionContainerSerivice.datasetEvent.next({
										eventName: DatasetEvents.ACTION_REALOAD_LIST
									});
									datasetNavigatorService.appService.showSuccessMessage('Elementi eliminati.');
								},
								error: response => {
									observer.next();
									observer.complete();
									
								},
								complete: () => {
									observer.next('clear-selection');
									observer.complete();
								}
							});
							return observer;
						}
					}
				]
			}
		}
	}
};
