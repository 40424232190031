import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import textForm from './forms/text.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_package_language_contents/detail/:recordId',
	params: {
		with_relations: 'facilities,masterImage,masterImageSmall,masterVideo'
	}
};

export const TripPackageLanguageContentConfig: IDatasetConfig = {
	name: 'trip_package_language_contents',
	title: 'Pacchetti',
	singleTitle: 'Pacchetto',
	ajaxDatasetCode: 'trip_package_language_contents',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		textForm
	},
	fetchConfig: {
		with_relations: 'facilities,masterImage,masterImageSmall,masterVideo'
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any) {
				params.with_relations = 'facilities,masterImage,masterImageSmall,masterVideo';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							return record.lang;
						},
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
