import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrenitaliaSolutionDetailsComponent } from './trenitalia-solution-details/trenitalia-solution-details.component';
import { TrenitaliaDetailsComponent } from './trenitalia-details.component';
import { PostSaleDialogComponent } from './post-sale-dialog/post-sale-dialog.component';
import { FormViewerModule } from 'app/main/components/form-viewer/form-viewer.module';
import { SearchTravellersModule } from '../search-travellers/search-travellers.module';
import { TrenitaliaCarnetModule } from '../trenitalia-carnet/trenitalia-carnet.module';
import { TrenitaliaPostSaleChangeConfirmDialog } from './trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component';
import { TrenitaliaOtherSolutionModule } from '../prices/other-solution/other-solution.module';
import { TrainBreadcrumbsComponent } from '../components/train-breadcrumbs/train-breadcrumbs.component';
import { TrainBreadcrumbsModule } from '../components/train-breadcrumbs/train-breadcrumbs.module';

@NgModule({
	declarations: [
		TrenitaliaDetailsComponent,
		TrenitaliaPostSaleChangeConfirmDialog,
		TrenitaliaSolutionDetailsComponent,
		PostSaleDialogComponent
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressBarModule,
		FormViewerModule,
		MatButtonModule,
		TranslateModule,
		MatExpansionModule,
		MatIconModule,
		MatDividerModule,
		MatTableModule,
		FlexModule,
		MatTooltipModule,
		MatCardModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatTabsModule,
		SearchTravellersModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatSelectModule,
		MatMenuModule,
		MatStepperModule,
		MatDividerModule,
        TrenitaliaCarnetModule,
		TrenitaliaOtherSolutionModule,
		TrainBreadcrumbsModule
	],
	entryComponents: [
		PostSaleDialogComponent,
		TrenitaliaPostSaleChangeConfirmDialog,
		TrenitaliaDetailsComponent
	],
	exports: [
		TrenitaliaDetailsComponent,
		PostSaleDialogComponent,
		TrenitaliaPostSaleChangeConfirmDialog
	],
})
export class TrenitaliaDetailsModule {}
