<div [class]='formViewInfo && formViewInfo.style'>

	<form 
		autocomplete="off"
		name='form' 
		*ngIf='formViewInfo' 
		[formGroup]="formViewInfo.formGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'
		>
		<ng-container [ngTemplateOutlet]="formGroupTemplate" [ngTemplateOutletContext]='formViewInfo'></ng-container>
	</form>

</div>

<ng-template #formGroupContainerTemplate let-item='item'>
	<div class='form-group' 
		fxLayoutGap="10px"
		[fxLayout]="item.config && item.config.flexLayout || 'row wrap'"
		[fxLayoutAlign]="item.config && item.config.flexLayoutAlign || 'start'"
		[fxLayoutGap]="item.config && item.config.fxLayoutGap || '10px'"
		[fxFlex]="item.config && item.config.flex || '1 1 auto'"
		fxLayout.lt-lg='column'
		fxLayoutAlign.lt-lg='start center'
		fxFlex.lt-lg='1 1 auto'
		[style.max-width]='item.config && item.config.maxWidth'
		[style.overflow]='"hidden"'
		>
		<ng-container [ngTemplateOutlet]="formGroupTemplate" [ngTemplateOutletContext]='item'></ng-container>
	</div>
</ng-template>

<ng-template #formGroupTemplate let-items='fields'>
	<ng-container *ngFor='let item of items; index as i; trackBy: trackByFn'>
		<ng-container *ngIf='item.isGroup'>
			<ng-container *ngIf='item.config && item.config.section'>
				<mat-accordion *ngIf='!item.config.section.showIf || item.config.section.showIf(record, dataContainerService)'>
					<mat-expansion-panel [expanded]='item.config.section.open'>
						<mat-expansion-panel-header>
							<mat-panel-title *ngIf='item.config.section.title'>
								{{item.config.section.title | translate}}
							</mat-panel-title>
							<mat-panel-description *ngIf='item.config.section.description'>
								{{item.config.section.description | translate}}
							</mat-panel-description>
						</mat-expansion-panel-header>
						<ng-container [ngTemplateOutlet]="formGroupContainerTemplate" [ngTemplateOutletContext]='{item:item}'></ng-container>
					</mat-expansion-panel>
				</mat-accordion>
			</ng-container>
			<ng-container *ngIf='!item.config || !item.config.section'>
				<span *ngIf='item.title' class='h4'>{{item.title | translate}}</span>
				<ng-container [ngTemplateOutlet]="formGroupContainerTemplate" [ngTemplateOutletContext]='{item:item}'></ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!item.isGroup">
			<form-input-viewer [class]='item.class'
				fxLayoutGap="10"
				[fxFlex]="item.flex"
				[style.max-width]='item.maxWidth'
				[style.width]='item.width'
				[formInputDefinition]='item.formInputDefinition'
				[formGroup]='formViewInfo.formGroup'
				[data]='record'
				[dataContainerService]='dataContainerService'
				[formControlKey]='item.controlKey'
				[readOnly]='item.isReadOnly'
			></form-input-viewer>
		</ng-container>
	</ng-container>
</ng-template>
