import { Component, OnInit, OnDestroy } from '@angular/core';

import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'select-custom-icon-input',
	templateUrl: './input.component.html'
})
export class SelectCustomIconInputComponent extends DestroyableComponent implements OnInit, OnDestroy{
	public selectOptions: any[];

	constructor(
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.selectOptions = this.inputService.formInputDefinition.options || [];
		});
	}
}
