import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import infoForm from './forms/info.forms';
import filterForm from './forms/filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { Subject, Observable } from 'rxjs';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domains/detail/:recordId',
};

export const PublicDomainsConfig: IDatasetConfig = {
	name: 'public_domains',
	title: 'Domini',
	singleTitle: 'Dominio',
	ajaxDatasetCode: 'public_domains',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
		infoForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortDirection: 'asc',
				columns: [
					{
						title : 'Logo',
						key: 'logo',
						fieldView: FieldViews.IMAGE,
					},
					{
						title: 'Nome',
						key: 'display_name'
					}
				],
				actions: [],
			}
		}
	}
};
