import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent, HttpHeaders,
	HttpInterceptor
} from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

	constructor(
		public auth: AuthService,
		public app: AppService,
		public domainFilterService: DomainFilterService
	){}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const newHeaders = {
			'X-Requested-With': 'XMLHttpRequest',
			'Accept': 'application/json',
			'X-Backoffice-App-Location': window.location.pathname
		};
		if(this.domainFilterService && this.domainFilterService.filterDomainId.value){
			if(!request.headers.has('X-Domain-Id')) newHeaders['X-Domain-Id'] = this.domainFilterService.filterDomainId.value;
			// always override this, set the current filtered domain id
			newHeaders['X-Filter-Domain-Id'] = this.domainFilterService.filterDomainId.value;
		}
		const newRequest = request.clone({
			setHeaders: newHeaders,
			withCredentials: true
		});

		return next.handle(newRequest);
	}
}
