import {
	InputTypes,
	ValueTypes,
	IFormConfig,
	IFieldDefinition,
} from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('carnet', {
	key: 'carnet',
	name: 'Numero carnet',
    inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('name_carnet', {
	key: 'name_carnet',
	name: 'Nome intestatario',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('lastname_carnet', {
    key: 'lastname_carnet',
    name: 'Cognome intestatario',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});


export { fieldsMap };
