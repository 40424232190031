import { Component, Inject, Input, OnInit, Self, SkipSelf, ViewChild } from '@angular/core';
import { TripPackageLangContentBaseClass } from '../../lang-cont-base.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { TripPackageSmartInterfaceMediaComponent } from '../media/step.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'resell-trip-package-smart-interface-media',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ 
		{ 
			provide: 'originalDatasetACS', 
			useClass: DatasetActionContainerService
		},
		{ 
			provide: 'reselledDatasetACS', 
			useClass: DatasetActionContainerService
		},
		{ 
			provide: 'reselledDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'originalDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'reselledMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		},
		{ 
			provide: 'originalMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		} 
	]
})
export class ResellTripPackageSmartInterfaceMediaComponent extends TripPackageSmartInterfaceMediaComponent{

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Inject('reselledDatasetACS')
		@Self()
		public reselledDatasetACS: DatasetActionContainerService,
		@Self()
		@Inject('originalDatasetACS')
		public originalDatasetACS: DatasetActionContainerService,
		@Self()
		@Inject('reselledDatasetRS')
		public reselledDatasetRS: DatasetRecordService,
		@Self()
		@Inject('originalDatasetRS')
		public originalDatasetRS: DatasetRecordService,
		@Self()
		@Inject('reselledMultilanguageService')
		public reselledDatasetMCS: DatasetMultilanguageContainerService,
		@Self()
		@Inject('originalMultilanguageService')
		public originalDatasetMCS: DatasetMultilanguageContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public stepService: StepService
	){
		/**
		 * init parent constructor
		 */
		super(appService, reselledDatasetRS, reselledDatasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, stepService);
		
		this.configs = {
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentForeignKey: 'trip_package_id',
			parentValueKey: 'trip_packages.recordId'
		};
		/**
		 * carousel block configs
		 */
		this.carouselBlockConfigs = {
			datasetCode: 'trip_package_language_contents_carousel_images',
			parentDatasetCode: 'trip_package_language_contents',
			parentForeignKey: 'trip_package_language_content_id'
		};
	}

	ngOnInit(): void {

		this.stepService.setStepState({
			dataset_code: 'trip_packages',
			form_code: 'media',
			record_id: this.parentDatasetRS.recordId,
			status: 1
		});

		this.reselledDatasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.reselledDatasetRS,
			fetchData: true
		});
		this.originalDatasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'detail',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.originalDatasetRS,
			fetchData: true
		});
		this.originalDatasetRS.datasetACS = this.originalDatasetACS;
		this.reselledDatasetRS.datasetACS = this.reselledDatasetACS;

		this.reselledDatasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.reselledDatasetMCS.init({
				datasetACS: this.reselledDatasetACS,
				datasetRS: this.reselledDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'reselledData.id'
			});
			this.reselledDatasetMCS.fetchRecord();
		});

		this.originalDatasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.originalDatasetMCS.init({
				datasetACS: this.originalDatasetACS,
				datasetRS: this.originalDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			});
			this.originalDatasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record){
				this.reselledDatasetMCS.activeLanguageCodes = record.active_language_codes;
				this.originalDatasetMCS.activeLanguageCodes = record.active_language_codes;
			}
		});
	}


}
