import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { TrenitaliaService } from '../../trenitalia.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { BehaviorSubject, Subject, EMPTY, of, } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { catchError, exhaustMap, takeUntil } from 'rxjs/operators';
import { DatasetService } from '../../../../../components/dataset/services/dataset.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';

@Component({
	selector: 'trenitalia-billing-form-individual',
	templateUrl: './billing-form-individual.component.html',
	styleUrls: ['./billing-form-individual.component.scss'],
	providers: [FormViewerService]
})
export class BillingFormIndividualComponent implements OnInit, OnDestroy {
	public formViewer: FormViewerComponent;
	public fields = formFields;
    @Input('billingData') billingData: any;

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		private _trnService: TrenitaliaService,
		public formViewerService: FormViewerService,
		private datasetService: DatasetService,
		public matDialog: MatDialog,
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		})

		this.formViewerService.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(v => {
			this.updateCheckoutData();
		});

        if(this.billingData && this.billingData.personType=='INDIVIDUAL'){
            this.formViewerService.setValues(
                {
                    personType: _.get(this.billingData, 'personType'),
                    tc_name: _.get(this.billingData, 'firstName'),
                    tc_surname: _.get(this.billingData, 'lastName'),
                    tc_tax_code: _.get(this.billingData, 'fiscalCode'),
                    tc_country: _.get(this.billingData, 'address.country'),
                    tc_province: _.get(this.billingData, 'address.province'),
                    tc_city: _.get(this.billingData, 'address.city'),
                    tc_cap: _.get(this.billingData, 'address.postalCode'),
                    tc_address: _.get(this.billingData, 'address.address'),
                    tc_recipient_code: _.get(this.billingData, 'recipientCode'),
                    tc_pec: _.get(this.billingData, 'certifiedMail'),
                }

            );
        }
	}

	disableInput(key: string, disable = true): void{
		const control = this.formViewerService.getFieldControl(key);
		if(!control) return;
		if(disable) control.disable();
		else control.enable();
	}

	updateCheckoutData() {
		// set this.checkoutData.invoiceProfile
		// is sufficient the id if just exists
		const formData = this.formViewerService.getFormData()
		this._trnService.checkoutData.value.billingFormValid = this.formViewerService.formGroup.valid;
		this._trnService.checkoutData.next(Object.assign(this._trnService.checkoutData.getValue(), {
			billingFormValid: this.formViewerService.formGroup.valid,
			billingFormData: formData,
		}));
	}
	
	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
