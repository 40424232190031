import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, IDatasetCallDefinition, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import customerForm from './forms/customer.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/entity_customers/detail/:recordId',
	params: {
		with_relations: 'entity,zone,bank,commercialCategory'
	}
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules,twilio'
	}
};

export const EntityCustomerConfig: IDatasetConfig = {
	name: 'entity_customers',
	title: 'Clienti',
	singleTitle: 'Cliente',
	ajaxDatasetCode: 'entity_customers',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		customerForm
	},
	fetchConfig: {
		with_relations: 'entity,zone,bank,commercialCategory'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: customerForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any) {
				params.with_relations = 'entity,zone,bank,commercialCategory';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, datasetACS, datasetNavigatorService): void{
							datasetNavigatorService.showDatasetDetail('entities', null, record.entity_id);
						}
					},
					{
						title: 'Descrizione',
						key: 'description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): string{
							if(record.entity && record.entity.description){
								return "<span class='entity_customer_description' style='max-width: 200px; display: block; white-space: pre-wrap; overflow: hidden; text-overflow: ellipsis;'>" + record.entity.description + "</span>";
							}
							return '';
						}
					},
					{
						title: 'Contatti',
						key: 'primary_email',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): string{
							let html = '';
							if(record){
								let style = 'display: flex; align-items: center; justify-content: flex-end; font-weight: 600;';
								let padding = '';

								if(record.branch){
									if (record.entity && record.entity.phone_number){
										padding = 'padding-bottom: 10px;';
									}
									html += '<div style="'+style+padding+'"><span class="material-icons-outlined" style="font-size: 14px; padding-right: 8px;">apartment</span>: ' + record.branch + '</div>';
								}
								if(record.entity && record.entity.phone_number){
									if (record.entity && record.entity.primary_email){
										padding = 'padding-bottom: 10px;';
									}
									html += '<div style="'+style+padding+'"><span class="material-icons-outlined" style="font-size: 14px; padding-right: 8px;">phone</span>Telefono: ' + record.entity.phone_number + '</div>';
								}
								if(record.entity && record.entity.primary_email){
									html += '<div style="'+style+'"><span class="material-icons-outlined" style="font-size: 14px; padding-right: 8px;">email</span>Email: ' + record.entity.primary_email + '</div>';
								}
							}
							return html;
						}
					}
				],
				customActions: [
					{
						buttonIcon: 'edit',
						buttonColor: 'accent',
						actionCode: 'edit',
						showIf(): boolean{ return true; },
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.showDatasetDetail('entities', null, record.entity_id);
						}
					}
				],
				actions: ['create', 'delete']
			}
		}
	}
};
