<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field style='max-width:90px;'>
		<mat-label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<input *ngIf='inputService.inputConfig.readOnly' matInput readonly />
		<i *ngIf='inputService.control.value && inputService.inputConfig.readOnly' [class]='inputService.control.value'></i>
		<mat-select *ngIf='!inputService.inputConfig.readOnly' [placeholder]="inputService.inputConfig.placeholder | translate" [formControlName]='inputService.formControlKey'>
			<mat-option *ngFor='let selectOption of selectOptions' [value]='selectOption.value'>
				<i [class]='selectOption.value' ></i>
			</mat-option>
			<mat-select-trigger>
				<i [class]='inputService.control.value'></i>
			</mat-select-trigger>
		</mat-select>
		<mat-icon matSuffix style='cursor: pointer;' *ngIf='inputService.control.value && !inputService.inputConfig.readOnly' (click)='inputService.control.setValue(null)'>close</mat-icon>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>