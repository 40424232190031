import { EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { IAppEvent, IMeResponse, ISocketEvent } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import saveAs from 'file-saver';
import * as semver from 'semver';
import { defaultSupportedLanguageCodes, defaultSupportedLanguages } from 'app/helpers/i18n.helper';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.token";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/material/dialog";
//import Bugsnag from '@bugsnag/js';
// Define the default config
var DEFAULT_APP_CONFIG = {
    serverAddress: null,
    serverPort: null,
    apiRoutePrefix: null,
    disableInMemoryAPI: null
};
var AppService = /** @class */ (function () {
    function AppService(config, snackBar, http, matDialog) {
        this.snackBar = snackBar;
        this.http = http;
        this.matDialog = matDialog;
        this.searchBarText = new BehaviorSubject(null);
        this.showAppInterface = new BehaviorSubject(true);
        this.eventEmitter = new EventEmitter();
        this.appEvent = new Subject();
        this.socketEvent = new Subject();
        /**
         * Configured by the page
         */
        this.toolbarPageActions = [];
        this.language = new BehaviorSubject('it');
        // http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
        this.supportedLanguageCodes = defaultSupportedLanguageCodes;
        this.supportedLanguages = defaultSupportedLanguages;
        this.appVersion = null;
        this.hasNewVersion = false;
        if (config) {
            this.baseDefault = Object.assign({}, DEFAULT_APP_CONFIG, config);
        }
        else {
            this.baseDefault = Object.assign({}, DEFAULT_APP_CONFIG);
        }
        this.configObserver = new BehaviorSubject(this.baseDefault);
    }
    AppService.prototype.getBaseServerAddressRoot = function (defaultValue) {
        if (defaultValue === void 0) { defaultValue = ''; }
        var address = this.baseDefault.serverAddress;
        if (this.baseDefault.serverPort && address) {
            address += ':' + this.baseDefault.serverPort;
        }
        address = address || defaultValue;
        return address;
    };
    AppService.prototype.getBaseServerAddress = function (defaultValue) {
        if (defaultValue === void 0) { defaultValue = ''; }
        var address = this.baseDefault.serverAddress;
        if (this.baseDefault.serverPort && address) {
            address += ':' + this.baseDefault.serverPort;
        }
        address = address || defaultValue;
        if (this.baseDefault.apiRoutePrefix) {
            address += this.baseDefault.apiRoutePrefix;
        }
        return address;
    };
    AppService.prototype.setLogo = function (response) {
        if (response && response.logo) {
            this.LogoImg = 'data:image/' + response.type + ';charset=utf-8;base64,' + response.logo;
        }
        else {
            this.LogoImg = null;
        }
    };
    AppService.prototype.showErrorMessage = function (message) {
        var snackBarRef = this.snackBar.open(message, 'DISMISS', {
            duration: 4500,
            panelClass: ['danger-snackbar', 'red-bg']
        });
        snackBarRef.onAction().subscribe(function () {
            snackBarRef.dismiss();
        });
    };
    AppService.prototype.showSuccessMessage = function (message) {
        var snackBarRef = this.snackBar.open(message, 'DISMISS', {
            duration: 3500,
            panelClass: ['danger-snackbar', 'green-bg']
        });
        snackBarRef.onAction().subscribe(function () {
            snackBarRef.dismiss();
        });
    };
    AppService.prototype.emitEvent = function (name) {
        this.appEvent.next({ name: name });
    };
    AppService.prototype.setCookie = function (key, value, ageInSeconds) {
        var newCookie = String(key) + '=' + String(value);
        if (ageInSeconds) {
            var date = new Date();
            date.setTime(date.getTime() + (ageInSeconds * 1000));
            newCookie += '; max-age=' + ageInSeconds;
            newCookie += '; expires=' + date.toUTCString();
        }
        document.cookie = newCookie;
    };
    AppService.prototype.getCookie = function (key) {
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var pair = ca[i].trim().split('=');
            if (pair[0].trim() === key)
                return pair[1];
        }
        return null;
    };
    AppService.prototype.removeCookie = function (key) {
        document.cookie = key + '=; max-age=-99999999; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
    AppService.prototype.getAbsolutePath = function (path) {
        if (!path)
            return path;
        if (!path.startsWith('/'))
            return path;
        return this.getBaseServerAddressRoot() + path;
    };
    AppService.prototype.downloadFile = function (fileId) {
        var _this = this;
        this.http.get(this.getBaseServerAddressRoot() + '/document/download/' + fileId, {
            responseType: 'blob',
            observe: 'response'
        })
            .subscribe({
            next: function (response) {
                var filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
                // const blob = new Blob([response.body], { type: response.body.type });
                saveAs(response.body, filename.replace(/\"/g, ''));
            },
            error: function (error) {
                _this.showErrorMessage(error.message);
            }
        });
    };
    AppService.prototype.downloadUrl = function (url, params, defaultFileName) {
        var _this = this;
        var result = new Subject();
        this.http.get(url, {
            responseType: 'blob',
            observe: 'response',
            params: params
        })
            .subscribe({
            next: function (response) {
                var filename = (response.headers.get('Content-Disposition') || 'filename=' + defaultFileName).split('filename=')[1];
                saveAs(response.body, filename.replace(/\"/g, ''));
                result.next(response);
                result.complete();
            },
            error: function (error) {
                _this.showErrorMessage(error.message);
                result.error(error);
                result.complete();
            }
        });
        return result;
    };
    AppService.prototype.openFile = function (fileId) {
        var _this = this;
        this.http.get(this.getBaseServerAddressRoot() + '/document/download/' + fileId, {
            responseType: 'blob',
            observe: 'response'
        })
            .subscribe({
            next: function (response) {
                var filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
                var blob = new Blob([response.body], { type: response.body.type });
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            },
            error: function (error) {
                _this.showErrorMessage(error.message);
            }
        });
    };
    AppService.prototype.checkAppVersions = function () {
        var _this = this;
        var loaded = new BehaviorSubject(false);
        this.http.get(this.getBaseServerAddressRoot() + '/versions')
            .subscribe({
            next: function (response) {
                if (response && response.web_app && response.web_app.version) {
                    if (!_this.appVersion) {
                        _this.appVersion = response.web_app.version;
                        _this.hasNewVersion = false;
                    }
                    else if (semver.gt(response.web_app.version, _this.appVersion)) {
                        _this.hasNewVersion = true;
                    }
                    else {
                        _this.hasNewVersion = false;
                    }
                }
                //if (response.version) Bugsnag.setContext('App version ' + response.version);
                loaded.next(true);
                loaded.complete();
            },
            error: function (response) {
                loaded.next(false);
                loaded.complete();
            }
        });
        return loaded;
    };
    AppService.prototype.init = function () {
        var _this = this;
        this.checkAppVersions()
            .subscribe(function (isLoaded) {
            if (isLoaded)
                return;
            //this.startBugsnag();
        });
        // setInterval??
        setTimeout(function () {
            _this.checkAppVersions();
        }, 10 * 60 * 1000); // every 10 minutes
    };
    /**
     * Initialize bugsnug
     * @param config
     */
    AppService.prototype.startBugsnag = function () {
        /*Bugsnag.start({
            apiKey: this.baseDefault.BUGSNAG_API_KEY,
            appVersion: this.appVersion || '0.0.1'
        });*/
    };
    AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.APP_CONFIG, 8), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.MatDialog)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
