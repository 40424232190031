import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { BookingService } from '../booking.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { TripBookingPackagesStepService } from './trip-booking-packages-step.service';
import { RELOAD_BOOKING_PACKAGES } from '../edit-booking.component';
import { BaseStepChangerComponent } from '../base-step-changer.component';
import * as _ from 'lodash';
var TripBookingPackagesStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingPackagesStepComponent, _super);
    function TripBookingPackagesStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, tripBookingPackagesStepService, bookingService, datasetNavigator) {
        var _this = _super.call(this, bookingService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.bookingService = bookingService;
        _this.datasetNavigator = datasetNavigator;
        _this.ticketingEnabled = false;
        _this.canAddTicket = false;
        _this.canAddProduct = false;
        _this.itemsCollapsed = false;
        _this.rootTripBookingPackagesCount = 0;
        return _this;
    }
    Object.defineProperty(TripBookingPackagesStepComponent.prototype, "tripBookingPackages", {
        get: function () { return this.tripBookingPackagesStepService.tripBookingPackagesPage && this.tripBookingPackagesStepService.tripBookingPackagesPage.items || []; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripBookingPackagesStepComponent.prototype, "loading", {
        get: function () { return this.tripBookingPackagesStepService.loading; },
        enumerable: true,
        configurable: true
    });
    TripBookingPackagesStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.tripBookingPackagesStepService.reloadTripBookingPackages();
            _this.tripBookingPackagesStepService.updateSettings();
        });
        this.datasetACS.datasetDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.tripBookingPackagesStepService.updateSettings();
        });
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.tripBookingPackagesStepService.updateSettings();
        });
        this.tripBookingPackagesStepService.pageItemsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.updateCount();
        });
        this.appService.appEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.name === RELOAD_BOOKING_PACKAGES) {
                _this.tripBookingPackagesStepService.reloadTripBookingPackages();
            }
        });
        this.updateCount();
        var pageLayout = document.querySelector('.booking-page-content');
        if (window.innerWidth < 959)
            pageLayout.classList.remove('p-16');
    };
    TripBookingPackagesStepComponent.prototype.updateCount = function () {
        var items = _.get(this.tripBookingPackagesStepService, 'tripBookingPackagesPage.items');
        if (!items || !Array.isArray(items))
            return 0;
        this.rootTripBookingPackagesCount = items.reduce(function (carry, item) {
            if (item.parent_trip_booking_package_relation_type)
                return carry;
            return carry + 1;
        }, 0);
    };
    TripBookingPackagesStepComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        var pageLayout = document.querySelector('.booking-page-content');
        if (pageLayout)
            pageLayout.classList.add('p-16');
    };
    TripBookingPackagesStepComponent.prototype.trackTripBookingPackagesFun = function (item) {
        return item.id;
    };
    return TripBookingPackagesStepComponent;
}(BaseStepChangerComponent));
export { TripBookingPackagesStepComponent };
