import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('entity_supplier_id'),
	// fieldsMap.get('trip_structure_id')
];

const config: IFormConfig = { 
	title: 'Crea Servizio',
	editTitle: 'Modifica Servizio',
	fields: formFields 
};

export default config;
