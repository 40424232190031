import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import ListForIntegrationsViewConfig from 'app/configs/datasets/domain_packages/list_for_integrations.view_config';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import widgetFormField from "./widgetFormField";
import { FormViewerComponent } from "../../../../form-viewer/form-viewer.component";
var IntegrationsStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IntegrationsStepComponent, _super);
    function IntegrationsStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, stepService) {
        var _this = 
        // parent constructor
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.stepService = stepService;
        _this.hasStepState = false;
        _this.widgetForm = widgetFormField;
        _this.loading = false;
        _this.domainPackageViewConfig = ListForIntegrationsViewConfig;
        _this.widget_entity_customer_id = '';
        return _this;
    }
    IntegrationsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.loadTourOperators();
        // init datasetACS
        this.datasetACS.init({
            actionCode: 'list',
            datasetCode: this.integrationsDatasetCode,
            parentDatasetRS: this.datasetRS,
            parentDatasetACS: this.parentDatasetACS,
            fetchData: false,
            useBeforeCalls: false,
        }).subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetACS.putContextData('contextual_domain_id', _this.datasetACS.domainFilterService.filterDomainId.value);
        });
        // setta step come completato
        if (this.hasStepState) {
            this.stepService.setStepState({
                dataset_code: this.datasetCode,
                form_code: 'integrations',
                record_id: this.datasetRS.recordId,
                status: 1
            });
        }
    };
    IntegrationsStepComponent.prototype.loadTourOperators = function () {
        var _this = this;
        console.log("entra");
        if (this.fetchTourOperatorSubcription) {
            this.fetchTourOperatorSubcription.unsubscribe();
        }
        var params = {};
        if (this.datasetCode == 'trip_packages') {
            params['trip_package_id'] = this.datasetRS.recordId;
        }
        else {
            params['trip_category_id'] = this.datasetRS.recordId;
        }
        if (this.widget_entity_customer_id) {
            params['widget_entity_customer_id'] = this.widget_entity_customer_id;
        }
        if (this.selectedWidgetColor) {
            params['widget_color'] = this.selectedWidgetColor;
        }
        this.loading = true;
        this.fetchTourOperatorSubcription = this.datasetService.post('/dataset/' + this.integrationsDatasetCode + '/command/get_for_tour_operators', params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.tourOperators = response;
                _this.loading = false;
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    IntegrationsStepComponent.prototype.createTripPackageIntegration = function (tourOperator) {
        var _this = this;
        tourOperator.activating = true;
        // set request body
        var data = {
            tour_operator_id: tourOperator.id,
            tour_operator_code: tourOperator.code,
            enabled: 1
        };
        if (this.datasetCode == 'trip_packages') {
            data['trip_package_id'] = this.datasetRS.recordId;
        }
        else {
            data['trip_category_id'] = this.datasetRS.recordId;
        }
        this.datasetService.post('/dataset/' + this.integrationsDatasetCode + '/create', data)
            .subscribe({
            next: function (response) {
                _this.loadTourOperators();
            },
            error: function (response) {
                tourOperator.activating = false;
            }
        });
    };
    IntegrationsStepComponent.prototype.trackByIndex = function (index, item) {
        return indexedDB;
    };
    IntegrationsStepComponent.prototype.integrationUpdated = function (integration, tourOperator) {
        var e_1, _a;
        var newTOs = [];
        try {
            for (var _b = tslib_1.__values(this.tourOperators), _c = _b.next(); !_c.done; _c = _b.next()) {
                var t = _c.value;
                if (t.id === tourOperator.id) {
                    t.integration = integration;
                }
                newTOs.push(t);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.tourOperators = newTOs;
    };
    IntegrationsStepComponent.prototype.widgetOptions = function (formViewerService) {
        var formValues = formViewerService.getFormData();
        this.widget_entity_customer_id = formValues['entity_customer.entity_customer_id'];
        this.selectedWidgetColor = formValues['widget_color'];
        this.loadTourOperators();
    };
    return IntegrationsStepComponent;
}(BaseStepViewComponent));
export { IntegrationsStepComponent };
