<ng-container [ngSwitch]='filterStyle'>
	<ng-container *ngSwitchCase='"style-1"'>
		<style-1-filter-component
			[filtersData]='filtersData'
			[dataContainerService]='dataContainerService'
			[formConfig]='formConfig'
			[showSearchText]='showSearchText'
			[actionButtons]='actionButtons'
			[loading]='loading'
			[filterStyle]='filterStyle'
			(filterChange)='filterChange.emit($event)'
		></style-1-filter-component>
	</ng-container>
	<ng-container *ngSwitchCase="'style-01'">
		<style-01-filter-component
			[filtersData]='filtersData'
			[dataContainerService]='dataContainerService'
			[formConfig]='formConfig'
			[showSearchText]='showSearchText'
			[actionButtons]='actionButtons'
			[loading]='loading'
			[filterStyle]='filterStyle'
			(filterChange)='filterChange.emit($event)'
		></style-01-filter-component>
	</ng-container>
	<ng-container *ngSwitchDefault>
		<style-default-filter-component
			[filtersData]='filtersData'
			[dataContainerService]='dataContainerService'
			[formConfig]='formConfig'
			[showSearchText]='showSearchText'
			[actionButtons]='actionButtons'
			[loading]='loading'
			(filterChange)='filterChange.emit($event)'
		></style-default-filter-component>
	</ng-container>
</ng-container>

<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>