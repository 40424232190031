import {isPlatformBrowser, LocationStrategy} from '@angular/common';
import {APP_INITIALIZER, ApplicationRef, InjectionToken, Injector, ModuleWithProviders, NgModule, PLATFORM_ID} from '@angular/core';
import { of } from 'rxjs';
import {filter, take, delay} from 'rxjs/operators';
import { NgswCommChannel } from './low_level';
import { SwPush } from './push';
import { SwUpdate } from './update';

export abstract class RegistrationOptions {
  scope?: string;
  enabled?: boolean;
}

export const SCRIPT = new InjectionToken<string>('NGSW_REGISTER_SCRIPT');
export const RELATIVE_SCRIPT = new InjectionToken<string>('NGSW_REGISTER_RELATIVE_SCRIPT');

export function ngswAppInitializer(
	injector: Injector, script: string, options: RegistrationOptions,
	platformId: string): Function {
	const initializer = () => {
		const app = injector.get<ApplicationRef>(ApplicationRef);
		if (!(isPlatformBrowser(platformId) && ('serviceWorker' in navigator) &&
			options.enabled !== false)) {
		return;
		}
		// bug isStable always false
		// const whenStable = app.isStable.pipe(filter(stable => stable));
		const whenStable = of(null).pipe(delay(500));

		// Wait for service worker controller changes, and fire an INITIALIZE action when a new SW
		// becomes active. This allows the SW to initialize itself even if there is no application
		// traffic.
		navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (navigator.serviceWorker.controller !== null) {
			navigator.serviceWorker.controller.postMessage({action: 'INITIALIZE'});
		}
		});

		// Don't return the Promise, as that will block the application until the SW is registered, and
		// cause a crash if the SW registration fails.
		whenStable.pipe(take(1)).subscribe(() => navigator.serviceWorker.register(script, {scope: options.scope})
		.catch(err => console.error('Service worker registration failed with:', err)));
	};
	return initializer;
}

export function ngswCommChannelFactory(
	opts: RegistrationOptions, platformId: string): NgswCommChannel {
  return new NgswCommChannel(
	  isPlatformBrowser(platformId) && opts.enabled !== false ? navigator.serviceWorker :
																undefined);
}

export function ngswScriptFactory(locationStrategy: LocationStrategy, script: string){
	return locationStrategy.prepareExternalUrl(script);
}

@NgModule({
  providers: [SwPush, SwUpdate],
})
export class AppServiceWorkerModule {
  /**
   * Register the given Angular Service Worker script.
   *
   * If `enabled` is set to `false` in the given options, the module will behave as if service
   * workers are not supported by the browser, and the service worker will not be registered.
   */
  static register(script: string, opts: {scope?: string; enabled?: boolean;} = {}):
	  ModuleWithProviders<AppServiceWorkerModule> {
	return {
	  ngModule: AppServiceWorkerModule,
	  providers: [
		{
			provide: RELATIVE_SCRIPT,
			useValue: script
		},
		{
			provide: SCRIPT,
			useFactory: ngswScriptFactory,
			deps: [LocationStrategy, RELATIVE_SCRIPT]
		},
		{provide: RegistrationOptions, useValue: opts},
		{
		  provide: NgswCommChannel,
		  useFactory: ngswCommChannelFactory,
		  deps: [RegistrationOptions, PLATFORM_ID]
		},
		{
		  provide: APP_INITIALIZER,
		  useFactory: ngswAppInitializer,
		  deps: [Injector, SCRIPT, RegistrationOptions, PLATFORM_ID],
		  multi: true,
		},
	  ],
	};
  }
}