import { Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Observable, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { DatasetRecordService } from '../services/dataset-record.service';

export interface IDialogData {
	title?: string;
	datasetCode: string;
	datasetData?: any; // copy dataset data to dialog DatasetActionContainerService
	parentDatasetACS?: DatasetActionContainerService;
	parentDatasetRS?: DatasetRecordService;
}

export abstract class BaseDatasetActionDialog implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;
	lastLoadError: any;

	abstract actionCode: string;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		public dialogRef: MatDialogRef<BaseDatasetActionDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.putAllDatasetData(this.data.datasetData);
		this.datasetACS.fetchParamMap = this.getParamMap();
		this.datasetACS.init({
			datasetCode: this.data.datasetCode,
			actionCode: this.actionCode,
			parentDatasetACS: this.data.parentDatasetACS,
			parentDatasetRS: this.data.parentDatasetRS,
			fetchData: this.actionCode !== 'delete'
		}).subscribe( ready => {
			if(!ready) return;
			if(!this.datasetACS.sourceDataLoaded){
				this.datasetACS.loadSourceData();
			}
		});
		
		this.datasetACS.loading
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(loading => {
			this.loading = loading;
		});
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	protected onLoaded(data: any): Observable<any>{return of(true); }

	protected getParamMap(): Map<string, any>{
		return new Map();
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
