import {Component, Inject, ViewChild, OnInit, ViewContainerRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogState } from '@angular/material/dialog';
import {Subscription, of} from 'rxjs';
import {exhaustMap, finalize, take, takeUntil} from 'rxjs/operators';

import { FormViewerComponent} from 'app/main/components/form-viewer/form-viewer.component';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import {BaseDatasetActionDialog, IDialogData} from 'app/main/components/dataset/dialogs/base-action-dialog.component';
import { IFormConfig, IDatasetActionConfig, IFormViewConfig } from 'app/interfaces';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { InvalidAllotmentsDialogComponent } from '../../invalid-allotments-dialog/dialog.component';
import { DatasetRecordService } from '../../../services/dataset-record.service';

export interface IEditDialogData extends IDialogData{
	record: any;
	recordId?: any;
	customActionCode?: any;
	customRoute?: any;
	method?: any;
	formConfig?: IFormConfig;
}

@Component({
	selector: 'dataset-edit-dialog',
	templateUrl: 'dataset-edit-dialog.component.html',
	styleUrls: ['dataset-edit-dialog.component.scss'],
	providers: [DatasetActionContainerService, PageClosureContainerService, DatasetRecordService]
})
export class DatasetEditDialogComponent extends BaseDatasetActionDialog implements OnInit{

	saveSubscription: Subscription;
	actionCode = 'edit';
	formConfig: IFormConfig;
	dialogTitle: string;
	formErrors: any;

	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		public dialogRef: MatDialogRef<DatasetEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IEditDialogData,
		protected matDialog: MatDialog,
		public datasetRS: DatasetRecordService,
		public viewContainerRef: ViewContainerRef
	) {
		super(datasetACS, appService, datasetService, dialogRef, data);
	}

	ngOnInit(): void{
		super.ngOnInit();

		this.datasetACS.datasetRS = this.datasetRS;
		this.datasetACS.ready
		.subscribe(() => {
			this.datasetRS.record.next(this.data.record);
			this.datasetRS.init();
		});

		if(this.data.formConfig){
			this.formConfig = this.data.formConfig;
		}else{
			const actionConfig: IDatasetActionConfig<IFormViewConfig> = this.datasetACS.actionConfig;
			if(actionConfig) this.formConfig = actionConfig.viewConfig.formConfig;
		}
		if(!this.formConfig){
			console.warn('no formConfig defined');
		}
		this.buildTitle();
	}

	buildTitle(): void{
		if(this.formConfig && this.formConfig.editTitle){
			this.dialogTitle = this.formConfig.editTitle;
		}else{
			this.dialogTitle = 'Modifica ' + this.datasetACS.datasetConfig.singleTitle;
		}
	}

	getActionRoute(): string {
		if(this.data.customRoute) return this.data.customRoute;
		if(this.data.method === 'create') {
			return this.datasetACS.getCreateRoute(this.data.customActionCode);
		}
		return this.datasetACS.getUpdateRoute(this.data.record, this.data.customActionCode, this.data.recordId);
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		this.loading = true;
		if(typeof(this.datasetACS.actionConfig.getCallParams) === 'function'){
			formData = this.datasetACS.actionConfig.getCallParams(this.datasetACS, this.data.record, formData);
		}
		of(formData)
		.pipe(
			exhaustMap(formData => {
				if(this.datasetACS.actionConfig && typeof(this.datasetACS.actionConfig.beforeSave) === 'function'){
					return this.datasetACS.actionConfig.beforeSave(this.datasetACS, this.datasetRS.recordId, formData, this.formViewer.record, this.viewContainerRef);
				}
				return of(formData);
			}),
			take(1),
			exhaustMap(data => {
				return this.datasetService.post<any>(this.getActionRoute(), data);
			}),
			takeUntil(this._unsubscribeAll)
		)
		.subscribe({
			next: response => {
				this.loading = false;
				this.appService.showSuccessMessage('Modificato con successo');
				this.dialogRef.close(response);
			},
			error: response => {
				this.loading = false;

				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}

				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			},
			complete: () => {
				this.loading = false;
				if(this.dialogRef.getState() == MatDialogState.OPEN){
					this.dialogRef.close(false);
				}
			}
		});

	}
}
