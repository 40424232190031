import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { FormInputViewerModule } from 'app/main/components/form-input-viewer/form-input-viewer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		ToolbarComponent
	],
	imports     : [
		RouterModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		MatDialogModule,
		FormInputViewerModule,
		FuseSharedModule,
		FuseSearchBarModule,
		FuseShortcutsModule,
		DatasetModule,
		TranslateModule.forChild(),
	],
	exports     : [
		ToolbarComponent
	]
})
export class ToolbarModule{}
