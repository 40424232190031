import { HttpClient } from '@angular/common/http';
import { OnInit, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var ViewDayCalendarComponent = /** @class */ (function () {
    function ViewDayCalendarComponent(appService, datasetNavigatorService, calendarService, http) {
        this.appService = appService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.calendarService = calendarService;
        this.http = http;
        this.isLoadingParticipants = {};
        this.hourSegments = [];
        this.unsubscriber = new Subject();
    }
    ViewDayCalendarComponent.prototype.ngOnInit = function () {
        /**
         * daily calendar hours segments
         */
        for (var i = 0; i < 24; i++) {
            for (var j = 1; j <= 2; j++) {
                this.hourSegments.push(i + ':' + (j === 1 ? '00' : (60 / j)));
            }
        }
    };
    ViewDayCalendarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        /**
         * if already loaded
         */
        this.loadParticipants();
        /**
         * only on loaded events
         */
        this.calendarService.loadingEvents.subscribe(function (isLoading) {
            if (isLoading)
                return;
            _this.loadParticipants();
        });
        /**
         * on dateViewChange
         */
        this.calendarService._viewDateChanged.subscribe(function (date) {
            _this.loadParticipants(date);
        });
    };
    ViewDayCalendarComponent.prototype.loadParticipants = function (date) {
        var _this = this;
        if (date) {
            this.viewDate = date;
        }
        var dateIdx = moment(this.viewDate).format('DDMMYY');
        if (!this.groupedEventsByHour[dateIdx])
            return;
        this.isLoadingParticipants = {};
        var _loop_1 = function (hourSegment) {
            var _loop_2 = function (eventIdx) {
                var event_1 = this_1.groupedEventsByHour[dateIdx][hourSegment][eventIdx];
                var actualTripId = event_1.meta.actual_trip.id;
                this_1.isLoadingParticipants[actualTripId] = true;
                var route = this_1.appService.getBaseServerAddress() + '/calendar/event/actual_trip/' + actualTripId + '/participants';
                this_1.http.get(route)
                    .pipe(takeUntil(this_1.unsubscriber))
                    .subscribe({
                    next: function (response) {
                        event_1.meta.participants.adt = response.adt;
                        event_1.meta.participants.chd = response.chd;
                        event_1.meta.participants.inf = response.inf;
                        event_1.meta.participants.snr = response.snr;
                        _this.groupedEventsByHour[dateIdx][hourSegment][eventIdx] = event_1;
                        _this.isLoadingParticipants[actualTripId] = false;
                    },
                    error: function (err) {
                        _this.isLoadingParticipants[actualTripId] = false;
                        console.error(err);
                    }
                });
            };
            for (var eventIdx in this_1.groupedEventsByHour[dateIdx][hourSegment]) {
                _loop_2(eventIdx);
            }
        };
        var this_1 = this;
        /**
         * chiamata http per recuperare i partecipanti
         */
        for (var hourSegment in this.groupedEventsByHour[dateIdx]) {
            _loop_1(hourSegment);
        }
    };
    ViewDayCalendarComponent.prototype.ngOnDestroy = function () {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    };
    return ViewDayCalendarComponent;
}());
export { ViewDayCalendarComponent };
