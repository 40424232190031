import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { BookingService } from "app/main/pages/edit-booking/booking.service";
var TripBookingPackageHeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingPackageHeaderComponent, _super);
    function TripBookingPackageHeaderComponent(datasetRS, datasetACS, bookingService) {
        var _this = _super.call(this) || this;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.bookingService = bookingService;
        _this.canDelete = false;
        _this.canCancel = false;
        _this.showInfo = false;
        _this.showEdit = false;
        _this.showConfirm = false;
        _this.hidePrice = false;
        _this.infoClick = new EventEmitter();
        _this.deleteClick = new EventEmitter();
        _this.editClick = new EventEmitter();
        _this.confirmClick = new EventEmitter();
        return _this;
    }
    Object.defineProperty(TripBookingPackageHeaderComponent.prototype, "tripBookingPackage", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripBookingPackageHeaderComponent.prototype, "tripBookingStatus", {
        get: function () {
            return this.datasetACS.getValueFromKeyPath('trip_bookings.record.status');
        },
        enumerable: true,
        configurable: true
    });
    return TripBookingPackageHeaderComponent;
}(DestroyableComponent));
export { TripBookingPackageHeaderComponent };
