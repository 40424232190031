import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { paypalFieldsMap } from '../../../fields/payment_methods/paypal/paypal.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	paypalFieldsMap.get('enabled'),
	paypalFieldsMap.get('client_id'),
	paypalFieldsMap.get('client_secret'),
	paypalFieldsMap.get('scenario'),
];

const paypalForm: IFormConfig = { 
	editTitle: 'Modifica Chiavi Paypal',
	fields: formFields
};

export default paypalForm;
