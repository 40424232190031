import {Component, Inject, ViewChild, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import { FormViewerComponent} from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import {BaseDatasetActionDialog, IDialogData} from 'app/main/components/dataset/dialogs/base-action-dialog.component';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { IFormConfig, IDatasetActionConfig, IFormViewConfig } from 'app/interfaces';
import { HttpErrorResponse } from '@angular/common/http';
import { ITextDialogData, TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { InvalidAllotmentsDialogComponent } from '../../invalid-allotments-dialog/dialog.component';
import { HttpHeaders } from '@angular/common/http';

export interface ICreateDialogData extends IDialogData{
	formConfig?: IFormConfig;
	customActionCode?: any;
	customTitleDialog?: string;
	initialValues?: any;
}

@Component({
	selector: 'dataset-create-dialog',
	templateUrl: 'dataset-create-dialog.component.html',
	styleUrls: ['dataset-create-dialog.component.scss'],
	providers: [DatasetActionContainerService, PageClosureContainerService]
})
export class DatasetCreateDialogComponent extends BaseDatasetActionDialog implements OnInit{

	saveSubscription: Subscription;
	actionCode = 'create';
	formConfig: IFormConfig;
	formErrors: any;
	dialogTitle: string;

	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		public dialogRef: MatDialogRef<DatasetCreateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ICreateDialogData,
		protected matDialog: MatDialog
	) {
		super(datasetACS, appService, datasetService, dialogRef, data);
	}

	ngOnInit(): void{
		super.ngOnInit();
		
		if(this.data.formConfig){
			this.formConfig = this.data.formConfig;
		}else{
			const actionConfig: IDatasetActionConfig<IFormViewConfig> = this.datasetACS.actionConfig;
			this.formConfig = actionConfig.viewConfig.formConfig;
		}

		this.buildTitle();
	}

	buildTitle(): void{
		if (this.data.customTitleDialog){
			this.dialogTitle = this.data.customTitleDialog;
		}else{
			if(this.formConfig && this.formConfig.title){
				this.dialogTitle = this.formConfig.title;
			}else{
				this.dialogTitle = 'Nuovo ' + this.datasetACS.datasetConfig.singleTitle;
			}
		}
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		this.loading = true;
		if(typeof( this.datasetACS.actionConfig.getCallParams) === 'function'){
			formData = this.datasetACS.actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		const headers: HttpHeaders = this.datasetACS.getContextualDomainHeaders();

		this.datasetService.post(this.datasetACS.getCreateRoute(this.data.customActionCode), formData, {
			headers: headers
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.loading = false;
				this.appService.showSuccessMessage('Creato con successo');
				this.dialogRef.close(response);
			},
			error: (response: HttpErrorResponse) => {
				this.loading = false;

				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}

				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			}
		});

	}

}
