import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('name'),
];

const config: IFormConfig = { 
	title: 'Modifica Filiale',
	editTitle: 'Modifica Filiale',
	fields: formFields 
};

export default config;
