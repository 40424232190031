import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('trip_facility_id', {
	key: 'trip_facility_id',
	name: 'Servizio',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_facilities',
	// inputHint: 'Scrivi per cercare una destinazione',
	remoteOptions: {
		clearInputOnCreation: true,
		supportCreate: true,
		createHint: 'Scrivi per cercare un servizio',
		clearOnSelection: true,
		limit: 100,
		icon: 'search',
		getLabel(option): any{
			if(!option) return '';
			return option.title;
		},
		getValue(option): any{
			if(!option) return;
			return option;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.title;
		}
	}
});

export default fieldsMap;
