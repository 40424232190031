import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/resources/dataset/trip_packages/:parentRecordId/detail/:recordId',
};

export const ActualTripResourcesConfig: IDatasetConfig = {
	name: 'actual_trip_resources',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'actual_trip_resources',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'resource_code'
					},
					{
						title: 'Descrizione',
						key: 'resource_description'
					},
					{
						title: 'Tipo Allotment',
						key: 'resource_allotment_type'
					},
					{
						title: 'Opzionale',
						key: 'pivot_optional_resource'
					},
					{
						title: 'Prenotazioni/Disponibilità',
						key: 'availability'
					}
				],
				actions: [],
				customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'pageview',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							if(!record || !record.id) return;
							datasetNavigatorService.showDatasetDetail('trip_resources', null, record.id);
						},
						showIf(): boolean{ return true; }
					}
				]
			}
		}
	}
};
