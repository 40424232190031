<div class='full-form'>
	<!--<h3 *ngIf='datasetRS.record.value && !datasetACS.loading.value' class="addNew">
		Aggiungi servizio o crea nuovo
		<span class="material-icons" matTooltip="Crea servizio" (click)='createNewService()'>add</span>
	</h3>
	<br/>-->
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'
	></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>Questa traduzione non è ancora presente!</p>

			<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
				<button mat-flat-button (click)='goPrevStep()'>Indietro</button>
			</div>
		</ng-container>

		<mat-expansion-panel *ngIf='originalDatasetRS.record.value && originalDatasetRS.record.value["lang"] === appService.language.value'>
			<mat-expansion-panel-header>
				<mat-panel-title>
					Dati originali
				</mat-panel-title>
				<mat-panel-description>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<dataset-list
				[enableEdit]='false'
				datasetCode='pivot_trip_packages_trip_facilities'
				[parentDatasetRS]='originalDatasetRS'
				[parentDatasetACS]='originalDatasetACS'
				[showSearchBar]='false'
				[elevate]='false'
				[showTitle]='false'
			></dataset-list>
		</mat-expansion-panel>

		<ng-container *ngIf='reselledDatasetRS.record.value && reselledDatasetRS.record.value["lang"] === appService.language.value'>
			<custom-mat-table
				[relation]='"facilities"'
				[displayedColumns]='displayedColumns'
				[filterFormGroup]='filterFormGroup'
				[field]='field'
				[datasetACS]='reselledDatasetACS'
				[datasetRS]='reselledDatasetRS'
				[dynamCols]='dynamCols'
				[stepName]='"trip_package_facilities"'
				[datasetCode]='"trip_packages"'
				[datasetConfig]='datasetConfig'
				[formConfigMap]='formConfig'
				(nextStep)='goNextStep()'
				[initialValueKey]='"title"'
			></custom-mat-table>
		</ng-container>
	</div>
</div>