import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TrenitaliaService } from "../../trenitalia.service";
var TrainSelectionSeatsComponent = /** @class */ (function () {
    function TrainSelectionSeatsComponent(trnService) {
        this.trnService = trnService;
        this.wagon = new EventEmitter();
    }
    TrainSelectionSeatsComponent.prototype.ngOnInit = function () {
        if (!Array.isArray(this.trainSeat.transportUnits)) {
            this.trainSeat.transportUnits = [this.trainSeat.transportUnits];
        }
        if (!Array.isArray(this.travellers)) {
            this.travellers = [this.travellers];
        }
        this.maxSel = this.travellers.length;
        // @ts-ignore
        window.maxsel = this.maxSel;
        // @ts-ignore
        window.normalNavigation = true;
        // @ts-ignore
        window.showMessage = function () {
            return;
        };
    };
    TrainSelectionSeatsComponent.prototype.ngAfterViewInit = function () {
        this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    TrainSelectionSeatsComponent.prototype.markSeleableSeats = function (wagonId, attempts) {
        var e_1, _a;
        var _this = this;
        if (attempts === void 0) { attempts = 0; }
        var wagon = this.trainSeat.transportUnits.find(function (wagon) { return wagon.unitIndex == wagonId; });
        var seats = _.get(wagon, 'seatsAvailability.seats');
        if (!seats || !Array.isArray(seats))
            return;
        if (attempts == 20)
            return; // iframe not loaded
        var wagonIframe = window.document.getElementById("wagon-" + wagonId);
        if (!wagonIframe) {
            return setTimeout(function () {
                _this.markSeleableSeats(wagonId, attempts + 1);
            }, 300);
        }
        if (!wagonIframe.contentWindow.document.querySelector('svg')) {
            return setTimeout(function () {
                _this.markSeleableSeats(wagonId, attempts + 1);
            }, 300);
        }
        try {
            for (var seats_1 = tslib_1.__values(seats), seats_1_1 = seats_1.next(); !seats_1_1.done; seats_1_1 = seats_1.next()) {
                var seat = seats_1_1.value;
                if (!seat.available)
                    continue;
                var seatElement = wagonIframe.contentWindow.document.getElementById('seat_' + seat.number);
                var seatSelElement = wagonIframe.contentWindow.document.getElementById('seat_' + seat.number + '_sel');
                if (seatElement && seatElement.onclick == undefined) {
                    console.warn('seat available but no onclick', seat, wagonId);
                    continue;
                }
                if (seatElement && !seatSelElement)
                    seatElement.classList.add('seleable');
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (seats_1_1 && !seats_1_1.done && (_a = seats_1.return)) _a.call(seats_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /*markSelectable(wagonId,trainSeat) {
        setTimeout(()=>{
            // @ts-ignore
            let wagonIframe = document.getElementById('wagon-' + wagonId);
            // @ts-ignore
            if(!wagonIframe) return this.markSelectable(wagonId);
            // @ts-ignore
            wagonIframe = wagonIframe.contentWindow.document.querySelector('body');
            for (let i in trainSeat.transportUnits) {
                if (Object.prototype.hasOwnProperty.call(trainSeat.transportUnits, i)) {
                    let wagon = trainSeat.transportUnits[i];
                    if (wagon.unitIndex == wagonId) {
                        let seats = wagon.seatsAvailability.seats;
                        for (let ii in seats) {
                            if (Object.prototype.hasOwnProperty.call(seats, ii)) {
                                if (seats[ii]['available'] === true) {
                                    let seat = wagonIframe.querySelector(
                                        '#seat_' + seats[ii].number
                                    );
                                    seat.classList.add('seleable');
                                }
                            }
                        }
                        break;
                    }
                }
            }
        }, 1500)
    }*/
    TrainSelectionSeatsComponent.prototype.selWagon = function (wId) {
        this.wagon.emit(wId);
    };
    return TrainSelectionSeatsComponent;
}());
export { TrainSelectionSeatsComponent };
