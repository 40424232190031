import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import add_coupon_code from './actions/add_coupon_code';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/tri_booking_discount/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	if(!params.trip_booking_id) params.trip_booking_id = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
	
	return params;
}

export const TripBookingDiscountConfig: IDatasetConfig = {
	name: 'trip_booking_discounts',
	title: 'Sconti',
	singleTitle: 'Sconto',
	ajaxDatasetCode: 'trip_booking_discounts',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CUSTOM,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				customActions: [
					{
						buttonColor: 'accent',
						showIf: () => true,
						buttonText: 'Aggiungi codice',
						isGlobal: true,
						actionCode: 'create',
						onClick: function(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							add_coupon_code(dataContainerService, datasetNavigatorService)
							.catch(e => {
								// do something with error?
							});
						}
					}
				],
				actions: ['create', 'delete']
			}
		}
	}
};
