import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import filterForm from './forms/filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';


export const TicketlandiaTourConfig: IDatasetConfig = {
	name: 'ticketlandia_tours',
	title: 'Tours',
	singleTitle: 'Tour',
	ajaxDatasetCode: 'ticketlandia_tours',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				columns: [
					{
						title: 'Museo',
						key: 'museum_name'
					},
					{
						title: 'Tour',
						key: 'tour_name'
					},
					{
						title: 'A partire da',
						key: 'from_price'
					},
					{
						title: 'Data fine',
						key: 'date_end'
					},
				],
				actions: [],
				customActions: [
					{
						buttonColor: 'default',
						showIf(record): boolean{ return record && record.allowed_actions && !record.allowed_actions.includes('resell') && !record.allowed_actions.includes('no_permission'); },
						actionCode: null,
						buttonText: 'Già in rivendita',
					},
					{
						buttonColor: 'warn',
						showIf(record): boolean{ return record && record.allowed_actions && record.allowed_actions.includes('no_permission'); },
						actionCode: null,
						buttonText: 'Nessun permesso',
					},
					{
						buttonColor: 'accent',
						showIf(): boolean{ return true; },
						actionCode: 'resell',
						buttonText: 'Rivendi',
						onClick(event, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							console.log('record', record);
							datasetNavigatorService.dialog.open(ConfirmDialogComponent, {
								minWidth: 550,
								data: {
									title: 'Conferma',
									contentMessage: 'Vuoi rivendere questo Tour ?'
								}
							}).afterClosed()
							.subscribe(confirmed => {
								if(!confirmed) return;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									minWidth: 550,
									data: {
										title: 'Creazione Pacchetto in corso'
									}
								});
								
								datasetNavigatorService.datasetService.post<any>('/dataset/ticketlandia_tours/command/create_trip_package', {
									channel_id: record.channel_id,
									tour_id: record.tour_id,
									title: record.tour_name_long,
									tour: record
								})
								.subscribe({
									next: () => {
										dialogRef.close();
										if(dataContainerService instanceof DatasetActionContainerService){
											dataContainerService.datasetEvent.next({
												eventName: DatasetEvents.ACTION_REALOAD_LIST
											});
										}
										datasetNavigatorService.appService.showSuccessMessage('Pacchetto creato');
									},
									error: () => {
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.setMessage('Si è verificato un errore');
									}
								});
							});
						}
					}
				]
			}
		}
	}
};
