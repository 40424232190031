import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import editForm from './forms/edit.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/services/detail/:recordId',
};

export const ServiceConfig: IDatasetConfig = {
	name: 'services',
	title: 'Servizi',
	singleTitle: 'Servizio',
	ajaxDatasetCode: 'services',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: 'Fornitore',
						key: 'supplier.description'
					},
					/*{
						title: 'Struttura',
						key: 'trip_structure.description'
					}*/
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
