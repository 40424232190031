/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../base-actions/list/dataset-list.component.ngfactory";
import * as i6 from "../../../../../../services/dataset-action-container.service";
import * as i7 from "../../../../../../../../../services/app.service";
import * as i8 from "../../../../../../../../pages/page-closure-container.service";
import * as i9 from "../../../../../../../../../services/auth.service";
import * as i10 from "../../../../../../services/dataset.service";
import * as i11 from "../../../../../../../../../services/domain-filter.service";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../../../../../base-actions/list/dataset-list.service";
import * as i14 from "../../../../../../base-actions/list/dataset-list.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../../../../services/dataset-navigator.service";
import * as i17 from "../../../../../../../../../../@fuse/services/translation-loader.service";
import * as i18 from "@angular/material/snack-bar";
import * as i19 from "../../../../../../../../../services/websocket.service";
import * as i20 from "../../../../../../services/dataset-record.service";
import * as i21 from "./step.component";
var styles_MeetingPointUnifiedListItemsComponent = [];
var RenderType_MeetingPointUnifiedListItemsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MeetingPointUnifiedListItemsComponent, data: {} });
export { RenderType_MeetingPointUnifiedListItemsComponent as RenderType_MeetingPointUnifiedListItemsComponent };
function View_MeetingPointUnifiedListItemsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MeetingPointUnifiedListItemsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MeetingPointUnifiedListItemsComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "dataset-list", [], null, null, null, i5.View_DatasetListComponent_0, i5.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i6.DatasetActionContainerService, i6.DatasetActionContainerService, [i7.AppService, i8.PageClosureContainerService, i9.AuthService, i10.DatasetService, i11.DomainFilterService, i12.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i13.DatasetListService, [i6.DatasetActionContainerService, i10.DatasetService]), i0.ɵdid(6, 770048, null, 0, i14.DatasetListComponent, [i15.ActivatedRoute, i10.DatasetService, i15.Router, i9.AuthService, i7.AppService, i6.DatasetActionContainerService, i16.DatasetNavigatorService, i11.DomainFilterService, "ListParamService", i17.FuseTranslationLoaderService, i18.MatSnackBar, i19.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], parentDatasetACS: [2, "parentDatasetACS"], parentDatasetRS: [3, "parentDatasetRS"], showSearchBar: [4, "showSearchBar"], elevate: [5, "elevate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.loading.value; _ck(_v, 2, 0, currVal_0); var currVal_1 = false; var currVal_2 = "meeting_point_unified_list_items"; var currVal_3 = _co.parentDatasetACS; var currVal_4 = _co.parentDatasetRS; var currVal_5 = false; var currVal_6 = false; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_MeetingPointUnifiedListItemsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "meeting_point_unified_list_items", [], null, null, null, View_MeetingPointUnifiedListItemsComponent_0, RenderType_MeetingPointUnifiedListItemsComponent)), i0.ɵprd(512, null, i6.DatasetActionContainerService, i6.DatasetActionContainerService, [i7.AppService, i8.PageClosureContainerService, i9.AuthService, i10.DatasetService, i11.DomainFilterService, i12.TranslateService, i0.Injector]), i0.ɵprd(512, null, i20.DatasetRecordService, i20.DatasetRecordService, [i6.DatasetActionContainerService, i10.DatasetService]), i0.ɵdid(3, 4440064, null, 0, i21.MeetingPointUnifiedListItemsComponent, [i7.AppService, i20.DatasetRecordService, i6.DatasetActionContainerService, i16.DatasetNavigatorService, i10.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var MeetingPointUnifiedListItemsComponentNgFactory = i0.ɵccf("meeting_point_unified_list_items", i21.MeetingPointUnifiedListItemsComponent, View_MeetingPointUnifiedListItemsComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", parentDatasetRS: "parentDatasetRS", parentDatasetACS: "parentDatasetACS" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { MeetingPointUnifiedListItemsComponentNgFactory as MeetingPointUnifiedListItemsComponentNgFactory };
