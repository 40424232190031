import { Injectable, Injector, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, forkJoin, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDatasetConfig, IDatasetPropertyDefinition, IDatasetCallDefinition } from 'app/interfaces';
import { DomainInstanceService } from 'app/services/domain-instance.service';
import { AbstractService } from 'app/services/abstract.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { MatDialog } from '@angular/material/dialog';
import { action_join_domain } from 'app/main/actions/join-domain.action';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth.service';

interface IRequestData { path: string; data: any; options?: any; typeCall?: string; }

@Injectable()
export class DatasetService extends AbstractService{

	constructor(
		protected http: HttpClient,
		public appService: AppService,
		protected authService: AuthService,
		protected domainInstanceService: DomainInstanceService,
		protected domainFilterService: DomainFilterService,
		public matDialog: MatDialog
	){
		super(http, appService);
	}

	appendDomainIdHeaders(value, headers?: HttpHeaders): HttpHeaders {
		if(headers) return headers.append('X-Domain-Id', value);
		return new HttpHeaders({
			'X-Domain-Id': value
		});
	}

	appendFilterHeaders(headers?: HttpHeaders, onlyWithFilterDomain?: boolean): HttpHeaders {
		if(!headers) headers = new HttpHeaders;
		// not override if just setted
		if(!headers.has('X-Domain-Id')){
			if (onlyWithFilterDomain || (this.domainFilterService.filterDomainId.value && this.domainFilterService.showFilterBar)) {
				headers = this.appendDomainIdHeaders(this.domainFilterService.filterDomainId.value, headers);
			}
		}
		return headers;
	}

	get<T>(path: string, httpParams?: any, headers?: HttpHeaders): Observable<T> {
		if(!path) return throwError('no path defined');
		const requestData: IRequestData = {
			path: path,
			data: httpParams,
			options: {},
			typeCall: 'get'
		};

		return this.http.get<T>(this.appService.getBaseServerAddress() + path, {
			headers: this.appendFilterHeaders(headers),
			params: httpParams,
		}).pipe(
			catchError(this.handleError('dataset/get :> ' + path, false, requestData))
		);
	}

	post<T>(path: string, data?: any, options?: {}): Observable<T> {
		if (this.domainFilterService.filterDomainId.value) {
			// copy previous headers
			const newHeaders = this.appendFilterHeaders(options && options['headers'], true);

			options = Object.assign({}, options, {headers: newHeaders});
		}

		const requestData: IRequestData = {path, data, options};

		return this.http.post<T>(this.appService.getBaseServerAddress() + path, data, options).pipe(
			catchError(this.handleError('dataset/post :> ' + path, false, requestData))
		);
	}

	put<T>(path: string, data?: any): Observable<T>{
		return this.http.put<T>(this.appService.getBaseServerAddress() + path, data)
		.pipe(
			catchError(this.handleError('dataset/put :> ' + path))
		);
	}

	delete<T>(path: string, data?: any): Observable<T>{
		if(data){
			return this.http.patch<T>(this.appService.getBaseServerAddress() + path, data)
			.pipe(
				catchError(this.handleError('dataset/delete :> ' + path))
			);
		}
		return this.http.delete<T>(this.appService.getBaseServerAddress() + path)
		.pipe(
			catchError(this.handleError('dataset/delete :> ' + path))
		);
	}

	getPropertyDefinitions(datasetCode: string): Observable<IDatasetPropertyDefinition[]>{
		return this.http.get<IDatasetPropertyDefinition[]>(this.appService.getBaseServerAddress() + '/properties/definitions', {
			params: {
				dataset_code: datasetCode
			}
		}).pipe(
			catchError(this.handleError('GET /properties/definitions'))
		);
	}

	fetchSourceData(resourceConfig: IDatasetConfig, beforeCalls: IDatasetCallDefinition[], paramMap: Map<string, any>, options?: any): Observable<any>{
		// console.log('fetchSourceData.options', options, new Error().stack);
		const calls: Observable<any>[] = [];
		const callsMap = [];
		if(beforeCalls){
			for (const routeResolverCall of beforeCalls) {
				const params = routeResolverCall.params || {};
				let callPath = routeResolverCall.path;
				if(paramMap){
					if(routeResolverCall.routeParamMap){
						for (const elMap of routeResolverCall.routeParamMap) {
							params[elMap.callParam] = paramMap.get(elMap.routeParam);
						}
					}

					paramMap.forEach((value, key) => {
						callPath = callPath.replace(':' + key, value);
					});
				}
				if(routeResolverCall.method === 'post'){
					calls.push(this.post(callPath, params));
				}else{
					calls.push(this.get(callPath, params));
				}
				callsMap.push(routeResolverCall);
			}
		}
		if(resourceConfig.supportProperties && (!options || !options.excludeProperties)){
			calls.push(this.getPropertyDefinitions(resourceConfig.name));
			callsMap.push({
				name: resourceConfig.name + '.' + resourceConfig.name + '_property_definitions',
				path: '/properties/definitions'
			});
		}
		if(resourceConfig.nestedProperties && (!options || !options.excludeProperties)){
			for (const nestedDatasetCode of resourceConfig.nestedProperties) {
				calls.push(this.getPropertyDefinitions(nestedDatasetCode));
				callsMap.push({
					name: nestedDatasetCode + '.' + nestedDatasetCode + '_property_definitions',
					path: '/properties/definitions'
				});
			}
		}
		
		if(calls.length === 0){
			return of({});
		}

		return forkJoin(calls)
		.pipe(map(result => {
			const mappedResult = {};
			for (let i = 0; i < result.length; i++) {
				const name = callsMap[i].name;
				const keys = name.split('.');
				if(keys.length == 1){
					mappedResult[name] = result[i];
				}else{
					let container = mappedResult;
					for(let j = 0; j < keys.length - 1; j++){
						const key = keys[j];
						if(!container[key]) container[key] = {};
						container = container[key];
					}
					container[keys[keys.length - 1]] = result[i];
				}
			}
			return mappedResult;
		}));
	}

	fetchPropertyDefinitions(resourceConfig: IDatasetConfig): Observable<any>{
		if(resourceConfig.supportProperties){
			return this.getPropertyDefinitions(resourceConfig.name)
			.pipe(map(result => {
				const mapped = {};
				mapped[resourceConfig.name] = { [resourceConfig.name + '_property_definitions']: result};
				return mapped;
			}));
		}
		return of({});
	}

	protected handleError(operations?: string, ignore: boolean = false, requestData?: IRequestData): (errorResponse: HttpErrorResponse) => Observable<any>{
		const baseHanlder = super.handleError(operations, ignore);

		return (errorResponse: HttpErrorResponse) => {
			if(errorResponse.status >= 400 && errorResponse.status < 500){
				if(errorResponse.error){
					if(errorResponse.error.code === 'invalid_allotments'){
						return throwError(errorResponse);
					}else if (errorResponse.error.code === 'ambiguity') {
						const observer = new Subject<any>();
						const dialogRef = this.domainInstanceService.openDomainsDialog();

						dialogRef.afterClosed().subscribe((selectResult) => {
							if (!selectResult) {
								observer.error(errorResponse);
								observer.complete();
								return observer;
							}

							if (errorResponse.error.setFilter) this.domainFilterService.setFilterDomain(selectResult, false);

							const newHeaders = this.appendDomainIdHeaders(selectResult, requestData.options && requestData.options['headers']);
							
							let newOptions = Object.assign({}, requestData.options, {headers: newHeaders});
							let callMyMethod = this.post.bind(this);

							if (requestData.typeCall === 'get') {
								callMyMethod = this.get.bind(this);
								newOptions = newHeaders;
							}

							callMyMethod(requestData.path, requestData.data, newOptions)
							.pipe(catchError(this.handleError(operations, ignore, requestData)))
							.subscribe({
								next: response => {
									observer.next(response);
									observer.complete();
								},
								error: response => {
									observer.error(response);
									observer.complete();
								}
							});
						});
						
						return observer;
					}else if(errorResponse.error.action_required){
						if(errorResponse.error.action_required.code === 'join_in_domain'){
							return action_join_domain(this, errorResponse.error.action_required);
							// return new Subject<any>(); // never complete, stop the action without error
						}
					}else{
						console.error('unhandled error', errorResponse.error);
					}
				}
			}
			return baseHanlder(errorResponse);
		};
	}
}
