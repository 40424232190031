export const locale = {
	lang: 'en',
	data: {
		// DATASET LIST
		'Azioni': 'Actions',
		'Apri Dettaglio': 'View Detail',
		'Elimina': 'Delete',
		'Aggiungi': 'Add new',
		'Seleziona': 'Select',
		'Filtri': 'Filters',
		'Pulisci filtri': 'Clear filters',
		'Nessuna immagine': 'No image',
		'Duplica': 'Duplicate',
		'Codice': 'Code',
		'Descrizione': 'Description',
		'Traduzioni Attive': 'Active Translations',
		// datasets translate
		// 'trip_destinations'
		'Destinazioni': 'Destinations',
		// 'trip_packages'
		'Pacchetti': 'Packages',
		'Status': 'Status',
		'Immagine': 'Image',
		// 'trip_resources'
		'Risorse': 'Resources',
		'Tipo': 'Type',
		'Allotment': 'Allotment',
		// trip_categories
		'#Pacchetti': '#Packages',
		// trip_facilities
		'Icona': 'Icon',
		'Nome': 'Name',
		// services
		'Fornitore': 'Supplier',
		// discounts
		'Codice Coupon': 'Coupon Code',
		// domains
		'Stato': 'Status',
		'Gerarchia': 'Hierarchy',
		'Domini': 'Domains',
		'Domini Utenti': 'User Domains',
		'Ruolo': 'Role',
		'Filiale': 'Branch',
		'Pagamento Diretto': 'Direct payment',
		'# Rate': '# Installments',
		// domain_packages
		'Richieste E Cessioni': 'Requests And Transfers',
		'Pacchetto': 'Package',
		'Dominio Richiedente': 'Requesting domain',
		'Dominio Cedente': 'Transferring Domain',
		// Users
		'Id Accesso': 'Login Id',
		'Utenti': 'Users',
		// sale_operations
		'Data': 'Date',
		'Totale': 'Total',
		'Punti Di Ritrovo': 'Meeting Points',
		'Categorie': 'Categories',
		'Informazioni Utili': 'Useful Information',
		'Criteri': 'Criteria',
		'Servizi': 'Services',
		'Listini': 'Price Lists',
		'Sconti': 'Discounts',
		'Anagrafiche': 'Entities',
		'Scegli uno spazio': 'Select domain',
		'Metodo Di Pagamenti': 'Method Payments',
		// trip_package_reviews
		'Nome Recensore': 'Reviewer\'s Name',
		'Punteggio': 'Score',
		// trip_booking_reports
		'Prenotazioni': 'Bookings',
		'Codice/Stato': 'Code/state',
		'Data Prenotazione': 'Booking Date',
		'Dettaglio partenza': 'Departure detail',
		'# PAX/Passeggero di riferimento': '# Pax/Passenger Reference',
		'Canale/Utente': 'Channel/User',
		'Cliente': 'Customer',
		'Prezzo Lordo': 'Gross Price',
		'Prezzo Netto': 'Net Price',
		'Numero Voucher Operatore': 'Operator Voucher Number',
		'Note': 'Note',
		'Voucher': 'Voucher',
		'Non Attivo': 'Inactive',
		'Attivo': 'Active',
		'Attivo e pubblicato': 'Active and published',
		'Proprietario': 'Owner',
		'Non selezionabile': 'Not selectable',
	}
};
