import { Component, OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonStepViewContainerComponent } from '../common-step-view-container/view.component';
import { StepService } from '../../../services/step-state.service';

@Component({
	selector   : 'simp-temp-view-container',
	templateUrl: './view.component.html',
	styleUrls  : ['./view.component.scss'],
	providers: [StepService]
})
export class SmartInterfaceStepViewContainerComponent extends CommonStepViewContainerComponent implements OnInit, OnDestroy, AfterViewInit {

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected _fuseSidebarService: FuseSidebarService,
		protected router: Router,
		protected route: ActivatedRoute,
		protected location: Location,
		protected elementRef: ElementRef,
		public stepService: StepService){
		/** init parent constructor */
		super(
			appService, 
			datasetRS, 
			datasetACS, 
			datasetNavigatorService, 
			_fuseSidebarService, 
			router, 
			route, 
			location,
			stepService
		);
	}

	ngOnInit(): void {
		this.stepService.initStepData();
		super.ngOnInit();

		this.stepperIndex = this.route.snapshot.queryParams['step'] ? Number(this.route.snapshot.queryParams['step']) : 1;
		this.stepIndex = this.stepperIndex;
	}

	ngOnDestroy(): void{
		this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F5F5F5';
	}

	ngAfterViewInit(): void{
		this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
	}
}
