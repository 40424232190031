import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var TinyMCEInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TinyMCEInputComponent, _super);
    function TinyMCEInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.tinymceConfig = {
            base_url: 'assets/tinymce',
            plugins: 'lists link image media table insertdatetime preview code',
            branding: false,
            setup: null,
            toolbar_mode: 'scrolling',
            toolbar_drawer: 'floating',
            mobile: { theme: 'silver' },
            toolbar: 'bold italic | alignleft ' // undo redo | styleselect |
                + 'aligncenter alignright alignjustify | bullist numlist | outdent indent'
                + ' | code',
        };
        _this.ready = false;
        return _this;
    }
    TinyMCEInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            var baseHref = document.querySelector('base').href;
            if (_this.tinymceConfig.base_url == 'assets/tinymce')
                _this.tinymceConfig.base_url = baseHref + _this.tinymceConfig.base_url;
            if (_this.inputService.inputConfig.readOnly) {
                _this.tinymceConfig.setup = function (editor) {
                    editor.setMode('readonly');
                };
            }
            _this.ready = true;
        });
    };
    return TinyMCEInputComponent;
}(DestroyableComponent));
export { TinyMCEInputComponent };
