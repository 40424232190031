import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TrenitaliaService } from '../../trenitalia.service';
import * as _ from 'lodash';
import { wrapArray } from 'app/helpers';

function findSolutionNode(id, solutionNodes){
	for (const solutionNode of solutionNodes) {
		if(solutionNode.idXml == id) return solutionNode;
		if(solutionNode.subSegments){
			const found = findSolutionNode(id, solutionNode.subSegments);
			if(found) return found;
		}
	}
	return null;
}

@Component({
	selector: 'trenitalia-train-details',
	templateUrl: './train-details.component.html',
	styleUrls: ['./train-details.component.scss']
})
export class TrainDetailsComponent implements OnInit, OnChanges {
	@Input('train') train: any;
	@Input('original') solution: any;
	public load = true;
	public stops;
	public infoNotes: any[] = [];
	public infoServices: any[] = [] 

	constructor(private _trnService: TrenitaliaService) {}

	ngOnInit() {
		const solution_node = findSolutionNode(this.train.id, this.solution.original.solutionNodes);
		if(solution_node){
			this._trnService
			.getStops({
				/*solution_node: _.get(this.original, this.solution.path),*/
				solution_node: solution_node
			})
			.subscribe({
				next: stops => {
					this.stops = stops;
				},
				error: () => {
					this.load = false;
				},
				complete: () => {
					this.load = false;
				}
			});
		}else{
			this.load = false;
		}
	}

	

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.train){
			this.updateNotes();
		}
	}

	updateNotes(){
		this.infoNotes = wrapArray(this.train.info).filter(note => {
			return note.type == 'Nota Informativa' && !['.', '[]'].includes(note.description);
		});
		this.infoServices = wrapArray(this.train.info).filter(note => {
			return note.type != 'Nota Informativa' && !['.', '[]'].includes(note.description);
		});
	}
}
