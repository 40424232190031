<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field class='pb-4'>
		<mat-icon matPrefix class='mr-4' *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
		<mat-label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<mat-chip-list #chipList>
			<mat-chip
				*ngFor="let item of selectedItems"
				[selectable]="true"
				[removable]="true"
				(removed)="removeItem(item)"
			>
				{{ displayFun(item) }}
				<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
			</mat-chip>
				
			<input
				[required]='inputService.inputConfig.required'
				[placeholder]="(inputService.inputConfig.placeholder || '') | translate" 
				autocomplete="off"
				[name]='inputService.formControlKey'
				#filterInput
				(input)="fetchFilteredOptions($event.target.value)"
				[formControl]='inputFormControl'
				[readonly]='inputService.inputConfig.readOnly'
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				[matChipInputAddOnBlur]="true"
				(matChipInputTokenEnd)="onMatChipInputTokenEnd($event)"
			/>
			<mat-spinner *ngIf="loading" diameter="15" matSuffix></mat-spinner>
		</mat-chip-list>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint}}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
		<input type='hidden' [formControlName]='inputService.formControlKey' />
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)='onMatAutocompleteSelectedEvent($event)'>
			<mat-option *ngIf='supportCreate && textSearch' [value]="textSearch">
				<mat-icon>add</mat-icon>
				<span>{{'Crea' | translate}}: {{textSearch}}</span>
			</mat-option>
			<mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByOptions" [value]="option">
				<mat-icon *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
				<span [innerHTML]='inputService.formInputDefinition.remoteOptions.getLabel(option)'></span>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</ng-container>