import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('package.description', {
	key: 'package.description',
	name: 'Pacchetto',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('request_domain.display_name', {
	key: 'request_domain.display_name',
	name: 'Dominio richiedente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('owner_domain.display_name', {
	key: 'owner_domain.display_name',
	name: 'Dominio cedente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if (record.reseller_domain){
			return record.reseller_domain.display_name;
		}
		return record.owner_domain.display_name;
	}
});

fieldsMap.set('mapped_customer_entity_description', {
	key: 'mapped_customer_entity_description',
	name: 'Cliente',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.mapped_customer_entity) return '';
		return record.mapped_customer_entity.description;
	}
});

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.SELECT,
	},
	options: [
		{
			label: 'IN ATTESA',
			value: 0
		},
		{
			label: 'RESPINTO',
			value: 1
		},
		{
			label: 'ACCETTATO',
			value: 2
		},
		{
			label: 'Annullata',
			value: 3
		},
		{
			label: 'Ritirata',
			value: 4
		}
	],
});

fieldsMap.set('filter_status', {
	key: 'status',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	disallowNull: false,
	defaultValue(): any{
		return null;
	},
	options: [
		{
			label: 'Tutti',
			value: null
		},
		{
			label: 'IN ATTESA',
			value: 0
		},
		{
			label: 'RESPINTO',
			value: 1
		},
		{
			label: 'ACCETTATO',
			value: 2
		},
		{
			label: 'Annullata',
			value: 3
		},
		{
			label: 'Ritirata',
			value: 4
		}
	],
});

export {fieldsMap};
