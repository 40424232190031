import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { ITripBookingPackageRelatedData, TripBookingPackageRelatedComponent } from '../trip-booking-packages-related.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

@Component({
	selector   : 'trip-booking-participant',
	templateUrl: './trip-booking-participant.component.html',
	styleUrls: ['./trip-booking-participant.component.scss'],
	providers: [
		DatasetActionContainerService,
		PageClosureContainerService,
		DatasetRecordService
	]
})
export class TripBookingParticipantComponent extends TripBookingPackageRelatedComponent implements OnInit, OnDestroy{

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedData){
			super(appService, datasetACS, datasetRS, matDialogRef, data);
		}

	onEdit(): void{
		this.datasetNavigatorService.openEditDialog(this.datasetACS, this.datasetRS.record.value)
		.afterClosed().subscribe(response => {
			this.datasetRS.reloadWithParams({
				trip_booking_participant_id: this.datasetRS.record.value.participant_id, 
				trip_booking_package_id: this.data.tripBookingPackageRS.recordId
			});
		});
	}

	trash(): void{
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.datasetRS.record.value)
		.beforeClosed()
		.subscribe(() => {
			this.datasetRS.record.next(null);
		});
	}
}
