import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import createFormConfig from './forms/create.form';
import completeFormConfig from './forms/complete.form';
import detailFormConfig from './forms/detail.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_booking_services/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	let trip_booking_package_id = dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId');
	let trip_booking_resource_id = dataContainerService.getValueFromKeyPath('trip_booking_resources.recordId');
	let trip_booking_id = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
	let trip_booking_discount_id = dataContainerService.getValueFromKeyPath('trip_booking_discounts.recordId');

	if(trip_booking_resource_id) params['trip_booking_resource_id'] = trip_booking_resource_id;
	if(trip_booking_package_id) params['trip_booking_package_id'] = trip_booking_package_id;
	if(trip_booking_discount_id) params['trip_booking_discount_id'] = trip_booking_discount_id;
	if(trip_booking_id) params['trip_booking_id'] = trip_booking_id;

	params['with_trashed'] = 1;

	return params;
}

export const TripBookingServiceConfig: IDatasetConfig = {
	name: 'trip_booking_services',
	title: 'Servizi',
	singleTitle: 'Servizio',
	ajaxDatasetCode: 'trip_booking_services',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
		restore: true
	},
	formConfigsMap: {
		createFormConfig,
		completeFormConfig
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['with_relations'] = 'tripService';
				return params;
			},
			viewConfig: {
				formConfig: detailFormConfig,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: createFormConfig
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: completeFormConfig
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Descrizione',
						key: 'description',
						fieldDefinition: {
							key: 'description',
							name: 'Descrizione',
							valueType: ValueTypes.STRING,
							fieldView: FieldViews.HTML,
							getDisplayValue(record: any): string{
								let html = record.description;
								if(Number(record.self_update) === 1){
									html = '<span class="primary info-label" title="Servizio Automatico">A</span> ' + record.description;
								}
								return html;
							}
						}
					},
					{
						title: 'Quantità',
						key: 'count'
					},
					{
						title: 'Prezzo U. Lordo',
						key: 'price_base'
					},
					{
						title: 'Prezzo U.',
						key: 'price'
					},
					{
						title: 'Totale Lordo',
						key: 'total_amount_lordo'
					},
					{
						title: 'Totale',
						key: 'total_amount'
					},					
					{
						title: 'fiscalità',
						key: 'taxation',
						showIf(dataContainerService) {
							const tripBookingPackage = dataContainerService.getValueFromKeyPath('trip_booking_packages.record');
							return Boolean(tripBookingPackage && tripBookingPackage.trip_package && tripBookingPackage.trip_package.target == 'ticket');
						},
					}
				],
				actions: ['create', 'detail', 'edit', 'delete', 'restore']
			}
		}
	}
};
