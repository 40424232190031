import { Component, OnInit } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/services/auth.service';

@Component({
	selector   : 'user-role-permission-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss']
})
export class UserRolePermissionStepComponent extends BaseStepViewComponent implements OnInit{

	public rolePermissions: any[];
	public checked: boolean = false;
	public loading: boolean = false;
	public changingGroupAccess: any = {};
	public isAdmin: boolean = false;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.getPermissions();
		
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(!record) return;
			this.isAdmin = record.level < 10;
		});

		/*this.authService.userDataObserver
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(userData => {
			this.isAdmin = this.authService.isAdministrator();
		});*/
	}

	getPermissions(): void{
		this.loading = true;
		this.datasetService.post<any>('/dataset/user_roles/command/get_permissions', {
			user_role_id: this.datasetRS.record.value.id
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.rolePermissions = response;
				this.loading = false;

				if (!this.loading) {
					this.isCheckboxChecked();
				}
			},
			error: () => {
				this.loading = false;
			}
		});
	}

	isCheckboxChecked(): void {
		const permission = this.findPermissionByCode('booking_group');
		if(!permission) return;
		const action = this.getAction(permission, 'edit_booking_date');
		if (action && action.id) this.checked = true;
	}

	findPermissionByCode(code: string): any {
		return this.rolePermissions.find(permission => permission.code === code);
	}

	getAction(permission: any, actionCode: string): any {
		return permission.actions.find(action => action.code === actionCode);
	}

	changeAccessType(group, level): void{
		this.changingGroupAccess[group.id] = true;
		this.datasetService.post<any>('/dataset/user_roles/command/set_access_level', {
			user_role_id: this.datasetRS.record.value.id,
			dataset_group_definition_id: group.id,
			access_level: level
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: () => {
				delete this.changingGroupAccess[group.id];
			},
			error: () => {
				delete this.changingGroupAccess[group.id];
			}
		});
	}

	commandSetPermission(group: any, action: any): void{
		this.datasetService.post<any>('/dataset/user_roles/command/set_permissions', {
			dataset_action_definition_id: action.action_id,
			user_role_id: this.datasetRS.record.value.id,
			dataset_group_definition_id: group.id
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: () => {
				this.getPermissions();
			},
			error: () => {}
		});
	}

	commandUnsetPermission(action: any): void{
		this.datasetService.post<any>('/dataset/user_roles/command/unset_permissions', {
			dataset_role_allowed_action_id: action.id,
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: () => {
				this.getPermissions();
			},
			error: () => {}
		});
	}

	changeState(group: any, action: any, active: boolean): void{
		if (active) {
			this.commandSetPermission(group, action);
		} else {
			this.commandUnsetPermission(action);
		}
	}
}
