/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../components/common-step/trip_packages/overview/view.component.ngfactory";
import * as i4 from "../../components/common-step/trip_packages/overview/view.component";
import * as i5 from "../../../../../services/app.service";
import * as i6 from "../../services/dataset-record.service";
import * as i7 from "../../services/dataset-action-container.service";
import * as i8 from "../../services/dataset-navigator.service";
import * as i9 from "../../services/dataset.service";
import * as i10 from "@angular/common";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i14 from "@angular/material/progress-spinner";
import * as i15 from "@angular/cdk/platform";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i18 from "@angular/material/button";
import * as i19 from "@angular/cdk/a11y";
import * as i20 from "../../../../pages/page-closure-container.service";
import * as i21 from "@angular/common/http";
import * as i22 from "../../../../../services/auth.service";
import * as i23 from "../../../../../services/domain-instance.service";
import * as i24 from "../../../../../services/domain-filter.service";
import * as i25 from "@angular/router";
import * as i26 from "./dialog.component";
var styles_RenderViewDialogComponent = [];
var RenderType_RenderViewDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenderViewDialogComponent, data: {} });
export { RenderType_RenderViewDialogComponent as RenderType_RenderViewDialogComponent };
function View_RenderViewDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.data.title)); _ck(_v, 2, 0, currVal_1); }); }
function View_RenderViewDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Recupero dati in corso")); _ck(_v, 2, 0, currVal_1); }); }
function View_RenderViewDialogComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "trip-package-overview", [], null, null, null, i3.View_TripPackageOverviewComponent_0, i3.RenderType_TripPackageOverviewComponent)), i0.ɵdid(1, 245760, null, 0, i4.TripPackageOverviewComponent, [i5.AppService, i6.DatasetRecordService, i7.DatasetActionContainerService, i8.DatasetNavigatorService, i9.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_RenderViewDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, null, null, null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_4)), i0.ɵdid(4, 278528, null, 0, i10.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.component; _ck(_v, 2, 0, currVal_0); var currVal_1 = "trip-package-overview"; _ck(_v, 4, 0, currVal_1); }, null); }
function View_RenderViewDialogComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "spinner"], ["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i11.DefaultLayoutDirective, [i0.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i11.DefaultLayoutAlignDirective, [i0.ElementRef, i12.StyleUtils, [2, i11.LayoutAlignStyleBuilder], i12.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i13.View_MatSpinner_0, i13.RenderType_MatSpinner)), i0.ɵdid(4, 114688, null, 0, i14.MatSpinner, [i0.ElementRef, i15.Platform, [2, i10.DOCUMENT], [2, i16.ANIMATION_MODULE_TYPE], i14.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 4)._noopAnimations; var currVal_3 = i0.ɵnov(_v, 4).diameter; var currVal_4 = i0.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
function View_RenderViewDialogComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_MatButton_0, i17.RenderType_MatButton)), i0.ɵdid(3, 180224, null, 0, i18.MatButton, [i0.ElementRef, i19.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(4, 0, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 3).disabled || null); var currVal_1 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.data.positiveText ? _co.data.positiveText : i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("chiudi"))); _ck(_v, 4, 0, currVal_3); }); }
export function View_RenderViewDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_2)), i0.ɵdid(3, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_3)), i0.ɵdid(7, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_5)), i0.ɵdid(9, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderViewDialogComponent_6)), i0.ɵdid(11, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.loading && _co.data.title); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.loading; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_RenderViewDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "render-view-dialog", [], null, null, null, View_RenderViewDialogComponent_0, RenderType_RenderViewDialogComponent)), i0.ɵprd(512, null, i20.PageClosureContainerService, i20.PageClosureContainerService, [i5.AppService]), i0.ɵprd(512, null, i9.DatasetService, i9.DatasetService, [i21.HttpClient, i5.AppService, i22.AuthService, i23.DomainInstanceService, i24.DomainFilterService, i1.MatDialog]), i0.ɵprd(512, null, i7.DatasetActionContainerService, i7.DatasetActionContainerService, [i5.AppService, i20.PageClosureContainerService, i22.AuthService, i9.DatasetService, i24.DomainFilterService, i2.TranslateService, i0.Injector]), i0.ɵprd(512, null, i6.DatasetRecordService, i6.DatasetRecordService, [i7.DatasetActionContainerService, i9.DatasetService]), i0.ɵprd(512, null, i8.DatasetNavigatorService, i8.DatasetNavigatorService, [i5.AppService, i1.MatDialog, i25.Router, i0.Injector, i9.DatasetService, i0.NgZone]), i0.ɵdid(6, 245760, null, 0, i26.RenderViewDialogComponent, [i5.AppService, i6.DatasetRecordService, i7.DatasetActionContainerService, i8.DatasetNavigatorService, i9.DatasetService, i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
var RenderViewDialogComponentNgFactory = i0.ɵccf("render-view-dialog", i26.RenderViewDialogComponent, View_RenderViewDialogComponent_Host_0, {}, {}, []);
export { RenderViewDialogComponentNgFactory as RenderViewDialogComponentNgFactory };
