import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { SmartInterfaceI18nFormStepComponent } from '../description-i18n-form-step/step.component';
var ResellSmartInterfaceI18nFormStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResellSmartInterfaceI18nFormStepComponent, _super);
    function ResellSmartInterfaceI18nFormStepComponent(appService, parentDatasetRS, parentDatasetACS, datasetACS, reselledDatasetRS, originalDatasetRS, reselledDatasetMCS, originalDatasetMCS, datasetNavigatorService, datasetService, formBuilder, zone, formViewerService, stepService) {
        var _this = _super.call(this, appService, reselledDatasetRS, datasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, formBuilder, zone, formViewerService, stepService) || this;
        _this.appService = appService;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.datasetACS = datasetACS;
        _this.reselledDatasetRS = reselledDatasetRS;
        _this.originalDatasetRS = originalDatasetRS;
        _this.reselledDatasetMCS = reselledDatasetMCS;
        _this.originalDatasetMCS = originalDatasetMCS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.formBuilder = formBuilder;
        _this.zone = zone;
        _this.formViewerService = formViewerService;
        _this.stepService = stepService;
        return _this;
    }
    ResellSmartInterfaceI18nFormStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.reselledDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.reselledDatasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentReselledValueKey
            });
            _this.datasetMCS.fetchRecord();
            _this.originalDatasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.originalDatasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentValueKey
            });
            _this.originalDatasetMCS.fetchRecord();
            _this.formViewerService.init({
                errors: _this.formErrors,
                dataContainerService: _this.datasetACS,
                fields: Array.from(_this.fileterFields.values())
            });
            _this.datasetRS.record.subscribe(function (record) { return _this.formViewerService.setRecord(record); });
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    return ResellSmartInterfaceI18nFormStepComponent;
}(SmartInterfaceI18nFormStepComponent));
export { ResellSmartInterfaceI18nFormStepComponent };
