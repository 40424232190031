import { Component, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { Subscription } from 'rxjs';
import { LogsService } from '../../logs.service';

@Component({
	selector     : 'logs-detail',
	templateUrl  : './detail.component.html',
	// styleUrls    : ['./logs-detail.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LogsDetailComponent{

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public logsService: LogsService
	){}

	public onPageChanged(event: any): void{
		this.logsService.detailCurrentPage = event.pageIndex + 1;
		this.logsService.detailPerPage = event.pageSize;
		this.logsService.fetchDetail();
	}
	
}
