/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../train-details/train-details.component.ngfactory";
import * as i2 from "../train-details/train-details.component";
import * as i3 from "../../trenitalia.service";
import * as i4 from "@angular/common";
import * as i5 from "./travel-solution-train-details.component";
var styles_TravelSolutionTrainDetailsComponent = [];
var RenderType_TravelSolutionTrainDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TravelSolutionTrainDetailsComponent, data: {} });
export { RenderType_TravelSolutionTrainDetailsComponent as RenderType_TravelSolutionTrainDetailsComponent };
function View_TravelSolutionTrainDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "trenitalia-train-details", [], null, null, null, i1.View_TrainDetailsComponent_0, i1.RenderType_TrainDetailsComponent)), i0.ɵdid(2, 638976, null, 0, i2.TrainDetailsComponent, [i3.TrenitaliaService], { train: [0, "train"], solution: [1, "solution"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.travelSolution; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_TravelSolutionTrainDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TravelSolutionTrainDetailsComponent_1)), i0.ɵdid(1, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trains; var currVal_1 = _co.indexTrackBy; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TravelSolutionTrainDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "trenitalia-travel-solution-train-details", [], null, null, null, View_TravelSolutionTrainDetailsComponent_0, RenderType_TravelSolutionTrainDetailsComponent)), i0.ɵdid(1, 573440, null, 0, i5.TravelSolutionTrainDetailsComponent, [], null, null)], null, null); }
var TravelSolutionTrainDetailsComponentNgFactory = i0.ɵccf("trenitalia-travel-solution-train-details", i5.TravelSolutionTrainDetailsComponent, View_TravelSolutionTrainDetailsComponent_Host_0, { travelSolution: "travelSolution" }, {}, []);
export { TravelSolutionTrainDetailsComponentNgFactory as TravelSolutionTrainDetailsComponentNgFactory };
