import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import filterForm from './forms/filter.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/branches/detail/:recordId',
};

export const BranchesConfig: IDatasetConfig = {
	name: 'branches',
	title: 'Filiali',
	singleTitle: 'Filiale',
	ajaxDatasetCode: 'branches',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Nome',
						key: 'name'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
