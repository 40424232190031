import { IStepViewConfig, TangerineBgColors } from 'app/interfaces';
import generalForm from './forms/general.form';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';

export const config: IStepViewConfig = {
	tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
	title: 'Sconto',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.name;
	},
	isLinear(component: CommonStepViewContainerComponent): boolean{
		return !Boolean(component.record);
	},
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-form-step',
			options: {
				formConfig: generalForm
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Regole',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'discount_rules',
						title: 'Regole Sconto'
					},
				]
			}
		}
	]
};
