import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews, IDatasetCallDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PriceRulesDialogComponent } from 'app/main/components/dataset/dialogs/price-rules-dialog/dialog.component';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import {TripServiceTaxation} from './taxation.enum';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/trip_services/detail/:recordId',
	params: {
		with_relations: 'domain'
	}
};

function getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any {
	const isTripPackageDataset = dataContainerService.getValueFromKeyPath('trip_packages');
	const resource = dataContainerService.getValueFromKeyPath('trip_resources.record');
	if (resource) {
		const listId = dataContainerService.getValueFromKeyPath('trip_resources.record.trip_service_catalog_list_id');

		if (listId) {
			params['trip_service_catalog_list_id'] = listId;
		} else {
			const parentId = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
			params['foreign_parent_record_id'] = parentId;
			params['foreign_parent_dataset_code'] = 'trip_resources';
			params['foreign_parent_field_key'] = 'trip_service_catalog_list_id';
		}
	} else if (isTripPackageDataset) {
		const listId = dataContainerService.getValueFromKeyPath('trip_packages.record.trip_service_catalog_list_id');

		if (listId) {
			params['trip_service_catalog_list_id'] = listId;
		} else {
			const parentId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			params['foreign_parent_record_id'] = parentId;
			params['foreign_parent_dataset_code'] = 'trip_packages';
			params['foreign_parent_field_key'] = 'trip_service_catalog_list_id';
		}
	} else {
		const listId = dataContainerService.getValueFromKeyPath('products.record.trip_service_catalog_list_id');

		if (listId) {
			params['trip_service_catalog_list_id'] = listId;
		} else {
			const parentId = dataContainerService.getValueFromKeyPath('products.recordId');
			params['foreign_parent_record_id'] = parentId;
			params['foreign_parent_dataset_code'] = 'products';
			params['foreign_parent_field_key'] = 'trip_service_catalog_list_id';
		}
	}
	return params;
}

export const TripServiceConfig: IDatasetConfig = {
	name: 'trip_services',
	title: 'Cataloghi Servizi',
	singleTitle: 'Catalogo Servizi',
	ajaxDatasetCode: 'trip_services',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
	},
	formConfigsMap: {
		editForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						width: '50px',
						title: 'Servizio Primario',
						key: 'primary_service',
						fieldDefinition: {
							key: 'primary_service',
							name: 'Servizio Primario',
							valueType: ValueTypes.NUMBER,
							fieldView: FieldViews.HTML,
							getDisplayValue(record: any): string {
								if (record.primary_service === 1) {
									return '<mat-icon alt="Usato per il calcolo del prezzo da mostrare" role="img" class="primary-fg mat-icon material-icons mat-icon-no-color">check_circle_outline</mat-icon>';
								}
								return '';
							},
						},
					},
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Codice Servizio',
						key: 'service.code',
						width: '60',
					},
					{
						title: 'Codice Criterio',
						key: 'trip_service_rule.code',
						width: '60',
					},
					{
						title: 'Descrizione Criterio',
						key: 'trip_service_rule.description',
					},					
					{
						title: 'fiscalità',
						key: 'taxation',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record){
							if(Object.keys(TripServiceTaxation).some((v) => v === record.taxation))
								return TripServiceTaxation[record.taxation];
							else
								return record.taxation;
						}
					},
					{
						title: 'Prezzo al pubblico',
						key: 'price',
						fieldDefinition: fieldsMap.get('price'),
					},
					{
						title: 'Listino Prezzi',
						key: 'id',
						fieldDefinition: {
							name: 'Listino Prezzi',
							key: 'open_price_list',
							valueType: ValueTypes.STRING,
							getDisplayValue(): string {
								return 'Apri';
							},
						},
						actionCode: 'noaction',
						onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void {
							datasetNavigatorService
								.openDialog(PriceRulesDialogComponent, {
									listId: record.individual_price_rule_list_id,
									parentDatasetACS: datasetACS,
									parentRecordId: record.id,
									parentFieldKey: 'individual_price_rule_list_id',
								})
								.afterClosed()
								.subscribe(() => {
									datasetACS.datasetEvent.next({
										eventName: DatasetEvents.ACTION_REALOAD_LIST,
									});
								});
						},
					},
				],
				actions: ['create', 'edit', 'delete'],
				customActions: [
					{
						buttonColor: 'primary',
						buttonIcon: 'check',
						actionCode: 'edit',
						tooltip: 'Imposta come servizio primario',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void {
							const dialogRef = datasetNavigatorService.openDialog(LoadingDialogComponent, {
								title: 'Azione in corso',
							});
							datasetNavigatorService.datasetService
								.post<any>('/dataset/trip_services/command/set_as_primary_service', {
									trip_service_id: record.id,
								})
								.subscribe({
									next: response => {
										if (dataContainerService instanceof DatasetActionContainerService) {
											dataContainerService.datasetEvent.next({
												eventName: DatasetEvents.ACTION_REALOAD_LIST,
											});
										}
										dialogRef.close();
									},
									error: response => {
										dialogRef.close();
									},
								});
						},
						showIf(record: any, dataContainerService: IDataContainerService): boolean {
							return record.primary_service !== 1;
						},
					},
				],
			},
		},
	},
};
