<form-viewer #formViewer
	[dataContainerService]="datasetACS"
	[record]="datasetRS.record.value.participant_counters"
	[formConfig]='formConfig'
	(valueChanges)="onValueChanges($event)"
></form-viewer>
<div>
<span class="info-icon" (click)="openInfoTrenitalia()" *ngIf="tripBookingPackagesStepService.canAddTrenitalia">
						<mat-icon>info</mat-icon> Info passeggeri Trenitalia
					</span>
</div>