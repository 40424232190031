<h2>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
	<ng-container *ngIf='datasetACS.actionConfig && datasetACS.actionConfig.viewConfig.formConfig'>
		<div card-data-viewer
			[record]='datasetRS.record.value'
			[datasetACS]='datasetACS'
			[viewFormConfig]='datasetACS.actionConfig.viewConfig.formConfig'
		></div>
	</ng-container>
</div>

<mat-tab-group>
	<mat-tab [label]="'Regole Listino' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='discount_rules'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
			>
			</dataset-list>
		</ng-template>
	</mat-tab>
	<mat-tab [label]="'Clienti' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='entity_customers'
				[filters]='{discount_rule_list_id: datasetRS.record.value.id}'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[enableCreate]='false'
				[enableDelete]='false'
			></dataset-list>
		</ng-template>
	</mat-tab>
</mat-tab-group>
