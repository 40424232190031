import { ValueTypes, FieldViews, IColumnDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { BookingPreviewDialogComponent, IBookingPreviewDialogData } from 'app/main/components/dataset/dialogs/booking-preview-dialog/dialog.component';


export const LIST_COLUMNS: IColumnDefinition[] = [
	{
		title: 'Codice/Stato',
		key: 'status_column',
		/*onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
			datasetNavigatorService.router.navigate(['/edit-booking/' + record.id]);
		},*/
		fieldDefinition: {
			name: 'Status',
			key: 'status',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				const label = record.booking_code || '';
				let foregroundColor = '';
				function getStatus(): string{
					if(record.status === 'draft') return 'Preventivo';
					if(record.status === 'confirmed'){
						foregroundColor = 'green-fg';
						let html = '<div class="booking_status" style="display:flex;flex-direction:row;align-items: center;">';
						// html += '<mat-icon role="img" class="primary-fg mr-4 mat-icon material-icons mat-icon-no-color">check</mat-icon>';
						html += '<span class="green-fg">Confermata</span>';
						html += '</div>';
						return html;
					}
					if(record.status === 'optional'){
						foregroundColor = 'orange-fg';
						return '<span class="orange-fg">Opzionale (in scadenza)</span>';
					}
					if(record.status === 'expired') return 'Scaduta';
					if(record.status === 'unconfirmed') return 'Non Confermata';
					if(record.status === 'canceled'){
						foregroundColor = 'warn-fg';
						return '<span class="warn-fg">Annullata</span>';
					}
					return '';
				}
				const status = getStatus();
				return '<span class="' + foregroundColor + '">' + label + '<br>' + status + '</span>';
			},
		},
		onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
			datasetNavigatorService.openDialog<IBookingPreviewDialogData>(BookingPreviewDialogComponent, {
				recordId: record.id,
				parentDatasetACS: datasetACS
			});
		}
	},
	/*{
		title: 'Codice',
		key: 'booking_code'
	},*/
	{
		title: 'Pacchetto',
		key: 'trip_package_description',
		fieldDefinition: {
			name: 'Pacchetto',
			key: 'trip_package_description',
			valueType: ValueTypes.PROPERTY,
		}
	},
	{
		title: 'Data Prenotazione',
		key: 'booking_date'
	},
	{
		title: 'Dettaglio partenza',
		key: 'start_day',
		fieldDefinition: {
			name: 'Data Partenza',
			key: 'start_day',
			valueType: ValueTypes.PROPERTY,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				let label = '';
				if(!record) return '';
				// todo use actual_trip
				return label;
			}
		}
	},
	{
		title: '# PAX/Passeggero di riferimento',
		key: 'main_participant_name',
		fieldDefinition: {
			name: 'Passeggero di riferimento',
			key: 'main_participant_name',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';
				let label = '# PAX: ' + record.participant_count;
				if(record.entity){
					label += '<br>' + record.entity.description;
				}
				return label;
			}
		}
	},
	/*{
		title: 'Numero PAX',
		key: 'participant_count'
	},*/
	{
		title: 'Canale/Utente',
		key: 'origin_channel_code',
		fieldDefinition: {
			name: 'Canale',
			key: 'origin_channel_code',
			valueType: ValueTypes.PROPERTY,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';
				let value = null;
				if(record.properties && record.properties.origin_channel_code && record.properties.origin_channel_code.value){
					value = record.properties.origin_channel_code.value;
				}

				let label = 'Back Office';

				if(value){
					if(['direct_online', 'zvsite'].includes(value)){
						label = 'Direct Online';
					}else if(['api'].includes(value)){
						label = 'API';
					}else if(['direct_offline'].includes(value)){
						label = 'Direct Offline';
					}else if(['ticketing_mobile'].includes(value)){
						label = 'Mobile';
					}
				}

				if(record.creator){
					label += '<br>' + record.creator.name;
				}

				return label;
			}
		}
	},
	{
		title: 'Cliente',
		key: 'customer_name',
		fieldDefinition: {
			key: 'customer_name',
			name: 'Nome Cliente',
			valueType: ValueTypes.STRING,
			getDisplayValue(record: any): string{
				if(!record || !record.customer) return '';
				return record.customer.description;
			}
		}
	},
	{
		title: 'Prezzo Lordo',
		key: 'total_amount',
		fieldDefinition: fieldsMap.get('total_amount')
	},
	{
		title: 'Prezzo Netto',
		key: 'total_amount_net',
		fieldDefinition: fieldsMap.get('total_amount')
	},
	{
		title: 'Numero Voucher Operatore',
		key: 'origin_channel_voucher_code'
	},
	{
		title: 'Note',
		key: 'note',
		fieldView: FieldViews.DIALOG_TEXT,
		fieldDefinition: {
			name: 'Note',
			key: 'note',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.DIALOG_TEXT,
			prefixIcon: 'note'
		}
	},
	{
		title: 'Voucher',
		key: 'id',
		fieldDefinition: {
			name: 'voucher',
			key: 'id',
			valueType: ValueTypes.STRING,
			getDisplayValue(record: any): string{
				return '<a href="' + getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.id + '" target="_blank">Voucher</a>';
			},
			fieldView: FieldViews.HTML,
		}
	}
];
