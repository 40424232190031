import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { AppService } from 'app/services/app.service';
import { Platform } from '@angular/cdk/platform';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
var LayoutComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    function LayoutComponent(_fuseConfigService, _platform, appService) {
        this._fuseConfigService = _fuseConfigService;
        this._platform = _platform;
        this.appService = appService;
        this.showAppInterface = true;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(LayoutComponent.prototype, "directive", {
        // Directive
        set: function (theDirective) {
            if (!theDirective) {
                return;
            }
            this.appService.layoutFusePerefctScrollBar = theDirective;
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    LayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (config) {
            _this.fuseConfig = config;
        });
        this.appService.showAppInterface
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (show) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, show];
                    case 1:
                        _a.showAppInterface = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    LayoutComponent.prototype.ngAfterViewInit = function () {
        // Check if is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.container3.nativeElement.style.overflowY = 'auto';
        }
    };
    /**
     * On destroy
     */
    LayoutComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    LayoutComponent.prototype.onInstallBtnClick = function () {
        var _this = this;
        if (this.deferedPrompt) {
            this.deferedPrompt.prompt();
            this.deferedPrompt.userChoice.then(function (result) {
                if (result.outcome === 'accepted') {
                    console.log('user accepted add to homescreen');
                }
                else {
                    console.log('user dismissed the add to homescreen');
                }
                _this.deferedPrompt = null;
                _this.showBanner = false;
            });
        }
    };
    LayoutComponent.prototype.onbeforeinstallprompt = function (e) {
        e.preventDefault();
        this.deferedPrompt = e;
        // check if mobile platform
        if (this._platform.ANDROID || this._platform.IOS) {
            this.showBanner = true;
        }
    };
    LayoutComponent.prototype.onAppInstalled = function (e) {
        console.log('app installed');
    };
    return LayoutComponent;
}());
export { LayoutComponent };
