import {ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('title', {
	key: 'title',
	name: 'Tour',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record){
			if(record.keyPhoto && record.keyPhoto.originalUrl){
				html += '<img width="200" style="max-height:100px !important;" src="' + record.keyPhoto.originalUrl + '">';
			}
			html += '<br\>';
			if(record.title){
				html += '<span class="h3">' + record.title + '</span>';
			}
		}
		return html;
	}
});

fieldsMap.set('price', {
	key: 'price',
	name: 'A partire da',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record){
			if(record.price){
				html += '€ ' + record.price;
			}
		}
		return html;
	}
});

fieldsMap.set('vendor.title', {
	key: 'vendor.title',
	name: 'Vendor',
	valueType: ValueTypes.STRING
});

export {fieldsMap};
