import { Component, EventEmitter, Output } from '@angular/core';
import { GetDisplayFieldValuePipe } from 'app/pipes/get-display-field-value.pipe';
import { ListTableViewComponent } from '../table/table.component';

@Component({
	selector   : 'list-custom-view',
	templateUrl: './custom.component.html',
	styleUrls  : ['./custom.component.scss'],
	providers: [GetDisplayFieldValuePipe]
})
export class ListCustomViewComponent extends ListTableViewComponent{

	@Output() reloadDatasetList = new EventEmitter<any>();

	trackById(index, element): any{
		return element.id;
	}

	reload(): void{
		this.reloadDatasetList.emit();
	}
}
