import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';

export interface IDay {
	code: string;
	day: string;
	time?: ITime[];
}
export interface ITime {
	opening: string;
	closure: string;
}
@Component({
	selector   : 'week-hours',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss']
})
export class WeekHoursComponent extends DestroyableComponent implements OnInit{

	//public serviceDays: IDay[];
	public DAYS: IDay[] = [
		{code: 'monday', day: 'Lunedì'}, 
		{code: 'tuesday', day: 'Martedì'},
		{code: 'wednesday', day: 'Mercoledì'},
		{code: 'thursday', day: 'Giovedì'},
		{code: 'friday', day: 'Venerdì'},
		{code: 'saturday', day: 'Sabato'},
		{code: 'sunday', day: 'Domenica'}
	];

	get serviceDays(): IDay[] {
		return this.inputService.control.value ? this.inputService.control.value : this.DAYS;
	}

	constructor(
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			//this.serviceDays = this.inputService.control.value;
			//this.addNewItem();
			this.inputService.control.setValue(this.serviceDays);
			this.inputService.formGroup.updateValueAndValidity();
		});
	}

	addTime(day: IDay): void {
		day.time = day.time ? day.time : [];
		day.time.push({opening: '', closure: ''});
	}

	removeTime(times: ITime[], time: ITime): void {
		let index = times.indexOf(time);
		if ( index > -1 ) {
			times.splice(index, 1);
		}
	}

}
