<h1 mat-dialog-title>{{data.title?data.title:'Prenotazioni'}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<p>Attenzione, ci sono alcune prenotazioni confermate su questa partenza che è stata cancellata.</p>

	<div class="custom-table-responsive">
		<table *ngIf='reservations' mat-table [dataSource]="reservations" [ngClass]='tableStyle || viewConfig?.tableStyle'>
			<ng-container matColumnDef="trip_booking_code">
				<mat-header-cell *matHeaderCellDef> Codice/Stato </mat-header-cell>
				<mat-cell *matCellDef="let row"> 
					<span [innerHTML]="row.column_code_html"></span>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="reservation">
				<mat-header-cell *matHeaderCellDef> Data Prenotazione </mat-header-cell>
				<mat-cell *matCellDef="let row"> {{row.tripBooking.booking_date | date: 'dd/MM/yyyy'}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="trip_booking_entity_customer_name">
				<mat-header-cell *matHeaderCellDef> Cliente </mat-header-cell>
				<mat-cell *matCellDef="let row"> {{row.tripBookingRepostView ? row.tripBookingRepostView.entity_customer_name : ''}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="total_amount">
				<mat-header-cell *matHeaderCellDef> Importo </mat-header-cell>
				<mat-cell *matCellDef="let row"> {{row.total_amount}} </mat-cell>
			</ng-container>
			<ng-container matColumnDef="refundableType">
				<mat-header-cell *matHeaderCellDef> Metodo Rimborso </mat-header-cell>
				<mat-cell *matCellDef="let row">
					<span *ngIf="row.is_automatic_refund_enabled" class='green-fg'>
						Automatico (tramite stripe)
					</span>
					<span *ngIf="!row.is_automatic_refund_enabled" class='warn-fg'>
						Manuale
					</span>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="linkToResource">
				<mat-header-cell *matHeaderCellDef> Apri </mat-header-cell>
				<!-- <mat-cell *matCellDef="let row"> {{row.linkToResource}} </mat-cell> -->
				<mat-cell *matCellDef="let row">
					<a *ngIf='row.linkToResource' mat-button [href]="row.linkToResource" target="_blank">Apri</a>
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="check">
				<mat-header-cell *matHeaderCellDef> Stato Cancellazione </mat-header-cell>
				<mat-cell *matCellDef="let row">
					<mat-spinner *ngIf='row.check === "loading"' diameter="25"></mat-spinner>
					<mat-icon *ngIf='row.check === true && row.refund_success' color='success'>done</mat-icon>
					<mat-icon *ngIf='row.check === "error"' color='danger'>close</mat-icon>
					<mat-icon *ngIf='row.check === true && !row.refund_success' color='warn' matTooltip="Rimborso automatico fallito, procedere con il rimborso manuale">warning</mat-icon>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="footer-discailmer">
				<td mat-footer-cell *matFooterCellDef colspan="6">
					<span *ngIf="pageMeta.total - reservations.length > 0">Ci sono altre {{pageMeta.total - reservations.length}} prenotazioni confermate da processare</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="footer-action">
				<td mat-footer-cell *matFooterCellDef>
					<button *ngIf="processMode && currentReservationsProcessed" (click)="loadBookings()" mat-button>Aggiorna lista</button>
				</td>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			<mat-footer-row mat-footer-row *matFooterRowDef="['footer-discailmer', 'footer-action']"></mat-footer-row>
		</table>
	</div>

	<div [innerHTML]="data.contentMessage"></div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<ng-container *ngIf="!processMode">
		<button mat-flat-button (click)="onNoClick()" color='secondary'>{{data.negativeText?data.negativeText:'Nessuna azione' | translate}}</button>
		<button mat-flat-button (click)="onDelete()" color='accent'>{{data.neutralText?data.neutralText:'Cancella Prenotazioni' | translate}}</button>
		<button mat-flat-button (click)='onDeleteAndRefund()' color='accent'>{{data.positiveText?data.positiveText:'Cancella e Rimborsa' | translate}}</button>
	</ng-container>
	<ng-container *ngIf="processMode">
		<p class="warn-fg">Le prenotazione saranno processate, non chiudere la schermata fino al termine dell'operazione.</p>
	</ng-container>
</mat-dialog-actions>
 