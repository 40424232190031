import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { BookingService } from "app/main/pages/edit-booking/booking.service";

@Component({
	selector: 'trip-booking-package-header',
	templateUrl: './header.component.html'
})
export class TripBookingPackageHeaderComponent extends DestroyableComponent{

	@Input() title: string;
	@Input() icon: string;
	@Input() canDelete = false;
	@Input() canCancel = false;
	@Input() showInfo = false;
	@Input() showEdit = false;
	@Input() showConfirm = false;
	@Input() hidePrice = false;

	@Output() infoClick = new EventEmitter();
	@Output() deleteClick = new EventEmitter();
	@Output() editClick = new EventEmitter();
	@Output() confirmClick = new EventEmitter();

	get tripBookingPackage (){
		return this.datasetRS.record.value;
	}

	get tripBookingStatus(){
		return this.datasetACS.getValueFromKeyPath('trip_bookings.record.status');
	}

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public bookingService: BookingService
	){
		super();
	}
}