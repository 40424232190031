import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';

export interface ILoadingDialogData {
	title?: string;
	contentMessage?: string;
}

@Component({
	selector: 'loading-dialog',
	templateUrl: 'dialog.component.html'
})
export class LoadingDialogComponent implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<LoadingDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ILoadingDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
