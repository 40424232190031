import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';

export interface IMeetingPointListItemsDialogData {
	listId: string;
	listRecord: any;
	parentDatasetACS: DatasetActionContainerService;
}

@Component({
	selector: 'meeting-point-list-items-dialog',
	templateUrl: 'dialog.component.html',
	providers: [PageClosureContainerService, DatasetActionContainerService, DatasetRecordService]
})
export class MeetingPointListItemsDialogComponent implements OnInit, OnDestroy{

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public appService: AppService,
		public datasetService: DatasetService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<MeetingPointListItemsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IMeetingPointListItemsDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.data.parentDatasetACS.datasetCode,
			actionCode: 'detail',
			parentDatasetACS: this.data.parentDatasetACS,
			fetchData: false,
			parentDatasetRS: this.datasetRS
		}).subscribe(ready => {
			if(!ready) return;

			this.datasetRS.recordId = this.data.listId;
			this.datasetACS.putDatasetData('list_id', this.data.listId);
		});
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	onDatasetEvent(event: IDatasetEvent): void{
		if(!this.data.listId){
			if(event.eventName === DatasetEvents.CREATED && event.data && event.data.list_id){
				this.data.listId = event.data.list_id;
			}
		}
	}
}
