import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetEvents, DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MeetingPointListItemsDialogComponent, IMeetingPointListItemsDialogData } from 'app/main/components/dataset/dialogs/meeting-point-list-items-dialog/dialog.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/meeting_point_unified_lists/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
	const tripPackage = dataContainerService.getValueFromKeyPath('trip_packages.record');
	if(tripPackage){
		params['properties.parent_dataset_code'] = 'trip_packages';
		params['properties.parent_id'] = tripPackage.id;
	}
	return params;
}

export const MeetingPointListConfig: IDatasetConfig = {
	name: 'meeting_point_unified_lists',
	title: 'Liste punti di ritrovo',
	singleTitle: 'Lista punti di ritrovo',
	ajaxDatasetCode: 'meeting_point_unified_lists',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		editForm
	},
	defaultActions: {
		detail: {
			getCallParams,
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
					},
					{
						title: 'Nome',
						key: 'description'
					},
					{
						title: 'Punti di ritrovo',
						key: 'id',
						fieldDefinition: {
							name: 'Punti di ritrovo',
							key: 'meeting_points',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								let value = 'Apri Lista';
								if(record && record.items_count){
									value += ' (' + record.items_count + ')';
								}
								return value;
							}
						},
						onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IMeetingPointListItemsDialogData>(MeetingPointListItemsDialogComponent, {
								listId: record.id,
								listRecord: record,
								parentDatasetACS: datasetACS
							}).afterClosed()
							.subscribe( () => {
								datasetACS.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
							});
						}
					}
				],
				actions: ['create', 'edit', 'delete']
			}
		}
	}
};
