<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>{{'Lista di punti di ritrovo' | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetRS.loading.value'></mat-progress-bar>

	<div *ngIf='!datasetRS.loading.value' class='mb-24'>
		<dataset-list
			[elevate]='false'	
			[showSearchBar]='false'
			[showTitle]='false'
			datasetCode='meeting_point_unified_list_items'
			[parentDatasetACS]='datasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{
				list_id: data.listId
			}'
			(datasetEvent)='onDatasetEvent($event)'
		></dataset-list>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
