import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
/**
 * @deprecated
 */
var TripBookingPackageRelatedComponent = /** @class */ (function () {
    function TripBookingPackageRelatedComponent(appService, datasetACS, datasetRS, matDialogRef, data) {
        this.appService = appService;
        this.datasetACS = datasetACS;
        this.datasetRS = datasetRS;
        this.matDialogRef = matDialogRef;
        this.data = data;
    }
    TripBookingPackageRelatedComponent.prototype.ngOnInit = function () {
        this._unsubscribeAll = new Subject();
        this.datasetACS.init({
            datasetCode: this.data.datasetCode,
            actionCode: this.data.actionCode,
            parentDatasetACS: this.data.tripBookingPackageACS,
            parentDatasetRS: this.data.tripBookingPackageRS,
            useBeforeCalls: false,
            fetchData: false
        });
    };
    TripBookingPackageRelatedComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TripBookingPackageRelatedComponent.prototype.onSelected = function (selectedOption) {
        this.datasetRS.record.next(selectedOption);
    };
    TripBookingPackageRelatedComponent.prototype.onCloseClick = function () {
        this.matDialogRef.close();
    };
    Object.defineProperty(TripBookingPackageRelatedComponent.prototype, "isResaleCopy", {
        get: function () {
            return Boolean(this.data.tripBookingPackage.ref_id);
        },
        enumerable: true,
        configurable: true
    });
    return TripBookingPackageRelatedComponent;
}());
export { TripBookingPackageRelatedComponent };
