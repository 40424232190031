import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';

const createFormFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('resource_type'),
	fieldsMap.get('resource_sub_types'),
	fieldsMap.get('files_download_overview'),
	fieldsMap.get('ticket_text'),
	fieldsMap.get('allotment_type'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('allotment_booking_mode'),
			fieldsMap.get('allotment_share_mode')
		]
	},
	fieldsMap.get('description'),
	fieldsMap.get('entity_supplier_id')
];

export default { 
	title: 'Crea risorsa',
	fields: createFormFields 
};
