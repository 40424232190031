import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent, CalendarDateFormatter } from 'angular-calendar';

import { fuseAnimations } from '@fuse/animations';

import { CalendarService } from './calendar.service';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { takeUntil } from 'rxjs/operators';
import { IListViewConfig, IPaginationResponse } from 'app/interfaces';

@Component({
	selector     : 'dataset-calendar-view',
	templateUrl  : './calendar.component.html',
	styleUrls    : ['./../../../../../../pages/calendar/calendar.component.scss', './calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [
		CalendarService,
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class DatasetCalendarViewComponent implements OnInit, OnDestroy, OnChanges{

	activeDayIsOpen: boolean;
	events: CalendarEvent[];
	_unsubscribeAll: Subject<any>;

	@ViewChild('filterInput', { static: false }) filterInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

	@Input() public viewConfig: IListViewConfig;
	@Input() public paginationResponse: IPaginationResponse<any>;
	@Input() selectable = false;
	@Input() selection: any;
	@Input() conditionalShowPaginationToolbar = true;
	@Input() elevate = true;
	@Input() showSearchBar: true;
	@Input() enableCreate = true;
	@Input() enableEdit = true;
	@Input() enableDelete = true;
	@Input() showPagination = true;
	@Input() enableActions = true;
	@Output() selected = new EventEmitter<any>();
	@Output() pageChanged = new EventEmitter<any>();

	@Input() emitAdd = false;
	@Input() addButtonText = 'Aggiungi';
	@Output() add = new EventEmitter<any>();
	public filtersData = {};

	constructor(
		public _calendarService: CalendarService,
		public datasetNavigatorService: DatasetNavigatorService,
		public datasetACS: DatasetActionContainerService,
		public matDialog: MatDialog
	){
		// Set the defaults
		this.activeDayIsOpen = false;
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this._calendarService.calendarConfig = this.viewConfig.calendarConfig;

		this.onViewDateChanged(this._calendarService.viewDate);

		this.datasetACS.datasetDataChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(keys => {
			if(!keys || !keys.includes('filters')) return;
			this.filtersData = this.datasetACS.datasetData['filters'];
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.viewConfig){
			this._calendarService.calendarConfig = this.viewConfig.calendarConfig;
		}
		if(changes.paginationResponse){
			if(!this.paginationResponse){
				this.events = [];
				return;
			}
			if(!this.viewConfig.calendarConfig.itemsToEvents){
				console.warn('define calendarConfig.itemsToEvents for: ' + this.datasetACS.datasetCode);
				return;
			}
			
			this.events = this.viewConfig.calendarConfig.itemsToEvents(this.paginationResponse.items, this);
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Before View Renderer
	 *
	 * @param {any} header
	 * @param {any} body
	 */
	beforeMonthViewRender(event: CalendarMonthViewBeforeRenderEvent): void{
		const currentMonth = moment.utc(this._calendarService.viewDate).startOf('month');
		for (const _day of event.body) {
			const dayTime = _day.date.getTime();
			if(dayTime <= currentMonth.valueOf()){
				_day.cssClass = 'not-in-month';
			}else{
				if(!this._calendarService.selectedDateTimes.includes(dayTime)) continue;
				_day.cssClass = 'cal-selected';
			}
		}
	}

	onViewDateChanged(date: Date): void{
		this._calendarService.selectedDateTimes = [];

		const prevFilterData = this.datasetACS.getValueFromKeyPath('filters');
		const newFilterData = Object.assign({}, {
			from_date: moment.utc(date).startOf('month').toISOString(),
			to_date: moment.utc(date).endOf('month').toISOString(),
		}, prevFilterData);
		this.datasetACS.putDatasetData('filters', newFilterData);
	}

	/**
	 * Day clicked
	 *
	 * @param {MonthViewDay} day
	 */
	dayClicked(day: CalendarMonthViewDay): void{
		const date: Date = day.date;
		const events: CalendarEvent[] = day.events;

		if ( isSameMonth(date, this._calendarService.viewDate) ){
			if ( (isSameDay(this._calendarService.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0 ){
				this.activeDayIsOpen = false;
			}else{
				if(this.viewConfig.calendarConfig.openCellDetail) this.activeDayIsOpen = true;
				this._calendarService.selectedDateTimes = [day.date];
				this._calendarService.viewDate = date;
			}
		}else{
			this.activeDayIsOpen = false;
		}
		/*if(this._calendarService.selectedDateTimes.includes(day.date.getTime())){
			const index = this._calendarService.selectedDateTimes.indexOf(day.date.getTime());
			this._calendarService.selectedDateTimes.splice(index, 1);
			day.cssClass = null;
		}else{
			this._calendarService.selectedDateTimes.push(day.date.getTime());
			day.cssClass = 'cal-selected';
		}*/

		if(this.viewConfig.calendarConfig.onDayClicked){
			this.viewConfig.calendarConfig.onDayClicked(this, day);
		}
	}

	/**
	 * Event times changed
	 * Event dropped or resized
	 *
	 * @param {CalendarEvent} event
	 * @param {Date} newStart
	 * @param {Date} newEnd
	 */
	eventTimesChanged(): void{
		// TODO
	}

	eventClick(): void{
		// TODO
	}

	onAdd(): void{
		if(!this.datasetACS.actionEnabled('create')) return;
		if(this.viewConfig.calendarConfig.onAdd){
			this.viewConfig.calendarConfig.onAdd(this);
			return;
		}
		if(this.emitAdd){
			this.add.emit();
		}else{
			this.datasetNavigatorService
			.onAdd(this.datasetACS);
		}
	}
}


