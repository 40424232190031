<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>{{'Risorse opzioni di partenze' | translate}}</h1>
<mat-dialog-content>
	<div class='mb-24'>
		<dataset-list
			[elevate]='false'	
			[showSearchBar]='false'
			[showTitle]='false'
			datasetCode='actual_trip_group_trip_resources'
			[parentDatasetACS]='data.parentDatasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{
				foreign_parent_record_id: data.parentRecordId,
				foreign_parent_dataset_code: data.parentDatasetACS.datasetCode,
				foreign_parent_field_key: data.parentFieldKey
			}'
			(datasetEvent)='onDatasetEvent($event)'
		></dataset-list>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
