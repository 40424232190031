import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Observable, Subject } from 'rxjs';
import { BookingService } from '../booking.service';
import { TripBookingConfig } from 'app/configs/datasets/trip_bookings';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { BaseStepChangerComponent } from '../base-step-changer.component';
import {getDisabledStaticValueCustomFields} from "../../../../configs/commons/shared-field-configs/custom_field.field";

@Component({
	selector   : 'edit-booking-step-0',
	templateUrl: './view.component.html',
	styleUrls: [ './view.component.scss' ]
})
export class EditBookingStep0Component extends BaseStepChangerComponent implements OnInit, OnDestroy{

	public loading = false;
	public formErrors: any;
	public formConfig = TripBookingConfig.formConfigsMap['baseInfoFormConfig'];

	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;

	get record(): any{ return this.datasetRS.record.value; }

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public bookingService: BookingService,
		public datasetService: DatasetService){
			super(bookingService);
		}

	ngOnInit(): void{
		this.bookingService.nextStep
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(stepper => {
			this.onSave()
			.subscribe(value => {
				stepper.next();
			});
		});
	}

	onSave(): Observable<any>{
		const subject = new Subject<any>();

		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
        /*if(!formData['entity_customer_id']){
            const value = getDisabledStaticValueCustomFields('entity_customer_id','booking',this.datasetService);
            if(value)formData['entity_customer_id']=value;
        }*/
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		this.datasetACS.loading.next(true);
		const route = '/dataset/trip_bookings/update/' + this.datasetRS.recordId;
		this.datasetService.post<any>(route, formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });

				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.clearForm();
				}
				this.datasetRS.recordId = response.id;
				this.datasetRS.reload()
				.subscribe(value => {
					if (value) subject.next(value);
					else this.datasetNavigatorService.onEdit(this.datasetACS, response);
				});
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}
			}
		});

		return subject;
	}
}
