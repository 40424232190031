import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import { fieldsMap } from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { LIST_COLUMNS } from './list_columns';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

export const TripBookingLogConfig: IDatasetConfig = {
	name: 'trip_booking_logs',
	title: 'Prenotazioni Log',
	singleTitle: 'Prenotazione Log',
	ajaxDatasetCode: 'trip_booking_logs',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	fetchConfig: {
		// ignore
	},
	formConfigsMap: {
		// ignore
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: LIST_COLUMNS,
				actions: [],
			},
			getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any{
				params.with_relations = 'user';
				params.with_attributes = 'user_action_reason';
				return params;
			}
		}
	}
};
