import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('target', {
	key: 'target',
	name: 'Tipologia',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: [{label: 'Biglietti', value: 'ticket'}, {label: 'Pacchetti', value: 'package'}],
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const ticketing = dataContainerService.getValueFromKeyPath('domainModules').ticketing;
		if(ticketing && ticketing.enabled){
			return ticketing.enabled;
		}
		return false;
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'Nome punto di ritrovo'
});

fieldsMap.set('default_time', {
	key: 'default_time',
	name: 'Orario di default',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	inputHint: 'Opzionale, orario di ritrovo di default per questo punto di ritrovo'
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	inputHint: 'Note aggiuntive'
});

addressFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'address.' + value.key });

	fieldsMap.set('address.' + key, value);
});

export {fieldsMap};
