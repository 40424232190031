import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import filterForm from './forms/filter.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/passport/detail/:recordId',
};

export const PassportConfig: IDatasetConfig = {
	name: 'passport',
	title: 'Credenziali API',
	singleTitle: 'Credenziali API',
	ajaxDatasetCode: 'passport',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Descrizione',
						key: 'name'
					},
					{
						title: 'Client ID',
						key: 'id'
					},
					{
						title: 'Client Secret',
						key: 'secret'
					},
					{
						title: 'Dominio',
						key: 'domain_id',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							if (record.domain) return record.domain.display_name;
							return '';
						}
					},
					{
						title: 'Utente',
						key: 'user_id',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							if (record.user) return record.user.name;
							return '';
						}
					},
					{
						title: 'Stato',
						key: 'revoked',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							if (record.revoked) return 'Disabilitato';
							return 'Abilitato';
						}
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
