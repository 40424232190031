/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./style-1-filter/data-filter.component.ngfactory";
import * as i3 from "./style-1-filter/data-filter.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../dataset/services/dataset-navigator.service";
import * as i6 from "../../dialogs/popover/popover.service";
import * as i7 from "./style-01-filter/data-filter.component.ngfactory";
import * as i8 from "./style-01-filter/data-filter.component";
import * as i9 from "./style-default/data-filter.component.ngfactory";
import * as i10 from "./style-default/data-filter.component";
import * as i11 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i12 from "@angular/material/progress-bar";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "@angular/common";
import * as i15 from "./data-filter.component";
var styles_DataFilterComponent = [i0.styles];
var RenderType_DataFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataFilterComponent, data: {} });
export { RenderType_DataFilterComponent as RenderType_DataFilterComponent };
function View_DataFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "style-1-filter-component", [], null, [[null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChange" === en)) {
        var pd_0 = (_co.filterChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Style1FilterComponent_0, i2.RenderType_Style1FilterComponent)), i1.ɵdid(2, 770048, null, 0, i3.Style1FilterComponent, [i4.MatDialog, i5.DatasetNavigatorService, i1.ElementRef, i6.PopoverService, i1.ViewContainerRef], { filtersData: [0, "filtersData"], dataContainerService: [1, "dataContainerService"], formConfig: [2, "formConfig"], showSearchText: [3, "showSearchText"], actionButtons: [4, "actionButtons"], loading: [5, "loading"], filterStyle: [6, "filterStyle"] }, { filterChange: "filterChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filtersData; var currVal_1 = _co.dataContainerService; var currVal_2 = _co.formConfig; var currVal_3 = _co.showSearchText; var currVal_4 = _co.actionButtons; var currVal_5 = _co.loading; var currVal_6 = _co.filterStyle; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_DataFilterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "style-01-filter-component", [], null, [[null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChange" === en)) {
        var pd_0 = (_co.filterChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_Style01FilterComponent_0, i7.RenderType_Style01FilterComponent)), i1.ɵdid(2, 770048, null, 0, i8.Style01FilterComponent, [i4.MatDialog, i5.DatasetNavigatorService, i1.ViewContainerRef], { filtersData: [0, "filtersData"], dataContainerService: [1, "dataContainerService"], formConfig: [2, "formConfig"], showSearchText: [3, "showSearchText"], actionButtons: [4, "actionButtons"], loading: [5, "loading"], filterStyle: [6, "filterStyle"] }, { filterChange: "filterChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filtersData; var currVal_1 = _co.dataContainerService; var currVal_2 = _co.formConfig; var currVal_3 = _co.showSearchText; var currVal_4 = _co.actionButtons; var currVal_5 = _co.loading; var currVal_6 = _co.filterStyle; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_DataFilterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "style-default-filter-component", [], null, [[null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChange" === en)) {
        var pd_0 = (_co.filterChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_StyleDefaultFilterComponent_0, i9.RenderType_StyleDefaultFilterComponent)), i1.ɵdid(2, 770048, null, 0, i10.StyleDefaultFilterComponent, [i4.MatDialog, i5.DatasetNavigatorService, i1.ViewContainerRef], { filtersData: [0, "filtersData"], dataContainerService: [1, "dataContainerService"], formConfig: [2, "formConfig"], showSearchText: [3, "showSearchText"], actionButtons: [4, "actionButtons"], loading: [5, "loading"] }, { filterChange: "filterChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filtersData; var currVal_1 = _co.dataContainerService; var currVal_2 = _co.formConfig; var currVal_3 = _co.showSearchText; var currVal_4 = _co.actionButtons; var currVal_5 = _co.loading; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_DataFilterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i11.View_MatProgressBar_0, i11.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i12.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i13.ANIMATION_MODULE_TYPE], [2, i12.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_DataFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i14.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataFilterComponent_1)), i1.ɵdid(3, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataFilterComponent_2)), i1.ɵdid(5, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataFilterComponent_3)), i1.ɵdid(7, 16384, null, 0, i14.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataFilterComponent_4)), i1.ɵdid(9, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterStyle; _ck(_v, 1, 0, currVal_0); var currVal_1 = "style-1"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "style-01"; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_DataFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "dataset-filter", [], null, null, null, View_DataFilterComponent_0, RenderType_DataFilterComponent)), i1.ɵdid(1, 770048, null, 0, i15.DataFilterComponent, [i4.MatDialog, i5.DatasetNavigatorService, i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataFilterComponentNgFactory = i1.ɵccf("dataset-filter", i15.DataFilterComponent, View_DataFilterComponent_Host_0, { filtersData: "filtersData", dataContainerService: "dataContainerService", formConfig: "formConfig", showSearchText: "showSearchText", actionButtons: "actionButtons", loading: "loading", filterStyle: "filterStyle" }, { filterChange: "filterChange" }, []);
export { DataFilterComponentNgFactory as DataFilterComponentNgFactory };
