<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence' class="dataset-select-form-field">
		<mat-label>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<input matInput readonly *ngIf='inputService.inputConfig.readOnly' [required]='inputService.inputConfig.required' [value]='selectedLabel || ""' />
		<mat-select #matSelect *ngIf='!inputService.inputConfig.readOnly' [required]='inputService.inputConfig.required' [placeholder]="inputService.inputConfig.placeholder" [formControlName]='inputService.formControlKey' (openedChange)="openedChange($event)" (selectionChange)="selectionChange($event)">
			<input class="myInput" 
				#myInput 
				matInput 
				focused="'true'" 
				type="text"
				(input)="search($event.target.value)" 
				autocomplete="off" 
				(keydown)="$event.stopPropagation()"
				[placeholder]="'Cerca' | translate">
			<mat-progress-bar *ngIf="loading" mode='indeterminate' ></mat-progress-bar>
			<mat-divider></mat-divider>
			<mat-option *ngIf='supportCreate' (click)='emitEventOnSupportCreation($event)'>
				<mat-icon>add</mat-icon>
				<span>{{'Crea nuovo' | translate}}</span>
			</mat-option>
			<mat-option *ngFor='let selectOption of selectOptions' [value]='selectOption.value'>
				<mat-icon *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
				<span [innerHTML]='selectOption.label'></span>
			</mat-option>
		</mat-select>
		<mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
		<div class="close-icon">
			<span class="material-icons" matSuffix style='cursor: pointer;' *ngIf='!loading && inputService.control.value && !inputService.inputConfig.readOnly' (click)='clear()'>close</span>
		</div>
		<!-- mat-hint here generate an error, need to investigate -->
		<!--<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint}}</mat-hint>-->
		<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>