<span class="material-icons-outlined dialog-close-icon" (click)='onCloseClick()'>close</span>

<h1 mat-dialog-title>{{data.title | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetACS.loading.value'></mat-progress-bar>

	<div *ngIf='!datasetACS.loading.value' class='mb-24'>
		<dataset-list
			[enableCreate]='false'
			(selected)='onSelected($event)'
			[selectable]='true'
			[selectionBtnIcon]='data.selectionBtnIcon'
			[selectionBtnTxt]='data.selectionBtnTxt || "Aggiungi"'
			[elevate]='false'	
			[showSearchBar]='true'
			[showTitle]='false'
			[datasetCode]='datasetACS.datasetCode'
			[parentDatasetACS]='datasetACS'
			[filters]='data.filters'
			[viewConfig]='data.viewConfig' 
			[tableStyle]='data.tableStyle || "style-2 selectable"'
			[filterStyle]='data.filterStyle || "style-1"'
			(datasetEvent)="onDatasetEvent($event)"
		></dataset-list>
	</div>
</mat-dialog-content>

<!-- actions dialog 
	<mat-dialog-actions align='end'>
		<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
	</mat-dialog-actions>
-->