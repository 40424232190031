/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../form-viewer/form-viewer.component.ngfactory";
import * as i7 from "../../../../../../../../services/form-viewer.service";
import * as i8 from "../../../../../../../../services/app.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../../../form-viewer/form-viewer.component";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "../../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i15 from "@angular/material/button";
import * as i16 from "@angular/cdk/a11y";
import * as i17 from "@angular/flex-layout/extended";
import * as i18 from "../../../../../../language-select/language-select.component.ngfactory";
import * as i19 from "../../../../../../language-select/language-select.component";
import * as i20 from "../../../../../services/dataset-action-container.service";
import * as i21 from "../../../../../../../pages/page-closure-container.service";
import * as i22 from "../../../../../../../../services/auth.service";
import * as i23 from "../../../../../services/dataset.service";
import * as i24 from "../../../../../../../../services/domain-filter.service";
import * as i25 from "../../../../../services/dataset-record.service";
import * as i26 from "../../../../../services/dataset-multilanguage-container.service";
import * as i27 from "./step.component";
import * as i28 from "../../../../../services/dataset-navigator.service";
var styles_TripPackageStep2Component = [];
var RenderType_TripPackageStep2Component = i0.ɵcrt({ encapsulation: 2, styles: styles_TripPackageStep2Component, data: {} });
export { RenderType_TripPackageStep2Component as RenderType_TripPackageStep2Component };
function View_TripPackageStep2Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TripPackageStep2Component_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Questa traduzione non \u00E8 ancora presente!")); _ck(_v, 2, 0, currVal_0); }); }
function View_TripPackageStep2Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "mt-24"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripPackageStep2Component_3)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "form-viewer", [], null, null, null, i6.View_FormViewerComponent_0, i6.RenderType_FormViewerComponent)), i0.ɵprd(131584, null, i7.FormViewerService, i7.FormViewerService, [i8.AppService, i9.FormBuilder, i0.NgZone]), i0.ɵdid(5, 770048, [[1, 4], ["formViewer", 4]], 0, i10.FormViewerComponent, [i7.FormViewerService, i0.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "mt-24 mb-24"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i0.ɵdid(7, 671744, null, 0, i11.DefaultLayoutDirective, [i0.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(8, 1720320, null, 0, i11.DefaultLayoutGapDirective, [i0.ElementRef, i0.NgZone, i13.Directionality, i12.StyleUtils, [2, i11.LayoutGapStyleBuilder], i12.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i0.ɵdid(9, 671744, null, 0, i11.DefaultLayoutAlignDirective, [i0.ElementRef, i12.StyleUtils, [2, i11.LayoutAlignStyleBuilder], i12.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatButton_0, i14.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i15.MatButton, [i0.ElementRef, i16.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(12, 0, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.datasetRS.record.value && !_co.datasetACS.loading.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.datasetACS.getFormConfig("textForm"); var currVal_2 = _co.datasetACS; var currVal_3 = _co.datasetRS.record.value; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "row"; _ck(_v, 7, 0, currVal_4); var currVal_5 = "10px"; _ck(_v, 8, 0, currVal_5); var currVal_6 = "end center"; _ck(_v, 9, 0, currVal_6); var currVal_9 = "accent"; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_7 = (i0.ɵnov(_v, 11).disabled || null); var currVal_8 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_10 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("Salva")); _ck(_v, 12, 0, currVal_10); }); }
export function View_TripPackageStep2Component_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { formViewer: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "full-form"]], null, null, null, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(4, 933888, null, 0, i17.DefaultClassDirective, [i0.ElementRef, i12.StyleUtils, i12.MediaMarshaller, i5.ɵNgClassImpl, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "language-select", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.appService.language.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_LanguageSelectComponent_0, i18.RenderType_LanguageSelectComponent)), i0.ɵdid(6, 114688, null, 0, i19.LanguageSelectComponent, [i8.AppService], { languageCodes: [0, "languageCodes"], activeLanguageCodes: [1, "activeLanguageCodes"], selected: [2, "selected"] }, { changed: "changed" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripPackageStep2Component_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripPackageStep2Component_2)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "full-form"; var currVal_1 = _co.datasetACS.getFormConfig("textForm").formStyle; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.datasetACS.getFormConfig("textForm").formStyle; var currVal_3 = "full-form"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.appService.supportedLanguageCodes; var currVal_5 = _co.datasetMCS.activeLanguageCodes; var currVal_6 = _co.appService.language.value; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.datasetACS.loading.value; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.datasetACS.ready.value; _ck(_v, 10, 0, currVal_8); }, null); }
export function View_TripPackageStep2Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "trip-package-step-2", [], null, null, null, View_TripPackageStep2Component_0, RenderType_TripPackageStep2Component)), i0.ɵprd(512, null, i20.DatasetActionContainerService, i20.DatasetActionContainerService, [i8.AppService, i21.PageClosureContainerService, i22.AuthService, i23.DatasetService, i24.DomainFilterService, i4.TranslateService, i0.Injector]), i0.ɵprd(512, null, i25.DatasetRecordService, i25.DatasetRecordService, [i20.DatasetActionContainerService, i23.DatasetService]), i0.ɵprd(512, null, i26.DatasetMultilanguageContainerService, i26.DatasetMultilanguageContainerService, [i8.AppService, i23.DatasetService]), i0.ɵdid(4, 4440064, null, 0, i27.TripPackageStep2Component, [i8.AppService, i25.DatasetRecordService, i20.DatasetActionContainerService, i28.DatasetNavigatorService, i23.DatasetService, i26.DatasetMultilanguageContainerService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var TripPackageStep2ComponentNgFactory = i0.ɵccf("trip-package-step-2", i27.TripPackageStep2Component, View_TripPackageStep2Component_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { TripPackageStep2ComponentNgFactory as TripPackageStep2ComponentNgFactory };
