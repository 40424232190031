import { TranslateService } from "@ngx-translate/core";
import tripCategoryIdField from "app/configs/commons/shared-field-configs/trip-category-id.field";
import tripDestinationIdField from "app/configs/commons/shared-field-configs/trip-destination-id.field";
import { datasetDetailPage } from "app/helpers/dataset-routing.helper";
import { cloneFieldDefinition } from "app/helpers/field.helper";
import { ListViewItemTypes, InputTypes, ValueTypes, FieldViews } from "app/interfaces";
import { ISelectDatasetRecordDialogData } from "app/main/components/dataset/dialogs/select-dataset-record/dialog.component";
import { fieldsMap } from "./fields";

export const config: ISelectDatasetRecordDialogData = {
	title: 'Prodotti',
	datasetCode: 'products',
	filters: {
		
	},
	viewConfig: {
		itemViewType: ListViewItemTypes.TABLE,
		defaultSortBy: 'description',
		defaultSortDirection: 'asc',
		showSearchBar: false,
		filterForm: {
			fields: [
				{
					config: {
						flexLayout: 'row',
					},
					fields: [
						fieldsMap.get('publish_on_web_filter'),
						fieldsMap.get('product_type_filter')
					]
				},
				{
					config: {
						flexLayout: 'row',
					},
					fields: [
						fieldsMap.get('product_category_filter'),
						cloneFieldDefinition(tripDestinationIdField)
					]
				},
			]	
		},
		columns: [
			{
				title: 'Stato',
				key: 'code',
				fieldView: FieldViews.HTML,
				getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
					let colorClass = 'orange-bg';
					let text = '<span class="status">' +  translateService.instant('Non Pubblico') + '</span>';
					if(record.public_product_on_web === 1){
						colorClass = 'green-bg';
						text = '<span class="status">' + translateService.instant('Pubblicato') + '</span>';
					}

					let html = '<div class="package_status" style="display:flex;align-items: center;">';
					html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
					html += '<div>'+text+'<br\><span>'+record.code+'</span></div>';
					html += '</div>';
					return html;
				},
				routerLink(record: any): string{
					return datasetDetailPage('products', record.id);
				}
			},
			{
				title : 'Immagine',
				key: 'master_image',
				fieldView: FieldViews.IMAGE,
				routerLink(record: any): string{
					return datasetDetailPage('products', record.id);
				}
			},
			{
				title: 'Descrizione',
				key: 'description'
			},
			{
				title: 'Tipo',
				key: 'product_type',
				fieldDefinition: {
					key: 'product_type',
					name: 'Tipo',
					valueType: ValueTypes.STRING
				},
				fieldView: FieldViews.HTML,
				getDisplayHTML(record, value){
					if(!value || value == 'standard') return 'Standard';
					if(value == 'travelmate') return 'Audioguida';
					if(value == 'esim-manet') return 'ESim';
					return value;
				}
			}
		]
	}
}