import { ApplicationRef, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { AppService } from 'app/services/app.service';
import { AuthService, CheckUserStatues } from 'app/services/auth.service';

import { IAppEvent, IUser } from 'app/interfaces';

import { FormGroup, FormBuilder } from '@angular/forms';
import FilterFields from './filter.fields';

import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { DomSanitizer } from '@angular/platform-browser';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { SelectDomainDialogComponent } from 'app/main/components/dataset/dialogs/select-domain-dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { action_edit_password } from 'app/main/actions/edit-password.action';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { action_edit_profile } from 'app/main/actions/edit-profile.action';

@Component({
	selector     : 'toolbar',
	templateUrl  : './toolbar.component.html',
	styleUrls    : ['./toolbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy{
	public filterFormGroup: FormGroup;
	public fileterFields = FilterFields;
	public selectedDomainId: any;
	public isAdministrator = false;

	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	languages: any;
	selectedLanguage: any;
	userStatusOptions: any[];
	dateNow = Date.now();
	user: IUser;
	
	// Private
	private _unsubscribeAll: Subject<any>;
	private _userImage: any;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {TranslateService} _translateService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _translateService: TranslateService,
		private auth: AuthService,
		private formBuilder: FormBuilder,
		private matDialog: MatDialog,
		public appService: AppService,
		public dashboardService: DashBoardService,
		public sanitizer: DomSanitizer,
		public domainFilterService: DomainFilterService,
		public datasetService: DatasetService,
		private zone: NgZone,
		private app: ApplicationRef
	){
		// Set the defaults
		this.userStatusOptions = [
			{
				'title': 'Online',
				'icon' : 'icon-checkbox-marked-circle',
				'color': '#4CAF50'
			},
			{
				'title': 'Away',
				'icon' : 'icon-clock',
				'color': '#FFC107'
			},
			{
				'title': 'Do not Disturb',
				'icon' : 'icon-minus-circle',
				'color': '#F44336'
			},
			{
				'title': 'Invisible',
				'icon' : 'icon-checkbox-blank-circle-outline',
				'color': '#BDBDBD'
			},
			{
				'title': 'Offline',
				'icon' : 'icon-checkbox-blank-circle-outline',
				'color': '#616161'
			}
		];

		this.languages = [
			{
				id   : 'en',
				title: 'English',
				flag : 'en'
			},
			{
				id   : 'it',
				title: 'Italiano',
				flag : 'it'
			}
		];

		// Set the private defaults
		this._unsubscribeAll = new Subject();

		this.zone.runOutsideAngular(() => {
			setInterval(() => {
				zone.run(() => {
					this.dateNow = Date.now();
				});
			}, 1000);
		});

		this.filterFormGroup = this.formBuilder.group({
			domain_id: this.domainFilterService.filterDomainId.value,
		});
	}

	setFilterDomain(domainId: any): void {
		this.domainFilterService.setFilterDomain(domainId);
	}

	unsetFilterDomain(): void {
		this.domainFilterService.unsetFilterDomain();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void
	{		
		this.filterFormGroup.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((data) => {
			if(data.domain_id != this.domainFilterService.filterDomainId.value){
				if (data.domain_id) {
					this.setFilterDomain(data.domain_id);
				} else {
					this.unsetFilterDomain();
				}
				
			}
			this.selectedDomainId = data && data.domain_id;
		});

		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((settings) => {
				this.horizontalNavbar = settings.layout.navbar.position === 'top';
				this.rightNavbar = settings.layout.navbar.position === 'right';
				this.hiddenNavbar = settings.layout.navbar.hidden === true;
			});

		// Set the selected language from default languages
		this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

		this.auth.userDataObserver
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((data) => {
			if(!data){
				this.user = null;
				this.isAdministrator = false;
			}else{
				this.user = data.user;
				this.isAdministrator = this.auth.isAdministrator();
			}
		});

		this.appService.appEvent
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((event: IAppEvent) => {
				if (event.name !== 'global_image_profile') return;
				this.userImage = event.target;
			});

		this.domainFilterService.filterDomainId
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(domainId => {
			this.filterFormGroup.controls['domain_id'].setValue(domainId);
		});
	}

	set userImage(value: any){
		this._userImage = value;
	}

	get userImage(): any{
		const baseServerAddress = this.appService.getBaseServerAddressRoot() + '/document_files/download/';
		if (this._userImage){
			return baseServerAddress + this._userImage;
		}else if (this.user.profile_image_id){
			return baseServerAddress + this.user.profile_image_id;
		}
		return 'assets/images/avatars/blank-profile-picture.png';
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void
	{
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	/**
	 * Search
	 *
	 * @param value
	 */
	search(value): void
	{
		// Do your search here...
		console.log(value);
	}

	/**
	 * Set the language
	 *
	 * @param lang
	 */
	setLanguage(lang): void
	{
		// Set the selected language for the toolbar
		this.selectedLanguage = lang;

		// Use the selected language for translations
		this._translateService.use(lang.id).subscribe(() => localStorage.setItem('locale', lang.id));
	}

	logout(): void{
		this.auth.logout();
	}

	editProfile(): void{
		action_edit_profile(this.user, this.datasetService)
		.then((response: any) => {
			if (!response) return;
			const success = <boolean>response.httpResp.success;
			const dialog = response.dialog;
			if (success){
				this.auth.updateUserInfo();
				// subscribe on checked user observable
				this.auth.chekcUserStatus.subscribe(status => {
					if (status == CheckUserStatues.CHECKED) dialog.close();
				});
			}
		});
	}

	resetPassword(): void{
		action_edit_password(this.user, this.datasetService);
	}

	filterDomainDialog(): void{
		this.matDialog.open(SelectDomainDialogComponent, {
			data: {
				title: 'Seleziona dominio',
				selectedDomain: this.domainFilterService.filterDomainId.value,
			}
		}).afterClosed()
		.subscribe(domainId => {
			if (domainId) this.domainFilterService.setFilterDomain(domainId);
		});
	}
}
