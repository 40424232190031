import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('domain_template', {
	key: 'domain_template',
	name: 'Tipologia template pacchetti',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Completo',
			value: 0
		},
		{
			label: 'Semplificato',
			value: 1
		}
	]
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'template';
	}
});

export {fieldsMap as appTemplateFieldsMap};
