import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import chartColors from './../chart-colors';

@Component({
	selector   : 'revenue',
	templateUrl: './revenue.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class RevenueComponent extends DestroyableComponent implements OnInit, AfterViewInit{
	public revenue: ChartDataSets[];
	public barChartLabels: Label[];
	public showLoader: boolean;

	@ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;

	/**
	 * Chart Ticket Year
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		onResize(chart: Chart): void{
			chart.data.datasets.forEach((chartDataset: ChartDataSets) => {
				const bgColors: string[] = <Array<string>>chartDataset.hoverBackgroundColor;
				const gradients = [];
				for(let i = 0; i<bgColors.length; i++){
					const bgColor = bgColors[i];
					const gradient: CanvasGradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom/1.7, 0, chart.chartArea.bottom);
					gradient.addColorStop(0, bgColor);
					gradient.addColorStop(1, 'white');
					gradients.push(gradient);
				}
				chartDataset.backgroundColor = gradients;
			});
			/**
			 * when data or style changes update chart
			 */
			chart.update();
		},
		scales: {
			xAxes: [{}], 
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						callback: function (value): string{
							return Number(value).toLocaleString('US');
						}
					},
					gridLines: {
						display: false,
					}
				}
			]
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		}
	};
	public chartPlugins = [makeDrawValuePlugin()];
	public barChartType: ChartType = 'bar';
	public barChartLegend = false;

	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.revenue = [{data: []}];
	}

	ngAfterViewInit(): void{
		this.dashboardService.checkoutDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'checkout_date') return;
				if(response) this.onData(response);
			}
		});
		this.dashboardService.travelDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'departure_date') return;
				if(response) this.onData(response);
			}
		});
	}
	// -----------------------------------------------------------------------------------------------------
	
	public onData(response: any): void{
		if(!response){
			this.showLoader = true;
			return;
		}
		const result = [];
		for (const item of response) {
			if(!item.year) continue;
			if(!result[item.year]) result[item.year] = 0;
			result[item.year] += Number(item.total_amount);
		}
		
		const values = [];
		const label: Label[] = [];
		const colors = [];
		const gradients = [];
		const years = Object.keys(result);
		for (let y = 0; y < years.length; y++){
			const year = years[y];
			const colorIndex = years.length - y - 1;
			values.push(result[year]);
			label.push(String(year));
			colors.push(chartColors[colorIndex]);

			const chartArea = this.chartComponent.chart.chartArea;
			const gradient: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.7, 0, chartArea.bottom);
			gradient.addColorStop(0, chartColors[colorIndex]);
			gradient.addColorStop(1, 'white');
			gradients.push(gradient);
		}
		if(values.length === 0){
			this.showLoader = true;
			this.revenue = [{data: []}];
		} else {
			this.showLoader = false;
			this.revenue = [
				{
					data: values,
					backgroundColor: gradients,
					borderColor: colors,
					hoverBackgroundColor: colors
				}
			];
		}
		this.barChartLabels = label;
	}
}
