import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { PageClosureContainerService } from '../../page-closure-container.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { getBaseServerAddressRoot } from 'app/configs/app-config';


@Component({
	selector     : 'utilities-page',
	templateUrl  : './utilities.component.html',
	// styleUrls    : ['./site-commands.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [PageClosureContainerService]
})
export class UtilitiesPageComponent{

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected http: HttpClient,
		public pageClosureContainerService: PageClosureContainerService
	){}

	ngOnInit(): void{

		this.http.post(getBaseServerAddressRoot() + '/test/session', {
			key: 'key',
			value: 'test'
		}).subscribe(result => {
			this.http.get(getBaseServerAddressRoot() + '/test/session/key')
			.subscribe(getResult => {
				console.log('/test/session/key', getResult);
			});
			this.http.get(getBaseServerAddressRoot() + '/test/session/key')
			.subscribe(getResult => {
				console.log('/test/session/key', getResult);
			});
		});

	}

}
