import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AppService } from 'app/services/app.service';

@Component({
	selector     : 'content',
	templateUrl  : './content.component.html',
	styleUrls    : ['./content.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ContentComponent{

	/**
	 * Constructor
	 */
	constructor(
		private router: Router,
		public appService: AppService
	){}
}
