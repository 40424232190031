import { ComponentType, Overlay, OriginConnectionPosition, OverlayConnectionPosition, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector, TemplateRef, ElementRef } from '@angular/core';

import { PopoverConfig } from './popover-config';
import { PopoverRef } from './popover-ref';
import { PopoverComponent } from './popover-container/popover-container.component';

/**
 * Injection token that can be used to access the data that was passed in to a popover.
 * */
export const POPOVER_DATA = new InjectionToken('popover.data');

const defaultConfig: PopoverConfig = {
	hasBackdrop: true,
	backdropClass: '',
	disableClose: false,
	panelClass: '',
	arrowOffset: 30,
	arrowSize: 20
};

/**
 * Service to open modal and manage popovers.
 */
@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<D = any>(componentOrTemplate: ComponentType<any> | TemplateRef<any>,
	  target: ElementRef | HTMLElement,
	  config: Partial<PopoverConfig> = {}): PopoverRef<D> {
	const popoverConfig: PopoverConfig = Object.assign({}, defaultConfig, config);

	const arrowSize = popoverConfig.arrowSize;
	const arrowOffset = popoverConfig.arrowOffset;
	const panelOffset = arrowSize / 2;

	// preferred positions, in order of priority
	const positions: ConnectionPositionPair[] = [
	  // bottom left
		{
			overlayX: 'start',
			overlayY: 'top',
			originX: 'center',
			originY: 'bottom',
			panelClass: ['top', 'left'],
			offsetX: -1 * arrowOffset,
			offsetY: panelOffset
		}
	];

	const positionStrategy = this.overlay
		.position()
		.flexibleConnectedTo(target)
		.withPush(false)
		.withFlexibleDimensions(false)
		.withPositions(positions);

	const overlayRef = this.overlay.create({
		minWidth: 500,
		hasBackdrop: config.hasBackdrop,
		backdropClass: config.backdropClass,
		panelClass: config.panelClass,
		positionStrategy,
		scrollStrategy: this.overlay.scrollStrategies.reposition()
	});

	const popoverRef = new PopoverRef(overlayRef, positionStrategy, popoverConfig);

	const popover = overlayRef.attach(new ComponentPortal(
		PopoverComponent,
		null,
		new PortalInjector(
			this.injector,
			new WeakMap<any, any>([
				[PopoverRef, popoverRef]
			])
		)
	)).instance;

	if (componentOrTemplate instanceof TemplateRef) {
	  // rendering a provided template dynamically
	  popover.attachTemplatePortal(
		new TemplatePortal(
			componentOrTemplate,
			null,
			{
				$implicit: config.data,
				popover: popoverRef
			}
		)
	  );
	} else {
	  // rendering a provided component dynamically
		popover.attachComponentPortal(
			new ComponentPortal(
				componentOrTemplate,
				null,
				new PortalInjector(
					this.injector,
					new WeakMap<any, any>([
						[POPOVER_DATA, config.data],
						[PopoverRef, popoverRef]
					])
				)
			)
		);

	}

	return popoverRef;
  }
}
