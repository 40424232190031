import { Component, OnInit, OnDestroy, Input, Injector, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DatasetService } from '../dataset/services/dataset.service';
import { UploadFileDialogComponent, IUploadFileDialogDataImageOptions, IUploadFileDialogData } from '../dataset/dialogs/upload-file-dialog/dialog.component';
import { AppService } from 'app/services/app.service';
import { FieldHelper } from 'app/helpers';

@Component({
	selector   : 'document-file-block',
	templateUrl: './view.component.html',
	styleUrls  : ['./view.component.scss']
})
export class DocumentFileBlockComponent implements OnInit, OnDestroy, OnChanges{
	@Input() public record;
	@Input() public documentFileKey: string;
	@Input() public uploadRoute: string;
	@Input() public title: string;
	@Input() public type: string;
	@Input() public uploadType: string;
	@Input() public getUploadDataFun: (record) => any;
	@Input() public uploadData: any;
	@Input() public imageOptions: IUploadFileDialogDataImageOptions;
	@Input() public svgEnablded: boolean;
	@Input() public canEditParam: boolean;
	@Output() public changed = new EventEmitter<any>();
	@Input() public uploadLabel = 'Carica';

	public src: string;
	public canEdit: boolean;

	public documentFile;

	constructor(
		public appService: AppService,
		private datasetNavigatorService: DatasetNavigatorService,
		public datasetACS: DatasetActionContainerService,
		private datasetService: DatasetService,
		protected dialog: MatDialog,
	){}

	ngOnInit(): void {
		this.canEdit = this.datasetACS.canEdit(this.record);

		if (!this.datasetACS.canEdit(this.record) && this.canEditParam) {
			this.canEdit = true;
		}
	}

	ngOnDestroy(): void{}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.record){
			if(this.record){
				const fieldConfig = this.datasetACS.datasetConfig.fieldsMap.get(this.documentFileKey);
				if(fieldConfig){
					let value = FieldHelper.getFieldValue(fieldConfig, this.record, null, this.datasetACS);
					if(value && typeof(value) === 'object'){
						value = value.id;
					}
					if(value){
						this.src = this.appService.getBaseServerAddressRoot() + '/document_files/download/' + value;
					}
				}else{
					let documentFileId = this.record[this.documentFileKey];
					if(documentFileId && typeof(documentFileId) === 'object'){
						documentFileId = documentFileId.id;
					}
					if(documentFileId){
						this.src = this.appService.getBaseServerAddressRoot() + '/document_files/download/' + documentFileId;
					}
				}
			}else{
				this.documentFile = null;
				this.src = null;
			}
		}
	}

	openUpload(): void{
		const data: IUploadFileDialogData = {
			fileType: this.type,
			route: this.uploadRoute,
			imageOptions: this.imageOptions,
			svgEnabled: this.svgEnablded,
			postData: {}
		};
		if(this.getUploadDataFun){
			data.postData = this.getUploadDataFun(this.record);
		}else if(this.uploadData){
			data.postData = this.uploadData;
		}
		if(this.uploadType === 'standard'){
			data.route = '/dataset/document_files/command/upload';
			data.postData = {
				dataset_code: this.datasetACS.datasetCode,
				record_id: this.record.id,
				attribute: this.documentFileKey
			};
		}
		this.dialog.open(UploadFileDialogComponent, {
			data
		}).afterClosed().subscribe(result => {
			if(result) this.changed.emit(true);
		});
	}
}
