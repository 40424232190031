import * as tslib_1 from "tslib";
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { TripPackageSmartInterfaceMediaComponent } from '../media/step.component';
import { takeUntil } from 'rxjs/operators';
var ResellTripPackageSmartInterfaceMediaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResellTripPackageSmartInterfaceMediaComponent, _super);
    function ResellTripPackageSmartInterfaceMediaComponent(appService, parentDatasetRS, parentDatasetACS, reselledDatasetACS, originalDatasetACS, reselledDatasetRS, originalDatasetRS, reselledDatasetMCS, originalDatasetMCS, datasetNavigatorService, datasetService, stepService) {
        var _this = 
        /**
         * init parent constructor
         */
        _super.call(this, appService, reselledDatasetRS, reselledDatasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, stepService) || this;
        _this.appService = appService;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.reselledDatasetACS = reselledDatasetACS;
        _this.originalDatasetACS = originalDatasetACS;
        _this.reselledDatasetRS = reselledDatasetRS;
        _this.originalDatasetRS = originalDatasetRS;
        _this.reselledDatasetMCS = reselledDatasetMCS;
        _this.originalDatasetMCS = originalDatasetMCS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.stepService = stepService;
        _this.configs = {
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentForeignKey: 'trip_package_id',
            parentValueKey: 'trip_packages.recordId'
        };
        /**
         * carousel block configs
         */
        _this.carouselBlockConfigs = {
            datasetCode: 'trip_package_language_contents_carousel_images',
            parentDatasetCode: 'trip_package_language_contents',
            parentForeignKey: 'trip_package_language_content_id'
        };
        return _this;
    }
    ResellTripPackageSmartInterfaceMediaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stepService.setStepState({
            dataset_code: 'trip_packages',
            form_code: 'media',
            record_id: this.parentDatasetRS.recordId,
            status: 1
        });
        this.reselledDatasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.reselledDatasetRS,
            fetchData: true
        });
        this.originalDatasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'detail',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.originalDatasetRS,
            fetchData: true
        });
        this.originalDatasetRS.datasetACS = this.originalDatasetACS;
        this.reselledDatasetRS.datasetACS = this.reselledDatasetACS;
        this.reselledDatasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.reselledDatasetMCS.init({
                datasetACS: _this.reselledDatasetACS,
                datasetRS: _this.reselledDatasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'reselledData.id'
            });
            _this.reselledDatasetMCS.fetchRecord();
        });
        this.originalDatasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.originalDatasetMCS.init({
                datasetACS: _this.originalDatasetACS,
                datasetRS: _this.originalDatasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'trip_packages.recordId'
            });
            _this.originalDatasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record) {
                _this.reselledDatasetMCS.activeLanguageCodes = record.active_language_codes;
                _this.originalDatasetMCS.activeLanguageCodes = record.active_language_codes;
            }
        });
    };
    return ResellTripPackageSmartInterfaceMediaComponent;
}(TripPackageSmartInterfaceMediaComponent));
export { ResellTripPackageSmartInterfaceMediaComponent };
