import { FuseNavigation } from '@fuse/types';

export interface IMenuItem{
	group?: string;
	fuseNavigation: FuseNavigation;
	hiddenWithSmartInterface?: boolean;
	children?: IMenuItem[];
}

/**
 * Items are grouped by group code
 * If user have access to that group the menu item will added
 */
export const allMenuItems: IMenuItem[] = [
	{
		group: 'dashboard',
		fuseNavigation: {
			id       : 'home',
			title    : 'Dashboard',
			translate: 'NAV.HOME.TITLE',
			type     : 'item',
			icon     : 'cottage',
			url      : 'home',
		}
	},
	{
		group: 'reviews_group',
		fuseNavigation: {
			id       : 'reviews',
			title    : 'Recensioni',
			translate: 'NAV.REVIEWS.TITLE',
			type     : 'item',
			icon     : 'star_border',
			url      : 'dataset/list/reviews',
		}
	},
	{
		group: 'booking_group',
		fuseNavigation: {
			id       : 'bookings',
			title    : 'Prenotazioni',
			translate: 'NAV.BOOKINGS.TITLE',
			type     : 'collapsable',
			icon     : 'book',
			children: [
				{
					id       : 'new_booking',
					title    : 'Nuova Prenotazione',
					translate: 'NAV.BOOKINGS.NEWBOOKING.TITLE',
					type     : 'item',
					icon     : 'add',
					url      : 'new-booking',
				},
				{
					id       : 'booking_list',
					title    : 'Prenotazioni',
					translate: 'NAV.BOOKINGS.TITLE',
					type     : 'item',
					icon     : 'view_list',
					url      : 'dataset/list/trip_booking_report_views',
				}
			]
		},
	},
	{
		group: 'calendar',
		fuseNavigation: {
			id       : 'calendar',
			title    : 'Calendario',
			translate: 'NAV.CALENDAR.TITLE',
			type     : 'item',
			icon     : 'calendar_month',
			url      : 'calendar',
		}
	},
	{
		children: [
			{
				group: 'package_group',
				fuseNavigation: {
					id       : 'packages',
					title    : 'Pacchetti',
					translate: 'NAV.PACKAGES.TITLE',
					type     : 'item',
					icon     : 'redeem',
					url      : 'dataset/list/trip_packages',
				},
			},
			{
				group: 'product_group',
				fuseNavigation: {
					id       : 'products',
					title    : 'Prodotti',
					translate: 'NAV.PRODUCTS.TITLE',
					type     : 'item',
					icon     : 'add_box',
					url      : 'dataset/list/products',
				},
			},
			{
				group: 'resources_group',
				hiddenWithSmartInterface: true,
				fuseNavigation: {
					id       : 'resources',
					title    : 'Risorse',
					translate: 'NAV.RESOURCES.TITLE',
					type     : 'item',
					icon     : 'directions_bus',
					url      : 'dataset/list/trip_resources',
				},
			},
			{
				group: 'meeting_points_group',
				hiddenWithSmartInterface: true,
				fuseNavigation: {
					id       : 'meeting_points',
					title    : 'Punti di ritrovo',
					translate: 'NAV.MEETING_POINTS.TITLE',
					type     : 'item',
					icon     : 'place',
					url      : 'dataset/list/meeting_points',
				},
			},
			{
				group: 'destinations_group',
				fuseNavigation: {
					id       : 'trip_destinations',
					title    : 'Destinazioni',
					translate: 'NAV.DESTINATIONS.TITLE',
					type     : 'item',
					icon     : 'explore',
					url      : 'dataset/list/trip_destinations',
				},
			},
			{
				group: 'categories_group',
				fuseNavigation: {
					id       : 'trip_categories',
					title    : 'Categorie',
					translate: 'NAV.CATEGORIES.TITLE',
					type     : 'item',
					icon     : 'tour',
					url      : 'dataset/list/trip_categories',
				},
			},
			{
				group: 'facilities_group',
				fuseNavigation: {
					id       : 'trip_facilities',
					title    : 'Informazioni Utili',
					translate: 'NAV.INFO.TITLE',
					type     : 'item',
					icon     : 'info',
					url      : 'dataset/list/trip_facilities',
				},
			}
			/*{
				id       : 'trip_structures',
				title    : 'Strutture',
				type     : 'item',
				icon     : 'business',
				url      : 'dataset/list/trip_structures',
			}*/
		],
		group: 'package_group',
		fuseNavigation: {
			id       : 'definitions_id',
			title    : 'Definizioni',
			translate: 'NAV.DEFINITIONS.TITLE',
			type     : 'group',
			icon     : 'assignment_ind'
		}
	},
	{
		group: 'price_group',
		fuseNavigation: {
			id       : 'services_and_prices',
			title    : 'Servizi & Prezzi',
			translate: 'NAV.PRICES_SERVICES.TITLE',
			type     : 'collapsable',
			icon     : 'event_available'
		},
		children: [
			{
				fuseNavigation: {
					id       : 'trip_service_rules',
					title    : 'Criteri',
					translate: 'NAV.CRITERIA.TITLE',
					type     : 'item',
					url      : 'dataset/list/trip_service_rules',
				},
			},
			{
				fuseNavigation: {
					id       : 'services',
					title    : 'Servizi',
					translate: 'NAV.SERVICES.TITLE',
					type     : 'item',
					url      : 'dataset/list/services',
				},
			}
		]
	},
	{
		group: 'discount_group',
		fuseNavigation: {
			id       : 'discount_and_rules',
			title    : 'Sconti e Listini',
			translate: 'NAV.DISCOUNTS.TITLE',
			type     : 'collapsable',
			icon     : 'local_offer'
		},
		children: [
			{
				fuseNavigation: {
					id       : 'discount_rule_lists',
					title    : 'Listini Sconti',
					translate: 'NAV.PRICE_LISTS.TITLE',
					type     : 'item',
					url      : 'dataset/list/discount_rule_lists',
				}
			},
			{
				fuseNavigation: {
					id       : 'discounts',
					title    : 'Sconti',
					translate: 'NAV.DISCOUNTS.TITLE',
					type     : 'item',
					url      : 'dataset/list/discounts',
				}
			}
		]
	},
	{
		group: 'entity_group',
		fuseNavigation: {
			id       : 'anagrafiche',
			title    : 'Anagrafiche',
			translate: 'NAV.ENTITIES.TITLE',
			type     : 'collapsable',
			icon     : 'group',
			
		},
		children: [
			{
				fuseNavigation: {
					id       : 'entities',
					title    : 'Clienti',
					translate: 'NAV.ENTITIES.CUSTOMERS.TITLE',
					type     : 'item',
					icon     : 'person',
					url      : 'dataset/list/entities',
					queryParams: { entity_types: ['customer'] },
					exactMatch: true
				},
			},
			{
				fuseNavigation: {
					id       : 'entities',
					title    : 'Fornitori',
					translate: 'NAV.ENTITIES.SUPPLIERS.TITLE',
					type     : 'item',
					icon     : 'person',
					url      : 'dataset/list/entities',
					queryParams: { entity_types: ['supplier'] },
					exactMatch: true
				},
			},
			{
				fuseNavigation: {
					id       : 'entities',
					title    : 'Tour Operator',
					translate: 'NAV.ENTITIES.TOUR_OPERATORS.TITLE',
					type     : 'item',
					icon     : 'person',
					url      : 'dataset/list/entities',
					queryParams: { entity_types: ['tour_operator'] },
					exactMatch: true
				},
			},
			{
				fuseNavigation: {
					id       : 'entities',
					title    : 'Staff',
					translate: 'NAV.ENTITIES.STAFF.TITLE',
					type     : 'item',
					icon     : 'person',
					url      : 'dataset/list/entities',
					queryParams: { entity_types: ['staff_element'] },
					exactMatch: true
				},
			},
			{
				fuseNavigation: {
					id       : 'entities',
					title    : 'Tutte',
					translate: 'NAV.ENTITIES.ALL.TITLE',
					type     : 'item',
					icon     : 'person',
					url      : 'dataset/list/entities',
					exactMatch: true
				},
			}
		]
	},
	{
		group: 'documents',
		hiddenWithSmartInterface: true, // remove?
		fuseNavigation: {
			id       : 'documents',
			title    : 'Documenti',
			translate: 'NAV.DOCUMENTS.TITLE',
			type     : 'item',
			icon     : 'description',
			url      : 'documents',
		}
	},
	{
		group: 'dashboard_bi',
		//hiddenWithSmartInterface: true, // remove?
		fuseNavigation: {
			id       : 'bi-statistics',
			title    : 'Statistics',
			translate: 'NAV.STATISTICS.TITLE',
			type     : 'item',
			icon     : 'timeline',
			url      : 'bi-statistics',
		}
	},
	{
		group: 'marketplace',
		fuseNavigation: {
			id       : 'marketplaces',
			title    : 'Marketplace',
			translate: 'NAV.MARKETPLACE.TITLE',
			type     : 'collapsable',
			icon     : 'shop'
		},
		children: [
			{
				fuseNavigation: {
					id       : 'marketplace',
					title    : 'Tangerine',
					translate: 'NAV.MARKETPLACE.TANGERINE.TITLE',
					type     : 'item',
					url      : 'marketplace',
				}
			},
			{
				fuseNavigation: {
					id       : 'tourcms_marketplace',
					title    : 'TourCMS',
					translate: 'NAV.MARKETPLACE.TOURCMS.TITLE',
					type     : 'item',
					url      : 'tourcms_marketplace',
				}
			},
			{
				fuseNavigation: {
					id       : 'bokun_marketplace',
					title    : 'Bokun',
					translate: 'NAV.MARKETPLACE.BOKUN.TITLE',
					type     : 'item',
					url      : 'bokun_marketplace',
				}
			},
			{
				fuseNavigation: {
					id       : 'plafond',
					title    : 'Plafond',
					translate: 'NAV.PLAFOND.TITLE',
					type     : 'item',
					//icon     : 'money',
					url      : 'dataset/list/plafond',
				}
			},
			{
				fuseNavigation: {
					id       : 'ticketlandia',
					title    : 'Ticketlandia',
					translate: 'NAV.MARKETPLACE.TICKETLANDIA.TITLE',
					type     : 'item',
					//icon     : 'money',
					url      : 'ticketlandia_marketplace',
				}
			}
		]
	},
	{
		group: 'marketplace',
		fuseNavigation: {
			id: 'domain_packages',
			title: 'Richieste/Cessioni',
			translate: 'NAV.DOMAINS_GROUP.REQUESTS_TRANSFERS.TITLE',
			type: 'item',
			icon: 'add_shopping_cart',
			url: 'dataset/list/domain_packages',
		}
	},
	{
		group: 'domains_group',
		fuseNavigation: {
			id       : 'domains_group',
			title    : 'Gruppo Domini',
			translate: 'NAV.DOMAINS_GROUP.TITLE',
			type     : 'group',
			children: [
				{
					id       : 'domains',
					title    : 'Domini',
					translate: 'NAV.DOMAINS_GROUP.DOMAINS.TITLE',
					type     : 'item',
					icon     : 'public',
					url      : 'dataset/list/domains',
				},
				{
					id       : 'domain_users',
					title    : 'Utenti Domini',
					translate: 'NAV.DOMAINS_GROUP.USER_DOMAINS.TITLE',
					type     : 'item',
					icon     : 'group',
					url      : 'dataset/list/domain_users',
				},
				{
					id       : 'branches',
					title    : 'Filiali',
					translate: 'NAV.DOMAINS_GROUP.BRANCHES.TITLE',
					type     : 'item',
					icon     : 'assignment_ind',
					url      : 'dataset/list/branches',
				},
				{
					id       : 'payment_types',
					title    : 'Metodi di pagamento',
					translate: 'NAV.DOMAINS_GROUP.PAYMENTS_TYPE.TITLE',
					type     : 'item',
					icon     : 'payment',
					url      : 'dataset/list/payment_types',
				}
			]
		},
	},
	{
		group: 'server_rt_group',
		fuseNavigation : {
			id       : 'transmission_operations',
			title    : 'Trasmissioni corrispettivi',
			translate: 'NAV.ADMIN_GROUP.TRANSMISSIONS.TITLE',
			type     : 'item',
			icon     : 'format_list_bulleted',
			url      : 'dataset/list/transmission_operations'
		}
	},
	{
		group: 'admin_group',
		fuseNavigation: {
			id       : 'admin_group',
			title    : 'Super Admin',
			translate: 'NAV.ADMIN_GROUP.TITLE',
			type     : 'group'
		},
		children: [
			{
				fuseNavigation: {
					id       : 'users',
					title    : 'Utenti',
					translate: 'NAV.ADMIN_GROUP.USERS.TITLE',
					type     : 'item',
					icon     : 'group',
					url      : 'dataset/list/users',
				}
			},
			{
				fuseNavigation: {
					id       : 'domain_info',
					title    : 'Informazioni sui domini',
					translate: 'NAV.ADMIN_GROUP.DOMAIN_INFO.TITLE',
					type     : 'item',
					icon     : 'public',
					url      : 'dataset/list/domains_info',
				}
			},
			{
				fuseNavigation: { 
					id       : 'dashboard_totem',
					title    : 'Dashboard totem',
					translate: 'NAV.ADMIN_GROUP.DASHBOARD_TOTEM.TITLE',
					type     : 'item',
					icon     : 'tablet_android',
					url      : 'dashboard-totem',
				}
			},
			/*{
				fuseNavigation: {
					id       : 'turnover_reports',
					title    : 'Informazioni sui domini',
					translate: 'NAV.ADMIN_GROUP.TURNOVER_REPORT.TITLE',
					type     : 'item',
					icon     : 'euro_symbol',
					url      : 'dataset/list/turnover_reports',
				}
			},*/
			/*{
				fuseNavigation: {
					id       : 'user_roles',
					title    : 'Ruoli',
					type     : 'item',
					icon     : 'recent_actors',
					url      : 'dataset/list/user_roles'
				}
			},*/
			{
				group: 'super_admin_group',
				fuseNavigation : {
					id       : 'sale_operations',
					title    : 'Pratiche',
					translate: 'NAV.ADMIN_GROUP.PRACTICES.TITLE',
					type     : 'item',
					url      : 'dataset/list/sale_operations'
				}
			},
			{
				fuseNavigation: {
					id       : 'super_admin_utilities',
					title    : 'Utilities',
					translate: 'NAV.ADMIN_GROUP.UTILITIES.TITLE',
					type     : 'item',
					url      : 'superadmin/utilities'
				}
			},
			{
				fuseNavigation: {
					id       : 'passport',
					title    : 'Generazione credenziali API',
					translate: 'NAV.ADMIN_GROUP.API.TITLE',
					type     : 'item',
					url      : 'superadmin/passport'
				}
			},
			{
				fuseNavigation: {
					id       : 'super_admin_logs',
					title    : 'Logs',
					translate: 'NAV.ADMIN_GROUP.LOGS.TITLE',
					type     : 'item',
					url      : 'superadmin/logs'
				}
			}
		]
	}
];
