/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./participant-list-email-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../form-viewer/form-viewer.component.ngfactory";
import * as i9 from "../../../../../services/form-viewer.service";
import * as i10 from "../../../../../services/app.service";
import * as i11 from "@angular/forms";
import * as i12 from "../../../form-viewer/form-viewer.component";
import * as i13 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "@ngx-translate/core";
import * as i17 from "./participant-list-email-dialog.component";
import * as i18 from "../../services/dataset.service";
var styles_ParticipantListEmailDialogComponent = [i0.styles];
var RenderType_ParticipantListEmailDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParticipantListEmailDialogComponent, data: {} });
export { RenderType_ParticipantListEmailDialogComponent as RenderType_ParticipantListEmailDialogComponent };
function View_ParticipantListEmailDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-left: 30px;"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "20"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ParticipantListEmailDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "form-viewer", [], null, null, null, i8.View_FormViewerComponent_0, i8.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i9.FormViewerService, i9.FormViewerService, [i10.AppService, i11.FormBuilder, i1.NgZone]), i1.ɵdid(5, 770048, [[1, 4], ["formViewer", 4]], 0, i12.FormViewerComponent, [i9.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i14.MatButton, [i1.ElementRef, i15.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(10, 0, [" ", " "])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantListEmailDialogComponent_1)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formConfig; var currVal_1 = _co.datasetACS; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_4 = _co.loading; var currVal_5 = "primary"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_7 = _co.loading; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_6 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("invia")); _ck(_v, 10, 0, currVal_6); }); }
export function View_ParticipantListEmailDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-participant-list-email-dialog", [], null, null, null, View_ParticipantListEmailDialogComponent_0, RenderType_ParticipantListEmailDialogComponent)), i1.ɵdid(1, 114688, null, 0, i17.ParticipantListEmailDialogComponent, [i7.MAT_DIALOG_DATA, i7.MatDialogRef, i18.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParticipantListEmailDialogComponentNgFactory = i1.ɵccf("app-participant-list-email-dialog", i17.ParticipantListEmailDialogComponent, View_ParticipantListEmailDialogComponent_Host_0, {}, {}, []);
export { ParticipantListEmailDialogComponentNgFactory as ParticipantListEmailDialogComponentNgFactory };
