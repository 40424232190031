import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('duration_standard_in_hours'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('child_from'),
			fieldsMap.get('child_to'),
			fieldsMap.get('adult_to')
		]
	}
];

const config: IFormConfig = { 
	title: 'Pacchetto',
	fields: formFields 
};

export default config;
