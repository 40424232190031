import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef } from '@angular/material';
import { ATripBookingPackageRelatedComponent } from '../abstract-trip-booking-package-related-data.component';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
var TripBookingResourceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingResourceComponent, _super);
    function TripBookingResourceComponent(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, bookingService, matDialogRef, data) {
        var _this = _super.call(this, appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.bookingService = bookingService;
        _this.matDialogRef = matDialogRef;
        _this.data = data;
        return _this;
    }
    Object.defineProperty(TripBookingResourceComponent.prototype, "tripBookingPackages", {
        get: function () {
            var tripBookingPackages = [this.tripBookingPackage];
            if (this.tripBookingPackage.children_trip_booking_packages) {
                tripBookingPackages = tripBookingPackages.concat(this.tripBookingPackage.children_trip_booking_packages);
            }
            return tripBookingPackages;
        },
        enumerable: true,
        configurable: true
    });
    return TripBookingResourceComponent;
}(ATripBookingPackageRelatedComponent));
export { TripBookingResourceComponent };
