<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>Questa traduzione non è ancora presente!</p>

			<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
				<button mat-flat-button (click)='goPrevStep()'>Indietro</button>
			</div>
		</ng-container>

		<ng-container *ngIf='datasetRS.record.value && datasetRS.record.value["lang"] === appService.language.value'>
			<ng-container>

				<div class="instruction top-30">
					<p class="instruction">
						Carica qui la tua immagine di copertina.
					</p>
				</div>

				<span class="h2">Immagine di copertina</span>
				<document-file-block	
					[record]='datasetRS.record.value'
					[documentFileKey]='"master_image"'
					[imageOptions]='{minWidth: 1920, minHeight: 400, ratio: 1920 / 400}'
					[type]='"image"'
					[uploadRoute]='"/dataset/trip_package_language_contents/command/upload_master_image"'
					[uploadData]='{trip_package_language_content_id: datasetRS.record.value.id}'
					(changed)='datasetRS.reload()'
				></document-file-block>
			</ng-container>

			<ng-container>
				<div class="instruction top-30">
					<p class="instruction">
						Carica qui le tue immagini.
					</p>
				</div>

				<div class="examples">
					<h3 class="examples-title"><b>Examples</b></h3>
		
					<div class="good-examples">
						<div class="example">
							<span class="text"><i class="material-icons">check_circle</i> Usa foto ad alta risoluzione (file jpg) in formato orizzontale, dimensioni 1920 x 1080</span>
						</div>
		
						<div class="example">
							<span class="text"><i class="material-icons">check_circle</i> Includi immagini mirate per impostare le aspettative dei clienti e raccontare la storia</span>
						</div>
	
						<div class="example">
							<span class="text"><i class="material-icons">check_circle</i> Carica le foto originali e senza violazione del copyright</span>
						</div>
					</div>
		
					<div class="bad-examples">
						<div class="example">
							<span class="text"><i class="material-icons">cancel</i> Includi volti riconoscibili senza moduli di rilascio del modello</span>
						</div>
	
						<div class="example">
							<span class="text"><i class="material-icons">cancel</i> Usa le foto con altri loghi, filigrane di fotografo, targhe leggibili</span>
						</div>

						<div class="example">
							<span class="text"><i class="material-icons">cancel</i> Carica schermate di qualsiasi tipo o foto di mappe stampate</span>
						</div>
					</div>
				</div>

				<span class="h2">Immagini</span>
				<carousel-block
					[showTitle]='false'
					[parentDatasetACS]='datasetACS'
					[parentDatasetRS]='datasetRS'
					[configs]='carouselBlockConfigs'
				></carousel-block>
			</ng-container>
		</ng-container>
	</div>
</div>