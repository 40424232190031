export const locale = {
	lang: 'en',
	data: {
		'Immagini': 'Images',
		'Carica più immagini per migliorare la visibilità': 'Upload more images to improve visibility',
		'Caricamento in corso...': 'Loading...',
		'Trascina i file qui per caricarlo': 'Drop file here',
		'Sono supportati i file: .jpeg, png': 'Supproted formats: .jpeg, png',
		'Formato: 1920 x 1080': 'Format: 1920 x 1080',
		'Cerca nel computer': 'Serach in pc',
		'Elimina immagine': 'Delete image',
	}
};
