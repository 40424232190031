import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'app/services/app.service';
import { FieldHelper } from 'app/helpers';
import { IUploadFileDialogData, IUploadFileDialogDataImageOptions, UploadFileDialogComponent } from 'app/main/components/dataset/dialogs/upload-file-dialog/dialog.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'file-block-input',
	templateUrl: './input.component.html',
	styleUrls  : ['./input.component.scss']
})
export class FileBlockInputComponent implements OnInit, OnDestroy, OnChanges{
	@Input() public record: any;
	@Input() public documentFileKey: string;
	@Input() public uploadRoute: string;
	@Input() public title: string;
	@Input() public type: string;
	@Input() public uploadType: string;
	@Input() public getUploadDataFun: (record) => any;
	@Input() public uploadData: any;
	@Input() public imageOptions: IUploadFileDialogDataImageOptions;
	@Input() public svgEnablded: boolean;
	@Output() public changed = new EventEmitter<any>();
	@Input() public uploadLabel = 'Carica';
	@Input() public datasetCode: string;
	@Input() public inputService: FormInputViewerService;

	public _unsubscribeAll: Subject<any>;

	public newRecord: BehaviorSubject<any>;

	public src: string;

	public documentFile;

	constructor(
		public appService: AppService,
		protected dialog: MatDialog){
			this.newRecord = new BehaviorSubject<any>(null);
			this._unsubscribeAll = new Subject<any>();
		}

	ngOnInit(): void {
		this.newRecord
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(newRec => {
			this.record = newRec;
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.record) this.displayImage();
	}

	displayImage(record?: any): void{
		if (record) this.record = record;
		if(this.record){
			const fieldConfig = this.inputService.formInputDefinition;
			if(fieldConfig){
				const value = FieldHelper.getFieldValue(fieldConfig, this.record);
				if(!value) return;
				this.src = this.appService.getBaseServerAddressRoot() + '/document_files/download/' + value;
			}else{
				let documentFileId = this.record[this.documentFileKey];
				if(documentFileId && typeof(documentFileId) === 'object'){
					documentFileId = documentFileId.id;
				}
				if(documentFileId){
					this.src = this.appService.getBaseServerAddressRoot() + '/document_files/download/' + documentFileId;
				}
			}
		}else{
			this.documentFile = null;
			this.src = null;
		}
	}

	openUpload(): void{
		const data: IUploadFileDialogData = {
			fileType: this.type,
			route: this.uploadRoute,
			imageOptions: this.imageOptions,
			svgEnabled: this.svgEnablded,
			postData: {}
		};
		if(this.getUploadDataFun){
			data.postData = this.getUploadDataFun(this.record);
		}else if(this.uploadData){
			data.postData = this.uploadData;
		}
		if(this.uploadType === 'standard'){
			data.route = '/dataset/document_files/command/upload';
			data.postData = {
				dataset_code: this.datasetCode,
				record_id: this.record.id,
				attribute: this.documentFileKey
			};
		}
		this.dialog.open(UploadFileDialogComponent, {data})
		.afterClosed()
		.subscribe(result => {
			if(result){
				this.changed.emit(result);
				this.displayImage(result);
			}
		});
	}
}
