import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var FuseTranslationLoaderService = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     */
    function FuseTranslationLoaderService(_translateService) {
        this._translateService = _translateService;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Load translations
     *
     * @param {Locale} args
     */
    FuseTranslationLoaderService.prototype.loadTranslations = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var locales = tslib_1.__spread(args);
        locales.forEach(function (locale) {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            _this._translateService.setTranslation(locale.lang, locale.data, true);
        });
    };
    FuseTranslationLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseTranslationLoaderService_Factory() { return new FuseTranslationLoaderService(i0.ɵɵinject(i1.TranslateService)); }, token: FuseTranslationLoaderService, providedIn: "root" });
    return FuseTranslationLoaderService;
}());
export { FuseTranslationLoaderService };
