import * as tslib_1 from "tslib";
import plafondForm from "app/configs/datasets/domain_packages/forms/plafond.form";
import plafondCreationForm from 'app/configs/datasets/plafond/forms/general.form';
import { DatasetCreateDialogComponent } from "../components/dataset/dialogs/core-actions/create/dataset-create-dialog.component";
import { FormDialogComponent } from "../dialogs/form-dialog/dialog.component";
var subscription;
export default function requirePlafondData(record, dataContainerService, datasetNavigator, form, datasetExtraData) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var data, dialogRef;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = {
                        title: 'Plafond',
                        dataContainerService: dataContainerService,
                        formConfig: plafondForm
                    };
                    // set form config
                    if (form)
                        data.formConfig = form;
                    // validation
                    if (record) {
                        data.formData = record;
                    }
                    else
                        data.formErrors = { plafond_id: ['Non valido'] };
                    dialogRef = datasetNavigator.openDialog(FormDialogComponent, data);
                    if (subscription)
                        subscription.unsubscribe();
                    subscription = dataContainerService.appService.eventEmitter
                        .subscribe(function (event) {
                        if (event.name === 'clickOnSupportCreation_plafond_id') {
                            datasetNavigator.openDialog(DatasetCreateDialogComponent, {
                                datasetCode: 'plafond',
                                formConfig: plafondCreationForm,
                                datasetData: datasetExtraData
                            })
                                .beforeClosed()
                                .subscribe({
                                next: function (response) {
                                    dialogRef.close(response);
                                },
                                error: function (response) {
                                    dialogRef.close();
                                }
                            });
                        }
                    });
                    return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
