<ng-template #trainNameTemplate let-train="train" let-last="last">
	<ng-container *ngIf="train.type == 'multi' && train.subSegments">
		<ng-container
			*ngFor="let t of train.subSegments;let last = last;"
			[ngTemplateOutlet]='trainNameTemplate'
			[ngTemplateOutletContext]='{last:last, train:t}'
		></ng-container>
	</ng-container>
	<ng-container *ngIf="train.type != 'multi'">
		<div class="train-name-container">
			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<img *ngIf="trainTypeImages[train.type]" [matTooltip]="train.type" [src]="trainTypeImages[train.type]" class="train-icon"/>
				<small class="train-title">{{train.name}}</small>
			</div>
			<!--<span class="train-subtitle">{{subTitle(train)}}</span>-->
		</div>
	</ng-container>
	<div *ngIf="!last" class="train-separator"> + </div>
</ng-template>

<div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between start'>
	<h1 style="margin:0">Cambio classe</h1>
	<button mat-icon-button aria-label="close dialog" mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</div>
<div><mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"
					   *ngIf='load'></mat-progress-bar></div>
<mat-dialog-content>


	<div *ngFor="let train of solution.travelSolutions[0].trains">
		<div fxLayout="row wrap" fxLayoutAlign="start center" style="gap:8px;margin-top:20px">
			<ng-container
				[ngTemplateOutlet]='trainNameTemplate'
				[ngTemplateOutletContext]='{last:true, train:train}'
			></ng-container>
		</div>
		<h3>Seleziona servizio e offerta</h3>
	<trenitalia-other-solution [solution]="solution"
                               [train]="train"
                               [errors]="errors[train.id]"
							   (custom-offer-select-price)="getEvent($event)"></trenitalia-other-solution>
	</div>
	<!--<trenitalia-prices [travel-solution]="solution" (select-price)="getEvent($event)"></trenitalia-prices>-->
</mat-dialog-content>
<mat-dialog-actions align="end">
	<span class="tot">Totale: {{total | currency:'EUR'}}</span>
	<button mat-raised-button style="margin-left: 8px" (click)="updateTravel()">Aggiorna</button>
</mat-dialog-actions>