import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('total_amount'),
	fieldsMap.get('threshold'),
	fieldsMap.get('reseller_notification_emails')
];

const config: IFormConfig = {
	title: 'Crea Plafond',
	editTitle: 'Modifica Plafond',
	fields: formFields 
};

export default config;
