import {InputTypes, ValueTypes, IFieldDefinition} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione Categoria',
	valueType: ValueTypes.STRING
});

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice Categoria',
	valueType: ValueTypes.STRING
});

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Abilitato',
	valueType: ValueTypes.BOOLEAN,
});

export {fieldsMap};
