import { Component, Input, QueryList, ViewChildren, OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, IDatasetEvent, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'product-advanced-blocks-step',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
})
export class ProductAdvancedBlockStepComponent extends BaseStepViewComponent implements OnInit{
	
	enableBlocks: any[];
	blockFilters: any[];
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;
	@ViewChildren('listBlocks') listBlocks: QueryList<DatasetListComponent>;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe( record => {
			this.blockFilters = [];
			this.enableBlocks = [];
			for (const blockConfig of this.stepConfig.options.blocks) {
				let blockFilter = {};
				if(blockConfig.filters){
					blockFilter = blockConfig.filters(this, this.datasetRS.record.value);
				}
				this.blockFilters.push(blockFilter);

				if(blockConfig.showIf){
					this.enableBlocks.push(blockConfig.showIf(record));
				}else{
					this.enableBlocks.push(true);
				}
			}
		});
	}

	onCreatedInBlock(blockConfig: any): void{
		if(blockConfig.updateParent){
			this.datasetRS.reload()
			.subscribe(() => {
				const block = this.listBlocks.find( elementRef => {
					return elementRef.datasetCode === blockConfig.datasetCode;
				});
				if(block) block.reload();
			});
		}
	}

	onDatasetEvent(event: IDatasetEvent): void{
		if(event.eventName === DatasetEvents.CREATED) return;
		this.datasetRS.reload();
	}
}
