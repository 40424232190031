import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators, FormGroup } from '@angular/forms';
import { fieldsMap as meetingFieldMap} from '../meeting_points/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import pickupPoint from 'app/configs/commons/shared-field-configs/pickup-point.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('pickup_point', cloneFieldDefinition(pickupPoint, { 
	inputType: InputTypes.DATASET_AUTOCOMPLETE
}));

fieldsMap.set('description', cloneFieldDefinition(meetingFieldMap.get('description'), {key: 'meeting_point.description'}));
fieldsMap.set('code', cloneFieldDefinition(meetingFieldMap.get('code'), {key: 'meeting_point.code'}));


fieldsMap.set('stock_availability', {
	key: 'stock_availability',
	name: 'Ticket Disponibili',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const ticketing = dataContainerService.getValueFromKeyPath('domainModules').ticketing;
		if(ticketing && ticketing.enabled){
			return ticketing.enabled;
		}
		return false;
	},
	getDisplayValue(record: any): string{
		console.log(record);
		if(!record) return '';
		return record.stock_availability;
	}
});

export {fieldsMap};
