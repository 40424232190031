<input type="hidden" id="selNum" value="0">
<h2 id="title-select-seat" #top>Scelta posti</h2>
<section id="recap" fxLayout="row" fxLayoutGap="2%">
	<ng-container *ngIf="trainSeat.transportUnits && trainSeat.transportUnits[0]!=null">
		<div fxFlex="50">
			<div *ngFor="let seats of trainSeat.transportUnits" class="wagon-container text-center">
				<ng-container *ngIf="seats">
					<h3>Vagone {{seats.seatsAvailability.wagonId}}</h3>
					<iframe id="wagon-{{seats.seatsAvailability.wagonId}}" [srcdoc]="seats.layout | safe_content"
							frameborder="0"
							on-load="markSeleableSeats(seats.seatsAvailability.wagonId)"
							(mouseover)="selWagon(seats.seatsAvailability.wagonId)"></iframe>
				</ng-container>
			</div>
		</div>


		<div fxFlex="50">
			<mat-card>
				<mat-card-header>
					<mat-card-title>{{trainInfo.type}}
						{{trainInfo.name}} </mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div *ngFor="let p of travellers | keyvalue">
						<h3><strong>Passeggero {{+p.key + 1}}
							<span>{{p.value.type == 'ADULT' ? 'ADT' : 'CHD'}}</span></strong>
						</h3>
						<h4>{{selectedOffers[p.value.xmlId][trainInfo.id].offer.name}} - {{selectedOffers[p.value.xmlId][trainInfo.id].service.name}}</h4>

						<div>Costo Selezione posto:
							<span *ngIf="trainSeat.travellers[p.value.xmlId].seat_is_free" class="free">Gratis</span>
							<span *ngIf="!trainSeat.travellers[p.value.xmlId].seat_is_free && trainSeat.travellers[p.value.xmlId].prices">{{trainSeat.travellers[p.value.xmlId].prices.amount}} €</span>
						</div>

						<div *ngIf="trainSeat.travellers[p.value.xmlId].seat" fxLayout="row" fxLayoutGap="8px">
														<span matTooltip="Vagone" class="train-ticket-seat">
															<mat-icon>train</mat-icon>
															{{trainSeat.travellers[p.value.xmlId].seat.wagon}}
														</span>
							<span matTooltip="Posto" class="train-ticket-seat">
															<mat-icon>event_seat</mat-icon>
								{{trainSeat.travellers[p.value.xmlId].seat.seatNumToShow}}
														</span>
						</div>

						<!--<div>Posto:
							<span *ngIf="trainSeat.travellers[p.value.xmlId].seat">
								<strong>{{trainSeat.travellers[p.value.xmlId].seat.seatNumToShow}} - </strong>
							</span>
						</div>
						<div>
							Vagone:
							<span *ngIf="trainSeat.travellers[p.value.xmlId].seat">
								<strong>{{trainSeat.travellers[p.value.xmlId].seat.wagon}}</strong>
							</span>
						</div>-->
					</div>
				</mat-card-content>
			</mat-card>
		</div>

	</ng-container>
    <div fxFlex="50" *ngIf="!trainSeat.transportUnits || trainSeat.transportUnits[0]==null">
        <div class="alert-message"> Selezione posti non possibile per questo treno</div>
    </div>
</section>
<section>

</section>


