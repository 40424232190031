export const locale = {
	lang: 'en',
	data: {
		'Entrate per data di viaggio': 'Revenue by Travel Date',
		'Ricavi fornitori per mese di viaggio': 'Supplier Revenue by Travel Month',
		'Entrate per data di checkout': 'Revenue by Checkout Date',
		'Ricavi per mese di checkout': 'Revenue by Checkout Month',
		'Tickets': 'Biglietti',
		'Biglietti per mese di viaggio': 'Tickets by Travel Month',
		'Biglietti per anno di viaggio': 'Tickets by Travel Year',
		'Reddito': 'Revenue',
		'Ricavi per anno di viaggio': 'Revenue by Travel Year',
		'Finestra di prenotazione': 'Booking Window',
		// tslint:disable-next-line: max-line-length
		'Giorni dalla prenotazione alla data di conduzione (inferiore a 1 significa prenotazione lo stesso giorno)': 'Days from Booking to Conduction Date (Below 1 means same day booking)',
		'Mercati di origine': 'Source Markets',
		'Primi 10 mercati di origine per entrate (per data di checkout)': 'Top 10 Source Markets Revenue (by Checkout Date)',
		'Recensioni': 'Reviews',
		'Valutazione media e di recensioni negative per mese di revisione': 'Average rating and Negative Reviews by review Month',
		'Valutazione media e recensioni negaive totali per anno di revisione': 'Average Rating and Total Negative Reviews by Review Year',
		'Partenze Dal': 'Start from',
		'Partenze Fino Al': 'Start to',
		'Prenotazioni Dal': 'Bookings from',
		'Prenotazioni Fino Al': 'Bookings to',
		'Filtro Pacchetti...': 'Filter by package...',
		'Filtro Categoria...': 'Filter by category...',
		'Filtro Clienti...': 'Filter by customer...',
		'Filiale': 'Branch'
	}
};
