import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormViewerModule } from 'app/main/components/form-viewer/form-viewer.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { FormDialogsModule } from 'app/main/dialogs/form-dialog/dialog.module';
import { MarketplaceComponent } from './marketplace.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
		MarketplaceComponent
	],
	imports: [
		MatFormFieldModule,
		MatButtonModule,
		MatIconModule,
		MatStepperModule,
		MatProgressBarModule,
		FormViewerModule,
		FuseSharedModule,
		FuseSidebarModule,
		MatTabsModule,
		MatTableModule,
		MatListModule,
		RouterModule,
		DatasetModule,
		MatToolbarModule,
		MatCardModule,
		MatTooltipModule,
		MatBadgeModule,
		MatChipsModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		FormDialogsModule,
		FlexLayoutModule,
		TranslateModule.forChild(),
	],
	exports     : [
		MarketplaceComponent
	]
})
export class MarketPlaceModule{}
