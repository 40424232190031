import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observer, Observable, Subscription } from 'rxjs';
import { IPaginationResponse, IAppEvent } from 'app/interfaces';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'dataset-autocomplete-input',
	templateUrl: './input.component.html',
	animations : fuseAnimations
})
export class DatasetAutocompleteInputComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public filteredOptions: Subject<any>;
	public optionSelected: any;
	public loading = true;
	protected filterOptionsSubscription: Subscription;
	protected initRemoteValueSubscription: Subscription;
	public textSearch: string;
	public supportCreate = false;
	public clearOnSelection = false;
	public creatingNew = false;
	public displayValue = '';
	@ViewChild(MatAutocomplete, { static: false }) matAutocomplete: MatAutocomplete;

	public inputFormControl = new FormControl();

	constructor(
		protected datasetService: DatasetService,
		public appService: AppService,
		public inputService: FormInputViewerService
	){
		super();
		this.filteredOptions = new Subject();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;

			this.optionSelected = this.inputService.formInputDefinition.optionSelected;
			if(this.optionSelected && this.inputService.formInputDefinition.remoteOptions){
				const value = this.inputService.formInputDefinition.remoteOptions.getValue(this.optionSelected);
				this.inputService.control.setValue(value);
			}
			if(this.optionSelected){
				this.displayValue = this.displayFun(this.optionSelected);
			}

			if(this.inputService.formInputDefinition.remoteOptions){
				this.supportCreate = Boolean(this.inputService.formInputDefinition.remoteOptions.supportCreate);
				this.clearOnSelection = Boolean(this.inputService.formInputDefinition.remoteOptions.clearOnSelection);
			}

			this.fetchFilteredOptions('');
			this.initRemoteValue();

			this.inputService.control
			.statusChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(status => {
				if(status === 'INVALID'){
					this.inputFormControl.setErrors({invalid: this.inputService.errorMessage});
				}else{
					this.inputFormControl.setErrors(null);
				}
				this.inputFormControl.markAsTouched();
			});

			this.inputService.control.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(value => {
				const currentValue = this.inputService.control.value;
				if(currentValue){
					if(!this.optionSelected || currentValue !== this.inputService.formInputDefinition.remoteOptions.getValue(this.optionSelected)){
						let reason = 'changed';
						if(!this.optionSelected){
							reason = 'init';
						}
						this.initRemoteValue(true)
						.subscribe({
							next: result => {
								this.inputService.onSet(this.optionSelected, reason);
							}
						});
						return;
					}
				}
			});
		});
		
	}

	initRemoteValue(withLoading?: boolean): Observable<any> {
		const resultSubject = new Subject<any>();
		const control = this.inputService.control;
		if(!control){
			resultSubject.complete();
			return resultSubject;
		}
		const currentValue = control.value;
		if(!currentValue){
			resultSubject.complete();
			return resultSubject;
		}
		if(this.initRemoteValueSubscription){
			this.initRemoteValueSubscription.unsubscribe();
		}
		let params = {};
		if(this.inputService.formInputDefinition.remoteOptions && typeof(this.inputService.formInputDefinition.remoteOptions.getParams) === 'function'){
			params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue(), 'init');
		}
		params['perPage'] = 1;
		if(!params['column_value']) params['column_value'] = currentValue;
		if(!params['column_name']) params['column_name'] = 'id';
		if(!params['sortBy']) params['sortBy'] = 'created_at';

		if(withLoading) this.loading = true;
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.initRemoteValueSubscription = this.datasetService.get<IPaginationResponse<any>>(this.getListRoute(), params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				if(result.items && result.items.length > 0){
					const record = result.items[0];
					this.optionSelected = record;
					this.displayValue = this.displayFun(this.optionSelected);
					this.creatingNew = false;
					resultSubject.next(record);
					resultSubject.complete();
					if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.onInit){
						this.inputService.formInputDefinition.extra.onInit(this.inputService.formGroup, this.optionSelected);
					}
				}else{
					resultSubject.error(result);
					resultSubject.complete();
				}
				if(withLoading) this.loading = false;
			},
			error: response => {
				resultSubject.error(response);
				resultSubject.complete();
				if(withLoading) this.loading = false;
			}
		});

		return resultSubject;
	}

	getListRoute(): string{
		let route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
		if(this.inputService.formInputDefinition.remoteOptions.getPivotConfig){
			const pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
			const parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
			route = '/pivot/' + pivotConfig.pivotCode  + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
		}
		return route;
	}

	fetchFilteredOptions(textSearch): void{
		if(!this.inputService.formInputDefinition.remoteOptions){
			console.warn('remoteOptions not setted in fieldDefinition', this.inputService.formInputDefinition);
			return;
		}
		if(this.filterOptionsSubscription) this.filterOptionsSubscription.unsubscribe();
		let params = {
			'text-search': textSearch,
			perPage: this.inputService.formInputDefinition.remoteOptions.limit || 5
		};
		if(this.inputService.formInputDefinition.remoteOptions.getParams){
			params = Object.assign(params, this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue()));
		}
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		const route = this.getListRoute();
		this.textSearch = textSearch;
		this.loading = true;
		this.filterOptionsSubscription = this.datasetService.get<IPaginationResponse<any>>(route, params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				this.filteredOptions.next(result.items);
				this.loading = false;
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	getFormControlValue(): any{
		if(!this.inputService.control) return null;
		return this.inputService.control.value;
	}

	selectDatasetOption(option: any): void{
		this.creatingNew = false;
		if(option){
			if(typeof(option) === 'string' && this.supportCreate){
				this.optionSelected = option;
				this.inputService.control.setValue(option);
				this.creatingNew = true;
			}else if (typeof(option) !== 'string' && this.clearOnSelection) {
				const value = this.inputService.formInputDefinition.remoteOptions.getValue(option);
				this.inputService.control.setValue(value);
				this.inputFormControl.setValue(null);
				this.optionSelected = null;
				this.textSearch = null;
			}else if(typeof(option) !== 'string'){
				const value = this.inputService.formInputDefinition.remoteOptions.getValue(option);
				this.optionSelected = option;
				this.inputService.control.setValue(value);
			}else{
				this.inputService.control.setValue(null);
				this.optionSelected = null;
				this.inputFormControl.setValue(null);
			}
		}else{
			this.inputService.control.setValue(null);
			this.optionSelected = null;
		}
		this.displayValue = this.displayFun(this.optionSelected);
		this.inputService.onSet(this.optionSelected, 'selected');
	}

	displayFun(record: any): any{
		if(typeof(record) === 'string' && this.supportCreate) return record;
		if(!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions) return '---';
		if(this.inputService.formInputDefinition.remoteOptions.getDisplay){
			return this.inputService.formInputDefinition.remoteOptions.getDisplay(record);
		}else if(this.inputService.formInputDefinition.remoteOptions.getLabel){
			return this.inputService.formInputDefinition.remoteOptions.getLabel(record, this.appService);
		}
	}

	trackByOptions(index, item): number{
		return index;
	}

	onFocusOut(): void{
		if(this.optionSelected) return;
		if(this.matAutocomplete && this.matAutocomplete.isOpen){
			// this prevent to execute onFocusOut when click on autocomplete options 
			setTimeout(() => {
				this.onFocusOut();
			}, 100);
			return;
		}
		if(this.filterOptionsSubscription){
			this.loading = false;
			this.filterOptionsSubscription.unsubscribe();
			this.filterOptionsSubscription = null;
		}
		if(this.initRemoteValueSubscription){
			this.loading = false;
			this.initRemoteValueSubscription.unsubscribe();
			this.initRemoteValueSubscription = null;
		}
		if(!this.textSearch || this.textSearch.length < 1){
			this.selectDatasetOption(null);
		}else{
			this.selectDatasetOption(this.textSearch);
		}
	}

	emitEventOnSupportCreation(event: any): void{
		this.appService.eventEmitter.emit({
			name: 'clickOnSupportCreation_' + this.inputService.formInputDefinition.key,
			extra: {inputValue: this.textSearch}, 
			target: event
		});
		if(
			this.inputService.formInputDefinition.remoteOptions &&
			this.inputService.formInputDefinition.remoteOptions.clearInputOnCreation
		){
			this.textSearch = null;
			this.inputService.control.setValue(null);
			this.optionSelected = null;
			this.inputFormControl.setValue(null);
		}
	}
}
