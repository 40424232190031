import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { CommonFormStepComponent } from './step.component';
var CommonSubDatasetFormStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonSubDatasetFormStepComponent, _super);
    function CommonSubDatasetFormStepComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommonSubDatasetFormStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: this.parentDatasetACS.actionCode,
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true,
            useBeforeCalls: false
        }).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (!record)
                return;
            _this.datasetRS.recordId = _this.stepConfig.options.getSubDatasetRecordId(record);
            _this.datasetRS.reload();
        });
        _super.prototype.ngOnInit.call(this);
    };
    return CommonSubDatasetFormStepComponent;
}(CommonFormStepComponent));
export { CommonSubDatasetFormStepComponent };
