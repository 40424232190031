import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('to_ref')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
