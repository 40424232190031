import 'hammerjs';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { WebSocketService } from './services/websocket.service';
import { appConfig } from './configs/app-config';
import { AppService } from './services/app.service';
import { getItaPaginatorIntl } from './i18n/ita-paginator-intl';
//import Bugsnag from '@bugsnag/js';
//import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
// the second parameter 'it' is optional
registerLocaleData(localeIt, 'it');
/**
 * Initialize application
 * @param appService
 * @param websocket
 */
export function startupServiceFactory(appService, websocket) {
    return function () {
        appService.init();
        websocket.isAuthenticated.subscribe(function (isAuth) {
            if (!isAuth) {
                websocket.clear();
            }
            else {
                websocket.init();
            }
        });
    };
}
// create a factory which will return the Bugsnag error handler
/*export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}*/
export var GOOGLE_API_KEY = 'AIzaSyBvkK6SN669qyITSX4zFJLdC-cXV2fSEd0';
var ɵ0 = function () {
    var basePath = null;
    if (location.pathname.startsWith('/app'))
        basePath = '/app/';
    else if (location.pathname.startsWith('/s3app'))
        basePath = '/s3app/';
    if (!basePath)
        return location.origin;
    return location.origin + basePath;
}, ɵ1 = { useUtc: true }, ɵ2 = appConfig, ɵ3 = getItaPaginatorIntl();
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
