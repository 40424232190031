import {
	Component,
	OnInit,
	ViewChild,
	OnDestroy,
	ElementRef,
	HostListener,
	AfterViewInit
} from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { catchError, exhaustMap, map, takeUntil, tap } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IStepConfig, InputTypes, ValueTypes } from 'app/interfaces';
import { Subject, of } from 'rxjs';
import { BookingService } from './booking.service';
import { PageClosureContainerService } from '../page-closure-container.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import sendVoucherAction from 'app/main/components/dataset/dialogs/voucher-email-dialog/voucher.email';
import * as moment from 'moment';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationItaliano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import {
	animate,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';
import { ConfirmWithReasonDialogComponent } from 'app/main/dialogs/confirm-with-reason-dialog/dialog.component';
import {
	IDatasetListDialogData,
	DatasetListDialogComponent
} from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import { BOOKING_SERVICE_TOKEN } from 'app/configs/commons/provide-tokens';

export const RELOAD_BOOKING = 'reload-booking';
export const RELOAD_BOOKING_PACKAGES = 'reload-booking-packages';
export const RELOAD_BOOKING_PACKAGE = 'reload-booking-package';

@Component({
	selector: 'edit-booking-page',
	templateUrl: './edit-booking.component.html',
	styleUrls: ['./edit-booking.component.scss'],
	animations: [
		trigger('grow', [
			state(
				'true',
				style({
					display: 'flex',
					height: '{{bookingReviewHeight}}px',
					bottom: '{{bottom}}px'
				}),
				{
					params: {
						bottomBarHeight: '67px',
						bottom: 0,
						bookingReviewHeight: 0
					}
				}
			),
			state('false', style({ height: 0 })),
			transition('false <=> true', animate(500))
		])
	],
	providers: [
		DatasetRecordService,
		DatasetActionContainerService,
		BookingService,
		{useExisting: BookingService, provide: BOOKING_SERVICE_TOKEN}, // alias
		PageClosureContainerService
	]
})
export class EditBookingPageComponent implements OnInit, OnDestroy {
	bottomBarState: string;
	bottom: number;
	bookingReviewHeight: number;
	hasPackages: boolean;

	public initStep = false;
	public stepIndex = 0;
	public isStepLinear = true;
	@ViewChild('mobileHeader', { static: false }) public mobileHeader: ElementRef<any>;
	@ViewChild('bottomFixedBar', { static: false }) public bottomFixedBar: ElementRef<any>;
	@ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

	public loading = false;
	public steps: IStepConfig[];
	protected _unsubscribeAll: Subject<any>;
	public now = moment();
	public channelValue: string = '';
	public creatorName: any = '';
	public enabledNextBookingStep = true;
	public fees:any;
	public confirmTooltip = '';

	get record(): any {
		return this.datasetRS.record.value;
	}

	get isDraft(): boolean {
		return this.record && this.record.status === 'draft';
	}

	get deadlineDate(): moment.Moment {
		if (
			!this.record ||
			!this.record.properties.optional_deadline ||
			!this.record.properties.optional_deadline.value
		) {
			return;
		}
		return moment(this.record.properties.optional_deadline.value);
	}

	get canConfirm(): boolean {
		return (
			this.record &&
			this.record.status != 'confirmed' &&
			this.record.packages &&
			this.record.packages.length > 0 &&
			this.record.entity &&
			this.record.customer &&
			this.record.participant_count > 0
		);
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected _fuseSidebarService: FuseSidebarService,
		protected router: Router,
		protected route: ActivatedRoute,
		protected location: Location,
		public bookingService: BookingService,
		protected matDialog: MatDialog,
		protected domainFilterService: DomainFilterService,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService
	) {
		// traduzioni
		this._fuseTranslationLoaderService.loadTranslations(
			navigationEnglish,
			navigationItaliano
		);
		this._unsubscribeAll = new Subject();
	}

	/**
	 * Update scrollbars on window resize
	 *
	 * @private
	 */
	@HostListener('window:resize', ['$event'])
	_updateOnResize(event: any): void {
		if (
			event.target.innerWidth > 959 &&
			this.appService.layoutFusePerefctScrollBar
		) {
			this.bottomBarState = 'false';
			this.appService.layoutFusePerefctScrollBar._init();
			this.appService.layoutFusePerefctScrollBar.update();
		}
		if (this.stepIndex == 2) {
			const pageLayout = document.querySelector('.booking-page-content');
			if (event.target.innerWidth > 959) {
				pageLayout.classList.add('p-16');
			} else {
				pageLayout.classList.remove('p-16');
			}
		}
	}

	ngOnInit(): void {
		if (
			!this.domainFilterService.filterDomainId.value &&
			this.route.snapshot.data.domainId
		) {
			this.domainFilterService.setFilterDomain(
				this.route.snapshot.data.domainId
			);
		}

		this.bottomBarState = 'false';

		this.now = moment();
		this.datasetACS
		.init({
			datasetCode: 'trip_bookings',
			actionCode: 'edit',
			fetchData: true,
			datasetRS: this.datasetRS
		})
		.subscribe(ready => {
			if (!ready) return;
			this.datasetRS.recordId = this.route.snapshot.paramMap.get('id') || this.route.snapshot.queryParamMap.get('id');
			if (this.datasetRS.recordId) {
				this.bookingService.useLastDraft = this.route.snapshot.queryParamMap.get('useLastDraft') === '1';
				this.datasetRS.reload().subscribe({
					error: () => {
						this.router.navigate(['new-booking']);
					}
				});
			} else {
				// search last draft is no exists, create new draft
				this.datasetACS.loading.next(true);
				this.bookingService
				.loadLastDraft()
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe({
					next: lastTripBooking => {
						if (lastTripBooking) {
							this.bookingService.useLastDraft = true;
							// this.datasetRS.record.next(lastTripBooking);
							this.datasetACS.loading.next(false);
							this.router.navigate(
								['edit-booking', lastTripBooking.id],
								{
									queryParams: {
										useLastDraft: '1'
									}
								}
							);
						} else {
							this.bookingService
							.newDraftBooking()
							.pipe(takeUntil(this._unsubscribeAll))
							.subscribe(newDraftTripBooking => {
								this.bookingService.useLastDraft = false;
								// this.datasetRS.record.next(newDraftTripBooking);
								this.datasetACS.loading.next(false);
								this.router.navigate([
									'edit-booking',
									newDraftTripBooking.id
								]);
							});
						}
					},
					error: (e) => {
						this.datasetACS.loading.next(false);
						console.error(e);
					}
				});
			}
		});

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(tripBooking => {
			this.confirmTooltip = this.computeConfirmTooltip(tripBooking);
			this.getChannelDisplayValue(tripBooking);
			this.getCreatorName(tripBooking);
			this.setHasPackages(tripBooking);
			this.bookingService.canEdit = Boolean(tripBooking);
			this.getFee(tripBooking);
		});

		this.appService.appEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			if (event.name === RELOAD_BOOKING || event.name === RELOAD_BOOKING_PACKAGES) {
				this.datasetRS.reload();
			}else if(event.name === 'DISABLE_NEXT_BOOKING_STEP'){
				this.enabledNextBookingStep = false;
			}else if(event.name === 'ACTIVE_NEXT_BOOKING_STEP'){
				this.enabledNextBookingStep = true;
			}
		});
	}

	toggleSidebar(name): void {
		this._fuseSidebarService.getSidebar(name).toggleOpen();
	}

	stepChange(stepChange): void {
		this.stepIndex = stepChange.selectedIndex;

		const urlTree = this.router.createUrlTree([], {
			queryParams: { step: this.stepIndex },
			queryParamsHandling: 'merge'
		});

		this.location.go(urlTree.toString());
	}

	ngOnDestroy(): void {
		if (this.appService.layoutFusePerefctScrollBar) {
			this.appService.layoutFusePerefctScrollBar._init();
		}
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	suspendBooking(): void {
		this.bookingService.suspendBooking(this.record.id).subscribe(() => {
			this.datasetRS.reload();
		});
	}

	cancelBooking(): void {
		this.matDialog
			.open(ConfirmWithReasonDialogComponent, {
				width: '550px',
				data: {
					title: 'Conferma',
					reasonLabel: 'Vuoi annullare questa prenotazione?',
					reasonRequired: false
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (!result) return;
				this.bookingService
				.cancelBooking(this.record.id, result)
				.subscribe(() => {
					this.datasetRS.reload();
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
				});
			});
	}

	cancelPermanentBooking(): void {
		if (this.isDraft !== true) return;
		this.matDialog
			.open(ConfirmDialogComponent, {
				width: '550px',
				data: {
					title: 'Conferma',
					contentMessage:
						'Vuoi scartare questa prenotazione?<br>Non sarà più possibile recuperarla.'
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (!result) return;
				this.bookingService
				.cancelPermanentBooking(this.record.id)
				.subscribe(() => {
					this.datasetRS.record.next(null);
					this.datasetACS.loading.next(true);
					this.bookingService
					.newDraftBooking()
					.pipe(takeUntil(this._unsubscribeAll))
					.subscribe({
						next: newDraftTripBooking => {
							this.bookingService.useLastDraft = false;
							//this.datasetRS.record.next(newDraftTripBooking);
							this.datasetACS.loading.next(false);
							this.router.navigate([
								'edit-booking',
								newDraftTripBooking.id
							]);
						},
						error: () => {
							this.datasetACS.loading.next(false);
						}
					});
				});
			});
	}

	getChannelDisplayValue(record: any): string {
		if (!record) return '';
		const value = record.properties.origin_channel_code.value;
		let label = 'Back Office';
		if (value) {
			if (['direct_online', 'zvsite'].includes(value)) {
				label = 'Direct Online';
			} else if (['api'].includes(value)) {
				label = 'API';
			} else if (['direct_offline'].includes(value)) {
				label = 'Direct Offline';
			} else if (['ticketing_mobile'].includes(value)) label = 'Mobile';
		}
		this.channelValue = label;
	}

	getCreatorName(record: any): string {
		if (!record) return '';
		this.creatorName = record.creator?record.creator.name:'';
	}

	confirmBooking(): void {
		this.bookingService.confirmBooking(this.record.id).subscribe(() => {
			this.datasetRS.reload();
			this.appService.appEvent.next({
				name: RELOAD_BOOKING_PACKAGES
			});
		});
	}

	optionalBooking(): void {
		const initial_date = moment.utc().add(5, 'days');
		this.matDialog
			.open(FormDialogComponent, {
				width: '700px',
				data: {
					title: 'Imposta',
					formData: {
						optional_deadline: initial_date
					},
					formConfig: {
						fields: [
							{
								key: 'optional_deadline',
								name: 'Data scadenza',
								inputType: InputTypes.DATETIME,
								valueType: ValueTypes.DATETIME
							}
						]
					},
					dataContainerService: this.datasetACS,
					positiveText: 'Conferma'
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					this.bookingService
						.optionalBooking(this.record.id, result)
						.subscribe(() => {
							this.datasetRS.reload();
							this.appService.appEvent.next({
								name: RELOAD_BOOKING_PACKAGES
							});
						});
				}
			});
	}

	emailVoucher(): void {
		sendVoucherAction(
			{ trip_booking_id: this.record.id },
			this.datasetACS,
			this.datasetNavigatorService
		);
	}

	bookingReviewBarToggle(): void {
		if (this.appService.layoutFusePerefctScrollBar) {
			const templateHeaderPosition = this.mobileHeader.nativeElement.getBoundingClientRect();
			const templateRefFixedBar = this.bottomFixedBar.nativeElement.getBoundingClientRect();

			this.bottom = templateRefFixedBar.height;
			this.bookingReviewHeight =
				templateRefFixedBar.top - templateHeaderPosition.bottom;
			this.bottomBarState =
				this.bottomBarState === 'false' ? 'true' : 'false';

			const container3: any = document.querySelector('#container-3');

			if (this.bottomBarState === 'true') {
				container3.style.overflowY = 'hidden';
				this.appService.layoutFusePerefctScrollBar._destroy();
			} else {
				container3.style.overflowY = 'scroll';
				this.appService.layoutFusePerefctScrollBar._init();
			}
		}
	}

	get statusClass(): string {
		if (this.record.status == 'confirmed') {
			return 'green-bg';
		} else if (this.record.status == 'canceled') {
			return 'red-bg';
		}
		return 'orange-bg';
	}

	setHasPackages(record: any): void {
		if (!record) return;
		const even = item => !item.delivery_status;
		this.hasPackages = record.packages ? record.packages.some(even) : false;
	}

	onViewLogs(): void {
		if (this.record.allowed_actions.includes('view_logs')) {
			this.datasetNavigatorService.openDialog<IDatasetListDialogData>(
				DatasetListDialogComponent,
				{
					title: 'Lista logs prenotazione',
					datasetCode: 'trip_booking_logs',
					parentDatasetACS: this.datasetACS,
					domainId: _.get(this.record, 'domain.id'),
					filters: {
						trip_booking_id: this.record.id
					}
				}
			);
		}
	}

	getFee(record){
		let fee = 0;
		if(this.hasPackages){
			for(const tripPackage of record.packages){
				if(tripPackage.fee) fee+= parseFloat(tripPackage.fee)
			}
		}
		this.fees = fee;
	}

	computeConfirmTooltip(tripBooking: any): string {
		if(!tripBooking) return '';
		if(tripBooking.status == 'confirmed') return 'Gia confermata';
		if(tripBooking.participant_count == 0) return 'Inserire almeno un partecipante';
		if(!tripBooking.packages || tripBooking.packages.length == 0) return 'Inserire almeno una prenotazione';
		if(!tripBooking.entity) return 'Intestatario obbligatorio';
		if(!tripBooking.customer) return 'Cliente obbligatorio';
		return 'Conferma';
	}
}
