import { ValidatorFn, AbstractControlOptions, FormGroup } from '@angular/forms';
import {SafeHtml} from '@angular/platform-browser';
import { IDataContainerService } from './data-container.interface';
import { IDatasetPropertyDefinition } from './datasets';
import { Pipe } from '@angular/compiler/src/core';
import { PipeTransform, Type } from '@angular/core'; 
import { Subject } from 'rxjs';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IBaseViewConfig, IListViewConfig } from '.';
import { AppService } from 'app/services/app.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { ISelectDatasetRecordDialogData } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';

export enum ValueTypes{
	STRING,
	NUMBER,
	DATE,
	TIME,
	DATETIME,
	BOOLEAN,
	ARRAY,
	REFERENCE,
	FILE,
	PROPERTY,
	JSON,
	PRETTY_JSON
}

export enum InputTypes{
	HIDDEN,
	TEXT,
	BASE_TEXT,
	PASSWORD,
	NUMBER,
	COUNTER,
	SELECT,
	DATE,
	COMPARE_DATE,
	TIME,
	DATETIME,
	TEXTAREA,
	EMAIL,
	CHECKBOX,
	RADIO,
	MULTI_CHECKBOX,
	MULTI_SELECT,
	TOGGLE,
	READONLY,
	COLOR,

	/**
	 * For use DATASET_XXX set datasetCode and config remoteOptions
	 */
	DATASET_SELECT,
	DATASET_SELECT_DEP_DROPDOWN,
	DATASET_MULTISELECT,
	DATASET_AUTOCOMPLETE,
	DATASET_COMBOBOX,
	DATASET_SEARCH,
	FILE,
	MULTIPLE_FILE,
	FILE_BLOCK,
	PROPERTY,
	STARS,
	TEXT_LIST,
	TEXT_LIST2, // title + description
	WEEK_HOURS,

	TINY_MCE,
	SELECT_CUSTOM_ICON,
	SELECT_FILTERABLE
}

export enum FieldViews{
	STANDARD,
	STARS,
	STORABLE_DATE,
	IMAGE,
	BG_IMAGE,
	CONTACT,
	LINK,
	HTML,
	CUSTOM_ICON,
	DIALOG_TEXT,
	HIDDEN,
	MENU,
	LIST,
	MIXED_COLUMN,
	BUTTON,
}

export interface IFieldOption{
	label: string;
	value: any;
	group?: IFieldOption[];
}

export interface IFilterFieldDefinition extends IFieldDefinition {
	filterKey?: string;
}

export interface IInputIcon{
	icon: string;
	color?: string;
	onClick?: (formGroup: FormGroup, navigationService: DatasetNavigatorService) => void;
}

export interface IFileBlockConfig{
	uploadRoute: string;
	imageOptions: any;
	record?: any;
	uploadData?: any;
	getUploadDataFun?: (record) => any;
	changed?: (inputComponent) => any;
}

export interface ISelectDialogConfig{
	/**
	 * Pass the view container ref to the dialog
	 */
	passThrough?: boolean;
	matDialogConfig?: MatDialogConfig;
	dialogConfig?: ISelectDatasetRecordDialogData;
}

export interface IFieldInputConfig{
	type?: InputTypes;
	hint?: string;
	required?: boolean; // if true set input as required
	readOnly?: boolean;
	placeholder?: string;
	clearable?: boolean;
	suffixs?: IInputIcon[];
	prefixs?: IInputIcon[];
	appearence?: string;
	viewConfig?: IListViewConfig;
	fileBlockConfig?: IFileBlockConfig;
	selectDialogConfig?: ISelectDialogConfig;
	customCssClass?: string;
}

export interface IRemoteSelectConfig{
	code: string;
	limit?: number;
}

export interface IFieldDefinition{
	key: string;	// the key of data in the dataset object
	name: string;	// used for title
	rawName?: string; // print name without pipes
	description?: string;
	inputType?: InputTypes;
	valueType: ValueTypes;
	inputConfig?: IFieldInputConfig; // use this for config

	disallowNull?: boolean;
	propertyDefinition?: IDatasetPropertyDefinition;
	disableHideWithDisplayNone?: boolean; // if not showed style the display with none
	inputHint?: string;
	appearance?: string;
	inputRequired?: boolean; // if true set input as required
	inputReadOnly?: boolean;
	fieldView?: FieldViews;
	datasetCode?: string;	// if is type REFERENCE
	customActionCode?: string;
	propertyCode?: string; // used for search the property definition, by default key is used
	dependOn?: string; // usare con tipologia DATASET_SELECT_DEP_DROPDOWN
	fetchOnLoad?: boolean; // usare con tipologia DATASET_SELECT_DEP_DROPDOWN
	maxWidth?: string;
	width?: string;
	resize?: {
		width: number|any;
		height: number|any;
	};
	remoteOptions?: {
		limit?: number;
		icon?: string;
		clearInputOnCreation?: boolean;
		supportCreate?: boolean;
		clearOnSelection?: boolean;
		createHint?: string;
		getPivotConfig?: (dataContainerService: IDataContainerService, record: any) => {
			pivotCode: string;
			foreignKey: string;
			parentDatasetCode: string
		},
		getLabel: (option, dataContainerService?: IDataContainerService|AppService) => any;
		getValue: (option) => any;
		getDisplay?: (option) => any;
		getParams?: (dataContainerService: IDataContainerService, record: any, options?: any) => any;
	};

	/** used for uniform remote options */
	remoteSelectConfig?: IRemoteSelectConfig;

	getOptionParams?: (dataContainerService: IDataContainerService, record: any, code: string) => any; 
	clickOptions?: {
		openDetailDatasetCode: string;
		getDetailRecordId: (record: any, dataContainerService?: IDataContainerService) => any;
	};

	getDisplayValue?: ((record: any, referenceRecord?: any) => string) | string;
	getDisplayTooltip?: ((record: any) => string) | string;
	options?: IFieldOption[];

	/**
	 * @deprecated 
	 */
	optionSource?: string;
	/**
	 * @deprecated 
	 */
	optionSourceKey?: string;


	optionSelected?: any;
	defaultValue?: (dataContainerService: IDataContainerService, options?: any[], record?: any) => any;
	placeholder?: string;
	showIf?: (record: any, dataContainerService?: IDataContainerService) => boolean;
	// different from showIf
	// this not load input and not send data when save
	skipIf?: (record: any, dataContainerService?: IDataContainerService) => boolean;
	readonlyIf?: (record: any, dataContainerService?: IDataContainerService) => boolean;
	onValueChanged?: (form: FormGroup) => void;
	formValidators?: ValidatorFn | ValidatorFn[] | AbstractControlOptions;

	printTransform?: ((value: any) => any); // convert the value for display

	extra?: any; // extra configuration
	prefixIcon?: string;
	suffixIcon?: string;
	transforms?: {
		pipeClass?: Type<PipeTransform>,
		transform?: (value: any, ...args: any[]) => any,
		args?: any[]
	}[];

	titleIcon?: string;
	titleTooltip?: string;
	exportEAgency?: (record: any, dataContainerService?: IDataContainerService) => boolean;
	computedDefinition?: (formRawData: any, dataContainerService?: IDataContainerService) => IFieldDefinition;
}

export interface IFormContainerConfig{
	flexLayout?: string;
	flexLayoutAlign?: string;
	fxLayoutGap?: string;
	maxWidth?: string;
	flex?: string;
	class?: string;
	section?: {
		title?: string;
		open?: boolean;
		showIf?: (record: any, dataContainerService?: IDataContainerService) => boolean
	};
}

export interface IFieldContainer{
	title?: string;
	description?: string;
	config?: IFormContainerConfig;
	fields: (IFieldDefinition|IFieldContainer)[];
}

export interface IFormTip{
	icon?: string;
	description: string;
}

export interface IFormConfig{
	title?: string;
	editTitle?: string;
	formStyle?: string;
	tips?: [IFormTip];
	fields: (IFieldContainer|IFieldDefinition)[];
	// add other configuration for form here
	onChanged?: (formGroup: FormGroup) => void;
}

export function isFieldContainer(field: IFieldContainer | IFieldDefinition): field is IFieldContainer{
	if(!field) return false;
	return (<IFieldContainer>field).fields !== undefined;
}
