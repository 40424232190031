import * as tslib_1 from "tslib";
import { ViewContainerRef } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DataFilterComponent } from '../data-filter.component';
var Style01FilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(Style01FilterComponent, _super);
    function Style01FilterComponent(matDialog, datasetNavigatorService, viewContainerRef) {
        var _this = _super.call(this, matDialog, datasetNavigatorService, viewContainerRef) || this;
        _this.matDialog = matDialog;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.viewContainerRef = viewContainerRef;
        return _this;
    }
    return Style01FilterComponent;
}(DataFilterComponent));
export { Style01FilterComponent };
