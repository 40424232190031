import { Component, OnInit, OnDestroy, Inject, Self, SkipSelf } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ATripBookingPackageRelatedComponent, ITripBookingPackageRelatedDataDialogData } from '../abstract-trip-booking-package-related-data.component';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from "app/main/pages/edit-booking/edit-booking.component";
import { ActionDialogComponent } from "app/main/pages/edit-booking/action-dialog/action-dialog.component";
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import * as _ from 'lodash';

@Component({
	selector   : 'trip-booking-service',
	templateUrl: './trip-booking-service.component.html',
	styleUrls: ['./trip-booking-service.component.scss'],
	providers: [
		DatasetActionContainerService, 
		PageClosureContainerService,
		DatasetRecordService
	]
})
export class TripBookingServiceComponent extends ATripBookingPackageRelatedComponent implements OnInit, OnDestroy{

	public canEdit = false;

	constructor(
		public appService: AppService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		public bookingService: BookingService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedDataDialogData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedDataDialogData,
	){
		super(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.updateCanEdit();
	}

	updateCanEdit(){
		if(!this.bookingService.canEdit){
			this.canEdit = false;
			return;
		}
		// disable edit if is a tourcms package
		if(_.get(this.tripBookingPackage, 'trip_package.tourcms_channel_id_tour_id')){
			this.canEdit = false;
			return;
		}
		// disable edit if is a bokun package
		if(_.get(this.tripBookingPackage, 'trip_package.bokun_channel_id_tour_id')){
			this.canEdit = false;
			return;
		}
		this.canEdit = true;
	}

	addTripBookingService(): void{
		this.datasetNavigatorService.openCreateDialog(this.datasetACS);
	}

	onRecalculateService(): void{
		const data = {
			trip_booking_package_id: _.get(this.tripBookingPackage, 'id')
		};

		this.datasetNavigatorService.dialog
			.open(ConfirmDialogComponent, {
				width: '550px',
				data: {
					title: 'Conferma',
					contentMessage: 'Vuoi aggiornare i prezzi dei servizi?'
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (!result) return;
				const dialogRef = this.datasetNavigatorService.dialog.open(ActionDialogComponent, {
					width: '550px',
					data: {
						title: 'Aggiornamento prezzi servizi'
					}
				});
				this.datasetNavigatorService.datasetService.post('/dataset/trip_booking_services/command/recalculate_service', data)
				.subscribe({
					next: () => {
						dialogRef.close();
						this.appService.showSuccessMessage('Aggiornato con successo');
						this.appService.appEvent.next({ name: RELOAD_BOOKING });
						this.appService.appEvent.next({
							name: RELOAD_BOOKING_PACKAGE,
							extra: {
								trip_booking_package_id: _.get(this.tripBookingPackage, 'id')
							}
						});
					},
					error: response => {
						/*
						if(response.error && response.error.errors){
							this.bookingService.onErrorActionResponse(response, dialogRef);
						}
						*/
						dialogRef.componentInstance.resultStatus = 'error';
						dialogRef.componentInstance.loading = false;
						dialogRef.componentInstance.setMessage('Si è verificato un errore');
					}
				});
			});
	}
}
