/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dataset-edit-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "@angular/flex-layout/extended";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../form-viewer/form-viewer.component.ngfactory";
import * as i11 from "../../../../../../services/form-viewer.service";
import * as i12 from "../../../../../../services/app.service";
import * as i13 from "@angular/forms";
import * as i14 from "../../../../form-viewer/form-viewer.component";
import * as i15 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i16 from "@angular/material/button";
import * as i17 from "@angular/cdk/a11y";
import * as i18 from "../../../../../pages/page-closure-container.service";
import * as i19 from "../../../services/dataset-action-container.service";
import * as i20 from "../../../../../../services/auth.service";
import * as i21 from "../../../services/dataset.service";
import * as i22 from "../../../../../../services/domain-filter.service";
import * as i23 from "../../../services/dataset-record.service";
import * as i24 from "./dataset-edit-dialog.component";
var styles_DatasetEditDialogComponent = [i0.styles];
var RenderType_DatasetEditDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatasetEditDialogComponent, data: {} });
export { RenderType_DatasetEditDialogComponent as RenderType_DatasetEditDialogComponent };
function View_DatasetEditDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.dialogTitle)); _ck(_v, 2, 0, currVal_1); }); }
function View_DatasetEditDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data.title)))); _ck(_v, 2, 0, currVal_1); }); }
function View_DatasetEditDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DatasetEditDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mb-24 full-form"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 933888, null, 0, i8.DefaultClassDirective, [i1.ElementRef, i9.StyleUtils, i9.MediaMarshaller, i7.ɵNgClassImpl, [6, i7.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "form-viewer", [], null, null, null, i10.View_FormViewerComponent_0, i10.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i11.FormViewerService, i11.FormViewerService, [i12.AppService, i13.FormBuilder, i1.NgZone]), i1.ɵdid(6, 770048, [[1, 4], ["formViewer", 4]], 0, i14.FormViewerComponent, [i11.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mb-24 full-form"; var currVal_1 = _co.formConfig.formStyle; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.formConfig.formStyle; var currVal_3 = "mb-24 full-form"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.formConfig; var currVal_5 = _co.datasetACS; var currVal_6 = _co.data.record; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_DatasetEditDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.TitleCasePipe, []), i1.ɵqud(671088640, 1, { formViewer: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "material-icons-outlined dialog-close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetEditDialogComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetEditDialogComponent_2)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetEditDialogComponent_3)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetEditDialogComponent_4)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "close"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_MatButton_0, i15.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i16.MatButton, [i1.ElementRef, i17.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(19, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "ml-4 save"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_MatButton_0, i15.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i16.MatButton, [i1.ElementRef, i17.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(23, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.data.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.data.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.loading || _co.datasetACS.loading.value); _ck(_v, 12, 0, currVal_3); var currVal_4 = (_co.datasetACS.actionConfig && !_co.datasetACS.loading.value); _ck(_v, 14, 0, currVal_4); var currVal_10 = _co.loading; var currVal_11 = "accent"; _ck(_v, 22, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formConfig.formStyle; _ck(_v, 2, 0, currVal_0); var currVal_5 = (i1.ɵnov(_v, 18).disabled || null); var currVal_6 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("Annulla")); _ck(_v, 19, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 22).disabled || null); var currVal_9 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("Salva")); _ck(_v, 23, 0, currVal_12); }); }
export function View_DatasetEditDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "dataset-edit-dialog", [], null, null, null, View_DatasetEditDialogComponent_0, RenderType_DatasetEditDialogComponent)), i1.ɵprd(512, null, i18.PageClosureContainerService, i18.PageClosureContainerService, [i12.AppService]), i1.ɵprd(512, null, i19.DatasetActionContainerService, i19.DatasetActionContainerService, [i12.AppService, i18.PageClosureContainerService, i20.AuthService, i21.DatasetService, i22.DomainFilterService, i3.TranslateService, i1.Injector]), i1.ɵprd(512, null, i23.DatasetRecordService, i23.DatasetRecordService, [i19.DatasetActionContainerService, i21.DatasetService]), i1.ɵdid(4, 245760, null, 0, i24.DatasetEditDialogComponent, [i19.DatasetActionContainerService, i12.AppService, i21.DatasetService, i2.MatDialogRef, i2.MAT_DIALOG_DATA, i2.MatDialog, i23.DatasetRecordService, i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var DatasetEditDialogComponentNgFactory = i1.ɵccf("dataset-edit-dialog", i24.DatasetEditDialogComponent, View_DatasetEditDialogComponent_Host_0, {}, {}, []);
export { DatasetEditDialogComponentNgFactory as DatasetEditDialogComponentNgFactory };
