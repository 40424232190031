import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';

import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('piva'),
	{
		key: 'has_external_user',
		name: 'Utenti B2B',
		valueType: ValueTypes.NUMBER,
		inputConfig: {
			type: InputTypes.SELECT
		},
		disallowNull: false,
		defaultValue(): any{
			return null;
		},
		options: [
			{
				label: 'Tutti',
				value: null
			},
			{
				label: 'Solo B2B',
				value: 1
			},
			{
				label: 'Non B2B',
				value: 0
			}
		]
	},
	{
		key: 'entity_types',
		name: 'Tipo',
		valueType: ValueTypes.ARRAY,
		inputType: InputTypes.MULTI_SELECT,
		options: [
			{
				label: 'Cliente',
				value: 'customer',
			},
			{
				label: 'Tour Operator',
				value: 'tour_operator',
			},
			{
				label: 'Staff',
				value: 'staff_element',
			},
			{
				label: 'Fornitore',
				value: 'supplier',
			},
			{
				label: 'Anagrafica semplice',
				value: 'none',
			}
		]
	},
	{
		key: 'staff_types',
		name: 'Tipo Staff',
		valueType: ValueTypes.ARRAY,
		inputType: InputTypes.DATASET_MULTISELECT,
		datasetCode: 'property_value_options',
		showIf(data: any): boolean{
			if(!data || !data.entity_types) return false;
			try{
				if(data.entity_types instanceof Array){
					return data.entity_types.includes('staff_element');
				}
			}catch(e){
				console.warn(e);
				return false;
			}
			return false;
		},
		remoteOptions: {
			getParams(): any{
				return { 
					property_target_table: 'entity_staff_elements', 
					property_code: 'staff_type' 
				};
			},
			getLabel(option): string{
				return option.label;
			},
			getValue(option): string{
				return option.value;
			}
		}
	}
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
