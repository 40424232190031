import * as tslib_1 from "tslib";
import { OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var TextListInput2Component = /** @class */ (function (_super) {
    tslib_1.__extends(TextListInput2Component, _super);
    function TextListInput2Component(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.list = [];
        return _this;
    }
    TextListInput2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.list = _this.inputService.control.value;
        });
    };
    TextListInput2Component.prototype.addNewItem = function () {
        if (this.list == null) {
            this.list = [];
            this.inputService.control.setValue(this.list);
        }
        this.list.push(['', '']);
        this.inputService.formGroup.updateValueAndValidity();
    };
    TextListInput2Component.prototype.updateValue = function (i, event) {
        this.list[i] = event.target.value;
    };
    TextListInput2Component.prototype.removeItem = function (i) {
        this.list.splice(i, 1);
    };
    TextListInput2Component.prototype.trackByFn = function (index) {
        return index;
    };
    TextListInput2Component.prototype.ngOnChanges = function (changes) {
        this.list = this.inputService.control.value;
    };
    return TextListInput2Component;
}(DestroyableComponent));
export { TextListInput2Component };
