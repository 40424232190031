import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('user.access_username'),
	fieldsMap.get('user.name'),
	fieldsMap.get('user.email'),
	fieldsMap.get('branch.code')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
