import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('tour_operator'),
	fieldsMap.get('phone_number'),
	fieldsMap.get('email'),
	fieldsMap.get('info_meeting'),
	fieldsMap.get('customer_service'),
	fieldsMap.get('terms_of_use'),
];

const config: IFormConfig = {
	title: 'Traduzione Voucher',
	editTitle: 'Traduzione Voucher',
	fields: formFields 
};

export default config;
