import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var SelectCustomIconInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectCustomIconInputComponent, _super);
    function SelectCustomIconInputComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        return _this;
    }
    SelectCustomIconInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.selectOptions = _this.inputService.formInputDefinition.options || [];
        });
    };
    return SelectCustomIconInputComponent;
}(DestroyableComponent));
export { SelectCustomIconInputComponent };
