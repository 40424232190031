import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'biextradecode'
})
export class BIExtraDecodePipe implements PipeTransform {

	public constructor() {}

	transform(item: any, key: string = 'code'): any {
		const property = this.transcodeProperty(key);
		if (item[key]){
			return JSON.parse(item[key])[0] || '-----';
		} else if (item['extra']){
			const extra = JSON.parse(item['extra'])[0];
			return extra[property] || '-----';
		}

		return '-----';
	}

	transcodeProperty(key: string): string{
		switch(key){
			case 'code':
				return 'f1';
			case 'description':
				return 'f2';
		}
	}
}