<mat-dialog-content>
	<h3>{{injectedData?.title || 'Seleziona un dominio sul quale agire' | translate}}</h3>
	<form-viewer #formViewer
		[dataContainerService]='datasetACS' 
		[formConfig]='formConfig'
	></form-viewer>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button (click)="close()" [disabled]="loading">{{'Chiudi' | translate}}</button>
	<button [disabled]="loading" mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">
		{{'Seleziona' | translate}}
	</button>

	<mat-spinner *ngIf="loading" diameter="20" style="margin-left: 30px;"></mat-spinner>
	<!--<button mat-raised-button mat-dialog-close tabindex="-1">chiudi</button>-->
</mat-dialog-actions>