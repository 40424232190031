<div id="forgot-password" fxLayout="column" fusePerfectScrollbar>
	<div
		id="forgot-password-form-wrapper"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="form-wrapper">

			<div class="logo mb-32">
				<img src="assets/images/logos/tangerine_logo.png" />
			</div>

			<div
				id="forgot-password-form"
				[hidden]="navigating"
				*fuseIfOnDom
				[@animate]="{
					value: '*',
					params: { duration: '300ms', y: '100px' }
				}"
			>

				<form
					name="forgotPasswordForm"
					[formGroup]="forgotPasswordForm"
					novalidate
				>
					<mat-form-field appearance="fill">
						<mat-label>Email</mat-label>
						<input matInput formControlName="email" />
						<mat-icon matSuffix class="secondary-text">mail</mat-icon>
						<mat-error
							*ngIf="
								forgotPasswordForm.get('email').hasError('required')
							"
						>
							Email is required
						</mat-error>
						<mat-error
							*ngIf="
								forgotPasswordForm.get('email').hasError('email')
							"
						>
							Please enter a valid email address
						</mat-error>
					</mat-form-field>

					<button
						mat-flat-button
						class="submit-button"
						color="accent"
						aria-label="SEND RESET LINK"
						[disabled]="forgotPasswordForm.invalid"
					>
						<span>Invia link</span>
						<mat-icon>keyboard_arrow_right</mat-icon>
					</button>
				</form>

				<a
					class="login-link"
					[routerLink]="'/auth/login'"
				>
					Torna al login
				</a>

			</div>
		</div>
	</div>
</div>
