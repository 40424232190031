import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
var TripPackageReselledStep3Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageReselledStep3Component, _super);
    function TripPackageReselledStep3Component(appService, parentDatasetRS, parentDatasetACS, reselledDatasetRS, originalDatasetRS, datasetACS, originalDatasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, originalDatasetMCS) {
        var _this = 
        // parent constructor
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.reselledDatasetRS = reselledDatasetRS;
        _this.originalDatasetRS = originalDatasetRS;
        _this.datasetACS = datasetACS;
        _this.originalDatasetACS = originalDatasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.reselledDatasetMCS = reselledDatasetMCS;
        _this.originalDatasetMCS = originalDatasetMCS;
        /**
         * carousel block configs
         */
        _this.carouselBlockConfigs = {
            datasetCode: 'trip_package_language_contents_carousel_images',
            parentDatasetCode: 'trip_package_language_contents',
            parentForeignKey: 'trip_package_language_content_id'
        };
        return _this;
    }
    TripPackageReselledStep3Component.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.reselledDatasetRS,
            fetchData: true
        });
        this.originalDatasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.originalDatasetRS,
            fetchData: true
        });
        this.reselledDatasetRS.datasetACS = this.datasetACS;
        this.originalDatasetRS.datasetACS = this.originalDatasetACS;
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.reselledDatasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.reselledDatasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'reselledData.id'
            });
            _this.reselledDatasetMCS.fetchRecord();
        });
        this.originalDatasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.originalDatasetMCS.init({
                datasetACS: _this.originalDatasetACS,
                datasetRS: _this.originalDatasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'trip_packages.recordId'
            });
            _this.originalDatasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record) {
                _this.reselledDatasetMCS.activeLanguageCodes = record.active_language_codes;
                _this.originalDatasetMCS.activeLanguageCodes = record.active_language_codes;
            }
        });
    };
    return TripPackageReselledStep3Component;
}(BaseStepViewComponent));
export { TripPackageReselledStep3Component };
