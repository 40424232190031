/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trenitalia-dialog-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../trenitalia-dialog.component.ngfactory";
import * as i3 from "../../../page-closure-container.service";
import * as i4 from "../../../../../services/app.service";
import * as i5 from "../../../../components/dataset/services/dataset-action-container.service";
import * as i6 from "../../../../../services/auth.service";
import * as i7 from "../../../../components/dataset/services/dataset.service";
import * as i8 from "../../../../../services/domain-filter.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../trenitalia.service";
import * as i11 from "../../booking.service";
import * as i12 from "../trenitalia-dialog.component";
import * as i13 from "@angular/material/snack-bar";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../../trip-booking-packages-step/trip-booking-packages-step.service";
import * as i16 from "../../../../components/dataset/services/dataset-record.service";
import * as i17 from "./trenitalia-dialog-container.component";
import * as i18 from "../../../../../helpers/index";
var styles_TrenitaliaDialogContainerComponent = [i0.styles];
var RenderType_TrenitaliaDialogContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrenitaliaDialogContainerComponent, data: {} });
export { RenderType_TrenitaliaDialogContainerComponent as RenderType_TrenitaliaDialogContainerComponent };
export function View_TrenitaliaDialogContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-trenitalia-dialog", [], null, [[null, "complete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("complete" === en)) {
        var pd_0 = (_co.onComplete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TrenitaliaDialogComponent_0, i2.RenderType_TrenitaliaDialogComponent)), i1.ɵprd(4608, null, i3.PageClosureContainerService, i3.PageClosureContainerService, [i4.AppService]), i1.ɵprd(4608, null, i5.DatasetActionContainerService, i5.DatasetActionContainerService, [i4.AppService, i3.PageClosureContainerService, i6.AuthService, i7.DatasetService, i8.DomainFilterService, i9.TranslateService, i1.Injector]), i1.ɵprd(512, null, i10.TrenitaliaService, i10.TrenitaliaService, [i7.DatasetService, i11.BookingService]), i1.ɵdid(4, 114688, null, 0, i12.TrenitaliaDialogComponent, [i10.TrenitaliaService, i13.MatSnackBar, i7.DatasetService, i4.AppService, i14.MatDialog, i11.BookingService, [2, i15.TripBookingPackagesStepService], [2, i16.DatasetRecordService]], { dataD: [0, "dataD"] }, { complete: "complete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataD; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TrenitaliaDialogContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "trenitalia-dialog-container", [], null, null, null, View_TrenitaliaDialogContainerComponent_0, RenderType_TrenitaliaDialogContainerComponent)), i1.ɵprd(4608, null, i3.PageClosureContainerService, i3.PageClosureContainerService, [i4.AppService]), i1.ɵprd(5120, null, i11.BookingService, i17.ɵ0, [i1.Injector, i18.DialogDataKeyToken]), i1.ɵprd(4608, null, i10.TrenitaliaService, i10.TrenitaliaService, [i7.DatasetService, i11.BookingService]), i1.ɵdid(4, 114688, null, 0, i17.TrenitaliaDialogContainerComponent, [i14.MAT_DIALOG_DATA, i14.MatDialogRef, i14.MatDialog], null, null), i1.ɵprd(256, null, i18.DialogDataKeyToken, "bookingService", [])], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var TrenitaliaDialogContainerComponentNgFactory = i1.ɵccf("trenitalia-dialog-container", i17.TrenitaliaDialogContainerComponent, View_TrenitaliaDialogContainerComponent_Host_0, {}, {}, []);
export { TrenitaliaDialogContainerComponentNgFactory as TrenitaliaDialogContainerComponentNgFactory };
