import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { DatasetRecordService } from '../../services/dataset-record.service';
import * as moment from 'moment';
import { BookingStep2Service } from 'app/main/pages/edit-booking/step-2/view.service';
import { M } from '@angular/cdk/keycodes';

export interface IActualTripDateDialogData {
	parentDatasetACS: DatasetActionContainerService;
	tripBookingPackage: any;
	bookingStepService: BookingStep2Service;
}

@Component({
	selector: 'actual-trip-date',
	templateUrl: 'dialog.component.html',
	// styleUrls: ['dialog.component.scss'],
	providers: [PageClosureContainerService, DatasetActionContainerService, DatasetRecordService]
})
export class ActualTripDateComponent implements OnInit, OnDestroy{

	@ViewChild('inputDate', {
    read: MatInput,
    static: false
}) inputDate: MatInput;

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	public minDate: moment.Moment;
	private timeMeetingPoint: Array<number> | undefined;

	private dates: any;
	public chosenDate: any;
	public status: boolean;

	private deliveryDate: string;
	private deliveryTime: string;

	get tripBookingPackage(): any{
		return this.data.tripBookingPackage;
	}

	constructor(
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<ActualTripDateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IActualTripDateDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.minDate = moment();
		this.setDateMeetingPoint();
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	onDatasetEvent(event: IDatasetEvent): void{
		// this.loadLastCreated();
	}

	datesFilter = (d: moment.Moment): boolean => {
		const day = d.day();
		/* Filter date by meeting_point. */
		if(this.timeMeetingPoint){
			return !this.timeMeetingPoint.includes(day);
		}
		return true;
	}

	dateChangeActualTrip(event: any) {
		this.status = undefined;
		const m: moment.Moment = event.value;
		m.locale('en');
		const dateCode = m.format('dddd').toLowerCase();
		this.dates.forEach((date) => {
			if (date.code === dateCode) {
				this.chosenDate = date;
				this.deliveryDate =  m.format("YYYY-MM-DD");
			}
		});
	}

	timeChangeActualTrip(event: any): void {
		let value = event.target.value;
		const format = 'HH:mm';
		let mCurrentTime: moment.Moment = moment(value, format),
		mOpeningTime: moment.Moment,
  	mClosureTime: moment.Moment;

		for (let time of this.chosenDate.time) {
			mOpeningTime = moment(time.opening, format);
			mClosureTime = moment(time.closure, format);
			let _status = mCurrentTime.isBetween(mOpeningTime, mClosureTime, null, '[]');
			if (_status) {
				this.status = true;
				this.deliveryTime = value
				break;
			} else {
				this.status = false;
				this.deliveryTime = '';
			}
		}
	}

	saveDataTimeActualTrip() {
		this.data.bookingStepService.updateActualTrip({
			trip_booking_package_id: this.tripBookingPackage.id,
			actual_trip_id: this.tripBookingPackage.actual_trip.id,
			delivery_date: this.deliveryDate,
			delivery_time:this.deliveryTime
		});
		this.dialogRef.close(false);
	}

	setDateMeetingPoint(): void {
		if(this.inputDate) this.inputDate.value = '';
		this.dates = this.tripBookingPackage.default_meeting_point_unified_list_item ?
														this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point.time : undefined;
		if (!this.dates) return;
		const mapTimes = this.dates.map((date, index) => {
			if(!date.time || date.time.length === 0) {
				index++;
				// changes the index of the day Monday to Sunday
				index = index === 7 ? 0 : index;
				return index;
			}
		});

		this.timeMeetingPoint = mapTimes;
	}

}
