import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fattureInCloudFields } from '../../fields/accounting/fatture-in-cloud.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fattureInCloudFields.get('enabled'),
	fattureInCloudFields.get('endPoint'),
	fattureInCloudFields.get('apiUID'),
	fattureInCloudFields.get('apiKey'),
    fattureInCloudFields.get('scenario')
];

const appForm: IFormConfig = { 
	editTitle: 'Modifica Configurazione Fatture in Cloud',
	fields: formFields 
};

export default appForm;
