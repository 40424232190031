import { Injectable, Injector, NgZone, ViewContainerRef } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents, getDatasetConfigAction } from './dataset-action-container.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'app/services/app.service';
import { DatasetEditDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/edit/dataset-edit-dialog.component';
import { DatasetActionOpenType, IFormConfig } from 'app/interfaces';
import { DatasetCreateDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/create/dataset-create-dialog.component';
import { DatasetDeleteDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/delete/dataset-delete-dialog.component';
import { DatasetDetailDialogComponent } from '../dialogs/core-actions/detail/dataset-detail-dialog.component';
import {DatasetService} from './dataset.service';
import { DatasetRestoreDialogComponent } from '../dialogs/core-actions/restore/dataset-restore-dialog.component';
import * as _ from 'lodash';

export interface IEditDialogOptions{
	formConfig?: IFormConfig;
	customActionCode?: any;
	recordId?: any;
	method?: any;
	title?: string;
	parentDatasetACS?: DatasetActionContainerService;
	viewContainerRef?: ViewContainerRef;
}

export type DialogConfigParams = {
	panelClass?: string;
	minWidth?: number;
	viewContainerRef?: ViewContainerRef;
}

@Injectable()
export class DatasetNavigatorService {

	constructor(
		public appService: AppService,
		public dialog: MatDialog,
		public router: Router,
		private injector: Injector,
		public datasetService: DatasetService,
		public zone: NgZone
	){}

	openDialog<T>(dialogComponent: any, data: T, dialogConfig?: DialogConfigParams): MatDialogRef<any>{
		const dialogRef = this.dialog.open(dialogComponent, { 
			panelClass: dialogConfig && typeof(dialogConfig.panelClass) != 'undefined' ? dialogConfig.panelClass : 'custom-dialog-container',
			minWidth: dialogConfig && typeof(dialogConfig.minWidth) != 'undefined' ? dialogConfig.minWidth : 700,
			viewContainerRef: dialogConfig && dialogConfig.viewContainerRef,
			data
		});
		return dialogRef;
	}
	
	openEditDialog(callerService: DatasetActionContainerService, record: any, dialogOptions?: IEditDialogOptions): MatDialogRef<DatasetEditDialogComponent, any>{
		const viewContainerRef = _.get(dialogOptions, 'viewContainerRef');
		if (dialogOptions) delete dialogOptions.viewContainerRef; // prevent to pass viewContainerRef to data by use only as parameter
		const dialogRef = this.dialog.open(DatasetEditDialogComponent, {
			panelClass: 'edit-dialog-container',
			data: Object.assign({}, {
				datasetCode: callerService.datasetCode,
				record,
				datasetData: callerService.datasetData,
				parentDatasetACS: callerService
			}, dialogOptions),
			viewContainerRef
		});
		dialogRef.afterClosed()
		.subscribe(result => {
			if (result){
				callerService.datasetEvent.next({
					eventName: DatasetEvents.EDITED,
					data: {
						id: dialogOptions && dialogOptions.recordId ? dialogOptions.recordId : record.id
					}
				});
				if(callerService.parentDatasetACS){
					callerService.parentDatasetACS.datasetEvent
					.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
				}
				const editConfig = getDatasetConfigAction(callerService.datasetCode, 'edit');
				if(editConfig && typeof(editConfig.afterSave) === 'function'){
					editConfig.afterSave(callerService, result, record, viewContainerRef);
				}
			}
		});
		return dialogRef;
	}

	openCreateDialog(callerService: DatasetActionContainerService, dialogOptions?: any): MatDialogRef<DatasetCreateDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetCreateDialogComponent, {
			panelClass: 'create-dialog-container',
			data: Object.assign({}, {
				datasetCode: callerService.datasetCode,
				parentDatasetACS: callerService
			}, dialogOptions)
		});
		dialogRef.afterClosed()
		.subscribe(result => {
			if (result){
				callerService.datasetEvent.next({
					eventName: DatasetEvents.CREATED,
					data: result
				});
			}
		});
		return dialogRef;
	}

	openDetailDialog(callerService: DatasetActionContainerService, recordId: string): MatDialogRef<DatasetDetailDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetDetailDialogComponent, {
			panelClass: 'detail-dialog-container',
			data: {
				datasetCode: callerService.datasetCode,
				parentDatasetACS: callerService,
				recordId
			}
		});
		return dialogRef;
	}

	getDetailRoute(datasetCode: string, recordId: string): string{
		return '/dataset/detail/' + datasetCode + '/' + recordId;
	}

	getEditRoute(datasetCode: string, recordId: string): string{
		return '/dataset/edit/' + datasetCode + '/' + recordId;
	}

	getListRoute(datasetCode: string): string{
		return '/dataset/list/' + datasetCode;
	}

	onEdit(callerService: DatasetActionContainerService, record: any, options?: IEditDialogOptions): void{
		if(!callerService.canEdit(record)) return;
		
		const openType = callerService.getDefaultOpenType('edit');
		
		if(openType === DatasetActionOpenType.DIALOG){
			this.openEditDialog(callerService, record, options);
		}else if(openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/edit/' + callerService.datasetCode + '/' + record.id]);
		}
	}

	onAdd(callerService: DatasetActionContainerService, options?: any): void {
		if(!callerService.canCreate()) return console.log('no permission', callerService.getData());

		const openType = callerService.getDefaultOpenType('create');

		if(openType === DatasetActionOpenType.DIALOG){
			this.openCreateDialog(callerService, options);
		}else if(openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/create/' + callerService.datasetCode ]);
		}
	}
	
	showDetail(callerService: DatasetActionContainerService, element: any, force?: boolean): void{
		if(!force && !callerService.canDetail(element)) return;
		const openType = callerService.getDefaultOpenType('detail');

		if(!element.id){
			console.warn('dataset-navigator.service invalid elementId');
			return;
		}

		if(openType === DatasetActionOpenType.DIALOG){
			this.openDetailDialog(callerService, element.id);
		}else if(openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/detail/' + callerService.datasetCode + '/' + element.id]);
		}
	}

	showDatasetEdit(datasetCode: string, parents: any, recordId: string): void{
		if(!recordId){
			console.warn('dataset-navigator.service.showDatasetDetail invalid recordId');
			return;
		}
		const injector = Injector.create({
			parent: this.injector, 
			providers: [{
				provide: DatasetActionContainerService,
				deps: []
			}]
		});
		const containerService: DatasetActionContainerService = injector.get(DatasetActionContainerService);
		containerService.init({
			datasetCode,
			actionCode: 'edit',
			parentDatasetRS: parents && parents.createWithParentDatasetRS,
			parentDatasetACS: parents && parents.createWithParentDatasetACS,
			fetchData: false
		});
		const openType = containerService.getDefaultOpenType('edit');
		
		if(openType === DatasetActionOpenType.DIALOG){
			const params = {};
			if(containerService && containerService.datasetConfig && containerService.datasetConfig.fetchConfig){
				if(containerService.datasetConfig.fetchConfig.with_attributes){
					params['with_attributes'] = containerService.datasetConfig.fetchConfig.with_attributes;
				}
				if(containerService.datasetConfig.fetchConfig.with_relations){
					params['with_relations'] = containerService.datasetConfig.fetchConfig.with_relations;
				}
			}
			this.datasetService.get<any>('/dataset/' + datasetCode + '/detail/' + recordId, params)
			.subscribe(record => {
				this.openEditDialog(containerService, record);
			});
		}else if(openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/edit/' + containerService.datasetCode + '/' + recordId]);
		}
	}

	showDatasetDetail(datasetCode: string, parents: any, recordId: string): void{
		if(!recordId){
			console.warn('dataset-navigator.service.showDatasetDetail invalid recordId');
			return;
		}
		const injector = Injector.create({
			parent: this.injector, 
			providers: [{
				provide: DatasetActionContainerService,
				deps: []
			}]
		});
		const containerService: DatasetActionContainerService = injector.get(DatasetActionContainerService);
		containerService.init({
			datasetCode,
			actionCode: 'detail',
			parentDatasetRS: parents && parents.createWithParentDatasetRS,
			parentDatasetACS: parents && parents.createWithParentDatasetACS,
			fetchData: false
		});
		const openType = containerService.getDefaultOpenType('detail');

		if(openType === DatasetActionOpenType.DIALOG){
			this.openDetailDialog(containerService, recordId);
		}else if(openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/detail/' + containerService.datasetCode + '/' + recordId]);
		}
	}

	openDeleteDialog(callerService: DatasetActionContainerService, record: any, dialogOptions?: any): MatDialogRef<DatasetDeleteDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetDeleteDialogComponent, {
			data: Object.assign({}, {
				datasetCode: callerService.datasetCode,
				recordId: record.id,
				record: record,
				datasetData: callerService.datasetData,
				parentDatasetACS: callerService
			}, dialogOptions)
		});

		dialogRef.afterClosed()
		.subscribe(result => {
			if (result){
				callerService.datasetEvent.next({
					eventName: DatasetEvents.DELETED,
					data: {
						id: record.id
					}
				});
			}
		});
		return dialogRef;
	}

	openRestoreDialog(callerService: DatasetActionContainerService, record: any, dialodOptions?: any): MatDialogRef<DatasetRestoreDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetRestoreDialogComponent, {
			data: Object.assign({}, {
				datasetCode: callerService.datasetCode,
				recordId: record.id,
				record: record,
				datasetData: callerService.datasetData,
				parentDatasetACS: callerService,
			}, dialodOptions)
		});
		dialogRef.afterClosed()
		.subscribe(result => {
			if (result){
				callerService.datasetEvent.next({
					eventName: DatasetEvents.ACTION_REALOAD_LIST,
					data: {
						id: record.id
					}
				});
			}
		});
		return dialogRef;
	}
}
