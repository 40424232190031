import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { bokunFieldsMap } from '../../fields/touroperators/bokun.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	bokunFieldsMap.get('enabled'),
	bokunFieldsMap.get('name'),
	bokunFieldsMap.get('parameters.endpoint'),
	bokunFieldsMap.get('parameters.api_key'),
	bokunFieldsMap.get('parameters.private_key'),
	bokunFieldsMap.get('parameters.tangerine_auth'),
	bokunFieldsMap.get('entity_customer.entity_customer_id'),
	bokunFieldsMap.get('entity_customer.description'),
	bokunFieldsMap.get('scenario'),
];

const bokunForm: IFormConfig = {
	editTitle: 'Modifica Configurazioni Bokun',
	fields: formFields 
};

export default bokunForm;
