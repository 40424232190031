import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService } from '../trenitalia.service';

@Component({
	selector: 'trenitalia-countdown',
	templateUrl: './countdown.component.html',
	styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
	private subscription: Subscription;
	public dateNow = new Date();
	public dDay = new Date();
	@Output('end') end = new EventEmitter();
	@Input('dataC') datac: any;
	public timeDifference;
	public secondsToDday;
	public minutesToDday;

	constructor(
		private _snackBar: MatSnackBar,
		private _trnService: TrenitaliaService
	) {}

	ngOnInit() {
		this.dDay = new Date(this.datac);
		this.dDay.setMinutes(this.dDay.getMinutes() + 30);
		this.subscription = interval(1000).subscribe(x => {
			this.getTimeDifference();
			if (this.minutesToDday == 2 && this.secondsToDday == 59) {
				this._snackBar.open(
					'Attenzione il tempo sta per scadere!',
					null,
					{ duration: 5000 }
				);
			} else if (this.minutesToDday <= 0 && this.secondsToDday <= 0) {
				this.end.emit('end');
				this.subscription.unsubscribe();
			}
		});
	}

	private getTimeDifference() {
		this.timeDifference = this.dDay.getTime() - new Date().getTime();
		this.allocateTimeUnits(this.timeDifference);
	}

	private allocateTimeUnits(timeDifference) {
		this.secondsToDday = Math.max(Math.floor((timeDifference / 1000) % 60), 0);
		this.minutesToDday = Math.max(Math.floor((timeDifference / (1000 * 60)) % 60), 0);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
