import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

export interface IConfirmDialogData {
	title?: string;
	contentMessage: string;
	positiveText?: string;
	negativeText?: string;
	neutralText?: string;
	reasonLabel?: string;
	reasonRequired?: boolean;
	reasonHint?: string;
}

@Component({
	selector: 'confirm-with-reason-dialog',
	templateUrl: 'dialog.component.html'
})
export class ConfirmWithReasonDialogComponent implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;
	public form: FormGroup;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<ConfirmWithReasonDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
		private fb: FormBuilder
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		const reasonConfig: any[] = [''];
		if(this.data.reasonRequired){
			reasonConfig.push(Validators.required);
		}
		this.form = this.fb.group({
			reason: reasonConfig
		});
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		if(this.form.valid){
			this.dialogRef.close(this.form.getRawValue());
		}
	}

	onNeutralClick(): void {
		this.dialogRef.close('ni');
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getData(){
		return this.form.getRawValue();
	}

}
