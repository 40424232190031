import { Component, SkipSelf, Self, Inject } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { TripFacilityConfig } from 'app/configs/datasets/trip_facilities';
import { FormBuilder } from '@angular/forms';
import { TripPackageTripFacilitiesComponent } from '../pivot_trip_packages_trip_facilities/step.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'resell-trip-package-trip-facilities',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ 
		{ 
			provide: 'originalDatasetACS', 
			useClass: DatasetActionContainerService
		},
		{ 
			provide: 'reselledDatasetACS', 
			useClass: DatasetActionContainerService
		},
		{ 
			provide: 'reselledDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'originalDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'reselledMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		},
		{ 
			provide: 'originalMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		} 
	]
})
export class ResellTripPackageTripFacilitiesComponent extends TripPackageTripFacilitiesComponent{

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Inject('reselledDatasetACS')
		@Self()
		public reselledDatasetACS: DatasetActionContainerService,
		@Self()
		@Inject('originalDatasetACS')
		public originalDatasetACS: DatasetActionContainerService,
		@Self()
		@Inject('reselledDatasetRS')
		public reselledDatasetRS: DatasetRecordService,
		@Self()
		@Inject('originalDatasetRS')
		public originalDatasetRS: DatasetRecordService,
		@Self()
		@Inject('reselledMultilanguageService')
		public reselledDatasetMCS: DatasetMultilanguageContainerService,
		@Self()
		@Inject('originalMultilanguageService')
		public originalDatasetMCS: DatasetMultilanguageContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		protected fb: FormBuilder
	){
		super(appService, reselledDatasetRS, reselledDatasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, fb);

		this.configs = {
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentForeignKey: 'trip_package_id',
			parentValueKey: 'trip_packages.recordId'
		};
	}

	ngOnInit(): void{
		this.formConfig = TripFacilityConfig.formConfigsMap['generalForm'];
		this.filterFormGroup = this.fb.group({trip_facility_id: null});

		this.reselledDatasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.reselledDatasetRS,
			fetchData: true
		});
		this.originalDatasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'detail',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.originalDatasetRS,
			fetchData: true
		});
		this.originalDatasetRS.datasetACS = this.originalDatasetACS;
		this.reselledDatasetRS.datasetACS = this.reselledDatasetACS;

		this.reselledDatasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.reselledDatasetMCS.init({
				datasetACS: this.reselledDatasetACS,
				datasetRS: this.reselledDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'reselledData.id'
			});
			this.reselledDatasetMCS.fetchRecord();
		});

		this.originalDatasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.originalDatasetMCS.init({
				datasetACS: this.originalDatasetACS,
				datasetRS: this.originalDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			});
			this.originalDatasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record){
				this.reselledDatasetMCS.activeLanguageCodes = record.active_language_codes;
				this.originalDatasetMCS.activeLanguageCodes = record.active_language_codes;
			}
		});
		
	}
}
