import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, InputTypes, ValueTypes, IFieldDefinition, FieldViews, IDatasetCallDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';
import generalForm from './forms/general.form';
import generalReadForm from './forms/general-read.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/trip_options/detail/:recordId'
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	return params;
}

export const TripOptionsForVirtualTripsConfig: IDatasetConfig = {
	name: 'trip_options_for_virtual_trips',
	title: 'Opzioni Partenze',
	singleTitle: 'Opzioni Partenze',
	ajaxDatasetCode: 'trip_options',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		delete: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		generalReadForm
	},
	defaultActions: {
		list: {
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CUSTOM,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc'
			},
		},
		edit: {
			getCallParams,
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		create: {
			getCallParams,
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
	}
};
