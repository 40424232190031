import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TrenitaliaRecapComponent} from './trenitalia-recap.component';
import {MatIconModule} from '@angular/material/icon';
import {FlexModule} from "@angular/flex-layout";
import {MatCardModule} from "@angular/material/card";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TrenitaliaDialogModule} from "../trenitalia-dialog.module";
import {MatButtonModule} from "@angular/material/button";
import {TrainBreadcrumbsModule} from "../components/train-breadcrumbs/train-breadcrumbs.module";
import {MatDividerModule} from "@angular/material/divider";



@NgModule({
  declarations: [TrenitaliaRecapComponent],
    imports: [
        CommonModule,
        MatIconModule,
        TrainBreadcrumbsModule,
        MatTooltipModule,
        MatButtonModule,
        MatDividerModule
    ],
    entryComponents:[TrenitaliaRecapComponent],
    exports:[TrenitaliaRecapComponent]
})
export class TrenitaliaRecapModule { }
