import {
	InputTypes,
	ValueTypes,
	IFormConfig,
	IFieldDefinition,
} from 'app/interfaces';
import { Injector } from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export const stationExtraSetting = {
	loading(injector: Injector){
		const trenitaliaService = injector.get(TrenitaliaService);
		if(!trenitaliaService) return of(false);
		return trenitaliaService.loadingStations;
	},
	allOptions(injector: Injector): Observable<any[]>{
		const trenitaliaService = injector.get(TrenitaliaService);
		if(!trenitaliaService) return of([]);

		return trenitaliaService.stations.pipe(
			map(stations => {
				if(!stations) return [];
				return stations.map(s => ({
					label: s.name,
					value: s.service_id,
					aliases: s.aliases
				}))
			})
		);
	}
};

const fieldsMap: Map<string, IFieldDefinition> = new Map();
fieldsMap.set('ar', {
	key: 'ar',
	name: 'A/R',
	inputType: InputTypes.TOGGLE,
	valueType: ValueTypes.BOOLEAN,
});
fieldsMap.set('start_station', {
	key: 'start_station',
	name: 'Partenza',
	inputConfig: {
		clearable: true,
		placeholder: 'Da',
		type: InputTypes.SELECT_FILTERABLE,
		appearence: 'fill'
	},
	valueType: ValueTypes.STRING,
	extra: stationExtraSetting
});

fieldsMap.set('end_station', {
	key: 'end_station',
	name: 'Destinazione',
	inputType: InputTypes.SELECT_FILTERABLE,
	valueType: ValueTypes.STRING,
	extra: stationExtraSetting
});
fieldsMap.set('date', {
	key: 'date',
	name: 'Partenza',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});
fieldsMap.set('time_d', {
	key: 'time_d',
	name: '',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
});
fieldsMap.set('date_r', {
	key: 'date_r',
	name: 'Ritorno',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	showIf(record: any) {
		return record.ar;
	},
});
fieldsMap.set('time_r', {
	key: 'time_r',
	name: '',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	showIf(record: any) {
		return record.ar;
	},
});

fieldsMap.set('adt_counter', {
	key: 'adt_counter',
	name: '',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('chd_counter', {
	key: 'chd_counter',
	name: '',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER,
});

export { fieldsMap };
