import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { startOfDay, isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';

import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { takeUntil } from 'rxjs/operators';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { IListViewConfig, IPaginationResponse } from 'app/interfaces';
import { CalendarService } from '../calendar.service';

@Component({
	selector     : 'dataset-calendar-header-view',
	templateUrl  : './calendar-header.component.html',
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class DatasetCalendarHeaderViewComponent implements OnInit, OnDestroy, OnChanges{
	_unsubscribeAll: Subject<any>;

	@Output() viewTypeChange = new EventEmitter<string>();
	@Output() viewDateChange = new EventEmitter<any>();
	@Output() addClicked = new EventEmitter<void>();

	public canCreate = false;

	constructor(
		public calendarService: CalendarService,
		public datasetNavigatorService: DatasetNavigatorService,
		public datasetACS: DatasetActionContainerService,
		protected matDialog: MatDialog
	){
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.canCreate = this.datasetACS.actionEnabled('create');
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{}

	onAddClick(): void{
		this.addClicked.emit();
	}
}
