import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { CurrencyPipe } from '@angular/common';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import pickupPointField from 'app/configs/commons/shared-field-configs/pickup-point.field';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import userField from 'app/configs/commons/shared-field-configs/user.field';
import branchesField from 'app/configs/commons/shared-field-configs/branches.field';
import tripCategoryIdField from 'app/configs/commons/shared-field-configs/trip-category-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();


fieldsMap.set('display_name', {
	key: 'display_name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('turnover', {
	key: 'turnover',
	name: 'Fatturato Lordo',
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('turnover_net', {
	key: 'turnover_net',
	name: 'Fatturato Netto',
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('tickets', {
	key: 'tickets',
	name: 'Tickets',
	valueType: ValueTypes.NUMBER,
});


/**
 * Start Filter fields
 */

fieldsMap.set('start_day_from', {
	key: 'start_day_from',
	name: 'Dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('start_day_to', {
	key: 'start_day_to',
	name: 'al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

export {fieldsMap};
