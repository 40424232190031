import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { FormViewerService } from "app/services/form-viewer.service";
import { fieldsMap as filterFields } from './filter.fields';
import { DatasetListComponent } from "app/main/components/dataset/base-actions/list/dataset-list.component";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { FieldViews, ValueTypes } from "app/interfaces";
import { TripBookingPackagesStepService } from "../../trip-booking-packages-step.service";
import { ActionDialogComponent } from "../../../action-dialog/action-dialog.component";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { config as productSelectionViewConfig } from 'app/configs/datasets/products/selectionViewConfig';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../../edit-booking.component";
import { AppService } from "app/services/app.service";
import { showFieldBasedOnConfig } from "../../../../../../configs/commons/shared-field-configs/show-field-on-config.field";
var ProductSelectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductSelectionComponent, _super);
    function ProductSelectionComponent(formViewerService, datasetRS, datasetACS, tripBookingPackagesStepService, datasetNavigator, appService) {
        var _this = _super.call(this) || this;
        _this.formViewerService = formViewerService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.datasetNavigator = datasetNavigator;
        _this.appService = appService;
        _this.filterFields = filterFields;
        _this.filters = {};
        return _this;
    }
    Object.defineProperty(ProductSelectionComponent.prototype, "productSelectionViewConfig", {
        get: function () {
            return Object.assign({}, productSelectionViewConfig.viewConfig, {
                showSearchBar: true,
                columns: [
                    {
                        title: 'Tipo',
                        key: 'product_type',
                        fieldDefinition: {
                            key: 'product_type',
                            name: 'Tipo',
                            valueType: ValueTypes.STRING
                        },
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value) {
                            if (!value || value == 'standard')
                                return 'Standard';
                            if (value == 'travelmate')
                                return 'Audioguida';
                            if (value == 'esim-manet')
                                return 'ESim';
                            return value;
                        }
                    },
                    {
                        title: 'Prodotto',
                        key: 'description'
                    },
                    {
                        title: 'Stato',
                        key: 'code',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value, translateService) {
                            var colorClass = 'orange-bg';
                            var text = '<span class="status">' + translateService.instant('Non Pubblico') + '</span>';
                            if (record.public_product_on_web === 1) {
                                colorClass = 'green-bg';
                                text = '<span class="status">' + translateService.instant('Pubblicato') + '</span>';
                            }
                            var html = '<div class="package_status" style="display:flex;align-items: center; justify-content: space-between;">';
                            html += '<div>' + text + '<br\><span>' + record.code + '</span></div>';
                            html += '<span class="' + colorClass + ' record-status-bar" style="width:12px; height:12px; border-radius:50%;"></span>';
                            html += '</div>';
                            return html;
                        },
                        showIf: function (dataContainerService) {
                            return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_columns', 'status', true);
                        }
                    },
                    {
                        title: 'Prezzo',
                        key: 'price',
                        fieldDefinition: {
                            key: 'price',
                            name: 'Prezzo',
                            valueType: ValueTypes.STRING
                        },
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value) {
                            if (record.properties.price.value)
                                return '€ ' + record.properties.price.value;
                            return null;
                        },
                        showIf: function (dataContainerService) {
                            return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_columns', 'price', false);
                        }
                    },
                ],
            });
        },
        enumerable: true,
        configurable: true
    });
    ProductSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formViewerService.init({
            fields: Array.from(filterFields.values())
        });
        this.formViewerService.formGroup.valueChanges
            .subscribe(function (filterFields) {
            _this.filters = Object.assign({}, _this.filters, {});
        });
    };
    ProductSelectionComponent.prototype.addTripBookingPackage = function (product) {
        var _this = this;
        this.tripBookingPackagesStepService
            .requestBookingData(product, 'product')
            .subscribe(function (postData) {
            if (!postData)
                return;
            var dialogRef = _this.datasetNavigator.openDialog(ActionDialogComponent, {
                title: 'Attendere',
                contentMessage: ''
            });
            var actionEndPoint = '/dataset/trip_booking_packages/create';
            _this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
                .subscribe({
                complete: function () {
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                    dialogRef.close();
                },
                error: function (err) {
                    dialogRef.componentInstance.resultStatus = 'error';
                    dialogRef.componentInstance.loading = false;
                    if (err && err.error && err.error.message)
                        dialogRef.componentInstance.setMessage('Si è verificato un errore<br> ' + err.error.message);
                    else
                        dialogRef.componentInstance.setMessage('Si è verificato un errore');
                    dialogRef.componentInstance.setTitle(null);
                }
            });
        });
    };
    return ProductSelectionComponent;
}(DestroyableComponent));
export { ProductSelectionComponent };
