import { Component, Input, Self, SkipSelf, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { HttpHeaders } from '@angular/common/http';
import { IStepConfig } from 'app/interfaces';
import { InvalidAllotmentsDialogComponent } from 'app/main/components/dataset/dialogs/invalid-allotments-dialog/dialog.component';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector   : 'trip-package-reselled-step-0',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ DatasetActionContainerService, DatasetRecordService]
})
export class TripPackageReselledStep0Component extends BaseStepViewComponent{
	
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;
	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;
	public formErrors: any;

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		protected matDialog: MatDialog
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_packages',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.datasetRS,
			fetchData: false
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetRS.record.next(this.parentDatasetACS.getValueFromKeyPath('reselledData'));
		});
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		if(this.stepConfig.options && typeof(this.stepConfig.options.getCallParams) === 'function'){
			formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
		}
		this.datasetACS.loading.next(true);
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/update/' + this.datasetRS.recordId;
		const headers: HttpHeaders = this.datasetACS.getContextualDomainHeaders();
		this.datasetService.post<any>(route, formData, {headers: headers})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.clearForm();
				}
				
				this.datasetRS.recordId = response.id;
				this.datasetRS.reload()
				.subscribe(value => {
					if(value){
						this.parentDatasetACS.pageClosureContainer.putToSharedData({reselledData: value});
						setTimeout(() => {
							this.goNextStep();
						}, 200);
					};
				});
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}
				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			}
		});
	}
}
