<h1 mat-dialog-title>{{data.title | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetACS.loading.value'></mat-progress-bar>

	<div *ngIf='!datasetACS.loading.value' class='mb-24'>
		<dataset-list
			[enableCreate]='false'
			[selectable]='false'
			[elevate]='false'
			[showSearchBar]='true'
			[showTitle]='false'
			[enableEdit]='false'
			[enableDelete]='false'
			[showCreateForm]='false'
			[enableActions]='false'
			[showExportBtn]='false'
			[datasetCode]='datasetACS.datasetCode'
			[parentDatasetACS]='datasetACS'
			[filters]='data.filters'
			[viewConfig]='data.viewConfig'
		></dataset-list>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
