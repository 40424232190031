import {Component, Inject, OnDestroy, OnInit, forwardRef, Injector} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { IListViewConfig } from 'app/interfaces';
import { DatasetRecordService } from '../../services/dataset-record.service';

export interface IDatasetListDialogData {
	title?: string;
	datasetCode: string;
	filters?: any;
	viewConfig?: IListViewConfig;
	parentDatasetACS?: DatasetActionContainerService;
	parentDatasetRS?: DatasetRecordService;
	domainId?: string;
}

@Component({
	selector: 'dataset-list-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: ['./dialog.components.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService), 
		forwardRef(() => DatasetActionContainerService)
	]
})
export class DatasetListDialogComponent implements OnInit, OnDestroy{

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		@Inject(forwardRef(() => PageClosureContainerService)) public pageClosureContainerPage: PageClosureContainerService,
		@Inject(forwardRef(() => DatasetActionContainerService)) public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<DatasetListDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDatasetListDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.data.datasetCode,
			actionCode: 'list',
			fetchData: false,
			parentDatasetACS: this.data.parentDatasetACS,
			parentDatasetRS: this.data.parentDatasetRS,
		});
		if(this.data.domainId) {
			this.datasetACS.putContextData('contextual_domain_id', this.data.domainId)
		}
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}
}
