/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../base-actions/list/dataset-list.component.ngfactory";
import * as i3 from "../../../services/dataset-action-container.service";
import * as i4 from "../../../../../../services/app.service";
import * as i5 from "../../../../../pages/page-closure-container.service";
import * as i6 from "../../../../../../services/auth.service";
import * as i7 from "../../../services/dataset.service";
import * as i8 from "../../../../../../services/domain-filter.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../base-actions/list/dataset-list.service";
import * as i11 from "../../../base-actions/list/dataset-list.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../services/dataset-navigator.service";
import * as i14 from "../../../../../../../@fuse/services/translation-loader.service";
import * as i15 from "@angular/material/snack-bar";
import * as i16 from "../../../../../../services/websocket.service";
import * as i17 from "../../../../document-file-block/view.component.ngfactory";
import * as i18 from "../../../../document-file-block/view.component";
import * as i19 from "@angular/material/dialog";
import * as i20 from "@angular/common";
import * as i21 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i22 from "@angular/material/progress-bar";
import * as i23 from "@angular/platform-browser/animations";
import * as i24 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i25 from "@angular/material/button";
import * as i26 from "@angular/cdk/a11y";
import * as i27 from "../../../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i28 from "@angular/material/tabs";
import * as i29 from "../../../../language-select/language-select.component.ngfactory";
import * as i30 from "../../../../language-select/language-select.component";
import * as i31 from "../../../services/dataset-record.service";
import * as i32 from "../../../services/dataset-multilanguage-container.service";
import * as i33 from "./step.component";
var styles_CommonI18nBlocksStepComponent = [i0.styles];
var RenderType_CommonI18nBlocksStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommonI18nBlocksStepComponent, data: {} });
export { RenderType_CommonI18nBlocksStepComponent as RenderType_CommonI18nBlocksStepComponent };
function View_CommonI18nBlocksStepComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "dataset-list", [], null, [[null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("created" === en)) {
        var pd_0 = (_co.onCreatedInBlock(_v.parent.context.blockConfig) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DatasetListComponent_0, i2.RenderType_DatasetListComponent)), i1.ɵprd(512, null, i3.DatasetActionContainerService, i3.DatasetActionContainerService, [i4.AppService, i5.PageClosureContainerService, i6.AuthService, i7.DatasetService, i8.DomainFilterService, i9.TranslateService, i1.Injector]), i1.ɵprd(512, null, "ListParamService", i10.DatasetListService, [i3.DatasetActionContainerService, i7.DatasetService]), i1.ɵdid(3, 770048, [[1, 4], ["listBlocks", 4]], 0, i11.DatasetListComponent, [i12.ActivatedRoute, i7.DatasetService, i12.Router, i6.AuthService, i4.AppService, i3.DatasetActionContainerService, i13.DatasetNavigatorService, i8.DomainFilterService, "ListParamService", i14.FuseTranslationLoaderService, i15.MatSnackBar, i16.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], parentDatasetACS: [2, "parentDatasetACS"], parentDatasetRS: [3, "parentDatasetRS"], filters: [4, "filters"], showSearchBar: [5, "showSearchBar"], elevate: [6, "elevate"], conditionalShowPaginationToolbar: [7, "conditionalShowPaginationToolbar"] }, { created: "created" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_v.parent.context.inTab; var currVal_1 = _v.parent.context.blockConfig.datasetCode; var currVal_2 = _co.datasetACS; var currVal_3 = _co.datasetRS; var currVal_4 = _co.blockFilters[_v.parent.context.index]; var currVal_5 = false; var currVal_6 = false; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_CommonI18nBlocksStepComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-file-block", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.datasetRS.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_DocumentFileBlockComponent_0, i17.RenderType_DocumentFileBlockComponent)), i1.ɵdid(1, 770048, null, 0, i18.DocumentFileBlockComponent, [i4.AppService, i13.DatasetNavigatorService, i3.DatasetActionContainerService, i7.DatasetService, i19.MatDialog], { record: [0, "record"], documentFileKey: [1, "documentFileKey"], uploadRoute: [2, "uploadRoute"], title: [3, "title"], type: [4, "type"], uploadType: [5, "uploadType"], getUploadDataFun: [6, "getUploadDataFun"], imageOptions: [7, "imageOptions"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetRS.record.value; var currVal_1 = _v.parent.context.blockConfig.documentFileKey; var currVal_2 = _v.parent.context.blockConfig.uploadRoute; var currVal_3 = (!_v.parent.context.inTab && _v.parent.context.blockConfig.title); var currVal_4 = "image"; var currVal_5 = _v.parent.context.blockConfig.uploadType; var currVal_6 = _v.parent.context.blockConfig.getUploadData; var currVal_7 = _v.parent.context.blockConfig.imageOptions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_CommonI18nBlocksStepComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ": ", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Blocco non configurato")); var currVal_1 = _v.parent.context.blockConfig.type; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_CommonI18nBlocksStepComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i20.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_2)), i1.ɵdid(3, 278528, null, 0, i20.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i20.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_3)), i1.ɵdid(5, 278528, null, 0, i20.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i20.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_4)), i1.ɵdid(7, 16384, null, 0, i20.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i20.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.blockConfig.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "list"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "document_file"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_CommonI18nBlocksStepComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i21.View_MatProgressBar_0, i21.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i22.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i23.ANIMATION_MODULE_TYPE], [2, i22.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CommonI18nBlocksStepComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["color", "primary"], ["mat-stroked-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goPrevStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i24.View_MatButton_0, i24.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i25.MatButton, [i1.ElementRef, i26.FocusMonitor, [2, i23.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Inserisci prima le descrizioni in lingua"]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Questa traduzione non \u00E8 ancora presente!")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_CommonI18nBlocksStepComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 0, 0, currVal_0); }); }
function View_CommonI18nBlocksStepComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i20.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { blockConfig: 0, inTab: 1, index: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit, true, _v.parent.context.index); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent.parent, 1); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CommonI18nBlocksStepComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 7, "mat-tab", [], null, null, null, i27.View_MatTab_0, i27.RenderType_MatTab)), i1.ɵdid(2, 770048, [[2, 4]], 2, i28.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 3, { templateLabel: 0 }), i1.ɵqud(335544320, 4, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CommonI18nBlocksStepComponent_11)), i1.ɵdid(6, 16384, [[3, 4]], 0, i28.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(0, [[4, 2]], 0, 1, null, View_CommonI18nBlocksStepComponent_12)), i1.ɵdid(8, 16384, null, 0, i28.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CommonI18nBlocksStepComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i27.View_MatTabGroup_0, i27.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i28.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i28.MAT_TABS_CONFIG], [2, i23.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_10)), i1.ɵdid(4, 278528, null, 0, i20.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.stepConfig.options.blocks; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CommonI18nBlocksStepComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i20.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { blockConfig: 0, inTab: 1, index: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit, false, _v.context.index); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CommonI18nBlocksStepComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_14)), i1.ɵdid(2, 278528, null, 0, i20.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig.options.blocks; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CommonI18nBlocksStepComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_9)), i1.ɵdid(2, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_13)), i1.ɵdid(4, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.stepConfig.options.blocks.length > 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.stepConfig.options.blocks.length == 1); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CommonI18nBlocksStepComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_7)), i1.ɵdid(2, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_8)), i1.ɵdid(4, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.datasetRS.record.value && !_co.datasetACS.loading.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.datasetRS.record.value && (_co.datasetRS.record.value["lang"] === _co.appService.language.value)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CommonI18nBlocksStepComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { listBlocks: 1 }), (_l()(), i1.ɵand(0, [["blockTemplate", 2]], null, 0, null, View_CommonI18nBlocksStepComponent_1)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "language-select", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.appService.language.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i29.View_LanguageSelectComponent_0, i29.RenderType_LanguageSelectComponent)), i1.ɵdid(4, 114688, null, 0, i30.LanguageSelectComponent, [i4.AppService], { languageCodes: [0, "languageCodes"], activeLanguageCodes: [1, "activeLanguageCodes"], selected: [2, "selected"] }, { changed: "changed" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_5)), i1.ɵdid(6, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommonI18nBlocksStepComponent_6)), i1.ɵdid(8, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appService.supportedLanguageCodes; var currVal_1 = _co.datasetMCS.activeLanguageCodes; var currVal_2 = _co.appService.language.value; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.datasetACS.loading.value; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.datasetACS.ready.value; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_CommonI18nBlocksStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "common-i18n-blocks-step", [], null, null, null, View_CommonI18nBlocksStepComponent_0, RenderType_CommonI18nBlocksStepComponent)), i1.ɵprd(512, null, i3.DatasetActionContainerService, i3.DatasetActionContainerService, [i4.AppService, i5.PageClosureContainerService, i6.AuthService, i7.DatasetService, i8.DomainFilterService, i9.TranslateService, i1.Injector]), i1.ɵprd(512, null, i31.DatasetRecordService, i31.DatasetRecordService, [i3.DatasetActionContainerService, i7.DatasetService]), i1.ɵprd(512, null, i32.DatasetMultilanguageContainerService, i32.DatasetMultilanguageContainerService, [i4.AppService, i7.DatasetService]), i1.ɵdid(4, 4440064, null, 0, i33.CommonI18nBlocksStepComponent, [i4.AppService, i31.DatasetRecordService, i3.DatasetActionContainerService, i13.DatasetNavigatorService, i7.DatasetService, i32.DatasetMultilanguageContainerService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var CommonI18nBlocksStepComponentNgFactory = i1.ɵccf("common-i18n-blocks-step", i33.CommonI18nBlocksStepComponent, View_CommonI18nBlocksStepComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { CommonI18nBlocksStepComponentNgFactory as CommonI18nBlocksStepComponentNgFactory };
