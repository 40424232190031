
<ng-template #fullFormTemplate let-form_config='formConfig'>
	<div class='full-form' [ngClass]='form_config.formStyle || "style-1"'>
		<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
		<form-viewer #formViewer
			[record]='datasetRS.record.value'
			[dataContainerService]='datasetACS' 
			[formConfig]='form_config'
		></form-viewer>
	
		<div *ngIf='datasetACS.canEdit(datasetRS.record.value)' class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<!--<button mat-raised-button>Annulla</button>-->
			<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='onSave()'>
				<span *ngIf='!datasetACS.loading.value'>Salva</span>
				<span *ngIf='datasetACS.loading.value'>Azione in corso ...</span>
			</button>
		</div>
	</div>
</ng-template>

<ng-container *ngIf='inputFormConfig'>
	<ng-container [ngTemplateOutlet]="fullFormTemplate" [ngTemplateOutletContext]='{formConfig:inputFormConfig}'></ng-container>
</ng-container>
<ng-container *ngIf='!inputFormConfig && datasetACS.datasetConfig && datasetACS.datasetConfig.stepViewConfig'>
	<ng-container *ngIf='datasetACS.datasetConfig && datasetACS.datasetConfig.stepViewConfig'>
		<ng-container [ngSwitch]='datasetACS.datasetCode'>
			<ng-container *ngSwitchCase='"trip_packages"'>
				<ng-container [ngSwitch]='datasetACS.getValueFromKeyPath("domainContext.domainTpl")'>
					<ng-container *ngSwitchCase='1'>
						<simp-temp-view-container
							[stepViewConfig]='stepViewConfig'
						></simp-temp-view-container>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<common-step-view-container
							[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
						></common-step-view-container>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase='"products"'>
				<simp-temp-view-container
					[stepViewConfig]='productStepViewConfig'
				></simp-temp-view-container>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<common-step-view-container
					[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
				></common-step-view-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>

<ng-container *ngIf='!inputFormConfig && datasetACS.datasetConfig && !datasetACS.datasetConfig.stepViewConfig'>
	<ng-container [ngSwitch]='datasetACS.datasetConfig.name'>
		
		<ng-container *ngSwitchDefault>
			<ng-container *ngIf='!datasetACS.actionConfig || !datasetACS.actionConfig.viewConfig || !datasetACS.actionConfig.viewConfig.formConfig'>
				<h2>In Construction</h2>
			</ng-container>
			<ng-container *ngIf='datasetACS.actionConfig && datasetACS.actionConfig.viewConfig && datasetACS.actionConfig.viewConfig.formConfig'>
				<ng-container [ngTemplateOutlet]="fullFormTemplate" [ngTemplateOutletContext]='{formConfig: datasetACS.actionConfig.viewConfig.formConfig}'></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
	