import { ValueTypes, FieldViews, IColumnDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import sendVoucherAction from './send-voucher.action';
import { DatasetListDialogComponent, IDatasetListDialogData } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { ITextDialogData, TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import {hideIfCustomDisableFields, showIfCustomAddFields,} from "../../commons/shared-field-configs/custom_field.field";
import * as _ from 'lodash';

const onClick = (event, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService) =>
	datasetNavigatorService.openDialog<ITextDialogData>(TextDialogComponent, {
		title: 'Download file',
		negativeText: 'Annulla',
		contentMessage: ((): string => {
			let html = '<ul>';
			if(record.download_ticket_file){
				for(const file of record.download_ticket_file) {
					if (file.participants_pdf_url) {
						html += `<li>${file.name}<ol>`
						for (const participants_pdf_url of file.participants_pdf_url) {
							html += `<li>Partecipante <a href="${participants_pdf_url.url}">Scarica: ${file.name}</a>
							<span>(Numero di download: ${participants_pdf_url.download_count})</span></li>`;
						}
						html += '</ol></li>'; 
					} else if(file.trip_booking_resource_id) {
						// OLD VERSION (DEPRECATED)
						html += `<li>
						<a href="${getBaseServerAddressRoot()}/ajax/download/ticket/${file.trip_booking_resource_id}">Scarica: ${file.name}</a>
						<span>(Numero di download: ${file.download_count})</span>
						</li>`; 
					}
				}
			}
			html += '</ul>';
			return html;
		})()
	}).afterClosed();

function getTicketDownloadTotalCount(record){
	let countDownload = 0;
	if(!record || !record.download_ticket_file) return 0;
	for(const file of record.download_ticket_file) {
		if (file.participants_pdf_url){
			for (const participants_pdf_url of file.participants_pdf_url) {
				if (!participants_pdf_url.download_count) continue;
				countDownload += participants_pdf_url.download_count;
			}
		} else {
			// OLD VERSION (DEPRECATED)
			if(!file.download_count) continue;
			countDownload += file.download_count;
		}
	}
	return countDownload;
}

export const ClassForegroundColorByBookingStatus = {
	draft: '',
	unconfirmed: '',
	confirmed: 'green-fg',
	optional: 'orange-fg',
	expired: 'warn-fg',
	canceled: 'warn-fg'
}

export function getHTMLBookingStatus(bookingStatus){
	if(bookingStatus === 'draft') return 'Preventivo';
	if(bookingStatus === 'confirmed'){
		let html = '<div class="booking_status" style="display:inline-flex;flex-direction:row;align-items: center;">';
		// html += '<mat-icon role="img" class="primary-fg mr-4 mat-icon material-icons mat-icon-no-color">check</mat-icon>';
		html += '<span class="green-fg">Confermata</span>';
		html += '</div>';
		return html;
	}
	if(bookingStatus === 'optional') return '<span class="orange-fg">Opzionale (in scadenza)</span>';
	if(bookingStatus === 'expired') return '<span class="warn-fg">Scaduta</span>';
	if(bookingStatus === 'unconfirmed') return 'Non Confermata';
	if(bookingStatus === 'canceled') return '<span class="warn-fg">Annullata</span>';
	return '';
}
export function getHtmlBookingWithCodeAndStatus(bookingCode, bookingStatus){
	const label = bookingCode || '';
	let foregroundColor = '';
	const status = getHTMLBookingStatus(bookingStatus);
	return '<span class="' + foregroundColor + '">' + label + '<br>' + status + '</span>';
}

export const LIST_COLUMNS: IColumnDefinition[] = [
	{
		title: 'Codice/Stato',
		key: 'status_column',
		
		/*onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
			datasetNavigatorService.router.navigate(['/edit-booking/' + record.id]);
		},*/
		fieldDefinition: {
			name: 'Status',
			key: 'status',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				const isAdminStyle = record.allowed_actions.includes('view_logs') ? 'cursor: pointer;' : '';
				let htmlResult = getHtmlBookingWithCodeAndStatus(record.trip_booking_booking_code, record.trip_booking_package_booking_status);
				if(record.pratica_code){
					htmlResult += '<br><small class="secondary-text">Pratica: ' + record.pratica_code + '</small>';
				}
				if(record.delivery_status){
					if(record.delivery_status === 'consegnato'){
						htmlResult += '<br><span class="green-fg">Consegnato</span>';
					}
					if(record.delivery_status === 'in consegna'){
						htmlResult += '<br><span class="orange-fg">In Consegna</span>';
					}
					if(record.delivery_status === 'standby'){
						htmlResult += '<br><span class="orange-fg">Stand by</span>';
					}
					if(record.delivery_status === 'scaduto'){
						htmlResult += '<br><span class="red-fg">Scaduto</span>';
					}
				}else if(record.trip_package_target == 'ticket'){
					htmlResult += '<br><span class="orange-fg">Stand by</span>';
				}
				return '<div style="' + isAdminStyle + '">' + htmlResult + '</div>';
			},
		},
		onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
			if (record.allowed_actions.includes('view_logs')){
				datasetNavigatorService.openDialog<IDatasetListDialogData>(DatasetListDialogComponent, {
					title: 'Lista logs prenotazione',
					datasetCode: 'trip_booking_logs',
					parentDatasetACS: datasetACS,
					domainId: _.get(record, 'domain.id'),
					filters: {
						trip_booking_id: record.trip_booking_id
					}
				});
			}
		}
	},
	/*{
		title: 'Codice',
		key: 'booking_code'
	},*/
	{
		title: 'Pacchetto',
		key: 'trip_package_description',
		fieldDefinition: {
			name: 'Pacchetto',
			key: 'trip_package_description',
			valueType: ValueTypes.STRING
		}
	},
	{
		title: 'Data Prenotazione',
		key: 'booking_date',
	},
	{
		title: 'Dettaglio partenza',
		key: 'start_day',
		fieldDefinition: {
			name: 'Data Partenza',
			key: 'start_day',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				let label = '';
				if(!record) return '';
				if(record.actual_trip_meeting_point_list_description){
					label = record.actual_trip_meeting_point_list_description;
				}
				if(record.actual_trip_start_time){
					label += ' (' + record.actual_trip_start_time.substring(0, 5) + ')';
				}
				if(record.actual_trip_start_day){
					const d = new Date(record.actual_trip_start_day);
					if(!isNaN(d.getTime())){
						label += '<br><span>' + d.toLocaleDateString() + '</span>';
					}
				}
				if(record.pickup_meeting_point_name && record.delivery_status && record.delivery_status != 'standby'){ // show only for delivery
					label += '<br><span>Punto di ritiro: ' + record.pickup_meeting_point_name + '</span>';
				}
				return label;
			}
		}
	},
	{
		title: '# PAX/Passeggero di riferimento',
		key: 'main_participant_name',
		fieldDefinition: {
			name: 'Passeggero di riferimento',
			key: 'main_participant_name',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';
				let label = '# PAX: ' + (record.trip_booking_participant_counters && record.trip_booking_participant_counters.total);
				if(record.trip_booking_entity_name){
					label += '<br>';
					label = "<div style='font-weight: 600; max-width: 250px; overflow: hidden; text-overflow: ellipsis; display: block;' alt='"+record.trip_booking_entity_customer_name+"'>";
					label += record.trip_booking_entity_name;
					label +=  "</div>";
				}
				return label;
			}
		}
	},
	{
		title: 'Presenze',
		key: 'participant_presences',
		fieldDefinition: {
			name: 'Presenze',
			key: 'participant_presences',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayTooltip(record): string{
				let tooltip = '';
				if(record.trip_booking_package_target != 'trip_packages') return '';
				if(!record.trip_booking_participant_counters) return '';
				if(record.trip_booking_participant_counters.ADT) tooltip += record.trip_booking_participant_counters.ADT + " ADT   ";
				if(record.trip_booking_participant_counters.CHD) tooltip += record.trip_booking_participant_counters.CHD + " CHD   ";
				if(record.trip_booking_participant_counters.INF) tooltip += record.trip_booking_participant_counters.INF + " INF   ";
				if(record.trip_booking_participant_counters.SNR) tooltip += record.trip_booking_participant_counters.SNR + " SNR";
				return tooltip;
			},
			getDisplayValue(record: any): string{
				if(!record || !record.trip_booking_participant_counters) return '';
				let styleColor = '#4caf50';
				if (typeof(record.actual_trip_participant_presences) == 'number' && record.actual_trip_participant_presences != record.trip_booking_participant_counters.total){
					styleColor = '#f44336';
				}
				let label = '<div style="cursor: pointer; display: flex; justify-content: space-between; align-items: center; color: ' + styleColor + ';">';
				label += '<span class="material-icons" style="padding-right: 5px; font-size: 18px; border-left: 3px solid ' + styleColor + '; padding-left: 5px; border-radius: 2px;">groups</span>';
				if(record.trip_booking_package_target == 'trip_packages') label += '<span style="font-weight: bold;">' + record.actual_trip_participant_presences + '/' + record.trip_booking_participant_counters.total + '</span>';
				else label += '<span style="font-weight: bold;">' + record.trip_booking_participant_counters.total + '</span>';
				label += '</div>';
				return label;
			}
		}
	},
	{
		title: 'Canale/Utente',
		key: 'origin_channel_code',
		fieldDefinition: {
			name: 'Canale',
			key: 'origin_channel_code',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';
				let value = record.trip_booking_origin_channel_code;

				let label = 'Back Office';

				if(value){
					if(['direct_online', 'zvsite'].includes(value)){
						label = 'Direct Online';
					}else if(['api'].includes(value)){
						label = 'API';
					}else if(['direct_offline'].includes(value)){
						label = 'Direct Offline';
					}else if(['ticketing_mobile'].includes(value)){
						label = 'Mobile';
					}else if(['ticketing_totem'].includes(value)){
						label = 'Totem';
					}
				}

				// TODO: add this data to result
				if(record.trip_booking_created_by_name){
					label += '<br>' + record.trip_booking_created_by_name;
				}

				return label;
			}
		}
	},
	{
		title: 'Cliente',
		key: 'customer_name',
		fieldDefinition: {
			key: 'customer_name',
			name: 'Nome Cliente',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record || !record.trip_booking_entity_customer_name) return '';
				let entity_customer_name = "<div style='font-weight: 600; max-width: 250px; overflow: hidden; text-overflow: ellipsis; display: block;' alt='"+record.trip_booking_entity_customer_name+"'>";
				entity_customer_name += record.trip_booking_entity_customer_name;
				entity_customer_name +=  "</div>";
				return entity_customer_name;
			},

		},
        showIf(dataContainerService: IDataContainerService) {
            if(!dataContainerService) return false;
            return !hideIfCustomDisableFields('entity_customer_id','report',dataContainerService)
        }
	},
    {
        title: 'Agenzia',
        key: 'branch_code',
        fieldDefinition: {
            key: 'branch_code',
            name: 'Agenzia',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue(record: any): string{
                if(!record || !record.trip_booking_branch_code) return '';
                return record.trip_booking_branch_code;
            }
        },
        showIf(dataContainerService: IDataContainerService) {
            if(!dataContainerService) return false;
            return showIfCustomAddFields('branch_code','report',dataContainerService)
        }
    },
	{
		title: 'Prezzo Lordo',
		key: 'total_amount',
		fieldDefinition: fieldsMap.get('total_amount')
	},
	{
		title: 'Prezzo Netto',
		key: 'total_amount_net',
		fieldDefinition: fieldsMap.get('total_amount_net')
	},
	{
		title: 'Numero Voucher Operatore',
		key: 'origin_channel_voucher_code',
        showIf(dataContainerService: IDataContainerService) {
            if(!dataContainerService) return false;
            return !hideIfCustomDisableFields('origin_channel_voucher_code','report',dataContainerService)
        }
	},
	{
		title: 'Note',
		key: 'note',
		fieldView: FieldViews.DIALOG_TEXT,
		fieldDefinition: {
			name: 'Note',
			key: 'trip_booking_note',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.DIALOG_TEXT,
			prefixIcon: 'note'
		}
	},
	{
		title: 'Voucher',
		key: 'id',
		fieldView: FieldViews.MIXED_COLUMN,
		getCellConfigs(record: any) {
			if(!record) return [];
			if(['trip_packages', 'autoservizio'].includes(record.trip_booking_package_target)){
				return [
					{
						title: 'Voucher',
						key: 'id',
						menu: {
							text: 'Voucher',
							items: [
								{
									icon: 'get_app',
									text: 'Scarica',
									makeLink(record): string{
										return getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id;
									}
								},
								{
									icon: 'email',
									text: 'Invia Email',
									showIf(record, dataContainerService): boolean{
										return record && record.trip_booking_package_booking_status === 'confirmed';
									},
									onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
										sendVoucherAction(record, datasetACS, datasetNavigatorService)
										.catch(e => {});
									}
								}
							]
						},
						fieldView: FieldViews.MENU,
						fieldDefinition: {
							name: 'voucher',
							key: 'id',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								return '<a href="' + getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id + '" target="_blank">Voucher</a>';
							},
							fieldView: FieldViews.HTML,
						},
					},
				];
			}else if(['milano_card', 'milano_card_offline'].includes(record.trip_booking_package_target)){
				const allowedDownloadDeliveryStatus = ['scaduto', 'in consegna', 'consegnato'];
				if(record.delivery_status && allowedDownloadDeliveryStatus.includes(record.delivery_status) && record.download_ticket_file) {
					let ticketDownloadTotalCount = getTicketDownloadTotalCount(record);
					return [
						{
							title: 'Tickets',
							key: 'id',
							fieldView: FieldViews.BUTTON,
							actionButton: {
								buttonColor: ticketDownloadTotalCount ? 'primary' : 'warn',
								buttonIcon: 'download',
								buttonText: 'Biglietti',
								tooltip: ticketDownloadTotalCount ? 'Risultano biglietti scaricati' : 'Nessun biglietto scaricato',
								onClick,
							},
						}
					];
				}
			}else if(record.trip_booking_package_target == 'product' && record.trip_booking_package_booking_status === 'confirmed'){
				return [
					{
						title: 'Azioni',
						key: 'id',
						menu: {
							text: 'Azioni',
							items: [
								{
									icon: 'email',
									text: 'Re-invia Email',
									onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
										sendVoucherAction(record, datasetACS, datasetNavigatorService)
										.catch(e => {});
									}
								}
							]
						},
						fieldView: FieldViews.MENU
					},
				];
			}else if(record.trip_booking_package_target == 'trenitalia' && record.trip_booking_package_booking_status === 'confirmed'){
                let ticketDownloadTotalCount = getTicketDownloadTotalCount(record);
                return [
                    {
                        title: 'Voucher',
                        key: 'id',
                        menu: {
                            text: 'Voucher',
                            items: [
                                {
                                    icon: 'get_app',
                                    text: 'Scarica',
                                    makeLink(record): string{
                                        return getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id;
                                    }
                                },
                                /*{
                                    icon: 'email',
                                    text: 'Invia Email',
                                    showIf(record, dataContainerService): boolean{
                                        return record && record.trip_booking_package_booking_status === 'confirmed';
                                    },
                                    onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
                                        sendVoucherAction(record, datasetACS, datasetNavigatorService)
                                            .catch(e => {});
                                    }
                                }*/
                            ]
                        },
                        fieldView: FieldViews.MENU,
                        fieldDefinition: {
                            name: 'voucher',
                            key: 'id',
                            valueType: ValueTypes.STRING,
                            getDisplayValue(record: any): string{
                                return '<a href="' + getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id + '" target="_blank">Voucher</a>';
                            },
                            fieldView: FieldViews.HTML,
                        },
                    },
                ];
            }else{
				return [];
			}
		}
	}
];
