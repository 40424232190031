import * as tslib_1 from "tslib";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { BaseDatasetActionDialog } from './../../base-action-dialog.component';
import { DatasetEditDialogComponent } from '../edit/dataset-edit-dialog.component';
var DatasetDetailDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetDetailDialogComponent, _super);
    function DatasetDetailDialogComponent(datasetACS, appService, datasetService, dialog, dialogRef, data) {
        var _this = _super.call(this, datasetACS, appService, datasetService, dialogRef, data) || this;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.dialog = dialog;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.actionCode = 'detail';
        return _this;
    }
    DatasetDetailDialogComponent.prototype.getParamMap = function () {
        var map = new Map();
        map.set('recordId', this.data.recordId);
        if (this.data.parentDatasetACS && this.data.parentDatasetACS.datasetConfig && this.data.parentDatasetACS.datasetConfig.pivot) {
            var parentDatasetCode = this.data.parentDatasetACS.datasetConfig.pivot.parentDatasetCode;
            var parentRecordId = this.data.parentDatasetACS.getValueFromKeyPath(parentDatasetCode + '.recordId');
            map.set('parentRecordId', parentRecordId);
        }
        return map;
    };
    DatasetDetailDialogComponent.prototype.onCloseClick = function () {
        this.dialogRef.close(false);
    };
    DatasetDetailDialogComponent.prototype.openEditDialog = function (callerService, record, dialogOptions) {
        var _this = this;
        var dialogRef = this.dialog.open(DatasetEditDialogComponent, {
            minWidth: 450,
            panelClass: 'edit-dialog-container',
            data: Object.assign({}, {
                datasetCode: callerService.datasetCode,
                record: record,
                datasetData: callerService.datasetData,
                parentDatasetACS: callerService
            }, dialogOptions)
        });
        dialogRef.afterClosed()
            .subscribe(function (result) {
            if (result) {
                callerService.datasetEvent.next({
                    eventName: DatasetEvents.EDITED,
                    data: {
                        id: record.id
                    }
                });
                if (_this.data.parentDatasetACS) {
                    _this.data.parentDatasetACS.datasetEvent.next({
                        eventName: DatasetEvents.EDITED,
                        data: {
                            id: record.id
                        }
                    });
                }
            }
        });
        return dialogRef;
    };
    DatasetDetailDialogComponent.prototype.onEditClick = function () {
        this.openEditDialog(this.datasetACS, this.datasetACS.datasetData.record);
    };
    return DatasetDetailDialogComponent;
}(BaseDatasetActionDialog));
export { DatasetDetailDialogComponent };
