import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/meeting_point_unified_list_items/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const listId = dataContainerService.getValueFromKeyPath('meeting_point_unified_lists.recordId');
	if(listId){
		params.list_id = listId;
	}else{
		const tripPackage = dataContainerService.getValueFromKeyPath('trip_packages.record');
		if(tripPackage){
			params['parent_dataset_code'] = 'trip_packages';
			params['parent_id'] = tripPackage.id;
		}
	}

	return params;
}

export const MeetingPointUnifiedListItemsConfig: IDatasetConfig = {
	name: 'meeting_point_unified_list_items',
	title: 'Punti di ritrovo',
	singleTitle: 'Punto di ritrovo',
	ajaxDatasetCode: 'meeting_point_unified_list_items',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'time',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'meeting_point_code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'meeting_point_description',
						fieldDefinition: fieldsMap.get('meeting_point_description')
					},
					{
						title: 'Orario',
						key: 'time'
					},
					{
						title: 'Lista',
						key: 'list_description',
						actionCode: 'edit',
						onClick(record, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							if(!record.list_id) return;
							datasetNavigatorService.showDatasetEdit('meeting_point_unified_lists', {
								createWithParentDatasetACS: datasetACS
							}, record.list_id);
						},
						showIf(dataContainerService: IDataContainerService): boolean{
							const filters = dataContainerService.getValueFromKeyPath('meeting_point_unified_list_items.filters');
							if(filters && filters.list_id) return false;
							return true;
						},
						fieldDefinition: {
							name: 'Lista',
							key: 'list_description',
							valueType: ValueTypes.STRING,
							fieldView: FieldViews.HTML,
							getDisplayValue(record): string{
								if(!record.list_id) return '';
								if(!record.allowed_actions || !record.allowed_actions.includes('edit')) return record.list_description;
								return record.list_description + ' <mat-icon class="mat-icon material-icons mat-icon-no-color" color="accent">edit</mat-icon>';
							}
						}
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
				customActions: [
					/*{
						buttonColor: 'accent',
						buttonText: 'Rinomina Lista',
						actionCode: 'edit',
						tooltip: 'Modifica Lista',
						onClick(evetn: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.showDatasetEdit('meeting_point_unified_lists', null, record.list_id);
						},
						showIf(record: any): boolean{
							return Boolean(record.list_id);
						}
					}*/
				]
			}
		}
	}
};
