/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./countdown.component";
import * as i4 from "@angular/material/snack-bar";
import * as i5 from "../trenitalia.service";
var styles_CountdownComponent = [i0.styles];
var RenderType_CountdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownComponent, data: {} });
export { RenderType_CountdownComponent as RenderType_CountdownComponent };
export function View_CountdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "span", [["class", "timer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["id", "minutes"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["id", "seconds"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.minutesToDday, "2.0")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.secondsToDday, "2.0")); _ck(_v, 7, 0, currVal_1); }); }
export function View_CountdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trenitalia-countdown", [], null, null, null, View_CountdownComponent_0, RenderType_CountdownComponent)), i1.ɵdid(1, 245760, null, 0, i3.CountdownComponent, [i4.MatSnackBar, i5.TrenitaliaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CountdownComponentNgFactory = i1.ɵccf("trenitalia-countdown", i3.CountdownComponent, View_CountdownComponent_Host_0, { datac: "dataC" }, { end: "end" }, []);
export { CountdownComponentNgFactory as CountdownComponentNgFactory };
