import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, Optional, HostBinding, ViewChild, ViewContainerRef, Type, ComponentFactoryResolver, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';


import { fuseAnimations } from '@fuse/animations';

import {Subject, Subscription} from 'rxjs';

import {IFieldDefinition, InputTypes, ValueTypes, IDatasetPropertyDefinition} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { FieldHelper } from 'app/helpers';
import { TitleCasePipe } from '@angular/common';
import {MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import { FormInputViewerService } from './form-input-viewer.service';
import { takeUntil } from 'rxjs/operators';
import { TextInputComponent } from './inputs/text/input.component';
import { ColorInputComponent } from './inputs/color/input.component';
import { DateInputComponent } from './inputs/date/input.component';
import { SelectInputComponent } from './inputs/select/input.component';
import { TextareaInputComponent } from './inputs/textarea/input.component';
import { RadioInputComponent } from './inputs/radio/input.component';
import { DateTimeInputComponent } from './inputs/datetime/input.component';
import { ToggleInputComponent } from './inputs/toggle/input.component';
import { ReadOnlyInputComponent } from './inputs/readonly/input.component';
import { DatasetAutocompleteInputComponent } from './inputs/dataset-autocomplete/input.component';
import { DatasetComboboxInputComponent } from './inputs/dataset-combobox/input.component';
import { DatasetMultiSelectInputComponent } from './inputs/dataset-multiselect/input.component';
import { DatasetSearchInputComponent } from './inputs/dataset-search/input.component';
import { DatasetSelectInputComponent } from './inputs/dataset-select/input.component';
import { DatasetSelectDepDropdownInputComponent } from './inputs/dataset-select-dropdown/input.component';
import { TextListInputComponent } from './inputs/text-list/input.component';
import { TextListInput2Component } from './inputs/text-list2/input.component';
import { WeekHoursComponent } from './inputs/week-hours/input.component';
import { TinyMCEInputComponent } from './inputs/tinymce/input.component';
import { FileInputComponent } from './inputs/file/input.component';
import { MultipleFileInputComponent } from './inputs/multiple-file/input.component';
import { TimeInputComponent } from './inputs/time/input.component';
import { HiddenInputComponent } from './inputs/hidden/input.component';
import { NumberInputComponent } from './inputs/number/input.component';
import { PasswordInputComponent } from './inputs/password/input.component';
import { MultiselectInputComponent } from './inputs/multiselect/input.component';
import { BaseTextInputComponent } from './inputs/base-text/input.component';
import { SelectCustomIconInputComponent } from './inputs/select_custom_icon/input.component';
import { SelectFilterableInputComponent } from './inputs/select_filterable/input.component';
import { CompareDateInputComponent } from './inputs/compare_date/input.component';


const inputComponents = {
	[InputTypes.TEXT] : TextInputComponent,
	[InputTypes.DATE] : DateInputComponent,
	[InputTypes.SELECT] : SelectInputComponent,
	[InputTypes.TEXTAREA] : TextareaInputComponent,
	[InputTypes.RADIO] : RadioInputComponent,
	[InputTypes.DATETIME] : DateTimeInputComponent,
	[InputTypes.TOGGLE] : ToggleInputComponent,
	[InputTypes.READONLY] : ReadOnlyInputComponent,
	[InputTypes.DATASET_AUTOCOMPLETE] : DatasetAutocompleteInputComponent,
	[InputTypes.DATASET_COMBOBOX] : DatasetComboboxInputComponent,
	[InputTypes.DATASET_MULTISELECT] : DatasetMultiSelectInputComponent,
	[InputTypes.DATASET_SEARCH] : DatasetSearchInputComponent,
	[InputTypes.DATASET_SELECT] : DatasetSelectInputComponent,
	[InputTypes.DATASET_SELECT_DEP_DROPDOWN] : DatasetSelectDepDropdownInputComponent,
	[InputTypes.TEXT_LIST] : TextListInputComponent,
	[InputTypes.TEXT_LIST2] : TextListInput2Component,
	[InputTypes.WEEK_HOURS] : WeekHoursComponent,
	[InputTypes.TINY_MCE] : TinyMCEInputComponent,
	[InputTypes.FILE] : FileInputComponent,
	[InputTypes.MULTIPLE_FILE] : MultipleFileInputComponent,
	[InputTypes.TIME] : TimeInputComponent,
	[InputTypes.COMPARE_DATE] : CompareDateInputComponent,
	[InputTypes.HIDDEN] : HiddenInputComponent,
	[InputTypes.NUMBER] : NumberInputComponent,
	[InputTypes.PASSWORD] : PasswordInputComponent,
	[InputTypes.MULTI_SELECT] : MultiselectInputComponent,
	[InputTypes.BASE_TEXT] : BaseTextInputComponent,
	[InputTypes.SELECT_CUSTOM_ICON] : SelectCustomIconInputComponent,
	[InputTypes.SELECT_FILTERABLE] : SelectFilterableInputComponent,
	[InputTypes.COLOR] : ColorInputComponent,
};

@Component({
	selector   : 'form-input-viewer',
	templateUrl: './form-input-viewer.component.html',
	styleUrls  : ['./form-input-viewer.component.scss'],
	animations : fuseAnimations,
	providers: [
		FormInputViewerService,
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		// {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class FormInputViewerComponent implements OnInit, OnDestroy, OnChanges{

	@Input() formInputDefinition: IFieldDefinition;
	@Input() formGroup: FormGroup;
	@Input() parentFormGroup: FormGroup; // not used
	@Input() dataContainerService: IDataContainerService;
	@Input() formControlKey: string;
	@Input() readOnly = false; // TODO: enable dinamic change
	@Input() data: any;
	@Output() customEvent = new EventEmitter<any>();

	@HostBinding('style.display') containerDisplayStyle = 'initial';
	@HostBinding('class.read-only-form-input-viewer') get readonlyClass(){
		return this.inputService.inputConfig && this.inputService.inputConfig.readOnly;
	}

	@ViewChild('target', { read: ViewContainerRef, static: false }) targetContainer: ViewContainerRef;

	protected dragging = false;
	public hidden = false;
	public searchValue: any;
	public filteredOptions: Subject<any>;
	public inputTypes = InputTypes;
	public propertyDefinition: IDatasetPropertyDefinition;
	public list: any[];

	public optionSelected: any;

	private _unsubscribeAll: Subject<any>;

	public computedTitle: string;
	public initialTimeValue: string;
	public computedFieldDefinition: IFieldDefinition;
	public showChangedSubscription: Subscription;
	public valueChangesSubscription: Subscription;

	constructor(
		public inputService: FormInputViewerService,
		public resolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.formInputDefinition){
			if(this.formInputDefinition.computedDefinition){
				this.computedFieldDefinition = this.formInputDefinition.computedDefinition(this.formGroup.getRawValue(), this.dataContainerService);
			}else{
				this.computedFieldDefinition = this.formInputDefinition;
			}
		}
		if(this.valueChangesSubscription){
			this.valueChangesSubscription.unsubscribe();
			this.valueChangesSubscription = null;
		}
		if(changes.formGroup && this.formInputDefinition.computedDefinition){
			this.valueChangesSubscription = this.formGroup.valueChanges
			.subscribe(() => {
				this.computedFieldDefinition = this.formInputDefinition.computedDefinition(this.formGroup.getRawValue(), this.dataContainerService);
				this.inputService.init(this);
			});
		}
		setTimeout(() => {
			this.inputService.init(this);
			this.cd.detectChanges();
		}, 0);

		if(this.inputService.formInputDefinition && !this.inputService.formInputDefinition.disableHideWithDisplayNone){
			if(this.showChangedSubscription) this.showChangedSubscription.unsubscribe();
			this.showChangedSubscription = this.inputService.showChanged
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				if(this.inputService.show) this.containerDisplayStyle = 'initial';
				else this.containerDisplayStyle = 'none';
			});
		}
	}

	/**
	 * For future use, dinamically load the correct component withour multiple ngIf
	 * Check if update works
	 * @returns 
	 */
	/*loadComponent(){
		if(!this.targetContainer) return;
		this.targetContainer.clear();

		if(!inputComponents[this.inputService.inputConfig.type]) return;

		const childComponent = this.resolver.resolveComponentFactory(inputComponents[this.inputService.inputConfig.type]);
		const componentRef = this.targetContainer.createComponent(childComponent);
	}*/

	buildFieldTitle(): void{
		if(this.formInputDefinition.rawName){
			this.computedTitle = this.formInputDefinition.rawName;
			return;
		}

		this.computedTitle = new TitleCasePipe().transform(this.formInputDefinition.name);
	}

	checkIfShow(): void{
		const control = this.formGroup.get(this.formControlKey);
		if(!control) return;
		const show = this.formInputDefinition.showIf(this.formGroup.getRawValue(), this.dataContainerService);
		this.hidden = !show;
	}

	codeToInputType(code: string): InputTypes{
		if(code === 'option_value') return InputTypes.SELECT;
		if(code === 'multiple_option_value') return InputTypes.MULTI_SELECT;
		if(code === 'select') return InputTypes.SELECT;
		if(code === 'boolean') return InputTypes.CHECKBOX;
		if(code === 'string[]') return InputTypes.TEXT_LIST;
		return InputTypes.TEXT;
	}

	preparePropertyDefinition(): void{
		if(!this.formInputDefinition || !this.dataContainerService || !this.formInputDefinition.inputType) return;
		if(this.formInputDefinition.valueType !== ValueTypes.PROPERTY) return;
		
		this.propertyDefinition = FieldHelper.getPropertyDefinition(
			this.dataContainerService, this.formInputDefinition.key,
			this.dataContainerService.getValueFromKeyPath('datasetCode')
		);

		if(!this.propertyDefinition){
			console.warn('no property definition for ', this.formInputDefinition, this.dataContainerService.getValueFromKeyPath('datasetCode'));
		}

		if(this.propertyDefinition && this.formInputDefinition.inputType === InputTypes.PROPERTY){
			this.inputService.inputConfig.type = this.codeToInputType(this.propertyDefinition.value_type);
		}
	}
	
	getInputTypeToHTML(type): string{
		if(type === InputTypes.TEXT) return 'text';
		if(type === InputTypes.NUMBER) return 'number';
		if(type === InputTypes.EMAIL) return 'email';
		if(type === InputTypes.PASSWORD) return 'password';
		if(type === InputTypes.FILE) return 'file';
		return 'text'; // by default
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		this.inputService.ngOnDestroy();
	}

	onTimeChanged(event): void{
		const value = this.formGroup.get(this.formControlKey).value;
		const timeValue = event.target.value;
		if(!value) return;
		if(typeof(timeValue) === 'string' && timeValue.search(':') !== -1){
			const timeParts = timeValue.split(':');
			const hours = Number(timeParts[0]);
			const minutes = Number(timeParts[1]);
			if(value instanceof Date){
				value.setUTCHours(hours);
				value.setUTCMinutes(minutes);
			}else if(typeof(value.hours) === 'function'){
				value.hours(hours);
				value.minutes(minutes);
			}
		}
	}

	getTimeValue(): string{
		const control = this.formGroup.get(this.formControlKey);
		if(!control) return '';
		const value = control.value;
		if(value){
			const date = new Date(value);
			return date.toLocaleTimeString('it-IT', {hour: '2-digit', minute: '2-digit'});
		}
		return '';
	}

	getErrorMessage(): string{
		let message  = 'Non valido';
		const control = this.formGroup.get(this.formControlKey);
		if(!control) return '';
		if(control.hasError('invalid')) message = control.getError('invalid');
		return message;
	}
}
