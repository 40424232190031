import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as facilityFieldMap} from '../trip_facilities/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_facility_id', {
	key: 'trip_facility_id',
	name: 'Informazioni Utili',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_facilities',
	inputHint: 'Scrivi per cercare una informazione',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.title;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			let label = option.title;
			return label;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
});

facilityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	fieldsMap.set('facility.' + key, value);
});

export {fieldsMap};
