import { NgModule } from '@angular/core';

import { InConstructionModule } from 'app/main/pages/in-construction/in-construction.module';
import { NotFoundModule } from 'app/main/pages/not-found/not-found.module';
import { LoginModule } from 'app/main/pages/authentication/login/login.module';
import { ForgotPasswordModule } from 'app/main/pages/authentication/forgot-password/forgot-password.module';
import { HomePageModule } from 'app/main/pages/home/home.module';
import { CalendarModule } from 'app/main/pages/calendar/calendar.module';
import { ResetPasswordModule } from 'app/main/pages/authentication/reset-password/reset-password.module';
import { DatasetPageModule } from 'app/main/pages/datasets/dataset-page.module';
import { EditBookingModule } from './pages/edit-booking/edit-booking.module';
import { MarketPlaceModule } from './pages/marketplace/marketplace.module';
import { DashbaordPageModule } from './pages/dashboard/dashboard.module';
import { FakeDashbaordPageModule } from './pages/fake-dashboard/fake-dashboard.module';
import { DocumentsPageModule } from './pages/documents/documents.module';
import { UtilitiesPageModule } from './pages/superadmin/utilities/utilities.module';
import { LogsPageModule } from './pages/superadmin/logs/logs.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PropertyDefinitionsPageModule } from './pages/superadmin/properties/property-definitions.module';
import { TourCMSMarketplacePageModule } from './pages/tourcms-marketplace/marketplace.module';
import { BokunMarketplacePageModule } from './pages/bokun-marketplace/marketplace.module';
import { PassportPageModule } from './pages/superadmin/passport/passport.module';
import { BIStatisticsPageModule } from './pages/bi-statistics/bi-statistics.module';
import { DashboardTotemPageModule } from './pages/dashboard-totem/dashboard-totem.module';
import {TicketlandiaMarketplacePageModule} from "./pages/ticketlandia-marketplace/marketplace.module";

@NgModule({
	imports: [
		InConstructionModule,
		NotFoundModule,
		LoginModule,
		HomePageModule,
		ForgotPasswordModule,
		ResetPasswordModule,
		CalendarModule,
		DatasetPageModule,
		EditBookingModule,
		MarketPlaceModule,
		TourCMSMarketplacePageModule,
		BokunMarketplacePageModule,
		TicketlandiaMarketplacePageModule,
		UtilitiesPageModule,
		PassportPageModule,
		LogsPageModule,
		PropertyDefinitionsPageModule,
		DashbaordPageModule,
		FakeDashbaordPageModule,
		DocumentsPageModule,
		BIStatisticsPageModule,
		DashboardTotemPageModule,
	],
	exports     : [
		HomePageModule,
		LoginModule,
		InConstructionModule,
		NotFoundModule,
		ForgotPasswordModule,
		ResetPasswordModule,
		CalendarModule,
		MatProgressSpinnerModule,
		DatasetPageModule,
		EditBookingModule,
		MarketPlaceModule,
		TourCMSMarketplacePageModule,
		BokunMarketplacePageModule,
		TicketlandiaMarketplacePageModule,
		DashbaordPageModule,
		UtilitiesPageModule,
		PassportPageModule,
		FakeDashbaordPageModule,
		DocumentsPageModule,
		BIStatisticsPageModule,
		DashboardTotemPageModule,
		LogsPageModule,
		PropertyDefinitionsPageModule,
	]
})
export class MainModule{}
