<div class="recap-menu" *ngIf="['travel','travel-change'].includes(checkoutData.mode)">
    <div *ngIf="!['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.selectedSolution?trnService.total.value:0"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">

        </trenitalia-recap>
    </div>
    <div class="flex-col flex-gap-8" *ngIf="['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.forward.selectedSolution.start.id"
            [end]="checkoutData.forward.selectedSolution.end.id"
            [departure]="checkoutData.forward.selectedSolution.departure"
            [arrive]="checkoutData.forward.selectedSolution.arrival"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.forward.total"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [fw]="true"
            [dataAv]="checkoutData.forward.dataAv?checkoutData.forward.dataAv:null"
            [trains]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution:null"
            [offeredServices]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.offeredServices:null">
        </trenitalia-recap>
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.return.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.selectedSolution?checkoutData.totalSelectedSolution:0"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">
        </trenitalia-recap>

        <div >
            <span class="total text-color-dark-blue" matTooltip="{{trnService.total.value['fee']>0?'Prezzo:'+(trnService.total.value['valueNet'] | currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(trnService.total.value['fee']| currency:'EUR':'symbol':'1.2-2'):''}}">
                Totale: {{trnService.total.value['value'] | currency:'EUR':'symbol':'1.2-2'}}
            </span>
        </div>
    </div>
</div>

<div *ngIf="checkoutData.searchSolutions.communications" class="alert-message">
    <div *ngFor="let comm of checkoutData.searchSolutions.communications" [innerHTML]="comm"></div>
</div>


<div class="flex-col flex-gap-8 p-8" *ngIf="validSolution">
	<trenitalia-travel-solution-list-item *ngFor="let sol of validSolution; index as i;trackBy: indexTrackBy "
		[travelSolution]="sol"
		[isSelected]="selectedSol==i"
		[isDetailsOpen]="details==i"
        [selectCarnet]="selectCarnet"
		(onPriceSelected)="onPriceSelected($event, i)"
		(onSelect)="onServiceSelected(i)"
		(onDetails)="selectDetails(i)"
		(onCarnetChange)="changeServiceCarnet($event)"
	></trenitalia-travel-solution-list-item>
</div>

<div class="text-center" *ngIf="showMore">
    <button mat-raised-button color="primary" [disabled]="loading" (click)="moreRes()">Altri Risultati</button>
</div>

