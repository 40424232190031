import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageClosureContainerService } from '../../../page-closure-container.service';
import { ITripBookingPackageRelatedData, TripBookingPackageRelatedComponent } from '../trip-booking-packages-related.component';

@Component({
	selector   : 'trip-booking-service',
	templateUrl: './trip-booking-service.component.html',
	styleUrls: ['./trip-booking-service.component.scss'],
	providers: [
		DatasetActionContainerService, 
		PageClosureContainerService,
		DatasetRecordService
	]
})
export class TripBookingServiceComponent extends TripBookingPackageRelatedComponent implements OnInit, OnDestroy{

    _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedData){
			super(appService, datasetACS, datasetRS, matDialogRef, data);
		}

	addTripBookingService(): void{
		this.datasetNavigatorService.openCreateDialog(this.datasetACS);
	}
}
