import * as tslib_1 from "tslib";
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { isArray } from 'util';
export default function action(record, datasetACS, datasetNavigatorService, data) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var formResult, loadingDialogRef2, response, responseMessage, e_1, errorMessage, keyIndex, keys, key;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, datasetNavigatorService.openDialog(FormDialogComponent, {
                        title: 'Credenziali B2B',
                        formData: data || {
                            username: null,
                            password: null,
                            password_confirm: null
                        },
                        formConfig: {
                            fields: [
                                {
                                    key: 'mode',
                                    name: 'Modalità di creazione',
                                    valueType: ValueTypes.STRING,
                                    inputConfig: {
                                        type: InputTypes.RADIO
                                    },
                                    defaultValue: function () {
                                        return 'manual';
                                    },
                                    options: [
                                        {
                                            label: 'Manuale',
                                            value: 'manual'
                                        },
                                        {
                                            label: 'Automatica',
                                            value: 'auto'
                                        }
                                    ]
                                },
                                {
                                    key: 'username',
                                    name: 'Username',
                                    valueType: ValueTypes.STRING,
                                    inputConfig: {
                                        type: InputTypes.TEXT,
                                        required: true,
                                    },
                                    showIf: function (formValue, dataContainerService) {
                                        return formValue.mode === 'manual';
                                    },
                                    formValidators: [Validators.required]
                                },
                                {
                                    key: 'password',
                                    name: 'Password',
                                    valueType: ValueTypes.STRING,
                                    inputConfig: {
                                        type: InputTypes.PASSWORD,
                                        required: true
                                    },
                                    showIf: function (formValue, dataContainerService) {
                                        return formValue.mode === 'manual';
                                    },
                                    formValidators: [Validators.required]
                                },
                                {
                                    key: 'password_confirm',
                                    name: 'Conferma Password',
                                    valueType: ValueTypes.STRING,
                                    inputConfig: {
                                        type: InputTypes.PASSWORD,
                                        required: true
                                    },
                                    showIf: function (formValue, dataContainerService) {
                                        return formValue.mode === 'manual';
                                    },
                                    formValidators: [Validators.required]
                                }
                            ]
                        },
                        dataContainerService: datasetACS,
                        positiveText: 'Crea',
                        negativeText: 'Annulla'
                    }).afterClosed().toPromise()];
                case 1:
                    formResult = _a.sent();
                    if (!formResult)
                        return [2 /*return*/]; // operazione annullata
                    if (formResult.mode !== 'auto') {
                        if (!formResult.username || !formResult.password) {
                            if (datasetNavigatorService) {
                                datasetNavigatorService.appService.showErrorMessage('Username e password sono obbligatori');
                            }
                            return [2 /*return*/, action(record, datasetACS, datasetNavigatorService, data)];
                        }
                    }
                    else {
                        formResult.username = null;
                        formResult.password = null;
                    }
                    if (!formResult)
                        return [2 /*return*/];
                    loadingDialogRef2 = datasetNavigatorService.openDialog(LoadingDialogComponent, {
                        title: 'Creazione in corso',
                    });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, datasetNavigatorService.datasetService
                            .post('/dataset/entities/command/create_site_user', {
                            entity_id: record.id,
                            mode: formResult.mode,
                            username: formResult.username,
                            password: formResult.password,
                            password_confirm: formResult.password_confirm
                        }).toPromise()];
                case 3:
                    response = _a.sent();
                    responseMessage = '';
                    if (response.message) {
                        responseMessage = response.message;
                    }
                    if (response.success) {
                        if (datasetNavigatorService) {
                            datasetNavigatorService.appService.showSuccessMessage(responseMessage || 'Creazione avventuta con successo');
                        }
                    }
                    else {
                        if (!responseMessage)
                            responseMessage = 'Si è verificato un problema durante la creazione dell\'utente.';
                        if (datasetNavigatorService) {
                            datasetNavigatorService.appService.showErrorMessage(responseMessage);
                        }
                    }
                    loadingDialogRef2.close();
                    return [2 /*return*/, response.success];
                case 4:
                    e_1 = _a.sent();
                    errorMessage = 'Si è verificato un errore';
                    if (e_1.error && e_1.error.errors) {
                        keyIndex = 0;
                        keys = Object.keys(e_1.error.errors);
                        key = keys[keyIndex];
                        while (keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e_1.error.errors, key)) {
                            keyIndex++;
                            key = keys[keyIndex];
                        }
                        if (e_1.error.errors[key]) {
                            if (isArray(e_1.error.errors[key]))
                                errorMessage = e_1.error.errors[key][0];
                            else if (typeof (e_1.error.errors[key]) === 'string')
                                errorMessage = e_1.error.errors[key];
                        }
                    }
                    if (datasetNavigatorService) {
                        datasetNavigatorService.appService.showErrorMessage(errorMessage);
                    }
                    loadingDialogRef2.close();
                    return [2 /*return*/, action(record, datasetACS, datasetNavigatorService, {
                            username: formResult.username
                        })];
                case 5: return [2 /*return*/];
            }
        });
    });
}
