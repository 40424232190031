import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter, OnDestroy, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {FieldViews, IPaginationResponse, IActionButton, DatasetActionOpenType, IMassAction, ICustomAction, ISocketEvent, IWebSockeConfig, IFormConfig} from 'app/interfaces';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetNavigatorService} from 'app/main/components/dataset/services/dataset-navigator.service';
import {AppService} from 'app/services/app.service';
import {IListViewConfig} from 'app/interfaces';
import { PageEvent } from '@angular/material/paginator';
import {FieldHelper} from 'app/helpers';
import { GetDisplayFieldValuePipe } from 'app/pipes/get-display-field-value.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { AuthService } from 'app/services/auth.service';
import { DomainFilterService } from 'app/services/domain-filter.service';

@Component({
	selector   : 'list-table-view',
	templateUrl: './table.component.html',
	styleUrls  : ['./table.component.scss'],
	providers: [GetDisplayFieldValuePipe]
})
export class ListTableViewComponent implements OnInit, OnChanges, OnDestroy{

	@Input() public viewConfig: IListViewConfig;
	@Input() public paginationResponse: IPaginationResponse<any>;
	@Input() public socketEvent: ISocketEvent;
	displayedColumns: string[] = [];

	@Input() selectable = false;
	@Input() selection: any;
	@Input() selectionBtnTxt: any;
	@Input() selectionBtnIcon: any;
	@Input() conditionalShowPaginationToolbar = true;
	@Input() elevate = true;
	@Input() showSearchBar: true;
	@Input() enableCreate = true;
	@Input() enableEdit = true;
	@Input() enableDelete = true;
	@Input() enableRestore = true;
	@Input() showPagination = true;
	@Input() enableActions = true;
	@Input() showHeaderToolbar = true;
	@Input() unsetParamFilters = false;
	@Output() selected = new EventEmitter<any>();
	@Output() pageChanged = new EventEmitter<any>();
	@Output() filterChange = new EventEmitter<any>();

	@Input() emitAdd = false;
	@Input() addButtonText = 'Aggiungi';
	@Input() tableStyle: string;
	@Input() filterStyle: string;
	@Output() add = new EventEmitter<any>();

	public massSelection = new Set<string>();
	public isAllMassiveSelected = false;

	public filterForm: IFormConfig;
	public filtersData = {};
	public canCreate = false;
	public tableValues: any;

	public fieldViewType = FieldViews;
	protected _unsubscribeAll: Subject<any>;
	public showPaginationComponent = false;
	public hasGlobalButtons = false;
	//public showHeaderToolbar = true;
	public globalActionButtons: ICustomAction[];
	public massActionButtons: IMassAction[];

	constructor(
		protected viewContainerRef: ViewContainerRef,
		protected datasetService: DatasetService,
		public authService: AuthService,
		protected dialog: MatDialog,
		protected appService: AppService,
		protected router: Router,
		public datasetACS: DatasetActionContainerService,
		public domainFilterService: DomainFilterService,
		public datasetNavigatorService: DatasetNavigatorService,
		private getDisplayFieldValuePipe: GetDisplayFieldValuePipe
	){}

	ngOnInit(): void {
		this._unsubscribeAll = new Subject();

		this.datasetACS.datasetDataChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(keys => {
			if(!keys || !keys.includes('filters')) return;
			this.filtersData = this.datasetACS.datasetData['filters'];
		});

		this.filtersData = this.datasetACS.datasetData['filters'] || {};
		this.filterForm = this.viewConfig.filterForm;
		// unset params filters
		if(this.unsetParamFilters){
			// TODO: rimuovere i campi del form dei filtri parametrizzati
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.viewConfig && changes.viewConfig.currentValue){
			this.buildDisplayColumns();

			this.buildToolbarActionButtons();
			this.hasGlobalButtons = this.viewConfig.customActions && this.viewConfig.customActions.filter(item => item.isGlobal).length > 0;
		}
		if(changes.paginationResponse){
			this.canCreate = this.datasetACS.canCreate() && this.enableCreate && this.datasetACS.actionEnabled('create');
			this.tableValues = {};
			if(this.paginationResponse){
				for (const record of this.paginationResponse.items) {
					this.tableValues[record.id] = this.buildItemValues(record);
				}
			}

			this.showPaginationComponent = this.showPagination && this.paginationResponse 
				&& (!this.conditionalShowPaginationToolbar || (!this.paginationResponse.meta.last || this.paginationResponse.meta.page > 1));
			this.buildToolbarActionButtons();
		}
	}

	buildToolbarActionButtons(): void{
		this.massActionButtons = [];
		this.globalActionButtons = [];
		if(this.viewConfig && this.viewConfig.massActions){
			this.massActionButtons = this.viewConfig.massActions.filter(item => {
				if(item.actionCode === 'create') return this.canCreate;
				return this.datasetACS.canStaticAction(item.actionCode);
			});
		}
		if(this.massActionButtons.length){
			if(!this.displayedColumns.includes('massselect')) this.displayedColumns.unshift('massselect');
		}else{
			if(this.displayedColumns.indexOf('massselect') === 0) this.displayedColumns.shift();
		}

		// in selectable mode disable global actions
		if(this.selectable) return;
		if(this.viewConfig && this.viewConfig.customActions){
			this.globalActionButtons = this.viewConfig.customActions.filter(item => {
				if(!item.isGlobal) return false;
				if(item.actionCode === 'create') return this.canCreate;
				return this.datasetACS.canStaticAction(item.actionCode);
			});
		}
		if(this.canCreate){
			this.globalActionButtons.unshift({
				actionCode: 'create',
				buttonColor: 'accent',
				buttonIcon: 'add',
				buttonText: this.addButtonText,
				isGlobal: true,
				onClick: () => {
					this.onAdd();
				},
				showIf: () => true
			});
		}
	}

	buildDisplayColumns(): void{
		if(!this.viewConfig || !this.viewConfig.columns){
			this.displayedColumns = [];
			return;
		}
		this.displayedColumns = this.viewConfig.columns
		.filter(columnDef => {
			if(!columnDef.showIf) return true;
			return columnDef.showIf(this.datasetACS);
		})
		.map(columnDef => {
			return columnDef.key;
		});
		if((this.viewConfig.actions && this.enableActions) || this.selectable){
			this.displayedColumns.push('actions');
		}
		if(this.viewConfig.massActions && this.viewConfig.massActions.length && this.enableActions){
			this.displayedColumns.unshift('massselect');
		}
	}

	buildEditActionButton(record: any): IActionButton{
		const editAction: IActionButton = {
			buttonIcon: 'edit',
			actionCode: 'edit',
			buttonColor: 'accent',
			tooltip: 'Modifica'
		};

		if(this.datasetACS.getDefaultOpenType('edit') === DatasetActionOpenType.PAGE){
			editAction.routerLink = this.datasetNavigatorService.getEditRoute(this.datasetACS.datasetCode, record.id);
		}else{
			editAction.onClick = (event: any, recordData: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void => {
				this.openEdit(record);
			};
		}
		return editAction;
	}

	buildDetailActionButton(record: any): IActionButton{
		const detailAction: IActionButton = {
			buttonIcon: 'pageview',
			actionCode: 'detail',
			buttonColor: 'accent',
			tooltip: 'Apri Dettaglio'
		};

		if(this.datasetACS.getDefaultOpenType('detail') === DatasetActionOpenType.PAGE){
			detailAction.routerLink = this.datasetNavigatorService.getDetailRoute(this.datasetACS.datasetCode, record.id);
		}else{
			detailAction.onClick = (event: any, recordData: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void => {
				this.datasetNavigatorService.showDetail(this.datasetACS, record);
			};
		}
		return detailAction;
	}

	buildDeleteActionButton(record: any): IActionButton{
		const deleteAction: IActionButton = {
			buttonIcon: 'delete',
			actionCode: 'delete',
			buttonColor: 'warn',
			tooltip: 'Elimina',
			onClick: (event: any, recordData: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void => {
				this.datasetNavigatorService.openDeleteDialog(this.datasetACS, record);
			}
		};

		return deleteAction;
	}

	buildRestoreActionButton(record: any): IActionButton{
		const restoreAction: IActionButton = {
			buttonIcon: 'restore',
			actionCode: 'restore',
			buttonColor: 'accent',
			tooltip: 'Ripristina',
			onClick: (event: any, recordData: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void => {
				this.datasetNavigatorService.openRestoreDialog(this.datasetACS, record);
			}
		};
		return restoreAction;
	}

	addActionButtonItem(code: string, actions: IActionButton[], record: any): void{
		if(code === 'detail' && this.datasetACS.actionEnabled('detail') && this.datasetACS.canDetail(record)){
			actions.push(this.buildDetailActionButton(record));
		}else if(code === 'edit' && this.datasetACS.actionEnabled('edit') && this.datasetACS.canEdit(record) && this.enableEdit){
			actions.push(this.buildEditActionButton(record));
		}else if(code === 'delete' && this.datasetACS.actionEnabled('delete') && this.datasetACS.canDelete(record) && this.enableDelete){
			actions.push(this.buildDeleteActionButton(record));
		}else if(code === 'restore' && this.datasetACS.actionEnabled('restore') && this.datasetACS.canRestore(record) && this.enableRestore){
			actions.push(this.buildRestoreActionButton(record));
		}else if(code === 'customs'){
			if(!this.viewConfig.customActions) return;
			for (const customAction of this.viewConfig.customActions) {
				if(customAction.actionCode){
					if(customAction.actionCode === 'edit' && !(this.datasetACS.canEdit(record) && this.enableEdit)){
						continue;
					}
					if(customAction.actionCode === 'delete' && !(this.datasetACS.canDelete(record) && this.enableEdit)){
						continue;
					}
					if(customAction.actionCode === 'restore' && !(this.datasetACS.canRestore(record) && this.enableRestore)){
						continue;
					}
					if(customAction.actionCode === 'detail' && !(this.datasetACS.canDetail(record))){
						continue;
					}
					if(!this.datasetACS.canAction(record, customAction.actionCode)) continue;
				}
				if(customAction.showIf(record, this.datasetACS) && !customAction.isGlobal){
					const newCustomAction = Object.assign({}, customAction);
					if(customAction.makeRouteLink){
						newCustomAction.routerLink = customAction.makeRouteLink(record, this.datasetACS, this.datasetNavigatorService);
					}
					if(customAction.makeQueryParams){
						newCustomAction.queryParams = customAction.makeQueryParams(record, this.datasetACS, this.datasetNavigatorService);
					}
					if(customAction.buttonColorDynamic){
						newCustomAction.buttonColor = customAction.buttonColorDynamic(record, this.datasetACS, this.datasetNavigatorService);
					}
					actions.push(newCustomAction);
				}
			}
		}else if(code.startsWith('custom_')){
			if(!this.viewConfig.customActions) return;
			const customIndex = Number(code.replace('custom_', ''));
			if(this.viewConfig.customActions[customIndex]){
				const customAction = this.viewConfig.customActions[customIndex];
				if(customAction.actionCode){
					if(customAction.actionCode === 'edit' && !(this.datasetACS.canEdit(record) && this.enableEdit)){
						return;
					}
					if(customAction.actionCode === 'delete' && !(this.datasetACS.canDelete(record) && this.enableEdit)){
						return;
					}
					if(customAction.actionCode === 'restore' && !(this.datasetACS.canRestore(record) && this.enableRestore)){
						return;
					}
					if(customAction.actionCode === 'detail' && !(this.datasetACS.canDetail(record))){
						return;
					}
				}
				if(customAction.showIf(record, this.datasetACS) && !customAction.isGlobal){
					const newCustomAction = Object.assign({}, customAction);
					if(customAction.makeRouteLink){
						newCustomAction.routerLink = customAction.makeRouteLink(record, this.datasetACS, this.datasetNavigatorService);
					}
					if(customAction.makeQueryParams){
						newCustomAction.queryParams = customAction.makeQueryParams(record, this.datasetACS, this.datasetNavigatorService);
					}
					actions.push(newCustomAction);
				}
			}
		}
	}

	/**
	 * Prebuild column values prevent angular to refresh view fro update every row 
	 */
	buildItemValues(record): any{
		if(!this.viewConfig || !this.viewConfig.columns) return;
		const newRowValues = {record};
		for (const column of this.viewConfig.columns) {
			if (column.getCellConfigs && typeof(column.getCellConfigs) == 'function'){
				newRowValues[column.key + '-cell-configs'] = column.getCellConfigs(record);
			}
			if(!column.fieldDefinition) column.fieldDefinition = this.datasetACS.datasetConfig.fieldsMap.get(column.key);
			if(column.fieldDefinition){
				newRowValues[column.key] = this.getDisplayFieldValuePipe.transform(record, column.fieldDefinition);
			}else{
				if(column.fieldView == FieldViews.MIXED_COLUMN){
					newRowValues[column.key] = record;
				}else{
					console.warn('called deprecated method getElementValue', new Error().stack, column, record);
					newRowValues[column.key] = this.getElementValue(record, column.key);
				}
			}
			if(column.fieldView === FieldViews.HTML && column.getDisplayHTML){
				newRowValues[column.key] = column.getDisplayHTML(record, newRowValues[column.key], this.datasetACS.translationService);
			}
			if (column.fieldDefinition && column.fieldDefinition.getDisplayTooltip && typeof(column.fieldDefinition.getDisplayTooltip) == 'function'){
				newRowValues[column.key + '-mat-tooltip'] = column.fieldDefinition.getDisplayTooltip(record);
			}
		}

		const WSconfig = this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig;
		let isNewRow = false;
		// check if new row throught web socket
		if (this.socketEvent){
			if (WSconfig && WSconfig.checkGloballyAffectedRow && typeof(WSconfig.checkGloballyAffectedRow) == 'function'){
				isNewRow = WSconfig.checkGloballyAffectedRow(record, this.socketEvent);
			}
			if (WSconfig && WSconfig[this.socketEvent.data.eventName]){
				const eventWSConfig = <IWebSockeConfig>WSconfig[this.socketEvent.data.eventName];
				// if implement custom check
				if (eventWSConfig.highlightAffectedRow && typeof(eventWSConfig.highlightAffectedRow) == 'function'){
					isNewRow = eventWSConfig.highlightAffectedRow(record, this.socketEvent);
				}
			}
		}

		const actions: IActionButton[] = [];
		const actionOrder = (this.viewConfig && this.viewConfig.itemActionsOrder) || ['detail', 'edit', 'delete', 'restore', 'customs'];

		for (const actionCode of actionOrder) {
			this.addActionButtonItem(actionCode, actions, record);
		}
		newRowValues['_column_actions'] = actions;
		newRowValues['isDeleted'] = Boolean(record.deleted_at);
		newRowValues['canEdit'] = this.datasetACS.actionEnabled('edit') && this.datasetACS.canEdit(record) && this.enableEdit;
		newRowValues['canDelete'] = this.datasetACS.actionEnabled('delete') && this.datasetACS.canDelete(record) && this.enableDelete;
		newRowValues['canDetail'] = this.datasetACS.actionEnabled('detail') && this.datasetACS.canDetail(record);
		newRowValues['canRestore'] = this.datasetACS.actionEnabled('restore') && this.datasetACS.canRestore(record) && this.enableRestore;
		newRowValues['isNewRow'] = isNewRow;
		newRowValues['allowed_actions'] = {
			edit: newRowValues['canEdit'],
			view_detail: newRowValues['canDetail'],
			delete: newRowValues['canDelete']
		};
		return newRowValues;
	}

	openTextDialog(text: string, title?: string): void{
		this.dialog.open(TextDialogComponent, {
			width: '500px',
			data: {
				title: title,
				contentMessage: text
			}
		});
	}

	onAdd(): void{
		if(this.emitAdd){
			this.add.emit();
		}else{
			this.datasetNavigatorService
			.onAdd(this.datasetACS);
		}
	}

	onDelete(data: any): void{
		this.datasetNavigatorService
		.openDeleteDialog(this.datasetACS, data);
	}

	onResore(data: any): void{
		this.datasetNavigatorService
		.openRestoreDialog(this.datasetACS, data);
	}

	showDetail(data: any): void{
		this.datasetNavigatorService
		.showDetail(this.datasetACS, data);
	}

	openEdit(data: any): void{
		this.datasetNavigatorService
		.onEdit(this.datasetACS, data, {
			parentDatasetACS: this.datasetACS,
			viewContainerRef: this.viewContainerRef
		});
	}

	select(record: any): void{
		this.selection = record;
		this.selected.emit(record);
	}

	/**
	 * 
	 * @param record @deprecated use GetDisplayFieldValuePipe
	 * @param key 
	 */
	getElementValue(record: any, key: string): any{
		const fieldDefinition = this.datasetACS.datasetConfig.fieldsMap.get(key);
		if(!fieldDefinition){
			return '! ' + key + ' !';
		}
		return FieldHelper.getDisplayFieldValue(fieldDefinition, record, undefined, this.datasetACS);
	}

	onPaginatorChange(event: PageEvent): void{
		this.pageChanged.emit(event);
	}

	baseTrackBy(index, item): any{
		return index;
	}

	masterToggle(): void{
		if(this.isAllMassiveSelected){
			this.massSelection.clear();
		}else{
			for (const key in this.tableValues) {
				if (!Object.prototype.hasOwnProperty.call(this.tableValues, key)) continue;
				const element = this.tableValues[key];
				if(!element.record || !element.record.id) continue;
				this.massSelection.add(element.record.id);
			}
		}
		this.isAllMassiveSelected = !this.isAllMassiveSelected;
	}

	toggleRow(row): void{
		if(!row || !row.record || !row.record.id) return;
		if(this.massSelection.has(row.record.id)){
			this.massSelection.delete(row.record.id);
		}else{
			this.massSelection.add(row.record.id);
		}

		let allSelected = true;
		for (const key in this.tableValues) {
			if (!Object.prototype.hasOwnProperty.call(this.tableValues, key)) continue;
			const element = this.tableValues[key];
			if(!element.record || !element.record.id  || this.massSelection.has(element.record.id)) continue;
			allSelected = false;
			break;
		}
		this.isAllMassiveSelected = allSelected;
	}

	clickMassAction(action: IMassAction): void {
		const ids = Array.from(this.massSelection);
		action.onClick(ids, this.datasetACS, this.datasetNavigatorService, this.viewContainerRef)
		.subscribe(command => {
			if(command === 'clear-selection'){
				this.massSelection.clear();
				this.isAllMassiveSelected = false;
			}
		});
	}
}
