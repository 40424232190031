<div class='trip-booking-resource-column' fxLayout="column">
	<ng-container *ngIf='trip_booking_resource'>
		<div class="resource-row" [ngClass]="is_pickup && trip_booking_resource.activated ? 'pickup' : ''" fxLayout="row" fxLayoutAlign="start center">
			<ng-container *ngIf='trip_booking_resource.optional'>
				<div [matTooltip]='not_activable_reason'>
					<button class="checkbox" [disabled]='not_activable_reason' mat-icon-button *ngIf='!trip_booking_resource.activated' [matTooltip]='"Risorsa opzionale NON attivata" | translate' (click)='activateResource()'>
						<mat-icon>check_box_outline_blank</mat-icon>
					</button>
					<button class="checkbox" [disabled]='not_activable_reason' mat-icon-button *ngIf='trip_booking_resource.activated' [matTooltip]='"Risorsa opzionale attivata" | translate' (click)='deactivateResource()'>
						<mat-icon>check_box</mat-icon>
					</button>
				</div>
			</ng-container>
			<div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 1 auto" (click)='selected()' style="cursor: pointer;">
				<span class='ml-4 info_resource' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
					<span class="resource_description">{{trip_resource.description}}</span>
					<ng-container *ngIf="is_pickup">
						<span class="pickup">PICK-UP</span>
					</ng-container>
					<span class='secondary-text mat-caption ml-4'>({{ trip_booking_resource.optional ? 'Opzionale' : 'Inclusa' | translate}})</span>
				</span>
				<!--<strong class="price" *ngIf='tripBookingResource.optional'>{{tripBookingResource.total_amount | currency:'EUR' }}</strong>-->
				<button class="page_view_btn" mat-icon-button color='accent' matTooltip='Dettaglio Risorsa'>
					<mat-icon>arrow_forward_ios</mat-icon>
				</button>
			</div>
		</div>
		<ng-container *ngIf='is_pickup && trip_booking_resource.activated'>
			<div class='resource_meeting_point' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<mat-icon>place</mat-icon>
				<span class='ml-4 mr-4'>{{'Punto di ritrovo' | translate}}: <span class='meeting_point_description' [matTooltip]='bookingStepService.meetingPointPickupResource.description' *ngIf='bookingStepService.meetingPointPickupResource'>{{bookingStepService.meetingPointPickupResource.description}}</span></span>
				<span><mat-spinner *ngIf='bookingStepService.loadPickupMeetingPoint' diameter='20'></mat-spinner></span>
				<button *ngIf='!bookingStepService.loadPickupMeetingPoint' mat-icon-button color='accent' [matTooltip]='"Modifica punto di ritrovo" | translate' (click)='bookingStepService.editMeetingPoint(datasetRS.record.value)'>
					<mat-icon>edit</mat-icon>
				</button>
			</div>
		</ng-container>
	</ng-container>
</div>