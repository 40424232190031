<div [class]='formConfig.formStyle'>
	<span class="material-icons-outlined dialog-close-icon" (click)='onNoClick()'>close</span>
	<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>{{ dialogTitle | translate }}</h1>
	<mat-dialog-content>
		<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetACS.loading.value'></mat-progress-bar>
	
		<div *ngIf='datasetACS.actionConfig && !datasetACS.loading.value' class='mb-24 full-form' [ngClass]='formConfig.formStyle'>
			<form-viewer #formViewer 
				[dataContainerService]='datasetACS' 
				[formConfig]='formConfig'
				[record]='data.initialValues'
			></form-viewer>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align='end'>
		<button mat-button color='' (click)="onNoClick()">{{'Annulla' | translate}}</button>
		<button class='ml-4' mat-flat-button [disabled]='loading' color='accent' (click)="onSave()">{{'Salva' | translate}}</button>
	</mat-dialog-actions>
</div>
