import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { ListTableViewComponent } from '../table/table.component';
var ListCustomViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ListCustomViewComponent, _super);
    function ListCustomViewComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.reloadDatasetList = new EventEmitter();
        return _this;
    }
    ListCustomViewComponent.prototype.trackById = function (index, element) {
        return element.id;
    };
    ListCustomViewComponent.prototype.reload = function () {
        this.reloadDatasetList.emit();
    };
    return ListCustomViewComponent;
}(ListTableViewComponent));
export { ListCustomViewComponent };
