import { OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription, combineLatest, empty, EMPTY } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {IDatasetConfig, DatasetActionOpenType, IBaseViewConfig, IListViewConfig, IDatasetActionConfig, IDomainTemplate} from 'app/interfaces';
import {default as DatasetConfigurations} from 'app/configs/datasets';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';

import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetCreateDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/create/dataset-create-dialog.component';

export abstract class BaseDatasetPageComponent implements OnInit, OnDestroy{
	

	protected _unsubscribeAll: Subject<any>;
	public datasetConfig: IDatasetConfig;
	public actionConfig: IDatasetActionConfig<any>;
	public loading = false;
	protected loadSubscription: Subscription;
	public actionCode: string;
	public datasetCode: string;
	public domainTpl: IDomainTemplate = IDomainTemplate.STANDARD;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected dialog: MatDialog,
		protected router: Router,
		protected pageClosureContainerService: PageClosureContainerService
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.pageClosureContainerService.clearToolbarPageActions();
		this.actionCode = this.route.snapshot.data.action;

		this.route.queryParams
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(result => {
			this.pageClosureContainerService.addToSharedData({ filters: result });
		});

		combineLatest(this.route.paramMap, this.route.data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(result => {
			if(!result || !result[0] || !result[1]) return;
			this.datasetCode = null;
			this.datasetConfig = DatasetConfigurations[result[0].get('datasetCode')];
			if(this.datasetConfig){
				this.actionConfig = this.datasetConfig.defaultActions[this.actionCode];
				if (this.datasetConfig.stepViewConfig){
					this.pageClosureContainerService.templatePage.next('page-type-' + this.actionCode + '-no-padding');
				}
			}
			this.datasetCode = result[0].get('datasetCode');
			this.pageClosureContainerService.addToSharedData(result[1].datasetData);
			if (result[1].datasetData.domainTpl !== null) {
				this.domainTpl = result[1].datasetData.domainTpl;
			}
			this._load();
		});
	}

	/**
	 *	Override this with a http request
	 */
	protected load(): Observable<any>{ return EMPTY; }
	protected onLoaded(response): void{}

	private _load(): void{
		this.loading = true;
		if(this.loadSubscription && !this.loadSubscription.closed){
			this.loadSubscription.unsubscribe();
		}
		this.loadSubscription = this.load()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.loading = false;
				this.onLoaded(response);
			},
			error: err => {
				this.loading = false;
			}
		});
	}

	openCreateDialog(): MatDialogRef<DatasetCreateDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetCreateDialogComponent, {
			data: {
				datasetConfig: this.datasetConfig,
				sourceData: this.route.snapshot.data.datasetData,
			}
		});
		return dialogRef;
	}

	onAdd(): void{
		if(!this.datasetConfig.defaultActions.create) return;

		if(this.datasetConfig.defaultActions.create.openType === DatasetActionOpenType.DIALOG){
			this.openCreateDialog();
		}else if(this.datasetConfig.defaultActions.create.openType === DatasetActionOpenType.PAGE){
			this.router.navigate(['/dataset/create/' + this.datasetConfig.name ]);
		}
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	
}
