import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { BehaviorSubject, Subject, Observable, of, throwError, EMPTY } from 'rxjs';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { AuthService } from 'app/services/auth.service';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { fieldsMap } from 'app/configs/datasets/trip_bookings/fields';
import { catchError, exhaustMap, finalize, map, tap } from 'rxjs/operators';

@Injectable()
export class BookingService{

	public loading = new BehaviorSubject<boolean>(false);
	public useLastDraft = false;
	public canEdit = false;
	public nextStep = new EventEmitter<MatStepper>();
	public prevStep = new EventEmitter<MatStepper>();

	public statusText = {
		canceled: 'Cancellata',
		confirmed: 'Confermata',
		optional: 'Opzionale (in scadenza)',
		expired: 'Scaduata',
		unconfirmed: 'Non Confermata',
		draft: 'Preventivo',
		mix: 'Mista'
	};

	public statusTextColor = {
		canceled: 'red-fg',
		confirmed: 'green-fg',
		optional: 'orange-fg',
		expired: 'orange-fg',
		unconfirmed: 'orange-fg',
		draft: 'orange-fg',
		mix: 'orange-fg'
	};

	constructor(
		protected http: HttpClient,
		protected appService: AppService,
		protected authService: AuthService,
		protected datasetService: DatasetService,
		protected matDialog: MatDialog,
		protected datasetACS: DatasetActionContainerService
	){}

	/**
	 * create new draft
	 */
	newDraftBooking(deleteId?: string): Subject<any>{
		const result = new Subject();
		this.loading.next(true);
		this.datasetService.post<any>('/dataset/trip_bookings/command/draft_booking', {deleteId})
		.subscribe({
			next: response => {
				result.next(response);
				result.complete();
				this.loading.next(false);
			},
			error: response => {
				result.error(response);
				result.complete();
				this.loading.next(false);
			}
		});
		return result;
	}

	loadLastDraft(): any{
		const result = new Subject();
		this.loading.next(true);
		const params = {
			perPage: 1,
			status: 'draft',
			sortBy: 'updated_at',
			sortDirection: 'desc',
			only_my: 1,
			with_detail: 1
		};
		this.datasetService.get<any>('/dataset/trip_bookings', params)
		.subscribe({
			next: response => {
				if(response && response.items && response.items.length > 0){
					result.next(response.items[0]);
				}else{
					result.next(null);
				}
				result.complete();
				
				this.loading.next(false);
			},
			error: response => {
				this.loading.next(false);
				result.error(response);
				result.complete();
			}
		});
		return result;
	}

	discardDraft(id: string): Observable<any>{
		return this.datasetService.delete<any>('/dataset/trip_bookings/delete/' + id);
	}

	onActionResponse(response, dialogRef): void{
		if(!dialogRef || !dialogRef.componentInstance){
			dialogRef = this.matDialog.open(ActionDialogComponent, {
				width: '550px',
				data: {}
			});
		}
		dialogRef.componentInstance.loading = false;
		if(response.success){
			dialogRef.componentInstance.resultStatus = 'success';
		}else{
			dialogRef.componentInstance.resultStatus = 'error';
		}
		if(response.message){
			dialogRef.componentInstance.setMessage(response.message);
		}
	}

	onErrorActionResponse(response, dialogRef: MatDialogRef<ActionDialogComponent>): void{
		if(!dialogRef || !dialogRef.componentInstance){
			dialogRef = this.matDialog.open(ActionDialogComponent, {
				width: '550px',
				data: {
					title: 'Azione in corso'
				}
			});
		}
		dialogRef.componentInstance.loading = false;
		dialogRef.componentInstance.resultStatus = 'error';
		dialogRef.componentInstance.setTitle('Errore');
		if(response.error){
			const message = this.getErrorMessage(response.error, response.error.message);
			if(message) dialogRef.componentInstance.setMessage(message);
		}else{
			if(response.error) dialogRef.componentInstance.setMessage(response.error);
			else dialogRef.componentInstance.setMessage('Si è verificato un errore.');
		}
	}

	getErrorMessage(response: any, defaultMessage: string): string{
		if(!response) return defaultMessage;
		let report = response;
		if(response.report){
			report = response.report;
		}
        if(response.details && response.details.length){
            let msg = '';
            for(let det of response.details){
                msg +="<br/> "+det.reason;
            }
            return msg;
        }
		if(response.message){
			return response.message;
		}

		if(report.invalid){
			for (const code of report.invalid) {
				if(code === 'no_packages') return 'Devi aggiungere almeno una partenza.';
				if(code === 'no_participants') return 'Devi aggiungere almeno un partecipante.';
			}
		}else if(report.actual_trip){
			if(report.max_allotment < 0) return 'Non ci sono posti sufficienti per la partenza selezionata.';
		}else if(report.trip_booking_packages){
			for (const tripBookingPackageReport of report.trip_booking_packages) {
				return this.getErrorMessage(tripBookingPackageReport, defaultMessage);
			}
		}else if(report.trip_booking_resources){
			for (const tripBookingPackageReport of report.trip_booking_resources) {
				return this.getErrorMessage(tripBookingPackageReport, defaultMessage);
			}
		}
		return defaultMessage;
	}

	suspendBooking(bookingId: string): Observable<any>{
		const result = new Subject();

		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Sospensione in corso'
			}
		});

		let actionResponse = null;
		this.datasetService.post<any>('/dataset/trip_bookings/command/suspend_booking', {
			trip_booking_id: bookingId
		}).subscribe({
			next: response => {
				this.useLastDraft = false;
				this.onActionResponse(response, dialogRef);
				result.next(response);
				result.complete();
			},
			error: response => {
				actionResponse = response;

				this.onErrorActionResponse(response, dialogRef);
			}
		});

		/*dialogRef.afterClosed()
		.subscribe(dialogResult => {
			result.next(actionResponse);
			result.complete();
		});*/
		return result;
	}

	confirmBooking(bookingId: string, tripPackageBookingIds?: string[]|null): Observable<any>{
		return this.execPreAction('trip_bookings:confirm')
		.pipe(
			exhaustMap(() => {
				const dialogRef = this.matDialog.open(ActionDialogComponent, {
					width: '550px',
					data: {
						title: 'Conferma in corso'
					}
				});

				return this.datasetService.post<any>('/dataset/trip_bookings/command/confirm_booking', {
					trip_booking_id: bookingId,
					trip_booking_package_ids:tripPackageBookingIds
				}).pipe(
					tap((response) => {
						this.useLastDraft = false;
						this.onActionResponse(response, dialogRef)
					}),
					catchError(errorResponse => {
						this.onErrorActionResponse(errorResponse, dialogRef);
						return EMPTY;
					})
				);
			})
		);
	}

	/**
	 * TODO: move in trip booking package component
	 * @param tripBookingPackageId 
	 * @param status 
	 * @returns 
	 */
	setDeliveryStatus(tripBookingPackageId: string, status: string): Observable<any>{
		const result = new Subject();

		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Conferma in corso'
			}
		});

		let actionResponse = null;
		this.datasetService.post<any>('/dataset/trip_bookings/command/confirm_delivery', {
			trip_booking_package_id: tripBookingPackageId,
			delivery_status: status // status = in consegna or consegnato
		}).subscribe({
			next: response => {
				this.useLastDraft = false;
				this.onActionResponse(response, dialogRef);
				result.next(response);
				result.complete();
			},
			error: response => {
				actionResponse = response;

				this.onErrorActionResponse(response, dialogRef);
			}
		});

		/*dialogRef.afterClosed()
		.subscribe(dialogResult => {
			result.next(actionResponse);
			result.complete();
		});*/
		return result;
	}

	cancelBooking(bookingId: string, extraData:any): Observable<any>{
		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Annullamento in corso'
			}
		});
		
		return this.datasetService.post<any>('/dataset/trip_bookings/command/cancel_booking', Object.assign({
			trip_booking_id: bookingId
		}, extraData)).pipe(
			tap({
				next: (response) => {
					this.useLastDraft = false;
					this.onActionResponse(response, dialogRef);
				}, 
				error: errorResponse => {
					this.onErrorActionResponse(errorResponse, dialogRef);
				}
			})
		);
	}

	cancelPermanentBooking(bookingId: string): Observable<any>{
		const result = new Subject();

		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Cancellazione in corso'
			}
		});

		let actionResponse = null;
		
		this.datasetService.post<any>('/dataset/trip_bookings/command/permanent_cancel_booking', {
			trip_booking_id: bookingId
		}).subscribe({
			next: response => {
				this.useLastDraft = false;
				this.onActionResponse(response, dialogRef);
				result.next(response);
				result.complete();
			},
			error: response => {
				actionResponse = response;
				this.onErrorActionResponse(response, dialogRef);
			}
		});
		return result;
	}

	optionalBooking(bookingId, data): Observable<any>{
		const result = new Subject();

		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Azione in corso'
			}
		});

		let actionResponse = null;

		const postData = Object.assign({
			trip_booking_id: bookingId
		}, data);
		
		this.datasetService.post<any>('/dataset/trip_bookings/command/optional_booking', postData)
		.subscribe({
			next: response => {
				this.useLastDraft = false;
				this.onActionResponse(response, dialogRef);
				result.next(response);
				result.complete();
			},
			error: response => {
				actionResponse = response;

				this.onErrorActionResponse(response, dialogRef);
			}
		});

		/*dialogRef.afterClosed()
		.subscribe(dialogResult => {
			result.next(actionResponse);
			result.complete();
		});*/
		return result;
	}

	getTripBooking(){
		return this.datasetACS.getValueFromKeyPath('trip_bookings.record');
	}

	execPreAction(action: string, formErrors?: any): Observable<any>{
		if(action == 'command:pre_action') return of(true);
		const tripBooking = this.datasetACS.getValueFromKeyPath('trip_bookings.record');
		const defaultValue = this.datasetACS.getValueFromKeyPath('trip_bookings.record.payment_method_code');
		const backofficeRequiredFields = this.datasetACS.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if(action != 'trip_bookings:confirm' && !['confirmed', 'mix'].includes(tripBooking.status)) return of(true);
				
		return of({})
		.pipe(
			exhaustMap(() => {
				if(!backofficeRequiredFields.includes('payment_method_code')) return of({});
				return this.matDialog.open(FormDialogComponent, {
					width: '500px',
					data: {
						title: 'Metodo di pagamento',
						formData: {
							payment_method_code: defaultValue
						},
						formConfig: {
							fields: [
								fieldsMap.get('payment_method_code'),
							]
						},
						dataContainerService: this.datasetACS,
						positiveText: 'Conferma',
						formErrors
					}
				}).afterClosed();
			}),
			exhaustMap((data) => {
				if(data === false || data === undefined) return EMPTY;
				const dialogRef = this.matDialog.open(ActionDialogComponent, {
					width: '550px',
					data: {
						title: 'Azione in corso'
					}
				});
				return this.execBookingCommand('pre_action', Object.assign({
					trip_booking_id: tripBooking.id,
					action
				}, data), false)
				.pipe(
					exhaustMap((response) => {
						if(response.allowed){
							dialogRef.close();
							return of(true);
						}else{
							this.onActionResponse({
								message: response.message || 'Operazione non permessa'
							}, dialogRef);
							return EMPTY;
						}
					}),
					catchError((response) => {
						if(response.status == 422 && response.error && response.error.errors){
							dialogRef.close();
							return this.execPreAction(action, response.error.errors);
						}
						this.onErrorActionResponse(response, dialogRef);
						return EMPTY;
					})
				)
			})
		);
	}

	execBookingCommand(command: string, postData: any, useDefaultDialog = true): Observable<any>{
		return this.execPreAction('command:'+command)
		.pipe(
			exhaustMap(() => {
				let dialogRef = null;
				
				if(useDefaultDialog){
					dialogRef = this.matDialog.open(ActionDialogComponent, {
						width: '550px',
						data: {
							title: 'Azione in corso'
						}
					});
				}

				return this.datasetService.post<any>('/dataset/trip_bookings/command/'+command, postData)
				.pipe(
					tap((response) => {
						if(useDefaultDialog){
							dialogRef.componentInstance.setTitle(null);
							this.onActionResponse(response, dialogRef);
						}
					}, (response) => {
						if(useDefaultDialog) this.onErrorActionResponse(response, dialogRef);
					})
				);
			})
		);
	}
}
