import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import form from './form';
import { fieldsMap } from './fields';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_package_customer_settings/detail/:recordId',
	params: {
		with_relations: 'tripPackage,entityCustomer'
	}
};

export const TripPackageCustomerSettings: IDatasetConfig = {
	name: 'trip_package_customer_settings',
	title: 'Export pratiche',
	singleTitle: 'Export Pratiche',
	ajaxDatasetCode: 'trip_package_customer_settings',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	fetchConfig: {
		with_relations: 'tripPackage,entityCustomer'
	},
	formConfigsMap: {},
	defaultActions: {
		detail: {
			beforeCalls: [beforeCallDetail],
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: form,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: form
			},
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: form
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['with_relations'] = 'tripPackage,entityCustomer';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Cliente',
						key: 'entity_customer_id',
						fieldDefinition: {
							key: 'entity_customer_id',
							name: 'Nome Cliente',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								if(!record || !record.entity_customer) return '---';
								return record.entity_customer.description;
							}
						}
					},
					{
						title: 'Pacchetto',
						key: 'trip_package_description',
						fieldDefinition: {
							name: 'Pacchetto',
							key: 'trip_package_description',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								if(!record || !record.trip_package) return '---';
								return record.trip_package.description;
							}
						}
					},
					{
						title: 'Esportazione Pratiche',
						key: 'disable_export_sale_operation',
						fieldDefinition: {
							name: 'Esportazione Pratiche',
							key: 'disable_export_sale_operation',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								if(record && record.disable_export_sale_operation == 1) return 'Disabilitate';
								return 'Abilitate';
							}
						}
					}
				],
				actions: ['detail', 'create', 'edit', 'delete'],
				itemActionsOrder: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
