import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, EMPTY } from "rxjs";
import { TrenitaliaService } from "../trenitalia.service";
import * as _ from "lodash";
import { catchError, exhaustMap, filter, finalize, take } from "rxjs/operators";
import { RELOAD_BOOKING_PACKAGES } from "../../edit-booking.component";
import { PostSaleDialogComponent } from "./post-sale-dialog/post-sale-dialog.component";
import { AppService } from "app/services/app.service";
import { BookingService } from "../../booking.service";
import { TrenitaliaPostSaleChangeConfirmDialog } from "./trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component";
import { wrapArray } from "app/helpers";
import {TrenitaliaDialogContainerComponent} from '../trenitalia-dialog-container/trenitalia-dialog-container.component';
import {DatasetService} from "../../../../components/dataset/services/dataset.service";

export const supportedPostSaleActions = [
	{
		code: 'booking_change_before_departure',
		label: 'Cambio Data/Ora',
		icon: 'date_range'
	},
	{
		code: 'traveller_change',
		label: 'Modifica dati Passeggeri',
		icon: 'group'
	},
	{
		code: 'change_class',
		label: 'Cambio Classe',
		icon: 'keyboard_double_arrow_up'
	},
	{
		code: 'travel_change_before_departure',
		label: 'Modifica Biglietto',
		icon: 'train'
	},
	{
		code: 'cancellation_before_departure',
		label: 'Cancella',
		icon: 'delete'
	},
	{
		code: 'refund_before_departure',
		label: 'Rimborsa',
		icon: 'euro symbol'
	},
	{
		code: 'carnet_refund',
		label: 'Rimborsa',
		icon: 'euro symbol'
	}
];

@Injectable()
export class TrenitaliaPostSaleService{

	public loading: BehaviorSubject<Boolean>;
	public tripBookingPackage: any;
	public travelDetails: BehaviorSubject<any>;
	public isCarnet = false;

	get serviceMap(){
		return _.get(this.travelDetails.value, 'serviceMap');
	}

	get travellers(){
		return _.get(this.travelDetails.value, 'travel.travellers');
	}

	get postActions(){
		return _.get(this.travelDetails.value, 'postActions');
	}

	constructor(
		public matDialog: MatDialog,
		public trnService: TrenitaliaService,
		public appService: AppService,
		protected mastSnackBar: MatSnackBar,
		protected bookingService: BookingService,
		public datasetService: DatasetService
	){
		this.loading = new BehaviorSubject(false);
		this.travelDetails = new BehaviorSubject(null);

		this.travelDetails.subscribe((travelDetails) => {
			this.isCarnet = this.checkIsCarnet();
		})
	}

	setTravelData(tripBookingPackage: any,isCarnet:boolean){
		this.tripBookingPackage = tripBookingPackage;

		this.loading.next(true);
		this.trnService.getDetails(this.tripBookingPackage.id,isCarnet)
		.subscribe({
			next: (details:any) => {
				this.travelDetails.next(details);
			},
			error: () => {
				// TODO: handle error
			},
			complete: () => {
				this.loading.next(false);
			}
		});
	}

	checkIsCarnet(){
		return !_.isEmpty(_.entries(_.get(this.serviceMap, 'catalog_services', {})).find(service=>_.get(service, [1, 'type'])==='CARNET'))
	}
	
	onDelete() {
		this.loading.next(true);
		// select all post sale details fro cancellations
		const postSaleDetails = _.values(this.postActions).reduce((carry: any[], solutionPostActions) => {
			const cancellationEnabled = _.get(solutionPostActions, 'cancellation_before_departure.enabled', false);
			if(!cancellationEnabled) return carry
			const postSaleDetails = _.get(solutionPostActions, 'cancellation_before_departure.postSaleDetails', []);
			carry = carry.concat(postSaleDetails);
			
			return carry; 
		}, [])
		this.trnService.evaluateTravelPostSale({
			travel: this.travelDetails.value.travel.original,
			selectedPostSaleDetails: postSaleDetails
		}).pipe(
			catchError(err => {
				this.mastSnackBar.open(
					'Errore imprevisto nella cancellazione!',
					'',
					{
						duration: 5000
					}
				);
				return EMPTY;
			}),
			exhaustMap((res: any) => {
				this.loading.next(false);
				return this.matDialog
				.open(TrenitaliaPostSaleChangeConfirmDialog, {
					minWidth: '300px',
					panelClass: 'create-dialog-container',
					data: {
						postSaleData: res.validatedPostSaleRecord,
						type: 'cancel',
						title: 'Cancella prenotazione',
						message: 'Vuoi cancellare la prenotazione?',
						icon: 'delete'
					}
				})
				.afterClosed();
			}),
			filter(dialogRes => dialogRes),
			exhaustMap(res => {
				this.loading.next(true);
				return this.bookingService
				.cancelBooking(this.tripBookingPackage.trip_booking_id, {
					trip_booking_package_ids: [this.tripBookingPackage.id]
				});
			}),
			take(1),
			finalize(() => {
				this.loading.next(false);
			})
		).subscribe(() => {
			this.loading.next(false);
			this.matDialog.closeAll();
			this.appService.appEvent.next({
				name: RELOAD_BOOKING_PACKAGES
			});
		});
	}

	edit(type, travelSolution: any, postActions: any) {
		this.matDialog
			.open(TrenitaliaDialogContainerComponent, {
				minWidth: '800px',
				panelClass: 'create-dialog-container',
				data: {
					participant: this.tripBookingPackage.participant_counters,
					trip_booking_id: this.tripBookingPackage.trip_booking_id,
					trip_booking_package: this.tripBookingPackage,
					mode: type,
					data: {
						travellers: this.travellers,
						postAction: postActions,
						travel: this.travelDetails.value.travel,
						reopen: this.travelDetails.value.reopen,
						travelSolution
					},
					bookingService: this.bookingService
				}
			})
			.afterClosed()
			.subscribe(
				s => {
					this.matDialog.closeAll();
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGES
					});
				},
				error => {
					this.mastSnackBar.open(
						'Errore nella modifica dei dati!Riprovare!',
						'',
						{
							duration: 5000
						}
					);
				},
				() => {
					this.loading.next(false);
				}
			);
	}

	/**
	 * Full refund, for partial refund filter post sale details
	 */
	onRefund(postSaleDetails: any[]) {
		this.loading.next(false);
		let evaluateRes = null;
		this.trnService.evaluateTravelPostSale({
			travel: this.travelDetails.value.travel.original,
			selectedPostSaleDetails: postSaleDetails
		}).pipe(
			catchError(err => {
				this.mastSnackBar.open(
					'Errore imprevisto nel calcolo del rimborso!',
					'',
					{
						duration: 5000
					}
				);
				return EMPTY;
			}),
			exhaustMap(res => {
				this.loading.next(false);
				evaluateRes = res;
				return this.matDialog
				.open(TrenitaliaPostSaleChangeConfirmDialog, {
					minWidth: '300px',
					panelClass: 'create-dialog-container',
					data: {
						postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
							missingAmount: res.missingAmount,
							reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
							reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
							additionalCharges: res.additionalCharges,
							postSaleFee: res.fee
						}),
						type: 'refund',
						title: 'Rimborso viaggio',
						message: 'Vuoi rimborsare il viaggio?',
						icon: 'currency_exchange'
					}
				})
				.afterClosed();
			}),
			filter(dialogRes => dialogRes),
			exhaustMap(res => {
				this.loading.next(true);
				return this.trnService.updateAndConfirm({
					travel: evaluateRes.travel,
					postSaleData: {
						postSaleRecord: evaluateRes.validatedPostSaleRecord,
						missingAmount: evaluateRes.missingAmount,
						reversedPaymentRecords: evaluateRes.reversedPaymentRecords,
						reusedPaymentRecords: evaluateRes.reusedPaymentRecords,
						additionalCharges: evaluateRes.additionalCharges
					},
					type: 'refund'
				})
			}),
			take(1),
			finalize(() => {
				this.loading.next(false);
			})
		).subscribe(() => {
			this.matDialog.closeAll();
			this.appService.appEvent.next({
				name: RELOAD_BOOKING_PACKAGES
			});
			this.mastSnackBar.open(
				'Rimborso effettuato',
				'',
				{
					duration: 5000
				}
			);
		}, error => {
			this.mastSnackBar.open(
				'Errore nella procedura del rimborso!',
				'',
				{
					duration: 5000
				}
			);
		});
	}

	/**
	 * upgrade class
	 */
	changeClass(postSaleDetails,viewContainerRef) {
		this.loading.next(true);
		const data = {
			reopenedTravel: this.travelDetails.value.reopen,
			operationType: 'ticketChange',
			selectedPostSaleDetails: postSaleDetails
		};
		this.trnService.changeClass(data)
		.subscribe(res => {
					this.loading.next(false);
					/*off['travelSolutions'][0]['custom_off'] = {};*/
					this.trnService.loading.next(false);

					this.matDialog
						.open(PostSaleDialogComponent, {
							minWidth: '800px',
							viewContainerRef: viewContainerRef,
							panelClass: 'create-dialog-container',
							data: {
								solution: res,
								travel: this.travelDetails.value.reopen.travel,
								travellers: _.get(res,'travellers'),
								postSaleDetails: postSaleDetails,
								serviceMap:this.serviceMap
							}
						})
						.afterClosed()
						.subscribe(s => {
								this.matDialog.closeAll();
								this.appService.appEvent.next({
									name: RELOAD_BOOKING_PACKAGES
								});
							},
							error => {
								this.appService.showErrorMessage('Errore nella modifica dei dati!Riprovare!');
							},
							() => {
								this.loading.next(false);
							}
						);
				});
	}

	sendEmail(tripBookingPackage){
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/send_travel_mail',
			tripBookingPackage
		);
	}
}
