import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { AppService } from '../../../../../services/app.service';
import {takeUntil} from 'rxjs/operators';
import {DatasetRecordService} from '../../../../components/dataset/services/dataset-record.service';
import {Subject} from 'rxjs';
import * as _ from "lodash";

@Component({
	selector: 'trenitalia-package',
	templateUrl: './trenitalia-package.component.html',
	styleUrls: ['./trenitalia-package.component.scss']
})
export class TrenitaliaPackageComponent implements OnInit,OnDestroy {
	@Input() inputTripBookingPackage: any;
	public dataView;
	public status;
    protected _unsubscribeAll: Subject<any>;
    public totalRowLordo: number;
    public totalFee;
    public isCarnet;

	constructor(
		public appService: AppService,
        public datasetRS: DatasetRecordService,
	) {}

	ngOnInit() {
        this._unsubscribeAll = new Subject();
        this.isCarnet = _.get(this.inputTripBookingPackage, 'external_solution_data.isCarnet')||_.get(this.inputTripBookingPackage, 'target_info.orderType') === 'carnet';
		this.status = this.inputTripBookingPackage.tour_operator_booking_status;
		if (this.inputTripBookingPackage.external_solution_data) {
			this.dataView = Array.isArray(
				this.inputTripBookingPackage.external_solution_data['travel'][
					'travelSolutions'
				]
			)
				? this.inputTripBookingPackage.external_solution_data['travel'][
						'travelSolutions'
				  ][0]
				: this.inputTripBookingPackage.external_solution_data['travel'][
						'travelSolutions'
				  ];
		}

        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(record => {
                let totalLordo = Number(this.inputTripBookingPackage.total_amount);

                if(this.inputTripBookingPackage.children_trip_booking_packages){
                    for (const tbp of this.inputTripBookingPackage.children_trip_booking_packages) {
                        totalLordo += Number(tbp.total_amount);
                    }
                }
                this.totalFee = this.inputTripBookingPackage.fee;
                this.totalRowLordo = totalLordo;
            });
	}
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
