import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TrenitaliaService, getTrainBreadcrumbs, trainTypeImages } from "../../trenitalia.service";
import * as moment from "moment";
import * as _ from 'lodash';
import { DestroyableComponent } from "app/main/destroyable.component";
import { takeUntil } from "rxjs/operators";
import { fuseAnimations } from "@fuse/animations";

@Component({
	selector: 'trenitalia-travel-solution-list-item',
	templateUrl: './travel-solution-list-item.component.html',
	styleUrls: ['./travel-solution-list-item.component.scss'],
	animations : fuseAnimations // TODO: copy animation and scroll from cdkAccordion, material/expansion/expansion-aimations.ts
})
export class TravelSolutionListItemComponent extends DestroyableComponent implements OnChanges{
	@Input() travelSolution: any;
	@Input() isSelected = false;
	@Input() isDetailsOpen = false;
    @Input() selectCarnet : any;

	@Output() onSelect = new EventEmitter();
	@Output() onDetails = new EventEmitter();
	@Output() onPriceSelected = new EventEmitter();
	@Output() onCarnetChange = new EventEmitter();
	
	public checkoutDataMode: string = null;
	public isCarnet = false;
	public isAr = false;
	public isReturn = false;
    public isSeats= false;
	public loading = false;
    public selectedServiceCarnet:any;

	constructor(
		public trnService: TrenitaliaService,
		public elRef: ElementRef
	){
		super();
	}

	ngOnInit(){
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.updateFromCheckoutData()
		});

		this.trnService.loading
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(load => {
			this.loading = load;
		});
	}

	scrollIn(){
		// TODO: copy animation and scroll from cdkAccordion, material/expansion/expansion-aimations.ts
		setTimeout(() => {
			this.elRef.nativeElement.scrollIntoView({block: 'start'});
		}, 600);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.travelSolution){
			//this.updateData();
		}
		if(changes.isSelected && changes.isSelected.currentValue && !changes.isSelected.previousValue){
			this.scrollIn();
		}
		if(changes.isDetailsOpen && changes.isDetailsOpen.currentValue && !changes.isDetailsOpen.previousValue){
			this.scrollIn();
		}
	}

	updateFromCheckoutData(){
		const checkoutData = this.trnService.checkoutData.value;
		this.checkoutDataMode = checkoutData.mode;
		this.isCarnet = Boolean(checkoutData.carnetData);
        this.selectedServiceCarnet = _.get(checkoutData,'carnetData.selectedService');
		this.isAr = Boolean(checkoutData.ar);
        this.isSeats = checkoutData.seatsRequested;
		this.isReturn = checkoutData.direction == 'RETURN';
	}

	indexTrackBy(index, item): any{
		return index;
	}
}