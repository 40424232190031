<!-- HEADER -->
<div class="header p-16 p-sm-24" [ngClass]="calendarService.viewDate | date:'MMM':undefined:'en-US'">

	<div class="header-content" fxLayout="column" fxLayoutAlign="space-between">

		<div class="header-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">

			<div class="logo mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
				<span class="logo-text" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
					Calendario
				</span>
			</div>

			<!-- TOOLBAR -->
			<div class="toolbar" fxLayout="row" fxLayoutAlign="start center">
				
				<button mat-icon-button
						mwlCalendarToday
						[(viewDate)]="calendarService.viewDate"
						(viewDateChange)="viewDateChange.emit($event)"
						aria-label="Today" matTooltip="Today">
					<mat-icon>today</mat-icon>
				</button>
				<!--
				<button mat-icon-button (click)="calendarService.view = 'day'" aria-label="Day" matTooltip="Day">
					<mat-icon>view_day</mat-icon>
				</button>
				-->
				<button mat-icon-button (click)="calendarService.view = 'week'" aria-label="Week" matTooltip="Week">
					<mat-icon>view_week</mat-icon>
				</button>

				<button mat-icon-button (click)="calendarService.view = 'month'" aria-label="Month" matTooltip="Month">
					<mat-icon>view_module</mat-icon>
				</button>
			</div>
		</div>
		<!-- / TOOLBAR -->
		

		<!-- HEADER BOTTOM -->
		<div class="header-bottom" fxLayout="row" fxLayoutAlign="center center"
			 [@animate]="{value:'*',params:{delay:'150ms'}}">

			<button mat-icon-button class="arrow"
					mwlCalendarPreviousView
					[view]="calendarService.view"
					[(viewDate)]="calendarService.viewDate"
					(viewDateChange)="viewDateChange.emit($event)"
					aria-label="Previous">
				<mat-icon>chevron_left</mat-icon>
			</button>

			<div class="title">
				{{ calendarService.viewDate | calendarDate:(calendarService.view + 'ViewTitle'):'en' }}
			</div>

			<button mat-icon-button class="arrow"
					mwlCalendarNextView
					[view]="calendarService.view"
					[(viewDate)]="calendarService.viewDate"
					(viewDateChange)="viewDateChange.emit($event)"
					aria-label="Next">
				<mat-icon>chevron_right</mat-icon>
			</button>
		</div>
		<!-- / HEADER BOTTOM -->
	</div>

	<!-- ADD EVENT BUTTON-->
	<button mat-mini-fab class="add-event-button mat-accent" aria-label="Add event" (click)='onAddClick()'
		*ngIf='canCreate'
			[@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
		<mat-icon>add</mat-icon>
	</button>
	<!-- / ADD EVENT BUTTON -->
</div>
<!-- / HEADER -->