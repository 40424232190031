import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';

function exportEAgency(record: any, dataContainerService: IDataContainerService): boolean{
	const modules = dataContainerService.getValueFromKeyPath('domainModules');
	if(modules && modules.eagency){
		const eagency = modules.eagency;
		return eagency.enabled; 
	}
	return false;
}

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	readonlyIf(record: any): boolean {
		return record === null;
	},
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	inputHint: 'Nome del pacchetto'
});

fieldsMap.set('publish_on_web', {
	key: 'publish_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, il pacchetto sarà visibile anche sul sito',
	options: [
		{
			label: 'Non pubblico',
			value: 0
		},
		{
			label: 'Pubblico',
			value: 1
		}
	]
});

fieldsMap.set('active_status', {
	key: 'active_status',
	name: 'Attivo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se disattivato, non sarà possibile prenotare partenze',
	options: [
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	]
});

fieldsMap.set('trip_service_rule_type', {
	key: 'trip_service_rule_type',
	name: 'Tiplogia di prezzo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputHint: 'Per gruppo o singolo partecipante',
	inputRequired: true,
	options: [
		{
			label: 'Per singolo partecipante',
			value: '0'
		},
		{
			label: 'Per gruppo',
			value: '1'
		}
	],
	showIf(record: any): boolean{
		if(record.code === null){
			return true;
		}
		return false;
	}
});

/** eAgency fields */
fieldsMap.set('supplier_id', cloneFieldDefinition(entitySupplierIdField, { 
	key: 'supplier_id',
	exportEAgency: exportEAgency,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return exportEAgency(record, dataContainerService);
	}
}));

fieldsMap.set('active_commission_percent', {
	key: 'active_commission_percent',
	name: 'Commissioni %',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	exportEAgency: exportEAgency,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return exportEAgency(record, dataContainerService);
	}
});

fieldsMap.set('taxation', {
	key: 'taxation',
	name: 'Fiscalità',
	valueType: ValueTypes.PROPERTY,
	inputConfig: {
		type: InputTypes.SELECT,
		// required: true
	},
	showIf(formData: any, dataContainerService: IDataContainerService): boolean{
		const record = dataContainerService.getValueFromKeyPath('trip_packages.record');
		return !record || !record.domain || isRecordInFilteredDomain(record, record.is_owner) || exportEAgency(record, dataContainerService);
	},
	exportEAgency: exportEAgency,
});

fieldsMap.set('commessa_id', {
	key: 'commessa_id',
	name: 'Commessa',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'commesse',
	inputConfig: {
		clearable: true,
		type: InputTypes.DATASET_AUTOCOMPLETE,
		hint: 'Scrivi per cercare una commessa'
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		supportCreate: true
	},
	exportEAgency: exportEAgency,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return exportEAgency(record, dataContainerService);
	}
});

export {fieldsMap};
