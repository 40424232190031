import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { BookingService } from '../booking.service';
@Component({
	selector   : 'trip-booking-stepper',
	templateUrl: './trip-booking-stepper.component.html',
	styleUrls: ['./trip-booking-stepper.component.scss']
	// encapsulation: ViewEncapsulation.None,
})
export class TripBookingStepperComponent{

	@Input() record: any;
	@Input() layout: any;
	@Output() stepChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('stepper', { static: false }) private stepper: MatStepper;

	constructor(private bookingService: BookingService){}

	next(): void{
		this.bookingService.nextStep.emit(this.stepper);
	}

	previous(): void{
		this.bookingService.prevStep.emit(this.stepper);
		this.stepper.previous();
	}
}
