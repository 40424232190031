<div *ngIf='(scenario.value === "create" || scenario.value === "edit")' class="alert-message options-message" (click)='resetForm("go-to-dataset", false)'>
	<span class="material-icons">undo</span> Torna alle opzioni
</div>

<div *ngIf='(scenario.value === "create" || scenario.value === "edit"); else show_dataset_options' class='full-form'>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<form #formViewer 
			autocomplete="off"
			name='form' 
			*ngIf='formViewerService.initialized.value && formViewerService.formGroup' 
			[formGroup]="formViewerService.formGroup" 
			fxLayout="column" 
			fxLayoutAlign="start"
			fxLayoutAlign.lt-lg='stretch'>

			<div class="instruction">
				<p class="instruction">
					Se necessario, è possibile configurare il prodotto con più opzioni. Le opzioni sono differenze nel servizio che possono modificare il prezzo o la disponibilità del prodotto, ad esempio un pranzo incluso, un tour in un'altra lingua o un tour privato.
				</p>
			</div>

			<div class="instruction">
				<label class="input-label section-question">Codice Opzione</label>
				<p class="instruction">
					Per aiutarti a tenere traccia dell'opzione prenotata dal cliente, aggiungi il tuo codice di riferimento. Questo è utile quando hai più opzioni per un'attività.
				</p>
			</div>
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("code")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["code"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<!-- descrizione -->
			
			<div class="instruction">
				<label class="input-label section-question">Titolo Opzione</label>
				<p class="instruction">
					Scrivi un titolo breve e descrittivo per questa opzione che spieghi come è diverso dal tour standard
				</p>
			</div>

			<div class="examples">
				<h3 class="examples-title"><b>Esempi</b></h3>

				<div class="good-examples">
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Visita guidata in francese</span>
					</div>
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Tour privato</span>
					</div>
				</div>
				<div class="bad-examples">
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Roma: tour guidato dei Musei Vaticani in francese</span>
					</div>
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Esclusivo tour privato solo per te e tre persone care</span>
					</div>
				</div>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<!-- limite partecipanti consentiti  -->

			<div class="instruction">
				<label class="input-label section-question">Numer massimo partecipanti</label>
			</div>
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("limit_pax_per_booking")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["limit_pax_per_booking"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<!-- descr -->

			<div class="instruction">
				<label class="input-label section-question">Descrizione dell'opzione</label>
				<p class="instruction">
					Scrivi una breve descrizione di ciò che rende questa opzione diversa o speciale.
				</p>
			</div>
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("note")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["note"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
			
			<div class="instruction">
				<label class="input-label section-question">Impostazioni attività</label>
				<p class="instruction">
					Quali lingue parla la guida durante l'attività? Scegliere tutte le opzioni pertinenti.
				</p>
			</div>
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("languages")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["languages"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>

				<mat-chip-list *ngIf='selectedLanguages.length > 0'>
					<mat-chip *ngFor='let language of selectedLanguages' (click)='removeLang(language)'>{{language.label}}</mat-chip>
				</mat-chip-list>
			</div>

			<div class="instruction top-20">
				<p class="instruction">
					Audioguide
				</p>
			</div>
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("audio_guide_languages")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["audio_guide_languages"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>

				<mat-chip-list *ngIf='selectedAudioGuideLanguages.length > 0'>
					<mat-chip *ngFor='let language of selectedAudioGuideLanguages' (click)='removeLang(language, "audio_guide")'>{{language.label}}</mat-chip>
				</mat-chip-list>
			</div>

			<div class="instruction top-20">
				<label class="input-label section-question">Durata</label>
			</div>
			<div class="base-textfield base-textfield-large duration-textfields">
				<div class="form-field">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("duration")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["duration"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>

				<div class="form-field">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("length_type")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["length_type"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
		</form>

		<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<button color='accent' [disabled]='!formViewerService.formGroup.valid' mat-flat-button (click)='onSave()'>
				<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
				<span *ngIf='datasetACS.loading.value'>Azione in corso ...</span>
			</button>
			<button *ngIf='(!datasetACS.loading.value && scenario.value === "edit")' color='warn' mat-flat-button (click)='delete()'>
				Elimina opzione
			</button>
		</div>

	</div>
</div>

<ng-template #show_dataset_options>
	<button color="accent" (click)='resetForm("create", false)' class="gyg-button gyg-button-medium gyg-button-cta-light outline">
		Aggiungi opzione
	</button>

	<!-- 
		<ng-container *ngIf='stepService.selectedOption.value'>
			<div class="selected-option">
				<span class="text">L'opzione selezionata è {{stepService.selectedOption.value.description}}</span>
				<div class="actions">
					<span class="edit" (click)='onSelected(stepService.selectedOption.value)'>Modifica</span>
					<span class="delete" (click)='delete()'>Elimina</span>
				</div>
			</div>
		</ng-container>
	 -->

	<dataset-list #tripOption
		[enableCreate]='false'
		(selected)='onSelected($event)'
		[selectable]='true'
		[selectionBtnTxt]='"Modifica"'
		[elevate]='false'
		[showSearchBar]='true'
		[showTitle]='false'
		[datasetCode]='"trip_options"'
		[parentDatasetACS]='parentDatasetACS'
		[filters]='{trip_package_id: datasetACS.getValueFromKeyPath("trip_packages.recordId")}'
		[viewConfig]='tripOptionsViewConfig'
	></dataset-list>
</ng-template>