import * as tslib_1 from "tslib";
import { OnInit, ViewContainerRef } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { NewTripPackageOverviewDialogComponent } from 'app/main/components/dataset/dialogs/new-trip-package-overview/dialog.component';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ConfirmWithReasonDialogComponent } from 'app/main/dialogs/confirm-with-reason-dialog/dialog.component';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { BookingService } from '../../../booking.service';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { actualTripsListViewConfig } from '../../new-trip-booking-package-selection/package-trip-selection/actual-trips-list-view-config';
import { RELOAD_BOOKING_PACKAGES, RELOAD_BOOKING } from '../../../edit-booking.component';
import { TripBookingParticipantComponent } from '../../../step-2/trip-booking-participants/trip-booking-participant.component';
import { TripBookingResourceComponent } from '../../../step-2/trip-booking-resources/trip-booking-resource.component';
import { TripBookingServiceComponent } from '../../../step-2/trip-booking-services/trip-booking-service.component';
import { isDatasetActionAllowed } from 'app/helpers';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import * as _ from 'lodash';
var TripPackageBookingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageBookingComponent, _super);
    function TripPackageBookingComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, domainFilterService, tripBookingPackageService, tripBookingPackagesStepService, bookingService, viewContainerRef) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.domainFilterService = domainFilterService;
        _this.tripBookingPackageService = tripBookingPackageService;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.bookingService = bookingService;
        _this.viewContainerRef = viewContainerRef;
        _this.loadingMeetingPoint = false;
        _this.canDelete = false;
        _this.canCancel = false;
        _this.isResaleCopy = false;
        _this.tripBookingPackage = null;
        _this.meetingPointDescription = '';
        return _this;
    }
    Object.defineProperty(TripPackageBookingComponent.prototype, "iterationData", {
        get: function () {
            return this.tripBookingPackageService.iterationData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripPackageBookingComponent.prototype, "tripBookingPackageRelatedDialogData", {
        get: function () {
            return {
                datasetCode: '',
                actionCode: '',
                tripBookingPackage: this.tripBookingPackage
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripPackageBookingComponent.prototype, "tripBookingStatus", {
        get: function () {
            return this.datasetACS.getValueFromKeyPath('trip_bookings.record.status');
        },
        enumerable: true,
        configurable: true
    });
    TripPackageBookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            _this.tripBookingPackage = record;
            _this.canDelete = _this.bookingService.canEdit && isDatasetActionAllowed(record, 'delete');
            _this.canCancel = !_this.canDelete && _this.bookingService.canEdit && isDatasetActionAllowed(record, 'cancel');
            _this.isResaleCopy = Boolean(record.ref_id);
            _this.meetingPointDescription = _this.computeMeetingPointDescription();
        });
    };
    TripPackageBookingComponent.prototype.computeMeetingPointDescription = function () {
        var description = _.get(this.tripBookingPackage, 'default_meeting_point_unified_list_item.meeting_point.description');
        var time = _.get(this.tripBookingPackage, 'default_meeting_point_unified_list_item.time');
        if (!time)
            time = _.get(this.tripBookingPackage, 'actual_trip.start_time');
        if (time) {
            time = time.slice(0, 5);
            if (description)
                description += ' (' + time + ')';
            else
                description = time;
        }
        return description;
    };
    TripPackageBookingComponent.prototype.deleteTripBookingPackage = function () {
        var _this = this;
        if (this.canCancel) {
            this.matDialog.open(ConfirmWithReasonDialogComponent, {
                width: '550px',
                data: {
                    title: 'Conferma',
                    reasonLabel: 'Vuoi annullare questa partenza?',
                    reasonRequired: false
                }
            })
                .afterClosed()
                .subscribe(function (result) {
                if (!result)
                    return;
                _this.bookingService.cancelBooking(_this.tripBookingPackage.trip_booking_id, Object.assign(result, {
                    trip_booking_package_ids: [_this.tripBookingPackage.id]
                }))
                    .subscribe(function () {
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                });
            });
        }
        else if (this.canDelete) {
            this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
                .afterClosed()
                .subscribe(function (result) {
                if (!result)
                    return;
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGES
                });
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            });
        }
        else {
            this.datasetNavigatorService.openDialog(TextDialogComponent, {
                title: 'Attenzione',
                contentMessage: 'Operazione non permessa'
            });
        }
    };
    TripPackageBookingComponent.prototype.trackTripBookingResourcesFun = function (index, item) {
        return item.id;
    };
    TripPackageBookingComponent.prototype.editDefaultMeetingPoint = function () {
        var _this = this;
        var data = {
            datasetCode: 'meeting_point_unified_list_items',
            filters: {
                list_id: this.tripBookingPackage.actual_trip.meeting_point_list_id,
                default_meeting_point_list_item_id: this.datasetRS.record.value.default_meeting_point_list_item_id
            },
            title: 'Punto di ritrovo',
            parentDatasetACS: this.datasetACS,
            parentDatasetRS: this.datasetRS
        };
        this.matDialog.open(SelectDatasetRecordDialogComponent, {
            data: data
        }).afterClosed()
            .subscribe(function (record) {
            if (!record)
                return;
            _this.loadingMeetingPoint = true;
            _this.datasetService.post(_this.datasetACS.getUpdateRoute(_this.datasetRS.record.value), {
                default_meeting_point_list_item_id: record.id
            }).pipe(takeUntil(_this._unsubscribeAll))
                .subscribe({
                next: function (response) {
                    _this.datasetRS.reload().subscribe({
                        next: function () {
                            _this.loadingMeetingPoint = false;
                        },
                        error: function () {
                            _this.loadingMeetingPoint = false;
                        }
                    });
                },
                error: function (response) {
                    _this.loadingMeetingPoint = false;
                }
            });
        });
    };
    TripPackageBookingComponent.prototype.reloadTripBooking = function () {
        this.appService.appEvent.next({ name: RELOAD_BOOKING });
    };
    TripPackageBookingComponent.prototype.editTrip = function () {
        this.addTripBookingPackage(this.tripBookingPackage);
    };
    /**
     *
     * @param tripBookingPackageToReplace optional
     */
    TripPackageBookingComponent.prototype.addTripBookingPackage = function (tripBookingPackageToReplace) {
        var _this = this;
        var tripBooking = this.datasetACS.getValueFromKeyPath('trip_bookings.record');
        var data = {
            datasetCode: 'actual_trips',
            filters: { start_from: tripBooking.booking_date, only_bookable: 1 },
            viewConfig: actualTripsListViewConfig
        };
        if (tripBookingPackageToReplace) {
            data.filters.trip_package_id = tripBookingPackageToReplace.trip_package_id;
            //data.filters.start_day = tripBookingPackageToReplace.actual_trip.start_day.substring(0, 10);
            data.filters.exclude_ids = tripBookingPackageToReplace.actual_trip_id;
            if (tripBookingPackageToReplace.properties.going_trip_booking_package_id && tripBookingPackageToReplace.properties.going_trip_booking_package_id.value) {
                var goingPackageId_1 = tripBookingPackageToReplace.properties.going_trip_booking_package_id.value;
                var bookingPackages = this.tripBookingPackagesStepService.tripBookingPackagesPage.items || [];
                var goingBookingPackage = bookingPackages.find(function (bookingPackage) { return bookingPackage.id == goingPackageId_1; });
                if (goingBookingPackage) {
                    data.filters.going_trip_id = goingBookingPackage.actual_trip_id;
                }
            }
        }
        this.matDialog.open(SelectDatasetRecordDialogComponent, {
            panelClass: 'create-dialog-container',
            data: data
        }).afterClosed()
            .subscribe(function (actualTrip) {
            if (!actualTrip)
                return;
            var postData = {
                trip_booking_id: _this.datasetRS.recordId,
                actual_trip_id: actualTrip.id,
            };
            var actionEndPoint = '/dataset/trip_booking_packages/create';
            if (tripBookingPackageToReplace) {
                postData['trip_booking_package_id_to_replace'] = tripBookingPackageToReplace.id;
                actionEndPoint = '/dataset/trip_bookings/command/replace_trip';
            }
            _this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
                .subscribe(function () {
                // without a subscribe observer not run
            });
        });
    };
    TripPackageBookingComponent.prototype.viewTripPackage = function () {
        this.datasetNavigatorService.openDialog(NewTripPackageOverviewDialogComponent, {
            parentDatasetRS: this.datasetRS,
            parentDatasetACS: this.datasetACS,
            tripPackage: this.tripBookingPackage.trip_package
        }, { minWidth: 700 });
    };
    TripPackageBookingComponent.prototype.getRelatedDialogComponent = function (datasetCode) {
        switch (datasetCode) {
            case 'trip_booking_resources':
                return TripBookingResourceComponent;
            case 'trip_booking_services':
                return TripBookingServiceComponent;
            case 'trip_booking_participant_extras':
                return TripBookingParticipantComponent;
            default:
                return null;
        }
    };
    return TripPackageBookingComponent;
}(DestroyableComponent));
export { TripPackageBookingComponent };
