import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const userCommonfieldsMap: Map<string, IFieldDefinition> = new Map();

userCommonfieldsMap.set('user.access_id', {
	key: 'user.access_id',
	name: 'Accesso ID',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

userCommonfieldsMap.set('user.access_username', {
	key: 'user.access_username',
	name: 'Nome utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

userCommonfieldsMap.set('user.name', {
	key: 'user.name',
	name: 'Utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

userCommonfieldsMap.set('domain_user_role.name', {
	key: 'domain_user_role.name',
	name: 'Ruolo Dominio',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

userCommonfieldsMap.set('domain.display_name', {
	key: 'domain.display_name',
	name: 'Dominio',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});


userCommonfieldsMap.set('branch_name', {
	key: 'branch_name',
	name: 'Filiale',
	valueType: ValueTypes.STRING,
	inputConfig: {
		readOnly: true
	}
});

userCommonfieldsMap.set('branch_code', {
	key: 'branch_code',
	name: 'Filiale',
	valueType: ValueTypes.STRING,
	inputConfig: {
		readOnly: true
	}
});

export {userCommonfieldsMap};
