import {Component, forwardRef, OnInit} from '@angular/core';
import {PageClosureContainerService} from '../../../../page-closure-container.service';
import {DatasetActionContainerService} from '../../../../../components/dataset/services/dataset-action-container.service';
import {FormViewerService} from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import {TrenitaliaService} from '../../trenitalia.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	selector: 'trenitalia-travellers-carnet',
	templateUrl: './travellers-carnet.component.html',
	styleUrls: ['../../search-travellers/search-travellers.component.scss','./travellers-carnet.component.scss'],
	providers:[
		forwardRef(() => PageClosureContainerService),
		forwardRef(() => DatasetActionContainerService),
		FormViewerService
	]
})
export class TravellersCarnetComponent implements OnInit {
	public formFields = formFields;
	protected checkoutData;
	constructor(
		public datasetACS: DatasetActionContainerService,
		public formViewerService: FormViewerService,
		public trnService : TrenitaliaService
	) {
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		});
	}
	get requestInvoice(): boolean {
		const request_invoice = this.formViewerService.formGroup.get('request_invoice');
		return request_invoice ? request_invoice.value : false;
	}
	
	ngOnInit() {
		this.checkoutData = this.trnService.checkoutData.getValue()
		if(this.checkoutData.user){
			const email = _.get(this.checkoutData.user, 'customers.contacts', []).find(user=>user.contactType==='EMAIL');
			const phone = _.get(this.checkoutData.user, 'customers.contacts', []).find(user=>user.contactType==='MOBILE');
			this.formViewerService.formGroup.get('first_name').setValue(_.get(this.checkoutData,'user.name'))
			this.formViewerService.formGroup.get('last_name').setValue(_.get(this.checkoutData,'user.surname'))
			this.formViewerService.formGroup.get('email').setValue(_.get(email,'contactValue'));
			this.formViewerService.formGroup.get('phone').setValue(_.get(phone,'contactValue'));
			this.formViewerService.formGroup.get('cartafreccia').setValue(_.get(this.checkoutData.user, 'cartafreccia'));
			this.formViewerService.formGroup.get('data_n').setValue(moment(_.get(this.checkoutData.user, 'customers.birthDate'), 'DD/MM/YYYY'));
			this.checkoutData.finalTravellers = this.formViewerService.getFormData();
			this.trnService.checkoutData.next(this.checkoutData);
		}
		this.formViewerService.formGroup.valueChanges.subscribe(s => {
            if(this.formViewerService.formGroup.valid){
                this.checkoutData.finalTravellers = this.formViewerService.getFormData();
                this.checkoutData.invoiceRequested = s.request_invoice;
               /* this.trnService.checkoutData.next(this.checkoutData);*/
            }
		});
	}
	
	
}
		