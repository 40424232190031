import { Component, OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FieldViews, IFormConfig, ListViewItemTypes } from 'app/interfaces';
import requirePlafondData from 'app/main/actions/require-plafond.action';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { DatasetListDialogComponent, IDatasetListDialogData } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import editPlafondForm from 'app/configs/datasets/plafond/forms/edit.form';

@Component({
	selector   : 'plafond',
	templateUrl: './detail-view.component.html',
	styleUrls  : ['./detail-view.component.scss']
})
export class PlafondComponent extends BaseViewComponent implements OnInit{

	public resetData: any;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService){
			super(datasetACS, datasetRS, datasetNavigatorService);
		}

	ngOnInit(): void {
		super.ngOnInit();

		// load reset data
		//this.lastResetInfo();
	}

	lastResetInfo(): void{
		const call = this.datasetNavigatorService.datasetService.post('/dataset/plafond/command/get_plafond_log_info', {
			target_id: this.datasetRS.recordId
		});

		call.subscribe({
			next: (resetData) => {
				this.resetData = resetData;
			},
			error: (e) => {
				// ignore
			}
		});
	}

	async reset(): Promise<void>{
		this.datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
			title: 'Plafond',
			contentMessage: 'Vuoi azzerare il plafond?'
		})
		.afterClosed()
		.subscribe(confirm => {
			if (!confirm) return;
			// make call
			this.makeCallReset()
			.then(() => {
				this.datasetRS.reload();
			});
		});
	}
	
	async makeCallReset(): Promise<any>{
		const data = {
			recordId: this.datasetRS.recordId
		};

		const loadingDialogRef = this.datasetNavigatorService.openDialog<IActionDialogData>(ActionDialogComponent, {
			title: 'Attendere durante il completamento della richiesta...',
			contentMessage: ''
		});

		let response;

		// make http call
		try{
			response = await this.datasetNavigatorService.datasetService.post('/dataset/plafond/command/reset_plafond', data)
			.toPromise();

			if (response){
				loadingDialogRef.componentInstance.loading = false;
				if (response.success){
					loadingDialogRef.componentInstance.resultStatus = 'success';
					loadingDialogRef.componentInstance.setTitle('Plafond impostato');
					loadingDialogRef.componentInstance.setMessage('Il plafond è stato azzerato correttamente.');
				}else{
					loadingDialogRef.componentInstance.resultStatus = 'error';
					loadingDialogRef.componentInstance.setTitle('Errore operazione');
					loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
				}
			}
	
			return response;
		}catch(e){
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore operazione');
			loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
		}
	}
}
