import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('code_status_view', {
	key: 'code_status_view',
	name: 'Codice/Stato',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		console.log(record);
		let html = '';

		html = '<span>' + record.code  + '</span><br>';
		if(record.active_status == 1){
			html += '<span>Attivo </span>';
		}else{
			html += '<span>Disattivo </span>';
		}
		return html;
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	formValidators: [ Validators.required ],
	inputHint: 'Nome categoria'
});

fieldsMap.set('packages_count', {
	key: 'packages_count',
	name: '#Pacchetti',
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('priority_order', {
	key: 'priority_order',
	name: 'Priorità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('active_status', {
	key: 'active_status',
	name: 'Attivo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	]
});


fieldsMap.set('active_status_filter', {
	key: 'active_status',
	name: 'Attivo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	]
});

fieldsMap.set('publish_on_web', {
	key: 'publish_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, la categoria sarà visibile anche sul sito',
	options: [
		{
			label: 'NON pubblico',
			value: 0
		},
		{
			label: 'Pubblico',
			value: 1
		}
	]
});

export {fieldsMap};
