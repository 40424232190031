<h2>{{record.description}}</h2>
<div fxLayout='row wrap' fxLayout.sm="column" fxLayout.xs="column" fxLayout.md="column" fxLayoutAlign='start start' fxLayoutGap="24px grid">
	<div fxFill fxFlex.gt-md='calc(50% - 24px)'>
		<mat-card>
			<mat-card-header>
				<mat-card-title>{{'Descrizione' | translate}}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<table>
					<tbody>
						<tr>
							<td>{{'Codice' | translate}}</td>
							<td><span>{{record.code}}</span></td>
						</tr>
						<tr>
							<td>{{'Stato' | translate}}</td>
							<td>
								<div style="display:flex;align-items: center;">
									<span 
										[class.red-bg]='record.active_status === 0'	
										[class.green-bg]='record.active_status === 1 && record.publish_on_web === 1' 
										[class.orange-bg]='record.active_status === 1 && record.publish_on_web !== 1' 
										class="record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>
									<span *ngIf='record.active_status === 0'>{{'Non Attivo' | translate}}</span>
									<span *ngIf='record.active_status === 1 && record.publish_on_web !== 1'>{{'Attivo' | translate}}</span>
									<span *ngIf='record.active_status === 1 && record.publish_on_web === 1'>{{'Attivo e pubblicato' | translate}}</span>
								</div>
							</td>
						</tr>
						<tr *ngIf='record.destinations'>
							<td>{{'Destinazioni' | translate}}</td>
							<td><ul><li *ngFor='let destination of record.destinations'>{{destination.description}}</li></ul></td>
						</tr>
						<tr *ngIf='record.properties.price.value'>
							<td>{{'Prezzo' | translate}}</td>
							<td><span>{{record.properties.price.value | currency:'EUR'}}</span></td>
						</tr>
						<tr *ngIf='record.properties.email.value'>
							<td>Email</td>
							<td><span>{{record.properties.email.value}}</span></td>
						</tr>
						<tr *ngIf='record.properties.phone.value'>
							<td>{{'Telefono' | translate}}</td>
							<td><span>{{record.properties.phone.value}}</span></td>
						</tr>
					</tbody>
				</table>

				<div *ngIf='record.properties.note_booking.value'>
					<span class='h2'>{{'Note' | translate}}</span>
					<div style='padding: 8px;border: 1px solid #eee;border-radius: 8px;'>
						{{record.properties.note_booking.value}}
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	
	<div fxFill fxFlex='calc(50% - 24px)'>
		<mat-card>
			<mat-card-header>
				<mat-card-title>{{'Traduzioni Attive' | translate}}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<table>
					<tbody>
						<tr *ngFor='let languageCode of record.active_language_codes'>
							<td><img class='flag' [src]='"assets/images/flags/4x3/" + languageCode + ".svg"' /></td>
							<td><span *ngIf='appService.supportedLanguages[languageCode]'>{{appService.supportedLanguages[languageCode].description}}</span></td>
						</tr>
					</tbody>
				</table>
				<p class='secondary-text' *ngIf='!record.active_language_codes || record.active_language_codes.length === 0'>{{'Nessuna traduzione attiva' | translate}}</p>
			</mat-card-content>
		</mat-card>
		
	</div>

	<div fxFill fxFlex.lt-lg='' fxFlex.lg='calc(50% - 24px)'>
		<mat-card>
			<mat-card-header>
				<mat-card-title>Media</mat-card-title>
			</mat-card-header>
			<img mat-card-image alt='Immagine di copertina' *ngIf='record.master_image' [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + record.master_image'/>
			<mat-card-content>
				<p class='secondary-text' *ngIf='!record.master_image'>{{'Nessuna immagine di copertina' | translate}}</p>

				<h3>{{'Immagini' | translate}}</h3>
				<div class='carousel-images' fxLayout='row wrap' fxLayoutGap='10px' *ngIf='record.carousel_images'>
					<img *ngFor='let document of record.carousel_images; trackBy: trackImageBy' [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + document.id'>
				</div>

				<p class='secondary-text' *ngIf='!record.carousel_images || record.carousel_images.length === 0'>{{'Nessuna immagine inserita' | translate}}</p>
			</mat-card-content>
		</mat-card>
	</div>

	<div fxFill fxFlex.lt-lg='' fxFlex.lg='calc(50% - 24px)'></div>
</div>