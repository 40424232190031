import {ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('thumbnail_image', {
	key: 'code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record && record.thumbnail_image){
			html += '<img width="100" src="' + record.thumbnail_image + '">';
		}
		return html;
	}
});

fieldsMap.set('tour_name', {
	key: 'tour_name_long',
	name: 'Nome',
	valueType: ValueTypes.STRING
});

fieldsMap.set('tour', {
	key: 'tour',
	name: 'Tour',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record){
			if(record.thumbnail_image){
				html += '<img width="200" style="max-height:100px !important;" src="' + record.thumbnail_image + '">';
			}
			html += '<br\>';
			if(record.tour_name_long){
				html += '<span class="h3">' + record.tour_name_long + '</span>';
			}
		}
		return html;
	}
});


fieldsMap.set('from_price', {
	key: 'from_price_display',
	name: 'A partire da',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML
});

fieldsMap.set('channel', {
	key: 'channle',
	name: 'Channel',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record && record.channel){
			if(record.channel.logo_url){
				html += '<img width="80" src="' + record.channel.logo_url + '">';
			}
			html += '<br\>';
			if(record.channel.channel_name){
				html += '<span class="h3">' + record.channel.channel_name + '</span>';
			}
		}
		return html;
	}
});

export {fieldsMap};
