import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bipercentage'
})
export class BiPercentagePipe implements PipeTransform {

	public constructor() {}

	transform(item: any, type: string = 'amount'): any {
		console.log(item,type);
		let key = '';
		let keyComparision = '';
		switch (type){
			case 'amount':{
				key = 'total_amount';
				keyComparision = 'total_amount_comparison';
				break;
			}
			case 'participants':{
				key = 'total_participants';
				keyComparision = 'total_participants_comparison';
				break;
			}
			case 'supplier_price':{
				key = 'total_supplier_price';
				keyComparision = 'total_supplier_price_comparison';
				break;
			}
			case 'profit':{
				key = 'total_profit';
				keyComparision = 'total_profit_comparison';
				break;
			}
			case 'percent':{
				key = 'total_profit_percent';
				keyComparision = 'total_profit_percent_comparison';
				break;
			}
		}
		const diff = item[key] - item[keyComparision];
		const division = diff / item[keyComparision];
		
		const result = division * 100;
		if(type=='percent')console.log(item,[item[key], item[keyComparision],diff, division, result]);
		return result;
	}
}