import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(auth, appService, router) {
        this.auth = auth;
        this.appService = appService;
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.auth.isAuthenticated()) {
                    this.auth.desiredPath = state.url.split('?')[0];
                    this.auth.desiredQueryParams = state.root.queryParams;
                    this.router.navigate(['auth/login'], {
                        replaceUrl: true
                    });
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
            });
        });
    };
    return AuthGuardService;
}());
export { AuthGuardService };
