import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import {
	DatasetActionContainerService,
	DatasetEvents,
	IDatasetEvent,
} from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { BookingService } from '../booking.service';
import {
	FormControl,
	FormGroupDirective,
	NgForm,
	Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
	FormDialogComponent,
	IFormDialogData,
} from 'app/main/dialogs/form-dialog/dialog.component';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { isArray } from 'lodash';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import { BaseStepChangerComponent } from '../base-step-changer.component';

@Component({
	selector: 'edit-booking-step-3',
	templateUrl: './view.component.html',
	styleUrls: ['view.component.scss'],
})
export class EditBookingStep3Component extends BaseStepChangerComponent
	implements OnInit, OnDestroy {
	public loading = false;

	@ViewChild('datasetListComponent', { static: true })
	datasetListComponent: DatasetListComponent;

	get record(): any {
		return this.datasetRS.record.value;
	}

	couponCodeFormControl = new FormControl('', [Validators.required]);

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public bookingService: BookingService
	) {
		super(bookingService);
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log(this.record);
	}

	onDiscountDatasetEvent(event: IDatasetEvent): void {
		this.datasetRS.reload();
	}

	async openDiscountListForm(): Promise<any> {
		const dialogRef = await this.datasetNavigatorService
			.openDialog<IFormDialogData>(
				FormDialogComponent,
				{
					title: 'Aggiungi sconto',
					dataContainerService: this.datasetACS,
					formConfig: {
						fields: [
							{
								key: 'coupon_code',
								name: 'Sconto',
								valueType: ValueTypes.REFERENCE,
								inputType: InputTypes.DATASET_SELECT,
								datasetCode: 'discounts',
								remoteOptions: {
									limit: 100,
									getLabel(option): any {
										if (!option) return '';
										return option.description?option.name +' - '+ option.description:option.name;
									},
									getValue(option): any {
										if (!option) return;
										return option.coupon_code;
									},
									getDisplay(option): any {
										if (!option) return '';
										return option.description?option.name +' - '+ option.description:option.name;
									},
								},
							},
						],
					},
				},
				{ minWidth: 700 }
			)
			.afterClosed()
			.toPromise();
		if (!dialogRef) return; // operazione annullata
		if (dialogRef && !dialogRef.coupon_code) return; // operazione annullata

		return await this.addCouponCode(dialogRef.coupon_code);
	}

	async addCouponCode(couponCode: string): Promise<any> {
		const loadingDialogRef2 = this.datasetNavigatorService.openDialog(
			LoadingDialogComponent,
			{
				title: 'Aggiunta sconto in corso',
			}
		);
		try {
			const response = await this.datasetNavigatorService.datasetService
				.post<any>(
					'/dataset/trip_booking_discounts/command/add_coupon',
					{
						trip_booking_id: this.datasetRS.recordId,
						code: couponCode,
					}
				)
				.toPromise();

			let responseMessage = '';
			if (response.message) {
				responseMessage = response.message;
			}
			if (response.success) {
				if (this.datasetNavigatorService) {
					this.datasetNavigatorService.appService.showSuccessMessage(
						responseMessage || 'Aggiunta avventuta con successo'
					);
				}
			} else {
				if (!responseMessage)
					responseMessage =
						"Si è verificato un problema durante l'aggiunta del codice sconto.";
				if (this.datasetNavigatorService) {
					this.datasetNavigatorService.appService.showErrorMessage(
						responseMessage
					);
				}
			}
			if (response.success) {
				this.datasetRS.reload();
				// reload dataset list discounts
				this.datasetListComponent.reload();
			}

			return response.success;
		} catch (e) {
			if (this.datasetNavigatorService) {
				this.datasetNavigatorService.appService.showErrorMessage(
					e.error.message
				);
			}
		} finally {
			// reset coupon code input
			this.couponCodeFormControl.reset();
			// finally close loading dialog
			loadingDialogRef2.close();
		}
	}
}
