import { Component, OnInit, Input } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { CommonFormStepComponent } from './step.component';

@Component({
	selector   : 'common-sub-dataset-form-step',
	templateUrl: './step.component.html',
	//styleUrls  : ['./step.component.scss']
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class CommonSubDatasetFormStepComponent extends CommonFormStepComponent implements OnInit{

	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: this.parentDatasetACS.actionCode,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true,
			useBeforeCalls: false
		}).pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(!record) return;
			this.datasetRS.recordId = this.stepConfig.options.getSubDatasetRecordId(record);
			this.datasetRS.reload();
		});

		super.ngOnInit();
	}
}