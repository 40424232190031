import { Observable, Subject } from 'rxjs';
import { IFormDialogData, FormDialogComponent } from '../dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';
import { DatasetService } from '../components/dataset/services/dataset.service';

export function action_join_domain(datasetService: DatasetService, actionRequired: any): Observable<any>{
	const observer = new Subject<any>();
	const targetDomain = actionRequired.data.target_domain;
	if(!targetDomain) {
		observer.error('Dati non validi');
		observer.complete();
		return observer;
	}
	const dialogRef = datasetService.matDialog.open<any, IFormDialogData, any>(FormDialogComponent, {
		data: {
			title: 'Seleziona una filiale',
			message: 'Per agire in questo dominio ( ' + targetDomain.display_name + ' ) è nessario associare il tuo account al dominio.' +
				'<br>Seleziona la Filiale da usare in questo dominio. (Opzionale)'
			,
			formConfig: {
				fields: [
					{
						key: 'branch_id',
						name: 'Filiale',
						valueType: ValueTypes.REFERENCE,
						datasetCode: 'branches',
						inputConfig: {
							type: InputTypes.DATASET_AUTOCOMPLETE
						},
						remoteOptions: {
							getParams(): any{
								const params = {
									domain_id: targetDomain.id
								};
								return params;
							},
							limit: 100,
							getLabel(option): any{
								if(!option) return '';
								return option.name + ' (' + option.code + ')';
							},
							getValue(option): any{
								if(!option) return;
								return option.id;
							}
						}
					}
				]
			},
			positiveText: 'Continua senza filiale',
			negativeText: 'Annulla operazione'
		}
	});
	dialogRef.afterClosed()
	.subscribe(formData => {
		datasetService.post<any>('/auth/me/join/domain', {
			branch_id: formData.branch_id,
			target_domain_id: targetDomain.id
		})
		.subscribe({
			next: response => {
				datasetService.appService.showSuccessMessage('Ora puoi eseguire azioni nel sotto-dominio.');
				observer.next();
				observer.complete();
			},
			error: error => {
				datasetService.showErrorMessageFromResponse(error);
				observer.error(error);
				observer.complete();
			}
		});
	});

	return observer;
}
