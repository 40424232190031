<h2>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>
<div class="trip_data">
	<div class="trip_details" *ngIf='datasetACS.actionConfig && datasetACS.actionConfig.viewConfig.formConfig'>
		<div card-data-viewer
			[record]='datasetRS.record.value'
			[datasetACS]='datasetACS'
			[viewFormConfig]='datasetACS.actionConfig.viewConfig.formConfig'
			[editFormConfig]='editFormConfig'
		></div>
		<!-- Confirmed participants -->
		<div class='participant-list' *ngIf='datasetRS.record.value.participant_overall.total > 0'>
			<div class="rettangolo" [class.notall]='datasetRS.record.value.participant_overall.total != datasetRS.record.value.total_participants_confirmed'></div>
			<div class="total_participants" (click)='openList()'>
				<span class="material-icons icon">groups</span>
				<span class="totals">{{ datasetRS.record.value.total_participants_confirmed }}/{{ datasetRS.record.value.participant_overall.total }}</span>
				<!-- spinner for websocket -->
				<div class="spinner" *ngIf='datasetRS.loading.value'>
					<mat-spinner [diameter]='20'></mat-spinner>
				</div>
			</div>
		</div>
	</div>
	<div class="package_details" *ngIf='package'>
		<mat-card class="mat-elevation-z0 details-departure">
			<img mat-card-image alt='Immagine di copertina' *ngIf='package.master_image' [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + package.master_image'/>
			<mat-card-content>
				<div class="name-trip-detail">
					<a [routerLink]='"/dataset/detail/trip_packages/" + package.id'>{{package.description}}</a>
				</div>
				<table>
					<tbody>
						<tr>
							<td>{{'Codice' | translate}}</td>
							<td><span>{{package.code}}</span></td>
						</tr>
						<tr *ngIf='package.destinations'>
							<td>{{'Destinazioni' | translate}}</td>
							<td><ul><li *ngFor='let destination of package.destinations'>{{destination.description}}</li></ul></td>
						</tr>
						<tr *ngIf='package.properties.price.value'>
							<td>{{'Prezzo al pubblico' | translate}}</td>
							<td><span>{{package.properties.price.value | currency:'EUR'}}</span></td>
						</tr>
						<tr *ngIf='package.properties.email.value'>
							<td>{{'Email' | translate}}</td>
							<td><span>{{package.properties.email.value}}</span></td>
						</tr>
						<tr *ngIf='package.properties.phone.value'>
							<td>{{'Telefono' | translate}}</td>
							<td><span>{{package.properties.phone.value}}</span></td>
						</tr>
					</tbody>
				</table>
				<div *ngIf='package.properties.note_booking.value'>
					<span class='h2'>{{'Note' | translate}}</span>
					<div style='padding: 8px;border: 1px solid #eee;border-radius: 8px;'>
						{{package.properties.note_booking.value}}
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>

<div *ngIf='datasetRS.record.value && !resellView' class='mt-16 mb-16 participant-list-btn' fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap='10px'>
	<mat-card fxFlex fxFill class="mat-elevation-z0 details-departure">
		<mat-card-content>
			<a mat-button mat-flat-button [matMenuTriggerFor]="menu" color='accent'>{{'Lista partecipanti' | translate}}</a>

			<mat-menu #menu="matMenu">
				<a mat-menu-item [href]='appService.getBaseServerAddressRoot() + "/participants/download/by/departure/" + datasetRS.recordId' target="_blank">{{'Stampa' | translate}}</a>
				<button mat-menu-item (click)="viewEmailInput()">{{'Invia via mail' | translate}}</button>
			</mat-menu>
		</mat-card-content>
	</mat-card>
</div>

<mat-tab-group>
	<ng-container *ngIf="showBooking">
		<mat-tab [label]='"Prenotazioni" | translate'>
			<ng-template matTabContent>
				<dataset-list
					[showTitle]='false'
					[elevate]='false'
					datasetCode='trip_booking_report_views'
					[parentDatasetACS]='datasetACS'
					[parentDatasetRS]='datasetRS'
					[filters]='{actual_trip_id: datasetRS.recordId }'
					[cacheDatasetListFilters]='false'
					[autoFetchData]='false'
					[showExportBtn]='false'
					[contextualDomainId]='contextualDomainId'
				></dataset-list>
			</ng-template>
		</mat-tab>
	</ng-container>
	<ng-container *ngIf='!resellView'>
		<mat-tab [label]="'Risorse' | translate" *ngIf='externalSyncedType == 0'>
			<ng-template matTabContent>
				<dataset-list
					[showTitle]='false'
					[elevate]='false'
					datasetCode='actual_trip_resources'
					[parentDatasetACS]='datasetACS'
					[parentDatasetRS]='datasetRS'
					[filters]='{actual_trip_id: datasetRS.recordId }'
					[showSearchBar]='false'
				>
				</dataset-list>
			</ng-template>
		</mat-tab>
		<mat-tab *ngFor='let resource of tripResourceWithStaff;trackBy: trackById' [label]='"Accompagnatori" | translate'>
			<ng-template mat-tab-label>
				{{'Accompagnatori risorsa' | translate}}: {{resource.description}}
			</ng-template>
			<dataset-list
				[showTitle]='false'
				[autoFetchData]='true'
				datasetCode='trip_resource_staff_assignments'
				[parentDatasetRS]='datasetRS'
				[parentDatasetACS]='datasetACS'
				[showSearchBar]='false'
				[elevate]='false'
				[filters]='{ date: datasetRS.record.value.start_day, actual_trip_id: datasetRS.recordId, trip_resource_id: resource.id}'
				[viewConfig]='tripResourceStaffAssignmentsTableView'
				[enableCreate]='false'
			>
			</dataset-list>
		</mat-tab>
	</ng-container>
</mat-tab-group>
