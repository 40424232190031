<h1 *ngIf='!resultStatus' mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-title fxLayout='row' fxLayoutAlign='start center'>
	<ng-container *ngIf='resultStatus === "success"'>
		<mat-icon color='accent'>check_circle_outline</mat-icon>
		<span class='h2 ml-4 primary-fg'>{{data?.title || 'Successo'}}</span>
	</ng-container>
	<ng-container *ngIf='resultStatus === "error"'>
		<mat-icon color='warn'>error_outline</mat-icon>
		<span class='h2 ml-4 warn-fg'>{{data?.title || 'Errore'}}</span>
	</ng-container>
</div>
<mat-dialog-content class="mat-spinner-action-dialog">
	<div *ngIf='loading' class="spinner" fxLayout='row' fxLayoutAlign='center center'>
		<mat-spinner></mat-spinner>
	</div>
	<!--<div class='mb-4' fxLayout='row' fxLayoutAlign='start center'>
		<ng-container *ngIf='resultStatus === "success"'>
			<mat-icon color='accent'>check_circle_outline</mat-icon>
			<span class='h5'>Successo</span>
		</ng-container>
		<ng-container *ngIf='resultStatus === "error"'>
			<mat-icon  color='warn'>error_outline</mat-icon>
			<span class='h5'>Errore</span>
		</ng-container>
	</div>-->
	<div 
		[class.warn-fg]='resultStatus === "error"' 
		[class.primary-fg]='resultStatus === "success"' 
		[innerHTML]="data.contentMessage"
	></div>
</mat-dialog-content>
<mat-dialog-actions align='end' *ngIf='!loading'>
	<button mat-button *ngIf='data.negativeText' (click)="onNoClick()">{{data.negativeText | translate}}</button>
	<button mat-flat-button (click)='onYesClick()' color='accent' >{{data.positiveText?data.positiveText:'OK' | translate}}</button>
</mat-dialog-actions>
