import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TrenitaliaService, getTrainBreadcrumbs, trainTypeImages } from "../../trenitalia.service";
import * as moment from "moment";
import * as _ from 'lodash';

@Component({
	selector: 'trenitalia-travel-solution-header',
	templateUrl: './travel-solution-header.component.html',
	styleUrls: ['./travel-solution-header.component.scss']
})
export class TravelSolutionHeaderComponent implements OnChanges{
	@Input() travelSolution: any;
	@Input() isSelected = false;

	@Output() onSelect = new EventEmitter();
	@Output() onDetails = new EventEmitter();

	public dayDiffDeparture = 0;
	public dayDiffArrival = 0;
	public checkoutDataMode: string = null;
	public isCarnet = false;
	public departureStationName = '';
	public arrivalStationName = '';

	constructor(
		public trnService: TrenitaliaService
	){

	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.travelSolution){
			this.updateData();
		}
	}

	updateData(){
		const startDay = this.trnService.checkoutData.value.direction === 'RETURN' ? 
			moment(this.trnService.checkoutData.value.return.start) :
			moment(this.trnService.checkoutData.value.start) ;

		this.dayDiffDeparture = Math.abs(moment(this.travelSolution.departure).diff(startDay, 'days'));
		this.dayDiffArrival = Math.abs(moment(this.travelSolution.arrival).diff(startDay, 'days'));

		this.checkoutDataMode = this.trnService.checkoutData.value.mode;
		this.isCarnet = Boolean(this.trnService.checkoutData.value.carnetData);
		this.arrivalStationName = _.get(this.travelSolution, ['trains', _.get(this.travelSolution, 'trains.length') - 1, 'end', 'stationName']);
		this.departureStationName = _.get(this.travelSolution, ['trains', 0, 'start', 'stationName']);
	}

	indexTrackBy(index, item): any{
		return index;
	}
}