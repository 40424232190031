import { ApplicationRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { AppService } from 'app/services/app.service';
import { AuthService, CheckUserStatues } from 'app/services/auth.service';
import { FormBuilder } from '@angular/forms';
import FilterFields from './filter.fields';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { DomSanitizer } from '@angular/platform-browser';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { SelectDomainDialogComponent } from 'app/main/components/dataset/dialogs/select-domain-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { action_edit_password } from 'app/main/actions/edit-password.action';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { action_edit_profile } from 'app/main/actions/edit-profile.action';
var ToolbarComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    function ToolbarComponent(_fuseConfigService, _fuseSidebarService, _translateService, auth, formBuilder, matDialog, appService, dashboardService, sanitizer, domainFilterService, datasetService, zone, app) {
        var _this = this;
        this._fuseConfigService = _fuseConfigService;
        this._fuseSidebarService = _fuseSidebarService;
        this._translateService = _translateService;
        this.auth = auth;
        this.formBuilder = formBuilder;
        this.matDialog = matDialog;
        this.appService = appService;
        this.dashboardService = dashboardService;
        this.sanitizer = sanitizer;
        this.domainFilterService = domainFilterService;
        this.datasetService = datasetService;
        this.zone = zone;
        this.app = app;
        this.fileterFields = FilterFields;
        this.isAdministrator = false;
        this.dateNow = Date.now();
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon': 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon': 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon': 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon': 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];
        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'en'
            },
            {
                id: 'it',
                title: 'Italiano',
                flag: 'it'
            }
        ];
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.zone.runOutsideAngular(function () {
            setInterval(function () {
                zone.run(function () {
                    _this.dateNow = Date.now();
                });
            }, 1000);
        });
        this.filterFormGroup = this.formBuilder.group({
            domain_id: this.domainFilterService.filterDomainId.value,
        });
    }
    ToolbarComponent.prototype.setFilterDomain = function (domainId) {
        this.domainFilterService.setFilterDomain(domainId);
    };
    ToolbarComponent.prototype.unsetFilterDomain = function () {
        this.domainFilterService.unsetFilterDomain();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterFormGroup.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            if (data.domain_id != _this.domainFilterService.filterDomainId.value) {
                if (data.domain_id) {
                    _this.setFilterDomain(data.domain_id);
                }
                else {
                    _this.unsetFilterDomain();
                }
            }
            _this.selectedDomainId = data && data.domain_id;
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (settings) {
            _this.horizontalNavbar = settings.layout.navbar.position === 'top';
            _this.rightNavbar = settings.layout.navbar.position === 'right';
            _this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': this._translateService.currentLang });
        this.auth.userDataObserver
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            if (!data) {
                _this.user = null;
                _this.isAdministrator = false;
            }
            else {
                _this.user = data.user;
                _this.isAdministrator = _this.auth.isAdministrator();
            }
        });
        this.appService.appEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.name !== 'global_image_profile')
                return;
            _this.userImage = event.target;
        });
        this.domainFilterService.filterDomainId
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (domainId) {
            _this.filterFormGroup.controls['domain_id'].setValue(domainId);
        });
    };
    Object.defineProperty(ToolbarComponent.prototype, "userImage", {
        get: function () {
            var baseServerAddress = this.appService.getBaseServerAddressRoot() + '/document_files/download/';
            if (this._userImage) {
                return baseServerAddress + this._userImage;
            }
            else if (this.user.profile_image_id) {
                return baseServerAddress + this.user.profile_image_id;
            }
            return 'assets/images/avatars/blank-profile-picture.png';
        },
        set: function (value) {
            this._userImage = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On destroy
     */
    ToolbarComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    ToolbarComponent.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    /**
     * Search
     *
     * @param value
     */
    ToolbarComponent.prototype.search = function (value) {
        // Do your search here...
        console.log(value);
    };
    /**
     * Set the language
     *
     * @param lang
     */
    ToolbarComponent.prototype.setLanguage = function (lang) {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this._translateService.use(lang.id).subscribe(function () { return localStorage.setItem('locale', lang.id); });
    };
    ToolbarComponent.prototype.logout = function () {
        this.auth.logout();
    };
    ToolbarComponent.prototype.editProfile = function () {
        var _this = this;
        action_edit_profile(this.user, this.datasetService)
            .then(function (response) {
            if (!response)
                return;
            var success = response.httpResp.success;
            var dialog = response.dialog;
            if (success) {
                _this.auth.updateUserInfo();
                // subscribe on checked user observable
                _this.auth.chekcUserStatus.subscribe(function (status) {
                    if (status == CheckUserStatues.CHECKED)
                        dialog.close();
                });
            }
        });
    };
    ToolbarComponent.prototype.resetPassword = function () {
        action_edit_password(this.user, this.datasetService);
    };
    ToolbarComponent.prototype.filterDomainDialog = function () {
        var _this = this;
        this.matDialog.open(SelectDomainDialogComponent, {
            data: {
                title: 'Seleziona dominio',
                selectedDomain: this.domainFilterService.filterDomainId.value,
            }
        }).afterClosed()
            .subscribe(function (domainId) {
            if (domainId)
                _this.domainFilterService.setFilterDomain(domainId);
        });
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
