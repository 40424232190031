import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Attivo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	inputRequired: true,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('client_id', {
	key: 'client_id',
	name: 'Chiave Pubblica',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
});

fieldsMap.set('client_secret', {
	key: 'client_secret',
	name: 'Chiave Segreta',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	inputRequired: true,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService): any {
		return 'method_payments.paypal';
	}
});

export {fieldsMap as paypalFieldsMap};
