import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, Observable } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { MatSelect } from '@angular/material/select';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'dataset-select-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class DatasetSelectInputComponent extends DestroyableComponent implements OnInit{
	public selectOptions: any[];
	public loading = false;
	protected fetchOptionsSubscription: Subscription;
	protected items = [];
	public selectedLabel: string;
	public supportCreate = false;

	@ViewChild('myInput', { static: false }) 
	myInput: ElementRef;

	@ViewChild('matSelect', { static: false })
	matSelect: MatSelect;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.buildSelectResourceOptions();

			this.getFormControl().valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(value => {
				if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.onSet){
					this.inputService.formInputDefinition.extra.onSet(this.inputService.formGroup, this.getItemFromValue(value));
				}
				if(value){
					const selectedOption = this.selectOptions.find(el => {
						return el.value === value;
					});
					if(!selectedOption){
						this.fetchSelectedOption(value)
						.subscribe( () => {
							this.updateSelectedLabel();
						});
					}else{
						this.updateSelectedLabel();
					}
				}else{
					if(value === undefined){
						this.getFormControl().setValue(null);
						return;
					}
					this.updateSelectedLabel();
				}	
			});
		});
	}

	updateSelectedLabel(): void{
		const value = this.getFormControl().value;
		const selectedOption = this.selectOptions.find(el => {
			return el.value === value;
		});

		this.selectedLabel = selectedOption ? selectedOption.label : null;
	}

	getFormControl(): AbstractControl{
		return this.inputService.control;
	}

	getItemFromValue(value): any{
		if(!this.items) return;
		for (const item of this.items) {
			const itemValue = this.inputService.formInputDefinition.remoteOptions.getValue(item);
			if(itemValue === value) return item;
		}
		return null;
	}

	buildSelectResourceOptions(text?: string): void{
		if(!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions) return;
		this.selectOptions = [];
		let params = {
			perPage:  this.inputService.formInputDefinition.remoteOptions.limit || 50
		};
		if(this.inputService.formInputDefinition.remoteOptions){
			this.supportCreate = Boolean(this.inputService.formInputDefinition.remoteOptions.supportCreate);
		}
		if(this.inputService.formInputDefinition.remoteOptions.getParams){
			params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
		}
		const formControl = this.getFormControl();
		if(formControl && formControl.value) params['include'] = formControl.value;
		if(text){
			params['text-search'] = text;
		}
		if(this.fetchOptionsSubscription){
			this.fetchOptionsSubscription.unsubscribe();
		}
		this.loading = true;
		let route;
		if (this.inputService.formInputDefinition.datasetCode) {
			route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
			if(this.inputService.formInputDefinition.remoteOptions.getPivotConfig){
				const pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
				const parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
				route = '/pivot/' + pivotConfig.pivotCode  + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
			}
		} else if (this.inputService.formInputDefinition.customActionCode){
			route = this.inputService.formInputDefinition.customActionCode;
		}
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		
		this.fetchOptionsSubscription = this.datasetService.get<IPaginationResponse<any>>(route, params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				this.items = result.items;
				const options = [];
				for (const item of result.items) {
					options.push({
						value: this.inputService.formInputDefinition.remoteOptions.getValue(item),
						label: this.inputService.formInputDefinition.remoteOptions.getLabel(item, this.inputService.dataContainerService)
					});
				}
				this.selectOptions = options;
				this.loading = false;
				if(formControl.value){
						const selectedOption = this.selectOptions.find(el => {
							return el.value === formControl.value;
						});
						if(!selectedOption){
							this.fetchSelectedOption(formControl.value)
							.subscribe( () => {
								this.updateSelectedLabel();
							});
					}else{
						this.updateSelectedLabel();
					}
				} else{
					if(formControl.value === undefined){
						this.getFormControl().setValue(null);
						return;
					}
					this.updateSelectedLabel();
				}
				//this.updateSelectedLabel();
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	search(text: string): void{
		this.buildSelectResourceOptions(text);
	}

	fetchSelectedOption(value: string): Observable<any>{
		const resultSubject = new Subject<any>();
		const id = this.inputService.control.value;
		if(!id || !this.inputService.formInputDefinition.datasetCode) {
			resultSubject.next(null);
			resultSubject.complete();
			return resultSubject;
		}
		this.loading = true;
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.datasetService.get<any>('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				if(response){
					this.selectOptions.push({
						value: this.inputService.formInputDefinition.remoteOptions.getValue(response),
						label: this.inputService.formInputDefinition.remoteOptions.getLabel(response, this.inputService.dataContainerService)
					});
				}
				this.loading = false;
				resultSubject.next(response);
				resultSubject.complete();
			},
			error: response => {
				this.loading = false;
				resultSubject.error(response);
				resultSubject.complete();
			}
		});
		return resultSubject;
	}

	clear(): void{
		this.inputService.control.setValue(null);
		this.inputService.control.markAsDirty();
	}

	openedChange(isOpen: boolean): void {
		this.myInput.nativeElement.focus();

		/*if (isOpen) {
			this.appService.emitEvent('open-menu');
		} else {
			this.appService.emitEvent('close-menu');
		}*/
	}

	selectionChange(event: MatOptionSelectionChange) {
		this.appService.eventEmitter.emit({
			name: 'selected_label_' + this.inputService.formInputDefinition.key, 
			target: event.source['triggerValue']
		});
	}

	emitEventOnSupportCreation(event: any): void{
		this.appService.eventEmitter.emit({
			name: 'clickOnSupportCreation_' + this.inputService.formInputDefinition.key, 
			target: event
		});
	}
}
