import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
var RenderColumnPipe = /** @class */ (function () {
    function RenderColumnPipe() {
    }
    RenderColumnPipe.prototype.transform = function (value, valueType) {
        switch (valueType) {
            case 'icon':
                return '<i class="' + value + '"></i>';
            case 'langs':
                return this.getFlags(value);
            default:
                return value;
        }
    };
    RenderColumnPipe.prototype.getFlags = function (value) {
        var e_1, _a;
        if (!value)
            return '';
        var active_language_codes = value;
        var html = '<div style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
        var style = 'style="margin-right: 5px;"';
        if (active_language_codes.length > 0) {
            try {
                for (var active_language_codes_1 = tslib_1.__values(active_language_codes), active_language_codes_1_1 = active_language_codes_1.next(); !active_language_codes_1_1.done; active_language_codes_1_1 = active_language_codes_1.next()) {
                    var lang = active_language_codes_1_1.value;
                    html += '<img width="16" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (active_language_codes_1_1 && !active_language_codes_1_1.done && (_a = active_language_codes_1.return)) _a.call(active_language_codes_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            html += '<span>---</span>';
        }
        html += '</div>';
        return html;
    };
    return RenderColumnPipe;
}());
export { RenderColumnPipe };
