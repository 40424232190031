import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'tinymce-input',
	templateUrl: './input.component.html'
})
export class TinyMCEInputComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public tinymceConfig = {
		base_url: 'assets/tinymce',
		plugins: 'lists link image media table insertdatetime preview code', 
		branding: false,
		setup: null,
		toolbar_mode: 'scrolling',
		toolbar_drawer: 'floating',
		mobile: {theme: 'silver'},
		toolbar: 'bold italic | alignleft ' // undo redo | styleselect |
			+ 'aligncenter alignright alignjustify | bullist numlist | outdent indent'
			+ ' | code',
	};

	public ready = false;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;

			const baseHref = document.querySelector('base').href;

			if(this.tinymceConfig.base_url == 'assets/tinymce') this.tinymceConfig.base_url = baseHref + this.tinymceConfig.base_url;
			if(this.inputService.inputConfig.readOnly){
				this.tinymceConfig.setup = function (editor) {
					editor.setMode('readonly');
				};
			}

			this.ready = true;
		});
	}
}
