import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
var RadioInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RadioInputComponent, _super);
    function RadioInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.selectOptions = [];
        return _this;
    }
    RadioInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            // only one build will write correct selectOptions
            if (_this.inputService.formInputDefinition.options) {
                _this.sourceOptions = _this.inputService.formInputDefinition.options;
                _this.selectOptions = _this.inputService.formInputDefinition.options;
            }
            else {
                _this.buildSelectPropertyOptions();
                _this.buildSelectOptions();
            }
            if (_this.inputService.control) {
                _this.inputService.control.valueChanges
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe(function () {
                    _this.updateSelectedLabel();
                });
            }
            _this.updateSelectedLabel();
        });
    };
    RadioInputComponent.prototype.updateSelectedLabel = function () {
        if (!this.inputService.control)
            return;
        var value = this.inputService.control.value;
        if (!this.sourceOptions)
            return null;
        var selected = this.sourceOptions.find(function (el) {
            return el.value === value;
        });
        this.selectedLabel = selected ? selected.label : null;
    };
    RadioInputComponent.prototype.buildSelectPropertyOptions = function () {
        if (!this.inputService.propertyDefinition)
            return;
        this.sourceOptions = this.inputService.propertyDefinition.property_value_options;
        this.selectOptions = this.inputService.propertyDefinition.property_value_options;
    };
    RadioInputComponent.prototype.buildSelectOptions = function () {
        if (!this.inputService.formInputDefinition || !this.inputService.dataContainerService || !this.inputService.formInputDefinition.optionSource)
            return;
        this.selectOptions = [];
        var source = this.inputService.dataContainerService.getValueFromKeyPath(this.inputService.formInputDefinition.optionSource);
        if (!source) {
            console.warn('source', this.inputService.formInputDefinition.optionSource, this.inputService.dataContainerService);
            return;
        }
        var options = source[this.inputService.formInputDefinition.optionSourceKey];
        if (!options) {
            console.warn('options', this.inputService.formInputDefinition.optionSourceKey, 'not defined in', source);
            return;
        }
        this.sourceOptions = options.map(function (item) {
            return {
                value: item.id,
                label: item.name
            };
        });
        this.selectOptions = this.sourceOptions.map(function (item) { return item; });
    };
    RadioInputComponent.prototype.search = function (query) {
        this.selectOptions = this.select(query);
    };
    RadioInputComponent.prototype.select = function (query) {
        var e_1, _a;
        var result = [];
        try {
            for (var _b = tslib_1.__values(this.sourceOptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var option = _c.value;
                if (option.label.toLowerCase().indexOf(query) > -1) {
                    result.push(option);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return result;
    };
    return RadioInputComponent;
}(DestroyableComponent));
export { RadioInputComponent };
