import {FieldViews, IFieldDefinition, InputTypes, ValueTypes} from 'app/interfaces';
import {Validators} from '@angular/forms';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import {showFieldBasedOnConfig} from "../../commons/shared-field-configs/show-field-on-config.field";

const fieldsMap: Map<string, IFieldDefinition> = new Map();


fieldsMap.set('master_image', {
	key: 'master_image',
	name: 'Immagine',
	valueType: ValueTypes.FILE,
	fieldView: FieldViews.IMAGE,
	resize: {
		width: null,
		height: 53
	}
});

fieldsMap.set('publish_on_web', {
	key: 'publish_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, il pacchetto sarà visibile anche sul sito',
	options: [
		{
			label: 'NON pubblico',
			value: 0
		},
		{
			label: 'Pubblico',
			value: 1
		}
	]
});


fieldsMap.set('publish_on_web_filter', {
	key: 'publish_on_web',
	name: 'Pubblico sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'NON pubblico',
			value: 0
		},
		{
			label: 'Pubblico',
			value: 1
		}
	],
	skipIf(record,dataContainerService: IDataContainerService):any{
		return !showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_filter_fields', 'publish_on_web', true);
	}
});


fieldsMap.set('note_booking', {
	key: 'note_booking',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.PROPERTY,
	inputHint: 'Note',
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return true;
	}
});

fieldsMap.set('supplier_id', cloneFieldDefinition(entitySupplierIdField, { key: 'supplier_id' }));

fieldsMap.set('active_language_codes', {
	key: 'active_language_codes',
	name: 'Traduzioni attive',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

		if(record.active_language_codes && record.active_language_codes.length > 0){
			const style = 'style="margin-right: 5px;"';
			for (const lang of record.active_language_codes) {
				html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
			}
		}else{
			html += '<span>---</span>';
		}

		html += '</div>';
		return html;
	}
});

fieldsMap.set('priority_order', {
	key: 'priority_order',
	name: 'Priorità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('child_from', {
	key: 'child_from',
	name: 'Bambini da',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	placeholder: '4',
	defaultValue(): number{ return 4; }
});

fieldsMap.set('child_to', {
	key: 'child_to',
	name: 'Adulto da',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	placeholder: '11',
	defaultValue(): number{ return 11; }
});

fieldsMap.set('adult_to', {
	key: 'adult_to',
	name: 'Senior da',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	placeholder: '64',
	defaultValue(): number{ return 64; }
});

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	readonlyIf(record: any): boolean {
		return record === null;
	},
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	inputHint: 'Nome del prodotto'
});

fieldsMap.set('allotment_type', {
	key: 'allotment_type',
	name: 'Tipo Allotment',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): string {
		return 'free';
	},
	options: [
		{
			label: 'Libero',
			value: 'free'
		},
		{
			label: 'Calendario',
			value: 'shared_allotment'
		},
		{
			label: 'Fisso',
			value: 'fixed'
		}
	],
	showIf(){
		return false; // disable for now
	}
});

fieldsMap.set('product_type_filter', {
	key: 'product_type',
	name: 'Tipo Prodotto',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	datasetCode: 'select_options',
	remoteOptions: {
		supportCreate: false,
		limit: 20,
		getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
			return {
				options_code: 'product_types'
			};	
		},
		getLabel(option): string{
			if(!option) return '';
			return option.label;
		},
		getValue(option): string{
			if(!option) return '';
			return option.value;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.label;
		}
	}
	
});

fieldsMap.set('product_category_filter',{
	key: 'trip_category_id',
	name: 'Categoria',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	skipIf(record,dataContainerService: IDataContainerService):any{
		return !showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_filter_fields', 'trip_category_id', true);
	},
	datasetCode: 'trip_categories',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
	},
})

fieldsMap.set('mac_causal', {
	key: 'mac_causal',
	name: 'MAC Causale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'MAC Causale',
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	skipIf(record: any, dataContainerService: IDataContainerService){
		return dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.seta.enabled') != true;
	}
});

fieldsMap.set('mac_category', {
	key: 'mac_category',
	name: 'MAC Categoria',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'MAC Categoria',
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	skipIf(record: any, dataContainerService: IDataContainerService){
		return dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.seta.enabled') != true;
	}
});

fieldsMap.set('taxation', {
	key: 'taxation',
	name: 'Fiscalità',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'select_options',
	inputHint: 'Seleziona la fiscalità',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
			return {
				options_code : 'taxation',
				target: 'products'
			};	
		},
		getLabel(option): string{
			if(!option) return '';
			return option.label;
		},
		getValue(option): string{
			if(!option) return '';
			return option.value;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.label;
		}
	}	
});

fieldsMap.set('rt_transmission_mode', {
	key: 'rt_transmission_mode',
	name: 'Opzioni avanzate RT',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	titleTooltip: 'Questo campo ha priorità sulla fiscalità',
	defaultValue(){ return 1; },
	disallowNull: true,
	options: [
		{
			label: 'In base alla fiscalità',
			value: 1
		},
		{
			label: 'Invia e ignora fiscalità',
			value: 2
		},
		{
			label: 'Non inviare e ignora fiscalità',
			value: 3
		}
	],
	showIf(record, dataContainerService: IDataContainerService): boolean{
		return dataContainerService && dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.kiara_cloud_api.enabled') == true;
	}
});

/*fieldsMap.set('external_id', {
	key: 'external_id',
	name: 'ID esterno',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT
	}
});*/

fieldsMap.set('public_product_on_web', {
	key: 'public_product_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, il prodotto sarà visibile anche sul sito',
	disallowNull: true,
	defaultValue(){return 0},
	onValueChanged(form){
		if(!form) return;
		if(form.get('public_product_on_web') && form.get('public_product_on_web').value === null){
			form.get('public_product_on_web').setValue(0);
		}
	},
	options: [
		{
			label: 'No',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	]
});

export {fieldsMap};
