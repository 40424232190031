import {Component, Inject, OnDestroy, OnInit, forwardRef, Injector, SkipSelf, Optional} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { IListViewConfig } from 'app/interfaces';
import { DatasetRecordService } from '../../services/dataset-record.service';
import * as _ from 'lodash';

export interface ISelectDatasetRecordDialogData {
	title?: string;
	datasetCode: string;
	filters?: any;
	viewConfig?: IListViewConfig;
	parentDatasetACS?: DatasetActionContainerService;
	parentDatasetRS?: DatasetRecordService;
	domainId?: string;
	tableStyle?: string;
	filterStyle?: string;
	selectionBtnIcon?: string;
	selectionBtnTxt?: string;
	onFilersChanged?: (data: any) => void
}

@Component({
	selector: 'select-dataset-record-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: ['./dialog.components.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService), 
		forwardRef(() => DatasetActionContainerService)
	]
})
export class SelectDatasetRecordDialogComponent implements OnInit, OnDestroy{

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		@Inject(forwardRef(() => PageClosureContainerService)) public pageClosureContainerPage: PageClosureContainerService,
		@Inject(forwardRef(() => DatasetActionContainerService)) public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<SelectDatasetRecordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ISelectDatasetRecordDialogData,
		@SkipSelf()
		@Optional()
		public parentDatasetACS?: DatasetActionContainerService,
		@SkipSelf()
		@Optional()
		public parentDatasetRS?: DatasetRecordService
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.data.datasetCode,
			actionCode: 'list',
			fetchData: false,
			parentDatasetACS: this.data.parentDatasetACS || this.parentDatasetACS,
			parentDatasetRS: this.data.parentDatasetRS || this.parentDatasetRS,
		});
		if(this.data.domainId) this.datasetACS.putContextData('contextual_domain_id', this.data.domainId);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	onSelected(element): void{
		this.dialogRef.close(element);
	}

	onDatasetEvent(event: IDatasetEvent){
		if(event.eventName == DatasetEvents.LIST_FIlTER_CHANGED && this.data && typeof(this.data.onFilersChanged) == 'function') this.data.onFilersChanged(event.data);
	}
}
