import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Attivo',
	inputType: InputTypes.TOGGLE,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('external_ref', {
	key: 'external_ref',
	name: 'Codice esterno',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record): boolean{
		console.log('showIf', record);
		if(!record) return false;
		return record.tour_operator_code === 'tour-cms';
	}
});

fieldsMap.set('external_ref', {
	key: 'external_ref',
	name: 'Codice esterno',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record, dataContainerService: IDataContainerService): boolean{
		const data = dataContainerService.getValueFromKeyPath('trip_package_integrations.record');
		if(!data) return false;
		return data.tour_operator_code === 'tour-cms';
	}
});

export {fieldsMap};
