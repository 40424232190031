import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from './../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('exclude'),
	// fieldsMap.get('meeting_point_id'),
	// fieldsMap.get('return_point_id'),
	fieldsMap.get('note')
];

const baseInfoFormConfig: IFormConfig = {
	fields
};

export default baseInfoFormConfig;
