import * as tslib_1 from "tslib";
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FormBuilder } from '@angular/forms';
import { TripPackageTripCategoriesComponent } from '../pivot_trip_packages_trip_categories/step.component';
import { takeUntil } from 'rxjs/operators';
var ResellTripPackageTripCategoriesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResellTripPackageTripCategoriesComponent, _super);
    function ResellTripPackageTripCategoriesComponent(parentDatasetRS, parentDatasetACS, datasetRS, datasetACS, datasetNavigatorService, formBuilder) {
        var _this = _super.call(this, datasetRS, datasetACS, datasetNavigatorService, formBuilder) || this;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.formBuilder = formBuilder;
        _this.datasetACS.init({
            datasetCode: 'trip_packages',
            actionCode: 'edit',
            parentDatasetACS: _this.parentDatasetACS,
            parentDatasetRS: _this.parentDatasetRS,
            datasetRS: _this.datasetRS,
            fetchData: false
        });
        _this.datasetACS.ready
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetRS.record.next(_this.parentDatasetACS.getValueFromKeyPath('reselledData'));
        });
        return _this;
    }
    return ResellTripPackageTripCategoriesComponent;
}(TripPackageTripCategoriesComponent));
export { ResellTripPackageTripCategoriesComponent };
