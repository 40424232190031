/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dataset-create.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/common-step/smart-interface-view-container/view.component.ngfactory";
import * as i3 from "../../services/step-state.service";
import * as i4 from "../../services/dataset.service";
import * as i5 from "../../services/dataset-action-container.service";
import * as i6 from "../../components/common-step/smart-interface-view-container/view.component";
import * as i7 from "../../../../../services/app.service";
import * as i8 from "../../services/dataset-record.service";
import * as i9 from "../../services/dataset-navigator.service";
import * as i10 from "../../../../../../@fuse/components/sidebar/sidebar.service";
import * as i11 from "@angular/router";
import * as i12 from "@angular/common";
import * as i13 from "../../components/common-step/common-step-view-container/view.component.ngfactory";
import * as i14 from "../../components/common-step/common-step-view-container/view.component";
import * as i15 from "@angular/material/card";
import * as i16 from "@ngx-translate/core";
import * as i17 from "../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i18 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i19 from "@angular/material/progress-bar";
import * as i20 from "@angular/platform-browser/animations";
import * as i21 from "../../../form-viewer/form-viewer.component.ngfactory";
import * as i22 from "../../../../../services/form-viewer.service";
import * as i23 from "@angular/forms";
import * as i24 from "../../../form-viewer/form-viewer.component";
import * as i25 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i26 from "@angular/material/button";
import * as i27 from "@angular/cdk/a11y";
import * as i28 from "@angular/flex-layout/flex";
import * as i29 from "@angular/flex-layout/core";
import * as i30 from "../../../../pages/page-closure-container.service";
import * as i31 from "../../../../../services/auth.service";
import * as i32 from "../../../../../services/domain-filter.service";
import * as i33 from "./dataset-create.component";
import * as i34 from "@angular/material/dialog";
import * as i35 from "../../../../../../@fuse/services/translation-loader.service";
import * as i36 from "../../../../../services/websocket.service";
var styles_DatasetCreateComponent = [i0.styles];
var RenderType_DatasetCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatasetCreateComponent, data: {} });
export { RenderType_DatasetCreateComponent as RenderType_DatasetCreateComponent };
function View_DatasetCreateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "simp-temp-view-container", [], null, null, null, i2.View_SmartInterfaceStepViewContainerComponent_0, i2.RenderType_SmartInterfaceStepViewContainerComponent)), i1.ɵprd(512, null, i3.StepService, i3.StepService, [i4.DatasetService, i5.DatasetActionContainerService]), i1.ɵdid(3, 4440064, null, 0, i6.SmartInterfaceStepViewContainerComponent, [i7.AppService, i8.DatasetRecordService, i5.DatasetActionContainerService, i9.DatasetNavigatorService, i10.FuseSidebarService, i11.Router, i11.ActivatedRoute, i12.Location, i1.ElementRef, i3.StepService], { stepViewConfig: [0, "stepViewConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepViewConfig; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DatasetCreateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "common-step-view-container", [], null, null, null, i13.View_CommonStepViewContainerComponent_0, i13.RenderType_CommonStepViewContainerComponent)), i1.ɵprd(512, null, i3.StepService, i3.StepService, [i4.DatasetService, i5.DatasetActionContainerService]), i1.ɵdid(3, 245760, null, 0, i14.CommonStepViewContainerComponent, [i7.AppService, i8.DatasetRecordService, i5.DatasetActionContainerService, i9.DatasetNavigatorService, i10.FuseSidebarService, i11.Router, i11.ActivatedRoute, i12.Location, i3.StepService], { stepViewConfig: [0, "stepViewConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig.stepViewConfig; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DatasetCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i12.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_3)), i1.ɵdid(4, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_4)), i1.ɵdid(6, 16384, null, 0, i12.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.getValueFromKeyPath("domainTpl"); _ck(_v, 2, 0, currVal_0); var currVal_1 = 1; _ck(_v, 4, 0, currVal_1); }, null); }
function View_DatasetCreateComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "simp-temp-view-container", [], null, null, null, i2.View_SmartInterfaceStepViewContainerComponent_0, i2.RenderType_SmartInterfaceStepViewContainerComponent)), i1.ɵprd(512, null, i3.StepService, i3.StepService, [i4.DatasetService, i5.DatasetActionContainerService]), i1.ɵdid(3, 4440064, null, 0, i6.SmartInterfaceStepViewContainerComponent, [i7.AppService, i8.DatasetRecordService, i5.DatasetActionContainerService, i9.DatasetNavigatorService, i10.FuseSidebarService, i11.Router, i11.ActivatedRoute, i12.Location, i1.ElementRef, i3.StepService], { stepViewConfig: [0, "stepViewConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productStepViewConfig; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DatasetCreateComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "common-step-view-container", [], null, null, null, i13.View_CommonStepViewContainerComponent_0, i13.RenderType_CommonStepViewContainerComponent)), i1.ɵprd(512, null, i3.StepService, i3.StepService, [i4.DatasetService, i5.DatasetActionContainerService]), i1.ɵdid(3, 245760, null, 0, i14.CommonStepViewContainerComponent, [i7.AppService, i8.DatasetRecordService, i5.DatasetActionContainerService, i9.DatasetNavigatorService, i10.FuseSidebarService, i11.Router, i11.ActivatedRoute, i12.Location, i3.StepService], { stepViewConfig: [0, "stepViewConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig.stepViewConfig; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DatasetCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i12.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_2)), i1.ɵdid(4, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_5)), i1.ɵdid(6, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_6)), i1.ɵdid(8, 16384, null, 0, i12.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetCode; _ck(_v, 2, 0, currVal_0); var currVal_1 = "trip_packages"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "products"; _ck(_v, 6, 0, currVal_2); }, null); }
function View_DatasetCreateComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i15.MatCardTitle, [], null, null), (_l()(), i1.ɵted(2, null, ["Crea nuovo ", ""])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.datasetACS.datasetConfig.singleTitle)))); _ck(_v, 2, 0, currVal_0); }); }
function View_DatasetCreateComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i15.MatCardTitle, [], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formConfig.title; _ck(_v, 2, 0, currVal_0); }); }
function View_DatasetCreateComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i17.View_MatCardHeader_0, i17.RenderType_MatCardHeader)), i1.ɵdid(1, 49152, null, 0, i15.MatCardHeader, [], null, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_DatasetCreateComponent_11)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_DatasetCreateComponent_12)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.formConfig || !_co.formConfig.title); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.formConfig && _co.formConfig.title); _ck(_v, 5, 0, currVal_1); }, null); }
function View_DatasetCreateComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i18.View_MatProgressBar_0, i18.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i19.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i20.ANIMATION_MODULE_TYPE], [2, i19.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DatasetCreateComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mb-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "form-viewer", [], null, null, null, i21.View_FormViewerComponent_0, i21.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i22.FormViewerService, i22.FormViewerService, [i7.AppService, i23.FormBuilder, i1.NgZone]), i1.ɵdid(3, 770048, [[1, 4], ["formViewer", 4]], 0, i24.FormViewerComponent, [i22.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formConfig; var currVal_1 = _co.datasetACS; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DatasetCreateComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "mat-card", [["class", "p-24 mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i17.View_MatCard_0, i17.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i15.MatCard, [[2, i20.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DatasetCreateComponent_10)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i15.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_13)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_14)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 5, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i15.MatCardActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i25.View_MatButton_0, i25.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i26.MatButton, [i1.ElementRef, i27.FocusMonitor, [2, i20.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showHeader; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.datasetACS.loading.value; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.formConfig && !_co.datasetACS.loading.value); _ck(_v, 9, 0, currVal_3); var currVal_7 = _co.datasetACS.loading.value; var currVal_8 = "accent"; _ck(_v, 13, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_4 = (i1.ɵnov(_v, 11).align === "end"); _ck(_v, 10, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 13).disabled || null); var currVal_6 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Invia")); _ck(_v, 14, 0, currVal_9); }); }
function View_DatasetCreateComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayout.xs", "column"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i28.DefaultLayoutDirective, [i1.ElementRef, i29.StyleUtils, [2, i28.LayoutStyleBuilder], i29.MediaMarshaller], { fxLayout: [0, "fxLayout"], "fxLayout.xs": [1, "fxLayout.xs"] }, null), i1.ɵdid(3, 671744, null, 0, i28.DefaultLayoutAlignDirective, [i1.ElementRef, i29.StyleUtils, [2, i28.LayoutAlignStyleBuilder], i29.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_9)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; var currVal_1 = "column"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "start center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.datasetACS.datasetConfig; _ck(_v, 5, 0, currVal_3); }, null); }
function View_DatasetCreateComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i12.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_8)), i1.ɵdid(4, 16384, null, 0, i12.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig.name; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DatasetCreateComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.TitleCasePipe, []), i1.ɵqud(671088640, 1, { formViewer: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_1)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DatasetCreateComponent_7)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig.stepViewConfig; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.datasetACS.datasetConfig.stepViewConfig; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_DatasetCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "dataset-create", [], null, null, null, View_DatasetCreateComponent_0, RenderType_DatasetCreateComponent)), i1.ɵprd(4608, null, i8.DatasetRecordService, i8.DatasetRecordService, [i5.DatasetActionContainerService, i4.DatasetService]), i1.ɵprd(512, null, i5.DatasetActionContainerService, i5.DatasetActionContainerService, [i7.AppService, i30.PageClosureContainerService, i31.AuthService, i4.DatasetService, i32.DomainFilterService, i16.TranslateService, i1.Injector]), i1.ɵdid(3, 245760, null, 0, i33.DatasetCreateComponent, [i11.ActivatedRoute, i4.DatasetService, i11.Router, i31.AuthService, i5.DatasetActionContainerService, i34.MatDialog, i35.FuseTranslationLoaderService, i36.WebSocketService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var DatasetCreateComponentNgFactory = i1.ɵccf("dataset-create", i33.DatasetCreateComponent, View_DatasetCreateComponent_Host_0, { autoFetchData: "autoFetchData", showTitle: "showTitle", datasetCode: "datasetCode", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS", showHeader: "showHeader" }, { created: "created" }, []);
export { DatasetCreateComponentNgFactory as DatasetCreateComponentNgFactory };
