<div id="login" fxLayout="column" fusePerfectScrollbar>
	<div
		id="login-form-wrapper"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="form-wrapper">

			<div class="logo mb-32">
				<img src="assets/images/logos/tangerine_logo.png" />
			</div>

			<div
				id="login-form"
				[hidden]="navigating"
				*fuseIfOnDom
				[@animate]="{
					value: '*',
					params: { duration: '300ms', y: '100px' }
				}"
			>
				<mat-progress-bar
					*ngIf="logging"
					class="mt-24 mb-24"
					style="min-width:300px"
					mode="indeterminate"
				></mat-progress-bar>

				<form name="loginForm" [formGroup]="loginForm" novalidate>
					<mat-form-field appearance="fill">
						<mat-label>Username</mat-label>
						<input
							matInput
							placeholder="Codice Utente"
							formControlName="access_username"
							autocomplete="access_username"
						/>
						<mat-icon matSuffix class="secondary-text">person_outline</mat-icon>
						<ng-container
							*ngIf="
								loginFormErrors && loginFormErrors.access_username
							"
						>
							<mat-error
								translate="LOGIN.ERROR.CODE_REQUIRED"
								*ngIf="loginFormErrors.access_username.required"
							>
								Username is required
							</mat-error>
							<mat-error
								translate="LOGIN.ERROR.INVALID_CODE"
								*ngIf="
									!loginFormErrors.access_username.required &&
									loginFormErrors.access_username.invalid
								"
							>
								Please enter a valid code
							</mat-error>
						</ng-container>
					</mat-form-field>

					<mat-form-field appearance="fill">
						<mat-label>Password</mat-label>
						<input
							matInput
							type="password"
							placeholder="Password"
							formControlName="password"
							autocomplete="current-password"
						/>
						<mat-icon matSuffix class="secondary-text"
							>vpn_key</mat-icon
						>
						<ng-container
							*ngIf="loginFormErrors && loginFormErrors.password"
						>
							<mat-error
								translate="LOGIN.ERROR.PASSWORD_REQUIRED"
								*ngIf="loginFormErrors.password.required"
							>
								Password is required
							</mat-error>
						</ng-container>
					</mat-form-field>

					<div
						class="remember-forgot-password"
						fxLayout="row"
						fxLayoutAlign="space-between center"
					>
						<mat-checkbox
							class="remember-me"
							aria-label="Remember Me"
							formControlName="rememberMe"
						>
							<span translate="LOGIN.REMEMBER_ME">Remember Me</span>
						</mat-checkbox>

					</div>

					<p *ngIf="lastLoginErrorMessage" class="red-fg">
						{{ lastLoginErrorMessage }}
					</p>

					<button
						mat-flat-button
						(click)="onLoginClick()"
						color="accent"
						class="submit-button"
						aria-label="LOG IN"
						[disabled]="loginForm.invalid || logging"
					>
						<span>Accedi</span>
						<mat-icon>keyboard_arrow_right</mat-icon>
					</button>
				</form>

				<a
					class="forgot-password"
					translate="LOGIN.FORGOT_PASSWORD"
					[routerLink]="'/auth/forgot-password'"
				>
					Forgot Password?
				</a>

				<div
					*ngIf="false"
					class="register"
					fxLayout="column"
					fxLayoutAlign="center center"
				>
					<span class="text">Don't have an account?</span>
					<a class="link" [routerLink]="'/auth/register'"
						>Create an account</a
					>
				</div>
			</div>
		</div>
	</div>
</div>
