import * as tslib_1 from "tslib";
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
var MeetingPointUnifiedListItemsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MeetingPointUnifiedListItemsComponent, _super);
    function MeetingPointUnifiedListItemsComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        return _this;
    }
    return MeetingPointUnifiedListItemsComponent;
}(BaseStepViewComponent));
export { MeetingPointUnifiedListItemsComponent };
