import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector, ViewContainerRef } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DomainFilterService } from 'app/services/domain-filter.service';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { isDatasetActionAllowed } from 'app/helpers';
import { ConfirmWithReasonDialogComponent } from "../../../../../dialogs/confirm-with-reason-dialog/dialog.component";
var ProductBookingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductBookingComponent, _super);
    function ProductBookingComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, domainFilterService, bookingStepService, tripBookingPackageService, bookingService, injector, viewContainerRef) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.domainFilterService = domainFilterService;
        _this.bookingStepService = bookingStepService;
        _this.tripBookingPackageService = tripBookingPackageService;
        _this.bookingService = bookingService;
        _this.injector = injector;
        _this.viewContainerRef = viewContainerRef;
        _this.loadingMeetingPoint = false;
        _this.hasEditableData = false;
        _this.canEditData = false;
        _this.canSingleConfirm = false;
        _this.isResaleCopy = false;
        _this.canDelete = false;
        _this.canCancel = false;
        _this.canEdit = false;
        return _this;
    }
    Object.defineProperty(ProductBookingComponent.prototype, "iterationData", {
        get: function () {
            return this.tripBookingPackageService.iterationData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductBookingComponent.prototype, "tripBookingStatus", {
        get: function () {
            return this.bookingStepService && this.bookingStepService.datasetRS.record.value && this.bookingStepService.datasetRS.record.value.status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductBookingComponent.prototype, "tripBookingPackageRelatedDialogData", {
        get: function () {
            return {
                datasetCode: '',
                actionCode: '',
                tripBookingPackage: this.tripBookingPackage,
            };
        },
        enumerable: true,
        configurable: true
    });
    ProductBookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            _this.tripBookingPackage = record;
            _this.refreshData();
            _this.canEdit = _this.hasEditableData && !_this.canEditData;
            var canDelete = _this.bookingService.canEdit && !_this.tripBookingPackage.tour_operator_booking_ref && !_this.tripBookingPackage.tour_operator_booking_commit_status;
            _this.canDelete = canDelete && _this.bookingService.canEdit && isDatasetActionAllowed(record, 'delete');
            _this.canCancel = !_this.canDelete && _this.bookingService.canEdit && isDatasetActionAllowed(record, 'cancel');
            _this.isResaleCopy = Boolean(record.ref_id);
            _this.canSingleConfirm = _this.tripBookingStatus == 'mix' && !['confirmed', 'canceled'].includes(_this.tripBookingPackage.booking_status) && !_this.tripBookingPackage.tour_operator_booking_commit_status;
        });
    };
    ProductBookingComponent.prototype.deleteTripBookingPackage = function () {
        var _this = this;
        if (this.canCancel) {
            this.matDialog.open(ConfirmWithReasonDialogComponent, {
                width: '550px',
                data: {
                    title: 'Conferma',
                    reasonLabel: 'Vuoi annullare questo prodotto?',
                    reasonRequired: false
                }
            })
                .afterClosed()
                .subscribe(function (result) {
                if (!result)
                    return;
                _this.bookingService.cancelBooking(_this.tripBookingPackage.trip_booking_id, Object.assign(result, {
                    trip_booking_package_ids: [_this.tripBookingPackage.id]
                }))
                    .subscribe(function () {
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                });
            });
        }
        else if (this.canDelete) {
            this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
                .afterClosed()
                .subscribe(function (result) {
                if (result) {
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                }
            });
        }
    };
    ProductBookingComponent.prototype.trackTripBookingResourcesFun = function (index, item) {
        return item.id;
    };
    ProductBookingComponent.prototype.reloadTripBooking = function () {
        this.appService.appEvent.next({ name: RELOAD_BOOKING });
    };
    ProductBookingComponent.prototype.viewProduct = function () {
        this.datasetNavigatorService.showDatasetEdit('products', {}, this.tripBookingPackage.product_id);
    };
    ProductBookingComponent.prototype.refreshData = function () {
        var formConfig = this.bookingStepService.getAditionalForm(this.tripBookingPackage.product, 'product', 'edit');
        this.hasEditableData = Boolean(formConfig);
        var canEditData = Boolean(this.tripBookingPackage);
        if (this.tripBookingPackage && this.tripBookingPackage.tour_operator_booking_commit_status > 0) {
            canEditData = false;
        }
        this.canEditData = canEditData;
    };
    ProductBookingComponent.prototype.editData = function () {
        var _this = this;
        if (!this.canEditData)
            return;
        this.bookingStepService.requestBookingData(this.tripBookingPackage.product, 'product', 'edit', this.tripBookingPackage.target_info)
            .subscribe(function (data) {
            if (_.isEmpty(data))
                return;
            _this.bookingStepService.loading = true;
            _this.datasetService.post(_this.datasetACS.getUpdateRoute(_this.datasetRS.record.value), {
                target_info: data
            }).pipe(takeUntil(_this._unsubscribeAll))
                .subscribe({
                next: function (response) {
                    _this.bookingStepService.loading = false;
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                },
                error: function (response) {
                    _this.bookingStepService.loading = false;
                }
            });
        });
    };
    return ProductBookingComponent;
}(DestroyableComponent));
export { ProductBookingComponent };
