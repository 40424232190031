<!--<form-viewer #formViewer
             [dataContainerService]='datasetACS'
             [formConfig]='formConfig'
></form-viewer>-->

<form autocomplete="off" name='form' *ngIf='formViewerService.initialized && formViewerService.formGroup'
      [formGroup]="formViewerService.formGroup" class="mb-12">
    <div *ngIf="checkoutData.carnetData" id="carnet-riep">
        <div fxLayout="row text-center">
            <div fxFlex class="mr-12">
                <div fxFlex="20" class="mr-12">
                    <span matTooltip="Numero carnet" class="train-carnet-number">
                        <span><mat-icon class="arr">local_activity</mat-icon> </span>
                        <span>{{checkoutData.carnetData.entitlements.id}}</span>
                    </span>
                </div>
                <div fxFlex="50" class="mr-12 text-center" >
                    <div><mat-icon class="arr">train</mat-icon>
                        <span><strong>{{checkoutData.carnetData.station_from.name}}</strong></span>
                        <span> <mat-icon class="arr">keyboard_arrow_right</mat-icon></span>
                        <span><strong>{{checkoutData.carnetData.station_to.name}}</strong></span>
                    </div>
                </div>
                <div fxFlex="30" class="mr-12 text-center">
                    <div>
                        <span>Biglietti utilizzati: {{checkoutData.carnetData.entitlements.currentUsages}}/{{checkoutData.carnetData.entitlements.maxUsages}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row text-center" class="items-center"  >
        <div fxFlex="20" class="mr-12">
            <form-input-viewer
                [formInputDefinition]='formFields.get("start_station")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["start_station"]'
                [dataContainerService]='datasetACS'
                [readOnly]="data.mode=='edit-date-time' || checkoutData.carnetData || !enableFormControl.from "
            ></form-input-viewer>
        </div>
        <div fxFlex="2" style="text-align: center; padding-top:10px" class="mr-16">
            <button mat-icon-button (click)="swap()" [disabled]="checkoutData.carnetData"><mat-icon>swap_horiz</mat-icon></button>
        </div>

        <div fxFlex="20" class="mr-12">
            <form-input-viewer
                [formInputDefinition]='formFields.get("end_station")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["end_station"]'
                [dataContainerService]='datasetACS'
                [readOnly]="data.mode=='edit-date-time' || checkoutData.carnetData || !enableFormControl.to "
            ></form-input-viewer>
        </div>

        <div fxFlex="12" class="mr-12" >
            <form-input-viewer
                [formInputDefinition]='formFields.get("date")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["date"]'
                [dataContainerService]='datasetACS'
                [readOnly]='!enableFormControl.start'
            ></form-input-viewer>
        </div>
        <div fxFlex="10" class="mr-12">
            <form-input-viewer
                [formInputDefinition]='formFields.get("time_d")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["time_d"]'
                [dataContainerService]='datasetACS'
                [readOnly]='!enableFormControl.start_time'
            ></form-input-viewer>
        </div>

        <div fxFlex="12" class="mr-12" *ngIf="checkoutData.ar">
            <form-input-viewer
                [formInputDefinition]='formFields.get("date_r")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["date_r"]'
                [dataContainerService]='datasetACS'
                [data]='formViewerService.record'
            ></form-input-viewer>
        </div>
        <div fxFlex="10" class="mr-12" *ngIf="checkoutData.ar">
            <form-input-viewer
                [formInputDefinition]='formFields.get("time_r")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["time_r"]'
                [dataContainerService]='datasetACS'
            ></form-input-viewer>
        </div>
        <div fxFlex="5" class="mr-12 btn-search" style="padding-top:10px">
            <button mat-flat-button (click)='onSearch()'
                    color='accent'
                    *ngIf="!checkoutData.carnetData"
                    [disabled]="trnService.loading.getValue()||carnet"
            >{{'Ricerca' | translate}}</button>
            <button mat-flat-button (click)='onSearchCarnet()'
                    color='accent'
                    [disabled]="trnService.loading.getValue() "
                    *ngIf="checkoutData.carnetData "
            >{{'Ricerca' | translate}}</button>
        </div>
    </div>
</form>
<mat-slide-toggle class="mr-12" *ngIf="['travel'].includes(checkoutData.mode) && trnService.step.value !== 2" (toggleChange)="getCarnet()" [(ngModel)]="carnet">Acquista con carnet</mat-slide-toggle>
<mat-slide-toggle
    *ngIf="['travel','edit'].includes(checkoutData.mode) && arShow"
    (toggleChange)="setAr()" [(ngModel)]="ar" [disabled]="!enableFormControl.ar">Andata / ritorno</mat-slide-toggle>
<div *ngIf="carnet">
    <trenitalia-sale-search-carnet></trenitalia-sale-search-carnet>
</div>