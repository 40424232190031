<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>Questa traduzione non è ancora presente!</p>
		</ng-container>

		<form autocomplete="off"
			name='form' 
			*ngIf='formViewerService.initialized && formViewerService.formGroup' 
			[formGroup]="formViewerService.formGroup" 
			fxLayout="column" 
			fxLayoutAlign="start"
			fxLayoutAlign.lt-lg='stretch'>

			<div class="product-publish-on-web">
				<label class="input-label section-title"><b>Pubblico</b></label>
		
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("public_product_on_web")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["public_product_on_web"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>

			<label class="input-label section-question">
				Descrivi la tua attività
			</label>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

				
			<div class="instruction with-bg">
				<label class="input-label section-question">Descrizione completa</label>
				<p class="instruction">
					Descrivi le attività che si svolgeranno durante la visita
				</p>
				<p class="instruction">
					Cosa vedranno? Cosa succederà? Come finirà?
				</p>
				<p class="instruction">
					Non scrivere "noi", "nostro", il nome della tua azienda o copiare e incollare testo da un sito Web esistente.
				</p>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("full_description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["full_description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

		</form>

		<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<button color='accent' [disabled]='!formViewerService.formGroup.valid' mat-flat-button (click)='onSave()'>
				<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
				<span *ngIf='datasetACS.loading.value'>Azione in corso ...</span>
			</button>
		</div>

	</div>
</div>