<mat-card class="card-pass">
	<mat-card-content>
		<!--Action button-->
		<div class="mb-24">
			<button mat-flat-button [class.mat-accent]="billingMode === 'LEGAL'" class="mr-16" (click)="onChangeBillingMode('LEGAL')">Fattura con Partita IVA</button>
			<button mat-flat-button [class.mat-accent]="billingMode === 'INDIVIDUAL'" class="mr-16" (click)="onChangeBillingMode('INDIVIDUAL')">Fattura con Codice Fiscale</button>
		</div>
		<!--form LEGAL -->
		<div *ngIf="billingMode === 'LEGAL'">
			<!--start search profile-->
			<label>Ricerca un profilo associato alla Partita IVA oppure creane uno nuovo.</label>
			<div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="3px">
				<!--input search-->
				<div fxFlex>
					<form-input-viewer
						[formInputDefinition]='fields.get("p_iva_search")'
						[formGroup]='searchFromGroup'
						formControlKey='p_iva_search'
					></form-input-viewer>
				</div>

				<!--Serach button-->
				<div fxFlex class="ml-4">
					<button *ngIf="!loading" (click)="onSearchProfile()" mat-flat-button color='accent'>{{'Cerca' | translate}}</button>
					<mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
				</div>

				<div fxFlex>
					<form-input-viewer
						[formInputDefinition]='fields.get("create_new_profile")'
						[formGroup]='searchFromGroup'
						formControlKey='create_new_profile'
					></form-input-viewer>
				</div>
			</div>
			<!--end search profile-->

			<!--billing data-->
			<label>Dati Fatturazione</label>
			<div fxLayout="row wrap" fxLayoutGap="3px" style="align-items: baseline;">
				<div *ngIf="!isInvoiceProfiles" fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("profile_name")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["profile_name"]'
					></form-input-viewer>
				</div>

				<mat-form-field *ngIf="isInvoiceProfiles" fxFlex class="mr-3px">
					<mat-label>Nome profilo</mat-label>
					<mat-select (selectionChange)='onSelectInvoiceProfile($event)'>
						<mat-option *ngFor="let profile of invoiceProfiles" [value]="profile">
						{{profile.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("business_name")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["business_name"]'
					></form-input-viewer>
				</div>
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("p_iva")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["p_iva"]'
					></form-input-viewer>
				</div>
			</div>
			<div fxLayout="row wrap" fxLayoutGap="3px">
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("corporate_tax_code")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["corporate_tax_code"]'
					></form-input-viewer>
				</div>
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("country_select")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["country"]'
					></form-input-viewer>
				</div>
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("province")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["province"]'
					></form-input-viewer>
				</div>
			</div>
			<div fxLayout="row wrap" fxLayoutGap="3px">
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("city")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["city"]'
					></form-input-viewer>
				</div>
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("cap")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["cap"]'
					></form-input-viewer>
				</div>
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("address")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["address"]'
					></form-input-viewer>
				</div>
			</div>
			<!--end billing data-->

			<!--mode of delivery-->
			<label>Obbligatorio Cod.Dest. o PEC</label>
			<div fxLayout="row wrap" fxLayoutGap="3px">
				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("recipient_code")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["recipient_code"]'
					></form-input-viewer>
				</div>

				<div fxFlex>
					<form-input-viewer
						[readOnly]="!creaNewProfile"
						[formInputDefinition]='fields.get("pec")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["pec"]'
					></form-input-viewer>
				</div>
			</div>
			<!--end mode of delivery-->
		</div>
		<!--end form LEGAL -->

		<!--form INDIVIDUAL -->
		<div *ngIf="billingMode === 'INDIVIDUAL'">
			<trenitalia-billing-form-individual [billingData]="billingData"></trenitalia-billing-form-individual>
		</div>
		<!--end form INDIVIDUAL -->
	</mat-card-content>
</mat-card>