import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NotFoundComponent } from './not-found.component';


@NgModule({
    declarations: [
        NotFoundComponent
    ],
    imports     : [
        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        NotFoundComponent
    ]
})

export class NotFoundModule{}
