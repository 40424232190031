import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CommonStepViewContainerComponent } from '../common-step-view-container/view.component';
import { StepService } from '../../../services/step-state.service';
var SmartInterfaceStepViewContainerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SmartInterfaceStepViewContainerComponent, _super);
    function SmartInterfaceStepViewContainerComponent(appService, datasetRS, datasetACS, datasetNavigatorService, _fuseSidebarService, router, route, location, elementRef, stepService) {
        var _this = 
        /** init parent constructor */
        _super.call(this, appService, datasetRS, datasetACS, datasetNavigatorService, _fuseSidebarService, router, route, location, stepService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this._fuseSidebarService = _fuseSidebarService;
        _this.router = router;
        _this.route = route;
        _this.location = location;
        _this.elementRef = elementRef;
        _this.stepService = stepService;
        return _this;
    }
    SmartInterfaceStepViewContainerComponent.prototype.ngOnInit = function () {
        this.stepService.initStepData();
        _super.prototype.ngOnInit.call(this);
        this.stepperIndex = this.route.snapshot.queryParams['step'] ? Number(this.route.snapshot.queryParams['step']) : 1;
        this.stepIndex = this.stepperIndex;
    };
    SmartInterfaceStepViewContainerComponent.prototype.ngOnDestroy = function () {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F5F5F5';
    };
    SmartInterfaceStepViewContainerComponent.prototype.ngAfterViewInit = function () {
        this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
    };
    return SmartInterfaceStepViewContainerComponent;
}(CommonStepViewContainerComponent));
export { SmartInterfaceStepViewContainerComponent };
