<h2>{{'Informazioni Base' | translate}}</h2>

<div class='full-form' [ngClass]='formConfig.formStyle'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	<form-viewer
		*ngIf='bookingService.canEdit'
		#formViewer
		[dataContainerService]='datasetACS'
		[record]='datasetRS.record.value'
		[formConfig]='formConfig' 
	></form-viewer>
	<data-container-viewer
		*ngIf='!bookingService.canEdit'
		[fields]='formConfig.fields'
		[record]='datasetRS.record.value'
		[dataContainerService]='datasetACS'
	></data-container-viewer>
</div>