import { IListViewConfig, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';

const viewConfig: IListViewConfig = {
	itemViewType: ListViewItemTypes.TABLE,
	showSearchBar: true,
	defaultSortBy: 'description',
	defaultSortDirection: 'asc',
	columns: [
		{
			title: 'Nominativo',
			key: 'description'
		},
		{
			title: 'Anagrafica',
			key: 'entity_id',
			fieldView: FieldViews.HTML,
			getDisplayHTML(record: any): any{
				if (record.entity && record.entity.description){
					return record.entity.description;
				}
				return '';
			}
		},
		{
			title: 'Età',
			key: 'age_range_code'
		},
		{
			title: 'Nazionalità',
			key: 'nation_code',
			fieldView: FieldViews.HTML,
			getDisplayHTML(record, value: string): string{
				let html = '';
				if(value){
					const alt = record.nation && record.nation.description || value;
					html += '<img width="24" class="flag-icon" src="assets/images/flags/4x3/' + value.toLowerCase() + '.svg" alt="' + alt + '" title="' + alt + '" />';
					if(record.nation && record.nation.description) html += '&nbsp;' + record.nation.description;
				}
				return html;
			}
		}
		/*{
			title: 'Contatti',
			key: 'contatti',
			fieldView: FieldViews.HTML,
			getDisplayHTML(record, value: string): string{
				if(!record || !record.entity) return '';
				let html = record.entity.primary_email;
				if(record.entity.phone_number){
					html += '<br/>' + record.entity.phone_number;
				}
				return html;
			}
		}*/
	],
	actions: ['edit', 'delete'],	
};

export default viewConfig;
