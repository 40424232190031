import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from '../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('trip_service_description'),
	fieldsMap.get('count'),
	fieldsMap.get('price'),
	fieldsMap.get('price_base'),
	fieldsMap.get('total_amount'),
	fieldsMap.get('total_amount_lordo'),
	fieldsMap.get('fee_included')
];

const completeInfoFormConfig: IFormConfig = {
	fields
};

export default completeInfoFormConfig;
