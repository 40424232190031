import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { AppService } from "../../../../../services/app.service";
import { DatasetRecordService } from "../../../../components/dataset/services/dataset-record.service";
import { takeUntil } from "rxjs/operators";
var ProductPackageComponent = /** @class */ (function () {
    function ProductPackageComponent(appService, datasetRS) {
        this.appService = appService;
        this.datasetRS = datasetRS;
        console.log(datasetRS);
    }
    ProductPackageComponent.prototype.ngOnInit = function () {
        var _this = this;
        switch (this.inputTripBookingPackage.product.product_type) {
            case 'standard':
                this.productType = 'Standard';
                break;
            case 'travelmate':
                this.productType = 'Audioguida';
                break;
            case 'esim-manet':
                this.productType = 'ESim';
                break;
        }
        this._unsubscribeAll = new Subject();
        console.log(this.inputTripBookingPackage);
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            var e_1, _a;
            var totalLordo = Number(_this.inputTripBookingPackage.total_amount);
            if (_this.inputTripBookingPackage.children_trip_booking_packages) {
                try {
                    for (var _b = tslib_1.__values(_this.inputTripBookingPackage.children_trip_booking_packages), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var tbp = _c.value;
                        totalLordo += Number(tbp.total_amount);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            _this.totalRowLordo = totalLordo;
        });
    };
    ProductPackageComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return ProductPackageComponent;
}());
export { ProductPackageComponent };
