import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';

const createFormFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('title'),
	fieldsMap.get('description'),
	fieldsMap.get('points'),
	fieldsMap.get('date'),
	fieldsMap.get('reviewer_display_name'),
	fieldsMap.get('reviewer_nation'),
	fieldsMap.get('reviewer_language'),
	fieldsMap.get('reviewer_email')
];

export default { 
	title: 'Crea risorsa',
	fields: createFormFields 
};
