import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	FieldViews,
} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as entityFieldMap } from '../entities/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('payment_type_id', {
	key: 'payment_type_id',
	name: 'Tipo di pagamento',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Seleziona il tipo di pagamento',
	datasetCode: 'payment_types',
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if (!option) return '';
			return '(' + option.code + ') ' + option.name;
		},
		getValue(option): any {
			if (!option) return;
			return option.id;
		},
		getDisplay(option): any {
			if (!option) return '';
			return '(' + option.code + ') ' + option.name;
		},
	},
});

entityFieldMap.forEach(function(value, key, map): void {
	value = Object.assign({}, value);

	fieldsMap.set(key, value);
});

// Use the follow for display
entityFieldMap.forEach(function(value, key, map): void {
	value = Object.assign({}, value, { key: 'entity.' + value.key });

	fieldsMap.set('entity.' + key, value);
});

export { fieldsMap };
