import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';

@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(
		public auth: AuthService,
		private appService: AppService,
		public router: Router
	){}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		if (!this.auth.isAuthenticated()) {
			this.auth.desiredPath = state.url.split('?')[0];
			this.auth.desiredQueryParams = state.root.queryParams;
			this.router.navigate(['auth/login'], {
				replaceUrl: true
			});
			return false;
		}
		return true;
	}
}
