import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';

const createFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('date_from'),
			fieldsMap.get('date_to')
		]
	},
	fieldsMap.get('quantity')
];

export default {
	fields: createFormFields 
};
