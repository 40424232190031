<div class="page-layout blank p-24" fusePerfectScrollbar>

	<div class="main-widget">
		<div class="doc_title position-relative p-24 mat-blue-600-bg" fxLayout="row" fxLayoutAlign="space-between center">
			<div fxLayout="column" fxLayoutAlign="start start">
				<span class="h2">{{'Documenti' | translate}}</span>
			</div>
		</div>
	</div>

	<div class="container-documents">

		<form #documentsForm="ngForm"> <!-- (ngSubmit)="download(documentsForm)" -->

			<!--Start Date-->
			<mat-form-field>
				<input matInput
					[matDatepicker]="picker"
					(click)='picker.open()'
					format="YYYY/MM/DD HH:mm:ss"
					[placeholder]='"Seleziona data" | translate'
					(dateInput)="addDate($event)"
					ngModel name="data"
					#dataValidation="ngModel"
					required />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker="matDatepicker"></mat-datepicker>
			</mat-form-field>

			<div *ngIf="dataValidation.invalid && (dataValidation.dirty || dataValidation.touched)">
				<p class="data-documento" *ngIf="dataValidation.errors && dataValidation.errors.required" >* {{'Campo data obbligatorio' | translate}}</p>
			</div>
			<!--end start Date-->

			<!---RadioButton-->
			<mat-card class='p-24'>
				<mat-card-header>
					<mat-card-title>{{'Lista Partecipanti' | translate}}</mat-card-title>
				</mat-card-header>
				
				<mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="selectedRadio" [(ngModel)]="selectedRadio">
					<mat-radio-button class="example-radio-button" *ngFor="let radio of radios" [value]="radio" 
						[disabled]="dataValidation.invalid"
						(change)="radioListChange($event)">
						{{radio}}
					</mat-radio-button>
				</mat-radio-group>

				<!--Package-->
				<div class="pacchetto-wrapper">
					<mat-form-field class="filter-chip-list"  *ngIf="selectedRadio==='Pacchetto'">

						<mat-chip-list #chipList aria-label="Package selection">

							<mat-chip
								*ngFor="let tripPackage of filterPackages"
								[selectable]="true"
								[removable]="true"
								(removed)="removePackage(tripPackage.id)">
								{{ tripPackage.description }}
								<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
							</mat-chip>
							
							<input #filterInput
								[placeholder]='"Seleziona pacchetto..." | translate'
								(input)="loadPackages($event.target.value)"
								[matAutocomplete]="auto"
								[matChipInputFor]="chipList"
								[matChipInputAddOnBlur]="true"
								(matChipInputTokenEnd)="addPackage($event)"
								ngModel name="pacchetto"
								[required]="selectedRadio==='Pacchetto'" />
						</mat-chip-list>
						
						<mat-autocomplete
							#auto="matAutocomplete"
							(optionSelected)="onSelectPackage($event)">
							<mat-option
								*ngFor="let option of filteredOptions | async"
								[value]="option">
								{{ option.description }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<!--end Package-->

				<div class="sottofiltri-pacchetto" *ngIf="selectedPackage">
					<mat-checkbox 
						[disabled]="!selectedPackage"
						[matTooltip]='"Filtra nel pacchetto selezionato" | translate'
						name="packageSubFilters"
						[(ngModel)]="packageSubFilters"
						(change)="packageSubFiltersEvent($event)">
						{{'ATTIVA SOTTOFILTRI PACCHETTO' | translate}}
					</mat-checkbox>
				</div>
				
				<mat-radio-group 
					class="package-sub-filters"
					name="selectedSubPackFilter" 
					[(ngModel)]="selectedSubPackFilter" 
					*ngIf="selectedRadio === 'Pacchetto' && packageSubFilters">

					<mat-radio-button *ngFor="let radioP of subFilterPackages" [value]="radioP" 
						[disabled]="dataValidation.invalid || !selectedPackage" 
						(change)="radioSubPackListChange($event)">
						{{radioP}}
					</mat-radio-button>
					
				</mat-radio-group>

				<!-- ActualTrip -->
				<div class="div-wrapper">
					<mat-form-field class="filter-chip-list" *ngIf="selectedSubPackFilter==='Partenza'">
						<mat-chip-list #chipactList aria-label="ActualTrip selection">
							<mat-chip
								*ngFor="let tripActual of filterActual"
								[selectable]="true"
								[removable]="true"
								(removed)="removeActual(tripActual.id)">
								{{ tripActual.meeting_point_list_description }} - {{tripActual.start_time}}
								<mat-icon matChipRemove>cancel</mat-icon>
							</mat-chip>
							<input #actualInput
								[placeholder]='"Inserisci Partenza..." | translate'
								(input)="actualFilteredOptions($event.target.value)"
								[matAutocomplete]="autoact"
								[matChipInputFor]="chipactList"
								[matChipInputAddOnBlur]="true"
								(matChipInputTokenEnd)="addActual($event)"
								ngModel name="actualTrip" />
						</mat-chip-list>
						<mat-autocomplete #autoact="matAutocomplete" (optionSelected)="onSelectActual($event)" >
							<mat-option
								*ngFor="let option of actualOptions | async"
								[value]="option">
								{{ option.meeting_point_list_description }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<!--end ActualTrip-->

				<!-- Resource -->
				<mat-form-field class="filter-chip-list" *ngIf="selectedRadio==='Risorsa' || selectedRadio === 'Pacchetto' && selectedSubPackFilter === 'Risorsa'">

					<mat-chip-list #chip aria-label="Resource selection">
						<mat-chip
							*ngFor="let tripResource of filterResource"
							[selectable]="true"
							[removable]="true"
							(removed)="removeResource(tripResource.id)">
							{{ tripResource.description }}
							<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
						</mat-chip>
						<input #ResourceInput
							[placeholder]='"Inserisci risorsa..." | translate'
							(input)="resourceFilteredOptions($event.target.value)"
							[matAutocomplete]="autores"
							[matChipInputFor]="chip"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="addResource($event)"
							ngModel name="resource" />
					</mat-chip-list>

					<mat-autocomplete
						#autores="matAutocomplete"
						(optionSelected)="onSelectResource($event)">
						<mat-option
							*ngFor="let option of resourceOptions | async"
							[value]="option">
							{{ option.description }}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
				
				<div class="risorsa-evidenziata" *ngIf="resourceWithPackage && selectedResource">
					<mat-checkbox 
						[disabled]="!selectedResource"
						[matTooltip]='"Visualizza tutti i partecipanti ed evidenzia coloro che hanno selezionato una determinata risorsa" | translate'
						name="isHighlighted"
						[(ngModel)]="isHighlighted">
						{{'RISORSA EVIDENZIATA' | translate}}
					</mat-checkbox>
				</div>
				<!--end Resource-->

				<!-- EntityStaff -->
				<mat-form-field class="filter-chip-list last-filter"  *ngIf="selectedRadio==='Accompagnatore'">
					<mat-chip-list #chipcli aria-label="Client selection">
						<mat-chip
							*ngFor="let tripClient of filterClient"
							[selectable]="true"
							[removable]="true"
							(removed)="removeClient(tripClient.id)">
							{{ tripClient.description }}
							<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
						</mat-chip>
						<input #clientInput
							[placeholder]='"Inserisci accompagnatore..." | translate'
							(input)="clientFilteredOptions($event.target.value)"
							[matAutocomplete]="autocli"
							[matChipInputFor]="chipcli"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="addClient($event)"
							ngModel name="entity" />
					</mat-chip-list>

					<mat-autocomplete
						#autocli="matAutocomplete"
						(optionSelected)="onSelectClient($event)" >
						<mat-option
							*ngFor="let option of entityOptions | async"
							[value]="option">
							{{ option.description }}
						</mat-option>
					</mat-autocomplete>

				</mat-form-field>
				<!--end EntityStaff-->

				<!-- EntityCustomer -->
				<mat-form-field class="filter-chip-list last-filter"  *ngIf="selectedRadio==='Cliente'">
					<mat-chip-list #chipcust aria-label="Client selection">
						<mat-chip
							*ngFor="let tripCustomer of filterCustomer"
							[selectable]="true"
							[removable]="true"
							(removed)="removeCustomer(tripCustomer.id)">
							{{ tripCustomer.description }}
							<mat-icon matChipRemove>cancel</mat-icon>
						</mat-chip>
						<input #customerInput
							placeholder="Inserisci cliente..."
							(input)="customerFilteredOptions($event.target.value)"
							[matAutocomplete]="autocust"
							[matChipInputFor]="chipcust"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="addCustomer($event)"
							ngModel name="customer" />
					</mat-chip-list>

					<mat-autocomplete
						#autocust="matAutocomplete"
						(optionSelected)="onSelectCustomer($event)" >
						<mat-option
							*ngFor="let option of customerOptions | async"
							[value]="option">
							{{ option.entity?.description }}
						</mat-option>
					</mat-autocomplete>

				</mat-form-field>
				<!--end EntityCustomer-->
				
				<!-- MeetingPoint -->
				<mat-form-field class="filter-chip-list last-filter"  *ngIf="selectedRadio==='Punto di ritrovo'">
					<mat-chip-list #chipmp aria-label="Meeting point selection">
						<mat-chip
							*ngFor="let mp of filterMeetingPoints"
							[selectable]="true"
							[removable]="true"
							(removed)="removeMeetingPoint(mp.id)">
							{{ mp.description }}
							<mat-icon matChipRemove>cancel</mat-icon>
						</mat-chip>
						<input #meetingPointInput
							[placeholder]='"Inserisci punto di ritrovo..." | translate'
							(input)="meetingPointFilteredOptions($event.target.value)"
							[matAutocomplete]="automp"
							[matChipInputFor]="chipmp"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="addMeetingPoint($event)"
							ngModel name="meeting_point" />
					</mat-chip-list>

					<mat-autocomplete
						#automp="matAutocomplete"
						(optionSelected)="onSelectMeetingPoint($event)" >
						<mat-option
							*ngFor="let option of meetingPointOptions | async"
							[value]="option">
							{{ option.description }}
						</mat-option>
					</mat-autocomplete>

				</mat-form-field>
				<!--end MeetingPoint-->

				<mat-card-actions>
					<button [disabled]='!canDownloadList()' mat-flat-button color='accent' (click)="downloadListaPartecipanti()">
						Download
					</button>

					<button style="margin-left: 20px;" [disabled]='!canDownloadList()' mat-flat-button color='warn' (click)="emailListaPartecipanti()">
						{{'Invia mail' | translate}}
					</button>
				</mat-card-actions>
			</mat-card>

					
			<!--- LISTA CARICHI -->
			<mat-card class='p-24 lista-carichi'>
				<mat-card-header>
					<mat-card-title>{{'Lista Transfert' | translate}}</mat-card-title>
				</mat-card-header>
				
				<mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="listaCarichiSelezionata" [(ngModel)]="listaCarichiSelezionata">
					<mat-radio-button class="example-radio-button" *ngFor="let radio of listaCarichi" [value]="radio" 
						[disabled]="dataValidation.invalid">
						{{radio}}
					</mat-radio-button>
				</mat-radio-group>

				<!--Package-->
				<div class="pacchetto-wrapper">
					<mat-form-field class="filter-chip-list"  *ngIf="listaCarichiSelezionata==='Pacchetto'">

						<mat-chip-list #chipListaCarichi aria-label="Package selection">

							<mat-chip
								*ngFor="let tripPackage of filterPickUpPackages"
								[selectable]="true"
								[removable]="true"
								(removed)="removePickUpPackage(tripPackage.id)">
								{{ tripPackage.description }}
								<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
							</mat-chip>
							
							<input #listaCarichiInput
								[placeholder]='"Seleziona pacchetto..." | translate'
								(input)="loadPickUpPackages($event.target.value)"
								[matAutocomplete]="listaCarichiAutocomplete"
								[matChipInputFor]="chipListaCarichi"
								[matChipInputAddOnBlur]="true"
								(matChipInputTokenEnd)="addPickUpPackage($event)"
								ngModel name="pacchetto_lista_carichi"
								[required]="listaCarichiSelezionata==='Pacchetto'" />
						</mat-chip-list>
						
						<mat-autocomplete
							#listaCarichiAutocomplete="matAutocomplete"
							(optionSelected)="onSelectPickUpPackage($event)">
							<mat-option
								*ngFor="let option of filteredPickUpPackages | async"
								[value]="option">
								{{ option.description }}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>
				<!--end Package-->

				<mat-card-actions>
					<button [disabled]='!selectedPickUpPackage' mat-flat-button color='accent' (click)="downloadListaCarichi()">
						Download
					</button>

					<button style="margin-left: 20px;" [disabled]='!selectedPickUpPackage' mat-flat-button color='warn' (click)="emailListaCarichi()">
						{{'Invia mail' | translate}}
					</button>
				</mat-card-actions>
			</mat-card>

		</form>

	</div>

</div>
