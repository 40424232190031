import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Abilitazione kiara cloud',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('company_code', {
	key: 'company_code',
	name: 'Codice Azienda',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('endpoint', {
	key: 'endpoint',
	name: 'Api Endpoint',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('username', {
	key: 'username',
	name: 'Nome Utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('password', {
	key: 'password',
	name: 'Nome Utente',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('till_id', {
	key: 'till_id',
	name: 'Id Cassa Server RT',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'modules.kiara_cloud_api';
	}
});

export {fieldsMap as kiaraCloudFields};
