/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./marketplace.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../components/form-viewer/form-viewer.component.ngfactory";
import * as i7 from "../../../services/form-viewer.service";
import * as i8 from "../../../services/app.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../components/form-viewer/form-viewer.component";
import * as i11 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "../../components/dataset/base-actions/list/dataset-list.component.ngfactory";
import * as i15 from "../../components/dataset/services/dataset-action-container.service";
import * as i16 from "../page-closure-container.service";
import * as i17 from "../../../services/auth.service";
import * as i18 from "../../components/dataset/services/dataset.service";
import * as i19 from "../../../services/domain-filter.service";
import * as i20 from "../../components/dataset/base-actions/list/dataset-list.service";
import * as i21 from "../../components/dataset/base-actions/list/dataset-list.component";
import * as i22 from "@angular/router";
import * as i23 from "../../components/dataset/services/dataset-navigator.service";
import * as i24 from "../../../../@fuse/services/translation-loader.service";
import * as i25 from "@angular/material/snack-bar";
import * as i26 from "../../../services/websocket.service";
import * as i27 from "@angular/common";
import * as i28 from "./marketplace.component";
var styles_TourCMSMarketplacePageComponent = [i0.styles];
var RenderType_TourCMSMarketplacePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TourCMSMarketplacePageComponent, data: {} });
export { RenderType_TourCMSMarketplacePageComponent as RenderType_TourCMSMarketplacePageComponent };
function View_TourCMSMarketplacePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TourCMSMarketplacePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", "."])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "tourcms-account-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "form-viewer", [], null, null, null, i6.View_FormViewerComponent_0, i6.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i7.FormViewerService, i7.FormViewerService, [i8.AppService, i9.FormBuilder, i1.NgZone]), i1.ɵdid(10, 770048, [[1, 4], ["tourCMSAuthFormViewer", 4]], 0, i10.FormViewerComponent, [i7.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTourCMSAccount() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i12.MatButton, [i1.ElementRef, i13.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tourCMSAuthFormConfig; _ck(_v, 10, 0, currVal_2); var currVal_5 = _co.savingCredentials; var currVal_6 = "accent"; _ck(_v, 12, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Credenziali TourCMS")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("Per rivendere i tour presenti su TourCMS \u00E8 necessario un account Agente con accesso al marketplace")); _ck(_v, 5, 0, currVal_1); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Salva")); _ck(_v, 13, 0, currVal_7); }); }
function View_TourCMSMarketplacePageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dataset-list", [["datasetCode", "tourcms_tours"], ["tableStyle", "style-1 selectable btn-outlined"]], null, null, null, i14.View_DatasetListComponent_0, i14.RenderType_DatasetListComponent)), i1.ɵprd(512, null, i15.DatasetActionContainerService, i15.DatasetActionContainerService, [i8.AppService, i16.PageClosureContainerService, i17.AuthService, i18.DatasetService, i19.DomainFilterService, i5.TranslateService, i1.Injector]), i1.ɵprd(512, null, "ListParamService", i20.DatasetListService, [i15.DatasetActionContainerService, i18.DatasetService]), i1.ɵdid(4, 770048, null, 0, i21.DatasetListComponent, [i22.ActivatedRoute, i18.DatasetService, i22.Router, i17.AuthService, i8.AppService, i15.DatasetActionContainerService, i23.DatasetNavigatorService, i19.DomainFilterService, "ListParamService", i24.FuseTranslationLoaderService, i25.MatSnackBar, i26.WebSocketService], { datasetCode: [0, "datasetCode"], tableStyle: [1, "tableStyle"] }, null)], function (_ck, _v) { var currVal_0 = "tourcms_tours"; var currVal_1 = "style-1 selectable btn-outlined"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_TourCMSMarketplacePageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tourCMSAuthFormViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "page-layout p-24 blank"], ["style", "padding-bottom: 80px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TourCMSMarketplacePageComponent_1)), i1.ɵdid(3, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TourCMSMarketplacePageComponent_2)), i1.ɵdid(5, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TourCMSMarketplacePageComponent_3)), i1.ɵdid(7, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || _co.savingCredentials); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.domainHasTourCMSAccount && !_co.loading); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.domainHasTourCMSAccount && !_co.loading); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_TourCMSMarketplacePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tourcms-marketplace-page", [], null, null, null, View_TourCMSMarketplacePageComponent_0, RenderType_TourCMSMarketplacePageComponent)), i1.ɵprd(512, null, i16.PageClosureContainerService, i16.PageClosureContainerService, [i8.AppService]), i1.ɵdid(2, 114688, null, 0, i28.TourCMSMarketplacePageComponent, [i8.AppService, i19.DomainFilterService, i23.DatasetNavigatorService, i16.PageClosureContainerService, i22.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TourCMSMarketplacePageComponentNgFactory = i1.ɵccf("tourcms-marketplace-page", i28.TourCMSMarketplacePageComponent, View_TourCMSMarketplacePageComponent_Host_0, {}, {}, []);
export { TourCMSMarketplacePageComponentNgFactory as TourCMSMarketplacePageComponentNgFactory };
