import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TripPackageLangContentBaseClass } from '../../lang-cont-base.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';

@Component({
	selector   : 'trip-package-smart-interface-media',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ 
		DatasetActionContainerService, 
		DatasetRecordService, 
		DatasetMultilanguageContainerService
	]
})
export class TripPackageSmartInterfaceMediaComponent extends TripPackageLangContentBaseClass implements OnInit{

	carouselBlockConfigs: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService,
		public stepService: StepService){
		/**
		 * init parent constructor
		 */
		super(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, null, {
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentForeignKey: 'trip_package_id',
			parentValueKey: 'trip_packages.recordId'
		});
		/**
		 * carousel block configs
		 */
		this.carouselBlockConfigs = {
			datasetCode: 'trip_package_language_contents_carousel_images',
			parentDatasetCode: 'trip_package_language_contents',
			parentForeignKey: 'trip_package_language_content_id'
		};
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.stepService.setStepState({
			dataset_code: 'trip_packages',
			form_code: 'media',
			record_id: this.parentDatasetRS.recordId,
			status: 1
		});
	}


}
