import * as tslib_1 from "tslib";
import { FormDialogComponent } from '../dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';
import { LoadingDialogComponent } from '../dialogs/loading-dialog/dialog.component';
export function action_edit_profile(record, datasetService, data) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef, formResult, loadingDialogRef2, response, responseMessage, e_1, errorMessage, keyIndex, keys, key;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dialogRef = datasetService.matDialog.open(FormDialogComponent, {
                        panelClass: 'custom-dialog-container',
                        minWidth: 400,
                        data: {
                            formData: data || {
                                name: record.name.split(' ')[0],
                                surname: record.name.split(' ')[1],
                                email: record.email
                            },
                            title: 'Modifica dati',
                            formConfig: {
                                fields: [
                                    {
                                        key: 'profile_image_id',
                                        name: 'Immagine di profilo utente',
                                        valueType: ValueTypes.REFERENCE,
                                        inputRequired: false,
                                        inputConfig: {
                                            type: InputTypes.FILE_BLOCK,
                                            fileBlockConfig: {
                                                record: record,
                                                uploadRoute: '/auth/me/image-profile',
                                                imageOptions: { minWidth: 1024, minHeight: 768, ratio: 1024 / 768 },
                                                uploadData: { recordId: record.id },
                                                changed: function (event) {
                                                    datasetService.appService.appEvent.next({
                                                        name: 'global_image_profile',
                                                        target: event.profile_image_id
                                                    });
                                                }
                                            }
                                        }
                                    },
                                    {
                                        key: 'name',
                                        name: 'Nome',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.TEXT
                                        }
                                    },
                                    {
                                        key: 'surname',
                                        name: 'Cognome',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.TEXT
                                        }
                                    },
                                    {
                                        key: 'email',
                                        name: 'Email',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.EMAIL
                                        }
                                    }
                                ]
                            },
                        }
                    });
                    return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                case 1:
                    formResult = _a.sent();
                    if (!formResult)
                        return [2 /*return*/];
                    if (!formResult.name || !formResult.surname || !formResult.email) {
                        datasetService.appService.showErrorMessage('Dati insufficienti.');
                        // reopen dialog
                        return [2 /*return*/, action_edit_profile(record, datasetService)];
                    }
                    loadingDialogRef2 = datasetService.matDialog.open(LoadingDialogComponent, {
                        data: {
                            title: 'Aggiornamento in corso'
                        }
                    });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, datasetService.post('/auth/me/edit-profile', {
                            id: record.id,
                            name: formResult.name,
                            surname: formResult.surname,
                            email: formResult.email,
                            profile_image_id: JSON.stringify(formResult.profile_image_id)
                        }).toPromise()];
                case 3:
                    response = _a.sent();
                    responseMessage = '';
                    if (response.message) {
                        responseMessage = response.message;
                    }
                    if (response.success) {
                        datasetService.appService.showSuccessMessage(responseMessage || 'Modifica avventuta con successo');
                    }
                    else {
                        if (!responseMessage)
                            responseMessage = 'Si è verificato un problema durante la richiesta dell\'utente.';
                        datasetService.appService.showErrorMessage(responseMessage);
                    }
                    return [2 /*return*/, {
                            httpResp: response,
                            dialog: loadingDialogRef2
                        }];
                case 4:
                    e_1 = _a.sent();
                    errorMessage = 'Si è verificato un errore';
                    if (e_1.error && e_1.error.errors) {
                        keyIndex = 0;
                        keys = Object.keys(e_1.error.errors);
                        key = keys[keyIndex];
                        while (keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e_1.error.errors, key)) {
                            keyIndex++;
                            key = keys[keyIndex];
                        }
                        if (e_1.error.errors[key]) {
                            if (Array.isArray(e_1.error.errors[key]))
                                errorMessage = e_1.error.errors[key][0];
                            else if (typeof (e_1.error.errors[key]) === 'string')
                                errorMessage = e_1.error.errors[key];
                        }
                    }
                    datasetService.appService.showErrorMessage(errorMessage);
                    loadingDialogRef2.close();
                    return [2 /*return*/, action_edit_profile(record, datasetService, {
                            name: formResult.name,
                            surname: formResult.surname,
                            email: formResult.email,
                            profile_image_id: formResult.profile_image_id
                        })];
                case 5: return [2 /*return*/];
            }
        });
    });
}
