import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('trip_booking.booking_code', {
	key: 'trip_booking.booking_code',
	name: 'Codice Prenotazione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('date', {
	key: 'date',
	name: 'Data',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	inputRequired: true,
	formValidators: [ Validators.required ]
});

fieldsMap.set('amount', {
	key: 'amount',
	name: 'Importo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('participant_count', {
	key: 'participant_count',
	name: 'Numero Partecipanti',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	formValidators: [ Validators.required ]
});

export {fieldsMap};
