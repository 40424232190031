import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
var TripPackageStep2Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageStep2Component, _super);
    function TripPackageStep2Component(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        return _this;
    }
    TripPackageStep2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.datasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'trip_packages.recordId'
            });
            _this.datasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    TripPackageStep2Component.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        this.datasetMCS.save(formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                }
                _this.goNextStep();
            },
            error: function (response) {
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
            }
        });
    };
    return TripPackageStep2Component;
}(BaseStepViewComponent));
export { TripPackageStep2Component };
