import * as tslib_1 from "tslib";
import { Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { exhaustMap, filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TrenitaliaDetailsComponent } from '../../../trenitalia-dialog/trenitalia-details/trenitalia-details.component';
import { TrenitaliaDialogContainerComponent } from '../../../trenitalia-dialog/trenitalia-dialog-container/trenitalia-dialog-container.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { wrapArray } from 'app/helpers';
var TrenitaliaBookingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TrenitaliaBookingComponent, _super);
    function TrenitaliaBookingComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, domainFilterService, bookingService, injector, bookingStepService, viewContainerRef, tripBookingPackageService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.domainFilterService = domainFilterService;
        _this.bookingService = bookingService;
        _this.injector = injector;
        _this.bookingStepService = bookingStepService;
        _this.viewContainerRef = viewContainerRef;
        _this.tripBookingPackageService = tripBookingPackageService;
        _this.isCarnet = false;
        return _this;
    }
    Object.defineProperty(TrenitaliaBookingComponent.prototype, "travelId", {
        /**
         * if travelId exists mean that travel was created on trenitalia
         */
        get: function () {
            return _.get(this.tripBookingPackage, 'external_solution_data.travel.id');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaBookingComponent.prototype, "tripBookingPackage", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaBookingComponent.prototype, "tripBookingStatus", {
        get: function () {
            return (this.bookingStepService &&
                this.bookingStepService.datasetRS.record.value &&
                this.bookingStepService.datasetRS.record.value.status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaBookingComponent.prototype, "iterationData", {
        get: function () {
            return this.tripBookingPackageService.iterationData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaBookingComponent.prototype, "showHeaderActions", {
        get: function () {
            return this.bookingService.canEdit && !this.tripBookingPackage.tour_operator_booking_ref && !this.tripBookingPackage.tour_operator_booking_commit_status;
        },
        enumerable: true,
        configurable: true
    });
    TrenitaliaBookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            _this.computeData();
            _this.checkParticipant();
        });
        this.computeData();
        this.bookingStepService.settingsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            _this.checkParticipant();
        });
    };
    TrenitaliaBookingComponent.prototype.checkParticipant = function () {
        var travellers = _.get(this.tripBookingPackage, ['external_solution_data', 'travel', 'travellers']);
        var adults = _.reduce(travellers, function (result, traveller) {
            return traveller['type'] == 'ADULT' ? result + 1 : result;
        }, 0);
        var chilren = _.reduce(travellers, function (result, traveller) {
            return traveller['type'] == 'CHILDREN' ? result + 1 : result;
        }, 0);
        var participants = this.bookingService.getTripBooking().participant_counters;
        this.participantsWarning = { status: false, value: {} };
        this.participantsWarning.status = (_.get(participants, 'ADT', 0) + _.get(participants, 'SNR', 0) !== adults) ||
            (_.get(participants, 'CHD', 0) !== chilren);
        this.participantsWarning.value = { ADT: adults, CHD: chilren };
    };
    TrenitaliaBookingComponent.prototype.computeData = function () {
        var e_1, _a;
        this.dataTrain = wrapArray(_.get(this.tripBookingPackage, 'external_solution_data.travel.travelSolutions'))[0];
        this.status = this.tripBookingPackage.tour_operator_booking_status;
        var totalLordo = Number(this.tripBookingPackage.total_amount);
        if (this.tripBookingPackage.children_trip_booking_packages) {
            try {
                for (var _b = tslib_1.__values(this.tripBookingPackage.children_trip_booking_packages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var tbp = _c.value;
                    totalLordo += Number(tbp.total_amount);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.totalFee = this.tripBookingPackage.fee;
        this.totalRowLordo = totalLordo;
        this.isCarnet = _.get(this.tripBookingPackage, 'external_solution_data.isCarnet') || _.get(this.tripBookingPackage, 'target_info.orderType') === 'carnet';
    };
    TrenitaliaBookingComponent.prototype.viewProduct = function () {
        var _this = this;
        this.matDialog
            .open(TrenitaliaDetailsComponent, {
            minWidth: '1400px',
            panelClass: 'create-dialog-container',
            viewContainerRef: this.viewContainerRef,
            data: {
                dataTrain: this.tripBookingPackage.external_solution_data,
                tripBookingPackage: this.tripBookingPackage,
                isCarnet: this.isCarnet
            }
        })
            .afterClosed()
            .subscribe(function (tripBookingPackage) {
            if (tripBookingPackage) {
                _this.bookingStepService.addTrenitaliaService(tripBookingPackage);
            }
        });
    };
    TrenitaliaBookingComponent.prototype.deleteTripBookingPackage = function () {
        var _this = this;
        this.datasetNavigatorService
            .openDeleteDialog(this.datasetACS, this.tripBookingPackage)
            .afterClosed()
            .subscribe(function (result) {
            if (result) {
                _this.bookingStepService.reloadTripBookingPackages();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            }
        });
    };
    TrenitaliaBookingComponent.prototype.editTripBookingPackage = function () {
        var _this = this;
        this.matDialog
            .open(ConfirmDialogComponent, {
            width: '300px',
            data: {
                title: 'Conferma',
                contentMessage: 'Vuoi modificare questo viaggio?'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.bookingStepService.addTrenitaliaService(_this.tripBookingPackage);
        });
    };
    TrenitaliaBookingComponent.prototype.confirmTripBookingPackage = function () {
        this.tripBookingPackageService.confirmTripBookingPackage();
    };
    TrenitaliaBookingComponent.prototype.editTripBookingTravellers = function () {
        var _this = this;
        this.matDialog
            .open(ConfirmDialogComponent, {
            width: '300px',
            data: {
                title: 'Conferma',
                contentMessage: 'Vuoi modificare i dati dei passeggeri?'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.matDialog
                .open(TrenitaliaDialogContainerComponent, {
                minWidth: '800px',
                panelClass: 'create-dialog-container',
                data: {
                    participant: _this.tripBookingPackage.participant_counters,
                    trip_booking_id: _this.tripBookingPackage.trip_booking_id,
                    trip_booking_package: _this.tripBookingPackage,
                    mode: 'edit-traveller-pre-sale',
                    data: {
                        travellers: _.get(_this.tripBookingPackage, 'target_info.travellers'),
                        buyer: _.get(_this.tripBookingPackage, 'target_info.buyer'),
                        invoice: _.get(_this.tripBookingPackage, 'target_info.withInvoice'),
                        invoiceData: _.get(_this.tripBookingPackage, 'target_info.invoiceProfileData'),
                        solution: _.get(_this.tripBookingPackage, 'target_info.solution')
                    },
                    bookingService: _this.bookingService
                }
            })
                .afterClosed()
                .pipe(filter(function (res) { return res; }), exhaustMap(function (res) {
                var postData = {
                    trip_booking_id: _this.datasetRS.recordId,
                    target: 'trenitalia',
                    target_info: res
                };
                postData['trip_booking_package_id_to_replace'] =
                    _this.tripBookingPackage.id;
                var actionEndPoint = '/dataset/trip_bookings/command/replace_trip_external_service';
                return _this.datasetService.post(actionEndPoint, postData);
            })).subscribe({
                next: function (response) {
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                },
                error: function (err) {
                    // show message error
                }
            });
        });
    };
    return TrenitaliaBookingComponent;
}(DestroyableComponent));
export { TrenitaliaBookingComponent };
