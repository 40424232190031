import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService } from './trenitalia.service';
import * as moment from 'moment';
import { DatasetRecordService } from '../../../components/dataset/services/dataset-record.service';
import { DatasetService } from '../../../components/dataset/services/dataset.service';
// @ts-ignore
import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/dialog.component';
import { wrapArray } from 'app/helpers';
import { AppService } from 'app/services/app.service';
import { catchError, exhaustMap, finalize, first, take, takeUntil } from 'rxjs/operators';
import { EMPTY, of, Subject } from 'rxjs';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { TrenitaliaPostSaleChangeConfirmDialog } from './trenitalia-details/trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component';
import { RELOAD_BOOKING_PACKAGES } from '../edit-booking.component';
import { LoadingDialogComponent } from "../../../dialogs/loading-dialog/dialog.component";
import { environment } from "../../../../../environments/environment";
import { BookingService } from '../booking.service';
import { TripBookingPackagesStepService } from "../trip-booking-packages-step/trip-booking-packages-step.service";
var TrenitaliaDialogComponent = /** @class */ (function () {
    function TrenitaliaDialogComponent(trnService, _snackBar, datasetService, appService, matDialog, bookingService, tripBookingPackagesStepService, datasetRs) {
        this.trnService = trnService;
        this._snackBar = _snackBar;
        this.datasetService = datasetService;
        this.appService = appService;
        this.matDialog = matDialog;
        this.bookingService = bookingService;
        this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        this.datasetRs = datasetRs;
        this.loading = false;
        this.tab = 0;
        this.seats = false;
        this.seatsSel = false;
        this.continue = false;
        this.confirm = false;
        this.showedStep = [];
        this.errors = {};
        this.seatsEnabled = false;
        this.nextTrainStep = false;
        this.prevTrainStep = false;
        this.dataDialog = undefined;
        this.isConfirmed = false;
        this.complete = new EventEmitter();
        this.undefined = undefined;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(TrenitaliaDialogComponent.prototype, "step", {
        get: function () {
            return this.trnService.step.value;
        },
        enumerable: true,
        configurable: true
    });
    TrenitaliaDialogComponent.prototype.backToStart = function () {
        this.checkoutData = _.omit(this.checkoutData, [
            'searchSolutions', 'returnSolution', 'forward', 'seatSel',
            'selectedSolution', 'dataAv', 'seats', 'seatsSel', 'seatsCount',
            'totalSelectedSolution', 'seatsRequested', 'selection', 'seatMaps', 'seatSel', 'returnSearch', 'rebuild'
        ]);
        this.seatsSel = false;
        this.trnService.step.next(1);
        this.trnService.checkoutData.next(this.checkoutData);
    };
    TrenitaliaDialogComponent.prototype.ngOnInit = function () {
        var e_1, _a, e_2, _b;
        var _this = this;
        this.isConfirmed = this.bookingService.getTripBooking().status === 'confirmed';
        if (this.tripBookingPackagesStepService) {
            this.tripBookingPackagesStepService.settingsUpdated
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function () {
                var tripBooking = _this.bookingService.getTripBooking();
                _this.isConfirmed = tripBooking && tripBooking.status === 'confirmed';
                if (!_.isEqual(_this.dataDialog.participant, tripBooking.participant_counters)) {
                    _this.checkoutData.adult = _this.checkoutData.mode == 'carnet' ? 1 : _.get(tripBooking.participant_counters, 'ADT', 0) + _.get(tripBooking.participant_counters, 'SNR', 0);
                    _this.checkoutData.children = _.get(tripBooking.participant_counters, 'CHD', 0);
                    _this.checkoutData.alterPass = true;
                    _this.backToStart();
                    _this.dataDialog = {
                        participant: tripBooking.participant_counters,
                        trip_booking_id: tripBooking.id,
                    };
                }
            });
        }
        this.trnService.errors
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (error) {
            var e_3, _a, e_4, _b, e_5, _c;
            if (!_.isEmpty(error)) {
                /*if(error.msg){
                    msg
                }*/
                var msg = '';
                try {
                    for (var _d = tslib_1.__values(_this.checkoutData.selectedSolution.trains), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var train = _e.value;
                        if (_.get(error, train.xmlId)) {
                            try {
                                for (var _f = (e_4 = void 0, tslib_1.__values(_this.checkoutData.travellers)), _g = _f.next(); !_g.done; _g = _f.next()) {
                                    var passenger = _g.value;
                                    if (_.get(error, [train.xmlId, passenger.xmlId])) {
                                        try {
                                            for (var _h = (e_5 = void 0, tslib_1.__values(error.train.xmlId.passenger.xmlId.messages)), _j = _h.next(); !_j.done; _j = _h.next()) {
                                                var message = _j.value;
                                                msg = msg + message + "\n";
                                            }
                                        }
                                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                        finally {
                                            try {
                                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                                            }
                                            finally { if (e_5) throw e_5.error; }
                                        }
                                    }
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                if (msg !== '') {
                    _this.matDialog.open(TextDialogComponent, {
                        minWidth: '550px',
                        data: {
                            contentMessage: msg
                        }
                    });
                }
            }
        });
        if (this.dataD)
            this.trnService.isDialog.next(true);
        if (_.has(this.dataD, 'participant')) {
            this.dataDialog = this.dataD;
        }
        else if (this.bookingService) {
            var tripBooking = this.bookingService.getTripBooking();
            this.dataDialog = {
                participant: tripBooking.participant_counters,
                trip_booking_id: tripBooking.id,
            };
        }
        this.trnService.btnAction
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (action) {
            switch (action) {
                case '': return;
                case 'conf-sel':
                    _this.confirmSel(_this.step == 3);
                    break;
                case 'travel-change':
                    _this.changeTravel();
                    break;
                case 'seat-sel':
                    _this.checkSeats();
                    break;
                case 'confirm-carnet':
                    _this.sendConfirm();
                    break;
                case 'conf-sel-seat':
                    _this.confirmSel(true);
                    break;
                case 'back':
                    _this.back();
                    break;
                case 'back-fw':
                    _this.back(true);
                    break;
                case 'back-start':
                    _this.backToStart();
                    break;
            }
        });
        this.trnService.loading
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (l) {
            _this.loading = l;
            if (l && _this.step != 1) {
                _this.loadingDialog = _this.matDialog.open(LoadingDialogComponent, {
                    minWidth: 400,
                    data: {
                        title: 'Attendere',
                        contentMessage: 'Operazione in corso...'
                    }
                });
            }
            else {
                if (_this.loadingDialog)
                    _this.loadingDialog.close();
            }
        });
        this.trnService.step
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (s) {
            _this.tab = Math.min(Math.max(0, s - 1), 4);
            //this.stepper.selectedIndex = this.tab;
            // timeout need for wait completed step status to change
            /*setTimeout(() => {
                console.log('newTabIndex', newTabIndex);
                if(newTabIndex > this.stepper.selectedIndex) this.stepper.selectedIndex = newTabIndex;
            }, 100);*/
            _this.checkContinue();
        });
        /*this.trnService.trainSeatStep
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(trainStep =>{
                this.trainSeatStep =  trainStep
                if(_.has(this.checkoutData,'selectedSolution')){
                    this.nextTrainStep = this.checkoutData.selectedSolution.trains.length-1 > this.trainSeatStep;
                    this.prevTrainStep = this.trainSeatStep > 0;
                }
            });*/
        this.trnService.continue
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (c) {
            _this.continue = c;
        });
        this.trnService.total
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.total = data;
            if (_this.step == 2 && _this.total > 0) {
                _this.trnService.continue.next(true);
            }
        });
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.checkoutData = data;
            if (_.get(environment, 'trenitalia.debug', false))
                console.log("Checkout data", _this.checkoutData);
            if (_this.checkoutData.selectedSolution) {
                /*this.seatsSel = false;*/
                _this.seats = _this.checkoutData.seats;
            }
            _this.checkContinue();
            _this.checkConfirm();
            // @ts-ignore
            _this.seatsEnabled = _this.checkSeatsEnable();
            _this.trnService.seatsEnabled.next(_this.seatsEnabled);
            if (_.has(_this.checkoutData, 'selectedSolution')) {
                _this.nextTrainStep = _this.checkoutData.selectedSolution.trains.length - 1 > _this.trainSeatStep;
            }
            if (_this.checkoutData.mode == 'carnet') {
                _this.showedStep = [1, 2, 3];
            }
        });
        if (!_.has(this.dataDialog, 'mode')) {
            this.showedStep = [1, 2, 3, 4];
            this.checkoutData.mode = this.checkoutData.mode == 'carnet' ? 'carnet' : 'travel';
            if (_.has(this.dataDialog, 'trip_booking_package')) { //edit mode
                this.checkoutData.mode = 'edit';
                this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
                this.checkoutData.editPassengers = _.get(this.dataDialog, 'trip_booking_package.target_info.travellers');
                this.checkoutData.buyer = _.get(this.dataDialog, 'trip_booking_package.target_info.buyer');
                if (_.has(this.dataDialog, 'trip_booking_package.target_info.solution.returnTravelSolution')) { //caso A/R
                    var returnTrip = _.get(this.dataDialog, 'trip_booking_package.target_info.solution.returnTravelSolution');
                    this.checkoutData.return = {
                        start: _.get(returnTrip, 'context.searchCriteria.departureTimesStart'),
                        from: _.get(returnTrip, 'context.searchCriteria.departure.id'),
                        to: _.get(returnTrip, 'context.searchCriteria.arrival.id')
                    };
                    this.checkoutData.ar = true;
                    this.checkoutData.direction = 'FORWARD';
                }
            }
        }
        else {
            this.checkoutData.mode = this.dataDialog.mode;
            this.checkoutData.reopen = this.dataDialog.data.reopen;
            switch (this.checkoutData.mode) {
                case 'edit-traveller-pre-sale': {
                    this.trnService.step.next(4);
                    this.checkoutData.editPassengers = this.checkoutData.travellers = _.get(this.dataDialog, 'data.travellers');
                    this.checkoutData.tripBookingId = _.get(this.dataDialog, 'trip_booking_id');
                    this.checkoutData.buyer = _.get(this.dataDialog, 'data.buyer');
                    this.checkoutData.invoiceRequested = _.get(this.dataDialog, 'data.invoice');
                    this.checkoutData.invoiceData = this.checkoutData.invoiceProfileData = _.get(this.dataDialog, 'data.invoiceData');
                    this.checkoutData.selectedSolution = this.checkoutData.selection = _.get(this.dataDialog, 'data.solution');
                    this.showedStep = [4];
                    break;
                }
                case 'edit-travellers': {
                    this.trnService.step.next(4);
                    var passenger = [];
                    try {
                        for (var _c = tslib_1.__values(this.dataDialog.data.travellers), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var traveller = _d.value;
                            for (var key in this.dataDialog.data.travelSolution.offeredServices) {
                                if (_.get(this.dataDialog, ['data', 'travelSolution', 'offeredServices', key, 'travellerXmlId']) === traveller.xmlId &&
                                    _.get(this.dataDialog, ['data', 'travelSolution', 'offeredServices', key, 'status']) === 'PURCHASED') {
                                    passenger.push(traveller);
                                }
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    this.checkoutData.editPassengers = passenger;
                    this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
                    this.showedStep = [4];
                    break;
                }
                case 'edit-date-time': {
                    this.trnService.step.next(1);
                    this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
                    this.checkoutData.from = parseInt(_.get(this.dataDialog.data, 'travelSolution.start.id'));
                    this.checkoutData.to = parseInt(_.get(this.dataDialog.data, 'travelSolution.end.id'));
                    this.checkoutData.start = _.get(this.dataDialog.data, 'travelSolution.departure');
                    console.log(this.dataDialog.data);
                    /*if( _.has(this.dataDialog.data,'travelSolution.returnTravelSolution')){ //caso A/R
                        const returnTrip =_.get(this.dataDialog.data,'travelSolution.returnTravelSolution');
                        this.checkoutData.return = {
                            start : _.get(returnTrip,'context.searchCriteria.departureTimesStart'),
                            from:_.get(returnTrip,'context.searchCriteria.departure.id'),
                            to:_.get(returnTrip,'context.searchCriteria.arrival.id'),
                            solution: returnTrip
                        }
                        this.checkoutData.direction = 'FORWARD';
                    }*/
                    this.showedStep = [1, 2, 3];
                    break;
                }
                case 'travel-change': {
                    this.checkoutData.postSaleDatails = _.get(this.dataDialog, 'data.postAction.postSaleDetails');
                    this.trnService.step.next(1);
                    var passenger = [];
                    try {
                        for (var _e = tslib_1.__values(this.dataDialog.data.travellers), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var traveller = _f.value;
                            for (var key in this.dataDialog.data.travelSolution.offeredServices) {
                                if (_.get(this.dataDialog, ['data', 'travelSolution', 'offeredServices', key, 'travellerXmlId']) === traveller.xmlId &&
                                    _.get(this.dataDialog, ['data', 'travelSolution', 'offeredServices', key, 'status']) === 'PURCHASED') {
                                    passenger.push(traveller);
                                }
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    this.checkoutData.editPassengers = this.checkoutData.passengers = passenger;
                    this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
                    this.checkoutData.from = parseInt(_.get(this.dataDialog.data, 'travelSolution.start.id'));
                    this.checkoutData.to = parseInt(_.get(this.dataDialog.data, 'travelSolution.end.id'));
                    this.checkoutData.start = _.get(this.dataDialog.data, 'travelSolution.departure');
                    /*if( _.has(this.dataDialog.data,'travelSolution.returnTravelSolution')){ //caso A/R
                       const returnTrip =_.get(this.dataDialog.data,'travelSolution.returnTravelSolution');
                       this.checkoutData.return = {
                           start : _.get(returnTrip,'context.searchCriteria.departureTimesStart'),
                           from:_.get(returnTrip,'context.searchCriteria.departure.id'),
                           to:_.get(returnTrip,'context.searchCriteria.arrival.id')
                       }
                       this.checkoutData.ar = true;
                       this.checkoutData.direction = 'FORWARD';
                    }*/
                    this.showedStep = [1, 2, 3];
                    break;
                }
            }
        }
        var initData;
        if (this.dataDialog.data || this.dataDialog.trip_booking_package) {
            initData = {
                tripBookingData: _.get(this.dataDialog, 'trip_booking_package'),
                mode: this.checkoutData.mode ? this.checkoutData.mode : 'travel',
                reopen: _.get(this.dataDialog, 'reopen')
            };
        }
        this.trnService.init(initData, this.checkoutData);
        /*this.trnService.checkoutData.next(this.checkoutData);*/
    };
    TrenitaliaDialogComponent.prototype.onCloseClick = function () {
        this.matDialog.closeAll();
        /*this.dialogRef.close(false);*/
        this.trnService.reset();
    };
    TrenitaliaDialogComponent.prototype.confirmSel = function (seats) {
        var _this = this;
        if (seats === void 0) { seats = false; }
        this.trnService.loading.next(true);
        /*if(_.get(this.checkoutData,'notSeleable')){
            this.matDialog.open(TextDialogComponent, {
                minWidth: '550px',
                data: {
                    contentMessage: "Soluzione di viaggio con una o più tratte non acquistabili su questo canale"
                }
            });
        }*/
        if (this.seatsSel) {
            this.checkoutData.dataAv.withSeatsSelection = true;
        }
        else {
            this.checkoutData.dataAv.withSeatsSelection = false;
        }
        if (seats) {
            this.setSeats();
        }
        if (this.checkoutData.direction === 'RETURN' && (seats || !seats && !this.seatsSel)) {
            /*this.checkoutData.forward.solution['returnTravelSolution'] = this.checkoutData.selectedSolution.original;*/
            var sol = this.checkoutData.returnSearchSolutions.find(function (s) { return s.returnTravelSolution.solutionId == _this.checkoutData.dataAv.travelSolutions.solutionId; });
            this.checkoutData.dataAv.travelSolutions = sol.original;
        }
        /*if(seats){
            this.checkoutData.dataAv.withSeatsSelection = false;
        }*/
        this.trnService.getAvailability(this.checkoutData.dataAv)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            var e_6, _a;
            if (data.validSelection === 'true') {
                _this.trnService.loading.next(false);
                _this.checkoutData.selection = _this.checkoutData.dataAv.travelSolutions = data['travelSolutions'];
                _this.checkoutData.travellers = _this.checkoutData.dataAv.travellers = Array.isArray(data['travellers'])
                    ? data['travellers']
                    : [data['travellers']];
                _this.checkoutData.selectedSolution.selectedOffers = {};
                _this.checkoutData.travellers.forEach(function (pass) {
                    _this.checkoutData.selectedSolution.selectedOffers[pass.xmlId] = {};
                    _this.checkoutData.selectedSolution.trains.forEach(function (train) {
                        _this.checkoutData.selectedSolution.selectedOffers[pass.xmlId][train.id] = {};
                        var offer = _this.checkoutData.dataAv.selectedOffer.find(function (off) {
                            return _.get(_this.checkoutData.selectedSolution.offeredServices, off.id).nodeId == train.id &&
                                _.get(_this.checkoutData.selectedSolution.offeredServices, off.id).travellerXmlId == pass.xmlId;
                        });
                        var offerId = _.get(_this.checkoutData.selectedSolution.offeredServices, offer.id).offerId;
                        var catalogServiceId = _.get(_this.checkoutData.selectedSolution.offeredServices, offer.id).catalogServiceId;
                        _this.checkoutData.selectedSolution.selectedOffers[pass.xmlId][train.id] =
                            {
                                offer: {
                                    id: offerId,
                                    name: _.get(_this.checkoutData.selectedSolution.servicesMap.offers, offerId + '.display_name')
                                },
                                service: {
                                    id: offer.catalogServiceId,
                                    name: _.get(_this.checkoutData.selectedSolution.servicesMap.catalog_services, catalogServiceId + '.display_name')
                                }
                            };
                    });
                });
                if (data['additional_fee_on_total']) {
                    _this.total['fee'] = _this.total['fee'] + data['additional_fee_on_total'] - _.get(_this.total, 'additional_fee_on_total', 0);
                    _this.total['valueNet'] = _this.total['valueNet'];
                    _this.total['value'] = _this.total['value'] + data['additional_fee_on_total'] - _.get(_this.total, 'additional_fee_on_total', 0);
                    _this.total['additional_fee_on_total'] = data['additional_fee_on_total'];
                    _this.trnService.total.next(_this.total);
                }
                if (_this.seatsSel && !_.has(_this.checkoutData, 'seatSel')) {
                    _this.checkoutData.seatMaps = data['trainSeatMaps'];
                    _this.trnService.step.next(3);
                    _this.trnService.checkoutData.next(_this.checkoutData);
                }
                else {
                    if (_this.checkoutData.direction === 'RETURN') {
                        _this.checkoutData.returnSolution = _this.checkoutData.selectedSolution;
                    }
                    if (_this.checkoutData.ar && !_.has(_this.checkoutData, 'returnSolution')) {
                        _this.trnService.loading.next(true);
                        _this.checkoutData.forward = {
                            solution: _this.checkoutData.selection,
                            searchedSolutions: _this.checkoutData.searchSolutions,
                            selectedSolution: _this.checkoutData.selectedSolution,
                            dataAv: _this.checkoutData.dataAv,
                            seatMaps: _.get(_this.checkoutData, 'seatMaps'),
                            seatSel: _.get(_this.checkoutData, 'seatSel'),
                            total: _this.total
                        };
                        /*this.checkoutData.forwardSolution = this.checkoutData.selection;
                        this.checkoutData.forwardSearchedSolution = this.checkoutData.searchSolutions;
                        if(this.checkoutData.dataAv.seatMap){
                            this.checkoutData.forwardSeatmap = this.checkoutData.dataAv.seatMap;
                        }*/
                        var departureArrival = moment(_.get(_this.checkoutData, 'arrival'));
                        var returnStart = departureArrival.isSameOrBefore(moment(_.get(_this.checkoutData, 'return.start'))) ? _.get(_this.checkoutData, 'return.start') : departureArrival.add(10, 'minutes');
                        _this.checkoutData.return.start = returnStart;
                        var sol = {
                            adult: _this.checkoutData.adult || 0,
                            children: _this.checkoutData.children,
                            start: returnStart,
                            ar: _this.checkoutData.ar,
                            from: _this.checkoutData.return.from,
                            to: _this.checkoutData.return.to,
                            direction: 'RETURN',
                            returnTripDate: returnStart,
                            roundTrip: true,
                            travellers: _this.checkoutData.travellers,
                            forwardTripDate: _.get(_this.checkoutData, 'selectedSolution.departure'),
                            forwardTrip: _this.checkoutData.selection,
                            seatMap: _.get(_this.checkoutData, 'dataAv.seatMap')
                        };
                        _this.trnService.getSolutions(sol)
                            .pipe(takeUntil(_this._unsubscribeAll))
                            .subscribe({
                            next: function (data) {
                                if (_.isEmpty(data.travelSolutions)) {
                                    _this.trnService.checkoutData.next(_this.checkoutData);
                                    _this.appService.showErrorMessage("Nessuna soluzione trovata!");
                                }
                                else {
                                    _this.trnService.loading.next(false);
                                    _this.checkoutData.searchSolutions = data;
                                    _this.checkoutData.returnSearchSolutions = data.travelSolutions;
                                    _this.checkoutData.searchSolutions.travelSolutions = _this.checkoutData.searchSolutions.travelSolutions.map(function (s) { return s.returnTravelSolution; });
                                    _this.checkoutData.seatsRequested = false;
                                    _this.checkoutData.returnSearch = true;
                                    _this.seatsSel = false;
                                    _this.checkoutData.direction = 'RETURN';
                                    _this.checkoutData = _.omit(_this.checkoutData, ['selectedSolution', 'selection', 'dataAv', 'seatMaps', 'seatsCount', 'seatSel']);
                                    _this.trnService.updateServicesMap(data.serviceMap || data.servicesMap);
                                    _this.trnService.checkoutData.next(_this.checkoutData);
                                    _this.trnService.step.next(2);
                                }
                            },
                            error: function (error) {
                                _this.trnService.loading.next(false);
                            }
                        });
                    }
                    else {
                        if (_this.checkoutData.dataAv.seatMap && _this.checkoutData.direction === 'RETURN')
                            _this.checkoutData.return.seatMap = _this.checkoutData.dataAv.seatMap;
                        if (_.has(_this.checkoutData, 'forward.dataAv.seatMap')) {
                            if (_.has(_this.checkoutData, 'return.seatMap')) {
                                _this.checkoutData.dataAv.seatMap = tslib_1.__spread(_this.checkoutData.return.seatMap, _this.checkoutData.forward.dataAv.seatMap);
                            }
                            else {
                                _this.checkoutData.dataAv.seatMap = wrapArray(_this.checkoutData.forward.dataAv.seatMap);
                            }
                            _this.seatsSel = true;
                        }
                        _this.trnService.checkoutData.next(_this.checkoutData);
                        _this.trnService.step.next(4);
                    }
                }
            }
            else {
                var message = 'Soluzione non più disponibile';
                var travelSolutions = _.get(data, 'travelSolutions');
                // TODO: check also saleabilityMessages
                if (travelSolutions) {
                    _this.errors = {};
                    if (!Array.isArray(travelSolutions))
                        travelSolutions = [travelSolutions];
                    var offeredServices = _.get(travelSolutions, [0, 'offeredServices']);
                    if (offeredServices && !Array.isArray(offeredServices))
                        offeredServices = [offeredServices];
                    if (offeredServices && _.has(travelSolutions, [0, 'returnTravelSolution', 'offeredServices'])) {
                        var returnOfferedServices = _.get(travelSolutions, [0, 'returnTravelSolution', 'offeredServices']);
                        if (Array.isArray(returnOfferedServices))
                            offeredServices.push.apply(offeredServices, tslib_1.__spread(returnOfferedServices));
                        else
                            offeredServices.push(returnOfferedServices);
                    }
                    if (offeredServices) {
                        try {
                            for (var offeredServices_1 = tslib_1.__values(offeredServices), offeredServices_1_1 = offeredServices_1.next(); !offeredServices_1_1.done; offeredServices_1_1 = offeredServices_1.next()) {
                                var offeredService = offeredServices_1_1.value;
                                if (_.get(offeredService, 'status') != 'SELECTED')
                                    continue;
                                var messages = _.get(offeredService, 'bookingInfo.reportItem.messages');
                                if (messages && !Array.isArray(messages))
                                    messages = [messages];
                                var train = _.get(offeredService, 'solutionNodeRef');
                                var traveller = _.get(offeredService, 'bookingInfo.traveller');
                                if (!_.has(_this.errors, train))
                                    _this.errors[train] = {};
                                if (!_.has(_this.errors, [train, traveller]))
                                    _this.errors[train][traveller] = {};
                                _this.errors[train][traveller].messages = messages;
                                // take last message to show
                                if (!_.isEmpty(messages))
                                    message = messages[0];
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (offeredServices_1_1 && !offeredServices_1_1.done && (_a = offeredServices_1.return)) _a.call(offeredServices_1);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                        if (!_.isEmpty(_this.errors))
                            _this.trnService.errors.next(_this.errors);
                    }
                }
                message = _.get(data, '_invalid_messages.0.message', message);
                _this.matDialog
                    .open(TextDialogComponent, {
                    data: {
                        title: 'Attenzione',
                        contentMessage: message
                    }
                });
                _this.seatsSel = false;
                _this.seats = false;
                _this.trnService.loading.next(false);
            }
        }, function (err) {
            if (err.error.details[0].code == '451') {
                _this._snackBar.open('Impossibile selezionare i posti! Selezionare un altra soluzione', null, {
                    duration: 5000
                });
                _this.seatsSel = false;
                _this.seats = false;
            }
            _this.trnService.loading.next(false);
        });
        this.trnService.scrollToTop();
    };
    TrenitaliaDialogComponent.prototype.sendConfirm = function (edit) {
        var _this = this;
        if (edit === void 0) { edit = false; }
        this.trnService.loading.next(true);
        var travellersData = this.mapTravellers();
        var res = {
            travellers: travellersData,
            solution: this.checkoutData.selection ? this.checkoutData.selection : this.checkoutData.selectedSolution.original,
            buyer: this.checkoutData.buyer,
            withInvoice: _.get(this.checkoutData, 'invoiceRequested', false)
        };
        var dataTravel = {};
        if (this.checkoutData.mode != 'carnet') {
            dataTravel = {
                travelSolutions: this.checkoutData.selection,
                travellers: travellersData,
                mode: 'travellers'
            };
        }
        else {
            this.checkoutData.dataAv.travelSolutions.offeredServices = _.isArray(this.checkoutData.dataAv.travelSolutions.offeredServices) ? this.checkoutData.dataAv.travelSolutions.offeredServices : [this.checkoutData.dataAv.travelSolutions.offeredServices];
            this.checkoutData.dataAv.travellers = _.isArray(this.checkoutData.dataAv.travellers) ? this.checkoutData.dataAv.travellers : [this.checkoutData.dataAv.travellers];
            this.checkoutData.dataAv.selectedOffer = [{ id: this.checkoutData.carnetData.selectedService }];
            this.checkoutData.dataAv.customerKey = this.checkoutData.travellers.customerKey;
            if (this.seats) {
                this.setSeats();
            }
            dataTravel = this.checkoutData.dataAv;
        }
        this.trnService.getAvailability(dataTravel)
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(first(), exhaustMap(function (resp) {
            if (!_.isEmpty(resp._invalid_traveller_parameters)) {
                _this.checkoutData.invalid_traveller_parameters = resp._invalid_traveller_parameters;
                _this.trnService.checkoutData.next(_this.checkoutData);
                var messages = Object.entries(resp._invalid_traveller_parameters).reduce(function (res, _a) {
                    var _b = tslib_1.__read(_a, 2), travellerXmlId = _b[0], params = _b[1];
                    return Object.entries(params).reduce(function (result, _a) {
                        var e_7, _b;
                        var _c = tslib_1.__read(_a, 2), paramKey = _c[0], paramData = _c[1];
                        try {
                            for (var _d = tslib_1.__values(paramData.validationMessages), _e = _d.next(); !_e.done; _e = _d.next()) {
                                var message = _e.value;
                                if (result.includes(message))
                                    continue;
                                result.push(message);
                            }
                        }
                        catch (e_7_1) { e_7 = { error: e_7_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                            }
                            finally { if (e_7) throw e_7.error; }
                        }
                        return result;
                    }, res);
                }, []);
                if (!_.isEmpty(messages))
                    _this.appService.showErrorMessage(messages.join('\n'));
                else
                    _this.appService.showErrorMessage('Dati non validi');
                _this.trnService.loading.next(false);
                return EMPTY;
            }
            if (!resp.validSelection) {
                var message = _.get(resp, '_invalid_messages.0.message', 'La soluzione o i dati inseriti non sono validi');
                _this.appService.showErrorMessage(message);
                _this.trnService.loading.next(false);
                return EMPTY;
            }
            return of(resp);
        }), exhaustMap(function (resp) {
            var invoiceProfileId = _this.checkoutData.billingFormData && _this.checkoutData.billingFormData.id_invoice_profile;
            if (invoiceProfileId) {
                _this.checkoutData.invoiceProfile = res.invoiceProfile = { id: invoiceProfileId };
                res.invoiceProfileData = _this.mapBillingData();
                res.invoiceProfileData.id = invoiceProfileId;
            }
            if (_this.checkoutData.invoiceRequested && !_.has(_this.checkoutData, 'invoiceProfile.id') && !invoiceProfileId) {
                // TODO: call server for create invoiceProfile if needed /command/create_invoice_profiles
                // set res.invoiceProfile
                // ATTENTION return the observable after the http request
                return _this.datasetService.post('/dataset/trenitalia/command/create_invoice_profiles', _this.mapBillingData())
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .pipe(catchError(function (err) {
                    var message = err && err.message;
                    if (err && err.error && err.error.message) {
                        message = err.error.message;
                    }
                    _this.matDialog
                        .open(TextDialogComponent, {
                        data: {
                            title: 'Attenzione',
                            contentMessage: message
                        }
                    });
                    _this.trnService.loading.next(false);
                    return EMPTY;
                }), exhaustMap(function (creationResponse) {
                    var invoiceProfileId = creationResponse.invoiceProfileId;
                    if (!invoiceProfileId) {
                        _this.matDialog
                            .open(TextDialogComponent, {
                            data: {
                                title: 'Attenzione',
                                contentMessage: "ID invoiceProfile non presente"
                            }
                        });
                        _this.trnService.loading.next(false);
                        return EMPTY;
                    }
                    _this.checkoutData.invoiceProfile = res.invoiceProfile = { id: invoiceProfileId };
                    _this.checkoutData.invoiceProfileData = res.invoiceProfileData = _this.mapBillingData();
                    res.invoiceProfileData.id = _this.checkoutData.invoiceProfile.id;
                    return of(creationResponse);
                }));
            }
            return of(resp);
        }))
            .subscribe({
            next: function (resp) {
                if (_this.checkoutData.mode == 'carnet') {
                    res = {
                        travellers: _.get(resp, 'travellers'),
                        solution: _.get(resp, 'travelSolutions')
                    };
                    if (_this.checkoutData.invoiceProfile) {
                        res.invoiceProfile = _this.checkoutData.invoiceProfile;
                        res.invoiceProfileData = _this.checkoutData.invoiceProfileData;
                        res.invoiceProfileData.id = _this.checkoutData.invoiceProfile.id;
                    }
                }
                if (_this.seatsSel && _this.checkoutData.dataAv.seatMap) {
                    res.seatMap = _this.checkoutData.dataAv.seatMap;
                }
                /*this.dialogRef.close(res);*/
                var postData = {
                    trip_booking_id: _this.bookingService.getTripBooking().id,
                    target: 'trenitalia',
                    target_info: res
                };
                var actionEndPoint = '/dataset/trip_booking_packages/create';
                if (_this.dataD && _this.dataD.trip_booking_package && _this.checkoutData.mode == 'edit') {
                    postData['trip_booking_package_id_to_replace'] =
                        _this.dataD.trip_booking_package.id;
                    actionEndPoint =
                        '/dataset/trip_bookings/command/replace_trip_external_service';
                }
                _this.datasetService.post(actionEndPoint, postData).subscribe({
                    next: function (response) {
                        /*this.reloadTripBookingPackages();*/
                        _this.appService.appEvent.next({
                            name: RELOAD_BOOKING_PACKAGES,
                        });
                        _this.complete.emit();
                        _this.loading = false;
                        _this.trnService.reset();
                        _this.trnService.loading.next(false);
                    },
                    error: function (err) {
                        _this.loading = false;
                    }
                });
            },
            error: function (response) {
                // todo: error handler
                _this.trnService.loading.next(false);
                console.log(response);
            }
        });
        this.trnService.scrollToTop(true);
    };
    TrenitaliaDialogComponent.prototype.back = function (fw) {
        if (fw === void 0) { fw = false; }
        switch (this.step) {
            case 4:
            case 3: {
                if (fw) {
                    this.checkoutData.direction = 'FORWARD';
                    this.checkoutData.searchSolutions = this.checkoutData.forward.searchedSolutions;
                    /*this.checkoutData.selection  = this.checkoutData.forward.solution;
                    this.checkoutData.selectedSolution = this.checkoutData.forward.selectedSolution;*/
                    this.checkoutData.dataAv = _.get(this.checkoutData, 'forward.dataAv');
                    this.checkoutData.returnSearch = false;
                    this.total = this.checkoutData.forward.total;
                    this.checkoutData = _.omit(this.checkoutData, ['returnSolution', 'forward']);
                    this.checkoutData.rebuild = true;
                }
                else {
                    this.checkoutData.returnSearch = true;
                }
                this.seatsSel = false;
                this.checkoutData.dataAv = _.omit(this.checkoutData.dataAv, 'seatMap');
                this.checkoutData = _.omit(this.checkoutData, ['seatSel', 'selectedSolution', 'dataAv', 'selection']);
                this.checkoutData.seatsRequested = false;
                this.trnService.checkoutData.next(this.checkoutData);
                this.trnService.step.next(2);
                break;
            }
            case 2: {
                if (this.checkoutData.direction === 'RETURN') {
                    this.checkoutData.direction = 'FORWARD';
                    this.checkoutData.searchSolutions = this.checkoutData.forward.searchedSolutions;
                    this.checkoutData.selection = this.checkoutData.forward.solution;
                    this.checkoutData.selectedSolution = this.checkoutData.forward.selectedSolution;
                    this.checkoutData.dataAv = _.get(this.checkoutData, 'forward.dataAv');
                    this.checkoutData.dataAv = _.omit(this.checkoutData.dataAv, 'seatMap');
                    this.checkoutData.seatsRequested = false;
                    this.checkoutData.returnSearch = false;
                    this.total = this.checkoutData.forward.total;
                    this.checkoutData = _.omit(this.checkoutData, ['returnSolution', 'forward', 'seatSel', 'selectedSolution', 'dataAv']);
                    this.checkoutData.rebuild = true;
                }
                this.trnService.checkoutData.next(this.checkoutData);
                break;
            }
            /*default:
                this.trnService.step.next(this.step-1);*/
        }
        this.checkConfirm();
    };
    TrenitaliaDialogComponent.prototype.checkSeats = function () {
        this.seatsSel = this.seatsSel != true;
        this.checkoutData.seatsRequested = this.seatsSel;
        this.trnService.checkoutData.next(this.checkoutData);
        /*this.confirmSel();*/
    };
    TrenitaliaDialogComponent.prototype.checkSeatsEnable = function () {
        var e_8, _a, e_9, _b;
        if (!this.checkoutData.dataAv)
            return false;
        if (!_.get(this.checkoutData, 'selectedSolution.trains'))
            return false;
        var offered = [];
        try {
            for (var _c = tslib_1.__values(this.checkoutData.dataAv.selectedOffer), _d = _c.next(); !_d.done; _d = _c.next()) {
                var offer = _d.value;
                offered.push(_.get(this.checkoutData, ['selectedSolution', 'offeredServices', offer.id]));
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_8) throw e_8.error; }
        }
        var _loop_1 = function (train) {
            var e_10, _a;
            var trainOff = offered.filter(function (off) { return off.nodeId == train.id; });
            var service = _.get(trainOff, [0, 'catalogServiceId']);
            try {
                for (var trainOff_1 = (e_10 = void 0, tslib_1.__values(trainOff)), trainOff_1_1 = trainOff_1.next(); !trainOff_1_1.done; trainOff_1_1 = trainOff_1.next()) {
                    var offtr = trainOff_1_1.value;
                    if (offtr.catalogServiceId != service) {
                        return { value: false };
                    }
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (trainOff_1_1 && !trainOff_1_1.done && (_a = trainOff_1.return)) _a.call(trainOff_1);
                }
                finally { if (e_10) throw e_10.error; }
            }
        };
        try {
            for (var _e = tslib_1.__values(this.checkoutData.selectedSolution.trains), _f = _e.next(); !_f.done; _f = _e.next()) {
                var train = _f.value;
                var state_1 = _loop_1(train);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        catch (e_9_1) { e_9 = { error: e_9_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_9) throw e_9.error; }
        }
        return this.seats;
    };
    TrenitaliaDialogComponent.prototype.editTravellerPreSale = function () {
        var travellersData = this.mapTravellers();
        var res = _.get(this.dataDialog, 'trip_booking_package.target_info');
        res.travellers = travellersData;
        if (this.checkoutData.invoiceRequested && this.checkoutData.billingFormValid) {
        }
    };
    TrenitaliaDialogComponent.prototype.editTravellers = function () {
        var e_11, _a;
        var _this = this;
        this.trnService.loading.next(true);
        var newTravellers = this.mapTravellers();
        newTravellers.forEach(function (tr, k) {
            newTravellers[k] = _.omit(tr, ['id']);
        });
        this.dataDialog.data.travellers.forEach(function (tr, k) {
            _this.dataDialog.data.travellers[k] = _.omit(tr, ['dataPass', 'valid', 'id']);
        });
        var travellersToChange = [];
        var postSaleAction = this.dataDialog.data.postAction;
        var purchasedOfferMap = new Map();
        var newTravellerXmlIdMap = new Map();
        this.dataDialog.data.travel.travelSolutions.forEach(function (travelSolution) {
            if (travelSolution.xmlId !== _.get(_this.dataDialog.data, 'travelSolution.xmlId') &&
                _.get(travelSolution, 'returnTravelSolution')) {
                travelSolution = _.get(_this.dataDialog.data, 'travelSolution');
            }
            if (!travelSolution.purchased_offers)
                return;
            Object.entries(travelSolution.offeredServices).forEach(function (_a) {
                var _b = tslib_1.__read(_a, 2), offerId = _b[0], purchasedService = _b[1];
                // @ts-ignore
                if (purchasedService.status != 'PURCHASED')
                    return;
                purchasedOfferMap.set([
                    purchasedService['original'].id.offeredServiceId,
                    purchasedService['original'].id.travelId,
                    purchasedService['original'].id.travelSolutionId
                ].join(':'), purchasedService['travellerXmlId']);
            });
        });
        var _loop_2 = function (postSaleDetail) {
            var key = [
                postSaleDetail.offeredServiceId.offeredServiceId,
                postSaleDetail.offeredServiceId.travelId,
                postSaleDetail.offeredServiceId.travelSolutionId
            ].join(':');
            var travellerXmlId = purchasedOfferMap.get(key);
            if (!travellerXmlId)
                return "continue";
            if (!newTravellers.find(function (t) { return t.xmlId == travellerXmlId; }))
                return "continue";
            travellersToChange.push({
                traveller: travellerXmlId,
                postSaleDetail: postSaleDetail
            });
        };
        try {
            /* post sale details*/
            for (var _b = tslib_1.__values(postSaleAction.postSaleDetails), _c = _b.next(); !_c.done; _c = _b.next()) {
                var postSaleDetail = _c.value;
                _loop_2(postSaleDetail);
            }
        }
        catch (e_11_1) { e_11 = { error: e_11_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_11) throw e_11.error; }
        }
        var data = {
            travel: this.dataDialog.data.reopen.travel,
            travellersToChange: travellersToChange,
            newTravellers: newTravellers,
            trip_booking_package_id: this.dataDialog.trip_booking_package.id
        };
        this.trnService.changeTravellers(data)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (change) {
            var pay = change.missingAmount.amount > 0.0
                ? change.missingAmount.amount
                : 'Gratis';
            if (change.fee > 0) {
                pay += " Diritti agenzia € " + change.fee;
            }
            _this.matDialog
                .open(ConfirmDialogComponent, {
                data: {
                    contentMessage: 'Vuoi modificare i dati dei passeggeri? Costo: ' +
                        pay
                },
                panelClass: 'create-dialog-container'
            })
                .afterClosed()
                .subscribe({
                next: function (result) {
                    if (result) {
                        var data_1 = {
                            fromTravellersTo: change.fromTravellersTo,
                            travel: change.travel,
                            type: 'traveller_change'
                        };
                        data_1['postSaleData'] = _.omit(change, ['travel', 'fromTravellersTo']);
                        _this.trnService.updateAndConfirm(data_1).subscribe(function (s) {
                            _this.trnService.loading.next(false);
                            /*this.matDialog.closeAll();*/
                            _this.complete.emit();
                            _this._snackBar.open('Cambio dati passeggeri effettuato', '', {
                                duration: 5000
                            });
                        });
                    }
                    else {
                        _this.loading = false;
                    }
                },
                error: function () {
                    _this.loading = false;
                },
                complete: function () {
                    _this.loading = false;
                }
            });
        });
    };
    TrenitaliaDialogComponent.prototype.editDateTime = function () {
        var _this = this;
        this.matDialog
            .open(TrenitaliaPostSaleChangeConfirmDialog, {
            minWidth: '300px',
            panelClass: 'create-dialog-container',
            data: {
                postSaleData: Object.assign({}, this.checkoutData.selectedSolution.validatedPostSaleRecord, {
                    missingAmount: _.get(this.checkoutData.selectedSolution, 'missingAmount'),
                    reversedPaymentRecords: wrapArray(_.get(this.checkoutData.selectedSolution, 'reversedPaymentRecords')),
                    reusedPaymentRecords: wrapArray(_.get(this.checkoutData.selectedSolution, 'reusedPaymentRecords')),
                    additionalCharges: _.get(this.checkoutData.selectedSolution, 'additionalCharges'),
                    postSaleFee: _.get(this.checkoutData.searchSolutions, 'fee'),
                }),
                type: 'edit-date-time',
                title: 'Cambio data e orario',
                message: 'Vuoi cambiare data ora viaggio?',
                icon: 'event'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.loading = true;
            var travelSolutions = wrapArray(_this.checkoutData.reopen.travel.travelSolutions);
            var changedTravelSolution = travelSolutions.find(function (travelSolution) {
                return travelSolution; // TODO: check not deleted or other check for take only the changed
            });
            var oldDate = {
                departure: changedTravelSolution.departure,
                arrive: changedTravelSolution.arrival
            };
            var newDate = {
                departure: _this.checkoutData.selectedSolution.departure,
                arrive: _this.checkoutData.selectedSolution.arrival
            };
            var remapDateTime = [oldDate, newDate];
            var data = {
                travel: _this.checkoutData.reopen.travel,
                travellers: _this.checkoutData.selectedSolution
                    .travellers,
                postSaleData: {
                    postSaleRecord: _this.checkoutData.selectedSolution.validatedPostSaleRecord,
                    reusedPaymentRecords: _this.checkoutData.selectedSolution.reusedPaymentRecords,
                    reversedPaymentRecords: _this.checkoutData.selectedSolution.reversedPaymentRecords,
                    missingAmount: _this.checkoutData.selectedSolution.missingAmount,
                    additionalCharges: _this.checkoutData.selectedSolution.additionalCharges
                },
                newTravelSolution: _this.checkoutData.selectedSolution.original,
                type: 'edit-date-time',
                fromDateTo: remapDateTime
            };
            _this.trnService.updateAndConfirm(data)
                .pipe(take(1), finalize(function () {
                _this.loading = false;
            }))
                .subscribe({
                next: function (s) {
                    _this.trnService.loading.next(false);
                    /*this.matDialog.closeAll();*/
                    _this.complete.emit();
                    _this._snackBar.open('Cambio data e ora effettuato', '', {
                        duration: 5000
                    });
                }
            });
        });
    };
    TrenitaliaDialogComponent.prototype.changeTravel = function () {
        var _this = this;
        this.loading = true;
        var oldOffer = _.get(this.checkoutData.reopen, 'travel.travelSolutions.offeredServices');
        if (this.seats) {
            this.setSeats();
        }
        if (this.checkoutData.direction === 'RETURN') {
            this.checkoutData.forward.solution['returnTravelSolution'] = this.checkoutData.selectedSolution.original;
            this.checkoutData.selectedSolution.original = this.checkoutData.forward.solution;
        }
        var data = {
            travel: this.checkoutData.reopen.travel,
            selectedPostSaleDetails: _.get(this.dataDialog.data.postAction, 'postSaleDetails'),
            oldOfferedServices: oldOffer,
            selectedOfferedServices: this.checkoutData.dataAv.selectedOffer,
            newTravelSolutionSelected: this.checkoutData.selectedSolution.original,
        };
        this.trnService.evaluateTravelPostSale(data)
            .subscribe(function (res) {
            _this.loading = false;
            if (res.validatedPostSaleRecord.allowed == 'false') {
                _this._snackBar.open(res.validatedPostSaleRecord.messages, '', {
                    duration: 5000
                });
            }
            else {
                _this.matDialog
                    .open(TrenitaliaPostSaleChangeConfirmDialog, {
                    minWidth: '300px',
                    panelClass: 'create-dialog-container',
                    data: {
                        postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
                            missingAmount: res.missingAmount,
                            reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
                            reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
                            additionalCharges: res.additionalCharges,
                            postSaleFee: res.fee
                        }),
                        type: 'travel-change',
                        title: 'Cambio Biglietto',
                        message: 'Vuoi cambiare il viaggio?',
                        icon: 'train'
                    }
                })
                    .afterClosed()
                    .subscribe(function (result) {
                    if (!result)
                        return;
                    var confirmData = {
                        travel: res.travel,
                        type: 'travel-change',
                        postSaleData: {
                            postSaleRecord: res.validatedPostSaleRecord,
                            missingAmount: res.missingAmount,
                            reversedPaymentRecords: res.reversedPaymentRecords,
                            reusedPaymentRecords: res.reusedPaymentRecords,
                            additionalCharges: res.additionalCharges
                        }
                    };
                    if (_this.seatsSel && _this.checkoutData.dataAv.seatMap) {
                        confirmData.seatMap = _this.checkoutData.dataAv.seatMap;
                    }
                    _this.loading = true;
                    _this.trnService
                        .updateAndConfirm(confirmData)
                        .subscribe({
                        next: function (s) {
                            _this.loading = false;
                            _this.trnService.loading.next(false);
                            /*this.matDialog.closeAll();*/
                            _this.complete.emit();
                            _this._snackBar.open('Cambio viaggio effettuato', '', {
                                duration: 5000
                            });
                        },
                        error: function (error) {
                            _this.loading = false;
                            _this._snackBar.open('Errore nella procedura del cambio!', '', {
                                duration: 5000
                            });
                        },
                        complete: function () {
                            _this.loading = false;
                        }
                    });
                });
            }
        }, function (error) {
            _this.loading = false;
            _this._snackBar.open('Errore nella procedura del cambio!', '', {
                duration: 5000
            });
        });
    };
    TrenitaliaDialogComponent.prototype.mapTravellers = function () {
        var _this = this;
        if (this.checkoutData.mode == 'carnet')
            return this.checkoutData.travellers;
        var travellers = this.checkoutData.travellers || this.checkoutData.editPassengers;
        if (!travellers) {
            console.warn('no travellers found', this.checkoutData);
            return;
        }
        return travellers.map(function (tr) {
            var data = _this.checkoutData.finalTravellers.find(function (final) {
                return final.travellerXmlId == tr.xmlId;
            });
            var newTr = Object.assign({}, tr);
            newTr.name = _.get(data, 'dataPass.pass_first_name', tr.name);
            newTr.surname = _.get(data, 'dataPass.pass_last_name', tr.surname);
            newTr.email = _.get(data, 'dataPass.pass_email', tr.email);
            newTr.cell = _.get(data, 'dataPass.pass_phone', tr.cell || tr.phoneNumber);
            newTr.cartaFreccia = _.get(data, 'dataPass.pass_cf', tr.cartaFreccia);
            newTr.contact_consent = _.get(data, 'dataPass.contact_consent', tr.contact_consent);
            // reverse value, if true is false and viceversa for trenitalia
            newTr.contact_consent = !Boolean(newTr.contact_consent);
            if (_.get(data, 'dataPass.pass_data_n')) {
                var birthDate = moment(_.get(data, 'dataPass.pass_data_n'));
                if (birthDate.isValid()) {
                    newTr.birthDate = birthDate.format('DD/MM/YYYY');
                    //newTr.age = Math.abs(moment().diff(birthDate), 'years'));
                }
            }
            return newTr;
        }).filter(function (traveller) {
            if (['edit-travellers', 'edit'].includes(_this.checkoutData.mode))
                return true;
            // TODO: check if traveller data is changed
            return true;
        });
    };
    TrenitaliaDialogComponent.prototype.mapBillingData = function () {
        var billingData = this.checkoutData.billingFormData;
        if (!billingData) {
            console.warn('no billing data found', this.checkoutData);
            return;
        }
        var newBillingData = null;
        if (billingData.person_type === 'LEGAL') {
            newBillingData = {
                name: _.get(billingData, 'profile_name'),
                personType: _.get(billingData, 'person_type'),
                vatNumber: _.get(billingData, 'p_iva'),
                businessName: _.get(billingData, 'business_name'),
                recipientCode: _.get(billingData, 'recipient_code'),
                certifiedMail: _.get(billingData, 'pec'),
                address: {
                    country: _.get(billingData, 'country'),
                    province: _.get(billingData, 'province'),
                    city: _.get(billingData, 'city'),
                    address: _.get(billingData, 'address'),
                    postalCode: _.get(billingData, 'cap'),
                }
            };
        }
        else if (billingData.person_type === 'INDIVIDUAL') {
            newBillingData = {
                //name: _.get(billingData, 'profile_name'),
                firstName: _.get(billingData, 'tc_name'),
                lastName: _.get(billingData, 'tc_surname'),
                personType: _.get(billingData, 'person_type'),
                fiscalCode: _.get(billingData, 'tc_tax_code'),
                recipientCode: _.get(billingData, 'tc_recipient_code'),
                certifiedMail: _.get(billingData, 'tc_pec'),
                address: {
                    country: _.get(billingData, 'tc_country'),
                    province: _.get(billingData, 'tc_province'),
                    city: _.get(billingData, 'tc_city'),
                    address: _.get(billingData, 'tc_address'),
                    postalCode: _.get(billingData, 'tc_cap'),
                }
            };
        }
        return newBillingData;
    };
    TrenitaliaDialogComponent.prototype.checkContinue = function () {
        if (this.step == 2) {
            if (_.get(this.checkoutData, 'selectedSolution') && _.get(this.checkoutData, 'dataAv.selectedOffer')) {
                this.trnService.continue.next(true);
            }
            else {
                this.trnService.continue.next(false);
            }
        }
        else if (this.step == 3) {
            if (_.get(this.checkoutData, 'seatSel') == _.get(this.checkoutData, 'seatsCount')) {
                this.trnService.continue.next(true);
            }
            else {
                this.trnService.continue.next(false);
            }
        }
    };
    TrenitaliaDialogComponent.prototype.checkConfirm = function () {
        if (this.checkoutData.mode == 'carnet') {
            if (this.seatsSel) {
                this.confirm = _.get(this.checkoutData, 'selectedSolution') &&
                    _.get(this.checkoutData.carnetData, 'selectedService') &&
                    (_.get(this.checkoutData, 'seatSel') === _.get(this.checkoutData, 'seatsCount'));
            }
            else {
                this.confirm = Boolean(_.get(this.checkoutData, 'selectedSolution') &&
                    _.get(this.checkoutData.carnetData, 'selectedService'));
            }
        }
        else {
            this.confirm =
                _.get(this.checkoutData, 'finalTravellers')
                    && this.checkoutData.finalTravellersValid
                    && (!this.checkoutData.invoiceRequested || this.checkoutData.billingFormValid);
        }
    };
    TrenitaliaDialogComponent.prototype.setSeats = function () {
        var _this = this;
        var seatMaps = this.checkoutData.seatMaps;
        this.checkoutData.dataAv.seatMap = [];
        this.checkoutData.selectedSolution.trains.forEach(function (t) {
            t.seats = [];
            wrapArray(_this.checkoutData.travellers).forEach(function (pass, k) {
                var e_12, _a;
                if (_.get(_this.checkoutData.seatMaps, [t.id, 'travellers', pass.xmlId, 'seat'])) {
                    var seatData = _.get(_this.checkoutData.seatMaps, [t.id, 'travellers', pass.xmlId, 'seat']);
                    var offer = void 0;
                    try {
                        for (var _b = tslib_1.__values(_this.checkoutData.dataAv.selectedOffer), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var offered = _c.value;
                            var off = _.get(_this.checkoutData, ['selectedSolution', 'offeredServices', offered.id]);
                            if (off && off.nodeId == t.id && off.travellerXmlId == pass.xmlId) {
                                offer = off;
                            }
                        }
                    }
                    catch (e_12_1) { e_12 = { error: e_12_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_12) throw e_12.error; }
                    }
                    _this.checkoutData.dataAv.seatMap.push({
                        seatNum: seatData.seatNum,
                        wagon: seatData.wagon,
                        airCraftNumber: seatData.seatNumToShow,
                        isFree: offer.is_seat_selection_free,
                        offeredServicesId: offer.id
                    });
                    t.seats.push({
                        type: t.type,
                        seat: seatData.seatNumToShow
                    });
                }
            });
        });
    };
    TrenitaliaDialogComponent.prototype.changeStepTrain = function (num) {
        this.trnService.trainSeatStep.next(this.trainSeatStep + num);
    };
    return TrenitaliaDialogComponent;
}());
export { TrenitaliaDialogComponent };
