import * as tslib_1 from "tslib";
import { ValueTypes, FieldViews } from 'app/interfaces';
import { fieldsMap } from './fields';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import sendVoucherAction from './send-voucher.action';
import { DatasetListDialogComponent } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { hideIfCustomDisableFields, showIfCustomAddFields, } from "../../commons/shared-field-configs/custom_field.field";
import * as _ from 'lodash';
var onClick = function (event, record, dataContainerService, datasetNavigatorService) {
    return datasetNavigatorService.openDialog(TextDialogComponent, {
        title: 'Download file',
        negativeText: 'Annulla',
        contentMessage: (function () {
            var e_1, _a, e_2, _b;
            var html = '<ul>';
            if (record.download_ticket_file) {
                try {
                    for (var _c = tslib_1.__values(record.download_ticket_file), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var file = _d.value;
                        if (file.participants_pdf_url) {
                            html += "<li>" + file.name + "<ol>";
                            try {
                                for (var _e = (e_2 = void 0, tslib_1.__values(file.participants_pdf_url)), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    var participants_pdf_url = _f.value;
                                    html += "<li>Partecipante <a href=\"" + participants_pdf_url.url + "\">Scarica: " + file.name + "</a>\n\t\t\t\t\t\t\t<span>(Numero di download: " + participants_pdf_url.download_count + ")</span></li>";
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            html += '</ol></li>';
                        }
                        else if (file.trip_booking_resource_id) {
                            // OLD VERSION (DEPRECATED)
                            html += "<li>\n\t\t\t\t\t\t<a href=\"" + getBaseServerAddressRoot() + "/ajax/download/ticket/" + file.trip_booking_resource_id + "\">Scarica: " + file.name + "</a>\n\t\t\t\t\t\t<span>(Numero di download: " + file.download_count + ")</span>\n\t\t\t\t\t\t</li>";
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            html += '</ul>';
            return html;
        })()
    }).afterClosed();
};
var ɵ0 = onClick;
function getTicketDownloadTotalCount(record) {
    var e_3, _a, e_4, _b;
    var countDownload = 0;
    if (!record || !record.download_ticket_file)
        return 0;
    try {
        for (var _c = tslib_1.__values(record.download_ticket_file), _d = _c.next(); !_d.done; _d = _c.next()) {
            var file = _d.value;
            if (file.participants_pdf_url) {
                try {
                    for (var _e = (e_4 = void 0, tslib_1.__values(file.participants_pdf_url)), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var participants_pdf_url = _f.value;
                        if (!participants_pdf_url.download_count)
                            continue;
                        countDownload += participants_pdf_url.download_count;
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
            else {
                // OLD VERSION (DEPRECATED)
                if (!file.download_count)
                    continue;
                countDownload += file.download_count;
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return countDownload;
}
export var ClassForegroundColorByBookingStatus = {
    draft: '',
    unconfirmed: '',
    confirmed: 'green-fg',
    optional: 'orange-fg',
    expired: 'warn-fg',
    canceled: 'warn-fg'
};
export function getHTMLBookingStatus(bookingStatus) {
    if (bookingStatus === 'draft')
        return 'Preventivo';
    if (bookingStatus === 'confirmed') {
        var html = '<div class="booking_status" style="display:inline-flex;flex-direction:row;align-items: center;">';
        // html += '<mat-icon role="img" class="primary-fg mr-4 mat-icon material-icons mat-icon-no-color">check</mat-icon>';
        html += '<span class="green-fg">Confermata</span>';
        html += '</div>';
        return html;
    }
    if (bookingStatus === 'optional')
        return '<span class="orange-fg">Opzionale (in scadenza)</span>';
    if (bookingStatus === 'expired')
        return '<span class="warn-fg">Scaduta</span>';
    if (bookingStatus === 'unconfirmed')
        return 'Non Confermata';
    if (bookingStatus === 'canceled')
        return '<span class="warn-fg">Annullata</span>';
    return '';
}
export function getHtmlBookingWithCodeAndStatus(bookingCode, bookingStatus) {
    var label = bookingCode || '';
    var foregroundColor = '';
    var status = getHTMLBookingStatus(bookingStatus);
    return '<span class="' + foregroundColor + '">' + label + '<br>' + status + '</span>';
}
export var LIST_COLUMNS = [
    {
        title: 'Codice/Stato',
        key: 'status_column',
        /*onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
            datasetNavigatorService.router.navigate(['/edit-booking/' + record.id]);
        },*/
        fieldDefinition: {
            name: 'Status',
            key: 'status',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                var isAdminStyle = record.allowed_actions.includes('view_logs') ? 'cursor: pointer;' : '';
                var htmlResult = getHtmlBookingWithCodeAndStatus(record.trip_booking_booking_code, record.trip_booking_package_booking_status);
                if (record.pratica_code) {
                    htmlResult += '<br><small class="secondary-text">Pratica: ' + record.pratica_code + '</small>';
                }
                if (record.delivery_status) {
                    if (record.delivery_status === 'consegnato') {
                        htmlResult += '<br><span class="green-fg">Consegnato</span>';
                    }
                    if (record.delivery_status === 'in consegna') {
                        htmlResult += '<br><span class="orange-fg">In Consegna</span>';
                    }
                    if (record.delivery_status === 'standby') {
                        htmlResult += '<br><span class="orange-fg">Stand by</span>';
                    }
                    if (record.delivery_status === 'scaduto') {
                        htmlResult += '<br><span class="red-fg">Scaduto</span>';
                    }
                }
                else if (record.trip_package_target == 'ticket') {
                    htmlResult += '<br><span class="orange-fg">Stand by</span>';
                }
                return '<div style="' + isAdminStyle + '">' + htmlResult + '</div>';
            },
        },
        onClick: function (record, datasetACS, datasetNavigatorService) {
            if (record.allowed_actions.includes('view_logs')) {
                datasetNavigatorService.openDialog(DatasetListDialogComponent, {
                    title: 'Lista logs prenotazione',
                    datasetCode: 'trip_booking_logs',
                    parentDatasetACS: datasetACS,
                    domainId: _.get(record, 'domain.id'),
                    filters: {
                        trip_booking_id: record.trip_booking_id
                    }
                });
            }
        }
    },
    /*{
        title: 'Codice',
        key: 'booking_code'
    },*/
    {
        title: 'Pacchetto',
        key: 'trip_package_description',
        fieldDefinition: {
            name: 'Pacchetto',
            key: 'trip_package_description',
            valueType: ValueTypes.STRING
        }
    },
    {
        title: 'Data Prenotazione',
        key: 'booking_date',
    },
    {
        title: 'Dettaglio partenza',
        key: 'start_day',
        fieldDefinition: {
            name: 'Data Partenza',
            key: 'start_day',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                var label = '';
                if (!record)
                    return '';
                if (record.actual_trip_meeting_point_list_description) {
                    label = record.actual_trip_meeting_point_list_description;
                }
                if (record.actual_trip_start_time) {
                    label += ' (' + record.actual_trip_start_time.substring(0, 5) + ')';
                }
                if (record.actual_trip_start_day) {
                    var d = new Date(record.actual_trip_start_day);
                    if (!isNaN(d.getTime())) {
                        label += '<br><span>' + d.toLocaleDateString() + '</span>';
                    }
                }
                if (record.pickup_meeting_point_name && record.delivery_status && record.delivery_status != 'standby') { // show only for delivery
                    label += '<br><span>Punto di ritiro: ' + record.pickup_meeting_point_name + '</span>';
                }
                return label;
            }
        }
    },
    {
        title: '# PAX/Passeggero di riferimento',
        key: 'main_participant_name',
        fieldDefinition: {
            name: 'Passeggero di riferimento',
            key: 'main_participant_name',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                if (!record)
                    return '';
                var label = '# PAX: ' + (record.trip_booking_participant_counters && record.trip_booking_participant_counters.total);
                if (record.trip_booking_entity_name) {
                    label += '<br>';
                    label = "<div style='font-weight: 600; max-width: 250px; overflow: hidden; text-overflow: ellipsis; display: block;' alt='" + record.trip_booking_entity_customer_name + "'>";
                    label += record.trip_booking_entity_name;
                    label += "</div>";
                }
                return label;
            }
        }
    },
    {
        title: 'Presenze',
        key: 'participant_presences',
        fieldDefinition: {
            name: 'Presenze',
            key: 'participant_presences',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayTooltip: function (record) {
                var tooltip = '';
                if (record.trip_booking_package_target != 'trip_packages')
                    return '';
                if (!record.trip_booking_participant_counters)
                    return '';
                if (record.trip_booking_participant_counters.ADT)
                    tooltip += record.trip_booking_participant_counters.ADT + " ADT   ";
                if (record.trip_booking_participant_counters.CHD)
                    tooltip += record.trip_booking_participant_counters.CHD + " CHD   ";
                if (record.trip_booking_participant_counters.INF)
                    tooltip += record.trip_booking_participant_counters.INF + " INF   ";
                if (record.trip_booking_participant_counters.SNR)
                    tooltip += record.trip_booking_participant_counters.SNR + " SNR";
                return tooltip;
            },
            getDisplayValue: function (record) {
                if (!record || !record.trip_booking_participant_counters)
                    return '';
                var styleColor = '#4caf50';
                if (typeof (record.actual_trip_participant_presences) == 'number' && record.actual_trip_participant_presences != record.trip_booking_participant_counters.total) {
                    styleColor = '#f44336';
                }
                var label = '<div style="cursor: pointer; display: flex; justify-content: space-between; align-items: center; color: ' + styleColor + ';">';
                label += '<span class="material-icons" style="padding-right: 5px; font-size: 18px; border-left: 3px solid ' + styleColor + '; padding-left: 5px; border-radius: 2px;">groups</span>';
                if (record.trip_booking_package_target == 'trip_packages')
                    label += '<span style="font-weight: bold;">' + record.actual_trip_participant_presences + '/' + record.trip_booking_participant_counters.total + '</span>';
                else
                    label += '<span style="font-weight: bold;">' + record.trip_booking_participant_counters.total + '</span>';
                label += '</div>';
                return label;
            }
        }
    },
    {
        title: 'Canale/Utente',
        key: 'origin_channel_code',
        fieldDefinition: {
            name: 'Canale',
            key: 'origin_channel_code',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                if (!record)
                    return '';
                var value = record.trip_booking_origin_channel_code;
                var label = 'Back Office';
                if (value) {
                    if (['direct_online', 'zvsite'].includes(value)) {
                        label = 'Direct Online';
                    }
                    else if (['api'].includes(value)) {
                        label = 'API';
                    }
                    else if (['direct_offline'].includes(value)) {
                        label = 'Direct Offline';
                    }
                    else if (['ticketing_mobile'].includes(value)) {
                        label = 'Mobile';
                    }
                    else if (['ticketing_totem'].includes(value)) {
                        label = 'Totem';
                    }
                }
                // TODO: add this data to result
                if (record.trip_booking_created_by_name) {
                    label += '<br>' + record.trip_booking_created_by_name;
                }
                return label;
            }
        }
    },
    {
        title: 'Cliente',
        key: 'customer_name',
        fieldDefinition: {
            key: 'customer_name',
            name: 'Nome Cliente',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                if (!record || !record.trip_booking_entity_customer_name)
                    return '';
                var entity_customer_name = "<div style='font-weight: 600; max-width: 250px; overflow: hidden; text-overflow: ellipsis; display: block;' alt='" + record.trip_booking_entity_customer_name + "'>";
                entity_customer_name += record.trip_booking_entity_customer_name;
                entity_customer_name += "</div>";
                return entity_customer_name;
            },
        },
        showIf: function (dataContainerService) {
            if (!dataContainerService)
                return false;
            return !hideIfCustomDisableFields('entity_customer_id', 'report', dataContainerService);
        }
    },
    {
        title: 'Agenzia',
        key: 'branch_code',
        fieldDefinition: {
            key: 'branch_code',
            name: 'Agenzia',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.HTML,
            getDisplayValue: function (record) {
                if (!record || !record.trip_booking_branch_code)
                    return '';
                return record.trip_booking_branch_code;
            }
        },
        showIf: function (dataContainerService) {
            if (!dataContainerService)
                return false;
            return showIfCustomAddFields('branch_code', 'report', dataContainerService);
        }
    },
    {
        title: 'Prezzo Lordo',
        key: 'total_amount',
        fieldDefinition: fieldsMap.get('total_amount')
    },
    {
        title: 'Prezzo Netto',
        key: 'total_amount_net',
        fieldDefinition: fieldsMap.get('total_amount_net')
    },
    {
        title: 'Numero Voucher Operatore',
        key: 'origin_channel_voucher_code',
        showIf: function (dataContainerService) {
            if (!dataContainerService)
                return false;
            return !hideIfCustomDisableFields('origin_channel_voucher_code', 'report', dataContainerService);
        }
    },
    {
        title: 'Note',
        key: 'note',
        fieldView: FieldViews.DIALOG_TEXT,
        fieldDefinition: {
            name: 'Note',
            key: 'trip_booking_note',
            valueType: ValueTypes.STRING,
            fieldView: FieldViews.DIALOG_TEXT,
            prefixIcon: 'note'
        }
    },
    {
        title: 'Voucher',
        key: 'id',
        fieldView: FieldViews.MIXED_COLUMN,
        getCellConfigs: function (record) {
            if (!record)
                return [];
            if (['trip_packages', 'autoservizio'].includes(record.trip_booking_package_target)) {
                return [
                    {
                        title: 'Voucher',
                        key: 'id',
                        menu: {
                            text: 'Voucher',
                            items: [
                                {
                                    icon: 'get_app',
                                    text: 'Scarica',
                                    makeLink: function (record) {
                                        return getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id;
                                    }
                                },
                                {
                                    icon: 'email',
                                    text: 'Invia Email',
                                    showIf: function (record, dataContainerService) {
                                        return record && record.trip_booking_package_booking_status === 'confirmed';
                                    },
                                    onClick: function (record, datasetACS, datasetNavigatorService) {
                                        sendVoucherAction(record, datasetACS, datasetNavigatorService)
                                            .catch(function (e) { });
                                    }
                                }
                            ]
                        },
                        fieldView: FieldViews.MENU,
                        fieldDefinition: {
                            name: 'voucher',
                            key: 'id',
                            valueType: ValueTypes.STRING,
                            getDisplayValue: function (record) {
                                return '<a href="' + getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id + '" target="_blank">Voucher</a>';
                            },
                            fieldView: FieldViews.HTML,
                        },
                    },
                ];
            }
            else if (['milano_card', 'milano_card_offline'].includes(record.trip_booking_package_target)) {
                var allowedDownloadDeliveryStatus = ['scaduto', 'in consegna', 'consegnato'];
                if (record.delivery_status && allowedDownloadDeliveryStatus.includes(record.delivery_status) && record.download_ticket_file) {
                    var ticketDownloadTotalCount = getTicketDownloadTotalCount(record);
                    return [
                        {
                            title: 'Tickets',
                            key: 'id',
                            fieldView: FieldViews.BUTTON,
                            actionButton: {
                                buttonColor: ticketDownloadTotalCount ? 'primary' : 'warn',
                                buttonIcon: 'download',
                                buttonText: 'Biglietti',
                                tooltip: ticketDownloadTotalCount ? 'Risultano biglietti scaricati' : 'Nessun biglietto scaricato',
                                onClick: onClick,
                            },
                        }
                    ];
                }
            }
            else if (record.trip_booking_package_target == 'product' && record.trip_booking_package_booking_status === 'confirmed') {
                return [
                    {
                        title: 'Azioni',
                        key: 'id',
                        menu: {
                            text: 'Azioni',
                            items: [
                                {
                                    icon: 'email',
                                    text: 'Re-invia Email',
                                    onClick: function (record, datasetACS, datasetNavigatorService) {
                                        sendVoucherAction(record, datasetACS, datasetNavigatorService)
                                            .catch(function (e) { });
                                    }
                                }
                            ]
                        },
                        fieldView: FieldViews.MENU
                    },
                ];
            }
            else if (record.trip_booking_package_target == 'trenitalia' && record.trip_booking_package_booking_status === 'confirmed') {
                var ticketDownloadTotalCount = getTicketDownloadTotalCount(record);
                return [
                    {
                        title: 'Voucher',
                        key: 'id',
                        menu: {
                            text: 'Voucher',
                            items: [
                                {
                                    icon: 'get_app',
                                    text: 'Scarica',
                                    makeLink: function (record) {
                                        return getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id;
                                    }
                                },
                            ]
                        },
                        fieldView: FieldViews.MENU,
                        fieldDefinition: {
                            name: 'voucher',
                            key: 'id',
                            valueType: ValueTypes.STRING,
                            getDisplayValue: function (record) {
                                return '<a href="' + getBaseServerAddressRoot() + '/voucher/download/by/id/' + record.trip_booking_id + '" target="_blank">Voucher</a>';
                            },
                            fieldView: FieldViews.HTML,
                        },
                    },
                ];
            }
            else {
                return [];
            }
        }
    }
];
export { ɵ0 };
