import { Component, ViewContainerRef } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DataFilterComponent } from '../data-filter.component';

@Component({
	selector   : 'style-01-filter-component',
	templateUrl: './data-filter.component.html',
	styleUrls  : ['./data-filter.component.scss']
})
export class Style01FilterComponent extends DataFilterComponent{

	constructor(
		public matDialog: MatDialog,
		public datasetNavigatorService: DatasetNavigatorService,
		protected viewContainerRef: ViewContainerRef
	){
		super(matDialog, datasetNavigatorService, viewContainerRef);
	}
}
