/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "../../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "./cache.component";
import * as i11 from "../../../../../../services/app.service";
import * as i12 from "@angular/common/http";
var styles_CacheUtilityComponent = [];
var RenderType_CacheUtilityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CacheUtilityComponent, data: {} });
export { RenderType_CacheUtilityComponent as RenderType_CacheUtilityComponent };
function View_CacheUtilityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CacheUtilityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "mat-card", [["class", "p-24 mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i0.ɵdid(1, 49152, null, 0, i5.MatCard, [[2, i3.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 4, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i4.View_MatCardHeader_0, i4.RenderType_MatCardHeader)), i0.ɵdid(3, 49152, null, 0, i5.MatCardHeader, [], null, null), (_l()(), i0.ɵeld(4, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i0.ɵdid(5, 16384, null, 0, i5.MatCardTitle, [], null, null), (_l()(), i0.ɵted(-1, null, ["Cache"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CacheUtilityComponent_1)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, 0, 7, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i5.MatCardActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearCache() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i0.ɵdid(12, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Pulisci cache"])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.optimizeCache() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i8.MatButton, [i0.ElementRef, i9.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ottimizza cache"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 8, 0, currVal_1); var currVal_5 = _co.loading; var currVal_6 = "accent"; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_9 = _co.loading; var currVal_10 = "accent"; _ck(_v, 15, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_2 = (i0.ɵnov(_v, 10).align === "end"); _ck(_v, 9, 0, currVal_2); var currVal_3 = (i0.ɵnov(_v, 12).disabled || null); var currVal_4 = (i0.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_7 = (i0.ɵnov(_v, 15).disabled || null); var currVal_8 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_7, currVal_8); }); }
export function View_CacheUtilityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cache-utility", [], null, null, null, View_CacheUtilityComponent_0, RenderType_CacheUtilityComponent)), i0.ɵdid(1, 49152, null, 0, i10.CacheUtilityComponent, [i11.AppService, i12.HttpClient], null, null)], null, null); }
var CacheUtilityComponentNgFactory = i0.ɵccf("cache-utility", i10.CacheUtilityComponent, View_CacheUtilityComponent_Host_0, {}, {}, []);
export { CacheUtilityComponentNgFactory as CacheUtilityComponentNgFactory };
