import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { BaseDatasetActionDialog } from 'app/main/components/dataset/dialogs/base-action-dialog.component';
import { InvalidAllotmentsDialogComponent } from '../../invalid-allotments-dialog/dialog.component';
var DatasetCreateDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetCreateDialogComponent, _super);
    function DatasetCreateDialogComponent(datasetACS, appService, datasetService, dialogRef, data, matDialog) {
        var _this = _super.call(this, datasetACS, appService, datasetService, dialogRef, data) || this;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.matDialog = matDialog;
        _this.actionCode = 'create';
        return _this;
    }
    DatasetCreateDialogComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.data.formConfig) {
            this.formConfig = this.data.formConfig;
        }
        else {
            var actionConfig = this.datasetACS.actionConfig;
            this.formConfig = actionConfig.viewConfig.formConfig;
        }
        this.buildTitle();
    };
    DatasetCreateDialogComponent.prototype.buildTitle = function () {
        if (this.data.customTitleDialog) {
            this.dialogTitle = this.data.customTitleDialog;
        }
        else {
            if (this.formConfig && this.formConfig.title) {
                this.dialogTitle = this.formConfig.title;
            }
            else {
                this.dialogTitle = 'Nuovo ' + this.datasetACS.datasetConfig.singleTitle;
            }
        }
    };
    DatasetCreateDialogComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        this.loading = true;
        if (typeof (this.datasetACS.actionConfig.getCallParams) === 'function') {
            formData = this.datasetACS.actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        var headers = this.datasetACS.getContextualDomainHeaders();
        this.datasetService.post(this.datasetACS.getCreateRoute(this.data.customActionCode), formData, {
            headers: headers
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.loading = false;
                _this.appService.showSuccessMessage('Creato con successo');
                _this.dialogRef.close(response);
            },
            error: function (response) {
                _this.loading = false;
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            }
        });
    };
    return DatasetCreateDialogComponent;
}(BaseDatasetActionDialog));
export { DatasetCreateDialogComponent };
