/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./input.component";
import * as i9 from "../../../../../services/app.service";
import * as i10 from "@angular/material/dialog";
var styles_FileBlockInputComponent = [i0.styles];
var RenderType_FileBlockInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileBlockInputComponent, data: {} });
export { RenderType_FileBlockInputComponent as RenderType_FileBlockInputComponent };
function View_FileBlockInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_FileBlockInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 1, 0, currVal_0); }); }
export function View_FileBlockInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileBlockInputComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileBlockInputComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(7, 0, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.type === "image") && _co.src); _ck(_v, 4, 0, currVal_1); var currVal_4 = "accent"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.uploadLabel)); _ck(_v, 7, 0, currVal_5); }); }
export function View_FileBlockInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-block-input", [], null, null, null, View_FileBlockInputComponent_0, RenderType_FileBlockInputComponent)), i1.ɵdid(1, 770048, null, 0, i8.FileBlockInputComponent, [i9.AppService, i10.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileBlockInputComponentNgFactory = i1.ɵccf("file-block-input", i8.FileBlockInputComponent, View_FileBlockInputComponent_Host_0, { record: "record", documentFileKey: "documentFileKey", uploadRoute: "uploadRoute", title: "title", type: "type", uploadType: "uploadType", getUploadDataFun: "getUploadDataFun", uploadData: "uploadData", imageOptions: "imageOptions", svgEnablded: "svgEnablded", uploadLabel: "uploadLabel", datasetCode: "datasetCode", inputService: "inputService" }, { changed: "changed" }, []);
export { FileBlockInputComponentNgFactory as FileBlockInputComponentNgFactory };
