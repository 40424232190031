import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { TextDialogComponent, ITextDialogData } from 'app/main/dialogs/text-dialog/dialog.component';
import * as moment from 'moment';

export default async function action(data: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): Promise<any> {
    
    let deliverySendInfo;
    
    const loadingDialogRef = datasetNavigatorService.openDialog(LoadingDialogComponent, {
        title: 'Recupero dati in corso',
    });
    
    try{
        deliverySendInfo = await datasetNavigatorService.datasetService
        .post<any>('/dataset/trip_booking_packages/command/get_delivery_send_info', data).toPromise();
    }finally{
        loadingDialogRef.close();
    }

	let destinationEmail = null;
	let lastSentAt = null;
	if(deliverySendInfo){
		if(deliverySendInfo.entity && deliverySendInfo.entity.primary_email){
			destinationEmail = deliverySendInfo.entity.primary_email;
		}

        if(deliverySendInfo.last_email_sent && deliverySendInfo.last_email_sent.created_at){
            const lastSentDate = moment(deliverySendInfo.last_email_sent.created_at);
            if(lastSentDate.isValid()) lastSentAt = lastSentDate.format('DD/MM/YYYY hh:mm:ss');
        }
	}

	if(!destinationEmail){
		datasetNavigatorService.openDialog<ITextDialogData>(TextDialogComponent, {
			title: 'Attenzione',
			contentMessage: 'L\'intestatario non ha una email.'
		});
		// no valid email
		return 'no email';
	}

	let summaryText = '<p>Vuoi inviare il link di conferma del luogo e data di ritiro via email a: ' + destinationEmail + ' ?</p>';

	if(lastSentAt){
		summaryText += '<p>Ultimo invio il: ' + lastSentAt + '</p>';
	}

	const confirmResult = await datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
		title: 'Invio link di conferma',
		contentMessage: summaryText,
		positiveText: 'Si',
		negativeText: 'Annulla'
	}).afterClosed().toPromise();
	if(!confirmResult) return;
	const loadingDialogRef2 = datasetNavigatorService.openDialog(LoadingDialogComponent, {
		title: 'Invio in corso',
	});
	try{
		await datasetNavigatorService.datasetService
		.post<any>('/dataset/trip_booking_packages/command/send_confirm_place_date', data).toPromise();
	}finally{
		loadingDialogRef2.close();
	}
}