import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('name'),
		]
	}
];

const config: IFormConfig = {
	title: 'Crea Filiale',
	editTitle: 'Modifica Filiale',
	fields: formFields 
};

export default config;
