import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields.user';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	//fieldsMap.get('creation_mode'),
    fieldsMap.get('name'),
    fieldsMap.get('email'),
	fieldsMap.get('access_username'),
	fieldsMap.get('branch.id'),
	fieldsMap.get('password'),
	fieldsMap.get('password_confirm')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
