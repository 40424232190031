import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('display_name'),
		]
	},
	fieldsMap.get('status'),
	fieldsMap.get('parent_domain_id'),
];

const config: IFormConfig = {
	title: 'Crea Dominio',
	editTitle: 'Modifica Dominio',
	fields: formFields 
};

export default config;
