import {
	IFieldDefinition,
	IFormConfig,
	InputTypes,
	ValueTypes,
} from '../../../../../interfaces';
import { Validators } from '@angular/forms';

export default {
	fields: [
		{
			config: {
				flexLayout: 'row',
			},
			fields: [
				{
					key: 'start_station',
					name: 'Da',
					inputType: InputTypes.DATASET_SELECT,
					valueType: ValueTypes.STRING,
					formValidators: [Validators.required],
					customActionCode: '/dataset/trenitalia/command/stations',
					remoteOptions: {
						getLabel(option): any {
							if (!option) return '';
							return option.name;
						},
						getValue(option): any {
							if (!option) return;
							return option.service_id;
						},
					},
				},
				{
					key: 'end_station',
					name: 'A',
					inputType: InputTypes.DATASET_SELECT,
					valueType: ValueTypes.STRING,
					formValidators: [Validators.required],
					customActionCode: '/dataset/trenitalia/command/stations',
					remoteOptions: {
						getLabel(option): any {
							if (!option) return '';
							return option.name;
						},
						getValue(option): any {
							if (!option) return;
							return option.service_id;
						},
					},
				},
				{
					key: 'date',
					name: 'Data Partenza',
					inputType: InputTypes.DATETIME,
					valueType: ValueTypes.DATETIME,
				},
			],
		},
		{
			config: {
				flexLayout: 'row',
			},
			fields: [
				{
					key: 'adt_counter',
					name: 'Adulti',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER,
				},
				{
					key: 'chd_counter',
					name: 'Ragazzi',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER,
				},
			],
		},
	],
} as IFormConfig;
