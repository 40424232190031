import {IFieldContainer, IFieldDefinition, IFormConfig, InputTypes, ValueTypes} from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('trip_resource_id'),
	
];

const config: IFormConfig = {
	fields: formFields
};

export default config;
