<h1>Intestatario Carnet</h1>
<mat-card class="card-pass">
    <mat-card-content>
        <div fxLayout="row wrap" fxLayoutGap="3px">
            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("first_name")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["first_name"]'
                ></form-input-viewer>
            </div>

            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("last_name")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["last_name"]'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("data_n")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["data_n"]'
                ></form-input-viewer>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="3px">
            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("email")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["email"]'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("phone")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["phone"]'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [readOnly]=true
                    [formInputDefinition]='formFields.get("cartafreccia")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["cartafreccia"]'
                ></form-input-viewer>
            </div>
        </div>
        <ng-container>
            <!-- Billing Form -->
            <div fxLayout="row wrap" fxLayoutGap="3px">
                <form-input-viewer
                    [formInputDefinition]='formFields.get("request_invoice")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["request_invoice"]'
                ></form-input-viewer>
            </div>
            <trenitalia-billing-form *ngIf="requestInvoice"></trenitalia-billing-form>
        </ng-container>
    </mat-card-content>
</mat-card>
