<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field>
		<mat-label>{{inputService.computedTitle | translate}}</mat-label>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<input *ngIf='inputService.inputConfig.readOnly' [required]='inputService.inputConfig.required' [matTooltip]='selectedLabel' matInput readonly type='text' [value]='selectedLabel'/>
		<mat-select [required]='inputService.inputConfig.required' [matTooltip]='selectedLabel' [placeholder]="inputService.inputConfig.placeholder | translate" [formControlName]='inputService.formControlKey' (selectionChange)="selectionChange($event)" multiple>
			<mat-option class="action-option" disabled="disabled">
				<button mat-button color='accent' (click)="selectAll()">{{'Seleziona Tutti' | translate}}</button>
				<button mat-button color='accent' (click)="deselectAll()">{{'Deseleziona Tutti' | translate}}</button>
			</mat-option>
			<mat-divider></mat-divider>
			<mat-option *ngFor='let selectOption of selectOptions' [value]='selectOption.value'>
				<span>{{selectOption.label | titlecase}}</span>
			</mat-option>
		</mat-select>
		<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>