import {Component, forwardRef, InjectionToken, Injector, OnInit} from '@angular/core';
import {FormViewerService} from '../../../../../services/form-viewer.service';
import {DatasetActionContainerService} from '../../../../components/dataset/services/dataset-action-container.service';
import { fieldsMap as formFields } from './form.fields';
import {PageClosureContainerService} from '../../../page-closure-container.service';
import { MatDialogRef } from '@angular/material/dialog';
import {TrenitaliaService} from '../trenitalia.service';
import { dialogDataFactory, DialogDataKeyToken } from 'app/helpers';
import { BookingService } from '../../booking.service';


@Component({
  selector: 'app-trenitalia-auth',
  templateUrl: './trenitalia-auth.component.html',
  styleUrls: ['./trenitalia-auth.component.scss'],
    providers:[
        forwardRef(() => PageClosureContainerService),
        forwardRef(() => DatasetActionContainerService),
        FormViewerService,
        TrenitaliaService,
		{
			provide: DialogDataKeyToken, useValue: 'bookingService'
		},
		{
			provide: BookingService, 
			useFactory: dialogDataFactory,
			deps: [Injector, DialogDataKeyToken]
		}
	]
})
export class TrenitaliaAuthComponent implements OnInit {
    public formFields = formFields;
    public error;
    public loading= false;
  constructor(public datasetACS: DatasetActionContainerService,
              public formViewerService: FormViewerService,
              public dialogRef: MatDialogRef<TrenitaliaAuthComponent>,
              public trnService : TrenitaliaService) {
      this.formViewerService.init({
          errors: null,
          dataContainerService: this.datasetACS,
          fields: Array.from(formFields.values())
      });
  }

  ngOnInit() {

  }
  login(){
      this.loading = true
      const data = this.formViewerService.getFormData();
      this.trnService.login(data).subscribe( user =>{
          this.loading = false
          this.dialogRef.close(user)
      },error => {
          this.loading = false
          this.error={
              message:"Credenziali errate riprovare"
          }
      })
  }
    onCloseClick() {
        this.dialogRef.close(false);
    }

}
