import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('descrizione', {
	key: 'descrizione',
	name: 'Descrizione',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
});

fieldsMap.set('num_rate', {
	key: 'num_rate',
	name: 'Numero rate',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('direct_payment', {
	key: 'direct_payment',
	name: 'Pagamento diretto',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Si',
			value: 1
		},
		{
			label: 'No',
			value: 0
		}
	]
});

export {fieldsMap};
