import { Component, OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FieldViews, IFormConfig, ListViewItemTypes } from 'app/interfaces';
import requirePlafondData from 'app/main/actions/require-plafond.action';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { DatasetListDialogComponent, IDatasetListDialogData } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import editPlafondForm from 'app/configs/datasets/plafond/forms/edit.form';

@Component({
	selector   : 'domain-packages',
	templateUrl: './detail-view.component.html',
	styleUrls  : ['./detail-view.component.scss']
})
export class DomainPackagesComponent extends BaseViewComponent implements OnInit{

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService){
			super(datasetACS, datasetRS, datasetNavigatorService);
		}

	ngOnInit(): void {
		super.ngOnInit();
	}

	addPlafond(): void{
		requirePlafondData(null, this.datasetACS, this.datasetNavigatorService, null)
		.then(data => {
			if (!data) return;

			const loadingDialogRef = this.datasetNavigatorService.openDialog<IActionDialogData>(ActionDialogComponent, {
				title: 'Attendere durante il completamento della richiesta...',
				contentMessage: ''
			});

			data['domainPackageId'] = this.datasetRS.record.value.id;

			try{
				this.datasetNavigatorService.datasetService.post<any>('/dataset/domain_packages/command/set_plafond', data)
				.toPromise()
				.then(() => {
					this.datasetRS.reload()
					.subscribe(() => {
						loadingDialogRef.close();
					});
				}, (error) => {
					loadingDialogRef.close();
				});
			} catch(e){
				throw e;
			}
		}, (error) => {
			// ignore
		});
	}

	detailPlafond(plafond: any): void{
		this.datasetNavigatorService.openDialog<IDatasetListDialogData>(DatasetListDialogComponent, {
			title: 'Transizioni',
			datasetCode: 'plafond_drop_items',
			parentDatasetACS: this.datasetACS,
			parentDatasetRS: this.datasetRS,
			filters: {
				plafond_id: plafond.id
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Data',
						key: 'date'
					},
					{
						title: 'Importo',
						key: 'amount',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(record.amount);
						}
					},
					{
						title: 'Numero Partecipanti',
						key: 'participant_count'
					}
				]
			}
		});
	}

	editPlafond(): void{
		requirePlafondData(this.datasetRS.record.value.plafond, this.datasetACS, this.datasetNavigatorService, editPlafondForm)
		.then(data => {
			if (!data) return;

			data['plafond_id'] = this.datasetRS.record.value.plafond_id;

			const loadingDialogRef = this.datasetNavigatorService.openDialog<IActionDialogData>(ActionDialogComponent, {
				title: 'Attendere durante il completamento della richiesta...',
				contentMessage: ''
			});

			try{
				this.datasetNavigatorService.datasetService.post<any>('/dataset/domain_packages/command/update_plafond', data)
				.toPromise()
				.then(() => {
					this.datasetRS.reload()
					.subscribe(() => {
						loadingDialogRef.close();
					});
				}, (error) => {
					loadingDialogRef.close();
				});
			} catch(e){
				throw e;
			}
		}, (error) => {
			// ignore
		});
	}

	deletePlafond(): void{
		this.datasetNavigatorService.openDialog(ConfirmDialogComponent, {
			title: 'Vuoi rimuovere il plafond dalla richiesta?',
			contentMessage: 'Rimuovendo Il plafond, non verrà eliminato e potrai associarlo in un secondo momento.'
		})
		.afterClosed()
		.subscribe({
			next: confirmed => {
				if (!confirmed) return;

				const data = {
					recordId: this.datasetRS.record.value.id
				};

				const loadingDialogRef = this.datasetNavigatorService.openDialog<IActionDialogData>(ActionDialogComponent, {
					title: 'Attendere durante il completamento della richiesta...',
					contentMessage: ''
				});

				this.datasetNavigatorService.datasetService.post<any>('/dataset/domain_packages/command/unset_plafond', data)
				.toPromise()
				.then(() => {
					this.datasetRS.reload()
					.subscribe(() => {
						loadingDialogRef.close();
					});
				}, (error) => {
					loadingDialogRef.close();
				});
			},
			error: excep => {
				// ignore
			}
		});
	}
}
