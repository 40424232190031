<!--<ng-container [ngTemplateOutlet]="step.stepLabel.template"></ng-container>-->

<ul class="nav-section-items">
	<ng-container *ngFor='let step of _steps; let i = index;'>
		<h6 class="nav-section-title" 
			*ngIf='step.computedStep?.config.isTitle'
			[innerHtml]='step.computedStep?.title'
		></h6>

		<li *ngIf='!step.computedStep?.config.isTitle' 
			class="nav-item nav-section-title" 
			[ngClass]="{'active': selectedIndex === i, 'disabled': !step.computedStep.completed}"
			(click)="onClick(step, i)">
			{{step.computedStep.title}} 
			<span *ngIf='stepService.isCompleted(step.computedStep.config?.id)' class="material-icons">done</span>
		</li>
	</ng-container>
</ul>