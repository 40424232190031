import { IStepViewConfig } from 'app/interfaces';
import { TripResourceLanguageContentConfig } from '../trip_resource_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { TripServiceConfig } from '../trip_services/index';
import { PivotTripResourceTripCategoriesConfig } from '../pivot_trip_resources_trip_categories';
import { PivotTripResourcesTripDestinationsConfig } from '../pivot_trip_resources_trip_destinations';
import { PivotTripResourceTripResourcesConfig } from '../pivot_trip_resource_trip_resources';

export const config: IStepViewConfig = {
	title: 'Prodotto',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	showOverview: true,
	steps: [
		{
			isTitle: true,
			title: 'Informazioni Base'
		},
		{
			id: 'product-base-info-step',
			title: 'Informazioni prodotto',
			stepperTitle: 'Informazioni prodotto',
			component: 'product-base-info-step',
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			id: 'product-trip-categories',
			title: 'Categorie',
			stepperTitle: 'Categorie',
			component: 'product-trip-categories',
			options: {
				datasetConfig: PivotTripResourceTripCategoriesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			id: 'product-trip-destinations',
			title: 'Destinazioni',
			stepperTitle: 'Destinazioni',
			component: 'product-trip-destinations',
			options: {
				datasetConfig: PivotTripResourcesTripDestinationsConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			isTitle: true,
			title: 'Descrizione attività'
		},
		{
			id: 'product-i18n-form-step',
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'product-i18n-form-step',
			options: {
				fieldsMap: TripResourceLanguageContentConfig.fieldsMap,
				datasetCode: 'trip_resource_language_contents',
				parentForeignKey: 'trip_resource_id',
				parentValueKey: 'products.recordId'
			}
		},
		{
			id: 'product-media',
			title: 'Media',
			stepperTitle: 'Media',
			component: 'product-media',
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			isTitle: true,
			title: 'Configurazioni',
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return record.allotment_type !== 'free' || record.product_type !== 'esim-manet';
			}
		},
		{
			id: 'product-advanced-blocks-step',
			title: 'Avanzate',
			component: 'product-advanced-blocks-step',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return record.allotment_type !== 'free';
			},
			options: {
				blocks: [
					{
						type: 'calendar',
						datasetCode: 'trip_resource_allotments',
						title: 'Allotment',
						showIf(record: any): boolean{
							return record.allotment_type == 'shared_allotment';
						}
					},
					{
						type: 'form',
						datasetCode: 'trip_resources',
						title: 'Allotment',
						showIf(record: any): boolean{
							return record.allotment_type == 'free' || record.allotment_type == 'fixed';
						}
					},
				]
			}
		},
		{
			id: 'product-bundle',
			title: 'Prodotti stock',
			stepperTitle: 'Prodotti stock',
			component: 'product-bundle',
			options: {
				datasetConfig: PivotTripResourceTripResourcesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return record.product_type === 'bundle_stock';
			}
		},
		{
			id: 'prices',
			title: 'Prezzi',
			component: 'common-blocks-step',
			options: {
				stepService: true,
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_services',
						updateParent: true,
						title: TripServiceConfig.title
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return record.product_type !== 'esim-manet';
			}
		},
	]
};
