<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>Prenotazione</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetRS.loading.value || datasetACS.loading.value'></mat-progress-bar>
	<div *ngIf='datasetRS.record.value' class='mb-24'>
		<p>{{'Codice' | translate}}: {{record.booking_code}}</p>
		<p>{{'Data Prenotazione' | translate}}: {{record.booking_date | date}}</p>
		<p class='pax-numb'>#Pax: {{record.participant_count}}</p>

		<div class='mat-elevation-z0' fxLayout='column' *ngFor='let tripBookingPackage of record.packages;trackBy: idTrackBy;'>
			<mat-toolbar class='trip-booking-package-header primary'>
				<mat-toolbar-row>
					<span *ngIf='tripBookingPackage.actual_trip.type === "going"'>
						{{'Andata' | translate}}:
					</span>
					<span *ngIf='tripBookingPackage.actual_trip.type === "return"'>
						{{'Ritorno' | translate}}:
					</span>
					<span class='ml-4'>
						<span>{{tripBookingPackage.trip_package.description}}</span>
					</span>
					<span fxFlex></span>
					<strong>{{tripBookingPackage.total_amount | currency:'EUR' }}</strong>
				</mat-toolbar-row>
			</mat-toolbar>
			<div class='date-info-trip'>
				<p>
					<mat-icon>departure_board</mat-icon>
					<span class='ml-4'>Partenza: {{tripBookingPackage.actual_trip.start_day | date}} <ng-container *ngIf='tripBookingPackage.actual_trip.start_time'>{{tripBookingPackage.actual_trip.start_time.slice(0, 5)}}</ng-container></span>
				</p>
			</div>
			<ng-container *ngIf='tripBookingPackage.trip_booking_resources && tripBookingPackage.trip_booking_resources.length > 0'>
				<mat-toolbar class='risorse-header'>
					<mat-icon>directions_bus</mat-icon> {{'Risorse' | translate}}
				</mat-toolbar>
				<div class='trip-booking-resources-list'>
					<ng-container *ngFor='let tripBookingResource of tripBookingPackage.trip_booking_resources;trackBy: idTrackBy'>
						<mat-toolbar class='trip-booking-resource-header' *ngIf='tripBookingResource && (tripBookingResource.activated || !tripBookingResource.optional)'>
							<mat-toolbar-row>
								<span class='ml-4'>
									<span>{{tripBookingResource.trip_resource.description}}</span>
									<span class='secondary-text mat-caption ml-4 included' *ngIf='!tripBookingResource.optional'>Inclusa</span>
									<span class='secondary-text mat-caption ml-4 optional' *ngIf='tripBookingResource.optional'>Opzionale</span>
								</span>
								<span fxFlex></span>
								<strong *ngIf='tripBookingResource.optional'>{{tripBookingResource.total_amount | currency:'EUR' }}</strong>
							</mat-toolbar-row>
						</mat-toolbar>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end' class='booking-dialog-footer'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
