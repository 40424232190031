import {InputTypes, ValueTypes, IFieldDefinition} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione Lista Punto di ritrovo',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		readOnly: true
	}
});

fieldsMap.set('time', {
	key: 'time',
	name: 'Orario',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		if(!record || !record.time) return '';
		if(typeof(record.time) === 'string') return record.time.substring(0, 5); 
		return record.time;
	},
	inputConfig: {
		type: InputTypes.TEXT,
		readOnly: true
	}
});

fieldsMap.set('to_ref', {
	key: 'to_ref',
	name: 'Tour ID',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT
	}
});

export {fieldsMap};
