import * as moment from 'moment';

export function convertToUTCDay(date?: Date): any{
	if(!date) date = new Date();
	if(date instanceof Date){
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();

		let text = year + '-';
		if(month < 10) text += '0';
		text += month + '-';
		if(day < 10) text += '0';
		text += day;

		return moment.utc(text);
	}
	return date;
}

export function forEachDay(from: any, to: any, callback: (_: any) => void, format?: string): void{
	const fromDate = moment.utc(from);
	const toDate = moment.utc(to);
	while(fromDate <= toDate){
		let day: any = fromDate;
		if(format && typeof(format) === 'string') day = day.format(format);
		callback(day);
		fromDate.add(1, 'day');
	}
}
