<ng-container *ngIf='record'>
	<ng-container [ngSwitch]='layout'>
		<ng-container *ngSwitchCase='"horizontal"'>
			<mat-horizontal-stepper #stepper labelPosition="bottom" (selectionChange)='stepChange.emit($event)'>
				<mat-step [completed]='record' [state]="'number'">
					<ng-template matStepLabel>
						{{'Partenze' | translate}}
					</ng-template>
				</mat-step>
				<mat-step [completed]='record' [state]="'number'">
					<ng-template matStepLabel>
						{{'Informazioni Base' | translate}}
					</ng-template>
				</mat-step>
				<mat-step [completed]='record' [state]="'number'">
					<ng-template matStepLabel>
						{{'Partecipanti' | translate}}
					</ng-template>
				</mat-step>
				<mat-step [completed]='record' [state]="'number'">
					<ng-template matStepLabel>
						{{'Sconti' | translate}}
					</ng-template>
				</mat-step>
			</mat-horizontal-stepper>
		</ng-container>
		<ng-container *ngSwitchCase='"vertical"'>
			<fuse-sidebar class="left" name="page-sidebar" position="left" lockedOpen="gt-md">
				<div class="header p-24">
					<h2 class="m-0 title">{{'Prenotazione' | translate}}</h2>
				</div>
				<div class="content">
					<mat-vertical-stepper #stepper linear="!Boolean(record)" (selectionChange)='stepChange.emit($event)'>
						<mat-step [completed]='record' [state]="'number'">
							<ng-template matStepLabel>
								{{'Partenze' | translate}}
							</ng-template>
						</mat-step>
						<mat-step [completed]='record' [state]="'number'">
							<ng-template matStepLabel>
								{{'Informazioni Base' | translate}}
							</ng-template>
						</mat-step>
						<mat-step [completed]='record' [state]="'number'">
							<ng-template matStepLabel>
								<span *ngIf='record'><!--[{{record.participant_count}}]--></span> {{'Partecipanti' | translate}}
							</ng-template>
						</mat-step>
						<mat-step [completed]='record' [state]="'number'">
							<ng-template matStepLabel>
								{{'Sconti' | translate}}
							</ng-template>
						</mat-step>
					</mat-vertical-stepper>
				</div>
			</fuse-sidebar>
		</ng-container>
		<ng-container *ngSwitchDefault>No stepper configured!</ng-container>
	</ng-container>
</ng-container>
