import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as packageFieldMap} from '../trip_packages/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Pacchetto',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_packages',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
});

fieldsMap.set('package_description', cloneFieldDefinition(packageFieldMap.get('description'), {
	key: 'package_description'
}));

fieldsMap.set('package_code', cloneFieldDefinition(packageFieldMap.get('code'), {
	key: 'package_code'
}));

export {fieldsMap};
