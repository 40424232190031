import { Component } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'toggle-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class ToggleInputComponent{

	constructor(
		public inputService: FormInputViewerService
	){}
}
