import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth.service';
import { default as DatasetConfigurations } from 'app/configs/datasets';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetService } from './dataset.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import { SelectDomainDialogComponent } from '../dialogs/select-domain-dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
export var DatasetEvents;
(function (DatasetEvents) {
    DatasetEvents[DatasetEvents["CREATED"] = 0] = "CREATED";
    DatasetEvents[DatasetEvents["EDITED"] = 1] = "EDITED";
    DatasetEvents[DatasetEvents["DELETED"] = 2] = "DELETED";
    DatasetEvents[DatasetEvents["ACTION_REALOAD_LIST"] = 3] = "ACTION_REALOAD_LIST";
    DatasetEvents[DatasetEvents["DOMAIN_SELECTED"] = 4] = "DOMAIN_SELECTED";
    DatasetEvents[DatasetEvents["LIST_FIlTER_CHANGED"] = 5] = "LIST_FIlTER_CHANGED";
})(DatasetEvents || (DatasetEvents = {}));
export function getDatasetConfigAction(datasetCode, actionCode) {
    var datasetConfig = DatasetConfigurations[datasetCode];
    if (!datasetConfig) {
        console.warn('dataset Config not found for', datasetCode);
        return null;
    }
    if (datasetConfig && datasetConfig.defaultActions[actionCode]) {
        return datasetConfig.defaultActions[actionCode];
    }
    console.warn('dataset action Config not found for', datasetCode, actionCode);
    return null;
}
var DatasetActionContainerService = /** @class */ (function () {
    function DatasetActionContainerService(appService, pageClosureContainer, authService, datasetService, domainFilterService, translationService, injector) {
        this.appService = appService;
        this.pageClosureContainer = pageClosureContainer;
        this.authService = authService;
        this.datasetService = datasetService;
        this.domainFilterService = domainFilterService;
        this.translationService = translationService;
        this.injector = injector;
        this.datasetData = {};
        this.sourceDataLoaded = false;
        this.isNestedChild = false;
        this.contextData = {};
        this.datasetEvent = new Subject();
        this.loading = new BehaviorSubject(false);
        this.ready = new BehaviorSubject(false);
        this.datasetDataChanged = new Subject();
        if (!this.domainFilterService)
            console.log('not domainFilterService', this);
    }
    DatasetActionContainerService.prototype.init = function (options) {
        var _this = this;
        this.actionCode = options.actionCode;
        this.datasetCode = options.datasetCode;
        var datasetConfig = DatasetConfigurations[options.datasetCode];
        if (!datasetConfig) {
            console.warn('dataset Config not found for', options.datasetCode);
        }
        this.datasetConfig = datasetConfig;
        if (datasetConfig && datasetConfig.defaultActions[options.actionCode]) {
            this.actionConfig = datasetConfig.defaultActions[options.actionCode];
        }
        if (options.actionConfig) {
            this.actionConfig = options.actionConfig;
        }
        this.datasetRS = options.datasetRS;
        this.parentDatasetACS = options.parentDatasetACS;
        this.parentDatasetRS = options.parentDatasetRS;
        if (this.parentDatasetACS) {
            this.isNestedChild = this.parentDatasetACS.datasetCode === this.datasetCode && this.parentDatasetACS.actionCode === this.actionCode;
        }
        if (!this.viewConfig && this.actionConfig)
            this.viewConfig = this.actionConfig.viewConfig;
        // fetch data also in nested ActionContainer
        if (this.parentDatasetACS && options.fetchData !== false) {
            this.loadSourceData(options.useBeforeCalls)
                .subscribe({
                next: function () {
                    _this.ready.next(true);
                },
                error: function () {
                    _this.ready.next(true);
                }
            });
        }
        else if (this.parentDatasetACS) {
            this.parentDatasetACSReadySubscription = this.parentDatasetACS.ready
                .subscribe(function (value) {
                if (value) {
                    _this.ready.next(true);
                    if (_this.parentDatasetACSReadySubscription)
                        _this.parentDatasetACSReadySubscription.unsubscribe();
                }
            });
        }
        else if (options.fetchData === true) {
            this.loadSourceData(options.useBeforeCalls)
                .subscribe({
                next: function () {
                    _this.ready.next(true);
                },
                error: function () {
                    _this.ready.next(true);
                }
            });
        }
        else {
            this.ready.next(true);
        }
        return this.ready;
    };
    /**
     * Load all relative data
     */
    DatasetActionContainerService.prototype.loadSourceData = function (useBeforeCalls) {
        var _this = this;
        if (useBeforeCalls === void 0) { useBeforeCalls = true; }
        return Observable.create(function (observer) {
            _this.loading.next(true);
            var hasProperties = _this.hasValueInKeyPath(_this.datasetConfig.name + '.' + _this.datasetConfig.name + '_property_definitions');
            _this.datasetService.fetchSourceData(_this.datasetConfig, useBeforeCalls && _this.actionConfig && _this.actionConfig.beforeCalls, _this.fetchParamMap, {
                excludeProperties: hasProperties
            })
                .subscribe({
                next: function (result) {
                    _this.putAllDatasetData(result);
                    _this.sourceDataLoaded = true;
                    _this.loading.next(false);
                    observer.next(true);
                },
                error: function () {
                    _this.loading.next(false);
                    observer.error(true);
                }
            });
        });
    };
    /**
     * Fetch only properties definition targets
     */
    DatasetActionContainerService.prototype.loadPropertyDefinitions = function () {
        var _this = this;
        return Observable.create(function (observer) {
            _this.loading.next(true);
            _this.datasetService.fetchPropertyDefinitions(_this.datasetConfig)
                .subscribe({
                next: function (result) {
                    _this.putAllDatasetData(result);
                    _this.loading.next(false);
                    observer.next(true);
                },
                error: function () {
                    _this.loading.next(false);
                    observer.error(true);
                }
            });
        });
    };
    DatasetActionContainerService.prototype.getContextualDomainHeaders = function () {
        var headers = new HttpHeaders({});
        if (this.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.getValueFromKeyPath('contextual_domain_id'));
        }
        else if (!this.hasValueInKeyPath('contextual_domain_id') && this.parentDatasetACS && this.parentDatasetACS.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.parentDatasetACS.getValueFromKeyPath('contextual_domain_id'));
        }
        return headers;
    };
    DatasetActionContainerService.prototype.getDomainSetting = function (path, domainId) {
        domainId = domainId || this.getValueFromKeyPath('contextual_domain_id') || (this.parentDatasetACS && this.parentDatasetACS.getValueFromKeyPath('contextual_domain_id')) || 'current';
        return this.domainFilterService && this.domainFilterService.getDomainSetting(domainId, path);
    };
    DatasetActionContainerService.prototype.canCreate = function () {
        var staticAllowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (!staticAllowedActionCodes)
            return false;
        return staticAllowedActionCodes.includes('create');
    };
    DatasetActionContainerService.prototype.canDetail = function (record) {
        if (!record || Boolean(record.deleted_at))
            return false;
        var allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        if (!allowedActionCodes)
            return false;
        return allowedActionCodes.includes('view_detail');
    };
    DatasetActionContainerService.prototype.canEdit = function (record) {
        if (!record || Boolean(record.deleted_at))
            return false;
        var allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        if (!allowedActionCodes)
            return false;
        return allowedActionCodes.includes('edit');
    };
    DatasetActionContainerService.prototype.canEditBookingDate = function (record) {
        if (!record || Boolean(record.deleted_at))
            return false;
        var allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        if (!allowedActionCodes)
            return false;
        return allowedActionCodes.includes('edit_booking_date');
    };
    DatasetActionContainerService.prototype.canDelete = function (record) {
        if (!record || Boolean(record.deleted_at))
            return false;
        var allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        if (!allowedActionCodes)
            return false;
        return allowedActionCodes.includes('delete');
    };
    DatasetActionContainerService.prototype.canRestore = function (record) {
        if (!record)
            return false;
        if (!Boolean(record.deleted_at))
            return false;
        var allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        if (!allowedActionCodes)
            return false;
        return allowedActionCodes.includes('restore');
    };
    DatasetActionContainerService.prototype.canStaticAction = function (actionCode) {
        var staticAllowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
        if (!staticAllowedActionCodes)
            return false;
        return staticAllowedActionCodes.includes(actionCode);
    };
    DatasetActionContainerService.prototype.canAction = function (record, actionCode) {
        if (!record)
            return false;
        var allowedActionCodes = [];
        if (record && record.allowed_actions)
            allowedActionCodes = record.allowed_actions;
        return allowedActionCodes.includes(actionCode);
    };
    DatasetActionContainerService.prototype.getViewConfig = function (viewConfig) {
        if (!viewConfig)
            viewConfig = this.viewConfig;
        if (!viewConfig && this.actionConfig)
            viewConfig = this.actionConfig.viewConfig;
        return viewConfig;
    };
    DatasetActionContainerService.prototype.actionEnabled = function (action, viewConfig) {
        var datasetConfig = this.datasetConfig;
        if (!datasetConfig)
            return false;
        // use the default viewconfig
        viewConfig = this.getViewConfig(viewConfig);
        if (viewConfig) {
            if (viewConfig.actions) {
                if (typeof (viewConfig.actions) === 'object' && typeof (viewConfig.actions[action]) === 'boolean') {
                    return viewConfig.actions[action];
                }
                if (!viewConfig.actions.includes(action))
                    return false;
            }
        }
        var actionEnabled = datasetConfig.enabledActions[action];
        if (typeof (actionEnabled) === 'function') {
            return actionEnabled(this.authService.userDataObserver.getValue());
        }
        else if (typeof (actionEnabled) === 'boolean') {
            return actionEnabled;
        }
        return true;
    };
    DatasetActionContainerService.prototype.getDefaultOpenType = function (action) {
        var datasetConfig = this.datasetConfig;
        return datasetConfig.defaultActions[action].openType;
    };
    DatasetActionContainerService.prototype.putDatasetData = function (key, value) {
        this.datasetData[key] = value;
        this.datasetDataChanged.next([key]);
    };
    DatasetActionContainerService.prototype.putAllDatasetData = function (data) {
        var oldDatasetData = this.datasetData;
        var newDatasetData = Object.assign({}, oldDatasetData);
        var keys = [];
        for (var key in data) {
            if (!Object.prototype.hasOwnProperty.call(data, key))
                continue;
            if (key === this.datasetCode && typeof (data[key]) === 'object') {
                for (var key2 in data[key]) {
                    if (!Object.prototype.hasOwnProperty.call(data[key], key2))
                        continue;
                    newDatasetData[key2] = data[key][key2];
                    keys.push(key2);
                }
            }
            else {
                newDatasetData[key] = data[key];
                keys.push(key);
            }
        }
        this.datasetData = newDatasetData;
        this.datasetDataChanged.next(keys);
    };
    DatasetActionContainerService.prototype.putContextData = function (key, value) {
        this.contextData[key] = value;
        return this;
    };
    DatasetActionContainerService.prototype.removeContextData = function (key) {
        delete this.contextData[key];
        return this;
    };
    DatasetActionContainerService.prototype.getData = function (debug) {
        var _a, e_1, _b;
        var data = (_a = {}, _a[this.datasetCode] = Object.assign({}, this.datasetData), _a);
        if (this.parentDatasetRS && (!this.datasetRS || this.parentDatasetRS.getDatasetCode() !== this.datasetCode)) {
            if (!data[this.parentDatasetRS.getDatasetCode()])
                data[this.parentDatasetRS.getDatasetCode()] = {};
            data[this.parentDatasetRS.getDatasetCode()].record = this.parentDatasetRS.record.value;
            data[this.parentDatasetRS.getDatasetCode()].recordId = this.parentDatasetRS.recordId;
            if (this.parentDatasetRS.record.value && this.parentDatasetRS.record.value.domain && this.parentDatasetRS.record.value.domain.id) {
                data['contextual_domain_id'] = this.parentDatasetRS.record.value.domain.id;
                data['contextual_domain'] = this.parentDatasetRS.record.value.domain;
            }
        }
        if (this.datasetRS) {
            data[this.datasetRS.getDatasetCode()].record = this.datasetRS.record.value;
            data[this.datasetRS.getDatasetCode()].recordId = this.datasetRS.recordId;
            if (this.datasetRS.record.value && this.datasetRS.record.value.domain && this.datasetRS.record.value.domain.id) {
                data['contextual_domain_id'] = this.datasetRS.record.value.domain.id;
                data['contextual_domain'] = this.datasetRS.record.value.domain;
            }
        }
        if (this.datasetConfig && this.datasetConfig.nestedProperties) {
            try {
                for (var _c = tslib_1.__values(this.datasetConfig.nestedProperties), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var code = _d.value;
                    if (data[this.datasetCode][code]) {
                        data[code] = data[this.datasetCode][code];
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        for (var key in this.contextData) {
            if (!Object.prototype.hasOwnProperty.call(this.contextData, key))
                continue;
            data[key] = this.contextData[key];
        }
        data.datasetCode = this.datasetCode;
        return data;
    };
    DatasetActionContainerService.prototype._getValueFromKeyPath = function (data, keys) {
        if (data == null || data === undefined)
            return null;
        if (!keys || keys.length === 0)
            return data;
        var currentKey = keys[0];
        return this._getValueFromKeyPath(data[currentKey], keys.slice(1));
    };
    DatasetActionContainerService.prototype.getValueFromKeyPath = function (keyPath, defaultValue) {
        var keys = keyPath.split('.');
        var data = this.getData();
        var value = this._getValueFromKeyPath(data, keys);
        var has = value !== null && value !== undefined;
        if (has)
            return value;
        if (this.parentDatasetACS) {
            return this.parentDatasetACS.getValueFromKeyPath(keyPath, defaultValue);
        }
        else if (this.pageClosureContainer) {
            return this._getValueFromKeyPath(this.pageClosureContainer.getSharedData(), keys);
        }
        return defaultValue;
    };
    DatasetActionContainerService.prototype._hasValueInKeyPath = function (data, keys) {
        if (data == null || data === undefined)
            return false;
        if (!keys || keys.length === 0)
            return true;
        var currentKey = keys[0];
        return this._hasValueInKeyPath(data[currentKey], keys.slice(1));
    };
    DatasetActionContainerService.prototype.hasValueInKeyPath = function (keyPath) {
        var keys = keyPath.split('.');
        var value = this._hasValueInKeyPath(this.getData(), keys);
        var has = value === true;
        if (has)
            return value;
        if (this.parentDatasetACS) {
            return this.parentDatasetACS.hasValueInKeyPath(keyPath);
        }
        else if (this.pageClosureContainer) {
            return this._hasValueInKeyPath(this.pageClosureContainer.getSharedData(), keys);
        }
        return false;
    };
    DatasetActionContainerService.prototype.getListFiltersCacheKey = function (prevKey) {
        var filterKey = "";
        if (!prevKey) {
            filterKey = this.authService.userDataObserver.value.session_id + this.datasetCode;
            if (this.domainFilterService.filterDomainId.value)
                filterKey += this.domainFilterService.filterDomainId.value;
        }
        else
            filterKey += prevKey;
        // if on detail page with parents
        if (this.actionCode == 'detail' && this.datasetRS || this.actionCode == 'edit' && this.datasetRS) {
            filterKey += this.datasetCode + this.datasetRS.recordId;
        }
        if (this.parentDatasetACS)
            return this.parentDatasetACS.getListFiltersCacheKey(filterKey);
        return filterKey;
    };
    DatasetActionContainerService.prototype.isAdministrator = function () {
        return this.authService.isAdministrator();
    };
    DatasetActionContainerService.prototype.getFormConfig = function (name) {
        if (!this.datasetConfig)
            return null;
        if (this.datasetConfig.formConfigsMap) {
            return this.datasetConfig.formConfigsMap[name];
        }
        return null;
    };
    DatasetActionContainerService.prototype.getCreateRoute = function (customActionCode) {
        var route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/create';
        if (customActionCode) {
            route = '/dataset/' + customActionCode + '/create';
        }
        if (this.datasetConfig.pivot) {
            var recordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
            if (!recordId) {
                console.error('no parentDatasetRS recordId defined', this);
                return route;
            }
            route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId + '/create';
        }
        return route;
    };
    DatasetActionContainerService.prototype.getUpdateRoute = function (record, customActionCode, customRecordId) {
        var recordId;
        if (record && !customRecordId) {
            recordId = record.id;
        }
        else {
            recordId = customRecordId;
        }
        var route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/update/' + recordId;
        if (customActionCode) {
            route = '/dataset/' + customActionCode + '/update/' + recordId;
        }
        if (this.datasetConfig.pivot) {
            var parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
            if (!parentRecordId) {
                console.error('no parentDatasetRS parentRecordId defined', this);
                return route;
            }
            var id = record.pivot[this.datasetConfig.pivot.foreignKey];
            if (!id) {
                console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
            }
            route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/update/' + id;
        }
        return route;
    };
    DatasetActionContainerService.prototype.getUpdatePropertiesRoute = function (record) {
        return '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/set/properties/' + record.id;
    };
    DatasetActionContainerService.prototype.getListRoute = function () {
        var route = '/dataset/' + this.datasetConfig.ajaxDatasetCode;
        if (this.datasetConfig.pivot) {
            var recordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
            if (!recordId)
                recordId = this.getValueFromKeyPath(this.datasetCode + '.filters.parentRecordId');
            if (!recordId) {
                console.warn(this.datasetConfig.pivot.parentDatasetCode + '.recordId', this.getData(), this);
                return null;
            }
            route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
        }
        return route;
    };
    DatasetActionContainerService.prototype.getDeleteRoute = function (record) {
        var route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/delete/' + record.id;
        if (this.datasetConfig.pivot) {
            var parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
            if (!parentRecordId) {
                console.error('no parentDatasetRS parentRecordId defined', this);
                return route;
            }
            var id = record.pivot[this.datasetConfig.pivot.foreignKey];
            if (!id) {
                console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
            }
            route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/delete/' + id;
        }
        return route;
    };
    DatasetActionContainerService.prototype.getRestoreRoute = function (record) {
        var route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/restore/' + record.id;
        if (this.datasetConfig.pivot) {
            var parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
            if (!parentRecordId) {
                console.error('no parentDatasetRS parentRecordId defined', this);
                return route;
            }
            var id = record.pivot[this.datasetConfig.pivot.foreignKey];
            if (!id) {
                console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
            }
            route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/restore/' + id;
        }
        return route;
    };
    DatasetActionContainerService.prototype.getConfig = function (datasetCode, configKey, template) {
        var defaultDatasetConfig = DatasetConfigurations[datasetCode];
        var DatasetTemplateExtensions = {
            'trip_packages': {
                stepViewConfig: {
                /*0: TripPackageDefaultStepViewConfig,
                1: TripPackagestepViewConfigTemplate1*/
                }
            }
        };
        var datasetTemplateExtension = DatasetTemplateExtensions[datasetCode];
        if (datasetTemplateExtension && datasetTemplateExtension[configKey] && datasetTemplateExtension[configKey][template])
            return datasetTemplateExtension[configKey][template];
        return defaultDatasetConfig[configKey];
    };
    DatasetActionContainerService.prototype.wrapInDomain = function (callback) {
        this.domainFilterService.wrapInDomain(SelectDomainDialogComponent, callback, this);
    };
    return DatasetActionContainerService;
}());
export { DatasetActionContainerService };
