import { startOfDay, endOfDay } from 'date-fns';
import * as moment from 'moment';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetCommandDialogComponent } from 'app/main/components/dataset/dialogs/dataset-command-dialog/dialog.component';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { LoadingDialogComponent, ILoadingDialogData } from 'app/main/dialogs/loading-dialog/dialog.component';
import { ActionDialogComponent } from '../edit-booking/action-dialog/action-dialog.component';

enum TripPackageTripServiceRuleType{
	GROUP = '1',
	PARTICIPANTS = '0'
}

export class CalendarEventModel
{
	start: Date;
	end?: Date;
	title: string;
	color: {
		primary: string;
		secondary: string;
	};
	actions?: any[];
	allDay?: boolean;
	cssClass?: string;
	resizable?: {
		beforeStart?: boolean;
		afterEnd?: boolean;
	};
	draggable?: boolean;
	meta?: any;
	package?: any;
	type = 'default';
	private datasetNavigatorService?: DatasetNavigatorService;
	private component?: any;

	/**
	 * Constructor
	 *
	 * @param data
	 */
	constructor(data?: any, datasetNavigatorService?: DatasetNavigatorService, component?: any){
		this.datasetNavigatorService = datasetNavigatorService;
		this.component = component;
		data = data || {};
		this.start = new Date(data.start) || startOfDay(new Date());
		this.end = new Date(data.end) || endOfDay(new Date());
		this.title = data.title || '';
		this.color = {
			primary  : data.color && data.color.primary || '#1e90ff',
			secondary: data.color && data.color.secondary || '#D1E8FF'
		};
		this.draggable = Boolean(data.draggable);
		this.resizable = {
			beforeStart: data.resizable && data.resizable.beforeStart,
			afterEnd   : data.resizable && data.resizable.afterEnd
		};
		this.actions = data.actions || [];
		this.allDay = data.allDay || false;
		this.cssClass = data.cssClass || '';
		this.meta = {
			location: data.meta && data.meta.location || '',
			notes   : data.meta && data.meta.notes || ''
		};
		this.package = data.package;
	}

	computeClass(): string{
		if(this.meta && this.meta.actual_trip){
			let classes = 'event-full-grade-1';
			// || !this.meta.actual_trip.max_allotment
			if (['canceled','closed'].includes(this.meta.actual_trip.start_status) || this.meta.actual_trip.max_allotment === 0){
				classes = 'event-full-grade-4';
			}else if(this.meta.actual_trip.consumed_allotment === 0 && (this.meta.actual_trip.max_allotment === null || this.meta.actual_trip.max_allotment > 0)){
				classes += ' event-full-grade-empty';
			}else if (this.meta.actual_trip.max_allotment === 0){
				classes += ' event-full-grade-3';
			}else if (this.meta.actual_trip.max_allotment){
				const rate = this.meta.actual_trip.consumed_allotment / this.meta.actual_trip.max_allotment;
				if(rate >= 1){
					classes = 'event-full-grade-3';
				}else if( rate > 0.5){
					classes = 'event-full-grade-2';
				}
			}
	
			return classes;
		}
		return '';
	}

	makeDateTime(date, time): string{
		let value = date;
		if(time) value += ' ' + time;
		return value;
	}

	fromResourseAllotment(resourceAllotment: any, day: string): CalendarEventModel{
		this.start = moment(day).toDate();
		this.end = this.start;
		this.title = 'Allotment';
		this.draggable = false;
		this.resizable = {
			beforeStart: false,
			afterEnd: false
		};
		this.allDay = true;
		this.meta = {
			resource_allotment: resourceAllotment
		};
		this.cssClass = this.computeClass();
		this.type = 'trip_resource_allotments';
		return this;
	}

	fromTripResourceStaffAssignment(tripResourceStaffAssignment: any): CalendarEventModel{
		if(!tripResourceStaffAssignment || !tripResourceStaffAssignment.staff_list_item) return;
		if(!tripResourceStaffAssignment.staff_list_item.entity_staff_element) return;
		const staffElement = tripResourceStaffAssignment.staff_list_item.entity_staff_element;
		this.start = moment(tripResourceStaffAssignment.date).toDate();
		this.end = this.start;
		this.title = staffElement.description || 'Staff';
		this.draggable = false;
		this.resizable = {
			beforeStart: false,
			afterEnd: false
		};
		this.allDay = true;
		this.meta = {
			tripResourceStaffAssignment,
			fullName: staffElement.description
		};
		
		if(staffElement.entity.lastname){
			this.meta.description = staffElement.entity.lastname;
			if(this.meta.fullName.indexOf(this.meta.description) === -1){
				this.meta.fullName += ' (' + this.meta.description;
				if(!staffElement.entity.firstname){
					this.meta.fullName += ' ' + staffElement.entity.firstname;
				}
				this.meta.fullName += ')';
			}
		}else if(staffElement.description){
			this.meta.description = staffElement.description;
			this.meta.description = this.meta.description.split(' ')[0];
		}
		this.cssClass = this.computeClass();
		this.type = 'trip_resource_staff_assignments';

		this.actions = [];

		this.actions.push({
			icon: 'pageview',
			color: 'default',
			routerLink: '/dataset/detail/entities/' + tripResourceStaffAssignment.staff_list_item.entity_staff_element.entity_id
		});

		this.actions.push({
			icon: 'delete',
			color: 'warn',
			onClick: ({event}): void => {
				console.log('onClick.component', this.component);
				this.datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
					contentMessage: 'Vuoi togliere "' + tripResourceStaffAssignment.staff_list_item.entity_staff_element.description + 
					'" dal giorno ' + this.start.toLocaleDateString()
				}).afterClosed()
				.subscribe(result => {
					if(!result) return;
					const dialogRef = this.datasetNavigatorService.dialog.open(ActionDialogComponent, {
						width: '550px',
						data: {
							title: 'Azione in corso'
						}
					});
					this.datasetNavigatorService.datasetService.post('/dataset/trip_resource_staff_assignments/command/free_in_range', {
						from: tripResourceStaffAssignment.date,
						to: tripResourceStaffAssignment.date,
						trip_resource_id: tripResourceStaffAssignment.trip_resource_id,
						entity_staff_element_unified_list_item_id: tripResourceStaffAssignment.entity_staff_element_unified_list_item_id
					}).subscribe({
						next: response => {
							dialogRef.close();
							this.datasetNavigatorService.appService.showSuccessMessage('Staff rimosso dal giorno');
							
							if(this.component && this.component.datasetACS){
								this.component.datasetACS.datasetEvent.next({eventName: DatasetEvents.ACTION_REALOAD_LIST});
							}
						},
						error: response => {
							dialogRef.componentInstance.loading = false;
							dialogRef.componentInstance.resultStatus = 'error';
							dialogRef.componentInstance.setMessage('Si è verificato un errore imprevisto.');
						}
					});
				});
			}
		});
		return this;
	}

	fromActualTrip(actualTrip: any): CalendarEventModel{
		const utcStartDate = moment.utc(this.makeDateTime(actualTrip.start_day.slice(0, 10), actualTrip.start_time));
		this.start = utcStartDate.clone().add(utcStartDate.toDate().getTimezoneOffset(), 'minutes').toDate();
		this.end = this.start;

		if (actualTrip.package) this.package = actualTrip.package;
		
		if(actualTrip.package && actualTrip.start_time){
			this.title = actualTrip.package.description;
		}
		this.draggable = false;
		this.resizable = {
			beforeStart: false,
			afterEnd: false
		};
		this.allDay = true;
		let statusDescription = '---';
		if(actualTrip.start_status === 'confirmed') statusDescription = 'Confermato';
		else if(actualTrip.start_status === 'unconfirmed') statusDescription = 'Non confermato';
		else if(actualTrip.start_status === 'canceled') statusDescription = 'Cancellato';

		let actual_trip_guide_languages = [];
		if(actualTrip.properties && 
			actualTrip.properties.actual_trip_guide_languages && 
			actualTrip.properties.actual_trip_guide_languages.value){
			actual_trip_guide_languages = actualTrip.properties.actual_trip_guide_languages.value;
		}
		let actual_trip_audioguide_languages = [];
		if(actualTrip.properties && 
			actualTrip.properties.actual_trip_audioguide_languages && 
			actualTrip.properties.actual_trip_audioguide_languages.value){
				actual_trip_audioguide_languages = actualTrip.properties.actual_trip_audioguide_languages.value;
		}
		this.meta = {
			actual_trip: actualTrip,
			status: statusDescription,
			actual_trip_guide_languages,
			actual_trip_audioguide_languages,
			available_slots: 0, //'&infin;',
			sold_slots: 0,
			optional_slots: 0,
			staff_elements: [],
			participants: {
				adt: '--',
				chd: '--',
				inf: '--',
				snr: '--'
			}
		};
		if(actualTrip.package && actualTrip.start_time){
			this.meta.start_time = utcStartDate.format('HH:mm');
		}
		if(actualTrip.max_allotment > 0){
			this.meta.available_slots = actualTrip.max_allotment - actualTrip.consumed_allotment;
		}
		if(actualTrip.staff_elements && actualTrip.staff_elements.length){
			this.meta.staff_elements = actualTrip.staff_elements;
		}
		if (actualTrip.package.properties.trip_service_rule_type && actualTrip.package.properties.trip_service_rule_type.value == TripPackageTripServiceRuleType.GROUP && actualTrip.groups_overall){
			this.meta.sold_slots = actualTrip.groups_overall.total || 0;
			this.meta.optional_slots = actualTrip.groups_overall.optional || 0;
		} else if (actualTrip.participant_overall) {
			this.meta.sold_slots = actualTrip.participant_overall.total || 0;
			this.meta.optional_slots = actualTrip.participant_overall.optional || 0;
		}
		// not show event if has no allotment and no participants
		/*if(this.meta.sold_slots <= 0 && actualTrip.max_allotment === 0){
			this.start = null;
			this.end = null;
		}*/
		this.actions = [{
			text: 'Apri dettaglio',
			routerLink: this.datasetNavigatorService.getDetailRoute('actual_trips', actualTrip.id),
			onClick: ({event}): void => {
				this.datasetNavigatorService.showDatasetDetail('actual_trips', null, event.meta.actual_trip.id);
			}
		}];
		this.cssClass = this.computeClass();
		this.type = 'actual_trips';
		return this;
	}

	fromEntityCalendarEvent(entityCalendarEvent: any, type: string): CalendarEventModel{
		let day = null;

		if(type === 'trip_resource_staff_assignments'){
			day = entityCalendarEvent.date;
			if(entityCalendarEvent.trip_resource){
				this.title = entityCalendarEvent.trip_resource.description;
			}else{
				return null;
			}
		}else{
			return null;
		}

		this.start = moment(day).toDate();
		this.end = this.start;
		
		this.allDay = true;
		this.meta = {
			entityCalendarEvent,
			entityCalendarEventType: type
		};
		this.cssClass = this.computeClass();
		this.type = 'entity_calendar_events';
		return this;
	}
}
