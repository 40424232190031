import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { DatasetService } from './dataset.service';
import { DatasetEvents } from './dataset-action-container.service';
var DatasetMultilanguageContainerService = /** @class */ (function () {
    function DatasetMultilanguageContainerService(appService, datasetService) {
        this.appService = appService;
        this.datasetService = datasetService;
        this.activeLanguageCodes = [];
    }
    DatasetMultilanguageContainerService.prototype.init = function (options) {
        var _this = this;
        this.datasetACS = options.datasetACS;
        this.datasetRS = options.datasetRS;
        this.parentForeignKey = options.parentForeignKey;
        this.parentValueKey = options.parentValueKey;
        if (this.languageSubscription)
            this.languageSubscription.unsubscribe();
        this.languageSubscription = this.appService.language
            .subscribe(function () {
            if (!_this.datasetACS.ready.value)
                return;
            _this.fetchRecord();
        });
    };
    DatasetMultilanguageContainerService.prototype.fetchRecord = function () {
        var _this = this;
        this.datasetACS.loading.next(true);
        if (this.fetchRecordSubscription)
            this.fetchRecordSubscription.unsubscribe();
        var params = {};
        params[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
        params['lang'] = this.appService.language.value;
        var result = new Subject();
        this.fetchRecordSubscription = this.datasetService.get(this.datasetACS.getListRoute(), params)
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                if (!response.items || !response.items.length || response.items.length > 1) {
                    console.warn('fetchRecord', response);
                }
                if (response.items && response.items.length > 0) {
                    _this.datasetRS.record.next(response.items[0]);
                    result.next(response.items[0]);
                }
                else {
                    _this.datasetRS.recordId = null;
                    _this.datasetRS.record.next(null);
                    result.next(null);
                }
            },
            error: function () {
                _this.datasetACS.loading.next(false);
            }
        });
        return result;
    };
    DatasetMultilanguageContainerService.prototype.fetchAll = function () {
        var _this = this;
        if (this.fetchAllSubscription)
            this.fetchAllSubscription.unsubscribe();
        var params = {};
        params[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
        this.fetchAllSubscription = this.datasetService.get(this.datasetACS.getListRoute(), params)
            .subscribe({
            next: function (response) {
                var e_1, _a;
                if (!response.items || !response.items.length || response.items.length > 1) {
                    console.warn('fetchAll', response);
                }
                _this.languageContents = response.items;
                if (_this.languageContents) {
                    _this.activeLanguageCodes = [];
                    try {
                        for (var _b = tslib_1.__values(_this.languageContents), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var languageContent = _c.value;
                            if (_this.activeLanguageCodes.includes(languageContent.lang))
                                continue;
                            _this.activeLanguageCodes.push(languageContent.lang);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
            }
        });
    };
    DatasetMultilanguageContainerService.prototype.save = function (formData) {
        var _this = this;
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.record.value) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        else {
            if (formData instanceof FormData) {
                formData.set('lang', this.appService.language.value);
                formData.set(this.parentForeignKey, this.datasetACS.getValueFromKeyPath(this.parentValueKey));
            }
            else {
                formData['lang'] = this.appService.language.value;
                formData[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
            }
        }
        var result = new Subject();
        this.datasetService.post(route, formData)
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                result.next(response);
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                result.error(response);
            }
        });
        return result;
    };
    return DatasetMultilanguageContainerService;
}());
export { DatasetMultilanguageContainerService };
