import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { MatSelect } from '@angular/material/select';
import { HttpHeaders } from '@angular/common/http';
var DatasetSelectInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetSelectInputComponent, _super);
    function DatasetSelectInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.loading = false;
        _this.items = [];
        _this.supportCreate = false;
        return _this;
    }
    DatasetSelectInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.buildSelectResourceOptions();
            _this.getFormControl().valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (value) {
                if (_this.inputService.formInputDefinition.extra && _this.inputService.formInputDefinition.extra.onSet) {
                    _this.inputService.formInputDefinition.extra.onSet(_this.inputService.formGroup, _this.getItemFromValue(value));
                }
                if (value) {
                    var selectedOption = _this.selectOptions.find(function (el) {
                        return el.value === value;
                    });
                    if (!selectedOption) {
                        _this.fetchSelectedOption(value)
                            .subscribe(function () {
                            _this.updateSelectedLabel();
                        });
                    }
                    else {
                        _this.updateSelectedLabel();
                    }
                }
                else {
                    if (value === undefined) {
                        _this.getFormControl().setValue(null);
                        return;
                    }
                    _this.updateSelectedLabel();
                }
            });
        });
    };
    DatasetSelectInputComponent.prototype.updateSelectedLabel = function () {
        var value = this.getFormControl().value;
        var selectedOption = this.selectOptions.find(function (el) {
            return el.value === value;
        });
        this.selectedLabel = selectedOption ? selectedOption.label : null;
    };
    DatasetSelectInputComponent.prototype.getFormControl = function () {
        return this.inputService.control;
    };
    DatasetSelectInputComponent.prototype.getItemFromValue = function (value) {
        var e_1, _a;
        if (!this.items)
            return;
        try {
            for (var _b = tslib_1.__values(this.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                var itemValue = this.inputService.formInputDefinition.remoteOptions.getValue(item);
                if (itemValue === value)
                    return item;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    DatasetSelectInputComponent.prototype.buildSelectResourceOptions = function (text) {
        var _this = this;
        if (!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions)
            return;
        this.selectOptions = [];
        var params = {
            perPage: this.inputService.formInputDefinition.remoteOptions.limit || 50
        };
        if (this.inputService.formInputDefinition.remoteOptions) {
            this.supportCreate = Boolean(this.inputService.formInputDefinition.remoteOptions.supportCreate);
        }
        if (this.inputService.formInputDefinition.remoteOptions.getParams) {
            params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
        }
        var formControl = this.getFormControl();
        if (formControl && formControl.value)
            params['include'] = formControl.value;
        if (text) {
            params['text-search'] = text;
        }
        if (this.fetchOptionsSubscription) {
            this.fetchOptionsSubscription.unsubscribe();
        }
        this.loading = true;
        var route;
        if (this.inputService.formInputDefinition.datasetCode) {
            route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
            if (this.inputService.formInputDefinition.remoteOptions.getPivotConfig) {
                var pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
                var parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
                route = '/pivot/' + pivotConfig.pivotCode + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
            }
        }
        else if (this.inputService.formInputDefinition.customActionCode) {
            route = this.inputService.formInputDefinition.customActionCode;
        }
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.fetchOptionsSubscription = this.datasetService.get(route, params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                var e_2, _a;
                _this.items = result.items;
                var options = [];
                try {
                    for (var _b = tslib_1.__values(result.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var item = _c.value;
                        options.push({
                            value: _this.inputService.formInputDefinition.remoteOptions.getValue(item),
                            label: _this.inputService.formInputDefinition.remoteOptions.getLabel(item, _this.inputService.dataContainerService)
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                _this.selectOptions = options;
                _this.loading = false;
                if (formControl.value) {
                    var selectedOption = _this.selectOptions.find(function (el) {
                        return el.value === formControl.value;
                    });
                    if (!selectedOption) {
                        _this.fetchSelectedOption(formControl.value)
                            .subscribe(function () {
                            _this.updateSelectedLabel();
                        });
                    }
                    else {
                        _this.updateSelectedLabel();
                    }
                }
                else {
                    if (formControl.value === undefined) {
                        _this.getFormControl().setValue(null);
                        return;
                    }
                    _this.updateSelectedLabel();
                }
                //this.updateSelectedLabel();
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    DatasetSelectInputComponent.prototype.search = function (text) {
        this.buildSelectResourceOptions(text);
    };
    DatasetSelectInputComponent.prototype.fetchSelectedOption = function (value) {
        var _this = this;
        var resultSubject = new Subject();
        var id = this.inputService.control.value;
        if (!id || !this.inputService.formInputDefinition.datasetCode) {
            resultSubject.next(null);
            resultSubject.complete();
            return resultSubject;
        }
        this.loading = true;
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.datasetService.get('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (response) {
                    _this.selectOptions.push({
                        value: _this.inputService.formInputDefinition.remoteOptions.getValue(response),
                        label: _this.inputService.formInputDefinition.remoteOptions.getLabel(response, _this.inputService.dataContainerService)
                    });
                }
                _this.loading = false;
                resultSubject.next(response);
                resultSubject.complete();
            },
            error: function (response) {
                _this.loading = false;
                resultSubject.error(response);
                resultSubject.complete();
            }
        });
        return resultSubject;
    };
    DatasetSelectInputComponent.prototype.clear = function () {
        this.inputService.control.setValue(null);
        this.inputService.control.markAsDirty();
    };
    DatasetSelectInputComponent.prototype.openedChange = function (isOpen) {
        this.myInput.nativeElement.focus();
        /*if (isOpen) {
            this.appService.emitEvent('open-menu');
        } else {
            this.appService.emitEvent('close-menu');
        }*/
    };
    DatasetSelectInputComponent.prototype.selectionChange = function (event) {
        this.appService.eventEmitter.emit({
            name: 'selected_label_' + this.inputService.formInputDefinition.key,
            target: event.source['triggerValue']
        });
    };
    DatasetSelectInputComponent.prototype.emitEventOnSupportCreation = function (event) {
        this.appService.eventEmitter.emit({
            name: 'clickOnSupportCreation_' + this.inputService.formInputDefinition.key,
            target: event
        });
    };
    return DatasetSelectInputComponent;
}(DestroyableComponent));
export { DatasetSelectInputComponent };
