import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
	TangerineBgColors,
} from 'app/interfaces';
import { fieldsMap } from './fields';
import generalForm from './general-form';
import { TripCategoryLanguageContentConfig } from '../trip_category_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import activeLanguageCodesField from 'app/configs/commons/shared-field-configs/active-language-codes.field';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_categories/detail/:recordId',
};

export const TripCategoryConfig: IDatasetConfig = {
	name: 'trip_categories',
	title: 'Categorie',
	singleTitle: 'Categoria',
	ajaxDatasetCode: 'trip_categories',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
	},
	formConfigsMap: {
		generalForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'packages_count',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice / Stato',
						key: 'code_status_view',
						onClick(
							record,
							resourceACS,
							resourceNavigatorService
						): void {
							resourceNavigatorService.showDetail(
								resourceACS,
								record
							);
						},
					},
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: '#Pacchetti',
						key: 'packages_count',
					},
					{
						title: 'Traduzioni Atttive',
						key: 'active_language_codes',
						fieldDefinition: activeLanguageCodesField
					}
				],
				actions: ['create', 'detail', 'delete'],
				filterForm: {
					fields: [
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('active_status_filter')
							]
						}
					]
				}
			},
		},
	},
	stepViewConfig: {
		tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
		title: 'Categoria',
		subtitle(component: CommonStepViewContainerComponent): string {
			if (!component.record) return;
			return component.record.description;
		},
		isLinear(component: CommonStepViewContainerComponent): boolean {
			return !Boolean(component.record);
		},
		steps: [
			{
				title: 'Informazioni Base',
				stepperTitle: 'Informazioni <br> Base',
				component: 'common-form-step',
				options: { formConfig: generalForm },
			},
			{
				title: 'Descrizioni',
				stepperTitle: 'Descrizioni',
				component: 'common-i18n-form-step',
				options: {
					formConfig:
						TripCategoryLanguageContentConfig.formConfigsMap[
							'textForm'
						],
					datasetCode: 'trip_category_language_contents',
					parentForeignKey: 'trip_category_id',
					parentValueKey: 'trip_categories.recordId',
				},
			},
			{
				title: 'Media',
				stepperTitle: 'Media',
				component: 'common-i18n-blocks-step',
				options: {
					datasetCode: 'trip_category_language_contents',
					parentForeignKey: 'trip_category_id',
					parentValueKey: 'trip_categories.recordId',
					blocks: [
						{
							type: 'document_file',
							title: 'Immagine Copertina',
							documentFileKey: 'properties_master_image_id',
							uploadType: 'standard',
							imageOptions: {
								minWidth: 1920,
								minHeight: 400,
								ratio: 1920 / 400,
							},
						},
						{
							type: 'document_file',
							title: 'Immagine Cose da fare',
							documentFileKey: 'properties_things_to_do_image_id',
							uploadType: 'standard',
							imageOptions: {
								minWidth: 470,
								minHeight: 600,
								ratio: 470 / 600,
							},
						},
					],
				},
			},
			{
				title: 'Integrazioni',
				component: 'trip-category-integrations-step',
				showIf(container: CommonStepViewContainerComponent): boolean{
					const record = container.record;
					if(!record) return true;
					return true;
				}
			}
		],
	},
};
