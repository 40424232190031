/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../common.style.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../mat-table/mat-table.component.ngfactory";
import * as i3 from "../../../../../../services/dataset-navigator.service";
import * as i4 from "../../../../../../../../../services/app.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../services/dataset.service";
import * as i8 from "../../../mat-table/mat-table.component";
import * as i9 from "../../../../../../services/step-state.service";
import * as i10 from "./step.component";
import * as i11 from "../../../../../../services/dataset-record.service";
import * as i12 from "../../../../../../services/dataset-action-container.service";
import * as i13 from "@angular/forms";
var styles_TripResourceTripResourcesComponent = [i0.styles];
var RenderType_TripResourceTripResourcesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripResourceTripResourcesComponent, data: {} });
export { RenderType_TripResourceTripResourcesComponent as RenderType_TripResourceTripResourcesComponent };
export function View_TripResourceTripResourcesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "section title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Elenca i prodotti che si vogliono associare a questo bundle "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "custom-mat-table", [], null, [[null, "nextStep"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextStep" === en)) {
        var pd_0 = (_co.goNextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomMatTableComponent_0, i2.RenderType_CustomMatTableComponent)), i1.ɵprd(512, null, i3.DatasetNavigatorService, i3.DatasetNavigatorService, [i4.AppService, i5.MatDialog, i6.Router, i1.Injector, i7.DatasetService, i1.NgZone]), i1.ɵdid(7, 245760, null, 0, i8.CustomMatTableComponent, [i4.AppService, i7.DatasetService, i3.DatasetNavigatorService, i9.StepService], { relation: [0, "relation"], displayedColumns: [1, "displayedColumns"], filterFormGroup: [2, "filterFormGroup"], field: [3, "field"], datasetACS: [4, "datasetACS"], datasetRS: [5, "datasetRS"], dynamCols: [6, "dynamCols"], stepName: [7, "stepName"], datasetConfig: [8, "datasetConfig"], formConfigMap: [9, "formConfigMap"], datasetCode: [10, "datasetCode"], stepper: [11, "stepper"], selectedIndex: [12, "selectedIndex"], initialValueKey: [13, "initialValueKey"] }, { nextStep: "nextStep" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "childrenResources"; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.filterFormGroup; var currVal_3 = _co.field; var currVal_4 = _co.datasetACS; var currVal_5 = _co.datasetRS; var currVal_6 = _co.dynamCols; var currVal_7 = "product-bundle"; var currVal_8 = _co.datasetConfig; var currVal_9 = _co.formConfig; var currVal_10 = "products"; var currVal_11 = _co.stepper; var currVal_12 = 9; var currVal_13 = "description"; _ck(_v, 7, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
export function View_TripResourceTripResourcesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "product-bundle", [], null, null, null, View_TripResourceTripResourcesComponent_0, RenderType_TripResourceTripResourcesComponent)), i1.ɵdid(1, 4440064, null, 0, i10.TripResourceTripResourcesComponent, [i11.DatasetRecordService, i12.DatasetActionContainerService, i3.DatasetNavigatorService, i13.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripResourceTripResourcesComponentNgFactory = i1.ɵccf("product-bundle", i10.TripResourceTripResourcesComponent, View_TripResourceTripResourcesComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", datasetConfig: "datasetConfig" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { TripResourceTripResourcesComponentNgFactory as TripResourceTripResourcesComponentNgFactory };
