import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Abilitazione Twilio SMS',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('sid', {
	key: 'sid',
	name: 'Account SID',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('token', {
	key: 'token',
	name: 'Auth Token',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('fromPhoneNumber', {
	key: 'fromPhoneNumber',
	name: 'From phone twilio number',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'twilio.sms';
	}
});

export {fieldsMap as twilioSMSFields};
