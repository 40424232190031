import { IFormDialogData, FormDialogComponent } from '../dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes, IMeResponse } from 'app/interfaces';
import { DatasetService } from '../components/dataset/services/dataset.service';
import { LoadingDialogComponent } from '../dialogs/loading-dialog/dialog.component';
import { FileBlockInputComponent } from '../components/form-input-viewer/inputs/file-block/input.component';

export async function action_edit_profile(record: any, datasetService: DatasetService, data?: any): Promise<any>{
	const dialogRef = datasetService.matDialog.open<any, IFormDialogData, any>(FormDialogComponent, {
		panelClass: 'custom-dialog-container',
		minWidth: 400,
		data: {
			formData: data || {
				name: record.name.split(' ')[0],
				surname: record.name.split(' ')[1],
				email: record.email
			},
			title: 'Modifica dati',
			formConfig: {
				fields: [
					{
						key: 'profile_image_id',
						name: 'Immagine di profilo utente',
						valueType: ValueTypes.REFERENCE,
						inputRequired: false,
						inputConfig: {
							type: InputTypes.FILE_BLOCK,
							fileBlockConfig: {
								record: record,
								uploadRoute: '/auth/me/image-profile',
								imageOptions: {minWidth: 1024, minHeight: 768, ratio: 1024 / 768},
								uploadData: {recordId: record.id},
								changed(event: any): void{
									datasetService.appService.appEvent.next({
										name: 'global_image_profile',
										target: event.profile_image_id
									});
								}
							}
						}
					},
					{
						key: 'name',
						name: 'Nome',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.TEXT
						}
					},
					{
						key: 'surname',
						name: 'Cognome',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.TEXT
						}
					},
					{
						key: 'email',
						name: 'Email',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.EMAIL
						}
					}
				]
			},
		}
	});
	// after closed
	const formResult = await dialogRef.afterClosed().toPromise();
	if(!formResult) return;
	if (!formResult.name || !formResult.surname || !formResult.email){
		datasetService.appService.showErrorMessage('Dati insufficienti.');
		// reopen dialog
		return action_edit_profile(record, datasetService);
	}

	const loadingDialogRef2 = datasetService.matDialog.open(LoadingDialogComponent, {
		data: {
			title: 'Aggiornamento in corso'
		}
	});

	try{
		const response = await datasetService.post<any>('/auth/me/edit-profile', {
			id: record.id,
			name: formResult.name,
			surname: formResult.surname,
			email: formResult.email,
			profile_image_id: JSON.stringify(formResult.profile_image_id)
		}).toPromise();

		let responseMessage = '';
		if(response.message){
			responseMessage = response.message;
		}
		if(response.success){
			datasetService.appService.showSuccessMessage(responseMessage || 'Modifica avventuta con successo');
		}else{
			if(!responseMessage) responseMessage = 'Si è verificato un problema durante la richiesta dell\'utente.';
			datasetService.appService.showErrorMessage(responseMessage);
		}
		return {
			httpResp: response,
			dialog: loadingDialogRef2
		}
	}catch(e){
		let errorMessage = 'Si è verificato un errore';
		if(e.error && e.error.errors){
			let keyIndex = 0;
			const keys = Object.keys(e.error.errors);
			let key = keys[keyIndex];
			while(keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e.error.errors, key)){
				keyIndex++;
				key = keys[keyIndex];
			}
			if(e.error.errors[key]){
				if(Array.isArray(e.error.errors[key])) errorMessage = e.error.errors[key][0];
				else if(typeof(e.error.errors[key]) === 'string') errorMessage = e.error.errors[key];
			}
		}
		datasetService.appService.showErrorMessage(errorMessage);
		loadingDialogRef2.close();
		return action_edit_profile(record, datasetService, {
			name: formResult.name,
			surname: formResult.surname,
			email: formResult.email,
			profile_image_id: formResult.profile_image_id
		});
	}
}
