import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractService } from './abstract.service';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppService } from 'app/services/app.service';
import { IMeResponse } from 'app/interfaces';
import { setUserNavigation } from 'app/helpers/navigation.helper';
import { DomainFilterService } from './domain-filter.service';
import { WebSocketService } from './websocket.service';

export enum CheckUserStatues{
	NONE, CHECKING, CHECKED
}

@Injectable({
	providedIn: 'root'
})
export class AuthService extends AbstractService{

	public chekcUserStatus: BehaviorSubject<CheckUserStatues> = new BehaviorSubject(CheckUserStatues.NONE);
	public userDataObserver: BehaviorSubject<IMeResponse> = new BehaviorSubject(null);
	public desiredPath: string = null;
	public desiredQueryParams = null;

	constructor(
		protected http: HttpClient,
		protected appService: AppService,
		private _fuseNavigationService: FuseNavigationService,
		public router: Router,
		private domainFilterService: DomainFilterService, 
		private webSocketService: WebSocketService
	){
		super(http, appService);
	}

	init(): void{
		this.userDataObserver
		.subscribe((data: IMeResponse) => {
			if(data && data.user){
				if(typeof(window['FreshworksWidget']) === 'function'){
					window['FreshworksWidget']('identify', 'ticketForm', {
						name: data.user.name,
						email: data.user.email,
					});

					window['FreshworksWidget']('prefill', 'ticketForm', {
						custom_fields: {
							single_domain_id: data.single_domain_id
						}
					});
				}
			}

			this.appService.me = data;
		});

		this.domainFilterService.domainSettingChangeEvent
		.subscribe((event) => {
			if(!this.userDataObserver.value) return;
			const domainId = this.domainFilterService.filterDomainId.value || 'null';
			if(!event.domain_id || event.domain_id != domainId) return;
			const domainSetting = this.domainFilterService.getDomainSetting(domainId);
			setUserNavigation(this._fuseNavigationService, this.userDataObserver.value, domainSetting);
		});
	}

	showAdminMenu(): boolean{
		const data = this.userDataObserver.getValue();
		return data && data.user && data.user.role && data.user.role.level < 10;
	}

	isAuthenticated(): boolean{
		return Boolean(this.userDataObserver.getValue());
	}

	getMyId(): string{
		if(!this.userDataObserver.getValue()) return null;
		return this.userDataObserver.getValue().user.id;
	}

	updateUserInfo(): void{
		this.chekcUserStatus.next(CheckUserStatues.CHECKING);
		this.http.get<IMeResponse>(this.appService.getBaseServerAddress() + '/auth/me')
		.pipe(
			catchError(this.handleError('auth/me', true))
		).subscribe({
			next: (response: IMeResponse) => {
				this.chekcUserStatus.next(CheckUserStatues.CHECKED);
				if(response){
					this.webSocketService.isAuthenticated.next(true);
					this.userDataObserver.next(response);
					if(response) this.domainFilterService.authLoaded = true;
					if(response.domain_settings){
						for (const key in response.domain_settings) {
							if (!Object.prototype.hasOwnProperty.call(response.domain_settings, key)) continue;
							const element = response.domain_settings[key];
							this.domainFilterService.domainSettings.set(key, element);
						}
					}

					if(response.single_domain_id){
						this.domainFilterService.filterDomainId.next(response.single_domain_id);
					}
					this.domainFilterService.loadDomainSettings(this.domainFilterService.filterDomainId.value);
					this.domainFilterService.showFilterBar = response.showFilterBar;
					this.appService.setLogo(response.srcData);
					this.domainFilterService.refreshDomainData();
					return;
				}
			},
			error: (err) => {
				console.error(err);
				this.chekcUserStatus.next(CheckUserStatues.CHECKED);
			}
		});
	}

	async login(credentials: any): Promise<void>{
		const options = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json'
			})
		};
		return new Promise<any>((resolve, reject) => {
			this.http.post<any>(this.appService.getBaseServerAddress() + '/auth/login', credentials, options)
			.pipe(
				catchError(this.handleError('auth/login'))
			).subscribe(response => {
				if(response){
					resolve(response);
					return;
				}
				reject('invalid response');
			}, err => {
				reject(err);
			});
		}).then(() => {
			this.updateUserInfo();
		});
	}

	async logout(): Promise<void>{
		return new Promise<any>((resolve, reject) => {
			this.http.post<any>(this.appService.getBaseServerAddress() + '/auth/logout', null)
			.pipe(
				catchError(this.handleError('auth/logout'))
			).subscribe(response => {
				if(response){
					this.userDataObserver.next(null);
					resolve(null);
					return;
				}
				reject('invalid response');
			}, err => {
				reject(err);
			});
		}).then(() => {
			this.domainFilterService.clear();
			
			// set user state for websocket
			this.webSocketService.isAuthenticated.next(false);

			this.router.navigate(['auth/login']);
		});
	}

	check(): void{
		this.updateUserInfo();
	}

	isAdministrator(): boolean{
		if(!this.userDataObserver.value) return false;
		return this.userDataObserver.value.user.role.level < 10;
	}

	resetPasswordByEmail(email): Observable<any>{
		return this.http.post<any>(this.appService.getBaseServerAddress() + '/auth/password/email', {email})
		.pipe(
			catchError(this.handleError('password/logout'))
		);
	}

	resetPassword(data): Observable<any>{
		return this.http.post<any>(this.appService.getBaseServerAddress() + '/auth/password/reset', data)
		.pipe(
			catchError(this.handleError('password/logout'))
		);
	}

	isActionPermitted(actionCode: string, group: string): boolean{
		if(!this.userDataObserver.value || !this.userDataObserver.value.permissions) return false;

		for (const groupPermission of this.userDataObserver.value.permissions) {
			if(groupPermission.code === group){
				if(groupPermission.access_level <= 0) return false;
				if(groupPermission.access_level === 1) return ['view', 'view_detail'].includes(actionCode);
				if(groupPermission.access_level === 2) return ['view', 'view_detail', 'create', 'edit'].includes(actionCode);
				if(groupPermission.access_level >= 3) return true;
			}
		}

		return false;
	}
}
