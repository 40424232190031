import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap} from '@angular/router';
import {Observable, throwError, forkJoin} from 'rxjs';
import {default as DatasetConfigurations} from 'app/configs/datasets';
import { catchError, map } from 'rxjs/operators';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {IDatasetConfig, IDatasetCallDefinition} from 'app/interfaces';
import {AppService} from 'app/services/app.service';

@Injectable()
export class DatasetResolver implements Resolve<any>{

	constructor(
		private datasetService: DatasetService,
		private appService: AppService,
		private router: Router
	){}

	private toMap(paramMap: ParamMap): Map<string, any>{
		const keys = paramMap.keys;
		const mapResult = new Map();
		for (const key of keys) {
			mapResult.set(key, paramMap.get(key));
		}
		return mapResult;
	}

	resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
		let datasetConfig: IDatasetConfig = null;
		let datasetCode = '';

		const calls: Observable<any>[] = [];

		if(router.paramMap && router.paramMap.has('datasetCode')){
			datasetCode = router.paramMap.get('datasetCode');
			datasetConfig = DatasetConfigurations[datasetCode];
		}
		if(!datasetConfig){
			return throwError('Configuration /' + datasetCode + '/ not found!')
			.pipe(catchError((err, caught) => {
				return this.onError(err);
			}));
		}
		const action = router.data.action;
		let beforeCalls: IDatasetCallDefinition[];
		if(datasetConfig.defaultActions[action]){
			beforeCalls = datasetConfig.defaultActions[action].beforeCalls;
		}

		if (action === 'create' && datasetConfig.supportTemplates) {
			calls.push(this.getUserDomain(datasetCode));
		}
		calls.push(this.datasetService.fetchSourceData(datasetConfig, beforeCalls, this.toMap(router.paramMap)));

		return forkJoin(calls)
		.pipe(
			map((result) => {
				const mappedResult: any = {};
				result.forEach(function(item: any, key: any): any {
					const keys = Object.keys(item);

					keys.forEach(function(itemKey: any): any {
						mappedResult[itemKey] = item[itemKey];
					});
				});
				return mappedResult;
			}),
			catchError((err, caught) => {
				return this.onError(err);
			})
		);
	}

	getUserDomain(datasetCode: string): Observable<any> {
		return this.datasetService.get<any>('/dataset/' + datasetCode + '/beforeCreate')
		.pipe(catchError((err, caught) => {
			return this.onError(err);
		}));
	}

	onError(err): Observable<any>{
		console.warn('error while resolve:', err);
		this.appService.showErrorMessage('Si è verificato un errore nel recupero dei dati.');
		// this.router.navigate(['/home']);
		return throwError(err);
	}
}
