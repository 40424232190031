import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import {fieldsMap} from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				name: 'Da',
				key: 'date_from',
				valueType: ValueTypes.DATE,
				inputConfig: {
					type: InputTypes.DATE,
					clearable: true
				}
			},
			{
				key: 'date_to',
				name: 'Fino a',
				valueType: ValueTypes.DATE,
				inputConfig: {
					type: InputTypes.DATE,
					clearable: true
				}
			}
		]
	},
	{
		key: 'status',
		name: 'Stato',
		valueType: ValueTypes.NUMBER,
		inputType: InputTypes.SELECT,
		options: [
			{
				label: 'Tutte',
				value: 0
			},
			{
				label: 'Annullate',
				value: 1
			},
			{
				label: 'Confermate',
				value: 2
			}
		]
	},
	fieldsMap.get('branch_code')
];

const filterForm: IFormConfig = { 
	editTitle: 'Filtri',
	fields: formFields 
};

export default filterForm;
