import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('date_from', {
	key: 'date_from',
	name: 'A iniziare da',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	extra: {
		dateFilter(values: any, d: Date): boolean{
			if(!values.date_to) return true;
			if(d > values.date_to) return false;
			return true;
		}
	}
});

fieldsMap.set('date_to', {
	key: 'date_to',
	name: 'Fino a',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	extra: {
		dateFilter(values: any, d: Date): boolean{
			if(!values.date_from) return true;
			if(d < values.date_from) return false;
			return true;
		}
	}
});

fieldsMap.set('date', {
	key: 'date',
	name: 'Data',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE
});

fieldsMap.set('entity_staff_element_unified_list_item_id', {
	key: 'entity_staff_element_unified_list_item_id',
	name: 'Staff',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_staff_element_unified_list_items',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {};
			params['trip_resource_id'] = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
			return params;
		},
		getLabel(option): any{
			if(!option || !option.entity_staff_element) return '';
			return option.entity_staff_element.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
});

fieldsMap.set('staff_code', {
	key: 'staff_code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any, ): string{
		if(!record || !record.staff_list_item || !record.staff_list_item.entity_staff_element) return '';
		return record.staff_list_item.entity_staff_element.code;
	}
});

fieldsMap.set('staff_description', {
	key: 'staff_description',
	name: 'Descrizione',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any, ): string{
		if(!record || !record.staff_list_item || !record.staff_list_item.entity_staff_element) return '';
		return record.staff_list_item.entity_staff_element.entity.description;
	}
});

export {fieldsMap};
