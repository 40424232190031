import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('phone_number', {
    key: 'phone_number',
    name: 'Tel',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('fiscalcode', {
    key: 'fiscalcode',
    name: 'Codice Fiscale',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('piva', {
    key: 'piva',
    name: 'Partita Iva',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('gender', {
    key: 'gender',
    name: 'Sesso',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('email', {
    key: 'email',
    name: 'Indrizzo Email',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('address_street', {
    key: 'address_street',
    name: 'Indirizzo',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('nation_code', {
    key: 'nation_code',
    name: 'Nazione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: 'Scenario',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	defaultValue() {
		return 'participants';
	}
});

fieldsMap.set('index', {
	key: 'index',
	name: 'Index',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
});

fieldsMap.set('original_id', {
	key: 'original_id',
	name: 'ID',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
});

export {fieldsMap};
