import {InputTypes, ValueTypes, IFieldDefinition} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [Validators.required]
});

fieldsMap.set('auto_apply', {
	key: 'auto_apply',
	name: 'Applica automaticamente',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	],
	inputRequired: true,
	formValidators: [Validators.required]
});

fieldsMap.set('coupon_code', {
	key: 'coupon_code',
	name: 'Codice Coupon',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('total', {
	key: 'total',
	name: 'Quantità',
	valueType: ValueTypes.NUMBER,
	disallowNull: false,
	defaultValue: () => null,
	inputConfig: {
		hint: 'Se non definito è senza limite',
		type: InputTypes.NUMBER,
		clearable: true,
	}
});

fieldsMap.set('used', {
	key: 'used',
	name: 'Utilizzato',
	valueType: ValueTypes.NUMBER,
	inputReadOnly: true,
	inputHint: 'Non modificabile'
});

fieldsMap.set('valid_from', {
	key: 'valid_from',
	name: 'Valido dal',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE
	}
});

fieldsMap.set('valid_to', {
	key: 'valid_to',
	name: 'Valido fino al',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE
	}
});

fieldsMap.set('mode', {
	key: 'mode',
	name: 'Modalità',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.SELECT,
		clearable: true
	},
	options: [
		{
			label: 'Per ogni partecipante',
			value: 'every_participant'
		},
		// anche dobbiamo fargli impostare per quale partecipante ADT, CHD, ...
		/*{
			label: 'Per partecipante',
			value: 'participant'
		},*/
		{
			label: 'Singolo',
			value: 'single'
		}
	]
});

fieldsMap.set('discount_rule_list_id', {
	key: 'discount_rule_list_id',
	name: 'Listino Sconti',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Seleziona un listino predefinito',
	datasetCode: 'discount_rule_lists',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	},
	clickOptions: {
		openDetailDatasetCode: 'discount_rule_lists',
		getDetailRecordId(record): string{
			return record.discount_rule_list_id;
		}
	}
});

export {fieldsMap};
