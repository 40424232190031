<div class="flex">
	<div class="flex-item">
		<div class="loading-div" *ngIf='dashboardService.loadingTravelDate || showLoader'>
			<mat-spinner *ngIf='dashboardService.loadingTravelDate' diameter="80" class='full-center-spinner'></mat-spinner>
		</div>
		<div style="display: block;">
			<canvas baseChart width="400" height="400" *ngIf='ticketsyear'
				[datasets]="ticketsyear"
				[labels]="barChartLabels" 
				[options]="barChartOptions"
				[legend]="barChartLegend" 
				[chartType]="barChartType"
			></canvas>
		</div>
	</div>
</div>