import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews, IDatasetCallDefinition } from '../../../interfaces';
import {fieldsMap} from './fields';
import viewForm from './forms/view.form';
import editForm from './forms/edit.form';
import createForm from './forms/create.form';
import { IDataContainerService } from '../../../interfaces/data-container.interface';
import { DatasetNavigatorService } from '../../../main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ActualTripGroupResourcesComponent } from 'app/main/components/dataset/dialogs/actual-trip-group-resources/dialog.component';

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const filters = dataContainerService.getValueFromKeyPath('actual_trip_groups.filters');
	params['trip_package_id'] = filters['trip_package_id'];
	params['tour_operator_code'] = filters['tour_operator_code'];
	return params;
}

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/actual_trip_groups/detail/:recordId',
	params: {
		with_relations: 'includedTripResources'
	}
};

export const ActualTripGroupConfig: IDatasetConfig = {
	name: 'actual_trip_groups',
	title: 'Optioni Partenze',
	singleTitle: 'Opzione Partenza',
	ajaxDatasetCode: 'actual_trip_groups',
	fieldsMap: fieldsMap,
	supportProperties: false,
	fetchConfig: {
		with_relations: 'includedTripResources'
	},
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		viewForm,
		editForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: viewForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: createForm
			},
			getCallParams
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
			getCallParams
		},
		list: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'code',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Nome',
						key: 'name'
					},
					{
						title: 'Risorse',
						key: 'id',
						fieldDefinition: {
							name: 'Risorse opzioni partenze',
							key: 'included_trip_resources',
							valueType: ValueTypes.ARRAY,
							getDisplayValue(): string {
								return 'Apri';
							},
						},
						actionCode: 'noaction',
						onClick(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void {
							datasetNavigatorService.openDialog(ActualTripGroupResourcesComponent, {
								parentDatasetACS: datasetACS,
								parentRecordId: record.id,
								parentFieldKey: 'actual_trip_group_id',
							})
							.afterClosed()
							.subscribe(() => {
								datasetACS.datasetEvent.next({
									eventName: DatasetEvents.ACTION_REALOAD_LIST,
								});
							});
						},
					},
				],
				actions: ['create', 'edit', 'delete'],
			}
		}
	}
};
