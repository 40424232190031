import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';

const form: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('trip_package_id'),
	fieldsMap.get('entity_customer_id'),
	fieldsMap.get('disable_export_sale_operation'),
];

export default {
	fields: form 
};
