<div class="page-layout p-24 blank marketplace-tour-operators" style="padding-bottom: 80px !important;">
	<mat-progress-bar *ngIf='loading' class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
	<div class="message-settings-api" *ngIf='!domainHasBokunAccount && !loading'>
		<h2>Credenziali Bokun</h2>
		<p>Per rivendere i tour presenti su Bokun è necessario un account vendor con accesso al marketplace.</p>
		<!-- redirect alla pagina dettaglio dominio da dove configura le chiavi API -->
		<p>Configura le tue credenziali <a [routerLink]='["/dataset/detail/domains/" + this.domainFilterService.filterDomainId.value]' [queryParams]='{step: 4}'>qui</a>.</p>
	</div>
	<div *ngIf='domainHasBokunAccount && !loading'>
		<dataset-list 
			datasetCode='bokun_tours'
			tableStyle='style-1 selectable btn-outlined'
		></dataset-list>
	</div>
</div>