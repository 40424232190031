<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<h2 *ngIf='datasetRS.record.value.properties.price.value'>Prezzo pacchetto a partire da <b>{{datasetRS.record.value.properties.price.value | currency:'EUR'}}</b></h2>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<dataset-list
			#tripList
			datasetCode='actual_trips'
			[showTitle]='false'
			[parentDatasetRS]='datasetRS'
			[parentDatasetACS]='datasetACS'
			[showSearchBar]='true'
			[enableCreate]='false'
			[enableDelete]='false'
			[enableEdit]='false'
			[elevate]='false'
			[emitAdd]='true'
			[addButtonText]='"Genera"'
			[filters]='{ trip_package_id: datasetRS.recordId, start_day: todayFilter }'
			(add)='generate()'
		></dataset-list>
		
	</div>
</div>