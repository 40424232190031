import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer | IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('category_service'),
	fieldsMap.get('description'),
	fieldsMap.get('publish_on_web'),
	fieldsMap.get('address.nation_code'),
	fieldsMap.get('address.region'),
	fieldsMap.get('address.city'),
	fieldsMap.get('address.locality'),
	fieldsMap.get('address.note'),
];

const config: IFormConfig = {
	title: 'Crea Destinazione',
	editTitle: 'Modifica Destinazione',
	fields: formFields,
};

export default config;
