import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			{
				key: 'start_from',
				name: 'Dal giorno',
				inputType: InputTypes.DATE,
				valueType: ValueTypes.DATE,
			}
		]
	}
];

const filterFormConfig: IFormConfig = {
	fields
};

export default filterFormConfig;
