import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetService } from "app/main/components/dataset/services/dataset.service";
import { AppService } from "app/services/app.service";
import { DomainFilterService } from "app/services/domain-filter.service";
import { takeUntil } from "rxjs/operators";
import { BookingService } from "../../booking.service";
import { RELOAD_BOOKING_PACKAGE } from "../../edit-booking.component";
import { TripBookingPackagesStepService } from "../trip-booking-packages-step.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackageService } from "./trip-booking-package.service";
var TripBookingPackageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingPackageComponent, _super);
    function TripBookingPackageComponent(appService, datasetRS, datasetACS, parentDatasetRS, parentDatasetACS, datasetNavigatorService, datasetService, matDialog, domainFilterService, tripBookingPackagesStepService, bookingService, tripBookingPackageService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.domainFilterService = domainFilterService;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.bookingService = bookingService;
        _this.tripBookingPackageService = tripBookingPackageService;
        return _this;
    }
    TripBookingPackageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: 'trip_booking_packages',
            actionCode: 'detail',
            useBeforeCalls: false,
            parentDatasetRS: this.parentDatasetRS,
            parentDatasetACS: this.parentDatasetACS,
            datasetRS: this.datasetRS
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
        });
        this.appService.appEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.name === RELOAD_BOOKING_PACKAGE && event.extra.trip_booking_package_id === _this.tripBookingPackage.id) {
                _this.datasetRS.reload();
            }
        });
        this.tripBookingPackageService.iterationData = this.iterationData;
    };
    TripBookingPackageComponent.prototype.ngOnChanges = function (changes) {
        if (changes.tripBookingPackage) {
            if (!this.datasetRS.record.value) {
                this.datasetRS.record.next(this.tripBookingPackage);
            }
            else if (this.tripBookingPackage && this.tripBookingPackage.updated_at > this.tripBookingPackage.updated_at) {
                this.datasetRS.record.next(this.tripBookingPackage);
            }
        }
        if (changes.iterationData) {
            this.tripBookingPackageService.iterationData = this.iterationData;
        }
    };
    return TripBookingPackageComponent;
}(DestroyableComponent));
export { TripBookingPackageComponent };
