import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { OnDestroy, OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { TrenitaliaService } from '../../trenitalia.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { Subject, EMPTY, of, } from 'rxjs';
import * as _ from 'lodash';
import { catchError, exhaustMap, takeUntil } from 'rxjs/operators';
import { DatasetService } from '../../../../../components/dataset/services/dataset.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { FormBuilder } from '@angular/forms';
var BillingFormComponent = /** @class */ (function () {
    function BillingFormComponent(datasetACS, _trnService, formViewerService, datasetService, matDialog, formBuilder) {
        this.datasetACS = datasetACS;
        this._trnService = _trnService;
        this.formViewerService = formViewerService;
        this.datasetService = datasetService;
        this.matDialog = matDialog;
        this.formBuilder = formBuilder;
        this.fields = formFields;
        this.billingMode = "LEGAL"; // LEGAL or INDIVIDUAL
        this.invoiceProfiles = [];
        this.loading = false;
        this.searchFromGroup = null;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(BillingFormComponent.prototype, "isInvoiceProfiles", {
        get: function () {
            return this.invoiceProfiles.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BillingFormComponent.prototype, "creaNewProfile", {
        get: function () {
            if (!this.searchFromGroup)
                return false;
            var controll = this.searchFromGroup.get('create_new_profile');
            return controll && controll.value;
        },
        enumerable: true,
        configurable: true
    });
    BillingFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchFromGroup = this.formBuilder.group({
            p_iva_search: this.formBuilder.control(null),
            create_new_profile: this.formBuilder.control(false)
        });
        this.searchFromGroup.get('create_new_profile').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this.creaNewProfile) {
                _this.invoiceProfiles = [];
            }
        });
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values()).filter(function (field) { return !['p_iva_search', 'create_new_profile'].includes(field.key); })
        });
        this.formViewerService.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (v) {
            _this.updateCheckoutData();
        });
        if (this.billingData && this.billingData.personType == 'LEGAL') {
            this.formViewerService.setValues({
                personType: _.get(this.billingData, 'personType'),
                profile_name: _.get(this.billingData, 'name'),
                id_invoice_profile: _.get(this.billingData, 'id.id', null),
                business_name: _.get(this.billingData, 'businessName'),
                p_iva: _.get(this.billingData, 'vatNumber'),
                corporate_tax_code: _.get(this.billingData, 'corporate_tax_code'),
                country: _.get(this.billingData, 'address.country'),
                province: _.get(this.billingData, 'address.province'),
                city: _.get(this.billingData, 'address.city'),
                cap: _.get(this.billingData, 'address.postalCode'),
                address: _.get(this.billingData, 'address.address'),
                recipient_code: _.get(this.billingData, 'recipient_code'),
                pec: _.get(this.billingData, 'certifiedMail'),
            });
            if (_.get(this.billingData, 'id', false)) {
                this.searchFromGroup.get('create_new_profile').setValue(false);
            }
        }
    };
    BillingFormComponent.prototype.updateCheckoutData = function () {
        // set this.checkoutData.invoiceProfile
        // is sufficient the id if just exists
        var formData = this.formViewerService.getFormData();
        this._trnService.checkoutData.value.billingFormValid = this.formViewerService.formGroup.valid;
        this._trnService.checkoutData.next(Object.assign(this._trnService.checkoutData.getValue(), {
            billingFormValid: this.formViewerService.formGroup.valid,
            billingFormData: formData,
        }));
    };
    BillingFormComponent.prototype.onSearchProfile = function () {
        var _this = this;
        var vatNumber = this.searchFromGroup.get('p_iva_search').value;
        if (!vatNumber)
            return;
        this.loading = true;
        return this.datasetService.post('/dataset/trenitalia/command/search_invoice_profiles', { vatNumber: vatNumber })
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(catchError(function (err) {
            _this.matDialog
                .open(TextDialogComponent, {
                data: {
                    title: 'Attenzione',
                    contentMessage: err.message
                }
            });
            return EMPTY;
        }), exhaustMap(function (response) {
            if (!response.length)
                return EMPTY;
            _this.invoiceProfiles = response.slice(0, 50);
            return of(response);
        }))
            .subscribe({
            next: function (resp) {
                // Disable input create new profile
                _this.searchFromGroup.get('create_new_profile').setValue(false);
                _this.loading = false;
            },
            error: function (response) {
                // todo: error handler
                _this.loading = false;
            }
        });
    };
    BillingFormComponent.prototype.onSelectInvoiceProfile = function (event) {
        var e_1, _a;
        var profile = event.value;
        var mapKey = {
            'id': 'id_invoice_profile',
            'name': 'profile_name',
            'businessName': 'business_name',
            'vatNumber': 'p_iva',
            'companyFiscalCode': 'corporate_tax_code',
            'address.country': 'country',
            'address.province': 'province',
            'address.city': 'city',
            'address.postalCode': 'cap',
            'address.address': 'address',
            'recipientCode': 'recipient_code',
            'certifiedMail': 'pec',
        };
        try {
            for (var _b = tslib_1.__values(Object.entries(mapKey)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), profileKey = _d[0], fieldKey = _d[1];
                var control = this.formViewerService.getFieldControl(fieldKey);
                if (!control)
                    continue;
                control.setValue(_.get(profile, profileKey));
                //control.disable();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    BillingFormComponent.prototype.resetForm = function () {
        var e_2, _a;
        var fieldsKey = [
            'id_invoice_profile',
            'profile_name',
            'business_name',
            'p_iva',
            'corporate_tax_code',
            'country',
            'province',
            'city',
            'cap',
            'address',
            'recipient_code',
            'pec',
        ];
        try {
            for (var fieldsKey_1 = tslib_1.__values(fieldsKey), fieldsKey_1_1 = fieldsKey_1.next(); !fieldsKey_1_1.done; fieldsKey_1_1 = fieldsKey_1.next()) {
                var fieldKey = fieldsKey_1_1.value;
                var control = this.formViewerService.getFieldControl(fieldKey);
                if (!control)
                    continue;
                control.setValue(null);
                control.enable();
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (fieldsKey_1_1 && !fieldsKey_1_1.done && (_a = fieldsKey_1.return)) _a.call(fieldsKey_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    BillingFormComponent.prototype.onChangeBillingMode = function (typeMode) {
        this.billingMode = typeMode;
    };
    BillingFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return BillingFormComponent;
}());
export { BillingFormComponent };
