import { ICalendarEventData } from 'app/interfaces/calendar-event.interface';

export const DATE_POCTUATION_REGEX = /-|:|\.\d+/g;
export const GOOGLE_CALENDAR_URL = 'https://www.google.com/calendar/render?action=TEMPLATE';

export function formatDescriptionForOnlineCalendar(description: string): string | null {
	if (description.length) {
		return description.replace(/\\r/g, '\n').replace(/\\n/g, '\n');
	}
	return null;
}

export function getHrefEventToCalendar(calendarEvent: ICalendarEventData): string{
	const startTimeFormatted = calendarEvent.startTime.toISOString().replace(DATE_POCTUATION_REGEX, '');
	const endTimeFormatted = calendarEvent.endTime ? calendarEvent.endTime.toISOString().replace(DATE_POCTUATION_REGEX, '') : startTimeFormatted;
	let chunk = encodeURI(
		`${GOOGLE_CALENDAR_URL}&dates=${startTimeFormatted}/${endTimeFormatted}`,
	);
	chunk += `&sprop=${encodeURIComponent(calendarEvent.url || '')}`;
	chunk += `&location=${encodeURIComponent(calendarEvent.address || '')}`;
	chunk += `&text=${encodeURIComponent(calendarEvent.title || '')}`;
	chunk += `&details=${encodeURIComponent(formatDescriptionForOnlineCalendar(calendarEvent.description || '') || '')}`;
	return chunk;
}
