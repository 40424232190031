import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { TrenitaliaService } from '../../trenitalia.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
var fieldKeyMap = {
    pass_first_name: 'name',
    pass_last_name: 'surname',
    pass_email: 'email',
    pass_data_n: 'birthDate',
    pass_phone: 'phoneNumber',
    pass_cf: 'cartaFreccia',
    contact_consent: 'contact_consent'
};
var travellerKeyMap = _.invert(fieldKeyMap);
export function travellerKeyToFieldKey(travellerKey) {
    return _.get(travellerKeyMap, travellerKey, travellerKey);
}
export function fieldKeyToTravellerKey(fieldKey) {
    return _.get(fieldKeyMap, fieldKey, fieldKey);
}
var PassengerFormComponent = /** @class */ (function () {
    function PassengerFormComponent(datasetACS, _trnService, formViewerService, tranitaliaservice) {
        this.datasetACS = datasetACS;
        this._trnService = _trnService;
        this.formViewerService = formViewerService;
        this.tranitaliaservice = tranitaliaservice;
        this.copyContactData = false;
        this.fields = formFields;
        this.valid = new EventEmitter();
        this.isReadOnly = false;
        this._unsubscribeAll = new Subject();
    }
    PassengerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values())
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            console.log(_this.passenger);
            if (!_.isEmpty(_this.passenger)) {
                _this.formViewerService.setValues({
                    pass_first_name: _.get(_this.passenger, 'dataPass.pass_first_name', ''),
                    pass_last_name: _.get(_this.passenger, 'dataPass.pass_last_name', ''),
                    pass_email: _.get(_this.passenger, 'dataPass.pass_email', ''),
                    pass_data_n: _.get(_this.passenger, 'dataPass.pass_data_n', null),
                    pass_phone: _.get(_this.passenger, 'dataPass.pass_phone', null),
                    pass_cf: _.get(_this.passenger, 'dataPass.pass_cf', null),
                    contact_consent: _.get(_this.passenger, 'dataPass.contact_consent', true)
                });
            }
            _this.setContactData();
        });
        this.tranitaliaservice.checkoutData
            .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged(_.isEqual))
            .subscribe(function (checkoutData) {
            if (!_.get(_this.passenger, 'travellerXmlId')) {
                console.warn('no travellerXmlId setted', _this.passenger);
                return;
            }
            var errors = _.get(checkoutData, 'invalid_traveller_parameters.' + _this.passenger.travellerXmlId);
            var changed = !_.isEqual(_this.invalidTravellerParameters, errors);
            _this.invalidTravellerParameters = errors;
            if (!errors) {
                if (changed) {
                    _this.formViewerService.setErrors(null);
                    _this.formViewerService.formGroup.updateValueAndValidity( /*{emitEvent: false}*/);
                }
                return;
            }
            var errorMessages = Object.entries(errors).reduce(function (result, _a) {
                var _b = tslib_1.__read(_a, 2), key = _b[0], paramData = _b[1];
                if (!_.isEmpty(_.get(paramData, 'validationMessages')))
                    result[travellerKeyToFieldKey(key)] = _.get(paramData, 'validationMessages');
                return result;
            }, {});
            _this.formViewerService.setErrors(errorMessages);
        });
        this.formViewerService.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (v) {
            if (_this.passenger.type === 'CHILDREN') {
                if (!_this.formViewerService.formGroup.get('pass_data_n').value) {
                    var errorData = { 'pass_data_n': ['Data obbligatoria'] };
                    _this.formViewerService.setErrors(errorData);
                }
                else {
                    /*Controllo età*/
                    /*const date = this.formViewerService.formGroup.get('pass_data_n').value;
                    const diff = moment().diff(date,'years');
                    if(diff > 15 || diff < 6){
                        const errorData={'pass_data_n':['Data non valida']};
                        this.formViewerService.setErrors(errorData);
                    }*/
                }
            }
            _this.sendData();
        });
        if (this.index == 0 && this.contactData) {
            this.contactData
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function (s) {
                if (!_this.copyContactData)
                    return;
                if (!_.isEmpty(s)) {
                    _this.setContactData();
                }
                else {
                    _this.formViewerService.formGroup.reset();
                }
            });
        }
    };
    PassengerFormComponent.prototype.disableInput = function (key, disable) {
        if (disable === void 0) { disable = true; }
        var control = this.formViewerService.getFieldControl(key);
        if (!control)
            return;
        if (disable)
            control.disable();
        else
            control.enable();
    };
    PassengerFormComponent.prototype.setContactData = function () {
        this.isReadOnly = this.index == 0 && this.copyContactData;
        if (this.index != 0 || !this.copyContactData)
            return;
        this.formViewerService.setValues({
            pass_first_name: this.contactData.value.first_name,
            pass_last_name: this.contactData.value.last_name,
            pass_email: this.contactData.value.email,
            pass_phone: this.contactData.value.phone
        });
    };
    PassengerFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.copyContactData) {
            this.setContactData();
        }
    };
    PassengerFormComponent.prototype.sendData = function () {
        console.log('sendData', this.index, this.formViewerService.formGroup.valid, this.formViewerService.getFormErrors());
        this.valid.emit({
            index: this.index,
            valid: this.formViewerService.formGroup.valid,
            data: this.formViewerService.getFormData()
        });
    };
    PassengerFormComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return PassengerFormComponent;
}());
export { PassengerFormComponent };
