
<div class="flex">
	<div class="loading-div" *ngIf='dashboardService.loadingReviewsStats || showLoader'>
		<mat-spinner *ngIf='dashboardService.loadingReviewsStats' diameter="80" class='full-center-spinner'></mat-spinner>
	</div>

	<div class="flex-item">
		<div style="display: block;">
			<canvas baseChart *ngIf='reviews'
				[datasets]="reviews"
				[labels]="barChartLabels" 
				[options]="barChartOptions"
				[legend]="barChartLegend" 
				[chartType]="barChartType"
			></canvas>
		</div>
	</div>
</div>