import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { HttpHeaders } from '@angular/common/http';
var DatasetAutocompleteInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetAutocompleteInputComponent, _super);
    function DatasetAutocompleteInputComponent(datasetService, appService, inputService) {
        var _this = _super.call(this) || this;
        _this.datasetService = datasetService;
        _this.appService = appService;
        _this.inputService = inputService;
        _this.loading = true;
        _this.supportCreate = false;
        _this.clearOnSelection = false;
        _this.creatingNew = false;
        _this.displayValue = '';
        _this.inputFormControl = new FormControl();
        _this.filteredOptions = new Subject();
        return _this;
    }
    DatasetAutocompleteInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.optionSelected = _this.inputService.formInputDefinition.optionSelected;
            if (_this.optionSelected && _this.inputService.formInputDefinition.remoteOptions) {
                var value = _this.inputService.formInputDefinition.remoteOptions.getValue(_this.optionSelected);
                _this.inputService.control.setValue(value);
            }
            if (_this.optionSelected) {
                _this.displayValue = _this.displayFun(_this.optionSelected);
            }
            if (_this.inputService.formInputDefinition.remoteOptions) {
                _this.supportCreate = Boolean(_this.inputService.formInputDefinition.remoteOptions.supportCreate);
                _this.clearOnSelection = Boolean(_this.inputService.formInputDefinition.remoteOptions.clearOnSelection);
            }
            _this.fetchFilteredOptions('');
            _this.initRemoteValue();
            _this.inputService.control
                .statusChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (status) {
                if (status === 'INVALID') {
                    _this.inputFormControl.setErrors({ invalid: _this.inputService.errorMessage });
                }
                else {
                    _this.inputFormControl.setErrors(null);
                }
                _this.inputFormControl.markAsTouched();
            });
            _this.inputService.control.valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (value) {
                var currentValue = _this.inputService.control.value;
                if (currentValue) {
                    if (!_this.optionSelected || currentValue !== _this.inputService.formInputDefinition.remoteOptions.getValue(_this.optionSelected)) {
                        var reason_1 = 'changed';
                        if (!_this.optionSelected) {
                            reason_1 = 'init';
                        }
                        _this.initRemoteValue(true)
                            .subscribe({
                            next: function (result) {
                                _this.inputService.onSet(_this.optionSelected, reason_1);
                            }
                        });
                        return;
                    }
                }
            });
        });
    };
    DatasetAutocompleteInputComponent.prototype.initRemoteValue = function (withLoading) {
        var _this = this;
        var resultSubject = new Subject();
        var control = this.inputService.control;
        if (!control) {
            resultSubject.complete();
            return resultSubject;
        }
        var currentValue = control.value;
        if (!currentValue) {
            resultSubject.complete();
            return resultSubject;
        }
        if (this.initRemoteValueSubscription) {
            this.initRemoteValueSubscription.unsubscribe();
        }
        var params = {};
        if (this.inputService.formInputDefinition.remoteOptions && typeof (this.inputService.formInputDefinition.remoteOptions.getParams) === 'function') {
            params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue(), 'init');
        }
        params['perPage'] = 1;
        if (!params['column_value'])
            params['column_value'] = currentValue;
        if (!params['column_name'])
            params['column_name'] = 'id';
        if (!params['sortBy'])
            params['sortBy'] = 'created_at';
        if (withLoading)
            this.loading = true;
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.initRemoteValueSubscription = this.datasetService.get(this.getListRoute(), params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                if (result.items && result.items.length > 0) {
                    var record = result.items[0];
                    _this.optionSelected = record;
                    _this.displayValue = _this.displayFun(_this.optionSelected);
                    _this.creatingNew = false;
                    resultSubject.next(record);
                    resultSubject.complete();
                    if (_this.inputService.formInputDefinition.extra && _this.inputService.formInputDefinition.extra.onInit) {
                        _this.inputService.formInputDefinition.extra.onInit(_this.inputService.formGroup, _this.optionSelected);
                    }
                }
                else {
                    resultSubject.error(result);
                    resultSubject.complete();
                }
                if (withLoading)
                    _this.loading = false;
            },
            error: function (response) {
                resultSubject.error(response);
                resultSubject.complete();
                if (withLoading)
                    _this.loading = false;
            }
        });
        return resultSubject;
    };
    DatasetAutocompleteInputComponent.prototype.getListRoute = function () {
        var route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
        if (this.inputService.formInputDefinition.remoteOptions.getPivotConfig) {
            var pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
            var parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
            route = '/pivot/' + pivotConfig.pivotCode + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
        }
        return route;
    };
    DatasetAutocompleteInputComponent.prototype.fetchFilteredOptions = function (textSearch) {
        var _this = this;
        if (!this.inputService.formInputDefinition.remoteOptions) {
            console.warn('remoteOptions not setted in fieldDefinition', this.inputService.formInputDefinition);
            return;
        }
        if (this.filterOptionsSubscription)
            this.filterOptionsSubscription.unsubscribe();
        var params = {
            'text-search': textSearch,
            perPage: this.inputService.formInputDefinition.remoteOptions.limit || 5
        };
        if (this.inputService.formInputDefinition.remoteOptions.getParams) {
            params = Object.assign(params, this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue()));
        }
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        var route = this.getListRoute();
        this.textSearch = textSearch;
        this.loading = true;
        this.filterOptionsSubscription = this.datasetService.get(route, params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                _this.filteredOptions.next(result.items);
                _this.loading = false;
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    DatasetAutocompleteInputComponent.prototype.getFormControlValue = function () {
        if (!this.inputService.control)
            return null;
        return this.inputService.control.value;
    };
    DatasetAutocompleteInputComponent.prototype.selectDatasetOption = function (option) {
        this.creatingNew = false;
        if (option) {
            if (typeof (option) === 'string' && this.supportCreate) {
                this.optionSelected = option;
                this.inputService.control.setValue(option);
                this.creatingNew = true;
            }
            else if (typeof (option) !== 'string' && this.clearOnSelection) {
                var value = this.inputService.formInputDefinition.remoteOptions.getValue(option);
                this.inputService.control.setValue(value);
                this.inputFormControl.setValue(null);
                this.optionSelected = null;
                this.textSearch = null;
            }
            else if (typeof (option) !== 'string') {
                var value = this.inputService.formInputDefinition.remoteOptions.getValue(option);
                this.optionSelected = option;
                this.inputService.control.setValue(value);
            }
            else {
                this.inputService.control.setValue(null);
                this.optionSelected = null;
                this.inputFormControl.setValue(null);
            }
        }
        else {
            this.inputService.control.setValue(null);
            this.optionSelected = null;
        }
        this.displayValue = this.displayFun(this.optionSelected);
        this.inputService.onSet(this.optionSelected, 'selected');
    };
    DatasetAutocompleteInputComponent.prototype.displayFun = function (record) {
        if (typeof (record) === 'string' && this.supportCreate)
            return record;
        if (!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions)
            return '---';
        if (this.inputService.formInputDefinition.remoteOptions.getDisplay) {
            return this.inputService.formInputDefinition.remoteOptions.getDisplay(record);
        }
        else if (this.inputService.formInputDefinition.remoteOptions.getLabel) {
            return this.inputService.formInputDefinition.remoteOptions.getLabel(record, this.appService);
        }
    };
    DatasetAutocompleteInputComponent.prototype.trackByOptions = function (index, item) {
        return index;
    };
    DatasetAutocompleteInputComponent.prototype.onFocusOut = function () {
        var _this = this;
        if (this.optionSelected)
            return;
        if (this.matAutocomplete && this.matAutocomplete.isOpen) {
            // this prevent to execute onFocusOut when click on autocomplete options 
            setTimeout(function () {
                _this.onFocusOut();
            }, 100);
            return;
        }
        if (this.filterOptionsSubscription) {
            this.loading = false;
            this.filterOptionsSubscription.unsubscribe();
            this.filterOptionsSubscription = null;
        }
        if (this.initRemoteValueSubscription) {
            this.loading = false;
            this.initRemoteValueSubscription.unsubscribe();
            this.initRemoteValueSubscription = null;
        }
        if (!this.textSearch || this.textSearch.length < 1) {
            this.selectDatasetOption(null);
        }
        else {
            this.selectDatasetOption(this.textSearch);
        }
    };
    DatasetAutocompleteInputComponent.prototype.emitEventOnSupportCreation = function (event) {
        this.appService.eventEmitter.emit({
            name: 'clickOnSupportCreation_' + this.inputService.formInputDefinition.key,
            extra: { inputValue: this.textSearch },
            target: event
        });
        if (this.inputService.formInputDefinition.remoteOptions &&
            this.inputService.formInputDefinition.remoteOptions.clearInputOnCreation) {
            this.textSearch = null;
            this.inputService.control.setValue(null);
            this.optionSelected = null;
            this.inputFormControl.setValue(null);
        }
    };
    return DatasetAutocompleteInputComponent;
}(DestroyableComponent));
export { DatasetAutocompleteInputComponent };
