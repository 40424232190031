import {
	InputTypes,
	ValueTypes,
	IFormConfig,
	IFieldDefinition,
} from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';
import { stationExtraSetting } from '../../search-form/form.fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();
fieldsMap.set('start_station', {
	key: 'start_station',
	name: 'Da',
	inputConfig: {
		clearable: true,
		placeholder: 'Partenza',
		type: InputTypes.SELECT_FILTERABLE,
		appearence: 'fill'
	},
	valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
	extra: stationExtraSetting
});

fieldsMap.set('end_station', {
	key: 'end_station',
	name: 'Destinazione',
	inputType: InputTypes.SELECT_FILTERABLE,
	valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
	extra: stationExtraSetting
});
fieldsMap.set('carnet_type', {
    key: 'carnet_type',
    name: 'Tipo Carnet',
    inputType: InputTypes.DATASET_SELECT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    customActionCode: '/dataset/trenitalia/command/carnet_list',
    remoteOptions: {
        getLabel(option): any {
            if (!option) return '';
            return option.description;
        },
        getValue(option): any {
            if (!option) return;
            return option.id;
        },
    },
});
fieldsMap.set('date', {
	key: 'date',
	name: 'Valido dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
    formValidators: [Validators.required],
	defaultValue: () => {
		return new Date();
	}
});


export { fieldsMap };
