import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('date_from', {
	key: 'date_from',
	name: 'A iniziare da',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	extra: {
		dateFilter(values: any, d: Date): boolean{
			if(!values.date_to) return true;
			if(d > values.date_to) return false;
			return true;
		}
	}
});

fieldsMap.set('date_to', {
	key: 'date_to',
	name: 'Fino a',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	extra: {
		dateFilter(values: any, d: Date): boolean{
			if(!values.date_from) return true;
			if(d < values.date_from) return false;
			return true;
		}
	}
});

fieldsMap.set('quantity', {
	key: 'quantity',
	name: 'Quantità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('variant_code', {
	key: 'variant_code',
	name: 'Variante',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

export {fieldsMap};
