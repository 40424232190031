import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import {fieldsMap} from '../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			fieldsMap.get('start_day_from'),
			fieldsMap.get('start_day_to'),
		]
	},
];

const filterFormConfig: IFormConfig = {
	fields
};

export default filterFormConfig;
