import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IStepConfig, IFieldDefinition } from 'app/interfaces';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
var ProductI18nFormStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductI18nFormStepComponent, _super);
    function ProductI18nFormStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, formBuilder, zone, formViewerService, stepService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        _this.formBuilder = formBuilder;
        _this.zone = zone;
        _this.formViewerService = formViewerService;
        _this.stepService = stepService;
        _this.isLastStep = true;
        return _this;
    }
    ProductI18nFormStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.datasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentValueKey
            });
            _this.formViewerService.init({
                errors: _this.formErrors,
                dataContainerService: _this.datasetACS,
                fields: Array.from(_this.fileterFields.values())
            });
        });
        this.datasetRS.record.subscribe(function (record) { return _this.formViewerService.setRecord(record); });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    ProductI18nFormStepComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewerService.formGroup.disable();
        var formData = this.formViewerService.formGroup.getRawValue();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        this.datasetMCS.save(formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.formViewerService) {
                    _this.formViewerService.formGroup.enable();
                }
                // for reaload active language when a new one is created
                if (!_this.datasetRS.recordId) {
                    _this.datasetACS.parentDatasetRS.reload();
                    _this.datasetMCS.fetchAll();
                }
                setTimeout(function () {
                    _this.stepService.setStepState({
                        dataset_code: 'products',
                        form_code: 'product-i18n-form-step',
                        record_id: _this.parentDatasetRS.recordId,
                        status: 1
                    });
                    _this.goNextStep();
                }, 200);
            },
            error: function (response) {
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewerService && _this.formViewerService.formGroup) {
                    _this.formViewerService.formGroup.enable();
                    _this.formViewerService.setErrors(_this.formErrors);
                }
            }
        });
    };
    return ProductI18nFormStepComponent;
}(BaseStepViewComponent));
export { ProductI18nFormStepComponent };
