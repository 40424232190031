import { EntityCustomerConfig } from '../entity_customers';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import generalForm from './forms/general.form';
import addressForm from './forms/address.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { IStepViewConfig, TangerineBgColors } from 'app/interfaces';
import { EntityCalendarEventConfig } from '../entity_calendar_events';

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	if(params instanceof FormData){

	}else{
		const addressData = {};
		for (const key in params) {
			if(!Object.prototype.hasOwnProperty.call(params, key)) continue;
			if(!key.startsWith('primary_address.')) continue;
			const newKey = key.replace('primary_address.', '');
			const value = params[key];
			addressData[newKey] = value;
			delete params[key];
		}
		params.primary_address = addressData;
	}
	return params;
}

export default {
	title: 'Anagrafica',
	tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
	isLinear(component: CommonStepViewContainerComponent): boolean{
		return !Boolean(component.record);
	},
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br> Base',
			component: 'common-form-step',
			options: { formConfig: generalForm },
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Indirizzo',
			stepperTitle: 'Indirizzo',
			component: 'common-form-step',
			options: { 
				formConfig: addressForm,
				getCallParams
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Dati Aggiuntivi',
			stepperTitle: 'Dati <br> Aggiuntivi',
			component: 'entity-types-step',
		},
		{
			title: 'Calendario',
			component: 'common-blocks-step',
			showIf(container: CommonStepViewContainerComponent): boolean{
				if(!container || !container.record) return false;
				return Boolean(container.record.entities_data && container.record.entities_data.staff_element);
			},
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'entity_calendar_events',
						updateParent: true,
						title: EntityCalendarEventConfig.title
					}
				]
			}
		},
		{
			title: 'Area B2B',
			stepperTitle: 'Area B2B',
			component: 'entity-b2b-step',
			showIf(container: CommonStepViewContainerComponent): boolean{
				if(!container || !container.record) return false;
				return Boolean(container.record.entities_data && container.record.entities_data.customer);
			}
		},
	]
} as IStepViewConfig;
