import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import { fieldsMap } from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import generalForm from './forms/general.form';
import userCreateForm from './forms/user-create.form';
import userEditForm from './forms/user-edit.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/entity_staff_users/detail/:recordId',
	params: {
		with_relations: 'user'
	}
};

function callParams(dataContainerService: IDataContainerService, parentData: any, params: any){
	const filters = dataContainerService.getValueFromKeyPath('entity_staff_users.filters');
	params['entity_id'] = filters.entity_id;
	params['entity_staff_element_id'] = filters.entity_staff_element_id;

	return params;
}

export const EntityStaffUserConfiguration: IDatasetConfig = {
	name: 'entity_staff_users',
	title: 'Utenti Staff',
	singleTitle: 'Utente Staff',
	ajaxDatasetCode: 'entity_staff_users',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	fetchConfig: {
		with_relations: 'user'
	},
	defaultActions: {
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams: callParams,
			viewConfig: {
				formConfig: userCreateForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams: callParams,
			viewConfig: {
				formConfig: userEditForm
			},
		},
		list: {
			getCallParams(dataContainerService: IDataContainerService, parenteData: any, params: any) {
				params.with_relations = 'user';
				params.with_attributes = 'branch';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nome utente',
						key: 'user.access_username'
					},
					{
						title: 'Descrizione',
						key: 'user.name'
					},
					{
						title: 'Email',
						key: 'user.email'
					},
					{
						title: 'Filiale',
						key: 'branch.code'
					}
				],
				actions: ['create', 'edit', 'delete']
			}
		}
	}
};
