import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { PageClosureContainerService } from '../../page-closure-container.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { LogsService } from './logs.service';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import userField from 'app/configs/commons/shared-field-configs/user.field';


const filterConfig: IFormConfig = {
	fields: [
		{
			key: 'log_type',
			name: 'Log Type',
			valueType: ValueTypes.STRING,
			inputConfig: {
				type: InputTypes.DATASET_COMBOBOX
			},
			datasetCode: 'select_options',
			remoteOptions: {
				supportCreate: true,
				limit: 20,
				getParams(): any{
					return {
						options_code: 'log_type_options',
						column_name: 'log_type'
					};	
				},
				getLabel(option): string{
					if(!option) return '';
					return option.label;
				},
				getValue(option): string{
					if(!option) return '';
					return option.value;
				}
			}
		},
		cloneFieldDefinition(userField),
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					name: 'Da',
					key: 'start_from',
					valueType: ValueTypes.DATE,
					inputConfig: {
						type: InputTypes.DATE,
						clearable: true
					}
				},
				{
					key: 'start_to',
					name: 'Fino a',
					valueType: ValueTypes.DATE,
					inputConfig: {
						type: InputTypes.DATE,
						clearable: true
					}
				}
			]
		}
	]
};

@Component({
	selector     : 'logs-page',
	templateUrl  : './logs.component.html',
	styleUrls    : ['./logs.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [PageClosureContainerService, LogsService]
})
export class LogsPageComponent implements OnInit{

	public selectedLog: any;
	public filterFormConfig: IFormConfig = filterConfig;

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		public logsService: LogsService,
		public pageClosureContainerService: PageClosureContainerService
	){}

	ngOnInit(): void{
		this.logsService.fetch();
	}
}
