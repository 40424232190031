import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { appFieldsMap } from '../../fields/app/fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	//appFieldsMap.get('generate_pratiche_from_date'),
	//appFieldsMap.get('enable_export_zucchetti'),
	//appFieldsMap.get('generate_pratiche_record'),
	appFieldsMap.get('email_send_from'),
	appFieldsMap.get('email_send_from_name'),
	appFieldsMap.get('scenario'),
];

const appForm: IFormConfig = { 
	editTitle: 'Modifica Configurazioni Dominio',
	fields: formFields 
};

export default appForm;
