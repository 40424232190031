import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { TranslateModule } from '@ngx-translate/core';
import { UtilitiesPageComponent } from './utilities.component';
import { CacheUtilityComponent } from './components/cache/cache.component';
import { SeedingUtilityComponent } from './components/seeding/seeding.component';
import { ImportSuppliersUtilityComponent } from './components/import-suppliers/import-suppliers.component';
import { ImportCustomersUtilityComponent } from './components/import-customers/import-customers.component';
import { ImportOldStatisticsUtilityComponent } from './components/import-old-statistics/import-old-statistics.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProcessFileUtilityComponent } from './components/process-file/process-file.component';

@NgModule({
	declarations: [
		UtilitiesPageComponent,
		CacheUtilityComponent,
		SeedingUtilityComponent,
		ImportCustomersUtilityComponent,
		ImportOldStatisticsUtilityComponent,
		ProcessFileUtilityComponent,
		
		ImportSuppliersUtilityComponent,
	],
	imports: [
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		MatCardModule,
		MatProgressBarModule,
		FlexLayoutModule,
		MatInputModule,

		FuseSharedModule,
		FuseSidebarModule,
		FuseWidgetModule,

		DatasetModule,
		TranslateModule.forChild(),
	],
	exports: [UtilitiesPageComponent],
})
export class UtilitiesPageModule {}
