import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from './../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('typology')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			fieldsMap.get('booking_date_from'),
			fieldsMap.get('booking_date_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			fieldsMap.get('start_day_from'),
			fieldsMap.get('start_day_to'),
		]
	},
	fieldsMap.get('trip_package_id'),
	fieldsMap.get('trip_resource_id'),
	fieldsMap.get('booking_code'),
	{
		key: 'origin_channel_voucher_code',
		name: 'Codice Voucher Operatore',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.TEXT
	},
	{
		key: 'origin_channel_booking_id',
		name: 'ID Ordine Esterno',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.TEXT
	},
	{
		name: 'Cliente',
		key: 'customer_description',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.TEXT
	},
	{
		name: 'Nominativo',
		key: 'entity_description',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.TEXT
	},
	{
		name: 'Origine',
		key: 'origin_channel',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.SELECT,
		options: [
			{
				label: 'Tutti',
				value: ''
			},
			{
				label: 'Back Office',
				value: 'backoffice'
			},
			{
				label: 'Direct Online',
				value: 'direct_online'
			},
			{
				label: 'Direct Offline',
				value: 'direct_offline'
			},
			{
				label: 'API',
				value: 'api'
			},
			{
				label: 'Mobile',
				value: 'ticketing_mobile'
			}
		]
	},
	{
		name: 'Stato',
		key: 'status',
		valueType: ValueTypes.STRING,
		inputType: InputTypes.SELECT,
		options: [
			{
				label: 'Tutti',
				value: ''
			},
			{
				label: 'Confermato',
				value: 'confirmed'
			},
			{
				label: 'Cancellato',
				value: 'canceled'
			},
			{
				label: 'Preventivo',
				value: 'draft'
			}
		]
	}
];

const filterFormConfig: IFormConfig = {
	fields
};

export default filterFormConfig;
