<div class='m-16'>
	<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>
	<mat-accordion>
		<mat-expansion-panel *ngFor='let tourOperator of tourOperators; trackBy:trackByIndex;'>
			<mat-expansion-panel-header>
				<mat-panel-title>
					{{tourOperator.name}} <a *ngIf='tourOperator.doc_link' class="material-icons" [href]='appService.getAbsolutePath(tourOperator.doc_link)' target="_blank">help</a>
				</mat-panel-title>
				<mat-panel-description>
					<span *ngIf='(tourOperator.integration && tourOperator.integration.enabled) || tourOperator.active'>Attivo</span>
					<span *ngIf='(!tourOperator.integration || !tourOperator.integration.enabled) && !tourOperator.active'>Non attivo</span>
				</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-container *ngIf="tourOperator.code != 'booking_widget'">
				<mat-progress-bar *ngIf='tourOperator.activating' mode='indeterminate'></mat-progress-bar>
				<ng-container *ngIf='!tourOperator.integration && (tourOperator.id || tourOperator.activable)'>
					<button mat-flat-button color='accent' (click)='createTripPackageIntegration(tourOperator)' [disabled]='tourOperator.activating'>Attiva</button>
				</ng-container>
				<p *ngIf='!tourOperator.id && !tourOperator.activable'>Per attivare questa integrazione è richiesto che l'amministratore configuri la connessione al servizio esterno.</p>
				<dataset-edit
					*ngIf='tourOperator.integration'
					[inputRecord]='tourOperator.integration'
					[parentDatasetACS]='datasetACS'
					[datasetCode]='integrationsDatasetCode'
					[showTitle]="false"
					[autoFetchData]="true"
					[routed]='false'
					(updated)='integrationUpdated($event, tourOperator)'
				></dataset-edit>
				<ng-container *ngIf='tourOperator.integration && tourOperator.code === "booking_engine"'>
					<a [href]="appService.getBaseServerAddressRoot() + '/preview/d/' + tourOperator.integration.domain.id + '/detail/trip_packages/' + tourOperator.integration.trip_package_id" target="_blank" mat-flat-button color='accent'>Visualizza prodotto</a>
				</ng-container>
				<dataset-list
					*ngIf='tourOperator.code === "tangerine"'
					datasetCode='domain_packages'
					[parentDatasetACS]='datasetACS'
					[showTitle]='false'
					[viewConfig]='domainPackageViewConfig'
					[filters]='{
						trip_package_id: datasetRS.recordId,
						tour_operator_id: tourOperator.id,
						contextual_domain_id: datasetACS.getValueFromKeyPath("contextual_domain_id"),
						without_parent: 1
					}'
				></dataset-list>
				<dataset-list
					*ngIf='tourOperator.integration && tourOperator.code === "tour-cms"'
					datasetCode='meeting_point_list_to_references'
					[parentDatasetACS]='datasetACS'
					[showTitle]="false"
					[autoFetchData]="true"
					[filters]='{trip_package_id: datasetRS.recordId, tour_operator_id: tourOperator.id}'
				></dataset-list>
				<dataset-list
					*ngIf="tourOperator.integration && ['viator', 'gyg', 'bokun'].includes(tourOperator.code)"
					datasetCode='actual_trip_groups'
					[parentDatasetACS]='datasetACS'
					[showTitle]="false"
					[autoFetchData]="true"
					[filters]='{trip_package_id: datasetRS.recordId, tour_operator_code: tourOperator.code}'
				></dataset-list>
			</ng-container>
			<ng-container *ngIf="tourOperator.code == 'booking_widget'">
				<p *ngIf='tourOperator.message'>{{tourOperator.message}}</p>
				<p>Codice da copiare nella pagina:</p>
                <form-viewer #formViewer
                             [record]=''
                             [dataContainerService]='datasetACS'
                             [formConfig]='widgetForm'
                             (valueChanges)="widgetOptions($event)"
                ></form-viewer>
				<code>{{tourOperator.snippet}}</code>
			</ng-container>
		</mat-expansion-panel>
	</mat-accordion>
</div>
