import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { BookingService } from '../booking.service';
import { TripBookingConfig } from 'app/configs/datasets/trip_bookings';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { BaseStepChangerComponent } from '../base-step-changer.component';
var EditBookingStep0Component = /** @class */ (function (_super) {
    tslib_1.__extends(EditBookingStep0Component, _super);
    function EditBookingStep0Component(appService, datasetRS, datasetACS, datasetNavigatorService, bookingService, datasetService) {
        var _this = _super.call(this, bookingService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.bookingService = bookingService;
        _this.datasetService = datasetService;
        _this.loading = false;
        _this.formConfig = TripBookingConfig.formConfigsMap['baseInfoFormConfig'];
        return _this;
    }
    Object.defineProperty(EditBookingStep0Component.prototype, "record", {
        get: function () { return this.datasetRS.record.value; },
        enumerable: true,
        configurable: true
    });
    EditBookingStep0Component.prototype.ngOnInit = function () {
        var _this = this;
        this.bookingService.nextStep
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (stepper) {
            _this.onSave()
                .subscribe(function (value) {
                stepper.next();
            });
        });
    };
    EditBookingStep0Component.prototype.onSave = function () {
        var _this = this;
        var subject = new Subject();
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        /*if(!formData['entity_customer_id']){
            const value = getDisabledStaticValueCustomFields('entity_customer_id','booking',this.datasetService);
            if(value)formData['entity_customer_id']=value;
        }*/
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        this.datasetACS.loading.next(true);
        var route = '/dataset/trip_bookings/update/' + this.datasetRS.recordId;
        this.datasetService.post(route, formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.clearForm();
                }
                _this.datasetRS.recordId = response.id;
                _this.datasetRS.reload()
                    .subscribe(function (value) {
                    if (value)
                        subject.next(value);
                    else
                        _this.datasetNavigatorService.onEdit(_this.datasetACS, response);
                });
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
            }
        });
        return subject;
    };
    return EditBookingStep0Component;
}(BaseStepChangerComponent));
export { EditBookingStep0Component };
