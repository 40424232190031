<div class="flex-row items-center justify-end flex-gap-6">
	<button *ngIf="showResources" mat-button (click)="tripBookingPackageService.openTripBookingRelatedDialog('trip_booking_resources', tripBookingPackageRelatedDialogData, viewContainerRef)">
		<span class="material-icons-outlined text-color-dark-blue">directions_bus_filled</span>
		<span class="text-color-dark-blue ml-8">{{'Risorse' | translate}}</span>
	</button>
	<button *ngIf="showServices" mat-button (click)="tripBookingPackageService.openTripBookingRelatedDialog('trip_booking_services', tripBookingPackageRelatedDialogData, viewContainerRef)">
		<span class="material-icons-outlined text-color-dark-blue">fact_check</span>
		<span class="text-color-dark-blue ml-8">{{'Servizi' | translate}}</span>
	</button>
	<button *ngIf="showParticipants" mat-button (click)="tripBookingPackageService.openTripBookingRelatedDialog('trip_booking_participant_extras', tripBookingPackageRelatedDialogData, viewContainerRef)">
		<span class="material-icons-outlined text-color-dark-blue">group</span>
		<span class="text-color-dark-blue ml-8">{{'Partecipanti' | translate}}</span>
	</button>
</div>