import * as tslib_1 from "tslib";
import { ValueTypes, InputTypes, FieldViews } from 'app/interfaces';
import branchesField from 'app/configs/commons/shared-field-configs/branches.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
var fieldsMap = new Map();
fieldsMap.set('code', {
    key: 'code',
    name: 'Codice',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING
});
fieldsMap.set('booking_code', {
    key: 'booking_code',
    name: 'Codice Prenotazione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING
});
fieldsMap.set('receipt', {
    key: 'receipt',
    name: 'Pacchetti trasmessi',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var fieldTitle = "";
        if (record.transmitted_packages) {
            var packages = record.transmitted_packages.split(',');
            try {
                for (var packages_1 = tslib_1.__values(packages), packages_1_1 = packages_1.next(); !packages_1_1.done; packages_1_1 = packages_1.next()) {
                    var bookingPackage = packages_1_1.value;
                    // concat package data
                    fieldTitle += bookingPackage + '<br/>';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (packages_1_1 && !packages_1_1.done && (_a = packages_1.return)) _a.call(packages_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return fieldTitle;
    }
});
fieldsMap.set('created_at', {
    key: 'created_at',
    name: 'Data',
    inputType: InputTypes.DATETIME,
    valueType: ValueTypes.DATETIME
});
fieldsMap.set('status', {
    key: 'status',
    name: 'Stato transazione',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var status = "<b>Inviato</b>";
        if (record.status == 0) {
            status = "<b>Annullato</b>";
        }
        return status;
    }
});
fieldsMap.set('total', {
    key: 'total',
    name: 'Totale',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    getDisplayValue: function (record) {
        return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.total);
    }
});
fieldsMap.set('branch_code', cloneFieldDefinition(branchesField));
export { fieldsMap };
