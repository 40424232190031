import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import * as moment from 'moment';
import * as _ from "lodash";
import { environment } from "../../../../../environments/environment";
import { BookingService } from '../booking.service';
import { exhaustMap } from 'rxjs/operators';
export var trainTypeImages = {
    Frecciarossa: 'assets/images/trenitalia/FRECCIAROSSA.svg',
    FrecciaBianca: 'assets/images/trenitalia/FB.svg',
    'Frecciarossa 1000': 'assets/images/trenitalia/frecciarossa_1000.svg',
    Frecciargento: 'assets/images/trenitalia/frecciargento.svg',
    Intercity: 'assets/images/trenitalia/intercity.svg',
    InterCityNotte: 'assets/images/trenitalia/intercity_notte.svg',
    Regionale: 'assets/images/trenitalia/regionale.svg',
    'Regionale Veloce': 'assets/images/trenitalia/RV.svg',
    'EuroCity': 'assets/images/trenitalia/EC.svg',
    'Autobus': 'assets/images/trenitalia/BU.svg',
    FrecciaLink: 'assets/images/trenitalia/FL.svg',
    default: 'assets/images/trenitalia/default_logo.svg',
};
export function getTrainBreadcrumbs(trains) {
    var e_1, _a;
    var result = [];
    if (!trains)
        return result;
    try {
        for (var trains_1 = tslib_1.__values(trains), trains_1_1 = trains_1.next(); !trains_1_1.done; trains_1_1 = trains_1.next()) {
            var train = trains_1_1.value;
            if (train.type == 'multi' && train.subSegments) {
                getTrainBreadcrumbs(train.subSegments).forEach(function (item) { return result.push(item); });
            }
            else {
                result.push(Object.assign(train, {
                    icon: trainTypeImages[train.type],
                    departureLocalDateStr: new Date(train.departure).toLocaleDateString(),
                    arrivalLocalDateStr: new Date(train.arrival).toLocaleDateString(),
                    departureLocalTimeStr: new Date(train.departure).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false }),
                    arrivalLocalTimeStr: new Date(train.arrival).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false })
                }));
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (trains_1_1 && !trains_1_1.done && (_a = trains_1.return)) _a.call(trains_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
}
var TrenitaliaService = /** @class */ (function () {
    function TrenitaliaService(datasetService, bookingService) {
        var _this = this;
        this.datasetService = datasetService;
        this.bookingService = bookingService;
        this.btnAction = new BehaviorSubject('');
        this.isDialog = new BehaviorSubject(false);
        this.seatsEnabled = new BehaviorSubject(false);
        this.loading = new BehaviorSubject(false);
        this.checkoutData = new BehaviorSubject({});
        this.servicesMap = new BehaviorSubject({});
        this.solutions = new BehaviorSubject({});
        this.step = new BehaviorSubject(1);
        this.trainSeatStep = new BehaviorSubject(0);
        this.total = new BehaviorSubject({});
        this.continue = new BehaviorSubject(false);
        this.errors = new BehaviorSubject({});
        this.stations = new BehaviorSubject([]);
        this.loadingStations = new BehaviorSubject(true);
        this.stationSubscription = this.datasetService.get('/dataset/trenitalia/command/all_stations')
            .subscribe(function (stations) {
            _this.stations.next(stations);
        }, function (error) {
            //TODO errore lettura stazioni
        }, function () {
            _this.loadingStations.next(false);
        });
    }
    TrenitaliaService.prototype.getStationDataFromId = function (id) {
        var stationList = this.stations.getValue();
        if (!stationList)
            return null;
        return stationList.find(function (st) { return st.service_id === id; });
    };
    TrenitaliaService.prototype.init = function (initData, checkoutData) {
        if (initData) {
            var data = {
                adult: initData.tripBookingData.participant_counters.ADT,
                children: initData.tripBookingData.participant_counters.CHD,
                direction: 'ONE_WAY',
                start: initData.tripBookingData.target_info.solution.context
                    .searchCriteria.departureTimesStart,
                from: parseInt(initData.tripBookingData.target_info.solution.context
                    .searchCriteria.departure.id),
                to: parseInt(initData.tripBookingData.target_info.solution.context
                    .searchCriteria.arrival.id),
                passengers: initData.tripBookingData.target_info.travellers,
                mode: initData.mode,
                reopen: initData.reopen
            };
            if (checkoutData) {
                data = tslib_1.__assign({}, data, checkoutData);
            }
            this.checkoutData.next(data);
        }
        else {
            this.checkoutData.next({
                adult: 1,
                children: 0,
                direction: 'ONE_WAY',
                start: moment().add(30, 'minutes'),
                from: _.get(environment, 'trenitalia.from', null),
                to: _.get(environment, 'trenitalia.to', null),
                mode: 'travel'
            });
        }
        _.get(environment, 'trenitalia.ar', false);
    };
    TrenitaliaService.prototype.getSolutions = function (s) {
        return this.datasetService.post('/dataset/trenitalia/command/search_solutions', s);
    };
    TrenitaliaService.prototype.similarSolutions = function (s) {
        return this.datasetService.post('/dataset/trenitalia/command/search_similar_solutions', s);
    };
    TrenitaliaService.prototype.getOffers = function (s) {
        return this.datasetService.post('/dataset/trenitalia/command/search_offers', s);
    };
    TrenitaliaService.prototype.getAvailability = function (s) {
        return this.datasetService.post('/dataset/trenitalia/command/evaluate_travel_solution', s);
    };
    TrenitaliaService.prototype.getStops = function (s) {
        return this.datasetService.post('/dataset/trenitalia/command/stops', s);
    };
    TrenitaliaService.prototype.reset = function () {
        this.checkoutData.next([]);
        this.step.next(1);
        this.total.next({});
    };
    TrenitaliaService.prototype.getDetails = function (tripBookingPackage, isCarnet) {
        if (isCarnet === void 0) { isCarnet = false; }
        return this.datasetService.post('/dataset/trenitalia/command/travel_details', { trip_booking_package_id: tripBookingPackage, is_carnet: isCarnet });
    };
    TrenitaliaService.prototype.evaluateDeleteOrRefund = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/evaluate_delete_or_refund', body);
    };
    TrenitaliaService.prototype.evaluateTravelPostSale = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/evaluate_post_sale', body);
    };
    TrenitaliaService.prototype.cancelTravel = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/cancel_travel', body);
    };
    TrenitaliaService.prototype.changeTravellers = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/change_travellers', body);
    };
    TrenitaliaService.prototype.changeClass = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/change_class', body);
    };
    TrenitaliaService.prototype.updateAndConfirm = function (body) {
        var _this = this;
        return this.bookingService.execPreAction('trenitalia:post-sale')
            .pipe(exhaustMap(function (a) {
            return _this.datasetService.post('/dataset/trenitalia/command/update_and_confirm', body);
        }));
    };
    /*
    metodi per carnet
    * */
    /**
     * Lista carnet
     * @param body Object
     */
    TrenitaliaService.prototype.searchAvailablesCarnet = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/search_availables_carnet', body);
    };
    TrenitaliaService.prototype.searchCarnetSolutions = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/search_carnet_solutions', body);
    };
    /**
     * login e recupero dati
     * @param body
     */
    TrenitaliaService.prototype.login = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/trenitalia_login', body);
    };
    /**
     * Check valid carnet for search travel
     * @param body
     */
    TrenitaliaService.prototype.checkCarnet = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/check_carnet', body);
    };
    TrenitaliaService.prototype.checkTravelChangePossibilities = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/check_travel_change_possibilities', body);
    };
    TrenitaliaService.prototype.updateServicesMap = function (servicesMap) {
        var s = {
            catalog_services: Object.assign({}, _.get(this.servicesMap.value, 'catalog_services'), _.get(servicesMap, 'catalog_services')),
            offers: Object.assign({}, _.get(this.servicesMap.value, 'offers'), _.get(servicesMap, 'offers'))
        };
        this.servicesMap.next(s);
    };
    TrenitaliaService.prototype.scrollToTop = function (end) {
        if (end === void 0) { end = false; }
        var element = document.getElementById(end ? "step-2-top" : "button-group-action-package");
        element.scrollIntoView();
    };
    TrenitaliaService.prototype.getFee = function (body) {
        return this.datasetService.post('/dataset/trenitalia/command/fee', body);
    };
    return TrenitaliaService;
}());
export { TrenitaliaService };
