import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TrenitaliaService } from '../../trenitalia.service';
import { wrapArray } from 'app/helpers';
function findSolutionNode(id, solutionNodes) {
    var e_1, _a;
    try {
        for (var solutionNodes_1 = tslib_1.__values(solutionNodes), solutionNodes_1_1 = solutionNodes_1.next(); !solutionNodes_1_1.done; solutionNodes_1_1 = solutionNodes_1.next()) {
            var solutionNode = solutionNodes_1_1.value;
            if (solutionNode.idXml == id)
                return solutionNode;
            if (solutionNode.subSegments) {
                var found = findSolutionNode(id, solutionNode.subSegments);
                if (found)
                    return found;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (solutionNodes_1_1 && !solutionNodes_1_1.done && (_a = solutionNodes_1.return)) _a.call(solutionNodes_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return null;
}
var TrainDetailsComponent = /** @class */ (function () {
    function TrainDetailsComponent(_trnService) {
        this._trnService = _trnService;
        this.load = true;
        this.infoNotes = [];
        this.infoServices = [];
    }
    TrainDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var solution_node = findSolutionNode(this.train.id, this.solution.original.solutionNodes);
        if (solution_node) {
            this._trnService
                .getStops({
                /*solution_node: _.get(this.original, this.solution.path),*/
                solution_node: solution_node
            })
                .subscribe({
                next: function (stops) {
                    _this.stops = stops;
                },
                error: function () {
                    _this.load = false;
                },
                complete: function () {
                    _this.load = false;
                }
            });
        }
        else {
            this.load = false;
        }
    };
    TrainDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.train) {
            this.updateNotes();
        }
    };
    TrainDetailsComponent.prototype.updateNotes = function () {
        this.infoNotes = wrapArray(this.train.info).filter(function (note) {
            return note.type == 'Nota Informativa' && !['.', '[]'].includes(note.description);
        });
        this.infoServices = wrapArray(this.train.info).filter(function (note) {
            return note.type != 'Nota Informativa' && !['.', '[]'].includes(note.description);
        });
    };
    return TrainDetailsComponent;
}());
export { TrainDetailsComponent };
