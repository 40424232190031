import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { takeUntil } from 'rxjs/operators';

export interface customParams {
	recordId: any;
}

export interface IRenderViewDialog {
	component: string;
	parentDatasetACS: DatasetActionContainerService;
	title?: string;
	positiveText?: String;
	negativeText?: String;
	params: customParams;
}

@Component({
	selector: 'render-view-dialog',
	templateUrl: 'dialog.component.html',
	providers: [DatasetRecordService, DatasetActionContainerService, DatasetNavigatorService, DatasetService, PageClosureContainerService]
})
export class RenderViewDialogComponent implements OnDestroy, OnInit {

	public loading = true;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public dialogRef: MatDialogRef<RenderViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IRenderViewDialog
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		// generate an error, maybo open with setTimeout or loading inside the template
		/*const loadingDialogRef: MatDialogRef<ActionDialogComponent, any> = this.datasetNavigatorService.openDialog(ActionDialogComponent, {
			title: 'Recupero dati in corso',
		});*/
		this.datasetRS.recordId = this.data.params.recordId;

		this.datasetACS.init({
			datasetCode: 'trip_packages',
			actionCode: 'detail',
			parentDatasetACS: this.data.parentDatasetACS,
			fetchData: false,
			datasetRS: this.datasetRS
		}).subscribe(ready => {
			if(!ready) return;
			
			this.datasetRS.reload();
		});

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			if(this.datasetRS.record.value){
				this.loading = false;
				//loadingDialogRef.close();
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.dialogRef.close(true);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
