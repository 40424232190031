import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general.form';
import {ConfirmDialogComponent} from '../../../main/dialogs/confirm-dialog/dialog.component';
import {DatasetNavigatorService} from '../../../main/components/dataset/services/dataset-navigator.service';
import {IDataContainerService} from '../../../interfaces/data-container.interface';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/reviews/detail/:recordId',
};

export const ReviewConfig: IDatasetConfig = {
	name: 'reviews',
	title: 'Recensioni',
	singleTitle: 'Recensione',
	ajaxDatasetCode: 'reviews',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'date',
				defaultSortDirection: 'desc',
				filterForm: {
					fields: [
						fieldsMap.get('status'),
						fieldsMap.get('trip_package_id'),
						//fieldsMap.get('trip_product_id'), TODO: Enable it when necessary
					]
				},
				columns: [
					{
						title: 'Prodotto',
						key: 'product_description',
						routerLink(record: any): string{
							const datasetCode = record.product_type + 's';
							return datasetDetailPage(datasetCode, record.product_id);
						}
					},
					{
						title: 'Nome Recensore',
						key: 'reviewer_display_name'
					},
					{
						title: 'Punteggio',
						key: 'points'
					},
					{
						title: 'Data',
						key: 'date'
					},
					{
						title: 'Stato',
						key: 'status'
					}
				],
				actions: ['detail'],
				customActions: [
					/*
					{
						isGlobal: true,
						buttonText: 'Aggiorna recensioni',
						actionCode: 'view',
						buttonColor: 'accent',
						buttonIcon: 'refresh',
						tooltip: 'Aggiorna tutte le recensioni',
						showIf: () => true,
						onClick(event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): void{
							const dialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
								title: 'Attendere',
								contentMessage: ''
							});
							datasetNavigator.datasetService.get<any>('/dataset/reviews/command/reviews')
							.subscribe({
								next: response => {
									dialogRef.close();
									if(dataContainerService instanceof DatasetActionContainerService){
										dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
									}
								},
								error: response => {
									const dialogInstance = dialogRef.componentInstance;
									if(dialogInstance instanceof ActionDialogComponent){
										dialogInstance.resultStatus = 'error';
										dialogInstance.setMessage('Si è verificato un errore imprevisto.');
									}
								}
							});
						}
					},*/
					{
						buttonIcon: 'check_circle_outline',
						buttonColor: 'primary',
						showIf: (record, dataContainerService: IDataContainerService) => {
							if(!record || !record.status) return false;
							return record.status === 'pending' || !record.status;
						},
						onClick: (event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => {
							const dialogRef = datasetNavigator.openDialog(ConfirmDialogComponent, {
								title: 'Approvazione Recensione',
								positiveText: 'Conferma',
								negativeText: 'Annulla'
							});
							dialogRef.beforeClosed().subscribe(result => {
								if (result) {
									datasetNavigator.datasetService.post('/dataset/reviews/command/update_review', {
										review_id: record.id,
										status: 'approved'
									}).subscribe({
										next: response => {
											record.status = 'approved';
											if(dataContainerService instanceof DatasetActionContainerService){
												dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
											}
										}
									});
								}
							});
						}
					},
					{
						buttonIcon: 'cancel',
						buttonColor: 'warn',
						showIf: (record, dataContainerService: IDataContainerService) => {
							if(!record || !record.status) return false;
							return record.status === 'pending' || !record.status;
						},
						onClick: (event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => {
							const dialogRef = datasetNavigator.openDialog(ConfirmDialogComponent, {
								title: 'Rifiuto Recensione',
								positiveText: 'Conferma',
								negativeText: 'Annulla'
							});
							dialogRef.beforeClosed().subscribe(result => {
								if (result) {
									datasetNavigator.datasetService.post('/dataset/reviews/command/update_review', {
										review_id: record.id,
										status: 'trashed'
									}).subscribe({
										next: response => {
											record.status = 'trashed';
											if(dataContainerService instanceof DatasetActionContainerService){
												dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
											}
										}
									});
								}
							});
						}
					}
				]
			}
		}
	}
};
