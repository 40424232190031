import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import editForm from './forms/edit.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/discount_rule_lists/detail/:recordId',
};

export const DiscountRuleListConfig: IDatasetConfig = {
	name: 'discount_rule_lists',
	title: 'Listini',
	singleTitle: 'Listino',
	ajaxDatasetCode: 'discount_rule_lists',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
					},
					{
						title: 'Description',
						key: 'description'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete']
			}
		}
	}
};
