import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const generalFormFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('resource_type'),
	fieldsMap.get('resource_sub_types'),
	fieldsMap.get('ticket_text'),
	fieldsMap.get('allotment_type'),
	fieldsMap.get('description')
];

export default { 
	title: 'Risorsa',
	fields: generalFormFields 
};
