import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets, ChartColor } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';

@Component({
	selector   : 'ticketsyear',
	templateUrl: './ticketsyear.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class TicketsYearComponent extends DestroyableComponent implements OnInit, AfterViewInit{
	public ticketsyear: ChartDataSets[];
	public barChartLabels: Label[];
	public showLoader: boolean;

	@ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;

	/**
	 * Chart Ticket Year
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		onResize(chart: Chart): void{
			chart.data.datasets.forEach((chartDataset: ChartDataSets) => {
				const bgColors: string[] = <Array<string>>chartDataset.hoverBackgroundColor;
				const gradients = [];
				for(let i = 0; i<bgColors.length; i++){
					const bgColor = bgColors[i];
					const gradient: CanvasGradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom/1.7, 0, chart.chartArea.bottom);
					gradient.addColorStop(0, bgColor);
					gradient.addColorStop(1, 'white');
					gradients.push(gradient);
				}
				chartDataset.backgroundColor = gradients;
			});
			/**
			 * when data or style changes update chart
			 */
			chart.update();
		},
		scales: { 
			xAxes: [{}], 
			yAxes: [
				{
					ticks: {
						beginAtZero: true
					},
					gridLines: {
						display: false,
					}
				}
			] 
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		}
	};
	public chartPlugins = [makeDrawValuePlugin()];
	public barChartType: ChartType = 'bar';
	public barChartLegend = false;

	constructor(public dashboardService: DashBoardService){
		super();
	}
	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.ticketsyear = [{data: []}];
	}

	ngAfterViewInit(): void{
		this.dashboardService.checkoutDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'checkout_date') return;
				if(response) this.onData(response);
			}
		});
		this.dashboardService.travelDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'departure_date') return;
				if(response) this.onData(response);
			}
		});
	}
	// -----------------------------------------------------------------------------------------------------

	public onData(response: any): void{
		if(!response){
			this.showLoader = true;
			return;
		}
		const r = [];

		for (const item of response) {
			if(!item.year) continue;
			const year = item.year;
			if (!r[year]) r[year] = 0;
			r[year] += Number(item.pax_count);
		}

		const values = [];
		const labels: Label[] = [];
		const years = Object.keys(r);
		const colors = [];
		const gradients = [];
		for (let i = 0; i < years.length; i++){
			const year = years[i];
			const colorIndex = years.length - i - 1;

			values.push(r[year]);
			labels.push(year.toString());
			colors.push(chartColors[colorIndex]);

			const chartArea = this.chartComponent.chart.chartArea;
			const gradient: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.7, 0, chartArea.bottom);
			gradient.addColorStop(0, chartColors[colorIndex]);
			gradient.addColorStop(1, 'white');
			gradients.push(gradient);
		}
		if(values.length === 0){
			this.showLoader = true;
			this.ticketsyear = [{data: []}];
		} else {
			this.showLoader = false;
			this.ticketsyear = [
				{
					data: values,
					backgroundColor: gradients,
					borderColor: colors,
					hoverBackgroundColor: colors,
					hoverBorderColor: colors
				}
			];
		}
		this.barChartLabels = labels;
	}	
}
