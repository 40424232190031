<div class='full-form'>
	<dataset-list
		datasetCode='trip_meeting_points'
		[parentDatasetRS]='datasetRS'
		[parentDatasetACS]='datasetACS'
		[showSearchBar]='false'
		[elevate]='false'
	></dataset-list>

	<dataset-list
		datasetCode='pivot_trip_packages_trip_resources'
		[parentDatasetRS]='datasetRS'
		[parentDatasetACS]='datasetACS'
		[showSearchBar]='false'
		[elevate]='false'
	></dataset-list>

	<dataset-list
		datasetCode='pivot_trip_packages_trip_categories'
		[parentDatasetRS]='datasetRS'
		[parentDatasetACS]='datasetACS'
		[showSearchBar]='false'
		[elevate]='false'
	></dataset-list>

</div>