import * as tslib_1 from "tslib";
import { FieldViews, InputTypes, ValueTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
var fieldsMap = new Map();
var columnsMap = new Map();
fieldsMap.set('departure_date_filter', {
    key: 'departure_date_filter',
    name: 'Data Partenza',
    inputConfig: {
        type: InputTypes.COMPARE_DATE
    },
    valueType: ValueTypes.STRING,
});
fieldsMap.set('actual_trips_mode', {
    key: 'actual_trips_mode',
    name: 'Modalità Partenze',
    description: 'Determina come vengono gestire le partenze',
    inputConfig: {
        hint: 'Determina come vengono gestire le partenze',
        type: InputTypes.SELECT,
        placeholder: 'Standard'
    },
    disallowNull: true,
    defaultValue: function () {
        return 'null';
    },
    options: [
        {
            label: 'Standard',
            value: 'null'
        },
        {
            label: 'Intervallo di date',
            value: 'virtual'
        }
    ],
    valueType: ValueTypes.STRING,
    skipIf: function (record, dataContainerService) {
        return record && (record.id || record.target == 'ticket');
    },
    showIf: function (record, dataContainerService) {
        return !record || (!record.id && record.target != 'ticket');
    }
});
fieldsMap.set('master_image', {
    key: 'master_image',
    name: 'Immagine',
    valueType: ValueTypes.FILE,
    fieldView: FieldViews.IMAGE,
    resize: {
        width: 96,
        height: 56
    }
});
fieldsMap.set('owner', {
    key: 'owner',
    name: 'Proprietario',
    valueType: ValueTypes.FILE,
    fieldView: FieldViews.IMAGE,
    resize: {
        width: 75,
        height: 40
    }
});
fieldsMap.set('target', {
    key: 'target',
    name: 'Tipologia',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    inputConfig: {
        type: InputTypes.SELECT,
    },
    options: [{ label: 'Biglietti', value: 'ticket' }, { label: 'Pacchetti', value: 'package' }],
    showIf: function (record, dataContainerService) {
        return dataContainerService && dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.ticketing.enabled') == true;
    }
});
fieldsMap.set('mac_causal', {
    key: 'mac_causal',
    name: 'MAC Causale',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    inputHint: 'MAC Causale',
    inputConfig: {
        type: InputTypes.TEXT,
        required: true
    },
    skipIf: function (record, dataContainerService) {
        return !dataContainerService || dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.seta.enabled') != true;
    }
});
fieldsMap.set('mac_category', {
    key: 'mac_category',
    name: 'MAC Categoria',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    inputHint: 'MAC Categoria',
    inputConfig: {
        type: InputTypes.TEXT,
        required: true
    },
    skipIf: function (record, dataContainerService) {
        return !dataContainerService || dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.seta.enabled') != true;
    }
});
fieldsMap.set('cutoff_in_minutes', {
    key: 'cutoff_in_minutes',
    name: 'Cut Off in minuti',
    valueType: ValueTypes.NUMBER,
    inputConfig: {
        type: InputTypes.NUMBER,
        clearable: true,
    },
    defaultValue: function () {
        return null;
    }
});
fieldsMap.set('duration_standard_in_hours', {
    key: 'duration_standard_in_hours',
    name: 'Durata standard in ore',
    valueType: ValueTypes.NUMBER,
    inputConfig: {
        type: InputTypes.NUMBER,
    },
    defaultValue: function () {
        return null;
    }
});
fieldsMap.set('code', {
    key: 'code',
    name: 'Codice',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    inputHint: 'Opzionale, se non inserito verrà generato automaticamente.',
    appearance: 'outline'
});
fieldsMap.set('description', {
    key: 'description',
    name: 'Descrizione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    inputHint: 'Nome del pacchetto'
});
fieldsMap.set('publish_on_web', {
    key: 'publish_on_web',
    name: 'Pubblica sul sito',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.NUMBER,
    inputHint: 'Se pubblico, il pacchetto sarà visibile anche sul sito',
    options: [
        {
            label: 'NON pubblico',
            value: 0
        },
        {
            label: 'Pubblico',
            value: 1
        }
    ]
});
fieldsMap.set('active_status', {
    key: 'active_status',
    name: 'Attivo',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.NUMBER,
    inputHint: 'Se disattivato, non sarà possibile prenotare partenze',
    options: [
        {
            label: 'Disattivo',
            value: 0
        },
        {
            label: 'Si',
            value: 1
        }
    ]
});
fieldsMap.set('active_status_filter', {
    key: 'active_status',
    name: 'Attivo',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return ''; },
    options: [
        {
            label: 'Tutti',
            value: ''
        },
        {
            label: 'Disattivo',
            value: 0
        },
        {
            label: 'Si',
            value: 1
        }
    ]
});
fieldsMap.set('publish_on_web_filter', {
    key: 'publish_on_web',
    name: 'Pubblico sul sito',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return ''; },
    options: [
        {
            label: 'Tutti',
            value: ''
        },
        {
            label: 'NON pubblico',
            value: 0
        },
        {
            label: 'Pubblico',
            value: 1
        }
    ]
});
fieldsMap.set('note_booking', {
    key: 'note_booking',
    name: 'Note',
    inputType: InputTypes.TEXTAREA,
    valueType: ValueTypes.PROPERTY,
    inputHint: 'Note',
    showIf: function (record, dataContainerService) {
        return true;
    }
});
fieldsMap.set('supplier_id', cloneFieldDefinition(entitySupplierIdField, { key: 'supplier_id' }));
fieldsMap.set('active_language_codes', {
    key: 'active_language_codes',
    name: 'Traduzioni attive',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
        if (record.active_language_codes && record.active_language_codes.length > 0) {
            var style = 'style="margin-right: 5px;"';
            try {
                for (var _b = tslib_1.__values(record.active_language_codes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var lang = _c.value;
                    html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            html += '<span>---</span>';
        }
        html += '</div>';
        return html;
    }
});
fieldsMap.set('priority_order', {
    key: 'priority_order',
    name: 'Priorità',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    defaultValue: function () { return 0; }
});
fieldsMap.set('active_commission_percent', {
    key: 'active_commission_percent',
    name: 'Commissioni %',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER
});
fieldsMap.set('disable_document_rt_transmission', {
    key: 'disable_document_rt_transmission',
    name: 'Opzioni avanzate RT',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.PROPERTY,
    titleTooltip: 'Questo campo ha priorità sulla fiscalità',
    options: [
        {
            label: 'In base alla fiscalità',
            value: 1
        },
        {
            label: 'Invia e ignora fiscalità',
            value: 2
        },
        {
            label: 'Non inviare e ignora fiscalità',
            value: 3
        }
    ],
    showIf: function (record, dataContainerService) {
        var domainModules = dataContainerService.getValueFromKeyPath('domainModules');
        // if kiara cloud api is configured
        if (domainModules.kiara_cloud_api) {
            var rtModule = domainModules.kiara_cloud_api;
            // if enabled
            if (rtModule.enabled != null) {
                return Boolean(rtModule.enabled);
            }
            return true;
        }
        return false;
    }
});
fieldsMap.set('taxation', {
    key: 'taxation',
    name: 'Fiscalità',
    valueType: ValueTypes.PROPERTY,
    inputConfig: {
        type: InputTypes.SELECT,
        required: true
    },
    formValidators: [Validators.required],
    disallowNull: true,
    showIf: function (formData, dataContainerService) {
        var record = dataContainerService.getValueFromKeyPath('trip_packages.record');
        return !record || !record.domain || isRecordInFilteredDomain(record, record.is_owner);
    }
});
fieldsMap.set('taxation', {
    key: 'taxation',
    name: 'Fiscalità',
    inputConfig: {
        type: InputTypes.DATASET_SELECT,
        required: true
    },
    disallowNull: true,
    valueType: ValueTypes.PROPERTY,
    datasetCode: 'select_options',
    inputHint: 'Seleziona la fiscalità',
    formValidators: [Validators.required],
    showIf: function (formData, dataContainerService) {
        var record = dataContainerService.getValueFromKeyPath('trip_packages.record');
        return !record || !record.domain || isRecordInFilteredDomain(record, record.is_owner);
    },
    remoteOptions: {
        limit: 100,
        getParams: function (dataContainerService, record, options) {
            return {
                options_code: 'taxation',
                target: 'trip_packages'
            };
        },
        getLabel: function (option) {
            if (!option)
                return '';
            return option.label;
        },
        getValue: function (option) {
            if (!option)
                return '';
            return option.value;
        },
        getDisplay: function (option) {
            if (!option)
                return '';
            return option.label;
        }
    }
});
fieldsMap.set('child_from', {
    key: 'child_from',
    name: 'Bambini da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '4',
    defaultValue: function () { return 4; }
});
fieldsMap.set('child_to', {
    key: 'child_to',
    name: 'Adulto da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '11',
    defaultValue: function () { return 11; }
});
fieldsMap.set('adult_to', {
    key: 'adult_to',
    name: 'Senior da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '64',
    defaultValue: function () { return 64; }
});
fieldsMap.set('commessa_id', {
    key: 'commessa_id',
    name: 'Commessa',
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'commesse',
    inputConfig: {
        clearable: true,
        type: InputTypes.DATASET_AUTOCOMPLETE,
        hint: 'Scrivi per cercare una commessa'
    },
    remoteOptions: {
        limit: 100,
        getLabel: function (option) {
            if (!option)
                return '';
            return option.code;
        },
        getValue: function (option) {
            if (!option)
                return;
            return option.id;
        },
        supportCreate: true
    }
});
fieldsMap.set('only_resells', {
    key: 'only_resells',
    name: 'Proprietà',
    valueType: ValueTypes.NUMBER,
    inputConfig: {
        type: InputTypes.SELECT
    },
    showIf: function (data, dataContainerService) {
        if (!dataContainerService)
            return false;
        return Boolean(dataContainerService.domainFilterService.filterDomainId.value);
    },
    defaultValue: function () { return 0; },
    options: [
        {
            label: 'Tutti',
            value: 0
        },
        {
            label: 'Solo i miei',
            value: 1
        },
        {
            label: 'Solo rivendite',
            value: 2
        }
    ]
});
columnsMap.set('active_status', {
    title: 'Stato',
    key: 'active_status',
    fieldView: FieldViews.HTML,
    getDisplayHTML: function (record, value, translateService) {
        record = record.current_resell_trip_package || record;
        var colorClass = 'red-bg';
        var text = '<span class="status">' + translateService.instant('Non Attivo') + '</span>';
        if (record.active_status === 1) {
            colorClass = 'green-bg';
            text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
            if (record.publish_on_web === 1) {
                text = '<span class="status">' + translateService.instant('Attivo e pubblicato') + '</span>';
            }
            else {
                colorClass = 'orange-bg';
            }
        }
        var html = '<div class="package_status" style="display:flex;align-items: center;">';
        html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
        html += text;
        html += '</div>';
        return html;
    }
});
columnsMap.set('code', {
    title: 'Codice',
    key: 'code',
    routerLink: function (record) {
        return datasetDetailPage('trip_packages', record.id);
    }
});
columnsMap.set('name', {
    title: 'Nome',
    key: 'description',
    fieldView: FieldViews.HTML,
    getDisplayHTML: function (record, value, translateService) {
        var html = '<span style=\'display: block;\'>' + record.description + '</span>';
        return html;
    }
});
columnsMap.set('image', {
    title: 'Immagine',
    key: 'master_image',
    fieldView: FieldViews.IMAGE,
    routerLink: function (record) {
        return datasetDetailPage('trip_packages', record.id);
    }
});
columnsMap.set('owner', {
    title: 'Proprietario',
    key: 'owner',
    fieldView: FieldViews.HTML,
    getDisplayHTML: function (record, value, translateService) {
        var html = '';
        //const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
        //const isFiltered = filteredDomainId && filteredDomainId !== 'null';
        if (record.domain) {
            if (record.reseller_domain_package && record.reseller_domain_package.reseller_domain) {
                // tslint:disable-next-line: max-line-length
                if (record.reseller_domain_package.reseller_domain.logo) {
                    html += "<label><img src=\"" + getBaseServerAddressRoot() + "/document_files/resize/200xnull/" + record.reseller_domain_package.reseller_domain.logo + "\" width=\"100px\" /></label>";
                }
                else {
                    html += "<label><span>" + record.reseller_domain_package.reseller_domain.display_name + "</span></label>";
                }
            }
            else if (record.tourcms_channel_id_tour_id || record.bokun_vendor_id_tour_id || record.ticketlandia_museum_id_event_id) {
                // aggiungere logo tour operator nel caso di pacchetto rivenduto
                if (record.tourcms_channel_id_tour_id) {
                    // tslint:disable-next-line: max-line-length
                    html += '<img src="assets/images/tour-operators/tour-cms.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" />';
                }
                else if (record.bokun_vendor_id_tour_id) {
                    // tslint:disable-next-line: max-line-length
                    html += '<img src="assets/images/tour-operators/bokun-logo.svg" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" />';
                }
                else if (record.ticketlandia_museum_id_event_id) {
                    // tslint:disable-next-line: max-line-length
                    html += '<img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 120px; margin-left: 10px;" />';
                }
            }
            else {
                // tslint:disable-next-line: max-line-length
                if (record.domain.logo) {
                    html += "<label><img src=\"" + getBaseServerAddressRoot() + "/document_files/resize/200xnull/" + record.domain.logo + "\" width=\"100px\" /></label>";
                }
                else {
                    html += "<label><span>" + record.domain.display_name + "</span></label>";
                }
            }
        }
        return html;
    }
});
export { fieldsMap, columnsMap };
