import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef } from '@angular/material';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var ATripBookingPackageRelatedComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ATripBookingPackageRelatedComponent, _super);
    function ATripBookingPackageRelatedComponent(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.matDialogRef = matDialogRef;
        _this.data = data;
        return _this;
    }
    ATripBookingPackageRelatedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.data.datasetCode,
            actionCode: this.data.actionCode,
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            useBeforeCalls: false,
            fetchData: false
        });
        if (this.parentDatasetRS.getDatasetCode() == 'trip_booking_packages') {
            this.parentDatasetRS.record
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function (val) {
                _this.tripBookingPackage = val;
            });
            this.tripBookingPackage = this.parentDatasetRS.record.value;
        }
    };
    ATripBookingPackageRelatedComponent.prototype.onSelected = function (selectedOption) {
        this.datasetRS.record.next(selectedOption);
    };
    ATripBookingPackageRelatedComponent.prototype.onCloseClick = function () {
        this.matDialogRef.close();
    };
    Object.defineProperty(ATripBookingPackageRelatedComponent.prototype, "isResaleCopy", {
        get: function () {
            return Boolean(this.data.tripBookingPackage.ref_id);
        },
        enumerable: true,
        configurable: true
    });
    return ATripBookingPackageRelatedComponent;
}(DestroyableComponent));
export { ATripBookingPackageRelatedComponent };
