import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TripPackageLangContentBaseClass } from '../../lang-cont-base.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { TripFacilityConfig } from 'app/configs/datasets/trip_facilities';
import { FormBuilder } from '@angular/forms';
import FilterFields from './form.fields';
var TripPackageTripFacilitiesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageTripFacilitiesComponent, _super);
    function TripPackageTripFacilitiesComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, fb) {
        var _this = _super.call(this, appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, null, {
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentForeignKey: 'trip_package_id',
            parentValueKey: 'trip_packages.recordId'
        }) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        _this.fb = fb;
        _this.field = FilterFields.get('trip_facility_id');
        _this.dynamCols = [
            {
                name: 'id',
                title: 'ID',
                primaryKey: true,
                show: false,
            },
            {
                name: 'slug',
                title: 'Icona',
                primaryKey: false,
                show: true,
                valueType: 'icon',
            },
            {
                name: 'title',
                title: 'Descrizione',
                primaryKey: false,
                show: true,
            },
            {
                name: 'active_language_codes',
                title: 'Lingue',
                primaryKey: false,
                show: true,
                valueType: 'langs',
                getDisplayValue: function (record) {
                    var e_1, _a;
                    var html = '<div style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                    if (record.active_language_codes && record.active_language_codes.length > 0) {
                        var style = 'style="margin-right: 5px;"';
                        try {
                            for (var _b = tslib_1.__values(record.active_language_codes), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var lang = _c.value;
                                html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                    }
                    else {
                        html += '<span>---</span>';
                    }
                    html += '</div>';
                    return html;
                }
            }
        ];
        _this.displayedColumns = ['id', 'slug', 'title', 'active_language_codes', 'action'];
        return _this;
    }
    TripPackageTripFacilitiesComponent.prototype.ngOnInit = function () {
        this.formConfig = TripFacilityConfig.formConfigsMap['generalForm'];
        this.filterFormGroup = this.fb.group({ trip_facility_id: null });
        _super.prototype.ngOnInit.call(this);
    };
    return TripPackageTripFacilitiesComponent;
}(TripPackageLangContentBaseClass));
export { TripPackageTripFacilitiesComponent };
