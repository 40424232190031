import { IStepViewConfig, TangerineBgColors } from 'app/interfaces';
import generalForm from './general.form';
import { TripResourceLanguageContentConfig } from '../trip_resource_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { TripServiceConfig } from '../trip_services';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import generalViewModeForm from './forms/general-view-mode.form';
import {PivotTripResourceTripResourcesConfig} from "../pivot_trip_resource_trip_resources";

export const config: IStepViewConfig = {
	tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
	title: 'Risorsa',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-form-step',
			options: {
				formConfig: generalForm
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-data-viewer-step',
			options: {
				fields: generalViewModeForm.fields
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Avanzate',
			component: 'common-blocks-step',
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return isRecordInFilteredDomain(record, record.is_owner);
			},
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_resource_allotments',
						title: 'Allotment',
						showIf(record: any): boolean{
							return record.allotment_type !== 'free';
						}
					},
					{
						type: 'list',
						datasetCode: 'entity_staff_element_unified_list_items',
						title: 'Staff'
					},
					{
						type: 'list',
						datasetCode: 'trip_resource_staff_assignments',
						title: 'Calendario Staff'
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_resources_trip_meeting_points',
						title: 'Punti di ritrovo Pick-Up',
						showIf(record: any): boolean{
							if(!record || !record.properties || !record.properties.resource_sub_types) return false;
							return ['PICKUP'].includes(record.properties.resource_sub_types.value);
						}
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_resource_trip_resources',
						title: 'Opzioni risorsa',
						showIf(record: any): boolean{
							if(!record || !record.properties || !record.properties.resource_sub_types) return false;
							return ['OPTS'].includes(record.properties.resource_sub_types.value);
						}
					},
					{
						type: 'resource-input',
						datasetCode: 'trip_resource',
						title: 'Campi aggiuntivi',
						component:'ResourceInputsComponent',
						getDisplayValue(record: any): string{
							if(!record) return '---';
							return record.data;
						},
						showIf(record: any): boolean{
							if(!record) return false;
							return ['BAR'].includes(record.properties.resource_sub_types.value);
						}
					},
				]
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-form-step',
			options: {
				formConfig: TripResourceLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_resource_language_contents',
				parentForeignKey: 'trip_resource_id',
				parentValueKey: 'trip_resources.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-data-viewer-step',
			options: {
				formConfig: TripResourceLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_resource_language_contents',
				parentForeignKey: 'trip_resource_id',
				parentValueKey: 'trip_resources.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Servizi e prezzi',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_services',
						updateParent: true,
						title: TripServiceConfig.title
					}
				]
			}
		},
		{
			title: 'Punti di ritiro',
			stepperTitle: 'Punti di <br>Ritiro',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_resource_meeting_point_tickets',
						title: 'Punti di ritiro',
					},
				]
			},
			showIf(component): boolean{
				const ticketing = component.datasetACS.getValueFromKeyPath('domainModules').ticketing;
				const ticketingIsEnabled = ticketing && ticketing.enabled;
				const record = component.record;
				if (!ticketingIsEnabled) return false;
				if(!record || !record.properties || !record.properties.resource_sub_types) return false;
				return ['TICKETTXT','TICKET'].includes(record.properties.resource_sub_types.value);
			}
		},
		{
			title: 'PDF e immagini',
			stepperTitle: 'PDF e <br>Immagini',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_resource_ticket_files',
						title: 'Carica PDF e immagini',
					},
				]
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record || !record.properties || !record.properties.resource_sub_types) return false;
				return ['TICKETPDF'].includes(record.properties.resource_sub_types.value);
			}
		},
		{
			title: 'Upload PDF/IMG',
			stepperTitle: 'PDF e <br>Immagini',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_resource_ticket_files',
						title: 'Carica PDF e immagini',
					},
				]
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record || !record.properties || !record.properties.resource_sub_types) return false;
				return ['FILE'].includes(record.properties.resource_sub_types.value);
			}
		},
	]
};
