import { Injectable } from '@angular/core';
import { startOfDay } from 'date-fns';

@Injectable()
export class CalendarService{

	public view = 'month';
	public viewDate: Date;
	public selectedDateTimes: any[] = [];
	public calendarConfig: any = {};

	/**
	 * Constructor
	 *
	 * @param {HttpClient} _httpClient
	 */
	constructor(
			){
		this.view = 'month';
		this.viewDate = new Date();
		this.selectedDateTimes = [startOfDay(new Date()).getTime()];
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------


}
