import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { Subject, Observable } from 'rxjs';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/voucher_setting_language_contents/detail/:recordId',
};

export const VoucherSettingLangContentsConfig: IDatasetConfig = {
	name: 'voucher_setting_language_contents',
	title: 'Traduzione Filiali',
	singleTitle: 'Traduzione Filiale',
	ajaxDatasetCode: 'voucher_setting_language_contents',
	fieldsMap: fieldsMap,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: false
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {},
		create: {},
		edit: {},
		list: {}
	}
};
