import {
	Pipe,
	PipeTransform,
	Injector,
	Optional
} from '@angular/core';
import { IFieldDefinition } from 'app/interfaces';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { FieldHelper } from 'app/helpers';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

@Pipe({
	name: 'getDisplayFieldValue'
})
export class GetDisplayFieldValuePipe implements PipeTransform {

	public constructor(
		private injector: Injector,
		private datasetService: DatasetService,
		@Optional() private datasetACS: DatasetActionContainerService
	) {}

	transform(record: any, fieldDefinition: IFieldDefinition, defaultValue = ''): any {
		if (!fieldDefinition) {
			console.log('GetDisplayFieldValuePipe > fieldDefinition invalid');
			return defaultValue;
		}
		if (!record) {
			console.log('GetDisplayFieldValuePipe > record invalid');
			return defaultValue;
		}
		
		let value = FieldHelper.getDisplayFieldValue(fieldDefinition, record, defaultValue, this.datasetACS);
		if(!fieldDefinition.transforms) return value;

		for (const transformConfig of fieldDefinition.transforms) {
			const args = transformConfig.args || [];
			if(transformConfig.pipeClass){
				const pipe = this.injector.get(transformConfig.pipeClass);
				value = pipe.transform(value, ...args);
			}else if(transformConfig.transform){
				value = transformConfig.transform(value, ...args);
			}
		}
		return value;
	}
}