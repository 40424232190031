/**
 * Default App Configuration
 *
 */
import { environment } from './../../environments/environment';

export const appConfig = {
	serverAddress: !environment.serverAddress ? window.location.origin : environment.serverAddress,
	serverPort: environment.serverPort,
	apiRoutePrefix: environment.apiRoutePrefix,
	sessionMaxAge: environment.sessionMaxAge,
	BUGSNAG_API_KEY: environment.BUGSNAG_API_KEY,

	websocket: environment.websocket
};

export function getBaseServerAddressRoot(defaultValue: string = ''): string{
	let address = appConfig.serverAddress;
	if(appConfig.serverPort && address){
		address += ':' + appConfig.serverPort;
	}
	address = address || defaultValue;

	return address;
}
