import { Component, OnInit, Inject, ViewChild, forwardRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetService } from '../../services/dataset.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import formConfig from './form.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';

@Component({
	selector: 'request-packages-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService), 
		forwardRef(() => DatasetActionContainerService),
	]
})

export class RequestPackagesDialogComponent implements OnInit {

	public loading: boolean;
	public submitted: boolean;
	public formConfig = formConfig;

	protected appService: AppService;
	protected datasetService: DatasetService;
	protected http: HttpClient;
	protected dataContainerService: DatasetActionContainerService;

	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;

	constructor(
		@Inject(MAT_DIALOG_DATA) protected injectedData: any, 
		protected dialogRef: MatDialogRef<RequestPackagesDialogComponent>,
		@Inject(forwardRef(() => PageClosureContainerService)) public pageClosureContainerPage: PageClosureContainerService,
		@Inject(forwardRef(() => DatasetActionContainerService)) public datasetACS: DatasetActionContainerService,
	) {}

	onConfirmClick(): void {
		this.loading = true;
		this.submitted = true;
		this.formViewer.formGroup.disable();
		const packagesForm = this.formViewer.getFormData();

		if (packagesForm.trip_packages_id === null || packagesForm.domain_id === null) {
			this.loading = false;
			this.submitted = false;
			this.formViewer.formGroup.enable();
			return;
		}

		const tripPackages = packagesForm.trip_packages_id.join();
		const URL = '/dataset/domain_packages/command/set_packages';
		const HEADERS = new HttpHeaders({'X-Domain-Id': packagesForm.domain_id});

		this.datasetService.post<any>(URL, {tripPackages}, {headers: HEADERS})
		.subscribe({
			next: (resp) => {
				this.dialogRef.close(true);

				if (resp.warning) {
					this.appService.showErrorMessage(resp.warning);
				} else {
					this.appService.showSuccessMessage('Richiesta effettuata con successo');
				}
			},
			error: () => {
				this.loading = false;
				this.submitted = false;
				this.formViewer.formGroup.enable();
			}
		});
	}

	close(): void {
		this.dialogRef.close(true);
	}

	ngOnInit(): void{
		this.appService = this.injectedData.appService;
		this.http = this.injectedData.httpClient;
		this.datasetService = this.injectedData.datasetService;
	}
}
