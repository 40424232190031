import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpHeaders } from '@angular/common/http';
var DatasetComboboxInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetComboboxInputComponent, _super);
    function DatasetComboboxInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.loading = true;
        _this.supportCreate = false;
        _this.selectedItems = [];
        _this.separatorKeysCodes = [ENTER, COMMA];
        _this.inputFormControl = new FormControl();
        _this.filteredOptions = new Subject();
        return _this;
    }
    DatasetComboboxInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            if (_this.inputService.formInputDefinition.remoteOptions) {
                _this.supportCreate = Boolean(_this.inputService.formInputDefinition.remoteOptions.supportCreate);
            }
            _this.fetchFilteredOptions('');
            _this.initRemoteValue();
            _this.inputService.control
                .statusChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (status) {
                if (status === 'INVALID') {
                    _this.inputFormControl.setErrors({ invalid: _this.inputService.errorMessage });
                }
                else {
                    _this.inputFormControl.setErrors(null);
                }
                _this.inputFormControl.markAsTouched();
            });
            _this.inputService.control.valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function () {
                var currentValue = _this.inputService.control.value;
                if (currentValue) {
                    // todo check if all items are presents
                }
            });
        });
    };
    DatasetComboboxInputComponent.prototype.initRemoteValue = function (withLoading) {
        var _this = this;
        var resultSubject = new Subject();
        var control = this.inputService.control;
        if (!control) {
            resultSubject.complete();
            return resultSubject;
        }
        var currentValue = control.value;
        if (!currentValue || currentValue === '[]') {
            resultSubject.complete();
            return resultSubject;
        }
        if (this.initRemoteValueSubscription) {
            this.initRemoteValueSubscription.unsubscribe();
        }
        var params = {};
        if (this.inputService.formInputDefinition.remoteOptions && typeof (this.inputService.formInputDefinition.remoteOptions.getParams) === 'function') {
            params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
        }
        params['perPage'] = 20;
        params['column_multipe_json_values'] = currentValue;
        if (!params['column_name'])
            params['column_name'] = 'id';
        params['sortBy'] = 'created_at';
        if (withLoading)
            this.loading = true;
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.initRemoteValueSubscription = this.datasetService.get(this.getListRoute(), params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (response.items && response.items.length > 0) {
                    _this.selectedItems = response.items;
                    resultSubject.next(response.items);
                    resultSubject.complete();
                    if (_this.inputService.formInputDefinition.extra && _this.inputService.formInputDefinition.extra.onInit) {
                        _this.inputService.formInputDefinition.extra.onInit(_this.inputService.formGroup, _this.selectedItems);
                    }
                }
                else {
                    resultSubject.error(response);
                    resultSubject.complete();
                }
                if (withLoading)
                    _this.loading = false;
            },
            error: function (response) {
                resultSubject.error(response);
                resultSubject.complete();
                if (withLoading)
                    _this.loading = false;
            }
        });
        return resultSubject;
    };
    DatasetComboboxInputComponent.prototype.onMatChipInputTokenEnd = function (event) {
        // Add package only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            var input = event.input;
            var item = event.value;
            // Add our item
            if (item && (typeof (item) !== 'string' || this.supportCreate)) {
                this.selectedItems.push(item);
                this.inputService.onSet(item);
                this.onSelectionChanged();
            }
            // Reset the input value
            if (input) {
                input.value = '';
            }
            this.inputFormControl.setValue(null);
        }
        if (this.filterOptionsSubscription) {
            this.filterOptionsSubscription.unsubscribe();
            this.filterOptionsSubscription = null;
            this.loading = false;
        }
    };
    DatasetComboboxInputComponent.prototype.onMatAutocompleteSelectedEvent = function (event) {
        this.selectedItems.push(event.option.value);
        this.inputService.onSet(event.option.value);
        this.onSelectionChanged();
        this.filterInput.nativeElement.value = '';
        this.inputFormControl.setValue(null);
        if (this.filterOptionsSubscription) {
            this.filterOptionsSubscription.unsubscribe();
            this.filterOptionsSubscription = null;
            this.loading = false;
        }
    };
    DatasetComboboxInputComponent.prototype.removeItem = function (item) {
        var index = this.selectedItems.indexOf(item);
        if (index !== -1) {
            this.selectedItems.splice(index, 1);
            this.onSelectionChanged();
        }
    };
    DatasetComboboxInputComponent.prototype.onSelectionChanged = function () {
        var _this = this;
        var val = null;
        if (this.selectedItems) {
            var elements = this.selectedItems.map(function (el) {
                if (typeof (el) === 'string')
                    return el;
                return _this.inputService.formInputDefinition.remoteOptions.getValue(el);
            });
            if (elements && elements.length > 0)
                val = JSON.stringify(elements);
        }
        this.inputService.control.setValue(val);
    };
    DatasetComboboxInputComponent.prototype.getListRoute = function () {
        var route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
        if (this.inputService.formInputDefinition.remoteOptions.getPivotConfig) {
            var pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
            var parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
            route = '/pivot/' + pivotConfig.pivotCode + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
        }
        return route;
    };
    DatasetComboboxInputComponent.prototype.fetchFilteredOptions = function (textSearch) {
        var _this = this;
        if (!this.inputService.formInputDefinition.remoteOptions) {
            console.warn('remoteOptions not setted in fieldDefinition', this.inputService.formInputDefinition);
            return;
        }
        if (this.filterOptionsSubscription)
            this.filterOptionsSubscription.unsubscribe();
        var params = {
            'text-search': textSearch,
            perPage: this.inputService.formInputDefinition.remoteOptions.limit || 5
        };
        if (this.inputService.formInputDefinition.remoteOptions.getParams) {
            params = Object.assign(params, this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue()));
        }
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        var route = this.getListRoute();
        this.textSearch = textSearch;
        this.loading = true;
        this.filterOptionsSubscription = this.datasetService.get(route, params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                _this.filteredOptions.next(result.items);
                _this.loading = false;
            },
            error: function () {
                _this.loading = false;
            }
        });
    };
    DatasetComboboxInputComponent.prototype.displayFun = function (record) {
        if (typeof (record) === 'string' && this.supportCreate)
            return record;
        if (!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions)
            return '---';
        if (this.inputService.formInputDefinition.remoteOptions.getDisplay) {
            return this.inputService.formInputDefinition.remoteOptions.getDisplay(record);
        }
        else if (this.inputService.formInputDefinition.remoteOptions.getLabel) {
            return this.inputService.formInputDefinition.remoteOptions.getLabel(record);
        }
    };
    DatasetComboboxInputComponent.prototype.trackByOptions = function (index) {
        return index;
    };
    return DatasetComboboxInputComponent;
}(DestroyableComponent));
export { DatasetComboboxInputComponent };
