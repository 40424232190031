import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService, DatasetEvents, getDatasetConfigAction } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { ParticipantListEmailDialogComponent } from 'app/main/components/dataset/dialogs/participant-list-email-dialog/participant-list-email-dialog.component';
import { IDataParticipantList } from 'app/interfaces/participant-list-email.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActualTripConfig } from 'app/configs/datasets/actual_trips';
import { DomainsConfig } from 'app/configs/datasets/domains';
import { VoucherSettingLangContentsConfig } from 'app/configs/datasets/voucher_setting_language_contents';
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { IPaginationResponse, InputTypes, ValueTypes } from 'app/interfaces';
import TripResourceStaffAssignmentsTableViewConfig from 'app/configs/datasets/trip_resource_staff_assignments/trip-resource-staff-assignments-table-view.viewConfig';
import { IFormConfig } from 'app/interfaces';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';

@Component({
	selector   : 'domain-user',
	templateUrl: './detail-view.component.html',
	styleUrls  : ['./detail-view.component.scss'],
})
export class DomainUserComponent extends BaseViewComponent implements OnInit{

	@ViewChild('entityFormViewer', { static: false }) entityFormViewer: FormViewerComponent;
	@ViewChild('configFormViewer', { static: false }) configFormViewer: FormViewerComponent;
	public infoFormConfig: IFormConfig;
	public voucherLangFormConfig: IFormConfig;
	formErrors: any;
	customImageOptions: any;
	stepConfig: any;
	userIsAdmin = false;
	stepperIndex = 0;
	domainConfigRawFormViewer: IFormConfig = {
		fields: [
			{
				name: '',
				key: 'configs',
				valueType: ValueTypes.PRETTY_JSON,
				inputConfig: {
					type: InputTypes.TEXTAREA
				}
			}
		]
	};

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public appService: AppService,
		public domainFilterService: DomainFilterService,
		private viewContainerRef: ViewContainerRef
	){
		super(datasetACS, datasetRS, datasetNavigatorService);
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.userIsAdmin = this.authService.isAdministrator();
		this.customImageOptions = {minWidth: 324, minHeight: 52, ratio: 324 / 52};
		this.stepConfig = {
			title: 'Traduzioni Voucher',
			stepperTitle: 'Traduzioni Voucher',
			component: 'common-i18n-form-step',
			options: {
				formConfig: 'generalForm',
				datasetCode: 'voucher_setting_language_contents',
				parentForeignKey: 'domain_id',
				parentValueKey: 'domains.recordId',
			},
		};

		this.infoFormConfig = DomainsConfig.formConfigsMap['infoForm'];
		this.voucherLangFormConfig = VoucherSettingLangContentsConfig.formConfigsMap['generalForm'];

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(domain => {
			if(domain) this.datasetACS.putContextData('contextual_domain_id', domain.id);
			else this.datasetACS.removeContextData('contextual_domain_id');

			if(domain && this.domainFilterService.filterDomainId.value && this.domainFilterService.filterDomainId.value !== domain.id){
				this.domainFilterService.setFilterDomain(domain.id);
			}
		});

		this.stepperIndex = Number(this.route.snapshot.queryParams['step']);
	}

	reload(): void {
		this.datasetRS.reload()
		.subscribe(() => this.appService.setLogo(this.datasetRS.record.value.logo_img));
	}

	saveRawConfig(): void{
		this.configFormViewer.formGroup.disable();
		const formData = this.configFormViewer.getFormData();
		this.datasetACS.loading.next(true);
		this.datasetService.post<any>(this.datasetACS.getUpdateRoute(null, 'domain_configs', this.datasetRS.record.value.id_domain_configs), formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (nextResponse) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: DatasetEvents.EDITED, data: nextResponse});
				if(this.configFormViewer){
					this.configFormViewer.formGroup.enable();
					this.configFormViewer.clearForm();
				}

				this.datasetRS.reload();

				const editConfig = getDatasetConfigAction(this.datasetACS.datasetCode, 'edit');
				if(editConfig && typeof(editConfig.afterSave) === 'function'){
					editConfig.afterSave(this.datasetACS, nextResponse, this.datasetRS.record.value, this.viewContainerRef);
				}
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.configFormViewer){
					this.configFormViewer.formGroup.enable();
					this.configFormViewer.setErrors(this.formErrors);
				}
			}
		});
	}

	onSave(): void{
		this.entityFormViewer.formGroup.disable();
		let formData = this.entityFormViewer.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		this.datasetACS.loading.next(true);
		this.datasetService.post<any>(this.datasetACS.getUpdateRoute(this.datasetRS.record.value), formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (nextResponse) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: DatasetEvents.EDITED, data: nextResponse});
				if(this.entityFormViewer){
					this.entityFormViewer.formGroup.enable();
					this.entityFormViewer.clearForm();
				}

				this.datasetRS.reload();
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.entityFormViewer){
					this.entityFormViewer.formGroup.enable();
					this.entityFormViewer.setErrors(this.formErrors);
				}
			}
		});
	}

	get baseUrl(): string{
		return window.location.origin;
	}
}
