<div class='info-container' style='width:100%;' fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start" fxLayoutAlign.lt-lg='start center'>
	<ng-container *ngIf='fields' [ngTemplateOutlet]="containerTemplate" [ngTemplateOutletContext]='{fields:fields}'></ng-container>
</div>
<ng-template #containerTemplate let-cfields='fields'>
	<ng-container *ngFor='let item of cfields'>
		<ng-container *ngIf='item && item.fields'>
			<div class='info-container' [ngClass]='item.config && item.config.class' style='width:100%;' 
				fxLayoutGap="10px"
				[fxLayout]="item.config && item.config.flexLayout || 'row wrap'"
				[fxLayoutAlign]="item.config && item.config.flexLayoutAlign || 'start'"
				[fxFlex]="item.config && item.config.flex || '1 1 auto'"
				fxLayout.lt-lg='column'
				fxLayoutAlign.lt-lg='start center'
				fxFlex.lt-lg='1 1 auto'
				[style.max-width]='item.config && item.config.maxWidth'>
				<ng-container [ngTemplateOutlet]="containerTemplate" [ngTemplateOutletContext]='{fields: item.fields}'></ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf='item && !item.fields'>
			<data-field-viewer [fieldDefinition]='item' [record]='record' [dataContainerService]='dataContainerService'></data-field-viewer>
		</ng-container>
	</ng-container>
</ng-template>
