import { Component, SkipSelf, Self } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TripDestinationStepComponent } from '../trip-destination-step/step.component';

@Component({
	selector   : 'resell-trip-destination-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class ResellTripDestinationStepComponent extends TripDestinationStepComponent{

	constructor(
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected formBuilder: FormBuilder
	){
		super(datasetRS, datasetACS, datasetNavigatorService, formBuilder);

		this.datasetACS.init({
			datasetCode: 'trip_packages',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.datasetRS,
			fetchData: false
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetRS.record.next(this.parentDatasetACS.getValueFromKeyPath('reselledData'));
		});
	}
}
