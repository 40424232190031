import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../../../../../services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from '../../../../components/dataset/services/dataset-record.service';
import { Subject } from 'rxjs';
import * as _ from "lodash";
var TrenitaliaPackageComponent = /** @class */ (function () {
    function TrenitaliaPackageComponent(appService, datasetRS) {
        this.appService = appService;
        this.datasetRS = datasetRS;
    }
    TrenitaliaPackageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._unsubscribeAll = new Subject();
        this.isCarnet = _.get(this.inputTripBookingPackage, 'external_solution_data.isCarnet') || _.get(this.inputTripBookingPackage, 'target_info.orderType') === 'carnet';
        this.status = this.inputTripBookingPackage.tour_operator_booking_status;
        if (this.inputTripBookingPackage.external_solution_data) {
            this.dataView = Array.isArray(this.inputTripBookingPackage.external_solution_data['travel']['travelSolutions'])
                ? this.inputTripBookingPackage.external_solution_data['travel']['travelSolutions'][0]
                : this.inputTripBookingPackage.external_solution_data['travel']['travelSolutions'];
        }
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            var e_1, _a;
            var totalLordo = Number(_this.inputTripBookingPackage.total_amount);
            if (_this.inputTripBookingPackage.children_trip_booking_packages) {
                try {
                    for (var _b = tslib_1.__values(_this.inputTripBookingPackage.children_trip_booking_packages), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var tbp = _c.value;
                        totalLordo += Number(tbp.total_amount);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            _this.totalFee = _this.inputTripBookingPackage.fee;
            _this.totalRowLordo = totalLordo;
        });
    };
    TrenitaliaPackageComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return TrenitaliaPackageComponent;
}());
export { TrenitaliaPackageComponent };
