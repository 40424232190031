import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews} from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general-form';
import updateForm from './forms/update-form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domain_user_roles/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
	if(domainId) {
		params['domain_id'] = domainId;
	}
	return params;
}

export const DomainsUserRolesConfig: IDatasetConfig = {
	name: 'domain_user_roles',
	title: 'Ruoli Domini',
	singleTitle: 'Ruolo Domini',
	ajaxDatasetCode: 'domain_user_roles',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		updateForm,
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: updateForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
			getCallParams
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: updateForm
			},
			getCallParams
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'level',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nome',
						key: 'name',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						},
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: string): string {
							let html = value;
							if (record.domain_id === null) {
								html = '<span style=\'color: #4f6c47;\'>' + value + '</span>';
							}
							return html;
						}
					},
					{
						title: 'Descrizione',
						key: 'description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: string): string {
							let html = value;
							if (record.domain_id === null) {
								html = '<span style=\'color: #4f6c47;\'>' + value + '</span>';
							}
							return html;
						}
					}
				],
				actions: ['create', 'detail', 'edit', 'delete']
			}
		}
	}
	/*stepViewConfig: {
		isLinear: () => true,
		title: 'Permessi',
		steps: [
			{
				title: 'Permessi',
				stepperTitle: 'Permessi',
				component: 'domain-user-role-permission-step',
			}
		]
	}*/
};
