import { Input, EventEmitter, Output, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookingService } from './booking.service';
import { DestroyableComponent } from 'app/main/destroyable.component';

export class BaseStepChangerComponent extends DestroyableComponent implements OnInit, OnDestroy{

	constructor(
		public bookingService: BookingService
	){
		super();
		this._unsubscribeAll = new Subject<any>();
	}

	ngOnInit(): void {
		// go to next step
		this.bookingService.nextStep
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(stepper => {
			stepper.next();
		});

		// go to previous step
		this.bookingService.prevStep
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(stepper => {
			stepper.previous();
		});
	}
}
