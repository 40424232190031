import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { Subject } from 'rxjs';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
var DatasetSearchInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetSearchInputComponent, _super);
    function DatasetSearchInputComponent(appService, datasetService, matDialog, inputService, viewContainerRef) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.inputService = inputService;
        _this.viewContainerRef = viewContainerRef;
        _this.loading = false;
        _this.displayValue = '';
        _this.previousValue = null;
        return _this;
    }
    DatasetSearchInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.getSelectedRecord();
            _this.previousValue = _this.inputService.control.value;
            _this.inputService.control.valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function () {
                var currentValue = _this.inputService.control.value;
                if (currentValue === _this.previousValue)
                    return;
                _this.previousValue = currentValue;
                if (currentValue) {
                    if (!_this.selectedRecord || currentValue !== _this.selectedRecord.id) {
                        _this.getSelectedRecord()
                            .subscribe({
                            next: function () {
                                _this.inputService.onSet(_this.selectedRecord);
                            }
                        });
                        return;
                    }
                }
                _this.inputService.onSet(_this.selectedRecord);
            });
        });
    };
    DatasetSearchInputComponent.prototype.getSelectedRecord = function () {
        var _this = this;
        var resultSubject = new Subject();
        var id = this.inputService.control.value;
        if (!id) {
            resultSubject.next(null);
            resultSubject.complete();
            return resultSubject;
        }
        var filters = null;
        if (this.inputService.formInputDefinition.remoteOptions && this.inputService.formInputDefinition.remoteOptions.getParams) {
            filters = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
        }
        this.loading = true;
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.datasetService.get('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + id, filters, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.selectedRecord = response;
                if (typeof (_this.inputService.formInputDefinition.remoteOptions.getDisplay) === 'function') {
                    _this.displayValue = _this.inputService.formInputDefinition.remoteOptions.getDisplay(response);
                }
                else {
                    _this.displayValue = _this.inputService.formInputDefinition.remoteOptions.getLabel(response, _this.inputService.dataContainerService);
                }
                _this.loading = false;
                resultSubject.next(response);
                resultSubject.complete();
            },
            error: function (response) {
                _this.loading = false;
                resultSubject.error(response);
                resultSubject.complete();
            }
        });
        return resultSubject;
    };
    DatasetSearchInputComponent.prototype.openSelectionDialog = function () {
        var _this = this;
        var filters = null;
        if (this.inputService.formInputDefinition.remoteOptions && this.inputService.formInputDefinition.remoteOptions.getParams) {
            filters = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
        }
        var data = {
            title: 'Seleziona',
            datasetCode: this.inputService.formInputDefinition.datasetCode,
            filters: filters,
            viewConfig: this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.listViewConfig,
            onFilersChanged: function (data) {
                _this.inputService.emitCustomEvent('selectionFilterChanged', data);
            }
        };
        if (this.inputService.formInputDefinition && this.inputService.formInputDefinition.inputConfig && this.inputService.formInputDefinition.inputConfig.viewConfig) {
            data['viewConfig'] = this.inputService.formInputDefinition.inputConfig.viewConfig;
        }
        if (_.has(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig.dialogConfig')) {
            data['selectionBtnIcon'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.selectionBtnIcon;
            data['tableStyle'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.tableStyle;
            data['filterStyle'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.filterStyle;
            data['selectionBtnTxt'] = _.get(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.selectionBtnTxt', undefined);
        }
        var params = { data: data };
        var selectDialogConfig = _.get(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig');
        var selectDialogConfigMatDialogConfig = _.get(selectDialogConfig, 'matDialogConfig');
        if (selectDialogConfig && selectDialogConfig.passThrough) {
            params.viewContainerRef = this.viewContainerRef;
        }
        if (selectDialogConfigMatDialogConfig) {
            if (selectDialogConfigMatDialogConfig.width) {
                params['width'] = selectDialogConfigMatDialogConfig.width.toString();
            }
            if (selectDialogConfigMatDialogConfig.panelClass) {
                params['panelClass'] = selectDialogConfigMatDialogConfig.panelClass;
            }
            if (selectDialogConfigMatDialogConfig.minHeight) {
                params['minHeight'] = selectDialogConfigMatDialogConfig.minHeight;
            }
            if (selectDialogConfigMatDialogConfig.id) {
                params['id'] = selectDialogConfigMatDialogConfig.id;
            }
        }
        var dialogRef = this.matDialog.open(SelectDatasetRecordDialogComponent, params);
        dialogRef.afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.selectedRecord = result;
            if (result) {
                if (_this.inputService.formInputDefinition.remoteOptions.getDisplay) {
                    _this.displayValue = _this.inputService.formInputDefinition.remoteOptions.getDisplay(result);
                }
                else if (_this.inputService.formInputDefinition.remoteOptions.getLabel) {
                    _this.displayValue = _this.inputService.formInputDefinition.remoteOptions.getLabel(result, _this.inputService.dataContainerService);
                }
            }
            _this.inputService.control.setValue(result.id);
        });
    };
    DatasetSearchInputComponent.prototype.unselect = function () {
        this.selectedRecord = null;
        this.displayValue = null;
        this.inputService.control.setValue(null);
    };
    return DatasetSearchInputComponent;
}(DestroyableComponent));
export { DatasetSearchInputComponent };
