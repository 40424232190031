import * as tslib_1 from "tslib";
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormInputViewerComponent } from 'app/main/components/form-input-viewer/form-input-viewer.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { AppService } from 'app/services/app.service';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import FilterFields from './filter.fields';
import formConfig from './form.fields';
import saveAs from 'file-saver';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
var DashboardTotemPageComponent = /** @class */ (function () {
    /**
     * Constructor
     *
    */
    function DashboardTotemPageComponent(appService, http, formBuilder, matDialog, datasetACS, datasetService, pageContainerService) {
        this.appService = appService;
        this.http = http;
        this.formBuilder = formBuilder;
        this.matDialog = matDialog;
        this.datasetACS = datasetACS;
        this.datasetService = datasetService;
        this.pageContainerService = pageContainerService;
        this.selectedGroups = [{ label: "Dominio", value: "dominio" }];
        this.enabledFilters = [];
        this.selectionsLabel = {};
        this.expandedRecordGroups = {};
        this.report = {};
        this.cachedData = {};
        this.fileterFields = FilterFields;
        this._page = 1;
        this._perPage = 30;
        this._pageIndex = 0;
        this._totals = {};
        // init form group
        this.filterFormGroup = this.formBuilder.group({
            from_date: moment().startOf('year'),
            to_date: null,
            from_date_comparison: null,
            to_date_comparison: null,
            filter_type: new FormControl('booking', Validators.required),
            comparison_type: new FormControl('year', Validators.required),
            sort_type: new FormControl('total_amount_desc', Validators.required),
            tipo_fatturato: new FormControl('fatturato_netto', Validators.required),
            trip_package_id: null,
            actual_trip_id: null,
            trip_category_id: null,
            trip_resource_id: null,
            entity_customer_id: null,
            entity_id: null,
            user_id: null,
            coupon_code: null,
            origin_channel: null,
            status: null,
            branch_code: null,
            groups: null,
            delivery_status: null,
            trip_booking_package_target: null
        });
        this._loading = new BehaviorSubject(false);
        this._loadingNext = new BehaviorSubject(false);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(DashboardTotemPageComponent.prototype, "min", {
        get: function () {
            return this.filterFormGroup.get('from_date').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "max", {
        get: function () {
            /**
             * if end of year can go to next year
             */
            var minDate = moment(this.min);
            var month = minDate.month();
            var year = minDate.year();
            if (month >= 11)
                year += 1;
            return new Date(year, 11, 31, 23, 59, 59);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "minComparison", {
        get: function () {
            return this.filterFormGroup.get('from_date_comparison').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "maxComparison", {
        get: function () {
            /**
             * if end of year can go to next year
             */
            var minDate = moment(this.minComparison);
            var month = minDate.month();
            var year = minDate.year();
            if (month >= 11)
                year += 1;
            return new Date(moment(this.minComparison).year(), 11, 31, 23, 59, 59);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "isCustomComparison", {
        get: function () {
            return (this.filterFormGroup.get('comparison_type').value == 'custom');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "fromDate", {
        get: function () {
            return moment(this.filterFormGroup.get('from_date').value).format('DD MMMM YYYY');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "toDate", {
        get: function () {
            return moment(this.filterFormGroup.get('to_date').value).format('DD MMMM YYYY');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "fromDateComparison", {
        get: function () {
            var comparison_type = this.filterFormGroup.get('comparison_type').value;
            var date = moment(this.filterFormGroup.get('from_date_comparison').value);
            var from = moment(this.filterFormGroup.get('from_date').value);
            var to = moment(this.filterFormGroup.get('to_date').value);
            var days = to.diff(from, 'days', true);
            // get date
            switch (comparison_type) {
                case 'custom':
                    return date.format('DD MMMM YYYY');
                case 'period':
                    return from.subtract(days + 1, 'days').format('DD MMMM YYYY');
                case 'year':
                    return from.subtract(1, 'year').format('DD MMMM YYYY');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "toDateComparison", {
        get: function () {
            var comparison_type = this.filterFormGroup.get('comparison_type').value;
            var date = moment(this.filterFormGroup.get('to_date_comparison').value);
            var from = moment(this.filterFormGroup.get('from_date').value);
            var to = moment(this.filterFormGroup.get('to_date').value);
            var days = to.diff(from, 'days', true);
            // get date
            switch (comparison_type) {
                case 'custom':
                    return date.format('DD MMMM YYYY');
                case 'period':
                    return to.subtract(days + 1, 'days').format('DD MMMM YYYY');
                case 'year':
                    return to.subtract(1, 'year').format('DD MMMM YYYY');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "participantPercentage", {
        get: function () {
            if (!this._totals['comparison_range']['total_participants'])
                return;
            var defaultRangeParticipants = this._totals['default_range']['total_participants'];
            var total_participants = 0;
            if (defaultRangeParticipants > 0)
                total_participants = defaultRangeParticipants;
            var num_participants_com = this._totals['comparison_range']['total_participants'];
            var diff = total_participants - num_participants_com;
            var division = diff / num_participants_com;
            var result = division * 100;
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DashboardTotemPageComponent.prototype, "totalAmountPercentage", {
        get: function () {
            if (!this._totals['comparison_range']['total_amount'])
                return;
            var defaultRangeAmount = this._totals['default_range']['total_amount'];
            var total_amount;
            if (!defaultRangeAmount) {
                total_amount = 0;
            }
            else
                total_amount = parseInt(defaultRangeAmount);
            var total_amount_com = parseInt(this._totals['comparison_range']['total_amount']);
            var diff = total_amount - total_amount_com;
            var division = diff / total_amount_com;
            var result = division * 100;
            return result;
        },
        enumerable: true,
        configurable: true
    });
    DashboardTotemPageComponent.prototype.onChangeDateFrom = function (date, inputName) {
        /*const to_date = date.value.add(1, 'd');
        this.filterFormGroup.get(inputName).setValue(to_date);*/
    };
    DashboardTotemPageComponent.prototype.openFilters = function () {
        var _this = this;
        this.matDialog.open(FormDialogComponent, {
            width: '700px',
            data: {
                title: 'Filtri ricerca',
                formData: this.filtersData,
                dataContainerService: this.datasetACS,
                formConfig: formConfig,
                positiveText: 'Applica'
            }
        })
            .afterClosed()
            .subscribe(function (formData) {
            if (formData) {
                var status_1 = formData['status'] || [];
                if (status_1.length > 0) {
                    formData['status'] = status_1.map(function (s) { return s.trim(); });
                }
            }
            _this.setFiltersData(formData);
            _this.setEnabledFilters();
            _this.countFilters();
        });
    };
    DashboardTotemPageComponent.prototype.setFiltersData = function (formData) {
        if (!formData)
            return;
        this.filtersData = {
            trip_package_id: formData.trip_package_id || '',
            start_station_id: formData.start_station_id || '',
            actual_trip_id: formData.actual_trip_id || '',
            trip_category_id: formData.trip_category_id || '',
            trip_resource_id: formData.trip_resource_id || '',
            entity_customer_id: formData.entity_customer_id || '',
            entity_id: formData.entity_id || '',
            user_id: formData.user_id || '',
            coupon_code: formData.coupon_code || '',
            origin_channel: formData.origin_channel || '',
            status: formData.status || '',
            branch_code: formData.branch_code || '',
            delivery_status: formData.delivery_status,
            trip_booking_package_target: formData.trip_booking_package_target
        };
    };
    DashboardTotemPageComponent.prototype.setEnabledFilters = function () {
        var e_1, _a;
        if (!this.filtersData)
            return;
        // set enabled filters
        this.enabledFilters = [];
        try {
            for (var _b = tslib_1.__values(Object.keys(this.filtersData)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                if (!this.filtersData[key])
                    continue;
                if (Array.isArray(this.filtersData[key]) && this.filtersData[key].length == 0)
                    continue;
                // set label and value
                this.enabledFilters.push({
                    label: this.selectionsLabel['selected_label_' + key],
                    value: key
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DashboardTotemPageComponent.prototype.removeFilter = function (filterKey) {
        delete this.filtersData[filterKey];
        this.setEnabledFilters();
        this.countFilters();
    };
    DashboardTotemPageComponent.prototype.countFilters = function () {
        var e_2, _a;
        if (!this.filtersData)
            return;
        var increment = 0;
        var keysFilters = Object.keys(this.filtersData);
        try {
            for (var keysFilters_1 = tslib_1.__values(keysFilters), keysFilters_1_1 = keysFilters_1.next(); !keysFilters_1_1.done; keysFilters_1_1 = keysFilters_1.next()) {
                var key = keysFilters_1_1.value;
                var value = this.filtersData[key];
                if (Array.isArray(value) && value.length > 0) {
                    increment++;
                }
                else if (!Array.isArray(value) && value) {
                    increment++;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (keysFilters_1_1 && !keysFilters_1_1.done && (_a = keysFilters_1.return)) _a.call(keysFilters_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.numFilters = increment;
    };
    DashboardTotemPageComponent.prototype.onPageChanged = function (event) {
        this._page = event.pageIndex + 1;
        this._perPage = event.pageSize;
        this._pageIndex = event.pageIndex;
        this.loadFirstGroup();
    };
    DashboardTotemPageComponent.prototype.generaFile = function (typeFile) {
        var _this = this;
        var params = this.getCallParams();
        this._loading.next(true);
        this.http.post(this.appService.getBaseServerAddress() + '/bi-statistics/export/' + typeFile, params, {
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                var filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
                saveAs(response.body, filename.replace(/\"/g, ''));
                _this._loading.next(false);
            },
            error: function (error) {
                _this.appService.showErrorMessage(error.message);
                _this._loading.next(false);
            }
        });
    };
    DashboardTotemPageComponent.prototype.parseJSON = function (value) {
        return JSON.parse(value);
    };
    DashboardTotemPageComponent.prototype.drop = function (event) {
        moveItemInArray(this.selectedGroups, event.previousIndex, event.currentIndex);
    };
    DashboardTotemPageComponent.prototype.remove = function (group) {
        this.selectedGroups = this.selectedGroups.filter(function (g) { return g != group; });
    };
    DashboardTotemPageComponent.prototype.loadFirstGroup = function () {
        var _this = this;
        this.cachedData = {};
        this.report = {};
        this.expandedRecordGroups = {};
        var params = this.getCallParams();
        if (this.selectedGroups[0])
            params['group'] = this.selectedGroups[0].value;
        // start page loader
        this._loading.next(true);
        // make group http call
        this.loadGroup(params)
            .pipe(map(function (group) {
            var _a;
            _this._totals = (_a = {},
                _a['default_range'] = {
                    total_amount: group['dataset']['totals']['total_amount'],
                    total_participants: group['dataset']['totals']['total_participants']
                },
                _a['comparison_range'] = {
                    total_amount: group['dataset']['totals']['total_amount_comparison'] || 0,
                    total_participants: group['dataset']['totals']['total_participants_comparison'] || 0
                },
                _a);
            return group;
        }), takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (resp) {
                var group = params['group'];
                _this.report[group] = resp;
                _this._loading.next(false);
                _this._loading.complete();
            },
            error: function (err) {
                _this.appService.showErrorMessage('Si è verificato un errore inaspettato.');
                _this._loading.next(false);
                _this._loading.complete();
            }
        });
    };
    DashboardTotemPageComponent.prototype.loadGroup = function (data) {
        return this.http.post(this.appService.getBaseServerAddress() + '/bi-statistics/' + data.group, data);
    };
    DashboardTotemPageComponent.prototype.getCallParams = function () {
        return {
            page: this._page,
            perPage: this._perPage,
            from_date: this.filterFormGroup.get('from_date').value,
            to_date: this.filterFormGroup.get('to_date').value,
            from_date_comparison: this.filterFormGroup.get('from_date_comparison').value,
            to_date_comparison: this.filterFormGroup.get('to_date_comparison').value,
            comparison_type: this.filterFormGroup.get('comparison_type').value,
            filter_type: this.filterFormGroup.get('filter_type').value,
            tipo_fatturato: this.filterFormGroup.get('tipo_fatturato').value,
            sort_type: this.filterFormGroup.get('sort_type').value,
            groups: this.selectedGroups.map(function (g) { return g && g.value; }),
            filters: this.filtersData
        };
    };
    DashboardTotemPageComponent.prototype.getFilterParams = function (item, group, loadFromCache) {
        var _a;
        var recordId = '';
        if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']) {
            recordId = JSON.parse(item['extra'])[0]['f3'];
        }
        else
            recordId = item['record_id'];
        var data = (_a = {}, _a[group] = recordId, _a);
        var currentG = this.report[group] || null;
        if (loadFromCache)
            currentG['filter_by'] = data;
        if (currentG) {
            data = Object.assign(currentG['filter_by'] || {}, data);
        }
        return data;
    };
    DashboardTotemPageComponent.prototype.expand = function (i, item, nextGroup, currentGroup, prevGroup, isMasterGroup) {
        var _this = this;
        var recordId = '';
        if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']) {
            recordId = JSON.parse(item['extra'])[0]['f3'];
        }
        else
            recordId = item['record_id'];
        if (this.expandedRecordGroups[currentGroup] && this.expandedRecordGroups[currentGroup][i] && this.expandedRecordGroups[currentGroup][i][recordId]) {
            delete this.expandedRecordGroups[currentGroup][i][recordId];
            return;
        }
        else if (isMasterGroup)
            this.closePanel();
        if (this.cachedData[currentGroup] && this.cachedData[currentGroup][recordId]) {
            var cache = this.cachedData[currentGroup][recordId];
            var group = cache['currentGroup'];
            this.report[group]['dataset'] = cache['dataset'];
            this.report[group]['filter_by'] = cache['filter_by'];
            this.report[group]['currentGroup'] = group;
            this.report[group]['prevGroup'] = cache['prevGroup'];
            this.report[group]['nextGroup'] = cache['nextGroup'];
            this.epxandPanel(i, item, cache['prevGroup'], isMasterGroup);
            return;
        }
        this._loadingNext.next(true);
        var params = this.getCallParams();
        params['group'] = nextGroup;
        params['filter_by'] = this.getFilterParams(item, currentGroup);
        params['arrayKey'] = recordId;
        // make group call
        this.loadGroup(params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (resp) {
                var group = params['group'];
                if (_this.report[group]) {
                    var newDataset = Object.assign(_this.report[group]['dataset'], resp['dataset']);
                    _this.report[group]['dataset'] = newDataset;
                    _this.report[group]['filter_by'] = resp['filter_by'];
                    _this.report[group]['currentGroup'] = resp['currentGroup'];
                    _this.report[group]['prevGroup'] = resp['prevGroup'];
                    _this.report[group]['nextGroup'] = resp['nextGroup'];
                }
                else
                    _this.report[group] = resp;
                _this.epxandPanel(i, item, currentGroup, isMasterGroup);
                if (isMasterGroup) {
                    if (!_this.cachedData[currentGroup])
                        _this.cachedData[currentGroup] = {};
                    if (!_this.cachedData[currentGroup][recordId])
                        _this.cachedData[currentGroup][recordId] = {};
                    _this.cachedData[currentGroup][recordId]['dataset'] = _this.report[group]['dataset'];
                    _this.cachedData[currentGroup][recordId]['filter_by'] = resp['filter_by'];
                    _this.cachedData[currentGroup][recordId]['currentGroup'] = resp['currentGroup'];
                    _this.cachedData[currentGroup][recordId]['prevGroup'] = resp['prevGroup'];
                    _this.cachedData[currentGroup][recordId]['nextGroup'] = resp['nextGroup'];
                }
                // loading next
                _this._loadingNext.next(false);
            },
            error: function (err) {
                console.log(err);
                _this._loadingNext.next(false);
            }
        });
    };
    DashboardTotemPageComponent.prototype.epxandPanel = function (i, item, currentGroup, isMasterGroup) {
        var recordId = '';
        if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']) {
            recordId = JSON.parse(item['extra'])[0]['f3'];
        }
        else
            recordId = item['record_id'];
        if (isMasterGroup)
            this.expandedRecordGroups = {};
        if (!this.expandedRecordGroups[currentGroup])
            this.expandedRecordGroups[currentGroup] = {};
        if (!this.expandedRecordGroups[currentGroup][i]) {
            this.expandedRecordGroups[currentGroup][i] = {};
        }
        this.expandedRecordGroups[currentGroup][i][recordId] = recordId;
    };
    DashboardTotemPageComponent.prototype.closePanel = function () {
        this.expandedRecordGroups = {};
        return;
    };
    /**
     * On init
     */
    DashboardTotemPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetService.fetchSourceData({
            ajaxDatasetCode: '',
            defaultActions: {},
            enabledActions: {
                create: false,
                detail: false,
                edit: false,
                list: false,
            },
            name: '',
            title: ''
        }, [
            {
                name: 'domainModules',
                path: '/dataset/domains/command/config',
                params: {
                    key: 'modules'
                }
            }
        ], null, null)
            .subscribe({
            next: function (result) {
                _this.pageContainerService.putToSharedData(result);
            },
            error: function () {
            }
        });
        this.filterFormGroup.get('groups').valueChanges
            .subscribe({
            next: function (group) {
                if (!group || _this.selectedGroups.includes(group))
                    return;
                _this.selectedGroups.push(group);
                _this.filterFormGroup.get('groups').patchValue(null);
            },
            error: function (err) {
                console.error(err);
            }
        });
        /**
         * on app event
         */
        var label = {};
        this.appService.eventEmitter
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            label[event.name] = event.target;
        });
        this.selectionsLabel = label;
        this.loadFirstGroup();
    };
    DashboardTotemPageComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return DashboardTotemPageComponent;
}());
export { DashboardTotemPageComponent };
