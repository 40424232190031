import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators, FormGroup } from '@angular/forms';
import { fieldsMap as meetingFieldMap} from '../meeting_points/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('time', {
	key: 'time',
	name: 'Orario',
	valueType: ValueTypes.TIME,
	inputConfig: {
		type: InputTypes.TIME,
		hint: 'Optionale, puoi aggiungere un punto di ritrovo senza orario'
	}
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Nota',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('type', {
	key: 'meeting_point_unified_list_type',
	name: 'Tipo',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): string{
		return 'single';
	}
});

fieldsMap.set('meeting_point_id', cloneFieldDefinition(meetingPointIdField, { 
	inputType: InputTypes.DATASET_AUTOCOMPLETE
}));

meetingFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'meeting_point.' + value.key });
	fieldsMap.set('meeting_point_' + key, value);
});

fieldsMap.set('list_id', {
	key: 'list_id',
	name: 'Lista',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_point_unified_lists',
	inputHint: 'Scrivi per cercare una lista già creata, lascia vuoto per crearne una nuova',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {};
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');

			if(tripPackageId){
				params['properties.parent_dataset_code'] = 'trip_packages';
				params['properties.parent_id'] = tripPackageId;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
});

export {fieldsMap};
