import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, Injector, ViewContainerRef, EventEmitter } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { NewTripPackageOverviewDialogComponent } from 'app/main/components/dataset/dialogs/new-trip-package-overview/dialog.component';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { ActualTripDateComponent } from 'app/main/components/dataset/dialogs/actual-trip-date/dialog.component';
import { BookingStep2Service } from '../../../step-2/view.service';
import sendDeliveryAction from 'app/main/components/dataset/dialogs/delivery-email-dialog/delivery.email';
import sendDeliveryCodeAction from 'app/main/components/dataset/dialogs/delivery-email-dialog/delivery.code.email';
var TicketBookingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TicketBookingComponent, _super);
    function TicketBookingComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, domainFilterService, bookingStepService, tripBookingPackageService, bookingService, injector, viewContainerRef) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.domainFilterService = domainFilterService;
        _this.bookingStepService = bookingStepService;
        _this.tripBookingPackageService = tripBookingPackageService;
        _this.bookingService = bookingService;
        _this.injector = injector;
        _this.viewContainerRef = viewContainerRef;
        _this.loadingMeetingPoint = false;
        _this.hasEditableData = false;
        _this.canEditData = false;
        _this.changed = new EventEmitter();
        return _this;
    }
    Object.defineProperty(TicketBookingComponent.prototype, "tripBookingPackage", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "isResaleCopy", {
        get: function () {
            return Boolean(this.datasetRS.record.value.ref_id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "tripBookingStatus", {
        get: function () {
            return this.bookingStepService && this.bookingStepService.datasetRS.record.value && this.bookingStepService.datasetRS.record.value.status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "tripBookingPackageRelatedDialogData", {
        get: function () {
            return {
                datasetCode: '',
                actionCode: '',
                tripBookingPackage: this.tripBookingPackage,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "canDelete", {
        get: function () {
            return this.bookingService.canEdit && !this.tripBookingPackage.tour_operator_booking_ref && !this.tripBookingPackage.tour_operator_booking_commit_status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "canEdit", {
        get: function () {
            return this.hasEditableData && !this.canEditData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TicketBookingComponent.prototype, "meetingPointDescription", {
        get: function () {
            if (!this.tripBookingPackage || !this.tripBookingPackage.default_meeting_point_unified_list_item)
                return null;
            if (!this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point)
                return null;
            var description = this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point.description;
            var time = this.tripBookingPackage.default_meeting_point_unified_list_item.time;
            if (time) {
                time = '(' + time.slice(0, 5) + ')';
                description += ' ' + time;
            }
            return description;
        },
        enumerable: true,
        configurable: true
    });
    TicketBookingComponent.prototype.ngOnInit = function () { };
    TicketBookingComponent.prototype.deleteTripBookingPackage = function () {
        var _this = this;
        this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
            .afterClosed()
            .subscribe(function (result) {
            if (result) {
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGES
                });
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            }
        });
    };
    TicketBookingComponent.prototype.trackTripBookingResourcesFun = function (index, item) {
        return item.id;
    };
    TicketBookingComponent.prototype.reloadTripBooking = function () {
        this.appService.appEvent.next({ name: RELOAD_BOOKING });
    };
    TicketBookingComponent.prototype.viewTripPackage = function () {
        this.datasetNavigatorService.openDialog(NewTripPackageOverviewDialogComponent, {
            parentDatasetRS: this.datasetRS,
            parentDatasetACS: this.datasetACS,
            tripPackage: this.tripBookingPackage.trip_package
        }, { minWidth: 700 });
    };
    TicketBookingComponent.prototype.editDefaultMeetingPoint = function () {
        var _this = this;
        var data = {
            datasetCode: 'meeting_point_unified_list_items',
            filters: {
                list_id: this.tripBookingPackage.actual_trip.meeting_point_list_id,
                default_meeting_point_list_item_id: this.datasetRS.record.value.default_meeting_point_list_item_id
            },
            title: 'Punto di ritrovo',
            parentDatasetACS: this.datasetACS,
            parentDatasetRS: this.datasetRS
        };
        this.matDialog.open(SelectDatasetRecordDialogComponent, {
            data: data
        }).afterClosed()
            .subscribe(function (record) {
            if (!record)
                return;
            _this.loadingMeetingPoint = true;
            _this.datasetService.post(_this.datasetACS.getUpdateRoute(_this.datasetRS.record.value), {
                default_meeting_point_list_item_id: record.id
            }).pipe(takeUntil(_this._unsubscribeAll))
                .subscribe({
                next: function (response) {
                    _this.datasetRS.reload().subscribe({
                        next: function () {
                            _this.loadingMeetingPoint = false;
                        },
                        error: function () {
                            _this.loadingMeetingPoint = false;
                        }
                    });
                },
                error: function (response) {
                    _this.loadingMeetingPoint = false;
                }
            });
        });
    };
    TicketBookingComponent.prototype.openDialog = function () {
        var _this = this;
        var data = {
            parentDatasetACS: this.datasetACS,
            tripBookingPackage: this.tripBookingPackage,
            bookingStepService: this.bookingStepService,
        };
        this.matDialog.open(ActualTripDateComponent, {
            data: data
        }).afterClosed()
            .subscribe({
            next: function (response) {
                _this.datasetRS.reload();
            },
        });
    };
    TicketBookingComponent.prototype.emailConfirmPlaceDate = function () {
        sendDeliveryAction({
            trip_booking_id: this.tripBookingPackage.trip_booking_id,
            trip_package_id: this.tripBookingPackage.trip_package_id
        }, this.datasetACS, this.datasetNavigatorService);
    };
    TicketBookingComponent.prototype.emailDeliveryCode = function () {
        sendDeliveryCodeAction({
            trip_booking_id: this.tripBookingPackage.trip_booking_id,
            trip_booking_package_id: this.tripBookingPackage.id
        }, this.datasetACS, this.datasetNavigatorService);
    };
    TicketBookingComponent.prototype.confirmPlaceDate = function () {
        var _this = this;
        this.bookingService.setDeliveryStatus(this.tripBookingPackage.id, 'in consegna')
            .subscribe(function () {
            _this.datasetRS.reload();
        });
    };
    TicketBookingComponent.prototype.confirmDelivery = function () {
        var _this = this;
        this.bookingService.setDeliveryStatus(this.tripBookingPackage.id, 'consegnato')
            .subscribe(function () {
            _this.datasetRS.reload();
        });
    };
    return TicketBookingComponent;
}(DestroyableComponent));
export { TicketBookingComponent };
