/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i2 from "@angular/material/icon";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i12 from "@angular/material/button";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "@angular/common";
import * as i15 from "@angular/forms";
import * as i16 from "./input.component";
import * as i17 from "../../form-input-viewer.service";
var styles_MultipleFileInputComponent = [];
var RenderType_MultipleFileInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultipleFileInputComponent, data: {} });
export { RenderType_MultipleFileInputComponent as RenderType_MultipleFileInputComponent };
function View_MultipleFileInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-icon", [["class", "title-input-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), i0.ɵdid(2, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i0.ElementRef, i5.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(3, 0, ["", ""])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.inputService.formInputDefinition.titleTooltip; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).inline; var currVal_1 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.inputService.formInputDefinition.titleIcon; _ck(_v, 3, 0, currVal_3); }); }
function View_MultipleFileInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "file-drop-zone"]], [[2, "dragging", null]], [[null, "dragleave"], [null, "dragstart"], [null, "dragover"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragleave" === en)) {
        var pd_0 = (_co.onFileDragLeave($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragstart" === en)) {
        var pd_1 = (_co.onFileDragStart($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (_co.onFileDragOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("drop" === en)) {
        var pd_3 = (_co.onFileDrop($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(2, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["cloud_download"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dragging; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 2).inline; var currVal_2 = (((i0.ɵnov(_v, 2).color !== "primary") && (i0.ɵnov(_v, 2).color !== "accent")) && (i0.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Carica uno o pi\u00F9 file")); _ck(_v, 5, 0, currVal_3); }); }
function View_MultipleFileInputComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_MatButton_0, i11.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i12.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["close"]))], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MultipleFileInputComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "file-loaded"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(2, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["insert_drive_file"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleFileInputComponent_5)), i0.ɵdid(5, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = !_co.inputService.inputConfig.readOnly; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).inline; var currVal_1 = (((i0.ɵnov(_v, 2).color !== "primary") && (i0.ɵnov(_v, 2).color !== "accent")) && (i0.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MultipleFileInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, null, null, null, null, function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i15.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i15.ControlContainer, null, [i15.FormGroupDirective]), i0.ɵdid(3, 16384, null, 0, i15.NgControlStatusGroup, [[4, i15.ControlContainer]], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 10, "div", [["class", "file-input-controller"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "label", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleFileInputComponent_2)), i0.ɵdid(7, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleFileInputComponent_3)), i0.ɵdid(12, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleFileInputComponent_4)), i0.ɵdid(14, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputService.formGroup; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.inputService.formInputDefinition.titleIcon; _ck(_v, 7, 0, currVal_1); var currVal_3 = (!_co.inputService.inputConfig.readOnly && !_co.inputService.control.value); _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.inputService.control.value; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.inputService.computedTitle)); _ck(_v, 9, 0, currVal_2); }); }
export function View_MultipleFileInputComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { fileInput: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultipleFileInputComponent_1)), i0.ɵdid(2, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputService.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MultipleFileInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "multiple-file-input", [], null, null, null, View_MultipleFileInputComponent_0, RenderType_MultipleFileInputComponent)), i0.ɵdid(1, 49152, null, 0, i16.MultipleFileInputComponent, [i17.FormInputViewerService], null, null)], null, null); }
var MultipleFileInputComponentNgFactory = i0.ɵccf("multiple-file-input", i16.MultipleFileInputComponent, View_MultipleFileInputComponent_Host_0, {}, {}, []);
export { MultipleFileInputComponentNgFactory as MultipleFileInputComponentNgFactory };
