import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('trip_destination_id', {
	key: 'trip_destination_id',
	name: 'Destinazione',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_destinations',
	remoteOptions: {
		clearInputOnCreation: true,
		supportCreate: true,
		createHint: 'Scrivi per cercare una destinazione',
		clearOnSelection: true,
		limit: 100,
		icon: 'search',
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

export default fieldsMap;
