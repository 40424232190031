import { Component, OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from '../../page-closure-container.service';
@Component({
	selector     : 'passport-page',
	templateUrl  : './passport.component.html',
	styleUrls    : ['./passport.component.scss'],
	providers: [PageClosureContainerService]
})
export class PassportComponent implements OnInit{

	/**
	 * Constructor
	 *
	 */
	constructor(public pageClosureContainerService: PageClosureContainerService){}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{}
}
