import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('title', {
	key: 'title',
	name: 'Titolo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('points', {
	key: 'points',
	name: 'Punteggio',
	inputType: InputTypes.STARS,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('date', {
	key: 'date',
	name: 'Data',
	inputType: InputTypes.DATETIME,
	valueType: ValueTypes.DATETIME
});

fieldsMap.set('reviewer_display_name', {
	key: 'reviewer_display_name',
	name: 'Nome Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('reviewer_first_name', {
	key: 'reviewer_first_name',
	name: 'Nome Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('reviewer_last_name', {
	key: 'reviewer_last_name',
	name: 'Cognome Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('reviewer_nation', {
	key: 'reviewer_nation',
	name: 'Nazione Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('reviewer_language', {
	key: 'reviewer_language',
	name: 'Lingua Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('reviewer_email', {
	key: 'reviewer_email',
	name: 'Email Recensore',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('product_description', {
	key: 'product_description',
	name: 'Prodotto',
	valueType: ValueTypes.STRING
});

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue: (record) => {
		switch (record.status) {
			case 'approved':
				return '<span style="color:green;">Approvata</span>';
			case 'trashed':
				return '<span style="color:red;">Rifiutata</span>';
			case 'pending':
				return '<span style="color:#DAA520;">In Attesa</span>';
			default:
				return '<span style="color:#DAA520;">In Attesa</span>';
		}
	},
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Approvata',
			value: 'approved'
		},
		{
			label: 'Rifiutata',
			value: 'trashed'
		},
		{
			label: 'In Attesa',
			value: 'pending'
		}
	]
});

fieldsMap.set('trip_package_id', cloneFieldDefinition(tripPackageIdField, {key: 'product_id'}));
fieldsMap.set('trip_product_id', cloneFieldDefinition(tripPackageIdField, {
	key: 'product_id',
	datasetCode: 'products',
	name: 'Prodotti',
}));

export {fieldsMap};
