/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./train-breadcrumbs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./train-breadcrumbs.component";
var styles_TrainBreadcrumbsComponent = [i0.styles];
var RenderType_TrainBreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrainBreadcrumbsComponent, data: {} });
export { RenderType_TrainBreadcrumbsComponent as RenderType_TrainBreadcrumbsComponent };
function View_TrainBreadcrumbsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { last: 0, train: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.context.last, _v.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent.parent, 0); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TrainBreadcrumbsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.train.subSegments; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TrainBreadcrumbsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "img", [["class", "train-icon"]], [[8, "src", 4]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.train.type; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trainTypeImages[_v.parent.parent.context.train.type]; _ck(_v, 0, 0, currVal_0); }); }
function View_TrainBreadcrumbsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "small", [["class", "train-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trainTypeImages[_v.parent.context.train.type]; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.train.name; _ck(_v, 4, 0, currVal_1); }); }
function View_TrainBreadcrumbsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" / "]))], null, null); }
function View_TrainBreadcrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.train.type == "multi") && _v.context.train.subSegments); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.train.type != "multi"); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 5, 0, currVal_2); }, null); }
function View_TrainBreadcrumbsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { last: 0, train: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.context.last, _v.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent, 0); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TrainBreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["trainBradcrumbs", 2]], null, 0, null, View_TrainBreadcrumbsComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "train-breacrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrainBreadcrumbsComponent_7)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trains; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TrainBreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "trenitalia-train-breadcrumbs", [], null, null, null, View_TrainBreadcrumbsComponent_0, RenderType_TrainBreadcrumbsComponent)), i1.ɵdid(1, 49152, null, 0, i10.TrainBreadcrumbsComponent, [], null, null)], null, null); }
var TrainBreadcrumbsComponentNgFactory = i1.ɵccf("trenitalia-train-breadcrumbs", i10.TrainBreadcrumbsComponent, View_TrainBreadcrumbsComponent_Host_0, { trains: "trains" }, {}, []);
export { TrainBreadcrumbsComponentNgFactory as TrainBreadcrumbsComponentNgFactory };
