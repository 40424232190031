import * as tslib_1 from "tslib";
import { ActionDialogComponent } from "app/main/pages/edit-booking/action-dialog/action-dialog.component";
export default function setPlafond(datasetNavigator, data, record) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var loadingDialogRef, response, e_1;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!data)
                        return [2 /*return*/];
                    loadingDialogRef = datasetNavigator.openDialog(ActionDialogComponent, {
                        title: 'Attendere durante il completamento della richiesta...',
                        contentMessage: ''
                    });
                    data['domainPackageId'] = record.id;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, datasetNavigator.datasetService.post('/dataset/domain_packages/command/set_plafond', data)
                            .toPromise()];
                case 2:
                    response = _a.sent();
                    if (response) {
                        loadingDialogRef.componentInstance.loading = false;
                        if (response.success) {
                            loadingDialogRef.componentInstance.resultStatus = 'success';
                            loadingDialogRef.componentInstance.setTitle('Plafond impostato');
                            loadingDialogRef.componentInstance.setMessage('Il plafond è stato associato correttamente alla richiesta. Vedi dettaglio richiesta.');
                        }
                        else {
                            loadingDialogRef.componentInstance.resultStatus = 'error';
                            loadingDialogRef.componentInstance.setTitle('Errore operazione');
                            loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
                        }
                    }
                    return [2 /*return*/, response];
                case 3:
                    e_1 = _a.sent();
                    loadingDialogRef.componentInstance.loading = false;
                    loadingDialogRef.componentInstance.resultStatus = 'error';
                    loadingDialogRef.componentInstance.setTitle('Errore operazione');
                    loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
