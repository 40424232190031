import { IStepViewConfig, TangerineBgColors } from 'app/interfaces';
import generalForm from './general-form';
import dateForm from './date-form/date-form';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { MeetingPointLanguageContentConfig } from '../meeting_point_language_contents';

export const config: IStepViewConfig = {
	tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
	title: 'Punto di ritrovo',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-form-step',
			options: {
				formConfig: generalForm
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-form-step',
			options: {
				formConfig:
					MeetingPointLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'meeting_point_language_contents',
				parentForeignKey: 'meeting_point_id',
				parentValueKey: 'meeting_points.recordId',
			},
		},
		{
			title: 'Mappa',
			stepperTitle: 'Mappa',
			component: 'common-map-step',
			options: {
				markers: 1
			}
		},
		{
			title: 'Orari di apertura e chiusura',
			stepperTitle: 'Orari di apertura e <br>chiusura',
			component: 'common-date-step',
			options: {
				formConfig: dateForm
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (container.record && container.record.target === 'ticket') {
					return true;
				}
				return false;
			}
		}
	]
};
