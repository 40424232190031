import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IStepViewConfig, IStepConfig } from 'app/interfaces';
import { StepService } from 'app/main/components/dataset/services/step-state.service';

@Component({
	selector   : 'common-step-view-container',
	templateUrl: './view.component.html',
	styleUrls  : ['./view.component.scss'],
	providers: [StepService]
})
export class CommonStepViewContainerComponent extends BaseViewComponent implements OnInit{
	
	@Input() stepViewConfig: IStepViewConfig;
	public initStep = false;
	public stepIndex = 0;
	public stepperIndex = 0;
	public isStepLinear = true;
	@ViewChild('stepper', {static: true}) public stepper: MatStepper;
	@ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;

	public computedSteps: any[] = [];

	public showOverview = true;

	public steps: IStepConfig[];

	public datasetStepViewConfigs: any;

	public subtitle: string;

	/*@Input() 
	set record(record: any){
		this.datasetRS.record.next(record);
	}*/
	get record(): any{ return this.datasetRS.record.value; }

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected _fuseSidebarService: FuseSidebarService,
		protected router: Router,
		protected route: ActivatedRoute,
		protected location: Location,
		protected stepService?: StepService,
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		super.ngOnInit();
		this.datasetRS.init();

		this.showOverview = Boolean(this.stepViewConfig.showOverview);
		this.isStepLinear = this.stepViewConfig.isLinear(this);
		// take from beforeCreate
		// in first case take from dataset.resolver call
		this.datasetStepViewConfigs = this.datasetACS.getValueFromKeyPath('stepViewConfig.' + this.datasetACS.datasetCode);
		// if not in creation then load from dataset callModules
		if (!this.datasetStepViewConfigs){
			this.datasetStepViewConfigs = this.datasetACS.getValueFromKeyPath('domainContext.stepViewConfig.' + this.datasetACS.datasetCode);
		}
		
		this.buildSteps();

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(this.onRecordChanged.bind(this));
	}

	buildSteps(): void{
		const newStepConfigs = [];
		const newComputedSteps = [];
		for (const stepConfig of this.stepViewConfig.steps) {
			if(this.datasetStepViewConfigs && stepConfig.id && this.datasetStepViewConfigs[stepConfig.id]){
				const datasetStepConfig = this.datasetStepViewConfigs[stepConfig.id];
				//check config domains
				if(!datasetStepConfig.display) continue;
			}
			if(stepConfig.showIf && !stepConfig.showIf(this)) continue;
			newStepConfigs.push(stepConfig);
			newComputedSteps.push({
				config: stepConfig,
				title: stepConfig.stepperTitle || stepConfig.title,
				completed: this.computeStepCompleted(stepConfig),
			});
		}
		this.steps = newStepConfigs;
		this.computedSteps = newComputedSteps;
		if(this.tabGroup){
			this.tabGroup.selectedIndex = this.stepIndex;
		}
	}

	computeStepCompleted(stepConfig: IStepConfig): boolean{
		if(this.showOverview) return false;
		if(!stepConfig.completed){
			return Boolean(this.record);
		}else{
			return stepConfig.completed(this);
		}
	}

	trackStepBy(index, item): any{
		return index;
	}

	onRecordChanged(record): void{
		this.isStepLinear = this.stepViewConfig.isLinear(this);
		if(!this.initStep){
			if(this.route.snapshot.queryParams['step']){
				this.showOverview = false;
				if(!this.isStepLinear){
					this.stepperIndex = Number(this.route.snapshot.queryParams['step']);
					// this.stepper.selectedIndex = this.route.snapshot.queryParams['step'];
				}else{
					this.stepperIndex = 0;
					// this.stepper.selectedIndex = 0;
				}
			}else{
				this.showOverview = true && this.stepViewConfig.showOverview;
			}
			if(!record) this.showOverview = false;
			
			this.initStep = true;
			this.stepIndex = this.stepperIndex;
			if(this.tabGroup) this.tabGroup.selectedIndex = this.stepperIndex;
		}
		if(this.stepViewConfig.subtitle){
			this.subtitle = this.stepViewConfig.subtitle(this);
		}
		this.buildSteps();
	}

	toggleSidebar(name): void{
		this._fuseSidebarService.getSidebar(name).toggleOpen();
	}

	stepChange(stepChange): void{
		this.showOverview = false;
		this.stepIndex = stepChange.selectedIndex;
		this.buildSteps();

		const urlTree = this.router.createUrlTree([], {
			queryParams: { step: this.stepIndex },
			queryParamsHandling: 'merge'
		});
		
		this.location.go(urlTree.toString());
	}

	onClickOverview(): void{
		this.showOverview = true;
		this.buildSteps();
		// this.stepper.selectedIndex = 0;

		const urlTree = this.router.createUrlTree([], {
			queryParams: { step: null },
			queryParamsHandling: 'merge'
		});
		
		this.location.go(urlTree.toString());
	}
}
