import * as tslib_1 from "tslib";
import { OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogState } from '@angular/material/dialog';
import { of } from 'rxjs';
import { exhaustMap, take, takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { BaseDatasetActionDialog } from 'app/main/components/dataset/dialogs/base-action-dialog.component';
import { InvalidAllotmentsDialogComponent } from '../../invalid-allotments-dialog/dialog.component';
import { DatasetRecordService } from '../../../services/dataset-record.service';
var DatasetEditDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetEditDialogComponent, _super);
    function DatasetEditDialogComponent(datasetACS, appService, datasetService, dialogRef, data, matDialog, datasetRS, viewContainerRef) {
        var _this = _super.call(this, datasetACS, appService, datasetService, dialogRef, data) || this;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.matDialog = matDialog;
        _this.datasetRS = datasetRS;
        _this.viewContainerRef = viewContainerRef;
        _this.actionCode = 'edit';
        return _this;
    }
    DatasetEditDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.datasetACS.datasetRS = this.datasetRS;
        this.datasetACS.ready
            .subscribe(function () {
            _this.datasetRS.record.next(_this.data.record);
            _this.datasetRS.init();
        });
        if (this.data.formConfig) {
            this.formConfig = this.data.formConfig;
        }
        else {
            var actionConfig = this.datasetACS.actionConfig;
            if (actionConfig)
                this.formConfig = actionConfig.viewConfig.formConfig;
        }
        if (!this.formConfig) {
            console.warn('no formConfig defined');
        }
        this.buildTitle();
    };
    DatasetEditDialogComponent.prototype.buildTitle = function () {
        if (this.formConfig && this.formConfig.editTitle) {
            this.dialogTitle = this.formConfig.editTitle;
        }
        else {
            this.dialogTitle = 'Modifica ' + this.datasetACS.datasetConfig.singleTitle;
        }
    };
    DatasetEditDialogComponent.prototype.getActionRoute = function () {
        if (this.data.customRoute)
            return this.data.customRoute;
        if (this.data.method === 'create') {
            return this.datasetACS.getCreateRoute(this.data.customActionCode);
        }
        return this.datasetACS.getUpdateRoute(this.data.record, this.data.customActionCode, this.data.recordId);
    };
    DatasetEditDialogComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        this.loading = true;
        if (typeof (this.datasetACS.actionConfig.getCallParams) === 'function') {
            formData = this.datasetACS.actionConfig.getCallParams(this.datasetACS, this.data.record, formData);
        }
        of(formData)
            .pipe(exhaustMap(function (formData) {
            if (_this.datasetACS.actionConfig && typeof (_this.datasetACS.actionConfig.beforeSave) === 'function') {
                return _this.datasetACS.actionConfig.beforeSave(_this.datasetACS, _this.datasetRS.recordId, formData, _this.formViewer.record, _this.viewContainerRef);
            }
            return of(formData);
        }), take(1), exhaustMap(function (data) {
            return _this.datasetService.post(_this.getActionRoute(), data);
        }), takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.loading = false;
                _this.appService.showSuccessMessage('Modificato con successo');
                _this.dialogRef.close(response);
            },
            error: function (response) {
                _this.loading = false;
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            },
            complete: function () {
                _this.loading = false;
                if (_this.dialogRef.getState() == 0 /* OPEN */) {
                    _this.dialogRef.close(false);
                }
            }
        });
    };
    return DatasetEditDialogComponent;
}(BaseDatasetActionDialog));
export { DatasetEditDialogComponent };
