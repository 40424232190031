var _a;
import { OnInit, OnDestroy, SimpleChanges, OnChanges, ViewContainerRef, ComponentFactoryResolver, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { FieldHelper } from 'app/helpers';
import { TitleCasePipe } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormInputViewerService } from './form-input-viewer.service';
import { takeUntil } from 'rxjs/operators';
import { TextInputComponent } from './inputs/text/input.component';
import { ColorInputComponent } from './inputs/color/input.component';
import { DateInputComponent } from './inputs/date/input.component';
import { SelectInputComponent } from './inputs/select/input.component';
import { TextareaInputComponent } from './inputs/textarea/input.component';
import { RadioInputComponent } from './inputs/radio/input.component';
import { DateTimeInputComponent } from './inputs/datetime/input.component';
import { ToggleInputComponent } from './inputs/toggle/input.component';
import { ReadOnlyInputComponent } from './inputs/readonly/input.component';
import { DatasetAutocompleteInputComponent } from './inputs/dataset-autocomplete/input.component';
import { DatasetComboboxInputComponent } from './inputs/dataset-combobox/input.component';
import { DatasetMultiSelectInputComponent } from './inputs/dataset-multiselect/input.component';
import { DatasetSearchInputComponent } from './inputs/dataset-search/input.component';
import { DatasetSelectInputComponent } from './inputs/dataset-select/input.component';
import { DatasetSelectDepDropdownInputComponent } from './inputs/dataset-select-dropdown/input.component';
import { TextListInputComponent } from './inputs/text-list/input.component';
import { TextListInput2Component } from './inputs/text-list2/input.component';
import { WeekHoursComponent } from './inputs/week-hours/input.component';
import { TinyMCEInputComponent } from './inputs/tinymce/input.component';
import { FileInputComponent } from './inputs/file/input.component';
import { MultipleFileInputComponent } from './inputs/multiple-file/input.component';
import { TimeInputComponent } from './inputs/time/input.component';
import { HiddenInputComponent } from './inputs/hidden/input.component';
import { NumberInputComponent } from './inputs/number/input.component';
import { PasswordInputComponent } from './inputs/password/input.component';
import { MultiselectInputComponent } from './inputs/multiselect/input.component';
import { BaseTextInputComponent } from './inputs/base-text/input.component';
import { SelectCustomIconInputComponent } from './inputs/select_custom_icon/input.component';
import { SelectFilterableInputComponent } from './inputs/select_filterable/input.component';
import { CompareDateInputComponent } from './inputs/compare_date/input.component';
var inputComponents = (_a = {},
    _a[InputTypes.TEXT] = TextInputComponent,
    _a[InputTypes.DATE] = DateInputComponent,
    _a[InputTypes.SELECT] = SelectInputComponent,
    _a[InputTypes.TEXTAREA] = TextareaInputComponent,
    _a[InputTypes.RADIO] = RadioInputComponent,
    _a[InputTypes.DATETIME] = DateTimeInputComponent,
    _a[InputTypes.TOGGLE] = ToggleInputComponent,
    _a[InputTypes.READONLY] = ReadOnlyInputComponent,
    _a[InputTypes.DATASET_AUTOCOMPLETE] = DatasetAutocompleteInputComponent,
    _a[InputTypes.DATASET_COMBOBOX] = DatasetComboboxInputComponent,
    _a[InputTypes.DATASET_MULTISELECT] = DatasetMultiSelectInputComponent,
    _a[InputTypes.DATASET_SEARCH] = DatasetSearchInputComponent,
    _a[InputTypes.DATASET_SELECT] = DatasetSelectInputComponent,
    _a[InputTypes.DATASET_SELECT_DEP_DROPDOWN] = DatasetSelectDepDropdownInputComponent,
    _a[InputTypes.TEXT_LIST] = TextListInputComponent,
    _a[InputTypes.TEXT_LIST2] = TextListInput2Component,
    _a[InputTypes.WEEK_HOURS] = WeekHoursComponent,
    _a[InputTypes.TINY_MCE] = TinyMCEInputComponent,
    _a[InputTypes.FILE] = FileInputComponent,
    _a[InputTypes.MULTIPLE_FILE] = MultipleFileInputComponent,
    _a[InputTypes.TIME] = TimeInputComponent,
    _a[InputTypes.COMPARE_DATE] = CompareDateInputComponent,
    _a[InputTypes.HIDDEN] = HiddenInputComponent,
    _a[InputTypes.NUMBER] = NumberInputComponent,
    _a[InputTypes.PASSWORD] = PasswordInputComponent,
    _a[InputTypes.MULTI_SELECT] = MultiselectInputComponent,
    _a[InputTypes.BASE_TEXT] = BaseTextInputComponent,
    _a[InputTypes.SELECT_CUSTOM_ICON] = SelectCustomIconInputComponent,
    _a[InputTypes.SELECT_FILTERABLE] = SelectFilterableInputComponent,
    _a[InputTypes.COLOR] = ColorInputComponent,
    _a);
var ɵ0 = { useUtc: true }, ɵ1 = MAT_MOMENT_DATE_FORMATS;
var FormInputViewerComponent = /** @class */ (function () {
    function FormInputViewerComponent(inputService, resolver, cd) {
        this.inputService = inputService;
        this.resolver = resolver;
        this.cd = cd;
        this.readOnly = false; // TODO: enable dinamic change
        this.customEvent = new EventEmitter();
        this.containerDisplayStyle = 'initial';
        this.dragging = false;
        this.hidden = false;
        this.inputTypes = InputTypes;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(FormInputViewerComponent.prototype, "readonlyClass", {
        get: function () {
            return this.inputService.inputConfig && this.inputService.inputConfig.readOnly;
        },
        enumerable: true,
        configurable: true
    });
    FormInputViewerComponent.prototype.ngOnInit = function () {
    };
    FormInputViewerComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.formInputDefinition) {
            if (this.formInputDefinition.computedDefinition) {
                this.computedFieldDefinition = this.formInputDefinition.computedDefinition(this.formGroup.getRawValue(), this.dataContainerService);
            }
            else {
                this.computedFieldDefinition = this.formInputDefinition;
            }
        }
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
            this.valueChangesSubscription = null;
        }
        if (changes.formGroup && this.formInputDefinition.computedDefinition) {
            this.valueChangesSubscription = this.formGroup.valueChanges
                .subscribe(function () {
                _this.computedFieldDefinition = _this.formInputDefinition.computedDefinition(_this.formGroup.getRawValue(), _this.dataContainerService);
                _this.inputService.init(_this);
            });
        }
        setTimeout(function () {
            _this.inputService.init(_this);
            _this.cd.detectChanges();
        }, 0);
        if (this.inputService.formInputDefinition && !this.inputService.formInputDefinition.disableHideWithDisplayNone) {
            if (this.showChangedSubscription)
                this.showChangedSubscription.unsubscribe();
            this.showChangedSubscription = this.inputService.showChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function () {
                if (_this.inputService.show)
                    _this.containerDisplayStyle = 'initial';
                else
                    _this.containerDisplayStyle = 'none';
            });
        }
    };
    /**
     * For future use, dinamically load the correct component withour multiple ngIf
     * Check if update works
     * @returns
     */
    /*loadComponent(){
        if(!this.targetContainer) return;
        this.targetContainer.clear();

        if(!inputComponents[this.inputService.inputConfig.type]) return;

        const childComponent = this.resolver.resolveComponentFactory(inputComponents[this.inputService.inputConfig.type]);
        const componentRef = this.targetContainer.createComponent(childComponent);
    }*/
    FormInputViewerComponent.prototype.buildFieldTitle = function () {
        if (this.formInputDefinition.rawName) {
            this.computedTitle = this.formInputDefinition.rawName;
            return;
        }
        this.computedTitle = new TitleCasePipe().transform(this.formInputDefinition.name);
    };
    FormInputViewerComponent.prototype.checkIfShow = function () {
        var control = this.formGroup.get(this.formControlKey);
        if (!control)
            return;
        var show = this.formInputDefinition.showIf(this.formGroup.getRawValue(), this.dataContainerService);
        this.hidden = !show;
    };
    FormInputViewerComponent.prototype.codeToInputType = function (code) {
        if (code === 'option_value')
            return InputTypes.SELECT;
        if (code === 'multiple_option_value')
            return InputTypes.MULTI_SELECT;
        if (code === 'select')
            return InputTypes.SELECT;
        if (code === 'boolean')
            return InputTypes.CHECKBOX;
        if (code === 'string[]')
            return InputTypes.TEXT_LIST;
        return InputTypes.TEXT;
    };
    FormInputViewerComponent.prototype.preparePropertyDefinition = function () {
        if (!this.formInputDefinition || !this.dataContainerService || !this.formInputDefinition.inputType)
            return;
        if (this.formInputDefinition.valueType !== ValueTypes.PROPERTY)
            return;
        this.propertyDefinition = FieldHelper.getPropertyDefinition(this.dataContainerService, this.formInputDefinition.key, this.dataContainerService.getValueFromKeyPath('datasetCode'));
        if (!this.propertyDefinition) {
            console.warn('no property definition for ', this.formInputDefinition, this.dataContainerService.getValueFromKeyPath('datasetCode'));
        }
        if (this.propertyDefinition && this.formInputDefinition.inputType === InputTypes.PROPERTY) {
            this.inputService.inputConfig.type = this.codeToInputType(this.propertyDefinition.value_type);
        }
    };
    FormInputViewerComponent.prototype.getInputTypeToHTML = function (type) {
        if (type === InputTypes.TEXT)
            return 'text';
        if (type === InputTypes.NUMBER)
            return 'number';
        if (type === InputTypes.EMAIL)
            return 'email';
        if (type === InputTypes.PASSWORD)
            return 'password';
        if (type === InputTypes.FILE)
            return 'file';
        return 'text'; // by default
    };
    FormInputViewerComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.inputService.ngOnDestroy();
    };
    FormInputViewerComponent.prototype.onTimeChanged = function (event) {
        var value = this.formGroup.get(this.formControlKey).value;
        var timeValue = event.target.value;
        if (!value)
            return;
        if (typeof (timeValue) === 'string' && timeValue.search(':') !== -1) {
            var timeParts = timeValue.split(':');
            var hours = Number(timeParts[0]);
            var minutes = Number(timeParts[1]);
            if (value instanceof Date) {
                value.setUTCHours(hours);
                value.setUTCMinutes(minutes);
            }
            else if (typeof (value.hours) === 'function') {
                value.hours(hours);
                value.minutes(minutes);
            }
        }
    };
    FormInputViewerComponent.prototype.getTimeValue = function () {
        var control = this.formGroup.get(this.formControlKey);
        if (!control)
            return '';
        var value = control.value;
        if (value) {
            var date = new Date(value);
            return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
        }
        return '';
    };
    FormInputViewerComponent.prototype.getErrorMessage = function () {
        var message = 'Non valido';
        var control = this.formGroup.get(this.formControlKey);
        if (!control)
            return '';
        if (control.hasError('invalid'))
            message = control.getError('invalid');
        return message;
    };
    return FormInputViewerComponent;
}());
export { FormInputViewerComponent };
export { ɵ0, ɵ1 };
