import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { BookingService } from '../booking.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { TripBookingPackagesStepService } from './trip-booking-packages-step.service';
import { RELOAD_BOOKING_PACKAGES } from '../edit-booking.component';
import { BaseStepChangerComponent } from '../base-step-changer.component';
import * as _ from 'lodash';

@Component({
	selector   : 'trip-booking-packages-step',
	templateUrl: './trip-booking-packages-step.component.html',
	styleUrls: ['./trip-booking-packages-step.component.scss'],
	providers: [TripBookingPackagesStepService]
})
export class TripBookingPackagesStepComponent extends BaseStepChangerComponent implements OnInit, OnDestroy{

	public pointerEvent: PointerEvent;

	public ticketingEnabled = false;
	public canAddTicket = false;
	public canAddProduct = false;
	public itemsCollapsed = false;
	public rootTripBookingPackagesCount = 0;

	get tripBookingPackages(): any[]{ return this.tripBookingPackagesStepService.tripBookingPackagesPage && this.tripBookingPackagesStepService.tripBookingPackagesPage.items || []; }
	get loading(): boolean{ return this.tripBookingPackagesStepService.loading; }
	
	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		public bookingService: BookingService,
		public datasetNavigator: DatasetNavigatorService
	){
		super(bookingService);
	}

	ngOnInit(): void{
		super.ngOnInit();

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.tripBookingPackagesStepService.reloadTripBookingPackages();
			this.tripBookingPackagesStepService.updateSettings();
		});

		this.datasetACS.datasetDataChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.tripBookingPackagesStepService.updateSettings();
		});

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.tripBookingPackagesStepService.updateSettings();
		});

		this.tripBookingPackagesStepService.pageItemsUpdated
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.updateCount();
		})

		this.appService.appEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			if(event.name === RELOAD_BOOKING_PACKAGES){
				this.tripBookingPackagesStepService.reloadTripBookingPackages();
			}
		});

		this.updateCount();

		const pageLayout = document.querySelector('.booking-page-content');
		if(window.innerWidth < 959) pageLayout.classList.remove('p-16');
	}

	updateCount(){
		const items = _.get(this.tripBookingPackagesStepService, 'tripBookingPackagesPage.items');
		if(!items || !Array.isArray(items)) return 0;
		this.rootTripBookingPackagesCount = items.reduce((carry, item) => {
			if(item.parent_trip_booking_package_relation_type) return carry;
			return carry + 1;
		}, 0);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		const pageLayout = document.querySelector('.booking-page-content');
		if(pageLayout)pageLayout.classList.add('p-16');
	}

	trackTripBookingPackagesFun(item: any): any{
		return item.id;
	}
}
