import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector     : 'process-file-utility',
	templateUrl  : './process-file.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ProcessFileUtilityComponent implements OnInit{

	protected _unsuscribeAll: Subject<any>;
	public domains: Subject<any>;

	public loading = false;
	public selectedFile: any;
	public processName: string;
	public selectedDomain: any;

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected _datasetService: DatasetService,
		protected http: HttpClient,
	)
	{
		this._unsuscribeAll = new Subject<any>();
		this.domains = new Subject<any>();
	}

	ngOnInit(): void {
		this._datasetService.get<any>('/dataset/domains', {
			perPage: 200
		})
		.pipe(takeUntil(this._unsuscribeAll))
		.subscribe({
			next: response => {
				this.domains.next(response.items);
				this.domains.complete();
			},
			error: response => {
				this.domains.next();
				this.domains.complete();
			}
		});
	}

	loadProcess(): void{
		const data = new FormData();
		data.set('file', this.selectedFile);
		data.set('process_name', this.processName);
		data.set('domain_id', this.selectedDomain);

		this.loading = true;
		this.http.post(this.appService.getBaseServerAddress() + '/dataset/admin_commands/command/process_big_file', data)
		.subscribe({
			next: response => {
				this.appService.showSuccessMessage('Process caricato con successo.');
				this.loading = false;
			},
			error: response => {
				this.appService.showErrorMessage('Azione fallita.');
				this.loading = false;
			}
		});
	}
	
	onFileChange(event): void{
		if(event.target.files.length <= 0) {
			this.selectedFile = null;
			return;
		}
		this.selectedFile = event.target.files[0];
	}
	
}