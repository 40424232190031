import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';

@Component({
	selector     : 'seeding-utility',
	templateUrl  : './seeding.component.html',
	encapsulation: ViewEncapsulation.None
})
export class SeedingUtilityComponent{

	public loading = false;
	public selectedSeeder: string;

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected http: HttpClient,
	){}

	startSeeding(): void{
		if(!this.selectedSeeder){
			this.appService.showErrorMessage('Seleziona un seeder');
			return;
		}
		const data = {
			seeder: this.selectedSeeder
		};
		this.loading = true;
		this.http.post(this.appService.getBaseServerAddress() + '/dataset/admin_commands/command/run_seed', data)
		.subscribe({
			next: response => {
				this.appService.showSuccessMessage('Seeder eseguito con successo.');
				this.loading = false;
				this.selectedSeeder = null;
			},
			error: response => {
				this.appService.showErrorMessage('Azione fallita.');
				this.loading = false;
			}
		});
	}
}
