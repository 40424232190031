import {IDatasetConfig, IDatasetCallDefinition, DatasetActionOpenType, ListViewItemTypes, ListViewExportTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import baseInfoFormConfig from './forms/base.form';
import listFilterForm from './forms/filter.form';
import { LIST_COLUMNS } from './list_columns';

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

export const TurnoverReportConfig: IDatasetConfig = {
	name: 'turnover_reports',
	title: 'Report Fatturati',
	singleTitle: 'Report Fatturato',
	ajaxDatasetCode: 'turnover_reports',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		baseInfoFormConfig
	},
	defaultActions: {
		list: {
			beforeCalls: [ callModule],
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				showExportBtn: true,
				exportBtnActions: [
					{
						typeFile: ListViewExportTypes.XLS,
						buttonText: 'Esporta xls'
					},
					{
						typeFile: ListViewExportTypes.CSV,
						buttonText: 'Esporta csv'
					}
				],
				defaultSortBy: 'turnover',
				defaultSortDirection: 'desc',
				filterForm: listFilterForm,
				cacheDatasetListFilters: true,
				columns: LIST_COLUMNS,
				actions: null,
			},
		}

	}
};
