import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields/pratica-fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('74_ter'),
	fieldsMap.get('cee'),
	fieldsMap.get('serie'),
	fieldsMap.get('filiale'),
	fieldsMap.get('commessa'),
	fieldsMap.get('return_date'),
	fieldsMap.get('scenario'),
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
