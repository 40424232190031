/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../@fuse/components/progress-bar/progress-bar.component.ngfactory";
import * as i3 from "../@fuse/components/progress-bar/progress-bar.component";
import * as i4 from "../@fuse/components/progress-bar/progress-bar.service";
import * as i5 from "./layout/layout.component.ngfactory";
import * as i6 from "./layout/layout.component";
import * as i7 from "../@fuse/services/config.service";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "./services/app.service";
import * as i10 from "./app.component";
import * as i11 from "@angular/common";
import * as i12 from "../@fuse/components/navigation/navigation.service";
import * as i13 from "../@fuse/components/sidebar/sidebar.service";
import * as i14 from "../@fuse/services/splash-screen.service";
import * as i15 from "../@fuse/services/translation-loader.service";
import * as i16 from "@ngx-translate/core";
import * as i17 from "@angular/router";
import * as i18 from "./services/auth.service";
import * as i19 from "./services/websocket.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-progress-bar", [], null, null, null, i2.View_FuseProgressBarComponent_0, i2.RenderType_FuseProgressBarComponent)), i1.ɵdid(1, 245760, null, 0, i3.FuseProgressBarComponent, [i4.FuseProgressBarService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-layout", [], null, [["window", "beforeinstallprompt"], ["window", "appinstalled"]], function (_v, en, $event) { var ad = true; if (("window:beforeinstallprompt" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onbeforeinstallprompt($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:appinstalled" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onAppInstalled($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_LayoutComponent_0, i5.RenderType_LayoutComponent)), i1.ɵdid(3, 4440064, null, 0, i6.LayoutComponent, [i7.FuseConfigService, i8.Platform, i9.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i10.AppComponent, [i11.DOCUMENT, i7.FuseConfigService, i12.FuseNavigationService, i13.FuseSidebarService, i14.FuseSplashScreenService, i15.FuseTranslationLoaderService, i16.TranslateService, i8.Platform, i17.Router, i9.AppService, i18.AuthService, i19.WebSocketService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
