import { animate, AnimationBuilder, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { AuthService, CheckUserStatues } from 'app/services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/animations";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../app/services/auth.service";
var FuseSplashScreenService = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    function FuseSplashScreenService(_animationBuilder, _document, _router, _authService) {
        this._animationBuilder = _animationBuilder;
        this._document = _document;
        this._router = _router;
        this._authService = _authService;
        // Initialize
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    FuseSplashScreenService.prototype._init = function () {
        var _this = this;
        // Get the splash screen element
        this.splashScreenEl = this._document.body.querySelector('#fuse-splash-screen');
        // If the splash screen element exists...
        if (this.splashScreenEl) {
            this.checkUserStatusSubscription = this._authService.chekcUserStatus.subscribe(function (status) {
                if (status !== CheckUserStatues.CHECKED)
                    return;
                _this.checkUserStatusSubscription.unsubscribe();
                // Hide it on the first NavigationEnd event
                _this._router.events
                    .pipe(filter((function (event) { return event instanceof NavigationEnd; })), take(1))
                    .subscribe(function () {
                    setTimeout(function () {
                        _this.hide();
                    });
                });
            });
        }
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Show the splash screen
     */
    FuseSplashScreenService.prototype.show = function () {
        var _this = this;
        this.player =
            this._animationBuilder
                .build([
                style({
                    opacity: '0',
                    zIndex: '99999'
                }),
                animate('400ms ease', style({ opacity: '1' }))
            ]).create(this.splashScreenEl);
        setTimeout(function () {
            _this.player.play();
        }, 0);
    };
    /**
     * Hide the splash screen
     */
    FuseSplashScreenService.prototype.hide = function () {
        var _this = this;
        this.player =
            this._animationBuilder
                .build([
                style({ opacity: '1' }),
                animate('400ms ease', style({
                    opacity: '0',
                    zIndex: '-10'
                }))
            ]).create(this.splashScreenEl);
        setTimeout(function () {
            _this.player.play();
        }, 0);
    };
    FuseSplashScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FuseSplashScreenService_Factory() { return new FuseSplashScreenService(i0.ɵɵinject(i1.AnimationBuilder), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.AuthService)); }, token: FuseSplashScreenService, providedIn: "root" });
    return FuseSplashScreenService;
}());
export { FuseSplashScreenService };
