import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('target'),
	fieldsMap.get('description'),
	// fieldsMap.get('default_time'),
	{
		title: 'Posizione',
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('address.street'),
			fieldsMap.get('address.number'),
		]
	},
	fieldsMap.get('address.locality'),
	fieldsMap.get('address.city'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('address.province'),
			fieldsMap.get('address.region'),
		]
	},
	fieldsMap.get('address.nation_code'),
	fieldsMap.get('address.note'),
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
