import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('street'),
			fieldsMap.get('number'),
		]
	},
	fieldsMap.get('locality'),
	fieldsMap.get('city'),
	{
		fields: [
			fieldsMap.get('province'),
			fieldsMap.get('region'),
		]
	},
	fieldsMap.get('nation_code'),
	fieldsMap.get('note'),
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
