import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	FieldViews,
} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap } from '../unified_addresses/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.',
});

fieldsMap.set('category_service', {
	key: 'category_service',
	name: 'Categoria Servizi',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Corse in taxi scontate',
			value: 'discounted_taxi'
		},
		{
			label: 'Audioguida di Milano',
			value: 'milan_audio_guide'
		},
		{
			label: 'Trasporti pubblici',
			value: 'public_transport'
		},
		{
			label: 'Musei, visite e mostre',
			value: 'museums_visits_and_exhibitions'
		},
		{
			label: 'Attività ed esperienze',
			value: 'activities_and_experiences'
		},
		{
			label: 'Duomo di Milano',
			value: 'milan_cathedral'
		},
		{
			label: 'Tour Hop on Hop off',
			value: 'tour_hop_on_hop_off'
		},
		{
			label: 'Ristoranti',
			value: 'restaurants'
		},
		{
			label: 'Navette aeroportuali',
			value: 'airport_shuttles'
		},
		{
			label: 'Shopping',
			value: 'shopping'
		},
	],
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const ticketing = dataContainerService.getValueFromKeyPath('domainModules').ticketing;
		if(ticketing && ticketing.enabled){
			return ticketing.enabled;
		}
		return false;
	},
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	formValidators: [Validators.required],
	inputHint: 'Nome della destinazione',
});

fieldsMap.set('active_language_codes', {
	key: 'active_language_codes',
	name: 'Traduzioni attive',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string {
		let html =
			'<div style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

		if (
			record.active_language_codes &&
			record.active_language_codes.length > 0
		) {
			const style = 'style="margin-right: 5px;"';
			for (const lang of record.active_language_codes) {
				html +=
					'<img width="24" ' +
					style +
					' class="flag-icon" src="assets/images/flags/4x3/' +
					lang +
					'.svg" alt="' +
					lang +
					'" title="' +
					lang +
					'" />';
			}
		} else {
			html += '<span>---</span>';
		}

		html += '<div>';
		return html;
	},
});

addressFieldMap.forEach(function(value, key, map): void {
	value = cloneFieldDefinition(value, { key: 'address.' + value.key });

	fieldsMap.set('address.' + key, value);
});

fieldsMap.set('publish_on_web', {
	key: 'publish_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, la destinazione sarà visibile anche sul sito',
	options: [
		{
			label: 'NON pubblico',
			value: 0
		},
		{
			label: 'Pubblico',
			value: 1
		}
	]
});

export { fieldsMap };
