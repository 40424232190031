import * as tslib_1 from "tslib";
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import * as moment from 'moment';
export default function action(data, datasetACS, datasetNavigatorService) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var voucherSendInfo, loadingDialogRef, destinationEmail, lastSentAt, lastSentDate, summaryText, confirmResult, loadingDialogRef2;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingDialogRef = datasetNavigatorService.openDialog(LoadingDialogComponent, {
                        title: 'Recupero dati in corso',
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, datasetNavigatorService.datasetService
                            .post('/dataset/trip_booking_report_views/command/get_voucher_send_info', data).toPromise()];
                case 2:
                    voucherSendInfo = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    loadingDialogRef.close();
                    return [7 /*endfinally*/];
                case 4:
                    destinationEmail = null;
                    lastSentAt = null;
                    if (voucherSendInfo) {
                        if (voucherSendInfo.entity && voucherSendInfo.entity.primary_email) {
                            destinationEmail = voucherSendInfo.entity.primary_email;
                        }
                        if (voucherSendInfo.last_email_sent && voucherSendInfo.last_email_sent.created_at) {
                            lastSentDate = moment(voucherSendInfo.last_email_sent.created_at);
                            if (lastSentDate.isValid())
                                lastSentAt = lastSentDate.format('DD/MM/YYYY hh:mm:ss');
                        }
                    }
                    if (!destinationEmail) {
                        datasetNavigatorService.openDialog(TextDialogComponent, {
                            title: 'Attenzione',
                            contentMessage: 'L\'intestatario non ha una email.'
                        });
                        // no valid email
                        return [2 /*return*/, 'no email'];
                    }
                    summaryText = '<p>Vuoi inviare il voucher via email a: ' + destinationEmail + ' ?</p>';
                    if (lastSentAt) {
                        summaryText += '<p>Ultimo invio il: ' + lastSentAt + '</p>';
                    }
                    return [4 /*yield*/, datasetNavigatorService.openDialog(ConfirmDialogComponent, {
                            title: 'Invio Voucher',
                            contentMessage: summaryText,
                            positiveText: 'Si',
                            negativeText: 'Annulla'
                        }).afterClosed().toPromise()];
                case 5:
                    confirmResult = _a.sent();
                    if (!confirmResult)
                        return [2 /*return*/];
                    loadingDialogRef2 = datasetNavigatorService.openDialog(LoadingDialogComponent, {
                        title: 'Invio in corso',
                    });
                    _a.label = 6;
                case 6:
                    _a.trys.push([6, , 8, 9]);
                    return [4 /*yield*/, datasetNavigatorService.datasetService
                            .post('/dataset/trip_booking_report_views/command/send_voucher', data).toPromise()];
                case 7:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 8:
                    loadingDialogRef2.close();
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    });
}
