import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, getDatasetConfigAction} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import {BaseDatasetActionDialog, IDialogData} from './../../base-action-dialog.component';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { IEditDialogOptions } from '../../../services/dataset-navigator.service';
import { DatasetEditDialogComponent } from '../edit/dataset-edit-dialog.component';

export interface IDatasetDetailDialogData extends IDialogData{
	recordId: string;
	parentRecordId?: string;
}

@Component({
	selector: 'dataset-detail-dialog',
	templateUrl: 'dataset-detail-dialog.component.html',
	styleUrls: ['dataset-detail-dialog.component.scss'],
	providers: [DatasetActionContainerService, PageClosureContainerService]
})
export class DatasetDetailDialogComponent extends BaseDatasetActionDialog{

	actionCode = 'detail';

	constructor(
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<DatasetDetailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDatasetDetailDialogData
	) {
		super(datasetACS, appService, datasetService, dialogRef, data);
	}

	getParamMap(): Map<string, any>{
		const map = new Map();
		map.set('recordId', this.data.recordId);

		if(this.data.parentDatasetACS && this.data.parentDatasetACS.datasetConfig && this.data.parentDatasetACS.datasetConfig.pivot){
			const parentDatasetCode = this.data.parentDatasetACS.datasetConfig.pivot.parentDatasetCode;
			const parentRecordId = this.data.parentDatasetACS.getValueFromKeyPath(parentDatasetCode + '.recordId');
			map.set('parentRecordId', parentRecordId);
		}

		return map;
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	openEditDialog(callerService: DatasetActionContainerService, record: any, dialogOptions?: IEditDialogOptions): MatDialogRef<DatasetEditDialogComponent, any>{
		const dialogRef = this.dialog.open(DatasetEditDialogComponent, {
			minWidth: 450,
			panelClass: 'edit-dialog-container',
			data: Object.assign({}, {
				datasetCode: callerService.datasetCode,
				record,
				datasetData: callerService.datasetData,
				parentDatasetACS: callerService
			}, dialogOptions)
		});
		dialogRef.afterClosed()
		.subscribe(result => {
			if (result){
				callerService.datasetEvent.next({
					eventName: DatasetEvents.EDITED,
					data: {
						id: record.id
					}
				});
				if(this.data.parentDatasetACS){
					this.data.parentDatasetACS.datasetEvent.next({
						eventName: DatasetEvents.EDITED,
						data: {
							id: record.id
						}
					});
				}
			}
		});
		return dialogRef;
	}

	onEditClick(): void{
		this.openEditDialog(this.datasetACS, this.datasetACS.datasetData.record);
	}
}
