import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { GenerateActualTripDialogComponent } from 'app/main/components/dataset/dialogs/generate-actual-trip-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import * as moment from 'moment';
var TripPackageStep4Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageStep4Component, _super);
    function TripPackageStep4Component(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, dialog) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.dialog = dialog;
        _this.todayFilter = JSON.stringify(['>=', moment.utc().startOf('day')]);
        return _this;
    }
    TripPackageStep4Component.prototype.ngOnInit = function () {
    };
    TripPackageStep4Component.prototype.generate = function () {
        var _this = this;
        this.dialog.open(GenerateActualTripDialogComponent, {
            data: {
                trip_package: this.datasetRS.record.value,
                datasetACS: this.datasetACS,
                postData: {
                    trip_package_id: this.datasetRS.recordId
                }
            }
        }).afterClosed().subscribe(function (result) {
            if (result) {
                _this.tripList.reload();
            }
        });
    };
    return TripPackageStep4Component;
}(BaseStepViewComponent));
export { TripPackageStep4Component };
