import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as destinationFieldMap} from '../trip_destinations/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripDestinationIdField from 'app/configs/commons/shared-field-configs/trip-destination-id.field';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_destination_id', cloneFieldDefinition(tripDestinationIdField));

destinationFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	fieldsMap.set('destination.' + key, value);
});

export {fieldsMap};
