import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { InConstructionComponent } from './in-construction.component';


@NgModule({
    declarations: [
        InConstructionComponent
    ],
    imports     : [
        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        InConstructionComponent
    ]
})

export class InConstructionModule{}
