import { Component, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFieldDefinition, IDatasetConfig, IFormConfig, IAppEvent } from 'app/interfaces';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { CdkStepper } from '@angular/cdk/stepper';
import { DatasetCreateDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/create/dataset-create-dialog.component';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';

@Component({
	selector   : 'custom-mat-table',
	templateUrl: './mat-table.component.html',
	styleUrls  : ['./mat-table.component.scss'],
	providers: [DatasetNavigatorService]
})
export class CustomMatTableComponent extends DestroyableComponent implements OnInit, OnDestroy{

	@ViewChild('mytable', { static: false }) table: MatTable<any>;

	@Input() relation: string;
	@Input() displayedColumns: any[];
	@Input() filterFormGroup: FormGroup;
	@Input() field: IFieldDefinition;
	@Input() datasetACS: DatasetActionContainerService;
	@Input() datasetRS: DatasetRecordService;
	@Input() dynamCols: any[];
	@Input() stepName: string;
	@Input() datasetConfig: IDatasetConfig;
	@Input() formConfigMap: IFormConfig;
	@Input() datasetCode: string;
	@Input() stepper: CdkStepper;
	@Input() selectedIndex: number;
	@Input() initialValueKey: any;
	@Output() nextStep = new EventEmitter<void>();

	dataSource: MatTableDataSource<any>;
	rowsAdded: any[] = [];
	rowsRemoved: any[] = [];
	resellView: boolean;

	get primaryKey(): any{
		const pk = this.dynamCols.filter(x => x.primaryKey === true);
		return pk[0];
	}

	constructor(
		public appService: AppService,
		public datasetService: DatasetService,
		public datasetNavigatorService: DatasetNavigatorService,
		public stepService: StepService){
			super();
		}

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource();
		this.datasetACS.loading.next(true);

		this.load()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (result) => {
				this.renderTable(result);
				this.setInternalStepState();
				this.datasetACS.loading.next(false);
			},
			error: () => {
				this.datasetACS.loading.next(false);
			}
		});

		this.detectFormChanges();

		this.appService.eventEmitter
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((event: IAppEvent) => {
			if (event.name === 'clickOnSupportCreation_' + this.field.key){
				this.createNewDatasetRow(event.extra.inputValue);
			}
		});

		this.resellView = !isRecordInFilteredDomain(this.datasetRS.record.value, this.datasetRS.record.value.is_owner);
	}

	detectFormChanges(): void{
		this.filterFormGroup.controls[this.field.key].valueChanges.subscribe({
			next: (item) => {
				if (item && item.id){
					const newItem = this.getItem(item);
					this.addRow(newItem);
				}
			},
			error: (result) => {
				console.log(result); // stampo errore
			}
		});
	}

	renderTable(result: any): void{
		for (const item of result.items) {
			const newItem = this.getItem(item);
			this.dataSource.data.push(newItem);
		}
		this.table.renderRows();
	}

	setInternalStepState(): void{
		const stepData = this.stepService.stepsData[this.stepName];
		if (stepData){
			if (this.dataSource.data.length > 0 && !stepData.status) stepData.status = 1;
		}
	}

	createNewDatasetRow(value?: string): void{
		const initialVal = {};
		initialVal[this.initialValueKey] = value;
		const dialog = this.datasetNavigatorService.openCreateDialog(this.datasetACS, {
			formConfig: this.formConfigMap,
			customActionCode: this.datasetConfig.ajaxDatasetCode,
			initialValues: initialVal
		});
		dialog.afterClosed()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (item) => {
				if (item && item.id){
					const newItem = this.getItem(item);
					this.addRow(newItem);
				}
			},
			error: (response) => {
				console.error(response);
			}
		});
	}

	protected load(): Observable<any>{
		const params = {};
		let headers = new HttpHeaders({});
		if(this.datasetACS.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
		}
		return this.datasetService.get<any>(this.getListRoute(), params, headers);
	}

	addRow(record: any): void {
		if (!this.dataSource.data.find(x => x[this.primaryKey.name] === record[this.primaryKey.name])) {
			this.dataSource.data.push(record);
			this.rowsAdded.push(record);
			const stepData = this.stepService.stepsData[this.stepName];
			if (stepData){
				if (this.dataSource.data.length > 0 && !stepData.status) stepData.status = 1;
			}
		}
		this.table.renderRows();
	}

	remove(record: any): void {
		this.dataSource.data = this.dataSource.data.filter((value) => {
			return value[this.primaryKey.name] !== record[this.primaryKey.name];
		});
		if (this.stepService.stepsData[this.stepName]){
			if (this.dataSource.data.length === 0) this.stepService.stepsData[this.stepName].status = 0;
		}
		this.rowsRemoved.push(record);
		this.table.renderRows();
	}

	getListRoute(): string{
		const recordId = this.datasetRS.recordId;
		return '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
	}

	getCreateRoute(): string{
		const recordId = this.datasetRS.recordId;
		return '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
	}

	getItem(item: any): any{
		const newItem: any = {};
		newItem['id'] = item.id;
		for(const col of this.displayedColumns){
			if (col === 'action') continue;
			newItem[col] = item[col];
		}
		return newItem;
	}

	onSave(): void{
		this.datasetACS.loading.next(true);
		const data = {
			relation: this.relation,
			data: this.dataSource.data.map(x => x.id).join(),
			recordId: this.datasetRS.recordId,
		};
		this.datasetService.post(this.getCreateRoute() + '/command/' + this.datasetConfig.pivot.commandSync, data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (res) => {
				this.datasetACS.loading.next(false);
				setTimeout(() => {
					this.stepService.setStepState({
						dataset_code: this.datasetCode,
						form_code: this.stepName,
						record_id: this.datasetACS.datasetRS.recordId,
						status: 1
					});
					if (this.stepper){
						this.stepper.selectedIndex = this.selectedIndex;
					} else this.nextStep.emit();
				}, 200);
			},
			error: response => {
				this.datasetACS.loading.next(false);
			}
		});
	}
}
