import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import filterForm from './forms/filter.form';
import stepViewConfig from './stepViewConfig';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/entities/detail/:recordId',
	params: {
		with_attributes: 'entities_data,phone_number,staff_type_property_data,staff_user_domain',
		with_relations: 'primaryAddress,customerData,supplierData,staffElementData,tourOperatorData'
	}
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	method: 'get',
	params: {
		key: 'modules,twilio,touroperators'
	}
};

export const EntityConfig: IDatasetConfig = {
	name: 'entities',
	title: 'Anagrafiche',
	singleTitle: 'Anagrafica',
	ajaxDatasetCode: 'entities',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	fetchConfig: {
		with_attributes: 'entities_data,phone_number,staff_type_property_data,staff_user_domain',
		with_relations: 'primaryAddress,customerData,supplierData,staffElementData,tourOperatorData'
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ callModule],
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(container, parent, params): any{
				params.with_attributes = 'entities_data,staff_type_property_data,phone_number,staff_user_domain';
				params.with_relations = 'primaryAddress,customerData,supplierData,staffElementData,tourOperatorData';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'description',
				defaultSortDirection: 'asc',
				filterForm: filterForm,
				columns: [
					{
						title: 'Codice',
						key: 'table_view_code',
						routerLink(record: any): string{
							return datasetDetailPage('entities', record.id);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: '',
						key: 'other',
						fieldDefinition: {
							key: 'other',
							name: '',
							valueType: ValueTypes.STRING,
							fieldView: FieldViews.HTML,
							getDisplayValue(record: any, refRecord: any): string{
								let result = '';
								if(record.staff_type_property_data && record.staff_type_property_data.label){
									result += '<p>Tipo Staff: ' + record.staff_type_property_data.label + '</p>';
									if (record.staff_user_domain){
										result += '<p><b>Anagrafica abilitata all\'accesso su Tangerine</b>.</p>';
									}
								}
								if(record.external_site_user_reference){
									result += '<p>Utente B2B: ' + record.external_site_user_reference + '</p>';
								}
								return result;
							}
						}
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	},
	stepViewConfig: stepViewConfig
};
