import * as tslib_1 from "tslib";
import { BasePortalOutlet, CdkPortalOutlet } from '@angular/cdk/portal';
import { ComponentRef, EmbeddedViewRef } from '@angular/core';
/**
 * Internal component that wraps user-provided popover content.
 */
var PopoverComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PopoverComponent, _super);
    function PopoverComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PopoverComponent.prototype.attachComponentPortal = function (componentPortal) {
        return this.portalOutlet.attachComponentPortal(componentPortal);
    };
    PopoverComponent.prototype.attachTemplatePortal = function (portal) {
        return this.portalOutlet.attachTemplatePortal(portal);
    };
    return PopoverComponent;
}(BasePortalOutlet));
export { PopoverComponent };
