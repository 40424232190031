<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<div class='text-list-input-container'>
		<label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</label>
		<div fxLayout='column' fxLayoutAlign="start stretch">
			<div *ngFor='let item of list; let i = index; trackBy: trackByFn'>
				<div fxLayout='row' fxLayoutAlign="space-between center">
					<div fxFlex fxLayout='column' fxLayoutAlign="start">
						<mat-form-field>
							<input [readonly]='inputService.inputConfig.readOnly' [placeholder]="'Titolo' | translate" matInput autocomplete="off" [name]='inputService.formControlKey + "text_input_item_" + i + "_0"' [(ngModel)]='list[i][0]' [ngModelOptions]="{standalone: true}"/>
						</mat-form-field>
						<mat-form-field>
							<mat-label>{{'Breve Descrizione' | translate}}</mat-label>
							<textarea [readonly]='inputService.inputConfig.readOnly' matInput autocomplete="off" [name]='inputService.formControlKey + "text_input_item_" + i + "_0"' [(ngModel)]='list[i][1]' [ngModelOptions]="{standalone: true}">
							</textarea>
						</mat-form-field>
					</div>
					<button *ngIf='!inputService.inputConfig.readOnly' mat-icon-button color='warn' (click)="removeItem(i)">
						<span class="material-icons">close</span>
					</button>
				</div>
			</div>
		</div>
		<button *ngIf='!inputService.inputConfig.readOnly' mat-button color='accent' (click)='addNewItem()'>{{'Aggiungi' | translate}}</button>
	</div>
</ng-container>