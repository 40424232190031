<div id="dashboard-analytics" class="page-layout blank grey-100-bg">
	<h1>Dashboard</h1>
	<div class='filters' fxLayout='row wrap' fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutGap="70px">
		<div fxLayout='column' class="departures">
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("departure_date_from")'
				[formGroup]='filterFormGroup'
				formControlKey='departure_date_from'
			></form-input-viewer>
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("departure_date_to")'
				[formGroup]='filterFormGroup'
				formControlKey='departure_date_to'
			></form-input-viewer>
		</div>

		<div fxLayout='column' class="bookings">
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("booking_date_from")'
				[formGroup]='filterFormGroup'
				formControlKey='booking_date_from'
			></form-input-viewer>
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("booking_date_to")'
				[formGroup]='filterFormGroup'
				formControlKey='booking_date_to'
			></form-input-viewer>
		</div>

		<!-- Resource -->
		<!--<mat-form-field class="filter-chip-list" >
				<mat-chip-list #chip aria-label="Resource selection">
					<mat-chip
						*ngFor="let tripResource of dashboardService.filterResource"
						[selectable]="true"
						[removable]="true"
						(removed)="removeResource(tripResource.id)">
						{{ tripResource.description }}
						<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
					</mat-chip>
					<input #ResourceInput
						placeholder="Filtra risorse..."
						(input)="resourceFilteredOptions($event.target.value)"
						[formControl]="resourceCtrl"
						[matAutocomplete]="autores"
						[matChipInputFor]="chip"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						[matChipInputAddOnBlur]="true"
						(matChipInputTokenEnd)="addResource($event)">
				</mat-chip-list>
				<mat-autocomplete
					#autores="matAutocomplete"
					(optionSelected)="onSelectResource($event)">
					<mat-option
						*ngFor="let option of resourceOptions | async"
						[value]="option">
						{{ option.description }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>-->
		<!--end Resource-->

		<div fxLayout='column' class="trip_package_categories">
			<!--Package-->
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("package_code")'
				[formGroup]='filterFormGroup'
				formControlKey='package_code'
			></form-input-viewer>
			<!--end Package-->
			<!-- Category -->
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("category_id")'
				[formGroup]='filterFormGroup'
				formControlKey='category_id'
			></form-input-viewer>
			<!--end Category-->
		</div>

		<div fxLayout='column' class="customers_branches">
			<!-- start Customer -->
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("customer_code")'
				[formGroup]='filterFormGroup'
				formControlKey='customer_code'
			></form-input-viewer>
			<!--end Customer-->
			<!-- start branch-->
			<form-input-viewer
				fxFlex
				[formInputDefinition]='fileterFields.get("branch_code")'
				[formGroup]='filterFormGroup'
				formControlKey='branch_code'
			></form-input-viewer>
			<!--end branch-->
		</div>
	</div>

	<div class="filters_mobile">
		<button mat-button color="accent" (click)='openFilters()'>
			<mat-icon>launch</mat-icon> 
			{{'Filtra' | translate}} 
			<span class="num_filters" *ngIf='numFilters'>({{numFilters}} {{numFilters > 1 ? 'Filtri Attivi' : 'Filtro Attivo'}})</span>
		</button>
	</div>

	<div class="grid-container" fxLayout="column" fxLayoutAlign="start">
		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('traveldate')">
				<!-- Widget 1-->
				<div class="fuse-card auto-width px-8" [ngClass.gt-sm]="'mb-0 mr-10'">
					<div class="title_chart">
						<h2>{{'Entrate per data di viaggio' | translate}}</h2>
						<div class="line"></div>
					</div>
					<traveldate></traveldate>
				</div>
				<!-- / widget 1 -->
			</div>

			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('checkoutdate')">
				<!-- Widget 2-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Entrate per data di checkout' | translate}}</h2>
						<h3>{{'Ricavi per mese di checkout' | translate}}</h3>
						<div class="line"></div>
					</div>
					<checkoutdate></checkoutdate>
				</div>
				<!-- / widget 2-->
			</div>
		</div>

		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start">
			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('ticket')">
				<!-- Widget 3-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Biglietti' | translate}}</h2>
						<h3>{{'Biglietti per mese di viaggio' | translate}}</h3>
						<div class="line"></div>
					</div>
					<ticket></ticket>
				</div>
				<!-- / widget 3-->
			</div>

			<div fxFlex="50" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start" >
				<!-- Widget 4-->
				<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('ticketsyear')">
					<div class="fuse-card auto-width mb-48">
						<div class="title_chart">
							<h2>{{'Biglietti' | translate}}</h2>
							<h3>{{'Biglietti per anno di viaggio' | translate}}</h3>
							<div class="line"></div>
						</div>
						<ticketsyear></ticketsyear>
					</div>
				</div>
				<!-- / widget 4-->
				<!-- Widget 5-->
				<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('revenue')">
					<div class="fuse-card auto-width mb-48">
						<div class="title_chart">
							<h2>{{'Reddito' | translate}}</h2>
							<h3>{{'Ricavi per anno di viaggio' | translate}}</h3>
							<div class="line"></div>
						</div>
						<revenue></revenue>
					</div>
				</div>
				<!-- / widget 5-->
			</div>
		</div>

		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start" >
			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('bookingwindow')">
				<!-- Widget 6-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Finestra di prenotazione' | translate}}</h2>
						<h3>{{'Giorni dalla prenotazione alla data di conduzione (inferiore a 1 significa prenotazione lo stesso giorno)' | translate}}</h3>
						<div class="line"></div>
					</div>
					<bookingwindow></bookingwindow>
				</div>
				<!-- / widget 6-->
			</div>

			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('sourcemarkets')">
				<!-- Widget 7-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Mercati di origine' | translate}}</h2>
						<h3>{{'Primi 10 mercati di origine per entrate (per data di checkout)' | translate}}</h3>
						<div class="line"></div>
					</div>
					<sourcemarkets></sourcemarkets>
				</div>
				<!-- / widget 7-->
			</div>
		</div>

		<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start">
			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('reviews')">
				<!-- Widget 8-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Recensioni' | translate}}</h2>
						<h3>{{'Valutazione media e di recensioni negative per mese di revisione' | translate}}</h3>
						<div class="line"></div>
					</div>
					<reviews></reviews>
				</div>
				<!-- / widget 8-->
			</div>

			<div class="grid-item" fxFlex="50" *ngIf="dashboardService.enabledStats.includes('reviewsstat')">
				<!-- Widget 9-->
				<div class="fuse-card auto-width px-8">
					<div class="title_chart">
						<h2>{{'Recensioni' | translate}}</h2>
						<h3>{{'Valutazione media e recensioni negative totali per anno di revisione' | translate}}</h3>
						<div class="line"></div>
					</div>
					<reviewsystat></reviewsystat>
				</div>
				<!-- / widget 9-->
			</div>
		</div>
	</div>
</div>