import { Routes, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { InConstructionComponent } from 'app/main/pages/in-construction/in-construction.component';
import { NotFoundComponent } from 'app/main/pages/not-found/not-found.component';
import { LoginComponent } from 'app/main/pages/authentication/login/login.component';
import { ForgotPasswordComponent } from 'app/main/pages/authentication/forgot-password/forgot-password.component';
import { CalendarComponent } from 'app/main/pages/calendar/calendar.component';
import { DocumentsPageComponent } from 'app/main/pages/documents/documents.component';
import { DefaultDatasetPageComponent } from 'app/main/pages/datasets/default-page/default-page.component';
import { DatasetResolver } from 'app/main/components/dataset/dataset.resolver';
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { LoggedGuardService as LoggedGuard } from 'app/auth/logged-guard.service';
import { ResetPasswordComponent } from 'app/main/pages/authentication/reset-password/reset-password.component';
import { EditBookingPageComponent } from 'app/main/pages/edit-booking/edit-booking.component';
import { MarketplaceComponent } from 'app/main/pages/marketplace/marketplace.component';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { DashboardPageComponent } from 'app/main/pages/dashboard/dashboard.component';
import { SuperadminGuardService } from 'app/auth/superadmin-guard.service';
import { UtilitiesPageComponent } from 'app/main/pages/superadmin/utilities/utilities.component';
import { LogsPageComponent } from 'app/main/pages/superadmin/logs/logs.component';
import { DomainResolver } from 'app/resolvers/domain.resolver';
import { TourCMSMarketplacePageComponent } from 'app/main/pages/tourcms-marketplace/marketplace.component';
import { BokunMarketplacePageComponent } from 'app/main/pages/bokun-marketplace/marketplace.component';
import { PassportComponent } from 'app/main/pages/superadmin/passport/passport.component';
import { BIStatisticsPageComponent } from 'app/main/pages/bi-statistics/bi-statistics.component';
import { DashboardTotemPageComponent } from 'app/main/pages/dashboard-totem/dashboard-totem.component';
import { TicketlandiaMarketplacePageComponent } from "../main/pages/ticketlandia-marketplace/marketplace.component";
// disable reuse, we not need
var CustomRouteReuseStrategy = /** @class */ (function () {
    function CustomRouteReuseStrategy() {
    }
    CustomRouteReuseStrategy.prototype.shouldDetach = function (route) { return false; };
    CustomRouteReuseStrategy.prototype.store = function (route, detachedTree) { };
    CustomRouteReuseStrategy.prototype.shouldAttach = function (route) { return false; };
    CustomRouteReuseStrategy.prototype.retrieve = function (route) { return null; };
    CustomRouteReuseStrategy.prototype.shouldReuseRoute = function (future, curr) {
        return curr.routeConfig === null && future.routeConfig === null;
    };
    return CustomRouteReuseStrategy;
}());
export { CustomRouteReuseStrategy };
var ɵ0 = { is_new: true }, ɵ1 = { is_new: false }, ɵ2 = { action: 'list' }, ɵ3 = { action: 'create' }, ɵ4 = { action: 'edit' }, ɵ5 = { action: 'detail' };
var routes = [
    {
        path: 'home',
        component: DashboardPageComponent,
        canActivate: [AuthGuard]
    },
    { path: 'documents', component: DocumentsPageComponent, canActivate: [AuthGuard] },
    { path: 'bi-statistics', component: BIStatisticsPageComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-totem', component: DashboardTotemPageComponent, canActivate: [AuthGuard] },
    { path: 'auth/login', component: LoginComponent, canActivate: [LoggedGuard] },
    { path: 'auth/logout', component: LoginComponent },
    { path: 'auth/forgot-password', component: ForgotPasswordComponent },
    { path: 'auth/reset-password', component: ResetPasswordComponent },
    { path: 'calendar', component: CalendarComponent, resolve: { calendarData: CalendarService }, canActivate: [AuthGuard] },
    { path: 'new-booking', component: EditBookingPageComponent, canActivate: [AuthGuard], resolve: { domainId: DomainResolver }, data: ɵ0 },
    { path: 'edit-booking/:id', component: EditBookingPageComponent, canActivate: [AuthGuard], data: ɵ1 },
    { path: 'marketplace', component: MarketplaceComponent, canActivate: [AuthGuard] },
    { path: 'tourcms_marketplace', component: TourCMSMarketplacePageComponent, canActivate: [AuthGuard], resolve: { domainId: DomainResolver } },
    { path: 'bokun_marketplace', component: BokunMarketplacePageComponent, canActivate: [AuthGuard], resolve: { domainId: DomainResolver } },
    { path: 'ticketlandia_marketplace', component: TicketlandiaMarketplacePageComponent, canActivate: [AuthGuard], resolve: { domainId: DomainResolver } },
    { path: 'dataset/list/:datasetCode', component: DefaultDatasetPageComponent, data: ɵ2, resolve: { datasetData: DatasetResolver }, canActivate: [AuthGuard] },
    { path: 'dataset/create/:datasetCode', component: DefaultDatasetPageComponent, data: ɵ3, resolve: { datasetData: DatasetResolver }, canActivate: [AuthGuard] },
    { path: 'dataset/edit/:datasetCode/:recordId', component: DefaultDatasetPageComponent, data: ɵ4, resolve: { datasetData: DatasetResolver }, canActivate: [AuthGuard] },
    {
        path: 'dataset/detail/:datasetCode/:recordId',
        component: DefaultDatasetPageComponent,
        data: ɵ5,
        resolve: { datasetData: DatasetResolver },
        canActivate: [AuthGuard]
    },
    { path: 'superadmin/logs', component: LogsPageComponent, canActivate: [AuthGuard, SuperadminGuardService] },
    { path: 'superadmin/utilities', component: UtilitiesPageComponent, canActivate: [AuthGuard, SuperadminGuardService] },
    { path: 'superadmin/passport', component: PassportComponent, canActivate: [AuthGuard, SuperadminGuardService] },
    { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'app', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'incostruction', component: InConstructionComponent },
    { path: 'notfound', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
