import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets, TickOptions, ChartSize  } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import chartColors from './../chart-colors';

@Component({
	selector   : 'ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class TicketComponent extends DestroyableComponent implements OnInit, AfterViewInit{

	public tickets: ChartDataSets[];
	public chartOptions: ChartOptions[];
	public showLoader: boolean;

	@ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;
	
	/**
	 * Chart Ticket
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		onResize(chart: Chart): void{
			chart.data.datasets.forEach((chartDataset: ChartDataSets) => {
				const gradient: CanvasGradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom/1.5, 0, chart.chartArea.bottom);
				gradient.addColorStop(0, chartDataset.hoverBackgroundColor.toString());
				gradient.addColorStop(1, 'white');
				chartDataset.backgroundColor = gradient;
			});
			/**
			 * when data or style changes update chart
			 */
			 chart.update();
		},
		scales: { 
			xAxes: [{}], 
			yAxes: [{}] 
		},
		legend: {
			display: true,
			position: 'bottom',
			fullWidth: true,
			labels: {
				usePointStyle: true,
				padding: 10,
			}
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		}
	};
	public chartPlugins = [makeDrawValuePlugin()];
	public barChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
	public barChartType: ChartType = 'bar';
	public barChartLegend = true;

	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.tickets = [{data: []}];
	}
	
	ngAfterViewInit(): void{
		this.dashboardService.checkoutDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'checkout_date') return;
				if(response) this.onData(response);
			}
		});
		this.dashboardService.travelDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				if(this.dashboardService.groupingBy.value !== 'departure_date') return;
				if(response) this.onData(response);
			}
		});
	}

	// -----------------------------------------------------------------------------------------------------
	public onData(response: any): void{
		const r = []; 

		for (const item of response) {
			if(!item.year) continue;
			const year = item.year;
			const month = item.month - 1;
			if (!r[year]) r[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			if (!r[year][month]) r[year][month] = 0;
			r[year][month] += Number(item.pax_count);
		}
		
		const data: ChartDataSets[] = [];
		const years = Object.keys(r);
		for (let y = 0; y < years.length; y++){
			const year = years[y];
			const colorIndex = years.length - y - 1;

			const chartArea = this.chartComponent.chart.chartArea;

			let gradient: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.5, 0, chartArea.bottom);
			gradient.addColorStop(0, chartColors[colorIndex]);
			gradient.addColorStop(1, 'white');
			
			data.push({ 
				data: r[year],
				label: String(year),
				fill: false,
				backgroundColor: gradient,
				borderColor: chartColors[colorIndex],
				hoverBackgroundColor: chartColors[colorIndex]
			});
		}
		if(!data || data.length === 0){
			this.showLoader = true;
			this.tickets = [{data: []}];
		} else {
			this.showLoader = false;
			this.tickets = data;
		}
	}
	
}
