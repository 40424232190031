import { NgModule } from "@angular/core";
import { TripBookingPackageComponent } from "./trip-booking-package.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatStepperModule, MatProgressBarModule, MatTabsModule, MatTableModule, MatListModule, MatToolbarModule, MatButtonToggleModule, MatCardModule, MatTooltipModule, MatBadgeModule, MatChipsModule, MatMenuModule, MatDatepickerModule, MatProgressSpinnerModule } from "@angular/material";
import { FuseSidebarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { DatasetModule } from "app/main/components/dataset/dataset.module";
import { FormViewerModule } from "app/main/components/form-viewer/form-viewer.module";
import { FormDialogsModule } from "app/main/dialogs/form-dialog/dialog.module";
import { PopoverModule } from "app/main/dialogs/popover/popover.module";
import { ActionDialogModule } from "../../action-dialog/action-dialog.module";
import { ProductBookingComponent } from "./product/product-booking.component";
import { TicketBookingComponent } from "./ticket/ticket-booking.component";
import { TripBookingParticipantComponent } from "./related-data/trip-booking-participants/trip-booking-participant.component";
import { TripBookingResourceComponent } from "./related-data/trip-booking-resource/trip-booking-resource.component";
import { TripBookingServiceComponent } from "./related-data/trip-booking-services/trip-booking-service.component";
import { TripPackageBookingComponent } from "./trip-package/trip-package-booking.component";
import { TripPackageResourceBookingComponent } from "./trip-package/trip-package-resource/trip-package-resource-booking.component";
import { TripBookingPackageHeaderComponent } from "./template-parts/header/header.component";
import { TripBookingPackageRelatedButtonsComponent } from "./template-parts/related-buttons/related-buttons.component";
import { TrenitaliaBookingComponent } from "./trenitalia/trenitalia-booking.component";
import { TripBookingPackageTrenitaliaPostSaleBookingComponent } from "./trenitalia/trip-booking-package-trenitalia-post-sale/trip-booking-package-trenitalia-post-sale.component";

@NgModule({
	declarations: [
		TripBookingPackageComponent,
		
		// trip booking packages related data components
		TripBookingParticipantComponent,
		TripBookingServiceComponent,
		TripBookingResourceComponent,
		// -----------------

		// card parts
		TripBookingPackageHeaderComponent,
		TripBookingPackageRelatedButtonsComponent,
		// ------------------

		// trip booking package types
		TripPackageBookingComponent,
		ProductBookingComponent,
		TrenitaliaBookingComponent,
		TripBookingPackageTrenitaliaPostSaleBookingComponent,
		TicketBookingComponent,
		TripPackageResourceBookingComponent,
		// ------------------
	],
	imports: [
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatStepperModule,
		MatProgressBarModule,
		FormViewerModule,
		FuseSharedModule,
		FuseSidebarModule,
		MatTabsModule,
		MatTableModule,
		MatListModule,
		DatasetModule,
		MatToolbarModule,
		MatButtonToggleModule,
		MatCardModule,
		MatTooltipModule,
		MatBadgeModule,
		MatChipsModule,
		MatMenuModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
		FormDialogsModule,
		OverlayModule,
		PopoverModule,
		ActionDialogModule,
		TranslateModule.forChild()
	],
	exports: [
		TripBookingPackageComponent
	],
	entryComponents: [
		TripBookingResourceComponent,
		TripBookingServiceComponent,
		TripBookingParticipantComponent
	]
})
export class TripBookingPackageModule{}
