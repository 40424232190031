import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter, OnDestroy, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FieldViews, DatasetActionOpenType } from 'app/interfaces';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import { FieldHelper } from 'app/helpers';
import { GetDisplayFieldValuePipe } from 'app/pipes/get-display-field-value.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { AuthService } from 'app/services/auth.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
var ListTableViewComponent = /** @class */ (function () {
    function ListTableViewComponent(viewContainerRef, datasetService, authService, dialog, appService, router, datasetACS, domainFilterService, datasetNavigatorService, getDisplayFieldValuePipe) {
        this.viewContainerRef = viewContainerRef;
        this.datasetService = datasetService;
        this.authService = authService;
        this.dialog = dialog;
        this.appService = appService;
        this.router = router;
        this.datasetACS = datasetACS;
        this.domainFilterService = domainFilterService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.getDisplayFieldValuePipe = getDisplayFieldValuePipe;
        this.displayedColumns = [];
        this.selectable = false;
        this.conditionalShowPaginationToolbar = true;
        this.elevate = true;
        this.enableCreate = true;
        this.enableEdit = true;
        this.enableDelete = true;
        this.enableRestore = true;
        this.showPagination = true;
        this.enableActions = true;
        this.showHeaderToolbar = true;
        this.unsetParamFilters = false;
        this.selected = new EventEmitter();
        this.pageChanged = new EventEmitter();
        this.filterChange = new EventEmitter();
        this.emitAdd = false;
        this.addButtonText = 'Aggiungi';
        this.add = new EventEmitter();
        this.massSelection = new Set();
        this.isAllMassiveSelected = false;
        this.filtersData = {};
        this.canCreate = false;
        this.fieldViewType = FieldViews;
        this.showPaginationComponent = false;
        this.hasGlobalButtons = false;
    }
    ListTableViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._unsubscribeAll = new Subject();
        this.datasetACS.datasetDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (keys) {
            if (!keys || !keys.includes('filters'))
                return;
            _this.filtersData = _this.datasetACS.datasetData['filters'];
        });
        this.filtersData = this.datasetACS.datasetData['filters'] || {};
        this.filterForm = this.viewConfig.filterForm;
        // unset params filters
        if (this.unsetParamFilters) {
            // TODO: rimuovere i campi del form dei filtri parametrizzati
        }
    };
    ListTableViewComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ListTableViewComponent.prototype.ngOnChanges = function (changes) {
        var e_1, _a;
        if (changes.viewConfig && changes.viewConfig.currentValue) {
            this.buildDisplayColumns();
            this.buildToolbarActionButtons();
            this.hasGlobalButtons = this.viewConfig.customActions && this.viewConfig.customActions.filter(function (item) { return item.isGlobal; }).length > 0;
        }
        if (changes.paginationResponse) {
            this.canCreate = this.datasetACS.canCreate() && this.enableCreate && this.datasetACS.actionEnabled('create');
            this.tableValues = {};
            if (this.paginationResponse) {
                try {
                    for (var _b = tslib_1.__values(this.paginationResponse.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var record = _c.value;
                        this.tableValues[record.id] = this.buildItemValues(record);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            this.showPaginationComponent = this.showPagination && this.paginationResponse
                && (!this.conditionalShowPaginationToolbar || (!this.paginationResponse.meta.last || this.paginationResponse.meta.page > 1));
            this.buildToolbarActionButtons();
        }
    };
    ListTableViewComponent.prototype.buildToolbarActionButtons = function () {
        var _this = this;
        this.massActionButtons = [];
        this.globalActionButtons = [];
        if (this.viewConfig && this.viewConfig.massActions) {
            this.massActionButtons = this.viewConfig.massActions.filter(function (item) {
                if (item.actionCode === 'create')
                    return _this.canCreate;
                return _this.datasetACS.canStaticAction(item.actionCode);
            });
        }
        if (this.massActionButtons.length) {
            if (!this.displayedColumns.includes('massselect'))
                this.displayedColumns.unshift('massselect');
        }
        else {
            if (this.displayedColumns.indexOf('massselect') === 0)
                this.displayedColumns.shift();
        }
        // in selectable mode disable global actions
        if (this.selectable)
            return;
        if (this.viewConfig && this.viewConfig.customActions) {
            this.globalActionButtons = this.viewConfig.customActions.filter(function (item) {
                if (!item.isGlobal)
                    return false;
                if (item.actionCode === 'create')
                    return _this.canCreate;
                return _this.datasetACS.canStaticAction(item.actionCode);
            });
        }
        if (this.canCreate) {
            this.globalActionButtons.unshift({
                actionCode: 'create',
                buttonColor: 'accent',
                buttonIcon: 'add',
                buttonText: this.addButtonText,
                isGlobal: true,
                onClick: function () {
                    _this.onAdd();
                },
                showIf: function () { return true; }
            });
        }
    };
    ListTableViewComponent.prototype.buildDisplayColumns = function () {
        var _this = this;
        if (!this.viewConfig || !this.viewConfig.columns) {
            this.displayedColumns = [];
            return;
        }
        this.displayedColumns = this.viewConfig.columns
            .filter(function (columnDef) {
            if (!columnDef.showIf)
                return true;
            return columnDef.showIf(_this.datasetACS);
        })
            .map(function (columnDef) {
            return columnDef.key;
        });
        if ((this.viewConfig.actions && this.enableActions) || this.selectable) {
            this.displayedColumns.push('actions');
        }
        if (this.viewConfig.massActions && this.viewConfig.massActions.length && this.enableActions) {
            this.displayedColumns.unshift('massselect');
        }
    };
    ListTableViewComponent.prototype.buildEditActionButton = function (record) {
        var _this = this;
        var editAction = {
            buttonIcon: 'edit',
            actionCode: 'edit',
            buttonColor: 'accent',
            tooltip: 'Modifica'
        };
        if (this.datasetACS.getDefaultOpenType('edit') === DatasetActionOpenType.PAGE) {
            editAction.routerLink = this.datasetNavigatorService.getEditRoute(this.datasetACS.datasetCode, record.id);
        }
        else {
            editAction.onClick = function (event, recordData, dataContainerService, datasetNavigatorService) {
                _this.openEdit(record);
            };
        }
        return editAction;
    };
    ListTableViewComponent.prototype.buildDetailActionButton = function (record) {
        var _this = this;
        var detailAction = {
            buttonIcon: 'pageview',
            actionCode: 'detail',
            buttonColor: 'accent',
            tooltip: 'Apri Dettaglio'
        };
        if (this.datasetACS.getDefaultOpenType('detail') === DatasetActionOpenType.PAGE) {
            detailAction.routerLink = this.datasetNavigatorService.getDetailRoute(this.datasetACS.datasetCode, record.id);
        }
        else {
            detailAction.onClick = function (event, recordData, dataContainerService, datasetNavigatorService) {
                _this.datasetNavigatorService.showDetail(_this.datasetACS, record);
            };
        }
        return detailAction;
    };
    ListTableViewComponent.prototype.buildDeleteActionButton = function (record) {
        var _this = this;
        var deleteAction = {
            buttonIcon: 'delete',
            actionCode: 'delete',
            buttonColor: 'warn',
            tooltip: 'Elimina',
            onClick: function (event, recordData, dataContainerService, datasetNavigatorService) {
                _this.datasetNavigatorService.openDeleteDialog(_this.datasetACS, record);
            }
        };
        return deleteAction;
    };
    ListTableViewComponent.prototype.buildRestoreActionButton = function (record) {
        var _this = this;
        var restoreAction = {
            buttonIcon: 'restore',
            actionCode: 'restore',
            buttonColor: 'accent',
            tooltip: 'Ripristina',
            onClick: function (event, recordData, dataContainerService, datasetNavigatorService) {
                _this.datasetNavigatorService.openRestoreDialog(_this.datasetACS, record);
            }
        };
        return restoreAction;
    };
    ListTableViewComponent.prototype.addActionButtonItem = function (code, actions, record) {
        var e_2, _a;
        if (code === 'detail' && this.datasetACS.actionEnabled('detail') && this.datasetACS.canDetail(record)) {
            actions.push(this.buildDetailActionButton(record));
        }
        else if (code === 'edit' && this.datasetACS.actionEnabled('edit') && this.datasetACS.canEdit(record) && this.enableEdit) {
            actions.push(this.buildEditActionButton(record));
        }
        else if (code === 'delete' && this.datasetACS.actionEnabled('delete') && this.datasetACS.canDelete(record) && this.enableDelete) {
            actions.push(this.buildDeleteActionButton(record));
        }
        else if (code === 'restore' && this.datasetACS.actionEnabled('restore') && this.datasetACS.canRestore(record) && this.enableRestore) {
            actions.push(this.buildRestoreActionButton(record));
        }
        else if (code === 'customs') {
            if (!this.viewConfig.customActions)
                return;
            try {
                for (var _b = tslib_1.__values(this.viewConfig.customActions), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var customAction = _c.value;
                    if (customAction.actionCode) {
                        if (customAction.actionCode === 'edit' && !(this.datasetACS.canEdit(record) && this.enableEdit)) {
                            continue;
                        }
                        if (customAction.actionCode === 'delete' && !(this.datasetACS.canDelete(record) && this.enableEdit)) {
                            continue;
                        }
                        if (customAction.actionCode === 'restore' && !(this.datasetACS.canRestore(record) && this.enableRestore)) {
                            continue;
                        }
                        if (customAction.actionCode === 'detail' && !(this.datasetACS.canDetail(record))) {
                            continue;
                        }
                        if (!this.datasetACS.canAction(record, customAction.actionCode))
                            continue;
                    }
                    if (customAction.showIf(record, this.datasetACS) && !customAction.isGlobal) {
                        var newCustomAction = Object.assign({}, customAction);
                        if (customAction.makeRouteLink) {
                            newCustomAction.routerLink = customAction.makeRouteLink(record, this.datasetACS, this.datasetNavigatorService);
                        }
                        if (customAction.makeQueryParams) {
                            newCustomAction.queryParams = customAction.makeQueryParams(record, this.datasetACS, this.datasetNavigatorService);
                        }
                        if (customAction.buttonColorDynamic) {
                            newCustomAction.buttonColor = customAction.buttonColorDynamic(record, this.datasetACS, this.datasetNavigatorService);
                        }
                        actions.push(newCustomAction);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else if (code.startsWith('custom_')) {
            if (!this.viewConfig.customActions)
                return;
            var customIndex = Number(code.replace('custom_', ''));
            if (this.viewConfig.customActions[customIndex]) {
                var customAction = this.viewConfig.customActions[customIndex];
                if (customAction.actionCode) {
                    if (customAction.actionCode === 'edit' && !(this.datasetACS.canEdit(record) && this.enableEdit)) {
                        return;
                    }
                    if (customAction.actionCode === 'delete' && !(this.datasetACS.canDelete(record) && this.enableEdit)) {
                        return;
                    }
                    if (customAction.actionCode === 'restore' && !(this.datasetACS.canRestore(record) && this.enableRestore)) {
                        return;
                    }
                    if (customAction.actionCode === 'detail' && !(this.datasetACS.canDetail(record))) {
                        return;
                    }
                }
                if (customAction.showIf(record, this.datasetACS) && !customAction.isGlobal) {
                    var newCustomAction = Object.assign({}, customAction);
                    if (customAction.makeRouteLink) {
                        newCustomAction.routerLink = customAction.makeRouteLink(record, this.datasetACS, this.datasetNavigatorService);
                    }
                    if (customAction.makeQueryParams) {
                        newCustomAction.queryParams = customAction.makeQueryParams(record, this.datasetACS, this.datasetNavigatorService);
                    }
                    actions.push(newCustomAction);
                }
            }
        }
    };
    /**
     * Prebuild column values prevent angular to refresh view fro update every row
     */
    ListTableViewComponent.prototype.buildItemValues = function (record) {
        var e_3, _a, e_4, _b;
        if (!this.viewConfig || !this.viewConfig.columns)
            return;
        var newRowValues = { record: record };
        try {
            for (var _c = tslib_1.__values(this.viewConfig.columns), _d = _c.next(); !_d.done; _d = _c.next()) {
                var column = _d.value;
                if (column.getCellConfigs && typeof (column.getCellConfigs) == 'function') {
                    newRowValues[column.key + '-cell-configs'] = column.getCellConfigs(record);
                }
                if (!column.fieldDefinition)
                    column.fieldDefinition = this.datasetACS.datasetConfig.fieldsMap.get(column.key);
                if (column.fieldDefinition) {
                    newRowValues[column.key] = this.getDisplayFieldValuePipe.transform(record, column.fieldDefinition);
                }
                else {
                    if (column.fieldView == FieldViews.MIXED_COLUMN) {
                        newRowValues[column.key] = record;
                    }
                    else {
                        console.warn('called deprecated method getElementValue', new Error().stack, column, record);
                        newRowValues[column.key] = this.getElementValue(record, column.key);
                    }
                }
                if (column.fieldView === FieldViews.HTML && column.getDisplayHTML) {
                    newRowValues[column.key] = column.getDisplayHTML(record, newRowValues[column.key], this.datasetACS.translationService);
                }
                if (column.fieldDefinition && column.fieldDefinition.getDisplayTooltip && typeof (column.fieldDefinition.getDisplayTooltip) == 'function') {
                    newRowValues[column.key + '-mat-tooltip'] = column.fieldDefinition.getDisplayTooltip(record);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_3) throw e_3.error; }
        }
        var WSconfig = this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig;
        var isNewRow = false;
        // check if new row throught web socket
        if (this.socketEvent) {
            if (WSconfig && WSconfig.checkGloballyAffectedRow && typeof (WSconfig.checkGloballyAffectedRow) == 'function') {
                isNewRow = WSconfig.checkGloballyAffectedRow(record, this.socketEvent);
            }
            if (WSconfig && WSconfig[this.socketEvent.data.eventName]) {
                var eventWSConfig = WSconfig[this.socketEvent.data.eventName];
                // if implement custom check
                if (eventWSConfig.highlightAffectedRow && typeof (eventWSConfig.highlightAffectedRow) == 'function') {
                    isNewRow = eventWSConfig.highlightAffectedRow(record, this.socketEvent);
                }
            }
        }
        var actions = [];
        var actionOrder = (this.viewConfig && this.viewConfig.itemActionsOrder) || ['detail', 'edit', 'delete', 'restore', 'customs'];
        try {
            for (var actionOrder_1 = tslib_1.__values(actionOrder), actionOrder_1_1 = actionOrder_1.next(); !actionOrder_1_1.done; actionOrder_1_1 = actionOrder_1.next()) {
                var actionCode = actionOrder_1_1.value;
                this.addActionButtonItem(actionCode, actions, record);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (actionOrder_1_1 && !actionOrder_1_1.done && (_b = actionOrder_1.return)) _b.call(actionOrder_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        newRowValues['_column_actions'] = actions;
        newRowValues['isDeleted'] = Boolean(record.deleted_at);
        newRowValues['canEdit'] = this.datasetACS.actionEnabled('edit') && this.datasetACS.canEdit(record) && this.enableEdit;
        newRowValues['canDelete'] = this.datasetACS.actionEnabled('delete') && this.datasetACS.canDelete(record) && this.enableDelete;
        newRowValues['canDetail'] = this.datasetACS.actionEnabled('detail') && this.datasetACS.canDetail(record);
        newRowValues['canRestore'] = this.datasetACS.actionEnabled('restore') && this.datasetACS.canRestore(record) && this.enableRestore;
        newRowValues['isNewRow'] = isNewRow;
        newRowValues['allowed_actions'] = {
            edit: newRowValues['canEdit'],
            view_detail: newRowValues['canDetail'],
            delete: newRowValues['canDelete']
        };
        return newRowValues;
    };
    ListTableViewComponent.prototype.openTextDialog = function (text, title) {
        this.dialog.open(TextDialogComponent, {
            width: '500px',
            data: {
                title: title,
                contentMessage: text
            }
        });
    };
    ListTableViewComponent.prototype.onAdd = function () {
        if (this.emitAdd) {
            this.add.emit();
        }
        else {
            this.datasetNavigatorService
                .onAdd(this.datasetACS);
        }
    };
    ListTableViewComponent.prototype.onDelete = function (data) {
        this.datasetNavigatorService
            .openDeleteDialog(this.datasetACS, data);
    };
    ListTableViewComponent.prototype.onResore = function (data) {
        this.datasetNavigatorService
            .openRestoreDialog(this.datasetACS, data);
    };
    ListTableViewComponent.prototype.showDetail = function (data) {
        this.datasetNavigatorService
            .showDetail(this.datasetACS, data);
    };
    ListTableViewComponent.prototype.openEdit = function (data) {
        this.datasetNavigatorService
            .onEdit(this.datasetACS, data, {
            parentDatasetACS: this.datasetACS,
            viewContainerRef: this.viewContainerRef
        });
    };
    ListTableViewComponent.prototype.select = function (record) {
        this.selection = record;
        this.selected.emit(record);
    };
    /**
     *
     * @param record @deprecated use GetDisplayFieldValuePipe
     * @param key
     */
    ListTableViewComponent.prototype.getElementValue = function (record, key) {
        var fieldDefinition = this.datasetACS.datasetConfig.fieldsMap.get(key);
        if (!fieldDefinition) {
            return '! ' + key + ' !';
        }
        return FieldHelper.getDisplayFieldValue(fieldDefinition, record, undefined, this.datasetACS);
    };
    ListTableViewComponent.prototype.onPaginatorChange = function (event) {
        this.pageChanged.emit(event);
    };
    ListTableViewComponent.prototype.baseTrackBy = function (index, item) {
        return index;
    };
    ListTableViewComponent.prototype.masterToggle = function () {
        if (this.isAllMassiveSelected) {
            this.massSelection.clear();
        }
        else {
            for (var key in this.tableValues) {
                if (!Object.prototype.hasOwnProperty.call(this.tableValues, key))
                    continue;
                var element = this.tableValues[key];
                if (!element.record || !element.record.id)
                    continue;
                this.massSelection.add(element.record.id);
            }
        }
        this.isAllMassiveSelected = !this.isAllMassiveSelected;
    };
    ListTableViewComponent.prototype.toggleRow = function (row) {
        if (!row || !row.record || !row.record.id)
            return;
        if (this.massSelection.has(row.record.id)) {
            this.massSelection.delete(row.record.id);
        }
        else {
            this.massSelection.add(row.record.id);
        }
        var allSelected = true;
        for (var key in this.tableValues) {
            if (!Object.prototype.hasOwnProperty.call(this.tableValues, key))
                continue;
            var element = this.tableValues[key];
            if (!element.record || !element.record.id || this.massSelection.has(element.record.id))
                continue;
            allSelected = false;
            break;
        }
        this.isAllMassiveSelected = allSelected;
    };
    ListTableViewComponent.prototype.clickMassAction = function (action) {
        var _this = this;
        var ids = Array.from(this.massSelection);
        action.onClick(ids, this.datasetACS, this.datasetNavigatorService, this.viewContainerRef)
            .subscribe(function (command) {
            if (command === 'clear-selection') {
                _this.massSelection.clear();
                _this.isAllMassiveSelected = false;
            }
        });
    };
    return ListTableViewComponent;
}());
export { ListTableViewComponent };
