import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as resourceFieldMap} from '../trip_resources/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', cloneFieldDefinition(resourceFieldMap.get('code'), {key: 'code', onClick: false}));
fieldsMap.set('description', cloneFieldDefinition(resourceFieldMap.get('description'), {key: 'description'}));
fieldsMap.set('quantity_ticket', {
	key: 'quantity',
	name: 'Ticket Disponibili',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se non specificato, verrà impostato a 0',
	getDisplayValue(record: any): string{
		return record.quantity;
	}
});

export {fieldsMap};
