import {
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import { FormViewerService } from 'app/services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from '../../../../components/dataset/services/dataset.service';
import { IPaginationResponse } from '../../../../../interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'trenitalia-search-travellers',
	templateUrl: './search-travellers.component.html',
	styleUrls: ['./search-travellers.component.scss'],
	providers: [ FormViewerService ]
})
export class SearchTravellersComponent implements OnInit, OnDestroy {
	public checkoutData;
	public passengers: any[];

	public formFields = formFields;
	public checkPass = true;
	public load: boolean;
	public passenger1: BehaviorSubject<any>;
	private oldPassengers: any;

	protected _unsubscribeAll: Subject<any>;

	get requestInvoice(): boolean {
		const request_invoice = this.formViewerService.formGroup.get('request_invoice');
		return request_invoice ? request_invoice.value : false;
	}

	constructor(
		public trnService: TrenitaliaService,
		public formViewerService: FormViewerService,
		public datasetACS: DatasetActionContainerService,
		private datasetService: DatasetService
	) {
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		});
		this._unsubscribeAll = new Subject();
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
		});
		this.passenger1 = new BehaviorSubject({});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnInit() {
		if( ['edit-travellers','edit','edit-traveller-pre-sale'].includes(this.checkoutData.mode)){
			this.checkPass = false;
			this.passengers = this.checkoutData.editPassengers.map((traveller,k) => {
				const passenger: any = {
					type: traveller.type,
					num: 0,
					valid: false,
					dataPass: {
						pass_first_name: traveller.name,
						pass_last_name: traveller.surname,
						pass_email: traveller.email,
						pass_phone: traveller.phoneNumber,
						pass_cf: traveller.cartaFreccia,
						contact_consent: Boolean(traveller.contact_consent || true)
					},
					travellerXmlId: ['edit-travellers','edit-traveller-pre-sale'].includes(this.checkoutData.mode)?traveller.xmlId:_.get(this.checkoutData,['travellers',k,'xmlId'])
				}

				if(traveller.birthDate){
					passenger.dataPass.pass_data_n = moment(traveller.birthDate, 'DD/MM/YYYY');
				}
				return passenger;
			});
            if(['edit','edit-traveller-pre-sale'].includes(this.checkoutData.mode)){
                this.formViewerService.setValues({
                    first_name: this.checkoutData.buyer.name,
                    last_name: this.checkoutData.buyer.surname,
                    email: this.checkoutData.buyer.email,
                   //confirm_email: this.checkoutData.buyer.email,
                    phone: this.checkoutData.buyer.phoneNumber
                });
            }
            if(this.checkoutData.invoiceRequested)this.formViewerService.formGroup.get('request_invoice').setValue(true);
		}else{
			this.load = true;
			this.trnService.loading.next(true);
			this.datasetService
			.get<IPaginationResponse<any>>(
				'/dataset/trip_booking_participants',
				{
					trip_booking_id: this.checkoutData.tripBookingId,
					sortBy: 'created_at',
					sortDirection: 'desc',
					perPage: 50,
					page: 1
				}
			).pipe(takeUntil(this._unsubscribeAll))
			.subscribe(result => {
				if (result.items && result.items.length) {
					this.passengers = [];
					if (this.checkoutData.editPassengers) {
						this.oldPassengers = this.checkoutData.editPassengers;
					}
					this.initContactData(result.items);
					if (this.checkoutData.editPassengers) {
						this.passengers = this.checkoutData.editPassengers;
					}else{
						const usedTangerinePassengerIds = new Set();
						this.passengers = this.checkoutData.travellers.map(t => {
							const candidateTripBookingParticipant = result.items.find(p => {
								if(usedTangerinePassengerIds.has(p.id)) return false;
								if(['ADT', 'SNR'].includes(p.age_range_code) && t.type != 'ADULT') return false;
								return true;
							});
							if(_.has(candidateTripBookingParticipant, 'id')) usedTangerinePassengerIds.add(_.get(candidateTripBookingParticipant, 'id'));
							const data = this.getPassengerDataFromTripBookingParticipant(candidateTripBookingParticipant);
							return {
								type: t.type,
								num: 0,
								valid: false,
								dataPass: {
									pass_first_name: data.name,
									pass_last_name: data.surname,
									pass_email: data.email,
									pass_phone: data.phone
								},
								tangerine_id: _.get(candidateTripBookingParticipant, 'id'),
								travellerXmlId: t.xmlId
							};
						});
					}
				}
				this.trnService.loading.next(false);
				this.load = false;
				this.checkoutData.passengers = this.passengers;
				this.trnService.checkoutData.next(this.checkoutData);
			});
		}
		this.formViewerService.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(s => {
			this.passenger1.next(this.formViewerService.getFormData());
			this.checkValidate();
		});
	}

	togglePassengerBuyer(){
		this.checkPass = !this.checkPass;
		this.passenger1.next(this.formViewerService.getFormData());
	}

	indexTrackBy(index, item): any{
		return index;
	}

	getPassengerDataFromTripBookingParticipant(tripBookingParticipant: any): any{
		let name = null;
		let lastname = null;
		let descParts = [null, null];
		if(_.get(tripBookingParticipant, 'description')){
			descParts = _.get(tripBookingParticipant, 'description').split(' ');
		}
		name = _.defaultTo(_.get(tripBookingParticipant, 'entity.firstname'), descParts[0]);
		lastname = _.defaultTo(_.get(tripBookingParticipant, 'entity.lastname'), _.get(descParts, '1'));

		return {
			name,
			surname: lastname,
			email: _.get(tripBookingParticipant, 'entity.primary_email'),
			phone: _.get(tripBookingParticipant, 'entity.phone_number')
		};
	}

	initContactData(tripBookingParticipants: any[]){
		if(!tripBookingParticipants || tripBookingParticipants.length == 0) return;
		// get first entity adult
		const candidateContact = tripBookingParticipants.sort((a, b) => {
			if(['ADT', 'SNR'].includes(a.age_range_code)){
				if(a.entity || !['ADT', 'SNR'].includes(b.age_range_code)) return -1;
				return 1;
			};
			return 1;
		})[0];
		if(!candidateContact){
			console.log('no valid candidate found', tripBookingParticipants);
			this.formViewerService.formGroup.reset();
			return;
		}
		const data = this.getPassengerDataFromTripBookingParticipant(candidateContact);
		
		this.formViewerService.setValues({
			first_name: data.name,
			last_name: data.surname,
			email: data.email,
			confirm_email: data.email,
			phone: data.phone
		});
	}

	/**
	 * Prende il numero per il form
	 * @param e
	 */
	getValid(e) {
		this.passengers[e.index].valid = e.valid;
		this.passengers[e.index].dataPass = e.data;
		_.unset(this.checkoutData, 'invalid_traveller_parameters.'+this.passengers[e.index].travellerXmlId);
		this.checkValidate();
	}

	checkValidate() {
		let validate = this.passengers.every(p => p.valid);
		const buyerValid = this.checkoutData.mode == 'edit-travellers' || this.formViewerService.formGroup.valid;
		this.checkoutData.finalTravellersValid = validate && buyerValid;
		
		if(this.checkoutData.mode != 'edit-travellers'){
			const dataBuyer = this.formViewerService.getFormData();
			this.checkoutData.buyer = {
				name: dataBuyer.first_name,
				surname: dataBuyer.last_name,
				email: dataBuyer.email,
				phoneNumber: dataBuyer.phone
			};
			this.checkoutData.invoiceRequested = dataBuyer.request_invoice;
		}
		this.checkoutData.finalTravellers = this.passengers;
		this.checkoutData.finalTravellersValid = validate;
		this.trnService.checkoutData.next(this.checkoutData);
	}

	checkInvoiceHolder() {}
}
