import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';

import discountViewConfig from 'app/configs/datasets/trip_booking_services/discount-view-config';

@Component({
	selector   : 'trip-booking-discount-item-view',
	templateUrl: './item.component.html',
	styleUrls  : ['./item.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService]
})
export class TripBookingDiscountItemViewComponent implements OnInit, OnChanges{

	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() item: any;
	@Output() delete: EventEmitter<any> = new EventEmitter();

	public viewConfig = discountViewConfig;

	constructor(
		protected datasetService: DatasetService,
		protected appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService
	){}

	ngOnInit(): void{}

	ngOnChanges(changes: SimpleChanges): void{
		this.datasetRS.record.next(this.item);
	}

	trashTripBookingDiscount(): void{
		this.delete.emit(this.item);
	}

	tripBookingDiscountServiceDatasetEvent(event: IDatasetEvent): void{
		this.parentDatasetACS.parentDatasetRS.reload();
	}
}
