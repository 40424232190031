<div *ngIf='!stepService.selectedOptionId; then noption;'></div>
<!-- <div *ngIf='(scenario.value === "go-to-dataset"); then selectoption;'></div> -->
<form-input-viewer
	[formInputDefinition]='fileterFields.get("selected_option_id")'
	[formGroup]='formGroup'
	[formControlKey]='"selected_option_id"'
	[dataContainerService]='parentDatasetACS'
	[data]='stepService.selectedOptionId'
></form-input-viewer>

<div *ngIf='stepService.selectedOptionId' class='full-form'>
	<!--<ng-container *ngIf='(scenario.value === "go-to-dataset")'>
		<button *ngIf='stepService.selectedOption.value == null' class="gyg-button gyg-button-medium gyg-button-cta-light outline" (click)='resetForm("create")'>
			Aggiungi disponibilità
		</button>

		<dataset-list
			[enableCreate]='false'
			(selected)='onSelected($event)'
			[selectable]='true'
			[selectionBtnTxt]='"Seleziona"'
			[elevate]='false'
			[showSearchBar]='true'
			[showTitle]='false'
			[datasetCode]='"availability"'
			[parentDatasetACS]='parentDatasetACS'
			[filters]='{option_id: stepService.selectedOptionId}'
			[viewConfig]='avilabilitiesViewConfig'
		></dataset-list>
	</ng-container>-->

	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<ng-container *ngIf='showForm'><!-- *ngIf='scenario.value === "create" || scenario.value === "edit"' -->
		<!--<div class="alert-message" (click)='resetForm("go-to-dataset")'>
			<span class="material-icons">undo</span> Torna alle disponibilità
		</div>-->
		
		<form autocomplete="off"
			name='form' 
			[formGroup]="formGroup" 
			fxLayout="column" 
			fxLayoutAlign="start"
			fxLayoutAlign.lt-lg='stretch'>

			<div class="description">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formGroup'
					[formControlKey]='"description"'
					[dataContainerService]='datasetACS'
				></form-input-viewer>
			</div>

			<div class="max-allotment">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("max_allotment")'
					[formGroup]='formGroup'
					[formControlKey]='"max_allotment"'
					[dataContainerService]='datasetACS'
				></form-input-viewer>
			</div>

			<div class="validity-row">
				<div class="title">
					<label class="input-label section-question">Validità di questa stagione</label>
				</div>

				<div class="validity">
					<div class="date valid_from">
						<form-input-viewer
							[formInputDefinition]='fileterFields.get("valid_from")'
							[formGroup]='formGroup'
							[formControlKey]='"valid_from"'
							[dataContainerService]='datasetACS'
						></form-input-viewer>
					</div>
		
					<div class="date valid_to">
						<form-input-viewer
							[formInputDefinition]='fileterFields.get("valid_to")'
							[formGroup]='formGroup'
							[formControlKey]='"valid_to"'
							[dataContainerService]='datasetACS'
						></form-input-viewer>
					</div>
				</div>
			</div>

			<div class="title">
				<label class="input-label section-question">Orari di partenza settimanali</label>
			</div>
			<ng-container *ngFor='let dayObj of DAYS'>
				<div class="row-day">
					<!--<form-input-viewer
						[formInputDefinition]='fileterFields.get(dayObj.day)'
						[formGroup]='formGroup'
						[formControlKey]='dayObj.day'
					></form-input-viewer>-->

					<div class="starting-times">
						<span class="day" [innerHtml]='dayObj.day'></span>
						<span class="starting-time" [class.disabled]='!formGroup.get("valid_from").value' (click)='addStartingTime(dayObj.code)'>
							<i class="material-icons">alarm</i> Aggiungi ora di inizio
						</span>
						<span *ngIf='days(dayObj.code).length' class="copyToWholeWeek" (click)='copyToWholeWeek(dayObj.code)'>
							<i class="material-icons">file_copy</i> Copia per l'intera settimana
						</span>

						<div [formArrayName]='dayObj.code'>
							<ng-container *ngFor="let controlFormGroup of formGroup.get(dayObj.code)['controls']; let first = first; index as i">
								<div class="time_start">
									<div class="input-time">
										<form-input-viewer
											[formInputDefinition]='fileterFields.get("starting_time")'
											[formGroup]='controlFormGroup'
											[formControlKey]='"starting_time"'
										></form-input-viewer>
									</div>

									<div class="remove-item">
										<span class="material-icons" (click)='removeAt(dayObj.code, i)'>delete</span>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>

			<div class="special-dates">
				<h2>Singole/Date speciali</h2>
				
				<div [formArrayName]="'spec'">
					<ng-container *ngFor="let specialDateCtrlFormGroup of formGroup.get('spec')['controls']; let first = first; index as j">
						<div class="block-dateTimes">
							<div class="remove-block">
								<span class="material-icons" (click)='removeBlockSpecialDates(j)'>delete</span>
							</div>
		
							<div class="special-dateTime">
								<div class="special-date">
									<div class="input-special-date">
										<form-input-viewer
											[formInputDefinition]='fileterFields.get("special_date")'
											[formGroup]='specialDateCtrlFormGroup'
											[formControlKey]='"special_date"'
										></form-input-viewer>
									</div>
								</div>
			
								<div class="special-times">
									<ng-container *ngFor="let dtimeFgroup of specialDateCtrlFormGroup.get('special_date_starting_time')['controls']; let first = first; index as i">
										<div class="input-special-time">
											<form-input-viewer
												[formInputDefinition]='fileterFields.get("starting_time")'
												[formGroup]='dtimeFgroup'
												[formControlKey]='"starting_time"'
											></form-input-viewer>
										</div>
										<ng-container *ngIf='!first'>
											<div class="remove-item">
												<span class="material-icons" (click)='removeSpecialTime(specialDateCtrlFormGroup.get("special_date_starting_time"), i)'>delete</span>
											</div>
										</ng-container>
									</ng-container>
								</div>
			
								<div class="starting-time" (click)='addSpecialDateStartingTime(specialDateCtrlFormGroup.get("special_date_starting_time"))'>
									<i class="material-icons">alarm</i> Aggiungi ora di inizio
								</div>
							</div>
						</div>
					</ng-container>
				</div>

				<button class="gyg-button gyg-button-medium gyg-button-cta-light outline" (click)='addSpecialDate()'>
					<span>Aggiungi nuova data</span>
				</button>
			</div>

			<div class="save-button-container">
				<button 
					[disabled]='!formGroup.valid'
					class="gyg-button gyg-button-medium gyg-button-cta-light" 
					(click)='onSave()'>
					<span>Salva</span>
				</button>
				<!-- delete btn when record exists -->
				<button mat-flat-button class="delete-btn" 
					*ngIf='(stepService.selectedAvailability.value !== null && !datasetACS.loading.value)' 
					color='warn' 
					(click)='delete()'>
					Elimina disponibilità
				</button>
			</div>
		</form>
	</ng-container>
</div>

<ng-template #noption>
	<div class="alert-message">Devi selezionare un'opzione per poter configurare le disponibilità</div>
</ng-template>

<!--<ng-template #selectoption>
	<form-input-viewer
		[formInputDefinition]='fileterFields.get("selected_option_id")'
		[formGroup]='formGroup'
		[formControlKey]='"selected_option_id"'
		[dataContainerService]='parentDatasetACS'
		[data]='stepService.selectedOptionId'
	></form-input-viewer>
</ng-template>-->