import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('user_id'),
	fieldsMap.get('domain_id')
];

const config: IFormConfig = { 
	editTitle: 'Filtri',
	fields: formFields 
};

export default config;
