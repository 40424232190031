import { Component, Input, NgZone, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { fieldsMap } from './form.fields';
import { FormBuilder } from '@angular/forms';
import { CdkStepper } from '@angular/cdk/stepper';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { showIfEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';

@Component({
	selector   : 'product-name-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [FormViewerService]
})
export class ProductNameStepComponent extends BaseStepViewComponent implements OnInit {
	
	// @ViewChild('formViewer') formViewer: FormViewerComponent;

	public fileterFields = fieldsMap;
	public formErrors: any;
	public showIfeAgency: boolean;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		protected formBuilder: FormBuilder,
		protected zone: NgZone,
		protected stepService: StepService,
		public formViewerService: FormViewerService){
		/** init parent constructor */
		super(datasetACS, datasetNavigatorService);
		/** load other data */
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			this.formViewerService.setRecord(record);
			
			if(this.datasetRS.recordId){
				this.canEdit = this.datasetACS.canEdit(this.datasetRS.record.value);
			}else{
				// is a new record
				this.canEdit = true;
			}
		});
	}

	onSave(): void{
		this.formViewerService.formGroup.disable();
		let formData = this.formViewerService.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		if(this.stepConfig.options && typeof(this.stepConfig.options.getCallParams) === 'function'){
			formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
		}
		this.datasetACS.loading.next(true);
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/create';
		if(this.datasetRS.recordId){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}else{
			formData['lang'] = this.appService.language.value;
		}
		const headers: HttpHeaders = this.datasetACS.getContextualDomainHeaders();
		this.datasetService.post<any>(route, formData, {headers: headers})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.stepService.setStepState({
					dataset_code: 'trip_packages',
					form_code: 'product_name',
					record_id: response.id,
					status: 1
				});
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				
				if(this.formViewerService){
					this.formViewerService.formGroup.enable();
					this.formViewerService.clearForm();
				}
				if(!this.datasetRS.record.value && response && response.id){
					this.datasetNavigatorService.router.navigate(['dataset/edit/' + this.datasetACS.datasetCode, response.id], { queryParams: { step: 2 } });
				}else if(this.datasetRS.record.value && response && response.id){
					this.datasetRS.recordId = response.id;
					this.datasetRS.reload()
					.subscribe(record => {
						if(record){
							this.formViewerService.setRecord(record);
							setTimeout(() => {
								this.goNextStep();
							}, 200);
						} else {
							this.datasetNavigatorService.onEdit(this.datasetACS, response);
						}
					});
				}
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewerService){
					this.formViewerService.formGroup.enable();
					this.formViewerService.setErrors(this.formErrors);
				}
			}
		});
	}

	ngOnInit(): void {
		this.datasetACS.loading.next(false);
		this.showIfeAgency = showIfEAgency(this.datasetRS.record.value, this.datasetACS);
		this.formViewerService.init({
			errors: this.formErrors,
			dataContainerService: this.datasetACS,
			fields: Array.from(this.fileterFields.values())
		});
	}
}
