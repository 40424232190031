import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { HttpHeaders } from '@angular/common/http';
var DatasetMultiSelectInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetMultiSelectInputComponent, _super);
    function DatasetMultiSelectInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.selectedItems = [];
        _this.loading = false;
        _this.items = [];
        return _this;
    }
    DatasetMultiSelectInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.buildSelectResourceOptions();
            _this.getFormControl().valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (value) {
                if (value === undefined) {
                    _this.getFormControl().setValue(null);
                    return;
                }
                _this.refreshSelectedItems()
                    .subscribe(function () {
                    _this.inputService.onSet(_this.selectedItems);
                });
            });
        });
    };
    DatasetMultiSelectInputComponent.prototype.updateSelectedLabel = function () {
        var e_1, _a;
        var value = this.getFormControl().value;
        var labels = [];
        if (value && value instanceof Array) {
            try {
                for (var _b = tslib_1.__values(this.selectedItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    var label = this.inputService.formInputDefinition.remoteOptions.getValue(item);
                    labels.push(label);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.selectedLabel = labels.length ? labels.join(', ') : null;
    };
    DatasetMultiSelectInputComponent.prototype.getFormControl = function () {
        return this.inputService.control;
    };
    DatasetMultiSelectInputComponent.prototype.getItemFromValue = function (value) {
        var e_2, _a;
        if (!this.items)
            return null;
        try {
            for (var _b = tslib_1.__values(this.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                var itemValue = this.inputService.formInputDefinition.remoteOptions.getValue(item);
                if (itemValue === value)
                    return item;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return null;
    };
    DatasetMultiSelectInputComponent.prototype.refreshSelectedItems = function () {
        var e_3, _a;
        var _this = this;
        if (this.refreshSelectedItemsSubscription) {
            this.refreshSelectedItemsSubscription.unsubscribe();
            this.refreshSelectedItemsSubscription = null;
        }
        var value = this.getFormControl().value;
        var resultSubject = new Subject();
        this.selectedItems = [];
        if (!value) {
            resultSubject.next();
            resultSubject.complete();
            return resultSubject;
        }
        var fetchArray = [];
        if (value instanceof Array) {
            try {
                for (var value_1 = tslib_1.__values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var v = value_1_1.value;
                    fetchArray.push(this.refreshSelectedItem(v));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_a = value_1.return)) _a.call(value_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        else {
            fetchArray.push(this.refreshSelectedItem(value));
        }
        this.refreshSelectedItemsSubscription = forkJoin(fetchArray).subscribe({
            next: function (result) {
                _this.updateSelectedLabel();
                resultSubject.next();
                resultSubject.complete();
            },
            error: function (result) {
                resultSubject.next();
                resultSubject.complete();
            }
        });
        return resultSubject;
    };
    DatasetMultiSelectInputComponent.prototype.refreshSelectedItem = function (value) {
        var _this = this;
        var resultSubject = new Subject();
        this.getSelectedItem(value).subscribe({
            next: function (result) {
                _this.selectedItems.push(result);
                resultSubject.next(true);
                resultSubject.complete();
            },
            error: function (result) {
                resultSubject.next(false);
                resultSubject.complete();
            }
        });
        return resultSubject;
    };
    DatasetMultiSelectInputComponent.prototype.buildSelectResourceOptions = function (text) {
        var _this = this;
        if (!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions)
            return;
        this.selectOptions = [];
        var params = {
            perPage: this.inputService.formInputDefinition.remoteOptions.limit || 50
        };
        if (this.inputService.formInputDefinition.remoteOptions.getParams) {
            params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
        }
        var formControl = this.getFormControl();
        if (formControl && formControl.value)
            params['include'] = formControl.value;
        if (text) {
            params['text-search'] = text;
        }
        if (this.fetchOptionsSubscription) {
            this.fetchOptionsSubscription.unsubscribe();
        }
        this.loading = true;
        var route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
        if (this.inputService.formInputDefinition.remoteOptions.getPivotConfig) {
            var pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
            var parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
            route = '/pivot/' + pivotConfig.pivotCode + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
        }
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.fetchOptionsSubscription = this.datasetService.get(route, params, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                var e_4, _a;
                _this.items = result.items;
                var options = [];
                try {
                    for (var _b = tslib_1.__values(result.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var item = _c.value;
                        options.push({
                            value: _this.inputService.formInputDefinition.remoteOptions.getValue(item),
                            label: _this.inputService.formInputDefinition.remoteOptions.getLabel(item)
                        });
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                _this.selectOptions = options;
                _this.loading = false;
                _this.updateSelectedLabel();
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    DatasetMultiSelectInputComponent.prototype.search = function (text) {
        this.buildSelectResourceOptions(text);
    };
    DatasetMultiSelectInputComponent.prototype.getSelectedItem = function (value) {
        var resultSubject = new Subject();
        if (!value) {
            resultSubject.next(null);
            resultSubject.complete();
            return resultSubject;
        }
        var item = this.getItemFromValue(value);
        if (item) {
            resultSubject.next(item);
            resultSubject.complete();
            return resultSubject;
        }
        var headers = new HttpHeaders({});
        if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        this.datasetService.get('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                resultSubject.next(response);
                resultSubject.complete();
            },
            error: function (response) {
                resultSubject.error(response);
                resultSubject.complete();
            }
        });
        return resultSubject;
    };
    return DatasetMultiSelectInputComponent;
}(DestroyableComponent));
export { DatasetMultiSelectInputComponent };
