import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormViewerModule } from 'app/main/components/form-viewer/form-viewer.module';
import { DataFilterModule } from 'app/main/components/data-filter/data-filter.module';
import { RouterModule } from '@angular/router';
import { PropertyDefinitionsPageComponent } from './property-definitions.component';

@NgModule({
	declarations: [
		PropertyDefinitionsPageComponent
	],
	imports     : [
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		MatCardModule,
		MatProgressBarModule,
		MatPaginatorModule,
		MatExpansionModule,
		MatToolbarModule,
		FlexLayoutModule,

		FuseSharedModule,
		FuseSidebarModule,
		FuseWidgetModule,
		FormViewerModule,
		DataFilterModule,
		RouterModule,

		DatasetModule,
		TranslateModule.forChild(),
	],
	exports: [
		PropertyDefinitionsPageComponent
	]
})
export class PropertyDefinitionsPageModule{}
