<h1 mat-dialog-title>{{data.title?data.title:'Carica file' | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<div [innerHTML]="data.message"></div>

	<div class='file-input-controller'>
		<div class='file-drop-zone' [class.dragging]='dragging' (dragleave)='onFileDragLeave($event)' (dragstart)='onFileDragStart($event)' (dragover)='onFileDragOver($event)' (drop)='onFileDrop($event)' *ngIf='!formGroup.get("file").value'>
			<div fxLayout='column' fxLayoutAlign='center center'>
				<mat-icon color='accent'>cloud_download</mat-icon>
				<span><strong>{{'Trascina il file qui per caricarlo' | translate}}</strong></span>
				<span>{{'oppure clicca per cercare nel computer' | translate}}</span>
				<span>{{'Sono supportati i file: .jpeg, .png' | translate}}</span>
				<span *ngIf='imageWidth && imageHeight'>
					{{'Formato' | translate}}: {{imageWidth}} x {{imageHeight}}
				</span>
			</div>
			<input #fileInput (change)="onFileChange($event)" type='file'/>
		</div>
		<div class='file-loaded' *ngIf='imageChangedEvent && svgEnablded'>
			<div *ngIf='fileType !== "image"' fxLayout='column' fxLayoutAlign='center center'>
				<mat-icon color='accent'>insert_drive_file</mat-icon>
				<span *ngIf='selectedFileName'>{{selectedFileName}}</span>
			</div>
			<img *ngIf='imagePreviewSrc' class='file-loaded-preview' [src]="imagePreviewSrc" alt='preview' />
			<button class='file-loaded-remove-btn' mat-icon-button (click)='clearFile()'>
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class='file-loaded' *ngIf='imageChangedEvent && !svgEnablded'>
			<div *ngIf='fileType !== "image"' fxLayout='column' fxLayoutAlign='center center'>
				<mat-icon color='accent'>insert_drive_file</mat-icon>
				<span *ngIf='selectedFileName'>{{selectedFileName}}</span>
			</div>
			<!--<img *ngIf='imagePreviewSrc' class='file-loaded-preview' [src]='imagePreviewSrc' alt='preview' />-->
			<image-cropper
				*ngIf='fileType === "image"'
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
				[aspectRatio]="imageRatio"
				[resizeToWidth]="imageWidth"
				[resizeToHeight]="imageHeight"
				[cropperMinWidth]="imageWidth"
				[cropperMinHeight]="imageHeight"
				[onlyScaleDown]="true"
				[roundCropper]="false"
				[autoCrop]="true"
				format="png"
				outputType="file"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
			<button class='file-loaded-remove-btn' mat-icon-button (click)='clearFile()'>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="onNoClick()">{{data.negativeText?data.negativeText:'Annulla' | translate}}</button>
	<button mat-flat-button (click)='onUpload()' [disabled]='loading' color='accent' >{{data.positiveText?data.positiveText:'Carica' | translate}}</button>
</mat-dialog-actions>
