<h1 mat-dialog-title>{{data.title?data.title:'Conferma'}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<div [innerHTML]="data.contentMessage"></div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="onNoClick()">{{data.negativeText?data.negativeText:'No' | translate}}</button>
	<button mat-button *ngIf='data.neutralText' (click)="onNeutralClick()">{{data.neutralText | translate}}</button>
	<button mat-flat-button (click)='onYesClick()' color='accent' >{{data.positiveText?data.positiveText:'Si' | translate}}</button>
</mat-dialog-actions>
