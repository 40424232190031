/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../base-actions/list/dataset-list.component.ngfactory";
import * as i5 from "../../services/dataset-action-container.service";
import * as i6 from "../../../../../services/app.service";
import * as i7 from "../../../../pages/page-closure-container.service";
import * as i8 from "../../../../../services/auth.service";
import * as i9 from "../../services/dataset.service";
import * as i10 from "../../../../../services/domain-filter.service";
import * as i11 from "../../base-actions/list/dataset-list.service";
import * as i12 from "../../base-actions/list/dataset-list.component";
import * as i13 from "@angular/router";
import * as i14 from "../../services/dataset-navigator.service";
import * as i15 from "../../../../../../@fuse/services/translation-loader.service";
import * as i16 from "@angular/material/snack-bar";
import * as i17 from "../../../../../services/websocket.service";
import * as i18 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i19 from "@angular/material/button";
import * as i20 from "@angular/cdk/a11y";
import * as i21 from "@angular/platform-browser/animations";
import * as i22 from "../../services/dataset-record.service";
import * as i23 from "./dialog.component";
var styles_PriceRulesDialogComponent = [];
var RenderType_PriceRulesDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PriceRulesDialogComponent, data: {} });
export { RenderType_PriceRulesDialogComponent as RenderType_PriceRulesDialogComponent };
function View_PriceRulesDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Listino prezzi")); _ck(_v, 2, 0, currVal_1); }); }
export function View_PriceRulesDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PriceRulesDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "mb-24"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "dataset-list", [["datasetCode", "price_rules"]], null, [[null, "datasetEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("datasetEvent" === en)) {
        var pd_0 = (_co.onDatasetEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DatasetListComponent_0, i4.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i5.DatasetActionContainerService, i5.DatasetActionContainerService, [i6.AppService, i7.PageClosureContainerService, i8.AuthService, i9.DatasetService, i10.DomainFilterService, i2.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i11.DatasetListService, [i5.DatasetActionContainerService, i9.DatasetService]), i0.ɵdid(8, 770048, null, 0, i12.DatasetListComponent, [i13.ActivatedRoute, i9.DatasetService, i13.Router, i8.AuthService, i6.AppService, i5.DatasetActionContainerService, i14.DatasetNavigatorService, i10.DomainFilterService, "ListParamService", i15.FuseTranslationLoaderService, i16.MatSnackBar, i17.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], parentDatasetACS: [2, "parentDatasetACS"], parentDatasetRS: [3, "parentDatasetRS"], filters: [4, "filters"], showSearchBar: [5, "showSearchBar"], elevate: [6, "elevate"] }, { datasetEvent: "datasetEvent" }), i0.ɵpod(9, { price_rule_list_id: 0, foreign_parent_record_id: 1, foreign_parent_dataset_code: 2, foreign_parent_field_key: 3 }), (_l()(), i0.ɵeld(10, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["color", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_MatButton_0, i18.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i19.MatButton, [i0.ElementRef, i20.FocusMonitor, [2, i21.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(14, 0, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig; _ck(_v, 1, 0, currVal_0); var currVal_1 = false; var currVal_2 = "price_rules"; var currVal_3 = _co.datasetACS; var currVal_4 = _co.datasetRS; var currVal_5 = _ck(_v, 9, 0, _co.data.listId, _co.data.parentRecordId, _co.data.parentDatasetACS.datasetCode, _co.data.parentFieldKey); var currVal_6 = false; var currVal_7 = false; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = ""; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_8 = (i0.ɵnov(_v, 13).disabled || null); var currVal_9 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_8, currVal_9); var currVal_11 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Chiudi")); _ck(_v, 14, 0, currVal_11); }); }
export function View_PriceRulesDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "price-rules-dialog", [], null, null, null, View_PriceRulesDialogComponent_0, RenderType_PriceRulesDialogComponent)), i0.ɵprd(512, null, i7.PageClosureContainerService, i7.PageClosureContainerService, [i6.AppService]), i0.ɵprd(512, null, i5.DatasetActionContainerService, i5.DatasetActionContainerService, [i6.AppService, i7.PageClosureContainerService, i8.AuthService, i9.DatasetService, i10.DomainFilterService, i2.TranslateService, i0.Injector]), i0.ɵprd(512, null, i22.DatasetRecordService, i22.DatasetRecordService, [i5.DatasetActionContainerService, i9.DatasetService]), i0.ɵdid(4, 245760, null, 0, i23.PriceRulesDialogComponent, [i5.DatasetActionContainerService, i22.DatasetRecordService, i6.AppService, i9.DatasetService, i1.MatDialog, i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var PriceRulesDialogComponentNgFactory = i0.ɵccf("price-rules-dialog", i23.PriceRulesDialogComponent, View_PriceRulesDialogComponent_Host_0, {}, {}, []);
export { PriceRulesDialogComponentNgFactory as PriceRulesDialogComponentNgFactory };
