/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "./related-buttons.component";
import * as i8 from "../../../../../../components/dataset/services/dataset-record.service";
import * as i9 from "../../trip-booking-package.service";
var styles_TripBookingPackageRelatedButtonsComponent = [];
var RenderType_TripBookingPackageRelatedButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TripBookingPackageRelatedButtonsComponent, data: {} });
export { RenderType_TripBookingPackageRelatedButtonsComponent as RenderType_TripBookingPackageRelatedButtonsComponent };
function View_TripBookingPackageRelatedButtonsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tripBookingPackageService.openTripBookingRelatedDialog("trip_booking_resources", _co.tripBookingPackageRelatedDialogData, _co.viewContainerRef) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["class", "material-icons-outlined text-color-dark-blue"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["directions_bus_filled"])), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "span", [["class", "text-color-dark-blue ml-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Risorse")); _ck(_v, 5, 0, currVal_2); }); }
function View_TripBookingPackageRelatedButtonsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tripBookingPackageService.openTripBookingRelatedDialog("trip_booking_services", _co.tripBookingPackageRelatedDialogData, _co.viewContainerRef) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["class", "material-icons-outlined text-color-dark-blue"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["fact_check"])), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "span", [["class", "text-color-dark-blue ml-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Servizi")); _ck(_v, 5, 0, currVal_2); }); }
function View_TripBookingPackageRelatedButtonsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tripBookingPackageService.openTripBookingRelatedDialog("trip_booking_participant_extras", _co.tripBookingPackageRelatedDialogData, _co.viewContainerRef) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "span", [["class", "material-icons-outlined text-color-dark-blue"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["group"])), (_l()(), i0.ɵeld(4, 0, null, 0, 2, "span", [["class", "text-color-dark-blue ml-8"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Partecipanti")); _ck(_v, 5, 0, currVal_2); }); }
export function View_TripBookingPackageRelatedButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "flex-row items-center justify-end flex-gap-6"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripBookingPackageRelatedButtonsComponent_1)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripBookingPackageRelatedButtonsComponent_2)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripBookingPackageRelatedButtonsComponent_3)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showResources; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showServices; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showParticipants; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_TripBookingPackageRelatedButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "trip-booking-package-related-buttons", [], null, null, null, View_TripBookingPackageRelatedButtonsComponent_0, RenderType_TripBookingPackageRelatedButtonsComponent)), i0.ɵdid(1, 180224, null, 0, i7.TripBookingPackageRelatedButtonsComponent, [i8.DatasetRecordService, i9.TripBookingPackageService, i0.ViewContainerRef], null, null)], null, null); }
var TripBookingPackageRelatedButtonsComponentNgFactory = i0.ɵccf("trip-booking-package-related-buttons", i7.TripBookingPackageRelatedButtonsComponent, View_TripBookingPackageRelatedButtonsComponent_Host_0, { showResources: "showResources", showServices: "showServices", showParticipants: "showParticipants" }, {}, []);
export { TripBookingPackageRelatedButtonsComponentNgFactory as TripBookingPackageRelatedButtonsComponentNgFactory };
