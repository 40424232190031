import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/meetingPoints/dataset/trip_packages/:parentRecordId/detail/:recordId',
};

export const TripMeetingPointConfig: IDatasetConfig = {
	name: 'trip_meeting_points',
	title: 'Punti di ritrovo',
	singleTitle: 'Punto di ritrovo',
	ajaxDatasetCode: 'trip_meeting_points',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
	},
	pivot: {
		pivotCode: 'meetingPoints',
		foreignKey: 'meeting_point_id',
		parentDatasetCode: 'trip_packages'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'meeting_point.code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'meeting_point.description'
					}
				],
				actions: ['create', 'detail', 'delete'],
				itemActionsOrder: ['custom_0', 'delete'],
				customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'pageview',
						tooltip: 'Dettaglio punto di ritrovo',
						showIf(): boolean{ return true; },
						makeRouteLink(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): string{
							return datasetNavigatorService.getDetailRoute('meeting_points', record.id);
						}
					}
				]
			}
		}
	}
};
