<div class="mx-16">
	<mat-progress-bar
		class="mt-24 mb-24"
		style="min-width:300px"
		mode="indeterminate"
		*ngIf="datasetACS.loading.value || datasetRS.loading.value"
	></mat-progress-bar>

	<div
		class="mt-24"
		*ngIf="
			datasetACS.ready.value &&
			datasetRS.record.value &&
			datasetRS.record.value.entities_data
		"
	>
		<mat-tab-group>
			<mat-tab label="Cliente">
				<ng-template matTabContent>
					<ng-container
						*ngIf="datasetRS.record.value.entities_data.customer"
					>
						<dataset-edit
							datasetCode="entity_customers"
							[parentDatasetRS]="datasetRS"
							[parentDatasetACS]="datasetACS"
							[inputRecordId]="
								datasetRS.record.value.entities_data.customer
							"
							[showTitle]="false"
							[autoFetchData]="true"
							[routed]='false'
						></dataset-edit>
					</ng-container>
					<ng-container
						*ngIf="!datasetRS.record.value.entities_data.customer"
					>
						<p>{{'Abilitare questa anagrafica come cliente?' | translate}}</p>
						<button
							[disabled]="datasetACS.loading.value"
							mat-flat-button
							color="accent"
							(click)="createEntityType('customer')"
						>
							Si
						</button>
					</ng-container>
				</ng-template>
			</mat-tab>
			<mat-tab label="Tour Operator">
				<ng-template matTabContent>
					<ng-container
						*ngIf="
							datasetRS.record.value.entities_data.tour_operator
						"
					>
						<p>{{'Questa anagrafica è abilitata come tour operator' | translate}}</p>
						<!--<dataset-edit
							datasetCode='entity_tour_operators'
							[parentDatasetRS]='datasetRS'
							[parentDatasetACS]='datasetACS'
							[showTitle]='false'
						></dataset-edit>-->
					</ng-container>
					<ng-container
						*ngIf="
							!datasetRS.record.value.entities_data.tour_operator
						"
					>
						<p>{{'Abilitare questa anagrafica come tour operator?' | translate}}</p>
						<button
							[disabled]="datasetACS.loading.value"
							mat-flat-button
							color="accent"
							(click)="createEntityType('tour_operator')"
						>
							Si
						</button>
					</ng-container>
				</ng-template>
			</mat-tab>
			<mat-tab label="Fornitore">
				<ng-template matTabContent>
					<ng-container
						*ngIf="datasetRS.record.value.entities_data.supplier"
					>
						<dataset-edit
							datasetCode="entity_suppliers"
							[parentDatasetRS]="datasetRS"
							[parentDatasetACS]="datasetACS"
							[inputRecordId]="
								datasetRS.record.value.entities_data.supplier
							"
							[showTitle]="false"
							[autoFetchData]="true"
							[routed]='false'
						></dataset-edit>
					</ng-container>
					<ng-container
						*ngIf="!datasetRS.record.value.entities_data.supplier"
					>
						<p>{{'Abilitare questa anagrafica come fornitore?' | translate}}</p>
						<button
							[disabled]="datasetACS.loading.value"
							mat-flat-button
							color="accent"
							(click)="createEntityType('supplier')"
						>
							Si
						</button>
					</ng-container>
				</ng-template>
			</mat-tab>
			<mat-tab label="Staff">
				<ng-template matTabContent>
					<ng-container
						*ngIf="
							datasetRS.record.value.entities_data.staff_element
						"
					>
						<dataset-edit
							[autoFetchData]="true"
							datasetCode="entity_staff_elements"
							[parentDatasetRS]="datasetRS"
							[parentDatasetACS]="datasetACS"
							[inputRecordId]="
								datasetRS.record.value.entities_data
									.staff_element
							"
							[showTitle]="false"
							[routed]='false'
						></dataset-edit>

						<!-- utenti staff -->
						<dataset-list
							[datasetCode]='"entity_staff_users"'
							[parentDatasetACS]='datasetACS'
							[parentDatasetRS]='datasetRS'
							[elevate]='false'
							[showExportBtn]='false'
							[enableCreate]='true'
							[enableEdit]='true'
							[enableDelete]='true'
							[autoFetchData]='false'
							[filters]='{
								entity_id: datasetRS.recordId, 
								entity_staff_element_id: datasetRS.record.value.entities_data.staff_element
							}'
						></dataset-list>
					</ng-container>
					<ng-container
						*ngIf="
							!datasetRS.record.value.entities_data.staff_element
						"
					>
						<p>{{'Abilitare questa anagrafica come staff?' | translate}}</p>
						<button
							[disabled]="datasetACS.loading.value"
							mat-flat-button
							color="accent"
							(click)="createEntityType('staff_element')"
						>
							{{'Si' | translate}}
						</button>
					</ng-container>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
