import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { fieldsMap as userFieldMap} from '../users/fields';

const userCreatefieldsMap: Map<string, IFieldDefinition> = new Map();

userCreatefieldsMap.set('domain_id', {
	key: 'domain_id',
	name: 'Dominio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domains',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			const domainId = dataContainerService.domainFilterService.filterDomainId.value;
			if (domainId) {
				return {domain_id: domainId};
			}
			return {};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.display_name;
		}
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
		return !dataContainerService.domainFilterService.filterDomainId.value && !domainId;
	}
});

userCreatefieldsMap.set('domain_user_role_id', {
	key: 'role_id',
	name: 'Ruolo',
	inputType: InputTypes.DATASET_SELECT_DEP_DROPDOWN,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domain_user_roles',
	dependOn: 'domain_id',
	fetchOnLoad: false,
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			const domainId = dataContainerService.domainFilterService.filterDomainId.value;
			if (domainId) {
				return {domain_id: domainId};
			}
			return {};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		}
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
		return !dataContainerService.domainFilterService.filterDomainId.value && !domainId;
	}
});

userCreatefieldsMap.set('internal_branch_id', {
	key: 'branch_id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT_DEP_DROPDOWN,
		clearable: true
	},
	dependOn: 'domain_id',
	fetchOnLoad: false,
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
			if (domainId) {
				return {domain_id: domainId};
			}
			return {};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
		return !dataContainerService.domainFilterService.filterDomainId.value && !domainId;
	}
});

userCreatefieldsMap.set('domain_user_role_id_detail', {
	key: 'role_id',
	name: 'Ruolo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domain_user_roles',
	dependOn: 'domain_id',
	fetchOnLoad: false,
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			const domainIdDetail = dataContainerService.getValueFromKeyPath('domains.recordId');
			if (domainIdDetail) {
				return {domain_id: domainIdDetail};
			}
			const domainId = dataContainerService.domainFilterService.filterDomainId.value;
			if (domainId) {
				return {domain_id: domainId};
			}
			return {};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		}
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
		return dataContainerService.domainFilterService.filterDomainId.value || domainId;
	}
});

userCreatefieldsMap.set('internal_branch_id_detail', {
	key: 'branch_id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	dependOn: 'domain_id',
	fetchOnLoad: false,
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			const domainIdDetail = dataContainerService.getValueFromKeyPath('domains.recordId');
			if (domainIdDetail) {
				return {domain_id: domainIdDetail};
			}
			const domainId = dataContainerService.domainFilterService.filterDomainId.value;
			if (domainId) {
				return {domain_id: domainId};
			}
			return {};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		const domainId = dataContainerService.getValueFromKeyPath('domains.recordId');
		return dataContainerService.domainFilterService.filterDomainId.value || domainId;
	}
});

userFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: value.key });
	userCreatefieldsMap.set(key, value);
});

export {userCreatefieldsMap};
