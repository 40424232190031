import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import participantFormConfig from './forms/participant.from';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_booking_resource_participant_extras/:recordId',
};

export const TripBookingResourceParticipantExtraConfig: IDatasetConfig = {
	name: 'trip_booking_resource_participant_extras',
	title: 'Dati Partecipanti',
	singleTitle: 'Dati Partecipante',
	ajaxDatasetCode: 'trip_booking_resource_participant_extras',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		participantFormConfig
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: participantFormConfig,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: participantFormConfig
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: participantFormConfig
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Stato',
						key: 'status'
					},
					{
						title: 'Partecipanti',
						key: 'participants'
					},
					{
						title: 'Total',
						key: 'total_amount'
					}
				],
				actions: []
			}
		}
	}
};
