import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import filterForm from './forms/filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';


export const BokunTourConfig: IDatasetConfig = {
	name: 'bokun_tours',
	title: 'Tours',
	singleTitle: 'Tour',
	ajaxDatasetCode: 'bokun_tours',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				columns: [
					{
						title: 'Vendor',
						key: 'vendor.title'
					},
					{
						title: 'title',
						key: 'title'
					},
					{
						title: 'A partire da',
						key: 'price'
					},
					{
						title: 'Descrizione',
						key: 'summary',
						fieldView: FieldViews.DIALOG_TEXT,
						fieldDefinition: {
							name: 'Descrizione',
							key: 'summary',
							valueType: ValueTypes.STRING,
							fieldView: FieldViews.DIALOG_TEXT,
							prefixIcon: 'note'
						}
					}
				],
				actions: [],
				customActions: [
					{
						buttonColor: 'default',
						showIf(record): boolean{ return record && record.allowed_actions && !record.allowed_actions.includes('resell') && !record.allowed_actions.includes('no_permission');; },
						actionCode: null,
						buttonText: 'Già in rivendita',
					},
					{
						buttonColor: 'warn',
						showIf(record): boolean{ return record && record.allowed_actions && record.allowed_actions.includes('no_permission'); },
						actionCode: null,
						buttonText: 'Nessun permesso',
					},
					{
						buttonColor: 'accent',
						showIf(record): boolean{
							return record && record.allowed_actions && record.allowed_actions.includes('resell');
						},
						actionCode: 'resell',
						buttonText: 'Rivendi',
						onClick(event, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.dialog.open(ConfirmDialogComponent, {
								minWidth: 550,
								data: {
									title: 'Conferma',
									contentMessage: 'Vuoi rivendere questo Tour ?'
								}
							}).afterClosed()
							.subscribe(confirmed => {
								if(!confirmed) return;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									minWidth: 550,
									data: {
										title: 'Creazione Pacchetto in corso'
									}
								});
								
								datasetNavigatorService.datasetService.post<any>('/dataset/bokun_tours/command/create_trip_package', {
									tour_id: record.id,
									title: record.title,
									tour: record
								})
								.subscribe({
									next: () => {
										dialogRef.close();
										if(dataContainerService instanceof DatasetActionContainerService){
											dataContainerService.datasetEvent.next({ 
												eventName: DatasetEvents.ACTION_REALOAD_LIST 
											});
										}
										datasetNavigatorService.appService.showSuccessMessage('Pacchetto creato');
									},
									error: () => {
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.setMessage('Si è verificato un errore');
									}
								});
							});
						}
					}
				]
			}
		}
	}
};
