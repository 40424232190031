import { ComponentType, Overlay, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { InjectionToken, Injector, TemplateRef, ElementRef } from '@angular/core';
import { PopoverRef } from './popover-ref';
import { PopoverComponent } from './popover-container/popover-container.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
/**
 * Injection token that can be used to access the data that was passed in to a popover.
 * */
export var POPOVER_DATA = new InjectionToken('popover.data');
var defaultConfig = {
    hasBackdrop: true,
    backdropClass: '',
    disableClose: false,
    panelClass: '',
    arrowOffset: 30,
    arrowSize: 20
};
/**
 * Service to open modal and manage popovers.
 */
var PopoverService = /** @class */ (function () {
    function PopoverService(overlay, injector) {
        this.overlay = overlay;
        this.injector = injector;
    }
    PopoverService.prototype.open = function (componentOrTemplate, target, config) {
        if (config === void 0) { config = {}; }
        var popoverConfig = Object.assign({}, defaultConfig, config);
        var arrowSize = popoverConfig.arrowSize;
        var arrowOffset = popoverConfig.arrowOffset;
        var panelOffset = arrowSize / 2;
        // preferred positions, in order of priority
        var positions = [
            // bottom left
            {
                overlayX: 'start',
                overlayY: 'top',
                originX: 'center',
                originY: 'bottom',
                panelClass: ['top', 'left'],
                offsetX: -1 * arrowOffset,
                offsetY: panelOffset
            }
        ];
        var positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(target)
            .withPush(false)
            .withFlexibleDimensions(false)
            .withPositions(positions);
        var overlayRef = this.overlay.create({
            minWidth: 500,
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            positionStrategy: positionStrategy,
            scrollStrategy: this.overlay.scrollStrategies.reposition()
        });
        var popoverRef = new PopoverRef(overlayRef, positionStrategy, popoverConfig);
        var popover = overlayRef.attach(new ComponentPortal(PopoverComponent, null, new PortalInjector(this.injector, new WeakMap([
            [PopoverRef, popoverRef]
        ])))).instance;
        if (componentOrTemplate instanceof TemplateRef) {
            // rendering a provided template dynamically
            popover.attachTemplatePortal(new TemplatePortal(componentOrTemplate, null, {
                $implicit: config.data,
                popover: popoverRef
            }));
        }
        else {
            // rendering a provided component dynamically
            popover.attachComponentPortal(new ComponentPortal(componentOrTemplate, null, new PortalInjector(this.injector, new WeakMap([
                [POPOVER_DATA, config.data],
                [PopoverRef, popoverRef]
            ]))));
        }
        return popoverRef;
    };
    PopoverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopoverService_Factory() { return new PopoverService(i0.ɵɵinject(i1.Overlay), i0.ɵɵinject(i0.INJECTOR)); }, token: PopoverService, providedIn: "root" });
    return PopoverService;
}());
export { PopoverService };
