import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators, FormGroup } from '@angular/forms';

let domainId: any;
const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('access_id', {
	key: 'access_id',
	name: 'ID Accesso',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('access_username', {
	key: 'access_username',
	name: 'Username',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Sono accettati solo testo minuscolo, numeri e i caratteri - _'
});

fieldsMap.set('password_input', {
	key: 'password_input',
	name: 'Password',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING
});

/*fieldsMap.set('branch_name', {
	key: 'branch_name',
	name: 'Filiale',
	valueType: ValueTypes.STRING,
	inputConfig: {
		readOnly: true
	}
});

fieldsMap.set('branch_code', {
	key: 'branch_code',
	name: 'Filiale',
	valueType: ValueTypes.STRING,
	inputConfig: {
		readOnly: true
	}
});*/

fieldsMap.set('password_confirm', {
	key: 'password_confirm',
	name: 'Conferma Password',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('email', {
	key: 'email',
	name: 'Email',
	inputType: InputTypes.EMAIL,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('role_id', {
	key: 'role_id',
	name: 'Ruolo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputRequired: true,
	formValidators: [Validators.required],
	datasetCode: 'user_roles',
	remoteOptions: {
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return null;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name;
		}
	}
});

fieldsMap.set('role.name', {
	key: 'role.name',
	name: 'Ruolo',
	valueType: ValueTypes.STRING
});

fieldsMap.set('branch_id', {
	key: 'branch_id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if(!option) return '';
			return option.code;
		},
		getValue(option): any {
			if(!option) return;
			return option.id;
		}
	}
});

export {fieldsMap};
