import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import {DefaultDatasetPageComponent} from './default-page.component';
import {DatasetModule} from 'app/main/components/dataset/dataset.module';

@NgModule({
	declarations: [
		DefaultDatasetPageComponent
	],
	imports     : [
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatDialogModule,

		FuseSharedModule,
		TranslateModule.forChild(),
		DatasetModule
	],
	exports: [
		DefaultDatasetPageComponent
	]
})
export class DefaultDatasetPageModule{}
