import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice Risorsa',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});


fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('level', {
	key: 'level',
	name: 'Livello',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Un livello < 10 conferisce pieni poteri',
	defaultValue(): number{
		return 100;
	}
});

export {fieldsMap};
