<h2>{{'Partecipanti' | translate}}</h2>

<!-- FORM GENERAZIONE PARTECIPANTI -->
<div class="participants-form" *ngIf="!record.ref_id">
	<mat-progress-bar mode="indeterminate" *ngIf='loading'></mat-progress-bar>
	<!--<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<form autocomplete="off" name='form' *ngIf='formViewerService.initialized && formViewerService.formGroup' [formGroup]="formViewerService.formGroup">
			<div class="participants-row">

				<div class="adt_counter counter">
					<h4>{{ 'Adulti' | translate }}</h4>
					<div>
						<div class="decrement" (click)='decrement("adt_counter")'>
							<span class="material-icons-outlined">
								remove_circle
							</span>
						</div>
						<form-input-viewer
							[formInputDefinition]='filterFields.get("adt_counter")'
							[formGroup]='formViewerService.formGroup'
							[formControlKey]='formViewerService.controlKeyMap["adt_counter"]'
							[dataContainerService]='datasetACS'
							[data]='{adt_counter: 0}'
						></form-input-viewer>
						<div class="increment" (click)='increment("adt_counter")'>
							<span class="material-icons-outlined">
								add_circle
							</span>
						</div>
					</div>
				</div>

				<div class="chd_counter counter">
					<h4>{{ 'Bambini' | translate }}</h4>
					<div>
						<div class="decrement" (click)='decrement("chd_counter")'>
							<span class="material-icons-outlined">
								remove_circle
							</span>
						</div>
						<form-input-viewer
							[formInputDefinition]='filterFields.get("chd_counter")'
							[formGroup]='formViewerService.formGroup'
							[formControlKey]='formViewerService.controlKeyMap["chd_counter"]'
							[dataContainerService]='datasetACS'
							[data]='{adt_counter: 0}'
						></form-input-viewer>
						<div class="increment" (click)='increment("chd_counter")'>
							<span class="material-icons-outlined">
								add_circle
							</span>
						</div>
					</div>
				</div>

				<div class="inf_counter counter">
					<h4>{{ 'Neonati' | translate }}</h4>
					<div>
						<div class="decrement" (click)='decrement("inf_counter")'>
							<span class="material-icons-outlined">
								remove_circle
							</span>
						</div>
						<form-input-viewer
							[formInputDefinition]='filterFields.get("inf_counter")'
							[formGroup]='formViewerService.formGroup'
							[formControlKey]='formViewerService.controlKeyMap["inf_counter"]'
							[dataContainerService]='datasetACS'
							[data]='{adt_counter: 0}'
						></form-input-viewer>
						<div class="increment" (click)='increment("inf_counter")'>
							<span class="material-icons-outlined">
								add_circle
							</span>
						</div>
					</div>
				</div>

				<div class="snr_counter counter">
					<h4>{{ 'Anziani' | translate }}</h4>
					<div>
						<div class="decrement" (click)='decrement("snr_counter")'>
							<span class="material-icons-outlined">
								remove_circle
							</span>
						</div>
						<form-input-viewer
							[formInputDefinition]='filterFields.get("snr_counter")'
							[formGroup]='formViewerService.formGroup'
							[formControlKey]='formViewerService.controlKeyMap["snr_counter"]'
							[dataContainerService]='datasetACS'
							[data]='{adt_counter: 0}'
						></form-input-viewer>
						<div class="increment" (click)='increment("snr_counter")'>
							<span class="material-icons-outlined">
								add_circle
							</span>
						</div>
					</div>
				</div>

			</div>

			<div class="add-group-participants">
				<div class="nation">
					<form-input-viewer
						[formInputDefinition]='filterFields.get("nation_code")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["nation_code"]'
						[dataContainerService]='datasetACS'
					></form-input-viewer>
				</div>
				<div class='action-add-participant'>
					<button [disabled]='!formViewerService.formGroup.valid || loading' mat-flat-button (click)='onAddParticipants()'>
						<i *ngIf='!loading' class="material-icons">add</i> 
						<span *ngIf='!loading'>{{'Aggiungi' | translate}}</span>
						<span *ngIf='loading'>Azione in corso ...</span>
					</button>
				</div>
			</div>
		</form>
	</div>-->
</div>

<dataset-list
	[emitAdd]='true'
	[elevate]='false'	
	[showSearchBar]='false'
	[showTitle]='false'
	[tableStyle]='"style-2"'
	datasetCode='trip_booking_participants'
	[parentDatasetACS]='datasetACS'
	[parentDatasetRS]='datasetRS'
	[filters]='{ trip_booking_id: record.id }'
	(datasetEvent)='onListDatasetEvent($event)'
	(add)='onAdd()'
	[enableCreate]='bookingService.canEdit && !record.ref_id'
	[enableEdit]='bookingService.canEdit'
	[enableDelete]='bookingService.canEdit && !record.ref_id'
></dataset-list>