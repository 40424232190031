import { Observable, Subject } from 'rxjs';
import { IFormDialogData, FormDialogComponent } from '../dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';
import { DatasetService } from '../components/dataset/services/dataset.service';
import { LoadingDialogComponent } from '../dialogs/loading-dialog/dialog.component';

export async function action_edit_password(record: any, datasetService: DatasetService, data?: any): Promise<any>{
	const dialogRef = datasetService.matDialog.open<any, IFormDialogData, any>(FormDialogComponent, {
		panelClass: 'custom-dialog-container',
		minWidth: 400,
		data: {
			formData: data || {
				old_password: null,
				password: null,
				confirmation_password: null
			},
			title: 'Modifica password',
			formConfig: {
				fields: [
					{
						key: 'old_password',
						name: 'Password corrente',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.PASSWORD
						},
					},
					{
						key: 'password',
						name: 'Nuova password',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.PASSWORD
						},
					},
					{
						key: 'password_confirmation',
						name: 'Conferma password',
						valueType: ValueTypes.STRING,
						inputRequired: true,
						inputConfig: {
							type: InputTypes.PASSWORD
						},
					}
				]
			},
		}
	});
	// after closed
	const formResult = await dialogRef.afterClosed().toPromise();
	if(!formResult) return;
	if (!formResult.old_password || !formResult.password || !formResult.password_confirmation){
		datasetService.appService.showErrorMessage('Dati insufficienti.');
		// reopen dialog
		return action_edit_password(record, datasetService);
	}

	const loadingDialogRef2 = datasetService.matDialog.open(LoadingDialogComponent, {
		data: {
			title: 'Creazione in corso'
		}
	});

	try{
		const response = await datasetService.post<any>('/auth/me/edit-password', {
			id: record.id,
			old_password: formResult.old_password,
			password: formResult.password,
			password_confirmation: formResult.password_confirmation
		}).toPromise();

		let responseMessage = '';
		if(response.message){
			responseMessage = response.message;
		}
		if(response.success){
			datasetService.appService.showSuccessMessage(responseMessage || 'Modifica avventuta con successo');
		}else{
			if(!responseMessage) responseMessage = 'Si è verificato un problema durante la richiesta dell\'utente.';
			datasetService.appService.showErrorMessage(responseMessage);
		}
		loadingDialogRef2.close();
		return response.success;
	}catch(e){
		let errorMessage = 'Si è verificato un errore';
		if(e.error && e.error.errors){
			let keyIndex = 0;
			const keys = Object.keys(e.error.errors);
			let key = keys[keyIndex];
			while(keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e.error.errors, key)){
				keyIndex++;
				key = keys[keyIndex];
			}
			if(e.error.errors[key]){
				if(Array.isArray(e.error.errors[key])) errorMessage = e.error.errors[key][0];
				else if(typeof(e.error.errors[key]) === 'string') errorMessage = e.error.errors[key];
			}
		}
		datasetService.appService.showErrorMessage(errorMessage);
		loadingDialogRef2.close();
		return action_edit_password(record, datasetService, {
			old_password: formResult.old_password,
			password: formResult.password,
			password_confirmation: formResult.password_confirmation
		});
	}
}
