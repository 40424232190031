<h1 *ngIf='!loading && data.title' mat-dialog-title>{{data.title | translate}}</h1>
<h1 *ngIf='loading' mat-dialog-title>{{'Recupero dati in corso' | translate}}</h1>
<mat-dialog-content>

	<ng-container *ngIf='!loading'>
		<ng-container [ngSwitch]='data.component'>
			<trip-package-overview 
				*ngSwitchCase='"trip-package-overview"'
			></trip-package-overview>
		</ng-container>
	</ng-container>

	<div *ngIf='loading' class="spinner" fxLayout='row' fxLayoutAlign='center center'>
		<mat-spinner></mat-spinner>
	</div>

</mat-dialog-content>

<mat-dialog-actions align='end' *ngIf="!loading">
	<button mat-flat-button (click)='onYesClick()' color='accent' >{{data.positiveText?data.positiveText:'chiudi' | translate}}</button>
</mat-dialog-actions>
