export const locale = {
	lang: 'it',
	data: {
		'LOGIN': {
			'TITLE': 'Accedi',
			'REMEMBER_ME': 'Ricordami',
			'FORGOT_PASSWORD': 'Password dimenticata?',
			'ERROR': {
				'PASSWORD_REQUIRED': 'Inserisci la password',
				'CODE_REQUIRED': 'Credenziali non valide',
				'INVALID_CODE': 'Credenziali non valide'
			}
		},
		'NAV': {
			'HOME': {
				'TITLE': 'Dashboard'
			},
			'CALENDAR': {
				'TITLE': 'Calendario'
			},
			'REVIEWS': {
				'TITLE': 'Recensioni'
			},
			'PACKAGES': {
				'TITLE': 'Pacchetti'
			},
			'PRODUCTS': {
				'TITLE': 'Prodotti'
			},
			'RESOURCES': {
				'TITLE': 'Risorse'
			},
			'DOCUMENTS': {
				'TITLE': 'Documenti'
			},
			'STATISTICS': {
				'TITLE': 'BI Statistiche'
			},
			'BOOKINGS': {
				'TITLE': 'Prenotazioni',

				'NEWBOOKING' : {
					'TITLE': 'Nuova Prenotazione'
				}
			},
			'MEETING_POINTS': {
				'TITLE': 'Punti di ritrovo'
			},
			'DESTINATIONS': {
				'TITLE': 'Destinazioni'
			},
			'CATEGORIES': {
				'TITLE': 'Categorie'
			},
			'INFO': {
				'TITLE': 'Informazioni Utili'
			},
			'PRICES_SERVICES': {
				'TITLE': 'Servizi & Prezzi'
			},
			'ENTITIES': {
				'TITLE': 'Anagrafiche',

				'CUSTOMERS': {
					'TITLE': 'Clienti'
				},
				'SUPPLIERS': {
					'TITLE': 'Fornitori'
				},
				'TOUR_OPERATORS': {
					'TITLE': 'Tour Operator'
				},
				'STAFF': {
					'TITLE': 'Staff'
				},
				'ALL': {
					'TITLE': 'Tutte'
				}
			},
			'MARKETPLACE': {
				'TITLE': 'Marketplace',

				'TANGERINE': {
					'TITLE': 'Tangerine'
				},
				'TOURCMS': {
					'TITLE': 'Tourcms'
				},
				'BOKUN': {
					'TITLE': 'Bokun'
				},
				'TICKETLANDIA': {
					'TITLE': 'Ticketlandia',
				},
			},
			'PLAFOND': {
				'TITLE': 'Plafond',
			},
			'DEFINITIONS': {
				'TITLE': 'Definizioni',
			},
			'DOMAINS_GROUP': {
				'TITLE': 'Gruppo Domini',

				'DOMAINS': {
					'TITLE': 'Domini'
				},
				'USER_DOMAINS': {
					'TITLE': 'Utenti Domini'
				},
				'BRANCHES': {
					'TITLE': 'Filiali'
				},
				'PAYMENTS_TYPE': {
					'TITLE': 'Metodi di pagamento'
				},
				'REQUESTS_TRANSFERS': {
					'TITLE': 'Richieste/Cessioni'
				}
			},
			'ADMIN_GROUP': {
				'TITLE': 'Super Admin',

				'USERS': {
					'TITLE': 'Utenti'
				},
				'DOMAIN_INFO': {
					'TITLE': 'Informazioni sui domini',
				},
				'DASHBOARD_TOTEM': {
					'TITLE': 'Dashboard totem',
				},
				'TURNOVER_REPORT': {
					'TITLE': 'Report Fatturato'
				},
				'PRACTICES': {
					'TITLE': 'Pratiche'
				},
				'TRANSMISSIONS': {
					'TITLE': 'Trasmissioni corrispettivi'
				},
				'UTILITIES': {
					'TITLE': 'Utilities'
				},
				'API': {
					'TITLE': 'Generazione credenziali API'
				},
				'LOGS': {
					'TITLE': 'Logs'
				}
			},
			'CRITERIA': {
				'TITLE': 'Criteri',
			},
			'SERVICES': {
				'TITLE': 'Servizi',
			},
			'PRICE_LISTS': {
				'TITLE': 'Listini Sconti'
			},
			'DISCOUNTS': {
				'TITLE': 'Sconti',
			}
		},

		// common words
		'DETAIL': 'dettaglio',
		'LIST': 'lista',
		'WELCOME_BACK': 'Ben tornato',
	}
};

