import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ListViewExportTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import createForm from './forms/create.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { action_edit_password } from 'app/main/actions/edit-password.action';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/users/detail/:recordId',
	/*params: {
		with_attributes: 'branch_name,branch_code'
	}*/
};

export const UserConfig: IDatasetConfig = {
	name: 'users',
	title: 'Utenti',
	singleTitle: 'Utente',
	ajaxDatasetCode: 'users',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			/*getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
				params['with_attributes'] = 'branch_name,branch_code';
				return params;
			},*/
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: createForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			/*getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
				params['with_attributes'] = 'branch_name,branch_code';
				return params;
			},*/
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'name',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'ID Accesso',
						key: 'access_id',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Username',
						key: 'access_username',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Nome',
						key: 'name',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Ruolo',
						key: 'role.name',
						onClick(record, resourceACS, resourceNavigatorService): void{
							if(!record || !record.role) return;
							resourceNavigatorService
								.showDatasetDetail('user_roles', null, record.role.id);
						}
					}
					/*{
						title: 'Filiale',
						key: 'branch_code'
					}*/
				],
				actions: ['create', 'detail', 'edit', 'delete'],
				itemActionsOrder: ['create', 'detail', 'edit', 'custom_0', 'delete'],
				customActions: [
					{
						buttonIcon: 'settings',
						buttonColor: 'accent',
						tooltip: 'Modifica password',
						showIf(): boolean {
							return true;
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							action_edit_password(record, datasetNavigatorService.datasetService);
						},
					},
				]
			}
		}
	}
};
