import { OverlayModule } from "@angular/cdk/overlay";
import { NgModule } from "@angular/core";
import { MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatStepperModule, MatProgressBarModule, MatTabsModule, MatTableModule, MatListModule, MatToolbarModule, MatCardModule, MatTooltipModule, MatBadgeModule, MatChipsModule, MatMenuModule, MatDatepickerModule, MatProgressSpinnerModule, MatButtonToggleModule } from "@angular/material";
import { FuseSidebarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { DatasetModule } from "app/main/components/dataset/dataset.module";
import { FormViewerModule } from "app/main/components/form-viewer/form-viewer.module";
import { FormDialogsModule } from "app/main/dialogs/form-dialog/dialog.module";
import { PopoverModule } from "app/main/dialogs/popover/popover.module";
import { TripBookingPackagesStepComponent } from "./trip-booking-packages-step.component";
import { ActionDialogModule } from "../action-dialog/action-dialog.module";
import { ParticipantsFormComponent } from "./participants-form/participants-form.component";
import { PackageTripSelectionComponent } from "./new-trip-booking-package-selection/package-trip-selection/package-trip-selection.component";
import { NewTripBookingPackageSelectionComponent } from "./new-trip-booking-package-selection/new-trip-booking-package-selection.component";
import { ProductSelectionComponent } from "./new-trip-booking-package-selection/product-selection/product-selection.component";
import { TrenitaliaCarnetModule } from "../trenitalia-dialog/trenitalia-carnet/trenitalia-carnet.module";
import { TrenitaliaDialogModule } from "../trenitalia-dialog/trenitalia-dialog.module";
import { TripBookingPackageModule } from "./trip-booking-package/trip-booking-package.module";
import { MilanoCardSelectionComponent } from "./new-trip-booking-package-selection/milano-card-selection/milano-card-selection.component";

@NgModule({
	declarations: [
		TripBookingPackagesStepComponent,
		ParticipantsFormComponent,
		PackageTripSelectionComponent,
		ProductSelectionComponent,
		MilanoCardSelectionComponent,
		NewTripBookingPackageSelectionComponent,
	],
	imports: [
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatStepperModule,
		MatProgressBarModule,
		FormViewerModule,
		FuseSharedModule,
		FuseSidebarModule,
		MatTabsModule,
		MatTableModule,
		MatListModule,
		DatasetModule,
		MatToolbarModule,
		MatButtonToggleModule,
		MatCardModule,
		MatTooltipModule,
		MatBadgeModule,
		MatChipsModule,
		MatMenuModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
		FormDialogsModule,
		OverlayModule,
		PopoverModule,
		ActionDialogModule,
		TrenitaliaCarnetModule,
		TrenitaliaDialogModule,
		TripBookingPackageModule,
		TranslateModule.forChild()
	],
	exports: [
		TripBookingPackagesStepComponent
	]
})
export class TripBookingPackagesStepModule{}
