import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { BaseDatasetActionDialog } from './../../base-action-dialog.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
var DatasetRestoreDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetRestoreDialogComponent, _super);
    function DatasetRestoreDialogComponent(datasetACS, appService, datasetService, dialogRef, data) {
        var _this = _super.call(this, datasetACS, appService, datasetService, dialogRef, data) || this;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.actionCode = 'restore';
        _this.message = 'Vuoi ripristinare questo record?';
        if (data.message) {
            _this.message = data.message;
        }
        return _this;
    }
    DatasetRestoreDialogComponent.prototype.onRestore = function () {
        var _this = this;
        this.loading = true;
        this.datasetService.post(this.datasetACS.getRestoreRoute(this.data.record))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.loading = false;
                _this.appService.showSuccessMessage('Ripristinato con successo');
                _this.dialogRef.close(true);
            },
            error: function (err) {
                _this.deleteError = err;
                _this.loading = false;
            }
        });
    };
    return DatasetRestoreDialogComponent;
}(BaseDatasetActionDialog));
export { DatasetRestoreDialogComponent };
