var _a;
import * as tslib_1 from "tslib";
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import confirmAction from './confirm.action';
import { DomainPackageState } from './status.enum';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import newCustomerForm from './forms/new-customer.form';
import setPlafond from './plafond.action';
import requirePlafondData from 'app/main/actions/require-plafond.action';
var CONFIRM_MESSAGES = (_a = {},
    _a[DomainPackageState.REFUSED] = 'Sei sicuro di voler rifiutare la richiesta?',
    _a[DomainPackageState.PENDING] = 'Sei sicuro di voler riformulare la richiesta?',
    _a[DomainPackageState.RETIRED] = 'Sei sicuro di voler ritirare la richiesta?',
    _a[DomainPackageState.CANCELED] = 'Sei sicuro di voler annullare la rivendita?',
    _a);
function requireCustomerData(dataContainerService, datasetNavigator, errors) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef, res;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dialogRef = datasetNavigator.openDialog(FormDialogComponent, {
                        title: 'Seleziona un listino',
                        dataContainerService: dataContainerService,
                        formConfig: newCustomerForm,
                        formErrors: errors
                    });
                    return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                case 1:
                    res = _a.sent();
                    if (!res)
                        return [2 /*return*/];
                    return [2 /*return*/, {
                            unified_list_id: res.unified_list_id,
                            payment_type_id: res.payment_type_id
                        }];
            }
        });
    });
}
function changeStatus(record, dataContainerService, datasetNavigator, newStatus, customerData) {
    datasetNavigator.openDialog(ConfirmDialogComponent, {
        title: 'Conferma',
        contentMessage: CONFIRM_MESSAGES[newStatus]
    })
        .afterClosed()
        .subscribe(function (res) {
        if (!res)
            return;
        var addLog = true;
        if (!record.package)
            addLog = false;
        var dialogRef = datasetNavigator.openDialog(ActionDialogComponent, {
            title: 'Attendere',
            contentMessage: ''
        });
        datasetNavigator.datasetService.post('/dataset/domain_packages/command/set_state', Object.assign({
            'id': record.id,
            'status': newStatus,
            'add_log': addLog
        }, customerData))
            .subscribe({
            next: function () {
                dialogRef.close();
                if (dataContainerService instanceof DatasetActionContainerService) {
                    dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
                }
            },
            error: function (e) {
                if (dataContainerService instanceof DatasetActionContainerService
                    && e.error
                    && (e.error.message === 'seleziona-listino'
                        || (e.error.action_required
                            && e.error.action_required.code === 'new-customer-data'))) {
                    requireCustomerData(dataContainerService, datasetNavigator, e.error.errors)
                        .then(function (newCustomerData) {
                        if (!newCustomerData)
                            return; // cancel the operation
                        changeStatus(record, dataContainerService, datasetNavigator, newStatus, newCustomerData);
                    }, function (error) {
                        // ignore
                    });
                }
                else {
                    var dialogInstance = dialogRef.componentInstance;
                    if (dialogInstance instanceof ActionDialogComponent) {
                        dialogInstance.resultStatus = 'error';
                        dialogInstance.setMessage('Si è verificato un errore imprevisto.');
                        dialogInstance.loading = false;
                    }
                }
            }
        });
    });
}
export default [
    {
        buttonIcon: 'done',
        actionCode: 'allow',
        buttonColor: 'accent',
        tooltip: 'Accetta',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            var data = {
                id: record.id,
                status: DomainPackageState.ACCEPTED,
            };
            confirmAction(data, dataContainerService, datasetNavigator)
                .then(function (result) {
                if (!result.success || !result.with_plafond_permission)
                    return;
                var confirmDialog = datasetNavigator.openDialog(ConfirmDialogComponent, {
                    title: 'Plafond',
                    contentMessage: 'Vuoi associare un plafond alla richiesta?'
                })
                    .afterClosed()
                    .subscribe({
                    next: function (confirm) {
                        if (!confirm)
                            return;
                        requirePlafondData(null, dataContainerService, datasetNavigator, null, {
                            domainPackageId: record.id
                        }).then(function (data) {
                            setPlafond(datasetNavigator, data, record).then(function (resp) {
                                if (!resp)
                                    return;
                                if (dataContainerService instanceof DatasetActionContainerService) {
                                    dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
                                }
                            });
                        }, function (error) {
                            // ignore
                        });
                    },
                    error: function (exc) {
                        console.error(exc);
                    }
                });
            });
        },
        showIf: function (record) {
            return true;
        }
    },
    {
        buttonIcon: 'block',
        actionCode: 'deny',
        buttonColor: 'warn',
        tooltip: 'Rifiuta',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.REFUSED);
        },
        showIf: function (record) {
            return true;
        }
    },
    {
        buttonIcon: 'autorenew',
        actionCode: 'reformulates',
        buttonColor: 'warn',
        tooltip: 'Riformula richiesta',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.PENDING);
        },
        showIf: function (record) {
            return true;
        }
    },
    {
        buttonIcon: 'remove_circle',
        actionCode: 'cancel',
        buttonColor: 'warn',
        tooltip: 'Cancella',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.CANCELED);
        },
        showIf: function () {
            return true;
        }
    },
    {
        buttonIcon: 'remove_circle',
        actionCode: 'retire',
        buttonColor: 'warn',
        tooltip: 'Annulla',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            changeStatus(record, dataContainerService, datasetNavigator, DomainPackageState.RETIRED);
        },
        showIf: function () {
            return true;
        }
    },
    {
        buttonIcon: 'money',
        actionCode: 'plafond_actions',
        buttonColor: 'accent',
        tooltip: 'Imposta plafond',
        onClick: function (event, record, dataContainerService, datasetNavigator) {
            requirePlafondData(null, dataContainerService, datasetNavigator, null, {
                domainPackageId: record.id
            }).then(function (data) {
                setPlafond(datasetNavigator, data, record).then(function (resp) {
                    if (!resp)
                        return;
                    if (dataContainerService instanceof DatasetActionContainerService) {
                        dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
                    }
                });
            }, function (error) {
                // ignore
            });
        },
        showIf: function (record, dataContainerService) {
            if (record.plafond_id) {
                return false;
            }
            return true;
        }
    }
];
