<h2>{{'Pratica' | translate}}</h2>
<pratica-data 
	[record]='datasetRS.record.value'
	[index]="-1"
	[rowDb]='datasetRS.record.value'
	[datACS]='datasetACS'
	[viewFormConfig]="'generalForm'"
	[showActions]='false'>
</pratica-data>

<h2>{{'Pratica' | translate}}</h2>
<pratica-data 
	[record]='datasetRS.record.value.pratica'
	[index]="-1"
	[rowDb]='datasetRS.record.value'
	[datACS]='datasetACS'
	[viewFormConfig]="'praticaForm'"
	[showActions]='false'>
</pratica-data>

<h2>{{'Servizi' | translate}}</h2>
<pratica-data 
	*ngFor="let service of datasetRS.record.value.services; let i = index"
	[record]='service'
	[index]="i"
	[rowDb]='datasetRS.record.value'
	[datACS]='datasetACS'
	[viewFormConfig]="'serviceForm'"
	[showActions]='false'>
</pratica-data>

<h2>{{'Partecipanti' | translate}}</h2>
<ng-container *ngFor="let participant of datasetRS.record.value.participants; let i = index">
	<pratica-data 
		[record]='participant'
		[index]="i"
		[rowDb]='datasetRS.record.value'
		[datACS]='datasetACS'
		[viewFormConfig]="'participantsForm'"
		[showActions]='false'>
	</pratica-data>
</ng-container>

<h2>{{'Cliente' | translate}}</h2>
<pratica-data 
	[record]='datasetRS.record.value.customer'
	[index]="-1"
	[rowDb]='datasetRS.record.value'
	[datACS]='datasetACS'
	[viewFormConfig]="'customerForm'"
	[showActions]='false'>
</pratica-data>

<h2>{{'Entity' | translate}}</h2>
<pratica-data 
	[record]='datasetRS.record.value.entity'
	[index]="-1"
	[rowDb]='datasetRS.record.value'
	[datACS]='datasetACS'
	[viewFormConfig]="'entityForm'"
	[showActions]='false'>
</pratica-data>

<h2>{{'Extra data' | translate}}</h2>
<mat-card class='p-24 mat-elevation-z0'>
	<mat-card-content>
		<pre>{{datasetRS.record.value.extra | json}}</pre>
	</mat-card-content>
</mat-card>
