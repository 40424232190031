import { IDatasetConfig, DatasetActionOpenType } from 'app/interfaces';

/*
* forms
*/
import appForm from './forms/app/app.form';
import appTemplateForm from './forms/app/template.form';
import praticheServerForm from './forms/filesytems/pratiche-server.form';
import externalsiteForm from './forms/externalsite/externalsite.form';
import viatorForm from './forms/touroperators/viator.form';
import gygForm from './forms/touroperators/gyg.forms';
import tourCmsForm from './forms/touroperators/tour-cms.forms';
import bokunForm from './forms/touroperators/bokun.forms';
import paypalForm from './forms/payment_methods/paypal/paypal.form';
import stripeForm from './forms/payment_methods/stripe/stripe.form';
import eagencyForm from './forms/accounting/eagency.form';
import kiaraCloudForm from './forms/accounting/kiara-cloud-server-rt.form';
import fattureInCloudForm from './forms/accounting/fatture-in-cloud.form';
import twilioSMSForm from './forms/twilio/sms.forms';
import appMobileForm from './forms/channels/app-mobile.form';
import totemForm from './forms/channels/totem.form';
import websiteForm from './forms/channels/website.form';
import bookingEngineForm from './forms/channels/booking-engine.form';
import backofficeForm from "./forms/channels/backoffice.form";

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domain_configs/detail/:recordId',
};

export const DomainConfigs: IDatasetConfig = {
	name: 'domain_configs',
	title: 'Configurazione Domini',
	singleTitle: 'Configurazione Dominio',
	ajaxDatasetCode: 'domain_configs',
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: true,
		list: false
	},
	formConfigsMap: {
		appForm,
		appTemplateForm,
		praticheServerForm,
		externalsiteForm,
		viatorForm,
		gygForm,
		tourCmsForm,
		bokunForm,
		paypalForm,
		stripeForm,
		eagencyForm,
		kiaraCloudForm,
		fattureInCloudForm,
		twilioSMSForm,
		appMobileForm,
		totemForm,
		websiteForm,
		bookingEngineForm,
        backofficeForm
	},
	defaultActions: {
		detail: {
			beforeCalls: [beforeCallDetail]
		},
		create: {},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			

		},
		list: {}
	}
};
