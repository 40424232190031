import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { stripeFieldsMap } from 'app/configs/datasets/domain_configs/fields/payment_methods/stripe/stripe.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	stripeFieldsMap.get('enabled'),
	stripeFieldsMap.get('client_id'),
	stripeFieldsMap.get('client_secret'),
	stripeFieldsMap.get('scenario'),
];

const stripeForm: IFormConfig = { 
	editTitle: 'Modifica Chiavi Stripe',
	fields: formFields
};

export default stripeForm;
