import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('name'),
	fieldsMap.get('auto_apply'),
	fieldsMap.get('description'),
	fieldsMap.get('coupon_code'),
	fieldsMap.get('mode'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('total'),
			fieldsMap.get('used')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('valid_from'),
			fieldsMap.get('valid_to')
		]
	}
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
