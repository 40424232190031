<div class='full-form' [ngClass]='datasetACS.getFormConfig("textForm").formStyle'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è ancora presente!' | translate}}</p>
		</ng-container>

		<form-viewer
			#formViewer
			[dataContainerService]='datasetACS'
			[formConfig]='datasetACS.getFormConfig("textForm")'
			[record]='datasetRS.record.value'
		></form-viewer>

		<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<button color='accent' mat-flat-button (click)='onSave()'>{{'Salva' | translate}}</button>
		</div>

	</div>
</div>