import * as tslib_1 from "tslib";
import { InputTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
export var ActualTripStartStatusLabels = {
    unconfirmed: 'Non confermato',
    confirmed: 'Confermata',
    canceled: 'Cancellato',
    closed: 'Chiusa'
};
var fieldsMap = new Map();
fieldsMap.set('code', {
    key: 'code',
    name: 'Codice',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});
fieldsMap.set('start_day', {
    key: 'start_day',
    name: 'Data Partenza',
    inputType: InputTypes.DATE,
    valueType: ValueTypes.DATE,
    formValidators: [Validators.required],
    inputHint: 'Giorno della partenza'
});
fieldsMap.set('start_time', {
    key: 'start_time',
    name: 'Orario Partenza',
    inputType: InputTypes.TIME,
    valueType: ValueTypes.TIME,
    formValidators: [Validators.required]
});
fieldsMap.set('start_date_time', {
    key: 'start_date_time',
    name: 'Dettaglio Partenza',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var html = '';
        var d = moment.utc(record.start_day);
        html += '<span>' + d.format('DD/MM/YYYY') + '</span>';
        if (record.start_time) {
            html += '<br>';
            html += '<span>' + record.start_time.slice(0, 5) + '</span>';
        }
        return html;
    }
});
fieldsMap.set('icon_type', {
    key: 'icon_type',
    name: 'Tipo',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var html = '';
        var iconName = 'remove';
        if (record.type === 'going') {
            iconName = 'call_made';
        }
        else if (record.type === 'return') {
            iconName = 'call_received';
        }
        html += '<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img">';
        html += iconName;
        html += '</mat-icon>';
        return html;
    }
});
fieldsMap.set('languages', {
    key: 'languages',
    name: 'Lingua',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a, e_2, _b;
        var html = '';
        if (record.properties) {
            var guideLanguages = [];
            var audioguideLanguages = [];
            if (record.properties.actual_trip_guide_languages && record.properties.actual_trip_guide_languages.value) {
                guideLanguages = record.properties.actual_trip_guide_languages.value;
            }
            if (record.properties.actual_trip_audioguide_languages && record.properties.actual_trip_audioguide_languages.value) {
                audioguideLanguages = record.properties.actual_trip_audioguide_languages.value;
            }
            if (guideLanguages.length > 0) {
                html += '<span>Guida</span>';
                html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                var style = 'style="margin-right: 5px;"';
                try {
                    for (var guideLanguages_1 = tslib_1.__values(guideLanguages), guideLanguages_1_1 = guideLanguages_1.next(); !guideLanguages_1_1.done; guideLanguages_1_1 = guideLanguages_1.next()) {
                        var lang = guideLanguages_1_1.value;
                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (guideLanguages_1_1 && !guideLanguages_1_1.done && (_a = guideLanguages_1.return)) _a.call(guideLanguages_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                html += '</div>';
            }
            if (audioguideLanguages.length > 0) {
                html += '<span>Audioguide</span>';
                html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                var style = 'style="margin-right: 5px;"';
                try {
                    for (var audioguideLanguages_1 = tslib_1.__values(audioguideLanguages), audioguideLanguages_1_1 = audioguideLanguages_1.next(); !audioguideLanguages_1_1.done; audioguideLanguages_1_1 = audioguideLanguages_1.next()) {
                        var lang = audioguideLanguages_1_1.value;
                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (audioguideLanguages_1_1 && !audioguideLanguages_1_1.done && (_b = audioguideLanguages_1.return)) _b.call(audioguideLanguages_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                html += '</div>';
            }
        }
        return html;
    }
});
fieldsMap.set('guide_languages', {
    key: 'guide_languages',
    name: 'Lingua Guida',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_3, _a;
        var html = '';
        if (record.properties) {
            var guideLanguages = [];
            if (record.properties.actual_trip_guide_languages && record.properties.actual_trip_guide_languages.value) {
                guideLanguages = record.properties.actual_trip_guide_languages.value;
            }
            if (guideLanguages.length > 0) {
                html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                var style = 'style="margin-right: 5px;"';
                try {
                    for (var guideLanguages_2 = tslib_1.__values(guideLanguages), guideLanguages_2_1 = guideLanguages_2.next(); !guideLanguages_2_1.done; guideLanguages_2_1 = guideLanguages_2.next()) {
                        var lang = guideLanguages_2_1.value;
                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (guideLanguages_2_1 && !guideLanguages_2_1.done && (_a = guideLanguages_2.return)) _a.call(guideLanguages_2);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                html += '</div>';
            }
        }
        return html;
    }
});
fieldsMap.set('audioguide_languages', {
    key: 'audioguide_languages',
    name: 'Lingua Audioguida',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_4, _a;
        var html = '';
        if (record.properties) {
            var audioguideLanguages = [];
            if (record.properties.actual_trip_audioguide_languages && record.properties.actual_trip_audioguide_languages.value) {
                audioguideLanguages = record.properties.actual_trip_audioguide_languages.value;
            }
            if (audioguideLanguages.length > 0) {
                html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                var style = 'style="margin-right: 5px;"';
                try {
                    for (var audioguideLanguages_2 = tslib_1.__values(audioguideLanguages), audioguideLanguages_2_1 = audioguideLanguages_2.next(); !audioguideLanguages_2_1.done; audioguideLanguages_2_1 = audioguideLanguages_2.next()) {
                        var lang = audioguideLanguages_2_1.value;
                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (audioguideLanguages_2_1 && !audioguideLanguages_2_1.done && (_a = audioguideLanguages_2.return)) _a.call(audioguideLanguages_2);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                html += '</div>';
            }
        }
        return html;
    }
});
fieldsMap.set('end_day', {
    key: 'end_day',
    name: 'Data Ritorno',
    inputType: InputTypes.DATE,
    valueType: ValueTypes.DATE,
    formValidators: [Validators.required],
    inputHint: 'Giorno del ritorno'
});
fieldsMap.set('end_time', {
    key: 'end_time',
    name: 'Orario ritorno',
    inputType: InputTypes.TIME,
    valueType: ValueTypes.TIME,
    formValidators: [Validators.required]
});
fieldsMap.set('type', {
    key: 'type',
    name: 'Tipo di partenza',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    defaultValue: function () { return 'single'; },
    options: [
        {
            label: 'Singola',
            value: 'single'
        },
        {
            label: 'Andata',
            value: 'going'
        },
        {
            label: 'Ritorno',
            value: 'return'
        }
    ]
});
fieldsMap.set('min_allotment', {
    key: 'min_allotment',
    name: 'Allotment minimo',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    formValidators: [Validators.required],
    inputHint: 'Minimo per partire',
    defaultValue: function () { return 0; }
});
fieldsMap.set('max_allotment', {
    key: 'max_allotment',
    name: 'Allotment massimo',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    defaultValue: function () { return 0; }
});
fieldsMap.set('default_max_allotment', {
    key: 'default_max_allotment',
    name: 'Allotment specifico',
    valueType: ValueTypes.PROPERTY,
    inputConfig: {
        type: InputTypes.NUMBER,
        clearable: true
    }
});
fieldsMap.set('create_start_status', {
    key: 'start_status',
    name: 'Stato partenza',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return 'confirmed'; },
    options: [
        {
            label: 'Non confermato',
            value: 'unconfirmed'
        },
        {
            label: 'Confermato',
            value: 'confirmed'
        }
    ]
});
fieldsMap.set('start_status', {
    key: 'start_status',
    name: 'Stato partenza',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return 'unconfirmed'; },
    options: [
        {
            label: ActualTripStartStatusLabels.unconfirmed,
            value: 'unconfirmed'
        },
        {
            label: ActualTripStartStatusLabels.confirmed,
            value: 'confirmed'
        },
        {
            label: ActualTripStartStatusLabels.canceled,
            value: 'canceled'
        }
    ]
});
fieldsMap.set('note', {
    key: 'note',
    name: 'Note',
    inputType: InputTypes.TEXTAREA,
    valueType: ValueTypes.STRING,
    inputHint: 'Note'
});
fieldsMap.set('trip_package_id', {
    key: 'trip_package_id',
    name: 'Pacchetto',
    inputType: InputTypes.DATASET_AUTOCOMPLETE,
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'trip_packages',
    inputHint: 'Scrivi per cercare un pacchetto',
    remoteOptions: {
        limit: 100,
        icon: 'place',
        getLabel: function (option) {
            if (!option)
                return '';
            return option.description;
        },
        getValue: function (option) {
            if (!option)
                return;
            return option.id;
        },
        getDisplay: function (option) {
            if (!option)
                return '';
            return option.description;
        }
    }
});
fieldsMap.set('availability', {
    name: 'Disponibilità',
    key: 'availability',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        if (record.has_exclusive_resource && record.consumed_allotment) {
            return 'Già Prenotato';
        }
        var max_allotment = '&infin;';
        if (record.max_allotment != null && record.max_allotment >= 0) {
            max_allotment = record.max_allotment;
        }
        if (record.is_external)
            return max_allotment;
        return record.consumed_allotment + '/' + max_allotment;
    }
});
var startStatusFgColors = {
    canceled: 'warn-fg',
    confirmed: 'green-fg',
    unconfirmed: ''
};
fieldsMap.set('availability_with_status', {
    name: 'Disponibilità',
    key: 'availability',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var statusLabel = ActualTripStartStatusLabels[record.start_status] || '';
        var statusColor = startStatusFgColors[record.start_status] || '';
        var statusHtml = statusLabel ? ('<span class="' + statusColor + '">' + statusLabel + '</span>') : '';
        if (record.has_exclusive_resource && record.consumed_allotment) {
            return '<span>Prenotato</span><br>' + statusHtml;
        }
        var max_allotment = '&infin;';
        if (record.max_allotment != null && record.max_allotment >= 0) {
            max_allotment = record.max_allotment;
        }
        return '<span>' + record.consumed_allotment + '/' + max_allotment + '</span><br>' + statusHtml;
    }
});
fieldsMap.set('participants_count', {
    name: 'Partecipanti',
    key: 'participants_count',
    valueType: ValueTypes.STRING,
    getDisplayValue: function (record) {
        if (!record || !record.participant_overall || !record.participant_overall.total)
            return '0';
        return record.participant_overall.total;
    }
});
fieldsMap.set('meeting_point_list_description', {
    name: 'Nome',
    key: 'meeting_point_list_description',
    valueType: ValueTypes.STRING
});
fieldsMap.set('display_name', {
    name: 'Nome Partenza',
    key: 'display_name',
    valueType: ValueTypes.STRING
});
var meetingPointRemoteOptions = {
    limit: 50,
    getParams: function (dataContainerService, data) {
        var params = {
            properties_parent_dataset_code: 'trip_packages',
            with_items: 1
        };
        var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
        if (tripPackageId) {
            params['properties_parent_id'] = tripPackageId;
        }
        return params;
    },
    getLabel: function (option) {
        if (!option)
            return '---';
        var label = option.description;
        if (option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-') {
            label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
        }
        else if (option.items_count > 0) {
        }
        return label;
    },
    getValue: function (option) {
        return option.id;
    }
};
fieldsMap.set('meeting_point_list_id', {
    key: 'meeting_point_list_id',
    name: 'Lista punti di ritrovo',
    inputType: InputTypes.DATASET_MULTISELECT,
    valueType: ValueTypes.ARRAY,
    datasetCode: 'meeting_point_unified_lists',
    remoteOptions: meetingPointRemoteOptions
});
export { fieldsMap };
