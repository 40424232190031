import {ValueTypes, IFieldDefinition} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('return_description', {
	name: 'Ritorno',
	key: 'return_description',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.return_trip) return '---';
		return record.return_trip.meeting_point_list_description;
	}
});

fieldsMap.set('going_description', {
	name: 'Andata',
	key: 'going_description',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.going_trip) return '---';
		return record.going_trip.meeting_point_list_description;
	}
});

fieldsMap.set('return_start_time', {
	name: 'Orario',
	key: 'return_start_time',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.return_trip) return '---';
		return record.return_trip.start_time;
	}
});

fieldsMap.set('going_start_time', {
	name: 'Orario',
	key: 'going_start_time',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.going_trip) return '---';
		return record.going_trip.start_time.slice(0, 5);
	}
});

export {fieldsMap};
