import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { supportedLanguages } from 'app/configs/datasets/actual_trips';
import { Subject } from 'rxjs';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	readonlyIf(record: any): boolean {
		return record === null;
	},
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.',
	appearance: 'outline',
});


fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
	inputHint: 'Nome del pacchetto',
	appearance: 'outline',
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	appearance: 'outline',
});

fieldsMap.set('languages', {
	key: 'languages',
	name: 'Languages',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: supportedLanguages,
	appearance: 'outline',
});

fieldsMap.set('audio_guide_languages', {
	key: 'audio_guide_languages',
	name: 'Audio guide languages',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: supportedLanguages,
	appearance: 'outline',
});

fieldsMap.set('duration', {
	key: 'duration',
	name: 'Durata',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	inputRequired: true,
	formValidators: [ Validators.required ],
	appearance: 'outline',
});

fieldsMap.set('limit_pax_per_booking', {
	key: 'limit_pax_per_booking',
	name: 'Limite partecipanti per prenotazione',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

fieldsMap.set('length_type', {
	key: 'length_type',
	name: 'Tipologia durata',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Ore',
			value: 'hours',
		},
		{
			label: 'Giorni',
			value: 'days',
		},
		{
			label: 'Mesi',
			value: 'month'
		}
	],
	appearance: 'outline',
});

export default fieldsMap;
