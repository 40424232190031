import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { tourCmsFieldsMap } from '../../fields/touroperators/tour-cms.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	tourCmsFieldsMap.get('enabled'),
	tourCmsFieldsMap.get('enable_push_availability'),
	// tourCmsFieldsMap.get('parameters.endpoint'), // disabled for now
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			tourCmsFieldsMap.get('parameters.private_key'),
			tourCmsFieldsMap.get('parameters.channel'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			tourCmsFieldsMap.get('parameters.marketplace_account_id'),
			tourCmsFieldsMap.get('parameters.marketplace_private_key'),
		]
	},
	/*tourCmsFieldsMap.get('entity_customer.entity_customer_id'),
	tourCmsFieldsMap.get('entity_customer.description'),*/
	tourCmsFieldsMap.get('branch.branch_id'),
	tourCmsFieldsMap.get('branch.code'),
	tourCmsFieldsMap.get('scenario'),
];

const tourCmsForm: IFormConfig = {
	editTitle: 'Modifica Configurazioni Tour CMS',
	fields: formFields 
};

export default tourCmsForm;
