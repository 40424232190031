import { IListViewConfig, ListViewItemTypes } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';


const viewConfig: IListViewConfig = {
	itemViewType: ListViewItemTypes.TABLE,
	showSearchBar: false,
	defaultSortBy: 'created_at',
	defaultSortDirection: 'desc',
	columns: [
		{
			title: 'Codice',
			key: 'staff_code'
		},
		{
			title: 'Description',
			key: 'staff_description'
		}
	],
	actions: [],
	itemActionsOrder: ['custom_0'],
	customActions: [
		{
			buttonColor: 'accent',
			buttonIcon: 'pageview',
			makeRouteLink(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): string{
				return datasetNavigatorService.getDetailRoute('entities',  record.staff_list_item.entity_staff_element.entity_id);
			},
			showIf(): boolean{ return true; }
		}
	]
};

export default viewConfig;
