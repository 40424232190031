<h1 mat-dialog-title>{{'Attenzione' | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>
	<div class='full-form'>
		<ng-container *ngIf='!invalidItems || invalidItems.length === 0'>
			<ng-container [ngSwitch]='data.datasetCode'>
				<ng-container *ngSwitchCase='"trip_package_resources"'>
					<p>{{'Non è possibile' | translate}} {{ data.actionCode === 'create' ? 'aggiungere' : 'modificare' | translate }} {{'la risorsa selezionata perchè gli allotments di alcuni giorni sono inferiori alle prenotazioni confermate.' | translate}}</p>
					<p>{{'Per' | translate}} {{ data.actionCode === 'create' ? 'aggiungere' : 'modificare' | translate }} {{'la risorsa è necessario aumentare gli allotments per le date future.' | translate}}</p>
				</ng-container>
				<ng-container *ngSwitchCase='"trip_resource_allotments"'>
					<p>{{'Non è possibile' | translate}} {{ data.actionCode === 'create' ? 'aggiungere' : 'modificare' | translate }} {{'l\'allotment perchè gli allotments di alcuni giorni sono inferiori alle prenotazioni confermate.' | translate}}</p>
					<p>{{'Per modificare l\'allotment è necessario sceglierne uno più alto.' | translate}}</p>
				</ng-container>
				<ng-container *ngSwitchCase='"trip_resources"'>
					<p>{{'Non è possibile modificare la risorsa perchè gli allotments di alcuni giorni sono inferiori alle prenotazioni confermate.' | translate}}</p>
					<p>{{'Per modificare la risorsa è necessario aumentare gli allotments delle date future.' | translate}}</p>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<p>{{'Non è possibile procedere con questa modifica perchè gli allotments di alcuni giorni sono inferiori alle prenotazioni confermate.' | translate}}</p>
					<p>{{'Per procedere è necessario aumentare gli allotments nelle date future.' | translate}}</p>
				</ng-container>
			</ng-container>
			
			
		</ng-container>
		<ng-container *ngIf='invalidItems && invalidItems.length > 0'>
			<p>{{'Nelle seguenti date le prenotazioni eccedono l\'allotment massimo della risorsa.' | translate}}</p>
			<p>{{'Per continuare questa operazione è necessario risolvere le seguenti criticità:' | translate}}</p>
			<table mat-table [dataSource]="invalidItems">
				<ng-container matColumnDef="day">
					<th mat-header-cell *matHeaderCellDef> {{'Data' | translate}} </th>
					<td mat-cell *matCellDef="let element"> {{element.day | date: 'dd/MM/yyyy'}} </td>
				</ng-container>
				<ng-container matColumnDef="consumed">
					<th mat-header-cell *matHeaderCellDef> {{'Prenotati' | translate}} </th>
					<td mat-cell *matCellDef="let element"> {{element.consumed}} </td>
				</ng-container>
				<ng-container matColumnDef="allotment_max">
					<th mat-header-cell *matHeaderCellDef> {{'Massimo' | translate}} </th>
					<td mat-cell *matCellDef="let element"> {{element.allotment_max}} </td>
				</ng-container>
				<ng-container matColumnDef="details">
					<th mat-header-cell *matHeaderCellDef> {{'Dettagli' | translate}} </th>
					<td mat-cell *matCellDef="let element"> 
						<p *ngIf="element.trip_package_name"><b>{{'Pacchetto:' | translate}}</b> {{element.trip_package_name}}</p>
						<p *ngIf="element.meeting_point"><b>{{'Partenza:' | translate}}</b> {{element.meeting_point}}</p>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
			<p *ngIf='hasMore && data.count'>{{'Ci sono altre' | translate}} {{data.count}} {{'criticità...' | translate}}</p>
		</ng-container>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="onNoClick()">Ok</button>
</mat-dialog-actions>
