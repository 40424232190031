import {
	Component,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material';
import {exhaustMap, filter, takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TrenitaliaDetailsComponent } from '../../../trenitalia-dialog/trenitalia-details/trenitalia-details.component';
import { TrenitaliaDialogContainerComponent } from '../../../trenitalia-dialog/trenitalia-dialog-container/trenitalia-dialog-container.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { wrapArray } from 'app/helpers';

@Component({
	selector: 'trenitalia-booking',
	templateUrl: './trenitalia-booking.component.html',
	styleUrls: ['./trenitalia-booking.component.scss'],
	providers: []
})
export class TrenitaliaBookingComponent extends DestroyableComponent implements OnInit, OnDestroy {

	public dataTrain;
	public status: string;
	public totalRowLordo: number;
	public isCarnet = false;
    public totalFee: number;
	public participantsWarning:any

	/**
	 * if travelId exists mean that travel was created on trenitalia
	 */
	get travelId(){
		return _.get(this.tripBookingPackage, 'external_solution_data.travel.id');
	}

	get tripBookingPackage(): any {
		return this.datasetRS.record.value;
	}

	get tripBookingStatus() {
		return (
			this.bookingStepService &&
			this.bookingStepService.datasetRS.record.value &&
			this.bookingStepService.datasetRS.record.value.status
		);
	}

	get iterationData(){
		return this.tripBookingPackageService.iterationData;
	}

	get showHeaderActions(){
		return this.bookingService.canEdit && !this.tripBookingPackage.tour_operator_booking_ref && !this.tripBookingPackage.tour_operator_booking_commit_status;
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public domainFilterService: DomainFilterService,
		public bookingService: BookingService,
		private injector: Injector,
		public bookingStepService: TripBookingPackagesStepService,
		public viewContainerRef: ViewContainerRef,
		public tripBookingPackageService: TripBookingPackageService
	){
		super();
	}

	ngOnInit() {
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			this.computeData();
			this.checkParticipant();
		});
		this.computeData();

		this.bookingStepService.settingsUpdated
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			this.checkParticipant();
		})
	}
	
	checkParticipant(){
		let travellers = _.get(this.tripBookingPackage,['external_solution_data','travel','travellers']);
		let adults = _.reduce(travellers,function(result, traveller){
			return traveller['type']=='ADULT'?result+1:result
		},0);
		let chilren = _.reduce(travellers,function(result, traveller){
			return traveller['type']=='CHILDREN'?result+1:result
		},0);
		const participants = this.bookingService.getTripBooking().participant_counters;
		this.participantsWarning = {status:false,value:{}}
		this.participantsWarning.status = 	(_.get(participants,'ADT',0)+_.get(participants,'SNR',0) !== adults)||
												(_.get(participants,'CHD',0) !== chilren)
		this.participantsWarning.value = {ADT:adults,CHD:chilren}
		
	}

	computeData(){
		this.dataTrain = wrapArray(_.get(this.tripBookingPackage, 'external_solution_data.travel.travelSolutions'))[0];
		this.status = this.tripBookingPackage.tour_operator_booking_status;
		let totalLordo = Number(this.tripBookingPackage.total_amount);

		if(this.tripBookingPackage.children_trip_booking_packages){
			for (const tbp of this.tripBookingPackage.children_trip_booking_packages) {
				totalLordo += Number(tbp.total_amount);
			}
		}
		this.totalFee = this.tripBookingPackage.fee;
		this.totalRowLordo = totalLordo;
		this.isCarnet = _.get(this.tripBookingPackage, 'external_solution_data.isCarnet')||_.get(this.tripBookingPackage, 'target_info.orderType') === 'carnet';
	}

	viewProduct() {
		this.matDialog
		.open(TrenitaliaDetailsComponent, {
			minWidth: '1400px',
			panelClass: 'create-dialog-container',
			viewContainerRef: this.viewContainerRef,
			data: {
				dataTrain: this.tripBookingPackage.external_solution_data,
				tripBookingPackage: this.tripBookingPackage,
                isCarnet:this.isCarnet
			}
		})
		.afterClosed()
		.subscribe(tripBookingPackage => {
			if (tripBookingPackage) {
				this.bookingStepService.addTrenitaliaService(
					tripBookingPackage
				);
			}
		});
	}

	deleteTripBookingPackage(): void {
		this.datasetNavigatorService
		.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
		.afterClosed()
		.subscribe(result => {
			if (result) {
				this.bookingStepService.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			}
		});
	}

	editTripBookingPackage(){
		this.matDialog
		.open(ConfirmDialogComponent, {
			width: '300px',
			data: {
				title: 'Conferma',
				contentMessage: 'Vuoi modificare questo viaggio?'
			}
		})
		.afterClosed()
		.subscribe(result => {
			if(!result) return;
			this.bookingStepService.addTrenitaliaService(this.tripBookingPackage);
		});
	}

	confirmTripBookingPackage(){
		this.tripBookingPackageService.confirmTripBookingPackage();
	}

	editTripBookingTravellers(){
		this.matDialog
		.open(ConfirmDialogComponent, {
			width: '300px',
			data: {
				title: 'Conferma',
				contentMessage:
					'Vuoi modificare i dati dei passeggeri?'
			}
		})
		.afterClosed()
		.subscribe(result => {
			if(!result) return;
			this.matDialog
				.open(TrenitaliaDialogContainerComponent, {
					minWidth: '800px',
					panelClass: 'create-dialog-container',
					data: {
						participant: this.tripBookingPackage.participant_counters,
						trip_booking_id: this.tripBookingPackage.trip_booking_id,
						trip_booking_package: this.tripBookingPackage,
						mode: 'edit-traveller-pre-sale',
						data: {
							travellers: _.get(this.tripBookingPackage,'target_info.travellers'),
							buyer:_.get(this.tripBookingPackage,'target_info.buyer'),
							invoice:_.get(this.tripBookingPackage,'target_info.withInvoice'),
							invoiceData:_.get(this.tripBookingPackage,'target_info.invoiceProfileData'),
							solution: _.get(this.tripBookingPackage,'target_info.solution')
						},
						bookingService: this.bookingService
					}
				})
				.afterClosed()
				.pipe(
					filter(res => res),
					exhaustMap(res => {
						const postData = {
							trip_booking_id: this.datasetRS.recordId,
							target: 'trenitalia',
							target_info: res
						};
							postData['trip_booking_package_id_to_replace'] =
								this.tripBookingPackage.id;
							const actionEndPoint =
								'/dataset/trip_bookings/command/replace_trip_external_service';
						return this.datasetService.post<any>(actionEndPoint, postData)
					})
				).subscribe({
				next: response => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGES
					});
				},
				error: (err) => {
					// show message error
				}
			});
		});
	}
}
