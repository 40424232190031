import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject, timer } from 'rxjs';
import { takeUntil, take, debounceTime, distinctUntilChanged, distinct } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IPaginationResponse, IListViewConfig, ISocketEvent, IWebSockeConfig} from 'app/interfaces';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetNavigatorService} from 'app/main/components/dataset/services/dataset-navigator.service';
import {ListViewItemTypes} from 'app/interfaces';
import {AppService} from 'app/services/app.service';
import {BaseDatasetComponent} from '../base-dataset.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DatasetListService } from './dataset-list.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebSocketService } from 'app/services/websocket.service';

@Component({
	selector   : 'dataset-list',
	templateUrl: './dataset-list.component.html',
	styleUrls  : ['./dataset-list.component.scss'],
	providers  : [
		DatasetActionContainerService, 
		{provide: 'ListParamService', useClass: DatasetListService}
	]
})
export class DatasetListComponent extends BaseDatasetComponent implements OnChanges, OnInit{

	public actionCode = 'list';

	public ItemViewTypes = ListViewItemTypes;
	public itemViewType = ListViewItemTypes.DEBUG;
	public items = [];
	public lastPaginationResponse: IPaginationResponse<any>;

	public searchTextFilterControl: FormControl;
	public filterForm: FormGroup;

	public socketEvent: ISocketEvent;
	public debounceSocketReload: Subject<boolean>;

	@Input() sortBy: string;
	@Input() sortDirection: string;
	@Input() filters: any;
	@Input() cacheDatasetListFilters = true;
	@Input() selectable = false;
	@Input() selection: any;
	@Input() selectionBtnTxt: any = 'Aggiungi';
	@Input() selectionBtnIcon: any;
	@Output() selected = new EventEmitter<any>();
	@Input() viewConfig: IListViewConfig;
	@Input() enableCreate = true;
	@Input() enableEdit = true;
	@Input() enableDelete = true;
	@Input() showCreateForm = false;
	@Input() showSearchBar = true;
	@Input() showPagination = true;
	@Input() enableActions = true;
	@Input() elevate = true;
	@Input() showExportBtn = true;
	@Input() showHeaderToolbar = true;
	@Input() unsetParamFilters = false;
	@Input() contextualDomainId: any;
	@Input() tableStyle: string;
	@Input() filterStyle: string;
	
	/**
	 * Show pagination toolbar only if need
	 */
	@Input() conditionalShowPaginationToolbar = true;

	@Input() emitAdd = false;
	@Input() addButtonText = 'Aggiungi';
	@Output() add = new EventEmitter<any>();
	@Output() created = new EventEmitter<any>();
	@Output() datasetEvent = new EventEmitter<IDatasetEvent>();

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		protected appService: AppService,
		public datasetACS: DatasetActionContainerService,
		private datasetNavigatorService: DatasetNavigatorService,
		private domainFilterService: DomainFilterService,
		@Inject('ListParamService') public datasetListService: DatasetListService,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService,
		protected mastSnackBar: MatSnackBar,
		protected webSocketService: WebSocketService){
			super(route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService);
			this.debounceSocketReload = new Subject<boolean>();
		}

	ngOnInit(): void{
		this.datasetACS.viewConfig = this.viewConfig;
		this.datasetListService.datasetACS = this.datasetACS;
		this.datasetListService.viewConfig = this.viewConfig;
		this.datasetListService.sortDirection = this.sortDirection;
		this.datasetListService.sortBy = this.sortBy;
		super.ngOnInit();

		this.searchTextFilterControl = new FormControl();
		this.filterForm = new FormGroup({
			'text-search': this.searchTextFilterControl
		});

		this.datasetACS.datasetEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			this.datasetEvent.emit(event);

			if(event.eventName === DatasetEvents.ACTION_REALOAD_LIST){
				this.reload();
			}
		});

		this.domainFilterService.filterDomainId
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			if(this.datasetACS.viewConfig.cacheDatasetListFilters && this.cacheDatasetListFilters){
				this.datasetACS.putDatasetData('filters', JSON.parse(localStorage.getItem(this.datasetACS.getListFiltersCacheKey())));
			}
			this.reload();
		});

		this.debounceSocketReload
		.pipe(takeUntil(this._unsubscribeAll), debounceTime(60*1000))
		.subscribe(() => {
			this.reload();
		});
	}

	onFilterChange(newFilters: any): void{
		this.cacheFilters(newFilters);
		this.datasetACS.putDatasetData("filters", newFilters);
		this.datasetEvent.emit({
			eventName: DatasetEvents.LIST_FIlTER_CHANGED,
			data: newFilters
		});
	}

	cacheFilters(newFilters: any): void{
		if(this.datasetACS.viewConfig.cacheDatasetListFilters && this.cacheDatasetListFilters){
			localStorage.setItem(this.datasetACS.getListFiltersCacheKey(), JSON.stringify(newFilters));
		}else{
			localStorage.removeItem(this.datasetACS.getListFiltersCacheKey());
		}
	}

	onChannelEvent(broadcastEvent: ISocketEvent): void{
		if(!broadcastEvent) return;
		if(!broadcastEvent.data && !broadcastEvent.data.datasetCode) return;
		if(broadcastEvent.data.datasetCode !== this.datasetACS.datasetCode) return;

		const config = this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig;
		// if non config
		if (!config) return;

		// save event
		this.socketEvent = broadcastEvent;

		// customize message based on event type
		let message = "";
		if (config.composeGlobalToastMessage) message = config.composeGlobalToastMessage(broadcastEvent);

		// load web socket configs
		const webSocketConfig = <IWebSockeConfig>this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig[broadcastEvent.data.eventName];
		// if non configs
		if (!webSocketConfig) return;

		// check configs dataset for reloading
		if(webSocketConfig.reloadDatasetList && !Object.keys(this.datasetACS.datasetData['filters']).length && this.datasetListService.currentPage == 1){
			this.debounceSocketReload.next(true);
		}
		
		// check configs dataset for toast
		if (webSocketConfig.showToast){
			if (webSocketConfig.composeToastMessage){
				message = webSocketConfig.composeToastMessage(broadcastEvent);
			}
			this.mastSnackBar.open(message || webSocketConfig.toastMessage || 'Something new is coming!', webSocketConfig.dismissToastTxt || '', {
				duration: 15*1000
			}).afterDismissed().subscribe(res => {
				if (res.dismissedByAction && webSocketConfig.reloadDatasetListOnDismissToast) this.reload();
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.filters && !changes.filters.isFirstChange()){
			this.datasetACS.putDatasetData('filters', changes.filters.currentValue);
		}
		if(changes.viewConfig && !changes.viewConfig.isFirstChange()){
			this.datasetACS.viewConfig = this.viewConfig;
			this.datasetListService.viewConfig = this.viewConfig;
			this.datasetListService.itemViewType = this.viewConfig && this.viewConfig.itemViewType;
		}
		if(changes.sortBy && !changes.sortBy.isFirstChange()){
			this.datasetListService.sortBy = this.sortBy;
		}
		if(changes.sortDirection && !changes.sortDirection.isFirstChange()){
			this.datasetListService.sortDirection = this.sortDirection;
		}

		this.socketEvent = null;
	}

	protected initLoad(): Observable<void>{
		if(!this.datasetACS.actionConfig){
			console.warn('no action config', this.datasetACS);
			
		}
		if(!this.viewConfig) this.viewConfig = this.datasetACS.actionConfig.viewConfig;
		if(this.viewConfig){
			this.itemViewType = this.viewConfig.itemViewType;
			this.datasetListService.viewConfig = this.viewConfig;
			this.datasetListService.itemViewType = this.viewConfig.itemViewType;
		}

		this.datasetACS.putDatasetData('filters', this.filters);

		this.datasetACS.datasetDataChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(keys => {
			if(keys.includes('filters')){
				this.datasetListService.currentPage = 1;
				this.socketEvent = null;
				this.reload();
			}
		});

		this.datasetACS.datasetEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			if(event.eventName === DatasetEvents.CREATED) this.created.emit();
		});

		return of(null);
	}

	protected load(): Observable<IPaginationResponse<any>>{
		const params = this.datasetListService.getParams();
		let headers = new HttpHeaders({});
		if (this.contextualDomainId){
			headers = headers.append('X-Domain-Id', this.contextualDomainId);
		}else if(this.datasetACS.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
		}
		
		return this.datasetService.get<IPaginationResponse<any>>(this.datasetACS.getListRoute(), params, headers);
	}

	protected onLoaded(response: IPaginationResponse<any>): Observable<any>{
		this.lastPaginationResponse = response;
		this.appService.appEvent.next({
			name: 'total_dataset_items', 
			extra: {
				paginationResponse: this.lastPaginationResponse,
				datasetCode: this.datasetACS.datasetCode
			}
		});
		if(response.static_dataset_allowed_actions && !response.static_dataset_allowed_actions.includes('view')){
			this.appService.showErrorMessage('Permesso negato');
			this.items = [];
			this.lastPaginationResponse = null;
			// this.router.navigate(['home']);
		}else{
			this.items = response.items;
			this.datasetACS.putDatasetData('staticAllowedActionCodes', response.static_dataset_allowed_actions);
		}
		return of(true);
	}

	protected selectRecord(record: any): void{
		this.selection = record;
		this.selected.emit(this.selection);
	}

	public onPageChanged(event): void{
		this.socketEvent = null;
		this.datasetListService.currentPage = event.pageIndex + 1;
		this.datasetListService.perPage = event.pageSize;
		this.reload();
	}

	public onAdd(): void{
		if(this.emitAdd){
			this.add.emit();
		}else{
			this.datasetNavigatorService
			.onAdd(this.datasetACS);
		}
	}
}
