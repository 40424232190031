import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';
var CheckoutDateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CheckoutDateComponent, _super);
    function CheckoutDateComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        /**
         * Chart Travel Date
        */
        _this.barChartOptions = {
            responsive: true,
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        ticks: {
                            callback: function (value) {
                                return Number(value).toLocaleString('US');
                            }
                        }
                    }
                ],
                scaleLabel: {
                    display: true,
                }
            },
            legend: {
                position: 'bottom',
                fullWidth: true,
                labels: {
                    usePointStyle: true,
                    padding: 10,
                }
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(tooltipItem.yLabel);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            }
        };
        _this.chartPlugins = [makeDrawValuePlugin()];
        _this.barChartLabels = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
        _this.barChartType = 'line';
        _this.barChartLegend = true;
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    CheckoutDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardService.checkoutDateResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.onData(response);
            }
        });
    };
    // -----------------------------------------------------------------------------------------------------
    CheckoutDateComponent.prototype.onData = function (response) {
        if (!response) {
            this.showLoader = true;
            return;
        }
        var r = [];
        for (var i = 0; i < response.length; i++) {
            if (!response[i].year)
                continue;
            var year = response[i].year;
            var month = response[i].month - 1;
            if (!r[year])
                r[year] = [];
            if (!r[year][month])
                r[year][month] = 0;
            r[year][month] += response[i].total_amount;
        }
        for (var y = 0; y < Object.keys(r).length; y++) {
            for (var m = 0; m < 12; m++) {
                if (!r[Object.keys(r)[y]][m])
                    r[Object.keys(r)[y]][m] = 0;
            }
        }
        var data = [];
        var years = Object.keys(r);
        for (var y = 0; y < years.length; y++) {
            var year = years[y];
            var colorIndex = years.length - y - 1;
            data.push({
                data: r[year],
                label: String(year),
                lineTension: 0,
                fill: false,
                backgroundColor: chartColors[colorIndex],
                borderColor: chartColors[colorIndex],
                hoverBackgroundColor: chartColors[colorIndex],
                pointBackgroundColor: chartColors[colorIndex],
                pointHoverBackgroundColor: chartColors[colorIndex],
                pointHoverBorderColor: chartColors[colorIndex],
                pointRadius: 5,
            });
        }
        if (!data || data.length === 0) {
            this.showLoader = true;
            this.checks = null;
        }
        else {
            this.showLoader = false;
            this.checks = data;
        }
    };
    return CheckoutDateComponent;
}(DestroyableComponent));
export { CheckoutDateComponent };
