import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import editForm from './edit.form';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/actual_trip_group_trip_resources/detail/:recordId',
	params: {
		with_relations: 'tripResource,actualTripGroup'
	}
};

export const ActualTripGroupResourcesConfig: IDatasetConfig = {
	name: 'actual_trip_group_trip_resources',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'actual_trip_group_trip_resources',
	fieldsMap: fieldsMap,
	supportProperties: false,
	fetchConfig: {
		with_relations: 'tripResource,actualTripGroup'
	},
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	defaultActions: {
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm,
			},
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['actual_trip_group_id'] = dataContainerService.getValueFromKeyPath('actual_trip_group_trip_resources.filters.foreign_parent_record_id');
				return params;
			}
		},
		list: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				itemViewType: ListViewItemTypes.CUSTOM,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				actions: ['create', 'delete'],
			}
		}
	}
};
