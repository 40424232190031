import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { IPaginationResponse } from 'app/interfaces';
import { takeUntil } from 'rxjs/operators';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import FilterFields from './filter.fields';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import formConfig from './form.fields';

@Component({
	selector     : 'dashboard',
	templateUrl  : './dashboard.component.html',
	styleUrls    : ['./dashboard.component.scss'],
	//encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class DashboardPageComponent implements OnInit, OnDestroy{
	_unsubscribeAll: Subject<any>;

	resourceOptions: Subject<any>;
	resourceCtrl: FormControl;

	filtersData: any;
	numFilters: number;
	
	public filterFormGroup: FormGroup;

	@ViewChild('ResourceInput', { static: false }) resourceInput: ElementRef<HTMLInputElement>;
	@ViewChild('autores', { static: false }) matAutocompleteRes: MatAutocomplete;

	public errorMessage: string;
	public fileterFields = FilterFields;

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------
	constructor(
		private _datasetService: DatasetService,
		public dashboardService: DashBoardService,
		private formBuilder: FormBuilder,
		private appService: AppService,
		private domainFilterSerice: DomainFilterService,
		private fuseTranslationLoader: FuseTranslationLoaderService,
		private matDialog: MatDialog,
	){
		this._unsubscribeAll = new Subject();
		this.resourceOptions = new Subject();
		this.resourceCtrl = new FormControl();
		this.filterFormGroup = this.formBuilder.group({
			branch_code: '',
			customer_code: null,
			package_code: null,
			category_id: null,
			booking_date_from: null,
			booking_date_to: null,
			departure_date_from: null,
			departure_date_to: null
		});
		// load translations
		this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.filterFormGroup.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.setFiltersData(this.filterFormGroup.getRawValue());
			this.filterCharts(this.filterFormGroup.getRawValue());
		});

		this.dashboardService.refreshEvent.next();

		this.domainFilterSerice.domainSettingChangeEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.dashboardService.refreshEvent.next();
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// --------------------------------------------------- Resource --------------------------------------------------------------
	resourceFilteredOptions(textSearch): void{
		const params = {
			'text-search': textSearch,
			perPage: 5
		};

		const route = '/dataset/trip_resources';
		this._datasetService.get<IPaginationResponse<any>>(route, params)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				this.resourceOptions.next(result.items);
			},
			error: () => {
			}
		});
	}

	onSelectResource(event: MatAutocompleteSelectedEvent): void{
		this.dashboardService.filterResource.push(event.option.value);
		if(this.resourceInput) this.resourceInput.nativeElement.value = '';
		this.resourceCtrl.setValue(null);
		this.dashboardService.refreshEvent.next();
	}

	addResource(event: MatChipInputEvent): void{
		if (!this.matAutocompleteRes.isOpen) {
			const input = event.input;
			const value = event.value;
	
			// Add our pacakge
			if (value) {
				this.dashboardService.filterResource.push(value);
			}
			// Reset the input value
			if (input) {
				input.value = '';
			}
	
			this.resourceCtrl.setValue(null);
			this.dashboardService.refreshEvent.next();
		}
	}
	removeResource(tripPackageId): void{
		const index = this.dashboardService.filterResource.findIndex(el => {
			return el.id === tripPackageId;
		});
		if(index > -1){
			this.dashboardService.filterResource.splice(index, 1);
			this.dashboardService.refreshEvent.next();
		}
		
	}
	// ------------------------------------------------------------------------------------------------------

	openFilters(): void{
		this.matDialog.open(FormDialogComponent, {
			width: '700px',
			data: {
				title: 'Filtri ricerca',
				formData: this.filtersData,
				formConfig: formConfig,
				positiveText: 'Applica'
			}
		})
		.afterClosed().subscribe(formData => {
			if(formData){
				this.setFiltersData(formData);
				this.filterCharts(formData);
			}
		});
	}

	setFiltersData(formData: any): void{
		this.filtersData = {
			branch_code: formData.branch_code,
			customer_code: formData.customer_code,
			package_code: formData.package_code,
			category_id: formData.category_id,
			booking_date_from: formData.booking_date_from,
			booking_date_to: formData.booking_date_to,
			departure_date_from: formData.departure_date_from,
			departure_date_to: formData.departure_date_to
		};
	}

	countFilters(): void{
		let increment = 0;
		const keysFilters = Object.keys(this.filtersData);
		//console.log(keysFilters);
		for (const key of keysFilters){
			//console.log(key);
			if (this.filtersData[key]){
				//console.log(this.filtersData[key]);
				increment++;
			}
		}
		this.numFilters = increment;
		//console.log(increment);
	}

	filterCharts(formData: any): void{
		this.dashboardService.filterBranch = formData.branch_code;
		this.dashboardService.filterCustomerCodes = formData.customer_code;
		this.dashboardService.filterPackageCodes = formData.package_code;
		this.dashboardService.filterCategoryIds = formData.category_id;
		this.dashboardService.filterBookingDateFrom = formData.booking_date_from;
		this.dashboardService.filterBookingDateTo = formData.booking_date_to;
		this.dashboardService.filterDataS = formData.departure_date_from;
		this.dashboardService.filterDataE = formData.departure_date_to;
		this.dashboardService.refreshGroupingDate();
		this.countFilters();
		this.dashboardService.refreshEvent.next();
	}
}
