import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import {AppService} from 'app/services/app.service';

import { Platform } from '@angular/cdk/platform';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';

@Component({
	selector     : 'app-layout',
	templateUrl  : './layout.component.html',
	styleUrls    : ['./layout.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('container3', { static: true }) container3: ElementRef<any>;

	fuseConfig: any;
	showAppInterface = true;

	showBanner: boolean;
	deferedPrompt: any;

	// Private
	private _unsubscribeAll: Subject<any>;
	private _fusePerfectScrollbar: FusePerfectScrollbarDirective;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _platform: Platform,
		public appService: AppService){

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// Directive
	@ViewChild(FusePerfectScrollbarDirective, { static: true })
	set directive(theDirective: FusePerfectScrollbarDirective)
	{
		if ( !theDirective )
		{
			return;
		}

		this.appService.layoutFusePerefctScrollBar = theDirective;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void
	{
		// Subscribe to config changes
		this._fuseConfigService.config
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((config) => {
			this.fuseConfig = config;
		});

		this.appService.showAppInterface
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(async show => {
			this.showAppInterface = await show;
		});
	}

	ngAfterViewInit(): void {
		// Check if is mobile
		if ( this._platform.ANDROID || this._platform.IOS )
		{
			this.container3.nativeElement.style.overflowY = 'auto';
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onInstallBtnClick(): void{
		if (this.deferedPrompt) {
			this.deferedPrompt.prompt();
			this.deferedPrompt.userChoice.then(result => {
				if (result.outcome === 'accepted') {
					console.log('user accepted add to homescreen');
				} else {
					console.log('user dismissed the add to homescreen');
				}
				this.deferedPrompt = null;
				this.showBanner = false;
			});
		}
	}

	@HostListener('window:beforeinstallprompt', ['$event'])
	onbeforeinstallprompt(e: any): void {
		e.preventDefault();
		this.deferedPrompt = e;
		// check if mobile platform
		if (this._platform.ANDROID || this._platform.IOS){
			this.showBanner = true;
		}
	}

	@HostListener('window:appinstalled', ['$event'])
	onAppInstalled(e: any): void{
		console.log('app installed');
	}
}
