import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { IStepConfig, IDatasetConfig, IFormConfig } from 'app/interfaces';
import FilterFields from './form.fields';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TripDestinationConfig } from 'app/configs/datasets/trip_destinations';

@Component({
	selector   : 'trip-destination-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss']
})
export class TripDestinationStepComponent extends BaseStepViewComponent implements OnInit, OnDestroy{

	public dynamCols: any[] = [
		{
			name: 'code',
			title: 'Codice',
			primaryKey: true,
			show: true,
		},
		{
			name: 'description',
			title: 'Descrizione',
			primaryKey: false,
			show: true,
		}
	];

	displayedColumns: string[] = ['code', 'description', 'action'];

	formConfig: IFormConfig;

	@Input() public datasetConfig: IDatasetConfig;

	public filterFormGroup: FormGroup;
	public field = FilterFields.get('trip_destination_id');

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected formBuilder: FormBuilder
	){
		super(datasetACS, datasetNavigatorService);

		this.filterFormGroup = this.formBuilder.group({
			trip_destination_id: null,
		});
	}

	ngOnInit(): void {
		this.formConfig = TripDestinationConfig.formConfigsMap['generalForm'];
	}
}
