import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { CurrencyPipe } from '@angular/common';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import pickupPointField from 'app/configs/commons/shared-field-configs/pickup-point.field';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import userField from 'app/configs/commons/shared-field-configs/user.field';
import branchesField from 'app/configs/commons/shared-field-configs/branches.field';
import tripCategoryIdField from 'app/configs/commons/shared-field-configs/trip-category-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();


fieldsMap.set('status', {
	key: 'status',
	name: 'Stato',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	printTransform(value: any): string{
		if(value === 'draft') return 'Preventivo';
		if(value === 'confirmed') return 'Confermata';
		if(value === 'unconfirmed') return 'Non Confermata';
		if(value === 'optional') return 'Opzionale (in scadenza)';
		if(value === 'canceled') return 'Cancellata';
		if(value === 'expired') return 'Scaduta';
		return value;
	}
});

fieldsMap.set('total_amount_net', {
	key: 'total_amount_net',
	name: 'Ammontare Netto',
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('total_amount', {
	key: 'total_amount',
	name: 'Ammontare Lordo',
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('booking_date', {
	key: 'trip_booking_booking_date',
	name: 'Data Prenotazione',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(!record) return html;
		if(record.trip_booking_booking_date){
			const d = new Date(record.trip_booking_booking_date);
			if(!isNaN(d.getTime())){
				html += '<span>' + d.toLocaleDateString() + '</span>';
				html += '<br>';
				html += '<span>' + d.toLocaleTimeString('it-IT', {
					hour: '2-digit', 
					minute: '2-digit'
				}) + '</span>';
			}
		}

		return html;
	}
});


fieldsMap.set('origin_channel_voucher_code', {
	name: 'Numero Voucher Operatore',
	key: 'origin_channel_voucher_code',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		
		if(!record && !record.trip_booking_origin_channel_voucher_code && !record.delivery_code) return;
		if(record.trip_booking_origin_channel_voucher_code){
			html += '<span>' + record.trip_booking_origin_channel_voucher_code + '</span>';				
		}
		if(record.delivery_code){
			html += '<span> Codice Consegna: ' + record.delivery_code + '</span>';				
		}
		return html;
	}
});


/**
 * Start Filter fields
 */

fieldsMap.set('booking_date_from', {
	key: 'booking_date_from',
	name: 'Prenotazioni Dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('booking_date_to', {
	key: 'booking_date_to',
	name: 'Prenotazioni al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('booking_code', {
	name: 'Codice',
	key: 'booking_code',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.TEXT
});

fieldsMap.set('meeting_points_id', cloneFieldDefinition(pickupPointField, {
	showIf(formData, dataContainerService?: IDataContainerService): boolean{
		if(!dataContainerService) return false;
		let modules = dataContainerService.getValueFromKeyPath('domainModules');
		if(!modules){
			modules = dataContainerService.getValueFromKeyPath(dataContainerService.datasetCode + '.domainModules');
		}
		if(modules && modules.ticketing ){
			return modules.ticketing.enabled;
		}
		return false;
	}
}));
fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Pacchetto',
	inputType: InputTypes.DATASET_MULTISELECT,
	valueType: ValueTypes.ARRAY,
	datasetCode: 'trip_packages',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
	}
});
fieldsMap.set('trip_resource_id', cloneFieldDefinition(tripResourceIdField));
fieldsMap.set('user_id', cloneFieldDefinition(userField));
fieldsMap.set('branch_code', cloneFieldDefinition(branchesField));
fieldsMap.set('entity_id', {
	key: 'entity_id',
	name: 'Nominativo',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entities',
	inputHint: 'Scrivi per cercare',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		}
	}
});
fieldsMap.set('entity_customer_id', {
	key: 'entity_customer_id',
	name: 'Cliente',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_customers',
	inputHint: 'Scrivi per cercare',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		}
	}
});

fieldsMap.set('discount_id', {
	key: 'discount_id',
	name: 'Coupon Sconto',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'discounts',
	inputHint: 'Scrivi per cercare',
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any {
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any {
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		}
	}
});

fieldsMap.set('start_day_from', {
	key: 'start_day_from',
	name: 'Partenza Dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('start_day_to', {
	key: 'start_day_to',
	name: 'Partenze fino al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('filter_trip_category_id', tripCategoryIdField);

export {fieldsMap};
