/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./participants-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../components/form-viewer/form-viewer.component.ngfactory";
import * as i5 from "../../../../../services/form-viewer.service";
import * as i6 from "../../../../../services/app.service";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../components/form-viewer/form-viewer.component";
import * as i9 from "@angular/common";
import * as i10 from "./participants-form.component";
import * as i11 from "../../../../components/dataset/services/dataset-action-container.service";
import * as i12 from "../../../../components/dataset/services/dataset-record.service";
import * as i13 from "../../booking.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "../trip-booking-packages-step.service";
var styles_ParticipantsFormComponent = [i0.styles];
var RenderType_ParticipantsFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParticipantsFormComponent, data: {} });
export { RenderType_ParticipantsFormComponent as RenderType_ParticipantsFormComponent };
function View_ParticipantsFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "info-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInfoTrenitalia() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["info"])), (_l()(), i1.ɵted(-1, null, [" Info passeggeri Trenitalia "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ParticipantsFormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "form-viewer", [], null, [[null, "valueChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanges" === en)) {
        var pd_0 = (_co.onValueChanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormViewerComponent_0, i4.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i5.FormViewerService, i5.FormViewerService, [i6.AppService, i7.FormBuilder, i1.NgZone]), i1.ɵdid(3, 770048, [[1, 4], ["formViewer", 4]], 0, i8.FormViewerComponent, [i5.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, { valueChanges: "valueChanges" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParticipantsFormComponent_1)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formConfig; var currVal_1 = _co.datasetACS; var currVal_2 = _co.datasetRS.record.value.participant_counters; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.tripBookingPackagesStepService.canAddTrenitalia; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ParticipantsFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "participants-form", [], null, null, null, View_ParticipantsFormComponent_0, RenderType_ParticipantsFormComponent)), i1.ɵdid(1, 245760, null, 0, i10.ParticipantsFormComponent, [i6.AppService, i11.DatasetActionContainerService, i12.DatasetRecordService, i13.BookingService, i14.MatDialog, i15.TripBookingPackagesStepService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParticipantsFormComponentNgFactory = i1.ɵccf("participants-form", i10.ParticipantsFormComponent, View_ParticipantsFormComponent_Host_0, {}, {}, []);
export { ParticipantsFormComponentNgFactory as ParticipantsFormComponentNgFactory };
