import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';


export const GoingAndReturnConfig: IDatasetConfig = {
	name: 'going_and_returns',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'going_and_returns',
	fieldsMap: fieldsMap,
	supportProperties: false,
	fetchConfig: {
		with_relations: 'goingTrip,returnTrip'
	},
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any) {
				params.with_relations = 'goingTrip,returnTrip';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				columns: [
					{
						title: 'Descrizione',
						key: 'return_description'
					}
				]
			}
		}
	}
};
