<ng-container *ngIf="tripBookingPackage && tripBookingPackage.trip_package">
	<ng-container *ngIf="tripBookingPackage.target == 'trip_packages' || !tripBookingPackage.target">
		<trip-package-booking></trip-package-booking>
	</ng-container>
</ng-container>
<ng-container *ngIf="tripBookingPackage.target == 'product'">
	<product-booking></product-booking>
</ng-container>

<ng-container *ngIf="tripBookingPackage.target == 'trenitalia'">
	<trenitalia-booking
		*ngIf="!tripBookingPackage.parent_trip_booking_package_relation_type"
	></trenitalia-booking>
</ng-container>

<ng-container *ngIf="tripBookingPackage && tripBookingPackage.target == 'milano_card' || tripBookingPackage.target == 'milano_card_offline'">
	<ticket-booking [iterationData]="{is_first: first, is_last: last}"></ticket-booking>
</ng-container>