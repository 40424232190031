import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import textForm from './forms/text.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_destination_language_contents/detail/:recordId',
};

export const TripDestinationLanguageContentConfig: IDatasetConfig = {
	name: 'trip_destination_language_contents',
	title: 'Destinazioni',
	singleTitle: 'Destinazione',
	ajaxDatasetCode: 'trip_destination_language_contents',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		textForm
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Lingua',
						key: 'lang'
					},
					{
						title: 'Descrizione',
						key: 'description'
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
