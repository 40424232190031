import { Injectable } from '@angular/core';
import {Location} from '@angular/common';
import {
  HttpRequest,
  HttpHandler, HttpEventType,
  HttpEvent, HttpResponse, HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class UnauthorizeInterceptor implements HttpInterceptor {

	constructor(public auth: AuthService, private location: Location, public router: Router) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap((event: HttpEvent<any>) => {

				if(event.type == HttpEventType.Response){
					// console.log('intercepted event', request, event);
				}
				if (event instanceof HttpErrorResponse) {
					if (event.status === 401 && this.auth.isAuthenticated()) {
						this.auth.logout();
					}
				}
			}, (event: any) => {
				if (event instanceof HttpErrorResponse) {
					if (event.status === 401) {
						this.auth.userDataObserver.next(null);
						const currentUrl = this.router.routerState.snapshot.url;
						if(currentUrl.startsWith('/auth/reset-password') 
						|| currentUrl.startsWith('/auth/forgot-password')){
							return;
						}
						this.router.navigate(['auth/login']);
					}
				}
			})
		);
	}
}
