import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PopoverModule } from 'app/main/dialogs/popover/popover.module';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DataFilterComponent } from './data-filter.component';
import { FormViewerModule } from '../form-viewer/form-viewer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonDialogsModule } from 'app/main/dialogs/dialogs.module';
import { Style1FilterComponent } from './style-1-filter/data-filter.component';
import { StyleDefaultFilterComponent } from './style-default/data-filter.component';
import { Style01FilterComponent } from './style-01-filter/data-filter.component';

@NgModule({
	declarations: [
		DataFilterComponent,
		StyleDefaultFilterComponent,
		Style1FilterComponent,
		Style01FilterComponent,
	],
	imports     : [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatIconModule,
		MatCardModule,
		MatToolbarModule,
		MatProgressBarModule,
		MatBadgeModule,
		MatTooltipModule,
		FuseSharedModule,
		MatDividerModule,
		TranslateModule.forChild(),
		FormViewerModule,
		FlexLayoutModule,
		CommonDialogsModule,
		PopoverModule
	],
	exports: [
		DataFilterComponent,
		StyleDefaultFilterComponent,
		Style1FilterComponent,
		Style01FilterComponent
	]
})
export class DataFilterModule{}
