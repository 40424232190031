/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "../../components/form-viewer/form-viewer.component.ngfactory";
import * as i7 from "../../../services/form-viewer.service";
import * as i8 from "../../../services/app.service";
import * as i9 from "@angular/forms";
import * as i10 from "../../components/form-viewer/form-viewer.component";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./popover.component";
import * as i16 from "../popover/popover-ref";
import * as i17 from "../popover/popover.service";
var styles_FormPopoverComponent = [i0.styles];
var RenderType_FormPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormPopoverComponent, data: {} });
export { RenderType_FormPopoverComponent as RenderType_FormPopoverComponent };
function View_FormPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FormPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormPopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "content"]], null, [[null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.submit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormPopoverComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormPopoverComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "form-viewer", [], null, null, null, i6.View_FormViewerComponent_0, i6.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i7.FormViewerService, i7.FormViewerService, [i8.AppService, i9.FormBuilder, i1.NgZone]), i1.ɵdid(10, 770048, [[1, 4], ["formViewer", 4]], 0, i10.FormViewerComponent, [i7.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "actions"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "24px"]], null, null, null, null, null)), i1.ɵdid(12, 671744, null, 0, i11.DefaultLayoutDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(13, 1720320, null, 0, i11.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i13.Directionality, i12.StyleUtils, [2, i11.LayoutGapStyleBuilder], i12.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(14, 671744, null, 0, i11.DefaultLayoutAlignDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutAlignStyleBuilder], i12.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "no-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "yes-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.data.formConfig; var currVal_4 = _co.data.dataContainerService; var currVal_5 = _co.data.formData; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "row"; _ck(_v, 12, 0, currVal_6); var currVal_7 = "24px"; _ck(_v, 13, 0, currVal_7); var currVal_8 = "end center"; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.title ? _co.data.title : "Inserisci i dati"); _ck(_v, 2, 0, currVal_0); var currVal_9 = (_co.data.negativeText ? _co.data.negativeText : i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Annulla"))); _ck(_v, 16, 0, currVal_9); var currVal_10 = !i1.ɵnov(_v, 10).formGroup.valid; _ck(_v, 18, 0, currVal_10); var currVal_11 = (_co.data.positiveText ? _co.data.positiveText : i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("Ok"))); _ck(_v, 19, 0, currVal_11); }); }
export function View_FormPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "form-popover", [], null, null, null, View_FormPopoverComponent_0, RenderType_FormPopoverComponent)), i1.ɵdid(1, 4440064, null, 0, i15.FormPopoverComponent, [i8.AppService, i16.PopoverRef, i17.POPOVER_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormPopoverComponentNgFactory = i1.ɵccf("form-popover", i15.FormPopoverComponent, View_FormPopoverComponent_Host_0, {}, {}, []);
export { FormPopoverComponentNgFactory as FormPopoverComponentNgFactory };
