<ng-template #trainBradcrumbs let-train="train" let-last="last">
    <ng-container *ngIf="train.type == 'multi' && train.subSegments">
        <ng-container
            *ngFor="let t of train.subSegments;let last = last;"
            [ngTemplateOutlet]='trainBradcrumbs'
            [ngTemplateOutletContext]='{last:last, train:t}'
        ></ng-container>
    </ng-container>
    <ng-container *ngIf="train.type != 'multi'">
        <img *ngIf="trainTypeImages[train.value]"  [src]="trainTypeImages[train.value]" class="train-icon"/>
        <small class="train-name"> {{train.name}}</small>
    </ng-container>
    <span *ngIf="!last"> / </span>
</ng-template>

<div>
<span>
    {{solutionData.start.stationName}}
    <mat-icon>keyboard_arrow_right</mat-icon>
    {{solutionData.end.stationName}}
</span>
</div>
<mat-card *ngFor="let solution of solutions " (click)="auth(solution.offers)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex>
            <div class="train-breacrumbs">
                <ng-container
                    *ngFor="let t of solution.trains;let last = last;"
                    [ngTemplateOutlet]='trainBradcrumbs'
                    [ngTemplateOutletContext]='{last:last, train:t}'
                ></ng-container>
            </div>
            <div fxLayout="row"  fxLayoutGap="12px">
                <div class="solution" fxFlex="40
">{{solution.title | lowercase}}</div>
                <div fxFlex="30">
                    <span *ngFor="let level of solution.levels;let last = last;">
                        <span class="level">{{level.value}}</span>
                        <span *ngIf="!last" class="level"> / </span>
                    </span>
                </div>
            </div>
        </div>
        <div fxFlex style="text-align: right">
            <span class="tr-price"
                  matTooltip="{{solution.fee>0?'Prezzo:€ '+solution.netPrice+' + Diritti agenzia:€ '+solution.fee:''}}"
            >{{solution.price | currency:'EUR':'symbol':'1.2-2'}}</span>
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</mat-card>
