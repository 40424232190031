import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';

import {Subject} from 'rxjs';
import {takeUntil, distinctUntilChanged} from 'rxjs/operators';

import {InputTypes, IFormConfig, IFieldDefinition, IFieldContainer, isFieldContainer} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { FormViewerService } from 'app/services/form-viewer.service';
import * as _ from 'lodash';

@Component({
	selector   : 'form-viewer',
	templateUrl: './form-viewer.component.html',
	styleUrls  : ['./form-viewer.component.scss'],
	animations : fuseAnimations,
	providers: [FormViewerService]
})
export class FormViewerComponent implements OnInit, OnDestroy, OnChanges{

	@Input() public formConfig: IFormConfig;
	@Input() dataContainerService: IDataContainerService;
	@Input() record: any;
	@Input() errors: any;
	@Input() readOnly = false;
	@Output() valueChanges = new EventEmitter<FormViewerService>();

	public formViewInfo: any; 

	private _unsubscribeAll: Subject<any>;
	public inputTypes = InputTypes;

	get formGroup(): FormGroup{
		return this.formViewerService.formGroup;
	}

	constructor(
		public formViewerService: FormViewerService,
		private cd: ChangeDetectorRef
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.formViewerService.init({
			errors: this.errors,
			fields: this.formConfig.fields,
			dataContainerService: this.dataContainerService
		});

		this.formViewerService.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.onFormValuesChanged();
		});

		this.formViewerService.formBuilded
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.buildFormView();
		});

		this.buildFormView();
	}

	buildFormField(field: IFieldContainer | IFieldDefinition){
		if(!field) return;
		if(isFieldContainer(field)){
			return {
				...field,
				isGroup: true,
				fields: field.fields.map(this.buildFormField.bind(this)).filter(field => field)
			}
		}else{
			const control = this.formViewerService.controlKeyMap[field.key] && this.formViewerService.formGroup.controls[this.formViewerService.controlKeyMap[field.key]];
			const isReadOnly = this.readOnly || field.inputReadOnly || (field.inputConfig && field.inputConfig.readOnly);
			if(!control && !isReadOnly) return;
			return {
				isGroup: false,
				isReadOnly,
				class: "form-input-viewer_"+((field.inputConfig && field.inputConfig.type) || field.inputType),
				flex: '1 1 auto',
				formInputDefinition: field,
				maxWidth: field.maxWidth,
				width: field.width,
				controlKey: this.formViewerService.controlKeyMap[field.key]
			}
		}
	}

	buildFormView(){
		const newFormBuilded = {
			style: this.formConfig.formStyle || "style-1",
			fields: [],
			formGroup: this.formViewerService.formGroup
		};

		if(this.formConfig.fields){
			newFormBuilded.fields = this.formConfig.fields.map(this.buildFormField.bind(this)).filter(field => field);
		}

		console.log('newFormBuilded', newFormBuilded);
		this.formViewInfo = newFormBuilded;
		this.cd.detectChanges();
	}

	/**
	 * If use multipart return FormData else an object
	 */
	getFormData(): any{
		return this.formViewerService.getFormData();
	}

	trackByFn(index): any{
		return index;
	}

	onFormValuesChanged(): void{
		this.valueChanges.emit(this.formViewerService);
		if(this.formConfig.onChanged){
			this.formConfig.onChanged(this.formViewerService.formGroup);
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	clearForm(): void{
		this.formViewerService.formGroup.reset();
	}

	setErrors(errors): void{
		this.formViewerService.setErrors(errors);
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.record && (!changes.record.isFirstChange() || !changes.record.previousValue)){
			this.formViewerService.setRecord(this.record);
		}
	}
}
