import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general-form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import activeLanguageCodesField from 'app/configs/commons/shared-field-configs/active-language-codes.field';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/payment_types/detail/:recordId',
};

export const PaymentTypeConfig: IDatasetConfig = {
	name: 'payment_types',
	title: 'Metodo di pagamenti',
	singleTitle: 'Metodo di pagamento',
	ajaxDatasetCode: 'payment_types',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Nome',
						key: 'name'
					},
					{
						title: 'Descrizione',
						key: 'descrizione'
					},
					{
						title: 'Pagamento diretto',
						key: 'direct_payment',
					},
					{
						title: '# Rate',
						key: 'num_rate',
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
