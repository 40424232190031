<ng-container [ngSwitch]='fieldDefinition.fieldView'>

	<ng-container *ngSwitchCase='fieldViews.STORABLE_DATE'>
		<div class='info-field'>
			<span class='info-head'><strong>{{fieldDefinition.rawName ? fieldDefinition.rawName : (fieldDefinition.name | titlecase)}} :</strong></span>
			<span class='info-value'>
				<span>{{computedValue}}</span>
				<a
					matTooltip='Aggiungi al tuo calendario'
					target="_blank" rel="nofollow"
					*ngIf='isSetted && fieldDefinition.getOptionParams' color='accent' mat-button 
					[href]='getHrefEventToCalendar(fieldDefinition.getOptionParams(dataContainerService, record, "save_on_google_calendar"))' >
					<mat-icon>today</mat-icon>
				</a>
			</span>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase='fieldViews.LINK'>
		<div class='info-field info-field_link'>
			<span class='info-head'><strong>{{fieldDefinition.rawName ? fieldDefinition.rawName : (fieldDefinition.name | titlecase)}} :</strong></span>
			<a class='info-value' [href]='computedValue' target="_blank">{{computedValue}}</a>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase='fieldViews.CUSTOM_ICON'>
		<div class='info-field info-field_link'>
			<span class='info-head'><strong>{{fieldDefinition.rawName ? fieldDefinition.rawName : (fieldDefinition.name | titlecase)}} :</strong></span>
			<i [class]='computedValue'></i>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase='fieldViews.HTML'>
		<div class='info-field info-field_html'>
			<span class='info-head'><strong>{{fieldDefinition.rawName ? fieldDefinition.rawName : (fieldDefinition.name | titlecase)}} :</strong></span>
			<span class='info-value' [innerHTML]='computedValue'></span>
		</div>
	</ng-container>

	<ng-container *ngSwitchCase="fieldViews.HIDDEN"></ng-container>
	
	<ng-container *ngSwitchDefault>
		<div class='info-field'>
			<span class='info-head'><strong>{{fieldDefinition.rawName ? fieldDefinition.rawName : (fieldDefinition.name | titlecase)}} :</strong></span>
			<span *ngIf='!loading' class='info-value' [class.clickable-info-value]='fieldDefinition.clickOptions' (click)='onClick()' > {{computedValue}} </span>
			<span class='info-value' *ngIf='loading'><mat-spinner diameter="20"></mat-spinner></span>
		</div>
	</ng-container>
</ng-container>
