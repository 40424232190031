import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { GOOGLE_API_KEY } from 'app/app.module';
import { FormControl } from '@angular/forms';
var CommonMapStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonMapStepComponent, _super);
    function CommonMapStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog, mapAPILoader) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.mapAPILoader = mapAPILoader;
        _this.markers = [];
        // google maps zoom level
        _this.zoom = 8;
        // initial center position for the map
        _this.lat = 45.468702;
        _this.lng = 9.190789;
        _this.mapReady = new BehaviorSubject(false);
        _this.searchTextFormControl = new FormControl('');
        _this.geosearching = false;
        return _this;
    }
    Object.defineProperty(CommonMapStepComponent.prototype, "record", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonMapStepComponent.prototype, "staticMapUrl", {
        get: function () {
            return this.record.point_map_url;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonMapStepComponent.prototype, "savedCoords", {
        get: function () {
            if (!this.record)
                return null;
            if (!this.record.properties)
                return null;
            if (!this.record.properties.geo_position || !this.record.properties.geo_position.value)
                return null;
            if (typeof (this.record.properties.geo_position.value) === 'string')
                return JSON.parse(this.record.properties.geo_position.value);
            return this.record.properties.geo_position.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonMapStepComponent.prototype, "datasetCode", {
        get: function () {
            return this.datasetACS.datasetCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommonMapStepComponent.prototype, "address", {
        get: function () {
            if (['meeting_points', 'trip_destinations'].includes(this.datasetCode)) {
                return this.reduceAddress(this.record && this.record.address);
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    CommonMapStepComponent.prototype.reduceAddress = function (obj) {
        var address = '';
        if (obj) {
            if (obj.street)
                address += obj.street;
            if (obj.number)
                address += ' ' + obj.number;
            if (address && (obj.city || obj.province || obj.zip_code || obj.region || obj.nation_code))
                address += ', ';
            if (obj.city)
                address += ' ' + obj.city;
            if (obj.province)
                address += ' (' + obj.province + ')';
            if (obj.zip_code)
                address += ' ' + obj.zip_code;
            if (obj.region)
                address += ' ' + obj.region;
            if (obj.nation_code)
                address += ' ' + obj.nation_code;
        }
        return address;
    };
    CommonMapStepComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initialLocation()
            .catch(function (e) {
            console.error(e);
        });
    };
    CommonMapStepComponent.prototype.search = function () {
        var _this = this;
        if (!this.searchTextFormControl.value || this.searchTextFormControl.value.length < 2)
            return;
        this.geosearching = true;
        this.getAddressInfo(this.searchTextFormControl.value)
            .then(function (result) {
            _this.setPosition(result);
            _this.geosearching = false;
        }, function (error) {
            _this.geosearching = false;
        });
    };
    CommonMapStepComponent.prototype.initialLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mapAPILoader.load()];
                    case 1:
                        _a.sent();
                        if (this.savedCoords && this.savedCoords.lat && this.savedCoords.lng) {
                            this.setCoordinate(this.savedCoords.lat, this.savedCoords.lng);
                            if (this.savedCoords.zoom)
                                this.zoom = this.savedCoords.zoom;
                        }
                        else {
                            this.getAddressInfo(this.address)
                                .then(function (result) {
                                _this.setPosition(result);
                            }, function (error) {
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CommonMapStepComponent.prototype.setMarker = function (data) {
        if (this.markers.length) {
            this.markers[0].lat = data.lat;
            this.markers[0].lng = data.lng;
            this.markers[0].label = data.label;
        }
        else {
            this.markers.push({
                lat: data.lat,
                lng: data.lng,
                draggable: true,
                label: data.label
            });
        }
        this.lat = this.markers[0].lat;
        this.lng = this.markers[0].lng;
        if (this.zoom < 12)
            this.zoom = 16;
        this.fetchMarkerInfo(this.markers[0]);
    };
    CommonMapStepComponent.prototype.getAddressInfo = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var geocoder;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mapAPILoader.load()];
                    case 1:
                        _a.sent();
                        geocoder = new window['google'].maps.Geocoder();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                geocoder.geocode({
                                    address: address
                                }, function (results, status) {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        if (results.length > 0 && results[0]) {
                                            return resolve(results[0]);
                                        }
                                    }
                                    reject();
                                });
                            })];
                }
            });
        });
    };
    CommonMapStepComponent.prototype.getCoordinateInfo = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var geocoder;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mapAPILoader.load()];
                    case 1:
                        _a.sent();
                        geocoder = new window['google'].maps.Geocoder();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                geocoder.geocode({
                                    location: {
                                        lat: lat, lng: lng
                                    }
                                }, function (results, status) {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        if (results.length > 0 && results[0]) {
                                            return resolve(results[0]);
                                        }
                                    }
                                    reject();
                                });
                            })];
                }
            });
        });
    };
    CommonMapStepComponent.prototype.fetchSelectedPositionInfo = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCoordinateInfo(lat, lng)];
                    case 1:
                        _a.selectedPositionInfo = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CommonMapStepComponent.prototype.fetchMarkerInfo = function (marker) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getCoordinateInfo(marker.lat, marker.lng)];
                    case 1:
                        _a.selectedPositionInfo = _b.sent();
                        marker.label = this.selectedPositionInfo.formatted_address;
                        marker.meta = this.selectedPositionInfo;
                        return [2 /*return*/];
                }
            });
        });
    };
    CommonMapStepComponent.prototype.mapClicked = function (event) {
        this.setCoordinate(event.coords.lat, event.coords.lng);
    };
    CommonMapStepComponent.prototype.clickedMarker = function (label, index) {
    };
    CommonMapStepComponent.prototype.markerDragEnd = function (m, $event) {
        this.setMarker({
            lat: $event.coords.lat,
            lng: $event.coords.lng
        });
    };
    CommonMapStepComponent.prototype.generateStaticMap = function () {
        var e_1, _a;
        if (!this.selectedPositionInfo)
            return null;
        var url = 'https://maps.googleapis.com/maps/api/staticmap?';
        url += '&zoom=' + this.zoom;
        url += '&size=600x300';
        url += '&maptype=roadmap';
        if (this.markers && this.markers.length > 0) {
            try {
                for (var _b = tslib_1.__values(this.markers), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var marker = _c.value;
                    url += '&markers=color:red|' + marker.lat + ',' + marker.lng;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            url += '&center=' + this.selectedPositionInfo.geometry.location.lat() + ',' + this.selectedPositionInfo.geometry.location.lng();
        }
        url += '&key=' + GOOGLE_API_KEY;
        return url;
    };
    CommonMapStepComponent.prototype.onSave = function () {
        var _this = this;
        if (!this.selectedPositionInfo)
            return;
        var message = 'Vuoi salvare solo la mappa o anche l\'indirizzo?';
        if (this.address)
            message += '<br>Indirizzo attuale: "' + this.address + '" ';
        var newAddressObj = this.componentsToAddressObject(this.selectedPositionInfo.address_components);
        message += '<br>Nuovo Indirizzo: "' + this.reduceAddress(newAddressObj) + '"';
        this.matDialog.open(ConfirmDialogComponent, {
            minWidth: '550px',
            data: {
                contentMessage: message,
                positiveText: 'Salva tutto',
                negativeText: 'Annulla',
                neutralText: 'Solo la mappa'
            }
        }).afterClosed()
            .subscribe(function (result) {
            if (result === false)
                return;
            _this.execSave(result === true ? 'full' : 'only');
        });
    };
    CommonMapStepComponent.prototype.componentsToAddressObject = function (components) {
        var e_2, _a;
        var addressObj = {};
        try {
            for (var components_1 = tslib_1.__values(components), components_1_1 = components_1.next(); !components_1_1.done; components_1_1 = components_1.next()) {
                var component = components_1_1.value;
                if (component.types.includes('street_number')) {
                    addressObj.number = component.long_name;
                }
                else if (component.types.includes('route')) {
                    addressObj.street = component.long_name;
                }
                else if (component.types.includes('administrative_area_level_3')) {
                    addressObj.city = component.long_name;
                }
                else if (component.types.includes('locality')) {
                    addressObj.locality = component.long_name;
                }
                else if (component.types.includes('administrative_area_level_2')) {
                    addressObj.province = component.short_name;
                }
                else if (component.types.includes('administrative_area_level_1')) {
                    addressObj.region = component.long_name;
                }
                else if (component.types.includes('country')) {
                    addressObj.nation_code = component.short_name;
                }
                else if (component.types.includes('postal_code')) {
                    addressObj.zip_code = component.long_name;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (components_1_1 && !components_1_1.done && (_a = components_1.return)) _a.call(components_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return addressObj;
    };
    CommonMapStepComponent.prototype.execSave = function (saveType) {
        var _this = this;
        var formData = {
            recordId: this.datasetRS.recordId,
            saveType: saveType,
            geoPosition: {
                lat: this.selectedPositionInfo.geometry.location.lat(),
                lng: this.selectedPositionInfo.geometry.location.lng(),
                zoom: this.zoom
            },
            staticMapURL: this.generateStaticMap()
        };
        if (this.stepConfig.options && typeof (this.stepConfig.options.getCallParams) === 'function') {
            formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
        }
        formData['address'] = this.componentsToAddressObject(this.selectedPositionInfo.address_components);
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/command/set_map_position';
        this.datasetService.post(route, formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: DatasetEvents.EDITED });
                _this.datasetRS.reload()
                    .subscribe(function (value) {
                    _this.goNextStep();
                });
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
            }
        });
    };
    CommonMapStepComponent.prototype.setPosition = function (result) {
        if (this.datasetCode === 'meeting_points') {
            this.setMarker({
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng()
            });
        }
        else {
            this.lat = result.geometry.location.lat();
            this.lng = result.geometry.location.lng();
            this.fetchSelectedPositionInfo(this.lat, this.lng);
        }
    };
    CommonMapStepComponent.prototype.setCoordinate = function (lat, lng) {
        if (this.datasetCode === 'meeting_points') {
            this.setMarker({
                lat: lat,
                lng: lng
            });
        }
        else {
            this.lat = lat;
            this.lng = lng;
            this.fetchSelectedPositionInfo(lat, lng);
        }
    };
    CommonMapStepComponent.prototype.resetPosition = function () {
        this.markers = [];
        this.selectedPositionInfo = null;
        this.initialLocation();
    };
    return CommonMapStepComponent;
}(BaseStepViewComponent));
export { CommonMapStepComponent };
