import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { fieldsMap as entityFieldMap} from '../entities/fields';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';

const infoFieldsMap: Map<string, IFieldDefinition> = new Map();

entityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: value.key });
	infoFieldsMap.set(key, value);
});

addressFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'primary_address.' + value.key });
	infoFieldsMap.set('primary_address.' + key, value);
});

/*infoFieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
});*/

infoFieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'info';
	}
});

export {infoFieldsMap};
