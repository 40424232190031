<ng-template #tripBookingPackageSummaryTemplate let-bookingPackage="bookingPackage">
	<div class="summary trip_packages_trips" *ngIf="bookingPackage.actual_trip" fxLayout="column" [ngClass]="{'is_return': bookingPackage.actual_trip.type == 'return'}">
		<ng-container *ngIf="bookingPackage.actual_trip.type !== 'return'">
			<div class="trip_package" fxLayout="column">
				<div class="label">{{'Pacchetto' | translate}}</div>
				<div fxLayout="row" fxLayoutAlign="space-between center">
					<span class="value">{{ bookingPackage.trip_package.description }}</span>
					<span class="value price">{{ bookingPackage.total_amount | currency:"EUR" }}</span>
				</div>
				<!-- show status package -->
				<ng-container *ngIf="isMixBookingStatus">
					<div *ngIf="bookingPackage.booking_status === 'canceled'">
						<span class="red-fg">{{'Cancellata' | translate}}</span>
					</div>
					<div *ngIf="bookingPackage.booking_status === 'confirmed'">
						<span class="green-fg">{{'Confermata' | translate}}</span>
					</div>
				</ng-container>
			</div>
		</ng-container>
		<div fxLayout="column">
			<div class="label" *ngIf="bookingPackage.actual_trip.type !== 'return'">{{'Partenza' | translate}}</div>
			<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
				<span class="label" *ngIf='bookingPackage.actual_trip.type !== "return"'>{{ "Andata" | translate }}:</span>
				<span class="label" *ngIf='bookingPackage.actual_trip.type == "return"'>{{ "Ritorno" | translate }}:</span>
				<div class="value" fxLayout="column">
					<span class="value">{{ bookingPackage.actual_trip.display_name }}</span>
					<span class="departure_date_time">
						<span class="start_day">{{ bookingPackage.actual_trip.start_day | date:'dd/MM/yyyy' }} </span>
						<ng-container *ngIf="bookingPackage.actual_trip.start_time">
							<span class="start_time">{{ bookingPackage.actual_trip.start_time.substring(0, 5) }}</span>
						</ng-container>
					</span>
				</div>
			</div>
		</div>
		<ng-container *ngIf='bookingPackage.trip_booking_resources.length > 0'>
			<div fxLayout="column">
				<div class="label" *ngIf='hasOptionalResourcesEnabled[bookingPackage.id]'>{{'Opzionale' | translate}}</div>
				<ul style="margin-top: 0;">
					<ng-container *ngFor='let bookingResource of bookingPackage.trip_booking_resources'>
						<li *ngIf='bookingResource.optional && bookingResource.activated'>
							<span style="padding-right: 5px;">{{ bookingResource.trip_resource.description }}</span>
							<span *ngIf='bookingResource.total_amount'>{{ bookingResource.total_amount | currency:'EUR' }}</span>
						</li>
					</ng-container>
				</ul>
			</div>
		</ng-container>
	</div>
	<div class="summary trip_packages_trips" *ngIf="bookingPackage.target == 'trenitalia'">
		<trenitalia-package [inputTripBookingPackage]="bookingPackage"></trenitalia-package>
	</div>
    <div class="summary trip_packages_trips" *ngIf="bookingPackage.target == 'product'">
        <product-package [inputTripBookingPackage]="bookingPackage" [mix]="isMixBookingStatus"></product-package>
    </div>
</ng-template>

<div class="trip_booking_summary flex-col justify-start items-start flex-gap-16">
	<div class='p-10' *ngIf="datasetRS.loading.value">
		<mat-progress-bar style='min-width:300px' mode="indeterminate"></mat-progress-bar>
	</div>
	<div class="entities flex-col flex-gap-8">
		<div *ngIf="extraParams['channel_value']" class="summary" fxLayout="column">
			<div class="label">{{'Canale' | translate}}</div>
			<div class="value">{{ extraParams['channel_value'] }}</div>
		</div>
		<div *ngIf="!datasetRS.record.value.ref_id && extraParams['user_name']" class="summary" fxLayout="column">
			<div class="label">{{'Utente' | translate}}</div>
			<div class="value">{{ extraParams['user_name'] }}</div>
		</div>
		<div *ngIf="datasetRS.record.value.ref_id" class="summary" fxLayout="column">
			<div class="label">{{'Rivendita' | translate}}</div>
			<div class="value">Tangerine</div>
		</div>
		<ng-container *ngIf="datasetRS.record.value.entity && datasetRS.record.value.entity.description">
			<div class="summary entity" fxLayout="column">
				<div class="label">{{'Intestatario' | translate}}</div>
				<div class="value">{{ datasetRS.record.value?.entity?.description }}</div>
			</div>
		</ng-container>
		<ng-container *ngIf="datasetRS.record.value.customer && datasetRS.record.value.customer.entity && datasetRS.record.value.customer.entity.description">
			<div class="summary entity_customer" fxLayout="column">
				<div class="label">{{'Cliente' | translate}}</div>
				<div class="value">{{ datasetRS.record.value?.customer?.entity?.description }}</div>
			</div>
		</ng-container>
		<ng-container *ngIf="datasetRS.record.value.participant_count > 0">
			<div class="summary participants" fxLayout="column">
				<div class="label">{{'Partecipanti' | translate}}</div>
				<div class="value particiapnts_data">
					<ng-container *ngFor='let participant of datasetRS.record.value.participant_counters | keyvalue'>
						<ng-container *ngIf="participant.key !== 'total'">
							<div fxLayout="row" fxLayoutGap="6px">
								<div class="label">{{ participant.key }}</div>
								<div class="value">{{ participant.value }}</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
	<ng-container *ngFor="let bookingPackage of datasetRS.record.value.packages">
		<ng-container 
			[ngTemplateOutlet]='tripBookingPackageSummaryTemplate' 
			[ngTemplateOutletContext]='{bookingPackage:bookingPackage}'
		></ng-container>

		<ng-container *ngIf="bookingPackage.children_trip_booking_packages && !['trenitalia'].includes(bookingPackage.target)">
			<ng-container *ngFor="let childBookingPackage of bookingPackage.children_trip_booking_packages">
				<ng-container 
					[ngTemplateOutlet]='tripBookingPackageSummaryTemplate' 
					[ngTemplateOutletContext]='{bookingPackage:childBookingPackage}'
				></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</div>