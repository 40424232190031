import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { IConfirmDialogData } from '../confirm-dialog/dialog.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import {takeUntil} from 'rxjs/operators';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
import { BOOKING_SERVICE_TOKEN } from 'app/configs/commons/provide-tokens';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { IPaginationResponse } from 'app/interfaces';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material';
import { getHtmlBookingWithCodeAndStatus } from 'app/configs/datasets/trip_booking_report_views/list_columns';


interface IDeleteAndRefundDialogComponentData extends IConfirmDialogData {
	actual_trip_id: string
}

@Component({
	selector: 'delete-and-refund-dialogs',
	templateUrl: 'dialog.component.html',
	styleUrls  : ['./dialog.component.scss'],
	providers: []
})
export class DeleteAndRefundDialogComponent implements OnDestroy, OnInit {
	loading = false;
	protected _unsubscribeAll: Subject<any>;
	displayedColumns: string[] = ['trip_booking_code', 'reservation', 'trip_booking_entity_customer_name', 'total_amount', 'refundableType', 'linkToResource', 'check'];	// refundableType = 'automatic' | 'manual'
	reservations: any[] = [];
	protected onReservationProcessed = new Subject<any>();
	currentReservationsProcessed = false;
	pageMeta = {
		last: true,
		perPage: 20,
		page: 1,
		total: 0
	};
	processMode: 'cancel' | 'cancel_and_refund' | null = null;
	reservationCancelSubscriptions: Record<string, Subscription> = {};
	tableDataSource: MatTableDataSource<any> = new MatTableDataSource()

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<DeleteAndRefundDialogComponent>,
		public datasetService: DatasetService,
		public datasetNavigationService: DatasetNavigatorService,
		@Inject(MAT_DIALOG_DATA) public data: IDeleteAndRefundDialogComponentData
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.onReservationProcessed
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((result) => {
			this.updateCurrentReservationsProcessed();
		});

		this.loadBookings();
	}

	updateCurrentReservationsProcessed(){
		this.currentReservationsProcessed = this.reservations.reduce((c, r) => {
			if([true, 'error'].includes(r.check)) return c;
			return false;
		}, true);
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onDeleteAndRefund(): void {
		this.processMode = 'cancel_and_refund';
		this.processReservations(true);
	}

	onDelete(): void {
		this.processMode = 'cancel';
		this.processReservations();
	}

	loadBookings(){
		if(!this.currentReservationsProcessed) return; // prevent change items while processing
		const params = {
			actual_trip_id: this.data.actual_trip_id,
			with_relations: 'tripBooking,tripBookingRepostView',
			with_attributes: 'method_payment_code',
			booking_status: 'confirmed',
			only_parents: 1,
			perPage: 30
		};

		this.datasetService.get<IPaginationResponse<any>>("/dataset/trip_booking_packages", params)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(response => {
			if(!response || !response.items) return;
			const idsSet = new Set(this.reservations.map(booking => booking.id));
			this.reservations = response.items.map(el => {
				return {
					...el,
					is_automatic_refund_enabled: el.available_refund_methods && el.available_refund_methods.includes('automatic'),
					linkToResource: this.datasetNavigationService.getEditRoute('trip_bookings', el.trip_booking_id),
					check: undefined, // undefined (inizio) | true | false | loading | error,
					refund_result: undefined,
					refund_success: undefined,
					column_code_html: getHtmlBookingWithCodeAndStatus(el.tripBooking.code, el.booking_status)
				};
			});
			this.pageMeta = response.meta;
			this.updateCurrentReservationsProcessed();
		});
	}

	processReservations(withRefund = false): void {
		for(let i = 0; i < this.reservations.length; i++){
			const reservation = this.reservations[i];
			this.processReservation(reservation)
		}
	}

	processReservation(reservation){
		if(reservation.trip_booking_package_status != 'confirmed') return;
		reservation.check = 'loading';
		const withRefund = this.processMode == 'cancel_and_refund';
		this.reservationCancelSubscriptions[reservation.id] = this.datasetService.post<any>('/dataset/trip_bookings/command/cancel_booking', {
			trip_booking_id: reservation.trip_booking_id,
			trip_booking_package_ids: [reservation.id],
			with_refund: withRefund
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				Object.assign(reservation, {
					column_code_html: getHtmlBookingWithCodeAndStatus(reservation.tripBooking.code, 'canceled'),
					check: !['TRUE', 'true', true].includes(response.success),
					refund_result: response.refund_result,
					refund_success: !withRefund || _.get(response, 'refund_result.success') === true
				});
				if(!reservation.check){
					this.appService.showErrorMessage(response.message || 'Si è verificato un errore in una delle prenotazioni');	
				}
				this.onReservationProcessed.next({reservation});
			},
			error: err => {
				reservation.check = 'error';
				this.appService.showErrorMessage('C\'è stato un errore nell\'eliminazione');
			},
			complete: () => {
				delete this.reservationCancelSubscriptions[reservation.id]
			}
		});
	}

	// stopLoading(stopLoading): void{
	// 	for(const l of stopLoading){
	// 		if(!l) return;
	// 	}
	// 	this.loading = false;
	// 	// this.dialogRef.close();
	// }

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
