import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as resourceFieldMap} from '../trip_resources/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_resource_id', {
	key: 'trip_resource_id',
	name: 'Risorsa',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_resources',
	inputHint: 'Scrivi per cercare una risorsa',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {};
			// const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');

			// if(tripPackageId) params['exclude_resources_of_trip_package_id'] = tripPackageId;
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
});

fieldsMap.set('meeting_point_unified_list_id', {
	key: 'meeting_point_unified_list_id',
	name: 'Lista punti di ritrovo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_point_unified_lists',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, data: any): any{
			const params = {
				'properties_parent_dataset_code': 'trip_packages'
			};
			// console.log('meeting_point_list_id.getParams', dataContainerService);
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			if(tripPackageId){
				params['properties_parent_id'] = tripPackageId;
			}
			return params;
		},
		getLabel(option: any): string{
			if(!option) return '---';
			let label = option.description;
			if(option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-'){
				label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
			}else if(option.items_count > 0){

			}
			return label;
		},
		getValue(option): string{
			return option.id;
		}
	}
});

fieldsMap.set('meeting_point_unified_list_description', {
	key: 'meeting_point_unified_list_description',
	name: 'Lista Punti di ritrovo',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.meeting_point_unified_list) return '---';
		let description = record.meeting_point_unified_list.description;
		if(record.meeting_point_unified_list.properties
			&& record.meeting_point_unified_list.properties.first_time
			&& record.meeting_point_unified_list.properties.first_time.value
			&& record.meeting_point_unified_list.properties.first_time.value !== '-'){
			description += ' (' + record.meeting_point_unified_list.properties.first_time.value.substring(0, 5) + ')';
		}
		return description;
	}
});

fieldsMap.set('optional_resource', {
	key: 'optional_resource',
	name: 'Tipologia',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return 0;
		return record.optional_resource;
	},
	options: [
		{
			label: 'Inclusa',
			value: 0
		},
		{
			label: 'Opzionale',
			value: 1
		},
		{
			label: 'Primaria',
			value: 2
		}
	]
});

fieldsMap.set('resource_code', cloneFieldDefinition(resourceFieldMap.get('code'), {key: 'trip_resource.code'}));
fieldsMap.set('resource_description', cloneFieldDefinition(resourceFieldMap.get('description'), {key: 'trip_resource.description'}));

export {fieldsMap};
