<h1 mat-dialog-title>{{data.title?data.title:'Conferma' | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<form-viewer #formViewer
		[dataContainerService]='data.datasetACS' 
		[formConfig]='data.formConfig'
	></form-viewer>

</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="close()">{{'Chiudi' | translate}}</button>
	<button mat-flat-button (click)='onAddClick()' color='accent' >{{'Aggiungi' | translate}}</button>
</mat-dialog-actions>
