import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { InputTypes, IFieldDefinition, ValueTypes, IFieldContainer, isFieldContainer } from 'app/interfaces';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { FieldHelper } from 'app/helpers';
import { formGroupToFormData } from 'app/helpers/field.helper';
import * as _ from 'lodash';

export interface IFormViewerServiceConfig{
	errors?: any;
	record?: any;
	fields?: (IFieldContainer|IFieldDefinition)[];
	dataContainerService?: IDataContainerService;
}

@Injectable()
export class FormViewerService implements OnDestroy{
	public formGroup: FormGroup;
	private _unsubscribeAll: Subject<any>;
	public useMultipart = false;
	public inputTypes = InputTypes;
	public controlKeyMap = {};
	public dataContainerService: IDataContainerService;
	public record: any;
	public fields: (IFieldContainer|IFieldDefinition)[];
	public readOnly = false;
	public errors: any;
	public initialized: BehaviorSubject<boolean>;
	public valueChanges: Subject<any>;
	public formBuilded: Subject<FormGroup>;
	public valueChangesSubscription: Subscription;
	
	constructor(
		public appService: AppService,
		public formBuilder: FormBuilder,
		public zone: NgZone
	){
		this._unsubscribeAll = new Subject();
		this.valueChanges = new Subject();
		this.initialized = new BehaviorSubject(false);
		this.formBuilded = new Subject();

		this.formBuilded
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			if(this.valueChangesSubscription) this.valueChangesSubscription.unsubscribe();
			this.valueChangesSubscription = this.formGroup.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.pipe(distinctUntilChanged(_.isEqual))
			.subscribe((value) => {
				this.valueChanges.next(value);
			});
		});
	}

	init(config: IFormViewerServiceConfig): Observable<boolean>{
		if(config.dataContainerService) this.dataContainerService = config.dataContainerService;
		if(config.record) this.record = config.record;
		if(config.fields) this.fields = config.fields;

		this.buildForm();
		if(config.errors){
			this.setErrors(config.errors);
		}

		if(this.dataContainerService){
			this.dataContainerService.datasetDataChanged
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this.buildForm();
			});
		}
		setTimeout(() => {
			this.initialized.next(true);
			this.initialized.complete();
		}, 50);
		return this.initialized;
	}

	getFieldControl(key: string){
		const controlKey = _.get(this.controlKeyMap, key);
		if(!controlKey) return;
		const control = _.get(this.formGroup.controls, controlKey);
		return control;
	}

	getFieldControlKey(field: IFieldDefinition): string{
		if(!field) return null;
		let fieldName = field.key;
		if(field.valueType === ValueTypes.PROPERTY){
			if(this.record && this.record.properties && this.record.properties[field.key]){
				fieldName = this.record.properties[field.key].property_definition_id;
			}else{
				const propertyDefinition = FieldHelper.getPropertyDefinition(this.dataContainerService, field.key, this.dataContainerService.getValueFromKeyPath('datasetCode'));
				if(propertyDefinition){
					fieldName = propertyDefinition.id;
				}
			}
		}
		return fieldName;
	}

	buildForm(): void{
		this.zone.runOutsideAngular(() => {
			const formGroup = this.formBuilder.group({});
			this.useMultipart = false;
			this.addGroupConfig(formGroup, this.fields);
			this.zone.run(() => {
				this.formGroup = formGroup;
				this.formBuilded.next(this.formGroup);
			});
		});
	}

	addGroupConfig(formGroup: FormGroup, fields: (IFieldContainer|IFieldDefinition)[], noDuplicateField = false): void{
		if(!fields) return;
		for (const field of fields) {
			if(!field){
				console.warn('undefined field in form config');
				continue;
			}
			if(isFieldContainer(field)){
				this.addGroupConfig(formGroup, (<IFieldContainer>field).fields);
				continue;
			}else{
				if(field && field.inputConfig && field.inputConfig.type === InputTypes.READONLY) continue;
				if(typeof(field.skipIf) == 'function' && field.skipIf(this.record, this.dataContainerService)) continue;
				const fieldName = this.getFieldControlKey(field);
				if(formGroup.controls[fieldName]){
					if(noDuplicateField) continue;
					console.warn('form controll just exist with this name', fieldName);
				}
				let defaultValue: any;
				if(field.defaultValue){
					if(field.optionSource && this.dataContainerService.hasValueInKeyPath(field.optionSource)){
						defaultValue = field.defaultValue(this.dataContainerService, this.dataContainerService.getValueFromKeyPath(field.optionSource + '.' + field.optionSourceKey), this.record);
					}else{
						defaultValue = field.defaultValue(this.dataContainerService, null, this.record);
					}
				}
				const value = FieldHelper.getFieldValue(field, this.record, defaultValue, this.dataContainerService);

				const control = new FormControl(value, field.formValidators);
				
				this.controlKeyMap[field.key] = fieldName;
				if(field.inputType === InputTypes.FILE || field.inputType === InputTypes.MULTIPLE_FILE){
					this.useMultipart = true;
				}
				if(field.onValueChanged){
					let subscription : Subscription;
					subscription = control.valueChanges
					.pipe(takeUntil(this._unsubscribeAll))
					.subscribe(() => {
						if(!formGroup.controls[fieldName]){
							subscription.unsubscribe();
							return;
						}
						field.onValueChanged(formGroup);
					});
				}
				formGroup.addControl(fieldName, control);
			}
		}
	}

	/**
	 * If use multipart return FormData else an object
	 */
	getFormData(): any{
		if(this.useMultipart){
			const formData = formGroupToFormData(this.formGroup);

			return formData;
		}else{
			return this.formGroup.getRawValue();
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	clearForm(value?: any): void{
		if (this.formGroup) this.formGroup.reset(value);
	}

	getFieldError(fieldDefinition: IFieldDefinition, errors: any): string{
		const fieldKey = this.getFieldControlKey(fieldDefinition);
		const error = errors && errors[fieldKey] && errors[fieldKey][0];
		if(error) return error;
		// check if there is an error with property code
		if(fieldDefinition.valueType === ValueTypes.PROPERTY){
			const propError = errors && errors[fieldDefinition.key] && errors[fieldDefinition.key][0];
			if(propError) return propError;
		}
	}

	setFieldsErrors(fields: (IFieldContainer|IFieldDefinition)[], errors: any): void{
		if(!fields) return;
		for (const field of fields) {
			if(isFieldContainer(field)){
				this.setFieldsErrors((<IFieldContainer>field).fields, errors);
				continue;
			}else{
				const fieldKey = this.getFieldControlKey(field);
				const control = this.formGroup.controls[fieldKey];
				if(!control) {
					continue;
				}
				const error = this.getFieldError(field, errors);

				if(!error){
					control.setErrors(null);
				}else{
					control.setErrors({invalid: error});
				}
				control.markAsTouched();
			}
		}
	}

	getFormErrors(){
		return Object.entries(this.formGroup.controls).map(([key, control]) => {
			return {
				key,
				errors: control.errors
			}
		})
	}

	setErrors(errors): void{
		if(!this.fields) return;
		this.setFieldsErrors(this.fields, errors);
		this.formGroup.markAsTouched();
	}

	setRecord(record: any): void{
		this.record = record;
		if(record){
			this.buildForm();
		} else this.clearForm();
	}

	_getFieldDefinition(key: string, fields: (IFieldContainer|IFieldDefinition)[]): IFieldDefinition{
		if(!key || !fields) return null;
		for (const field of fields) {
			if(isFieldContainer(field)){
				const found = this._getFieldDefinition(key, field.fields);
				if(found) return found;
			}else{
				if(field.key === key) return field;
			}
		}
		return null;
	}

	getFieldDefinition(key: string): IFieldDefinition{
		return this._getFieldDefinition(key, this.fields);
	}

	setInputValue(key: string, value: any, options?: any): void{
		const controlKey = _.get(this.controlKeyMap, key);
		if(!controlKey) return;
		const control = _.get(this.formGroup.controls, controlKey);
		if(!control) return;
		// do same for boolean? maybe need a test
		if(typeof(value) == 'number') control.setValue(value, _.get(options, 'setValueOptions'));
		else control.setValue(value || null, _.get(options, 'setValueOptions')); // null prevet set undefined
		if(options && options.touch) control.markAsTouched();
	}

	setValues(values: any, options?: any): void{
		for (const [key, value] of Object.entries(values)) {
			this.setInputValue(key, value, options);
		} 
	}
}
