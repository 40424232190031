import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { IStepViewConfig, IStepConfig } from 'app/interfaces';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
import { StepService } from '../../../services/step-state.service';
import { CdkStepDirective } from './cdk-step.component';

@Component({
	selector: 'app-custom-stepper',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.scss'],
	providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }]
})
export class CustomStepperComponent extends CdkStepper implements OnInit {

	constructor(
		_dir: Directionality, 
		_changeDetectorRef: ChangeDetectorRef,
		protected router: Router,
		protected route: ActivatedRoute,
		public stepService?: StepService
	) {
		super(_dir, _changeDetectorRef);
	}

	onClick(step: CdkStepDirective, index: number): void {
		if (!step.completed) return;
		this.selectedIndex = index;
	}

	ngOnInit(): void {
		
	}
}
