<div [ngClass]='[filterStyle, showSearchText ? "with_search_bar" : formConfig ? "without_search_bar" : ""]' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
	<ng-container *ngIf='formConfig' >
		<div class='extra-filters' fxLayout="row" fxLayoutGap="32px">
			<button class="desktop-btn" #popoverTarget [disabled]="dataContainerService.loading.value" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)='showPopover(popoverTarget)'>
				<span class="material-icons-outlined">filter_list</span>
				<span>Filtra</span>
			</button>
			<button class="mobile-btn" [disabled]="dataContainerService.loading.value" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)='showMobileFilters()'>
				<span class="material-icons-outlined">filter_list</span>
				<span>Filtra</span>
			</button>
			<ng-container *ngIf='filterCount'>
				<button class="clear-filters" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" (click)='clearFilters()'>
					<span class="material-icons-outlined">highlight_off</span>
					<span>Rimuovi filtri attivi</span>
				</button>
			</ng-container>
			<!-- mat menu filters -->
			<!--<ng-container *ngIf='isFiltersOpen'>
				<div class="form-filters" fxLayout="column">
					<form-viewer #formViewer
						[record]='filtersData'
						[formConfig]='formConfig'
						[dataContainerService]='dataContainerService'
					></form-viewer>
		
					<div class="footer" fxLayout="row" fxLayoutAlign="end center">
						<button class="clear-filters" (click)='toggleFilters()'>Annulla</button>
						<button class="apply-filters" (click)='applyFilter(formViewer.getFormData()); toggleFilters();'>Applica</button>
					</div>
				</div>
			</ng-container>-->
		</div>
	</ng-container>
	
	<ng-container *ngIf='showSearchText'>
		<div class='search-filter-box' fxLayout='row' fxLayoutAlign='start center' *ngIf='(actionButtons && actionButtons.length > 0) || showSearchText || formConfig' (click)='isFiltersOpen = false;'>
			<input fxFlex type='text' [placeholder]="'Cerca' | translate" (keydown.enter)='applyFilter(null)' autocomplete="off" [formControl]='defaultSearchControl' />		
			<mat-icon *ngIf='!formConfig && defaultSearchControl.value' style='cursor: pointer;' (click)='defaultSearchControl.setValue(""); applyFilter();' matTooltip='Pulisci filtro'>close</mat-icon>
			<mat-icon class="search-icon" style="margin-right: 16px;">search</mat-icon>
	
			<ng-container *ngIf='!showSearchText'>
				<div fxFlex ></div>
			</ng-container>
		</div>
	</ng-container>
</div>