import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../../base-view.component';
import { AuthService } from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DomainConfigs } from 'app/configs/datasets/domain_configs';
var DomainConfigsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DomainConfigsComponent, _super);
    function DomainConfigsComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.showActions = true;
        _this.elevate = true;
        _this.btnText = 'Modifica';
        _this.canEdit = true;
        return _this;
    }
    DomainConfigsComponent.prototype.ngOnInit = function () {
        this.formConfig = DomainConfigs.formConfigsMap[this.viewFormConfig];
        this.customActionCode = 'domain_configs';
        /*if (this.isNew()){
            this.btnText = 'Crea';
        }*/
    };
    DomainConfigsComponent.prototype.computeCanEdit = function () {
        this.canEdit = this.datasetACS.canEdit(this.rowDb);
    };
    DomainConfigsComponent.prototype.isNew = function () {
        return this.rowDb.has_domain_configs === 0;
    };
    DomainConfigsComponent.prototype.onPerformAction = function () {
        /*if (this.isNew()) {
            this.onCreate();
        } else {
            this.onEdit();
        }*/
        this.onEdit();
    };
    DomainConfigsComponent.prototype.getMethod = function () {
        if (this.isNew()) {
            return 'create';
        }
        return 'update';
    };
    DomainConfigsComponent.prototype.onEdit = function () {
        this.computeCanEdit();
        if (this.record) {
            this.record.id = this.rowDb.id_domain_configs;
        }
        this.datasetNavigatorService.openEditDialog(this.datasetACS, this.record, {
            formConfig: this.formConfig,
            recordId: this.rowDb.id_domain_configs,
            customActionCode: this.customActionCode,
            method: this.getMethod(),
        });
    };
    DomainConfigsComponent.prototype.onCreate = function () {
        this.datasetNavigatorService.openCreateDialog(this.datasetACS, {
            formConfig: this.formConfig,
            customActionCode: this.customActionCode
        });
    };
    return DomainConfigsComponent;
}(BaseViewComponent));
export { DomainConfigsComponent };
