import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';
import { FormGroup } from '@angular/forms';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('meeting_point_id')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('valid_from'),
			fieldsMap.get('valid_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('booking_from'),
			fieldsMap.get('booking_to'),
		]
	},
	fieldsMap.get('meeting_point_list_id'),
	fieldsMap.get('return_meeting_point_unified_lists'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('supplier_price'),
			fieldsMap.get('active_commission_percent'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('profitability_amount'),
			fieldsMap.get('mark_up_percent'),
		]
	},
	fieldsMap.get('price'),
];

const config: IFormConfig = { 
	title: 'Crea Regola Prezzo',
	editTitle: 'Modifica Regola Prezzo',
	fields: formFields
};

export default config;
