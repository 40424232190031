import plafondForm from "app/configs/datasets/domain_packages/forms/plafond.form";
import plafondCreationForm from 'app/configs/datasets/plafond/forms/general.form';
import { IAppEvent, IFormConfig } from "app/interfaces";
import { IDataContainerService } from "app/interfaces/data-container.interface";
import { Subscription } from "rxjs";
import { DatasetCreateDialogComponent, ICreateDialogData } from "../components/dataset/dialogs/core-actions/create/dataset-create-dialog.component";
import { DatasetNavigatorService } from "../components/dataset/services/dataset-navigator.service";
import { FormDialogComponent, IFormDialogData } from "../dialogs/form-dialog/dialog.component";

let subscription: Subscription;

export default async function requirePlafondData(record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService, form?: IFormConfig, datasetExtraData?: any): Promise<any>{
	const data: IFormDialogData = {
		title: 'Plafond',
		dataContainerService: dataContainerService,
		formConfig: plafondForm
	};
	// set form config
	if (form) data.formConfig = form;
	// validation
	if (record){
		data.formData = record;
	} else data.formErrors = {plafond_id: ['Non valido']};

	const dialogRef = datasetNavigator.openDialog<IFormDialogData>(FormDialogComponent, data);

	if (subscription) subscription.unsubscribe();
	subscription = dataContainerService.appService.eventEmitter
	.subscribe((event: IAppEvent) => {
		if (event.name === 'clickOnSupportCreation_plafond_id'){
			datasetNavigator.openDialog<ICreateDialogData>(DatasetCreateDialogComponent, {
				datasetCode: 'plafond',
				formConfig: plafondCreationForm,
				datasetData: datasetExtraData
			})
			.beforeClosed()
			.subscribe({
				next: response => {
					dialogRef.close(response);
				},
				error: response => {
					dialogRef.close();
				}
			});
		}
	});

	return await dialogRef.afterClosed().toPromise();
}