import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';

enum DomainPackageState {
	PENDING = 0,
	REFUSED = 1,
	ACCEPTED = 2,
	DEFINITELY_RETIRED = 5,
}

export const DomainsPackagesLog: IDatasetConfig = {
	name: 'domains_packages_log',
	title: 'Dettaglio richieste e cessioni',
	singleTitle: 'Dettaglio richieste e cessioni',
	ajaxDatasetCode: 'domains_packages_log',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {},
	defaultActions: {
		detail: {},
		create: {},
		edit: {},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Dati',
						key: 'message',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record): any{
							let colorClass = 'orange-bg';
							const text = record.message;

							if(record.extra.status === DomainPackageState.ACCEPTED) {
								colorClass = 'green-bg';
							} else if (record.extra.status === DomainPackageState.REFUSED) {
								colorClass = 'red-bg';
							} else if (record.extra.status === DomainPackageState.DEFINITELY_RETIRED){
								colorClass = 'red-bg';
							}

							let html = '<div class="request_log_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Data azione',
						key: 'created_at',
					}
				]
			}
		}
	}
};
