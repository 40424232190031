<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence'>
		<mat-label>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<!--<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint}}</mat-hint>-->
		<input matInput *ngIf='inputService.inputConfig.readOnly' readonly type='text' [value]='selectedLabel' [required]='inputService.inputConfig.required'/>
		<mat-select *ngIf='!inputService.inputConfig.readOnly' (selectionChange)="selectionChange($event)"
			[required]='inputService.inputConfig.required'
			[placeholder]="inputService.inputConfig.placeholder | translate" 
			[formControlName]='inputService.formControlKey'>
			<ng-container *ngFor='let selectOption of selectOptions'>
				<ng-container *ngIf='selectOption && !selectOption.group'>
					<mat-option [value]='selectOption.value'>
						<span [innerHTML]='selectOption.label | titlecase'></span>
					</mat-option>
				</ng-container>
				<!-- grouped options -->
				<ng-container *ngIf='selectOption && selectOption.group && selectOption.group.length > 0'>
					<mat-optgroup [label]="selectOption.label">
						<mat-option *ngFor='let option of selectOption.group' [value]='option.value'>
							<span [innerHTML]='option.label | titlecase'></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
			</ng-container>
		</mat-select>
		<mat-icon matSuffix style='cursor: pointer;' *ngIf='!inputService.inputConfig.readOnly && !inputService.formInputDefinition.disallowNull && inputService.inputConfig.clearable && inputService.control.value' (click)='inputService.control.setValue(null)'>close</mat-icon>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>