<div id="calendar" class="page-layout simple fullwidth trips-calendar">
	<h2 class="top-title">{{'Calendario' | translate}}</h2>
	<div class="filter-header" *ngIf='filterFormGroup'>
		<div class="icons" [class.is-mobile]='isMobile'>
			<button
				mat-icon-button
				mwlCalendarToday
				(click)="view = 'today'"
				[(viewDate)]="viewDate"
				(viewDateChange)="onViewDateChanged($event)"
				aria-label="Today"
				matTooltip="Today">
				<mat-icon [class.is-active]='view === "today"'>today</mat-icon>
			</button>

			<button
				mat-icon-button
				(click)="view = 'day'"
				aria-label="Day"
				matTooltip="Day">
				<mat-icon [class.is-active]='view === "day"'>view_day</mat-icon>
			</button>

			<button
				mat-icon-button
				(click)="view = 'week'"
				aria-label="Week"
				matTooltip="Week">
				<mat-icon [class.is-active]='view === "week"'>view_week</mat-icon>
			</button>

			<button
				class='month-icon'
				mat-icon-button
				(click)="view = 'month'"
				aria-label="Month"
				matTooltip="Month">
				<mat-icon [class.is-active]='view === "month"'>view_module</mat-icon>
			</button>
		</div>

		<div class="input_filters">
			<div class="trip_category_input">
				<form-input-viewer
					[formInputDefinition]='filterCategoriesFieldDefinition'
					[formGroup]='filterFormGroup'
					formControlKey='filter_trip_categories'
				></form-input-viewer>
			</div>
	
			<div class="trip_package_input">
				<form-input-viewer
					[formInputDefinition]='filterPackagesFieldDefinition'
					[formGroup]='filterFormGroup'
					formControlKey='filter_trip_packages'
				></form-input-viewer>
			</div>
		</div>
	</div>
	
	<div *ngIf="_calendarService.loadingEvents.value" class="mx-24">
		<mat-progress-bar
			color="accent"
			mode="indeterminate"
		></mat-progress-bar>
	</div>

	<ng-template #dateChanger>
		<div class="date_changer">
			<button mat-icon-button
				class="arrow"
				mwlCalendarPreviousView
				[view]="view"
				[(viewDate)]="viewDate"
				(viewDateChange)="onViewDateChanged($event)"
				aria-label="Previous">
				<mat-icon>chevron_left</mat-icon>
			</button>

			<div class="title">
				{{ viewDate | calendarDate: view + 'ViewTitle':localeId }}
			</div>

			<button mat-icon-button
				class="arrow"
				mwlCalendarNextView
				[view]="view"
				[(viewDate)]="viewDate"
				(viewDateChange)="onViewDateChanged($event)"
				aria-label="Next">
				<mat-icon>chevron_right</mat-icon>
			</button>
		</div>
	</ng-template>

	<ng-template #monthHeaderTemplate
		let-days="days"
		let-locale="locale"
		let-columnHeaderClicked="columnHeaderClicked"
		let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate">

		<ng-container *ngTemplateOutlet='dateChanger'></ng-container>

		<div class="cal-cell-row cal-header cal-header-month" role="row">
			<div class="cal-cell"
				*ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
				[class.cal-past]="day.isPast"
				[class.cal-today]="day.isToday"
				[class.cal-future]="day.isFuture"
				[class.cal-weekend]="day.isWeekend"
				(click)="columnHeaderClicked.emit({isoDayNumber: day.day, sourceEvent: $event})"
				[ngClass]="day.cssClass"
				tabindex="0"
				role="columnheader"
			>
				{{ day.date | calendarDate: 'monthViewColumnHeader':locale }}
			</div>
		</div>
	</ng-template>

	<ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked" let-eventDropped="eventDropped">
		
		<ng-container *ngTemplateOutlet='dateChanger'></ng-container>

		<div class="cal-day-headers no-border cursor-default">
			<ng-container *ngFor="let day of days">
				<div class="cal-header no-highlight" [class.cal-today]="day.isToday"
					[class.cal-past]="day.isPast"
					[class.cal-future]="day.isFuture"
					[class.cal-drag-over]="day.dragOver"
					(mwlClick)="dayHeaderClicked.emit({day: day})"
					mwlDroppable
					(dragEnter)="day.dragOver = true"
					(dragLeave)="day.dragOver = false"
					(drop)="day.dragOver = false; eventDropped.emit({event: $event.dropData.event, newStart: day.date})">
					
					<div class="top" [class.today]="day.isToday">
						<div class="day-label custom-day-laybel">
							{{ day.date | calendarDate:'weekViewColumnHeader':locale }} {{ day.date | date:'d' }}
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-template>

	<!-- WEEK EVENT TEMPLATE -->
	<ng-template #customEventTemplate
		let-weekEvent="weekEvent"
		let-tooltipPlacement="tooltipPlacement"
		let-eventClicked="eventClicked"
		let-tooltipTemplate="tooltipTemplate"
		let-tooltipAppendToBody="tooltipAppendToBody"
		let-tooltipDisabled="tooltipDisabled"
		let-tooltipDelay="tooltipDelay"
		let-column="column"
		let-daysInWeek="daysInWeek">
		
		<div class="custom-week-cal-event" (mwlClick)="eventClicked.emit({ sourceEvent: $event })" (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })">
			<div class="event-week-container">
				<div class="week-event-header-row">
					<div class="left-side">
						<div class="start-time">
							<mat-icon>access_time</mat-icon> {{weekEvent.event.meta.start_time}}
						</div>
			
						<div *ngIf='weekEvent.event.meta.actual_trip.pax_by_resource' class="week-event-text-msg" [ngClass]='weekEvent.event?.cssClass'>
							<span>{{weekEvent.event.meta.actual_trip.pax_by_resource.pax_punto_partenza}} / </span>
							<span *ngIf='weekEvent.event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>
								{{weekEvent.event.meta.actual_trip.pax_by_resource.pax_prenotati_risorsa}} / 
							</span>
							<span>{{weekEvent.event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa}}</span>
						</div>
					</div>
					<div class="right-side">
						<img *ngIf='weekEvent.event.meta.actual_trip.type != "return"' src="assets/icons/material-icons/filled/going_trip_icon.svg" />
						<img *ngIf='weekEvent.event.meta.actual_trip.type == "return"' src="assets/icons/material-icons/filled/return_trip_icon.svg" />
					</div>
				</div>
			
				<div class="event_title" [matTooltip]='weekEvent.event.package.description'>
					<a [routerLink]='datasetNavigatorService.getDetailRoute("trip_packages", weekEvent.event.package.id)'>{{weekEvent.event.package.description}}</a>
				</div>
				<div class="actual_trip_description">
					<img src="assets/icons/material-icons/filled/md-pin.svg" class="trip_place" />
					<span class="trip_title" [matTooltip]='weekEvent.event.meta.actual_trip.display_name'>
						<a [routerLink]='datasetNavigatorService.getDetailRoute("actual_trips", weekEvent.event.meta.actual_trip.id)'>
							{{weekEvent.event.meta.actual_trip.display_name}}
						</a>
					</span>
				</div>
			</div>
		</div>
	</ng-template>
	<!-- WEEK EVENT TEMPLATE -->

	<ng-template
		#customMonthCellTemplate
		let-day="day"
		let-openDay="openDay"
		let-locale="locale"
		let-tooltipPlacement="tooltipPlacement"
		let-highlightDay="highlightDay"
		let-unhighlightDay="unhighlightDay"
		let-eventClicked="eventClicked"
		let-tooltipTemplate="tooltipTemplate"
		let-tooltipAppendToBody="tooltipAppendToBody"
		let-trackByEventId="trackByEventId"
		let-validateDrag="validateDrag">

		<div class="cellTemplate" [class.cell-open]='(viewDate.getDate() == day.date.getDate())'>
			<!-- CELL HEADER -->
			<div class="cal-cell-top">
				<mat-progress-spinner class='day-loading' *ngIf='isDayLoading(day.date)' diameter='20' mode='indeterminate'></mat-progress-spinner>
				<!--<span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
					{{day.badgeTotal}}
				</span>-->
				<span class="cal-day-number">
					{{day.date | calendarDate: 'monthViewDayNumber':locale}}
				</span>
			</div>
			<!-- CELL BODY -->
			<div class="cal-events" *ngIf="day.events.length > 0">
				<ng-container *ngFor='let dayPackage of groupedEvents[day.date | date:"ddMMyy"] | keyvalue'>
					<div class="events-wrapper">
						<div class="package-description" [title]="dayPackage.value.trip_package_code + ' - ' + dayPackage.value.trip_package_description">{{dayPackage.value.trip_package_code}} - {{(dayPackage.value.trip_package_description.length > 16) ? (dayPackage.value.trip_package_description | slice:0:16) + '...' : dayPackage.value.trip_package_description}}</div>
						<div class="events-day">
							<div class="cal-event-container" *ngFor="let event of dayPackage.value.events; trackBy: trackByEventId">
								<a [routerLink]='datasetNavigatorService.getDetailRoute("actual_trips", event.meta.actual_trip.id)'>
									<div *ngIf="event.meta.actual_trip" class="cal-event"
										[ngStyle]="{ backgroundColor: event.color?.primary }"
										[ngClass]="event?.cssClass"
										(mouseenter)="highlightDay.emit({ event: event })"
										(mouseleave)="unhighlightDay.emit({ event: event })"
										[mwlCalendarTooltip]="
											(event.title | calendarEventTitle: 'monthTooltip':event) 
											+ ' - ' + (event.meta.start_time)
										"
										[tooltipPlacement]="tooltipPlacement"
										[tooltipEvent]="event"
										[tooltipTemplate]="customTooltipTemplate"
										[tooltipAppendToBody]="tooltipAppendToBody"
										mwlDraggable
										[class.cal-draggable]="event.draggable"
										dragActiveClass="cal-drag-active"
										[dropData]="{ event: event, draggedFrom: day }"
										[dragAxis]="{ x: event.draggable, y: event.draggable }"
										[validateDrag]="validateDrag"
										(mwlClick)="eventClicked.emit({ event: event })">
										<!-- NEW FORMAT SLOTS -->
										<span *ngIf='event.meta.actual_trip.pax_by_resource' class="cal-event-text-msg slots">
											<span>{{event.meta.actual_trip.pax_by_resource.pax_punto_partenza}} / </span>
											<span *ngIf='event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>
												{{event.meta.actual_trip.pax_by_resource.pax_prenotati_risorsa}} / </span>
											<span>
												{{ event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa === null ? '&#8734;'
														: event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa }}
											</span>
										</span>
									</div>
								</a>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-template>

	<ng-template #customTooltipTemplate let-event="event" let-placement="placement">
		<div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
			<div class="cal-tooltip-arrow"></div>
			<div class="cal-tooltip-inner">
				{{event.meta.actual_trip.display_name}} - {{event.meta.actual_trip.start_time}}
			</div>
		</div>
	</ng-template>

	<!-- CONTENT -->
	<div
		class="content"
		fusePerfectScrollbar
		[@animate]="{ value: '*', params: { delay: '200ms', y: '50px' } }"
	>
		<div [ngSwitch]="view">
			<mwl-calendar-month-view
				*ngSwitchCase="'month'"
				[locale]="localeId"
				[weekStartsOn]='1'
				[viewDate]="viewDate"
				[events]="events"
				[refresh]="refresh"
				[headerTemplate]='monthHeaderTemplate'
				[cellTemplate]="customMonthCellTemplate"
				(dayClicked)="dayClicked($event.day)"
				(eventClicked)="eventClick($event.event)"
				(eventTimesChanged)="eventTimesChanged($event)"
				(beforeViewRender)="beforeMonthViewRender($event)"
			></mwl-calendar-month-view>
			<mwl-calendar-week-view
				*ngSwitchCase="'week'"
				[locale]="localeId"
				[weekStartsOn]='1'
				[viewDate]="viewDate"
				[headerTemplate]='weekHeaderTemplate'
				[eventTemplate]='customEventTemplate'
				(viewDateChange)="onViewDateChanged($event)"
				[events]="events"
				[refresh]="refresh"
				(dayClicked)="dayClicked($event.day)"
				(eventClicked)="eventClick($event.event)"
				(eventTimesChanged)="eventTimesChanged($event)"
			></mwl-calendar-week-view>
			<view-day-calendar
				*ngSwitchCase="'day'"
				[viewDate]='viewDate'
				[groupedEventsByHour]='groupedEventsByHour'
				[headerTemplate]='weekHeaderTemplate'
			></view-day-calendar>
		</div>
	</div>
	<!-- / CONTENT -->
</div>

<calendar-sidebar
	[selectedDay]='selectedDay'
	[groupedEvents]='groupedEvents'
	(closed)='sidebarClosed($event)'
></calendar-sidebar>