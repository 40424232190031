import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as categoryFieldMap} from '../trip_categories/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_category_id', {
	key: 'trip_category_id',
	name: 'Categoria',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_categories',
	inputHint: 'Scrivi per cercare una categoria',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			const label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			const label = option.description;
			return label;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
});

categoryFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	fieldsMap.set('category.' + key, value);
});

export {fieldsMap};
