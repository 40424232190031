import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { ParticipantListEmailDialogComponent } from 'app/main/components/dataset/dialogs/participant-list-email-dialog/participant-list-email-dialog.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActualTripConfig } from 'app/configs/datasets/actual_trips';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import TripResourceStaffAssignmentsTableViewConfig from 'app/configs/datasets/trip_resource_staff_assignments/trip-resource-staff-assignments-table-view.viewConfig';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
import { ActualTripParticipantsListComponent } from 'app/main/components/dataset/dialogs/actual-trip-participants-list/participant-list-dialog.component';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { WebSocketService } from 'app/services/websocket.service';
import { MatDialog } from '@angular/material';
var ActualTripDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ActualTripDetailComponent, _super);
    function ActualTripDetailComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService, appService, domainFilterService, webSocketService, dialog) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.appService = appService;
        _this.domainFilterService = domainFilterService;
        _this.webSocketService = webSocketService;
        _this.dialog = dialog;
        _this.editFormConfig = ActualTripConfig.formConfigsMap['editForm'];
        _this.emailFormConfig = ActualTripConfig.formConfigsMap['emailForm'];
        _this.package = null;
        _this.loadingResources = false;
        _this.tripResourceStaffAssignmentsTableView = TripResourceStaffAssignmentsTableViewConfig;
        _this.resellView = false;
        _this.showBooking = false;
        _this.externalSyncedType = 0;
        _this.debounceTimeReload = new Subject();
        return _this;
    }
    ActualTripDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.datasetRS
            .record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (!record) {
                _this.package = null;
                _this.externalSyncedType = 0;
            }
            else {
                _this.package = record.package;
                _this.externalSyncedType = record.external_synced_type;
            }
            _this.resellView = !isRecordInFilteredDomain(record, record.is_owner);
            _this.contextualDomainId = record.domain.id || null;
            if (_this.resellView && _this.domainFilterService.filterDomainId.value) {
                _this.contextualDomainId = _this.domainFilterService.filterDomainId.value;
            }
        });
        this.getResources()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () { });
        this.debounceTimeReload
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(60 * 1000))
            .subscribe(function () {
            _this.datasetRS.reload();
        });
        this.showBooking = this.authService.isActionPermitted('view', 'booking_group');
    };
    ActualTripDetailComponent.prototype.onParticipantsUpdated = function (event) {
        if ((event.data.datasetCode == 'actual_trip_participants') && (event.data.payloadData.actual_trip_id == this.datasetRS.recordId)) {
            this.debounceTimeReload.next(true);
        }
    };
    ActualTripDetailComponent.prototype.getResources = function () {
        var _this = this;
        var result = new Subject();
        this.loadingResources = true;
        this.datasetService.get('/dataset/actual_trip_resources', {
            actual_trip_id: this.datasetRS.recordId,
            perPage: 50
        }).subscribe({
            next: function (response) {
                _this.tripResources = response.items;
                if (_this.tripResources) {
                    _this.tripResourceWithStaff = _this.tripResources.filter(function (el) {
                        return el.staff_elements_count;
                    });
                }
                else {
                    _this.tripResourceWithStaff = [];
                }
                result.next(response);
                _this.loadingResources = false;
            },
            error: function (err) {
                _this.loadingResources = false;
                result.error(err);
            }
        });
        return result;
    };
    ActualTripDetailComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    ActualTripDetailComponent.prototype.viewEmailInput = function () {
        var data = {
            postData: { actual_trip_id: this.datasetRS.recordId },
            DatACS: this.datasetACS,
            typeList: 'actual_trip',
        };
        this.datasetNavigatorService.openDialog(ParticipantListEmailDialogComponent, data);
    };
    ActualTripDetailComponent.prototype.openList = function () {
        this.datasetNavigatorService.openDialog(ActualTripParticipantsListComponent, {
            datasetACS: this.datasetACS,
            datasetRS: this.datasetRS
        }, { minWidth: 600 });
    };
    return ActualTripDetailComponent;
}(BaseViewComponent));
export { ActualTripDetailComponent };
