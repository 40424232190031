

export function datasetDetailPage(datasetCode: string, recordId: string): string{
	return '/dataset/detail/' + datasetCode + '/' + recordId;
}

export function datasetListPage(datasetCode: string): string{
	return '/dataset/list/' + datasetCode;
}

export function datasetEditPage(datasetCode: string, recordId: string): string{
	return '/dataset/edit/' + datasetCode + '/' + recordId;
}

export function datasetCreatePage(datasetCode: string): string{
	return '/dataset/create/' + datasetCode;
}
