<div class='full-form style-1' [ngClass]='stepConfig.options.formConfig.formStyle'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	
	<data-container-viewer
		[dataContainerService]='datasetACS'
		[fields]='stepConfig.options.originalDataFields' 
		[record]='parentDatasetRS.record.value'
	></data-container-viewer>

	<hr>

	<form-viewer #formViewer
		[record]='datasetRS.record.value'
		[dataContainerService]='datasetACS' 
		[formConfig]='stepConfig.options.formConfig'
		[readOnly]='!canEdit'
	></form-viewer>

	<div *ngIf='canEdit' class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
		<!--<button mat-raised-button>Annulla</button>-->
		<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='onSave()'>
			<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
			<span *ngIf='datasetACS.loading.value'>{{'Azione in corso...' | translate}}</span>
		</button>
	</div>
</div>