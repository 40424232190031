import { Component, Input } from "@angular/core";
import { trainTypeImages } from "../../trenitalia.service";

@Component({
	selector: 'trenitalia-train-breadcrumbs',
	templateUrl: './train-breadcrumbs.component.html',
	styleUrls: ['./train-breadcrumbs.component.scss']
})
export class TrainBreadcrumbsComponent {
	@Input() trains: any[];

	get trainTypeImages(){
		return trainTypeImages;
	}
}