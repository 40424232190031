import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { throwError, forkJoin } from 'rxjs';
import { default as DatasetConfigurations } from 'app/configs/datasets';
import { catchError, map } from 'rxjs/operators';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
var DatasetResolver = /** @class */ (function () {
    function DatasetResolver(datasetService, appService, router) {
        this.datasetService = datasetService;
        this.appService = appService;
        this.router = router;
    }
    DatasetResolver.prototype.toMap = function (paramMap) {
        var e_1, _a;
        var keys = paramMap.keys;
        var mapResult = new Map();
        try {
            for (var keys_1 = tslib_1.__values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                var key = keys_1_1.value;
                mapResult.set(key, paramMap.get(key));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return mapResult;
    };
    DatasetResolver.prototype.resolve = function (router, state) {
        var _this = this;
        var datasetConfig = null;
        var datasetCode = '';
        var calls = [];
        if (router.paramMap && router.paramMap.has('datasetCode')) {
            datasetCode = router.paramMap.get('datasetCode');
            datasetConfig = DatasetConfigurations[datasetCode];
        }
        if (!datasetConfig) {
            return throwError('Configuration /' + datasetCode + '/ not found!')
                .pipe(catchError(function (err, caught) {
                return _this.onError(err);
            }));
        }
        var action = router.data.action;
        var beforeCalls;
        if (datasetConfig.defaultActions[action]) {
            beforeCalls = datasetConfig.defaultActions[action].beforeCalls;
        }
        if (action === 'create' && datasetConfig.supportTemplates) {
            calls.push(this.getUserDomain(datasetCode));
        }
        calls.push(this.datasetService.fetchSourceData(datasetConfig, beforeCalls, this.toMap(router.paramMap)));
        return forkJoin(calls)
            .pipe(map(function (result) {
            var mappedResult = {};
            result.forEach(function (item, key) {
                var keys = Object.keys(item);
                keys.forEach(function (itemKey) {
                    mappedResult[itemKey] = item[itemKey];
                });
            });
            return mappedResult;
        }), catchError(function (err, caught) {
            return _this.onError(err);
        }));
    };
    DatasetResolver.prototype.getUserDomain = function (datasetCode) {
        var _this = this;
        return this.datasetService.get('/dataset/' + datasetCode + '/beforeCreate')
            .pipe(catchError(function (err, caught) {
            return _this.onError(err);
        }));
    };
    DatasetResolver.prototype.onError = function (err) {
        console.warn('error while resolve:', err);
        this.appService.showErrorMessage('Si è verificato un errore nel recupero dei dati.');
        // this.router.navigate(['/home']);
        return throwError(err);
    };
    return DatasetResolver;
}());
export { DatasetResolver };
