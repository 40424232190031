<h2 mat-dialog-title class="text-center">{{data['title']}}</h2>
<mat-dialog-content>
    <div>
        <mat-icon *ngIf="data.icon">{{data.icon}}</mat-icon>
        {{data.message}}
    </div>
    <br>
    <!--<div fxLayout="row wrap" fxLayoutAlign="center" fxFlexFill class="text-center"
         *ngIf="totalPenalitiesAmount == 0 && missingAmount == 0">
        <div>Operazione Trenitalia gratuita</div>
    </div>-->
    <div fxLayout="column wrap"
         fxLayoutAlign="start"
         fxFlexFill
         class="price-container"
         *ngIf="totalPenalitiesAmount > 0 || missingAmount > 0 || postSaleFee >0">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill>
            <div fxFlex="50" >Nuovo:</div>
            <div fxFlex="50" class="text-right">
                {{totalValueAmount+missingAmount-totalPenalitiesAmount |currency: 'EUR'}}
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill>
            <div fxFlex="50">Attuale:</div>
            <div fxFlex="50" class="text-right">{{totalValueAmount	 | currency:'EUR'}}</div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill>
            <div fxFlex="50" >Penale:</div>
            <div fxFlex="50" class="text-right">
                {{totalPenalitiesAmount |currency: 'EUR'}}
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill *ngIf="data.postSaleData.additionalCharges">
            <div fxFlex="50" >Operazione:</div>
            <div fxFlex="50" class="text-right">
                {{data.postSaleData.additionalCharges.amount.amount |currency: 'EUR'}}
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" *ngIf="postSaleFee>0">
            <div fxFlex="50" >Diritti Agenzia:</div>
            <div fxFlex="50" class="text-right">
                {{postSaleFee |currency: 'EUR'}}
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill *ngIf="data.postSaleData.reversedPaymentRecords.length==0">
            <div fxFlex="50" >Da pagare:</div>
            <div fxFlex="50" class="text-right">
                 <strong *ngIf="missingAmount + postSaleFee > 0">{{missingAmount+postSaleFee |currency: 'EUR'}}</strong>
				 <span *ngIf="missingAmount + postSaleFee == 0">{{missingAmount+postSaleFee | currency: 'EUR'}}</span>
            </div>
        </div>
        <!--<div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill *ngIf="!hasReversedPaymentRecords">
            <div fxFlex="50" >Totale:</div>
            <div fxFlex="50" class="text-right">
                {{totalValueAmount + missingAmount+postSaleFee |currency: 'EUR'}}
            </div>
        </div>-->
        <div fxLayout="row wrap" fxLayoutAlign="start"  *ngFor="let rev of data.postSaleData.reversedPaymentRecords">
            <div fxFlex="50" *ngIf="rev">Da rimborsare:</div>
            <div fxFlex="50" *ngIf="rev" class="text-right">
                <strong>{{rev.amount.amount-(postSaleFee/ data.postSaleData.reversedPaymentRecords.length)|currency: 'EUR'}}</strong>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Annulla</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>