/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./in-construction.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i3 from "../../../../@fuse/services/config.service";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./in-construction.component";
import * as i8 from "../../../../@fuse/services/translation-loader.service";
var styles_InConstructionComponent = [i0.styles];
var RenderType_InConstructionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InConstructionComponent, data: {} });
export { RenderType_InConstructionComponent as RenderType_InConstructionComponent };
export function View_InConstructionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "page-layout blank p-24"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.FusePerfectScrollbarDirective, [i1.ElementRef, i3.FuseConfigService, i4.Platform, i5.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("SAMPLE.HELLO")); _ck(_v, 3, 0, currVal_1); }); }
export function View_InConstructionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "in-construction", [], null, null, null, View_InConstructionComponent_0, RenderType_InConstructionComponent)), i1.ɵdid(1, 49152, null, 0, i7.InConstructionComponent, [i8.FuseTranslationLoaderService], null, null)], null, null); }
var InConstructionComponentNgFactory = i1.ɵccf("in-construction", i7.InConstructionComponent, View_InConstructionComponent_Host_0, {}, {}, []);
export { InConstructionComponentNgFactory as InConstructionComponentNgFactory };
