import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
} from 'app/interfaces';

import { fieldsMap } from './fields/general-fields';
import generalForm from './forms/general-form';
import praticaForm from './forms/pratica-form';
import serviceForm from './forms/service-form';
import participantsForm from './forms/participants-form';
import customerForm from './forms/customer-form';
import entityForm from './forms/entity-forms';

import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/sale_operations/detail/:recordId',
	params: {
		with_attributes: 'allowed_actions'
	}
};

export const SaleOperationConfig: IDatasetConfig = {
	name: 'sale_operations',
	title: 'Pratiche',
	singleTitle: 'Pratiche',
	ajaxDatasetCode: 'sale_operations',
	fieldsMap: fieldsMap,
	supportProperties: true,
	fetchConfig: {
		with_attributes: 'allowed_actions'
	},
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		list: false,
	},
	formConfigsMap: {
		generalForm,
		praticaForm,
		serviceForm,
		participantsForm,
		customerForm,
		entityForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
				actions: {
				edit: true,
				delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(container, parent, params): any{
				params.with_attributes = 'allowed_actions';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'date',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						routerLink(record: any): string{
							return datasetDetailPage('sale_operations', record.id);
						}
					},
					{
						title: 'Data',
						key: 'date',
					},
					{
						title: 'Stato',
						key: 'status',
					},
					{
						title: 'Totale',
						key: 'total',
					}
				],
				actions: ['detail'],
				/*customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'pageview',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							if(!record || !record.id) return;
							datasetNavigatorService.showDatasetDetail('sale_operations', null, record.id);
						},
						showIf(): boolean{ return true; }
					}
				],*/
			},
		},
	},
};
