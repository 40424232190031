import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Subject, forkJoin, of, BehaviorSubject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { CalendarEventModel } from './event.model';
import * as moment from 'moment';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { forEachDay } from 'app/helpers/date.helper';
import { DomainFilterService } from 'app/services/domain-filter.service';
var CalendarService = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    function CalendarService(appService, _httpClient, datasetNavigatorService, domainFilterService) {
        this.appService = appService;
        this._httpClient = _httpClient;
        this.datasetNavigatorService = datasetNavigatorService;
        this.domainFilterService = domainFilterService;
        this.events = [];
        this.filterParams = {};
        this.groupedEvents = {};
        this.groupedEventsCache = {};
        this.groupedEventsByHour = [];
        this.groupedEventsByHourCache = {};
        this.dayLoading = {};
        // Set the defaults
        this.onEventsUpdated = new Subject();
        this._viewDateChanged = new Subject();
        this.activeDayIsOpenSubscriber = new BehaviorSubject({ opened: false });
        this.loadingEvents = new BehaviorSubject(false);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    CalendarService.prototype.resolve = function (route, state) {
        this.filterParams = this.getStoredFilterParams();
        return of({});
    };
    CalendarService.prototype.appendFilterHeaders = function () {
        if (this.domainFilterService.filterDomainId.value && this.domainFilterService.showFilterBar) {
            return new HttpHeaders({
                'X-Domain-Id': this.domainFilterService.filterDomainId.value
            });
        }
    };
    CalendarService.prototype.getEvents = function (from, to, params) {
        var _this = this;
        var result = new Subject();
        var dayParams = Object.assign({}, params);
        dayParams['start_from'] = from;
        dayParams['start_to'] = to;
        var daySubscription = this._httpClient.get(this.appService.getBaseServerAddress() + '/calendar/event/actual_trips', {
            headers: this.appendFilterHeaders(),
            params: dayParams
        }).subscribe({
            next: function (response) {
                _this.events = _this.events.concat(response.map(function (item) {
                    var calendarEvent = new CalendarEventModel(undefined, _this.datasetNavigatorService);
                    calendarEvent.fromActualTrip(item);
                    return calendarEvent;
                }));
                _this.onEventsUpdated.next(_this.events);
                result.next(response);
                result.complete();
                forEachDay(from, to, function (day) {
                    if (_this.dayLoading[day]) {
                        _this.dayLoading[day].loading = false;
                        _this.dayLoading[day].subscription = null;
                    }
                }, 'Y-MM-DD');
            },
            error: function (response) {
                result.error(response);
                result.complete();
                forEachDay(from, to, function (day) {
                    if (_this.dayLoading[day]) {
                        _this.dayLoading[day].loading = false;
                        _this.dayLoading[day].subscription = null;
                    }
                }, 'Y-MM-DD');
            },
            complete: function () {
                _this.groupData();
            }
        });
        forEachDay(from, to, function (day) {
            _this.dayLoading[day] = { loading: true, subscription: daySubscription };
        }, 'Y-MM-DD');
        return result;
    };
    CalendarService.prototype.getDayEvents = function (day, params) {
        return this.getEvents(day, day, params);
    };
    CalendarService.prototype.getAllDaysEvents = function (options) {
        var e_1, _a;
        var _this = this;
        var result = new Subject();
        var params = Object.assign({
            perPage: 200,
            sortBy: 'start_day|start_time',
            sortDirection: 'asc',
            package_active: 1,
            only_availables: 0,
            with_relations: 'package',
            with_attributes: 'staff_elements'
        }, this.filterParams);
        for (var key in params) {
            if (!Object.prototype.hasOwnProperty.call(params, key))
                continue;
            if (params[key] === undefined || params[key] === null)
                delete params[key];
        }
        var startFrom = moment.utc().startOf('month');
        var startTo = moment.utc().endOf('month');
        if (options) {
            if (options.start)
                startFrom = options.start;
            if (options.end)
                startTo = options.end;
        }
        if (this.dayLoading) {
            for (var key in this.dayLoading) {
                if (!Object.prototype.hasOwnProperty.call(this.dayLoading, key))
                    continue;
                if (!this.dayLoading[key] || !this.dayLoading[key].subscription)
                    continue;
                this.dayLoading[key].subscription.unsubscribe();
            }
            this.dayLoading = {};
        }
        this.events = [];
        this.groupedEvents = {};
        this.groupedEventsByHour = [];
        this.groupedEventsCache = {};
        this.groupedEventsByHourCache = {};
        if ((!params.filter_trip_categories || params.filter_trip_categories.length === 0 || params.filter_trip_categories === '[]')
            &&
                (!params.filter_trip_packages || params.filter_trip_packages.length === 0 || params.filter_trip_packages === '[]')) {
            this.onEventsUpdated.next(this.events);
            this.storeFilterParams();
            result.next([]);
            result.complete();
            return result;
        }
        this.loadingEvents.next(true);
        var days = [];
        var arrayRequests = [];
        var currentDate = startFrom.clone();
        while (currentDate <= startTo) {
            var day = currentDate.format('Y-MM-DD');
            if (!days.includes(day))
                days.push(day);
            currentDate.add(1, 'day');
        }
        var chunkSize = 5;
        var chunks = [];
        for (var index = 0; index < days.length; index += chunkSize) {
            var lastIndex = index + chunkSize - 1;
            if (lastIndex >= days.length)
                lastIndex = days.length - 1;
            chunks.push({
                from: days[index],
                to: days[lastIndex]
            });
        }
        if (params.filter_trip_categories && Array.isArray(params.filter_trip_categories)) {
            params.filter_trip_categories = JSON.stringify(params.filter_trip_categories);
        }
        else if (params.filter_trip_packages && Array.isArray(params.filter_trip_packages)) {
            params.filter_trip_packages = JSON.stringify(params.filter_trip_packages);
        }
        try {
            for (var chunks_1 = tslib_1.__values(chunks), chunks_1_1 = chunks_1.next(); !chunks_1_1.done; chunks_1_1 = chunks_1.next()) {
                var chunk = chunks_1_1.value;
                var dayRequest = this.getEvents(chunk.from, chunk.to, params);
                arrayRequests.push(dayRequest);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (chunks_1_1 && !chunks_1_1.done && (_a = chunks_1.return)) _a.call(chunks_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        forkJoin(arrayRequests)
            .subscribe({
            next: function (response) {
                result.next(response);
                result.complete();
                _this.loadingEvents.next(false);
                _this.storeFilterParams();
            },
            error: function (response) {
                result.error(response);
                result.complete();
                _this.loadingEvents.next(false);
            }
        });
        return result;
    };
    CalendarService.prototype.groupData = function () {
        for (var i = 0; i <= this.events.length; i++) {
            var eventModel = this.events[i];
            if (!eventModel)
                return;
            var trip_package_id = eventModel.package.id;
            var dateStart = moment(eventModel.start);
            var dateIdx = dateStart.format('DDMMYY');
            /**
             * group by package and date
             */
            if (!this.groupedEvents[dateIdx])
                this.groupedEvents[dateIdx] = {};
            if (!this.groupedEvents[dateIdx][trip_package_id]) {
                this.groupedEvents[dateIdx][trip_package_id] = {
                    trip_package_id: trip_package_id,
                    trip_package_code: eventModel.package.code,
                    trip_package_description: eventModel.package.description,
                    events: []
                };
            }
            /**
             * some cache
             */
            if (!this.groupedEventsCache[eventModel.meta.actual_trip.id]) {
                this.groupedEventsCache[eventModel.meta.actual_trip.id] = {};
                this.groupedEvents[dateIdx][trip_package_id].events.push(eventModel);
            }
            /**
             * group by hour
             */
            var hour = dateStart.format('H:mm');
            if (dateStart.minutes() < 30) {
                hour = dateStart.format('H:00');
            }
            else if (dateStart.minutes() > 30) {
                hour = dateStart.format('H:30');
            }
            if (!this.groupedEventsByHour[dateIdx])
                this.groupedEventsByHour[dateIdx] = [];
            if (!this.groupedEventsByHour[dateIdx][hour])
                this.groupedEventsByHour[dateIdx][hour] = [];
            /**
             * push to array with some cache
             */
            if (!this.groupedEventsByHourCache[eventModel.meta.actual_trip.id]) {
                this.groupedEventsByHourCache[eventModel.meta.actual_trip.id] = {};
                this.groupedEventsByHour[dateIdx][hour].push(eventModel);
            }
        }
    };
    /**
     * Update events
     *
     * @param events
     * @returns {Promise<any>}
     */
    CalendarService.prototype.updateEvents = function () {
        return new Promise(function (resolve, reject) {
            reject('TODO');
        });
    };
    CalendarService.prototype.storeFilterParams = function () {
        window.localStorage.setItem('calendar:actual_trips@filters', JSON.stringify(this.filterParams));
    };
    CalendarService.prototype.getStoredFilterParams = function () {
        var value = window.localStorage.getItem('calendar:actual_trips@filters');
        if (!value)
            return {};
        try {
            return JSON.parse(value);
        }
        catch (e) {
            return {};
        }
    };
    return CalendarService;
}());
export { CalendarService };
