import * as tslib_1 from "tslib";
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
export default function action(action, params, datasetACS, datasetNavigatorService) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var loadingDialogRef, response, e_1, action_required;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingDialogRef = datasetNavigatorService.openDialog(ActionDialogComponent, {
                        title: 'Recupero dati in corso',
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, datasetNavigatorService.datasetService.post(action, params).toPromise()];
                case 2:
                    response = _a.sent();
                    if (response) {
                        loadingDialogRef.componentInstance.loading = false;
                        if (response.success) {
                            loadingDialogRef.componentInstance.resultStatus = 'success';
                            loadingDialogRef.componentInstance.setTitle('Richiesta inviata');
                            loadingDialogRef.componentInstance.setMessage('Puoi vedere lo stato delle tue richieste nella sezione apposita che puoi trovare nel menu laterale.');
                        }
                        else {
                            loadingDialogRef.componentInstance.resultStatus = 'error';
                            loadingDialogRef.componentInstance.setTitle('Errore');
                            loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
                        }
                    }
                    else {
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore');
                        loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
                    }
                    return [2 /*return*/, response];
                case 3:
                    e_1 = _a.sent();
                    action_required = e_1 && e_1.error && e_1.error.action_required;
                    if (action_required && e_1.error.action_required.code === 'new-customer-data') {
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Dati dominio mancanti');
                        // tslint:disable-next-line: max-line-length
                        loadingDialogRef.componentInstance.setMessage('Inserisci dati del dominio: <a style="color: #f44336 !important;" href="' + document.baseURI + 'dataset/detail/domains/' + datasetACS.domainFilterService.filterDomainId.value + '?step=5" target="_blank"><b>clicca qui per accedere alla pagina di modifica dati</b></a>');
                    }
                    else if (action_required && e_1.error.action_required.code === 'deny-global-request') {
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore durante la richiesta globale');
                        // tslint:disable-next-line: max-line-length
                        loadingDialogRef.componentInstance.setMessage('Esistono già richieste in corso. Perciò non è possibile effettuare una richiesta globale.');
                    }
                    else if (action_required && e_1.error.action_required.code === 'deny-single-category-request' || action_required && e_1.error.action_required.code === 'deny-category-request') {
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore durante la richiesta');
                        // tslint:disable-next-line: max-line-length
                        if (e_1.error.action_required.code === 'deny-category-request') {
                            loadingDialogRef.componentInstance.setMessage('Esiste già una richiesta per la categoria. Perciò non è possibile effettuare un\'altra richiesta per la stessa categoria.');
                        }
                        else {
                            loadingDialogRef.componentInstance.setMessage('Esiste già una richiesta globale. Perciò non è possibile effettuare una richiesta singola o per categoria.');
                        }
                    }
                    else {
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore');
                        loadingDialogRef.componentInstance.setMessage('Errore imprevisto');
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
