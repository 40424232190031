import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AppService } from 'app/services/app.service';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'text-input',
	templateUrl: './input.component.html',
	styleUrls  : ['./input.component.scss'],
	animations : fuseAnimations
})
export class TextInputComponent{

	constructor(
		public inputService: FormInputViewerService,
		public appService: AppService){}

	onSearchChange(value: any): void{
		this.appService.eventEmitter.emit({
			name: 'selected_label_' + this.inputService.formInputDefinition.key, 
			target: value
		});
	}
}
