import { wrapArray } from "app/helpers";
import * as _ from "lodash";
var TrenitaliaPostSaleChangeConfirmDialog = /** @class */ (function () {
    function TrenitaliaPostSaleChangeConfirmDialog(data) {
        this.data = data;
    }
    Object.defineProperty(TrenitaliaPostSaleChangeConfirmDialog.prototype, "totalPenalitiesAmount", {
        get: function () {
            return Number(_.get(this.data, 'postSaleData.totalPenalties.amount', 0));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleChangeConfirmDialog.prototype, "totalValueAmount", {
        get: function () {
            return Number(_.get(this.data, 'postSaleData.totalValue.amount'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleChangeConfirmDialog.prototype, "missingAmount", {
        get: function () {
            return Number(_.get(this.data, 'postSaleData.missingAmount.amount', 0));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleChangeConfirmDialog.prototype, "hasReversedPaymentRecords", {
        get: function () {
            var reversedPaymentRecords = wrapArray(_.get(this.data, 'postSaleData.reversedPaymentRecords'));
            return reversedPaymentRecords && reversedPaymentRecords.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleChangeConfirmDialog.prototype, "postSaleFee", {
        get: function () {
            return Number(_.get(this.data, 'postSaleData.postSaleFee', 0));
        },
        enumerable: true,
        configurable: true
    });
    return TrenitaliaPostSaleChangeConfirmDialog;
}());
export { TrenitaliaPostSaleChangeConfirmDialog };
