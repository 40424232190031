<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence' [floatLabel]='inputService.formInputDefinition.placeholder ? "always" : "auto"'>
		<mat-label>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>

		<div class="flex-row flex-gap-16 items-center justify-between">
			<div class="counter-btn" (click)="sub()" [ngClass]="{'counter-btn_disabled':!canSub}" *ngIf="!inputService.inputConfig.readOnly">
				<mat-icon>remove</mat-icon>
			</div>
				
			<input 
				[required]='inputService.inputConfig.required' 
				[readonly]='inputService.inputConfig.readOnly' 
				[name]='inputService.formControlKey' 
				[autocomplete]="'nope_' + inputService.formControlKey" 
				type='number' 
				matInput 
				[placeholder]="(inputService.inputConfig.placeholder || '') | translate" 
				[formControlName]='inputService.formControlKey'
				[min]="minValue"
			>
				
			<div class="counter-btn" (click)="add()" [ngClass]="{'counter-btn_disabled':!canAdd}" *ngIf="!inputService.inputConfig.readOnly">
				<mat-icon>add</mat-icon>
			</div>
		</div>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint}}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>