import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { CommonI18nFormStepComponent } from 'app/main/components/dataset/components/common-step/common-i18n-form-step/step.component';
var TripPackageReselledStep2Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageReselledStep2Component, _super);
    function TripPackageReselledStep2Component(appService, parentDatasetRS, parentDatasetACS, reselledDatasetRS, datasetACS, originalDatasetRS, datasetNavigatorService, datasetService, reselledDatasetMCS, originalDatasetMCS) {
        var _this = _super.call(this, appService, reselledDatasetRS, datasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS) || this;
        _this.appService = appService;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.reselledDatasetRS = reselledDatasetRS;
        _this.datasetACS = datasetACS;
        _this.originalDatasetRS = originalDatasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.reselledDatasetMCS = reselledDatasetMCS;
        _this.originalDatasetMCS = originalDatasetMCS;
        _this.isLastStep = true;
        return _this;
    }
    TripPackageReselledStep2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.reselledDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.reselledDatasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentReselledValueKey
            });
            _this.datasetMCS.fetchRecord();
            _this.originalDatasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.originalDatasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentValueKey
            });
            _this.originalDatasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    return TripPackageReselledStep2Component;
}(CommonI18nFormStepComponent));
export { TripPackageReselledStep2Component };
