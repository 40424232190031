<div class="page-layout p-24 blank simple left-sidebar">

	<div fxLayout="column" fxLayoutGap="10px" fxFlexFill>

		<div *ngIf='!domain'>
			<span class="h2">{{'Scegli uno spazio' | translate}}</span>

			<dataset-list
				[enableCreate]='false'
				(selected)='onSelected($event)'
				[selectable]='true'
				[selectionBtnTxt]='"Seleziona"'
				[elevate]='false'
				[showSearchBar]='true'
				[showTitle]='false'
				[datasetCode]='"public_domains"'
				[viewConfig]='domainViewConfig'
				tableStyle='style-1 selectable btn-outlined'
			></dataset-list>
		</div>

		<div *ngIf='domain'>
			<div class="domain-card" fxLayout='row'>
				<button mat-flat-button (click)='goBack()'><i class="material-icons">arrow_back</i> {{'Cambia' | translate}}</button>
				<div>
					<span class='h2'>{{domain.display_name}}</span>
		
					<div *ngIf='domain.logo' class="domain-img">
						<img class="logo-dominio" [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + domain.logo' [alt]='"Logo dominio" | translate'>
					</div>
				</div>
			</div>
			
			<dataset-list
				[enableCreate]='false'
				[elevate]='false'
				[showSearchBar]='true'
				[showTitle]='false'
				[datasetCode]='"public_trip_packages"'
				[viewConfig]='packageViewConfig'
				[filters]='{reseller_domain_id: domain.id}'
				tableStyle='style-1'
			></dataset-list>
		</div>
	</div>

</div>