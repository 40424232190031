import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth.service';
var LoggedGuardService = /** @class */ (function () {
    function LoggedGuardService(auth, appService, router) {
        this.auth = auth;
        this.appService = appService;
        this.router = router;
    }
    LoggedGuardService.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.auth.isAuthenticated()) {
                    this.router.navigate(['home']);
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
            });
        });
    };
    return LoggedGuardService;
}());
export { LoggedGuardService };
