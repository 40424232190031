import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators, FormGroup } from '@angular/forms';
import { fieldsMap as meetingFieldMap} from '../meeting_points/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('delivery_code', cloneFieldDefinition(meetingFieldMap.get('code'), {key: 'delivery_code'}));

fieldsMap.set('qty_code', {
	key: 'qty_code',
	name: 'Quantità di codici da generare (Si consiglia di utilizzare questo metodo)',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	formValidators: [ Validators.required ],
	inputHint: 'Nuomero di codici da generare'
});

fieldsMap.set('import_bulk_code', {
	key: 'import_bulk_code',
	name: 'Inserisci i codici uno sotto l\'altro (ATTENZIONE utilizzare questo metodo con moderazione)',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
});

export {fieldsMap};
