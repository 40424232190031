import { IStepViewConfig, TangerineBgColors } from 'app/interfaces';
import generalForm from './forms/general.form';
import generalViewModeForm from './forms/general-view-mode.form';
import originalResellDataFrom from './forms/original-resell-data.form';
import resellDataForm from './forms/resell-data.form';
import { TripPackageLanguageContentConfig } from '../trip_package_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { TripServiceConfig } from '../trip_services';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import * as _ from 'lodash';

export const config: IStepViewConfig = {
	title: 'Pacchetto',
	tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		//console.log('dataset acs data', component.datasetACS.getData());
		if(component.datasetACS.hasValueInKeyPath('reselledData')){
			return 'Rivendita: ' +component.record.description;
		}
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	showOverview: true,
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-form-step',
			options: {
				formConfig: generalForm
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-data-viewer-step',
			options: {
				fields: generalViewModeForm.fields
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Informazioni Base Rivendita',
			stepperTitle: 'Informazioni <br>Base<br>Rivendita',
			component: 'trip-package-reselled-step-0',
			options: {
				originalDataFields: originalResellDataFrom.fields,
				formConfig: resellDataForm
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			title: 'Avanzate',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_package_resources',
						title: 'Risorse',
						showIf(record): boolean{
							return !record.tourcms_channel_id_tour_id && !record.bokun_vendor_id_tour_id;
						}
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_destinations',
						title: 'Destinazioni'
					},
					{
						type: 'list',
						datasetCode: 'meeting_point_unified_list_items',
						title: 'Punti di ritrovo',
						showIf(record): boolean{
							return record && record.target !== 'ticket' && !record.tourcms_channel_id_tour_id && record.actual_trips_mode != 'virtual';
						}
					},
					{
						type: 'list',
						datasetCode: 'meeting_point_trip_packages',
						title: 'Punti di ritiro',
						showIf(record): any {
							return record.target === 'ticket';
						}
					},
					{
						type: 'list',
						datasetCode: 'bulk_delivery_codes',
						title: 'Carica codici massivi',
						showIf(record): any {
							return record.target === 'ticket';
						}
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_categories',
						title: 'Categorie'
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				return !component.datasetACS.hasValueInKeyPath('reselledData')
			}
		},
		{
			title: 'Avanzate',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_package_resources',
						title: 'Risorse',
						showIf(record): boolean{
							return !record.tourcms_channel_id_tour_id && !record.bokun_vendor_id_tour_id;
						}
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_destinations',
						title: 'Destinazioni',
						recordAs: 'reselledData'
					},
					{
						type: 'list',
						datasetCode: 'meeting_point_unified_list_items',
						title: 'Punti di ritrovo',
						showIf(record: any): boolean{
							return record && !record.tourcms_channel_id_tour_id && record.actual_trips_mode != 'virtual';
						}
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_categories',
						title: 'Categorie',
						recordAs: 'reselledData'
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				return component.datasetACS.hasValueInKeyPath('reselledData')
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-form-step',
			options: {
				formConfig: TripPackageLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-data-viewer-step',
			options: {
				fields: TripPackageLanguageContentConfig.formConfigsMap['textForm'].fields,
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return false;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Descrizioni Rivendita',
			stepperTitle: 'Descrizioni<br>Rivendita',
			component: 'trip-package-reselled-step-2',
			options: {
				formConfig: TripPackageLanguageContentConfig.formConfigsMap['textForm'],
				fields: TripPackageLanguageContentConfig.formConfigsMap['textForm'].fields,
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId',
				parentReselledValueKey: 'reselledData.id'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			title: 'Servizi',
			stepperTitle: 'Servizi',
			component: 'trip-package-step-3',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		
		{
			title: 'Servizi Rivendita',
			stepperTitle: 'Servizi<br>Rivendita',
			component: 'trip-package-reselled-step-3',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'departures',
			title: 'Partenze',
			component: 'trip-package-step-4',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (_.get(container, 'record.target') === 'ticket') return false;
				if(_.get(container, 'record.actual_trips_mode') == 'virtual') return false;
				return true;
			}
		},
		{
			id: 'trip_options',
			title: 'Opzioni partenze',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_options_for_virtual_trips',
						title: 'Opzioni'
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				return _.get(container, 'record.actual_trips_mode') == 'virtual';
			}
		},
		{
			title: 'Servizi e prezzi',
			component: 'common-blocks-step',
			showIf(component): boolean{
				return !component.record || !Boolean(component.record.tour_operator_id);
			},
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_services',
						updateParent: true,
						title: TripServiceConfig.title
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			id: 'integrazioni',
			title: 'Integrazioni',
			component: 'trip-package-integrations-step',
			showIf(container: CommonStepViewContainerComponent): boolean{
				const record = container.record;
				
				if(!record) return true; // show while create, mean user is the owner anyway
				// if(Boolean(record.tour_operator_id)) return false;
				return true;
				// return isRecordInFilteredDomain(record, record.is_owner);
				/*const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
				const isFiltered = filteredDomainId && filteredDomainId !== 'null';
				
				return (!isFiltered && record.is_owner) || (isFiltered && filteredDomainId === record.domain.id);*/
			}
		}
		/*{
			title: 'Recensioni',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_package_reviews',
						filters(component, record): any{
							return { trip_package_id: record.id };
						}
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		}*/
	]
};
