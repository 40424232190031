import {FieldViews, DatasetActionOpenType, IDatasetConfig, ListViewItemTypes, ValueTypes, InputTypes, IDatasetCallDefinition} from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import {config as stepViewConfig} from './stepViewConfig';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import trashedField from 'app/configs/commons/shared-field-configs/trashed.field';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import makeRequestAction from './make-request.action';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import tripCategoryIdField from 'app/configs/commons/shared-field-configs/trip-category-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import { TranslateService } from '@ngx-translate/core';
import tripDestinationIdField from 'app/configs/commons/shared-field-configs/trip-destination-id.field';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/products/detail/:recordId',
	params: {
		with_relations: 'domain',
		//with_attributes: 'is_owner,can_enable_for_marketplace,trip_service_rule_type'
	}
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

export const ProductsConfig: IDatasetConfig = {
	name: 'products',
	title: 'Prodotti',
	singleTitle: 'Prodotto',
	ajaxDatasetCode: 'products',
	fieldsMap: fieldsMap,
	supportProperties: true,
	supportTemplates: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
		restore: true
	},
	formConfigsMap: {
		generalForm
	},
	fetchConfig: {
		with_relations: 'domain',
		//with_attributes: 'is_owner,can_enable_for_marketplace,trip_service_rule_type'
	},
	stepViewConfig,
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ 
				beforeCallDetail,
				callModule,
				{
					name: 'domainContext',
					path: '/dataset/trip_packages/beforeCreate/:recordId',
				}
			],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [callModule],
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ 
				beforeCallDetail,
				callModule,
				{
					name: 'domainContext',
					path: '/dataset/trip_packages/beforeCreate/:recordId',
				}
			],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
				params.with_attributes = 'master_image';
				return params;
			},
			viewConfig: {
				showRequestPackage: false,
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Stato',
						key: 'active_status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							let colorClass = 'orange-bg';
							let text = '<span class="status">' +  translateService.instant('Non Pubblico') + '</span>';
							if(record.public_product_on_web === 1){
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Pubblicato') + '</span>';
							}

							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += '<div>'+text+'<br\><span>'+record.code+'</span></div>';
							html += '</div>';
							return html;
						},
						routerLink(record: any): string{
							return datasetDetailPage('products', record.id);
						}
					},
					{
						title : 'Immagine',
						key: 'master_image',
						fieldView: FieldViews.IMAGE,
						routerLink(record: any): string{
							return datasetDetailPage('products', record.id);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: 'Tipo',
						key: 'product_type',
						fieldDefinition: {
							key: 'product_type',
							name: 'Tipo',
							valueType: ValueTypes.STRING
						},
						fieldView: FieldViews.HTML,
						getDisplayHTML(record, value){
							if(!value || value == 'standard') return 'Standard';
							if(value == 'travelmate') return 'Audioguida';
							if(value == 'esim-manet') return 'ESim';
							return value;
						},
						routerLink(record: any): string{
							return datasetDetailPage('products', record.id);
						},
					},
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes'
					}
				],
				actions: ['create', 'detail', 'delete', 'restore'],
				itemActionsOrder: ['detail', 'delete', 'restore'],
				filterForm: {
					fields: [
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('publish_on_web_filter'),
								fieldsMap.get('product_type_filter')
							]
						},
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('product_category_filter'),
								cloneFieldDefinition(tripDestinationIdField)
							]
						},
						/*{
							config: {
								flexLayout: 'row',
							},
							fields: [
								cloneFieldDefinition(trashedField)
							]
						}*/
					]
				}
			}
		}
	}
};
