import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'renderColumn', pure: false})
export class RenderColumnPipe implements PipeTransform{
	transform(value: any, valueType?: string): any {
		switch(valueType){
			case 'icon':
				return '<i class="' + value + '"></i>';
			case 'langs':
				return this.getFlags(value);
			default:
				return value;
		}
	}

	getFlags(value: any): any{
		if (!value) return '';
		const active_language_codes = value;
		let html = '<div style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
		const style = 'style="margin-right: 5px;"';
		if (active_language_codes.length > 0){
			for (const lang of active_language_codes){
				html += '<img width="16" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
			}
		} else {
			html += '<span>---</span>';
		}
		html += '</div>';
		return html;
	}
}
