import { EventEmitter, Injectable, Injector, OnDestroy, Optional } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from '../dataset/services/dataset.service';
import { IFieldDefinition, IFieldInputConfig, IDatasetPropertyDefinition, InputTypes, ValueTypes } from 'app/interfaces';
import { FormGroup, AbstractControl } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FormInputViewerComponent } from './form-input-viewer.component';
import { FieldHelper } from 'app/helpers';
import { DatasetNavigatorService } from '../dataset/services/dataset-navigator.service';

const defaultInputConfig = {
	type: InputTypes.TEXT,
	clearable: false,
	hint: null,
	required: false,
	readOnly: false,
	placeholder: null,
	appearence: 'outline'
};

@Injectable()
export class FormInputViewerService implements OnDestroy{

	public formInputDefinition: IFieldDefinition;
	public inputConfig: IFieldInputConfig;
	public formGroup: FormGroup;
	public dataContainerService: IDataContainerService;
	public formControlKey: string;
	public propertyDefinition: IDatasetPropertyDefinition;
	public hidden = false;
	public computedTitle: string;
	public errorMessage: string;
	public control: AbstractControl;
	protected _unsubscribeAll: Subject<any>;
	public initialized = new BehaviorSubject(null);
	public show = false;
	public data: any;
	public exportEAgency = false;
	public customEvent: EventEmitter<any> = null;

	public showChanged = new BehaviorSubject(null);

	public datasetDataChangedSubscription: Subscription;
	public valueChangesSubscription: Subscription;
	public statusChangeSubscription: Subscription;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		@Optional() protected datasetNavigationService: DatasetNavigatorService
	){
		this._unsubscribeAll = new Subject();
	}

	init(component: FormInputViewerComponent): void{
		this.formInputDefinition = component.computedFieldDefinition || component.formInputDefinition;
		this.formGroup = component.formGroup;
		this.dataContainerService = component.dataContainerService;
		this.formControlKey = component.formControlKey;
		this.propertyDefinition = component.propertyDefinition;
		this.data = component.data;
		this.customEvent = component.customEvent;
		this.initInputConfig();
		if(component.readOnly) this.inputConfig.readOnly = true;

		this.preparePropertyDefinition();
		this.buildFieldTitle();
		this.checkIfShow();
		this.checkIfReadOnly();
		this.evaluateExports();

		if(this.dataContainerService){
			if(this.datasetDataChangedSubscription) this.datasetDataChangedSubscription.unsubscribe();
			this.datasetDataChangedSubscription = this.dataContainerService.datasetDataChanged
			.pipe(takeUntil(this._unsubscribeAll))
			.pipe(distinctUntilChanged())
			.subscribe(() => {
				this.preparePropertyDefinition();
			});
		}

		if(this.formGroup){
			if(this.valueChangesSubscription) this.valueChangesSubscription.unsubscribe();
			this.valueChangesSubscription = this.formGroup.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.pipe(distinctUntilChanged())
			.subscribe( _ => {
				this.onFormValueChanged();
			});

			this.control = this.formGroup.controls[this.formControlKey];
			if(this.control){
				if(this.statusChangeSubscription) this.statusChangeSubscription.unsubscribe();
				this.statusChangeSubscription = this.control.statusChanges
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe( status => {
					this.errorMessage = this.getErrorMessage();
				});
			}
		}
		if(!this.control && this.inputConfig.type !== InputTypes.READONLY){
			console.warn('not control found', this.formControlKey, this.formGroup);
		}
		this.initialized.next(true);

		this.show = Boolean(!this.hidden && this.formInputDefinition && (this.control || this.inputConfig.type === InputTypes.READONLY));
		this.showChanged.next(this.show);
	}

	onFormValueChanged(): void{
		this.checkIfShow();
		this.errorMessage = this.getErrorMessage();
		this.show = Boolean(!this.hidden && this.formInputDefinition && (this.control || this.inputConfig.type === InputTypes.READONLY));
		this.showChanged.next(this.show);
	}

	buildFieldTitle(): void{
		if(this.formInputDefinition.rawName){
			this.computedTitle = this.formInputDefinition.rawName;
			return;
		}

		this.computedTitle = new TitleCasePipe().transform(this.formInputDefinition.name);
	}

	initInputConfig(): void{
		if(!this.formInputDefinition){
			this.inputConfig = defaultInputConfig;
			return;
		}else{
			if(this.formInputDefinition.inputConfig){
				this.inputConfig = Object.assign({}, defaultInputConfig, this.formInputDefinition.inputConfig);
			}else{
				// support old config formInputDefinition
				this.inputConfig = {
					type: this.formInputDefinition.inputType,
					clearable: false,
					hint: this.formInputDefinition.inputHint,
					required: this.formInputDefinition.inputRequired,
					placeholder: this.formInputDefinition.placeholder,
					readOnly: this.formInputDefinition.inputReadOnly
				};
			}
			this.inputConfig.appearence = this.formInputDefinition.appearance || 'outline';
		}
	}

	checkIfShow(): void{
		//fare controllo per tutti per nascondere il componente form-input-viewer se vuoto
		//const control = this.formGroup.controls[this.formControlKey];
		//if(!control) return;
		const typ = typeof(this.formInputDefinition.showIf);
		if(typ === 'boolean'){
			this.hidden = !this.formInputDefinition.showIf;
		}else if(typ === 'function'){
			const show = this.formInputDefinition.showIf(this.formGroup.getRawValue(), this.dataContainerService);
			this.hidden = !show;
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getErrorMessage(): string{
		let message  = 'Non valido';
		const control = this.formGroup.controls[this.formControlKey];
		if(!control) return '';
		if(control.hasError('invalid')) message = control.getError('invalid');
		return message;
	}

	preparePropertyDefinition(): void{
		if(!this.formInputDefinition || !this.dataContainerService) return;
		if(this.formInputDefinition.valueType !== ValueTypes.PROPERTY) return;
		
		this.propertyDefinition = FieldHelper.getPropertyDefinition(
			this.dataContainerService, this.formInputDefinition.key,
			this.dataContainerService.getValueFromKeyPath('datasetCode')
		);

		if(!this.propertyDefinition){
			console.warn('no property definition for ', this.formInputDefinition, this.dataContainerService.getValueFromKeyPath('datasetCode'));
		}

		if(this.propertyDefinition && this.inputConfig.type === InputTypes.PROPERTY){
			this.inputConfig.type = this.codeToInputType(this.propertyDefinition.value_type);
		}
	}

	codeToInputType(code: string): InputTypes{
		if(code === 'option_value') return InputTypes.SELECT;
		if(code === 'multiple_option_value') return InputTypes.MULTI_SELECT;
		if(code === 'select') return InputTypes.SELECT;
		if(code === 'boolean') return InputTypes.CHECKBOX;
		if(code === 'string[]') return InputTypes.TEXT_LIST;
		return InputTypes.TEXT;
	}

	onSet(data: any, reason?: string): void{
		if(this.formInputDefinition.extra && this.formInputDefinition.extra.onSet){
			this.formInputDefinition.extra.onSet(this.formGroup, data, reason);
		}
		if(this.customEvent) this.customEvent.emit({code: 'onSet', data, reason});
	}

	emitCustomEvent(code: string, data: any){
		if(this.customEvent) this.customEvent.emit({code, data});
	}
	
	checkIfReadOnly(): void {
		const control = this.formGroup.controls[this.formControlKey];
		if(!control) return;
		const typ = typeof(this.formInputDefinition.readonlyIf);
		if(typ === 'function'){
			const isReadOnly: boolean = this.formInputDefinition.readonlyIf(this.data, this.dataContainerService);
			this.inputConfig.readOnly = isReadOnly;
		}
	}

	evaluateExports(): void{
		if(this.formInputDefinition && this.formInputDefinition.exportEAgency){
			this.exportEAgency = this.formInputDefinition.exportEAgency(this.data, this.dataContainerService);
		}else{
			this.exportEAgency = false;
		}
	}
}
