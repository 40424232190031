import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef } from '@angular/material';
import { ATripBookingPackageRelatedComponent } from '../abstract-trip-booking-package-related-data.component';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from "app/main/pages/edit-booking/edit-booking.component";
import { ActionDialogComponent } from "app/main/pages/edit-booking/action-dialog/action-dialog.component";
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import * as _ from 'lodash';
var TripBookingServiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingServiceComponent, _super);
    function TripBookingServiceComponent(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, datasetNavigatorService, bookingService, matDialogRef, data) {
        var _this = _super.call(this, appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.bookingService = bookingService;
        _this.matDialogRef = matDialogRef;
        _this.data = data;
        _this.canEdit = false;
        return _this;
    }
    TripBookingServiceComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.updateCanEdit();
    };
    TripBookingServiceComponent.prototype.updateCanEdit = function () {
        if (!this.bookingService.canEdit) {
            this.canEdit = false;
            return;
        }
        // disable edit if is a tourcms package
        if (_.get(this.tripBookingPackage, 'trip_package.tourcms_channel_id_tour_id')) {
            this.canEdit = false;
            return;
        }
        // disable edit if is a bokun package
        if (_.get(this.tripBookingPackage, 'trip_package.bokun_channel_id_tour_id')) {
            this.canEdit = false;
            return;
        }
        this.canEdit = true;
    };
    TripBookingServiceComponent.prototype.addTripBookingService = function () {
        this.datasetNavigatorService.openCreateDialog(this.datasetACS);
    };
    TripBookingServiceComponent.prototype.onRecalculateService = function () {
        var _this = this;
        var data = {
            trip_booking_package_id: _.get(this.tripBookingPackage, 'id')
        };
        this.datasetNavigatorService.dialog
            .open(ConfirmDialogComponent, {
            width: '550px',
            data: {
                title: 'Conferma',
                contentMessage: 'Vuoi aggiornare i prezzi dei servizi?'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            var dialogRef = _this.datasetNavigatorService.dialog.open(ActionDialogComponent, {
                width: '550px',
                data: {
                    title: 'Aggiornamento prezzi servizi'
                }
            });
            _this.datasetNavigatorService.datasetService.post('/dataset/trip_booking_services/command/recalculate_service', data)
                .subscribe({
                next: function () {
                    dialogRef.close();
                    _this.appService.showSuccessMessage('Aggiornato con successo');
                    _this.appService.appEvent.next({ name: RELOAD_BOOKING });
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGE,
                        extra: {
                            trip_booking_package_id: _.get(_this.tripBookingPackage, 'id')
                        }
                    });
                },
                error: function (response) {
                    /*
                    if(response.error && response.error.errors){
                        this.bookingService.onErrorActionResponse(response, dialogRef);
                    }
                    */
                    dialogRef.componentInstance.resultStatus = 'error';
                    dialogRef.componentInstance.loading = false;
                    dialogRef.componentInstance.setMessage('Si è verificato un errore');
                }
            });
        });
    };
    return TripBookingServiceComponent;
}(ATripBookingPackageRelatedComponent));
export { TripBookingServiceComponent };
