import * as tslib_1 from "tslib";
import { InputTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { CurrencyPipe } from '@angular/common';
import { roundTo } from 'app/helpers';
import * as moment from 'moment';
var fieldsMap = new Map();
fieldsMap.set('valid_from', {
    key: 'valid_from',
    name: 'Valido da',
    valueType: ValueTypes.DATE,
    inputConfig: {
        type: InputTypes.DATE,
        clearable: true
    },
    defaultValue: function (dataContainerService, options, record) {
        var lastPriceRule = dataContainerService.getValueFromKeyPath('trip_services.last_created');
        if (lastPriceRule && lastPriceRule.valid_from) {
            return lastPriceRule.valid_from;
        }
    }
});
fieldsMap.set('valid_to', {
    key: 'valid_to',
    name: 'Valido fino a',
    valueType: ValueTypes.DATE,
    inputConfig: {
        type: InputTypes.DATE,
        clearable: true
    },
    defaultValue: function (dataContainerService, options, record) {
        var lastPriceRule = dataContainerService.getValueFromKeyPath('trip_services.last_created');
        if (lastPriceRule && lastPriceRule.valid_to) {
            return lastPriceRule.valid_to;
        }
    }
});
fieldsMap.set('booking_from', {
    key: 'booking_from',
    name: 'Prenotazioni da',
    valueType: ValueTypes.DATE,
    inputConfig: {
        type: InputTypes.DATE,
        clearable: true
    }
});
fieldsMap.set('booking_to', {
    key: 'booking_to',
    name: 'Prenotazioni fino a',
    valueType: ValueTypes.DATE,
    inputConfig: {
        type: InputTypes.DATE,
        clearable: true
    }
});
function calcPriceValues(formGroup, fieldChanged) {
    var supplier_price = formGroup.controls['supplier_price'].value === null ? null : Number(formGroup.controls['supplier_price'].value);
    var mark_up_percent = formGroup.controls['mark_up_percent'].value === null ? null : Number(formGroup.controls['mark_up_percent'].value);
    var profitability_amount = formGroup.controls['profitability_amount'].value === null ? null : Number(formGroup.controls['profitability_amount'].value);
    var active_commission_percent = !formGroup.controls['active_commission_percent'] || formGroup.controls['active_commission_percent'].value === null
        ? null
        : Number(formGroup.controls['active_commission_percent'].value);
    var price = Number(formGroup.controls['price'].value);
    var result = {
        supplier_price: supplier_price,
        profitability_amount: profitability_amount,
        mark_up_percent: mark_up_percent,
        active_commission_percent: active_commission_percent,
        price: price
    };
    if (isNaN(supplier_price) || supplier_price === null) {
        // supplier_price not defined
        /*if(!isNaN(price) && !isNaN(mark_up_percent) && fieldChanged !== 'supplier_price'){
            result.supplier_price = roundTo(price / (1 + (mark_up_percent / 100)), 2);
        }*/
    }
    else {
        if (fieldChanged === 'mark_up_percent' && !isNaN(mark_up_percent) && supplier_price !== 0) {
            result.profitability_amount = roundTo(supplier_price * mark_up_percent / 100, 2);
        }
        if (fieldChanged === 'price' && !isNaN(price)) {
            result.profitability_amount = roundTo(price - supplier_price, 2);
            if (supplier_price !== 0) {
                result.mark_up_percent = roundTo((price - supplier_price) * 100 / Math.abs(supplier_price), 2);
            }
            else {
                result.mark_up_percent = 100;
            }
        }
        if (isNaN(result.profitability_amount))
            result.profitability_amount = 0;
        if (fieldChanged !== 'price') {
            result.price = roundTo(supplier_price + result.profitability_amount, 2);
        }
        if (fieldChanged === 'profitability_amount' && !isNaN(result.price)) {
            if (supplier_price !== 0) {
                result.mark_up_percent = roundTo((result.price - supplier_price) * 100 / Math.abs(supplier_price), 2);
            }
            else {
                result.mark_up_percent = 100;
            }
        }
        if (fieldChanged !== 'active_commission_percent' && !isNaN(result.price)) {
            if (isNaN(supplier_price) || supplier_price === null) {
                result.active_commission_percent = null;
            }
            else if (supplier_price !== 0) {
                result.active_commission_percent = roundTo(supplier_price * 100 / result.price, 2);
            }
            else {
                result.active_commission_percent = 0;
            }
        }
    }
    return result;
}
function setPriceResult(formGroup, fieldChanged, priceResults) {
    var e_1, _a;
    var fields = ['supplier_price', 'price', 'mark_up_percent', 'profitability_amount', 'active_commission_percent'];
    try {
        for (var fields_1 = tslib_1.__values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
            var field = fields_1_1.value;
            if (field === fieldChanged)
                continue;
            var currentValue = formGroup.controls[field].value;
            var newValue = priceResults[field];
            if (String(newValue) === String(currentValue) || isNaN(newValue))
                continue;
            formGroup.controls[field].patchValue(newValue, { onlySelf: true, eventEmit: false });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    // formGroup.updateValueAndValidity();
}
fieldsMap.set('supplier_price', {
    key: 'supplier_price',
    name: 'Prezzo fornitore',
    inputConfig: {
        clearable: true,
        type: InputTypes.NUMBER,
    },
    disallowNull: false,
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            pipeClass: CurrencyPipe,
            args: ['EUR']
        }
    ],
    defaultValue: function () {
        return null;
    },
    onValueChanged: function (formGroup) {
        if (formGroup.get('supplier_price').value === null)
            return;
        var result = calcPriceValues(formGroup, 'supplier_price');
        setPriceResult(formGroup, 'supplier_price', result);
    }
});
fieldsMap.set('mark_up_percent', {
    key: 'mark_up_percent',
    name: 'Mark Up %',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            transform: function (value) {
                if (!value)
                    return value;
                return value + '%';
            }
        }
    ],
    onValueChanged: function (formGroup) {
        if (formGroup.get('mark_up_percent').value === null)
            return;
        var result = calcPriceValues(formGroup, 'mark_up_percent');
        setPriceResult(formGroup, 'mark_up_percent', result);
    }
});
fieldsMap.set('profitability_amount', {
    key: 'profitability_amount',
    name: 'Profitto',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            pipeClass: CurrencyPipe,
            args: ['EUR']
        }
    ],
    defaultValue: function (data, options, record) {
        if (!record)
            return null;
        return roundTo(record.supplier_price * record.mark_up_percent / 100, 2);
    },
    onValueChanged: function (formGroup) {
        if (formGroup.get('profitability_amount').value === null)
            return;
        var result = calcPriceValues(formGroup, 'profitability_amount');
        setPriceResult(formGroup, 'profitability_amount', result);
    }
});
fieldsMap.set('profitability_percent', {
    key: 'profitability_percent',
    name: 'Profitto',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            transform: function (value) {
                if (!value)
                    return value;
                return value + '%';
            }
        }
    ]
});
fieldsMap.set('active_commission_percent', {
    key: 'active_commission_percent',
    name: 'Commissioni atttive %',
    inputConfig: {
        type: InputTypes.NUMBER,
        clearable: true
    },
    disallowNull: false,
    defaultValue: function () {
        return null;
    },
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            transform: function (value) {
                if (!isNaN(Number(value)))
                    return value;
                return value + '%';
            }
        }
    ],
    onValueChanged: function (formGroup) {
        if (formGroup.get('active_commission_percent').value === null)
            return;
        var result = calcPriceValues(formGroup, 'active_commission_percent');
        setPriceResult(formGroup, 'active_commission_percent', result);
    }
});
fieldsMap.set('price', {
    key: 'price',
    name: 'Prezzo',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    transforms: [
        {
            pipeClass: CurrencyPipe,
            args: ['EUR']
        }
    ],
    onValueChanged: function (formGroup) {
        var result = calcPriceValues(formGroup, 'price');
        setPriceResult(formGroup, 'price', result);
    }
});
fieldsMap.set('meeting_point_list_id', {
    key: 'meeting_point_list_id',
    name: 'Lista punti di ritrovo',
    inputType: InputTypes.DATASET_SELECT,
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'meeting_point_unified_lists',
    showIf: function (formData, dataContainerService) {
        var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
        return Boolean(tripPackageId);
    },
    remoteOptions: {
        limit: 50,
        getParams: function (dataContainerService, data) {
            var params = {
                'properties_parent_dataset_code': 'trip_packages'
            };
            // console.log('meeting_point_list_id.getParams', dataContainerService);
            var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
            if (tripPackageId) {
                params['properties_parent_id'] = tripPackageId;
            }
            return params;
        },
        getLabel: function (option) {
            if (!option)
                return '---';
            var label = option.description;
            if (option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-') {
                label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
            }
            else if (option.items_count > 0) {
            }
            return label;
        },
        getValue: function (option) {
            return option.id;
        }
    }
});
fieldsMap.set('meeting_point_list_description', {
    key: 'meeting_point_list_description',
    name: 'Lista Punti di ritrovo',
    valueType: ValueTypes.STRING,
    getDisplayValue: function (record) {
        if (!record || !record.meeting_point_list)
            return '---';
        var description = record.meeting_point_list.description;
        if (record.meeting_point_list.properties
            && record.meeting_point_list.properties.first_time
            && record.meeting_point_list.properties.first_time.value
            && record.meeting_point_list.properties.first_time.value !== '-') {
            description += ' (' + record.meeting_point_list.properties.first_time.value.substring(0, 5) + ')';
        }
        return description;
    }
});
fieldsMap.set('return_meeting_point_list_descriptions', {
    key: 'return_meeting_point_list_descriptions',
    name: 'Liste Punti di ritrovo (Ritorno)',
    valueType: ValueTypes.STRING,
    getDisplayValue: function (record) {
        if (!record || !record.properties)
            return '';
        return record.properties.returns_description && record.properties.returns_description.value;
    }
});
fieldsMap.set('return_meeting_point_unified_lists', {
    key: 'return_meeting_point_unified_lists',
    name: 'Ritorno: Liste punti di ritrovo',
    inputType: InputTypes.DATASET_MULTISELECT,
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'meeting_point_unified_lists',
    showIf: function (formData, dataContainerService) {
        var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
        return Boolean(tripPackageId);
    },
    remoteOptions: {
        limit: 50,
        getParams: function (dataContainerService, data) {
            var params = {
                'properties_parent_dataset_code': 'trip_packages',
            };
            var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
            if (tripPackageId) {
                params['properties_parent_id'] = tripPackageId;
            }
            return params;
        },
        getLabel: function (option) {
            if (!option)
                return '---';
            var label = option.description;
            if (option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-') {
                label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
            }
            else if (option.items_count > 0) {
            }
            return label;
        },
        getValue: function (option) {
            return option.id;
        }
    }
});
fieldsMap.set('validity_table_view', {
    key: 'validity_table_view',
    name: 'Validità',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var html = '';
        if (record.valid_from) {
            html += '<span>Dal ' + moment.utc(record.valid_from).format('DD/MM/Y') + '</span>';
        }
        if (record.valid_to) {
            if (record.valid_from)
                html += '<br>';
            html += '<span>Fino al ' + moment.utc(record.valid_to).format('DD/MM/Y') + '</span>';
        }
        return html;
    }
});
fieldsMap.set('booking_table_view', {
    key: 'booking_table_view',
    name: 'Prenotato',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var html = '';
        if (record.booking_from) {
            html += '<span>Dal ' + moment.utc(record.booking_from).format('DD/MM/Y') + '</span>';
        }
        if (record.booking_to) {
            if (record.booking_from)
                html += '<br>';
            html += '<span>Fino al ' + moment.utc(record.booking_to).format('DD/MM/Y') + '</span>';
        }
        return html;
    }
});
fieldsMap.set('supplier_table_view', {
    key: 'supplier_table_view',
    name: 'Prezzi Fornitore',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var html = '';
        if (record.active_commission_percent !== null) {
            html += '<span>Fornitore: ' + Number(record.supplier_price).toLocaleString(undefined, { currency: 'EUR', style: 'currency' }) + '</span>';
        }
        if (record.active_commission_percent) {
            html += '<br>';
            html += '<span>Commissioni Attive: ' + Number(record.active_commission_percent / 100).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + '</span>';
        }
        if (record.mark_up_percent) {
            html += '<br>';
            html += '<span>Mark Up: ' + Number(record.mark_up_percent / 100).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + '</span>';
        }
        return html;
    }
});
fieldsMap.set('meeting_point_id', {
    key: 'meeting_point_id',
    name: 'Punto di ritiro',
    inputType: InputTypes.DATASET_SEARCH,
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'meeting_points',
    inputHint: 'Scrivi per cercare un punto di ritiro',
    remoteOptions: {
        limit: 100,
        getParams: function (dataContainerService) {
            var params = {};
            var isPickup = dataContainerService.getValueFromKeyPath('trip_resources.record.properties.resource_sub_types.value') == 'PICKUP';
            if (isPickup) {
                params.trip_resource_id = dataContainerService.getValueFromKeyPath('trip_resources.record.id');
            }
            return params;
        },
        getLabel: function (option) {
            if (!option)
                return '';
            var label = option.description;
            /*if(option.default_time){
                label += ' ' + option.default_time;
            }*/
            return label;
        },
        getValue: function (option) {
            if (!option)
                return;
            return option.id;
        },
        getDisplay: function (option) {
            if (!option)
                return '';
            var label = option.description;
            if (option.default_time) {
                label += ' ' + option.default_time;
            }
            return label;
        }
    },
    defaultValue: function (datasetContainerService, options, record) {
        if (!record)
            return null;
        return record.id;
    },
    showIf: function (formData, dataContainerService) {
        var isPickup = dataContainerService.getValueFromKeyPath('trip_resources.record.properties.resource_sub_types.value') == 'PICKUP';
        return isPickup; // FUTURE: show for pickup ?
    }
});
export { fieldsMap };
