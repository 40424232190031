import { FuseNavigation } from '@fuse/types';
import { IMenuItem } from 'app/configs/navigations/menu-items';
import { allMenuItems } from 'app/configs/navigations/menu-items';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { IMeResponse } from 'app/interfaces';
import * as _ from 'lodash';

export function buildUserNavigationFromDomainSetting(data: IMeResponse, menuItems: IMenuItem[] | any[], domainSetting?: any){
	const menuGroups: string[] = _.get(domainSetting, 'interface_settings.main_menu_enabled_groups');
	const navigations: FuseNavigation[] = [];
	for (const menuItem of menuItems) {
		if (menuItem.hidden) continue;
		if (domainSetting.is_smart_interface && menuItem.hiddenWithSmartInterface) continue;
		if(menuItem.group && (!menuGroups || !menuGroups.includes(menuItem.group))) continue;

		let userNavigation: FuseNavigation;

		if (!menuItem.fuseNavigation) {
			userNavigation = menuItem;
		} else {
			userNavigation = Object.assign({}, menuItem.fuseNavigation);
		}

		// TODO: use domainSetting badges
		if (data.badge && data.badge.count > 0) {
			if (userNavigation.id === data.badge.id) {
				userNavigation.badge = {};
				userNavigation.badge.title = data.badge.count.toString();
				userNavigation.badge.bg = data.badge.bg;
			}
		}

		if (userNavigation.id === 'domains' && !data.showFilterBar && data.single_domain_id) {
			userNavigation.url = 'dataset/detail/domains/' + data.single_domain_id;
		}

		if(menuItem.children){
			userNavigation.children = buildUserNavigationFromDomainSetting(data, menuItem.children, domainSetting);
		} else if (userNavigation.children){
			userNavigation.children = buildUserNavigationFromDomainSetting(data, userNavigation.children, domainSetting);
		}

		navigations.push(userNavigation);
	}
	return navigations;
}

export function buildUserNavigation(data: IMeResponse, menuItems: IMenuItem[] | any[], domainSetting?: any): FuseNavigation[]{
	const userPermission = data.permissions;
	const user = data.user;
	const specialGroups = [
		'admin_group',
		'super_admin_group'
	];

	if(!userPermission) return [];
	const groupMap = new Map();
	for (const group of userPermission) {
		// groupMap.set(group.code, group.access_level);
		if (group.actions && group.actions.length > 0) {
			groupMap.set(group.code, group.actions.some((action: any) => action.active == true));
		} else if (specialGroups.includes(group.code)) {
			groupMap.set(group.code, true);
		}
	}

	const navigations: FuseNavigation[] = [];
	for (const menuItem of menuItems) {
		if (menuItem.hidden) continue;
		if (data.is_smart_interface){
			if (menuItem.hiddenWithSmartInterface) continue;
		}
		if(menuItem.group){
			if(!groupMap.get(menuItem.group)) continue;
			if(groupMap.get(menuItem.group) <= 0) continue;
		}

		let userNavigation: FuseNavigation;

		if (!menuItem.fuseNavigation && menuItem) {
			userNavigation = menuItem;
		} else {
			userNavigation = Object.assign({}, menuItem.fuseNavigation);
		}

		if (data.badge && data.badge.count > 0) {
			if (userNavigation.id === data.badge.id) {
				userNavigation.badge = {};
				userNavigation.badge.title = data.badge.count.toString();
				userNavigation.badge.bg = data.badge.bg;
			}
		}

		if (userNavigation.id === 'domains' && data.user && data.user.role && data.user.role.level > 10 && !data.showFilterBar) {
			userNavigation.url = 'dataset/detail/domains/' + data.single_domain_id;
		}

		if(menuItem.children){
			userNavigation.children = buildUserNavigation(data, menuItem.children);
		} else if (userNavigation.children){
			buildUserNavigation(data, userNavigation.children);
		}

		navigations.push(userNavigation);
	}
	return navigations;
}

export function setUserNavigation(fuseNavigationService: FuseNavigationService, data: IMeResponse, domainSetting?: any): void{
	fuseNavigationService.unregister('user');
	fuseNavigationService.register('user', domainSetting ? buildUserNavigationFromDomainSetting(data, allMenuItems, domainSetting) : buildUserNavigation(data, allMenuItems, domainSetting));
	fuseNavigationService.setCurrentNavigation('user');
}
