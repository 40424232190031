<h2>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>

<div class="card">
	<div class="info_domain">
		<h2>{{'Informazioni richiesta' | translate}}</h2>
		<p *ngIf='datasetRS.record.value.package?.description'><b>{{'Pacchetto' | translate}}:</b> {{datasetRS.record.value.package.description}}</p>
		<p *ngIf='datasetRS.record.value.request_domain?.display_name'><b>{{'Dominio richiedente' | translate}}:</b> {{datasetRS.record.value.request_domain.display_name}}</p>
		<p *ngIf='datasetRS.record.value.owner_domain?.display_name'><b>{{'Dominio cedente' | translate}}:</b> {{datasetRS.record.value.owner_domain.display_name}}</p>
	</div>

	<div class="plafond">
		<!-- check if is massive to show add btn?? -->
		<ng-container *ngIf='datasetRS.record.value.allowed_actions.includes("plafond_actions") && datasetRS.record.value.plafond == null'>
			<button (click)='addPlafond()' class="add-btn" mat-flat-button color="primary">
				{{ 'Aggiungi plafond' }}
			</button>
		</ng-container>
	</div>
</div>

<mat-tab-group #matTabGroup [selectedIndex]='0'>
	<mat-tab label="Azioni richiesta">
		<dataset-list
			[showTitle]='false'
			[elevate]='false'
			datasetCode='domains_packages_log'
			[parentDatasetACS]='datasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{id: datasetRS.recordId}'
			[autoFetchData]='false'
			[enableCreate]='false'>
		</dataset-list>
	</mat-tab>

	<mat-tab *ngIf='datasetRS.record.value.plafond' label="{{datasetRS.record.value.plafond.description}} ({{datasetRS.record.value.plafond.total_consumed | currency:'EUR'}} / {{datasetRS.record.value.plafond.total_amount | currency:'EUR'}}) ">
		<mat-card>
			<div class="plafond">
				<div class="info_plafond">
					<div class="amount_plafond">
						{{datasetRS.record.value.plafond.total_consumed | currency:'EUR'}} / {{datasetRS.record.value.plafond.total_amount | currency:'EUR'}}
					</div>
		
					<div class="actions">
						<!--<button mat-stroked-button (click)='detailsPlafond()'>Dettagli</button>-->
						<ng-container *ngIf='datasetRS.record.value.allowed_actions.includes("plafond_actions")'>
							<button mat-flat-button color="primary" (click)='editPlafond()'>{{ 'Modifica' }}</button>
							<button mat-flat-button color="warn" (click)='deletePlafond()'>Rimuovi</button>
						</ng-container>
					</div>
				</div>
		
				<div class="details">
					<h2>Lista operazioni plafond</h2>
		
					<dataset-list
						[enableCreate]='false'
						[selectable]='false'
						[elevate]='false'
						[showSearchBar]='true'
						[showTitle]='false'
						[enableEdit]='false'
						[enableDelete]='false'
						[showCreateForm]='false'
						[enableActions]='false'
						[showExportBtn]='false'
						datasetCode='plafond_drop_items'
						[parentDatasetACS]='datasetACS'
						[parentDatasetRS]='datasetRS'
						[filters]='{plafond_id: datasetRS.record.value.plafond.id, domain_package_id: datasetRS.recordId}'>
					</dataset-list>
				</div>
			</div>
		</mat-card>
	</mat-tab>

</mat-tab-group>
