import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import {FormInputViewerComponent } from './form-input-viewer.component';
import { BaseTextInputComponent } from './inputs/base-text/input.component';
import { TextInputComponent } from './inputs/text/input.component';
import { ColorInputComponent } from './inputs/color/input.component';
import { TextListInputComponent } from './inputs/text-list/input.component';
import { FileInputComponent } from './inputs/file/input.component';
import { MultipleFileInputComponent } from './inputs/multiple-file/input.component';
import { DatasetAutocompleteInputComponent } from './inputs/dataset-autocomplete/input.component';
import { DatasetSelectInputComponent } from './inputs/dataset-select/input.component';
import { DatasetSelectDepDropdownInputComponent } from './inputs/dataset-select-dropdown/input.component';
import { SelectInputComponent } from './inputs/select/input.component';
import { TextareaInputComponent } from './inputs/textarea/input.component';
import { TextListInput2Component } from './inputs/text-list2/input.component';
import { DatasetSearchInputComponent } from './inputs/dataset-search/input.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TinyMCEInputComponent } from './inputs/tinymce/input.component';
import { DateInputComponent } from './inputs/date/input.component';
import { NumberInputComponent } from './inputs/number/input.component';
import { MultiselectInputComponent } from './inputs/multiselect/input.component';
import { PasswordInputComponent } from './inputs/password/input.component';
import { SelectCustomIconInputComponent } from './inputs/select_custom_icon/input.component';
import { CompareDateInputComponent } from './inputs/compare_date/input.component';
import { DateTimeInputComponent } from './inputs/datetime/input.component';
import { DatasetMultiSelectInputComponent } from './inputs/dataset-multiselect/input.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatasetComboboxInputComponent } from './inputs/dataset-combobox/input.component';
import { CheckboxInputComponent } from './inputs/checkbox/input.component';
import { ToggleInputComponent } from './inputs/toggle/input.component';
import { HiddenInputComponent } from './inputs/hidden/input.component';
import { ReadOnlyInputComponent } from './inputs/readonly/input.component';
import { RadioInputComponent } from './inputs/radio/input.component';
import { FileBlockInputComponent } from './inputs/file-block/input.component';
import { WeekHoursComponent } from './inputs/week-hours/input.component';
import { TimeInputComponent } from './inputs/time/input.component';
import { SelectFilterableInputComponent } from './inputs/select_filterable/input.component';
import { CounterInputComponent } from './inputs/counter/input.component';

@NgModule({
	declarations: [
		FormInputViewerComponent,
		BaseTextInputComponent,
		TextInputComponent,
		ColorInputComponent,
		TextListInputComponent,
		FileInputComponent,
		MultipleFileInputComponent,
		DatasetAutocompleteInputComponent,
		DatasetSelectInputComponent,
		DatasetSelectDepDropdownInputComponent,
		SelectInputComponent,
		TextareaInputComponent,
		TextListInput2Component,
		DatasetSearchInputComponent,
		TinyMCEInputComponent,
		DateInputComponent,
		NumberInputComponent,
		MultiselectInputComponent,
		PasswordInputComponent,
		SelectCustomIconInputComponent,
		CompareDateInputComponent,
		DateTimeInputComponent,
		DatasetMultiSelectInputComponent,
		DatasetComboboxInputComponent,
		CheckboxInputComponent,
		ToggleInputComponent,
		HiddenInputComponent,
		ReadOnlyInputComponent,
		RadioInputComponent,
		FileBlockInputComponent,
		WeekHoursComponent,
		TimeInputComponent,
		SelectFilterableInputComponent,
		CounterInputComponent
	],
	entryComponents:[
		FormInputViewerComponent,
		BaseTextInputComponent,
		TextInputComponent,
		ColorInputComponent,
		TextListInputComponent,
		FileInputComponent,
		MultipleFileInputComponent,
		DatasetAutocompleteInputComponent,
		DatasetSelectInputComponent,
		DatasetSelectDepDropdownInputComponent,
		SelectInputComponent,
		TextareaInputComponent,
		TextListInput2Component,
		DatasetSearchInputComponent,
		TinyMCEInputComponent,
		DateInputComponent,
		NumberInputComponent,
		MultiselectInputComponent,
		PasswordInputComponent,
		SelectCustomIconInputComponent,
		CompareDateInputComponent,
		DateTimeInputComponent,
		DatasetMultiSelectInputComponent,
		DatasetComboboxInputComponent,
		CheckboxInputComponent,
		ToggleInputComponent,
		HiddenInputComponent,
		ReadOnlyInputComponent,
		RadioInputComponent,
		FileBlockInputComponent,
		WeekHoursComponent,
		TimeInputComponent,
		SelectFilterableInputComponent
	],
	imports: [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatListModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		MatChipsModule,
		EditorModule,
		MatSlideToggleModule,

		FuseSharedModule,
		TranslateModule.forChild()
	],
	exports: [FormInputViewerComponent, ToggleInputComponent]
})
export class FormInputViewerModule {}
