import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields/participants-fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('original_id'),
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('phone_number'),
	fieldsMap.get('fiscalcode'),
	fieldsMap.get('piva'),
	fieldsMap.get('gender'),
	fieldsMap.get('email'),
	fieldsMap.get('address_street'),
	fieldsMap.get('nation_code'),
	fieldsMap.get('index'),
	fieldsMap.get('scenario'),
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;