import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators, FormGroup } from '@angular/forms';
import { fieldsMap as meetingFieldMap} from '../meeting_points/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('time', {
	key: 'time',
	name: 'Orario',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	inputHint: 'Opzionale, se non inserito verrà usato il default.',
	defaultValue(datasetContainerService, options, record): any{
		if(!record || !record.pivot) return null;
		return record.pivot.time;
	}
});

fieldsMap.set('pivot.time', {
	key: 'pivot.time',
	name: 'Orario',
	valueType: ValueTypes.TIME
});

fieldsMap.set('meeting_point_id', {
	key: 'meeting_point_id',
	name: 'Punto di ritrovo',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_points',
	inputHint: 'Scrivi per cercare un punto di ritrovo',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			/*if(option.default_time){
				label += ' ' + option.default_time;
			}*/
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	extra: {
		onSet(formGroup: FormGroup, selectedData: any): void{
			if(!selectedData) return;
			const controller = formGroup.get('time');
			if(controller && !controller.value){
				controller.setValue(selectedData.default_time);
			}
		}
	}
});

meetingFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	fieldsMap.set('meeting_point.' + key, value);
});

export {fieldsMap};
