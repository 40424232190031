export const locale = {
	lang: 'en',
	data: {
		'LOGIN': {
			'TITLE': 'Sign in',
			'REMEMBER_ME': 'Remember me',
			'FORGOT_PASSWORD': 'Password forgotten?'
		},
		'NAV': {
			'HOME': {
				'TITLE': 'Dashboard'
			},
			'CALENDAR': {
				'TITLE': 'Calendar'
			},
			'REVIEWS': {
				'TITLE': 'Reviews'
			},
			'PACKAGES': {
				'TITLE': 'Packages'
			},
			'PRODUCTS': {
				'TITLE': 'Products'
			},
			'RESOURCES': {
				'TITLE': 'Resources'
			},
			'DOCUMENTS': {
				'TITLE': 'Documents'
			},
			'STATISTICS': {
				'TITLE': 'BI Statistics'
			},
			'PLAFOND': {
				'TITLE': 'Plafond',
			},
			'BOOKINGS': {
				'TITLE': 'Bookings',

				'NEWBOOKING' : {
					'TITLE': 'New Booking'
				}
			},
			'MEETING_POINTS': {
				'TITLE': 'Meeting Points'
			},
			'DESTINATIONS': {
				'TITLE': 'Destinations'
			},
			'CATEGORIES': {
				'TITLE': 'Categories'
			},
			'INFO': {
				'TITLE': 'Useful Info'
			},
			'PRICES_SERVICES': {
				'TITLE': 'Prices & Services'
			},
			'ENTITIES': {
				'TITLE': 'Entities',

				'CUSTOMERS': {
					'TITLE': 'Customers'
				},
				'SUPPLIERS': {
					'TITLE': 'Suppliers'
				},
				'TOUR_OPERATORS': {
					'TITLE': 'Tour Operators'
				},
				'STAFF': {
					'TITLE': 'Staff'
				},
				'ALL': {
					'TITLE': 'All'
				}
			},
			'MARKETPLACE': {
				'TITLE': 'Marketplace',

				'TANGERINE': {
					'TITLE': 'Tangerine',
				},
				'TOURCMS': {
					'TITLE': 'Tourcms',
				},
				'BOKUN': {
					'TITLE': 'Bokun'
				},
				'TICKETLANDIA': {
					'TITLE': 'Ticketlandia',
				}
			},
			'DEFINITIONS': {
				'TITLE': 'Definizioni',
			},
			'DOMAINS_GROUP': {
				'TITLE': 'Domains Group',

				'DOMAINS': {
					'TITLE': 'Domains'
				},
				'USER_DOMAINS': {
					'TITLE': 'User Domains'
				},
				'BRANCHES': {
					'TITLE': 'Branches'
				},
				'PAYMENTS_TYPE': {
					'TITLE': 'Payments type'
				},
				'REQUESTS_TRANSFERS': {
					'TITLE': 'Requests & Transfers'
				}
			},
			'ADMIN_GROUP': {
				'TITLE': 'Super Admin',

				'USERS': {
					'TITLE': 'Users'
				},
				'DOMAIN_INFO': {
					'TITLE': 'Domain information',
				},
				'TURNOVER_REPORT': {
					'TITLE': 'Turnover Report'
				},
				'PRACTICES': {
					'TITLE': 'Sale Operations'
				},
				'TRANSMISSIONS': {
					'TITLE': 'Transmissions'
				},
				'UTILITIES': {
					'TITLE': 'Utilities'
				},
				'API': {
					'TITLE': 'API credentials generation'
				},
				'LOGS': {
					'TITLE': 'Logs'
				}
			},
			'CRITERIA': {
				'TITLE': 'Criteria',
			},
			'SERVICES': {
				'TITLE': 'Services',
			},
			'PRICE_LISTS': {
				'TITLE': 'Price Lists'
			},
			'DISCOUNTS': {
				'TITLE': 'Discounts',
			}
		},
		// common words
		'DETAIL': 'detail',
		'LIST': 'list',
		'WELCOME_BACK': 'Welcome Back',
		'Si': 'Yes',
		'Annulla': 'Cancel',
		'Ok': 'Ok',
		'Azione in corso...': 'Action in progress...',
		'Chiudi': 'Close',
		'Crea': 'Create',
		'Cerca': 'Search',
		'Carica un file': 'Upload file',
		'Aggiungi': 'Add',
		'Invia': 'Send',
		'Modifica': 'Edit',
		'Rivendi Pacchetto': 'Resell Package',
		'Blocco non configurato': 'Block not configured',
		'Questa traduzione non è ancora presente!': 'This translation is not yet present!',
		'Questa traduzione non è presente!': 'This translation is missing!',
		'Salva e vai avanti': 'Save and move on',
		'Salva': 'Save',
		'Ruolo di tipo amministrativo, accesso completo al pannello.': 'Administrative role, full access to the panel.',
		'Accesso Vietato': 'Access Denied',
		'Può visualizzare': 'Can view',
		'Può aggiungere e modificare': 'Can add and edit',
		'Accesso completo': 'Full access',
		'Esportazione': 'Export',
		'Nessuna immagine di copertina': 'No cover image',
		'Immagini': 'Images',
		'Informazioni Utili': 'Useful information',
		'Immagine di copertina': 'Cover image',
		'Lista di punti di ritrovo': 'Meeting point list',
		'invia': 'send',
		'Listino prezzi': 'Price list',
		'chiudi': 'close',
		'Seleziona i pacchetti da richiedere': 'Select the packages to request',
		'Attendi...': 'Wait...',
		'Seleziona un dominio sul quale agire': 'Select a domain to act on',
		'Seleziona': 'Select',
		'Conferma': 'Confirm',
		'Aggiungi partecipanti': 'Add participants',
		'Genera': 'Generates',
		'Rimuovi': 'Remove',
		'Ripristina': 'Reset',
		'Assegna': 'Assign',
		'Genera Partenze': 'Generate Departures',
		'Valutazione media e recensioni negative totali per anno di revisione': 'Average rating and total negative reviews per year of review',
		'Seleziona dominio': 'Select domain',
		'Calendario': 'Calendar',
		'Categorie': 'Categories',
		'Pacchetti': 'Packages',
		'Biglietti': 'Tickets',
		'Modifica Profilo': 'Edit Profile',
		'Modifica Password': 'Change Password',
		'Codice / Stato': 'Code / Status',
		'Traduzioni Atttive': 'Active Translations',
		'Nessun elemento presente': 'No items present',
		'Filtri': 'Filters',
		'Presenze': 'Attendance',
		'Sospendi prenotazione': 'Suspend booking',
		'Disponibilità': 'Availability',
		'Prenotazioni Dal': 'Bookings From',
		'Prenotazioni Al': 'Bookings At',
		'Partenza Dal': 'Departures From',
		'Partenze Fino Al': 'Departures At',
		'Risorsa': 'Resource',
		'Categoria': 'Category',
		'Codice Voucher Operatore': 'Operator Voucher Code',
		'Id Ordine Esterno': 'id External Order',
		'Nominativo': 'Nominative',
		'Utente': 'User',
		'Coupon Sconto': 'Discount Coupon',
		'Origine': 'Origin',
		'Mostra Scadute (Solo Api)': 'Show Expired (Api Only)',
		'Scrivi per cercare': 'Type to search',
		'Filtri ricerca': 'Search filters',
		'Applica': 'Apply',
		'Contatti': 'Contacts',
		'Pubblico Sul Sito': 'Publish On Site',
		'Punto Di Ritrovo': 'Meeting point',
		'Tipologia Risorsa': 'Resource Type',
		'Proprietà': 'Property',
		'Mostra Eliminati': 'Show deleted',
		'Pacchetto': 'Package',
		'Informazioni <br>Base': 'Basic <br>Information',
		'Informazioni Base': 'Basic Information',
		'Avanzate': 'Advance',
		'Descrizioni': 'Description',
		'Partenze': 'Departures',
		'Servizi e prezzi': 'Services and prices',
		'Integrazioni': 'Integrations',
		'Nessuna immagine inserita': 'No images inserted',
		'Prezzo': 'Price',
		'Nessuna traduzione attiva': 'No active translations',
		'Opzionale, se non inserito verrà generato automaticamente.': 'Optional, if not entered it will be generated automatically.',
		'Nome del pacchetto': 'Package name',
		'Pubblica Sul Sito': 'Publish On Site',
		'Priorità': 'Priority',
		'Fiscalità': 'Taxation',
		'Commissioni %': 'Commissions %',
		'Commessa': 'Shop assistant',
		'Scrivi per cercare una commessa': 'Write to look for a shop assistant',
		'Durata Standard In Ore': 'Standard Duration In Hours',
		'Bambini Da':  'Children From',
		'Adulto Da': 'Adult From',
		'Senior Da': 'Senior From',
		'Opzionale': 'Optional',
		'Dettaglio risorsa': 'Resource detail',
		'Orario': 'Hours',
		'Lista': 'List',
		'Risorse': 'Resources',
		'Punti di ritrovo': 'Meeting Points',
		'Punti Di Ritrovo': 'Meeting Points',
		'Aggiungi Risorsa': 'Add Resource',
		'Scrivi per cercare una risorsa': 'Type to search for a resource',
		'Lista Punti Di Ritrovo': 'Meeting Points List',
		'Scrivi per cercare una destinazione': 'Type to search for a destination',
		'Destinazione': 'Destination',
		'Aggiungi una Destinazione al pacchetto': 'Add a Destination to the package',
		'Nuovo Punto di ritrovo': 'New meeting point',
		'Scrivi per cercare un punto di ritrovo': 'Write to find a meeting point',
		'Optionale, puoi aggiungere un punto di ritrovo senza orario': 'Optionally, you can add an untimed meeting point',
		'Nota': 'Note',
		'Nuovo Categoria': 'New Category',
		'Scrivi per cercare una categoria': 'Write to search for a category',
		'Priorità Totem': 'Priority Totem',
		'Pubblico': 'Public',
		'Descrizione Completa': 'Full Description',
		'Testo completo di descrizione.': 'Full description text.',
		'Breve Descrizione': 'Short description',
		'Prima Di Partire': 'Before leaving',
		'Servizi Inclusi': 'Services included',
		'Servizi Esclusi': 'Services Excluded',
		'Testo Voucher Pdf': 'Text Voucher Pdf',
		'Questo testo verrà stampato sul voucher': 'This text will be printed on the voucher',
		'Testo Voucher Ticketing': 'Text Voucher Ticketing',
		'Indietro': 'Back',
		'Lista Punti Di Ritorno': 'Return Points List',
		'Data Inizio Partenze': 'Start Date Departures',
		'Data Fine Partenze': 'Departure End Date',
		'Durata In Ore': 'Duration In Hours',
		'Giorno Della Settimana': 'Day of the week',
		'Allotment Minimo': 'Minimum allotments',
		'Allotment Specifico': 'Specific Allotments',
		'Stato Partenza': 'Status Departure',
		'Lingua': 'Language',
		'Lingua Con Audioguida': 'Language With Audioguide',
		'Da': 'From',
		'Fino A': 'To',
		'Crea Servizio': 'Create Service',
		'Servizio Primario': 'Primary Service',
		'Codice Servizio': 'Service Code',
		'Codice Criterio': 'Policy Code',
		'Descrizione Criterio': 'Description Policy',
		'Prezzo Al Pubblico': 'Retail price',
		'Listino Prezzi': 'Price list',
		'Non attivo': 'Inactive',
		'Attivo': 'Active',
		'Tipo / Tipologia': 'Type / Typology',
		'Tipo Risorsa': 'Resource type',
		'Tipologia': 'Typology',
		'Tipo Allotment': 'Allotments type'
	}
};
