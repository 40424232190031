<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<label>{{inputService.computedTitle | translate}}</label>
	<input 
		(input)='onSearchChange($event.target.value)'
		[readonly]='inputService.inputConfig.readOnly' 
		[required]='inputService.inputConfig.required'
		[name]='inputService.formControlKey'
		autocomplete="off" 
		type='text' 
		matInput 
		[placeholder]="(inputService.inputConfig.placeholder || '') | translate" 
		[formControlName]='inputService.formControlKey'>
</ng-container>