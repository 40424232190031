
<div style="display: block;">
	<div class="loading-div" *ngIf='dashboardService.loadingReviewsStats || showLoader'>
		<mat-spinner *ngIf='dashboardService.loadingReviewsStats' diameter="80" class='full-center-spinner'></mat-spinner>
	</div>
	<canvas baseChart *ngIf='reviewsystat'
			[datasets]="reviewsystat"
			[labels]="barChartLabels"
			[options]="barChartOptions"
			[legend]="barChartLegend" 
			[chartType]="barChartType">
	</canvas>
</div>