import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews, IDatasetCallDefinition, ListViewExportTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/bulk_delivery_codes/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	return params;
}

export const BulkDeliveryCodeConfig: IDatasetConfig = {
	name: 'bulk_delivery_codes',
	title: 'Generazione massiva di codici',
	singleTitle: 'Generazione massiva di codici',
	ajaxDatasetCode: 'bulk_delivery_codes',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				showExportBtn: true,
				exportBtnActions: [
					{
						typeFile: ListViewExportTypes.XLS,
						buttonText: 'Esporta xls'
					},
					{
						typeFile: ListViewExportTypes.CSV,
						buttonText: 'Esporta csv'
					}
				],
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codici Milano Card',
						key: 'delivery_code',
					},
				],
				actions: ['create', 'detail', 'delete'],
			}
		}
	}
};
