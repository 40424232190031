import { IDataContainerService } from "app/interfaces/data-container.interface";
import {DatasetService} from "../../../main/components/dataset/services/dataset.service";

/**
 *
 * @param key
 * @param type booking | report
 * @param dataContainerService
 */
export function hideIfCustomDisableFields(key: string, type:string, dataContainerService: IDataContainerService){
    if(!dataContainerService) return false;
    const fields = dataContainerService.domainFilterService.getDomainSetting('current', 'custom_fields.'+type+'.disabled.field_list');
    if(!fields) return false
    return fields.includes(key);
}
export function getDisabledStaticValueCustomFields(key: string, type:string, dataContainerService: any){
    if(!dataContainerService) return null;
    const value = dataContainerService.domainFilterService.getDomainSetting('current', 'custom_fields.'+type+'.static_field.'+key);
    if(!value) return null
    console.log(value)
    return value;
}

/**
 *
 * @param key
 * @param type booking | report
 * @param dataContainerService
 */
export function showIfCustomAddFields(key: string, type:string, dataContainerService: IDataContainerService){
    if(!dataContainerService) return false;
    const fields = dataContainerService.domainFilterService.getDomainSetting('current', 'custom_fields.'+type+'.add.field_list');
    if(!fields) return false
    return fields.includes(key);
}

