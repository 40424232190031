import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AppService} from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';

import {Subscription} from 'rxjs';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { AuthService, CheckUserStatues } from 'app/services/auth.service';

@Component({
	selector   : 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls  : ['./reset-password.component.scss'],
	animations : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy{

	resetForm: FormGroup;
	resetFormErrors: any;
	token: string;
	email: string;
	authSubscription: Subscription;

	constructor(
		private router: Router,
		private appService: AppService,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private _fuseConfigService: FuseConfigService,
		private _fuseSplashService: FuseSplashScreenService,
		private activatedRoute: ActivatedRoute
	){
		this.resetFormErrors = {
			token   : {},
			password: {}
		};
		this._fuseConfigService.config = {
			layout: {
				navbar   : {
					hidden: true
				},
				toolbar  : {
					hidden: true
				},
				footer   : {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	ngOnInit(): void{
		this.resetForm = this.formBuilder.group({
			password: ['', Validators.required],
			password_confirmation: ['', Validators.required],
		});

		this.authService.chekcUserStatus.subscribe(status => {
			if(status === CheckUserStatues.CHECKED){
				this._fuseSplashService.hide();
			}
		});

		this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
		this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
	}

	ngOnDestroy(): void{
		
	}

	onSubmitClick(): void{
		this.authService.resetPassword({
			email: this.email,
			token: this.token,
			password: this.resetForm.value.password,
			password_confirmation: this.resetForm.value.password_confirmation
		}).subscribe(response => {
			this.appService.showSuccessMessage('Password modificata');
			this.router.navigate(['auth/login']);
		});
	}
}
