import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Injector } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { MatSelect } from '@angular/material/select';
import * as _ from 'lodash';
var SelectFilterableInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectFilterableInputComponent, _super);
    function SelectFilterableInputComponent(appService, datasetService, inputService, injector) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        _this.injector = injector;
        _this.loading = false;
        _this.intlCollator = Intl.Collator(undefined, { sensitivity: 'base', usage: 'sort', ignorePunctuation: false });
        return _this;
    }
    SelectFilterableInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            if (_.has(_this.inputService, 'formInputDefinition.extra.allOptions')) {
                _this.inputService.formInputDefinition.extra.allOptions(_this.injector)
                    .subscribe(function (options) {
                    _this.allSelectOptions = options;
                    _this.buildSelectResourceOptions();
                    _this.updateSelectedItem();
                });
            }
            else {
                console.warn('set formInputDefinition.extra.allOptions', _this.inputService.formInputDefinition);
            }
            if (_.has(_this.inputService, 'formInputDefinition.extra.loading')) {
                _this.inputService.formInputDefinition.extra.loading(_this.injector)
                    .subscribe(function (loading) {
                    _this.loading = loading;
                });
            }
            _this.getFormControl().valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (value) {
                if (_this.inputService.formInputDefinition.extra && _this.inputService.formInputDefinition.extra.onSet) {
                    _this.inputService.formInputDefinition.extra.onSet(_this.inputService.formGroup, _this.getItemFromValue(value));
                }
                if (value) {
                    _this.updateSelectedItem();
                }
                else {
                    if (value === undefined) {
                        _this.getFormControl().setValue(null);
                        return;
                    }
                    _this.updateSelectedItem();
                }
            });
        });
    };
    SelectFilterableInputComponent.prototype.updateSelectedItem = function () {
        var value = this.getFormControl().value;
        this.selectedItem = this.allSelectOptions.find(function (el) {
            return el.value === value;
        });
        this.buildSelectResourceOptions();
    };
    SelectFilterableInputComponent.prototype.getFormControl = function () {
        return this.inputService.control;
    };
    SelectFilterableInputComponent.prototype.getItemFromValue = function (value) {
        if (!this.allSelectOptions)
            return;
        var selectedOption = this.allSelectOptions.find(function (el) {
            return el.value === value;
        });
        return selectedOption;
    };
    SelectFilterableInputComponent.prototype.buildSelectResourceOptions = function () {
        var _this = this;
        if (!this.allSelectOptions)
            return;
        this.loading = true;
        var searchLower = this.searchText && this.searchText.toLowerCase();
        var sortIndex = {};
        var options = this.allSelectOptions.filter(function (item) {
            var e_1, _a;
            if (!searchLower)
                return true;
            if (_this.selectedItem && _this.selectedItem.value == item.value)
                return true;
            if (!item.label)
                return false;
            var nameIndex = item.label.toLowerCase().indexOf(searchLower);
            if (nameIndex != -1) {
                sortIndex[item.value] = nameIndex;
                return true;
            }
            if (item.aliases && item.aliases.length) {
                try {
                    for (var _b = tslib_1.__values(item.aliases), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var alias = _c.value;
                        if (alias.indexOf(searchLower) != -1) {
                            sortIndex[item.value] = -1;
                            return true;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            return false;
        }).sort(function (a, b) {
            if (_this.selectedItem) {
                if (_this.selectedItem.value == a.value)
                    return -1;
                if (_this.selectedItem.value == b.value)
                    return 1;
            }
            if (!searchLower)
                return _this.intlCollator.compare(a.label, b.label);
            var i = sortIndex[a.value];
            var j = sortIndex[b.value];
            if (i == j)
                return _this.intlCollator.compare(a.label, b.label);
            return (i < j && i != -1) || j == -1 ? -1 : 1;
        }).slice(0, 20);
        /*if(this.selectedItem && !options.find(o => o.value == this.selectedItem.value)){
            options.unshift(this.selectedItem);
        }*/
        this.filteredSelectOptions = options;
        this.loading = false;
    };
    SelectFilterableInputComponent.prototype.search = function (text) {
        this.searchText = text;
        this.buildSelectResourceOptions();
    };
    SelectFilterableInputComponent.prototype.clear = function () {
        this.inputService.control.setValue(null);
        this.inputService.control.markAsDirty();
    };
    SelectFilterableInputComponent.prototype.openedChange = function (isOpen) {
        this.myInput.nativeElement.focus();
    };
    SelectFilterableInputComponent.prototype.selectionChange = function (event) {
        this.appService.eventEmitter.emit({
            name: 'selected_label_' + this.inputService.formInputDefinition.key,
            target: event.source['triggerValue']
        });
    };
    return SelectFilterableInputComponent;
}(DestroyableComponent));
export { SelectFilterableInputComponent };
