import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import infoForm from './forms/info.forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domains/detail/:recordId',
	params: {
		with_attributes: 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client',
		with_relations: 'entity'
	}
};

export const DomainsConfig: IDatasetConfig = {
	name: 'domains',
	title: 'Domini',
	singleTitle: 'Dominio',
	ajaxDatasetCode: 'domains',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: function(data: any): boolean {
			return data && data.user && data.user.role && data.user.role.level < 10;
		},
		edit: true,
		list: true
	},
	fetchConfig: {
		with_attributes: 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client',
		with_relations: 'entity'
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
		infoForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			},
			getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any{
				dataContainerService.putDatasetData('domainId', dataContainerService.getValueFromKeyPath('domains.recordId'));
				return params;
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_attributes = 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client';
				params.with_relations = 'entity';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Nome',
						key: 'display_name'
					},
					{
						title: 'Stato',
						key: 'status'
					},
					{
						title: 'Gerarchia',
						key: 'domain_path'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
