import { Component, Input, QueryList, ViewChildren, OnInit } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, IDatasetEvent, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { DatasetListComponent } from '../../../base-actions/list/dataset-list.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'common-data-viewer-step',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
})
export class CommonDataViewerComponent extends BaseStepViewComponent implements OnInit{
	
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		
	}
}
