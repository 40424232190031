<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence'>
		<mat-label>{{inputService.computedTitle | translate}}</mat-label>
		<mat-icon *ngIf='inputService.formInputDefinition.remoteOptions && inputService.formInputDefinition.remoteOptions.icon' matPrefix>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
		<input [required]='inputService.inputConfig.required' [readonly]='inputService.inputConfig.readOnly' class='dataset-search-input' (click)='openSelectionDialog()' matInput readonly type='text' autocomplete="off" [value]='displayValue'>
		<mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
		<button mat-icon-button *ngIf="!loading && !inputService.inputConfig.readOnly" matSuffix (click)='openSelectionDialog()'>
			<mat-icon>search</mat-icon>
		</button>
		<button mat-icon-button matSuffix *ngIf='selectedRecord && !loading && !inputService.inputConfig.readOnly' (click)='unselect()'>
			<span class="material-icons">close</span>
		</button>
		<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>