import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('active_status'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('priority_order'),
			fieldsMap.get('totem_priority')
		]
	},
	fieldsMap.get('description'),
	fieldsMap.get('full_description'),
	fieldsMap.get('tour_brief'),
	fieldsMap.get('schedule_info'),
	fieldsMap.get('before_leaving'),
	fieldsMap.get('included_services'),
	fieldsMap.get('excluded_services'),
	fieldsMap.get('voucher_text'),
	fieldsMap.get('voucher_text_ticketing'),
	fieldsMap.get('confirm_pickup_point_email_info'),
	fieldsMap.get('delivery_email_info'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('ribbon_sito_description'),
			fieldsMap.get('ribbon_sito_color'),
		]
	},
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
