import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';

import { CalendarComponent } from 'app/main/pages/calendar/calendar.component';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { CalendarEventFormDialogComponent } from 'app/main/pages/calendar/event-form/event-form.component';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FormInputViewerModule } from 'app/main/components/form-input-viewer/form-input-viewer.module';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarSidebarComponent } from './sidebar/sidebar.component';
import { ViewDayCalendarComponent } from './view-day-calendar/view-day-calendar.component';

@NgModule({
	declarations   : [
		CalendarComponent,
		CalendarEventFormDialogComponent,
		CalendarSidebarComponent,
		ViewDayCalendarComponent
	],
	imports : [

		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSlideToggleModule,
		MatToolbarModule,
		MatTooltipModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,

		AngularCalendarModule.forRoot({
			provide   : DateAdapter,
			useFactory: adapterFactory
		}),
		ColorPickerModule,

		FuseSidebarModule,
		FuseSharedModule,
		FuseConfirmDialogModule,
		FlexLayoutModule,
		RouterModule,
		FormInputViewerModule,

		DatasetModule,
		TranslateModule.forChild(),
	],
	providers      : [
		CalendarService
	],
	entryComponents: [
		CalendarEventFormDialogComponent
	]
})
export class CalendarModule
{
}
