import { IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import { fieldsMap } from './../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('booking_date'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('entity_id'),
			fieldsMap.get('entity_primary_email')
		]
	},
	fieldsMap.get('entity_customer_id'),
	fieldsMap.get('payment_type_id'),
	fieldsMap.get('origin_channel_voucher_code'),
	fieldsMap.get('note')
];

const baseInfoFormConfig: IFormConfig = {
	fields
};

export default baseInfoFormConfig;
