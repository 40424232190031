import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
var CommonDataViewerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonDataViewerComponent, _super);
    function CommonDataViewerComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        return _this;
    }
    CommonDataViewerComponent.prototype.ngOnInit = function () {
    };
    return CommonDataViewerComponent;
}(BaseStepViewComponent));
export { CommonDataViewerComponent };
