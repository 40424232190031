<div class="recap-container">
    <div class="recap flex-row justify-between">
        <div class=" flex-row font-weight-500 text-color-dark-blue font-size-14 text-left pt-8">
            <div class="justify-start">
                <span>
                <span class="label" *ngIf="dataRecap.direction=='FORWARD'||fw">ANDATA </span>
                <span class="label" *ngIf="dataRecap.direction=='RETURN' && !fw">RITORNO </span>
                {{dataRecap.start.name}}</span>
                <span> <mat-icon style="vertical-align: middle">chevron_right</mat-icon> </span>
                <span>{{dataRecap.end.name}} - </span>
                <span>{{dataRecap.departure| date: 'd/M/y H:mm'}}  </span>
                <span *ngIf="dataRecap.arrive"> - {{dataRecap.arrive | date: 'H:mm'}} </span>
                <span style="gap:8px; font-weight: 600">
                    <span style="font-weight: 500"> | {{dataRecap.adult}} ADT</span>
                    <span style="font-weight: 500" *ngIf="dataRecap.children>0">, {{dataRecap.children}} RGZ </span>
                </span>
                <span *ngIf="dataRecap.tot>0">
                    <span> |
                        <span class="total" *ngIf="!['RETURN','FORWARD'].includes(dataRecap.direction)"
                              matTooltip="{{dataRecap.fee>0?'Prezzo:'+(dataRecap.totNet| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(dataRecap.fee| currency:'EUR':'symbol':'1.2-2'):''}}">
                            Totale: {{dataRecap.tot | currency:'EUR':'symbol':'1.2-2' }}
                        </span>
                        <span class="total" *ngIf="['RETURN','FORWARD'].includes(dataRecap.direction)"
                              matTooltip="{{dataRecap.fee>0?'Prezzo:'+(dataRecap.totNet| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(dataRecap.fee| currency:'EUR':'symbol':'1.2-2'):''}}">
                            {{dataRecap.tot | currency:'EUR':'symbol':'1.2-2' }}</span>
                    </span>
                </span>
                <mat-icon class="open-details" (click)="onShowDetails()" *ngIf="dataAv">{{showDetails?'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
            </div>
        </div>
        <div class="flex-row">
            <div class="justify-end">
                <!--<mat-icon class="open-details" (click)="trnService.btnAction.next('back')" *ngIf="dataAv" [matTooltip]="'Modifica'">edit</mat-icon>-->
                <button mat-raised-button
                        *ngIf="dataAv && (trnService.step.value > 2||fw)"
                        [disabled]="trnService.loading.value" (click)="fw?trnService.btnAction.next('back-fw'):trnService.btnAction.next('back')">
                    Modifica
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>


    </div>
    <ng-container *ngIf="showDetails">
        <div class="train-info" *ngFor="let tr of trains; let last = last">
            <div class="flex-row flex-gap-20">
                    <mat-icon>train</mat-icon>
                    <trenitalia-train-breadcrumbs [trains]="[tr]"></trenitalia-train-breadcrumbs>
                <div >
                    <span class="tr-time">{{tr.departure | date:'HH:mm'}}</span>
                </div>
                <div >
                    {{tr.start.stationName}}
                </div>
                <div >
                    <mat-icon class="arr">keyboard_arrow_right</mat-icon>
                </div>
                <div >
                    <span class="tr-time">{{tr.arrival | date:'HH:mm'}}</span>
                </div>
                <div >
                    {{tr.end.stationName}}
                </div>
            </div>
            <div class="train-tickets-container flex-col">
                <ng-container *ngIf="dataRecap.details && dataRecap.details[tr.id]">
                    <div *ngFor="let ticket of dataRecap.details[tr.id].tickets">
                            <div class="train-ticket flex-row  flex-gap-8 justify-start">
                                <div>
                                    <div><strong>{{ticket.offerName}} / {{ticket.catalogServiceName}} <span *ngIf="ticket.position"> - {{ticket.position}} </span></strong> </div>
                                    <div *ngIf="ticket.traveller">{{ticket.traveller.type}}</div>
                                </div>
                                <div>
                                    <div *ngIf="ticket.seatInfo && ticket.seatInfo.seat !== '999'" fxLayout="row" fxLayoutGap="8px">
										<span matTooltip="Vagone" class="train-ticket-seat">
											<mat-icon>train</mat-icon>
                                            {{ticket.seatInfo.wagon}}
										</span>
                                        <span matTooltip="Posto" class="train-ticket-seat">
											<mat-icon>event_seat</mat-icon>
                                            {{ticket.seatInfo.seat}}
										</span>
                                    </div>
                                </div>
                                <div class="train-ticket-price" style="text-align: center;" *ngIf="ticket.prices">
                                    <span  class="font-weight-700" matTooltip="{{ticket.prices.fee>0?'Prezzo:'+(ticket.prices.netPrice| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(ticket.prices.fee | currency:'EUR':'symbol':'1.2-2'):''}}">
                                        {{ticket.prices.price | currency:'EUR':'symbol':'1.2-2'}}
                                    </span>
                                </div>
                            </div>

                    </div>
                </ng-container>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
        </div>
    </ng-container>
</div>