import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { Subject } from 'rxjs';
import { FormControl, FormBuilder } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import FilterFields from './filter.fields';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import formConfig from './form.fields';
var ɵ0 = { useUtc: true }, ɵ1 = MAT_MOMENT_DATE_FORMATS;
var DashboardPageComponent = /** @class */ (function () {
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    function DashboardPageComponent(_datasetService, dashboardService, formBuilder, appService, domainFilterSerice, fuseTranslationLoader, matDialog) {
        this._datasetService = _datasetService;
        this.dashboardService = dashboardService;
        this.formBuilder = formBuilder;
        this.appService = appService;
        this.domainFilterSerice = domainFilterSerice;
        this.fuseTranslationLoader = fuseTranslationLoader;
        this.matDialog = matDialog;
        this.fileterFields = FilterFields;
        this._unsubscribeAll = new Subject();
        this.resourceOptions = new Subject();
        this.resourceCtrl = new FormControl();
        this.filterFormGroup = this.formBuilder.group({
            branch_code: '',
            customer_code: null,
            package_code: null,
            category_id: null,
            booking_date_from: null,
            booking_date_to: null,
            departure_date_from: null,
            departure_date_to: null
        });
        // load translations
        this.fuseTranslationLoader.loadTranslations(english, italiano);
    }
    /**
     * On init
     */
    DashboardPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterFormGroup.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.setFiltersData(_this.filterFormGroup.getRawValue());
            _this.filterCharts(_this.filterFormGroup.getRawValue());
        });
        this.dashboardService.refreshEvent.next();
        this.domainFilterSerice.domainSettingChangeEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.dashboardService.refreshEvent.next();
        });
    };
    DashboardPageComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // --------------------------------------------------- Resource --------------------------------------------------------------
    DashboardPageComponent.prototype.resourceFilteredOptions = function (textSearch) {
        var _this = this;
        var params = {
            'text-search': textSearch,
            perPage: 5
        };
        var route = '/dataset/trip_resources';
        this._datasetService.get(route, params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                _this.resourceOptions.next(result.items);
            },
            error: function () {
            }
        });
    };
    DashboardPageComponent.prototype.onSelectResource = function (event) {
        this.dashboardService.filterResource.push(event.option.value);
        if (this.resourceInput)
            this.resourceInput.nativeElement.value = '';
        this.resourceCtrl.setValue(null);
        this.dashboardService.refreshEvent.next();
    };
    DashboardPageComponent.prototype.addResource = function (event) {
        if (!this.matAutocompleteRes.isOpen) {
            var input = event.input;
            var value = event.value;
            // Add our pacakge
            if (value) {
                this.dashboardService.filterResource.push(value);
            }
            // Reset the input value
            if (input) {
                input.value = '';
            }
            this.resourceCtrl.setValue(null);
            this.dashboardService.refreshEvent.next();
        }
    };
    DashboardPageComponent.prototype.removeResource = function (tripPackageId) {
        var index = this.dashboardService.filterResource.findIndex(function (el) {
            return el.id === tripPackageId;
        });
        if (index > -1) {
            this.dashboardService.filterResource.splice(index, 1);
            this.dashboardService.refreshEvent.next();
        }
    };
    // ------------------------------------------------------------------------------------------------------
    DashboardPageComponent.prototype.openFilters = function () {
        var _this = this;
        this.matDialog.open(FormDialogComponent, {
            width: '700px',
            data: {
                title: 'Filtri ricerca',
                formData: this.filtersData,
                formConfig: formConfig,
                positiveText: 'Applica'
            }
        })
            .afterClosed().subscribe(function (formData) {
            if (formData) {
                _this.setFiltersData(formData);
                _this.filterCharts(formData);
            }
        });
    };
    DashboardPageComponent.prototype.setFiltersData = function (formData) {
        this.filtersData = {
            branch_code: formData.branch_code,
            customer_code: formData.customer_code,
            package_code: formData.package_code,
            category_id: formData.category_id,
            booking_date_from: formData.booking_date_from,
            booking_date_to: formData.booking_date_to,
            departure_date_from: formData.departure_date_from,
            departure_date_to: formData.departure_date_to
        };
    };
    DashboardPageComponent.prototype.countFilters = function () {
        var e_1, _a;
        var increment = 0;
        var keysFilters = Object.keys(this.filtersData);
        try {
            //console.log(keysFilters);
            for (var keysFilters_1 = tslib_1.__values(keysFilters), keysFilters_1_1 = keysFilters_1.next(); !keysFilters_1_1.done; keysFilters_1_1 = keysFilters_1.next()) {
                var key = keysFilters_1_1.value;
                //console.log(key);
                if (this.filtersData[key]) {
                    //console.log(this.filtersData[key]);
                    increment++;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (keysFilters_1_1 && !keysFilters_1_1.done && (_a = keysFilters_1.return)) _a.call(keysFilters_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.numFilters = increment;
        //console.log(increment);
    };
    DashboardPageComponent.prototype.filterCharts = function (formData) {
        this.dashboardService.filterBranch = formData.branch_code;
        this.dashboardService.filterCustomerCodes = formData.customer_code;
        this.dashboardService.filterPackageCodes = formData.package_code;
        this.dashboardService.filterCategoryIds = formData.category_id;
        this.dashboardService.filterBookingDateFrom = formData.booking_date_from;
        this.dashboardService.filterBookingDateTo = formData.booking_date_to;
        this.dashboardService.filterDataS = formData.departure_date_from;
        this.dashboardService.filterDataE = formData.departure_date_to;
        this.dashboardService.refreshGroupingDate();
        this.countFilters();
        this.dashboardService.refreshEvent.next();
    };
    return DashboardPageComponent;
}());
export { DashboardPageComponent };
export { ɵ0, ɵ1 };
