import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';

import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
	selector: 'service-rule-documentation-dialog',
	templateUrl: 'dialog.component.html'
})
export class ServiceRuleDocumentationDialogComponent implements OnDestroy, OnInit {

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<void>,
		private fuseTranslationLoader: FuseTranslationLoaderService
	) {
		this._unsubscribeAll = new Subject();
		// carica traduzioni
		this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	ngOnInit(): void{
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.dialogRef.close(true);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
