import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
	TangerineBgColors,
	IDatasetCallDefinition,
} from 'app/interfaces';
import { fieldsMap } from './fields';
import generalForm from './general-form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { TripDestinationLanguageContentConfig } from './../trip_destination_language_contents';
import activeLanguageCodesField from 'app/configs/commons/shared-field-configs/active-language-codes.field';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_destinations/detail/:recordId',
	params: {
		with_relations: 'address'
	}
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

function getCallParams(
	dataContainerService: IDataContainerService,
	parentData: any,
	params: any
): any {
	if (params instanceof FormData) {
	} else {
		const addressData = {};
		for (const key in params) {
			if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
			if (!key.startsWith('address.')) continue;
			const newKey = key.replace('address.', '');
			const value = params[key];
			addressData[newKey] = value;
			delete params[key];
		}
		params.address = addressData;
	}
	return params;
}

export const TripDestinationConfig: IDatasetConfig = {
	name: 'trip_destinations',
	title: 'Destinazioni',
	singleTitle: 'Destinazione',
	ajaxDatasetCode: 'trip_destinations',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
	},
	formConfigsMap: {
		generalForm,
	},
	fetchConfig: {
		with_relations: 'address'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail, callModule],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [callModule],
			viewConfig: {
				formConfig: generalForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail, callModule],
			viewConfig: {
				formConfig: generalForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any) {
				params.with_relations = 'address';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(
							record,
							resourceACS,
							resourceNavigatorService
						): void {
							resourceNavigatorService.showDetail(
								resourceACS,
								record
							);
						},
					},
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes',
						fieldDefinition: activeLanguageCodesField
					}
				],
				actions: ['create', 'detail', 'delete'],
			},
		},
	},
	stepViewConfig: {
		tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
		title: 'Destinazione',
		isLinear(component: CommonStepViewContainerComponent): boolean {
			return !Boolean(component.record);
		},
		steps: [
			{
				title: 'Informazioni Base',
				stepperTitle: 'Informazioni <br> Base',
				component: 'common-form-step',
				options: {
					formConfig: generalForm,
				},
				completed(component): boolean {
					return Boolean(component.record);
				},
			},
			{
				title: 'Descrizioni',
				stepperTitle: 'Descrizioni',
				component: 'common-i18n-form-step',
				options: {
					datasetCode: 'trip_destination_language_contents',
					parentForeignKey: 'trip_destination_id',
					parentValueKey: 'trip_destinations.recordId',
					formConfig:
						TripDestinationLanguageContentConfig.formConfigsMap[
							'textForm'
						],
				},
			},
			{
				title: 'Media',
				stepperTitle: 'Media',
				component: 'common-i18n-blocks-step',
				options: {
					datasetCode: 'trip_destination_language_contents',
					parentForeignKey: 'trip_destination_id',
					parentValueKey: 'trip_destinations.recordId',
					blocks: [
						{
							type: 'document_file',
							title: 'Immagine Copertina',
							documentFileKey: 'image_id',
							uploadType: 'standard',
							imageOptions: {
								minWidth: 1920,
								minHeight: 400,
								ratio: 1920 / 400,
							},
						},
					],
				},
			},
			{
				title: 'Mappa',
				stepperTitle: 'Mappa',
				component: 'common-map-step',
				options: {
					markers: 0
				}
			}
		],
	},
};
