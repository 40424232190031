import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes, FieldViews } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

function scorporaIVA(importo: number, aliquota: number): string{
	const parsedAliquota = 1 + aliquota;
	const total = importo / parsedAliquota;
	return total.toFixed(2);
}

function tassazione(importo: number, aliquota: number): string{
	const total = (importo*(aliquota*100)) / 100;
	return total.toFixed(2);
}

fieldsMap.set('barcode', {
	key: 'barcode',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('quantity', {
	key: 'quantity',
	name: 'Quantità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('unitPrice', {
	key: 'unitPrice',
	name: 'Prezzo unitario',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): any{
		const importoScorporato = scorporaIVA(record.unitPrice, record.vat_rate);
		return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(parseFloat(importoScorporato));
	}
});

fieldsMap.set('tax', {
	key: 'tax',
	name: 'Tassa',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): any{
		let importoScorporato = parseFloat(scorporaIVA(record.unitPrice, record.vat_rate));
		const tax = tassazione(importoScorporato, record.vat_rate);
		return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(parseFloat(tax))
	}
});

fieldsMap.set('taxedPrice', {
	key: 'taxedPrice',
	name: 'Prezzo Tassato',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): any{
		return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(record.unitPrice);
	}
});

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('barcode'),
	fieldsMap.get('quantity'),
	fieldsMap.get('unitPrice'),
	fieldsMap.get('tax'),
	fieldsMap.get('taxedPrice')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;