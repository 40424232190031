import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';

import tripBookingParticipantViewConfig from 'app/configs/datasets/trip_booking_services/participant-view-config';
import { takeUntil } from 'rxjs/operators';
import { ISelectDatasetRecordDialogData, SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE, RELOAD_BOOKING_PACKAGES } from 'app/main/pages/edit-booking/edit-booking.component';
import { BookingStep2Service } from 'app/main/pages/edit-booking/step-2/view.service';

@Component({
	selector   : 'trip-booking-resource-item-view',
	templateUrl: './item.component.html',
	styleUrls  : ['./item.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService, BookingStep2Service]
})
export class TripBookingResourceItemViewComponent implements OnInit, OnChanges, OnDestroy{

	protected _unsubscribeAll: Subject<any>;

	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() item: any;
	@Output() delete: EventEmitter<void> = new EventEmitter();
	@Output() edit: EventEmitter<void> = new EventEmitter();
	@Output() reloadDatasetList: EventEmitter<void> = new EventEmitter();
	@Output() selectedResource: EventEmitter<any> = new EventEmitter();

	public viewConfig = tripBookingParticipantViewConfig;
	public trip_booking_resource: any;
	public trip_resource: any;
	public is_pickup: boolean;
	public not_activable_reason: any;

	constructor(
		protected datasetService: DatasetService,
		protected appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		public matDialog: MatDialog,
		public bookingStepService: BookingStep2Service){
			this._unsubscribeAll = new Subject<any>();
		}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_booking_resources',
			actionCode: 'detail',
			datasetRS: this.datasetRS,
			parentDatasetACS: this.parentDatasetACS,
			fetchData: false
		}).subscribe(ready => {
			if(!ready) return;
		});
	}

	init(): void{
		this.trip_booking_resource = this.datasetRS.record.value;
		this.trip_resource = this.trip_booking_resource.trip_resource;
		this.is_pickup = this.trip_resource && this.trip_resource.properties.resource_sub_types.value === 'PICKUP';
		this.not_activable_reason = this.makeNotActivableReason();
		// load meeting point
		if(this.is_pickup && this.trip_booking_resource.activated){
			this.bookingStepService.loadMeetingPoint(this.item);
		}
	}

	ngOnChanges(changes: SimpleChanges): void{
		this.datasetRS.record.next(this.item);
		this.init();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	activateResource(): void{
		this.bookingStepService.activateResource(this.item)
		.afterClosed()
		.subscribe(() => {
			this.reloadDatasetList.emit();
		});
	}

	deactivateResource(): void{
		this.bookingStepService.deactivateResource(this.item)
		.afterClosed()
		.subscribe(() => {
			this.reloadDatasetList.emit();
		});
	}

	makeNotActivableReason(): string{
		return this.bookingStepService.makeNotActivableReason(this.item);
	}

	selected(): void{
		this.selectedResource.emit(this.item);
	}
}
