import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, IDatasetCallDefinition, ValueTypes, InputTypes} from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general.form';
import {config as stepViewConfig} from './stepViewConfig';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import activeLanguageCodesField from 'app/configs/commons/shared-field-configs/active-language-codes.field';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/trip_resources/detail/:recordId',
	params: {
		with_relations: 'domain',
		with_attributes: 'is_owner'
	}
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules,twilio'
	}
};

export const TripResourceConfig: IDatasetConfig = {
	name: 'trip_resources',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'trip_resources',
	fieldsMap: fieldsMap,
	supportProperties: true,
	fetchConfig: {
		with_relations: 'domain',
		with_attributes: 'is_owner'
	},
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	stepViewConfig: stepViewConfig,
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ callModule ],
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_relations = 'domain,staff_elements';
				return params;
			},
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: 'Tipo / Tipologia',
						key: 'resource_type_sub_types_view'
					},
					{
						title: 'Allotment',
						key: 'allotment_type'
					},
					{
						title: 'Traduzioni Atttive',
						key: 'active_language_codes',
						fieldDefinition: activeLanguageCodesField
					},
					{
						title: 'Staff',
						key: 'staff_description'
					}
				],
				actions: ['create', 'detail', 'delete'],
				filterForm: {
					fields: [
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('resource_type_filter')
							]
						},						
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('resource_sub_types_filter')
							]
						},
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('allotment_type_filter')
							]
						},
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('staff_filter')
							]
						}
					]
				}
			}
		}
	}
};
