import * as tslib_1 from "tslib";
import { ListViewItemTypes, FieldViews } from 'app/interfaces';
import { fieldsMap } from './fields';
export var TripOptionsConfig = {
    name: 'trip_options',
    title: 'Options setup',
    singleTitle: 'Options setup',
    ajaxDatasetCode: 'trip_options',
    fieldsMap: fieldsMap,
    supportProperties: true,
    enabledActions: {
        detail: false,
        create: false,
        edit: false,
        delete: true,
        list: true
    },
    defaultActions: {
        list: {
            viewConfig: {
                itemViewType: ListViewItemTypes.TABLE,
                showSearchBar: false,
                defaultSortBy: 'created_at',
                defaultSortDirection: 'desc',
                columns: [
                    {
                        title: 'Codice',
                        key: 'code',
                    },
                    {
                        title: 'Descrizione',
                        key: 'description',
                    },
                    {
                        title: 'Durata',
                        key: 'duration',
                    },
                    {
                        title: 'Traduzioni Attive',
                        key: 'languages',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record) {
                            var e_1, _a;
                            var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                            if (record.languages && record.languages.length > 0) {
                                var style = 'style="margin-right: 5px;"';
                                try {
                                    for (var _b = tslib_1.__values(record.languages), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var lang = _c.value;
                                        var langVal = lang.value;
                                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + langVal + '.svg" alt="' + langVal + '" title="' + langVal + '" />';
                                    }
                                }
                                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_1) throw e_1.error; }
                                }
                            }
                            else {
                                html += '<span>---</span>';
                            }
                            html += '</div>';
                            return html;
                        }
                    },
                    {
                        title: 'Lingue audio guide',
                        key: 'audio_guide_languages',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record) {
                            var e_2, _a;
                            var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
                            if (record.audio_guide_languages && record.audio_guide_languages.length > 0) {
                                var style = 'style="margin-right: 5px;"';
                                try {
                                    for (var _b = tslib_1.__values(record.audio_guide_languages), _c = _b.next(); !_c.done; _c = _b.next()) {
                                        var lang = _c.value;
                                        var langVal = lang.value;
                                        html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + langVal + '.svg" alt="' + langVal + '" title="' + langVal + '" />';
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                            }
                            else {
                                html += '<span>---</span>';
                            }
                            html += '</div>';
                            return html;
                        }
                    }
                ]
            },
        }
    }
};
