import { InputTypes, ValueTypes, IFieldDefinition, FieldViews } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { fieldsMap as userFieldMap} from '../users/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('domain_user_role_id', {
	key: 'domain_user_role_id',
	name: 'Ruolo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domain_user_roles',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: DatasetActionContainerService, record: any): any {
			return {domain_id: record['domain.id']};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		}
	}
});

fieldsMap.set('branch_id', {
	key: 'branch_id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any {
			return {domain_id: record['domain.id']};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('domain_id', {
	key: 'domain.id',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
});

userFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'user.' + value.key });
	fieldsMap.set('user.' + key, value);
});

export {fieldsMap};
