import { Injectable, Injector} from '@angular/core';
import { BehaviorSubject, Subscription} from 'rxjs';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { IPaginationResponse } from 'app/interfaces';

@Injectable()
export class LogsService{
	public loading = false;

	public currentPage = 1;
	public perPage = 10;
	public detailCurrentPage = 1;
	public detailPerPage = 10;
	public fetchSubscription: Subscription;
	public listPaginationResponse: IPaginationResponse<any>;
	public listItems: any[] = [];
	public detailItems: any[] = [];
	public detailPaginationResponse: IPaginationResponse<any>;
	public selectedBulkId: BehaviorSubject<string>;
	public filterData: any = {
		log_type: null
	};

	constructor(
		private datasetService: DatasetService
	){
		this.selectedBulkId = new BehaviorSubject(null);

		this.selectedBulkId.subscribe(value => {
			this.fetchDetail();
		});
	}

	onFilterDataChange(newFilterData): void{
		this.filterData = newFilterData;
		this.fetch();
	}

	fetch(): void{
		const params = {
			page: this.currentPage,
			perPage: this.perPage,
			log_type: this.filterData.log_type,
			user_id: this.filterData.user_id,
			start_from: this.filterData.start_from,
			start_to: this.filterData.start_to
		};
		for (const key in params) {
			if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
			if(params[key] === undefined || params[key] === null){
				delete params[key];
			}
		}
		if(this.fetchSubscription){
			this.fetchSubscription.unsubscribe();
		}
		this.selectedBulkId.next(null);
		this.loading = true;
		this.fetchSubscription = this.datasetService.get<IPaginationResponse<any>>('/dataset/app_logs', params)
		.subscribe({
			next: response => {
				this.listPaginationResponse = response;
				this.listItems = response.items;
				this.loading = false;
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	fetchDetail(): void{
		if(!this.selectedBulkId.value){
			this.detailItems = [];
			return;
		}
		const params = {
			page: this.detailCurrentPage,
			perPage: this.detailPerPage,
		};
		if(this.fetchSubscription){
			this.fetchSubscription.unsubscribe();
		}
		this.loading = true;
		this.fetchSubscription = this.datasetService.get<IPaginationResponse<any>>('/dataset/app_logs/detail/' + this.selectedBulkId.value, params)
		.subscribe({
			next: response => {
				this.detailItems = response.items;
				this.detailPaginationResponse = response;
				this.loading = false;
			},
			error: () => {
				this.loading = false;
			}
		});
	}
}
