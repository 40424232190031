export function makeDrawValuePlugin(options?: any): Chart.PluginServiceRegistrationOptions{

	let textAlign: CanvasTextAlign = 'center';
	let textBaseline: CanvasTextBaseline = 'bottom';
	let formatDataFun = (value): string => { 
		return Number(value).toLocaleString('US'); 
	}; 
	if(options){
		if(options.textPosition){
			if(options.textPosition.align) textAlign = options.textPosition.align;
			if(options.textPosition.baseline) textBaseline = options.textPosition.baseline;
		}
		if(typeof(options.formatDataFun)) formatDataFun = options.formatDataFun;
	}

	return {
		afterDatasetsDraw(chart, e, o): void{
			const ctx = chart.ctx;
			ctx.save();
			ctx.textAlign = textAlign;
			ctx.textBaseline = textBaseline;
			
			chart.data.datasets.forEach(function (dataset, i): void {
				const meta = chart.getDatasetMeta(i);
				ctx.fillStyle = String(dataset.borderColor);
				
				meta.data.forEach(function (bar, index): void {
					const data = dataset.data[index];
					if(Array.isArray(dataset.borderColor)){
						ctx.fillStyle = String(dataset.borderColor[index]);
					}
					let textData = data.toString();
					if(formatDataFun) textData = formatDataFun(data);
					if(textData) ctx.fillText(textData, bar._model.x, bar._model.y );
				});
			});
			ctx.restore();
		}
	};
}