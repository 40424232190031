import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class BaseViewComponent implements OnInit, OnDestroy{
	
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService
	){
		this._unsubscribeAll = new Subject<any>();
	}

	ngOnInit(): void{}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
