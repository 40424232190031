import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import {AuthService} from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { SaleOperationConfig } from 'app/configs/datasets/sale_operations';

import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { IFormConfig } from 'app/interfaces';
import { TransmissionOperationConfig } from 'app/configs/datasets/transmission_operations';

@Component({
	selector: 'transmission-data',
	templateUrl: './transmission-data.component.html',
	styleUrls: ['./transmission-data.component.scss']
})
export class TransmissionDataComponent extends BaseViewComponent implements OnInit {

	formConfig: IFormConfig;
	request_document_RT_form: IFormConfig;
	response_document_RT_form: IFormConfig;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService
	){
		super(datasetACS, datasetRS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.formConfig = TransmissionOperationConfig.formConfigsMap['detailForm'];
		this.request_document_RT_form = TransmissionOperationConfig.formConfigsMap['request_document_RT_form'];
		this.response_document_RT_form = TransmissionOperationConfig.formConfigsMap['response_document_RT_form'];
	}
}
