import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('primary_email'),
	fieldsMap.get('piva'),
	fieldsMap.get('fiscal_code'),
	fieldsMap.get('natural_person'),
	fieldsMap.get('firstname'),
	fieldsMap.get('lastname'),
	fieldsMap.get('birth_day'),
	fieldsMap.get('birth_location'),
	fieldsMap.get('gender')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
