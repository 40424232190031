import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'trip_category_id',
	name: 'Categoria',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_categories',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
	}
};
