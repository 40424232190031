/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../base-actions/list/dataset-list.component.ngfactory";
import * as i2 from "../../../services/dataset-action-container.service";
import * as i3 from "../../../../../../services/app.service";
import * as i4 from "../../../../../pages/page-closure-container.service";
import * as i5 from "../../../../../../services/auth.service";
import * as i6 from "../../../services/dataset.service";
import * as i7 from "../../../../../../services/domain-filter.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../base-actions/list/dataset-list.service";
import * as i10 from "../../../base-actions/list/dataset-list.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/dataset-navigator.service";
import * as i13 from "../../../../../../../@fuse/services/translation-loader.service";
import * as i14 from "@angular/material/snack-bar";
import * as i15 from "../../../../../../services/websocket.service";
import * as i16 from "../../../../document-file-block/view.component.ngfactory";
import * as i17 from "../../../../document-file-block/view.component";
import * as i18 from "@angular/material/dialog";
import * as i19 from "../resources/resource-inputs/resource-inputs.component.ngfactory";
import * as i20 from "../resources/resource-inputs/resource-inputs.component";
import * as i21 from "../../../services/dataset-record.service";
import * as i22 from "@angular/common";
import * as i23 from "../../../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i24 from "@angular/material/tabs";
import * as i25 from "@angular/platform-browser/animations";
import * as i26 from "./sub-dataset-step.component";
import * as i27 from "../../../services/step-state.service";
var styles_CommonSubDatasetBlocksStepComponent = [];
var RenderType_CommonSubDatasetBlocksStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommonSubDatasetBlocksStepComponent, data: {} });
export { RenderType_CommonSubDatasetBlocksStepComponent as RenderType_CommonSubDatasetBlocksStepComponent };
function View_CommonSubDatasetBlocksStepComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "dataset-list", [], null, [[null, "created"], [null, "datasetEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("created" === en)) {
        var pd_0 = (_co.onCreatedInBlock(_v.parent.context.blockConfig) !== false);
        ad = (pd_0 && ad);
    } if (("datasetEvent" === en)) {
        var pd_1 = (_co.onDatasetEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DatasetListComponent_0, i1.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i2.DatasetActionContainerService, i2.DatasetActionContainerService, [i3.AppService, i4.PageClosureContainerService, i5.AuthService, i6.DatasetService, i7.DomainFilterService, i8.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i9.DatasetListService, [i2.DatasetActionContainerService, i6.DatasetService]), i0.ɵdid(3, 770048, [[1, 4], ["listBlocks", 4]], 0, i10.DatasetListComponent, [i11.ActivatedRoute, i6.DatasetService, i11.Router, i5.AuthService, i3.AppService, i2.DatasetActionContainerService, i12.DatasetNavigatorService, i7.DomainFilterService, "ListParamService", i13.FuseTranslationLoaderService, i14.MatSnackBar, i15.WebSocketService], { autoFetchData: [0, "autoFetchData"], showTitle: [1, "showTitle"], datasetCode: [2, "datasetCode"], parentDatasetACS: [3, "parentDatasetACS"], parentDatasetRS: [4, "parentDatasetRS"], filters: [5, "filters"], showSearchBar: [6, "showSearchBar"], elevate: [7, "elevate"], conditionalShowPaginationToolbar: [8, "conditionalShowPaginationToolbar"] }, { created: "created", datasetEvent: "datasetEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = (_co.enableBlocks.length > 1); var currVal_2 = _v.parent.context.blockConfig.datasetCode; var currVal_3 = (_co.blockDatasetActionContainerServices[_v.parent.context.index] || _co.datasetACS); var currVal_4 = (_co.blockDatasetRecordServices[_v.parent.context.index] || _co.datasetRS); var currVal_5 = _co.blockFilters[_v.parent.context.index]; var currVal_6 = false; var currVal_7 = false; var currVal_8 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_CommonSubDatasetBlocksStepComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "document-file-block", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.datasetRS.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DocumentFileBlockComponent_0, i16.RenderType_DocumentFileBlockComponent)), i0.ɵdid(1, 770048, null, 0, i17.DocumentFileBlockComponent, [i3.AppService, i12.DatasetNavigatorService, i2.DatasetActionContainerService, i6.DatasetService, i18.MatDialog], { record: [0, "record"], documentFileKey: [1, "documentFileKey"], uploadRoute: [2, "uploadRoute"], title: [3, "title"], type: [4, "type"], getUploadDataFun: [5, "getUploadDataFun"], imageOptions: [6, "imageOptions"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetRS.record.value; var currVal_1 = _v.parent.context.blockConfig.documentFileKey; var currVal_2 = _v.parent.context.blockConfig.uploadRoute; var currVal_3 = (!_v.parent.context.inTab && _v.parent.context.blockConfig.title); var currVal_4 = "image"; var currVal_5 = _v.parent.context.blockConfig.getUploadData; var currVal_6 = _v.parent.context.blockConfig.imageOptions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CommonSubDatasetBlocksStepComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "resource-inputs", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.datasetRS.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_ResourceInputsComponent_0, i19.RenderType_ResourceInputsComponent)), i0.ɵdid(1, 114688, null, 0, i20.ResourceInputsComponent, [i18.MatDialog, i2.DatasetActionContainerService, i21.DatasetRecordService, i6.DatasetService, i12.DatasetNavigatorService, i3.AppService], { data: [0, "data"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetRS.record.value.data; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CommonSubDatasetBlocksStepComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ": ", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Blocco non configurato")); var currVal_1 = _v.parent.context.blockConfig.type; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_CommonSubDatasetBlocksStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i22.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_2)), i0.ɵdid(3, 278528, null, 0, i22.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_3)), i0.ɵdid(5, 278528, null, 0, i22.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_4)), i0.ɵdid(7, 278528, null, 0, i22.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i22.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_5)), i0.ɵdid(9, 16384, null, 0, i22.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i22.NgSwitch], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.blockConfig.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "list"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "document_file"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "resource-input"; _ck(_v, 7, 0, currVal_3); }, null); }
function View_CommonSubDatasetBlocksStepComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_v.parent.parent.context.$implicit.title)); _ck(_v, 0, 0, currVal_0); }); }
function View_CommonSubDatasetBlocksStepComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i22.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(2, { blockConfig: 0, inTab: 1, index: 2 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit, true, _v.parent.parent.context.index); var currVal_1 = i0.ɵnov(_v.parent.parent.parent.parent, 1); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CommonSubDatasetBlocksStepComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 7, "mat-tab", [], null, null, null, i23.View_MatTab_0, i23.RenderType_MatTab)), i0.ɵdid(2, 770048, [[2, 4]], 2, i24.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 3, { templateLabel: 0 }), i0.ɵqud(335544320, 4, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CommonSubDatasetBlocksStepComponent_9)), i0.ɵdid(6, 16384, [[3, 4]], 0, i24.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(0, [[4, 2]], 0, 1, null, View_CommonSubDatasetBlocksStepComponent_10)), i0.ɵdid(8, 16384, null, 0, i24.MatTabContent, [i0.TemplateRef], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CommonSubDatasetBlocksStepComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_8)), i0.ɵdid(2, 16384, null, 0, i22.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableBlocks[_v.context.index]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CommonSubDatasetBlocksStepComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i23.View_MatTabGroup_0, i23.RenderType_MatTabGroup)), i0.ɵdid(1, 3325952, null, 1, i24.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i24.MAT_TABS_CONFIG], [2, i25.ANIMATION_MODULE_TYPE]], null, null), i0.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_7)), i0.ɵdid(4, 278528, null, 0, i22.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.stepConfig.options.blocks; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).dynamicHeight; var currVal_1 = (i0.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CommonSubDatasetBlocksStepComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i22.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { blockConfig: 0, inTab: 1, index: 2 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit, false, _v.parent.context.index); var currVal_1 = i0.ɵnov(_v.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CommonSubDatasetBlocksStepComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_13)), i0.ɵdid(2, 16384, null, 0, i22.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableBlocks[_v.context.index]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CommonSubDatasetBlocksStepComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_12)), i0.ɵdid(2, 278528, null, 0, i22.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig.options.blocks; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CommonSubDatasetBlocksStepComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { listBlocks: 1 }), (_l()(), i0.ɵand(0, [["blockTemplate", 2]], null, 0, null, View_CommonSubDatasetBlocksStepComponent_1)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_6)), i0.ɵdid(4, 16384, null, 0, i22.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonSubDatasetBlocksStepComponent_11)), i0.ɵdid(6, 16384, null, 0, i22.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.enableBlocks.length > 1); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.enableBlocks.length === 1); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CommonSubDatasetBlocksStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "common-sub-dataset-blocks-step", [], null, null, null, View_CommonSubDatasetBlocksStepComponent_0, RenderType_CommonSubDatasetBlocksStepComponent)), i0.ɵprd(512, null, i2.DatasetActionContainerService, i2.DatasetActionContainerService, [i3.AppService, i4.PageClosureContainerService, i5.AuthService, i6.DatasetService, i7.DomainFilterService, i8.TranslateService, i0.Injector]), i0.ɵprd(512, null, i21.DatasetRecordService, i21.DatasetRecordService, [i2.DatasetActionContainerService, i6.DatasetService]), i0.ɵdid(3, 4440064, null, 0, i26.CommonSubDatasetBlocksStepComponent, [i3.AppService, i21.DatasetRecordService, i2.DatasetActionContainerService, i12.DatasetNavigatorService, i0.Injector, i27.StepService, i6.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CommonSubDatasetBlocksStepComponentNgFactory = i0.ɵccf("common-sub-dataset-blocks-step", i26.CommonSubDatasetBlocksStepComponent, View_CommonSubDatasetBlocksStepComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { CommonSubDatasetBlocksStepComponentNgFactory as CommonSubDatasetBlocksStepComponentNgFactory };
