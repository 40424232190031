import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { userCreatefieldsMap } from '../user-create.fields';

const userCreateFormFields: (IFieldContainer|IFieldDefinition)[] = [
	userCreatefieldsMap.get('access_username'),
	userCreatefieldsMap.get('name'),
	userCreatefieldsMap.get('email'),
	userCreatefieldsMap.get('domain_id'),
	userCreatefieldsMap.get('internal_branch_id'),
	userCreatefieldsMap.get('domain_user_role_id'),
	userCreatefieldsMap.get('internal_branch_id_detail'),
	userCreatefieldsMap.get('domain_user_role_id_detail'),
	userCreatefieldsMap.get('password_input'),
	userCreatefieldsMap.get('password_confirm')
];

export default {
	fields: userCreateFormFields 
};
