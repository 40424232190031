/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../data-viewer/container/data-container-viewer.component.ngfactory";
import * as i6 from "../../data-viewer/container/data-container-viewer.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../language-select/language-select.component.ngfactory";
import * as i9 from "../../../../language-select/language-select.component";
import * as i10 from "../../../../../../services/app.service";
import * as i11 from "../../../services/dataset-action-container.service";
import * as i12 from "../../../../../pages/page-closure-container.service";
import * as i13 from "../../../../../../services/auth.service";
import * as i14 from "../../../services/dataset.service";
import * as i15 from "../../../../../../services/domain-filter.service";
import * as i16 from "../../../services/dataset-record.service";
import * as i17 from "../../../services/dataset-multilanguage-container.service";
import * as i18 from "./step.component";
import * as i19 from "../../../services/dataset-navigator.service";
var styles_CommonI18nDataViewerStepComponent = [];
var RenderType_CommonI18nDataViewerStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommonI18nDataViewerStepComponent, data: {} });
export { RenderType_CommonI18nDataViewerStepComponent as RenderType_CommonI18nDataViewerStepComponent };
function View_CommonI18nDataViewerStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CommonI18nDataViewerStepComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Questa traduzione non \u00E8 presente!")); _ck(_v, 2, 0, currVal_0); }); }
function View_CommonI18nDataViewerStepComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "data-container-viewer", [], null, null, null, i5.View_DataContainerViewerComponent_0, i5.RenderType_DataContainerViewerComponent)), i0.ɵdid(1, 180224, null, 0, i6.DataContainerViewerComponent, [], { fields: [0, "fields"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig.options.fields; var currVal_1 = _co.datasetACS; var currVal_2 = _co.datasetRS.record.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CommonI18nDataViewerStepComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "mt-24"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonI18nDataViewerStepComponent_3)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonI18nDataViewerStepComponent_4)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.datasetRS.record.value && !_co.datasetACS.loading.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.datasetRS.record.value; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CommonI18nDataViewerStepComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "language-select", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.appService.language.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_LanguageSelectComponent_0, i8.RenderType_LanguageSelectComponent)), i0.ɵdid(2, 114688, null, 0, i9.LanguageSelectComponent, [i10.AppService], { languageCodes: [0, "languageCodes"], activeLanguageCodes: [1, "activeLanguageCodes"], selected: [2, "selected"] }, { changed: "changed" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonI18nDataViewerStepComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonI18nDataViewerStepComponent_2)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appService.supportedLanguageCodes; var currVal_1 = _co.datasetMCS.activeLanguageCodes; var currVal_2 = _co.appService.language.value; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.datasetACS.loading.value; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.datasetACS.ready.value; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_CommonI18nDataViewerStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "common-i18n-data-viewer-step", [], null, null, null, View_CommonI18nDataViewerStepComponent_0, RenderType_CommonI18nDataViewerStepComponent)), i0.ɵprd(512, null, i11.DatasetActionContainerService, i11.DatasetActionContainerService, [i10.AppService, i12.PageClosureContainerService, i13.AuthService, i14.DatasetService, i15.DomainFilterService, i4.TranslateService, i0.Injector]), i0.ɵprd(512, null, i16.DatasetRecordService, i16.DatasetRecordService, [i11.DatasetActionContainerService, i14.DatasetService]), i0.ɵprd(512, null, i17.DatasetMultilanguageContainerService, i17.DatasetMultilanguageContainerService, [i10.AppService, i14.DatasetService]), i0.ɵdid(4, 4440064, null, 0, i18.CommonI18nDataViewerStepComponent, [i10.AppService, i16.DatasetRecordService, i11.DatasetActionContainerService, i19.DatasetNavigatorService, i14.DatasetService, i17.DatasetMultilanguageContainerService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var CommonI18nDataViewerStepComponentNgFactory = i0.ɵccf("common-i18n-data-viewer-step", i18.CommonI18nDataViewerStepComponent, View_CommonI18nDataViewerStepComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", isLastStep: "isLastStep", formConfig: "formConfig", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { CommonI18nDataViewerStepComponentNgFactory as CommonI18nDataViewerStepComponentNgFactory };
