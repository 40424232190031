export const locale = {
	lang: 'en',
	data: {
		'VALORI': 'VALUES',
		'ADT  numero di adult': 'ADT  number of adults',
		'CHD  numero di child': 'CHD  number of child',
		'INF  numero di infant': 'INF  number of infants',
		'SNR  numero di senior': 'SNR  number of seniors',
		'PAX  numero di partecipanti della prenotazione': 'PAX  number of participants in the booking',
		'DPR  data prenotazione': 'DPR  date of booking',
		'DCO  data conferma': 'DCO  confirmed',
		'DAN  data annullamento': 'DAN  cancellation date',
		'DPA  data partenza/inizio': 'DPA  departure/start date',
		'DRI  data ritorno/fine': 'DRI  return/end date',
		'OPA  ora inizio': 'OPA  start time',
		'ORI  ora fine': 'ORI  end time',
		'GIO  numero di giorni': 'GIO  number of days',
		'NOT  totale di notti': 'NOT  total nights',
		'CPA  codice pacchetto': 'CPA  package code',
		// tslint:disable-next-line: max-line-length
		'LIS  codice listino del cliente permette di definire servizi diversi da addebitare in base al cliente': 'LIS  customer price list code allows you to define different services to be charged based on the customer',
		'TOT  totale/costo riga': 'TOT  Total/Line Cost',
		'PAC  totale/costo pacchetto': 'PAC  total / package cost',
		'PRE  totale/costo prenotazione': 'PRE  total / booking cost',
		'TRUE sempre vero': 'TRUE always true',
		'FALSE sempre falso': 'FALSE always false',
		'Funzioni': 'Functions',
		'date() data del sistema operativo': 'date () date of the operating system',
		'date(text) converte text in una data valida (es. 2019-01-01)': 'date (text) converts text to a valid date (e.g. 2019-01-01)',
		// tslint:disable-next-line: max-line-length
		'date_diff(d1, d2) ritorna la differenza in giorni tra la prima data (d1) e la seconda data (d2)': 'date_diff (d1, d2) returns the difference in days between the first date (d1) and the second date (d2)',
		'Operatori aritmentici': 'Arithmetic operators',
		'+ addizione': '+ addition',
		'- sottrazione': '- subtraction',
		'* moltiplicazione': '* multiplication',
		'/ divisione': '/ division',
		'% modulo': '% module',
		'** potenza': '** power',
		'Operatori logic': 'Logic operators',
		'AND oppure &&  per legare due condizioni che devono risultare vere': 'AND or && to bind two conditions that must be true',
		'OR  oppure ||  per legare due condizioni di cui almeno una vera': 'OR or || to link two conditions, at least one of which is true',
		'NOT oppure !  per negare una condizione': 'NOT or! to negate a condition',
		'Operatori di comparazione': 'Comparison operators',
		'a == b ritorna vero se a è uguale a b': 'a == b returns true if a equals b',
		'a != b ritorna vero se a è diverso da b': 'a! = b returns true if a is different from b',
		'a < b ritorna vero se a è minore a b': 'a <b returns true if a is less than b',
		'a > b ritorna vero se a è maggiore a b': 'a> b returns true if a is greater than b',
		'a <= b ritorna vero se a è minore o uguale a b': 'a <= b returns true if a is less than or equal to b',
		'a => b ritorna vero se a è maggiore o uguale a b': 'a => b returns true if a is greater than or equal to b',
		'a matches b ritorna vero se a rispetta l\'espressione regolare b': 'a matches b returns true if a matches the regular expression b',
		'Operatori su array': 'Array Operators',
		'a in b  ritorna vero se a è contenuto nell\'array b': 'a in b returns true if a is contained in the array b',
		'a notin b  ritorna vero se a non è contenuto nell\'array b': 'a notin b returns true if a is not contained in the array b',
		'Esempi di confizioni': 'Examples of confictions',
		'se nella prenotazione c\'è almeno un child': 'if there is at least one child in the reservation',
		'se fra la data partenza e la data di prenotazione ci sono almeno 60 giorni': 'if there are at least 60 days between the departure date and the booking date',
		'se la durata della prenotazione è tra 7 e 15 giorni': 'if the duration of the reservation is between 7 and 15 days',
		'se la data di partenza è nel griono 15/08/2008': 'if the departure date is in the 15th August 2008'
	}
};
