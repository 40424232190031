import * as tslib_1 from "tslib";
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import newCustomerForm from './forms/new-customer.form';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
function requireCustomerData(dataContainerService, datasetNavigator, errors) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef, res;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dialogRef = datasetNavigator.openDialog(FormDialogComponent, {
                        title: 'Seleziona un listino',
                        dataContainerService: dataContainerService,
                        formConfig: newCustomerForm,
                        formErrors: errors
                    });
                    return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                case 1:
                    res = _a.sent();
                    if (!res)
                        return [2 /*return*/];
                    return [2 /*return*/, {
                            unified_list_id: res.unified_list_id,
                            payment_type_id: res.payment_type_id
                        }];
            }
        });
    });
}
export default function action(data, datasetACS, datasetNavigatorService) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var loadingDialogRef, result, e_1, customerData, newData;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingDialogRef = datasetNavigatorService.openDialog(ActionDialogComponent, {
                        title: 'Attendere',
                        contentMessage: ''
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 6]);
                    return [4 /*yield*/, datasetNavigatorService.datasetService.post('/dataset/domain_packages/command/set_state', data)
                            .toPromise()];
                case 2:
                    result = _a.sent();
                    if (datasetACS instanceof DatasetActionContainerService) {
                        datasetACS.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
                    }
                    loadingDialogRef.close();
                    return [2 /*return*/, result]; // complete, succes
                case 3:
                    e_1 = _a.sent();
                    loadingDialogRef.close();
                    if (!(e_1.error.message === 'seleziona-listino' || e_1.error.errors || (e_1.error.action_required && e_1.error.action_required.code === 'new-customer-data'))) return [3 /*break*/, 5];
                    return [4 /*yield*/, requireCustomerData(datasetACS, datasetNavigatorService, e_1.error.errors)];
                case 4:
                    customerData = _a.sent();
                    if (!customerData)
                        return [2 /*return*/, false]; // cancel the operation
                    newData = Object.assign({}, data, customerData);
                    return [2 /*return*/, action(newData, datasetACS, datasetNavigatorService)];
                case 5: return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    });
}
