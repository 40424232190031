<div id="carnet-search">

        <div fxLayout="row text-center" fxLayoutGap="12px">
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("carnet")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["carnet"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("name_carnet")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["name_carnet"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("lastname_carnet")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["lastname_carnet"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
        </div>

    <mat-card-footer align='start'>
        <button mat-flat-button color='accent' (click)="searchCarnet()" [disabled]="trnService.loading.getValue()">Conferma</button>
    </mat-card-footer>
</div>