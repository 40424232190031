import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { LogsService } from '../../logs.service';

@Component({
	selector     : 'logs-list',
	templateUrl  : './list.component.html',
	styleUrls    : ['./list.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LogsListComponent implements OnInit{

	/**
	 * Constructor
	 *
	 */
	constructor(
		protected appService: AppService,
		protected http: HttpClient,
		protected datasetService: DatasetService,
		public logsService: LogsService
	){}

	ngOnInit(): void{
		
	}

	public onPageChanged(event: any): void{
		this.logsService.currentPage = event.pageIndex + 1;
		this.logsService.perPage = event.pageSize;
		this.logsService.fetch();
	}

}
