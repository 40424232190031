import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActionDialogComponent } from './action-dialog.component';

@NgModule({
	declarations: [
		ActionDialogComponent
	],
	imports: [
		MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule,
		MatMenuModule, MatSelectModule, MatTableModule, MatTabsModule,
		MatProgressSpinnerModule, MatProgressBarModule, MatPaginatorModule,
		MatCardModule, MatExpansionModule, MatToolbarModule, MatInputModule,
		MatFormFieldModule, MatTooltipModule, MatBadgeModule, MatDialogModule,

		FuseSharedModule,
		TranslateModule.forChild()
	],
	entryComponents: [
		ActionDialogComponent
	],
	exports     : [
		ActionDialogComponent
	]
})
export class ActionDialogModule{}
