import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, EMPTY } from "rxjs";
import { TrenitaliaService } from "../trenitalia.service";
import * as _ from "lodash";
import { catchError, exhaustMap, filter, finalize, take } from "rxjs/operators";
import { RELOAD_BOOKING_PACKAGES } from "../../edit-booking.component";
import { PostSaleDialogComponent } from "./post-sale-dialog/post-sale-dialog.component";
import { AppService } from "app/services/app.service";
import { BookingService } from "../../booking.service";
import { TrenitaliaPostSaleChangeConfirmDialog } from "./trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component";
import { wrapArray } from "app/helpers";
import { TrenitaliaDialogContainerComponent } from '../trenitalia-dialog-container/trenitalia-dialog-container.component';
import { DatasetService } from "../../../../components/dataset/services/dataset.service";
export var supportedPostSaleActions = [
    {
        code: 'booking_change_before_departure',
        label: 'Cambio Data/Ora',
        icon: 'date_range'
    },
    {
        code: 'traveller_change',
        label: 'Modifica dati Passeggeri',
        icon: 'group'
    },
    {
        code: 'change_class',
        label: 'Cambio Classe',
        icon: 'keyboard_double_arrow_up'
    },
    {
        code: 'travel_change_before_departure',
        label: 'Modifica Biglietto',
        icon: 'train'
    },
    {
        code: 'cancellation_before_departure',
        label: 'Cancella',
        icon: 'delete'
    },
    {
        code: 'refund_before_departure',
        label: 'Rimborsa',
        icon: 'euro symbol'
    },
    {
        code: 'carnet_refund',
        label: 'Rimborsa',
        icon: 'euro symbol'
    }
];
var TrenitaliaPostSaleService = /** @class */ (function () {
    function TrenitaliaPostSaleService(matDialog, trnService, appService, mastSnackBar, bookingService, datasetService) {
        var _this = this;
        this.matDialog = matDialog;
        this.trnService = trnService;
        this.appService = appService;
        this.mastSnackBar = mastSnackBar;
        this.bookingService = bookingService;
        this.datasetService = datasetService;
        this.isCarnet = false;
        this.loading = new BehaviorSubject(false);
        this.travelDetails = new BehaviorSubject(null);
        this.travelDetails.subscribe(function (travelDetails) {
            _this.isCarnet = _this.checkIsCarnet();
        });
    }
    Object.defineProperty(TrenitaliaPostSaleService.prototype, "serviceMap", {
        get: function () {
            return _.get(this.travelDetails.value, 'serviceMap');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleService.prototype, "travellers", {
        get: function () {
            return _.get(this.travelDetails.value, 'travel.travellers');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaPostSaleService.prototype, "postActions", {
        get: function () {
            return _.get(this.travelDetails.value, 'postActions');
        },
        enumerable: true,
        configurable: true
    });
    TrenitaliaPostSaleService.prototype.setTravelData = function (tripBookingPackage, isCarnet) {
        var _this = this;
        this.tripBookingPackage = tripBookingPackage;
        this.loading.next(true);
        this.trnService.getDetails(this.tripBookingPackage.id, isCarnet)
            .subscribe({
            next: function (details) {
                _this.travelDetails.next(details);
            },
            error: function () {
                // TODO: handle error
            },
            complete: function () {
                _this.loading.next(false);
            }
        });
    };
    TrenitaliaPostSaleService.prototype.checkIsCarnet = function () {
        return !_.isEmpty(_.entries(_.get(this.serviceMap, 'catalog_services', {})).find(function (service) { return _.get(service, [1, 'type']) === 'CARNET'; }));
    };
    TrenitaliaPostSaleService.prototype.onDelete = function () {
        var _this = this;
        this.loading.next(true);
        // select all post sale details fro cancellations
        var postSaleDetails = _.values(this.postActions).reduce(function (carry, solutionPostActions) {
            var cancellationEnabled = _.get(solutionPostActions, 'cancellation_before_departure.enabled', false);
            if (!cancellationEnabled)
                return carry;
            var postSaleDetails = _.get(solutionPostActions, 'cancellation_before_departure.postSaleDetails', []);
            carry = carry.concat(postSaleDetails);
            return carry;
        }, []);
        this.trnService.evaluateTravelPostSale({
            travel: this.travelDetails.value.travel.original,
            selectedPostSaleDetails: postSaleDetails
        }).pipe(catchError(function (err) {
            _this.mastSnackBar.open('Errore imprevisto nella cancellazione!', '', {
                duration: 5000
            });
            return EMPTY;
        }), exhaustMap(function (res) {
            _this.loading.next(false);
            return _this.matDialog
                .open(TrenitaliaPostSaleChangeConfirmDialog, {
                minWidth: '300px',
                panelClass: 'create-dialog-container',
                data: {
                    postSaleData: res.validatedPostSaleRecord,
                    type: 'cancel',
                    title: 'Cancella prenotazione',
                    message: 'Vuoi cancellare la prenotazione?',
                    icon: 'delete'
                }
            })
                .afterClosed();
        }), filter(function (dialogRes) { return dialogRes; }), exhaustMap(function (res) {
            _this.loading.next(true);
            return _this.bookingService
                .cancelBooking(_this.tripBookingPackage.trip_booking_id, {
                trip_booking_package_ids: [_this.tripBookingPackage.id]
            });
        }), take(1), finalize(function () {
            _this.loading.next(false);
        })).subscribe(function () {
            _this.loading.next(false);
            _this.matDialog.closeAll();
            _this.appService.appEvent.next({
                name: RELOAD_BOOKING_PACKAGES
            });
        });
    };
    TrenitaliaPostSaleService.prototype.edit = function (type, travelSolution, postActions) {
        var _this = this;
        this.matDialog
            .open(TrenitaliaDialogContainerComponent, {
            minWidth: '800px',
            panelClass: 'create-dialog-container',
            data: {
                participant: this.tripBookingPackage.participant_counters,
                trip_booking_id: this.tripBookingPackage.trip_booking_id,
                trip_booking_package: this.tripBookingPackage,
                mode: type,
                data: {
                    travellers: this.travellers,
                    postAction: postActions,
                    travel: this.travelDetails.value.travel,
                    reopen: this.travelDetails.value.reopen,
                    travelSolution: travelSolution
                },
                bookingService: this.bookingService
            }
        })
            .afterClosed()
            .subscribe(function (s) {
            _this.matDialog.closeAll();
            _this.appService.appEvent.next({
                name: RELOAD_BOOKING_PACKAGES
            });
        }, function (error) {
            _this.mastSnackBar.open('Errore nella modifica dei dati!Riprovare!', '', {
                duration: 5000
            });
        }, function () {
            _this.loading.next(false);
        });
    };
    /**
     * Full refund, for partial refund filter post sale details
     */
    TrenitaliaPostSaleService.prototype.onRefund = function (postSaleDetails) {
        var _this = this;
        this.loading.next(false);
        var evaluateRes = null;
        this.trnService.evaluateTravelPostSale({
            travel: this.travelDetails.value.travel.original,
            selectedPostSaleDetails: postSaleDetails
        }).pipe(catchError(function (err) {
            _this.mastSnackBar.open('Errore imprevisto nel calcolo del rimborso!', '', {
                duration: 5000
            });
            return EMPTY;
        }), exhaustMap(function (res) {
            _this.loading.next(false);
            evaluateRes = res;
            return _this.matDialog
                .open(TrenitaliaPostSaleChangeConfirmDialog, {
                minWidth: '300px',
                panelClass: 'create-dialog-container',
                data: {
                    postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
                        missingAmount: res.missingAmount,
                        reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
                        reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
                        additionalCharges: res.additionalCharges,
                        postSaleFee: res.fee
                    }),
                    type: 'refund',
                    title: 'Rimborso viaggio',
                    message: 'Vuoi rimborsare il viaggio?',
                    icon: 'currency_exchange'
                }
            })
                .afterClosed();
        }), filter(function (dialogRes) { return dialogRes; }), exhaustMap(function (res) {
            _this.loading.next(true);
            return _this.trnService.updateAndConfirm({
                travel: evaluateRes.travel,
                postSaleData: {
                    postSaleRecord: evaluateRes.validatedPostSaleRecord,
                    missingAmount: evaluateRes.missingAmount,
                    reversedPaymentRecords: evaluateRes.reversedPaymentRecords,
                    reusedPaymentRecords: evaluateRes.reusedPaymentRecords,
                    additionalCharges: evaluateRes.additionalCharges
                },
                type: 'refund'
            });
        }), take(1), finalize(function () {
            _this.loading.next(false);
        })).subscribe(function () {
            _this.matDialog.closeAll();
            _this.appService.appEvent.next({
                name: RELOAD_BOOKING_PACKAGES
            });
            _this.mastSnackBar.open('Rimborso effettuato', '', {
                duration: 5000
            });
        }, function (error) {
            _this.mastSnackBar.open('Errore nella procedura del rimborso!', '', {
                duration: 5000
            });
        });
    };
    /**
     * upgrade class
     */
    TrenitaliaPostSaleService.prototype.changeClass = function (postSaleDetails, viewContainerRef) {
        var _this = this;
        this.loading.next(true);
        var data = {
            reopenedTravel: this.travelDetails.value.reopen,
            operationType: 'ticketChange',
            selectedPostSaleDetails: postSaleDetails
        };
        this.trnService.changeClass(data)
            .subscribe(function (res) {
            _this.loading.next(false);
            /*off['travelSolutions'][0]['custom_off'] = {};*/
            _this.trnService.loading.next(false);
            _this.matDialog
                .open(PostSaleDialogComponent, {
                minWidth: '800px',
                viewContainerRef: viewContainerRef,
                panelClass: 'create-dialog-container',
                data: {
                    solution: res,
                    travel: _this.travelDetails.value.reopen.travel,
                    travellers: _.get(res, 'travellers'),
                    postSaleDetails: postSaleDetails,
                    serviceMap: _this.serviceMap
                }
            })
                .afterClosed()
                .subscribe(function (s) {
                _this.matDialog.closeAll();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGES
                });
            }, function (error) {
                _this.appService.showErrorMessage('Errore nella modifica dei dati!Riprovare!');
            }, function () {
                _this.loading.next(false);
            });
        });
    };
    TrenitaliaPostSaleService.prototype.sendEmail = function (tripBookingPackage) {
        return this.datasetService.post('/dataset/trenitalia/command/send_travel_mail', tripBookingPackage);
    };
    return TrenitaliaPostSaleService;
}());
export { TrenitaliaPostSaleService };
