import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('display_name', {
	key: 'display_name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('parent_domain_id', {
	key: 'parent_domain_id',
	name: 'Dominio Padre',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	formValidators: [ Validators.required ],
	datasetCode: 'domains',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		}
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('status', {
	key: 'enabled',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_generate_pratiche', {
	key: 'enable_generate_pratiche',
	name: 'Generazione Pratiche',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_export_zucchetti', {
	key: 'enable_export_zucchetti',
	name: 'Export Zucchetti',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_gyg', {
	key: 'enable_gyg',
	name: 'GYG',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_viator', {
	key: 'enable_viator',
	name: 'Viator',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_tour_cms', {
	key: 'enable_tour_cms',
	name: 'Tour CMS',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_bokun', {
	key: 'enable_bokun',
	name: 'Bokun',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_kiara_cloud', {
	key: 'enable_kiara_cloud',
	name: 'Kiara Cloud',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_eagency', {
	key: 'enable_eagency',
	name: 'Eagency',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_fatture_in_cloud', {
	key: 'enable_fatture_in_cloud',
	name: 'Fatture in cloud',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('enable_ticketing', {
	key: 'enable_ticketing',
	name: 'Ticketing',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('domain_path', {
	key: 'domain_path',
	name: 'Gerarchia',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('stripe_fee', {
	key: 'stripe_fee',
	name: 'Stripe Fee',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.NUMBER,
		clearable: true
	},
	defaultValue: null,
	disallowNull: false,
	skipIf(record, dataContainerService){
		return !dataContainerService || !dataContainerService.authService.isAdministrator();
	}
})

export {fieldsMap};
