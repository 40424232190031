import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../user-detail.fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		fields: [
			fieldsMap.get('user.access_username'),
			fieldsMap.get('user.name')
		]
	},
	{
		fields: [
			fieldsMap.get('user.email'),
			fieldsMap.get('domain_user_role.name')
		]
	}
];

export default {
	fields: detailFormFields 
};
