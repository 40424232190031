import { Component, OnInit, Input } from '@angular/core';
import { TripPackageLangContentBaseClass } from '../../lang-cont-base.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { TripFacilityConfig } from 'app/configs/datasets/trip_facilities';
import { IFormConfig, IDatasetConfig } from 'app/interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import FilterFields from './form.fields';

@Component({
	selector   : 'trip-package-trip-facilities',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ 
		DatasetActionContainerService, 
		DatasetRecordService, 
		DatasetMultilanguageContainerService 
	]
})
export class TripPackageTripFacilitiesComponent extends TripPackageLangContentBaseClass implements OnInit{

	@Input() datasetConfig: IDatasetConfig;
	filterFormGroup: FormGroup;
	field = FilterFields.get('trip_facility_id');
	formConfig: IFormConfig;

	dynamCols: any[] = [
		{
			name: 'id',
			title: 'ID',
			primaryKey: true,
			show: false,
		},
		{
			name: 'slug',
			title: 'Icona',
			primaryKey: false,
			show: true,
			valueType: 'icon',
		},
		{
			name: 'title',
			title: 'Descrizione',
			primaryKey: false,
			show: true,
		},
		{
			name: 'active_language_codes',
			title: 'Lingue',
			primaryKey: false,
			show: true,
			valueType: 'langs',
			getDisplayValue(record: any): string{
				let html = '<div style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
		
				if(record.active_language_codes && record.active_language_codes.length > 0){
					const style = 'style="margin-right: 5px;"';
					for (const lang of record.active_language_codes) {
						html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
					}
				}else{
					html += '<span>---</span>';
				}
		
				html += '</div>';
				return html;
			}
		}
	];
	displayedColumns: string[] = ['id', 'slug', 'title', 'active_language_codes', 'action'];

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService,
		protected fb: FormBuilder){
		super(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, null, {
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentForeignKey: 'trip_package_id',
			parentValueKey: 'trip_packages.recordId'
		});
	}

	ngOnInit(): void{
		this.formConfig = TripFacilityConfig.formConfigsMap['generalForm'];
		this.filterFormGroup = this.fb.group({trip_facility_id: null});
		super.ngOnInit();
	}
}
