import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkStepper } from '@angular/cdk/stepper';
import { Directionality } from '@angular/cdk/bidi';
import { StepService } from '../../../services/step-state.service';
var CustomStepperComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomStepperComponent, _super);
    function CustomStepperComponent(_dir, _changeDetectorRef, router, route, stepService) {
        var _this = _super.call(this, _dir, _changeDetectorRef) || this;
        _this.router = router;
        _this.route = route;
        _this.stepService = stepService;
        return _this;
    }
    CustomStepperComponent.prototype.onClick = function (step, index) {
        if (!step.completed)
            return;
        this.selectedIndex = index;
    };
    CustomStepperComponent.prototype.ngOnInit = function () {
    };
    return CustomStepperComponent;
}(CdkStepper));
export { CustomStepperComponent };
