import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractService } from './abstract.service';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AppService } from 'app/services/app.service';
import { setUserNavigation } from 'app/helpers/navigation.helper';
import { DomainFilterService } from './domain-filter.service';
import { WebSocketService } from './websocket.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app.service";
import * as i3 from "../../@fuse/components/navigation/navigation.service";
import * as i4 from "@angular/router";
import * as i5 from "./domain-filter.service";
import * as i6 from "./websocket.service";
export var CheckUserStatues;
(function (CheckUserStatues) {
    CheckUserStatues[CheckUserStatues["NONE"] = 0] = "NONE";
    CheckUserStatues[CheckUserStatues["CHECKING"] = 1] = "CHECKING";
    CheckUserStatues[CheckUserStatues["CHECKED"] = 2] = "CHECKED";
})(CheckUserStatues || (CheckUserStatues = {}));
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(http, appService, _fuseNavigationService, router, domainFilterService, webSocketService) {
        var _this = _super.call(this, http, appService) || this;
        _this.http = http;
        _this.appService = appService;
        _this._fuseNavigationService = _fuseNavigationService;
        _this.router = router;
        _this.domainFilterService = domainFilterService;
        _this.webSocketService = webSocketService;
        _this.chekcUserStatus = new BehaviorSubject(CheckUserStatues.NONE);
        _this.userDataObserver = new BehaviorSubject(null);
        _this.desiredPath = null;
        _this.desiredQueryParams = null;
        return _this;
    }
    AuthService.prototype.init = function () {
        var _this = this;
        this.userDataObserver
            .subscribe(function (data) {
            if (data && data.user) {
                if (typeof (window['FreshworksWidget']) === 'function') {
                    window['FreshworksWidget']('identify', 'ticketForm', {
                        name: data.user.name,
                        email: data.user.email,
                    });
                    window['FreshworksWidget']('prefill', 'ticketForm', {
                        custom_fields: {
                            single_domain_id: data.single_domain_id
                        }
                    });
                }
            }
            _this.appService.me = data;
        });
        this.domainFilterService.domainSettingChangeEvent
            .subscribe(function (event) {
            if (!_this.userDataObserver.value)
                return;
            var domainId = _this.domainFilterService.filterDomainId.value || 'null';
            if (!event.domain_id || event.domain_id != domainId)
                return;
            var domainSetting = _this.domainFilterService.getDomainSetting(domainId);
            setUserNavigation(_this._fuseNavigationService, _this.userDataObserver.value, domainSetting);
        });
    };
    AuthService.prototype.showAdminMenu = function () {
        var data = this.userDataObserver.getValue();
        return data && data.user && data.user.role && data.user.role.level < 10;
    };
    AuthService.prototype.isAuthenticated = function () {
        return Boolean(this.userDataObserver.getValue());
    };
    AuthService.prototype.getMyId = function () {
        if (!this.userDataObserver.getValue())
            return null;
        return this.userDataObserver.getValue().user.id;
    };
    AuthService.prototype.updateUserInfo = function () {
        var _this = this;
        this.chekcUserStatus.next(CheckUserStatues.CHECKING);
        this.http.get(this.appService.getBaseServerAddress() + '/auth/me')
            .pipe(catchError(this.handleError('auth/me', true))).subscribe({
            next: function (response) {
                _this.chekcUserStatus.next(CheckUserStatues.CHECKED);
                if (response) {
                    _this.webSocketService.isAuthenticated.next(true);
                    _this.userDataObserver.next(response);
                    if (response)
                        _this.domainFilterService.authLoaded = true;
                    if (response.domain_settings) {
                        for (var key in response.domain_settings) {
                            if (!Object.prototype.hasOwnProperty.call(response.domain_settings, key))
                                continue;
                            var element = response.domain_settings[key];
                            _this.domainFilterService.domainSettings.set(key, element);
                        }
                    }
                    if (response.single_domain_id) {
                        _this.domainFilterService.filterDomainId.next(response.single_domain_id);
                    }
                    _this.domainFilterService.loadDomainSettings(_this.domainFilterService.filterDomainId.value);
                    _this.domainFilterService.showFilterBar = response.showFilterBar;
                    _this.appService.setLogo(response.srcData);
                    _this.domainFilterService.refreshDomainData();
                    return;
                }
            },
            error: function (err) {
                console.error(err);
                _this.chekcUserStatus.next(CheckUserStatues.CHECKED);
            }
        });
    };
    AuthService.prototype.login = function (credentials) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                options = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
                };
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http.post(_this.appService.getBaseServerAddress() + '/auth/login', credentials, options)
                            .pipe(catchError(_this.handleError('auth/login'))).subscribe(function (response) {
                            if (response) {
                                resolve(response);
                                return;
                            }
                            reject('invalid response');
                        }, function (err) {
                            reject(err);
                        });
                    }).then(function () {
                        _this.updateUserInfo();
                    })];
            });
        });
    };
    AuthService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http.post(_this.appService.getBaseServerAddress() + '/auth/logout', null)
                            .pipe(catchError(_this.handleError('auth/logout'))).subscribe(function (response) {
                            if (response) {
                                _this.userDataObserver.next(null);
                                resolve(null);
                                return;
                            }
                            reject('invalid response');
                        }, function (err) {
                            reject(err);
                        });
                    }).then(function () {
                        _this.domainFilterService.clear();
                        // set user state for websocket
                        _this.webSocketService.isAuthenticated.next(false);
                        _this.router.navigate(['auth/login']);
                    })];
            });
        });
    };
    AuthService.prototype.check = function () {
        this.updateUserInfo();
    };
    AuthService.prototype.isAdministrator = function () {
        if (!this.userDataObserver.value)
            return false;
        return this.userDataObserver.value.user.role.level < 10;
    };
    AuthService.prototype.resetPasswordByEmail = function (email) {
        return this.http.post(this.appService.getBaseServerAddress() + '/auth/password/email', { email: email })
            .pipe(catchError(this.handleError('password/logout')));
    };
    AuthService.prototype.resetPassword = function (data) {
        return this.http.post(this.appService.getBaseServerAddress() + '/auth/password/reset', data)
            .pipe(catchError(this.handleError('password/logout')));
    };
    AuthService.prototype.isActionPermitted = function (actionCode, group) {
        var e_1, _a;
        if (!this.userDataObserver.value || !this.userDataObserver.value.permissions)
            return false;
        try {
            for (var _b = tslib_1.__values(this.userDataObserver.value.permissions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var groupPermission = _c.value;
                if (groupPermission.code === group) {
                    if (groupPermission.access_level <= 0)
                        return false;
                    if (groupPermission.access_level === 1)
                        return ['view', 'view_detail'].includes(actionCode);
                    if (groupPermission.access_level === 2)
                        return ['view', 'view_detail', 'create', 'edit'].includes(actionCode);
                    if (groupPermission.access_level >= 3)
                        return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.FuseNavigationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.DomainFilterService), i0.ɵɵinject(i6.WebSocketService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}(AbstractService));
export { AuthService };
