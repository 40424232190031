<div>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<div class='mt-24'>
		<div *ngIf='isAdmin'>
			<p>{{'Ruolo di tipo amministrativo, accesso completo al pannello.' | translate}}</p>
		</div>
		<table class='px-16' *ngIf='!isAdmin'>
			<thead>
				<tr>
					<th>Area</th>
					<th>Tipo accesso</th>
				</tr>
			</thead>
			<tbody>
				<tr class='permission-row' *ngFor='let permission of rolePermissions'>
					<td>
						<span class='h5'>{{permission.name}}</span>
					</td>
					<td>
						<div>
							<mat-form-field>
								<mat-select [value]='permission.access_level' (valueChange)='changeAccessType(permission, $event)'>
									<mat-option [value]='0'>{{'Accesso Vietato' | translate}}</mat-option>
									<mat-option [value]='1'>{{'Può visualizzare' | translate}}</mat-option>
									<mat-option [value]='2'>{{'Può aggiungere e modificare' | translate}}</mat-option>
									<mat-option [value]='3'>{{'Accesso completo' | translate}}</mat-option>
								</mat-select>
								<mat-spinner *ngIf='changingGroupAccess[permission.id]' diameter='20' matSuffix></mat-spinner>
							</mat-form-field>
							<ng-container *ngFor='let action of permission.actions'>
								<mat-checkbox 
									*ngIf="!action.default_action"
									class="no-default-action" 
									(change)="changeState(permission, action, $event.checked)" 
									[checked]="action.active"
								>
									{{action.name}}
								</mat-checkbox>
							</ng-container>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		
	</div>
</div>