/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./request-package-tool.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./request-package-tool.component";
import * as i8 from "@angular/common/http";
import * as i9 from "../../../../../services/app.service";
import * as i10 from "../../services/dataset.service";
import * as i11 from "@angular/material/dialog";
var styles_RequestPackageToolComponent = [i0.styles];
var RenderType_RequestPackageToolComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequestPackageToolComponent, data: {} });
export { RenderType_RequestPackageToolComponent as RenderType_RequestPackageToolComponent };
export function View_RequestPackageToolComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn-request-packages"], ["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", "\n"])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "warn"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Rivendi Pacchetto")); _ck(_v, 2, 0, currVal_3); }); }
export function View_RequestPackageToolComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "request-package-tool", [], null, null, null, View_RequestPackageToolComponent_0, RenderType_RequestPackageToolComponent)), i1.ɵdid(1, 49152, null, 0, i7.RequestPackageToolComponent, [i8.HttpClient, i9.AppService, i10.DatasetService, i11.MatDialog], null, null)], null, null); }
var RequestPackageToolComponentNgFactory = i1.ɵccf("request-package-tool", i7.RequestPackageToolComponent, View_RequestPackageToolComponent_Host_0, { viewConfig: "viewConfig", datasetACS: "datasetACS" }, {}, []);
export { RequestPackageToolComponentNgFactory as RequestPackageToolComponentNgFactory };
