import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	
	fieldsMap.get('access_username'),
	fieldsMap.get('name'),
	fieldsMap.get('email'),
	//fieldsMap.get('branch_id')
];

export default {
	fields: detailFormFields 
};
