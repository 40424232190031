import { Validators } from '@angular/forms';
import { IFieldContainer, IFieldDefinition, InputTypes, ValueTypes } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		key: 'id',
		name: 'Client ID',
		inputType: InputTypes.NUMBER,
		valueType: ValueTypes.NUMBER
	},
	fieldsMap.get('secret'),
	fieldsMap.get('name'),
	fieldsMap.get('redirect'),
	{
		key: 'personal_access_client',
		name: 'Personal Access Client',
		inputType: InputTypes.NUMBER,
		valueType: ValueTypes.NUMBER,
		getDisplayValue(record): string{
			if (record.personal_access_client == 1) return 'Sì';
			return 'No';
		}
	},
	{
		key: 'password_client',
		name: 'Password Client',
		inputType: InputTypes.NUMBER,
		valueType: ValueTypes.NUMBER,
		getDisplayValue(record): string{
			if (record.personal_access_client == 1) return 'Sì';
			return 'No';
		}
	},
	{
		key: 'revoked',
		name: 'Abilitato',
		inputType: InputTypes.NUMBER,
		valueType: ValueTypes.NUMBER,
		getDisplayValue(record): string{
			if (record.revoked == 1) return 'No';
			return 'Sì';
		}
	},
	fieldsMap.get('user_id'),
	fieldsMap.get('domain_id')
];

export default {
	fields: detailFormFields 
};
