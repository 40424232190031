import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, Observable, forkJoin } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { AbstractControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'dataset-multiselect-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class DatasetMultiSelectInputComponent extends DestroyableComponent implements OnInit, OnDestroy{
	public selectOptions: any[];
	public selectedItems: any[] = [];
	public selectedLabel: string;

	public loading = false;
	protected fetchOptionsSubscription: Subscription;
	protected refreshSelectedItemsSubscription: Subscription;
	protected items = [];

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.buildSelectResourceOptions();

			this.getFormControl().valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(value => {
				if(value === undefined){
					this.getFormControl().setValue(null);
					return;
				}
				this.refreshSelectedItems()
				.subscribe(() => {
					this.inputService.onSet(this.selectedItems);
				});
			});
		});
	}

	updateSelectedLabel(): void{
		const value = this.getFormControl().value;
		const labels = [];
		if(value && value instanceof Array){
			for (const item of this.selectedItems) {
				const label = this.inputService.formInputDefinition.remoteOptions.getValue(item);
				labels.push(label);
			}
		}

		this.selectedLabel = labels.length ? labels.join(', ') : null;
	}

	getFormControl(): AbstractControl{
		return this.inputService.control;
	}

	getItemFromValue(value: any): any{
		if(!this.items) return null;
		for (const item of this.items) {
			const itemValue = this.inputService.formInputDefinition.remoteOptions.getValue(item);
			if(itemValue === value) return item;
		}
		return null;
	}

	refreshSelectedItems(): Observable<any>{
		if(this.refreshSelectedItemsSubscription){
			this.refreshSelectedItemsSubscription.unsubscribe();
			this.refreshSelectedItemsSubscription = null;
		}
		const value = this.getFormControl().value;
		const resultSubject = new Subject<any>();
		this.selectedItems = [];
		if(!value){
			resultSubject.next();
			resultSubject.complete();
			return resultSubject;
		}
		const fetchArray = [];
		if(value instanceof Array){
			for (const v of value) {
				fetchArray.push(this.refreshSelectedItem(v));
			}
		}else{
			fetchArray.push(this.refreshSelectedItem(value));
		}

		this.refreshSelectedItemsSubscription = forkJoin(fetchArray).subscribe({
			next: result => {
				this.updateSelectedLabel();
				resultSubject.next();
				resultSubject.complete();
			},
			error: result => {
				resultSubject.next();
				resultSubject.complete();
			}
		});
		return resultSubject;
	}

	refreshSelectedItem(value): Observable<any>{
		const resultSubject = new Subject<any>();
		this.getSelectedItem(value).subscribe({
			next: result => {
				this.selectedItems.push(result);
				resultSubject.next(true);
				resultSubject.complete();
			},
			error: result => {
				resultSubject.next(false);
				resultSubject.complete();
			}
		});
		return resultSubject;
	}

	buildSelectResourceOptions(text?: string): void{
		if(!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions) return;
		this.selectOptions = [];
		let params = {
			perPage:  this.inputService.formInputDefinition.remoteOptions.limit || 50
		};
		if(this.inputService.formInputDefinition.remoteOptions.getParams){
			params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
		}
		const formControl = this.getFormControl();
		if(formControl && formControl.value) params['include'] = formControl.value;
		if(text){
			params['text-search'] = text;
		}
		if(this.fetchOptionsSubscription){
			this.fetchOptionsSubscription.unsubscribe();
		}
		this.loading = true;
		let route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
		if(this.inputService.formInputDefinition.remoteOptions.getPivotConfig){
			const pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
			const parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
			route = '/pivot/' + pivotConfig.pivotCode  + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
		}
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.fetchOptionsSubscription = this.datasetService.get<IPaginationResponse<any>>(route, params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				this.items = result.items;
				const options = [];
				for (const item of result.items) {
					options.push({
						value: this.inputService.formInputDefinition.remoteOptions.getValue(item),
						label: this.inputService.formInputDefinition.remoteOptions.getLabel(item)
					});
				}
				this.selectOptions = options;
				this.loading = false;
				this.updateSelectedLabel();
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	search(text: string): void{
		this.buildSelectResourceOptions(text);
	}

	getSelectedItem(value: string): Observable<any>{
		const resultSubject = new Subject<any>();
		if(!value) {
			resultSubject.next(null);
			resultSubject.complete();
			return resultSubject;
		}
		const item = this.getItemFromValue(value);
		if(item){
			resultSubject.next(item);
			resultSubject.complete();
			return resultSubject;
		}
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.datasetService.get<any>('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				resultSubject.next(response);
				resultSubject.complete();
			},
			error: response => {
				resultSubject.error(response);
				resultSubject.complete();
			}
		});
		return resultSubject;
	}
}
