import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { BookingService } from '../../../../booking.service';
import { RELOAD_BOOKING } from '../../../../edit-booking.component';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { TripBookingResourceService } from '../../trip-booking-resource.service';
var TripPackageResourceBookingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageResourceBookingComponent, _super);
    function TripPackageResourceBookingComponent(appService, datasetRS, datasetACS, parentDatasetRS, parentDatasetACS, tripBookingResourceService, bookingService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.tripBookingResourceService = tripBookingResourceService;
        _this.bookingService = bookingService;
        return _this;
    }
    TripPackageResourceBookingComponent.prototype.ngOnInit = function () {
        this.datasetACS.init({
            datasetCode: 'trip_booking_resources',
            actionCode: 'detail',
            useBeforeCalls: false,
            parentDatasetRS: this.parentDatasetRS,
            parentDatasetACS: this.parentDatasetACS
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
        });
    };
    TripPackageResourceBookingComponent.prototype.changeStatus = function () {
        if (this.not_activable_reason)
            return;
        if (this.datasetRS.record.value.activated == 1) {
            this.deactivateOptionalResource();
        }
        else {
            this.activateOptionalResource();
        }
    };
    TripPackageResourceBookingComponent.prototype.computeOptionalResource = function () {
        var bookingResource = this.datasetRS.record.value;
        var notActivableReason = this.tripBookingResourceService.makeNotActivableReason(bookingResource);
        var isPickup = bookingResource.trip_resource && bookingResource.trip_resource.properties.resource_sub_types.value === 'PICKUP';
        if (isPickup && bookingResource.activated) {
            this.tripBookingResourceService.loadMeetingPoint(this.datasetRS.record.value);
        }
        this.trip_resource = bookingResource.trip_resource;
        this.not_activable_reason = notActivableReason;
        this.activated = bookingResource.activated,
            this.optional = bookingResource.optional;
        this.is_pickup = bookingResource.trip_resource && bookingResource.trip_resource.properties.resource_sub_types.value === 'PICKUP';
    };
    TripPackageResourceBookingComponent.prototype.ngOnChanges = function (changes) {
        if (changes.inputTripBookingResource) {
            if (!this.datasetRS.record.value) {
                this.datasetRS.record.next(this.inputTripBookingResource);
            }
            else if (this.inputTripBookingResource && this.inputTripBookingResource.updated_at > this.inputTripBookingResource.updated_at) {
                this.datasetRS.record.next(this.inputTripBookingResource);
            }
            this.computeOptionalResource();
        }
    };
    TripPackageResourceBookingComponent.prototype.activateOptionalResource = function () {
        var _this = this;
        this.tripBookingResourceService.activateResource(this.datasetRS.record.value)
            .afterClosed()
            .subscribe(function () { return _this.appService.appEvent.next({ name: RELOAD_BOOKING }); });
    };
    TripPackageResourceBookingComponent.prototype.deactivateOptionalResource = function () {
        var _this = this;
        this.tripBookingResourceService.deactivateResource(this.datasetRS.record.value)
            .afterClosed()
            .subscribe(function () { return _this.appService.appEvent.next({ name: RELOAD_BOOKING }); });
    };
    return TripPackageResourceBookingComponent;
}(DestroyableComponent));
export { TripPackageResourceBookingComponent };
