import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('active_status'),
			fieldsMap.get('priority_order'),
			fieldsMap.get('totem_priority')
		]
	},
	fieldsMap.get('description'),
	fieldsMap.get('small_text'),
	fieldsMap.get('full_description'),
	fieldsMap.get('small_title'),
	fieldsMap.get('things_to_do')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
