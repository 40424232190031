import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormControl, NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { ParticipantListEmailDialogComponent } from 'app/main/components/dataset/dialogs/participant-list-email-dialog/participant-list-email-dialog.component';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import * as _moment from 'moment';

import { IDataParticipantList } from 'app/interfaces/participant-list-email.interface';

@Component({
	selector: 'documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss'],
	providers: [PageClosureContainerService, DatasetActionContainerService],
	//encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,

})
export class DocumentsPageComponent implements OnInit {
	
	public date: string;

	public selectedPackage = false;
	public selectedPickUpPackage = false;
	public selectedResource = false;
	public selectedEntity = false;
	public selectedCustomer = false;
	public selectedMeetingPoint = false;
	public selectedActualTrip = false;

	public resourceWithPackage = false;
	
	public isHighlighted = false;
	public packageSubFilters = false;

	canDownloadPackage: boolean;
	canDownloadPickUpPackage: boolean;
	canDownloadResource: boolean;
	canDownloadEntity: boolean;
	canDownloadCustomer: boolean;
	canDownloadTrip: boolean;
	canDownloadMeetingPoint: boolean;
	canDownloadSubPackageResource: boolean;

	private dateInput: string;

	public filterPackages: any[] = [];
	public filterPickUpPackages: any[] = [];
	public filterResource: any[] = [];
	public filterClient: any[] = [];
	public filterCustomer: any[] = [];
	public filterActual: any[] = [];
	public filterMeetingPoints: any[] = [];

	resourceFilteredOptionsSubscriptions: Subscription;
	loadPickUpPackagesSubscription: Subscription;
	loadPackagesSubscription: Subscription;
	clientFilteredOptionsSubscription: Subscription;

	_unsubscribeAll: Subject<any>;
	filteredOptions: Subject<any>;
	filteredPickUpPackages: Subject<any>;
	resourceOptions: Subject<any>;
	actualOptions: Subject<any>;
	entityOptions: Subject<any>;
	customerOptions: Subject<any>;
	meetingPointOptions: Subject<any>;

	selectedRadio: string;
	selectedSubPackFilter: string;
	listaCarichiSelezionata: string;

	radios: string[] = ['Pacchetto', 'Risorsa', 'Accompagnatore', 'Cliente', 'Punto di ritrovo'];
	subFilterPackages: string[] = ['Risorsa', 'Partenza'];
	listaCarichi: string[] = ['Pacchetto'];
	
	@ViewChild('filterInput', { static: false }) filterInput: ElementRef<HTMLInputElement>;
	@ViewChild('ResourceInput', { static: false }) resourceInput: ElementRef<HTMLInputElement>;
	@ViewChild('clientInput', { static: false }) entityInput: ElementRef<HTMLInputElement>;
	@ViewChild('customerInput', { static: false }) customerInput: ElementRef<HTMLInputElement>;
	@ViewChild('actualInput', { static: false }) actualInput: ElementRef<HTMLInputElement>;
	@ViewChild('meetingPointInput', { static: false }) meetingPointInput: ElementRef<HTMLInputElement>;
	@ViewChild('listaCarichiInput', { static: false }) listaCarichiInput: ElementRef<HTMLInputElement>;

	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
	@ViewChild('autores', { static: false }) matAutocompleteRes: MatAutocomplete;
	@ViewChild('autocli', { static: false }) matAutocompleteCli: MatAutocomplete;
	@ViewChild('autocust', { static: false }) matAutocompleteCust: MatAutocomplete;
	@ViewChild('autoact', { static: false }) matAutocompleteAct: MatAutocomplete;
	@ViewChild('automp', { static: false }) matAutocompleteMp: MatAutocomplete;
	@ViewChild('listaCarichiAutocomplete', { static: false }) matListaCarichiAutocomplete: MatAutocomplete;

	@ViewChild('documentsForm', { static: true }) myForm: NgForm;

	/**
	 * Constructor
	 *
	*/
	constructor(
		private datasetACS: DatasetActionContainerService,
		private _datasetService: DatasetService, 
		private datasetNavigatorService: DatasetNavigatorService) {
		this._unsubscribeAll = new Subject<any>();
		this.filteredOptions = new Subject<any>();
		this.filteredPickUpPackages = new Subject<any>();
		this.resourceOptions = new Subject<any>();
		this.actualOptions = new Subject<any>();
		this.entityOptions = new Subject<any>();
		this.customerOptions = new Subject<any>();
		this.meetingPointOptions = new Subject<any>();
	}
	/**
	 * On init
	 */
	ngOnInit(): void {}

	canDownloadList(): boolean {
		switch(true) {
			case this.selectedPackage && !this.packageSubFilters:
				return true;

			case this.selectedResource && !this.packageSubFilters:
				return true;

			case this.filterClient.length === 1:
				return true;

			case this.filterCustomer.length === 1:
				return true;

			case this.selectedMeetingPoint:
				return true;

			case this.selectedActualTrip && this.packageSubFilters:
				return true;

			case this.selectedResource && this.resourceWithPackage:
				return true;
		}

		return false;
	}

	resetData(): void {
		this.filterPackages = [];
		this.filterResource = [];
		this.filterActual = [];
		this.filterClient = [];
		this.filterCustomer = [];
		this.filterMeetingPoints = [];

		this.resourceWithPackage = false;
		this.isHighlighted = false;
		this.selectedResource = false;
		this.selectedSubPackFilter = null;
		this.selectedPackage = false;
		this.packageSubFilters = false;
		this.selectedEntity = false;
		this.selectedCustomer = false;
		this.selectedMeetingPoint = false;
		this.selectedActualTrip = false;
	}

	packageSubFiltersEvent(event: MatCheckboxChange): void {
		this.resourceWithPackage = false;
		this.isHighlighted = false;
		this.selectedResource = false;
		this.selectedSubPackFilter = null;
		this.filterResource = [];
		this.filterActual = [];
	}

	radioListChange(event: MatRadioChange): void {
		this.resetData();
	}

	radioSubPackListChange(event: MatRadioChange): void {
		this.selectedSubPackFilter = event.value;
		this.resourceWithPackage = false;
		this.isHighlighted = false;
		this.selectedResource = false;
		this.selectedActualTrip = false;
		this.filterResource = [];
		this.filterActual = [];

		if (this.selectedRadio === 'Pacchetto' && event.value === 'Risorsa') {
			this.resourceWithPackage = true;
		}
	}

	checkSubFiltersConditions(): void {
		const isPack: boolean = this.selectedRadio === 'Pacchetto' && this.filterPackages.length === 1;
		const isActual: boolean = this.selectedSubPackFilter === 'Partenza' && this.filterActual.length === 1;
		const subResource: boolean = this.selectedSubPackFilter === 'Risorsa' && this.filterResource.length === 1;

		switch(true) {
			case isPack && isActual:
				this.canDownloadTrip = true;
				break;
				
			case isPack && subResource:
				this.canDownloadSubPackageResource = true;
				break;

			default:
				return;
		}
	}

	checkGenericFiltersConditions(): void {
		switch(true) {
			case this.selectedRadio === 'Pacchetto' && this.filterPackages.length === 1:
				this.canDownloadPackage = true;
				break;
			
			case this.selectedRadio === 'Risorsa' && this.filterResource.length === 1:
				this.canDownloadResource = true;
				break;

			case this.selectedRadio === 'Accompagnatore' && this.filterClient.length === 1:
				this.canDownloadEntity = true;
				break;

			case this.selectedRadio === 'Cliente' && this.filterCustomer.length === 1:
				this.canDownloadCustomer = true;
				break;

			case this.selectedRadio === 'Punto di ritrovo' && this.filterMeetingPoints.length === 1:
				this.canDownloadMeetingPoint = true;
				break;

			default:
				return;
		}
	}

	checkCanDownloadPickUp(): void {
		switch(true) {
			case this.listaCarichiSelezionata === 'Pacchetto' && this.filterPickUpPackages.length === 1:
				this.canDownloadPickUpPackage = true;
				break;

			default:
				return;
		}
	}

	getActionPickUp(): string {
		this.canPerformAction();
		
		switch(true) {
			case this.canDownloadPickUpPackage:
				return this.getPartialUrlPickUp('package') +  this.filterPickUpPackages[0].id + '/' + this.dateInput;

			default:
				return '';
		}
	}

	canPerformAction(): void {
		this.canDownloadPackage = false;
		this.canDownloadPickUpPackage = false;
		this.canDownloadEntity = false;
		this.canDownloadCustomer = false;
		this.canDownloadResource = false;
		this.canDownloadTrip = false;
		this.canDownloadMeetingPoint = false;
		this.canDownloadSubPackageResource = false;

		this.checkCanDownloadPickUp();

		if (this.packageSubFilters) {
			this.checkSubFiltersConditions();
		} else {
			this.checkGenericFiltersConditions();
		}
	}

	getPartialUrl(subfix: string): string {
		return getBaseServerAddressRoot() + '/participants/download/by/' + subfix + '/';
	}

	getPartialUrlPickUp(subfix: string): string {
		return getBaseServerAddressRoot() + '/transfer/download/by/' + subfix + '/';
	}

	getAction(): string {
		this.canPerformAction();

		switch (true) {
			case this.canDownloadPackage:
				return this.getPartialUrl('package') + this.filterPackages[0].id + '/' + this.dateInput;

			case this.canDownloadTrip:
				return this.getPartialUrl('departure') + this.filterActual[0].id;

			case this.canDownloadSubPackageResource:
				const highlighted = this.isHighlighted ? 1 : 0;
				return this.getPartialUrl('package') + this.filterPackages[0].id + '/' + this.dateInput  + '?resource=' + this.filterResource[0].id + '&highlighted=' + highlighted;

			case this.canDownloadResource:
				return this.getPartialUrl('resource') +  this.filterResource[0].id + '/' + this.dateInput;

			case this.canDownloadEntity:
				return this.getPartialUrl('entity') +  this.filterClient[0].entity.id + '/' + this.dateInput + '?date=' + this.dateInput;

			case this.canDownloadCustomer:
				return this.getPartialUrl('customer') +  this.filterCustomer[0].id + '/' + this.dateInput;

			case this.canDownloadMeetingPoint:
				return this.getPartialUrl('meetingPoint') +  this.filterMeetingPoints[0].id + '/' + this.dateInput;

			default:
				return '';
		}
	}

	getDataList(): IDataParticipantList {
		this.canPerformAction();

		switch (true) {
			case this.canDownloadPackage:
				return {
					DatACS: this.datasetACS,
					postData: {id: this.filterPackages[0].id, date: this.dateInput},
					typeList: 'package',
				};

			case this.canDownloadTrip:
				return {
					DatACS: this.datasetACS,
					postData: {id: this.filterActual[0].id},
					typeList: 'departure',
				};

			case this.canDownloadSubPackageResource:
				const highlighted = this.isHighlighted ? 1 : 0;
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterPackages[0].id, 
						date: this.dateInput, 
						request: {
							resource: this.filterResource[0].id, 
							highlighted: highlighted
						}
					},
					typeList: 'package_resource',
				};

			case this.canDownloadResource:
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterResource[0].id,
						date: this.dateInput, 
					},
					typeList: 'resource',
				};

			case this.canDownloadEntity:
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterClient[0].entity.id,
						date: this.dateInput,
					},
					typeList: 'entity',
				};

			case this.canDownloadCustomer:
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterCustomer[0].id,
						date: this.dateInput,
					},
					typeList: 'customer',
				};

			case this.canDownloadMeetingPoint:
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterMeetingPoints[0].id,
						date: this.dateInput, 
					},
					typeList: 'meeting_point',
				};
		}
	}

	getTransferList(): IDataParticipantList {
		this.canPerformAction();

		switch (true) {
			case this.canDownloadPickUpPackage:
				return {
					DatACS: this.datasetACS,
					postData: {
						id: this.filterPickUpPackages[0].id,
						date: this.dateInput, 
					},
					typeList: 'pickup_package',
				};
		}
	}

	downloadListaPartecipanti(): void {
		if (this.getAction()) {
			window.open(this.getAction());
		}
	}

	downloadListaCarichi(): void {
		if (this.getActionPickUp()) {
			window.open(this.getActionPickUp());
		}
	}

	emailListaPartecipanti(): void {
		this.datasetNavigatorService.openDialog(ParticipantListEmailDialogComponent, this.getDataList());
	}

	emailListaCarichi(): void {
		this.datasetNavigatorService.openDialog(ParticipantListEmailDialogComponent, this.getTransferList());
	}

	addDate(event: MatDatepickerInputEvent<_moment.Moment>): void{
		this.dateInput = _moment(event.value).format('YYYY-MM-DD');
	}

	// load Package
	loadPackages(textSearch: any): void {
		const params = {
			'text-search': textSearch,
			perPage: 5
		};
		if(this.loadPackagesSubscription){
			this.loadPackagesSubscription.unsubscribe();
		}

		const route = '/dataset/trip_packages';

		this.loadPackagesSubscription = this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.filteredOptions.next(result.items);
				},
				error: () => {}
			});
	}

	addPackage(event: MatChipInputEvent): void {
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;

			if (input) {
				input.value = '';
			}
		}
	}

	onSelectPackage(event: MatAutocompleteSelectedEvent): void {
		if (this.filterPackages.length < 1) {
			this.filterPackages.push(event.option.value);
			this.filterInput.nativeElement.value = '';
			this.selectedPackage = true;
		}
	}

	removePackage(tripPackageId: any): void {
		const index = this.filterPackages.findIndex(el => {
			return el.id === tripPackageId;
		});
		if (index > -1) {
			this.filterPackages.splice(index, 1);

			this.selectedPackage = false;
			this.packageSubFilters = false;
			this.resourceWithPackage = false;
			this.isHighlighted = false;
			this.selectedResource = false;
			this.selectedSubPackFilter = null;
			this.filterResource = [];
			this.filterActual = [];
		}
	}

	// load PickUpPackages
	loadPickUpPackages(textSearch: any): void {
		const params = {
			'onlyPickUp': 1,
			'start_day': this.dateInput,
			'text-search': textSearch,
			'perPage': 5
		};

		if(this.loadPickUpPackagesSubscription){
			this.loadPickUpPackagesSubscription.unsubscribe();
		}

		const route = '/dataset/trip_packages';

		this.loadPickUpPackagesSubscription = this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.filteredPickUpPackages.next(result.items);
				},
				error: () => {}
			});
	}

	addPickUpPackage(event: MatChipInputEvent): void {
		if (!this.matListaCarichiAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;

			if (input) {
				input.value = '';
			}
		}
	}

	onSelectPickUpPackage(event: MatAutocompleteSelectedEvent): void {
		if (this.filterPickUpPackages.length < 1) {
			this.filterPickUpPackages.push(event.option.value);
			this.listaCarichiInput.nativeElement.value = '';
			this.selectedPickUpPackage = true;
		}
	}

	removePickUpPackage(tripPackageId: any): void {
		const index = this.filterPickUpPackages.findIndex(el => {
			return el.id === tripPackageId;
		});
		if (index > -1) {
			this.filterPickUpPackages.splice(index, 1);
			this.selectedPickUpPackage = false;
		}
	}
	// --------------------------------------------------- Resource --------------------------------------------------------------

	resourceFilteredOptions(textSearch: string): void {
		const params = {
			'text-search': textSearch,
			...(this.resourceWithPackage && {'trip_package_id?': this.filterPackages[0].id}),
			'perPage': 5
		};

		if(this.resourceFilteredOptionsSubscriptions){
			this.resourceFilteredOptionsSubscriptions.unsubscribe();
		}

		const route = '/dataset/trip_resources';

		this.resourceFilteredOptionsSubscriptions = this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.resourceOptions.next(result.items);
				},
				error: () => {}
			});
	}

	onSelectResource(event: MatAutocompleteSelectedEvent): void {
		if (this.filterResource.length < 1) {
			this.filterResource.push(event.option.value);
			this.resourceInput.nativeElement.value = '';
			this.selectedResource = true;
		}
	}

	addResource(event: MatChipInputEvent): void {
		if (!this.matAutocompleteRes.isOpen) {
			const input = event.input;
			const value = event.value;

			// Reset the input value
			if (input) {
				input.value = '';
			}
		}
	}
	removeResource(tripPackageId: any): void {
		const index = this.filterResource.findIndex(el => {
			return el.id === tripPackageId;
		});
		if (index > -1) {
			this.filterResource.splice(index, 1);

			this.selectedResource = false;
		}
	}
	// ------------------------------------------------------------------------------------------------------
	// --------------------------------------------------- EntityStaff--------------------------------------------------------------

	clientFilteredOptions(textSearch: string): void {
		const params = {
			'text-search': textSearch,
			perPage: 5
		};

		if(this.clientFilteredOptionsSubscription){
			this.clientFilteredOptionsSubscription.unsubscribe();
		}

		const route = '/dataset/entity_staff_elements';

		this.clientFilteredOptionsSubscription = this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.entityOptions.next(result.items);
				},
				error: () => {}
			});
	}

	onSelectClient(event: MatAutocompleteSelectedEvent): void {
		if (this.filterClient.length < 1) {
			this.filterClient.push(event.option.value);
			this.entityInput.nativeElement.value = '';
			this.selectedEntity = true;
		}
	}

	addClient(event: MatChipInputEvent): void {
		if (!this.matAutocompleteCli.isOpen) {
			const input = event.input;
			const value = event.value;

			// Reset the input value
			if (input) {
				input.value = '';
			}
		}
	}
	removeClient(tripPackageId: any): void {
		const index = this.filterClient.findIndex(el => {
			return el.id === tripPackageId;
		});
		if (index > -1) {
			this.filterClient.splice(index, 1);
			this.selectedEntity = false;
		}
	}

	// ------------------------------------------------------------------------------------------------------
	// --------------------------------------------------- EntityCustomer--------------------------------------------------------------

	customerFilteredOptions(textSearch: string): void {
		const params = {
			'with_relations': 'entity',
			'sortBy': 'description',
			'sortDirection': 'asc',
			'text-search': textSearch,
			'perPage': 10
		};

		const route = '/dataset/entity_customers';

		this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.customerOptions.next(result.items);
				},
				error: () => {}
			});
	}

	onSelectCustomer(event: MatAutocompleteSelectedEvent): void {
		if (this.filterCustomer.length < 1) {
			this.filterCustomer.push(event.option.value);
			this.customerInput.nativeElement.value = '';
			this.selectedCustomer = true;
		}
	}

	addCustomer(event: MatChipInputEvent): void {
		if (!this.matAutocompleteCust.isOpen) {
			const input = event.input;
			const value = event.value;

			// Reset the input value
			if (input) {
				input.value = '';
			}
		}
	}
	removeCustomer(id: any): void {
		const index = this.filterCustomer.findIndex(el => {
			return el.id === id;
		});
		if (index > -1) {
			this.filterCustomer.splice(index, 1);
			this.selectedCustomer = false;
		}
	}
	// ------------------------------------------------------------------------------------------------------
	// --------------------------------------------------- ActualTrip--------------------------------------------------------------

	actualFilteredOptions(textSearch: any): void {
		const params = {
			'text-search': textSearch,
			'trip_package_id': this.filterPackages[0].id,
			'start_day': this.dateInput,
			'perPage': 5
		};

		const route = '/dataset/actual_trips';

		this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.actualOptions.next(result.items);
				},
				error: () => {}
			});
	}

	onSelectActual(event: MatAutocompleteSelectedEvent): void {
		if (this.filterActual.length < 1) {
			this.filterActual.push(event.option.value);
			this.actualInput.nativeElement.value = '';
			this.selectedActualTrip = true;
		}
	}

	addActual(event: MatChipInputEvent): void {
		if (!this.matAutocompleteAct.isOpen) {
			const input = event.input;
			const value = event.value;

			if (input) {
				input.value = '';
			}
		}
	}
	removeActual(tripPackageId: any): void {
		const index = this.filterActual.findIndex(el => {
			return el.id === tripPackageId;
		});
		if (index > -1) {
			this.filterActual.splice(index, 1);
			this.selectedActualTrip = false;
		}
	}


	/*
	* meeting points 
	*/
	meetingPointFilteredOptions(textSearch: any): void {
		const params = {
			'text-search': textSearch,
			'sortBy': 'description',
			'sortDirection': 'asc',
			'perPage': 5
		};

		const route = '/dataset/meeting_points';

		this._datasetService.get<IPaginationResponse<any>>(route, params)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: result => {
					this.meetingPointOptions.next(result.items);
				},
				error: () => {}
			});
	}

	onSelectMeetingPoint(event: MatAutocompleteSelectedEvent): void {
		if (this.filterMeetingPoints.length < 1) {
			this.filterMeetingPoints.push(event.option.value);
			this.meetingPointInput.nativeElement.value = '';
			this.selectedMeetingPoint = true;
		}
	}

	addMeetingPoint(event: MatChipInputEvent): void {
		if (!this.matAutocompleteMp.isOpen) {
			const input = event.input;
			const value = event.value;

			if (input) {
				input.value = '';
			}
		}
	}
	removeMeetingPoint(id: any): void {
		const index = this.filterMeetingPoints.findIndex(el => {
			return el.id === id;
		});
		if (index > -1) {
			this.filterMeetingPoints.splice(index, 1);
			this.selectedMeetingPoint = false;
		}
	}
	
}
