import { InputTypes, ValueTypes, IFieldDefinition, FieldViews } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { fieldsMap as userFieldMap} from '../users/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('domain_user_role.name', {
	key: 'domain_user_role.name',
	name: 'Ruolo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domain_user_roles',
	remoteOptions: {
		limit: 1,
		getLabel(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name + ' (' + option.description + ')';
		}
	}
});

userFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, {key: 'user.' + value.key });
	fieldsMap.set('user.' + key, value);
});

export {fieldsMap};
