import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as resourceFieldMap} from '../trip_resources/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('document_file_id', {
	key: 'document_file_id',
	name: 'File',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		return record.document_file.file_name;
	}
});

fieldsMap.set('files', {
	key: 'files',
	name: 'File',
	inputType: InputTypes.MULTIPLE_FILE,
	valueType: ValueTypes.ARRAY,
});

fieldsMap.set('download_count', {
	key: 'download_count',
	name: 'Numero di download',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('downloaded_filter', {
	key: 'downloaded_type',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{ return ''; },
	options: [
		{
			label: 'Tutti',
			value: ''
		},
		{
			label: 'Scaricati',
			value: 'downloaded'
		},
		{
			label: 'Non Scaricati',
			value: 'not_downloaded'
		}
	]
});

export {fieldsMap};
