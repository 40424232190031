<div *ngIf="travelSolution.trains" collapsedHeight="auto" expandedHeight="auto">
	<trenitalia-travel-solution-header
		[travelSolution]="travelSolution"
		[isSelected]="isSelected"
		(onSelect)="onSelect.emit()"
		(onDetails)="onDetails.emit()"
	></trenitalia-travel-solution-header>
</div>
<div [@slideInOut]="isSelected && !['edit-date-time'].includes(checkoutDataMode) || isDetailsOpen">
	<div *ngIf="isSelected && travelSolution.saleable &&!['edit-date-time'].includes(checkoutDataMode) && !isCarnet" style="padding: 0 24px 16px;">
		<trenitalia-prices [travel-solution]="travelSolution" (select-price)="onPriceSelected.emit($event)"></trenitalia-prices>
	</div>
	<div class="container" *ngIf="isDetailsOpen">
		<trenitalia-travel-solution-train-details [travelSolution]="travelSolution"></trenitalia-travel-solution-train-details>
	</div>
	<div *ngIf="isSelected && checkoutDataMode==='carnet'">
		<mat-form-field fxFlex appearance="fill">
			<mat-label>Seleziona servizio</mat-label>
			<mat-select [value]="selectedServiceCarnet"
				(selectionChange)="onCarnetChange.emit($event)">
				<mat-option *ngFor="let service of selectCarnet" [value]="service.id">
					{{service.value}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div *ngIf="isSelected && travelSolution.saleable && !['edit-date-time'].includes(checkoutDataMode)" class="footer-result flex-row items-center justify-between">
		<div>
			<mat-slide-toggle class="mr-12"
								*ngIf="['travel','travel-change','carnet','edit'].includes(checkoutDataMode)"
                                [checked]="isSeats"
								(toggleChange)="trnService.btnAction.next('seat-sel')"
								[disabled]="!trnService.seatsEnabled.value || loading">Scegli posti</mat-slide-toggle>
		</div>
		<div class="text-right">
			<button mat-raised-button
					*ngIf="!['edit-date-time','carnet','travel-change'].includes(checkoutDataMode) && (isSeats || isReturn || !isAr)  "
					(click)="trnService.btnAction.next('conf-sel')"
					[disabled]="loading"
			>
				<span>Continua</span>
				<mat-icon>keyboard_arrow_right</mat-icon>
			</button>
            <button mat-raised-button
                    *ngIf="['travel-change'].includes(checkoutDataMode) && isSelected && isSeats "
                    (click)="trnService.btnAction.next('conf-sel')"
                    [disabled]="loading"
            >
                <span>Continua</span>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
			<!--caso A/R-->
			<button mat-raised-button
					*ngIf="!['carnet'].includes(checkoutDataMode)&&(isAr && !isReturn)&&!isSeats"
					(click)="trnService.btnAction.next('conf-sel')"
					[disabled]="loading"
			>
				<span>Ritorno</span>
				<mat-icon>keyboard_arrow_right</mat-icon>
			</button>

			<button mat-raised-button
					*ngIf="checkoutDataMode == 'travel-change' && isSelected && !isSeats"
					(click)="trnService.btnAction.next('travel-change')"
					[disabled]="loading"
			>
				<span>Aggiorna Tratta</span>
			</button>

            <button mat-flat-button color="primary"
                    *ngIf="['carnet'].includes(checkoutDataMode)"
                    style="margin-left: 8px"
                    [disabled]="loading"
                    (click)="trnService.btnAction.next('confirm-carnet')">CONFERMA
            </button>
		</div>
	</div>
</div>