import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import { Subject } from 'rxjs';
import * as _ from "lodash";
var TrenitaliaRecapComponent = /** @class */ (function () {
    function TrenitaliaRecapComponent(trnService) {
        this.trnService = trnService;
        this.fw = false;
        this.showDetails = false;
        this._unsubscribeAll = new Subject();
    }
    TrenitaliaRecapComponent.prototype.ngOnInit = function () {
    };
    TrenitaliaRecapComponent.prototype.ngOnChanges = function (changes) {
        if (changes) {
            this.dataRecap = {
                start: changes.start ? this.trnService.getStationDataFromId(parseInt(changes.start.currentValue)) : this.dataRecap.start,
                end: changes.end ? this.trnService.getStationDataFromId(parseInt(changes.end.currentValue)) : this.dataRecap.end,
                departure: changes.departure ? changes.departure.currentValue : this.dataRecap.departure,
                arrive: changes.arrive ? changes.arrive.currentValue : this.dataRecap.arrive,
                tot: changes.tot ? changes.tot.currentValue.value : this.dataRecap.tot ? this.dataRecap.tot.value : 0,
                totNet: changes.tot ? changes.tot.currentValue.valueNet : this.dataRecap.tot ? this.dataRecap.tot.valueNet : 0,
                fee: changes.tot ? changes.tot.currentValue.fee : this.dataRecap.tot ? this.dataRecap.tot.fee : 0,
                direction: this.direction,
                adult: this.adult,
                children: this.children,
                details: this.updateDetails()
            };
        }
    };
    TrenitaliaRecapComponent.prototype.updateDetails = function () {
        var e_1, _a;
        if (!this.dataAv || !this.offeredServices)
            return null;
        var travelSolutionTickets = {};
        var _loop_1 = function (off) {
            var id = off.id || off.xmlId;
            var offeredService = _.get(this_1.offeredServices, id);
            if (!_.has(travelSolutionTickets, offeredService.nodeId)) {
                travelSolutionTickets[offeredService.nodeId] = { tickets: [] };
            }
            var seatInfo = null;
            if (this_1.dataAv.seatMap) {
                var s = this_1.dataAv.seatMap.find(function (s) { return s.offeredServicesId == offeredService.id; });
                if (s) {
                    seatInfo = { wagon: s.wagon, seat: s.airCraftNumber };
                }
            }
            var traveller = this_1.dataAv.travellers.find(function (t) { return t.xmlId == offeredService.travellerXmlId; });
            var param = _.get(offeredService, 'original.parameters', []);
            var position = void 0;
            if (_.isArray(param)) {
                var parameters = param.find(function (p) { return p.typeId == 2; });
                position = parameters ? parameters.value : null;
            }
            travelSolutionTickets[offeredService.nodeId].tickets.push({
                traveller: traveller,
                catalogServiceName: _.get(this_1.trnService.servicesMap.value, ['catalog_services', offeredService.catalogServiceId, 'display_name']),
                offerName: _.get(this_1.trnService.servicesMap.value, ['offers', offeredService.offerId, 'display_name']),
                position: position,
                offeredService: offeredService,
                prices: this_1.getPrices(offeredService.offerId, offeredService.catalogServiceId, traveller.xmlId, offeredService.nodeId),
                seatInfo: seatInfo,
            });
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.dataAv.selectedOffer), _c = _b.next(); !_c.done; _c = _b.next()) {
                var off = _c.value;
                _loop_1(off);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return travelSolutionTickets;
    };
    TrenitaliaRecapComponent.prototype.onShowDetails = function () {
        this.showDetails = this.showDetails !== true;
    };
    TrenitaliaRecapComponent.prototype.getPrices = function (offerId, serviceId, traveller, train) {
        if (_.get(this.selectedSolution, 'offers_map')) {
            var services = _.get(this.selectedSolution, ['offers_map', train, 'travellers', traveller]);
            if (services) {
                var service = services.find(function (s) { return s.id == serviceId; });
                if (service) {
                    var prices = service['offers'].find(function (s) { return s.offerId == offerId; });
                    return {
                        price: prices.totalPrice,
                        netPrice: prices.totalPriceNet,
                        fee: prices.fee
                    };
                }
            }
        }
        else {
            var prices = _.get(this.selectedSolution, ['priceGrid', train, 'matrix', offerId, serviceId]);
            if (!prices)
                return null;
            var travellers = this.selectedSolution.travellers.length;
            return {
                price: prices.totalPrice / travellers,
                netPrice: prices.totalPriceNet / travellers,
                fee: prices.fee / travellers
            };
        }
    };
    return TrenitaliaRecapComponent;
}());
export { TrenitaliaRecapComponent };
