<div class='full-form' [ngClass]='formConfig.formStyle'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='reselledDatasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è ancora presente!' | translate}}</p>
		</ng-container>

		<mat-accordion>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Dati originali
					</mat-panel-title>
					<mat-panel-description>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<data-container-viewer
					*ngIf='originalDatasetRS.record.value'
					[dataContainerService]='datasetACS'
					[fields]='stepConfig.options.fields' 
					[record]='originalDatasetRS.record.value'
				></data-container-viewer>
			</mat-expansion-panel>
		</mat-accordion>

		<form-viewer
			#formViewer
			[dataContainerService]='datasetACS'
			[formConfig]='formConfig'
			[record]='datasetRS.record.value'
		></form-viewer>

		<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='onSave()'>
				<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
				<span *ngIf='datasetACS.loading.value'>{{'Azione in corso...' | translate}}</span>
			</button>
		</div>

	</div>
</div>