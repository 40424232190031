<div class='full-form'>
	<div class="section title">
		<label class="input-label section-question">
			Cosa visiteranno i clienti?
		</label>

		<div class="instruction">
			<p class="instruction">
				Elenca i principali siti o punti di interesse che i clienti visiteranno. I clienti accederanno al sito o la visita sarà solo all'esterno, senza entrare nel punto di interesse?
			</p>
		</div>

		<div class="examples">
			<h3 class="examples-title"><b>Esempi</b></h3>

			<div class="good-examples">
				<div class="example">
					<span class="text"><i class="material-icons">check_circle</i> Musei Vaticani</span>
				</div>

				<div class="example">
					<span class="text"><i class="material-icons">check_circle</i> Vertice della Torre Eiffel</span>
				</div>

				<div class="example">
					<span class="text"><i class="material-icons">check_circle</i> Hong Kong Disneyland</span>
				</div>
			</div>

			<div class="bad-examples">
				<div class="example">
					<span class="text"><i class="material-icons">cancel</i> Italia</span>
				</div>
				
				<div class="example">
					<span class="text"><i class="material-icons">cancel</i> Una gelateria</span>
				</div>
			</div>
		</div>
	</div>

	<custom-mat-table
		[relation]='"destinations"'
		[displayedColumns]='displayedColumns'
		[filterFormGroup]='filterFormGroup'
		[field]='field'
		[datasetACS]='datasetACS'
		[datasetRS]='datasetRS'
		[dynamCols]='dynamCols'
		[stepName]='"product-trip-destinations"'
		[datasetCode]='"products"'
		[datasetConfig]='datasetConfig'
		[formConfigMap]='formConfig'
		[stepper]='stepper'
		[selectedIndex]='5'
		(nextStep)='goNextStep()'
		[initialValueKey]='"description"'
	></custom-mat-table>
</div>