<div class="p-16">
	<trip-booking-package-header icon="edit_note">
		<ng-container ngProjectAs="[title]" >
			<span>Modifica post vendita: </span>
			<span *ngIf="tripBookingPackage.target_info?.changeDetail?.changeType == 'traveller_change'">Cambio Nominativo</span>
			<span *ngIf="tripBookingPackage.target_info?.changeDetail?.changeType == 'edit-date-time'">Cambio Data/ora viaggio</span>
			<span *ngIf="tripBookingPackage.target_info?.changeDetail?.changeType == 'refund'">Rimborso</span>
			<span *ngIf="tripBookingPackage.target_info?.changeDetail?.changeType == 'travel-change'">Cambio Biglietto</span>
			<span *ngIf="tripBookingPackage.target_info?.changeDetail?.changeType == 'change-class'">Cambio Classe</span>
		</ng-container>

		<ng-container ngProjectAs="[sub-title-1]">
			<span class="font-size-14 secondary-text" *ngIf="tripBookingPackage.created_at">In data: {{ tripBookingPackage.created_at | date: 'dd/MM/yyyy HH:mm' }}</span>
		</ng-container>
	</trip-booking-package-header>
</div>