import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as entityFieldMap} from '../entities/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('discount_id', {
	key: 'discount_id',
	name: 'Sconto',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'discounts',
	remoteOptions: {
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {
				trip_booking_id: dataContainerService.getValueFromKeyPath('trip_bookings.recordId')
			};

			for (const key in params) {
				if(params[key] === null || params[key] === undefined){
					delete params[key];
				}
			}

			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name;
		}
	}
});

export {fieldsMap};
