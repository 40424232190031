<div *ngIf="!submitted; else waiting;">
	<mat-dialog-content>
		<h3>{{'Seleziona i pacchetti da richiedere' | translate}}</h3>
		<form-viewer #formViewer
			[dataContainerService]='datasetACS' 
			[formConfig]='formConfig'
		></form-viewer>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-button (click)="close()" [disabled]="loading">{{'Chiudi' | translate}}</button>
		<button [disabled]="loading" mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">
			{{'Seleziona' | translate}}
		</button>
	
		<mat-spinner *ngIf="loading" diameter="20" style="margin-left: 30px;"></mat-spinner>
		<!--<button mat-raised-button mat-dialog-close tabindex="-1">chiudi</button>-->
	</mat-dialog-actions>
</div>

<ng-template #waiting>
	<h1 mat-dialog-title>{{'Attendi...' | translate}}</h1>

	<mat-dialog-content>
		<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
		<p>{{'Azione in corso...' | translate}}</p>
	</mat-dialog-content>
</ng-template>