import { IDataContainerService } from './../../../../../../../../../interfaces/data-container.interface';
import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('product_bundle_id', {
	key: 'product_bundle_id',
	name: 'Prodotto Stock',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'products',
	remoteOptions: {
		clearInputOnCreation: true,
		supportCreate: true,
		createHint: 'Scrivi per cercare un prodotto',
		clearOnSelection: true,
		limit: 100,
		icon: 'search',
		getParams(dataContainerService: IDataContainerService, data: any): any{
			const params = {};
			params['product_type'] = 'stock';
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

export default fieldsMap;
