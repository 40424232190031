<div fxLayout='column'>
	<div fxFlex>
		<div class='log-list-item' *ngFor='let log of logsService.listItems' (click)='logsService.selectedBulkId.next(log.bulk_id)' [class.selected]='logsService.selectedBulkId.value && logsService.selectedBulkId.value == log.bulk_id'>
			<span *ngIf='log.log_types' class='log-list-item_types' [class.warn-fg]='log.log_types.indexOf("exception") >= 0'>
				{{ log.log_types.slice(0, 10).join(', ') }}
				<span *ngIf='log.log_types.length > 10'>...({{log.log_types.length}})</span>
			</span>
			<span class='log-list-item_date secondary-text'>
				{{ log.created_at | date:'HH:mm:ss d/M/yy'}}
			</span>
		</div>
	</div>
	
	<mat-paginator
		[pageSizeOptions]="[5, 10, 20, 50, 100, 200]" 
		showFirstLastButtons
		[length]="logsService.listPaginationResponse && logsService.listPaginationResponse.meta.total"
		[pageSize]="(logsService.listPaginationResponse && logsService.listPaginationResponse.meta.perPage) || logsService.perPage"
		(page)="onPageChanged($event)">
	</mat-paginator>
</div>