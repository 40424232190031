import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { BaseDatasetComponent } from '../base-dataset.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AuthService } from 'app/services/auth.service';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { Observable, of } from 'rxjs';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { takeUntil, take, exhaustMap } from 'rxjs/operators';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { InvalidAllotmentsDialogComponent } from '../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';
var DatasetUpdateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetUpdateComponent, _super);
    function DatasetUpdateComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService, matDialog, _fuseTranslationLoaderService, webSocketService) {
        var _this = _super.call(this, route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.webSocketService = webSocketService;
        _this.stepViewConfig = TripPackageStepConfigSmartInterface;
        _this.productStepViewConfig = ProductStepViewConfig;
        _this.actionCode = 'edit';
        _this.routed = true;
        _this.updated = new EventEmitter();
        return _this;
    }
    DatasetUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            _this.updated.emit(record);
        });
        if (this.inputRecordId)
            this.datasetRS.recordId = this.inputRecordId;
        if (this.inputRecord)
            this.datasetRS.record.next(this.inputRecord);
        _super.prototype.ngOnInit.call(this);
        this.datasetACS.datasetRS = this.datasetRS;
    };
    DatasetUpdateComponent.prototype.load = function () {
        if (this.firstLoad && this.datasetRS.record.value) {
            return of(this.datasetRS.record.value);
        }
        else {
            return this.datasetRS.reload();
        }
    };
    DatasetUpdateComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.recordId) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        if (this.inputActionRoute) {
            route = this.inputActionRoute;
        }
        of(formData)
            .pipe(exhaustMap(function (formData) {
            if (actionConfig && typeof (actionConfig.beforeSave) === 'function') {
                return actionConfig.beforeSave(_this.datasetACS, _this.datasetRS.recordId, formData, _this.formViewer.record);
            }
            return of(formData);
        }), takeUntil(this._unsubscribeAll), take(1), exhaustMap(function (data) {
            return _this.datasetService.post(route, data);
        }))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.clearForm();
                }
                if (!_this.datasetRS.record.value && _this.routed) {
                    _this.datasetNavigatorService.router.navigate(['dataset/edit/' + _this.datasetACS.datasetCode, response.id], { queryParams: { step: 1 } });
                }
                else {
                    _this.datasetRS.recordId = response.id;
                    _this.datasetRS.reload();
                }
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            }
        });
    };
    return DatasetUpdateComponent;
}(BaseDatasetComponent));
export { DatasetUpdateComponent };
