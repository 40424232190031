import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
var MultiselectInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MultiselectInputComponent, _super);
    function MultiselectInputComponent(inputService, appService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.appService = appService;
        _this.sourceOptions = [];
        return _this;
    }
    MultiselectInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            // only one build will write correct selectOptions
            if (_this.inputService.formInputDefinition.options) {
                _this.selectOptions = _this.inputService.formInputDefinition.options;
                _this.sourceOptions = _this.inputService.formInputDefinition.options;
            }
            else {
                _this.buildSelectPropertyOptions();
                _this.buildSelectOptions();
            }
            if (_this.getFormControl()) {
                _this.getFormControl().valueChanges
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe(function () {
                    _this.updateSelectedLabel();
                });
            }
            _this.updateSelectedLabel();
        });
    };
    MultiselectInputComponent.prototype.getFormControl = function () {
        if (!this.inputService.control) {
            console.warn('controll not found for ', this.inputService.formControlKey, this.inputService.formGroup.controls);
            return null;
        }
        return this.inputService.control;
    };
    MultiselectInputComponent.prototype.updateSelectedLabel = function () {
        var e_1, _a;
        var value = this.inputService.control.value;
        var labels = [];
        if (value && value instanceof Array) {
            try {
                for (var _b = tslib_1.__values(this.sourceOptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var option = _c.value;
                    if (!value.includes(option.value))
                        continue;
                    labels.push(option.label);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.selectedLabel = labels.length ? labels.join(', ') : null;
    };
    MultiselectInputComponent.prototype.buildSelectPropertyOptions = function () {
        if (!this.inputService.propertyDefinition)
            return;
        this.selectOptions = this.inputService.propertyDefinition.property_value_options;
        this.sourceOptions = this.inputService.propertyDefinition.property_value_options;
    };
    MultiselectInputComponent.prototype.buildSelectOptions = function () {
        if (!this.inputService.formInputDefinition || !this.inputService.dataContainerService || !this.inputService.formInputDefinition.optionSource)
            return;
        this.selectOptions = [];
        var source = this.inputService.dataContainerService.getValueFromKeyPath(this.inputService.formInputDefinition.optionSource);
        if (!source) {
            console.warn('source', this.inputService.formInputDefinition.optionSource, this.inputService.dataContainerService);
            return;
        }
        var options = source[this.inputService.formInputDefinition.optionSourceKey];
        if (!options) {
            console.warn('options', this.inputService.formInputDefinition.optionSourceKey, 'not defined in', source);
            return;
        }
        this.sourceOptions = options.map(function (item) {
            return {
                value: item.id,
                label: item.name
            };
        });
        this.selectOptions = this.sourceOptions.map(function (item) { return item; });
    };
    MultiselectInputComponent.prototype.search = function (query) {
        this.selectOptions = this.select(query);
    };
    MultiselectInputComponent.prototype.select = function (query) {
        var e_2, _a;
        var result = [];
        try {
            for (var _b = tslib_1.__values(this.sourceOptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                var option = _c.value;
                if (option.label.toLowerCase().indexOf(query) > -1) {
                    result.push(option);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return result;
    };
    MultiselectInputComponent.prototype.selectAll = function () {
        if (!this.selectOptions)
            return;
        this.inputService.control
            .patchValue(tslib_1.__spread(this.selectOptions.map(function (item) { return item.value; })));
    };
    MultiselectInputComponent.prototype.selectionChange = function (event) {
        this.appService.eventEmitter.emit({
            name: 'selected_label_' + this.inputService.formInputDefinition.key,
            target: event.source['triggerValue']
        });
    };
    MultiselectInputComponent.prototype.deselectAll = function () {
        this.inputService.control.patchValue([]);
    };
    MultiselectInputComponent.prototype.ngOnChanges = function (changes) {
        if (changes.propertyDefinition && !changes.propertyDefinition.isFirstChange()) {
            this.buildSelectPropertyOptions();
        }
    };
    return MultiselectInputComponent;
}(DestroyableComponent));
export { MultiselectInputComponent };
