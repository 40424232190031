import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields.user';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	//fieldsMap.get('creation_mode'),
	fieldsMap.get('name'),
	fieldsMap.get('email'),
	fieldsMap.get('branch.id')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
