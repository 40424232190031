import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { viatorFieldsMap } from '../../fields/touroperators/viator.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	viatorFieldsMap.get('enabled'),
	// viatorFieldsMap.get('name'),
	viatorFieldsMap.get('parameters.api_key'),
	viatorFieldsMap.get('parameters.supplier_id'),
	viatorFieldsMap.get('entity_customer.entity_customer_id'),
	viatorFieldsMap.get('entity_customer.description'),
	viatorFieldsMap.get('branch.branch_id'),
	viatorFieldsMap.get('branch.code'),
	viatorFieldsMap.get('scenario'),
];

const viatorForm: IFormConfig = { 
	editTitle: 'Modifica Configurazioni Viator',
	fields: formFields 
};

export default viatorForm;
