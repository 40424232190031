import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewEncapsulation, SkipSelf, Self } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookingService } from '../../../../booking.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { RELOAD_BOOKING } from '../../../../edit-booking.component';
import { TripBookingPackagesStepService } from '../../../trip-booking-packages-step.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { TripBookingResourceService } from '../../trip-booking-resource.service';

@Component({
	selector   : 'trip-package-resource-booking',
	templateUrl: './trip-package-resource-booking.component.html',
	styleUrls: ['./trip-package-resource-booking.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService, PageClosureContainerService, TripBookingResourceService]
})
export class TripPackageResourceBookingComponent extends DestroyableComponent implements OnInit, OnDestroy, OnChanges{

	trip_resource: any;
	not_activable_reason: any;
	activated: any;
	optional: any;
	is_pickup: any;

	@Input() inputTripBookingResource: any;

	constructor(
		public appService: AppService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		public tripBookingResourceService: TripBookingResourceService,
		public bookingService: BookingService
	){
		super();
	}

	ngOnInit(): void{

		this.datasetACS.init({
			datasetCode: 'trip_booking_resources',
			actionCode: 'detail',
			useBeforeCalls: false,
			parentDatasetRS: this.parentDatasetRS,
			parentDatasetACS: this.parentDatasetACS
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
		});
	}

	changeStatus(): void{
		if(this.not_activable_reason) return;
		if(this.datasetRS.record.value.activated == 1){
			this.deactivateOptionalResource();
		}else{
			this.activateOptionalResource();
		}
	}

	computeOptionalResource(): void{
		const bookingResource = this.datasetRS.record.value;
		const notActivableReason = this.tripBookingResourceService.makeNotActivableReason(bookingResource);
		const isPickup = bookingResource.trip_resource && bookingResource.trip_resource.properties.resource_sub_types.value === 'PICKUP';
		if(isPickup && bookingResource.activated){
			this.tripBookingResourceService.loadMeetingPoint(this.datasetRS.record.value);
		}
		this.trip_resource = bookingResource.trip_resource;
		this.not_activable_reason = notActivableReason;
		this.activated = bookingResource.activated,
		this.optional = bookingResource.optional;
		this.is_pickup = bookingResource.trip_resource && bookingResource.trip_resource.properties.resource_sub_types.value === 'PICKUP';
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.inputTripBookingResource){
			if(!this.datasetRS.record.value){
				this.datasetRS.record.next(this.inputTripBookingResource);
			}else if(this.inputTripBookingResource && this.inputTripBookingResource.updated_at > this.inputTripBookingResource.updated_at){
				this.datasetRS.record.next(this.inputTripBookingResource);
			}
			this.computeOptionalResource();
		}
	}

	activateOptionalResource(): void{
		this.tripBookingResourceService.activateResource(this.datasetRS.record.value)
			.afterClosed()
			.subscribe(() => this.appService.appEvent.next({name: RELOAD_BOOKING}));
	}

	deactivateOptionalResource(): void{
		this.tripBookingResourceService.deactivateResource(this.datasetRS.record.value)
			.afterClosed()
			.subscribe(() => this.appService.appEvent.next({name: RELOAD_BOOKING}));
	}
}
