import {IDatasetConfig, IDatasetCallDefinition, DatasetActionOpenType, ListViewItemTypes, InputTypes, FieldViews, ValueTypes, ListViewExportTypes, ISocketEvent } from 'app/interfaces';
import {fieldsMap} from './fields';
import baseInfoFormConfig from './forms/base.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import listFilterForm from './forms/filter.form';
import { LIST_COLUMNS } from './list_columns';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import {showIfCustomAddFields} from "../../commons/shared-field-configs/custom_field.field";

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

export const TripBookingReportViewConfig: IDatasetConfig = {
	name: 'trip_booking_report_views',
	title: 'Prenotazioni',
	singleTitle: 'Prenotazione',
	ajaxDatasetCode: 'trip_booking_report_views',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		baseInfoFormConfig
	},
	defaultActions: {
		detail: {
			beforeCalls: [ callModule,
				{
					name: 'domainContext',
					path: '/dataset/trip_booking_report_views/beforeCreate/:recordId',
				}]
		},
		create: {
			beforeCalls: [ callModule]
		},
		list: {
			beforeCalls: [ callModule],
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any{
				const moduleTicketingEnabled = dataContainerService && dataContainerService.getValueFromKeyPath('domainModules.ticketing.enabled');
				if(moduleTicketingEnabled){
					params['addColumn[]'] = ['email'];
				}
                const customFields = dataContainerService.getDomainSetting('custom_fields.report.add.field_list');
                if(customFields){
                    params['addColumn[]']=params['addColumn[]']?params['addColumn[]']:[];
                    for(let fields of customFields)params['addColumn[]'].push(fields)
                }
				params['with_presences'] = 1;
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				showExportBtn: true,
				exportBtnActions: [
					{
						typeFile: ListViewExportTypes.XLS,
						buttonText: 'Esporta xls'
					},
					{
						typeFile: ListViewExportTypes.CSV,
						buttonText: 'Esporta csv'
					},
					{
						typeFile: ListViewExportTypes.CSV_VIA_EMAIL,
						buttonText: 'Esporta csv via EMail'
					}
				],
				defaultSortBy: 'trip_booking_booking_date',
				defaultSortDirection: 'desc',
				filterForm: listFilterForm,
				cacheDatasetListFilters: true,
				columns: LIST_COLUMNS,
				actions: [],
				customActions: [
					{
						buttonColor: 'primary',
						buttonIcon: 'edit',
						tooltip: 'Modifica',
						makeRouteLink(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): string{
							return '/edit-booking/' + record.trip_booking_id;
						},
						showIf(): boolean{ return true; }
					},
					{
						buttonColor: 'primary',
						buttonIcon: 'done',
						tooltip: 'Conferma Consegna',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.datasetService.post<any>('/dataset/trip_bookings/command/confirm_delivery', {
								trip_booking_package_id: record.trip_booking_package_id,
								delivery_status: 'consegnato' // status = in consegna or consegnato
							}).subscribe({
								next: response => {
									if (dataContainerService instanceof DatasetActionContainerService) {
										dataContainerService.datasetEvent.next({
											eventName: DatasetEvents.ACTION_REALOAD_LIST
										});
									}
									datasetNavigatorService.appService.showSuccessMessage('Stato della consegna aggiornato.');
								},
								error: response => {
									datasetNavigatorService.datasetService.appService.showErrorMessage('Si è verificato un errore.');
								}
							});
						},
						showIf(record, dataContainerService): boolean{
							return record && record.delivery_status === 'in consegna';
						}
					}
				]
			},
			webSocketConfig: {
				created: {
					reloadDatasetList: true,
					showToast: true,
					dismissToastTxt: "Chiudi"
				},
				updated: {
					reloadDatasetList: true,
					showToast: true,
					dismissToastTxt: "Chiudi"
				},
				command_executed: {
					showToast: true,
					dismissToastTxt: "Chiudi",
					reloadDatasetListOnDismissToast: true,
					composeToastMessage(socketEvent: ISocketEvent): string{
						if (socketEvent.data.payloadData.command && socketEvent.data.payloadData.record){
							switch(socketEvent.data.payloadData.command){
								case 'command_confirm_booking':
									return 'La prenotazione ' + socketEvent.data.payloadData.record.booking_code + ' è stata confermata';
								case 'command_cancel_booking':
									return 'La prenotazione ' + socketEvent.data.payloadData.record.booking_code + ' è stata annullata';
								default: return '';
							}
						}
					},
					highlightAffectedRow(record: any, socketEvent: ISocketEvent): boolean{
						return false;
					}
				},
				composeGlobalToastMessage(socketEvent: ISocketEvent): string{
					if (socketEvent.data.eventName == 'created' && socketEvent.data.payloadData.multiple && socketEvent.data.payloadData.count){
						return 'Ci sono ' + socketEvent.data.payloadData.count + ' nuove prenotazioni';
					}else if (socketEvent.data.eventName == 'updated'){
						return 'Ci sono ' + socketEvent.data.payloadData.count + ' prenotazioni aggiornate';
					}

					return 'Operazione eseguita tramite socket';
				},
				checkGloballyAffectedRow(record: any, socketEvent: ISocketEvent): boolean{
					if (socketEvent){
						if (socketEvent.data.payloadData.multiple && socketEvent.data.payloadData.records){
							return socketEvent.data.payloadData.records[record.id] || false;
						}else if (socketEvent.data.payloadData.record){
							return record.trip_booking_id == socketEvent.data.payloadData.record.id;
						}
					}
					return false;
				}
			},
		}

	}
};
