import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InputTypes, isFieldContainer } from 'app/interfaces';
import { FormViewerService } from 'app/services/form-viewer.service';
var FormViewerComponent = /** @class */ (function () {
    function FormViewerComponent(formViewerService, cd) {
        this.formViewerService = formViewerService;
        this.cd = cd;
        this.readOnly = false;
        this.valueChanges = new EventEmitter();
        this.inputTypes = InputTypes;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(FormViewerComponent.prototype, "formGroup", {
        get: function () {
            return this.formViewerService.formGroup;
        },
        enumerable: true,
        configurable: true
    });
    FormViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formViewerService.init({
            errors: this.errors,
            fields: this.formConfig.fields,
            dataContainerService: this.dataContainerService
        });
        this.formViewerService.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.onFormValuesChanged();
        });
        this.formViewerService.formBuilded
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.buildFormView();
        });
        this.buildFormView();
    };
    FormViewerComponent.prototype.buildFormField = function (field) {
        if (!field)
            return;
        if (isFieldContainer(field)) {
            return tslib_1.__assign({}, field, { isGroup: true, fields: field.fields.map(this.buildFormField.bind(this)).filter(function (field) { return field; }) });
        }
        else {
            var control = this.formViewerService.controlKeyMap[field.key] && this.formViewerService.formGroup.controls[this.formViewerService.controlKeyMap[field.key]];
            var isReadOnly = this.readOnly || field.inputReadOnly || (field.inputConfig && field.inputConfig.readOnly);
            if (!control && !isReadOnly)
                return;
            return {
                isGroup: false,
                isReadOnly: isReadOnly,
                class: "form-input-viewer_" + ((field.inputConfig && field.inputConfig.type) || field.inputType),
                flex: '1 1 auto',
                formInputDefinition: field,
                maxWidth: field.maxWidth,
                width: field.width,
                controlKey: this.formViewerService.controlKeyMap[field.key]
            };
        }
    };
    FormViewerComponent.prototype.buildFormView = function () {
        var newFormBuilded = {
            style: this.formConfig.formStyle || "style-1",
            fields: [],
            formGroup: this.formViewerService.formGroup
        };
        if (this.formConfig.fields) {
            newFormBuilded.fields = this.formConfig.fields.map(this.buildFormField.bind(this)).filter(function (field) { return field; });
        }
        console.log('newFormBuilded', newFormBuilded);
        this.formViewInfo = newFormBuilded;
        this.cd.detectChanges();
    };
    /**
     * If use multipart return FormData else an object
     */
    FormViewerComponent.prototype.getFormData = function () {
        return this.formViewerService.getFormData();
    };
    FormViewerComponent.prototype.trackByFn = function (index) {
        return index;
    };
    FormViewerComponent.prototype.onFormValuesChanged = function () {
        this.valueChanges.emit(this.formViewerService);
        if (this.formConfig.onChanged) {
            this.formConfig.onChanged(this.formViewerService.formGroup);
        }
    };
    FormViewerComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    FormViewerComponent.prototype.clearForm = function () {
        this.formViewerService.formGroup.reset();
    };
    FormViewerComponent.prototype.setErrors = function (errors) {
        this.formViewerService.setErrors(errors);
    };
    FormViewerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.record && (!changes.record.isFirstChange() || !changes.record.previousValue)) {
            this.formViewerService.setRecord(this.record);
        }
    };
    return FormViewerComponent;
}());
export { FormViewerComponent };
