import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy, OnInit, SkipSelf } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';

import discountViewConfig from 'app/configs/datasets/trip_booking_services/discount-view-config';

@Component({
	selector   : 'trip-options-for-virtual-trips-list-item-view',
	templateUrl: './item.component.html',
	styleUrls  : ['./item.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService]
})
export class TripOptionForVirtualTripsListItemViewComponent implements OnInit, OnChanges{

	@Input() item: any;
	@Output() delete: EventEmitter<any> = new EventEmitter();
	@Output() edit: EventEmitter<void> = new EventEmitter();

	public viewConfig = discountViewConfig;

	constructor(
		protected datasetService: DatasetService,
		protected appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService
	){}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_options_for_virtual_trips',
			actionCode: 'edit',
			datasetRS: this.datasetRS,
			parentDatasetACS: this.parentDatasetACS,
			fetchData: false
		}).subscribe(ready => {
			if(!ready) return;
		});
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.item){
			this.datasetRS.record.next(this.item);
		}
	}

	deleteOption(): void{
		this.delete.emit(this.datasetRS.record.value);
	}

	editOption(){
		this.edit.emit(this.datasetRS.record.value);
	}
}
