import {Injectable, OnInit} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import * as moment from 'moment';
import * as _ from "lodash";
import {environment} from "../../../../../environments/environment";
import { BookingService } from '../booking.service';
import { exhaustMap } from 'rxjs/operators';

export interface IEvaluateTravelPostSaleBody {
	travel: any;
	selectedPostSaleDetails: any[];
	selectedOfferedServices?: any[];
	oldOfferedServices?: any[];
}

export const trainTypeImages = {
	Frecciarossa: 'assets/images/trenitalia/FRECCIAROSSA.svg',
	FrecciaBianca: 'assets/images/trenitalia/FB.svg',
	'Frecciarossa 1000': 'assets/images/trenitalia/frecciarossa_1000.svg',
	Frecciargento: 'assets/images/trenitalia/frecciargento.svg',
	Intercity: 'assets/images/trenitalia/intercity.svg',
	InterCityNotte: 'assets/images/trenitalia/intercity_notte.svg',
	Regionale: 'assets/images/trenitalia/regionale.svg',
	'Regionale Veloce': 'assets/images/trenitalia/RV.svg',
	'EuroCity': 'assets/images/trenitalia/EC.svg',
	'Autobus': 'assets/images/trenitalia/BU.svg',
	FrecciaLink: 'assets/images/trenitalia/FL.svg',
	default: 'assets/images/trenitalia/default_logo.svg',
};

export function getTrainBreadcrumbs(trains){
	const result = [];
	if(!trains) return result;
	for (const train of trains) {
		if(train.type == 'multi' && train.subSegments){
			getTrainBreadcrumbs(train.subSegments).forEach(item => result.push(item));
		}else{
			result.push(Object.assign(train, {
				icon: trainTypeImages[train.type],
				departureLocalDateStr: new Date(train.departure).toLocaleDateString(),
				arrivalLocalDateStr: new Date(train.arrival).toLocaleDateString(),
				departureLocalTimeStr: new Date(train.departure).toLocaleTimeString(undefined, {hour:'2-digit', minute:'2-digit', hour12: false}),
				arrivalLocalTimeStr: new Date(train.arrival).toLocaleTimeString(undefined, {hour:'2-digit', minute:'2-digit', hour12: false})
			}));
		}
	}
	return result;
}

@Injectable()
export class TrenitaliaService {
	public loading: BehaviorSubject<boolean>;
	public checkoutData: BehaviorSubject<any>;
	public solutions: BehaviorSubject<any>;
	public step: BehaviorSubject<number>;
	public trainSeatStep: BehaviorSubject<any>
	public stations: BehaviorSubject<any[]>;
	public loadingStations: BehaviorSubject<boolean>;
	public total: BehaviorSubject<{}>;
	public continue: BehaviorSubject<any>;
	public errors: BehaviorSubject<any>;
	public btnAction: BehaviorSubject<any>;
	public isDialog: BehaviorSubject<boolean>;
	public seatsEnabled: BehaviorSubject<boolean>;
	public servicesMap: BehaviorSubject<any>;
	public stationSubscription: Subscription;
	constructor(
		public datasetService: DatasetService,
		public bookingService: BookingService
	) {
		this.btnAction = new BehaviorSubject('');
		this.isDialog = new BehaviorSubject(false);
		this.seatsEnabled = new BehaviorSubject(false);
		this.loading = new BehaviorSubject(false);
		this.checkoutData = new BehaviorSubject({});
		this.servicesMap = new BehaviorSubject({});
		this.solutions = new BehaviorSubject({});
		this.step = new BehaviorSubject(1);
		this.trainSeatStep = new BehaviorSubject(0);
		this.total = new BehaviorSubject({});
		this.continue = new BehaviorSubject(false);
		this.errors = new BehaviorSubject({});
		this.stations = new BehaviorSubject([]);
		this.loadingStations = new BehaviorSubject(true);
		this.stationSubscription = this.datasetService.get<any[]>('/dataset/trenitalia/command/all_stations')
		.subscribe( stations =>{
			this.stations.next(stations);
		}, error => {
			//TODO errore lettura stazioni
		}, () => {
			this.loadingStations.next(false);
		})
	}


	getStationDataFromId(id){
		let stationList = this.stations.getValue();
		if(!stationList) return null;
		return stationList.find(st =>st.service_id===id)
	}

	init(initData?:any,checkoutData?:any) {
		if (initData) {
			let data ={
				adult: initData.tripBookingData.participant_counters.ADT,
				children: initData.tripBookingData.participant_counters.CHD,
				direction: 'ONE_WAY',
				start:
				initData.tripBookingData.target_info.solution.context
					.searchCriteria.departureTimesStart,
				from: parseInt(
					initData.tripBookingData.target_info.solution.context
						.searchCriteria.departure.id
				),
				to: parseInt(
					initData.tripBookingData.target_info.solution.context
						.searchCriteria.arrival.id
				),
				passengers: initData.tripBookingData.target_info.travellers,
				mode: initData.mode,
				reopen: initData.reopen
			}
			if(checkoutData){
				data = {...data,...checkoutData}
			}
			this.checkoutData.next(data);

		} else {
			this.checkoutData.next({
				adult: 1,
				children: 0,
				direction: 'ONE_WAY',
				start: moment().add(30, 'minutes'),
				from: _.get(environment,'trenitalia.from',null),
				to: _.get(environment,'trenitalia.to',null),
				mode: 'travel'
			});
		}_.get(environment,'trenitalia.ar',false)
	}

	getSolutions(s: any) {
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/search_solutions',
			s
		);
	}

	similarSolutions(s) {
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/search_similar_solutions',
			s
		);
	}

	getOffers(s: any) {
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/search_offers',
			s
		);
	}

	getAvailability(s: any) {
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/evaluate_travel_solution',
			s
		);
	}

	getStops(s) {
		return this.datasetService.post('/dataset/trenitalia/command/stops', s);
	}

	reset() {
		this.checkoutData.next([]);
		this.step.next(1);
		this.total.next({});
	}

	getDetails(tripBookingPackage,isCarnet=false) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/travel_details',
			{ trip_booking_package_id: tripBookingPackage, is_carnet:isCarnet }
		);
	}

	evaluateDeleteOrRefund(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/evaluate_delete_or_refund',
			body
		);
	}

	evaluateTravelPostSale(body: IEvaluateTravelPostSaleBody) {
		return this.datasetService.post<any>(
			'/dataset/trenitalia/command/evaluate_post_sale',
			body
		);
	}

	cancelTravel(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/cancel_travel',
			body
		);
	}

	changeTravellers(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/change_travellers',
			body
		);
	}

	changeClass(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/change_class',
			body
		);
	}

	updateAndConfirm(body) {
		return this.bookingService.execPreAction('trenitalia:post-sale')
		.pipe(
			exhaustMap((a) => {
				return this.datasetService.post(
					'/dataset/trenitalia/command/update_and_confirm',
					body
				);
			})
		);
	}

	/*
	metodi per carnet
	* */
	/**
	 * Lista carnet
	 * @param body Object
	 */
	searchAvailablesCarnet(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/search_availables_carnet',
			body
		);
	}

	searchCarnetSolutions(body){
		return this.datasetService.post(
			'/dataset/trenitalia/command/search_carnet_solutions',
			body
		);
	}

	/**
	 * login e recupero dati
	 * @param body
	 */
	login(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/trenitalia_login',
			body
		);
	}

	/**
	 * Check valid carnet for search travel
	 * @param body
	 */
	checkCarnet(body) {
		return this.datasetService.post(
			'/dataset/trenitalia/command/check_carnet',
			body
		);
	}

	checkTravelChangePossibilities(body){
		return this.datasetService.post(
			'/dataset/trenitalia/command/check_travel_change_possibilities',
			body
		);
	}

	updateServicesMap(servicesMap: any) {
		const s = {
			catalog_services: Object.assign({}, _.get(this.servicesMap.value, 'catalog_services'), _.get(servicesMap, 'catalog_services')),
			offers: Object.assign({}, _.get(this.servicesMap.value, 'offers'), _.get(servicesMap, 'offers'))
		};
		this.servicesMap.next(s);
	}

	scrollToTop(end=false) {

		const element = document.getElementById(end?"step-2-top":"button-group-action-package");
		element.scrollIntoView();
	}

	getFee(body){
		return this.datasetService.post(
			'/dataset/trenitalia/command/fee',
			body
		);
	}
}
