import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Abilitato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

//#region entity customer
fieldsMap.set('entity_customer.entity_customer_id', {
	key: 'entity_customer.entity_customer_id',
	name: 'Cliente',
	valueType: ValueTypes.REFERENCE,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	datasetCode: 'entity_customers',
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any {
			if(!option) return;
			return option.id;
		},
	}	
});

fieldsMap.set('entity_customer.description', {
	key: 'entity_customer.description',
	name: 'Cliente',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});
//#endregion entity customer

//#region branch
fieldsMap.set('branch.branch_id', {
	key: 'branch.branch_id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	fieldView: FieldViews.HIDDEN,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if(!option) return '';
			return option.code;
		},
		getValue(option): any {
			if(!option) return;
			return option.id;
		},
	}	
});

fieldsMap.set('branch.code', {
	key: 'branch.code',
	name: 'Filiale',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});
//#endregion branch

export {fieldsMap as toursFieldsMap};
