
<div >
	<div class="loading-div" *ngIf='dashboardService.loadingTravelDate || showLoader'>
		<mat-spinner *ngIf='dashboardService.loadingTravelDate' diameter="80" class='full-center-spinner'></mat-spinner>
	</div>

	<canvas baseChart *ngIf='travels'
		[datasets]="travels"
		[labels]="barChartLabels" 
		[options]="barChartOptions"
		[legend]="barChartLegend" 
		[chartType]="barChartType" 
	></canvas>
</div>
