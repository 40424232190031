import { Component, OnInit, ViewChild } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { FormViewerService } from "app/services/form-viewer.service";
import { fieldsMap as filterFields } from './filter.fields';
import { DatasetListComponent } from "app/main/components/dataset/base-actions/list/dataset-list.component";
import { convertToUTCDay } from "app/helpers/date.helper";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import tripPackageIdField from "app/configs/commons/shared-field-configs/trip-package-id.field";
import { cloneFieldDefinition } from "app/helpers/field.helper";
import { IListViewConfig, ListViewItemTypes, ValueTypes, InputTypes, FieldViews } from "app/interfaces";
import { IDataContainerService } from "app/interfaces/data-container.interface";
import { TripBookingPackagesStepService } from "../../trip-booking-packages-step.service";
import { takeUntil } from "rxjs/operators";
import { ActionDialogComponent, IActionDialogData } from "../../../action-dialog/action-dialog.component";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { actualTripsListViewConfig } from "./actual-trips-list-view-config";
import { DomainFilterService } from "app/services/domain-filter.service";
import * as _ from "lodash";

@Component({
	selector   : 'package-trip-selection',
	templateUrl: './package-trip-selection.component.html',
	styleUrls: ['./package-trip-selection.component.scss'],
	providers: [FormViewerService]
})
export class PackageTripSelectionComponent extends DestroyableComponent implements OnInit{

	public filterFields = filterFields;
	public filters: any = {
		start_day: JSON.stringify(['>=', convertToUTCDay().toISOString()]), 
		only_bookable: 1
	};
	public showList = false;
	public changeStartDayFilter: string;

	get actualTripsListViewConfig(): IListViewConfig{
		return actualTripsListViewConfig;
	}
	
	@ViewChild('datasetListActualTrips', {static: true}) actualTripListComponent: DatasetListComponent;

	constructor(
		public formViewerService: FormViewerService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		public datasetNavigator: DatasetNavigatorService,
		public domainFilterService: DomainFilterService
	){
		super();
	}

	ngOnInit(){
		this.formViewerService.init({
			fields: Array.from(filterFields.values())
		});

		this.showList = this.domainFilterService.getDomainSetting('current', 'backoffice.booking.prevent_autoload_trips') != true;

		this.formViewerService.formGroup.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(filterFields => {
			let startDay = filterFields.start_day;
			if(typeof(startDay) == 'object' && typeof(startDay.toJSON) == 'function') startDay = startDay.toJSON();
			if(!startDay) startDay = convertToUTCDay().toJSON();
			if(this.changeStartDayFilter){
				this.formViewerService.setValues({start_day: this.changeStartDayFilter}, {setValueOptions: {emitEvent: false}})
				startDay = this.changeStartDayFilter;
			}
			this.changeStartDayFilter = null;
			const newFilters = Object.assign({}, this.filters, {
				start_from: startDay,
				trip_package_id: filterFields.trip_package_id ? filterFields.trip_package_id : null
			});

			if(!newFilters.trip_package_id) delete newFilters.trip_package_id;

			this.filters = newFilters;
			if(JSON.stringify(this.filters) == JSON.stringify(newFilters)) this.showList = true;
		});
	}

	addTripBookingPackage(actualTrip: any): void{
		const dialogRef = this.datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
			title: 'Attendere',
			contentMessage: ''
		});
		const postData = {
			trip_booking_id: this.datasetRS.recordId,
			actual_trip_id: actualTrip.id,
		};
		if(actualTrip.is_virtual){
			delete postData.actual_trip_id;
			postData['trip_package_id'] = actualTrip.trip_package_id;
			_.set(postData, 'availability', {
				meeting_point_list_id: actualTrip.meeting_point_list_id,
				start_day: actualTrip.start_day,
				start_time: actualTrip.start_time
			});
		}
		let actionEndPoint = '/dataset/trip_booking_packages/create';
		this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
		.subscribe({
			next: () => {
				this.datasetRS.reload()
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(() => {
					dialogRef.close();
				});
			},
			error: errorResult => {
				dialogRef.componentInstance.resultStatus = 'error';
				dialogRef.componentInstance.loading = false;
				dialogRef.componentInstance.setMessage('Si è verificato un errore');
				dialogRef.componentInstance.setTitle(null);
			},
			complete: () => {
				dialogRef.close();
			}
		});
	}

	onTripPackageIdInputCustomEvent(event: any){
		if(_.get(event, 'code') == 'selectionFilterChanged'){
			const dateFilterRaw = _.get(event, 'data.departure_date_filter');
			if(dateFilterRaw){
				const dateFilter = JSON.parse(dateFilterRaw);
				if(Array.isArray(dateFilter) && dateFilter.length == 2){
					this.changeStartDayFilter = dateFilter[1].substring(0, 10);
				}else {
					this.changeStartDayFilter = null;
				}
			}
		}
	}
}