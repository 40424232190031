import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import { CdkStepper } from '@angular/cdk/stepper';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
var CustomMatTableComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomMatTableComponent, _super);
    function CustomMatTableComponent(appService, datasetService, datasetNavigatorService, stepService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.stepService = stepService;
        _this.nextStep = new EventEmitter();
        _this.rowsAdded = [];
        _this.rowsRemoved = [];
        return _this;
    }
    Object.defineProperty(CustomMatTableComponent.prototype, "primaryKey", {
        get: function () {
            var pk = this.dynamCols.filter(function (x) { return x.primaryKey === true; });
            return pk[0];
        },
        enumerable: true,
        configurable: true
    });
    CustomMatTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataSource = new MatTableDataSource();
        this.datasetACS.loading.next(true);
        this.load()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (result) {
                _this.renderTable(result);
                _this.setInternalStepState();
                _this.datasetACS.loading.next(false);
            },
            error: function () {
                _this.datasetACS.loading.next(false);
            }
        });
        this.detectFormChanges();
        this.appService.eventEmitter
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.name === 'clickOnSupportCreation_' + _this.field.key) {
                _this.createNewDatasetRow(event.extra.inputValue);
            }
        });
        this.resellView = !isRecordInFilteredDomain(this.datasetRS.record.value, this.datasetRS.record.value.is_owner);
    };
    CustomMatTableComponent.prototype.detectFormChanges = function () {
        var _this = this;
        this.filterFormGroup.controls[this.field.key].valueChanges.subscribe({
            next: function (item) {
                if (item && item.id) {
                    var newItem = _this.getItem(item);
                    _this.addRow(newItem);
                }
            },
            error: function (result) {
                console.log(result); // stampo errore
            }
        });
    };
    CustomMatTableComponent.prototype.renderTable = function (result) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(result.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                var newItem = this.getItem(item);
                this.dataSource.data.push(newItem);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.table.renderRows();
    };
    CustomMatTableComponent.prototype.setInternalStepState = function () {
        var stepData = this.stepService.stepsData[this.stepName];
        if (stepData) {
            if (this.dataSource.data.length > 0 && !stepData.status)
                stepData.status = 1;
        }
    };
    CustomMatTableComponent.prototype.createNewDatasetRow = function (value) {
        var _this = this;
        var initialVal = {};
        initialVal[this.initialValueKey] = value;
        var dialog = this.datasetNavigatorService.openCreateDialog(this.datasetACS, {
            formConfig: this.formConfigMap,
            customActionCode: this.datasetConfig.ajaxDatasetCode,
            initialValues: initialVal
        });
        dialog.afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (item) {
                if (item && item.id) {
                    var newItem = _this.getItem(item);
                    _this.addRow(newItem);
                }
            },
            error: function (response) {
                console.error(response);
            }
        });
    };
    CustomMatTableComponent.prototype.load = function () {
        var params = {};
        var headers = new HttpHeaders({});
        if (this.datasetACS.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
        }
        return this.datasetService.get(this.getListRoute(), params, headers);
    };
    CustomMatTableComponent.prototype.addRow = function (record) {
        var _this = this;
        if (!this.dataSource.data.find(function (x) { return x[_this.primaryKey.name] === record[_this.primaryKey.name]; })) {
            this.dataSource.data.push(record);
            this.rowsAdded.push(record);
            var stepData = this.stepService.stepsData[this.stepName];
            if (stepData) {
                if (this.dataSource.data.length > 0 && !stepData.status)
                    stepData.status = 1;
            }
        }
        this.table.renderRows();
    };
    CustomMatTableComponent.prototype.remove = function (record) {
        var _this = this;
        this.dataSource.data = this.dataSource.data.filter(function (value) {
            return value[_this.primaryKey.name] !== record[_this.primaryKey.name];
        });
        if (this.stepService.stepsData[this.stepName]) {
            if (this.dataSource.data.length === 0)
                this.stepService.stepsData[this.stepName].status = 0;
        }
        this.rowsRemoved.push(record);
        this.table.renderRows();
    };
    CustomMatTableComponent.prototype.getListRoute = function () {
        var recordId = this.datasetRS.recordId;
        return '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
    };
    CustomMatTableComponent.prototype.getCreateRoute = function () {
        var recordId = this.datasetRS.recordId;
        return '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
    };
    CustomMatTableComponent.prototype.getItem = function (item) {
        var e_2, _a;
        var newItem = {};
        newItem['id'] = item.id;
        try {
            for (var _b = tslib_1.__values(this.displayedColumns), _c = _b.next(); !_c.done; _c = _b.next()) {
                var col = _c.value;
                if (col === 'action')
                    continue;
                newItem[col] = item[col];
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return newItem;
    };
    CustomMatTableComponent.prototype.onSave = function () {
        var _this = this;
        this.datasetACS.loading.next(true);
        var data = {
            relation: this.relation,
            data: this.dataSource.data.map(function (x) { return x.id; }).join(),
            recordId: this.datasetRS.recordId,
        };
        this.datasetService.post(this.getCreateRoute() + '/command/' + this.datasetConfig.pivot.commandSync, data)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (res) {
                _this.datasetACS.loading.next(false);
                setTimeout(function () {
                    _this.stepService.setStepState({
                        dataset_code: _this.datasetCode,
                        form_code: _this.stepName,
                        record_id: _this.datasetACS.datasetRS.recordId,
                        status: 1
                    });
                    if (_this.stepper) {
                        _this.stepper.selectedIndex = _this.selectedIndex;
                    }
                    else
                        _this.nextStep.emit();
                }, 200);
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
            }
        });
    };
    return CustomMatTableComponent;
}(DestroyableComponent));
export { CustomMatTableComponent };
