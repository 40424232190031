import { ValueTypes, FieldViews } from 'app/interfaces';

export default {
	key: 'active_language_codes',
	name: 'Traduzioni attive',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

		if(record.active_language_codes && record.active_language_codes.length > 0){
			const style = 'style="margin-right: 5px;"';
			for (const lang of record.active_language_codes) {
				html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
			}
		}else{
			html += '<span>---</span>';
		}

		html += '</div>';
		return html;
	}
};
