import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, TemplateRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarEventModel } from '../event.model';

@Component({
	selector     : 'view-day-calendar',
	templateUrl  : './view-day-calendar.component.html',
	styleUrls    : ['./view-day-calendar.component.scss'],
})

export class ViewDayCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() headerTemplate: TemplateRef<any>;
	@Input() groupedEventsByHour: CalendarEventModel[];
	@Input() viewDate: Date;

	unsubscriber: Subject<any>;
	isLoadingParticipants: any = {};
	hourSegments: any[] = [];

	constructor(
		public appService: AppService,
		public datasetNavigatorService: DatasetNavigatorService,
		public calendarService: CalendarService,
		protected http: HttpClient){
			this.unsubscriber = new Subject<any>();
		}

	ngOnInit(): void{
		/**
		 * daily calendar hours segments
		 */
		for (let i=0; i<24; i++){
			for (let j=1; j<=2; j++){
				this.hourSegments.push(i + ':' + (j === 1 ? '00' : (60/j)));
			}
		}
	}

	ngAfterViewInit(): void{
		/**
		 * if already loaded
		 */
		this.loadParticipants();
		/**
		 * only on loaded events
		 */
		this.calendarService.loadingEvents.subscribe(isLoading => {
			if (isLoading) return;
			this.loadParticipants();
		});
		/**
		 * on dateViewChange
		 */
		this.calendarService._viewDateChanged.subscribe((date: Date) => {
			this.loadParticipants(date);
		});
	}

	loadParticipants(date?: Date): void{
		if (date){
			this.viewDate = date;
		}
		const dateIdx = moment(this.viewDate).format('DDMMYY');

		if (!this.groupedEventsByHour[dateIdx]) return;

		this.isLoadingParticipants = {};

		/**
		 * chiamata http per recuperare i partecipanti
		 */
		for (const hourSegment in this.groupedEventsByHour[dateIdx]){
			for(const eventIdx in this.groupedEventsByHour[dateIdx][hourSegment]){
				const event = this.groupedEventsByHour[dateIdx][hourSegment][eventIdx];
				const actualTripId = event.meta.actual_trip.id;
				
				this.isLoadingParticipants[actualTripId] = true;

				const route = this.appService.getBaseServerAddress() + '/calendar/event/actual_trip/' + actualTripId + '/participants';
				this.http.get(route)
				.pipe(takeUntil(this.unsubscriber))
				.subscribe({
					next: (response: any) => {
						event.meta.participants.adt = response.adt;
						event.meta.participants.chd = response.chd;
						event.meta.participants.inf = response.inf;
						event.meta.participants.snr = response.snr;
						this.groupedEventsByHour[dateIdx][hourSegment][eventIdx] = event;
						this.isLoadingParticipants[actualTripId] = false;
					},
					error: (err) => {
						this.isLoadingParticipants[actualTripId] = false;
						console.error(err);
					}
				});
			}
		}
	}

	ngOnDestroy(): void{
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}
}
