import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActionDialogComponent, IActionDialogData } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import newCustomerForm from './forms/new-customer.form';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';

async function requireCustomerData(dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService, errors?: any): Promise<any>{
	const dialogRef = datasetNavigator.openDialog<IFormDialogData>(FormDialogComponent, {
		title: 'Seleziona un listino',
		dataContainerService: dataContainerService,
		formConfig: newCustomerForm,
		formErrors: errors
	});
	const res = await dialogRef.afterClosed().toPromise();
	if(!res) return;
	return {
		unified_list_id: res.unified_list_id,
		payment_type_id: res.payment_type_id
	};
}

export default async function action(data: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): Promise<any>{
	const loadingDialogRef = datasetNavigatorService.openDialog<IActionDialogData>(ActionDialogComponent, {
		title: 'Attendere',
		contentMessage: ''
	});
	
	try{
		const result = await datasetNavigatorService.datasetService.post<any>('/dataset/domain_packages/command/set_state', data)
		.toPromise();

		if(datasetACS instanceof DatasetActionContainerService){
			datasetACS.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
		}
		loadingDialogRef.close();
		return result; // complete, succes
	}catch(e){
		loadingDialogRef.close();
		if (e.error.message === 'seleziona-listino' || e.error.errors || (e.error.action_required && e.error.action_required.code === 'new-customer-data')) {
			const customerData = await requireCustomerData(datasetACS, datasetNavigatorService, e.error.errors);
			if(!customerData) return false; // cancel the operation
			const newData = Object.assign({}, data, customerData);
			return action(newData, datasetACS, datasetNavigatorService);
		}
	}
}