import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	// indicare campi
];

export default {
	fields: detailFormFields 
};
