import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('selected_option_id', {
	key: 'selected_option_id',
	name: 'Opzione',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	appearance: 'outline',
	inputHint: 'Filtra per opzione',
	datasetCode: 'trip_options',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			let params = {};
			if (tripPackageId) params = {trip_package_id: tripPackageId};
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	appearance: 'outline',
	inputHint: '(e.g. Summer Season, Autumn 2011... )'
});

fieldsMap.set('valid_from', {
	key: 'valid_from',
	name: 'dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'outline',
});

fieldsMap.set('valid_to', {
	key: 'valid_to',
	name: 'al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'outline',
});

fieldsMap.set('special_date', {
	key: 'special_date',
	name: 'Data',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'outline',
});

fieldsMap.set('starting_time', {
	key: 'starting_time',
	name: 'Ora inizio',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	appearance: 'outline',
});

fieldsMap.set('max_allotment', {
	key: 'max_allotment',
	name: 'Max Allotment',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

export { fieldsMap };
