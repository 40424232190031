import * as tslib_1 from "tslib";
import { CdkStep } from '@angular/cdk/stepper';
import { CdkStepper } from '@angular/cdk/stepper';
var CdkStepDirective = /** @class */ (function (_super) {
    tslib_1.__extends(CdkStepDirective, _super);
    function CdkStepDirective(_stepper) {
        return _super.call(this, _stepper) || this;
    }
    return CdkStepDirective;
}(CdkStep));
export { CdkStepDirective };
