import { NgModule } from '@angular/core';

import {DefaultDatasetPageModule} from './default-page/default-page.module';

import {DatasetModule as DatasetComponentModule} from 'app/main/components/dataset/dataset.module';


@NgModule({
	imports: [
		DefaultDatasetPageModule,
		DatasetComponentModule
	],
	exports     : [
		DefaultDatasetPageModule
	]
})
export class DatasetPageModule{}
