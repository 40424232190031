<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence' [ngClass]='inputService?.formInputDefinition?.inputConfig?.customCssClass'>
		<mat-label style='pointer-events: auto;'>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<textarea cdkTextareaAutosize [readonly]='inputService.inputConfig.readOnly' matInput [placeholder]="inputService.inputConfig.placeholder | translate" [formControlName]='inputService.formControlKey'></textarea>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>