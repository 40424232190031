import * as tslib_1 from "tslib";
import { InputTypes, ValueTypes } from 'app/interfaces';
import { propertyTypeCodeToValueType } from './property-field.helper';
import { FieldHelper } from '.';
import * as moment from 'moment';
export function cloneFieldDefinition(fieldDefinition, custom) {
    if (custom === void 0) { custom = {}; }
    var newFieldDefinition = Object.assign(Object.assign({}, fieldDefinition), custom);
    if (fieldDefinition.remoteOptions && !custom['remoteOptions'])
        newFieldDefinition.remoteOptions = Object.assign({}, fieldDefinition.remoteOptions);
    if (fieldDefinition.clickOptions && !custom['clickOptions'])
        newFieldDefinition.clickOptions = Object.assign({}, fieldDefinition.clickOptions);
    if (fieldDefinition.optionSelected && !custom['optionSelected'])
        newFieldDefinition.optionSelected = Object.assign({}, fieldDefinition.optionSelected);
    if (fieldDefinition.extra && !custom['extra'])
        newFieldDefinition.extra = Object.assign({}, fieldDefinition.extra);
    if (fieldDefinition.propertyDefinition && !custom['propertyDefinition'])
        newFieldDefinition.propertyDefinition = Object.assign({}, fieldDefinition.propertyDefinition);
    return newFieldDefinition;
}
export function convertValueType(value, valueType) {
    if (valueType === ValueTypes.NUMBER) {
        if (value !== null) {
            value = Number(value);
            if (isNaN(value))
                value = 0;
        }
    }
    else if (valueType === ValueTypes.BOOLEAN) {
        if (value === true || value === 1 || value === '1' || value === 'true') {
            value = true;
        }
        else if (value === false || value === 0 || value === '0' || value === 'false') {
            value = false;
        }
        else {
            value = null;
        }
    }
    else if (valueType === ValueTypes.JSON) {
        if (value && typeof (value) === 'string')
            value = JSON.parse(value);
    }
    else if (valueType === ValueTypes.ARRAY) {
        if (typeof (value) === 'string') {
            value = value.split(',');
        }
    }
    else if (valueType === ValueTypes.DATETIME) {
        value = moment.utc(value);
    }
    else if (valueType === ValueTypes.PRETTY_JSON) {
        if (typeof (value) === 'object') {
            value = JSON.stringify(value, null, 4);
        }
    }
    return value;
}
export function getPropertyDefinition(dataContainerService, code, datasetCode) {
    var key = datasetCode + '.' + datasetCode + '_property_definitions';
    var propertyDefinitions = dataContainerService.getValueFromKeyPath(key);
    if (!propertyDefinitions) {
        // console.warn('getPropertyDefinition not found', key, code, dataContainerService.getData(), dataContainerService);
        return;
    }
    var result = propertyDefinitions.items.find(function (candidate) {
        return candidate.code === code;
    });
    if (!result) {
        console.warn('property not found', code, dataContainerService, propertyDefinitions);
        return;
    }
    return result;
}
export function getFieldValue(fieldDefinition, record, defaultValue, dataContainerService) {
    var e_1, _a;
    var value;
    if (record) {
        if (fieldDefinition.valueType === ValueTypes.PROPERTY) {
            if (record.properties && record.properties[fieldDefinition.key]) {
                value = record.properties[fieldDefinition.key].value;
            }
        }
        else {
            value = record;
            var keyParts = fieldDefinition.key.split('.');
            try {
                for (var keyParts_1 = tslib_1.__values(keyParts), keyParts_1_1 = keyParts_1.next(); !keyParts_1_1.done; keyParts_1_1 = keyParts_1.next()) {
                    var keyPart = keyParts_1_1.value;
                    if (value === null)
                        break;
                    if (value[keyPart] === undefined) {
                        value = undefined;
                        break;
                    }
                    value = value[keyPart];
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (keyParts_1_1 && !keyParts_1_1.done && (_a = keyParts_1.return)) _a.call(keyParts_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    }
    if (typeof (value) === 'undefined') {
        if (typeof (defaultValue) !== 'undefined') {
            value = defaultValue;
        }
        else if (typeof (fieldDefinition.defaultValue) === 'function') {
            value = fieldDefinition.defaultValue(dataContainerService);
        }
    }
    else if (fieldDefinition.disallowNull === true && value === null) {
        if (typeof (defaultValue) !== 'undefined' && defaultValue !== null) {
            value = defaultValue;
        }
        else if (typeof (fieldDefinition.defaultValue) === 'function') {
            value = fieldDefinition.defaultValue(dataContainerService);
        }
    }
    var valueType = fieldDefinition.valueType;
    if (fieldDefinition.propertyDefinition && valueType === ValueTypes.PROPERTY) {
        valueType = propertyTypeCodeToValueType(fieldDefinition.propertyDefinition.value_type);
    }
    value = convertValueType(value, valueType);
    return value;
}
export function getDateString(value) {
    var dt = new Date(value);
    if (value && dt && !isNaN(Number(dt))) {
        return dt.toLocaleDateString();
    }
    else {
        return '--/--/----';
    }
}
export function getDateTimeString(value) {
    var dt = new Date(value);
    if (value && dt && !isNaN(Number(dt))) {
        var time = dt.toLocaleTimeString('it-IT', {
            hour: '2-digit',
            minute: '2-digit'
        });
        var str = dt.toLocaleDateString();
        if (time !== '00:00') {
            str += ' ' + time;
        }
        return str;
    }
    else {
        return '--/--/---- --:--';
    }
}
export function getDisplayFieldValue(fieldDefinition, record, defaultValue, dataContainerService) {
    var e_2, _a, e_3, _b, e_4, _c, e_5, _d;
    var value = '';
    var propertyDefinition = null;
    var propertyData = null;
    if (record && typeof (record[fieldDefinition.key]) !== 'undefined') {
        value = record[fieldDefinition.key];
    }
    if (record && fieldDefinition.valueType === ValueTypes.PROPERTY) {
        propertyDefinition = dataContainerService && FieldHelper.getPropertyDefinition(dataContainerService, fieldDefinition.propertyCode || fieldDefinition.key, fieldDefinition.datasetCode || dataContainerService.getValueFromKeyPath('datasetCode'));
        // if(!propertyDefinition) console.warn('propertyDefinition not found');
        if (record.properties && record.properties[fieldDefinition.key]) {
            value = record.properties[fieldDefinition.key].value;
            propertyData = record.properties[fieldDefinition.key];
        }
    }
    // @deprecated optionSource
    // Check if in dataContainerService there id the list of options
    if (fieldDefinition.optionSource && dataContainerService && dataContainerService.getValueFromKeyPath(fieldDefinition.optionSource)) {
        var options = dataContainerService.getValueFromKeyPath(fieldDefinition.optionSource + '.' + fieldDefinition.optionSourceKey);
        if (options) {
            try {
                for (var options_1 = tslib_1.__values(options), options_1_1 = options_1.next(); !options_1_1.done; options_1_1 = options_1.next()) {
                    var option = options_1_1.value;
                    if (option.id === value) {
                        return option.name;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (options_1_1 && !options_1_1.done && (_a = options_1.return)) _a.call(options_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    if (typeof (fieldDefinition.getDisplayValue) === 'function' && record) {
        value = fieldDefinition.getDisplayValue(record);
    }
    else if (typeof (fieldDefinition.getDisplayValue) === 'string' && record) {
        var keyParts = fieldDefinition.getDisplayValue.split('.');
        var keyValue = record;
        try {
            for (var keyParts_2 = tslib_1.__values(keyParts), keyParts_2_1 = keyParts_2.next(); !keyParts_2_1.done; keyParts_2_1 = keyParts_2.next()) {
                var keyPart = keyParts_2_1.value;
                if (keyValue === null || keyValue[keyPart] === undefined) {
                    keyValue = undefined;
                    break;
                }
                keyValue = keyValue[keyPart];
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (keyParts_2_1 && !keyParts_2_1.done && (_b = keyParts_2.return)) _b.call(keyParts_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        if (keyValue !== undefined) {
            value = keyValue;
        }
    }
    else {
        value = getFieldValue(fieldDefinition, record, defaultValue);
    }
    // convert value
    if (typeof (fieldDefinition.printTransform) === 'function') {
        return fieldDefinition.printTransform(value);
    }
    else {
        var valueType = fieldDefinition.valueType;
        if (fieldDefinition.valueType === ValueTypes.PROPERTY && propertyDefinition) {
            valueType = propertyTypeCodeToValueType(propertyDefinition.value_type);
        }
        if (valueType === ValueTypes.DATE) {
            return getDateString(value);
        }
        else if (valueType === ValueTypes.DATETIME) {
            return getDateTimeString(value);
        }
        else if (valueType === ValueTypes.BOOLEAN) {
            if (value === true) {
                return 'Si';
            }
            else if (value === false) {
                return 'No';
            }
        }
        else if (valueType === ValueTypes.TIME) {
            if (value && value.split(':').length > 1) {
                value = value.split(':')[0] + ':' + value.split(':')[1];
            }
            return value;
        }
    }
    /*if(fieldDefinition.inputType === InputTypes.SELECT && fieldDefinition.options){
        for (const option of fieldDefinition.options) {
            if(option.value === value){
                return option.label;
            }
        }
    }*/
    if (fieldDefinition.valueType === ValueTypes.PROPERTY && propertyData) {
        if (propertyData.type === 'option_value' && propertyData.label) {
            value = propertyData.label;
        }
    }
    if ([InputTypes.MULTI_SELECT, InputTypes.SELECT, InputTypes.PROPERTY].includes(fieldDefinition.inputType)) {
        var options = fieldDefinition.options;
        if (fieldDefinition.valueType === ValueTypes.PROPERTY && propertyDefinition) {
            options = propertyDefinition.property_value_options;
        }
        if (options) {
            var label = '';
            var values = value;
            if (!(values instanceof Array))
                values = [value];
            try {
                for (var values_1 = tslib_1.__values(values), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                    var v = values_1_1.value;
                    try {
                        for (var options_2 = (e_5 = void 0, tslib_1.__values(options)), options_2_1 = options_2.next(); !options_2_1.done; options_2_1 = options_2.next()) {
                            var option = options_2_1.value;
                            if (option.value === v) {
                                if (!label)
                                    label = option.label;
                                else
                                    label += ', ' + option.label;
                            }
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (options_2_1 && !options_2_1.done && (_d = options_2.return)) _d.call(options_2);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (values_1_1 && !values_1_1.done && (_c = values_1.return)) _c.call(values_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
            if (label)
                value = label;
        }
    }
    return value;
}
export function isFieldValueSetted(fieldDefinition, record, defaultValue, dataContainerService) {
    var e_6, _a;
    var value = null;
    if (record && typeof (record[fieldDefinition.key]) !== 'undefined') {
        value = record[fieldDefinition.key];
    }
    if (record && fieldDefinition.valueType === ValueTypes.PROPERTY) {
        if (record.properties && record.properties[fieldDefinition.key]) {
            value = record.properties[fieldDefinition.key].value;
        }
    }
    if (typeof (fieldDefinition.getDisplayValue) === 'function' && record) {
        value = fieldDefinition.getDisplayValue(record);
    }
    else if (typeof (fieldDefinition.getDisplayValue) === 'string' && record) {
        var keyParts = fieldDefinition.getDisplayValue.split('.');
        var keyValue = record;
        try {
            for (var keyParts_3 = tslib_1.__values(keyParts), keyParts_3_1 = keyParts_3.next(); !keyParts_3_1.done; keyParts_3_1 = keyParts_3.next()) {
                var keyPart = keyParts_3_1.value;
                if (keyValue === null || keyValue[keyPart] === undefined) {
                    keyValue = undefined;
                    break;
                }
                keyValue = keyValue[keyPart];
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (keyParts_3_1 && !keyParts_3_1.done && (_a = keyParts_3.return)) _a.call(keyParts_3);
            }
            finally { if (e_6) throw e_6.error; }
        }
        if (keyValue !== undefined) {
            value = keyValue;
        }
    }
    else {
        value = getFieldValue(fieldDefinition, record, defaultValue);
    }
    return Boolean(value);
}
export function formGroupToFormData(formGroup) {
    var formData = new FormData();
    for (var key in formGroup.controls) {
        if (!Object.prototype.hasOwnProperty.call(formGroup.controls, key))
            continue;
        var control = formGroup.controls[key];
        if (control.value instanceof FileList) {
            for (var i = 0; i < control.value.length; i++) {
                formData.append(key + '[]', control.value[i]);
            }
        }
        else {
            formData.append(key, control.value);
        }
    }
    return formData;
}
export function currencyValuePrint(value) {
    if (typeof (value) !== 'number' && typeof (value) !== 'string')
        return '-';
    if (typeof (value) === 'string') {
        var strVal = value;
        value = Number(value);
        if (isNaN(value))
            return strVal;
    }
    return value.toLocaleString('it-IT', {
        style: 'currency',
        currency: 'EUR',
    });
}
