import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TripPackageLangContentBaseClass } from '../../lang-cont-base.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
var TripPackageSmartInterfaceMediaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageSmartInterfaceMediaComponent, _super);
    function TripPackageSmartInterfaceMediaComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, stepService) {
        var _this = 
        /**
         * init parent constructor
         */
        _super.call(this, appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, null, {
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentForeignKey: 'trip_package_id',
            parentValueKey: 'trip_packages.recordId'
        }) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        _this.stepService = stepService;
        /**
         * carousel block configs
         */
        _this.carouselBlockConfigs = {
            datasetCode: 'trip_package_language_contents_carousel_images',
            parentDatasetCode: 'trip_package_language_contents',
            parentForeignKey: 'trip_package_language_content_id'
        };
        return _this;
    }
    TripPackageSmartInterfaceMediaComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.stepService.setStepState({
            dataset_code: 'trip_packages',
            form_code: 'media',
            record_id: this.parentDatasetRS.recordId,
            status: 1
        });
    };
    return TripPackageSmartInterfaceMediaComponent;
}(TripPackageLangContentBaseClass));
export { TripPackageSmartInterfaceMediaComponent };
