import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('quantity')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
