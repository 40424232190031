import { IFieldContainer, IFieldDefinition, IFormConfig, InputTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { fieldsMap } from '../fields/entity-fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
    fieldsMap.get('original_id'),
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('phone_number'),
	fieldsMap.get('fiscalcode'),
	fieldsMap.get('piva'),
	fieldsMap.get('email'),
	fieldsMap.get('address_street'),
	fieldsMap.get('nation_code'),
    {
		key: 'scenario',
		name: 'Scenario',
		valueType: ValueTypes.STRING,
        inputType: InputTypes.HIDDEN,
        fieldView: FieldViews.HIDDEN,
        defaultValue() {
            return 'entity';
        }
	},
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;