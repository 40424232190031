import { trainTypeImages } from "../../trenitalia.service";
var TrainBreadcrumbsComponent = /** @class */ (function () {
    function TrainBreadcrumbsComponent() {
    }
    Object.defineProperty(TrainBreadcrumbsComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    return TrainBreadcrumbsComponent;
}());
export { TrainBreadcrumbsComponent };
