
import { IPaginationResponse, IListViewConfig, ListViewItemTypes, FieldViews} from 'app/interfaces';
import filterForm from './forms/filter.form';
import {DomainPackageState} from './status.enum';
import customActions from './custom-actions';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';

export default {
	itemViewType: ListViewItemTypes.TABLE,
	showSearchBar: true,
	filterForm: filterForm,
	defaultSortDirection: 'asc',
	columns: [
		{
			title: 'Dominio richiedente',
			key: 'request_domain.display_name'
		},
		{
			title: 'Cliente richiedente',
			key: 'mapped_customer_entity_description',
			routerLink(record): string{
				if(!record || !record.mapped_customer_entity) return '';
				return datasetDetailPage('entities', record.mapped_customer_entity.id);
			}
		},
		{
			title: 'Stato',
			key: 'status',
			fieldView: FieldViews.HTML,
			getDisplayHTML(record): any{
				let colorClass = 'orange-bg';
				let text = '<b>IN ATTESA</b>';

				if(record.status === DomainPackageState.ACCEPTED){ 
					colorClass = 'green-bg';
					text = '<b>ACCETTATO</b>';
				} else if (record.status === DomainPackageState.REFUSED) {
					colorClass = 'red-bg';
					text = '<b>RESPINTO</b>';
				}else if (record.status === DomainPackageState.CANCELED) {
					colorClass = 'red-bg';
					text = '<b>Annullata</b>';
				}else if (record.status === DomainPackageState.RETIRED) {
					colorClass = 'red-bg';
					text = '<b>Ritirata</b>';
				}else if(record.status > 0){
					colorClass = 'orange-bg';
					text = '<b>---</b>';
				}

				let html = '<div class="package_status" style="display:flex;align-items: center;">';
				html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
				html += text;
				html += '</div>';
				return html;
			}
		}
	],
	actions: ['detail'],
	customActions: customActions
} as IListViewConfig;
