import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import FilterFields from './filter.fields';
import { fuseAnimations } from '@fuse/animations';
import { DatasetSelectInputComponent } from 'app/main/components/form-input-viewer/inputs/dataset-select/input.component';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { DomainFilterService } from 'app/services/domain-filter.service';

@Component({
	selector     : 'navbar-vertical-style-2',
	templateUrl  : './style-2.component.html',
	styleUrls    : ['./style-2.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class NavbarVerticalStyle2Component implements OnInit, AfterViewInit, OnDestroy
{
	public filterFormGroup: FormGroup;
	public fileterFields = FilterFields;
	public domainLogoId: any;
	
	fuseConfig: any;
	navigation: any;

	// Private
	private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseNavigationService} _fuseNavigationService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {Router} _router
	 */
	constructor(
		public _appService: AppService,
		public _filterDomain: DomainFilterService,
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _router: Router,
		private formBuilder: FormBuilder,
		public sanitizer: DomSanitizer,
	)
	{
		// Set the private defaults
		this._unsubscribeAll = new Subject();

		this.filterFormGroup = this.formBuilder.group({
			domain_id: null,
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	// Directive
	@ViewChild(FusePerfectScrollbarDirective, { static: true })
	set directive(theDirective: FusePerfectScrollbarDirective)
	{
		if ( !theDirective )
		{
			return;
		}

		this._fusePerfectScrollbar = theDirective;

		// Update the scrollbar on collapsable item toggle
		this._fuseNavigationService.onItemCollapseToggled
			.pipe(
				delay(500),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				this._fusePerfectScrollbar.update();
			});

		// Scroll to the active item position
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe(() => {
					setTimeout(() => {
						const activeNavItem: any = document.querySelector('navbar .nav-link.active');

						if ( activeNavItem ){
							const activeItemOffsetTop       = activeNavItem.offsetTop,
								activeItemOffsetParentTop = !activeNavItem.offsetParent ? 0 : activeNavItem.offsetParent.offsetTop,
								scrollDistance            = activeItemOffsetTop - activeItemOffsetParentTop - (48 * 3);

							this._fusePerfectScrollbar.scrollToTop(scrollDistance);
						}
					});
				}
			);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void
	{
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
					if ( this._fuseSidebarService.getSidebar('navbar') )
					{
						this._fuseSidebarService.getSidebar('navbar').close();
					}
				}
			);

		// Get current navigation
		this._fuseNavigationService.onNavigationChanged
			.pipe(
				filter(value => value !== null),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				this.navigation = this._fuseNavigationService.getCurrentNavigation();
			});

		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.fuseConfig = config;
			});

		this._appService.appEvent
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(res => {
				if (res.name == 'open-menu') {
					this.unfoldSidebar();
				} else {
					this.foldSidebar();
				}
			});

		this._filterDomain.domainData
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(domainData => {
				if(!domainData) return;
				this.domainLogoId = domainData.logo;
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngAfterViewInit(): void {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar opened status
	 */
	toggleSidebarOpened(): void
	{
		this._fuseSidebarService.getSidebar('navbar').toggleOpen();
	}

	/**
	 * Toggle sidebar folded status
	 */
	toggleSidebarFolded(): void
	{
		this._fuseSidebarService.getSidebar('navbar').toggleFold();
	}

	unfoldSidebar(): void {
		this._fuseSidebarService.getSidebar('navbar').unfold();
	}

	foldSidebar(): void {
		this._fuseSidebarService.getSidebar('navbar').fold();
	}

	unfoldTemporarily(): void {
		this._fuseSidebarService.getSidebar('navbar').unfoldTemporarily();
	}

	isUnfolded(): boolean {
		return this._fuseSidebarService.getSidebar('navbar').unfolded;
	}
}
