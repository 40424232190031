import { IFormFilterConfig, ValueTypes, InputTypes } from 'app/interfaces';

export default {
	fields: [
		{
			name: 'Museo',
			key: 'museum_id',
			valueType: ValueTypes.STRING,
			inputConfig: {
				type: InputTypes.DATASET_SELECT,
			},
			datasetCode: 'ticketlandia_museum',
			remoteOptions: {
				getLabel(option): string{
					if(!option) return '';
					return option.name;
				},
				getValue(option): string{
					if(!option) return null;
					return option.id;
				}
			}
		}
	]
} as IFormFilterConfig;
