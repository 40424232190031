import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trainTypeImages, TrenitaliaService } from '../trenitalia.service';
import { AppService } from '../../../../../services/app.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TrenitaliaPostSaleService } from './trenitalia-post-sale.service';
import { wrapArray } from 'app/helpers';
import { LoadingDialogComponent } from "../../../../dialogs/loading-dialog/dialog.component";
import { FormDialogComponent } from "../../../../dialogs/form-dialog/dialog.component";
import { InputTypes, ValueTypes } from "../../../../../interfaces";
import { Validators } from "@angular/forms";
var TrenitaliaDetailsComponent = /** @class */ (function () {
    function TrenitaliaDetailsComponent(appService, matDialog, dataDialog, trnService, mastSnackBar, trenitaliaPostSaleService) {
        this.appService = appService;
        this.matDialog = matDialog;
        this.dataDialog = dataDialog;
        this.trnService = trnService;
        this.mastSnackBar = mastSnackBar;
        this.trenitaliaPostSaleService = trenitaliaPostSaleService;
        this.editTrls = false;
        this.load = false;
        this._unsubscribeAll = new Subject;
    }
    Object.defineProperty(TrenitaliaDetailsComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    TrenitaliaDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isCarnet = this.dataDialog.isCarnet;
        this.trenitaliaPostSaleService.setTravelData(this.dataDialog.tripBookingPackage, this.isCarnet);
        this.trenitaliaPostSaleService.travelDetails
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (travelDetails) {
            _this.updateData();
        });
    };
    TrenitaliaDetailsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TrenitaliaDetailsComponent.prototype.updateData = function () {
        this.travelSolutions = wrapArray(_.get(this.trenitaliaPostSaleService.travelDetails.value, 'travel.travelSolutions'));
        this.postActions = _.get(this.trenitaliaPostSaleService.travelDetails.value, 'travel.postActions');
    };
    TrenitaliaDetailsComponent.prototype.sendMail = function () {
        var _this = this;
        var destinationEmail = _.get(this.dataDialog.tripBookingPackage, 'tour_operator_booking_data.complete_order_response.travel.travelContact.email');
        if (!destinationEmail && this.trenitaliaPostSaleService.isCarnet) {
            destinationEmail = _.get(this.dataDialog.tripBookingPackage, 'tour_operator_booking_data.complete_order_response.travel.travellers.email');
        }
        var formConfig = {
            formStyle: '',
            fields: [
                {
                    key: 'send_mail',
                    name: 'Invia mail a',
                    valueType: ValueTypes.STRING,
                    formValidators: [Validators.required, Validators.email],
                    inputConfig: {
                        appearence: 'legacy',
                        type: InputTypes.EMAIL,
                        clearable: true,
                        placeholder: 'example@mail.com',
                        required: true
                    }
                },
                {
                    key: 'sub_mail',
                    name: 'usa l\'email sostitutiva',
                    titleTooltip: 'Invia una email sostitutiva a quella inviata da Tranitalia',
                    inputType: InputTypes.CHECKBOX,
                    valueType: ValueTypes.BOOLEAN,
                    appearance: 'legacy'
                }
            ]
        };
        this.matDialog.open(FormDialogComponent, {
            data: {
                title: 'Invia biglietti via mail',
                formData: {
                    send_mail: destinationEmail
                },
                formConfig: formConfig,
                positiveText: 'Invia'
            }
        }).afterClosed()
            .subscribe(function (response) {
            if (!response)
                return;
            var loadingDialogRef2 = _this.matDialog.open(LoadingDialogComponent, {
                data: { title: 'Invio in corso' }
            });
            var body = { trip_booking_package_id: _this.dataDialog.tripBookingPackage.id, send_replacement_email: response.sub_mail };
            if (destinationEmail !== response.send_mail) {
                body['optional_email'] = response.send_mail;
            }
            _this.trenitaliaPostSaleService.sendEmail(body)
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe({
                next: function () {
                    _this.appService.showSuccessMessage('Email inviata con successo');
                },
                error: function () {
                    loadingDialogRef2.close();
                },
                complete: function () {
                    loadingDialogRef2.close();
                }
            });
        });
    };
    return TrenitaliaDetailsComponent;
}());
export { TrenitaliaDetailsComponent };
