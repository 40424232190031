import { Component, OnInit } from '@angular/core';
import { IListViewConfig } from 'app/interfaces';
import { PublicDomainsConfig } from 'app/configs/datasets/public_domains/index';
import { PublicTripPackageConfig } from 'app/configs/datasets/public_trip_packages/index';
import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';

export interface Domain {
	id: any;
	display_name: string;
	domain_path: string;
	logo: any;
}

@Component({
	selector   : 'marketplace-page',
	templateUrl: './marketplace.component.html',
	styleUrls  : ['./marketplace.component.scss'],
	providers: [PageClosureContainerService]
})
export class MarketplaceComponent implements OnInit {

	public domain: Domain;

	protected domainViewConfig: IListViewConfig;
	protected packageViewConfig: IListViewConfig;

	constructor(
		protected appService: AppService,
		protected domainFilterService: DomainFilterService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected snackBar: MatSnackBar,
	) {}

	handleDomainChanges(): boolean{
		if (this.domain !== null) {
			this.goBack();
		}
		return true;
	}

	onSelected(record: Domain): void{
		this.domain = record;
	}

	goBack(): void {
		this.domain = null;
	}

	onPackageSelected(record: any): void {
		this.action(record).then((resp) => {
			if (!resp.success) {
				this.appService.showErrorMessage(resp.messagge);
			} else {
				this.appService.showSuccessMessage(resp.messagge);
			}
		})
		.catch(e => {
			console.error(e);
		});
	}

	async action(record: any): Promise<any>{
		const loadingDialogRef: MatDialogRef<ActionDialogComponent, any> = this.datasetNavigatorService.openDialog(ActionDialogComponent, {
			title: 'Recupero dati in corso',
		});
	
		try{
			const response = await this.datasetNavigatorService.datasetService
			.post<any>('/dataset/domain_packages/command/set_packages', {
				trip_package_id: record.id,
			}).toPromise();
	
			if(response){
				loadingDialogRef.componentInstance.loading = false;
				if (response.success){
					loadingDialogRef.componentInstance.resultStatus = 'success';
					loadingDialogRef.componentInstance.setTitle('Richiesta inviata');
					loadingDialogRef.componentInstance.setMessage('Puoi vedere lo stato delle tue richieste nella sezione apposita che puoi trovare nel menu laterale.');
				}else{
					loadingDialogRef.componentInstance.resultStatus = 'error';
					loadingDialogRef.componentInstance.setTitle('Errore');
					loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
				}
			}
			return response;
		}catch(e){
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore');
			loadingDialogRef.componentInstance.setMessage('Errore imprevisto');
		}
	}

	ngOnInit(): void {
		this.domainViewConfig = PublicDomainsConfig.defaultActions.list.viewConfig;
		this.packageViewConfig = PublicTripPackageConfig.defaultActions.list.viewConfig;
	}
}
