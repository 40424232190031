import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { FormViewerService } from "app/services/form-viewer.service";
import { fieldsMap as filterFields } from './filter.fields';
import { DatasetListComponent } from "app/main/components/dataset/base-actions/list/dataset-list.component";
import { convertToUTCDay } from "app/helpers/date.helper";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { FieldViews, ValueTypes } from "app/interfaces";
import { TripBookingPackagesStepService } from "../../trip-booking-packages-step.service";
import { ActionDialogComponent } from "../../../action-dialog/action-dialog.component";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { config as productSelectionViewConfig } from 'app/configs/datasets/products/selectionViewConfig';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../../edit-booking.component";
import { AppService } from "app/services/app.service";
var MilanoCardSelectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MilanoCardSelectionComponent, _super);
    function MilanoCardSelectionComponent(formViewerService, datasetRS, datasetACS, tripBookingPackagesStepService, datasetNavigator, appService) {
        var _this = _super.call(this) || this;
        _this.formViewerService = formViewerService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.datasetNavigator = datasetNavigator;
        _this.appService = appService;
        _this.filterFields = filterFields;
        _this.filters = {
            target: "ticket",
            sortBy: "start_day|start_time",
            start_day: JSON.stringify(['>=', convertToUTCDay().toISOString()]),
            only_bookable: 1
        };
        return _this;
    }
    Object.defineProperty(MilanoCardSelectionComponent.prototype, "productSelectionViewConfig", {
        get: function () {
            return Object.assign({}, productSelectionViewConfig.viewConfig, {
                showSearchBar: true,
                columns: [
                    {
                        title: 'Tipo',
                        key: 'product_type',
                        fieldDefinition: {
                            key: 'product_type',
                            name: 'Tipo',
                            valueType: ValueTypes.STRING
                        },
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value) {
                            if (!value || value == 'standard')
                                return 'Standard';
                            if (value == 'travelmate')
                                return 'Audioguida';
                            if (value == 'esim-manet')
                                return 'ESim';
                            return value;
                        }
                    },
                    {
                        title: 'Descrizione',
                        key: 'description'
                    },
                    {
                        title: 'Stato',
                        key: 'code',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value, translateService) {
                            var colorClass = 'orange-bg';
                            var text = '<span class="status">' + translateService.instant('Non Pubblico') + '</span>';
                            if (record.publish_on_web === 1) {
                                colorClass = 'green-bg';
                                text = '<span class="status">' + translateService.instant('Pubblicato') + '</span>';
                            }
                            var html = '<div class="package_status" style="display:flex;align-items: center; justify-content: space-between;">';
                            html += '<div>' + text + '<br\><span>' + record.code + '</span></div>';
                            html += '<span class="' + colorClass + ' record-status-bar" style="width:12px; height:12px; border-radius:50%;"></span>';
                            html += '</div>';
                            return html;
                        },
                    },
                ],
            });
        },
        enumerable: true,
        configurable: true
    });
    MilanoCardSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formViewerService.init({
            fields: Array.from(filterFields.values())
        });
        this.formViewerService.formGroup.valueChanges
            .subscribe(function (filterFields) {
            _this.filters = Object.assign({}, _this.filters, {});
        });
    };
    MilanoCardSelectionComponent.prototype.addTripBookingPackage = function (tripPackage) {
        var _this = this;
        var dialogRef = this.datasetNavigator.openDialog(ActionDialogComponent, {
            title: 'Attendere',
            contentMessage: ''
        });
        var postData = {
            target: "milano_card",
            trip_booking_id: this.datasetRS.recordId,
            trip_package_id: tripPackage.id
        };
        var actionEndPoint = '/dataset/trip_booking_packages/create';
        this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
            .subscribe({
            next: function () {
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGES
                });
            },
            complete: function () {
                dialogRef.close();
            },
            error: function (errorResponse) {
                dialogRef.componentInstance.resultStatus = 'error';
                dialogRef.componentInstance.loading = false;
                dialogRef.componentInstance.setMessage('Si è verificato un errore');
                dialogRef.componentInstance.setTitle(null);
            }
        });
    };
    return MilanoCardSelectionComponent;
}(DestroyableComponent));
export { MilanoCardSelectionComponent };
