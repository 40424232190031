<div class='full-form'>
	<div class="section title">

		<div class="instruction">
			<p class="instruction">
				Elenca i prodotti che si vogliono associare a questo bundle
			</p>
		</div>
	</div>

	<custom-mat-table
		[relation]='"childrenResources"'
		[displayedColumns]='displayedColumns'
		[filterFormGroup]='filterFormGroup'
		[field]='field'
		[datasetACS]='datasetACS'
		[datasetRS]='datasetRS'
		[dynamCols]='dynamCols'
		[stepName]='"product-bundle"'
		[datasetCode]='"products"'
		[datasetConfig]='datasetConfig'
		[formConfigMap]='formConfig'
		[selectedIndex]='9'
		[stepper]='stepper'
		(nextStep)='goNextStep()'
		[initialValueKey]='"description"'
	></custom-mat-table>
</div>