import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('id', {
	key: 'id',
	name: 'Client Id',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('secret', {
	key: 'secret',
	name: 'Client Secret',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputRequired: true
});

fieldsMap.set('redirect', {
	key: 'redirect',
	name: 'Redirect',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('personal_access_client', {
	key: 'personal_access_client',
	name: 'Personal Access Client',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'True',
			value: 1
		},
		{
			label: 'False',
			value: 0
		}
	]
});

fieldsMap.set('password_client', {
	key: 'password_client',
	name: 'Password Client',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'True',
			value: 1
		},
		{
			label: 'False',
			value: 0
		}
	]
});

fieldsMap.set('revoked', {
	key: 'revoked',
	name: 'Disabilita',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Abilitato',
			value: 0
		},
		{
			label: 'Disabilitato',
			value: 1
		}
	]
});

fieldsMap.set('user_id', {
	key: 'user_id',
	name: 'Utente',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'users',
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.access_id + ') ' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('domain_id', {
	key: 'domain_id',
	name: 'Dominio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domains',
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

export {fieldsMap};
