import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from '../../../../../components/dataset/services/dataset-action-container.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { TrenitaliaService } from '../../trenitalia.service';
import * as _ from 'lodash';
import * as moment from 'moment';
var TravellersCarnetComponent = /** @class */ (function () {
    function TravellersCarnetComponent(datasetACS, formViewerService, trnService) {
        this.datasetACS = datasetACS;
        this.formViewerService = formViewerService;
        this.trnService = trnService;
        this.formFields = formFields;
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values())
        });
    }
    Object.defineProperty(TravellersCarnetComponent.prototype, "requestInvoice", {
        get: function () {
            var request_invoice = this.formViewerService.formGroup.get('request_invoice');
            return request_invoice ? request_invoice.value : false;
        },
        enumerable: true,
        configurable: true
    });
    TravellersCarnetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkoutData = this.trnService.checkoutData.getValue();
        if (this.checkoutData.user) {
            var email = _.get(this.checkoutData.user, 'customers.contacts', []).find(function (user) { return user.contactType === 'EMAIL'; });
            var phone = _.get(this.checkoutData.user, 'customers.contacts', []).find(function (user) { return user.contactType === 'MOBILE'; });
            this.formViewerService.formGroup.get('first_name').setValue(_.get(this.checkoutData, 'user.name'));
            this.formViewerService.formGroup.get('last_name').setValue(_.get(this.checkoutData, 'user.surname'));
            this.formViewerService.formGroup.get('email').setValue(_.get(email, 'contactValue'));
            this.formViewerService.formGroup.get('phone').setValue(_.get(phone, 'contactValue'));
            this.formViewerService.formGroup.get('cartafreccia').setValue(_.get(this.checkoutData.user, 'cartafreccia'));
            this.formViewerService.formGroup.get('data_n').setValue(moment(_.get(this.checkoutData.user, 'customers.birthDate'), 'DD/MM/YYYY'));
            this.checkoutData.finalTravellers = this.formViewerService.getFormData();
            this.trnService.checkoutData.next(this.checkoutData);
        }
        this.formViewerService.formGroup.valueChanges.subscribe(function (s) {
            if (_this.formViewerService.formGroup.valid) {
                _this.checkoutData.finalTravellers = _this.formViewerService.getFormData();
                _this.checkoutData.invoiceRequested = s.request_invoice;
                /* this.trnService.checkoutData.next(this.checkoutData);*/
            }
        });
    };
    return TravellersCarnetComponent;
}());
export { TravellersCarnetComponent };
