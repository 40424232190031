import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	//fieldsMap.get('description'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('mac_causal'),
			fieldsMap.get('mac_category'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('active_status'),
			fieldsMap.get('publish_on_web'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('priority_order'),
			fieldsMap.get('taxation'),
			fieldsMap.get('disable_document_rt_transmission')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('supplier_id'),
			fieldsMap.get('active_commission_percent'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('commessa_id'),
			fieldsMap.get('duration_standard_in_hours'),
		]
	},
	fieldsMap.get('note_booking'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('child_from'),
			fieldsMap.get('child_to'),
			fieldsMap.get('adult_to')
		]
	}
];

const config: IFormConfig = { 
	title: 'Crea Pacchetto',
	editTitle: 'Modifica Pacchetto',
	fields: formFields 
};

export default config;
