import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';

@Component({
	selector   : 'text-list-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class TextListInputComponent extends DestroyableComponent implements OnInit, OnChanges{

	public list = [];

	constructor(
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.list = this.inputService.control.value;
		});
	}

	addNewItem(): void{
		if(this.list == null){
			this.list = [];
			this.inputService.control.setValue(this.list);
		}
		this.list.push('');
		this.inputService.formGroup.updateValueAndValidity();
	}

	updateValue(i, event): void{
		this.list[i] = event.target.value;
	}

	removeItem(i): void{
		this.list.splice(i, 1);
	}

	trackByFn(index, item): any{
		return index;
	}

	ngOnChanges(changes: SimpleChanges): void{
		if (this.inputService.control) {
			this.list = this.inputService.control.value;
		}
	}
}
