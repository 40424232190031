import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { DatasetService } from '../../services/dataset.service';
import { IDataActualTripParticipantList } from 'app/interfaces/participant-list-email.interface';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { ILoadingDialogData, LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';

@Component({
	selector: 'actual-trip-participants-list-dialog',
	templateUrl: './participant-list-dialog.component.html',
	styleUrls: ['./participant-list-dialog.component.scss']
})

export class ActualTripParticipantsListComponent implements OnInit, OnDestroy {

	_unsubscribeAll: Subject<any>;

	datasetACS: DatasetActionContainerService;
	datasetRS: DatasetRecordService;

	loading = true;
	contentErrorMessage: string;
	switchTemplate = 'participantsListTemplate';

	participantsList: any;
	bookings: any;

	constructor(
		@Inject(MAT_DIALOG_DATA) private injectedData: IDataActualTripParticipantList, 
		private dialogRef: MatDialogRef<ActualTripParticipantsListComponent>,
		private datasetService: DatasetService,
		public datasetNavigator: DatasetNavigatorService) {
			this._unsubscribeAll = new Subject<any>();
		}

	ngOnInit() {
		if (this.injectedData){
			this.datasetACS = this.injectedData.datasetACS;
			this.datasetRS = this.injectedData.datasetRS;

			this.getParticipantList();
		}
	}

	getParticipantList(): void{
		const data = {actual_trip_id: this.datasetRS.recordId};
		/**
		 * make http call to command_load_participants
		 */
		this.datasetService.post('/dataset/actual_trips/command/load_participants', data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: list => {
				this.loading = false;
				this.participantsList = list;
			},
			error: exception => {
				this.loading = false;
				this.contentErrorMessage = 'Errore durante il caricamento dei dati';
				console.error(exception);
			}
		});
	}

	getBookingList(): void{
		const data = {actual_trip_id: this.datasetRS.recordId};
		/**
		 * make http call to command_load_booking_participants
		 */
		this.datasetService.post('/dataset/actual_trips/command/load_booking_participants', data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: list => {
				this.loading = false;
				this.bookings = list;
			},
			error: exception => {
				this.loading = false;
				this.contentErrorMessage = 'Errore durante il caricamento dei dati';
				console.error(exception);
			}
		});
	}

	switchTpl(): void{
		this.loading = true;

		switch(this.switchTemplate){
			case 'participantsListTemplate':
				this.switchTemplate = 'bookingGroupedParticipantsTemplate';
				this.getBookingList();
				break;
			case 'bookingGroupedParticipantsTemplate':
				this.switchTemplate = 'participantsListTemplate';
				this.getParticipantList();
				break;
			default:
				throw new Error('Something went wrong');
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}