<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>Dettagli {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetACS.loading.value'></mat-progress-bar>

	<div *ngIf='!datasetACS.loading.value && datasetACS.datasetData.record' class='mb-24'>
		<dataset-detail
			[showActions]='false'
			[showTitle]='false'
			[datasetCode]='datasetACS.datasetCode'
			[inputRecord]='datasetACS.datasetData.record'
		></dataset-detail>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
	<button class='ml-4' mat-flat-button *ngIf='!loading && datasetACS.canEdit(datasetACS.datasetData.record) && datasetACS.actionEnabled("edit")' color='accent' (click)="onEditClick()">{{'Modifica' | translate}}</button>
</mat-dialog-actions>
