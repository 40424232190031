import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ATripBookingPackageRelatedComponent } from '../abstract-trip-booking-package-related-data.component';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
var TripBookingParticipantComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingParticipantComponent, _super);
    function TripBookingParticipantComponent(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, datasetNavigatorService, bookingService, matDialog, matDialogRef, data) {
        var _this = _super.call(this, appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.parentDatasetRS = parentDatasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.bookingService = bookingService;
        _this.matDialog = matDialog;
        _this.matDialogRef = matDialogRef;
        _this.data = data;
        return _this;
    }
    TripBookingParticipantComponent.prototype.onEdit = function () {
        var _this = this;
        this.datasetNavigatorService
            .openEditDialog(this.datasetACS, this.datasetRS.record.value)
            .afterClosed().subscribe(function (response) {
            _this.datasetRS.reloadWithParams({
                trip_booking_participant_id: _this.datasetRS.record.value.participant_id,
                trip_booking_package_id: _this.data.tripBookingPackage.id
            });
        });
    };
    TripBookingParticipantComponent.prototype.trash = function () {
        var _this = this;
        this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.datasetRS.record.value)
            .beforeClosed()
            .subscribe(function () {
            _this.datasetRS.record.next(null);
        });
    };
    return TripBookingParticipantComponent;
}(ATripBookingPackageRelatedComponent));
export { TripBookingParticipantComponent };
