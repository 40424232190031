export const locale = {
	lang: 'en',
	data: {
		'Utente B2B': 'B2B User',
		'Id utente': 'User Id',
		'Creato il': 'Created at',
		'Tutti i pacchetti sono disabilitati per questo cliente': 'All packages are disabled for this client',
		'Tutti i pacchetti sono abilitati per questo cliente': 'All packages are enabled for this client',
		'Abilitare questa anagrafica come cliente?': 'Enable this registry as a customer?',
		'Questa anagrafica è abilitata come tour operator': 'This registry is enabled as a tour operator',
		'Abilitare questa anagrafica come tour operator?': 'Enable this registry as a tour operator?',
		'Abilitare questa anagrafica come fornitore?': 'Enable this registry as a supplier?',
		'Abilitare questa anagrafica come staff?': 'Enable this registry as a staff?',
		'Si': 'Yes'
	}
};
