import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { TrenitaliaService } from '../../trenitalia.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {Validators} from "@angular/forms";

const fieldKeyMap = {
	pass_first_name: 'name',
	pass_last_name: 'surname',
	pass_email: 'email',
	pass_data_n: 'birthDate',
	pass_phone: 'phoneNumber',
	pass_cf: 'cartaFreccia',
	contact_consent: 'contact_consent'
};
const travellerKeyMap = _.invert(fieldKeyMap); 

export function travellerKeyToFieldKey(travellerKey: string): string{
	return _.get(travellerKeyMap, travellerKey, travellerKey);
}

export function fieldKeyToTravellerKey(fieldKey: string): string{
	return _.get(fieldKeyMap, fieldKey, fieldKey);
}

@Component({
	selector: 'trenitalia-passenger-form',
	templateUrl: './passenger-form.component.html',
	styleUrls: ['./passenger-form.component.scss'],
	providers: [FormViewerService]
})
export class PassengerFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input('index') index: number;
	@Input('contactData') contactData: BehaviorSubject<any>;
	@Input('passenger') passenger: any;
	@Input('copyContactData') copyContactData: boolean = false;
	public formViewer: FormViewerComponent;
	public fields = formFields;
	@Output('validChild') valid = new EventEmitter();
	public isReadOnly = false;
	private invalidTravellerParameters;

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		private _trnService: TrenitaliaService,
		public formViewerService: FormViewerService,
		public tranitaliaservice: TrenitaliaService
	) {
		this._unsubscribeAll = new Subject<any>();
	}

	ngOnInit() {
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
            console.log(this.passenger);
			if(!_.isEmpty(this.passenger)){
				this.formViewerService.setValues({
					pass_first_name: _.get(this.passenger, 'dataPass.pass_first_name', ''),
					pass_last_name: _.get(this.passenger, 'dataPass.pass_last_name', ''),
					pass_email: _.get(this.passenger, 'dataPass.pass_email', ''),
					pass_data_n: _.get(this.passenger, 'dataPass.pass_data_n', null),
					pass_phone: _.get(this.passenger, 'dataPass.pass_phone', null),
					pass_cf: _.get(this.passenger, 'dataPass.pass_cf', null),
					contact_consent: _.get(this.passenger, 'dataPass.contact_consent', true)
				});
			}
			this.setContactData();
		});

		this.tranitaliaservice.checkoutData
		.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged(_.isEqual))
		.subscribe(checkoutData => {
			if(!_.get(this.passenger, 'travellerXmlId')){
				console.warn('no travellerXmlId setted', this.passenger);
				return;
			}
			const errors = _.get(checkoutData, 'invalid_traveller_parameters.'+this.passenger.travellerXmlId);
			const changed = !_.isEqual(this.invalidTravellerParameters, errors);
			this.invalidTravellerParameters = errors;
			if(!errors){
				if(changed) {
					this.formViewerService.setErrors(null);
					this.formViewerService.formGroup.updateValueAndValidity(/*{emitEvent: false}*/);
				}
				return;
			}
			const errorMessages = Object.entries(errors).reduce((result, [key, paramData]) => {
				if(!_.isEmpty(_.get(paramData, 'validationMessages'))) result[travellerKeyToFieldKey(key)] = _.get(paramData, 'validationMessages');
				return result;
			}, {});
			this.formViewerService.setErrors(errorMessages);
		});

		this.formViewerService.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(v => {
            if(this.passenger.type==='CHILDREN') {
                if(!this.formViewerService.formGroup.get('pass_data_n').value){
                    const errorData={'pass_data_n':['Data obbligatoria']};
                    this.formViewerService.setErrors(errorData);
                }else{
                    /*Controllo età*/
                    /*const date = this.formViewerService.formGroup.get('pass_data_n').value;
                    const diff = moment().diff(date,'years');
                    if(diff > 15 || diff < 6){
                        const errorData={'pass_data_n':['Data non valida']};
                        this.formViewerService.setErrors(errorData);
                    }*/
                }
            }
			this.sendData();
		});

		if(this.index == 0 && this.contactData){
			this.contactData
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(s => {
				if(!this.copyContactData) return;
				if (!_.isEmpty(s)) {
					this.setContactData();
				} else {
					this.formViewerService.formGroup.reset();
				}
			});
		}
	}

	disableInput(key: string, disable = true): void{
		const control = this.formViewerService.getFieldControl(key);
		if(!control) return;
		if(disable) control.disable();
		else control.enable();
	}

	setContactData(){
		this.isReadOnly = this.index == 0 && this.copyContactData;
		if(this.index != 0 || !this.copyContactData) return;
		this.formViewerService.setValues({
			pass_first_name: this.contactData.value.first_name,
			pass_last_name: this.contactData.value.last_name,
			pass_email: this.contactData.value.email,
			pass_phone: this.contactData.value.phone
		});
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.copyContactData){
			this.setContactData();
		}
	}

	sendData() {
		console.log('sendData', this.index, this.formViewerService.formGroup.valid, this.formViewerService.getFormErrors());
		this.valid.emit({
			index: this.index,
			valid: this.formViewerService.formGroup.valid,
			data: this.formViewerService.getFormData()
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
