<ng-template #trainBradcrumbs let-train="train" let-last="last">
	<ng-container *ngIf="train.type == 'multi' && train.subSegments">
		<ng-container
			*ngFor="let t of train.subSegments;let last = last;"
			[ngTemplateOutlet]='trainBradcrumbs' 
			[ngTemplateOutletContext]='{last:last, train:t}'
		></ng-container>
	</ng-container>
	<ng-container *ngIf="train.type != 'multi'">
		<img *ngIf="trainTypeImages[train.type]" [matTooltip]="train.type" [src]="trainTypeImages[train.type]" class="train-icon"/>
		<small class="train-name"> {{train.name}}</small>
	</ng-container>
	<span *ngIf="!last"> / </span>
</ng-template>

<div class="train-breacrumbs">
	<ng-container
		*ngFor="let t of trains;let last = last;"
		[ngTemplateOutlet]='trainBradcrumbs' 
		[ngTemplateOutletContext]='{last:last, train:t}'
	></ng-container>
</div>