import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import {AuthService} from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { SaleOperationConfig } from 'app/configs/datasets/sale_operations';

import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { IFormConfig } from 'app/interfaces';

@Component({
	selector: 'pratica-data',
	templateUrl: './pratica-data.component.html',
	styleUrls: ['./pratica-data.component.scss']
})
export class PraticaDataComponent extends BaseViewComponent implements OnInit {

	@Input() public record: any;
	@Input() public index: any;
	@Input() public rowDb: any;
	@Input() public datACS: IDataContainerService;
	@Input() public viewFormConfig: any;
	@Input() public showActions = true;

	public formConfig: IFormConfig;

	canEdit = false;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService
	){
		super(datasetACS, datasetRS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.formConfig = SaleOperationConfig.formConfigsMap[this.viewFormConfig];
		this.computeCanEdit();
	}

	computeCanEdit(): void {
		this.canEdit = this.datasetACS.canEdit(this.rowDb);
	}

	setOriginalId(): void {
		if (Object.prototype.hasOwnProperty.call(this.record, 'id')) {
			this.record.original_id = this.record.id;
		}
	}

	onEdit(): void {
		this.computeCanEdit();
		this.setOriginalId();
		this.record.id = this.rowDb.id;
		this.record.index = this.index;
		this.datasetNavigatorService.openEditDialog(this.datasetACS, this.record, {formConfig: this.formConfig});
	}
}
