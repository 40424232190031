<div class="trip_package" fxLayout="column" *ngIf="dataView">
    <div class="label">{{'Treno' | translate}} <span *ngIf="dataView.returnTravelSolution">(A/R)</span></div>
    <div fxLayout="column">
        <div
            class="value">{{ inputTripBookingPackage.external_solution_data.travel.travelSolutions[0].start.stationName }}
            <mat-icon style="vertical-align: middle">chevron_right</mat-icon>
            {{ dataView.end.stationName }}
        </div>
        <span class='h4 secondary-text' *ngIf="isCarnet">
					<span style="color: darkred"> CARNET VIAGGI </span>
				</span>
        <div *ngIf="!inputTripBookingPackage.target_info.orderType || !isCarnet">
            {{ dataView.departure | date: 'dd/MM/yyyy HH:mm'}}
            -
            {{ dataView.arrival | date: 'HH:mm' }}
        </div>
        <div *ngIf="status==='canceled'">
            <strong>
                <span style="color:red"> CANCELLATO </span>
            </strong>
        </div>
        <div *ngIf="status==='refunded'">
            <strong>
                <span style="color:red"> RIMBORSATO </span>
            </strong>
        </div>
    </div>
    <div fxLayout="column" *ngIf="dataView.returnTravelSolution">
        <br>
        <div
            class="value">{{ dataView.returnTravelSolution.start.stationName }}
            <mat-icon style="vertical-align: middle">chevron_right</mat-icon>
            {{ dataView.returnTravelSolution.end.stationName }}
        </div>

        <span class='h4 secondary-text' *ngIf="isCarnet">
					<span style="color: darkred"> CARNET VIAGGI </span>
				</span>
        <div *ngIf="!inputTripBookingPackage.target_info.orderType || !isCarnet">
            {{ dataView.returnTravelSolution.departure | date: 'dd/MM/yyyy HH:mm'}}
            -
            {{ dataView.returnTravelSolution.arrival | date: 'HH:mm' }}
        </div>

        <div *ngIf="status==='canceled'">
            <strong>
                <span style="color:red"> CANCELLATO </span>
            </strong>
        </div>
        <div *ngIf="status==='refunded'">
            <strong>
                <span style="color:red"> RIMBORSATO </span>
            </strong>
        </div>

    </div>
    <div class="value price"
         [ngClass]="{'del': status=='canceled' || status=='refunded'}"
         matTooltip="{{totalFee>0?'Prezzo:'+(totalRowLordo-totalFee| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(totalFee | currency:'EUR':'symbol':'1.2-2'):''}}">
        {{totalRowLordo | currency:'EUR' }}
    </div>
    <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
          *ngIf="status=='confirmed'">
								<i class="material-icons-outlined">download</i>
								<a class="download-ticket"
                                   [href]='appService.getBaseServerAddressRoot() + "/download-ticket/by/id/" + inputTripBookingPackage.id'
                                   target="_blank">{{'Scarica Biglietti' | translate}}</a>
							</span>
</div>
