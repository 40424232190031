import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
var DateInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DateInputComponent, _super);
    function DateInputComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.manualInput = _.get(_this.inputService.formInputDefinition, 'extra.manualInput', false);
        return _this;
    }
    DateInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.dateFilterBinded = _this.dateFilter.bind(_this);
        });
    };
    DateInputComponent.prototype.dateFilter = function (d) {
        if (this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter) {
            return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
        }
        return true;
    };
    return DateInputComponent;
}(DestroyableComponent));
export { DateInputComponent };
