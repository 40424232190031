import { Component, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'text-list-input2',
	templateUrl: './input.component.html',
})
export class TextListInput2Component extends DestroyableComponent implements OnInit, OnChanges{

	public list = [];

	constructor(
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.list = this.inputService.control.value;
		});
	}


	addNewItem(): void{
		if(this.list == null){
			this.list = [];
			this.inputService.control.setValue(this.list);
		}
		this.list.push(['', '']);
		this.inputService.formGroup.updateValueAndValidity();
	}

	updateValue(i, event): void{
		this.list[i] = event.target.value;
	}

	removeItem(i): void{
		this.list.splice(i, 1);
	}

	trackByFn(index): any{
		return index;
	}

	ngOnChanges(changes: SimpleChanges): void{
		this.list = this.inputService.control.value;
	}
}
