import { showIfEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';
import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { fieldsMap as entityFieldMap } from '../entities/fields';

export function showIfKiaraCloud(record: any, dataActionContainer: IDataContainerService): boolean{
	const modules = dataActionContainer.getValueFromKeyPath('domainModules');
	if(modules && modules.kiara_cloud_api){
		const kiaraCloudApi = modules.kiara_cloud_api;
		return kiaraCloudApi.enabled; 
	}
	return false;
}

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('list_description', {
	key: 'description',
	name: 'Descrizione',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record){
			if(record.entity && record.entity.description){
				html += record.entity.description;
			}
			if(record.branch){
				html += '<br> Filiale: ' + record.branch
			}
			if(record.entity && record.entity.phone_number){
				html += '<br> Telefono: ' + record.entity.phone_number
			}
			if(record.entity && record.entity.primary_email){
				html += '<br> Email: ' + record.entity.primary_email
			}
		}
		return html;
	}
})

fieldsMap.set('payment_type_id', {
	key: 'payment_type_id',
	name: 'Tipo di pagamento',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Seleziona il tipo di pagamento',
	datasetCode: 'payment_types',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.name;
		}
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('discount_rule_list_id', {
	key: 'discount_rule_list_id',
	name: 'Listino Sconti',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Seleziona un listino predefinito',
	datasetCode: 'discount_rule_lists',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	},
	clickOptions: {
		openDetailDatasetCode: 'discount_rule_lists',
		getDetailRecordId(record): string{
			return record.discount_rule_list_id;
		}
	}
});

fieldsMap.set('billable', {
	key: 'billable',
	name: 'Fatturabile',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Invio corrispettivi a server RT',
	options: [
		{
			label: "Disattivato",
			value: 0
		},
		{
			label: "Attivo",
			value: 1
		}
	],
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return showIfKiaraCloud(record, dataContainerService);
	}
});

fieldsMap.set('send_sms', {
	key: 'send_sms',
	name: 'Invio SMS',
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'info',
	titleTooltip: 'Tutte le prenotazioni di questo cliente invieranno in modo automatico un SMS con il voucher all\'intestatario della prenotazione',
	inputConfig: {
		type: InputTypes.CHECKBOX,
		customCssClass: 'sms-customer',
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const twilio = dataContainerService.getValueFromKeyPath('domainModules');
		if(twilio && twilio.sms && twilio.sms.enabled){
			return twilio.sms.enabled; 
		}
		return false;
	}
});

fieldsMap.set('typology', {
	key: 'typology',
	name: 'Tipologia',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.PROPERTY
});

fieldsMap.set('tour_cms_ref', {
	key: 'tour_cms_ref',
	name: 'Codice TourCMS',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.PROPERTY
});

fieldsMap.set('bokun_ref', {
	key: 'bokun_ref',
	name: 'Codice Bokun',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.PROPERTY,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const bokunEnabled = dataContainerService.getValueFromKeyPath('domainModules.bokun.enabled');
		return bokunEnabled;
		},
});

fieldsMap.set('pec', {
	key: 'pec',
	name: 'Pec',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	exportEAgency(): boolean{
		return true;
	}
});

fieldsMap.set('sdi', {
	key: 'sdi',
	name: 'SDI',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	exportEAgency(): boolean{
		return true;
	}
});

fieldsMap.set('customer_notes', {
	key: 'customer_notes',
	name: 'Note Cliente',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	exportEAgency(): boolean{
		return true;
	}
});

fieldsMap.set('branch', {
	key: 'branch',
	name: 'Filiale',
	inputConfig: {
		type: InputTypes.TEXT,
		hint: "Solo 2 caratteri"
	},
	valueType: ValueTypes.STRING,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	},
	exportEAgency(): boolean{
		return true;
	}
});

fieldsMap.set('sub_account_id', {
	key: 'sub_account_id',
	name: 'Piano Conti',
	valueType: ValueTypes.REFERENCE,
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: false
	},
	datasetCode: 'account_plans',
	remoteOptions: {
		limit: 100,
		getParams(): any{
			return {type: 'C'};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.code + ' - ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.code + ' - ' + option.description;
		}
	},
	exportEAgency(): boolean{
		return true;
	}
});

entityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	if(key === 'code'){
		value.exportEAgency = function(record, dataContainerService): boolean{
			return showIfEAgency(record, dataContainerService);
		};
	}

	fieldsMap.set(key, value);
});

// Use the follow for display
entityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'entity.' + value.key });

	fieldsMap.set('entity.' + key, value);
});

export {fieldsMap};
