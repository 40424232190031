import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import * as moment from 'moment';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';

@Component({
	selector   : 'datetime-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class DateTimeInputComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public dateFilterBinded: (d: Date) => boolean;

	public initialTimeValue: string;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.dateFilterBinded = this.dateFilter.bind(this);
			this.initialTimeValue = this.getTimeValue();
		});
	}

	getTimeValue(): string{
		const control = this.inputService.control;
		if(!control) return '';
		const value = control.value;
		if(value){
			const date = new Date(value);
			return date.toLocaleTimeString('it-IT', {hour: '2-digit', minute: '2-digit'});
		}
		return '';
	}

	dateFilter(d: Date): boolean{
		if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter){
			return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
		}
		return true;
	}

	onTimeChanged(event: any): void{
		const value = this.inputService.control.value;
		const timeValue = event.target.value;
		if(!value) return;
		if(typeof(timeValue) === 'string' && timeValue.search(':') !== -1){
			const timeParts = timeValue.split(':');
			const hours = Number(timeParts[0]);
			const minutes = Number(timeParts[1]);
			if(value instanceof Date){
				value.setUTCHours(hours);
				value.setUTCMinutes(minutes);
			}else if(typeof(value.hours) === 'function'){
				value.add(moment().utcOffset(), 'm');
				value.hours(hours);
				value.minutes(minutes);
				value.subtract(moment().utcOffset(), 'm');
			}
		}
	}
}
