import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import filterForm from './forms/filter.form';
import { DomainPackageState } from './status.enum';
import customActions from './custom-actions';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domain_packages/detail/:recordId',
	params: {
		with_relations: 'package,category,requestDomain,ownerDomain,resellerDomain,mappedCustomerEntity,plafond'
	}
};

export const DomainPackages: IDatasetConfig = {
	name: 'domain_packages',
	title: 'Richieste e cessioni',
	singleTitle: 'Richieste e cessioni',
	ajaxDatasetCode: 'domain_packages',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		filterForm
	},
	fetchConfig: {
		with_relations: 'package,category,requestDomain,ownerDomain,resellerDomain,mappedCustomerEntity,plafond'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_relations = 'package,category,requestDomain,ownerDomain,resellerDomain,mappedCustomerEntity,plafond';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Pacchetto',
						key: 'package.description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record): any{
							if (record.package){
								return record.package.description;
							} else if (record.category){
								return 'Richiesta categoria: ' + record.category.description;
							}
							return 'E\' stata formulata una richiesta di rivendita per tutti i pacchetti.';
						}
					},
					{
						title: 'Dominio richiedente',
						key: 'request_domain.display_name'
					},
					{
						title: 'Dominio Cedente',
						key: 'owner_domain.display_name'
					},
					{
						title: 'Stato',
						key: 'status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record): any{
							let colorClass = 'orange-bg';
							let text = '<b>IN ATTESA</b>';

							if (record.inhibited){
								colorClass = 'blue-bg';
								text = '<b>SOSPESO PER RICHIESTA MASSIVA</b>';
							}else if(record.status === DomainPackageState.ACCEPTED){
								colorClass = 'green-bg';
								text = '<b>ACCETTATO</b>';
							} else if (record.status === DomainPackageState.REFUSED) {
								colorClass = 'red-bg';
								text = '<b>RESPINTO</b>';
							}else if (record.status === DomainPackageState.CANCELED) {
								colorClass = 'red-bg';
								text = '<b>Annullata</b>';
							}else if (record.status === DomainPackageState.RETIRED) {
								colorClass = 'red-bg';
								text = '<b>Ritirata</b>';
							}else if (record.status === DomainPackageState.RETIRED_DEFINITELY){
								colorClass = 'red-bg';
								text = '<b>Ritirata definitivamente</b>';
							}else if(record.status > 0){
								colorClass = 'orange-bg';
								text = '<b>---</b>';
							}

							let html = '<div class="domain_packages_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += '<span>' + text + '</span>';
							html += '</div>';
							return html;
						}
					}
				],
				actions: ['detail'],
				customActions: customActions
			}
		}
	}
};
