import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from '../user-edit.fields';

const userEditForm: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('user.name'),
	fieldsMap.get('user.email'),
	fieldsMap.get('domain_user_role_id'),
	fieldsMap.get('branch_id'),
	fieldsMap.get('domain_id'),
];

export default {
	fields: userEditForm 
};
