import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { LayoutComponent } from './layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
	declarations: [
		LayoutComponent
	],
	imports     : [
		RouterModule,

		FuseSharedModule,
		FuseSidebarModule,

		ContentModule,
		FooterModule,
		NavbarModule,
		QuickPanelModule,
		ToolbarModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule
	],
	exports     : [
		LayoutComponent
	]
})
export class LayoutModule
{
}
