export default [
	'rgb(54, 162, 235)',
	'rgb(255, 99, 132)',
	'rgb(75, 192, 192)',
	'rgb(153, 102, 255)',
	'rgb(201, 203, 207)',
	'rgba(241, 90, 34, 0.3)',
	'rgba(88,24,69,0.3)',
	'rgba(245,45,10,0.3)',
	'rgba(245,145,10,0.3)',
	'rgba(245,238,10,0.3)',
	'rgba(68,241,17,0.3)',
	'rgba(17,241,41,0.3)',
	'rgba(17,241,218,0.3)',
	'rgba(17,204,241,0.3)', 
	'rgba(33,255,0,0.3)',
	'rgba(11,0,255,0.3)',
	'rgba(17,150,241,0.3)',
	'rgba(17,92,241,0.3)',
	'rgba(27,17,241,0.3)',
	'rgba(150,17,241,0.3)',
	'rgba(221, 17, 241,0.3)',
	'rgba(241, 17, 201,0.3)',
	'rgba(241, 17, 160,0.3)',
	'rgba(241,17,105,0.3)',
	'rgba(255,0,0,0.3)',
	'rgba(0,255,0,0.3)',
	'rgba(0,0,255,0.3)'
];
