import { InjectionToken, Injector, Provider } from '@angular/core';
import * as DateHelper from './date.helper';
import * as FieldHelper from './field.helper';
import * as PropertyFieldHelper from './property-field.helper';
import { MAT_DIALOG_DATA } from '@angular/material';

/**
 * use roundToNearest ??
 * @param n 
 * @param digits 
 */
export function roundTo(n: number, digits: number): number{
	let negative = false;
	if (digits === undefined) {
		digits = 0;
	}
	if( n < 0) {
		negative = true;
		n = n * -1;
	}
	const multiplicator = Math.pow(10, digits);
	n = parseFloat((n * multiplicator).toFixed(11));
	n = Number((Math.round(n) / multiplicator).toFixed(2));
	if( negative ) {    
		n = Number((n * -1).toFixed(2));
	}
	return n;
}

export function wrapArray(item: any): Array<any>{
	if(!item) return [];
	return Array.isArray(item) ? item : [item];
}

export function isDatasetActionAllowed(data: any, action: string){
	if(!data || typeof(data) != 'object') return false;
	const allowed_actions = data.allowed_actions || data.static_dataset_allowed_actions;
	if(!allowed_actions || !Array.isArray(allowed_actions)) return false;
	return allowed_actions.includes(action);
}

export const DialogDataKeyToken = new InjectionToken('dialogDataKeyToken');

export function dialogDataFactory(injector: Injector, key: string){
	const dialogData = injector.get(MAT_DIALOG_DATA, {});
	return dialogData && dialogData[key];
}

export {
	DateHelper,
	FieldHelper,
	PropertyFieldHelper
};
