<mat-card class='p-24'>
	<mat-card-header>
		<mat-card-title>File Process</mat-card-title>
	</mat-card-header>
	<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>
	<mat-card-content>
		<form autocomplete="off" name="processFileForm">
			<input type='file' name='file' (change)="onFileChange($event)">
			<input type='text' name='processName' [(ngModel)]="processName" [placeholder]='"Nome processo" | translate'>
			<mat-form-field>
				<mat-label>{{'Seleziona dominio' | translate}}</mat-label>
				<mat-select required name='selectedDomain' [(ngModel)]="selectedDomain" [placeholder]='"Seleziona Dominio.." | translate'>
					<mat-option *ngFor="let domain of domains | async" [value]='domain.id'>
						{{domain.display_name}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<button [disabled]='!selectedFile || !processName || !selectedDomain || loading' mat-flat-button color='accent' (click)='loadProcess()'>{{'Carica' | translate}}</button>
	</mat-card-actions>
</mat-card>