import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('quantity', {
	key: 'quantity',
	name: 'Quantità',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Inserire disponibilità prodotto'
});

export {fieldsMap};
