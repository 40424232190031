import {InputTypes, ValueTypes, IFieldDefinition, FieldViews, IFieldOption} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

const iconsOptions: IFieldOption[] = [];
for (let index = 1; index < 101; index++) {
	const element = {
		value: 'icon_set_1_icon-' + index,
		label: 'icon_set_1_icon-' + index
	};
	iconsOptions.push(element);
}

for (let index = 102; index < 119; index++) {
	if(index === 113) continue;
	const element = {
		value: 'icon_set_2_icon-' + index,
		label: 'icon_set_2_icon-' + index
	};
	iconsOptions.push(element);
}

fieldsMap.set('slug', {
	key: 'slug',
	name: 'Icona',
	inputType: InputTypes.SELECT_CUSTOM_ICON,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.CUSTOM_ICON,
	options: iconsOptions
});

fieldsMap.set('title', {
	key: 'title',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('active_language_codes', {
	key: 'active_language_codes',
	name: 'Traduzioni attive',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

		if(record.active_language_codes && record.active_language_codes.length > 0){
			const style = 'style="margin-right: 5px;"';
			for (const lang of record.active_language_codes) {
				html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
			}
		}else{
			html += '<span>---</span>';
		}

		html += '<div>';
		return html;
	}
});

export {fieldsMap};
