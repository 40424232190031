<div>
	<div class="loading-div" *ngIf='dashboardService.loadingBookingWidow || showLoader'>
		<mat-spinner *ngIf='dashboardService.loadingBookingWidow' diameter="80" class='full-center-spinner'></mat-spinner>
	</div>
	<canvas baseChart *ngIf='bookingwindow'
		[datasets]="bookingwindow"
		[labels]="barChartLabels"
		[options]="barChartOptions"
		[legend]="barChartLegend"
		[chartType]="barChartType"
	></canvas>
</div>