import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
  selector: 'input-hidden',
  templateUrl: './input.component.html',
  animations : fuseAnimations
})
export class HiddenInputComponent {

  constructor(public inputService: FormInputViewerService) { }
}
