import { Component, Input } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';

@Component({
	selector   : 'trip-package-step-1',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
})
export class TripPackageStep1Component extends BaseStepViewComponent{
	
	@Input() parentDatasetRS: DatasetRecordService;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService
	){
		super(datasetACS, datasetNavigatorService);
	}
}
