import { TripMeetingPointConfig } from '../trip_meeting_points';

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/meetingPoints/dataset/trip_resources/:parentRecordId/detail/:recordId',
};

const pivotTripResourcesTripMeetingPointConfig = Object.assign({}, TripMeetingPointConfig);

pivotTripResourcesTripMeetingPointConfig.pivot = {
	pivotCode: 'meetingPoints',
	foreignKey: 'meeting_point_id',
	parentDatasetCode: 'trip_resources'
};

pivotTripResourcesTripMeetingPointConfig.defaultActions = Object.assign({}, pivotTripResourcesTripMeetingPointConfig.defaultActions);
pivotTripResourcesTripMeetingPointConfig.defaultActions.detail = Object.assign({}, pivotTripResourcesTripMeetingPointConfig.defaultActions.detail);

pivotTripResourcesTripMeetingPointConfig.defaultActions.detail.beforeCalls = [beforeCallDetail];

export const PivotTripResourcesTripMeetingPointConfig = pivotTripResourcesTripMeetingPointConfig;
