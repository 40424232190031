import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CalendarEventModel } from 'app/main/pages/calendar/event.model';
import { CalendarMonthViewDay, CalendarEvent } from 'angular-calendar';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { DatasetCalendarViewComponent } from 'app/main/components/dataset/base-actions/list/views/calendar/calendar.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_resource_allotments/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	let trip_resource_id = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
	if (!trip_resource_id){
		trip_resource_id = dataContainerService.getValueFromKeyPath('products.recordId');
	}
	params['trip_resource_id'] = trip_resource_id;
	return params;
}

export const TripResourceAllotmentConfig: IDatasetConfig = {
	name: 'trip_resource_allotments',
	title: 'Allotment Risorse',
	singleTitle: 'Allotment Risorsa',
	ajaxDatasetCode: 'trip_resource_allotments',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CALENDAR,
				showSearchBar: true,
				calendarConfig: {
					itemsToEvents(items: any[], component: DatasetCalendarViewComponent): CalendarEventModel[]{
						if(!items) return [];
						return items.map(item => {
							const calendarEvent = new CalendarEventModel(undefined, component.datasetNavigatorService);
							calendarEvent.fromResourseAllotment(item.resource_allotment, item.day);
							return calendarEvent;
						});
					},
					onDayClicked(calendarComponent: DatasetCalendarViewComponent, day: CalendarMonthViewDay): void{
						const date: Date = day.date;
						const events: CalendarEvent[] = day.events;
						const event = events && events.find(el => {
							return el && el.meta && el.meta.resource_allotment;
						});
				
						const utcDate = convertToUTCDay(date);
						calendarComponent.datasetNavigatorService.onAdd(calendarComponent.datasetACS, {
							initialValues: {
								date_from: utcDate.toISOString(),
								date_to: utcDate.toISOString(),
								quantity: event && event.meta && event.meta.resource_allotment && event.meta.resource_allotment.quantity
							}
						});
					},
					onAdd(calendarComponent: DatasetCalendarViewComponent): void{
						calendarComponent.datasetNavigatorService.onAdd(calendarComponent.datasetACS);
					},
					templateCodes: {
						eventTitleTemplate: 'simple-text',
						eventActionsTemplate: 'empty',
						monthCellTemplate: 'text',
						monthOpenDayEventsTemplate: 'empty'
					}
				}
			}
		}
	}
};
