import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
	TangerineBgColors,
} from 'app/interfaces';
import { fieldsMap } from './fields';
import generalForm from './general-form';
import { TripFacilityLanguageContentConfig } from '../trip_facility_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_facilities/detail/:recordId',
};

export const TripFacilityConfig: IDatasetConfig = {
	name: 'trip_facilities',
	title: 'Informazioni Utili',
	singleTitle: 'Informazioni Utili',
	ajaxDatasetCode: 'trip_facilities',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
	},
	formConfigsMap: {
		generalForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'slug',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Icona',
						key: 'slug',
					},
					{
						title: 'Nome',
						key: 'title',
						onClick(
							record,
							resourceACS,
							resourceNavigatorService
						): void {
							resourceNavigatorService.showDetail(
								resourceACS,
								record
							);
						},
					},
					{
						title: 'Traduzioni Atttive',
						key: 'active_language_codes',
					},
				],
				actions: ['create', 'detail', 'delete'],
			},
		},
	},
	stepViewConfig: {
		tangerineBgColor: TangerineBgColors.TANGERINE_DARK,
		title: 'Informazioni Utili',
		subtitle(component: CommonStepViewContainerComponent): string {
			if (!component.record) return;
			return component.record.title;
		},
		isLinear(component: CommonStepViewContainerComponent): boolean {
			return !Boolean(component.record);
		},
		steps: [
			{
				title: 'Generale',
				stepperTitle: 'Generale',
				component: 'common-form-step',
				options: { formConfig: generalForm },
			},
			{
				title: 'Descrizioni',
				stepperTitle: 'Descrizioni',
				component: 'common-i18n-form-step',
				options: {
					formConfig:
						TripFacilityLanguageContentConfig.formConfigsMap[
							'textForm'
						],
					datasetCode: 'trip_facility_language_contents',
					parentForeignKey: 'trip_facility_id',
					parentValueKey: 'trip_facilities.recordId',
				},
			},
		],
	},
};
