<h2>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>

<div class="card">
	<div class="info_plafond">
		<p class="plafond_description">{{datasetRS.record.value.description}}</p>
		<p class="amount_plafond"><b>{{'Totale plafond' | translate}}:</b> {{datasetRS.record.value.total_amount | currency:'EUR'}}</p>
		<p class="consumed_plafond"><b>{{'Totale consumato' | translate}}:</b> {{datasetRS.record.value.total_consumed | currency:'EUR'}}</p>
	</div>

	<ng-container *ngIf='datasetACS.canEdit(datasetRS.record.value)'>
		<div class="reset">
			<button (click)='reset()' class="add-btn" mat-flat-button color="primary">
				{{ 'RESET' }}
			</button>
		</div>
	</ng-container>
</div>

<mat-tab-group #matTabGroup [selectedIndex]='0'>
	<mat-tab label="Richieste plafond">
		<dataset-list
			[enableCreate]='false'
			[selectable]='false'
			[elevate]='false'
			[showSearchBar]='true'
			[showTitle]='false'
			[enableEdit]='false'
			[enableDelete]='false'
			[showCreateForm]='false'
			[enableActions]='false'
			[showExportBtn]='false'
			datasetCode='domain_packages'
			[parentDatasetACS]='datasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{plafond_id: datasetRS.recordId}'>
		</dataset-list>
	</mat-tab>

	<mat-tab label="Operazioni plafond">
		<dataset-list
			[enableCreate]='false'
			[selectable]='false'
			[elevate]='false'
			[showSearchBar]='true'
			[showTitle]='false'
			[enableEdit]='false'
			[enableDelete]='false'
			[showCreateForm]='false'
			[enableActions]='false'
			[showExportBtn]='false'
			datasetCode='plafond_drop_items'
			[parentDatasetACS]='datasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{plafond_id: datasetRS.recordId}'>
		</dataset-list>
	</mat-tab>

	<mat-tab label="Logs plafond">
		<dataset-list
			[enableCreate]='false'
			[selectable]='false'
			[elevate]='false'
			[showSearchBar]='true'
			[showTitle]='false'
			[enableEdit]='false'
			[enableDelete]='false'
			[showCreateForm]='false'
			[enableActions]='false'
			[showExportBtn]='false'
			datasetCode='plafond_logs'
			[parentDatasetACS]='datasetACS'
			[parentDatasetRS]='datasetRS'
			[filters]='{target_id: datasetRS.recordId}'>
		</dataset-list>
	</mat-tab>
</mat-tab-group>
