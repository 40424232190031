import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { FormGroup } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import FormFields from './form.fields';
import { supportedLanguages } from 'app/configs/datasets/actual_trips';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { TripOptionsConfig } from 'app/configs/datasets/trip_options';
import { IListViewConfig } from 'app/interfaces';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';

export interface ILanguage{
	value: string;
	label: string;
}

@Component({
	selector   : 'trip-booking-options',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ DatasetActionContainerService, DatasetRecordService, DatasetMultilanguageContainerService, FormViewerService ]
})
export class OptionSetupStepComponent extends BaseStepViewComponent implements OnInit{

	scenario: BehaviorSubject<any>;

	selectedLanguages: ILanguage[] = [];
	selectedAudioGuideLanguages: ILanguage[] = [];

	tripOptionsViewConfig: IListViewConfig;

	protected fileterFields = FormFields;
	protected formErrors: any;

	@ViewChild('tripOption', { static: false }) tripOtions: DatasetListComponent;

	@Input() isLastStep = false;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService,
		public formViewerService: FormViewerService,
		public stepService: StepService){
		/** init */
		super(datasetACS, datasetNavigatorService);
		this.scenario = new BehaviorSubject('go-to-dataset');
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'list',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true
		}).subscribe(() => {
			this.formViewerService.init({
				errors: this.formErrors,
				dataContainerService: this.datasetACS,
				fields: Array.from(this.fileterFields.values())
			});
		});
		this.setLanguages('languages');
		this.setLanguages('audio_guide_languages');

		this.tripOptionsViewConfig = TripOptionsConfig.defaultActions.list.viewConfig;
		this.scenario.subscribe(res => {
			if (res === 'go-to-dataset') this.scrollToTop();
		});
	}

	resetForm(action: string): void{
		this.scenario.next(action);
		this.datasetRS.record.next(null);
		this.datasetRS.recordId = null;
		this.formViewerService.clearForm();
		this.selectedLanguages = [];
		this.selectedAudioGuideLanguages = [];
	}

	onSelected(event): void{
		this.datasetRS.recordId = event.id;
		//this.stepService.selectedOption.next(event);
		this.scenario.next('edit');
		this.datasetACS.loading.next(true);
		this.datasetRS.reload()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (record) => {
				this.formViewerService.setRecord(record);
				this.formViewerService.formGroup.get('languages').setValue(null);
				this.formViewerService.formGroup.get('audio_guide_languages').setValue(null);
				this.selectedLanguages = record.languages;
				this.selectedAudioGuideLanguages = record.audio_guide_languages;
				this.datasetACS.loading.next(false);
			},
			error: (response) => {
				console.error(response);
				this.datasetACS.loading.next(false);
			}
		});
	}

	setLanguages(input: any): void{
		this.formViewerService.formBuilded.subscribe((formGroup: FormGroup) => {
			formGroup.get(input).valueChanges.subscribe(newVal => {
				if (newVal !== null){
					const obj: ILanguage = supportedLanguages.find(x => x.value === newVal);
					if (input === 'languages'){
						if (!this.selectedLanguages.includes(obj)) this.selectedLanguages.push(obj);
					} else {
						if (!this.selectedAudioGuideLanguages.includes(obj)) this.selectedAudioGuideLanguages.push(obj);
					}
				}
			});
		});
	}

	removeLang(language: ILanguage, type?: any): void{
		if (!type) {
			this.selectedLanguages = this.selectedLanguages.filter(x => x.value !== language.value);
		} else if (type === 'audio_guide') {
			this.selectedAudioGuideLanguages = this.selectedAudioGuideLanguages.filter(x => x.value !== language.value);
		}
	}

	beforeSave(formData: any): any{
		formData['languages'] = this.selectedLanguages;
		formData['audio_guide_languages'] = this.selectedAudioGuideLanguages;
		return formData;
	}

	delete(): void{
		this.scrollToTop();
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.datasetRS.record.value);
		this.datasetACS.datasetEvent.subscribe((event) => {
			if (event.eventName !== DatasetEvents.DELETED) return;
			this.resetForm('go-to-dataset');
			if (this.tripOtions){
				this.tripOtions.reload();
			}
			this.appService.appEvent.subscribe(appEvent => {
				if (appEvent.name !== 'total_dataset_items') return;
				if (appEvent.extra.datasetCode !== this.datasetACS.datasetCode) return;
				if (appEvent.extra.paginationResponse.meta.total) return;

				this.stepService.setStepState({
					dataset_code: 'trip_packages',
					form_code: 'trip_options_form',
					record_id: this.parentDatasetRS.recordId,
					status: 0
				});
			});
		});
	}

	onSave(): void{
		this.datasetACS.loading.next(true);
		this.formViewerService.formGroup.disable();

		let formData = this.formViewerService.getFormData();

		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/create';
		if(this.datasetRS.record.value){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}else formData['trip_package_id'] = this.datasetACS.getValueFromKeyPath('trip_packages.recordId');

		formData = this.beforeSave(formData);
		
		this.datasetService.post(route, formData)
		.subscribe({
			next: (record) => {
				if(this.formViewerService){
					this.formViewerService.formGroup.enable();
				}
				if (record){
					this.scenario.next('go-to-dataset');
					//this.stepService.selectedOption.next(record);
					this.datasetACS.loading.next(false);
				}
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });

				setTimeout(() => {
					this.stepService.setStepState({
						dataset_code: 'trip_packages',
						form_code: 'trip_options_form',
						record_id: this.parentDatasetRS.recordId,
						status: 1
					});

					this.goNextStep();
				}, 200);
			},
			error: response => {
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.formViewerService && this.formViewerService.formGroup){
					this.formViewerService.formGroup.enable();
					this.formViewerService.setErrors(this.formErrors);
				}
				this.datasetACS.loading.next(false);
			}
		});
	}
}
