import { Component, OnInit, Input, Inject } from '@angular/core';
import { IListViewConfig } from 'app/interfaces';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPackagesDialogComponent } from 'app/main/components/dataset/dialogs/request-packages-dialog/dialog.component';

@Component({
	selector: 'request-package-tool',
	templateUrl: './request-package-tool.component.html',
	styleUrls: ['./request-package-tool.component.scss']
})
export class RequestPackageToolComponent {

	@Input() public viewConfig: IListViewConfig;
	@Input() public datasetACS: DatasetActionContainerService;

	constructor(
		private http: HttpClient,
		private appService: AppService,
		private datasetService: DatasetService,
		private matDialog: MatDialog
	){}

	openDialog(): void{
		const loadingDialog = this.matDialog.open(RequestPackagesDialogComponent, {
			minWidth: 400,
			data: {
				appService: this.appService,
				httpClient: this.http,
				datasetService: this.datasetService,
				datasetACS: this.datasetACS,
			}
		});
	}
}
