<ng-container *ngIf='inputService.show'>
	<ng-container [ngSwitch]='inputService.inputConfig.type' [formGroup]="inputService.formGroup">
		<ng-container *ngSwitchCase='inputTypes.CHECKBOX'>
			<checkbox-input></checkbox-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.SELECT'>
			<select-input></select-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TEXTAREA'>
			<textarea-input></textarea-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.RADIO'>
			<radio-input></radio-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATE'>
			<date-input></date-input>
		</ng-container>
		<ng-container *ngSwitchCase='inputTypes.COMPARE_DATE'>
			<compare-date-input></compare-date-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATETIME'>
			<datetime-input></datetime-input>
		</ng-container>
		
		<ng-container *ngSwitchCase='inputTypes.TOGGLE'>
			<toggle-input></toggle-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.READONLY'>
			<readonly-input></readonly-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.COLOR'>
			<color-input></color-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_AUTOCOMPLETE'>
			<dataset-autocomplete-input></dataset-autocomplete-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_COMBOBOX'>
			<dataset-combobox-input></dataset-combobox-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_SELECT'>
			<dataset-select-input></dataset-select-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_SELECT_DEP_DROPDOWN'>
			<dataset-select-dep-dropdown-input></dataset-select-dep-dropdown-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_MULTISELECT'>
			<dataset-multiselect-input></dataset-multiselect-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.DATASET_SEARCH'>
			<dataset-search-input></dataset-search-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TEXT_LIST'>
			<text-list-input></text-list-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TEXT_LIST2'>
			<text-list-input2></text-list-input2>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.WEEK_HOURS'>
			<week-hours></week-hours>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TINY_MCE'>
			<tinymce-input></tinymce-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.FILE'>
			<file-input></file-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.MULTIPLE_FILE'>
			<multiple-file-input></multiple-file-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.FILE_BLOCK'>
			<file-block-input 
				[record]='formInputDefinition.inputConfig.fileBlockConfig.record'
				[inputService]='inputService'
				[documentFileKey]='inputService.formControlKey'
				[type]='"image"'
				[uploadRoute]='formInputDefinition.inputConfig.fileBlockConfig.uploadRoute'
				[uploadData]='formInputDefinition.inputConfig.fileBlockConfig.uploadData'
				[getUploadDataFun]='formInputDefinition.inputConfig.fileBlockConfig.getUploadDataFun'
				[imageOptions]='formInputDefinition.inputConfig.fileBlockConfig.imageOptions'
				[svgEnablded]='true'
				[uploadLabel]='"Carica Immagine" | translate'
				(changed)='formInputDefinition.inputConfig.fileBlockConfig.changed($event)'
			></file-block-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TIME'>
			<time-input></time-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.TEXT'>
			<text-input></text-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.HIDDEN'>
			<input-hidden></input-hidden>
		</ng-container>
		
		<ng-container *ngSwitchCase='inputTypes.NUMBER'>
			<number-input></number-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.MULTI_SELECT'>
			<multiselect-input></multiselect-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.PASSWORD'>
			<password-input></password-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.SELECT_CUSTOM_ICON'>
			<select-custom-icon-input></select-custom-icon-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.SELECT_FILTERABLE'>
			<select-filterable-input></select-filterable-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.BASE_TEXT'>
			<base-text-input></base-text-input>
		</ng-container>

		<ng-container *ngSwitchCase='inputTypes.COUNTER'>
			<counter-input></counter-input>
		</ng-container>

		<ng-container *ngSwitchDefault>
			<text-input></text-input>
		</ng-container>
	</ng-container>
</ng-container>