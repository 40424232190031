import { InputTypes, ValueTypes, IFormConfig } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export default {
	fields: [
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'from',
					name: 'A partire da',
					inputType: InputTypes.DATE,
					valueType: ValueTypes.DATE
				},
				{
					key: 'to',
					name: 'Fino a',
					inputType: InputTypes.DATE,
					valueType: ValueTypes.DATE
				},
			]
		},
		{
			key: 'entity_staff_element_unified_list_item_id',
			name: 'Staff',
			inputType: InputTypes.DATASET_SELECT,
			valueType: ValueTypes.REFERENCE,
			datasetCode: 'entity_staff_element_unified_list_items',
			remoteOptions: {
				limit: 50,
				getParams(dataContainerService: IDataContainerService, data: any): any{
					const params = {
						foreign_parent_dataset_code: 'trip_resources',
						foreign_parent_record_id: dataContainerService.getValueFromKeyPath('trip_resources.recordId')
					};
					return params;
				},
				getLabel(option: any): string{
					if(!option || !option.entity_staff_element.entity) return '---';
					return option.entity_staff_element.entity.description;
				},
				getValue(option): string{
					return option.id;
				}
			}
		}
	]
} as IFormConfig;
