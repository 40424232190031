<div id="main">

	<!-- TOOLBAR: Above -->
	<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above' && showAppInterface">
		<ng-container *ngTemplateOutlet="toolbar"></ng-container>
	</ng-container>
	<!-- / TOOLBAR: Above -->

	<div id="container-1" class="container">

		<!-- NAVBAR: Left -->
		<ng-container *ngIf="fuseConfig.layout.navbar.position === 'left' && showAppInterface">
			<ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Left -->

		<div id="container-2" class="container">

			<!-- TOOLBAR: Below fixed -->
			<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed' && showAppInterface">
				<ng-container *ngTemplateOutlet="toolbar"></ng-container>
			</ng-container>
			<!-- / TOOLBAR: Below fixed -->

			<div #container3 id="container-3" class="container" fusePerfectScrollbar
				 [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

				<!-- TOOLBAR: Below static -->
				<ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below-static' && showAppInterface">
					<ng-container *ngTemplateOutlet="toolbar"></ng-container>
				</ng-container>
				<!-- / TOOLBAR: Below static -->

				<!-- CONTENT -->
				<content></content>
				<!-- / CONTENT -->

				<!-- FOOTER: Below static -->
				<ng-container *ngIf="fuseConfig.layout.footer.position === 'below-static' && showAppInterface">
					<ng-container *ngTemplateOutlet="footer"></ng-container>
				</ng-container>
				<!-- / FOOTER: Below static -->

			</div>

			<!-- FOOTER: Below fixed -->
			<ng-container *ngIf="fuseConfig.layout.footer.position === 'below-fixed' && showAppInterface">
				<ng-container *ngTemplateOutlet="footer"></ng-container>
			</ng-container>
			<!-- / FOOTER: Below fixed -->

			<mat-toolbar *ngIf='appService.hasNewVersion' class="pl-24 mat-elevation-z1 accent" fxLayout='row' fxLayoutAlign='start center'> 
				<span class='mr-24'>Una nuova versione è disponibile, vuoi aggiornare la pagina ? </span>
				<a mat-icon-button href="javascript:window.location.reload(true)">
					<mat-icon>refresh</mat-icon>
				</a>
			</mat-toolbar>

		</div>

		<!-- NAVBAR: Right -->
		<ng-container *ngIf="fuseConfig.layout.navbar.position === 'right' && showAppInterface">
			<ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
		</ng-container>
		<!-- / NAVBAR: Right -->

	</div>

	<!-- FOOTER: Above -->
	<ng-container *ngIf="fuseConfig.layout.footer.position === 'above' && showAppInterface">
		<ng-container *ngTemplateOutlet="footer"></ng-container>
	</ng-container>
	<!-- FOOTER: Above -->

</div>

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
	<quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
	<div class="install_banner" *ngIf='showBanner'>
		<img src="assets/icons/icon-72x72.png" class="icon" />
		<span>
			Aggiungi l'applicazione sul tuo dispositivo per avere una migliore esperienza d'uso 
			<a class="add_to_home" (click)='onInstallBtnClick()'>Aggiungi a schermata Home</a>
		</span>
		<!--<i class="fa fa-times"></i>-->
	</div>
	<toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
			 [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
	</toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
	<footer *ngIf="!fuseConfig.layout.footer.hidden"
			[ngClass]="fuseConfig.layout.footer.customBackgroundColor === true ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background : fuseConfig.layout.footer.position">
	</footer>
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
	<fuse-sidebar name="navbar" class="navbar-fuse-sidebar"
				  [folded]="fuseConfig.layout.navbar.folded"
				  lockedOpen="gt-md"
				  *ngIf="!fuseConfig.layout.navbar.hidden">
		<navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
	</fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
	<fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar"
				  [folded]="fuseConfig.layout.navbar.folded"
				  lockedOpen="gt-md"
				  *ngIf="!fuseConfig.layout.navbar.hidden">
		<navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
	</fuse-sidebar>
</ng-template>
<!-- / RIGHT NAVBAR -->
