import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService, trainTypeImages } from '../../trenitalia.service';
import { AppService } from '../../../../../../services/app.service';
import { BookingService } from '../../../booking.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { supportedPostSaleActions, TrenitaliaPostSaleService } from '../trenitalia-post-sale.service';
import * as moment from 'moment';

@Component({
	selector: 'trenitalia-solution-details',
	templateUrl: './trenitalia-solution-details.component.html',
	styleUrls: ['./trenitalia-solution-details.component.scss'],
})
export class TrenitaliaSolutionDetailsComponent implements OnInit, OnDestroy, OnChanges{

	@Input() travelSolution: any;
    @Input() ar: any;
	public postActions: any;
	public status: String;
	public travellers: any[];
	public travelSolutionTicketsMap: any;
	public postActionButtons: any[];
    public carnetData: any;
    public carnetExpire: any;
	public cancelTimeEnded = false;
	public showCancelTimer = false;
	protected _unsubscribeAll: Subject<any>;

	get isCarnet(){
		return this.trenitaliaPostSaleService.isCarnet;
	}

	get trainTypeImages(){
		return trainTypeImages;
	}

	constructor(
		public appService: AppService,
		public matDialog: MatDialog,
		public trnService: TrenitaliaService,
		protected mastSnackBar: MatSnackBar,
		public bookingService: BookingService,
		public trenitaliaPostSaleService: TrenitaliaPostSaleService,
        public viewContainerRef: ViewContainerRef
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this.trenitaliaPostSaleService.travelDetails
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(travelDetails => {
			// map post sale actions
			this.updatePostActionButtons();
            if(this.isCarnet){
                this.carnetData = _.get(travelDetails,'travel.original.entitlements')
                this.carnetExpire = _.get(travelDetails,'travel.original.travelSolutions.0.offeredServices.endValidity')

            }

			this.status = _.get(this.trenitaliaPostSaleService.tripBookingPackage, 'tour_operator_booking_status');
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.travelSolution){
			this.updateTravelSolutionData();
		}
	}

	updateTravelSolutionData(){
		const travellersWithOfferedService = new Set();
		
		const travelSolutionTickets = {};
		for (const [offeredServiceXmlId, offeredService] of Object.entries<any>(this.travelSolution.offeredServices)) {
			if(!['SELECTED', 'PURCHASED'].includes(offeredService.status)) continue;
			if(!_.has(travelSolutionTickets, offeredService.nodeId)){
				travelSolutionTickets[offeredService.nodeId] = {tickets: []};
			}
			let seatInfo = null;
            let reservationCodes = null;
			const reservation = _.get(offeredService, 'original.bookingInfo.reservation');
            if(reservation){
                reservationCodes = {cpCode:reservation.cpCode,pnrCode:reservation.pnrCode,referenceNumber:reservation.referenceNumber,ticketCode:_.get(offeredService,'original.entitlementId')}
            }
			if(reservation && reservation.showSeat == 'true'){
				seatInfo = {wagon: reservation.wagon, seat:reservation.seat};
			}
            if(!reservation && _.has(this.travelSolution,'seatMap')){
                const travellerSeat = this.travelSolution.seatMap.find(s => s.offeredServicesId == offeredServiceXmlId)
                seatInfo = {wagon:travellerSeat.wagon, seat:travellerSeat.airCraftNumber}
            }
			const traveller = this.trenitaliaPostSaleService.travellers.find(t => t.xmlId == offeredService.travellerXmlId);
			const param = _.get(offeredService, 'original.parameters',[]);
            let position :any;
            if(_.isArray(param)) {
                const parameters = param.find(p => p.typeId == 2);
                position = parameters?parameters.value:null;
            }
            if(!_.has(travelSolutionTickets, [offeredService.nodeId,'tickets',traveller.xmlId])){
                travelSolutionTickets[offeredService.nodeId].tickets[traveller.xmlId] = [];
            }

			travelSolutionTickets[offeredService.nodeId].tickets[traveller.xmlId].push({
				traveller,
				catalogServiceName: _.get(this.trenitaliaPostSaleService.serviceMap, ['catalog_services',offeredService.catalogServiceId,'display_name']),
				offerName: _.get(this.trenitaliaPostSaleService.serviceMap, ['offers',offeredService.offerId,'display_name']),
				position: position,
				offeredService,
				seatInfo,
                reservationCodes
			});
			if(traveller) travellersWithOfferedService.add(traveller.xmlId);
		}
		this.travelSolutionTicketsMap = travelSolutionTickets;

		this.travellers = this.trenitaliaPostSaleService.travellers.filter(t => travellersWithOfferedService.has(t.xmlId));

		this.postActions = _.get(this.trenitaliaPostSaleService, ['postActions', this.travelSolution.xmlId]);
		this.updatePostActionButtons();
	}

	/**
	 * update post action buttons
	 * @returns
	 */
	updatePostActionButtons() {
		const result = [];
		if (!this.postActions) {
			this.postActionButtons = result;
			return;
		}
		let showCancelTimer = false;
		for (const postAction of supportedPostSaleActions) {
			if (!this.postActions[postAction.code]) continue;
			// only global cancellation at moment
			if (postAction.code == 'cancellation_before_departure') {
				showCancelTimer = _.get(this.postActions, 'cancellation_before_departure.enabled', false);
				continue;
			}
			const action: any = Object.assign({}, postAction);
			action.postAction = this.postActions[postAction.code];
			action.messages = this.getMessage(
				_.get(this.postActions[postAction.code], 'messages')
			);
			result.push(action);
		}
		this.postActionButtons = result;

		this.cancelTimeEnded = this.showCancelTimer != showCancelTimer;
		this.showCancelTimer = showCancelTimer;
	}

	postSaleActionClick(postSaleActionCode: string, postSaleDetails?: any[]) {
		if (!_.has(this.postActions, postSaleActionCode)) return;
		if (postSaleActionCode == 'traveller_change') {
			this.trenitaliaPostSaleService.edit('edit-travellers', this.travelSolution, this.postActions[postSaleActionCode]);
		} else if (postSaleActionCode == 'booking_change_before_departure') {
			this.trenitaliaPostSaleService.edit('edit-date-time', this.travelSolution, this.postActions[postSaleActionCode]);
		} else if (postSaleActionCode == 'travel_change_before_departure') {
            this.trenitaliaPostSaleService.edit('travel-change', this.travelSolution, this.postActions[postSaleActionCode]);
		} else if (postSaleActionCode == 'cancellation_before_departure') {
			this.trenitaliaPostSaleService.onDelete();
		} else if (postSaleActionCode == 'change_class') {
			this.trenitaliaPostSaleService.changeClass(this.postActions.change_class.postSaleDetails,this.viewContainerRef);
		} else if (postSaleActionCode == 'refund_before_departure') {
			this.trenitaliaPostSaleService.onRefund(postSaleDetails || _.get(this.postActions, 'refund_before_departure.postSaleDetails'));
		} else if (postSaleActionCode == 'carnet_refund') {
            this.trenitaliaPostSaleService.onRefund(postSaleDetails || _.get(this.postActions, 'carnet_refund.postSaleDetails'));
        }else {
			console.warn('action not supported', postSaleActionCode);
		}
	}

	onDelete(){
		this.trenitaliaPostSaleService.onDelete();
	}

	endTimer(e) {
		this.showCancelTimer = false;
		this.cancelTimeEnded = true;
	}

	getMessage(messages) {
		let msg = '';
		if (messages !== undefined) {
			messages.forEach(s => {
				msg += s + '\n';
			});
		}
		return msg;
	}
}