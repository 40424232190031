<h2>{{'Sconti' | translate}}</h2>

<div fxLayout="column" fxLayoutGap="32px">
	<div class="add-btn-row" fxLayout="row" fxLayoutAlign="start">
		<button mat-button (click)="openDiscountListForm()">
			<span class="material-icons-outlined">add</span> {{ "Aggiungi sconto" | translate }}
		</button>
	</div>
	
	<div class="coupon-code-row" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-sm="15px">
		<div fxLayout="column" fxLayoutGap="20px">
			<label>{{'Inserisci coupon' | translate}}</label>
			<input type="text" [formControl]="couponCodeFormControl" />
		</div>
		<button (click)="addCouponCode(couponCodeFormControl.value)" mat-button [disabled]="!couponCodeFormControl.valid">
			{{'Invia' | translate}}
		</button>
	</div>
</div>

<div class="mt-35">
	<dataset-list 
		#datasetListComponent
		[elevate]='false'	
		[showSearchBar]='false'
		[showTitle]='false'
		[enableCreate]='false'
		[enableEdit]='false'
		datasetCode='trip_booking_discounts'
		[parentDatasetACS]='datasetACS'
		[parentDatasetRS]='datasetRS'
		[filters]='{ trip_booking_id: record.id, only_valid: 1 }'
		(datasetEvent)='onDiscountDatasetEvent($event)'
	></dataset-list>
</div>