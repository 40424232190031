import { OnInit, OnDestroy, Inject } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookingService } from '../booking.service';
import { BookingStep2Service } from './view.service';
import { takeUntil } from 'rxjs/operators';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from '../edit-booking.component';

export interface ITripBookingPackageRelatedData{
	datasetCode: string;
	actionCode: string;
	tripBookingPackage: any;
	tripBookingPackages: any[];
	tripBookingPackageACS: DatasetActionContainerService;
	tripBookingPackageRS: DatasetRecordService;
	bookingService: BookingService;
	bookingStepService: BookingStep2Service;
}

/**
 * @deprecated
 */
export class TripBookingPackageRelatedComponent implements OnInit, OnDestroy{

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedData){}

	ngOnInit(): void{
		this._unsubscribeAll = new Subject();

		this.datasetACS.init({
			datasetCode: this.data.datasetCode,
			actionCode: this.data.actionCode,
			parentDatasetACS: this.data.tripBookingPackageACS,
			parentDatasetRS: this.data.tripBookingPackageRS,
			useBeforeCalls: false,
			fetchData: false
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onSelected(selectedOption: any): void{
		this.datasetRS.record.next(selectedOption);
	}

	onCloseClick(): void{
		this.matDialogRef.close();
	}

	get isResaleCopy(): boolean{
		return Boolean(this.data.tripBookingPackage.ref_id);
	}
}
