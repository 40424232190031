import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/categories/dataset/trip_resources/:parentRecordId/detail/:recordId',
};

export const PivotTripResourceTripCategoriesConfig: IDatasetConfig = {
	name: 'pivot_trip_resources_trip_categories',
	title: 'Categorie',
	singleTitle: 'Categoria',
	ajaxDatasetCode: 'trip_categories',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
	},
	pivot: {
		pivotCode: 'categories',
		foreignKey: 'trip_category_id',
		parentDatasetCode: 'trip_resources',
		commandSync: 'tpl1_trip_resources_pivots',
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'category.code',
						routerLink(record): string{
							return datasetDetailPage('trip_categories', record.id);
						}
					},
					{
						title: 'Descrizione',
						key: 'category.description'
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
