import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from '../entity_staff_element_unified_list_items/fields';
import createForm from './forms/create.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/entity_staff_element_unified_list_items/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const actualTripId = dataContainerService.getValueFromKeyPath('actual_trips.recordId');
	if(actualTripId){
		params['foreign_parent_record_id'] = actualTripId;
		params['foreign_parent_dataset_code'] = 'actual_trips';
		const listProperties = dataContainerService.getValueFromKeyPath('entity_staff_element_unified_list_items_for_actual_trips.filters.list_properties');
		if(listProperties) params['list_properties'] = JSON.stringify(listProperties);
		else console.log('no listproperties', dataContainerService);
	}
	if(params['list_properties'] && typeof(params['list_properties']) !== 'string'){
		params['list_properties'] = JSON.stringify(params['list_properties']);
	}

	return params;
}

export const EntityStaffElementUnifiedListItemsForActualTripsConfig: IDatasetConfig = {
	name: 'entity_staff_element_unified_list_items',
	title: 'Staff',
	singleTitle: 'Staff',
	ajaxDatasetCode: 'entity_staff_element_unified_list_items',
	fieldsMap: fieldsMap,
	supportProperties: true,
	nestedProperties: ['entity_staff_elements'],
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		createForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: createForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'entity_staff_element_code',
					},
					{
						title: 'Descrizione',
						key: 'entity_staff_element_description',
						fieldDefinition: fieldsMap.get('entity_staff_element_description')
					},
					{
						title: 'Tipologia',
						key: 'entity_staff_element_staff_type'
					},
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
