import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';

export interface ITextDialogData {
	title?: string;
	contentMessage: string;
	positiveText?: String;
	negativeText?: String;
}

@Component({
	selector: 'text-dialog',
	templateUrl: 'dialog.component.html'
})
export class TextDialogComponent implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<TextDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ITextDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.dialogRef.close(true);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
