import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('active_status'),
			fieldsMap.get('publish_on_web'),
		]
	}
	//fieldsMap.get('priority_order') // never used
];

const config: IFormConfig = { 
	title: 'Crea Categoria',
	editTitle: 'Modifica Categoria',
	fields: formFields,
};

export default config;
