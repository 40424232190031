import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, InputTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IActionDialogData, ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';

export const EntityCustomerTripPackageConfig: IDatasetConfig = {
	name: 'entity_customer_trip_packages',
	title: 'Pacchetti',
	singleTitle: 'Pacchetti',
	ajaxDatasetCode: 'entity_customer_trip_packages',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: true,
		list: true
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: {
					fields: [
						{
							key: 'enabled',
							name: 'Abilitato',
							valueType: ValueTypes.NUMBER,
							inputConfig: {
								type: InputTypes.SELECT
							},
							disallowNull: false,
							options: [
								{
									label: 'Si',
									value: 1
								},
								{
									label: 'No',
									value: 0
								},
								{
									label: 'Tutti',
									value: null
								}
							]
						}
					]
				},
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Pacchetto',
						key: 'description'
					},
					{
						title: 'Abilitato',
						key: 'enabled'
					}
				],
				actions: ['edit'],
				itemActionsOrder: ['custom_0', 'custom_1'],
				customActions: [
					{
						actionCode: 'edit',
						buttonColor: 'primary',
						buttonText: 'Aggiungi',
						showIf(record): boolean{
							return !record.enabled;
						},
						onClick(event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): void{
							const dialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
								title: 'Attendere',
								contentMessage: ''
							});
							datasetNavigator.datasetService.post<any>('/dataset/entity_customer_trip_packages/command/add_to_list', {
								'trip_package_id': record.trip_package_id,
								'list_id': record.list_id
							})
							.subscribe({
								next: response => {
									dialogRef.close();
									if(dataContainerService instanceof DatasetActionContainerService){
										dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
									}
								},
								error: response => {
									const dialogInstance = dialogRef.componentInstance;
									if(dialogInstance instanceof ActionDialogComponent){
										dialogInstance.resultStatus = 'error';
										dialogInstance.setMessage('Si è verificato un errore imprevisto.');
										dialogInstance.loading = false;
									}
								}
							});
						}
					},
					{
						buttonColor: 'warn',
						buttonText: 'Rimuovi',
						actionCode: 'edit',
						showIf(record): boolean{
							return Number(record.enabled) === 1;
						},
						onClick(event, record, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): void{
							const dialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
								title: 'Attendere',
								contentMessage: ''
							});
							datasetNavigator.datasetService.post<any>('/dataset/entity_customer_trip_packages/command/remove_from_list', {
								'trip_package_id': record.trip_package_id,
								'list_id': record.list_id
							})
							.subscribe({
								next: response => {
									dialogRef.close();
									if(dataContainerService instanceof DatasetActionContainerService){
										dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
									}
								},
								error: response => {
									const dialogInstance = dialogRef.componentInstance;
									if(dialogInstance instanceof ActionDialogComponent){
										dialogInstance.resultStatus = 'error';
										dialogInstance.setMessage('Si è verificato un errore imprevisto.');
										dialogInstance.loading = false;
									}
								}
							});
						}
					}
				]
			}
		}
	}
};
