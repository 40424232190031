import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';

import viewConfig from 'app/configs/datasets/actual_trip_groups/forms/view.form';

@Component({
	selector   : 'actual-trip-group-trip-resources-item-view',
	templateUrl: './item.component.html',
	styleUrls  : ['./item.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService]
})
export class ActualTripGroupTripResourceItemViewComponent implements OnInit, OnChanges{

	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() item: any;
	@Output() delete: EventEmitter<void> = new EventEmitter();
	@Output() addItem: EventEmitter<void> = new EventEmitter();

	public viewConfig = viewConfig;

	constructor(
		protected datasetService: DatasetService,
		protected appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService
	){}

	ngOnInit(): void{
		this.datasetRS.init();
	}

	ngOnChanges(changes: SimpleChanges): void{
		this.datasetRS.record.next(this.item);
	}
}
