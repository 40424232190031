import {AfterViewChecked, Component, OnInit} from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackagesStepService } from "../trip-booking-packages-step.service";
import { takeUntil } from "rxjs/operators";

@Component({
	selector   : 'new-trip-booking-package-selection',
	templateUrl: './new-trip-booking-package-selection.component.html',
	styleUrls: ['./new-trip-booking-package-selection.component.scss'],
	providers: []
})
export class NewTripBookingPackageSelectionComponent extends DestroyableComponent implements OnInit{

	public selectionType: string = null;
	public selectionOptions = [];

	constructor(
		public tripBookingPackagesStepService: TripBookingPackagesStepService
	){
		super();
	}

	ngOnInit(): void {
		this.tripBookingPackagesStepService.settingsUpdated
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.updateOptions();
		});

		this.updateOptions();
	}

	updateOptions(){
		const newSelectionOptions = [];
        if(this.tripBookingPackagesStepService.canAddTripBooking) {
            newSelectionOptions.push({
                value: 'trip_packages',
                icon: 'card_travel',
                label: 'Esperienze'
            });
        }

		if(this.tripBookingPackagesStepService.canAddProduct){
			newSelectionOptions.push({
				value: 'products',
				icon: 'shopping_bag',
				label: 'Prodotti'
			});
		}

		if(this.tripBookingPackagesStepService.canAddTicket){
			newSelectionOptions.push({
				value: 'milano_card',
				icon: 'card_giftcard',
				label: 'Milano Card'
			});
		}

		if(this.tripBookingPackagesStepService.canAddTrenitalia){
			newSelectionOptions.push({
				value: 'trenitalia',
				icon: 'train',
				label: 'Biglietti Trenitalia'
			});

			newSelectionOptions.push({
				value: 'trenitalia-carnet',
				icon: 'confirmation_number',
				label: 'Carnet Trenitalia'
			});
		}

		this.selectionOptions = newSelectionOptions;
		if(this.selectionOptions.length == 1 && this.tripBookingPackagesStepService.hasParticipants){
			this.selectionType = this.selectionOptions[0].value;
		}

		// check if selectionType exists in list
		if(this.selectionType && this.selectionOptions.findIndex((el) => el.value == this.selectionType) == -1 ||
            !this.tripBookingPackagesStepService.hasParticipants){
			this.selectionType = null;
		}
	}
}