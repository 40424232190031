import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SearchTravellersComponent } from '../search-travellers/search-travellers.component';
import { TrenitaliaService, trainTypeImages } from '../trenitalia.service';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';

const baseInfoUrl = 'https://www.trenitalia.com/it/html/trenitalia/Info_Tariffe_it_LeFrecce.html#';

@Component({
	selector: 'trenitalia-prices',
	templateUrl: './prices.component.html',
	styleUrls: ['./prices.component.scss']
})
export class PricesComponent extends DestroyableComponent implements OnInit {
	public priceGrid: any;
	@Input('travel-solution') travelSolution: any;
	@Output('select-price') onPriceSelected = new EventEmitter();
	otherOffer: any = false;
	customTravelSolution: any;
	loading = false;
    public errors;

	public templateGridData = {};

	get trainTypeImages(){
		return trainTypeImages;
	}

	constructor(
		/*protected dialogRef: MatDialogRef<SearchTravellersComponent>,*/
		public _trnService: TrenitaliaService,
		/*public dialog: MatDialog*/
	){
		super();
	}

	ngOnInit() {
		this.priceGrid = this.travelSolution.priceGrid;
    	
		this._trnService.errors
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(err =>{
            this.errors = err;
        });

		if(this.travelSolution.require_offer_map){
			this.setOtherOffer();
		}

		this.computeTemplateGrid();
	}

	subTitle(train) {
		let str = '';
		if (train && train.start) {
			str +=
				train.start.stationName +
				'(' +
				new Date(train.departure).toLocaleTimeString(undefined, {
					hour: '2-digit',
					minute: '2-digit'
				}) +
				')';
			str +=
				' - ' +
				train.end.stationName +
				'(' +
				new Date(train.arrival).toLocaleTimeString(undefined, {
					hour: '2-digit',
					minute: '2-digit'
				}) +
				')';
		}
		return str;
	}

	columns(train) {
		const columns = [];
		if (!_.has(this.priceGrid, train.id+'.columns')) return columns; 
		for (const colKey of this.priceGrid[train.id].columns) {
			if (!this._trnService.servicesMap.value.catalog_services[colKey]) {
				continue;
			}
			columns.push(
                this._trnService.servicesMap.value.catalog_services[colKey]
			);
		}
		return columns;
	}

	rows(trainId) {
		const rows = [];
		if (!_.has(this.priceGrid, trainId+'.rows')) return rows; 
		for (const rowKey of this.priceGrid[trainId].rows) {
			if (!this._trnService.servicesMap.value.offers[rowKey]) continue;
			rows.push(this._trnService.servicesMap.value.offers[rowKey]);
		}
		return rows;
	}

	priceSelect(offerId, catalogServiceId, trainId) {
		if (
			!_.has(this.priceGrid, [trainId, 'matrix', offerId, catalogServiceId, 'totalPrice'])
		){
			console.warn('invalid price selection', this.priceGrid, this.travelSolution, offerId, catalogServiceId, trainId);
			return;
		}
		if (
			_.get<number>(this.priceGrid, [trainId, 'matrix', offerId, catalogServiceId, 'availableAmount'], 999) == 0
		) {
			return;
		}
		if(!_.get(this.priceGrid, trainId+'.priceSelected')){
			console.warn('priceSelected not defined', this.priceGrid, this.travelSolution, offerId, catalogServiceId, trainId);
			return;
		}

		this.priceGrid[trainId].priceSelected.row = offerId;
		this.priceGrid[trainId].priceSelected.col = catalogServiceId;
		this.onPriceSelected.emit({
			offer: offerId,
			catalog: catalogServiceId,
			train: trainId
		});
		this.computeTemplateGrid();
	}

	setOtherOffer() {
		this.otherOffer = true;
		this.loading = true;
		this._trnService.loading.next(true);
		this._trnService
		.getOffers({
			travelSolutions: [this.travelSolution.original],
			travellers: this.travelSolution.travellers
		})
		.subscribe(off => {
			this.customTravelSolution = off;
            this.customTravelSolution.servicesMap = off.servicesMap || off.serviceMap;
            this._trnService.updateServicesMap(this.customTravelSolution.servicesMap)
			this.customTravelSolution.travelSolutions[0].travellers = _.get(off,'travellers');
			this.customTravelSolution.travelSolutions[0].custom_off = {};
			this._trnService.loading.next(false);
			this.loading = false;
		}, error => {
			// TODO: handle error
			this._trnService.loading.next(false);
			this.loading = false;
		});
	}

	onCustomOfferPriceSelected(e) {
		/*if (!this.travelSolution['customOffer']) {
            this.travelSolution['customOffer'] = [];
        }
        this.travelSolution['customOffer'] = e;*/
		this.onPriceSelected.emit({
			sol: this.customTravelSolution
		});
		this.computeTemplateGrid();
	}

	backToGrid(){
		this.otherOffer = false;
		this.onPriceSelected.emit({
			travelSolution: this.travelSolution
		});
		this.computeTemplateGrid();
	}

	openInfo(row) {
		window.open(baseInfoUrl+row, '_blank', 'width:500,height=500');
		/*this.dialog.open(InfoDialog, {
			data: row
		});*/
	}

	/**
	 * TODO: rework check carta freccia
	 * @param priceGrid 
	 * @returns 
	 */
	checkCF(priceGrid) {
		if(!_.has(priceGrid, 'priceSelected.row')) return false;
		let sel =
			priceGrid.matrix[priceGrid.priceSelected.row][
				priceGrid.priceSelected.col
			];
		if (!sel.additionalDataRequired) return false;
		let off = this.travelSolution.offeredServices[sel.offers[0]];
		if (off.messages[0].includes('Cartafreccia')) return true;
	}

	indexTrackBy(index, item): any{
		return index;
	}

	/**
	 * Can be improuved by cache result
	 * @param trainid 
	 * @param rowid 
	 * @param colid 
	 * @returns 
	 */
	cellContext(trainid, rowid, colid){
		return {
			isSelected: this.cellIsSelected(trainid, rowid, colid), 
			price: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPrice']), 
			availableAmount: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'availableAmount']), 
			rowid, colid, trainid,
			hasPrice: this.cellHasPrice(trainid, rowid, colid),
            fee:_.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'fee']),
            totalPriceNet:_.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPriceNet'])
		};
	}

	cellHasPrice(trainid, rowid, colid){
		return typeof(_.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPrice'])) == 'number';
	}

	cellIsSelected(trainid, rowid, colid){
		if(!_.get(this.priceGrid, [trainid, 'priceSelected'])) return false;
		if(typeof(rowid) == 'number' && _.get(this.priceGrid, [trainid, 'priceSelected', 'row']) != rowid) return false;
		if(typeof(colid) == 'number' && _.get(this.priceGrid, [trainid, 'priceSelected', 'col']) != colid) return false;
		return true;
	}

	computeTemplateGrid(){
		const newTemplateGridData = {};
		for (const train of this.travelSolution.trains) {
			const templateGridTrainData = {rows: [], cols: [], matrix: []};
			for (const col of this.columns(train)) {
				templateGridTrainData.cols.push({
					col,
					id: col.id,
					isSelected: this.cellIsSelected(train.id, null, col.id)
				});
			}
			for (const row of this.rows(train.id)) {
				templateGridTrainData.rows.push({
					isSelected: this.cellIsSelected(train.id, row.id, null),
					row,
					id: row.id,
				});

				for (const col of templateGridTrainData.cols) {
					_.set(templateGridTrainData.matrix, [row.id, col.id], this.cellContext(train.id, row.id, col.id));
				}
			}
			newTemplateGridData[train.id] = templateGridTrainData;
		}
		this.templateGridData = newTemplateGridData;
	}
}

@Component({
	selector: 'info-dialog',
	templateUrl: 'info-dialog.html'
})
export class InfoDialog {
	public url = baseInfoUrl;

	constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
		this.url += this.data;
	}
}
