import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { GenerateActualTripDialogComponent } from 'app/main/components/dataset/dialogs/generate-actual-trip-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import * as moment from 'moment';
import { StepService } from 'app/main/components/dataset/services/step-state.service';

@Component({
	selector   : 'actual-trips-smart-interface',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
})
export class ActualTripsSmartInterfaceComponent extends BaseStepViewComponent implements OnInit{
	
	formErrors: any;
	@ViewChild('tripList', { static: false }) tripList: DatasetListComponent;
	public todayFilter = JSON.stringify(['>=', moment.utc().startOf('day')]);

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		private dialog: MatDialog,
		public stepService: StepService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.stepService.setStepState({
			dataset_code: 'trip_packages',
			form_code: 'actual_trips',
			record_id: this.datasetRS.recordId,
			status: 1
		});
	}

	generate(): void{
		this.dialog.open(GenerateActualTripDialogComponent, {
			data: {
				trip_package: this.datasetRS.record.value,
				datasetACS: this.datasetACS,
				postData: {
					trip_package_id: this.datasetRS.recordId
				}
			}
		}).afterClosed().subscribe(result => {
			if(result){
				this.tripList.reload();
			}
		});
	}
}
