import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import chartColors from './../chart-colors';
var TicketComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TicketComponent, _super);
    function TicketComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        /**
         * Chart Ticket
        */
        _this.barChartOptions = {
            responsive: true,
            onResize: function (chart) {
                chart.data.datasets.forEach(function (chartDataset) {
                    var gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom / 1.5, 0, chart.chartArea.bottom);
                    gradient.addColorStop(0, chartDataset.hoverBackgroundColor.toString());
                    gradient.addColorStop(1, 'white');
                    chartDataset.backgroundColor = gradient;
                });
                /**
                 * when data or style changes update chart
                 */
                chart.update();
            },
            scales: {
                xAxes: [{}],
                yAxes: [{}]
            },
            legend: {
                display: true,
                position: 'bottom',
                fullWidth: true,
                labels: {
                    usePointStyle: true,
                    padding: 10,
                }
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(tooltipItem.yLabel);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            }
        };
        _this.chartPlugins = [makeDrawValuePlugin()];
        _this.barChartLabels = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
        _this.barChartType = 'bar';
        _this.barChartLegend = true;
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    TicketComponent.prototype.ngOnInit = function () {
        this.tickets = [{ data: [] }];
    };
    TicketComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dashboardService.checkoutDateResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.dashboardService.groupingBy.value !== 'checkout_date')
                    return;
                if (response)
                    _this.onData(response);
            }
        });
        this.dashboardService.travelDateResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.dashboardService.groupingBy.value !== 'departure_date')
                    return;
                if (response)
                    _this.onData(response);
            }
        });
    };
    // -----------------------------------------------------------------------------------------------------
    TicketComponent.prototype.onData = function (response) {
        var e_1, _a;
        var r = [];
        try {
            for (var response_1 = tslib_1.__values(response), response_1_1 = response_1.next(); !response_1_1.done; response_1_1 = response_1.next()) {
                var item = response_1_1.value;
                if (!item.year)
                    continue;
                var year = item.year;
                var month = item.month - 1;
                if (!r[year])
                    r[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                if (!r[year][month])
                    r[year][month] = 0;
                r[year][month] += Number(item.pax_count);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (response_1_1 && !response_1_1.done && (_a = response_1.return)) _a.call(response_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var data = [];
        var years = Object.keys(r);
        for (var y = 0; y < years.length; y++) {
            var year = years[y];
            var colorIndex = years.length - y - 1;
            var chartArea = this.chartComponent.chart.chartArea;
            var gradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom / 1.5, 0, chartArea.bottom);
            gradient.addColorStop(0, chartColors[colorIndex]);
            gradient.addColorStop(1, 'white');
            data.push({
                data: r[year],
                label: String(year),
                fill: false,
                backgroundColor: gradient,
                borderColor: chartColors[colorIndex],
                hoverBackgroundColor: chartColors[colorIndex]
            });
        }
        if (!data || data.length === 0) {
            this.showLoader = true;
            this.tickets = [{ data: [] }];
        }
        else {
            this.showLoader = false;
            this.tickets = data;
        }
    };
    return TicketComponent;
}(DestroyableComponent));
export { TicketComponent };
