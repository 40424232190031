import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TrenitaliaService, trainTypeImages } from '../trenitalia.service';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var baseInfoUrl = 'https://www.trenitalia.com/it/html/trenitalia/Info_Tariffe_it_LeFrecce.html#';
var PricesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PricesComponent, _super);
    function PricesComponent(
    /*protected dialogRef: MatDialogRef<SearchTravellersComponent>,*/
    _trnService) {
        var _this = _super.call(this) || this;
        _this._trnService = _trnService;
        _this.onPriceSelected = new EventEmitter();
        _this.otherOffer = false;
        _this.loading = false;
        _this.templateGridData = {};
        return _this;
    }
    Object.defineProperty(PricesComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    PricesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.priceGrid = this.travelSolution.priceGrid;
        this._trnService.errors
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (err) {
            _this.errors = err;
        });
        if (this.travelSolution.require_offer_map) {
            this.setOtherOffer();
        }
        this.computeTemplateGrid();
    };
    PricesComponent.prototype.subTitle = function (train) {
        var str = '';
        if (train && train.start) {
            str +=
                train.start.stationName +
                    '(' +
                    new Date(train.departure).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                    }) +
                    ')';
            str +=
                ' - ' +
                    train.end.stationName +
                    '(' +
                    new Date(train.arrival).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit'
                    }) +
                    ')';
        }
        return str;
    };
    PricesComponent.prototype.columns = function (train) {
        var e_1, _a;
        var columns = [];
        if (!_.has(this.priceGrid, train.id + '.columns'))
            return columns;
        try {
            for (var _b = tslib_1.__values(this.priceGrid[train.id].columns), _c = _b.next(); !_c.done; _c = _b.next()) {
                var colKey = _c.value;
                if (!this._trnService.servicesMap.value.catalog_services[colKey]) {
                    continue;
                }
                columns.push(this._trnService.servicesMap.value.catalog_services[colKey]);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return columns;
    };
    PricesComponent.prototype.rows = function (trainId) {
        var e_2, _a;
        var rows = [];
        if (!_.has(this.priceGrid, trainId + '.rows'))
            return rows;
        try {
            for (var _b = tslib_1.__values(this.priceGrid[trainId].rows), _c = _b.next(); !_c.done; _c = _b.next()) {
                var rowKey = _c.value;
                if (!this._trnService.servicesMap.value.offers[rowKey])
                    continue;
                rows.push(this._trnService.servicesMap.value.offers[rowKey]);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return rows;
    };
    PricesComponent.prototype.priceSelect = function (offerId, catalogServiceId, trainId) {
        if (!_.has(this.priceGrid, [trainId, 'matrix', offerId, catalogServiceId, 'totalPrice'])) {
            console.warn('invalid price selection', this.priceGrid, this.travelSolution, offerId, catalogServiceId, trainId);
            return;
        }
        if (_.get(this.priceGrid, [trainId, 'matrix', offerId, catalogServiceId, 'availableAmount'], 999) == 0) {
            return;
        }
        if (!_.get(this.priceGrid, trainId + '.priceSelected')) {
            console.warn('priceSelected not defined', this.priceGrid, this.travelSolution, offerId, catalogServiceId, trainId);
            return;
        }
        this.priceGrid[trainId].priceSelected.row = offerId;
        this.priceGrid[trainId].priceSelected.col = catalogServiceId;
        this.onPriceSelected.emit({
            offer: offerId,
            catalog: catalogServiceId,
            train: trainId
        });
        this.computeTemplateGrid();
    };
    PricesComponent.prototype.setOtherOffer = function () {
        var _this = this;
        this.otherOffer = true;
        this.loading = true;
        this._trnService.loading.next(true);
        this._trnService
            .getOffers({
            travelSolutions: [this.travelSolution.original],
            travellers: this.travelSolution.travellers
        })
            .subscribe(function (off) {
            _this.customTravelSolution = off;
            _this.customTravelSolution.servicesMap = off.servicesMap || off.serviceMap;
            _this._trnService.updateServicesMap(_this.customTravelSolution.servicesMap);
            _this.customTravelSolution.travelSolutions[0].travellers = _.get(off, 'travellers');
            _this.customTravelSolution.travelSolutions[0].custom_off = {};
            _this._trnService.loading.next(false);
            _this.loading = false;
        }, function (error) {
            // TODO: handle error
            _this._trnService.loading.next(false);
            _this.loading = false;
        });
    };
    PricesComponent.prototype.onCustomOfferPriceSelected = function (e) {
        /*if (!this.travelSolution['customOffer']) {
            this.travelSolution['customOffer'] = [];
        }
        this.travelSolution['customOffer'] = e;*/
        this.onPriceSelected.emit({
            sol: this.customTravelSolution
        });
        this.computeTemplateGrid();
    };
    PricesComponent.prototype.backToGrid = function () {
        this.otherOffer = false;
        this.onPriceSelected.emit({
            travelSolution: this.travelSolution
        });
        this.computeTemplateGrid();
    };
    PricesComponent.prototype.openInfo = function (row) {
        window.open(baseInfoUrl + row, '_blank', 'width:500,height=500');
        /*this.dialog.open(InfoDialog, {
            data: row
        });*/
    };
    /**
     * TODO: rework check carta freccia
     * @param priceGrid
     * @returns
     */
    PricesComponent.prototype.checkCF = function (priceGrid) {
        if (!_.has(priceGrid, 'priceSelected.row'))
            return false;
        var sel = priceGrid.matrix[priceGrid.priceSelected.row][priceGrid.priceSelected.col];
        if (!sel.additionalDataRequired)
            return false;
        var off = this.travelSolution.offeredServices[sel.offers[0]];
        if (off.messages[0].includes('Cartafreccia'))
            return true;
    };
    PricesComponent.prototype.indexTrackBy = function (index, item) {
        return index;
    };
    /**
     * Can be improuved by cache result
     * @param trainid
     * @param rowid
     * @param colid
     * @returns
     */
    PricesComponent.prototype.cellContext = function (trainid, rowid, colid) {
        return {
            isSelected: this.cellIsSelected(trainid, rowid, colid),
            price: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPrice']),
            availableAmount: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'availableAmount']),
            rowid: rowid, colid: colid, trainid: trainid,
            hasPrice: this.cellHasPrice(trainid, rowid, colid),
            fee: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'fee']),
            totalPriceNet: _.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPriceNet'])
        };
    };
    PricesComponent.prototype.cellHasPrice = function (trainid, rowid, colid) {
        return typeof (_.get(this.priceGrid, [trainid, 'matrix', rowid, colid, 'totalPrice'])) == 'number';
    };
    PricesComponent.prototype.cellIsSelected = function (trainid, rowid, colid) {
        if (!_.get(this.priceGrid, [trainid, 'priceSelected']))
            return false;
        if (typeof (rowid) == 'number' && _.get(this.priceGrid, [trainid, 'priceSelected', 'row']) != rowid)
            return false;
        if (typeof (colid) == 'number' && _.get(this.priceGrid, [trainid, 'priceSelected', 'col']) != colid)
            return false;
        return true;
    };
    PricesComponent.prototype.computeTemplateGrid = function () {
        var e_3, _a, e_4, _b, e_5, _c, e_6, _d;
        var newTemplateGridData = {};
        try {
            for (var _e = tslib_1.__values(this.travelSolution.trains), _f = _e.next(); !_f.done; _f = _e.next()) {
                var train = _f.value;
                var templateGridTrainData = { rows: [], cols: [], matrix: [] };
                try {
                    for (var _g = (e_4 = void 0, tslib_1.__values(this.columns(train))), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var col = _h.value;
                        templateGridTrainData.cols.push({
                            col: col,
                            id: col.id,
                            isSelected: this.cellIsSelected(train.id, null, col.id)
                        });
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
                try {
                    for (var _j = (e_5 = void 0, tslib_1.__values(this.rows(train.id))), _k = _j.next(); !_k.done; _k = _j.next()) {
                        var row = _k.value;
                        templateGridTrainData.rows.push({
                            isSelected: this.cellIsSelected(train.id, row.id, null),
                            row: row,
                            id: row.id,
                        });
                        try {
                            for (var _l = (e_6 = void 0, tslib_1.__values(templateGridTrainData.cols)), _m = _l.next(); !_m.done; _m = _l.next()) {
                                var col = _m.value;
                                _.set(templateGridTrainData.matrix, [row.id, col.id], this.cellContext(train.id, row.id, col.id));
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                newTemplateGridData[train.id] = templateGridTrainData;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.templateGridData = newTemplateGridData;
    };
    return PricesComponent;
}(DestroyableComponent));
export { PricesComponent };
var InfoDialog = /** @class */ (function () {
    function InfoDialog(data) {
        this.data = data;
        this.url = baseInfoUrl;
        this.url += this.data;
    }
    return InfoDialog;
}());
export { InfoDialog };
