import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PublicDomainsConfig } from 'app/configs/datasets/public_domains/index';
import { PublicTripPackageConfig } from 'app/configs/datasets/public_trip_packages/index';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
var MarketplaceComponent = /** @class */ (function () {
    function MarketplaceComponent(appService, domainFilterService, datasetNavigatorService, snackBar) {
        this.appService = appService;
        this.domainFilterService = domainFilterService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.snackBar = snackBar;
    }
    MarketplaceComponent.prototype.handleDomainChanges = function () {
        if (this.domain !== null) {
            this.goBack();
        }
        return true;
    };
    MarketplaceComponent.prototype.onSelected = function (record) {
        this.domain = record;
    };
    MarketplaceComponent.prototype.goBack = function () {
        this.domain = null;
    };
    MarketplaceComponent.prototype.onPackageSelected = function (record) {
        var _this = this;
        this.action(record).then(function (resp) {
            if (!resp.success) {
                _this.appService.showErrorMessage(resp.messagge);
            }
            else {
                _this.appService.showSuccessMessage(resp.messagge);
            }
        })
            .catch(function (e) {
            console.error(e);
        });
    };
    MarketplaceComponent.prototype.action = function (record) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loadingDialogRef, response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadingDialogRef = this.datasetNavigatorService.openDialog(ActionDialogComponent, {
                            title: 'Recupero dati in corso',
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.datasetNavigatorService.datasetService
                                .post('/dataset/domain_packages/command/set_packages', {
                                trip_package_id: record.id,
                            }).toPromise()];
                    case 2:
                        response = _a.sent();
                        if (response) {
                            loadingDialogRef.componentInstance.loading = false;
                            if (response.success) {
                                loadingDialogRef.componentInstance.resultStatus = 'success';
                                loadingDialogRef.componentInstance.setTitle('Richiesta inviata');
                                loadingDialogRef.componentInstance.setMessage('Puoi vedere lo stato delle tue richieste nella sezione apposita che puoi trovare nel menu laterale.');
                            }
                            else {
                                loadingDialogRef.componentInstance.resultStatus = 'error';
                                loadingDialogRef.componentInstance.setTitle('Errore');
                                loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
                            }
                        }
                        return [2 /*return*/, response];
                    case 3:
                        e_1 = _a.sent();
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore');
                        loadingDialogRef.componentInstance.setMessage('Errore imprevisto');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MarketplaceComponent.prototype.ngOnInit = function () {
        this.domainViewConfig = PublicDomainsConfig.defaultActions.list.viewConfig;
        this.packageViewConfig = PublicTripPackageConfig.defaultActions.list.viewConfig;
    };
    return MarketplaceComponent;
}());
export { MarketplaceComponent };
