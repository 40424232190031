import {Component, Inject, OnDestroy, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IFormConfig } from 'app/interfaces';
import { POPOVER_DATA } from '../popover/popover.service';
import { PopoverRef } from '../popover/popover-ref';

export interface IFormDialogData {
	title?: string;
	formData?: any;
	message?: string;
	formConfig: IFormConfig;
	dataContainerService?: IDataContainerService;
	negativeText?: string;
	positiveText?: string;
	formErrors?: any;
}

@Component({
	selector: 'form-popover',
	templateUrl: 'popover.component.html',
	styleUrls: ['./popover.component.scss']
})
export class FormPopoverComponent implements OnDestroy, OnInit, AfterViewInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;
	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;

	constructor(
		public appService: AppService,
		private popoverRef: PopoverRef,
		@Inject(POPOVER_DATA) public data: IFormDialogData) {
			this._unsubscribeAll = new Subject();
		}

	ngOnInit(): void{}

	ngAfterViewInit(): void{
		this.setErrors(this.data.formErrors);
	}

	onNoClick(): void {
		this.popoverRef.close(false);
	}

	onYesClick(): void{
		const data = this.formViewer.getFormData();
		if (this.formViewer.formGroup.valid) {
			this.popoverRef.close(data);
		}
	}
	
	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	submit(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.onYesClick();
	}

	setErrors(errors: any): any{
		if(this.formViewer) this.formViewer.setErrors(errors);
	}
}
