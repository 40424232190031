<div *ngIf="offersMap">
	<div *ngFor="let travel of travelSolution.travellers index as i" class="traveller">
        <div *ngIf="!selectedCustomizedOffers[travel.xmlId]" class="text-center" style="color:red">
            Nessun servizio disponibile
        </div>

        <ng-container *ngIf="selectedCustomizedOffers[travel.xmlId]">
            <h2>Passeggero {{i + 1}}</h2>
            <span>{{travel.type == 'ADULT' ? 'Adulto' : 'Ragazzo'}}</span>
            <div class="row flex-row flex-gap-8" >
                <mat-form-field class="flex">
                    <mat-label>Servizio</mat-label>
                    <mat-select [value]="selectedCustomizedOffers[travel.xmlId].catalogServiceId"
                                (selectionChange)="changeService($event,travel.xmlId)">
                        <mat-option *ngFor="let serv of services[travel.xmlId]" [value]="serv.id">
                            {{serv.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="flex">
                    <mat-label>Offerta</mat-label>
                    <mat-select [value]="selectedCustomizedOffers[travel.xmlId].offerId"
                                (selectionChange)="changeOffer($event,travel.xmlId)">
                        <mat-option *ngFor="let off of offers[travel.xmlId]" [value]="off.id">
                            {{off.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="errors && errors[travel.xmlId]" class="alert-error">
                <div *ngFor=" let mess of errors[travel.xmlId].messages; let last = last">
                    <span *ngIf="mess.length"><mat-icon>error</mat-icon>{{mess}} <span *ngIf="!last"> - </span></span>
                </div>
            </div>
            <ng-container *ngIf="selectedCustomizedOffers[travel.xmlId] && selectedCustomizedOffers[travel.xmlId].offerData">
                <div class="price">
                    <span class="price"
                        matTooltip="{{selectedCustomizedOffers[travel.xmlId].fee>0?'Prezzo:'+
                        (selectedCustomizedOffers[travel.xmlId].netPrice| currency:'EUR':'symbol':'1.2-2')
                        +' Diritti agenzia'+ (selectedCustomizedOffers[travel.xmlId].fee| currency:'EUR':'symbol':'1.2-2'):''}}">
                        € {{selectedCustomizedOffers[travel.xmlId].price}}
                    </span>
                </div>
                <div class="row" class="description"
                     *ngIf="selectedCustomizedOffers[travel.xmlId].offerData.description">
                    <h2>{{selectedCustomizedOffers[travel.xmlId].offerData.display_name}}</h2>
                    <span>{{selectedCustomizedOffers[travel.xmlId].offerData.description}}</span>
                </div>
                <div class="row">
                <span>
                    <span *ngIf="selectedCustomizedOffers[travel.xmlId].offerData.can_change">
                        <mat-icon color="primary">check_circle</mat-icon>
                    </span>
                    <span *ngIf="!selectedCustomizedOffers[travel.xmlId].offerData.can_change">
                        <mat-icon color="warn">remove_circle</mat-icon>
                    </span>
                    Cambio
                </span>
                    <span>
                    <span *ngIf="selectedCustomizedOffers[travel.xmlId].offerData.can_refund">
                        <mat-icon color="primary">check_circle</mat-icon>
                    </span>
                    <span *ngIf="!selectedCustomizedOffers[travel.xmlId].offerData.can_refund">
                        <mat-icon color="warn">remove_circle</mat-icon>
                    </span>
                    Rimborso
                </span>
                </div>
                <ng-container *ngIf="selectedCustomizedOffers[travel.xmlId].parameters">

                </ng-container>
                <div class="row" *ngFor="let offerParameter of selectedCustomizedOffers[travel.xmlId].parameters; index as offerParamIndex">
                    <mat-form-field fxFlex appearance="fill">
                        <mat-label>{{offerParameter.name}}</mat-label>
                        <mat-select [(ngModel)]="selectedCustomizedOffers[travel.xmlId].parameters[offerParamIndex].value" (selectionChange)="sendSelection()">
                            <mat-option *ngFor="let paramValue of offerParameter.values; index as i" [value]="paramValue">
                                {{paramValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row" fxLayout="row wrap" fxLayoutGap="8px" *ngIf="selectedCustomizedOffers[travel.xmlId].cartaFrecciaRequired">
                    <mat-form-field appearance="outline">
                        <mat-label><span>CartaFRECCIA</span></mat-label>
                        <input matInput placeholder="CartaFRECCIA" autocomplete="off"
                               type='text' [(ngModel)]="travel.cartaFreccia" maxlength="30" required>
                        <mat-hint>Obbligatoria</mat-hint>
                    </mat-form-field>
                </div>
            </ng-container>
        </ng-container>
	</div>
</div>

