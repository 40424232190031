<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='reselledDatasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<ng-container *ngIf='!reselledDatasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è ancora presente!' | translate}}</p>

			<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
				<button mat-flat-button (click)='goPrevStep()'>Indietro</button>
			</div>
		</ng-container>

		<ng-container *ngIf='reselledDatasetRS.record.value && reselledDatasetRS.record.value["lang"] === appService.language.value'>
			<mat-tab-group>
				<mat-tab [label]='"Informazioni Utili" | translate'>
					<ng-template matTabContent>
						<mat-accordion *ngIf='originalDatasetRS.record.value'>
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title>
										Dati originali
									</mat-panel-title>
									<mat-panel-description>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<dataset-list
									[enableEdit]='false'
									datasetCode='pivot_trip_packages_trip_facilities'
									[parentDatasetRS]='originalDatasetRS'
									[parentDatasetACS]='originalDatasetACS'
									[showSearchBar]='false'
									[elevate]='false'
									[showTitle]='false'
								></dataset-list>
							</mat-expansion-panel>
						</mat-accordion>
						<dataset-list
							datasetCode='pivot_trip_packages_trip_facilities'
							[parentDatasetRS]='reselledDatasetRS'
							[parentDatasetACS]='datasetACS'
							[showSearchBar]='false'
							[elevate]='false'
							[showTitle]='false'
						></dataset-list>
					</ng-template>
				</mat-tab>
				<mat-tab [label]='"Immagine di copertina" | translate'>
					<ng-template matTabContent>
						<mat-accordion v-if='originalDatasetRS.record.value'>
							<mat-expansion-panel>
								<mat-expansion-panel-header>
									<mat-panel-title>
										Dati originali
									</mat-panel-title>
									<mat-panel-description>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<document-file-block	
									[record]='originalDatasetRS.record.value'
									[documentFileKey]='"master_image"'
									[imageOptions]='{minWidth: 1920, minHeight: 400, ratio: 1920 / 400}'
									[type]='"image"'
									(changed)='originalDatasetRS.reload()'
								></document-file-block>
							</mat-expansion-panel>
						</mat-accordion>
						<document-file-block	
							[record]='reselledDatasetRS.record.value'
							[documentFileKey]='"master_image"'
							[imageOptions]='{minWidth: 1920, minHeight: 400, ratio: 1920 / 400}'
							[type]='"image"'
							[uploadRoute]='"/dataset/trip_package_language_contents/command/upload_master_image"'
							[uploadData]='{trip_package_language_content_id: reselledDatasetRS.record.value.id}'
							(changed)='reselledDatasetRS.reload()'
						></document-file-block>
					</ng-template>
				</mat-tab>
				<mat-tab [label]='"Immagini" | translate'>
					<ng-template matTabContent>
						<mat-accordion>
							<mat-expansion-panel v-if='originalDatasetRS.record.value'>
								<mat-expansion-panel-header>
									<mat-panel-title>
										Dati originali
									</mat-panel-title>
									<mat-panel-description>
									</mat-panel-description>
								</mat-expansion-panel-header>
								<carousel-block
									[showTitle]='false'
									[parentDatasetACS]='originalDatasetACS'
									[parentDatasetRS]='originalDatasetRS'
									[configs]='carouselBlockConfigs'
								></carousel-block>
							</mat-expansion-panel>
						</mat-accordion>
						<carousel-block
							[showTitle]='false'
							[parentDatasetACS]='datasetACS'
							[parentDatasetRS]='reselledDatasetRS'
							[configs]='carouselBlockConfigs'
						></carousel-block>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</ng-container>

	</div>
</div>