import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
var UserRolePermissionStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserRolePermissionStepComponent, _super);
    function UserRolePermissionStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.checked = false;
        _this.loading = false;
        _this.changingGroupAccess = {};
        _this.isAdmin = false;
        return _this;
    }
    UserRolePermissionStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getPermissions();
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (!record)
                return;
            _this.isAdmin = record.level < 10;
        });
        /*this.authService.userDataObserver
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(userData => {
            this.isAdmin = this.authService.isAdministrator();
        });*/
    };
    UserRolePermissionStepComponent.prototype.getPermissions = function () {
        var _this = this;
        this.loading = true;
        this.datasetService.post('/dataset/user_roles/command/get_permissions', {
            user_role_id: this.datasetRS.record.value.id
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.rolePermissions = response;
                _this.loading = false;
                if (!_this.loading) {
                    _this.isCheckboxChecked();
                }
            },
            error: function () {
                _this.loading = false;
            }
        });
    };
    UserRolePermissionStepComponent.prototype.isCheckboxChecked = function () {
        var permission = this.findPermissionByCode('booking_group');
        if (!permission)
            return;
        var action = this.getAction(permission, 'edit_booking_date');
        if (action && action.id)
            this.checked = true;
    };
    UserRolePermissionStepComponent.prototype.findPermissionByCode = function (code) {
        return this.rolePermissions.find(function (permission) { return permission.code === code; });
    };
    UserRolePermissionStepComponent.prototype.getAction = function (permission, actionCode) {
        return permission.actions.find(function (action) { return action.code === actionCode; });
    };
    UserRolePermissionStepComponent.prototype.changeAccessType = function (group, level) {
        var _this = this;
        this.changingGroupAccess[group.id] = true;
        this.datasetService.post('/dataset/user_roles/command/set_access_level', {
            user_role_id: this.datasetRS.record.value.id,
            dataset_group_definition_id: group.id,
            access_level: level
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function () {
                delete _this.changingGroupAccess[group.id];
            },
            error: function () {
                delete _this.changingGroupAccess[group.id];
            }
        });
    };
    UserRolePermissionStepComponent.prototype.commandSetPermission = function (group, action) {
        var _this = this;
        this.datasetService.post('/dataset/user_roles/command/set_permissions', {
            dataset_action_definition_id: action.action_id,
            user_role_id: this.datasetRS.record.value.id,
            dataset_group_definition_id: group.id
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function () {
                _this.getPermissions();
            },
            error: function () { }
        });
    };
    UserRolePermissionStepComponent.prototype.commandUnsetPermission = function (action) {
        var _this = this;
        this.datasetService.post('/dataset/user_roles/command/unset_permissions', {
            dataset_role_allowed_action_id: action.id,
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function () {
                _this.getPermissions();
            },
            error: function () { }
        });
    };
    UserRolePermissionStepComponent.prototype.changeState = function (group, action, active) {
        if (active) {
            this.commandSetPermission(group, action);
        }
        else {
            this.commandUnsetPermission(action);
        }
    };
    return UserRolePermissionStepComponent;
}(BaseStepViewComponent));
export { UserRolePermissionStepComponent };
