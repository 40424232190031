import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import createForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_resource_ticket_files/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripResourceId = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
	if(tripResourceId){
		params['trip_resource_id'] =  tripResourceId;
	}
	return params;
}

export const TripResourceTicketFileConfig: IDatasetConfig = {
	name: 'trip_resource_ticket_files',
	title: 'File',
	singleTitle: 'File',
	ajaxDatasetCode: 'trip_resource_ticket_files',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		createForm,
		//detailForm,
		//editForm
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Nome file',
						key: 'document_file_id'
					},
					{
						title: 'Numero di download',
						key: 'download_count',
						showIf(dataContainerService) {
							const resourceSubTypeCode = dataContainerService.getValueFromKeyPath('trip_resources.record.properties.resource_sub_types.value');
							return resourceSubTypeCode === 'TICKETPDF';
						}
					}
				],
				actions: ['delete'],
				customActions: [
					{
						isGlobal: true,
						buttonText: 'Aggiungi',
						actionCode: 'view',
						buttonColor: 'primary',
						buttonIcon: 'add',
						tooltip: 'Aggiungi',
						showIf: () => true,
						onClick(event, record, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IFormDialogData>(FormDialogComponent, {
								title: 'Nuovo File',
								message: 'Puoi inserire uno o più file di tipo PDF o JPEG/JPG.',
								dataContainerService: dataContainerService,
								formConfig: createForm,
								positiveText: 'Salva',
								negativeText: 'Annulla',
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									minWidth: 550,
									data: {
										title: 'Caricamento file in corso'
									}
								});
								result.append('trip_resource_id', dataContainerService.getValueFromKeyPath('trip_resources.recordId'));
								datasetNavigatorService.datasetService.post<any>('/dataset/trip_resource_ticket_files/command/save_files', result)
								.subscribe({
									next: () => {
										dialogRef.close();
									
										if(dataContainerService instanceof DatasetActionContainerService){
											dataContainerService.datasetEvent.next({ 
												eventName: DatasetEvents.ACTION_REALOAD_LIST 
											});
										}
										
										datasetNavigatorService.appService.showSuccessMessage('File caricati con successo.');
									},
									error: () => {
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.setMessage('Si è verificato un errore');
									}
								});
							});
						}
					},
					{
						buttonColor: 'primary',
						buttonIcon: 'file_copy',
						tooltip: 'download',
						showIf: () => true,
						onClick(event, record, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							dataContainerService.appService.downloadUrl(dataContainerService.appService.getBaseServerAddressRoot() + '/document_files/download/' + record.document_file_id);
						}
					}
				],
			}
		}
	}
};
