import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
var WeekHoursComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WeekHoursComponent, _super);
    function WeekHoursComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        //public serviceDays: IDay[];
        _this.DAYS = [
            { code: 'monday', day: 'Lunedì' },
            { code: 'tuesday', day: 'Martedì' },
            { code: 'wednesday', day: 'Mercoledì' },
            { code: 'thursday', day: 'Giovedì' },
            { code: 'friday', day: 'Venerdì' },
            { code: 'saturday', day: 'Sabato' },
            { code: 'sunday', day: 'Domenica' }
        ];
        return _this;
    }
    Object.defineProperty(WeekHoursComponent.prototype, "serviceDays", {
        get: function () {
            return this.inputService.control.value ? this.inputService.control.value : this.DAYS;
        },
        enumerable: true,
        configurable: true
    });
    WeekHoursComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            //this.serviceDays = this.inputService.control.value;
            //this.addNewItem();
            _this.inputService.control.setValue(_this.serviceDays);
            _this.inputService.formGroup.updateValueAndValidity();
        });
    };
    WeekHoursComponent.prototype.addTime = function (day) {
        day.time = day.time ? day.time : [];
        day.time.push({ opening: '', closure: '' });
    };
    WeekHoursComponent.prototype.removeTime = function (times, time) {
        var index = times.indexOf(time);
        if (index > -1) {
            times.splice(index, 1);
        }
    };
    return WeekHoursComponent;
}(DestroyableComponent));
export { WeekHoursComponent };
