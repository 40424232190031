<div *ngIf='actionButtons && actionButtons.length > 0' class='search-filter-action-buttons-mobile mr-8 pr-8'>
	<ng-container *ngFor='let customAction of actionButtons;trackBy: indexTrackBy'>
		<button
			mat-flat-button
			class='mr-4'
			[matTooltip]='customAction.tooltip' 
			[color]='customAction.buttonColor' 
			(click)='customAction.onClick($event, null, dataContainerService, datasetNavigatorService)'>
			<mat-icon *ngIf='customAction.buttonIcon'>{{customAction.buttonIcon}}</mat-icon>
			<span *ngIf='customAction.buttonText' [class.ml-4]='customAction.buttonIcon'>{{customAction.buttonText | translate}}</span>
		</button>
	</ng-container>
</div>
<div class='search-filter-box' fxLayout='row' fxLayoutAlign='start center' *ngIf='(actionButtons && actionButtons.length > 0) || showSearchText || formConfig'>
	<div *ngIf='actionButtons && actionButtons.length > 0' class='search-filter-action-buttons mr-8 pr-8'>
		<ng-container *ngFor='let customAction of actionButtons;trackBy: indexTrackBy'>
			<button
				mat-flat-button
				class='mr-4'
				[matTooltip]='customAction.tooltip' 
				[color]='customAction.buttonColor' 
				(click)='customAction.onClick($event, null, dataContainerService, datasetNavigatorService)'>
				<mat-icon *ngIf='customAction.buttonIcon'>{{customAction.buttonIcon}}</mat-icon>
				<span *ngIf='customAction.buttonText' [class.ml-4]='customAction.buttonIcon'>{{customAction.buttonText | translate}}</span>
			</button>
		</ng-container>
	</div>
	<ng-container *ngIf='showSearchText'>
		<mat-icon>search</mat-icon>
		<input fxFlex type='text' [placeholder]="'Cerca' | translate" (keydown.enter)='applyFilter(null)' autocomplete="off" [formControl]='defaultSearchControl'/>		
		<mat-icon *ngIf='!formConfig && defaultSearchControl.value' style='cursor: pointer;' (click)='defaultSearchControl.setValue(""); applyFilter();' matTooltip='Pulisci filtro'>close</mat-icon>
	</ng-container>

	<ng-container *ngIf='!showSearchText'>
		<div fxFlex ></div>
	</ng-container>
	<!--<mat-form-field  class='mr-4' appearance="outline" [floatLabel]='"never"'>
		<mat-icon matPrefix class='mr-4'>search</mat-icon>
		<input matInput type='text' placeholder="Cerca" (keydown.enter)='applyFilter()' autocomplete="off" [formControl]='defaultSearchControl'>
	</mat-form-field>-->
	
	<div class='extra-filters' *ngIf='formConfig'>
		<button (click)='openFilters()' [ngClass]='filterCount ? "active-filters" : ""' mat-flat-button color='primary'>
			<span>{{'Filtri' | translate}}</span>
			<span class='ml-4 filter-count' *ngIf='filterCount'>{{filterCount}}</span>
		</button>
		<button mat-icon-button [matTooltip]='"Pulisci filtri" | translate' *ngIf='filterCount' (click)='clearFilters()'>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>