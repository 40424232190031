import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService, trainTypeImages } from '../../trenitalia.service';
import * as _ from 'lodash';
import {RELOAD_BOOKING_PACKAGES} from '../../../edit-booking.component';
import {AppService} from '../../../../../../services/app.service';
import { TrenitaliaPostSaleChangeConfirmDialog } from '../trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component';
import { wrapArray } from 'app/helpers';
import { finalize, take } from 'rxjs/operators';
@Component({
	selector: 'app-post-sale-dialog',
	templateUrl: './post-sale-dialog.component.html',
	styleUrls: ['./post-sale-dialog.component.scss']
})
export class PostSaleDialogComponent implements OnInit {
	public solution;
	public total;
	public selectedOfferedServices;
	public load;
	public errors:any = {};

	constructor(
		public appService: AppService,
		@Inject(MAT_DIALOG_DATA) private dataDialog: any,
		public _trnService: TrenitaliaService,
		public matDialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}
	get trainTypeImages(){
		return trainTypeImages;
	}

	getEvent(e) {
		this.updateTotal();
	}

	ngOnInit(): void {
		this.solution =this.dataDialog.solution;
		/*const oldOffered = _.get(this.dataDialog,['travel','travelSolutions',0,'offeredServices'])
		if(oldOffered && _.isArray(oldOffered)) {
			const offer = oldOffered.filter(off => off.status == 'PURCHASED');
			let selectedOffer:any={};
			for ( let off of offer){
				const serviceId = _.get(off,'catalogServiceId');
				console.log(this.dataDialog);
				console.log("type",_.get(this.dataDialog,['serviceMap','catalog_services',serviceId,'type']));
				if(_.get(this.dataDialog,['serviceMap','catalog_services',serviceId,'type'])==='SEAT'){
					if(!_.has(selectedOffer,off.solutionNodeRef))_.set(selectedOffer,off.solutionNodeRef,{});
					if(!_.has(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller]))_.set(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller],{});
					_.set(selectedOffer,[off.solutionNodeRef,off.bookingInfo.traveller], {offer: off.offer.id,service: off.catalogServiceId.id});
				}
			}
			_.set(this.solution,['travelSolutions',0,'priceSelected'],selectedOffer);
		}*/
		this.solution.travelSolutions[0]['custom_off']= {};
		this._trnService.loading.subscribe(l => {
			this.load = l;
		});
	}

	updateTotal() {
		this.selectedOfferedServices = [];
		this.total = 0;
		this.solution.travelSolutions[0].trains.forEach(t => {
				this.solution.travellers.forEach(tr => {
					this.total += _.get(
						this.solution.travelSolutions[0].custom_off,
						t.id + '.' + tr.xmlId + '.price'
					);
				   /* let offered =  _.get(this.solution.travelSolutions[0].offeredServices,_.get(
						this.solution.travelSolutions[0].custom_off,
						t.id + '.' + tr.xmlId + '.offer[0]'
					));*/

					let sel:any = _.get(this.solution.travelSolutions[0].custom_off, [t.id,tr.xmlId]);
					if(!sel){
						console.warn('custom_off not found', this.solution, t.id, tr.xmlId);
						return;
					}
					let offer:any = {id:sel.offer[0]};
					if(sel.parameter)offer.parameters = sel.parameter
					this.selectedOfferedServices.push(offer);
					/*this.selectedOfferedServices.push({
						id: _.get(
							this.solution.travelSolutions[0].custom_off,
							t.id + '.' + tr.xmlId + '.offer[0]'
						)
					});*/
				});
		});
	}

	updateTravel() {
		this.load = true;
		this._trnService.loading.next(true);
		this.dataDialog.travel.travellers = this.dataDialog.travellers
		const data = {
			travel: this.dataDialog.travel,
			selectedPostSaleDetails: this.dataDialog.postSaleDetails,
			oldOfferedServices: this.dataDialog.oldOfferedServices,
			selectedOfferedServices: this.selectedOfferedServices,
			newTravelSolutionSelected: this.solution.travelSolutions[0].original
		};
		this._trnService.evaluateTravelPostSale(data).subscribe(res => {
			this.load = false;
			if (res['validatedPostSaleRecord'].allowed=='false') {
				let message = 'Soluzione non più disponibile';
				let travelSolutions = _.get(res, 'travel.travelSolutions');
				if(travelSolutions){
					if(!Array.isArray(travelSolutions)) travelSolutions = [travelSolutions];
					let offeredServices = _.get(travelSolutions, [0,'offeredServices']).filter(of=>of.status=='SELECTED');
					if(offeredServices && !Array.isArray(offeredServices)) offeredServices = [offeredServices];
					if(offeredServices && _.has(travelSolutions, [0, 'returnTravelSolution', 'offeredServices'])){
						const returnOfferedServices = _.get(travelSolutions, [0, 'returnTravelSolution', 'offeredServices']);
						if(Array.isArray(returnOfferedServices)) offeredServices.push(...returnOfferedServices);
						else offeredServices.push(returnOfferedServices);
					}
					if(offeredServices){
						for (const offeredService of offeredServices) {
							if(_.get(offeredService, 'status') != 'SELECTED') continue;
							let messages = _.get(offeredService, 'bookingInfo.reportItem.messages');
							if(messages && !Array.isArray(messages)) messages = [messages];
							const train = _.get(offeredService,'solutionNodeRef');
							const traveller = _.get(offeredService,'bookingInfo.traveller');
							if(!_.has(this.errors,train)) this.errors[train] = {}
							if(!_.has(this.errors,[train,traveller])) this.errors[train][traveller] = {}
							this.errors[train][traveller].messages = messages;
							if(!_.isEmpty(messages)) message = messages[1];
						}
					}
				}
				this.appService.showErrorMessage(message);
			}else{
				this.matDialog
					.open(TrenitaliaPostSaleChangeConfirmDialog, {
						minWidth: '300px',
						panelClass: 'create-dialog-container',
						data: {
							postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
								missingAmount: res.missingAmount,
								reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
								reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
								additionalCharges: res.additionalCharges,
								postSaleFee: res.fee
							}),
							type: 'change-class',
							title: 'Cambio classe viaggio',
							message: 'Vuoi cambiare classe viaggio?',
							icon: 'keyboard_double_arrow_up',

						}
					})
					.afterClosed()
					.subscribe(result => {
						if(!result) return;
						this.load = true;
						this._trnService
						.updateAndConfirm({
							travel: res.travel,
							type: 'change-class',
							postSaleData: {
								postSaleRecord: res.validatedPostSaleRecord,
								missingAmount: res.missingAmount,
								reversedPaymentRecords: res.reversedPaymentRecords,
								reusedPaymentRecords: res.reusedPaymentRecords,
								additionalCharges: res.additionalCharges
							}
						})
						.pipe(
							take(1),
							finalize(() => {
								this.load = false;
							})
						)
						.subscribe({
							next: s => {
								this._trnService.loading.next(false);
								this.matDialog.closeAll();
								this.appService.appEvent.next({
									name: RELOAD_BOOKING_PACKAGES
								});
								this.appService.showSuccessMessage('Cambio livello effettuato');
							},
							error: error => {
								this.appService.showErrorMessage('Errore nella procedura del cambio livello!');
							}
						});
					});
			}
		});
	}
}
