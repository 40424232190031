/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../form-viewer/form-viewer.component.ngfactory";
import * as i10 from "../../../../../services/form-viewer.service";
import * as i11 from "../../../../../services/app.service";
import * as i12 from "@angular/forms";
import * as i13 from "../../../form-viewer/form-viewer.component";
import * as i14 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i15 from "@angular/material/button";
import * as i16 from "@angular/cdk/a11y";
import * as i17 from "../../../../pages/page-closure-container.service";
import * as i18 from "../../services/dataset-action-container.service";
import * as i19 from "../../../../../services/auth.service";
import * as i20 from "../../services/dataset.service";
import * as i21 from "../../../../../services/domain-filter.service";
import * as i22 from "./dialog.component";
var styles_SelectDomainDialogComponent = [i0.styles];
var RenderType_SelectDomainDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectDomainDialogComponent, data: {} });
export { RenderType_SelectDomainDialogComponent as RenderType_SelectDomainDialogComponent };
function View_SelectDomainDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "margin-left: 30px;"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "20"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_SelectDomainDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "form-viewer", [], null, null, null, i9.View_FormViewerComponent_0, i9.RenderType_FormViewerComponent)), i1.ɵprd(131584, null, i10.FormViewerService, i10.FormViewerService, [i11.AppService, i12.FormBuilder, i1.NgZone]), i1.ɵdid(8, 770048, [[1, 4], ["formViewer", 4]], 0, i13.FormViewerComponent, [i10.FormViewerService, i1.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 11, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatButton_0, i14.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i15.MatButton, [i1.ElementRef, i16.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""], ["tabindex", "1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_MatButton_0, i14.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i15.MatButton, [i1.ElementRef, i16.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(17, 0, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDomainDialogComponent_1)), i1.ɵdid(20, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formConfig; var currVal_2 = _co.datasetACS; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_5 = _co.loading; _ck(_v, 12, 0, currVal_5); var currVal_9 = _co.loading; var currVal_10 = "primary"; _ck(_v, 16, 0, currVal_9, currVal_10); var currVal_12 = _co.loading; _ck(_v, 20, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform((((_co.injectedData == null) ? null : _co.injectedData.title) || "Seleziona un dominio sul quale agire"))); _ck(_v, 4, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Chiudi")); _ck(_v, 13, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 16).disabled || null); var currVal_8 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_11 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("Seleziona")); _ck(_v, 17, 0, currVal_11); }); }
export function View_SelectDomainDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "select-domain-dialog", [], null, null, null, View_SelectDomainDialogComponent_0, RenderType_SelectDomainDialogComponent)), i1.ɵprd(512, null, i17.PageClosureContainerService, i17.PageClosureContainerService, [i11.AppService]), i1.ɵprd(512, null, i18.DatasetActionContainerService, i18.DatasetActionContainerService, [i11.AppService, i17.PageClosureContainerService, i19.AuthService, i20.DatasetService, i21.DomainFilterService, i8.TranslateService, i1.Injector]), i1.ɵdid(3, 4243456, null, 0, i22.SelectDomainDialogComponent, [i7.MAT_DIALOG_DATA, i7.MatDialogRef, i17.PageClosureContainerService, i18.DatasetActionContainerService], null, null)], null, null); }
var SelectDomainDialogComponentNgFactory = i1.ɵccf("select-domain-dialog", i22.SelectDomainDialogComponent, View_SelectDomainDialogComponent_Host_0, {}, {}, []);
export { SelectDomainDialogComponentNgFactory as SelectDomainDialogComponentNgFactory };
