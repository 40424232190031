import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import {DatasetActionContainerService, DatasetEvents} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import {AuthService} from 'app/services/auth.service';
import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationItaliano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { WebSocketService } from 'app/services/websocket.service';
import { ISocketEvent } from 'app/interfaces';

export abstract class BaseDatasetComponent implements OnInit, OnDestroy{

	abstract actionCode: string;

	protected _unsubscribeAll: Subject<any>;
	protected loadSubscription: Subscription;
	public lastLoadError: any;

	protected firstLoad = true;
	@Input() autoFetchData = false;

	@Input() showTitle = true;
	@Input() datasetCode: string;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		protected datasetACS: DatasetActionContainerService,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService,
		protected webSocketService: WebSocketService){
		// traduzioni
		this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationItaliano);
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.datasetCode,
			actionCode: this.actionCode,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: this.autoFetchData
		});
		this.datasetACS.datasetEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			if(event.eventName === DatasetEvents.CREATED || event.eventName === DatasetEvents.EDITED || event.eventName === DatasetEvents.DELETED){
				this.reload();
			}
		});
		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.initLoad()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe( _ => {
				this._load();
			});
		});

		if (this.webSocketService.ioSocket) this.webSocketService.ioSocket.on('channel-event', this.onChannelEvent.bind(this));
	}

	/**
	 *	Override this with a http request
	 */
	protected load(): Observable<any>{ return of(true); }
	protected onLoaded(response): Observable<any>{ return of(true); }
	protected initLoad(): Observable<any>{ return of(true); }
	protected onChannelEvent(broadcastEvent: ISocketEvent): void {}

	private _load(): void{
		this.datasetACS.loading.next(true);
		if (this.loadSubscription && !this.loadSubscription.closed){
			this.loadSubscription.unsubscribe();
		}
		this.loadSubscription = this.load()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.firstLoad = false;
				this.onLoaded(response)
				.subscribe({
					next: val => {
						this.datasetACS.loading.next(false);
					},
					error: val => {
						this.datasetACS.loading.next(false);
					}
				});
				
			},
			error: err => {
				this.datasetACS.loading.next(false);
				this.lastLoadError = err;
			}
		});
	}

	reload(): void{
		this._load();
	}

	protected getDialogData(): any{ return {}; }

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		// unsubscribe web socket
		if(this.webSocketService.ioSocket) this.webSocketService.ioSocket.off('channel-event');
	}
}
