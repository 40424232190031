import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { FieldHelper } from 'app/helpers';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { ValueTypes } from 'app/interfaces';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
var ReadOnlyInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReadOnlyInputComponent, _super);
    function ReadOnlyInputComponent(inputService, datasetService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.datasetService = datasetService;
        _this.loading = false;
        _this.show = true;
        return _this;
    }
    ReadOnlyInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (value) {
            _this.computeValue();
        });
    };
    ReadOnlyInputComponent.prototype.computeValue = function () {
        var _this = this;
        if (typeof (this.inputService.formInputDefinition.skipIf) == 'function' && this.inputService.formInputDefinition.skipIf(this.inputService.data, this.inputService.dataContainerService)) {
            this.show = false;
        }
        else {
            this.show = true;
        }
        if (this.inputService.formInputDefinition.valueType === ValueTypes.REFERENCE && typeof (this.inputService.formInputDefinition.getDisplayValue) === 'function') {
            this.computedValue = null;
            var value = FieldHelper.getFieldValue(this.inputService.formInputDefinition, this.inputService.data, null, this.inputService.dataContainerService);
            if (!value) {
                this.computedValue = null;
                return;
            }
            this.loading = true;
            var headers = new HttpHeaders({});
            if (this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
                headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
            }
            this.datasetService.get('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                next: function (referenceRecord) {
                    if (typeof (_this.inputService.formInputDefinition.getDisplayValue) === 'function') {
                        _this.computedValue = _this.inputService.formInputDefinition.getDisplayValue(_this.inputService.data, referenceRecord);
                    }
                    _this.loading = false;
                },
                error: function (response) {
                    _this.loading = false;
                }
            });
        }
        else {
            this.computedValue = FieldHelper.getDisplayFieldValue(this.inputService.formInputDefinition, this.inputService.data, undefined, this.inputService.dataContainerService);
        }
    };
    return ReadOnlyInputComponent;
}(DestroyableComponent));
export { ReadOnlyInputComponent };
