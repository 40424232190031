import * as tslib_1 from "tslib";
import { PathLocationStrategy, PlatformLocation } from "@angular/common";
var AppLocationStrategy = /** @class */ (function (_super) {
    tslib_1.__extends(AppLocationStrategy, _super);
    function AppLocationStrategy(__platformLocation, href) {
        var _this = _super.call(this, __platformLocation, href) || this;
        _this.__platformLocation = __platformLocation;
        _this.basePath = null;
        if (href && href.startsWith('http')) {
            var url = new URL(href);
            if (url.pathname != '/') {
                _this.basePath = url.pathname.replace(/\/$/, '');
            }
        }
        return _this;
    }
    AppLocationStrategy.prototype.path = function (includeHash) {
        if (includeHash === void 0) { includeHash = false; }
        var pathname = _super.prototype.path.call(this, includeHash);
        if (this.basePath && pathname.startsWith(this.basePath)) {
            return pathname.replace(this.basePath, '');
        }
        return pathname;
    };
    return AppLocationStrategy;
}(PathLocationStrategy));
export { AppLocationStrategy };
