import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TrenitaliaService } from '../../trenitalia.service';
import * as _ from 'lodash';
import { TrenitaliaAuthComponent } from '../../trenitalia-auth/trenitalia-auth.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export var carnetTrainTypeImages = {
    'FR': 'assets/images/trenitalia/FRECCIAROSSA.svg',
    'Frecciarossa 1000': 'assets/images/trenitalia/frecciarossa_1000.svg',
    'FA': 'assets/images/trenitalia/frecciargento.svg',
    Intercity: 'assets/images/trenitalia/intercity.svg',
    InterCityNotte: 'assets/images/trenitalia/intercity_notte.svg',
    Regionale: 'assets/images/trenitalia/regionale.svg',
    'Regionale Veloce': 'assets/images/trenitalia/RV.svg',
    'EuroCity': null,
    'Autobus': null // get the image
};
var SelectCarnetComponent = /** @class */ (function () {
    function SelectCarnetComponent(trnService, matDialog) {
        this.trnService = trnService;
        this.matDialog = matDialog;
        this.solutions = [];
        this._unsubscribeAll = new Subject();
    }
    SelectCarnetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            var e_1, _a;
            _this.checkoutData = data;
            var servicesMap = _.get(_this.checkoutData, 'searchSolutions.servicesMap') || _.get(_this.checkoutData, 'searchSolutions.serviceMap');
            var offer = _.keys(servicesMap.offers)[0];
            var train = _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0].trains[0].id');
            _this.solutionData = _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0]');
            try {
                for (var _b = tslib_1.__values(Object.entries(servicesMap.catalog_services)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                    _this.solutions.push({
                        trains: _.isArray(_.get(value, 'attrs.train_classification')) ? _.get(value, 'attrs.train_classification') : [{ value: _.get(value, 'attrs.train_classification') }],
                        levels: _.isArray(_.get(value, 'attrs.level')) ? _.get(value, 'attrs.level') : [{ value: _.get(value, 'attrs.level') }],
                        title: _.get(value, 'display_name'),
                        price: _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0].priceGrid.' + train + '.matrix.' + offer + '.' + key + '.totalPrice'),
                        netPrice: _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0].priceGrid.' + train + '.matrix.' + offer + '.' + key + '.totalPriceNet'),
                        fee: _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0].priceGrid.' + train + '.matrix.' + offer + '.' + key + '.fee'),
                        offers: _.get(_this.checkoutData, 'searchSolutions.travelSolutions[0].priceGrid.' + train + '.matrix.' + offer + '.' + key + '.offers')
                    });
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    SelectCarnetComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
    };
    Object.defineProperty(SelectCarnetComponent.prototype, "trainTypeImages", {
        get: function () {
            return carnetTrainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    SelectCarnetComponent.prototype.auth = function (off) {
        var _this = this;
        this.matDialog.open(TrenitaliaAuthComponent, {})
            .afterClosed()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (user) {
            if (user) {
                _this.checkoutData.selectedOffers = off;
                _this.checkoutData.user = user;
                _this.trnService.step.next(3);
            }
        });
    };
    return SelectCarnetComponent;
}());
export { SelectCarnetComponent };
