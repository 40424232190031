/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/common";
import * as i12 from "../../../../form-viewer/form-viewer.component.ngfactory";
import * as i13 from "../../../../../../services/form-viewer.service";
import * as i14 from "../../../../../../services/app.service";
import * as i15 from "@angular/forms";
import * as i16 from "../../../../form-viewer/form-viewer.component";
import * as i17 from "./step.component";
import * as i18 from "../../../services/dataset-record.service";
import * as i19 from "../../../services/dataset-action-container.service";
import * as i20 from "../../../services/dataset-navigator.service";
import * as i21 from "../../../services/dataset.service";
import * as i22 from "@angular/material/dialog";
var styles_CommonDateStepComponent = [];
var RenderType_CommonDateStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommonDateStepComponent, data: {} });
export { RenderType_CommonDateStepComponent as RenderType_CommonDateStepComponent };
function View_CommonDateStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_CommonDateStepComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform((_co.hasNext ? "Salva e vai avanti" : "Salva"))); _ck(_v, 1, 0, currVal_0); }); }
function View_CommonDateStepComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Azione in corso...")); _ck(_v, 1, 0, currVal_0); }); }
function View_CommonDateStepComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "mt-24 mb-24"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i0.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 1720320, null, 0, i5.DefaultLayoutGapDirective, [i0.ElementRef, i0.NgZone, i7.Directionality, i6.StyleUtils, [2, i5.LayoutGapStyleBuilder], i6.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i0.ɵdid(3, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i0.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i0.ɵdid(5, 180224, null, 0, i9.MatButton, [i0.ElementRef, i10.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CommonDateStepComponent_3)), i0.ɵdid(7, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CommonDateStepComponent_4)), i0.ɵdid(9, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "end center"; _ck(_v, 3, 0, currVal_2); var currVal_5 = _co.datasetACS.loading.value; var currVal_6 = "accent"; _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_7 = !_co.datasetACS.loading.value; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.datasetACS.loading.value; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var currVal_3 = (i0.ɵnov(_v, 5).disabled || null); var currVal_4 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_CommonDateStepComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonDateStepComponent_1)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "form-viewer", [], null, null, null, i12.View_FormViewerComponent_0, i12.RenderType_FormViewerComponent)), i0.ɵprd(131584, null, i13.FormViewerService, i13.FormViewerService, [i14.AppService, i15.FormBuilder, i0.NgZone]), i0.ɵdid(6, 770048, [[1, 4], ["formViewer", 4]], 0, i16.FormViewerComponent, [i13.FormViewerService, i0.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"], record: [2, "record"], readOnly: [3, "readOnly"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonDateStepComponent_2)), i0.ɵdid(8, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.loading.value; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.stepConfig.options.formConfig; var currVal_2 = _co.datasetACS; var currVal_3 = _co.datasetRS.record.value; var currVal_4 = !_co.canEdit; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.canEdit; _ck(_v, 8, 0, currVal_5); }, null); }
export function View_CommonDateStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "common-date-step", [], null, null, null, View_CommonDateStepComponent_0, RenderType_CommonDateStepComponent)), i0.ɵdid(1, 4440064, null, 0, i17.CommonDateStepComponent, [i14.AppService, i18.DatasetRecordService, i19.DatasetActionContainerService, i20.DatasetNavigatorService, i21.DatasetService, i22.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommonDateStepComponentNgFactory = i0.ɵccf("common-date-step", i17.CommonDateStepComponent, View_CommonDateStepComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { CommonDateStepComponentNgFactory as CommonDateStepComponentNgFactory };
