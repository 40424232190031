import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';

const fieldDefinition: IFieldDefinition = {
	key: 'with_trashed',
	name: 'Mostra eliminati',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{ return 0; },
	options: [
		{
			value:  0,
			label: 'No'
		},
		{
			value:  1,
			label: 'Si'
		},
		{
			value:  2,
			label: 'Solo gli eliminati'
		}
	]
};

export default fieldDefinition;
