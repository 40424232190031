import {Component, forwardRef, OnInit} from '@angular/core';
import {PageClosureContainerService} from '../../../../page-closure-container.service';
import {DatasetActionContainerService} from '../../../../../components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from '../../../../../components/dataset/services/dataset-record.service';
import {FormViewerService} from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import {TrenitaliaService} from '../../trenitalia.service';
import * as _ from 'lodash';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'trenitalia-search-carnet',
  templateUrl: './search-carnet.component.html',
  styleUrls: ['../../search-form/search-form.component.scss','./search-carnet.component.scss'],
    providers: [
        forwardRef(() => DatasetActionContainerService),
        forwardRef(() => DatasetRecordService),
        FormViewerService
    ]
})
export class SearchCarnetComponent implements OnInit {
    public formFields = formFields;
    protected checkoutData;

  constructor(public datasetACS: DatasetActionContainerService,
              public trnService: TrenitaliaService,
              public formViewerService: FormViewerService,
              private _snackBar: MatSnackBar,) {
      this.formViewerService.init({
          errors: null,
          dataContainerService: this.datasetACS,
          fields: Array.from(formFields.values())
      });
  }

  ngOnInit() {
      this.trnService.checkoutData.subscribe(data=>{
          this.checkoutData = data;
          if(this.checkoutData.from==null || this.trnService.step.value === 2) {
              this.formViewerService.formGroup
                  .get('start_station')
                  .setValue(this.checkoutData.from);
              this.formViewerService.formGroup
                  .get('end_station')
                  .setValue(this.checkoutData.to);
              this.formViewerService.formGroup
                  .get('date')
                  .setValue(this.checkoutData.start);
              this.formViewerService.formGroup
                  .get('carnet_type')
                  .setValue(this.checkoutData.carnetId);
          }
      })
      this.formViewerService.formGroup.valueChanges.subscribe(data=>{
          if(this.formViewerService.formGroup.valid){
              this.checkoutData.from = _.get(data,'start_station');
              this.checkoutData.to = _.get(data,'end_station');
              this.checkoutData.carnetId = _.get(data,'carnet_type');
              this.checkoutData.start = _.get(data,'date');
              this.trnService.checkoutData.next(this.checkoutData);
              this.trnService.continue.next(true);
          }
      })
  }
    search(){
        this.trnService.loading.next(true);
        const data = {
            adult:this.checkoutData.adult,
            children:this.checkoutData.children,
            direction :'ticket',
            start: this.checkoutData.start,
            from: this.checkoutData.from,
            to:this.checkoutData.to,
            carnetId: this.checkoutData.carnetId
        }
        this.trnService.searchAvailablesCarnet(data).subscribe(carnets=>{
            this.trnService.loading.next(false);
            if(_.isEmpty(carnets)){
                this._snackBar.open('Nessuna soluzione trovata!', null, {
                    duration: 5000
                });
            }else{
                this.checkoutData.searchSolutions = carnets
                this.trnService.checkoutData.next(this.checkoutData);
                this.trnService.step.next(2);
            }
        })
    }

}
