import { Component, OnDestroy, OnInit } from '@angular/core';
import {TrenitaliaService} from '../../trenitalia.service';
import * as _ from 'lodash';
import {TrenitaliaAuthComponent} from '../../trenitalia-auth/trenitalia-auth.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export const carnetTrainTypeImages = {
	'FR': 'assets/images/trenitalia/FRECCIAROSSA.svg',
	'Frecciarossa 1000': 'assets/images/trenitalia/frecciarossa_1000.svg',
	'FA': 'assets/images/trenitalia/frecciargento.svg',
	Intercity: 'assets/images/trenitalia/intercity.svg',
	InterCityNotte: 'assets/images/trenitalia/intercity_notte.svg',
	Regionale: 'assets/images/trenitalia/regionale.svg',
	'Regionale Veloce': 'assets/images/trenitalia/RV.svg',
	'EuroCity': null, // todo get the image
	'Autobus': null // get the image
};

@Component({
	selector: 'trenitalia-select-carnet',
	templateUrl: './select-carnet.component.html',
	styleUrls: ['../../search-results/search-results.component.scss','./select-carnet.component.scss']
})


export class SelectCarnetComponent implements OnInit, OnDestroy {
	private checkoutData;
	public solutions = [];
	public travellers;
	public solutionData;
	
	protected _unsubscribeAll: Subject<any>;
	
	constructor(
		public trnService: TrenitaliaService,
		public matDialog: MatDialog
	) {
		this._unsubscribeAll = new Subject();
	}
		
	ngOnInit() {
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
			const servicesMap = _.get(this.checkoutData, 'searchSolutions.servicesMap') || _.get(this.checkoutData, 'searchSolutions.serviceMap');
			const offer = _.keys(servicesMap.offers)[0];
			const train = _.get(this.checkoutData,'searchSolutions.travelSolutions[0].trains[0].id');
			this.solutionData = _.get(this.checkoutData,'searchSolutions.travelSolutions[0]');
			for (const [key, value] of Object.entries(servicesMap.catalog_services)) {
				this.solutions.push({
					trains: _.isArray(_.get(value, 'attrs.train_classification'))?_.get(value, 'attrs.train_classification'):[{value:_.get(value, 'attrs.train_classification')}],
					levels: _.isArray(_.get(value, 'attrs.level'))?_.get(value, 'attrs.level'):[{value:_.get(value, 'attrs.level')}],
					title: _.get(value, 'display_name'),
					price: _.get(this.checkoutData,'searchSolutions.travelSolutions[0].priceGrid.'+train+'.matrix.'+offer+'.'+key+'.totalPrice'),
                    netPrice: _.get(this.checkoutData,'searchSolutions.travelSolutions[0].priceGrid.'+train+'.matrix.'+offer+'.'+key+'.totalPriceNet'),
                    fee: _.get(this.checkoutData,'searchSolutions.travelSolutions[0].priceGrid.'+train+'.matrix.'+offer+'.'+key+'.fee'),
                    offers: _.get(this.checkoutData,'searchSolutions.travelSolutions[0].priceGrid.'+train+'.matrix.'+offer+'.'+key+'.offers')
				});
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
	}

	get trainTypeImages(){
		return carnetTrainTypeImages;
	}
	
	auth(off){
		this.matDialog.open(TrenitaliaAuthComponent,{})
		.afterClosed()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(user=>{
			if(user){
				this.checkoutData.selectedOffers=off;
				this.checkoutData.user = user;
				this.trnService.step.next(3);
			}
		})
	}
}