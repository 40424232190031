import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import * as moment from 'moment';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
var DateTimeInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DateTimeInputComponent, _super);
    function DateTimeInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        return _this;
    }
    DateTimeInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.dateFilterBinded = _this.dateFilter.bind(_this);
            _this.initialTimeValue = _this.getTimeValue();
        });
    };
    DateTimeInputComponent.prototype.getTimeValue = function () {
        var control = this.inputService.control;
        if (!control)
            return '';
        var value = control.value;
        if (value) {
            var date = new Date(value);
            return date.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
        }
        return '';
    };
    DateTimeInputComponent.prototype.dateFilter = function (d) {
        if (this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter) {
            return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
        }
        return true;
    };
    DateTimeInputComponent.prototype.onTimeChanged = function (event) {
        var value = this.inputService.control.value;
        var timeValue = event.target.value;
        if (!value)
            return;
        if (typeof (timeValue) === 'string' && timeValue.search(':') !== -1) {
            var timeParts = timeValue.split(':');
            var hours = Number(timeParts[0]);
            var minutes = Number(timeParts[1]);
            if (value instanceof Date) {
                value.setUTCHours(hours);
                value.setUTCMinutes(minutes);
            }
            else if (typeof (value.hours) === 'function') {
                value.add(moment().utcOffset(), 'm');
                value.hours(hours);
                value.minutes(minutes);
                value.subtract(moment().utcOffset(), 'm');
            }
        }
    };
    return DateTimeInputComponent;
}(DestroyableComponent));
export { DateTimeInputComponent };
