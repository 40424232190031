import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrenitaliaDialogComponent } from './trenitalia-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchFormComponent } from './search-form/search-form.component';
import { FormViewerModule } from '../../../components/form-viewer/form-viewer.module';
import { TranslateModule } from '@ngx-translate/core';
import { TrenitaliaService } from './trenitalia.service';
import { SearchResultsComponent } from './search-results/search-results.component';
import { FlexModule } from '@angular/flex-layout';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricesComponent, InfoDialog } from './prices/prices.component';
import { SearchTravellersModule } from './search-travellers/search-travellers.module';
import { TrainDetailsComponent } from './search-results/train-details/train-details.component';
import { SafePipe } from './search-results/safe.pipe';
import { TrenitaliaSeatsComponent } from './trenitalia-seats/trenitalia-seats.component';
import { SafeContentPipe } from './trenitalia-seats/safe-content.pipe';
import { TrainSelectionSeatsComponent } from './trenitalia-seats/train-selection-seats/train-selection-seats.component';
import { PostSaleDialogComponent } from './trenitalia-details/post-sale-dialog/post-sale-dialog.component';
import {TrenitaliaCarnetModule} from './trenitalia-carnet/trenitalia-carnet.module';
import { TrenitaliaAuthComponent } from './trenitalia-auth/trenitalia-auth.component';
import { SearchCarnetComponent } from './search-form/search-carnet/search-carnet.component';
import { TrenitaliaDetailsModule } from './trenitalia-details/trenitalia-details.module';
import { TrenitaliaOtherSolutionModule } from './prices/other-solution/other-solution.module';
import { TrainBreadcrumbsComponent } from './components/train-breadcrumbs/train-breadcrumbs.component';
import { TravelSolutionTrainDetailsComponent } from './search-results/travel-solution-train-details/travel-solution-train-details.component';
import { TrainBreadcrumbsModule } from './components/train-breadcrumbs/train-breadcrumbs.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { TrenitaliaDialogContainerComponent } from './trenitalia-dialog-container/trenitalia-dialog-container.component';
import { TravelSolutionHeaderComponent } from './search-results/travel-solution-header/travel-solution-header.component';
import { TravelSolutionListItemComponent } from './search-results/travel-solution-list-item/travel-solution-list-item.component';
import { TrenitaliaRecapComponent } from './trenitalia-recap/trenitalia-recap.component';
import {TrenitaliaRecapModule} from './trenitalia-recap/trenitalia-recap.module';

@NgModule({
	declarations: [
		TrenitaliaDialogComponent,
		SearchFormComponent,
		SearchResultsComponent,
		TravelSolutionTrainDetailsComponent,
		PricesComponent,
		TrainDetailsComponent,
		InfoDialog,
		SafePipe,
		SafeContentPipe,
		TrenitaliaSeatsComponent,
		TrainSelectionSeatsComponent,
		TrenitaliaAuthComponent,
		SearchCarnetComponent,
		TrenitaliaDialogContainerComponent,
		TravelSolutionHeaderComponent,
		TravelSolutionListItemComponent,
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressBarModule,
		FormViewerModule,
		MatButtonModule,
		TranslateModule,
		MatExpansionModule,
		MatIconModule,
		MatDividerModule,
		MatTableModule,
		FlexModule,
		MatTooltipModule,
		MatCardModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatTabsModule,
		SearchTravellersModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatSelectModule,
		MatMenuModule,
		MatStepperModule,
		MatDividerModule,
		TrenitaliaCarnetModule,
		TrenitaliaDetailsModule,
		TrenitaliaOtherSolutionModule,
		TrainBreadcrumbsModule,
		ScrollingModule,
        TrenitaliaRecapModule
	],
	entryComponents: [
		TrenitaliaDialogComponent,
        TrenitaliaDialogContainerComponent,
		InfoDialog,
		PostSaleDialogComponent,
	],
    exports: [TrenitaliaDialogComponent, PricesComponent, TrainBreadcrumbsComponent]
})
export class TrenitaliaDialogModule {}
