import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { makeDrawValuePlugin } from '../chartjs.plugins';
var ReviewsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReviewsComponent, _super);
    function ReviewsComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        _this.barChartOptions = {
            responsive: true,
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        id: 'avg',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            max: 5,
                            min: 0
                        }
                    },
                    {
                        id: 'percent',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            max: 100,
                            min: 0
                        }
                    }
                ]
            },
            legend: {
                position: 'right',
                align: 'start',
                fullWidth: true,
                labels: {
                    usePointStyle: true,
                    padding: 15
                }
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(tooltipItem.yLabel);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            },
        };
        _this.chartPlugins = [makeDrawValuePlugin()];
        _this.barChartLabels = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
        _this.barChartType = 'line';
        _this.barChartLegend = true;
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ReviewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardService.reviewResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.onData(response);
            }
        });
    };
    ReviewsComponent.prototype.onData = function (response) {
        var e_1, _a;
        this.showLoader = true;
        if (!response)
            return;
        else if (response.length)
            this.showLoader = false;
        var data = [
            {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                label: 'negative',
                yAxisID: 'percent',
                lineTension: 0,
                fill: false,
                backgroundColor: '#ff6384',
                hoverBackgroundColor: '#ff6384',
                borderColor: '#ff6384',
                hoverBorderColor: '#ff6384',
                pointBackgroundColor: '#ff6384',
                pointHoverBackgroundColor: '#ff6384',
                pointBorderColor: '#ff6384',
                pointHoverBorderColor: '#ff6384',
                pointRadius: 5
            },
            {
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                label: 'positive',
                yAxisID: 'avg',
                lineTension: 0,
                fill: false,
                backgroundColor: '#4bc0c0',
                hoverBackgroundColor: '#4bc0c0',
                borderColor: '#4bc0c0',
                hoverBorderColor: '#4bc0c0',
                pointBackgroundColor: '#4bc0c0',
                pointHoverBackgroundColor: '#4bc0c0',
                pointBorderColor: '#4bc0c0',
                pointHoverBorderColor: '#4bc0c0',
                pointRadius: 5
            }
        ];
        if (response.length > 0) {
            var monthCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var monthTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            try {
                for (var response_1 = tslib_1.__values(response), response_1_1 = response_1.next(); !response_1_1.done; response_1_1 = response_1.next()) {
                    var item = response_1_1.value;
                    var monthIndex = Number(item.month) - 1;
                    monthCount[monthIndex]++;
                    monthTotal[monthIndex] += item.total;
                    data[1].data[monthIndex] = Number(item.avg_points) + Number(data[1].data[monthIndex]);
                    data[0].data[monthIndex] += item.negative_points;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (response_1_1 && !response_1_1.done && (_a = response_1.return)) _a.call(response_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            for (var index = 0; index < 12; index++) {
                var avg = Number(data[1].data[index]);
                var negPoints = Number(data[0].data[index]);
                data[1].data[index] = Math.round((avg / monthCount[index]) * 100) / 100;
                data[0].data[index] = Math.round((negPoints / monthTotal[index]) * 10000) / 100;
            }
        }
        this.reviews = data;
    };
    return ReviewsComponent;
}(DestroyableComponent));
export { ReviewsComponent };
