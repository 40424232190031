import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
var PlafondComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PlafondComponent, _super);
    function PlafondComponent(datasetACS, datasetRS, datasetNavigatorService) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        return _this;
    }
    PlafondComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        // load reset data
        //this.lastResetInfo();
    };
    PlafondComponent.prototype.lastResetInfo = function () {
        var _this = this;
        var call = this.datasetNavigatorService.datasetService.post('/dataset/plafond/command/get_plafond_log_info', {
            target_id: this.datasetRS.recordId
        });
        call.subscribe({
            next: function (resetData) {
                _this.resetData = resetData;
            },
            error: function (e) {
                // ignore
            }
        });
    };
    PlafondComponent.prototype.reset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.datasetNavigatorService.openDialog(ConfirmDialogComponent, {
                    title: 'Plafond',
                    contentMessage: 'Vuoi azzerare il plafond?'
                })
                    .afterClosed()
                    .subscribe(function (confirm) {
                    if (!confirm)
                        return;
                    // make call
                    _this.makeCallReset()
                        .then(function () {
                        _this.datasetRS.reload();
                    });
                });
                return [2 /*return*/];
            });
        });
    };
    PlafondComponent.prototype.makeCallReset = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, loadingDialogRef, response, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            recordId: this.datasetRS.recordId
                        };
                        loadingDialogRef = this.datasetNavigatorService.openDialog(ActionDialogComponent, {
                            title: 'Attendere durante il completamento della richiesta...',
                            contentMessage: ''
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.datasetNavigatorService.datasetService.post('/dataset/plafond/command/reset_plafond', data)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        if (response) {
                            loadingDialogRef.componentInstance.loading = false;
                            if (response.success) {
                                loadingDialogRef.componentInstance.resultStatus = 'success';
                                loadingDialogRef.componentInstance.setTitle('Plafond impostato');
                                loadingDialogRef.componentInstance.setMessage('Il plafond è stato azzerato correttamente.');
                            }
                            else {
                                loadingDialogRef.componentInstance.resultStatus = 'error';
                                loadingDialogRef.componentInstance.setTitle('Errore operazione');
                                loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
                            }
                        }
                        return [2 /*return*/, response];
                    case 3:
                        e_1 = _a.sent();
                        loadingDialogRef.componentInstance.loading = false;
                        loadingDialogRef.componentInstance.resultStatus = 'error';
                        loadingDialogRef.componentInstance.setTitle('Errore operazione');
                        loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return PlafondComponent;
}(BaseViewComponent));
export { PlafondComponent };
