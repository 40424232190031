import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('primary_email'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('accounting_email'),
			fieldsMap.get('operational_email')
		]
	},
	fieldsMap.get('phone_number'),
	fieldsMap.get('piva'),
	fieldsMap.get('fiscal_code'),
	fieldsMap.get('natural_person'),
	fieldsMap.get('firstname'),
	fieldsMap.get('lastname'),
	fieldsMap.get('birth_day'),
	fieldsMap.get('birth_location'),
	fieldsMap.get('gender'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('pec'),
			fieldsMap.get('sdi'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('identity_document_type'),
			fieldsMap.get('identity_document_number'),
		]
	},
	fieldsMap.get('country_doc_issue'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('doc_issue_date'),
			fieldsMap.get('identity_document_expiration_date'),
		]
	},
	fieldsMap.get('note')
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
