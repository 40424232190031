import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
	selector   : 'select-input',
	templateUrl: './input.component.html',
	animations : fuseAnimations
})
export class SelectInputComponent extends DestroyableComponent implements OnInit{
	public selectOptions: any[] = [];
	public sourceOptions: any[];

	public selectedLabel: string;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			
			// only one build will write correct selectOptions
			if(this.inputService.formInputDefinition.options){
				this.sourceOptions = this.inputService.formInputDefinition.options;
				this.selectOptions = this.inputService.formInputDefinition.options;
			}else{
				this.buildSelectPropertyOptions();
				this.buildSelectOptions();
			}

			if(this.inputService.control){
				this.inputService.control.valueChanges
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(() => {
					this.updateSelectedLabel();
				});
			}
			this.updateSelectedLabel();
		});
	}

	updateSelectedLabel(): void{
		if(!this.inputService.control) return;
		const value = this.inputService.control.value;
		if(!this.sourceOptions) return null;
		const selected = this.sourceOptions.find(el => {
			return el.value === value;
		});

		this.selectedLabel = selected ? selected.label : null;
	}

	buildSelectPropertyOptions(): void{
		if(!this.inputService.propertyDefinition) return;
		this.sourceOptions = this.inputService.propertyDefinition.property_value_options;
		this.selectOptions = this.inputService.propertyDefinition.property_value_options;
	}

	buildSelectOptions(): void{
		if(!this.inputService.formInputDefinition || !this.inputService.dataContainerService || !this.inputService.formInputDefinition.optionSource) return;
		this.selectOptions = [];
		const source = this.inputService.dataContainerService.getValueFromKeyPath(this.inputService.formInputDefinition.optionSource);
		if(!source){
			console.warn('source', this.inputService.formInputDefinition.optionSource, this.inputService.dataContainerService);
			return;
		}
		const options = source[this.inputService.formInputDefinition.optionSourceKey];
		if(!options){
			console.warn('options', this.inputService.formInputDefinition.optionSourceKey, 'not defined in', source);
			return;
		}

		this.sourceOptions = options.map(item => {
			return {
				value: item.id,
				label: item.name
			};
		});

		this.selectOptions = this.sourceOptions.map(item => item);
	}

	search(query: string): void{
		this.selectOptions = this.select(query);
	}

	select(query: string): string[]{
		const result: string[] = [];
		for(const option of this.sourceOptions){
			if(option.label.toLowerCase().indexOf(query) > -1){
				result.push(option);
			}
		}
		return result;
	}

	selectionChange(event: MatOptionSelectionChange) {
		this.appService.eventEmitter.emit({
			name: 'selected_label_' + this.inputService.formInputDefinition.key, 
			target: event.source['triggerValue']
		});
	}
}
