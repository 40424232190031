import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class LoggedGuardService implements CanActivate {

	constructor(
		public auth: AuthService,
		private appService: AppService,
		public router: Router){}

	async canActivate(): Promise<boolean> {
		if (this.auth.isAuthenticated()) {
			this.router.navigate(['home']);
			return false;
		}
		return true;
	}
}
