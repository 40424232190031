import { Component, NgZone, SkipSelf, Self, Inject } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { SmartInterfaceI18nFormStepComponent } from '../description-i18n-form-step/step.component';

@Component({
	selector   : 'resell-smart-interface-i18n-form-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ 
		DatasetActionContainerService, 
		FormViewerService,
		{ 
			provide: 'reselledDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'originalDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'reselledMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		},
		{ 
			provide: 'originalMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		}
	]
})
export class ResellSmartInterfaceI18nFormStepComponent extends SmartInterfaceI18nFormStepComponent{

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Inject('reselledDatasetRS')
		@Self()
		public reselledDatasetRS: DatasetRecordService,
		@Inject('originalDatasetRS')
		@Self()
		public originalDatasetRS: DatasetRecordService,
		@Inject('reselledMultilanguageService') 
		@Self()
		public reselledDatasetMCS: DatasetMultilanguageContainerService,
		@Inject('originalMultilanguageService') 
		@Self()
		public originalDatasetMCS: DatasetMultilanguageContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public formBuilder: FormBuilder,
		protected zone: NgZone,
		public formViewerService: FormViewerService,
		protected stepService: StepService,
	){
		super(appService, reselledDatasetRS, datasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS, formBuilder, zone, formViewerService, stepService);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.reselledDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.reselledDatasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentReselledValueKey
			});
			this.datasetMCS.fetchRecord();
			this.originalDatasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.originalDatasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentValueKey
			});
			this.originalDatasetMCS.fetchRecord();

			this.formViewerService.init({
				errors: this.formErrors,
				dataContainerService: this.datasetACS,
				fields: Array.from(this.fileterFields.values())
			});

			this.datasetRS.record.subscribe((record) => this.formViewerService.setRecord(record));
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
	}
}
