import { OnInit, OnDestroy } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
var TripPackageOverviewComponent = /** @class */ (function () {
    function TripPackageOverviewComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService) {
        this.appService = appService;
        this.datasetRS = datasetRS;
        this.datasetACS = datasetACS;
        this.datasetNavigatorService = datasetNavigatorService;
        this.datasetService = datasetService;
    }
    Object.defineProperty(TripPackageOverviewComponent.prototype, "record", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    TripPackageOverviewComponent.prototype.ngOnInit = function () {
        this._unsubscribeAll = new Subject();
    };
    TripPackageOverviewComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TripPackageOverviewComponent.prototype.trackImageBy = function (index) {
        return index;
    };
    return TripPackageOverviewComponent;
}());
export { TripPackageOverviewComponent };
