import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from '../fields';

const generalFormFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('name'),
	fieldsMap.get('description'),
	fieldsMap.get('role_type'),
	fieldsMap.get('propagate_role_to_children')
];

export default {
	fields: generalFormFields 
};
