import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { FormInputViewerModule } from 'app/main/components/form-input-viewer/form-input-viewer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavbarVerticalStyle2Component } from 'app/layout/components/navbar/vertical/style-2/style-2.component';

@NgModule({
	declarations: [
		NavbarVerticalStyle2Component
	],
	imports: [
		MatFormFieldModule,
		FormInputViewerModule,
		FlexLayoutModule,
		MatButtonModule,
		MatIconModule,
		FuseSharedModule,
		FuseNavigationModule,
		DatasetModule
	],
	exports: [
		NavbarVerticalStyle2Component,
	]
})
export class NavbarVerticalStyle2Module{}
