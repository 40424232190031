
<div class="flex">
	<div class="flex-item">
		
		<div class="loading-div" *ngIf='dashboardService.loadingSourceMarket || showLoader'>
			<mat-spinner *ngIf='dashboardService.loadingSourceMarket' diameter="80" class='full-center-spinner'></mat-spinner>
		</div>

		<div style="display: block;">
			<canvas baseChart *ngIf='sourcemarkets'
			[data]="sourcemarkets"
			[labels]="sourcelabel"
			[chartType]="pieChartType"
			[options]="pieChartOptions"
			[colors]="pieChartColors"
			[legend]="pieChartLegend">
			</canvas>
		</div>
	</div>
</div>

