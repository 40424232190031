import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
var CarouselBlockComponent = /** @class */ (function () {
    function CarouselBlockComponent(appService, datasetACS, datasetService, datasetNavigatorService, fuseTranslationLoader) {
        this.appService = appService;
        this.datasetACS = datasetACS;
        this.datasetService = datasetService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.fuseTranslationLoader = fuseTranslationLoader;
        this.showTitle = true;
        this.dragging = true;
        this.uploading = false;
        this.dataList = [];
        this.imageRatio = 1920 / 1080;
        this.imageWidth = 1920;
        this.imageHeight = 1080;
        // load translations
        this.fuseTranslationLoader.loadTranslations(english, italiano);
    }
    CarouselBlockComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.imageOptions) {
            if (this.imageOptions.ratio) {
                this.imageRatio = this.imageOptions.ratio;
            }
            if (this.imageOptions.minWidth) {
                this.imageWidth = this.imageOptions.minWidth;
            }
            if (this.imageOptions.minHeight) {
                this.imageHeight = this.imageOptions.minHeight;
            }
        }
        this._unsubscribeAll = new Subject();
        this.datasetACS.init({
            datasetCode: this.configs.datasetCode,
            actionCode: 'list',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: false,
            useBeforeCalls: false
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.loadImages();
        });
        this.datasetACS.datasetEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            _this.loadImages();
        });
    };
    CarouselBlockComponent.prototype.loadImages = function () {
        var _this = this;
        this.datasetACS.loading.next(true);
        this.datasetService.get(this.datasetACS.getListRoute())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.imagesPage = response;
                if (_this.imagesPage && _this.imagesPage.items) {
                    _this.dataList = _this.imagesPage.items.concat([]);
                }
                else {
                    _this.dataList = [];
                }
            },
            error: function () {
                _this.datasetACS.loading.next(false);
            }
        });
    };
    CarouselBlockComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    CarouselBlockComponent.prototype.onFileDragStart = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.dragging = true;
    };
    CarouselBlockComponent.prototype.onFileDragLeave = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.dragging = false;
    };
    CarouselBlockComponent.prototype.onFileDragOver = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.dragging = true;
    };
    CarouselBlockComponent.prototype.onFileDrop = function (event) {
        event.preventDefault();
        event.stopPropagation();
        this.dragging = false;
        var file = null;
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            file = event.dataTransfer.items[0].getAsFile();
        }
        else if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            file = event.dataTransfer.files[0];
        }
        if (file)
            this.imageChangedEvent = { target: { files: [file] } };
        else
            this.imageChangedEvent = null;
        this.inputFile = file;
    };
    CarouselBlockComponent.prototype.onFileChange = function (event) {
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            this.imageChangedEvent = event;
            this.inputFile = file;
            this.uploadFile();
        }
        else {
            this.inputFile = null;
            this.imageChangedEvent = null;
        }
    };
    CarouselBlockComponent.prototype.trackImageBy = function (index, item) {
        return index;
    };
    CarouselBlockComponent.prototype.uploadFile = function () {
        var _this = this;
        var formData = new FormData();
        if (!this.croppedFile)
            return;
        formData.append(this.configs.parentForeignKey, this.parentDatasetRS.recordId);
        formData.append('file', this.croppedFile);
        this.uploading = true;
        this.datasetService.post('/dataset/' + this.configs.parentDatasetCode + '/command/upload_carousel_image', formData)
            .subscribe({
            next: function (response) {
                _this.inputFile = null;
                _this.imageChangedEvent = null;
                _this.croppedFile = null;
                _this.uploading = false;
                _this.loadImages();
            },
            error: function (response) {
                _this.uploading = false;
            }
        });
    };
    CarouselBlockComponent.prototype.imageCropped = function (event) {
        var invalidImage = null;
        if (event.file) {
            if (this.imageOptions) {
                if (this.imageOptions.minWidth > event.width) {
                    invalidImage = 'Dimensioni immagine non supportate';
                }
                else if (this.imageOptions.minHeight > event.height) {
                    invalidImage = 'Dimensioni immagine non supportate';
                }
            }
        }
        if (invalidImage) {
            this.inputFile = null;
            this.croppedFile = null;
            this.imageChangedEvent = null;
            this.appService.showErrorMessage(invalidImage);
            return;
        }
        this.croppedFile = event.file;
    };
    CarouselBlockComponent.prototype.imageLoaded = function () {
        // show cropper
    };
    CarouselBlockComponent.prototype.cropperReady = function () {
        // cropper ready
    };
    CarouselBlockComponent.prototype.loadImageFailed = function () {
        this.appService.showErrorMessage('Impossibile caricare l\'immagine');
    };
    CarouselBlockComponent.prototype.onDropList = function (event) {
        if (this.parentDatasetRS.record.value &&
            this.parentDatasetRS.record.value.allowed_actions &&
            this.parentDatasetRS.record.value.allowed_actions.includes('edit')) {
            moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);
            this.updateOrder();
        }
    };
    CarouselBlockComponent.prototype.removeImage = function (document) {
        this.datasetNavigatorService.openDeleteDialog(this.datasetACS, document);
    };
    CarouselBlockComponent.prototype.updateOrder = function () {
        var e_1, _a;
        var _this = this;
        var params = {};
        params[this.configs.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.configs.parentDatasetCode + '.record.id');
        params['carousel_images'] = {};
        var i = 0;
        try {
            for (var _b = tslib_1.__values(this.dataList), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                params['carousel_images'][item.id] = i;
                i++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (this.reorderSubscription && !this.reorderSubscription.closed) {
            this.reorderSubscription.unsubscribe();
        }
        this.reorderSubscription = this.datasetService.post('/dataset/' + this.configs.parentDatasetCode + '/command/reorder_carousel_images', params)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.appService.showSuccessMessage('Ordine immagini modificato.');
            },
            error: function (response) {
            }
        });
    };
    return CarouselBlockComponent;
}());
export { CarouselBlockComponent };
