import { IFormFilterConfig, ValueTypes, InputTypes } from 'app/interfaces';

export default {
	fields: [
		{
			name: 'Channel',
			key: 'channel_id',
			valueType: ValueTypes.STRING,
			inputConfig: {
				type: InputTypes.DATASET_SELECT,
			},
			datasetCode: 'tourcms_channels',
			remoteOptions: {
				getLabel(option): string{
					if(!option) return '';
					return option.channel_name;
				},
				getValue(option): string{
					if(!option) return null;
					return option.id;
				}
			}
		}
	]
} as IFormFilterConfig;
