export const environment = {
	production: true,
	hmr       : false,
	serviceWorker: true,
	serverAddress: '',
	serverPort: null,
	apiRoutePrefix: '/ajax',
	sessionMaxAge: 24 * 60 * 60,
	BUGSNAG_API_KEY: '2fa607505b9fab8ba5c99a92d3b632eb',
	websocket: {
		enabled: false,
		port: 3444,
		// url: 'http://localhost' //default use same of serverAddress
	},
	trenitalia: {
		ar: true
	}
};