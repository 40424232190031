import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'entity_supplier_id',
	name: 'Fornitore',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_suppliers',
	inputHint: 'Scrivi per cercare un fornitore',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.description;
		}
	}
};
