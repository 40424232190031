import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews, IPaginationResponse, ListViewExportTypes } from 'app/interfaces';
import detailForm from './detail.form';
import request_document_RT_form from './request_document_rt.form';
import response_document_RT_form from './response_document_rt.form';
import { fieldsMap } from './fields';
import filterForm from './filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/transmission_operations/detail/:recordId',
	params: {
		with_attributes: 'allowed_actions',
		with_relations: 'tripBooking'
	}
};

export const TransmissionOperationConfig: IDatasetConfig = {
	name: 'transmission_operations',
	title: 'Trasmissioni',
	singleTitle: 'Trasmissione',
	ajaxDatasetCode: 'transmission_operations',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		delete: false,
		list: true
	},
	formConfigsMap: {
		detailForm,
		request_document_RT_form,
		response_document_RT_form
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		list: {
			viewConfig: {
				filterForm: filterForm,
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				showExportBtn: true,
				exportBtnActions: [
					{
						typeFile: ListViewExportTypes.XLS,
						buttonText: 'Esporta xls'
					},
					{
						typeFile: ListViewExportTypes.CSV,
						buttonText: 'Esporta csv'
					}
				],
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice Trasmissione',
						key: 'code'
					},
					{
						title: 'Codice Prenotazione',
						key: 'trip_booking_code',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any){
							return record.trip_booking_code || '';
						}
					},
					{
						title: 'Filiale',
						key: 'branch_code',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any){
							return record.branch_code || '';
						}
					},
					{
						title: 'Codice Pratica',
						key: 'updated_at',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							if (record.pratica_codes) return record.pratica_codes;
							// deprecated relation sale_operation
							if (!record.trip_booking) return '';
							if (!record.trip_booking.sale_operation) return '';
							return record.trip_booking.sale_operation.pratica.code;
						}
					},
					{
						title: 'Pacchetti trasmessi',
						key: 'receipt'
					},
					{
						title: 'Stato',
						key: 'status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any){
							let colorClass = '';
							let text = '';
							if (record.status == 1){
								colorClass = 'green-bg';
								text = '<b>CONFERMATA</b>';
							}else if (record.status == 0) {
								colorClass = 'red-bg';
								text = '<b>ANNULLATA</b>';
							}
							let html = '<div class="transmission_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;vertical-align:middle;"></span>';
							html += '<span style="vertical-align:middle;">' + text + '</span>';
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Data',
						key: 'created_at'
					},
					{
						title: 'Totale',
						key: 'total'
					}
				],
				actions: ['detail'],
				metaReviews: ['total_amount'],
				getMetaReview(paginationResponse: IPaginationResponse<any>, metaReview: any): any{
					const total_amount = new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(paginationResponse.meta[metaReview]);
					return 'Totale trasmissioni: ' + total_amount;
				}
			}
		}
	}
};
