import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as entityFieldMap} from '../entities/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('staff_type', {
	key: 'staff_type',
	name: 'Tipologia',
	inputType: InputTypes.MULTI_SELECT,
	valueType: ValueTypes.PROPERTY
});

entityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value);

	fieldsMap.set(key, value);
});

// Use the follow for display
entityFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'entity.' + value.key });

	fieldsMap.set('entity.' + key, value);
});

export {fieldsMap};
