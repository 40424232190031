import { InputTypes, ValueTypes, IFormConfig } from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';


export default {
	fields: [
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'adt_counter',
					name: 'Adulti',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER
				},
				{
					key: 'chd_counter',
					name: 'Bambini',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER
				}
			]
		},
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'inf_counter',
					name: 'Neonati',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER
				},
				{
					key: 'snr_counter',
					name: 'Anziani',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER,
				}
			]
		},
		cloneFieldDefinition(nationCodeField, {
			formValidators: [Validators.required],
			inputHint: 'Obbligatorio',
			inputRequired: true
		})
	]
} as IFormConfig;
