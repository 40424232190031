import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { fieldsMap } from './form.fields';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { showIfEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';
var ProductNameStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductNameStepComponent, _super);
    function ProductNameStepComponent(appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService) {
        var _this = 
        /** init parent constructor */
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this.formBuilder = formBuilder;
        _this.zone = zone;
        _this.stepService = stepService;
        _this.formViewerService = formViewerService;
        // @ViewChild('formViewer') formViewer: FormViewerComponent;
        _this.fileterFields = fieldsMap;
        /** load other data */
        _this.datasetRS.record
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe(function (record) {
            _this.formViewerService.setRecord(record);
            if (_this.datasetRS.recordId) {
                _this.canEdit = _this.datasetACS.canEdit(_this.datasetRS.record.value);
            }
            else {
                // is a new record
                _this.canEdit = true;
            }
        });
        return _this;
    }
    ProductNameStepComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewerService.formGroup.disable();
        var formData = this.formViewerService.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        if (this.stepConfig.options && typeof (this.stepConfig.options.getCallParams) === 'function') {
            formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
        }
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.recordId) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        else {
            formData['lang'] = this.appService.language.value;
        }
        var headers = this.datasetACS.getContextualDomainHeaders();
        this.datasetService.post(route, formData, { headers: headers })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.stepService.setStepState({
                    dataset_code: 'trip_packages',
                    form_code: 'product_name',
                    record_id: response.id,
                    status: 1
                });
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.formViewerService) {
                    _this.formViewerService.formGroup.enable();
                    _this.formViewerService.clearForm();
                }
                if (!_this.datasetRS.record.value && response && response.id) {
                    _this.datasetNavigatorService.router.navigate(['dataset/edit/' + _this.datasetACS.datasetCode, response.id], { queryParams: { step: 2 } });
                }
                else if (_this.datasetRS.record.value && response && response.id) {
                    _this.datasetRS.recordId = response.id;
                    _this.datasetRS.reload()
                        .subscribe(function (record) {
                        if (record) {
                            _this.formViewerService.setRecord(record);
                            setTimeout(function () {
                                _this.goNextStep();
                            }, 200);
                        }
                        else {
                            _this.datasetNavigatorService.onEdit(_this.datasetACS, response);
                        }
                    });
                }
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewerService) {
                    _this.formViewerService.formGroup.enable();
                    _this.formViewerService.setErrors(_this.formErrors);
                }
            }
        });
    };
    ProductNameStepComponent.prototype.ngOnInit = function () {
        this.datasetACS.loading.next(false);
        this.showIfeAgency = showIfEAgency(this.datasetRS.record.value, this.datasetACS);
        this.formViewerService.init({
            errors: this.formErrors,
            dataContainerService: this.datasetACS,
            fields: Array.from(this.fileterFields.values())
        });
    };
    return ProductNameStepComponent;
}(BaseStepViewComponent));
export { ProductNameStepComponent };
