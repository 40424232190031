import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { BehaviorSubject, Subject, Observable, of, EMPTY } from 'rxjs';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { AuthService } from 'app/services/auth.service';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { fieldsMap } from 'app/configs/datasets/trip_bookings/fields';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
var BookingService = /** @class */ (function () {
    function BookingService(http, appService, authService, datasetService, matDialog, datasetACS) {
        this.http = http;
        this.appService = appService;
        this.authService = authService;
        this.datasetService = datasetService;
        this.matDialog = matDialog;
        this.datasetACS = datasetACS;
        this.loading = new BehaviorSubject(false);
        this.useLastDraft = false;
        this.canEdit = false;
        this.nextStep = new EventEmitter();
        this.prevStep = new EventEmitter();
        this.statusText = {
            canceled: 'Cancellata',
            confirmed: 'Confermata',
            optional: 'Opzionale (in scadenza)',
            expired: 'Scaduata',
            unconfirmed: 'Non Confermata',
            draft: 'Preventivo',
            mix: 'Mista'
        };
        this.statusTextColor = {
            canceled: 'red-fg',
            confirmed: 'green-fg',
            optional: 'orange-fg',
            expired: 'orange-fg',
            unconfirmed: 'orange-fg',
            draft: 'orange-fg',
            mix: 'orange-fg'
        };
    }
    /**
     * create new draft
     */
    BookingService.prototype.newDraftBooking = function (deleteId) {
        var _this = this;
        var result = new Subject();
        this.loading.next(true);
        this.datasetService.post('/dataset/trip_bookings/command/draft_booking', { deleteId: deleteId })
            .subscribe({
            next: function (response) {
                result.next(response);
                result.complete();
                _this.loading.next(false);
            },
            error: function (response) {
                result.error(response);
                result.complete();
                _this.loading.next(false);
            }
        });
        return result;
    };
    BookingService.prototype.loadLastDraft = function () {
        var _this = this;
        var result = new Subject();
        this.loading.next(true);
        var params = {
            perPage: 1,
            status: 'draft',
            sortBy: 'updated_at',
            sortDirection: 'desc',
            only_my: 1,
            with_detail: 1
        };
        this.datasetService.get('/dataset/trip_bookings', params)
            .subscribe({
            next: function (response) {
                if (response && response.items && response.items.length > 0) {
                    result.next(response.items[0]);
                }
                else {
                    result.next(null);
                }
                result.complete();
                _this.loading.next(false);
            },
            error: function (response) {
                _this.loading.next(false);
                result.error(response);
                result.complete();
            }
        });
        return result;
    };
    BookingService.prototype.discardDraft = function (id) {
        return this.datasetService.delete('/dataset/trip_bookings/delete/' + id);
    };
    BookingService.prototype.onActionResponse = function (response, dialogRef) {
        if (!dialogRef || !dialogRef.componentInstance) {
            dialogRef = this.matDialog.open(ActionDialogComponent, {
                width: '550px',
                data: {}
            });
        }
        dialogRef.componentInstance.loading = false;
        if (response.success) {
            dialogRef.componentInstance.resultStatus = 'success';
        }
        else {
            dialogRef.componentInstance.resultStatus = 'error';
        }
        if (response.message) {
            dialogRef.componentInstance.setMessage(response.message);
        }
    };
    BookingService.prototype.onErrorActionResponse = function (response, dialogRef) {
        if (!dialogRef || !dialogRef.componentInstance) {
            dialogRef = this.matDialog.open(ActionDialogComponent, {
                width: '550px',
                data: {
                    title: 'Azione in corso'
                }
            });
        }
        dialogRef.componentInstance.loading = false;
        dialogRef.componentInstance.resultStatus = 'error';
        dialogRef.componentInstance.setTitle('Errore');
        if (response.error) {
            var message = this.getErrorMessage(response.error, response.error.message);
            if (message)
                dialogRef.componentInstance.setMessage(message);
        }
        else {
            if (response.error)
                dialogRef.componentInstance.setMessage(response.error);
            else
                dialogRef.componentInstance.setMessage('Si è verificato un errore.');
        }
    };
    BookingService.prototype.getErrorMessage = function (response, defaultMessage) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        if (!response)
            return defaultMessage;
        var report = response;
        if (response.report) {
            report = response.report;
        }
        if (response.details && response.details.length) {
            var msg = '';
            try {
                for (var _e = tslib_1.__values(response.details), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var det = _f.value;
                    msg += "<br/> " + det.reason;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return msg;
        }
        if (response.message) {
            return response.message;
        }
        if (report.invalid) {
            try {
                for (var _g = tslib_1.__values(report.invalid), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var code = _h.value;
                    if (code === 'no_packages')
                        return 'Devi aggiungere almeno una partenza.';
                    if (code === 'no_participants')
                        return 'Devi aggiungere almeno un partecipante.';
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else if (report.actual_trip) {
            if (report.max_allotment < 0)
                return 'Non ci sono posti sufficienti per la partenza selezionata.';
        }
        else if (report.trip_booking_packages) {
            try {
                for (var _j = tslib_1.__values(report.trip_booking_packages), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var tripBookingPackageReport = _k.value;
                    return this.getErrorMessage(tripBookingPackageReport, defaultMessage);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        else if (report.trip_booking_resources) {
            try {
                for (var _l = tslib_1.__values(report.trip_booking_resources), _m = _l.next(); !_m.done; _m = _l.next()) {
                    var tripBookingPackageReport = _m.value;
                    return this.getErrorMessage(tripBookingPackageReport, defaultMessage);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return defaultMessage;
    };
    BookingService.prototype.suspendBooking = function (bookingId) {
        var _this = this;
        var result = new Subject();
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Sospensione in corso'
            }
        });
        var actionResponse = null;
        this.datasetService.post('/dataset/trip_bookings/command/suspend_booking', {
            trip_booking_id: bookingId
        }).subscribe({
            next: function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
                result.next(response);
                result.complete();
            },
            error: function (response) {
                actionResponse = response;
                _this.onErrorActionResponse(response, dialogRef);
            }
        });
        /*dialogRef.afterClosed()
        .subscribe(dialogResult => {
            result.next(actionResponse);
            result.complete();
        });*/
        return result;
    };
    BookingService.prototype.confirmBooking = function (bookingId, tripPackageBookingIds) {
        var _this = this;
        return this.execPreAction('trip_bookings:confirm')
            .pipe(exhaustMap(function () {
            var dialogRef = _this.matDialog.open(ActionDialogComponent, {
                width: '550px',
                data: {
                    title: 'Conferma in corso'
                }
            });
            return _this.datasetService.post('/dataset/trip_bookings/command/confirm_booking', {
                trip_booking_id: bookingId,
                trip_booking_package_ids: tripPackageBookingIds
            }).pipe(tap(function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
            }), catchError(function (errorResponse) {
                _this.onErrorActionResponse(errorResponse, dialogRef);
                return EMPTY;
            }));
        }));
    };
    /**
     * TODO: move in trip booking package component
     * @param tripBookingPackageId
     * @param status
     * @returns
     */
    BookingService.prototype.setDeliveryStatus = function (tripBookingPackageId, status) {
        var _this = this;
        var result = new Subject();
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Conferma in corso'
            }
        });
        var actionResponse = null;
        this.datasetService.post('/dataset/trip_bookings/command/confirm_delivery', {
            trip_booking_package_id: tripBookingPackageId,
            delivery_status: status // status = in consegna or consegnato
        }).subscribe({
            next: function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
                result.next(response);
                result.complete();
            },
            error: function (response) {
                actionResponse = response;
                _this.onErrorActionResponse(response, dialogRef);
            }
        });
        /*dialogRef.afterClosed()
        .subscribe(dialogResult => {
            result.next(actionResponse);
            result.complete();
        });*/
        return result;
    };
    BookingService.prototype.cancelBooking = function (bookingId, extraData) {
        var _this = this;
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Annullamento in corso'
            }
        });
        return this.datasetService.post('/dataset/trip_bookings/command/cancel_booking', Object.assign({
            trip_booking_id: bookingId
        }, extraData)).pipe(tap({
            next: function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
            },
            error: function (errorResponse) {
                _this.onErrorActionResponse(errorResponse, dialogRef);
            }
        }));
    };
    BookingService.prototype.cancelPermanentBooking = function (bookingId) {
        var _this = this;
        var result = new Subject();
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Cancellazione in corso'
            }
        });
        var actionResponse = null;
        this.datasetService.post('/dataset/trip_bookings/command/permanent_cancel_booking', {
            trip_booking_id: bookingId
        }).subscribe({
            next: function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
                result.next(response);
                result.complete();
            },
            error: function (response) {
                actionResponse = response;
                _this.onErrorActionResponse(response, dialogRef);
            }
        });
        return result;
    };
    BookingService.prototype.optionalBooking = function (bookingId, data) {
        var _this = this;
        var result = new Subject();
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Azione in corso'
            }
        });
        var actionResponse = null;
        var postData = Object.assign({
            trip_booking_id: bookingId
        }, data);
        this.datasetService.post('/dataset/trip_bookings/command/optional_booking', postData)
            .subscribe({
            next: function (response) {
                _this.useLastDraft = false;
                _this.onActionResponse(response, dialogRef);
                result.next(response);
                result.complete();
            },
            error: function (response) {
                actionResponse = response;
                _this.onErrorActionResponse(response, dialogRef);
            }
        });
        /*dialogRef.afterClosed()
        .subscribe(dialogResult => {
            result.next(actionResponse);
            result.complete();
        });*/
        return result;
    };
    BookingService.prototype.getTripBooking = function () {
        return this.datasetACS.getValueFromKeyPath('trip_bookings.record');
    };
    BookingService.prototype.execPreAction = function (action, formErrors) {
        var _this = this;
        if (action == 'command:pre_action')
            return of(true);
        var tripBooking = this.datasetACS.getValueFromKeyPath('trip_bookings.record');
        var defaultValue = this.datasetACS.getValueFromKeyPath('trip_bookings.record.payment_method_code');
        var backofficeRequiredFields = this.datasetACS.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
        if (action != 'trip_bookings:confirm' && !['confirmed', 'mix'].includes(tripBooking.status))
            return of(true);
        return of({})
            .pipe(exhaustMap(function () {
            if (!backofficeRequiredFields.includes('payment_method_code'))
                return of({});
            return _this.matDialog.open(FormDialogComponent, {
                width: '500px',
                data: {
                    title: 'Metodo di pagamento',
                    formData: {
                        payment_method_code: defaultValue
                    },
                    formConfig: {
                        fields: [
                            fieldsMap.get('payment_method_code'),
                        ]
                    },
                    dataContainerService: _this.datasetACS,
                    positiveText: 'Conferma',
                    formErrors: formErrors
                }
            }).afterClosed();
        }), exhaustMap(function (data) {
            if (data === false || data === undefined)
                return EMPTY;
            var dialogRef = _this.matDialog.open(ActionDialogComponent, {
                width: '550px',
                data: {
                    title: 'Azione in corso'
                }
            });
            return _this.execBookingCommand('pre_action', Object.assign({
                trip_booking_id: tripBooking.id,
                action: action
            }, data), false)
                .pipe(exhaustMap(function (response) {
                if (response.allowed) {
                    dialogRef.close();
                    return of(true);
                }
                else {
                    _this.onActionResponse({
                        message: response.message || 'Operazione non permessa'
                    }, dialogRef);
                    return EMPTY;
                }
            }), catchError(function (response) {
                if (response.status == 422 && response.error && response.error.errors) {
                    dialogRef.close();
                    return _this.execPreAction(action, response.error.errors);
                }
                _this.onErrorActionResponse(response, dialogRef);
                return EMPTY;
            }));
        }));
    };
    BookingService.prototype.execBookingCommand = function (command, postData, useDefaultDialog) {
        var _this = this;
        if (useDefaultDialog === void 0) { useDefaultDialog = true; }
        return this.execPreAction('command:' + command)
            .pipe(exhaustMap(function () {
            var dialogRef = null;
            if (useDefaultDialog) {
                dialogRef = _this.matDialog.open(ActionDialogComponent, {
                    width: '550px',
                    data: {
                        title: 'Azione in corso'
                    }
                });
            }
            return _this.datasetService.post('/dataset/trip_bookings/command/' + command, postData)
                .pipe(tap(function (response) {
                if (useDefaultDialog) {
                    dialogRef.componentInstance.setTitle(null);
                    _this.onActionResponse(response, dialogRef);
                }
            }, function (response) {
                if (useDefaultDialog)
                    _this.onErrorActionResponse(response, dialogRef);
            }));
        }));
    };
    return BookingService;
}());
export { BookingService };
