import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import {FormViewerComponent } from './form-viewer.component';
import {FormInputViewerModule } from './../form-input-viewer/form-input-viewer.module';

@NgModule({
	declarations: [
		FormViewerComponent
	],
	imports     : [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatExpansionModule,

		FuseSharedModule,
		TranslateModule.forChild(),
		FormInputViewerModule
	],
	exports: [
		FormViewerComponent,
		FormInputViewerModule
	]
})
export class FormViewerModule{}
