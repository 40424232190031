<div class='full-form style-1'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value || !mapReady'></mat-progress-bar>
	<p *ngIf='savedCoords'>Coordinate salvate: {{savedCoords.lat}}, {{savedCoords.lng}}</p>
	<p *ngIf='!savedCoords || (lat != savedCoords.lat || lng != savedCoords.lng)'>Nuove coordinate: {{this.lat}}, {{this.lng}}</p>
	<p *ngIf='selectedPositionInfo && (!this.markers || !this.markers.length)'>
		Nuovo indirizzo: {{selectedPositionInfo.formatted_address}}
	</p>
	<div fxLayout='row' fxLayoutAlign='start center'>
		<mat-form-field fxFlex class='mr-8'>
			<input matInput placeholder="Cerca luogo ..." [formControl]='searchTextFormControl'>
			<mat-spinner *ngIf="geosearching" diameter="20" matSuffix></mat-spinner>
		</mat-form-field>
		<button mat-flat-button color='accent' (click)='search()'>
			Cerca
		</button>
	</div>
	
	<agm-map
		[latitude]="lat"
		[longitude]="lng"
		[(zoom)]="zoom"
		[disableDefaultUI]="false"
		[zoomControl]="false"
		(mapReady)='mapReady.next(true)'
		(mapClick)="mapClicked($event)">

		<agm-marker 
			*ngFor="let m of markers; let i = index"
			(markerClick)="clickedMarker(m.label, i)"
			[latitude]="m.lat"
			[longitude]="m.lng"
			[markerDraggable]="m.draggable"
			animation='DROP'
			(dragEnd)="markerDragEnd(m, $event)">
			<agm-info-window [isOpen]='m.label'>
				<strong>{{m.label}}</strong>
			</agm-info-window>
		</agm-marker>
	</agm-map>

	<div *ngIf='canEdit' class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
		<button *ngIf='savedCoords' [disabled]='lat == savedCoords.lat && lng == savedCoords.lng' mat-flat-button (click)='resetPosition()'>Annulla modifiche</button>
		<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='onSave()'>
			<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
			<span *ngIf='datasetACS.loading.value'>{{'Azione in corso...' | translate}}</span>
		</button>
	</div>
</div>