import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CalendarEventModel } from 'app/main/pages/calendar/event.model';
import { DatasetCalendarViewComponent } from 'app/main/components/dataset/base-actions/list/views/calendar/calendar.component';

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	params['entity_id'] = dataContainerService.getValueFromKeyPath('entities.recordId');
	return params;
}

export const EntityCalendarEventConfig: IDatasetConfig = {
	name: 'entity_calendar_events',
	title: 'Calendario',
	singleTitle: 'Calendario Impegni',
	ajaxDatasetCode: 'entity_calendar_events',
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CALENDAR,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				calendarConfig: {
					itemsToEvents(items: any[], component: DatasetCalendarViewComponent): CalendarEventModel[]{
						if(!items) return [];
						return items.map(item => {
							const calendarEvent = new CalendarEventModel(undefined, component.datasetNavigatorService, component);
							calendarEvent.fromEntityCalendarEvent(item, 'trip_resource_staff_assignments');
							return calendarEvent;
						});
					},
					templateCodes: {

					}
				}
			}
		}
	}
};
