import {Component, forwardRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PageClosureContainerService} from '../../../page-closure-container.service';
import {trainTypeImages, TrenitaliaService} from '../trenitalia.service';
import {AppService} from '../../../../../services/app.service';
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TrenitaliaPostSaleService} from './trenitalia-post-sale.service';
import {wrapArray} from 'app/helpers';
import {LoadingDialogComponent} from "../../../../dialogs/loading-dialog/dialog.component";
import {FormDialogComponent} from "../../../../dialogs/form-dialog/dialog.component";
import {IFormConfig, InputTypes, ValueTypes} from "../../../../../interfaces";
import {Validators} from "@angular/forms";

@Component({
	selector: 'trenitalia-details',
	templateUrl: './trenitalia-details.component.html',
	styleUrls: ['./trenitalia-details.component.scss'],
	providers: [forwardRef(() => PageClosureContainerService), TrenitaliaPostSaleService, TrenitaliaService]
})
export class TrenitaliaDetailsComponent implements OnInit {
	public travellers: any[];
	public editTrls = false;
	public postActions: any;
	public load = false;
	public package: any;
	public details: any;
	public status: string;
	public travelSolutions: any[];
	public isCarnet:boolean;

	public postActionButtons: any[];

	protected _unsubscribeAll: Subject<any>;

	get trainTypeImages(){
		return trainTypeImages;
	}

	constructor(
		public appService: AppService,
		public matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public dataDialog: any,
		public trnService: TrenitaliaService,
		protected mastSnackBar: MatSnackBar,
		public trenitaliaPostSaleService: TrenitaliaPostSaleService
	) {
		this._unsubscribeAll = new Subject;
	}

	ngOnInit() {

		this.isCarnet = this.dataDialog.isCarnet;
		this.trenitaliaPostSaleService.setTravelData(this.dataDialog.tripBookingPackage,this.isCarnet);
		this.trenitaliaPostSaleService.travelDetails
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(travelDetails => {
			this.updateData();
		});
	}


	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	updateData() {
		this.travelSolutions = wrapArray(_.get(this.trenitaliaPostSaleService.travelDetails.value, 'travel.travelSolutions'));
		this.postActions = _.get(this.trenitaliaPostSaleService.travelDetails.value, 'travel.postActions');
	}

	sendMail(){
		let destinationEmail = _.get(this.dataDialog.tripBookingPackage,'tour_operator_booking_data.complete_order_response.travel.travelContact.email');
		if(!destinationEmail && this.trenitaliaPostSaleService.isCarnet){
			destinationEmail = _.get(this.dataDialog.tripBookingPackage,'tour_operator_booking_data.complete_order_response.travel.travellers.email')
		}

		const formConfig: IFormConfig = {
			formStyle: '',
			fields: [
				{
					key: 'send_mail',
					name: 'Invia mail a',
					valueType: ValueTypes.STRING,
					formValidators: [ Validators.required, Validators.email ],
					inputConfig: {
						appearence: 'legacy',
						type: InputTypes.EMAIL,
						clearable: true,
						placeholder: 'example@mail.com',
						required: true
					}
				},
				{
					key: 'sub_mail',
					name: 'usa l\'email sostitutiva',
					titleTooltip: 'Invia una email sostitutiva a quella inviata da Tranitalia',
					inputType: InputTypes.CHECKBOX,
					valueType: ValueTypes.BOOLEAN,
					appearance: 'legacy'
				}
			]
		};
		
		this.matDialog.open(FormDialogComponent, {
			data:{
				title: 'Invia biglietti via mail',
				formData: {
					send_mail: destinationEmail
				},
				formConfig,
				positiveText: 'Invia'
			}
		}).afterClosed()
		.subscribe((response)=>{
			if(!response) return;
			const loadingDialogRef2 = this.matDialog.open(LoadingDialogComponent, {
				data:{title: 'Invio in corso'}
			});

			const body = {trip_booking_package_id:this.dataDialog.tripBookingPackage.id,send_replacement_email:response.sub_mail}
			if( destinationEmail!==response.send_mail){body['optional_email'] = response.send_mail}
			this.trenitaliaPostSaleService.sendEmail(body)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: () => {
					this.appService.showSuccessMessage('Email inviata con successo');
				},
				error: ()=>{
					loadingDialogRef2.close()
				},
				complete:()=>{
					loadingDialogRef2.close()
				}
			});
		});

	}
}