import * as tslib_1 from "tslib";
import { ElementRef, ViewContainerRef } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DataFilterComponent } from '../data-filter.component';
import { PopoverService } from 'app/main/dialogs/popover/popover.service';
import { FormPopoverComponent } from 'app/main/dialogs/form-popover/popover.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
var Style1FilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(Style1FilterComponent, _super);
    function Style1FilterComponent(matDialog, datasetNavigatorService, _eref, popoverService, viewContainerRef) {
        var _this = _super.call(this, matDialog, datasetNavigatorService, viewContainerRef) || this;
        _this.matDialog = matDialog;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this._eref = _eref;
        _this.popoverService = popoverService;
        _this.viewContainerRef = viewContainerRef;
        _this.isFiltersOpen = false;
        return _this;
    }
    Style1FilterComponent.prototype.onClick = function (event) {
        if (!this._eref.nativeElement.contains(event.target))
            this.isFiltersOpen = false;
    };
    Style1FilterComponent.prototype.toggleFilters = function () {
        this.isFiltersOpen = !this.isFiltersOpen;
    };
    Style1FilterComponent.prototype.showPopover = function (target) {
        var _this = this;
        this.popoverService.open(FormPopoverComponent, target, {
            hasBackdrop: true,
            panelClass: 'custom-dialog-container',
            data: {
                title: 'Filtri ricerca',
                formData: this.filtersData,
                formConfig: this.formConfig,
                dataContainerService: this.dataContainerService,
                positiveText: 'Applica'
            }
        })
            .afterClosed().subscribe(function (result) {
            if (result) {
                _this.applyFilter(result);
            }
        });
    };
    Style1FilterComponent.prototype.showMobileFilters = function () {
        var _this = this;
        this.datasetNavigatorService.openDialog(FormDialogComponent, {
            title: 'Filtri ricerca',
            formData: this.filtersData,
            formConfig: this.formConfig,
            dataContainerService: this.dataContainerService,
            positiveText: 'Applica'
        })
            .afterClosed().subscribe(function (result) {
            if (result) {
                _this.applyFilter(result);
            }
        });
    };
    return Style1FilterComponent;
}(DataFilterComponent));
export { Style1FilterComponent };
