<ng-template #blockTemplate let-blockConfig='blockConfig' let-inTab='inTab' let-index='index'>
	<ng-container [ngSwitch]='blockConfig.type'>
		<!-- CALENDAR BASED ALLOTMENT -->
		<dataset-list
			*ngSwitchCase='"calendar"'
			#listBlocks
			[showTitle]='enableBlocks.length > 1'
			[autoFetchData]='true'
			[datasetCode]='blockConfig.datasetCode'
			[filters]='blockFilters[index]'
			[parentDatasetRS]='datasetRS'
			[parentDatasetACS]='datasetACS'
			[conditionalShowPaginationToolbar]='true'
			[showSearchBar]='false'
			[elevate]='false'
			[showTitle]='!inTab'
			(created)='onCreatedInBlock(blockConfig)'
			(datasetEvent)='onDatasetEvent($event)'
		></dataset-list>
		<!-- FORM FIXED ALLOTMENT -->
		<form-fixed-allotment 
			*ngSwitchCase='"form"'
			[stepConfig]='stepConfig'
			[hasNext]='true'
			[hasPrevious]='true'
			(nextStep)='stepper.next()'
		></form-fixed-allotment>
		<!-- NOT CONFIGURED DATASET -->
		<div *ngSwitchDefault>
			<p>{{'Blocco non configurato' | translate}}: {{blockConfig.type}}</p>
		</div>
	</ng-container>
</ng-template>

<div class='full-form'>
	<ng-container>
		<ng-container *ngFor='let blockConfig of stepConfig.options.blocks;let i = index;'>
			<ng-container *ngIf='enableBlocks[i]'>
				<ng-container 
					[ngTemplateOutlet]='blockTemplate' 
					[ngTemplateOutletContext]='{blockConfig: blockConfig, inTab: false, index: i}'
				></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</div>