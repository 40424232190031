import * as tslib_1 from "tslib";
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { InvalidAllotmentsDialogComponent } from '../../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
var CommonFormStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonFormStepComponent, _super);
    function CommonFormStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.datasetRS.record
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe(function (record) {
            if (_this.datasetRS.recordId) {
                _this.canEdit = _this.datasetACS.canEdit(_this.datasetRS.record.value);
            }
            else {
                // is a new record
                _this.canEdit = true;
            }
        });
        return _this;
    }
    CommonFormStepComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        if (this.stepConfig.options && typeof (this.stepConfig.options.getCallParams) === 'function') {
            formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
        }
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.recordId) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        else {
            formData['lang'] = this.appService.language.value;
        }
        var headers = this.datasetACS.getContextualDomainHeaders();
        this.datasetService.post(route, formData, { headers: headers })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.clearForm();
                }
                if (!_this.datasetRS.record.value) {
                    _this.datasetNavigatorService.router.navigate(['dataset/edit/' + _this.datasetACS.datasetCode, response.id], { queryParams: { step: 1 } });
                }
                else {
                    _this.datasetRS.recordId = response.id;
                    _this.datasetRS.reload()
                        .subscribe(function (value) {
                        if (value) {
                            setTimeout(function () {
                                _this.goNextStep();
                            }, 200);
                        }
                        else
                            _this.datasetNavigatorService.onEdit(_this.datasetACS, response);
                    });
                }
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            }
        });
    };
    return CommonFormStepComponent;
}(BaseStepViewComponent));
export { CommonFormStepComponent };
