import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FormInputViewerComponent } from 'app/main/components/form-input-viewer/form-input-viewer.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { AppService } from 'app/services/app.service';
import { map, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import FilterFields from './filter.fields';
import formConfig from './form.fields';
import saveAs from 'file-saver';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import fileterFields from "./filter.fields";
import * as _ from 'lodash';

@Component({
	selector: 'bi-statistics',
	templateUrl: './bi-statistics.component.html',
	styleUrls: ['./bi-statistics.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	providers: [ PageClosureContainerService, DatasetActionContainerService ]
})
export class BIStatisticsPageComponent implements OnInit, OnDestroy{

	selectedGroups: any[] = [];
	enabledFilters: any[] = [];
	selectionsLabel: any = {};
	expandedRecordGroups: any = {};
	report: any = {};
	cachedData: any = {};

	fileterFields = FilterFields;
	filterFormGroup: FormGroup;
	filtersData: any;
	numFilters: number;
	viewCostStatus= false;

	_loading: BehaviorSubject<boolean>;
	_loadingNext: BehaviorSubject<boolean>;
	_unsubscribeAll: Subject<any>;
	_page: number = 1;
	_perPage: number = 30;
	_pageIndex: number = 0;
	_totals: any = {};
	_showCostSwitch = false;

	@ViewChild('to_date', { static: false }) to_date: FormInputViewerComponent;

	/**
	 * Constructor
	 *
	*/
	constructor(
		public appService: AppService,
		private http: HttpClient,
		private formBuilder: FormBuilder,
		private matDialog: MatDialog,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		public pageContainerService: PageClosureContainerService
	) {
		// init form group
		this.filterFormGroup = this.formBuilder.group({
			from_date: moment(),
			to_date: moment().add(1, 'M'),
			from_date_comparison: null,
			to_date_comparison: null,
			filter_type: new FormControl('booking', Validators.required),
			comparison_type: new FormControl('year', Validators.required),
			sort_type: new FormControl('total_amount_desc', Validators.required),
			tipo_fatturato: new FormControl('fatturato_netto', Validators.required),
			trip_package_id: null,
			actual_trip_id: null,
			trip_category_id: null,
			trip_resource_id: null,
			entity_customer_id: null,
			entity_id: null,
			user_id: null,
			coupon_code: null,
			origin_channel: null,
			status: null,
			branch_code: null,
			groups: null,
			delivery_status: null,
			trip_booking_package_target: null
		});

		this._loading = new BehaviorSubject<boolean>(false);
		this._loadingNext = new BehaviorSubject<boolean>(false);
		this._unsubscribeAll = new Subject<any>();
	}

	get min(): Date{
		return <Date>this.filterFormGroup.get('from_date').value;
	}

	get max(): Date{
		/**
		 * if end of year can go to next year
		 */
		const minDate = moment(this.min);
		const month = minDate.month();
		let year = minDate.year();
		if (month >= 11) year += 1;
		return new Date(year, 11, 31, 23, 59, 59);
	}

	get minComparison(): Date{
		return <Date>this.filterFormGroup.get('from_date_comparison').value;
	}

	get maxComparison(): Date{
		/**
		 * if end of year can go to next year
		 */
		const minDate = moment(this.minComparison);
		const month = minDate.month();
		let year = minDate.year();
		if (month >= 11) year += 1;
		return new Date(moment(this.minComparison).year(), 11, 31, 23, 59, 59);
	}

	get isCustomComparison(): boolean{
		return (this.filterFormGroup.get('comparison_type').value == 'custom');
	}

	get fromDate(): string{
		return moment(this.filterFormGroup.get('from_date').value).format('DD MMMM YYYY');
	}

	get toDate(): string{
		return moment(this.filterFormGroup.get('to_date').value).format('DD MMMM YYYY');
	}

	get fromDateComparison(): string{
		const comparison_type = this.filterFormGroup.get('comparison_type').value;
		const date = moment(this.filterFormGroup.get('from_date_comparison').value);
		const from = moment(this.filterFormGroup.get('from_date').value);
		const to = moment(this.filterFormGroup.get('to_date').value);
		const days = to.diff(from, 'days', true);
		// get date
		switch(comparison_type){
			case 'custom':
				return date.format('DD MMMM YYYY');
			case 'period':
				return from.subtract(days + 1, 'days').format('DD MMMM YYYY');
			case 'year':
				return from.subtract(1, 'year').format('DD MMMM YYYY');
		}
	}

	get toDateComparison(): string{
		const comparison_type = this.filterFormGroup.get('comparison_type').value;
		const date = moment(this.filterFormGroup.get('to_date_comparison').value);
		const from = moment(this.filterFormGroup.get('from_date').value);
		const to = moment(this.filterFormGroup.get('to_date').value);
		const days = to.diff(from, 'days', true);
		// get date
		switch(comparison_type){
			case 'custom':
				return date.format('DD MMMM YYYY');
			case 'period':
				return to.subtract(days + 1, 'days').format('DD MMMM YYYY');
			case 'year':
				return to.subtract(1, 'year').format('DD MMMM YYYY');
		}
	}

	get participantPercentage(): any{
		if (!this._totals['comparison_range']['total_participants']) return;
		const defaultRangeParticipants = <number>this._totals['default_range']['total_participants'];
		let total_participants = 0;
		if (defaultRangeParticipants > 0) total_participants = defaultRangeParticipants;
		const num_participants_com = <number>this._totals['comparison_range']['total_participants'];
		const diff = total_participants - num_participants_com;
		const division = diff / num_participants_com;
		const result = division * 100;
		return result;
	}

	get totalAmountPercentage(): any{
		if (!this._totals['comparison_range']['total_amount']) return;
		const defaultRangeAmount = this._totals['default_range']['total_amount'];
		let total_amount;
		if (!defaultRangeAmount){
			total_amount = 0;
		} else total_amount = parseInt(defaultRangeAmount);
		const total_amount_com = parseInt(this._totals['comparison_range']['total_amount']);
		const diff = total_amount - total_amount_com;
		const division = diff / total_amount_com;
		const result = division * 100;
		return result;
	}
	get totalCostPercentage(): any{
		if (!this._totals['comparison_range']['total_supplier_price']) return;
		const defaultRangeCost = this._totals['default_range']['total_supplier_price'];
		let total_supplier_price;
		if (!defaultRangeCost){
			total_supplier_price = 0;
		} else total_supplier_price = parseInt(defaultRangeCost);
		const total_supplier_price_com = parseInt(this._totals['comparison_range']['total_supplier_price']);
		const diff = total_supplier_price - total_supplier_price_com;
		const division = diff / total_supplier_price_com;
		const result = division * 100;
		return result;
	}
	
	get totalMarginPercentage(): any{
		if (!this._totals['comparison_range']['total_profit']) return;
		const defaultRangeMargin = this._totals['default_range']['total_profit'];
		let total_profit;
		if (!defaultRangeMargin){
			total_profit = 0;
		} else total_profit = parseInt(defaultRangeMargin);
		const total_profit_com = parseInt(this._totals['comparison_range']['total_profit']);
		const diff = total_profit - total_profit_com;
		const division = diff / total_profit_com;
		const result = division * 100;
		return result;
	}
	

	onChangeDateFrom(date: any, inputName: string): void{
		/*const to_date = date.value.add(1, 'd');
		this.filterFormGroup.get(inputName).setValue(to_date);*/
	}

	openFilters(): void {
		this.matDialog.open(FormDialogComponent, {
			width: '700px',
			data: {
				title: 'Filtri ricerca',
				formData: this.filtersData,
				dataContainerService: this.datasetACS,
				formConfig: formConfig,
				positiveText: 'Applica'
			}
		})
		.afterClosed()
		.subscribe(formData => {
			if(formData){
				const status = formData['status'] || [];
				if (status.length > 0){
					formData['status'] = status.map(s => s.trim());
				}
			}
			this.setFiltersData(formData);
			this.setEnabledFilters();
			this.countFilters();
		});
	}

	setFiltersData(formData: any): void {
		if (!formData) return;
		this.filtersData = {
			trip_package_id: formData.trip_package_id || '',
			start_station_id: formData.start_station_id || '',
			actual_trip_id: formData.actual_trip_id || '',
			trip_category_id: formData.trip_category_id || '',
			trip_resource_id: formData.trip_resource_id || '',
			entity_customer_id: formData.entity_customer_id || '',
			entity_id: formData.entity_id || '',
			user_id: formData.user_id || '',
			coupon_code: formData.coupon_code || '',
			origin_channel: formData.origin_channel || '',
			status: formData.status || '',
			branch_code: formData.branch_code || '',
			delivery_status: formData.delivery_status,
			trip_booking_package_target: formData.trip_booking_package_target
		};
	}

	setEnabledFilters(){
		if (!this.filtersData) return;
		// set enabled filters
		this.enabledFilters = [];
		for (const key of Object.keys(this.filtersData)){
			if (!this.filtersData[key]) continue;
			if (Array.isArray(this.filtersData[key]) && this.filtersData[key].length == 0) continue;
			// set label and value
			this.enabledFilters.push({
				label: this.selectionsLabel['selected_label_' + key],
				value: key
			});
		}
	}

	removeFilter(filterKey: string){
		delete this.filtersData[filterKey];
		this.setEnabledFilters();
		this.countFilters();
	}

	countFilters(): void{
		if (!this.filtersData) return;
		let increment = 0;
		const keysFilters = Object.keys(this.filtersData);
		for (const key of keysFilters){
			const value = this.filtersData[key];
			if (Array.isArray(value) && value.length > 0){
				increment++;
			}else if (!Array.isArray(value) && value){
				increment++;
			}
		}
		this.numFilters = increment;
	}

	onPageChanged(event: any): void{
		this._page = event.pageIndex + 1;
		this._perPage = event.pageSize;
		this._pageIndex = event.pageIndex;
		this.loadFirstGroup();
	}

	generaFile(typeFile: string): void{
		const params: any = this.getCallParams();
		this._loading.next(true);
		this.http.post(this.appService.getBaseServerAddress() + '/bi-statistics/export/' + typeFile, params, {
			responseType: 'blob',
			observe: 'response'
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: HttpResponse<Blob>) => {
				const filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
				saveAs(response.body, filename.replace(/\"/g, ''));
				this._loading.next(false);
			},
			error: (error) => {
				this.appService.showErrorMessage(error.message);
				this._loading.next(false);
			}
		});
	}

	parseJSON(value: string): string{
		return JSON.parse(value);
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.selectedGroups, event.previousIndex, event.currentIndex);
	}

	remove(group: string){
		this.selectedGroups = this.selectedGroups.filter(g => g != group);
	}

	loadFirstGroup(): void{
		this.cachedData = {};
		this.report = {};
		this.expandedRecordGroups = {};
		const params = this.getCallParams();
		if (this.selectedGroups[0]) params['group'] = this.selectedGroups[0].value;
		// start page loader
		this._loading.next(true);
		// make group http call
		this.loadGroup(params)
		.pipe(
			map((group) => {
				this._totals = {
					['default_range']: {
						total_amount: group['dataset']['totals']['total_amount'],
						total_participants: group['dataset']['totals']['total_participants'],
						total_supplier_price: this.viewCostStatus?group['dataset']['totals']['total_supplier_price']:0,
						total_profit: this.viewCostStatus?group['dataset']['totals']['total_profit']:0,
						total_profit_percent: this.viewCostStatus?group['dataset']['totals']['total_profit_percent']:0,
					},
					['comparison_range']: {
						total_amount: group['dataset']['totals']['total_amount_comparison'] || 0,
						total_participants: group['dataset']['totals']['total_participants_comparison'] || 0,
						total_supplier_price: this.viewCostStatus?group['dataset']['totals']['total_supplier_price_comparison']:0,
						total_profit: this.viewCostStatus?group['dataset']['totals']['total_profit_comparison']:0,
						total_profit_percent: this.viewCostStatus?group['dataset']['totals']['total_profit_percent_comparison']:0,
					}
				};
				console.log(this._totals)
				return group;
			}),
			takeUntil(this._unsubscribeAll)
		)
		.subscribe({
			next: (resp) => {
				const group = params['group'];
				this.report[group] = resp;
				this._loading.next(false);
				this._loading.complete();
			},
			error: (err) => {
				this.appService.showErrorMessage('Si è verificato un errore inaspettato.');
				this._loading.next(false);
				this._loading.complete();
			}
		});
	}

	loadGroup(data: any): Observable<any>{
		return this.http.post(this.appService.getBaseServerAddress() + '/bi-statistics/' + data.group, data);
	}

	getCallParams(): any{
		return {
			page: this._page,
			perPage: this._perPage,
			from_date: this.filterFormGroup.get('from_date').value,
			to_date: this.filterFormGroup.get('to_date').value,
			from_date_comparison: this.filterFormGroup.get('from_date_comparison').value,
			to_date_comparison: this.filterFormGroup.get('to_date_comparison').value,
			comparison_type: this.filterFormGroup.get('comparison_type').value,
			filter_type: this.filterFormGroup.get('filter_type').value,
			tipo_fatturato: this.filterFormGroup.get('tipo_fatturato').value,
			sort_type: this.filterFormGroup.get('sort_type').value,
			groups: this.selectedGroups.map(g => g && g.value),
			view_cost: this.viewCostStatus,
			filters: this.filtersData
		};
	}

	getFilterParams(item: any, group: any, loadFromCache?: boolean): any{
		let recordId = '';
		if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']){
			recordId = JSON.parse(item['extra'])[0]['f3'];
		} else recordId = item['record_id'];
		let data = {[group]: recordId};
		const currentG = this.report[group] || null;
		if (loadFromCache) currentG['filter_by'] = data;
		if (currentG){
			data = Object.assign(currentG['filter_by'] || {}, data);
		}

		return data;
	}

	expand(i: number, item: any, nextGroup: string, currentGroup?: string, prevGroup?: string, isMasterGroup?: boolean): void{
		let recordId = '';
		if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']){
			recordId = JSON.parse(item['extra'])[0]['f3'];
		} else recordId = item['record_id'];

		if (this.expandedRecordGroups[currentGroup] && this.expandedRecordGroups[currentGroup][i] && this.expandedRecordGroups[currentGroup][i][recordId]){
			delete this.expandedRecordGroups[currentGroup][i][recordId];
			return;
		} else if (isMasterGroup) this.closePanel();

		if (this.cachedData[currentGroup] && this.cachedData[currentGroup][recordId]){
			const cache = this.cachedData[currentGroup][recordId];
			const group = cache['currentGroup'];
			this.report[group]['dataset'] = cache['dataset'];
			this.report[group]['filter_by'] = cache['filter_by'];
			this.report[group]['currentGroup'] = group;
			this.report[group]['prevGroup'] = cache['prevGroup'];
			this.report[group]['nextGroup'] = cache['nextGroup'];
			this.epxandPanel(i, item, cache['prevGroup'], isMasterGroup);
			return;
		}
		this._loadingNext.next(true);
		const params = this.getCallParams();
		params['group'] = nextGroup;
		params['filter_by'] = this.getFilterParams(item, currentGroup);
		params['arrayKey'] = recordId;
		// make group call
		this.loadGroup(params)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (resp) => {
				const group = params['group'];
				if (this.report[group]){
					const newDataset = Object.assign(this.report[group]['dataset'], resp['dataset']);
					this.report[group]['dataset'] = newDataset;
					this.report[group]['filter_by'] = resp['filter_by'];
					this.report[group]['currentGroup'] = resp['currentGroup'];
					this.report[group]['prevGroup'] = resp['prevGroup'];
					this.report[group]['nextGroup'] = resp['nextGroup'];
				} else this.report[group] = resp;

				this.epxandPanel(i, item, currentGroup, isMasterGroup);
				if (isMasterGroup){
					if (!this.cachedData[currentGroup]) this.cachedData[currentGroup] = {};
					if (!this.cachedData[currentGroup][recordId]) this.cachedData[currentGroup][recordId] = {};
					this.cachedData[currentGroup][recordId]['dataset'] = this.report[group]['dataset'];
					this.cachedData[currentGroup][recordId]['filter_by'] = resp['filter_by'];
					this.cachedData[currentGroup][recordId]['currentGroup'] = resp['currentGroup'];
					this.cachedData[currentGroup][recordId]['prevGroup'] = resp['prevGroup'];
					this.cachedData[currentGroup][recordId]['nextGroup'] = resp['nextGroup'];
				}
				// loading next
				this._loadingNext.next(false);
			},
			error: (err) => {
				console.log(err);
				this._loadingNext.next(false);
			}
		});
	}

	epxandPanel(i: number, item: any, currentGroup: any, isMasterGroup?: boolean){
		let recordId = '';
		if (item['extra'] && item['extra'][0] && item['extra'][0]['f3']){
			recordId = JSON.parse(item['extra'])[0]['f3'];
		} else recordId = item['record_id'];
		if (isMasterGroup) this.expandedRecordGroups = {};
		if (!this.expandedRecordGroups[currentGroup]) this.expandedRecordGroups[currentGroup] = {};
		if (!this.expandedRecordGroups[currentGroup][i]){
			this.expandedRecordGroups[currentGroup][i] = {};
		}
		this.expandedRecordGroups[currentGroup][i][recordId] = recordId;
	}

	closePanel(){
		this.expandedRecordGroups = {};
		return;
	}
	
	viewCost():void{
		if(!this.viewCostStatus) {
			this.selectedGroups = [{label: 'Pacchetto', value: 'pacchetto'}, {
				label: 'Data di partenza',
				value: 'data di partenza'
			}];
			this.filterFormGroup.get('groups').disable()
		}else{
			this.filterFormGroup.get('groups').enable()
			this.selectedGroups = [];
		}
		this.report = [];
		this.viewCostStatus=!this.viewCostStatus;
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.datasetService.fetchSourceData({
			ajaxDatasetCode: '',
			defaultActions: {},
			enabledActions: {
				create: false,
				detail: false,
				edit: false,
				list: false,
			},
			name: '',
			title: ''
		}, [
			{
				name: 'domainModules',
				path: '/dataset/domains/command/config',
				params: {
					key: 'modules'
				}
			}
		], null, null)
		.subscribe({
			next: result => {
				console.log(result);
				if(_.get(result, 'domainModules.bi_statistics.cost_enabled') == true) this._showCostSwitch = true
				this.pageContainerService.putToSharedData(result);
			},
			error: () => {
			}
		});
		


		this.filterFormGroup.get('groups').valueChanges
		.subscribe({
			next: (group) => {
				if (!group || this.selectedGroups.includes(group)) return;
				this.selectedGroups.push(group);
				this.filterFormGroup.get('groups').patchValue(null);
			},
			error: (err) => {
				console.error(err);
			}
		});

		/**
		 * on app event
		 */
		const label = {};
		this.appService.eventEmitter
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((event) => {
			label[event.name] = event.target
		});

		this.selectionsLabel = label;
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}

