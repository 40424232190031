/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./marketplace.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../components/dataset/base-actions/list/dataset-list.component.ngfactory";
import * as i8 from "../../components/dataset/services/dataset-action-container.service";
import * as i9 from "../../../services/app.service";
import * as i10 from "../page-closure-container.service";
import * as i11 from "../../../services/auth.service";
import * as i12 from "../../components/dataset/services/dataset.service";
import * as i13 from "../../../services/domain-filter.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../components/dataset/base-actions/list/dataset-list.service";
import * as i16 from "../../components/dataset/base-actions/list/dataset-list.component";
import * as i17 from "../../components/dataset/services/dataset-navigator.service";
import * as i18 from "../../../../@fuse/services/translation-loader.service";
import * as i19 from "@angular/material/snack-bar";
import * as i20 from "../../../services/websocket.service";
import * as i21 from "./marketplace.component";
var styles_BokunMarketplacePageComponent = [i0.styles];
var RenderType_BokunMarketplacePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BokunMarketplacePageComponent, data: {} });
export { RenderType_BokunMarketplacePageComponent as RenderType_BokunMarketplacePageComponent };
function View_BokunMarketplacePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_BokunMarketplacePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "message-settings-api"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Credenziali Bokun"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Per rivendere i tour presenti su Bokun \u00E8 necessario un account vendor con accesso al marketplace."])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Configura le tue credenziali "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(9, { step: 0 }), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["qui"])), (_l()(), i1.ɵted(-1, null, ["."]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 9, 0, 4); var currVal_3 = _ck(_v, 10, 0, ("/dataset/detail/domains/" + _co.domainFilterService.filterDomainId.value)); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
function View_BokunMarketplacePageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dataset-list", [["datasetCode", "bokun_tours"], ["tableStyle", "style-1 selectable btn-outlined"]], null, null, null, i7.View_DatasetListComponent_0, i7.RenderType_DatasetListComponent)), i1.ɵprd(512, null, i8.DatasetActionContainerService, i8.DatasetActionContainerService, [i9.AppService, i10.PageClosureContainerService, i11.AuthService, i12.DatasetService, i13.DomainFilterService, i14.TranslateService, i1.Injector]), i1.ɵprd(512, null, "ListParamService", i15.DatasetListService, [i8.DatasetActionContainerService, i12.DatasetService]), i1.ɵdid(4, 770048, null, 0, i16.DatasetListComponent, [i5.ActivatedRoute, i12.DatasetService, i5.Router, i11.AuthService, i9.AppService, i8.DatasetActionContainerService, i17.DatasetNavigatorService, i13.DomainFilterService, "ListParamService", i18.FuseTranslationLoaderService, i19.MatSnackBar, i20.WebSocketService], { datasetCode: [0, "datasetCode"], tableStyle: [1, "tableStyle"] }, null)], function (_ck, _v) { var currVal_0 = "bokun_tours"; var currVal_1 = "style-1 selectable btn-outlined"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_BokunMarketplacePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page-layout p-24 blank marketplace-tour-operators"], ["style", "padding-bottom: 80px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BokunMarketplacePageComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BokunMarketplacePageComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BokunMarketplacePageComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.domainHasBokunAccount && !_co.loading); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.domainHasBokunAccount && !_co.loading); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_BokunMarketplacePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "bokun-marketplace-page", [], null, null, null, View_BokunMarketplacePageComponent_0, RenderType_BokunMarketplacePageComponent)), i1.ɵprd(512, null, i10.PageClosureContainerService, i10.PageClosureContainerService, [i9.AppService]), i1.ɵdid(2, 114688, null, 0, i21.BokunMarketplacePageComponent, [i9.AppService, i13.DomainFilterService, i17.DatasetNavigatorService, i10.PageClosureContainerService, i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BokunMarketplacePageComponentNgFactory = i1.ɵccf("bokun-marketplace-page", i21.BokunMarketplacePageComponent, View_BokunMarketplacePageComponent_Host_0, {}, {}, []);
export { BokunMarketplacePageComponentNgFactory as BokunMarketplacePageComponentNgFactory };
