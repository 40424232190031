import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import filterForm from './forms/filter.form';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/plafond/detail/:recordId',
};

export const PlafondConfig: IDatasetConfig = {
	name: 'plafond',
	title: 'Plafonds',
	singleTitle: 'Plafond',
	ajaxDatasetCode: 'plafond',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any{
				if (dataContainerService.datasetData['domainPackageId']){
					params['domainPackageId'] = dataContainerService.datasetData['domainPackageId'];
				}
				return params;
			},
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code'
					},
					{
						title: 'Nome',
						key: 'description'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
