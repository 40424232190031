import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import {fieldsMap} from './../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			fieldsMap.get('booking_date_from'),
			fieldsMap.get('booking_date_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			fieldsMap.get('start_day_from'),
			fieldsMap.get('start_day_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				name: '(Trenitalia) Codice Recupero',
				key: 'trenitalia_travel_id',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.TEXT,
				},
				showIf(record: any, dataContainerService?: IDataContainerService) {
					if(!dataContainerService) return false;
					return dataContainerService.getDomainSetting('enabled_modules.trenitalia.enabled') == true;
				}
			},
			{
				name: '(Trenitalia) Codice Prenotazione',
				key: 'trenitalia_pnr_code',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.TEXT,
				},
				showIf(record: any, dataContainerService?: IDataContainerService) {
					if(!dataContainerService) return false;
					return dataContainerService.getDomainSetting('enabled_modules.trenitalia.enabled') == true;
				}
			}
		]
	},
	{
		config: {
			flexLayout: 'row',
		},
		fields: [
			{
				key: 'booking_type',
				name: 'Tipo di prenotazione',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.DATASET_SELECT,
					clearable: true
				},
				datasetCode: 'select_options',
				remoteOptions: {
					supportCreate: false,
					limit: 10,
					getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
						return {
							options_code: 'booking_type'
						};	
					},
					getLabel(option): string{
						if(!option) return '';
						return option.label;
					},
					getValue(option): string{
						if(!option) return '';
						return option.value;
					},
					getDisplay(option): any{
						if(!option) return '';
						return option.label;
					}
				}
				
			}
		],
	},
	fieldsMap.get('trip_package_id'),
	fieldsMap.get('trip_resource_id'),
	fieldsMap.get('filter_trip_category_id'),
	fieldsMap.get('booking_code'),
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			{
				key: 'origin_channel_voucher_code',
				name: 'Codice Voucher Operatore',
				valueType: ValueTypes.STRING,
				inputType: InputTypes.TEXT
			},
			{
				key: 'origin_channel_booking_id',
				name: 'ID Ordine Esterno',
				valueType: ValueTypes.STRING,
				inputType: InputTypes.TEXT
			},
		]
	},
	fieldsMap.get('entity_customer_id'),
	fieldsMap.get('entity_id'),
	fieldsMap.get('user_id'),
	fieldsMap.get('discount_id'),
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			{
				name: 'Origine',
				key: 'origin_channel',
				valueType: ValueTypes.STRING,
				inputType: InputTypes.SELECT,
				options: [
					{
						label: 'Tutti',
						value: ''
					},
					{
						label: 'Back Office',
						value: 'backoffice'
					},
					{
						label: 'Direct Online',
						value: 'direct_online'
					},
					{
						label: 'Direct Offline',
						value: 'direct_offline'
					},
					{
						label: 'API',
						value: 'api'
					},
					{
						label: 'Mobile',
						value: 'ticketing_mobile'
					},
					{
						label: 'Totem',
						value: 'ticketing_totem'
					}
				]
			},
			{
				name: 'Mostra scadute (solo API)',
				key: 'show_api_expired',
				valueType: ValueTypes.NUMBER,
				inputType: InputTypes.SELECT,
				defaultValue(): any{ return 0; },
				options: [
					{
						label: 'Si',
						value: 1
					},
					{
						label: 'No',
						value: 0
					},
				]
			}
		],
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				name: 'Stato',
				key: 'status',
				valueType: ValueTypes.STRING,
				inputType: InputTypes.SELECT,
				options: [
					{
						label: 'Tutti',
						value: ''
					},
					{
						label: 'Confermato',
						value: 'confirmed'
					},
					{
						label: 'Cancellato',
						value: 'canceled'
					},
					{
						label: 'Scaduto',
						value: 'expired'
					},
					{
						label: 'Preventivo',
						value: 'draft'
					},
					{
						label: 'Opzionale',
						value: 'optional',
					},
				]
			},
			fieldsMap.get('branch_code'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			{
				name: 'Stato consegna milano card',
				key: 'delivery_status_mc',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.SELECT,
					clearable: true
				},
				options: [
					{
						label: 'Standby (milano card)',
						value: 'standby',
					},
					{
						label: 'In consegna (milano card)',
						value: 'in consegna',
					},
					{
						label: 'Consegnato (milano card)',
						value: 'consegnato',
					},
					{
						label: 'Scaduto (milano card)',
						value: 'scaduto',
					},
				],
				showIf(record: any, dataContainerService?: IDataContainerService) {
					if(!dataContainerService) return false;
					let modules = dataContainerService.getValueFromKeyPath('domainModules');
					if(!modules){
						modules = dataContainerService.getValueFromKeyPath(dataContainerService.datasetCode + '.domainModules');
					}
					if(modules && modules.ticketing ){
						return modules.ticketing.enabled;
					}
					return false;
				}
			},
			fieldsMap.get('meeting_points_id')
		]
	},
	{
		config: {
			flexLayout: 'row'
		},	
		fields: [
			{
				name: 'Tipo di attivazione milano card',
				key: 'booking_package_target',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.SELECT,
					clearable: true
				},
				options: [
					{
						label: 'Attivazione Online (milano card)',
						value: 'online',
					},
					{
						label: 'Attivazione Offline (milano card)',
						value: 'offline',
					},
				],
				showIf(record: any, dataContainerService?: IDataContainerService) {
					if(!dataContainerService) return false;
					let modules = dataContainerService.getValueFromKeyPath('domainModules');
					if(!modules){
						modules = dataContainerService.getValueFromKeyPath(dataContainerService.datasetCode + '.domainModules');
					}
					if(modules && modules.ticketing ){
						return modules.ticketing.enabled;
					}
					return false;
				}
			},
			{
				key: 'delivery_code',
				name: 'Codice Prenotazione (milano card)',
				valueType: ValueTypes.STRING,
				inputType: InputTypes.TEXT,
				showIf(record: any, dataContainerService?: IDataContainerService) {
					if(!dataContainerService) return false;
					let modules = dataContainerService.getValueFromKeyPath('domainModules');
					if(!modules){
						modules = dataContainerService.getValueFromKeyPath(dataContainerService.datasetCode + '.domainModules');
					}
					if(modules && modules.ticketing ){
						return modules.ticketing.enabled;
					}
					return false;
				}
			},
		]
	}
];

const filterFormConfig: IFormConfig = {
	fields
};

export default filterFormConfig;
