import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IFormConfig, IStepConfig } from 'app/interfaces';

@Component({
	selector   : 'common-i18n-form-step',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ DatasetActionContainerService, DatasetRecordService, DatasetMultilanguageContainerService ]
})
export class CommonI18nFormStepComponent extends BaseStepViewComponent implements OnInit{
	
	@Input() isLastStep = true;
	@Input() stepConfig: IStepConfig;
	@Input() formConfig: IFormConfig;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;
	formErrors: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.datasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentValueKey
			});
			this.datasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		this.datasetMCS.save(formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				if(this.formViewer){
					this.formViewer.formGroup.enable();
				}
				// for reaload active language when a new one is created
				if(!this.datasetRS.recordId){
					this.datasetACS.parentDatasetRS.reload();
					this.datasetMCS.fetchAll();
				}

				if(this.hasNext){
					setTimeout(() => {
						this.goNextStep();
					}, 200);
				}else if(response.id){
					this.datasetRS.recordId = response.id;
					this.datasetRS.reload();
				}
				
			},
			error: response => {
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}
			}
		});
	}
}
