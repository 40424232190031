import { OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';


export class DestroyableComponent implements OnDestroy{

	protected _unsubscribeAll = new Subject();

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
