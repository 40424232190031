import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {AppService} from "../../../../../services/app.service";
import {DatasetRecordService} from "../../../../components/dataset/services/dataset-record.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'product-package',
  templateUrl: './product-package.component.html',
  styleUrls: ['./product-package.component.scss']
})
export class ProductPackageComponent implements OnInit,OnDestroy{
    @Input() inputTripBookingPackage: any;
    @Input() mix: boolean;
    protected _unsubscribeAll: Subject<any>;
    public totalRowLordo: number;
    public productType:string;
  constructor(public appService: AppService,
              public datasetRS: DatasetRecordService) {
      console.log(datasetRS)
  }

  ngOnInit() {
      switch (this.inputTripBookingPackage.product.product_type){
          case 'standard':this.productType = 'Standard';break;
          case 'travelmate':this.productType = 'Audioguida';break;
          case 'esim-manet':this.productType = 'ESim';break;
      }
      this._unsubscribeAll = new Subject();
      console.log(this.inputTripBookingPackage)
      this.datasetRS.record
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(record => {
              let totalLordo = Number(this.inputTripBookingPackage.total_amount);
              if(this.inputTripBookingPackage.children_trip_booking_packages){
                  for (const tbp of this.inputTripBookingPackage.children_trip_booking_packages) {
                      totalLordo += Number(tbp.total_amount);
                  }
              }
              this.totalRowLordo = totalLordo;
          });

  }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}


