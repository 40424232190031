import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, throwError, Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { SelectDomainDialogComponent } from 'app/main/components/dataset/dialogs/select-domain-dialog/dialog.component';

@Injectable()
export class DomainResolver implements Resolve<any>{
	constructor(
		private domainFilterService: DomainFilterService,
		private appService: AppService	){}

	resolve(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
		const observer = new Subject<any>();

		if(this.domainFilterService.filterDomainId.value){
			setTimeout(() => {
				observer.next(this.domainFilterService.filterDomainId.value);
				observer.complete();
			}, 50);
		}else{
			this.domainFilterService.wrapInDomain(SelectDomainDialogComponent, (domainId) => {
				if(!domainId) observer.error('no_domain');
				else observer.next(domainId);
				observer.complete();
			}, null, true);
		}

		return observer;
	}

	onError(err): Observable<any>{
		console.warn('error while resolve:', err);
		this.appService.showErrorMessage('Non è stato selezionato nessun dominio');
		//this.router.navigate(['/home']);
		return throwError(err);
	}
}
