import { Component, OnInit, ViewChild } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { FormViewerService } from "app/services/form-viewer.service";
import { fieldsMap as filterFields } from './filter.fields';
import { DatasetListComponent } from "app/main/components/dataset/base-actions/list/dataset-list.component";
import { convertToUTCDay } from "app/helpers/date.helper";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { FieldViews, IListViewConfig, ValueTypes } from "app/interfaces";
import { TripBookingPackagesStepService } from "../../trip-booking-packages-step.service";
import { ActionDialogComponent, IActionDialogData } from "../../../action-dialog/action-dialog.component";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { config as productSelectionViewConfig } from 'app/configs/datasets/products/selectionViewConfig';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../../edit-booking.component";
import { AppService } from "app/services/app.service";
import { TranslateService } from "@ngx-translate/core";
import {IDataContainerService} from "../../../../../../interfaces/data-container.interface";
import {
	showFieldBasedOnConfig
} from "../../../../../../configs/commons/shared-field-configs/show-field-on-config.field";

@Component({
	selector   : 'product-selection',
	templateUrl: './product-selection.component.html',
	styleUrls: ['./product-selection.component.scss'],
	providers: [FormViewerService]
})
export class ProductSelectionComponent extends DestroyableComponent implements OnInit{

	public filterFields = filterFields;
	public filters: any = {
	
	};

	get productSelectionViewConfig(): IListViewConfig{
		return Object.assign({}, productSelectionViewConfig.viewConfig, {
			showSearchBar: true,
			columns: [
				{
					title: 'Tipo',
					key: 'product_type',
					fieldDefinition: {
						key: 'product_type',
						name: 'Tipo',
						valueType: ValueTypes.STRING
					},
					fieldView: FieldViews.HTML,
					getDisplayHTML(record, value){
						if(!value || value == 'standard') return 'Standard';
						if(value == 'travelmate') return 'Audioguida';
						if(value == 'esim-manet') return 'ESim';
						return value;
					}
				},
				{
					title: 'Prodotto',
					key: 'description'
				},
				{
					title: 'Stato',
					key: 'code',
					fieldView: FieldViews.HTML,
					getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
						let colorClass = 'orange-bg';
						let text = '<span class="status">' +  translateService.instant('Non Pubblico') + '</span>';
						if(record.public_product_on_web === 1){
							colorClass = 'green-bg';
							text = '<span class="status">' + translateService.instant('Pubblicato') + '</span>';
						}
	
						let html = '<div class="package_status" style="display:flex;align-items: center; justify-content: space-between;">';
						html += '<div>'+text+'<br\><span>'+record.code+'</span></div>';
						html += '<span class="' + colorClass + ' record-status-bar" style="width:12px; height:12px; border-radius:50%;"></span>';
						html += '</div>';
						return html;
					},
					showIf(dataContainerService: IDataContainerService):any{
						return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_columns', 'status', true);
					}
				},
				{
					title: 'Prezzo',
					key: 'price',
					fieldDefinition: {
						key: 'price',
						name: 'Prezzo',
						valueType: ValueTypes.STRING
					},
					fieldView: FieldViews.HTML,
					getDisplayHTML(record, value){
						if(record.properties.price.value) return '€ '+record.properties.price.value;
						return null
					},
					showIf(dataContainerService: IDataContainerService):any{
						return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'products.list_columns', 'price', false);
					}
				},
				
			],
		});
	}
	
	@ViewChild('datasetList', {static: true}) listComponent: DatasetListComponent;

	constructor(
		public formViewerService: FormViewerService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		public datasetNavigator: DatasetNavigatorService,
		private appService: AppService
	){
		super();
	}

	ngOnInit(){
		this.formViewerService.init({
			fields: Array.from(filterFields.values())
		});

		this.formViewerService.formGroup.valueChanges
		.subscribe(filterFields => {
			this.filters = Object.assign({}, this.filters, {
			
			});
		});
	}

	addTripBookingPackage(product: any): void{
		this.tripBookingPackagesStepService
		.requestBookingData(product, 'product')
		.subscribe(postData => {
			if(!postData) return;
			const dialogRef = this.datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
				title: 'Attendere',
				contentMessage: ''
			});
			
			let actionEndPoint = '/dataset/trip_booking_packages/create';
			this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
			.subscribe({
				complete: () => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGES
					});
					dialogRef.close();
				},
				error: err => {
					dialogRef.componentInstance.resultStatus = 'error';
					dialogRef.componentInstance.loading = false;
					if(err && err.error && err.error.message) dialogRef.componentInstance.setMessage('Si è verificato un errore<br> '+err.error.message);
					else dialogRef.componentInstance.setMessage('Si è verificato un errore');
					dialogRef.componentInstance.setTitle(null);
				}
			});
		});
	}
}
