import {FieldViews, IFieldContainer, IFieldDefinition, IFilterFieldDefinition, IFormConfig} from './field.interface';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { SmartInterfaceStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/smart-interface-view-container/view.component';
import { DatasetCalendarViewComponent } from 'app/main/components/dataset/base-actions/list/views/calendar/calendar.component';
import { CalendarEventModel } from 'app/main/pages/calendar/event.model';
import { CalendarMonthViewDay } from 'angular-calendar';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IPaginationResponse, ISocketEvent } from '.';
import { ViewContainerRef } from '@angular/core';

export interface IDatasetCallDefinition{
	name: string;
	path: string;
	method?: string;
	params?: any;
	routeParamMap?: [
		{
			routeParam: string,
			callParam: string
		}
	];
}

export enum DatasetActionOpenType{
	PAGE,
	DIALOG
}

export enum ListViewItemTypes{
	CUSTOM,
	TABLE,
	DEBUG,
	CALENDAR,
}

export enum ListViewExportTypes {
	XLS = 'xlsx',
	CSV = 'csv',
	CSV_VIA_EMAIL = 'csv_via_email'
}

export enum TangerineBgColors {
	TANGERINE_DARK = 'tangerine_dark',
	//TANGERINE_LIGHT = 'tangerine_light' TODO: da implementare
}

export interface IActionButton{
	actionCode?: string;
	buttonText?: string;
	buttonIcon?: string;
	buttonColor: string;
	routerLink?: string;
	tooltip?: string;
	destinationLink?: string;
	onClick?: (event: any, record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => void;
}

export interface ICustomAction extends IActionButton{
	isGlobal?: boolean; // if true, this action is not for single record but for all list
	queryParams?: any;
	showIf: (record: any, dataContainerService: IDataContainerService) => boolean;
	makeRouteLink?: (record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => string;
	makeQueryParams?: (record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => any;
	buttonColorDynamic?: (record: any, dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService) => string;
}

export interface IMassAction{
	actionCode?: string;
	buttonText?: string;
	buttonIcon?: string;
	buttonColor: string;
	tooltip?: string;
	onClick?: (ids: string[], datasetContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService, viewContainerRef?: ViewContainerRef) => Observable<string>;
}

export interface IBaseViewConfig{
	/**
	 * @deprecated use formConfig
	 */
	fields?: (IFieldDefinition|IFieldContainer)[];
	customActions?: ICustomAction[];
}

export interface IMenuFieldDefinition{
	text?: string;
	icon?: string;
	color?: string;
	items?: IMenuFieldDefinition[];
	tooltip?: string;
	onClick?: (record: any, datasetACS: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService) => void;
	showIf?: (record: any, dataContainerService: IDataContainerService) => boolean;
	makeLink?: (record: any) => string;
}

export interface IColumnDefinition{
	title: string;
	key: string;
	menu?: IMenuFieldDefinition;
	fieldDefinition?: IFieldDefinition;
	fieldView?: FieldViews;
	actionButton?: IActionButton;
	onClick?: (record: any, datasetACS: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService) => void;
	routerLink?: (record: any) => any;
	getDisplayHTML?: (record: any, value?: any, translateService?: TranslateService) => any;
	showIf?: (dataContainerService: IDataContainerService) => boolean;
	width?: string;
	actionCode?: string;
	style?: any;
	getCellConfigs?: (record: any) => IColumnDefinition[];
}

export interface IListFilterParam{
	getParams: () => any;
}

export interface IFormFilterConfig extends IFormConfig {
	
}

export interface IFormViewConfig extends IBaseViewConfig{
	// default form config used for actions
	formConfig: IFormConfig;
}

export interface IDetailViewConfig extends IBaseViewConfig{
	formConfig?: IFormConfig;
	actions: {
		edit: boolean;
		delete: boolean;
	};
}

export interface ICalendarConfig{
	itemsToEvents?: (items: any[], component: DatasetCalendarViewComponent) => CalendarEventModel[];
	onDayClicked?: (calendarComponent: DatasetCalendarViewComponent, day: CalendarMonthViewDay) => void;
	onAdd?: (calendarComponent: DatasetCalendarViewComponent) => void;
	openCellDetail?: boolean;
	templateCodes?: {
		eventTitleTemplate?: string;
		eventActionsTemplate?: string;
		monthCellTemplate?: string;
		monthOpenDayEventsTemplate?: string;
	};
}

export interface IExportAction {
	typeFile: ListViewExportTypes;
	buttonText: string;
	buttonIcon?: string;
	tooltip?: string;
	onClick?: (url: string) => void;
}

export interface IListViewConfig extends IBaseViewConfig{
	itemViewType: ListViewItemTypes;
	tableStyle?: string | StyleListViewConfig;
	filterStyle?: string | StyleListViewConfig;
	defaultSortBy?: string;
	defaultSortDirection?: string;
	columns?: IColumnDefinition[];
	showSearchBar?: boolean;
	showExportBtn?: boolean;
	showRequestPackage?: boolean;
	exportBtnActions?: IExportAction[];
	actions?: ('create' | 'edit' | 'delete' | 'detail' | 'restore')[];
	itemActionsOrder?: string[];
	filterForm?: IFormFilterConfig;
	cacheDatasetListFilters?: boolean;
	calendarConfig?: ICalendarConfig; // used when itemViewType id CALENDAR
	massActions?: IMassAction[];
	metaReviews?: string[];
	getMetaReview?: (paginationResponse: IPaginationResponse<any>, metaReview: string) => any;
}

export enum StyleListViewConfig {
	S1 = 'style-01', // style date 10/11/2022
}

export interface IWebSockeConfig{
	reloadDatasetList?: boolean;
	showToast?: boolean;
	toastMessage?: string;
	dismissToastTxt?: string;
	reloadDatasetListOnDismissToast?: boolean;
	composeToastMessage?: (socketEvent: ISocketEvent) => any;
	highlightAffectedRow?: (record: any, socketEvent: ISocketEvent) => boolean;
}

export interface IWebSocketActions{
	created?: IWebSockeConfig;
	updated?: IWebSockeConfig;
	deleted?: IWebSockeConfig;
	command_executed?: IWebSockeConfig;
	composeGlobalToastMessage?: (socketEvent: ISocketEvent) => any;
	checkGloballyAffectedRow?: (record: any, socketEvent: ISocketEvent) => boolean;
}

export interface IDatasetActionConfig<T extends IBaseViewConfig>{
	// open page or dialog
	openType?: DatasetActionOpenType;
	// define calls for fetch data before route or open dialog
	beforeCalls?: IDatasetCallDefinition[];
	// depend by actions
	viewConfig?: T;
	// websocket config
	webSocketConfig?: IWebSocketActions;
	// elaborate params for http request
	getCallParams?: (dataContainerService: IDataContainerService, parentData: any, params: any) => any;
	beforeSave?: (dataContainerService: IDataContainerService, recordId: string, data: any, previousData: any, viewContainerRef?: ViewContainerRef) => Observable<any>;
	afterSave?: (dataContainerService: IDataContainerService, data: any, currendRecord: any, viewContainerRef?: ViewContainerRef) => void;
	beforeDelete?: (dataContainerService: IDataContainerService, currendRecord: any, viewContainerRef?: ViewContainerRef) => Observable<any>;
}

export enum IDomainTemplate {
	STANDARD = 0,
	SIMPLIFIED = 1
}

export interface IStepConfig{
	title: string;
	component?: string;
	stepperTitle?: string;
	showIf?: (container: CommonStepViewContainerComponent|SmartInterfaceStepViewContainerComponent) => boolean;
	completed?: (container: CommonStepViewContainerComponent|SmartInterfaceStepViewContainerComponent) => boolean;
	options?: any;
	isTitle?: boolean;
	id?: any;
}

export interface IStepViewConfig{
	title: string;
	steps: IStepConfig[];
	showOverview?: boolean;
	tangerineBgColor?: TangerineBgColors;
	isLinear: (container: CommonStepViewContainerComponent|SmartInterfaceStepViewContainerComponent) => boolean;
	subtitle?: (component: CommonStepViewContainerComponent|SmartInterfaceStepViewContainerComponent) => string;
}

export interface IDatasetConfig{
	name: string;
	title: string;
	singleTitle?: string;
	ajaxDatasetCode: string;
	datasetListStyle?: StyleListViewConfig;

	replaceDatasetCode?: string;

	fieldsMap?: Map<string, IFieldDefinition>;
	supportProperties?: boolean;
	supportTemplates?: boolean;
	nestedProperties?: string[];

	enabledActions: {
		create: boolean | ((user: any) => boolean);
		list: boolean | ((user: any) => boolean);
		edit: boolean | ((user: any) => boolean);
		detail: boolean | ((user: any) => boolean);
		delete?: boolean | ((user: any) => boolean);
		restore?: boolean | ((user: any) => boolean);
	};

	fetchConfig?: {
		with_attributes?: string;
		with_relations?: string;
	};

	defaultActions: {
		create?: IDatasetActionConfig<IFormViewConfig>;
		list?: IDatasetActionConfig<IListViewConfig>;
		edit?: IDatasetActionConfig<IFormViewConfig>;
		detail?: IDatasetActionConfig<IDetailViewConfig>;
	};

	// Configurare se è una relazione 
	pivot?: {
		pivotCode: string;
		foreignKey: string;
		parentDatasetCode: string;
		commandSync?: string;
	};

	stepViewConfig?: IStepViewConfig;

	formConfigsMap?: { [key: string]: IFormConfig };
}
