import { Component, OnInit, ViewChild } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { FormViewerService, IFormViewerServiceConfig } from "app/services/form-viewer.service";
import { IFieldContainer, IFieldDefinition, InputTypes, ValueTypes } from "app/interfaces";
import { FormViewerComponent } from "app/main/components/form-viewer/form-viewer.component";
import { takeUntil } from "rxjs/operators";
import { BookingService } from "../../booking.service";
import { AppService } from "app/services/app.service";
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../edit-booking.component";
import { MatDialog } from "@angular/material";
import { ActionDialogComponent } from "../../action-dialog/action-dialog.component";
import { cloneFieldDefinition } from "app/helpers/field.helper";
import nationCodeField from "app/configs/commons/shared-field-configs/nation-code.field";
import * as _ from "lodash";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import {TripBookingPackagesStepService} from "../trip-booking-packages-step.service";

const inputCounterConfig: IFieldDefinition = {
	key: 'adt',
	name: 'Adulto',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.COUNTER,
	},
	extra: {
		minValue: 0
	},
	maxWidth: '140px',
	width: '140px'
};

@Component({
	selector   : 'participants-form',
	templateUrl: './participants-form.component.html',
	styleUrls: ['./participants-form.component.scss'],
	providers: []
})
export class ParticipantsFormComponent extends DestroyableComponent implements OnInit{

	public formConfig: IFormViewerServiceConfig = {
		fields: [
			{
				config: {
					fxLayoutGap: '10px',
					flexLayout: 'row'
				},
				fields: [
					Object.assign({}, inputCounterConfig, {key: 'ADT', name: 'Adulti'}),
					Object.assign({}, inputCounterConfig, {key: 'CHD', name: 'Bambini'}),
					Object.assign({}, inputCounterConfig, {key: 'INF', name: 'Neonati'}),
					Object.assign({}, inputCounterConfig, {key: 'SNR', name: 'Anziani'}),
					cloneFieldDefinition(nationCodeField, {
						maxWidth: '160px',
						defaultValue(dataContainerService, options, record){ 
							return dataContainerService.getValueFromKeyPath('trip_bookings.record.last_nation_code', 'IT') || 'IT';
						}
					})
				]
			}
		]
	};

	@ViewChild('formViewer', {static: true}) formViewer: FormViewerComponent;

	constructor(
		private appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public bookingService: BookingService,
		private matDialog: MatDialog,
        public tripBookingPackagesStepService: TripBookingPackagesStepService
	){
		super();
	}

	ngOnInit(): void {
		
	}

	isChanged(data){
		for (const cat of ['ADT', 'SNR', 'CHD', 'INF']) {
			const currentVal = _.get(this.datasetRS.record.value.participant_counters, cat) || 0;
			const formVal = _.get(data, cat) || 0;
			if(currentVal != formVal) return true;
		}
		return false;
	}

	onValueChanges(formViewerService: FormViewerService){
		const formValues = formViewerService.getFormData();
		if(!this.isChanged(formValues)) return;
		const formGroup = this.formViewer.formViewerService.formGroup;
		formGroup.disable();

		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			width: '550px',
			data: {
				title: 'Aggiornamento partecipanti'
			}
		});

		this.bookingService.execBookingCommand('update_participants', {
			trip_booking_id: this.datasetRS.recordId,
			participants: formValues
		}, false)
		.subscribe({
			next: () => {
				this.appService.appEvent.next({ name: RELOAD_BOOKING });
				this.appService.appEvent.next({ name: RELOAD_BOOKING_PACKAGES });
			},
			error: (response) => {
				this.bookingService.onErrorActionResponse(response, dialogRef);
				// reset
				formViewerService.setValues({
					ADT: _.get(this.datasetRS.record.value.participant_counters, 'ADT', 0) || 0,
					CHD: _.get(this.datasetRS.record.value.participant_counters, 'CHD', 0) || 0,
					SNR: _.get(this.datasetRS.record.value.participant_counters, 'SNR', 0) || 0,
					INF: _.get(this.datasetRS.record.value.participant_counters, 'INF', 0) || 0
				});
				formGroup.enable();
			},
			complete: () => {
				// reset
				formViewerService.setValues({
					ADT: _.get(this.datasetRS.record.value.participant_counters, 'ADT', 0) || 0,
					CHD: _.get(this.datasetRS.record.value.participant_counters, 'CHD', 0) || 0,
					SNR: _.get(this.datasetRS.record.value.participant_counters, 'SNR', 0) || 0,
					INF: _.get(this.datasetRS.record.value.participant_counters, 'INF', 0) || 0
				});
				dialogRef.close();
				formGroup.enable();
			}
		});
	}

	openInfoTrenitalia() {
		const url = "https://www.trenitalia.com/it/html/trenitalia/Info_regionale.html";
		window.open(url, '_blank', 'width:500,height=500');
		/*this.dialog.open(InfoDialog, {
			data: row
		});*/
	}
}