import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { InputTypes, ValueTypes, IFieldDefinition, ListViewItemTypes, FieldViews } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import tripCategoryIdField from 'app/configs/commons/shared-field-configs/trip-category-id.field';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import trashedField from 'app/configs/commons/shared-field-configs/trashed.field';
import { fieldsMap as TripPackageFieldsMap } from 'app/configs/datasets/trip_packages/fields';
import {
	showFieldBasedOnConfig
} from "../../../../../../configs/commons/shared-field-configs/show-field-on-config.field";
import * as _ from 'lodash';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('start_day', {
	key: 'start_day',
	name: 'Data Partenza',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE,
		clearable: false
	},
	defaultValue(dataContainerService: IDataContainerService): any{
		return convertToUTCDay().toISOString();
	}
});

function skipIfNoStatus(record: any, dataContainerService: IDataContainerService){
	const showStatus = showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'packages.list_columns', 'status', true);
	return !showStatus;
}

fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Pacchetto',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_packages',
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		clearable: true,
		viewConfig: {
			showRequestPackage: false,
			itemViewType: ListViewItemTypes.TABLE,
			showSearchBar: true,
			defaultSortBy: 'created_at',
			defaultSortDirection: 'desc',
			columns: [
				{
					title : '',
					key: 'master_image',
					fieldView: FieldViews.BG_IMAGE,
					style: {
						'padding': '0px',
						'border-bottom-left-radius': '15px',
						'border-top-left-radius': '15px',
						'text-align': 'center'
					},
					routerLink(record: any): string{
						return datasetDetailPage('trip_packages', record.id);
					}
				},
				{
					title: 'Codice',
					key: 'code',
					routerLink(record: any): string{
						return datasetDetailPage('trip_packages', record.id);
					}
				},
				{
					title: 'Status',
					key: 'active_status',
					fieldView: FieldViews.HTML,
					getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
						let colorClass = 'red-bg';
						let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
						if(record.active_status === 1){
							colorClass = 'green-bg';
							text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							if(record.publish_on_web === 1){
								text = '<span class="status">' + translateService.instant('Attivo e pubblicato') + '</span>';
							}else{
								colorClass = 'orange-bg';
							}
						}
		
						let html = '<div class="package_status" style="display:flex;align-items: center;">';
						html += '<span class="' + colorClass + ' record-status-bar" style="width:11px;height:11px;display:inline-block;border-radius:100%!important;"></span>';
						html += text;
						html += '</div>';
						return html;
					},
					showIf(dataContainerService){
						return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'packages.list_columns', 'status', true);
					}
				},
				{
					title: 'Descrizione',
					key: 'description',
					fieldView: FieldViews.HTML,
					getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
						let html = '<span style=\'display: block;\'>' + record.description + '</span>';
		
						const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
						const isFiltered = filteredDomainId && filteredDomainId !== 'null';
						
						if(record.domain){
							if (record.reseller_domain_package && record.reseller_domain_package.reseller_domain){
								// tslint:disable-next-line: max-line-length
								html += '<span style=\'font-size: 11px;\'>' + translateService.instant('Proprietario') + ':</span> <label style=\'display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;\'>' + record.reseller_domain_package.reseller_domain.display_name + '</label>';
							}else if ((!isFiltered && !record.is_owner) || (isFiltered && filteredDomainId !== record.domain.id)) {
								// tslint:disable-next-line: max-line-length
								html += '<span style=\'font-size: 11px;\'>' + translateService.instant('Proprietario') + ':</span> <label style=\'display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;\'>' + record.domain.display_name + '</label>';
							}
							// aggiungere logo tour operator nel caso di pacchetto rivenduto
							if ((!isFiltered && record.is_owner) || (isFiltered && filteredDomainId === record.domain.id)){
								if (record.tourcms_channel_id_tour_id){
									// tslint:disable-next-line: max-line-length
									html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px;">Proprietario:</label> <img src="assets/images/tour-operators/tour-cms.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
								} else if (record.bokun_vendor_id_tour_id){
									// tslint:disable-next-line: max-line-length
									html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/bokun-logo.svg" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
								} else if (record.ticketlandia_museum_id_event_id){
									// tslint:disable-next-line: max-line-length
									html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 90px; margin-left: 10px;" /></span>';
								}
							}
						}
						return html;
					},
				},
				{
					title: 'Traduzioni Attive',
					key: 'active_language_codes'
				},
				{
					title: 'Prezzo',
					key: 'price',
					fieldDefinition: {
						name: 'Prezzo',
						key: 'price',
						valueType: ValueTypes.STRING,
						fieldView: FieldViews.HTML,
						getDisplayValue(record: any): any{
							const price = _.get(record, 'properties.price.value');
							if(!['string', 'number'].includes(typeof(price))) return '';
							return 'A partire da <br>€'+price;
						},
						
					},
					showIf(dataContainerService: IDataContainerService):any{
						return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'packages.list_columns', 'price', false);
					}
				},
			],
			filterForm: {
				formStyle: 'style-1',
				fields: [
					{
						config: {
							flexLayout: 'row',
							fxLayoutGap: '50px',
						},
						fields: [
							TripPackageFieldsMap.get('departure_date_filter'),
							{
								key: 'space_holder',
								valueType: ValueTypes.STRING,
								inputConfig: {
									type: InputTypes.HIDDEN
								},
								name: ''
							}
						]
					},
					{
						
						config: {
							flexLayout: 'row',
							fxLayoutGap: '50px',
						},
						fields: [
							cloneFieldDefinition(TripPackageFieldsMap.get('active_status_filter'), {inputType: InputTypes.RADIO, skipIf: skipIfNoStatus}),
							cloneFieldDefinition(TripPackageFieldsMap.get('publish_on_web_filter'), {inputType: InputTypes.RADIO, skipIf: skipIfNoStatus})
						]
					},
					{
						config: {
							flexLayout: 'row',
							fxLayoutGap: '50px',
						},
						fields: [
							cloneFieldDefinition(TripPackageFieldsMap.get('only_resells'), {
								inputConfig: {
									type: InputTypes.RADIO
								}
							}),
							cloneFieldDefinition(trashedField, {inputType: InputTypes.RADIO, skipIf: skipIfNoStatus})
						]
					},
					{
						config: {
							flexLayout: 'row',
							fxLayoutGap: '50px',
						},
						fields: [
							cloneFieldDefinition(meetingPointIdField, {
								inputType: InputTypes.DATASET_SELECT
							}),
							tripCategoryIdField
						]
					},
					{
						config: {
							flexLayout: 'row',
							fxLayoutGap: '50px',
						},
						fields: [
							cloneFieldDefinition(tripResourceIdField, {
								inputType: InputTypes.DATASET_SELECT
							}),
							{
								key: 'resource_sub_type',
								name: 'Tipologia Risorsa',
								inputType: InputTypes.DATASET_SELECT,
								valueType: ValueTypes.PROPERTY,
								datasetCode: 'trip_resource_sub_types',
								remoteOptions: {
									getValue(record: any): string{
										return record.code;
									},
									getLabel(record: any): string{
										return record.description;
									}
								}
							}
						]
					}
				]
			}
		},
		selectDialogConfig: {
			passThrough: true,
			dialogConfig: {
				datasetCode: "", // leave empty
				selectionBtnIcon: null,
				tableStyle: 'style-2 selectable btn-outlined',
				filterStyle: 'style-1',
				selectionBtnTxt: 'Filtra'
			},
			matDialogConfig: {
				panelClass: 'custom-dialog-container'
			}
		}
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getParams(dataContainerService: IDataContainerService, record: any, options?: any){
			const filters = {};
			const showStatus = showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'packages.list_columns', 'status', true);
			if(!showStatus){
				filters['active_status'] = 1;
			}
			// auto apply date filter
			if(!showStatus && record.start_day){
				filters['departure_date_filter'] = JSON.stringify(['=', record.start_day]);
			}
			return filters;
		}
	}
});

export { fieldsMap };
