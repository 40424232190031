import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Nominativo',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('age_range_code', {
	key: 'age_range_code',
	name: 'Età',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	optionSelected: 'ADT',
	options: [
		{
			value: 'SNR',
			label: '(SNR) Senior'
		},
		{
			value: 'ADT',
			label: '(ADT) Adulto'
		},
		{
			value: 'CHD',
			label: '(CHD) Bambino'
		},
		{
			value: 'INF',
			label: '(INF) Neonato'
		},
	]
});

fieldsMap.set('lang_code', {
	key: 'lang_code',
	name: 'Lingua',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'localizations',
	getDisplayValue(record): string{
		return record.entity.description;
	},
	remoteOptions: {
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.lang;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name;
		}
	}
});

fieldsMap.set('nation_code', {
	key: 'nation_code',
	name: 'Nazionalità',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'nations',
	remoteOptions: {
		getParams(): any{
			return { sortBy: 'description', sortDirection: 'asc' };
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

fieldsMap.set('entity_id', {
	key: 'entity_id',
	name: 'Cliente',
	inputType: InputTypes.DATASET_SEARCH,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entities',
	remoteOptions: {
		limit: 5,
		icon: 'person',
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		}
	}
});

export {fieldsMap};
