import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
} from 'app/interfaces';
import { fieldsMap } from './fields';
import generalForm from './forms/general.form';
import supplierForm from './forms/supplier.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/entity_suppliers/detail/:recordId',
	params: {
		with_relations: 'entity'
	}
};

export const EntitySupplierConfig: IDatasetConfig = {
	name: 'entity_suppliers',
	title: 'Fornitori',
	singleTitle: 'Fornitore',
	ajaxDatasetCode: 'entity_suppliers',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
	},
	formConfigsMap: {
		generalForm,
		supplierForm,
	},
	fetchConfig: {
		with_relations: 'entity'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [beforeCallDetail],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false,
				},
			},
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm,
			},
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: supplierForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any) {
				params.with_relations = 'entity';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(
							record,
							datasetACS,
							datasetNavigatorService
						): void {
							datasetNavigatorService.showDatasetDetail(
								'entities',
								null,
								record.entity_id
							);
						},
					},
					{
						title: 'Descrizione',
						key: 'entity.description',
					},
				],
				customActions: [
					{
						buttonIcon: 'edit',
						buttonColor: 'accent',
						actionCode: 'edit',
						showIf(): boolean {
							return true;
						},
						onClick(
							event: any,
							record: any,
							dataContainerService: IDataContainerService,
							datasetNavigatorService: DatasetNavigatorService
						): void {
							datasetNavigatorService.showDatasetDetail(
								'entities',
								null,
								record.entity_id
							);
						},
					},
				],
				actions: ['create', 'delete'],
			},
		},
	},
};
