<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>Questa traduzione non è ancora presente!</p>
		</ng-container>

		<form autocomplete="off"
			name='form' 
			*ngIf='formViewerService.initialized && formViewerService.formGroup' 
			[formGroup]="formViewerService.formGroup" 
			fxLayout="column" 
			fxLayoutAlign="start"
			fxLayoutAlign.lt-lg='stretch'>

			<label class="input-label section-question">
				Descrivi la tua attività
			</label>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("active_status")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["active_status"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

				
			<div class="instruction with-bg">
				<label class="input-label section-question">Descrizione completa</label>
				<p class="instruction">
					Descrivi le attività che si svolgeranno durante la visita
				</p>
				<p class="instruction">
					Cosa vedranno? Cosa succederà? Come finirà?
				</p>
				<p class="instruction">
					Non scrivere "noi", "nostro", il nome della tua azienda o copiare e incollare testo da un sito Web esistente.
				</p>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("full_description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["full_description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("tour_brief")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["tour_brief"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("schedule_info")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["schedule_info"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("before_leaving")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["before_leaving"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="instruction top-30">
				<label class="input-label section-question">Cosa è incluso nel prezzo?</label>
				<p class="instruction">
					I biglietti d'ingresso sono inclusi nel prezzo? Fornisci spuntini? Attrezzature? Elencali qui.
				</p>
			</div>

			<div class="examples">
				<h3 class="examples-title"><b>Esempi</b></h3>
	
				<div class="good-examples">
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Prelievo e rientro in hotel</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Cuffie per ascoltare chiaramente la guida turistica</span>
					</div>
				</div>
	
				<div class="bad-examples">
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Il tour più incredibile ed eccellente di sempre</span>
					</div>
					
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> La guida locale professionale ti spiegherà tutto ciò che c'è da sapere.</span>
					</div>

					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Opportunità fotografiche e un momento fantastico</span>
					</div>
				</div>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("included_services")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["included_services"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="instruction top-30">
				<label class="input-label section-question">Cosa non è incluso? (opzionale)</label>
			</div>

			<div class="examples">
				<h3 class="examples-title"><b>Esempi</b></h3>
	
				<div class="good-examples">
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Prelievo e rientro in hotel</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Cibo e bevande</span>
					</div>

					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Mance</span>
					</div>
				</div>
	
				<div class="bad-examples">
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Purtroppo, durante questo tour non verrà servito cibo, ma c'è un bar dove è possibile acquistarlo.</span>
					</div>

					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Le mance non sono incluse, si consiglia di lasciare alla guida il 15-20% alla fine del tour.</span>
					</div>
				</div>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("excluded_services")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["excluded_services"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("voucher_text")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["voucher_text"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("voucher_text_ticketing")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["voucher_text_ticketing"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</form>

		<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
			<button color='accent' [disabled]='!formViewerService.formGroup.valid' mat-flat-button (click)='onSave()'>
				<span *ngIf='!datasetACS.loading.value'>{{ (hasNext ? "Salva e vai avanti" : "Salva") | translate }}</span>
				<span *ngIf='datasetACS.loading.value'>Azione in corso ...</span>
			</button>
		</div>

	</div>
</div>