/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.components.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../base-actions/list/dataset-list.component.ngfactory";
import * as i6 from "../../services/dataset-action-container.service";
import * as i7 from "../../../../../services/app.service";
import * as i8 from "../../../../pages/page-closure-container.service";
import * as i9 from "../../../../../services/auth.service";
import * as i10 from "../../services/dataset.service";
import * as i11 from "../../../../../services/domain-filter.service";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../base-actions/list/dataset-list.service";
import * as i14 from "../../base-actions/list/dataset-list.component";
import * as i15 from "@angular/router";
import * as i16 from "../../services/dataset-navigator.service";
import * as i17 from "../../../../../../@fuse/services/translation-loader.service";
import * as i18 from "@angular/material/snack-bar";
import * as i19 from "../../../../../services/websocket.service";
import * as i20 from "@angular/material/dialog";
import * as i21 from "@angular/common";
import * as i22 from "./dialog.component";
import * as i23 from "../../services/dataset-record.service";
var styles_SelectDatasetRecordDialogComponent = [i0.styles];
var RenderType_SelectDatasetRecordDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectDatasetRecordDialogComponent, data: {} });
export { RenderType_SelectDatasetRecordDialogComponent as RenderType_SelectDatasetRecordDialogComponent };
function View_SelectDatasetRecordDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_SelectDatasetRecordDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mb-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "dataset-list", [], null, [[null, "selected"], [null, "datasetEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("datasetEvent" === en)) {
        var pd_1 = (_co.onDatasetEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DatasetListComponent_0, i5.RenderType_DatasetListComponent)), i1.ɵprd(512, null, i6.DatasetActionContainerService, i6.DatasetActionContainerService, [i7.AppService, i8.PageClosureContainerService, i9.AuthService, i10.DatasetService, i11.DomainFilterService, i12.TranslateService, i1.Injector]), i1.ɵprd(512, null, "ListParamService", i13.DatasetListService, [i6.DatasetActionContainerService, i10.DatasetService]), i1.ɵdid(4, 770048, null, 0, i14.DatasetListComponent, [i15.ActivatedRoute, i10.DatasetService, i15.Router, i9.AuthService, i7.AppService, i6.DatasetActionContainerService, i16.DatasetNavigatorService, i11.DomainFilterService, "ListParamService", i17.FuseTranslationLoaderService, i18.MatSnackBar, i19.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], parentDatasetACS: [2, "parentDatasetACS"], filters: [3, "filters"], selectable: [4, "selectable"], selectionBtnTxt: [5, "selectionBtnTxt"], selectionBtnIcon: [6, "selectionBtnIcon"], viewConfig: [7, "viewConfig"], enableCreate: [8, "enableCreate"], showSearchBar: [9, "showSearchBar"], elevate: [10, "elevate"], tableStyle: [11, "tableStyle"], filterStyle: [12, "filterStyle"] }, { selected: "selected", datasetEvent: "datasetEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.datasetACS.datasetCode; var currVal_2 = _co.datasetACS; var currVal_3 = _co.data.filters; var currVal_4 = true; var currVal_5 = (_co.data.selectionBtnTxt || "Aggiungi"); var currVal_6 = _co.data.selectionBtnIcon; var currVal_7 = _co.data.viewConfig; var currVal_8 = false; var currVal_9 = true; var currVal_10 = false; var currVal_11 = (_co.data.tableStyle || "style-2 selectable"); var currVal_12 = (_co.data.filterStyle || "style-1"); _ck(_v, 4, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
export function View_SelectDatasetRecordDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "material-icons-outlined dialog-close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i20.MatDialogTitle, [[2, i20.MatDialogRef], i1.ElementRef, i20.MatDialog], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i20.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDatasetRecordDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectDatasetRecordDialogComponent_2)), i1.ɵdid(11, 16384, null, 0, i21.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = (_co.loading || _co.datasetACS.loading.value); _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.datasetACS.loading.value; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.data.title)); _ck(_v, 4, 0, currVal_1); }); }
export function View_SelectDatasetRecordDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "select-dataset-record-dialog", [], null, null, null, View_SelectDatasetRecordDialogComponent_0, RenderType_SelectDatasetRecordDialogComponent)), i1.ɵprd(512, null, i8.PageClosureContainerService, i8.PageClosureContainerService, [i7.AppService]), i1.ɵprd(512, null, i6.DatasetActionContainerService, i6.DatasetActionContainerService, [i7.AppService, i8.PageClosureContainerService, i9.AuthService, i10.DatasetService, i11.DomainFilterService, i12.TranslateService, i1.Injector]), i1.ɵdid(3, 245760, null, 0, i22.SelectDatasetRecordDialogComponent, [i8.PageClosureContainerService, i6.DatasetActionContainerService, i7.AppService, i20.MatDialog, i20.MatDialogRef, i20.MAT_DIALOG_DATA, [3, i6.DatasetActionContainerService], [3, i23.DatasetRecordService]], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var SelectDatasetRecordDialogComponentNgFactory = i1.ɵccf("select-dataset-record-dialog", i22.SelectDatasetRecordDialogComponent, View_SelectDatasetRecordDialogComponent_Host_0, {}, {}, []);
export { SelectDatasetRecordDialogComponentNgFactory as SelectDatasetRecordDialogComponentNgFactory };
