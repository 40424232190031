import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('city', {
	key: 'city',
	name: 'Città',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('street', {
	key: 'street',
	name: 'Indirizzo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('number', {
	key: 'number',
	name: 'Numero civico',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('zip_code', {
	key: 'zip_code',
	name: 'CAP',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('province', {
	key: 'province',
	name: 'Provincia',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('region', {
	key: 'region',
	name: 'Regione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('locality', {
	key: 'locality',
	name: 'Località',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale'
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	inputHint: 'Note aggiuntive'
});

fieldsMap.set('point_map_url', {
	key: 'point_map_url',
	name: 'Posizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Seleziona la posizione nella mappa'
});

fieldsMap.set('nation_code', cloneFieldDefinition(nationCodeField));

export {fieldsMap};
