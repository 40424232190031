import {Component, forwardRef, OnInit} from '@angular/core';
import {FormViewerService} from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import {DatasetActionContainerService} from '../../../../../components/dataset/services/dataset-action-container.service';
import {TrenitaliaService} from '../../trenitalia.service';
import {AppService} from '../../../../../../services/app.service';
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
@Component({
  selector: 'trenitalia-sale-search-carnet',
  templateUrl: './search-carnet.component.html',
  styleUrls: ['./search-carnet.component.scss'],
	providers:[
		FormViewerService,
		forwardRef(() => DatasetActionContainerService),]
})
export class SearchCarnetComponent implements OnInit {
	public formFields = formFields;
	protected _unsubscribeAll: Subject<any>;

	constructor(public formViewerService: FormViewerService,
			  public datasetACS: DatasetActionContainerService,
			  public trnService: TrenitaliaService,
			  public AppService: AppService) {
		this.formViewerService.init({
		  errors: null,
		  dataContainerService: this.datasetACS,
		  fields: Array.from(formFields.values())
		});
	}

  ngOnInit() {
  }

  searchCarnet(){
	  this.trnService.loading.next(true);
	  const dataForm = this.formViewerService.getFormData();
	  const data={
		  carnetNumber: dataForm.carnet,
		  name: dataForm.name_carnet,
		  surname: dataForm.lastname_carnet
	  }
	  this.trnService.checkCarnet(data)
		  .subscribe(carnet =>{
		  if(_.get(carnet,'error')){
			  this.AppService.showErrorMessage(_.get(carnet,'error'));
			  this.trnService.loading.next(false);
		  }else if(_.get(carnet,'entitlements.currentUsages')===_.get(carnet,'entitlements.maxUsages')){
			  this.AppService.showErrorMessage('Carnet Esaurito!');
			  this.trnService.loading.next(false);
		  }else{
			  let checkout = this.trnService.checkoutData.getValue();
			  checkout.carnetData = {};
			  checkout.from = +_.get(carnet,'solutionNodes.start.id');
			  checkout.to = +_.get(carnet,'solutionNodes.end.id');
			  checkout.carnetData.entitlements = _.get(carnet,'entitlements');
			  checkout.carnetData.station_from = this.trnService.getStationDataFromId(checkout.from);
			  checkout.carnetData.station_to = this.trnService.getStationDataFromId(checkout.to);
			  checkout.travellers = _.get(carnet,'travellers')
			  checkout.mode = 'carnet'
			  checkout.carnetData.offersAvailable = _.get(carnet,'services.offer')
			  this.trnService.checkoutData.next(checkout);
			  this.trnService.loading.next(false);
		  }
	  },
	  error => {
		  this.AppService.showErrorMessage('Carnet non trovato!')
		  this.trnService.loading.next(false);
	  });
  }

}
