import * as tslib_1 from "tslib";
import { InputTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { Validators } from '@angular/forms';
var fieldsMap = new Map();
var iconsOptions = [];
for (var index = 1; index < 101; index++) {
    var element = {
        value: 'icon_set_1_icon-' + index,
        label: 'icon_set_1_icon-' + index
    };
    iconsOptions.push(element);
}
for (var index = 102; index < 119; index++) {
    if (index === 113)
        continue;
    var element = {
        value: 'icon_set_2_icon-' + index,
        label: 'icon_set_2_icon-' + index
    };
    iconsOptions.push(element);
}
fieldsMap.set('slug', {
    key: 'slug',
    name: 'Icona',
    inputType: InputTypes.SELECT_CUSTOM_ICON,
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.CUSTOM_ICON,
    options: iconsOptions
});
fieldsMap.set('title', {
    key: 'title',
    name: 'Nome',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required]
});
fieldsMap.set('description', {
    key: 'description',
    name: 'Descrizione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required]
});
fieldsMap.set('active_language_codes', {
    key: 'active_language_codes',
    name: 'Traduzioni attive',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
        if (record.active_language_codes && record.active_language_codes.length > 0) {
            var style = 'style="margin-right: 5px;"';
            try {
                for (var _b = tslib_1.__values(record.active_language_codes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var lang = _c.value;
                    html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            html += '<span>---</span>';
        }
        html += '<div>';
        return html;
    }
});
export { fieldsMap };
