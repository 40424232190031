<form autocomplete="off" name='form' *ngIf='formViewerService.initialized && formViewerService.formGroup'
      [formGroup]="formViewerService.formGroup">
    <div fxLayout="row text-center" fxLayoutGap="12px">
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='formFields.get("start_station")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["start_station"]'
                [dataContainerService]='datasetACS'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='formFields.get("end_station")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["end_station"]'
                [dataContainerService]='datasetACS'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='formFields.get("carnet_type")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["carnet_type"]'
                [dataContainerService]='datasetACS'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='formFields.get("date")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["date"]'
                [dataContainerService]='datasetACS'
                [readOnly]=true
            ></form-input-viewer>
        </div>
        <div fxFlex class="pt-32">
            <button mat-raised-button (click)="search()"  [disabled]="!trnService.continue.value">Ricerca</button>
        </div>
    </div>
</form>