import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { DatasetActionContainerService, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
var ActualTripDateComponent = /** @class */ (function () {
    function ActualTripDateComponent(dialog, dialogRef, data) {
        var _this = this;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.loading = false;
        this.datesFilter = function (d) {
            var day = d.day();
            /* Filter date by meeting_point. */
            if (_this.timeMeetingPoint) {
                return !_this.timeMeetingPoint.includes(day);
            }
            return true;
        };
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(ActualTripDateComponent.prototype, "tripBookingPackage", {
        get: function () {
            return this.data.tripBookingPackage;
        },
        enumerable: true,
        configurable: true
    });
    ActualTripDateComponent.prototype.ngOnInit = function () {
        this.minDate = moment();
        this.setDateMeetingPoint();
    };
    ActualTripDateComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ActualTripDateComponent.prototype.onCloseClick = function () {
        this.dialogRef.close(false);
    };
    ActualTripDateComponent.prototype.onDatasetEvent = function (event) {
        // this.loadLastCreated();
    };
    ActualTripDateComponent.prototype.dateChangeActualTrip = function (event) {
        var _this = this;
        this.status = undefined;
        var m = event.value;
        m.locale('en');
        var dateCode = m.format('dddd').toLowerCase();
        this.dates.forEach(function (date) {
            if (date.code === dateCode) {
                _this.chosenDate = date;
                _this.deliveryDate = m.format("YYYY-MM-DD");
            }
        });
    };
    ActualTripDateComponent.prototype.timeChangeActualTrip = function (event) {
        var e_1, _a;
        var value = event.target.value;
        var format = 'HH:mm';
        var mCurrentTime = moment(value, format), mOpeningTime, mClosureTime;
        try {
            for (var _b = tslib_1.__values(this.chosenDate.time), _c = _b.next(); !_c.done; _c = _b.next()) {
                var time = _c.value;
                mOpeningTime = moment(time.opening, format);
                mClosureTime = moment(time.closure, format);
                var _status = mCurrentTime.isBetween(mOpeningTime, mClosureTime, null, '[]');
                if (_status) {
                    this.status = true;
                    this.deliveryTime = value;
                    break;
                }
                else {
                    this.status = false;
                    this.deliveryTime = '';
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ActualTripDateComponent.prototype.saveDataTimeActualTrip = function () {
        this.data.bookingStepService.updateActualTrip({
            trip_booking_package_id: this.tripBookingPackage.id,
            actual_trip_id: this.tripBookingPackage.actual_trip.id,
            delivery_date: this.deliveryDate,
            delivery_time: this.deliveryTime
        });
        this.dialogRef.close(false);
    };
    ActualTripDateComponent.prototype.setDateMeetingPoint = function () {
        if (this.inputDate)
            this.inputDate.value = '';
        this.dates = this.tripBookingPackage.default_meeting_point_unified_list_item ?
            this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point.time : undefined;
        if (!this.dates)
            return;
        var mapTimes = this.dates.map(function (date, index) {
            if (!date.time || date.time.length === 0) {
                index++;
                // changes the index of the day Monday to Sunday
                index = index === 7 ? 0 : index;
                return index;
            }
        });
        this.timeMeetingPoint = mapTimes;
    };
    return ActualTripDateComponent;
}());
export { ActualTripDateComponent };
