import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { twilioSMSFields } from '../../fields/twilio/sms.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	twilioSMSFields.get('enabled'),
	twilioSMSFields.get('sid'),
	twilioSMSFields.get('token'),
    twilioSMSFields.get('fromPhoneNumber'),
	twilioSMSFields.get('scenario')
];

const twilioSMSForm: IFormConfig = {
	editTitle: 'Modifica Configurazioni Twilio SMS',
	fields: formFields 
};

export default twilioSMSForm;
