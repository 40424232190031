import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from 'app/main/components/dataset/components/common-step/smart-interface/datasets/trip_packages/product-name-step/form.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('active_status'),
			fieldsMap.get('publish_on_web'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('supplier_id_field'),
			fieldsMap.get('taxation'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('commessa_id'),
			fieldsMap.get('active_commission_percent'),
		]
	}
];

const config: IFormConfig = { 
	title: 'Pacchetto',
	fields: formFields 
};

export default config;
