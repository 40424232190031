import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, InputTypes, FieldViews, ValueTypes, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import baseInfoFormConfig from './forms/base.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import listFilterForm from './forms/filter.form';
import { LIST_COLUMNS } from './list_columns';

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

export const TripBookingConfig: IDatasetConfig = {
	name: 'trip_bookings',
	title: 'Prenotazioni',
	singleTitle: 'Prenotazione',
	ajaxDatasetCode: 'trip_bookings',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	fetchConfig: {
		with_attributes: 'booking_type,method_payment'
	},
	formConfigsMap: {
		baseInfoFormConfig
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ callModule ],
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ callModule ],
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ callModule ],
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				filterForm: listFilterForm,
				columns: LIST_COLUMNS,
				actions: [],
				customActions: [
					{
						buttonColor: 'primary',
						buttonIcon: 'edit',
						destinationLink: '_blank',
						makeRouteLink(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): string{
							return '/edit-booking/' + record.id;
						},
						showIf(): boolean{ return true; }
					}
				]
			},
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['split_packages'] = 1;
				return params;
			}
		}
	}
};
