<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<div class='text-list-input-container'>
		<label>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</label>
		<span class='text-list-input-hint' *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</span>
		<mat-list>
			<mat-list-item *ngFor='let item of list; let i = index; trackBy: trackByFn'>
				<div fxLayout='row' fxLayoutAlign="space-between center">
					<mat-form-field>
						<input [readonly]='inputService.inputConfig.readOnly' matInput autocomplete="off" [name]='inputService.formControlKey + "text_input_item_" + i' [(ngModel)]='list[i]' [ngModelOptions]="{standalone: true}"/>
					</mat-form-field>
					<button *ngIf='!inputService.inputConfig.readOnly' mat-icon-button color='warn' (click)="removeItem(i)">
						<span class="material-icons">close</span>
					</button>
				</div>
			</mat-list-item>
		</mat-list>
		<button *ngIf='!inputService.inputConfig.readOnly' mat-button color='accent' (click)='addNewItem()'>{{'Aggiungi' | translate}}</button>
	</div>
</ng-container>