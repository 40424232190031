import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { fieldsMap } from './../fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { showIfEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('send_sms'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('code'),
			{
				key: 'disable_auto_export',
				name: 'Esporta cliente eAgency',
				valueType: ValueTypes.NUMBER,
				inputConfig: {
					type: InputTypes.SELECT,
					clearable: false,
					hint: 'Se abilitato, ogni modifica aggiornerà i dati su eAgency'
				},
				width: '200px',
				options: [
					{
						value: 0,
						label: 'Si'
					},
					{
						value: 1,
						label: 'No'
					}
				],
				showIf(record: any, dataContainerService: IDataContainerService): boolean{
					return showIfEAgency(record, dataContainerService);
				}
			},
			{
				key: 'eagency_pratica_mode',
				name: 'Emissione eAgency',
				valueType: ValueTypes.STRING,
				inputConfig: {
					type: InputTypes.SELECT,
					clearable: false,
					hint: 'Se "Lordo" verrà esportato sempre l\'ammontare lordo.'
				},
				width: '200px',
				defaultValue(){return 'default';},
				options: [
					{
						value: 'default',
						label: 'Default'
					},
					{
						value: 'lordo',
						label: 'Lordo'
					}
				],
				showIf(record: any, dataContainerService: IDataContainerService): boolean{
					return showIfEAgency(record, dataContainerService);
				}
			}
		]
	},
	fieldsMap.get('typology'),
	fieldsMap.get('payment_type_id'),
	fieldsMap.get('discount_rule_list_id'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('billable'),
			fieldsMap.get('branch'),
		]
	},
	fieldsMap.get('tour_cms_ref'),
	fieldsMap.get('bokun_ref'),
	{
		config: {
			section: {
				title: 'Dati contabili',
				open: false,
				showIf(record: any, dataActionContainer: IDataContainerService): boolean{
					return showIfEAgency(record, dataActionContainer);
				}
			}
		},

		fields: [
			fieldsMap.get('pec'),
			fieldsMap.get('sdi'),
			fieldsMap.get('sub_account_id'),
			fieldsMap.get('customer_notes')
		]
	}
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
