import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('display_name')
		]
	},
	fieldsMap.get('status'),
	fieldsMap.get('stripe_fee')
];

export default {
	fields: detailFormFields 
};
