import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('display_name', {
	key: 'display_name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('domain_path', {
	key: 'domain_path',
	name: 'Gerarchia',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('logo', {
	key: 'logo',
	name: 'Logo',
	valueType: ValueTypes.FILE,
	fieldView: FieldViews.IMAGE
});

export {fieldsMap};
