import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { TrenitaliaService } from '../../trenitalia.service';
import { FormViewerService } from '../../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { BehaviorSubject, Subject, EMPTY, of, } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { catchError, exhaustMap, takeUntil } from 'rxjs/operators';
import { DatasetService } from '../../../../../components/dataset/services/dataset.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'trenitalia-billing-form',
	templateUrl: './billing-form.component.html',
	styleUrls: ['./billing-form.component.scss'],
	providers: [FormViewerService]
})
export class BillingFormComponent implements OnInit, OnDestroy {
	public formViewer: FormViewerComponent;
	public fields = formFields;
	public billingMode = "LEGAL"; // LEGAL or INDIVIDUAL
	public invoiceProfiles = [];
	public loading = false;
	public searchFromGroup: FormGroup = null;
    @Input('billingData') billingData: any;

	get isInvoiceProfiles(): boolean {
		return this.invoiceProfiles.length > 0
	}

	get creaNewProfile(){
		if(!this.searchFromGroup) return false;
		const controll = this.searchFromGroup.get('create_new_profile');
		return controll && controll.value
	}

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		private _trnService: TrenitaliaService,
		public formViewerService: FormViewerService,
		private datasetService: DatasetService,
		public matDialog: MatDialog,
		public formBuilder: FormBuilder
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this.searchFromGroup = this.formBuilder.group({
			p_iva_search: this.formBuilder.control(null),
			create_new_profile: this.formBuilder.control(false)
		});

		this.searchFromGroup.get('create_new_profile').valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			if(this.creaNewProfile){
				this.invoiceProfiles = [];
			}
		});

		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values()).filter(field => !['p_iva_search', 'create_new_profile'].includes(field.key))
		});

		this.formViewerService.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(v => {
			this.updateCheckoutData();
		});
        if(this.billingData && this.billingData.personType=='LEGAL'){
            this.formViewerService.setValues(
                {
                    personType: _.get(this.billingData, 'personType'),
                    profile_name: _.get(this.billingData, 'name'),
                    id_invoice_profile: _.get(this.billingData, 'id.id',null),
                    business_name: _.get(this.billingData, 'businessName'),
                    p_iva: _.get(this.billingData, 'vatNumber'),
                    corporate_tax_code: _.get(this.billingData, 'corporate_tax_code'),
                    country: _.get(this.billingData, 'address.country'),
                    province: _.get(this.billingData, 'address.province'),
                    city: _.get(this.billingData, 'address.city'),
                    cap: _.get(this.billingData, 'address.postalCode'),
                    address: _.get(this.billingData, 'address.address'),
                    recipient_code: _.get(this.billingData, 'recipient_code'),
                    pec: _.get(this.billingData, 'certifiedMail'),
                }

            );
            if(_.get(this.billingData, 'id',false)){
                this.searchFromGroup.get('create_new_profile').setValue(false);
            }
        }
	}

	updateCheckoutData() {
		// set this.checkoutData.invoiceProfile
		// is sufficient the id if just exists
		const formData = this.formViewerService.getFormData()
		this._trnService.checkoutData.value.billingFormValid = this.formViewerService.formGroup.valid;
		this._trnService.checkoutData.next(Object.assign(this._trnService.checkoutData.getValue(), {
			billingFormValid: this.formViewerService.formGroup.valid,
			billingFormData: formData,
		}));
	}

	onSearchProfile() {
		const vatNumber = this.searchFromGroup.get('p_iva_search').value;
		if (!vatNumber) return;
		this.loading = true;
		return this.datasetService.post('/dataset/trenitalia/command/search_invoice_profiles', {vatNumber})
		.pipe(takeUntil(this._unsubscribeAll))
		.pipe(
			catchError((err) => {
				this.matDialog
				.open(TextDialogComponent, {
						data: {
							title: 'Attenzione',
							contentMessage: err.message
						}
				});
				return EMPTY;
			}),
			exhaustMap((response: any) => {
				if (!response.length) return EMPTY
				this.invoiceProfiles = response.slice(0,50)
				return of(response)
			})
		)
		.subscribe({
			next: resp => {
				// Disable input create new profile
				this.searchFromGroup.get('create_new_profile').setValue(false);
				this.loading = false;
			},
			error: response => {
				// todo: error handler
				this.loading = false;
			}
		});
	}

	onSelectInvoiceProfile(event: MatSelectChange): void {
		const profile = event.value

		const mapKey = {
			'id': 'id_invoice_profile',
			'name': 'profile_name', 
			'businessName': 'business_name',
			'vatNumber': 'p_iva',
			'companyFiscalCode': 'corporate_tax_code',
			'address.country': 'country',
			'address.province': 'province',
			'address.city': 'city',
			'address.postalCode': 'cap',
			'address.address': 'address',
			'recipientCode': 'recipient_code',
			'certifiedMail': 'pec',
		};

		for(const [profileKey, fieldKey] of Object.entries(mapKey)){
			const control = this.formViewerService.getFieldControl(fieldKey);
			if(!control) continue;
			control.setValue(_.get(profile, profileKey));
			//control.disable();
		}
	}

	resetForm(): void {
		const fieldsKey = [
			'id_invoice_profile',
			'profile_name', 
			'business_name',
			'p_iva',
			'corporate_tax_code',
			'country',
			'province',
			'city',
			'cap',
			'address',
			'recipient_code',
			'pec',
		];

		for(const fieldKey of fieldsKey){
			const control = this.formViewerService.getFieldControl(fieldKey);
			if(!control) continue;
			control.setValue(null);
			control.enable();
		}
	}

	onChangeBillingMode(typeMode: string): void {
		this.billingMode = typeMode
	}
	
	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
