import * as tslib_1 from "tslib";
import { QueryList, OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
var ProductAdvancedBlockStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductAdvancedBlockStepComponent, _super);
    function ProductAdvancedBlockStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        return _this;
    }
    ProductAdvancedBlockStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            var e_1, _a;
            _this.blockFilters = [];
            _this.enableBlocks = [];
            try {
                for (var _b = tslib_1.__values(_this.stepConfig.options.blocks), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var blockConfig = _c.value;
                    var blockFilter = {};
                    if (blockConfig.filters) {
                        blockFilter = blockConfig.filters(_this, _this.datasetRS.record.value);
                    }
                    _this.blockFilters.push(blockFilter);
                    if (blockConfig.showIf) {
                        _this.enableBlocks.push(blockConfig.showIf(record));
                    }
                    else {
                        _this.enableBlocks.push(true);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    ProductAdvancedBlockStepComponent.prototype.onCreatedInBlock = function (blockConfig) {
        var _this = this;
        if (blockConfig.updateParent) {
            this.datasetRS.reload()
                .subscribe(function () {
                var block = _this.listBlocks.find(function (elementRef) {
                    return elementRef.datasetCode === blockConfig.datasetCode;
                });
                if (block)
                    block.reload();
            });
        }
    };
    ProductAdvancedBlockStepComponent.prototype.onDatasetEvent = function (event) {
        if (event.eventName === DatasetEvents.CREATED)
            return;
        this.datasetRS.reload();
    };
    return ProductAdvancedBlockStepComponent;
}(BaseStepViewComponent));
export { ProductAdvancedBlockStepComponent };
