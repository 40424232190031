import {ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('thumbnail_image', {
	key: 'code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		let html = '';
		if(record && record.thumbnail_image){
			html += '<img width="100" src="' + record.thumbnail_image + '">';
		}
		return html;
	}
});

fieldsMap.set('tour_name', {
	key: 'name',
	name: 'Nome',
	valueType: ValueTypes.STRING
});
fieldsMap.set('museum_name', {
	key: 'museumData.name',
	name: 'Museo',
	valueType: ValueTypes.STRING
});



fieldsMap.set('from_price', {
	key: 'from_price_display',
	name: 'A partire da',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML
});
fieldsMap.set('date_end', {
	key: 'dateEnd',
	name: 'Data Fine',
	valueType: ValueTypes.STRING
});

export {fieldsMap};
