<ng-template #stationTemplate let-name="name" let-date="date" let-diff="diff">
	<div class="flex-col flex-gap-8" style="max-width:200px">
		<span class="text-color-black font-size-14 font-weight-500">{{name}}</span>
		<span class="text-color-light-blue font-size-18 font-weight-600">{{date | date:'HH:mm'}}
			<span *ngIf="diff > 0"><sup>+{{diff}}</sup></span>
		</span>
	</div>
</ng-template>

<div style="width: 100%" class="cursor-pointer" (click)="onSelect.emit()">
	<trenitalia-train-breadcrumbs [trains]="travelSolution.trains"></trenitalia-train-breadcrumbs>
	<div class="flex-row items-start justify-between mt-16">
		<div class="flex-row flex items-center justify-between flex-gap-16">
			<ng-container [ngTemplateOutlet]="stationTemplate" [ngTemplateOutletContext]="{name: departureStationName, date: travelSolution.departure, diff: dayDiffDeparture}"></ng-container>
			<div class="flex text-center durations flex-row items-center flex-gap-8">
				<div class="duration-sign flex"></div>
				<div class="travel-time text-color-black font-size-14">
					<span class="text-color-black font-size-14 font-weight-600">{{travelSolution.duration}}</span>
					<br/>
					<small *ngIf="travelSolution.trains.length>1">
						{{travelSolution.trains.length - 1}} cambi
					</small>
				</div>
				<div class="duration-sign flex"></div>
			</div>
			<ng-container [ngTemplateOutlet]="stationTemplate" [ngTemplateOutletContext]="{name: arrivalStationName, date: travelSolution.arrival, diff: dayDiffArrival}"></ng-container>
		</div>
		<div class="p-2 text-right flex-basis-5">
			<button class="details" mat-button (click)="onDetails.emit();$event.stopPropagation();">
				<span class="text-color-dark-blue">Dettagli</span>
				<mat-icon class="text-color-light-blue ml-4">info</mat-icon>
			</button>
		</div>
		<div class="p-2 text-right flex-basis-5">
			<ng-container *ngIf="checkoutDataMode == 'edit-date-time'">
				<button mat-flat-button disabled color="accent" *ngIf="isSelected">
					Selezionato
				</button>
				<button mat-button  *ngIf="!isSelected" (click)="onSelect.emit();$event.stopPropagation();">
					Seleziona
				</button>
			</ng-container>
			<ng-container *ngIf="checkoutDataMode != 'edit-date-time' && !isCarnet">
				<div *ngIf="travelSolution.saleable" >
					<div class="flex-row justify-end flex-gap-14 items-center" *ngIf="travelSolution.bestPrice">
						<div class="flex flex-col justify-end text-right text-color-black flex-gap-4">
							<span class="font-size-12 font-weight-600">A partire da</span>
							<span class="font-size-14 font-weight-500">{{travelSolution.bestPrice.amount | currency:'EUR':'symbol':'1.2-2'}}</span>
						</div>
						<mat-icon class="text-color-black">keyboard_arrow_down</mat-icon>
					</div>
					<ng-container *ngIf="!travelSolution.bestPrice">
						<button mat-button *ngIf="!isSelected" (click)="onSelect.emit();$event.stopPropagation()">
							Seleziona <mat-icon>keyboard_arrow_down</mat-icon>
						</button>
					</ng-container>
				</div>
				<div *ngIf="!travelSolution.saleable">
					Non selezionabile
				</div>
			</ng-container>
			<div *ngIf="checkoutDataMode != 'edit-date-time' && isCarnet && !travelSolution.bestPrice">
				<button mat-flat-button disabled color="accent" *ngIf="isSelected">
					Selezionato
				</button>
				<button mat-button *ngIf="!isSelected" (click)="onSelect.emit();$event.stopPropagation()">
					Seleziona <mat-icon>keyboard_arrow_down</mat-icon>
				</button>
			</div>
		</div>
	</div>
</div>