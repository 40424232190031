<div id="calendar" class="page-layout simple fullwidth">

	<dataset-calendar-header-view
		(viewDateChange)='onViewDateChanged($event)'
		(addClicked)='onAdd()'
	></dataset-calendar-header-view>
	
	<div *ngIf='datasetACS.loading.value' class='mx-24'>
		<mat-progress-bar  color="accent" mode="indeterminate"></mat-progress-bar>
	</div>

	<!-- EVENT TITLE TEMPLATES -->
	<ng-template #customEventTitleTemplate let-day="day" let-event='event' let-locale="locale">
		<ng-container [ngSwitch]='_calendarService.calendarConfig.templateCodes.eventTitleTemplate'>
			<ng-container *ngSwitchCase='"simple-text"'>
				<span class='ml-4 h4'>
					<ng-container [ngSwitch]='event.type'>
						<ng-container *ngSwitchCase='"trip_resource_staff_assignments"'>
							<span *ngIf='event.meta.fullName'>{{event.meta.fullName}}</span>
						</ng-container>
						<ng-container *ngSwitchCase='"trip_resource_allotments"'>
							<span *ngIf='event.meta.resource_allotment && event.meta.resource_allotment.quantity >= 0'>
								[{{event.meta.resource_allotment.quantity}}]
							</span>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<span>{{event.title || "Evento"}}</span>
						</ng-container>
					</ng-container>
				</span>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<span style='display:none;'>TEMPLATE customEventTitleTemplate missed for {{_calendarService.calendarConfig.templateCodes.eventTitleTemplate}}</span>
			</ng-container>
		</ng-container>
	</ng-template>
	<!-- / EVENT TITLE TEMPLATES -->

	<!-- EVENT ACTIONS TEMPLATES -->
	<ng-template #customEventActionsTemplate let-event='event'>
		<ng-container [ngSwitch]='_calendarService.calendarConfig.templateCodes.eventActionsTemplate'>
			<ng-container *ngSwitchCase='"default_actions"'>
				<span class="cal-event-actions">
					<ng-container *ngFor="let eventAction of event.actions">
						<a
							*ngIf="eventAction.routerLink"
							[routerLink]="eventAction.routerLink"
							mat-icon-button
							[ngClass]="eventAction.cssClass"
							class="cal-event-action"
						>
							<mat-icon>{{ eventAction.icon }}</mat-icon>
						</a>
						<button
							*ngIf="!eventAction.routerLink"
							mat-icon-button
							[color]='eventAction.color'
							[ngClass]="eventAction.cssClass"
							class="cal-event-action"
							(click)="eventAction.onClick({ event: event, component: this })"
						>
							<mat-icon>{{ eventAction.icon }}</mat-icon>
						</button>
					</ng-container>
				</span>
			</ng-container>
			<ng-container *ngSwitchCase='"no-actions"'>
				<span class="cal-event-actions"></span>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<span style='display:none;'>TEMPLATE customEventActionsTemplate missed for {{_calendarService.calendarConfig.templateCodes.eventTitleTemplate}}</span>
			</ng-container>
		</ng-container>
	</ng-template>
	<!-- /EVENT ACTIONS TEMPLATES -->
	<ng-template #emptyTemplate></ng-template>

	<!-- CELL TEMPLATES -->
	<ng-template
	  #customMonthCellTemplate
	  let-day="day"
	  let-openDay="openDay"
	  let-locale="locale"
	  let-tooltipPlacement="tooltipPlacement"
	  let-highlightDay="highlightDay"
	  let-unhighlightDay="unhighlightDay"
	  let-eventClicked="eventClicked"
	  let-tooltipTemplate="tooltipTemplate"
	  let-tooltipAppendToBody="tooltipAppendToBody"
	  let-trackByEventId="trackByEventId"
	  let-validateDrag="validateDrag"
	>
		<ng-container [ngSwitch]='_calendarService.calendarConfig.templateCodes.monthCellTemplate'>
			<ng-container *ngSwitchCase='"text"'>
				<div class="cal-cell-top">
					<span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber':locale}}</span>
				</div>
				<div class="cal-events" *ngIf="day.events.length > 0">
					<div 
						(mwlClick)="eventClicked.emit({ event: event })"
						(mouseenter)="highlightDay.emit({ event: event })"
						(mouseleave)="unhighlightDay.emit({ event: event })"
						class="cal-event-container"
						[ngClass]="event?.cssClass"
						*ngFor="let event of day.events; trackBy: trackByEventId" 
						fxLayout='column' 
						fxLayoutAlign='center center'>
						<ng-container [ngSwitch]='event.type'>
							<ng-container *ngSwitchCase='"trip_resource_staff_assignments"'>
								<span class='single-line' *ngIf='event.meta.description'>{{event.meta.description}}</span>
								<span class='single-line' *ngIf='!event.meta.description'>{{event.title || "Evento"}}</span>
							</ng-container>
							<ng-container *ngSwitchCase='"trip_resource_allotments"'>
								<span *ngIf='event.meta.resource_allotment && event.meta.resource_allotment.quantity >= 0'>
									[{{event.meta.resource_allotment.quantity}}]
								</span>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<span>{{event.title || "Evento"}}</span>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<span style='display:none;'>TEMPLATE customMonthCellTemplate missed for {{_calendarService.calendarConfig.templateCodes.monthCellTemplate}}</span>
			</ng-container>
		</ng-container>
	</ng-template>
	<!-- / CELL TEMPLATES 	-->

	<!-- CONTENT -->
	<div class="content" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'50px'}}">
		<div [ngSwitch]="_calendarService.view">
			<mwl-calendar-month-view
				*ngSwitchCase="'month'"
				[viewDate]="_calendarService.viewDate"
				[events]="events"
				[refresh]="datasetACS.loading"
				[activeDayIsOpen]="activeDayIsOpen"
				[eventTitleTemplate]='_calendarService.calendarConfig.templateCodes.eventTitleTemplate && customEventTitleTemplate'
				[eventActionsTemplate]='_calendarService.calendarConfig.templateCodes.eventActionsTemplate && customEventActionsTemplate'
				[cellTemplate]='_calendarService.calendarConfig.templateCodes.monthCellTemplate && customMonthCellTemplate'
				[openDayEventsTemplate]='_calendarService.calendarConfig.templateCodes.monthOpenDayEventsTemplate && emptyTemplate'
				(dayClicked)="dayClicked($event.day)"
				(eventClicked)="eventClick($event.event)"
				(eventTimesChanged)="eventTimesChanged($event)"
				(beforeViewRender)="beforeMonthViewRender($event)">
			</mwl-calendar-month-view>
			<mwl-calendar-week-view
				*ngSwitchCase="'week'"
				[eventTitleTemplate]='_calendarService.calendarConfig.templateCodes.eventTitleTemplate && customEventTitleTemplate'
				[eventActionsTemplate]='_calendarService.calendarConfig.templateCodes.eventActionsTemplate && customEventActionsTemplate'
				[viewDate]="_calendarService.viewDate"
				(viewDateChange)="onViewDateChanged($event)"
				[events]="events"
				[refresh]="datasetACS.loading"
				(dayClicked)="dayClicked($event.day)"
				(eventClicked)="eventClick($event.event)"
				(eventTimesChanged)="eventTimesChanged($event)">
			</mwl-calendar-week-view>
			<mwl-calendar-day-view
				*ngSwitchCase="'day'"
				[viewDate]="_calendarService.viewDate"
				[eventTitleTemplate]='_calendarService.calendarConfig.templateCodes.eventTitleTemplate && customEventTitleTemplate'
				[eventActionsTemplate]='_calendarService.calendarConfig.templateCodes.eventActionsTemplate && customEventActionsTemplate'
				(viewDateChange)="onViewDateChanged($event)"
				[events]="events"
				[refresh]="datasetACS.loading"
				(dayClicked)="dayClicked($event.day)"
				(eventClicked)="eventClick($event.event)"
				(eventTimesChanged)="eventTimesChanged($event)">
			</mwl-calendar-day-view>
		</div>
	</div>
	<!-- / CONTENT -->
</div>
