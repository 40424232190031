import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';

@Component({
	selector   : 'trip-package-step-3',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ 
		DatasetActionContainerService, 
		DatasetRecordService, 
		DatasetMultilanguageContainerService 
	]
})
export class TripPackageStep3Component extends BaseStepViewComponent implements OnInit{
	
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;
	formErrors: any;
	carouselBlockConfigs: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService){
		// parent constructor
		super(datasetACS, datasetNavigatorService);
		/**
		 * carousel block configs
		 */
		this.carouselBlockConfigs = {
			datasetCode: 'trip_package_language_contents_carousel_images',
			parentDatasetCode: 'trip_package_language_contents',
			parentForeignKey: 'trip_package_language_content_id'
		};
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.datasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			});
			this.datasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
		
	}
}
