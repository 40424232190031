import { Component, Input, NgZone, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { fieldsMap } from './form.fields';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { ProductBaseInfoStepComponent } from '../product-base-info-step/step.component';

@Component({
	selector   : 'form-fixed-allotment',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [FormViewerService]
})
export class ProductFixedAllotmentComponent extends ProductBaseInfoStepComponent implements OnInit {
	
	// @ViewChild('formViewer') formViewer: FormViewerComponent;

	public fileterFields = fieldsMap;
	public formErrors: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		protected formBuilder: FormBuilder,
		protected zone: NgZone,
		protected stepService: StepService,
		public formViewerService: FormViewerService){
		/** init parent constructor */
		super(
			appService, 
			datasetRS, 
			datasetACS, 
			datasetService, 
			datasetNavigatorService, 
			matDialog, 
			formBuilder,
			zone,
			stepService,
			formViewerService
		);
	}

	onSave(): void{
		super.onSave(false);
	}

	ngOnInit(): void {
		super.ngOnInit();
		
		this.stepService.setStepState({
			dataset_code: 'products',
			form_code: 'product-advanced-blocks-step',
			record_id: this.datasetRS.recordId,
			status: 1
		});
	}
}
