import {InputTypes, ValueTypes, IFieldDefinition} from 'app/interfaces';
import { FormGroup } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { showIfEAgency, skipIfNotEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';
import { I } from '@angular/cdk/keycodes';
import {
	getDisabledStaticValueCustomFields,
	hideIfCustomDisableFields, showIfCustomAddFields
} from "../../commons/shared-field-configs/custom_field.field";

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	printTransform(value: any): string{
		if(value === 'draft') return 'Preventivo';
		if(value === 'confirmed') return 'Confermata';
		if(value === 'unconfirmed') return 'Non Confermata';
		if(value === 'optional') return 'Opzionale (in scadenza)';
		if(value === 'canceled') return 'Cancellata';
		if(value === 'expired') return 'Scaduta';
		return value;
	}
});

fieldsMap.set('total_amount', {
	key: 'total_amount',
	name: 'Ammontare',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('participant_count', {
	key: 'participant_count',
	name: 'Partecipanti',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('booking_date', {
	key: 'booking_date',
	name: 'Data Prenotazione',
	valueType: ValueTypes.DATETIME,
	inputReadOnly: true,
	inputConfig: {
		type: InputTypes.DATETIME,
		readOnly: true,
		customCssClass: 'booking_date'
	},
	defaultValue(): any{
		return Date.now();
	},
	readonlyIf(record: any): boolean {
		if (!record || !record.allowed_actions) return true;
		return !record.allowed_actions.includes('edit_booking_date');
	}
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.TEXTAREA,
	appearance: 'outline',
	inputConfig: {
		type: InputTypes.TEXTAREA,
		customCssClass: 'booking_note'
	}
});

fieldsMap.set('disable_export_sale_operation', {
	key: 'disable_export_sale_operation',
	name: 'Non esportare in eAgency',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.CHECKBOX,
		customCssClass: 'disable-export-sale-operations',
	},
	showIf: showIfEAgency,
	skipIf: skipIfNotEAgency,
	titleTooltip: 'Se abilitato non crea la pratica per questa prenotazione'
});

function skipIfDisableExport(record, dataContainerService){
	if(!dataContainerService) return true;
	if(dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.eagency.enabled') == true) return true;
	return dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.seta.enabled') != true;
}

fieldsMap.set('disable_export', {
	key: 'disable_export_sale_operation',
	name: 'Non esportare',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.CHECKBOX,
		customCssClass: 'disable-export-sale-operations',
	},
	showIf(record, dataContainerService){return !skipIfDisableExport(record, dataContainerService) && !hideIfCustomDisableFields('disable_export_sale_operation','booking',dataContainerService);},
	skipIf(record, dataContainerService){
		return skipIfDisableExport(record, dataContainerService);
	},
	titleTooltip: 'Se abilitato non crea la pratica per questa prenotazione'
});

/*fieldsMap.set('twilio_sms', {
	key: 'twilio_sms',
	name: 'Invia notifica via SMS',
	inputType: InputTypes.CHECKBOX,
	valueType: ValueTypes.PROPERTY,
	titleTooltip: 'Se configurato twilio e abilitato invia notifica SMS per questa prenotazione',
	readonlyIf(record: any): boolean{
		return false;
	}
});*/

fieldsMap.set('entity_customer_id', {
	key: 'entity_customer_id',
	name: 'Cliente',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_customers',
	appearance: 'outline',
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		selectDialogConfig: {
			dialogConfig: {
				datasetCode: "", // leave empty
				selectionBtnIcon: 'add',
				tableStyle: 'style-1 selectable',
				filterStyle: 'style-1'
			},
			matDialogConfig: {
				panelClass: 'select-dataset-record-dialog',
				// width: '800px'
			}
		}
	},
	remoteOptions: {
		limit: 5,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return referenceRecord.entity.description;
	},
	extra: {
		onSet(form: FormGroup, record: any): void{
			if(!record) return;
			if(record.payment_type_id){
				const paymentTypeIdController = form.get('payment_type_id');
				if(paymentTypeIdController) paymentTypeIdController.setValue(record.payment_type_id);
			}

			const discountRuleListIdFormController = form.get('discount_rule_list_id');
			if(discountRuleListIdFormController){
				discountRuleListIdFormController.setValue(record.discount_rule_list_id);
			}
		}
	},
	skipIf(record: any, dataContainerService: IDataContainerService) {
		return hideIfCustomDisableFields('entity_customer_id','booking',dataContainerService)
	},
	/*defaultValue(dataContainerService: IDataContainerService) {
		return getDisabledStaticValueCustomFields('entity_customer_id','booking',dataContainerService)
	}*/
});

fieldsMap.set('entity_id', {
	key: 'entity_id',
	name: 'Intestatario',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entities',
	appearance: 'outline',
	inputRequired:true,
	remoteOptions: {
		limit: 5,
		getLabel(option): any{
			if(!option) return '';
			if(typeof(option) === 'string') return option;
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			if(typeof(option) === 'string') return option;
			return option.id;
		},
		supportCreate: true
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return referenceRecord.description;
	},
	extra: {
		onSet(form: FormGroup, record: any): void{
			if(!record || typeof(record) === 'string') return;
			const entityPrimaryEmailFormController = form.get('entity_primary_email');
			if(entityPrimaryEmailFormController){
				entityPrimaryEmailFormController.setValue(record.primary_email);
			}
			const entityPhoneFormController = form.get('tel');
			if (entityPhoneFormController) entityPhoneFormController.setValue(record.phone_number);

			const entityBirthLocationFormController = form.get('entity_birth_location');
			if (entityBirthLocationFormController) entityBirthLocationFormController.setValue(record.birth_location);

			const entityBirthDayFormController = form.get('entity_birth_day');
			if (entityBirthDayFormController) entityBirthDayFormController.setValue(record.birth_day);

			if(record.primary_address){
				const entityNationCodeFormController = form.get('entity_nation_code');
				if (entityNationCodeFormController) entityNationCodeFormController.setValue(record.primary_address.nation_code);

				const entityStreetFormController = form.get('entity_street');
				if (entityStreetFormController) entityStreetFormController.setValue(record.primary_address.street);

				const entityZipCodeFormController = form.get('entity_zip_code');
				if (entityZipCodeFormController) entityZipCodeFormController.setValue(record.primary_address.zip_code);

				const entityCityFormController = form.get('entity_city');
				if (entityCityFormController) entityCityFormController.setValue(record.primary_address.city);
			}
		},
		onInit(form: FormGroup, record: any): void{
			const entityPrimaryEmailFormController = form.get('entity_primary_email');
			if(!entityPrimaryEmailFormController) return;
			if(typeof(record) === 'string') return;
			if(!record) return;
			entityPrimaryEmailFormController.setValue(record.primary_email);

			const entityPhoneFormController = form.get('tel');
			if (entityPhoneFormController) entityPhoneFormController.setValue(record.phone_number);
		}
	}
});

fieldsMap.set('entity_primary_email', {
	key: 'entity_primary_email',
	name: 'Email Intestatario',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.STRING,
	datasetCode: 'entities',
	appearance: 'outline',
	inputRequired:true,
	remoteOptions: {
		limit: 5,
		getLabel(option): any{
			if(!option) return '';
			if(typeof(option) === 'string') return option;
			return '(' + option.code + ') ' + option.primary_email;
		},
		getValue(option): any{
			if(!option) return;
			if(typeof(option) === 'string') return option;
			return option.primary_email;
		},
		getParams(dataContainerService: IDataContainerService, record: any, options?: any): any{
			const params = {
				column_name: 'primary_email',
				sortBy: 'description'
			};
			if(options === 'init' && record && record.entity_id){
				params['column_value'] = record.entity_id;
				params['column_name'] = 'id';
			}
			return params;
		},
		supportCreate: true,
		createHint: 'Questa email verrà usata come email principale dell\'intestatario'
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(record && record.entity){
			return record.entity.primary_email;
		}
		if(!referenceRecord) return '---';
		return referenceRecord.primary_email;
	},
	extra: {
		onSet(form: FormGroup, record: any, reason?: string): void{
			if(!record || typeof(record) === 'string') return;
			if(reason === 'init') return;
			const entityFormController = form.get('entity_id');
			if(entityFormController){
				if(entityFormController.value !== record.id){
					entityFormController.setValue(record.id);
				}
			}
			const entityPhoneFormController = form.get('tel');
			if (entityPhoneFormController) entityPhoneFormController.setValue(record.phone_number);
		}
	}
});

fieldsMap.set('tel', {
	key: 'tel',
	name: 'Telefono',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	appearance: 'outline'
});

fieldsMap.set('discount_rule_list_id', {
	key: 'discount_rule_list_id',
	name: 'Listino',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Listino applicato',
	datasetCode: 'discount_rule_lists',
	appearance: 'outline',
	remoteOptions: {
		limit: 100,
		getParams(): any{
			return { sortBy: 'code', sortDirection: 'asc' };
		},
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return referenceRecord.description;
	},
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		if(!dataContainerService) return false;
		return hideIfCustomDisableFields('discount_rule_list_id','booking',dataContainerService);
	}
});

fieldsMap.set('payment_type_id', {
	key: 'payment_type_id',
	name: 'Tipo di pagamento',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	inputHint: 'Seleziona il tipo di pagamento',
	datasetCode: 'payment_types',
	appearance: 'outline',
	remoteOptions: {
		limit: 100,
		getParams(): any{
			return { sortBy: 'code', sortDirection: 'asc' };
		},
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return referenceRecord.name;
	},
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		if(!dataContainerService) return false;
		return hideIfCustomDisableFields('payment_type_id','booking',dataContainerService);
	}
});

fieldsMap.set('payment_method_code', {
	key: 'payment_method_code',
	name: 'Metodo di pagamento',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: [
		{
			label: 'Contante',
			value: 'CONT'
		},
		{
			label: 'Cartasi',
			value: 'CARTASI'
		},
		{
			label: 'Setefi',
			value: 'SETEFI'
		}
	],
	defaultValue(){
		return 'CONT';
	}
});

fieldsMap.set('entity_birth_location', {
	key: 'entity_birth_location',
	name: 'Luogo di nascita',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired:true,
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('entity_birth_day', {
	key: 'entity_birth_day',
	name: 'Data di nascita',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	inputRequired:true,
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('entity_nation_code', {
	key: 'entity_nation_code',
	name: 'Nazione',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'nations',
	inputHint: 'Seleziona la nazione',
	inputRequired:true,
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	},
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('entity_street', {
	key: 'entity_street',
	name: 'Indirizzo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired:true,
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('entity_zip_code', {
	key: 'entity_zip_code',
	name: 'CAP',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired:true,
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('entity_city', {
	key: 'entity_city',
	name: 'Città',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired:true,
	skipIf(record: any, dataContainerService?: IDataContainerService) {
		const fieldsEnabled = dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_required_fields');
		if (fieldsEnabled) {
			return !fieldsEnabled.includes(this.key);
		}
	}
});

fieldsMap.set('booking_date_from', {
	key: 'booking_date_from',
	name: 'Prenotazioni Dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('booking_date_to', {
	key: 'booking_date_to',
	name: 'Prenotazioni al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('booking_code', {
	name: 'Codice',
	key: 'booking_code',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.TEXT
});

fieldsMap.set('origin_channel_voucher_code', {
	name: 'Numero Voucher Operatore',
	key: 'origin_channel_voucher_code',
	valueType: ValueTypes.PROPERTY,
	inputType: InputTypes.TEXT,
	appearance: 'outline',
	showIf(record: any, dataContainerService?: IDataContainerService) {
		if(!dataContainerService) return false;
		return !hideIfCustomDisableFields('origin_channel_voucher_code','booking',dataContainerService);
	}

});

fieldsMap.set('online_order_id', {
	name: 'Id Ordine Online',
	key: 'origin_channel_booking_id',
	appearance: 'outline',
	valueType: ValueTypes.PROPERTY,
	inputConfig: {
		type: InputTypes.READONLY,
		readOnly: true
	},
	showIf(record): boolean{
		return false;
	},
	getDisplayValue(record: any): string{
		if(!record) return;
		if(!record.properties || !record.properties.origin_channel_booking_id) return;
		const value = record.properties.origin_channel_booking_id.value;
		if(typeof(value) === 'string' && value.includes(':')){
			return value.split(':')[0];
		}
		return value;
	}
});

fieldsMap.set('trip_package_id', cloneFieldDefinition(tripPackageIdField));
fieldsMap.set('trip_resource_id', cloneFieldDefinition(tripResourceIdField));

fieldsMap.set('start_day_from', {
	key: 'start_day_from',
	name: 'Partenza Dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('start_day_to', {
	key: 'start_day_to',
	name: 'Partenze fino al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

export {fieldsMap};