<ng-template #blockTemplate let-blockConfig='blockConfig' let-inTab='inTab' let-index='index'>
	<ng-container [ngSwitch]='blockConfig.type'>
		<dataset-list
			*ngSwitchCase='"list"'
			#listBlocks
			[datasetCode]='blockConfig.datasetCode'
			[filters]='blockFilters[index]'
			[parentDatasetRS]='datasetRS'
			[parentDatasetACS]='datasetACS'
			[conditionalShowPaginationToolbar]='true'
			[showSearchBar]='false'
			[showTitle]='!inTab'
			[elevate]='false'
			(created)='onCreatedInBlock(blockConfig)'
		></dataset-list>
		<document-file-block
			*ngSwitchCase='"document_file"'
			[title]='!inTab && blockConfig.title'
			[record]='datasetRS.record.value'
			[documentFileKey]='blockConfig.documentFileKey'
			[imageOptions]='blockConfig.imageOptions'
			[type]='"image"'
			[uploadType]='blockConfig.uploadType'
			[uploadRoute]='blockConfig.uploadRoute'
			[getUploadDataFun]='blockConfig.getUploadData'
			(changed)='datasetRS.reload()'
		></document-file-block>
		<div *ngSwitchDefault>
			<p>{{'Blocco non configurato' | translate}}: {{blockConfig.type}}</p>
		</div>
	</ng-container>
</ng-template>

<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'
	></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<ng-container *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è ancora presente!' | translate}}</p>
			<button mat-stroked-button color='primary' (click)='goPrevStep()'>Inserisci prima le descrizioni in lingua</button>
		</ng-container>
		<ng-container *ngIf='datasetRS.record.value && datasetRS.record.value["lang"] === appService.language.value'>
			<mat-tab-group *ngIf='stepConfig.options.blocks.length > 1'>
				<ng-container *ngFor='let blockConfig of stepConfig.options.blocks;let i = index;'>
					<mat-tab>
						<ng-template mat-tab-label>{{blockConfig.title}}</ng-template>
						<ng-template matTabContent>
							<ng-container 
								[ngTemplateOutlet]='blockTemplate' 
								[ngTemplateOutletContext]='{blockConfig: blockConfig, inTab: true, index: i}'
							></ng-container>
						</ng-template>
					</mat-tab>
				</ng-container>
			</mat-tab-group>
			<ng-container *ngIf='stepConfig.options.blocks.length == 1'>
				<ng-container *ngFor='let blockConfig of stepConfig.options.blocks;let i = index;'>
					<ng-container 
						[ngTemplateOutlet]='blockTemplate' 
						[ngTemplateOutletContext]='{blockConfig: blockConfig, inTab: false, index: i}'
					></ng-container>
				</ng-container>
			</ng-container>
			
		</ng-container>
	</ng-container>
</div>