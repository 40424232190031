import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { eagencyFields } from '../../fields/accounting/eagency.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	eagencyFields.get('enabled'),
    eagencyFields.get('scenario')
];

const appForm: IFormConfig = { 
	editTitle: 'Modifica Configurazione Eagency',
	fields: formFields 
};

export default appForm;
