import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';

export const TripBookingPackageConfig: IDatasetConfig = {
	name: 'trip_booking_packages',
	title: 'Partenze',
	singleTitle: 'Partenza',
	ajaxDatasetCode: 'trip_booking_packages',
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	fetchConfig: {
		with_attributes: 'total_amount_net,total_amount'
	},
	formConfigsMap: {},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG
		},
		create: {
			openType: DatasetActionOpenType.DIALOG
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG
		},
		list: {
			openType: DatasetActionOpenType.PAGE
		}
	}
};
