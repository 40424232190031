<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<mat-checkbox value='1' 
		[ngClass]='inputService?.formInputDefinition?.inputConfig?.customCssClass'
		[formControlName]='inputService.formControlKey'
		[matTooltip]='inputService.formInputDefinition.titleIcon ? null : inputService.formInputDefinition.titleTooltip'
		[disabled]='inputService.inputConfig.readOnly'>
		<span>
			{{inputService.formInputDefinition.name | translate}} 
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
		</span>
	</mat-checkbox>
</ng-container>