import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subject, forkJoin, of, Subscription, BehaviorSubject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { IPaginationResponse } from 'app/interfaces';
import { CalendarEventModel } from './event.model';
import * as moment from 'moment';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { forEachDay } from 'app/helpers/date.helper';
import { DomainFilterService } from 'app/services/domain-filter.service';

export interface IDayLoading{
	loading: boolean;
	subscription?: Subscription;
}

@Injectable()
export class CalendarService implements Resolve<any>
{
	events: CalendarEventModel[] = [];
	onEventsUpdated: Subject<any>;
	_viewDateChanged: Subject<Date>;
	loadingEvents: BehaviorSubject<boolean>;
	getEventSubscription: Subscription;
	filterParams: any = {};
	groupedEvents: any = {};
	groupedEventsCache: any = {};
	groupedEventsByHour: any[] = [];
	groupedEventsByHourCache: any = {};

	public activeDayIsOpenSubscriber: BehaviorSubject<any>;
	public dayLoading: {[key: string]: IDayLoading} = {};

	/**
	 * Constructor
	 *
	 * @param {HttpClient} _httpClient
	 */
	constructor(
		private appService: AppService,
		private _httpClient: HttpClient,
		private datasetNavigatorService: DatasetNavigatorService,
		private domainFilterService: DomainFilterService
	){
		// Set the defaults
		this.onEventsUpdated = new Subject();
		this._viewDateChanged = new Subject<Date>();
		this.activeDayIsOpenSubscriber = new BehaviorSubject<any>({opened: false});
		this.loadingEvents = new BehaviorSubject<any>(false);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Resolver
	 *
	 * @param {ActivatedRouteSnapshot} route
	 * @param {RouterStateSnapshot} state
	 * @returns {Observable<any> | Promise<any> | any}
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any{
		this.filterParams = this.getStoredFilterParams();
		return of({});
	}

	appendFilterHeaders(): HttpHeaders {
		if (this.domainFilterService.filterDomainId.value && this.domainFilterService.showFilterBar) {
			return new HttpHeaders({
				'X-Domain-Id': this.domainFilterService.filterDomainId.value
			});
		}
	}

	getEvents(from, to, params): Subject<any>{
		const result = new Subject();
		const dayParams = Object.assign({}, params);
		dayParams['start_from'] = from;
		dayParams['start_to'] = to;
		const daySubscription = this._httpClient.get<any[]>(this.appService.getBaseServerAddress() + '/calendar/event/actual_trips', {
			headers: this.appendFilterHeaders(),
			params: dayParams
		}).subscribe({
			next: response => {
				this.events = this.events.concat(response.map(item => {
					const calendarEvent = new CalendarEventModel(undefined, this.datasetNavigatorService);
					calendarEvent.fromActualTrip(item);
					return calendarEvent;
				}));
				this.onEventsUpdated.next(this.events);
				result.next(response);
				result.complete();
				forEachDay(from, to, day => {
					if(this.dayLoading[day]){
						this.dayLoading[day].loading = false;
						this.dayLoading[day].subscription = null;
					}
				}, 'Y-MM-DD');
			},
			error: response => {
				result.error(response);
				result.complete();
				forEachDay(from, to, day => {
					if(this.dayLoading[day]){
						this.dayLoading[day].loading = false;
						this.dayLoading[day].subscription = null;
					}
				}, 'Y-MM-DD');
			},
			complete: () => {
				this.groupData();
			}
		});

		forEachDay(from, to, day => {
			this.dayLoading[day] = { loading: true, subscription: daySubscription };
		}, 'Y-MM-DD');
		
		return result;
	}

	getDayEvents(day, params): Subject<any>{
		return this.getEvents(day, day, params);
	}

	getAllDaysEvents(options?: any): Subject<any>{
		const result = new Subject();
		const params: any = Object.assign({
			perPage: 200,
			sortBy: 'start_day|start_time',
			sortDirection: 'asc',
			package_active: 1,
			only_availables: 0,
			with_relations: 'package',
			with_attributes: 'staff_elements'
		}, this.filterParams);
		for (const key in params) {
			if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
			if(params[key] === undefined || params[key] === null) delete params[key];
		}
		let startFrom = moment.utc().startOf('month');
		let startTo = moment.utc().endOf('month');
		if(options){
			if(options.start) startFrom = options.start;
			if(options.end) startTo = options.end;
		}
		if(this.dayLoading){
			for (const key in this.dayLoading) {
				if (!Object.prototype.hasOwnProperty.call(this.dayLoading, key))continue;
				if(!this.dayLoading[key] || !this.dayLoading[key].subscription) continue;
				this.dayLoading[key].subscription.unsubscribe();
			}
			this.dayLoading = {};
		}
		this.events = [];
		this.groupedEvents = {};
		this.groupedEventsByHour = [];
		this.groupedEventsCache = {};
		this.groupedEventsByHourCache = {};

		if(
			(!params.filter_trip_categories || params.filter_trip_categories.length === 0 || params.filter_trip_categories === '[]') 
			&&
			(!params.filter_trip_packages || params.filter_trip_packages.length === 0 || params.filter_trip_packages === '[]') 
		){
			this.onEventsUpdated.next(this.events);
			this.storeFilterParams();
			result.next([]);
			result.complete();
			return result;
		}
		this.loadingEvents.next(true);
		const days = [];
		const arrayRequests: Subject<any>[] = [];
		const currentDate = startFrom.clone();
		while(currentDate <= startTo){
			const day = currentDate.format('Y-MM-DD');
			if(!days.includes(day)) days.push(day);
			currentDate.add(1, 'day');
		}
		const chunkSize = 5;
		const chunks = [];
		for (let index = 0; index < days.length; index += chunkSize) {
			let lastIndex = index + chunkSize - 1;
			if(lastIndex >= days.length) lastIndex = days.length - 1;
			chunks.push({
				from: days[index],
				to: days[lastIndex]
			});
		}
		if (params.filter_trip_categories && Array.isArray(params.filter_trip_categories)){
			params.filter_trip_categories = JSON.stringify(params.filter_trip_categories);
		} else if (params.filter_trip_packages && Array.isArray(params.filter_trip_packages)){
			params.filter_trip_packages = JSON.stringify(params.filter_trip_packages);
		}
		for (const chunk of chunks) {
			const dayRequest = this.getEvents(chunk.from, chunk.to, params);
			arrayRequests.push(dayRequest);
		}
		forkJoin(arrayRequests)
		.subscribe({
			next: response => {
				result.next(response);
				result.complete();
				this.loadingEvents.next(false);
				this.storeFilterParams();
			},
			error: response => {
				result.error(response);
				result.complete();
				this.loadingEvents.next(false);
			}
		});
			
		return result;
	}

	groupData(): void{
		for (let i = 0; i <= this.events.length; i++){
			const eventModel = <CalendarEventModel>this.events[i];
			if (!eventModel) return;

			const trip_package_id = eventModel.package.id;
			const dateStart = moment(eventModel.start);
			const dateIdx = dateStart.format('DDMMYY');
			/**
			 * group by package and date
			 */
			if (!this.groupedEvents[dateIdx]) this.groupedEvents[dateIdx] = {};
			if (!this.groupedEvents[dateIdx][trip_package_id]){
				this.groupedEvents[dateIdx][trip_package_id] = {
					trip_package_id: trip_package_id,
					trip_package_code: eventModel.package.code,
					trip_package_description: eventModel.package.description,
					events: []
				};
			}
			/**
			 * some cache
			 */
			if (!this.groupedEventsCache[eventModel.meta.actual_trip.id]){
				this.groupedEventsCache[eventModel.meta.actual_trip.id] = {};
				this.groupedEvents[dateIdx][trip_package_id].events.push(eventModel);
			}

			/**
			 * group by hour
			 */
			let hour = dateStart.format('H:mm');
			if (dateStart.minutes() < 30){
				hour = dateStart.format('H:00');
			} else if (dateStart.minutes() > 30){
				hour = dateStart.format('H:30');
			}
			if (!this.groupedEventsByHour[dateIdx]) this.groupedEventsByHour[dateIdx] = [];
			if (!this.groupedEventsByHour[dateIdx][hour]) this.groupedEventsByHour[dateIdx][hour] = [];
			/**
			 * push to array with some cache
			 */
			if (!this.groupedEventsByHourCache[eventModel.meta.actual_trip.id]){
				this.groupedEventsByHourCache[eventModel.meta.actual_trip.id] = {};
				this.groupedEventsByHour[dateIdx][hour].push(eventModel);
			}
		}
	}

	/**
	 * Update events
	 *
	 * @param events
	 * @returns {Promise<any>}
	 */
	updateEvents(): Promise<any>{
		return new Promise((resolve, reject) => {
			reject('TODO');
		});
	}

	storeFilterParams(): void{
		window.localStorage.setItem('calendar:actual_trips@filters', JSON.stringify(this.filterParams));
	}

	getStoredFilterParams(): any{
		const value = window.localStorage.getItem('calendar:actual_trips@filters');
		if(!value) return {};
		try{
			return JSON.parse(value);
		}catch(e){
			return {};
		}
	}
}
