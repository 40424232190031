<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
	<div class="description" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
		<button class="back" mat-icon-button (click)="datasetRS.record.next(null);" *ngIf="datasetRS.record.value">
			<mat-icon>arrow_back_ios</mat-icon>
		</button>
		<span class="material-icons-outlined">group</span>
		<span class="label">{{'Partecipanti' | translate}}</span>
	</div>

	<div class="close-icon" fxLayout="row" fxLayoutAlign="end center">
		<span class="material-icons-outlined dialog-close-icon" (click)="onCloseClick()">close</span>
	</div>
</div>

<div class="trip-booking-participants-container">
	<ng-container *ngIf="!datasetRS.record.value">
		<dataset-list
			[enableCreate]='false'
			[enableEdit]='!isResaleCopy'
			[enableDelete]='!isResaleCopy'
			[elevate]='false'
			[showSearchBar]='false'
			[showTitle]='false'
			datasetCode='trip_booking_participant_extras'
			[parentDatasetACS]='data.tripBookingPackageACS'
			[parentDatasetRS]='data.tripBookingPackageRS'
			[enableDelete]='data.bookingService.canEdit'
			[enableCreate]='data.bookingService.canEdit'
			[enableEdit]='data.bookingService.canEdit'
			(selected)='onSelected($event)'
		></dataset-list>
	</ng-container>

	<ng-container *ngIf="datasetRS.record.value">
		<mat-progress-bar style="min-width: 300px;" mode="indeterminate" *ngIf="datasetRS.loading.value"></mat-progress-bar>

		<div class="trip_booking_selected_participant" fxLayout="column" fxLayoutGap="24px">
			<h3 class="participant_description">{{ datasetRS.record.value.participant_description }}</h3>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<span class="label">{{ 'Nominativo' | translate }}:</span>
				<span class="value">{{ datasetRS.record.value.entity_description }}</span>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<span class="label">{{ 'Escluso' | translate }}:</span>
				<span class="value">{{ datasetRS.record.value.exclude == 0 ? 'No' : datasetRS.record.value.exclude == 1 ? 'Sì' : '' }}</span>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<span class="label">{{ 'Note' | translate }}:</span>
				<span class="value">{{ datasetRS.record.value.note }}</span>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<span class="label">{{ 'Azioni' | translate }}:</span>
				<span class="material-icons-outlined edit" (click)="onEdit()">edit</span>
				<!--<span class="material-icons-outlined delete" (click)="trash()">delete</span>-->
			</div>
		</div>
	</ng-container>
</div>
<!-- footer -->
<div class="footer" fxLayout="row" fxLayoutAlign="space-between center"></div>