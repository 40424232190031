import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from '../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('trip_service_id'),
	// fieldsMap.get('supplier_id'),
	// fieldsMap.get('tour_operator_id'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('count'),
			fieldsMap.get('price'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('commission_discount_percent'),
			fieldsMap.get('fee_included')
		]
	}
];

const completeInfoFormConfig: IFormConfig = {
	fields
};

export default completeInfoFormConfig;
