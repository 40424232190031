<mat-card [class.mat-elevation-z0]='!elevate' class='no-card-box'>
	<mat-card-content>
		<ng-container *ngIf='showHeaderToolbar'>
			<dataset-filter
				[loading]='datasetACS.loading.value'
				[actionButtons]='globalActionButtons'
				[showSearchText]='showSearchBar && viewConfig.showSearchBar'
				[filtersData]='filtersData'
				(filterChange)='datasetACS.putDatasetData("filters", $event)'
				[formConfig]="viewConfig.filterForm"
				[dataContainerService]='datasetACS'
			></dataset-filter>
		</ng-container>
		<mat-progress-bar *ngIf='!showHeaderToolbar && datasetACS.loading.value' mode='indeterminate'></mat-progress-bar>
		<div *ngIf='!datasetACS.loading.value && paginationResponse && paginationResponse.items && paginationResponse.items.length == 0'>
			<p class='pl-16'>{{'Nessun elemento presente' | translate}}</p>
		</div>
		<div class='custom-list-items-container' *ngIf='paginationResponse && paginationResponse.items && paginationResponse.items.length !== 0'>
			<ng-container *ngFor='let item of paginationResponse.items; trackBy:trackById'>
				<ng-container [ngSwitch]='datasetACS.datasetConfig.name'>
					<ng-container *ngSwitchCase='"trip_booking_discounts"'>
						<trip-booking-discount-item-view 
							[parentDatasetACS]='datasetACS'
							[item]='item'
							(delete)='onDelete(item)'
						></trip-booking-discount-item-view>
					</ng-container>
					<ng-container *ngSwitchCase='"trip_booking_participant_extras"'>
						<trip-booking-participant-item-view 
							[parentDatasetACS]='datasetACS'
							[item]='item'
							(edit)="openEdit(item)"
							(selected)="selected.emit(item)"
						></trip-booking-participant-item-view>
					</ng-container>
					<ng-container *ngSwitchCase='"trip_booking_resources"'>
						<trip-booking-resource-item-view 
							[parentDatasetACS]='datasetACS'
							[item]='item'
							(edit)="openEdit(item)"
							(reloadDatasetList)="reload()"
							(selectedResource)="selected.emit($event)"
						></trip-booking-resource-item-view>
					</ng-container>
					<ng-container *ngSwitchCase='"actual_trip_group_trip_resources"'>
						<actual-trip-group-trip-resources-item-view
							[parentDatasetACS]='datasetACS'
							[item]='item'
							(delete)='onDelete(item)'
							(addItem)='onAdd()'
						></actual-trip-group-trip-resources-item-view>
					</ng-container>
					<ng-container *ngSwitchCase='"trip_options_for_virtual_trips"'>
						<trip-options-for-virtual-trips-list-item-view
							[item]='item'
							(delete)='onDelete(item)'
							(edit)="openEdit(item)"
						></trip-options-for-virtual-trips-list-item-view>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<p>No custom item view defined for {{datasetACS.datasetConfig.name}}</p>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	
	</mat-card-content>
	<mat-card-footer *ngIf='canCreate || hasGlobalButtons || showPaginationComponent'>
		<mat-toolbar>
			<span fxFlex></span>
			<mat-paginator 
				*ngIf='showPaginationComponent' 
				[pageSizeOptions]="[5, 10, 20, 50, 100, 200]" 
				showFirstLastButtons
				[length]="paginationResponse.meta.total"
				[pageSize]="paginationResponse.meta.perPage"
				(page)="onPaginatorChange($event)">
			</mat-paginator>
		</mat-toolbar>
	</mat-card-footer>
</mat-card>
