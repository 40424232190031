<div class="page-layout blank p-24" fusePerfectScrollbar>
	<div class="content" fxLayout="row wrap" fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutGap="24px grid">
		<seeding-utility fxFlex fxFlex.gt-lg="50%"></seeding-utility>
		<cache-utility fxFlex fxFlex.gt-lg="50%"></cache-utility>
	</div>

	<!--
	<div class="content mt-24" fxLayout='row wrap' fxLayoutGap='24px grid'>
		<import-customers-utility fxFlex fxFlex.gt-lg='50%'></import-customers-utility>
		<import-suppliers-utility fxFlex fxFlex.gt-lg="50%"></import-suppliers-utility>
	</div>-->
	<div class="content mt-24" fxLayout='row wrap' fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutGap='24px grid'>
		<!--<import-old-statistics-utility fxFlex fxFlex.gt-lg='50%'></import-old-statistics-utility>-->
		<process-file-utility fxFlex fxFlex.gt-lg='50%'></process-file-utility>
	</div>
	
</div>
