<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	
	<form autocomplete="off"
		name='form' 
		*ngIf='formViewerService.initialized && formViewerService.formGroup' 
		[formGroup]="formViewerService.formGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'>

		<div class="section title">
			<label class="input-label section-question">
				Questo è un pacchetto in rivendita.
			</label>
	
			<div class="instruction">
				<p class="instruction">
					Puoi impostare alcuni dati relativi alla tua rivendita.
				</p>
				<p class="instruction">
					Puoi sempre visionare i dati originali del pacchetto.
				</p>
			</div>

			<div class="base-textfield base-textfield-large">
				<data-container-viewer
					[dataContainerService]='datasetACS'
					[fields]='stepConfig.options.originalDataFields' 
					[record]='parentDatasetRS.record.value'
				></data-container-viewer>
			</div>
		</div>

		<label style="padding-left: 10px;" class="input-label section-title"><b>Stato pacchetto</b></label>
		<div class="section status-package">
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("active_status")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["active_status"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("publish_on_web")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["publish_on_web"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<!-- eAgency -->
		<ng-container *ngIf='showIfeAgency'>
			<label style="padding-left: 10px;" class="input-label section-title"><b>export eAgency dati</b></label>
			<div class="section status-package">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("supplier_id")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["supplier_id"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
	
				<div class="base-textfield base-textfield-large taxation">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("taxation")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["taxation"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
	
			<div class="section status-package">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("commessa_id")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["commessa_id"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
	
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("active_commission_percent")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["active_commission_percent"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
		</ng-container>
	
		<div class="save-button-container">
			<button [disabled]='formViewerService.formGroup.invalid && !formErrors || datasetACS.loading.value' class="gyg-button gyg-button-medium gyg-button-cta-light" (click)='onSave()'>
				<span>Salva e continua</span>
			</button>
		</div>
	</form>

</div>