import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('display_name', {
	key: 'display_name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('parent_domain_id', {
	key: 'parent_domain_id',
	name: 'Dominio Padre',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	formValidators: [ Validators.required ],
	datasetCode: 'domains',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		}
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('status', {
	key: 'enabled',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: 0
		},
		{
			label: 'Abilitato',
			value: 1
		}
	]
});

fieldsMap.set('domain_path', {
	key: 'domain_path',
	name: 'Gerarchia',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('stripe_fee', {
	key: 'stripe_fee',
	name: 'Stripe Fee',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.NUMBER,
		clearable: true
	},
	defaultValue: null,
	disallowNull: false,
	skipIf(record, dataContainerService){
		return !dataContainerService || !dataContainerService.authService.isAdministrator();
	}
})

export {fieldsMap};
