import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ListViewItemTypes } from 'app/interfaces';
import { AppService } from 'app/services/app.service';
import { BaseDatasetComponent } from '../base-dataset.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DatasetListService } from './dataset-list.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WebSocketService } from 'app/services/websocket.service';
var DatasetListComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetListComponent, _super);
    function DatasetListComponent(route, datasetService, router, authService, appService, datasetACS, datasetNavigatorService, domainFilterService, datasetListService, _fuseTranslationLoaderService, mastSnackBar, webSocketService) {
        var _this = _super.call(this, route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.domainFilterService = domainFilterService;
        _this.datasetListService = datasetListService;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.mastSnackBar = mastSnackBar;
        _this.webSocketService = webSocketService;
        _this.actionCode = 'list';
        _this.ItemViewTypes = ListViewItemTypes;
        _this.itemViewType = ListViewItemTypes.DEBUG;
        _this.items = [];
        _this.cacheDatasetListFilters = true;
        _this.selectable = false;
        _this.selectionBtnTxt = 'Aggiungi';
        _this.selected = new EventEmitter();
        _this.enableCreate = true;
        _this.enableEdit = true;
        _this.enableDelete = true;
        _this.showCreateForm = false;
        _this.showSearchBar = true;
        _this.showPagination = true;
        _this.enableActions = true;
        _this.elevate = true;
        _this.showExportBtn = true;
        _this.showHeaderToolbar = true;
        _this.unsetParamFilters = false;
        /**
         * Show pagination toolbar only if need
         */
        _this.conditionalShowPaginationToolbar = true;
        _this.emitAdd = false;
        _this.addButtonText = 'Aggiungi';
        _this.add = new EventEmitter();
        _this.created = new EventEmitter();
        _this.datasetEvent = new EventEmitter();
        _this.debounceSocketReload = new Subject();
        return _this;
    }
    DatasetListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.viewConfig = this.viewConfig;
        this.datasetListService.datasetACS = this.datasetACS;
        this.datasetListService.viewConfig = this.viewConfig;
        this.datasetListService.sortDirection = this.sortDirection;
        this.datasetListService.sortBy = this.sortBy;
        _super.prototype.ngOnInit.call(this);
        this.searchTextFilterControl = new FormControl();
        this.filterForm = new FormGroup({
            'text-search': this.searchTextFilterControl
        });
        this.datasetACS.datasetEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            _this.datasetEvent.emit(event);
            if (event.eventName === DatasetEvents.ACTION_REALOAD_LIST) {
                _this.reload();
            }
        });
        this.domainFilterService.filterDomainId
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this.datasetACS.viewConfig.cacheDatasetListFilters && _this.cacheDatasetListFilters) {
                _this.datasetACS.putDatasetData('filters', JSON.parse(localStorage.getItem(_this.datasetACS.getListFiltersCacheKey())));
            }
            _this.reload();
        });
        this.debounceSocketReload
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(60 * 1000))
            .subscribe(function () {
            _this.reload();
        });
    };
    DatasetListComponent.prototype.onFilterChange = function (newFilters) {
        this.cacheFilters(newFilters);
        this.datasetACS.putDatasetData("filters", newFilters);
        this.datasetEvent.emit({
            eventName: DatasetEvents.LIST_FIlTER_CHANGED,
            data: newFilters
        });
    };
    DatasetListComponent.prototype.cacheFilters = function (newFilters) {
        if (this.datasetACS.viewConfig.cacheDatasetListFilters && this.cacheDatasetListFilters) {
            localStorage.setItem(this.datasetACS.getListFiltersCacheKey(), JSON.stringify(newFilters));
        }
        else {
            localStorage.removeItem(this.datasetACS.getListFiltersCacheKey());
        }
    };
    DatasetListComponent.prototype.onChannelEvent = function (broadcastEvent) {
        var _this = this;
        if (!broadcastEvent)
            return;
        if (!broadcastEvent.data && !broadcastEvent.data.datasetCode)
            return;
        if (broadcastEvent.data.datasetCode !== this.datasetACS.datasetCode)
            return;
        var config = this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig;
        // if non config
        if (!config)
            return;
        // save event
        this.socketEvent = broadcastEvent;
        // customize message based on event type
        var message = "";
        if (config.composeGlobalToastMessage)
            message = config.composeGlobalToastMessage(broadcastEvent);
        // load web socket configs
        var webSocketConfig = this.datasetACS.datasetConfig.defaultActions.list.webSocketConfig[broadcastEvent.data.eventName];
        // if non configs
        if (!webSocketConfig)
            return;
        // check configs dataset for reloading
        if (webSocketConfig.reloadDatasetList && !Object.keys(this.datasetACS.datasetData['filters']).length && this.datasetListService.currentPage == 1) {
            this.debounceSocketReload.next(true);
        }
        // check configs dataset for toast
        if (webSocketConfig.showToast) {
            if (webSocketConfig.composeToastMessage) {
                message = webSocketConfig.composeToastMessage(broadcastEvent);
            }
            this.mastSnackBar.open(message || webSocketConfig.toastMessage || 'Something new is coming!', webSocketConfig.dismissToastTxt || '', {
                duration: 15 * 1000
            }).afterDismissed().subscribe(function (res) {
                if (res.dismissedByAction && webSocketConfig.reloadDatasetListOnDismissToast)
                    _this.reload();
            });
        }
    };
    DatasetListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filters && !changes.filters.isFirstChange()) {
            this.datasetACS.putDatasetData('filters', changes.filters.currentValue);
        }
        if (changes.viewConfig && !changes.viewConfig.isFirstChange()) {
            this.datasetACS.viewConfig = this.viewConfig;
            this.datasetListService.viewConfig = this.viewConfig;
            this.datasetListService.itemViewType = this.viewConfig && this.viewConfig.itemViewType;
        }
        if (changes.sortBy && !changes.sortBy.isFirstChange()) {
            this.datasetListService.sortBy = this.sortBy;
        }
        if (changes.sortDirection && !changes.sortDirection.isFirstChange()) {
            this.datasetListService.sortDirection = this.sortDirection;
        }
        this.socketEvent = null;
    };
    DatasetListComponent.prototype.initLoad = function () {
        var _this = this;
        if (!this.datasetACS.actionConfig) {
            console.warn('no action config', this.datasetACS);
        }
        if (!this.viewConfig)
            this.viewConfig = this.datasetACS.actionConfig.viewConfig;
        if (this.viewConfig) {
            this.itemViewType = this.viewConfig.itemViewType;
            this.datasetListService.viewConfig = this.viewConfig;
            this.datasetListService.itemViewType = this.viewConfig.itemViewType;
        }
        this.datasetACS.putDatasetData('filters', this.filters);
        this.datasetACS.datasetDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (keys) {
            if (keys.includes('filters')) {
                _this.datasetListService.currentPage = 1;
                _this.socketEvent = null;
                _this.reload();
            }
        });
        this.datasetACS.datasetEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.eventName === DatasetEvents.CREATED)
                _this.created.emit();
        });
        return of(null);
    };
    DatasetListComponent.prototype.load = function () {
        var params = this.datasetListService.getParams();
        var headers = new HttpHeaders({});
        if (this.contextualDomainId) {
            headers = headers.append('X-Domain-Id', this.contextualDomainId);
        }
        else if (this.datasetACS.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
        }
        return this.datasetService.get(this.datasetACS.getListRoute(), params, headers);
    };
    DatasetListComponent.prototype.onLoaded = function (response) {
        this.lastPaginationResponse = response;
        this.appService.appEvent.next({
            name: 'total_dataset_items',
            extra: {
                paginationResponse: this.lastPaginationResponse,
                datasetCode: this.datasetACS.datasetCode
            }
        });
        if (response.static_dataset_allowed_actions && !response.static_dataset_allowed_actions.includes('view')) {
            this.appService.showErrorMessage('Permesso negato');
            this.items = [];
            this.lastPaginationResponse = null;
            // this.router.navigate(['home']);
        }
        else {
            this.items = response.items;
            this.datasetACS.putDatasetData('staticAllowedActionCodes', response.static_dataset_allowed_actions);
        }
        return of(true);
    };
    DatasetListComponent.prototype.selectRecord = function (record) {
        this.selection = record;
        this.selected.emit(this.selection);
    };
    DatasetListComponent.prototype.onPageChanged = function (event) {
        this.socketEvent = null;
        this.datasetListService.currentPage = event.pageIndex + 1;
        this.datasetListService.perPage = event.pageSize;
        this.reload();
    };
    DatasetListComponent.prototype.onAdd = function () {
        if (this.emitAdd) {
            this.add.emit();
        }
        else {
            this.datasetNavigatorService
                .onAdd(this.datasetACS);
        }
    };
    return DatasetListComponent;
}(BaseDatasetComponent));
export { DatasetListComponent };
