<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<div class='text-list-input-container'>
		<div fxLayout='column' fxLayoutAlign="start stretch">
			<div *ngFor='let dayObj of serviceDays; let i = index;'>
				<div fxLayout='row' fxLayoutAlign="space-between center">
					<div fxFlex fxLayout='column' fxLayoutAlign="start">
						<label class="content-day">
							<!-- <mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon> -->
							<h3><strong>- {{ dayObj.day }}</strong></h3>
							<span class="starting-time" (click)='addTime(dayObj)'>
								<i class="material-icons">alarm</i> {{'Aggiungi orario' | translate}}
							</span>
						</label>
						<ng-container *ngFor='let timeObj of dayObj.time; let i = index'>
							<div fxLayout='row' fxLayoutAlign="start baseline">
								<!-- opening time -->
								<div>
									<mat-form-field class="example-full-width" appearance="fill" [style.width.%]=50>
										<mat-label>{{ 'Apertura' | translate }}</mat-label>
										<input type="time" matInput [name]='dayObj.code + "_start_time_" + i' [(ngModel)]='timeObj.opening' [ngModelOptions]="{standalone: true}" required>
									</mat-form-field>
								</div>
								<!-- end opening time -->

								<!-- closing times -->
								<div>
									<mat-form-field class="example-full-width" appearance="fill" [style.width.%]=50>
										<mat-label>{{ 'Chiusura' | translate }}</mat-label>
										<input type="time" matInput [name]='dayObj.code + "_end_time_" + i' [(ngModel)]='timeObj.closure' [ngModelOptions]="{standalone: true}" required>
									</mat-form-field>
								</div>
								<!-- end closing times -->
								<div class="remove-item">
									<span class="material-icons" (click)='removeTime(dayObj.time, timeObj)'>delete</span>
								</div>
							</div> <!-- Close row -->
						</ng-container>
					</div>
					<!--
					<button *ngIf='!inputService.inputConfig.readOnly' mat-icon-button color='warn' (click)="removeItem(i)">
						<mat-icon>close</mat-icon>
					</button>
					-->
				</div>
			</div>
		</div>
		<!-- <button *ngIf='!inputService.inputConfig.readOnly' mat-button color='accent' (click)='addNewItem()'>{{'Aggiungi giorni' | translate}}</button> -->
	</div>
</ng-container>