import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export const ActualTripStartStatusLabels = {
	unconfirmed: 'Non confermato',
	confirmed: 'Confermata',
	canceled: 'Cancellato',
	closed: 'Chiusa'
}

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('start_day', {
	key: 'start_day',
	name: 'Data Partenza',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	formValidators: [ Validators.required ],
	inputHint: 'Giorno della partenza'
});

fieldsMap.set('start_time', {
	key: 'start_time',
	name: 'Orario Partenza',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	formValidators: [ Validators.required ]
});

fieldsMap.set('start_date_time', {
	key: 'start_date_time',
	name: 'Dettaglio Partenza',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		const d = moment.utc(record.start_day);
		html += '<span>' + d.format('DD/MM/YYYY') + '</span>';

		if(record.start_time){
			html += '<br>';
			html += '<span>' + record.start_time.slice(0, 5) + '</span>';
		}
		return html;
	}
});

fieldsMap.set('icon_type', {
	key: 'icon_type',
	name: 'Tipo',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		let iconName = 'remove';
		if(record.type === 'going'){
			iconName = 'call_made';
		}else if(record.type === 'return'){
			iconName = 'call_received';
		}
		html += '<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img">';
		html += iconName;
		html += '</mat-icon>';
		return html;
	}
});

fieldsMap.set('languages', {
	key: 'languages',
	name: 'Lingua',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.properties){
			let guideLanguages = [];
			let audioguideLanguages = [];
			if(record.properties.actual_trip_guide_languages && record.properties.actual_trip_guide_languages.value){
				guideLanguages = record.properties.actual_trip_guide_languages.value;
			}
			if(record.properties.actual_trip_audioguide_languages && record.properties.actual_trip_audioguide_languages.value){
				audioguideLanguages = record.properties.actual_trip_audioguide_languages.value;
			}
			if(guideLanguages.length > 0){
				html += '<span>Guida</span>';
				html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

				const style = 'style="margin-right: 5px;"';
				for (const lang of guideLanguages) {
					html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
				}

				html += '</div>';
			}

			if(audioguideLanguages.length > 0){
				html += '<span>Audioguide</span>';
				html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

				const style = 'style="margin-right: 5px;"';
				for (const lang of audioguideLanguages) {
					html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
				}

				html += '</div>';
			}
		}
		return html;
	}
});

fieldsMap.set('guide_languages', {
	key: 'guide_languages',
	name: 'Lingua Guida',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.properties){
			let guideLanguages = [];
			if(record.properties.actual_trip_guide_languages && record.properties.actual_trip_guide_languages.value){
				guideLanguages = record.properties.actual_trip_guide_languages.value;
			}
			if(guideLanguages.length > 0){
				html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

				const style = 'style="margin-right: 5px;"';
				for (const lang of guideLanguages) {
					html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
				}

				html += '</div>';
			}
		}
		return html;
	}
});

fieldsMap.set('audioguide_languages', {
	key: 'audioguide_languages',
	name: 'Lingua Audioguida',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.properties){
			let audioguideLanguages = [];
			if(record.properties.actual_trip_audioguide_languages && record.properties.actual_trip_audioguide_languages.value){
				audioguideLanguages = record.properties.actual_trip_audioguide_languages.value;
			}
			if(audioguideLanguages.length > 0){
				html += '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';

				const style = 'style="margin-right: 5px;"';
				for (const lang of audioguideLanguages) {
					html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
				}

				html += '</div>';
			}
		}
		return html;
	}
});

fieldsMap.set('end_day', {
	key: 'end_day',
	name: 'Data Ritorno',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	formValidators: [ Validators.required ],
	inputHint: 'Giorno del ritorno'
});

fieldsMap.set('end_time', {
	key: 'end_time',
	name: 'Orario ritorno',
	inputType: InputTypes.TIME,
	valueType: ValueTypes.TIME,
	formValidators: [ Validators.required ]
});

fieldsMap.set('type', {
	key: 'type',
	name: 'Tipo di partenza',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	defaultValue(): string{ return 'single'; },
	options: [
		{
			label: 'Singola',
			value: 'single'
		},
		{
			label: 'Andata',
			value: 'going'
		},
		{
			label: 'Ritorno',
			value: 'return'
		}

	]
});

fieldsMap.set('min_allotment', {
	key: 'min_allotment',
	name: 'Allotment minimo',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	formValidators: [ Validators.required ],
	inputHint: 'Minimo per partire',
	defaultValue(): any{ return 0; }
});

fieldsMap.set('max_allotment', {
	key: 'max_allotment',
	name: 'Allotment massimo',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{ return 0; }
});

fieldsMap.set('default_max_allotment', {
	key: 'default_max_allotment',
	name: 'Allotment specifico',
	valueType: ValueTypes.PROPERTY,
	inputConfig: {
		type: InputTypes.NUMBER,
		clearable: true
	}
});

fieldsMap.set('create_start_status', {
	key: 'start_status',
	name: 'Stato partenza',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return 'confirmed'; },
	options: [
		{
			label: 'Non confermato',
			value: 'unconfirmed'
		},
		{
			label: 'Confermato',
			value: 'confirmed'
		}
	]
});

fieldsMap.set('start_status', {
	key: 'start_status',
	name: 'Stato partenza',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return 'unconfirmed'; },
	options: [
		{
			label: ActualTripStartStatusLabels.unconfirmed,
			value: 'unconfirmed'
		},
		{
			label: ActualTripStartStatusLabels.confirmed,
			value: 'confirmed'
		},
		{
			label: ActualTripStartStatusLabels.canceled,
			value: 'canceled'
		}
	]
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	inputHint: 'Note'
});

fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Pacchetto',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_packages',
	inputHint: 'Scrivi per cercare un pacchetto',
	remoteOptions: {
		limit: 100,
		icon: 'place',
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

fieldsMap.set('availability', {
	name: 'Disponibilità',
	key: 'availability',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		if(record.has_exclusive_resource && record.consumed_allotment){
			return 'Già Prenotato'
		}
		let max_allotment = '&infin;';
		if(record.max_allotment != null && record.max_allotment >= 0){
			max_allotment = record.max_allotment;
		}
		if(record.is_external) return max_allotment ;
		return record.consumed_allotment + '/' + max_allotment;
	}
});

const startStatusFgColors = {
	canceled: 'warn-fg',
	confirmed: 'green-fg',
	unconfirmed: ''
}

fieldsMap.set('availability_with_status', {
	name: 'Disponibilità',
	key: 'availability',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		const statusLabel = ActualTripStartStatusLabels[record.start_status] || '';
		const statusColor = startStatusFgColors[record.start_status] || '';
		const statusHtml = statusLabel ? ('<span class="'+statusColor+'">'+statusLabel+'</span>') : '';
		if(record.has_exclusive_resource && record.consumed_allotment){
			return '<span>Prenotato</span><br>'+statusHtml;
		}
		let max_allotment = '&infin;';
		if(record.max_allotment != null && record.max_allotment >= 0){
			max_allotment = record.max_allotment;
		}
		return '<span>'+record.consumed_allotment + '/' + max_allotment+'</span><br>'+statusHtml;
	}
});

fieldsMap.set('participants_count', {
	name: 'Partecipanti',
	key: 'participants_count',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.participant_overall || !record.participant_overall.total) return '0';
		return record.participant_overall.total;
	}
});

fieldsMap.set('meeting_point_list_description', {
	name: 'Nome',
	key: 'meeting_point_list_description',
	valueType: ValueTypes.STRING
});

fieldsMap.set('display_name', {
	name: 'Nome Partenza',
	key: 'display_name',
	valueType: ValueTypes.STRING
});

const meetingPointRemoteOptions = {
	limit: 50,
	getParams(dataContainerService: IDataContainerService, data: any): any{
		const params = {
			properties_parent_dataset_code: 'trip_packages',
			with_items: 1
		};
		const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
		if(tripPackageId){
			params['properties_parent_id'] = tripPackageId;
		}
		return params;
	},
	getLabel(option: any): string{
		if(!option) return '---';
		let label = option.description;
		if(option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-'){
			label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
		}else if(option.items_count > 0){

		}
		return label;
	},
	getValue(option): string{
		return option.id;
	}
};

fieldsMap.set('meeting_point_list_id', {
	key: 'meeting_point_list_id',
	name: 'Lista punti di ritrovo',
	inputType: InputTypes.DATASET_MULTISELECT,
	valueType: ValueTypes.ARRAY,
	datasetCode: 'meeting_point_unified_lists',
	remoteOptions: meetingPointRemoteOptions
});

export {fieldsMap};
