<mat-card class="card-pass">
    <mat-card-content>
        <div class="pass-title" *ngIf="passenger.type=='ADULT'">Passeggero {{index+1}}: Adulto</div>
        <div class="pass-title" *ngIf="passenger.type=='CHILDREN'">Passeggero {{index+1}}: Ragazzo</div>
        <div fxLayout="row wrap" fxLayoutGap="3px">
            <div fxFlex>
                <form-input-viewer
					[readOnly]="isReadOnly"
                    [formInputDefinition]='fields.get("pass_first_name")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_first_name"]'
                ></form-input-viewer>
            </div>

            <div fxFlex>
                <form-input-viewer
					[readOnly]="isReadOnly"
                    [formInputDefinition]='fields.get("pass_last_name")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_last_name"]'
                ></form-input-viewer>
            </div>
			<div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='fields.get("pass_data_n")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_data_n"]'
                ></form-input-viewer>
            </div>
            
		</div>
		<div fxLayout="row wrap" fxLayoutGap="3px">
            <div fxFlex>
                <form-input-viewer
					[readOnly]="isReadOnly"
                    [formInputDefinition]='fields.get("pass_email")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_email"]'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
					[readOnly]="isReadOnly"
                    [formInputDefinition]='fields.get("pass_phone")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_phone"]'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='fields.get("pass_cf")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["pass_cf"]'
                ></form-input-viewer>
            </div>
        </div>
		<div fxLayout="row wrap" fxLayoutGap="3px">
			<form-input-viewer
				[formInputDefinition]='fields.get("contact_consent")'
				[formGroup]='formViewerService.formGroup'
				[formControlKey]='formViewerService.controlKeyMap["contact_consent"]'
			></form-input-viewer>
		</div>
    </mat-card-content>
</mat-card>