import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { ITripBookingPackageRelatedData, TripBookingPackageRelatedComponent } from '../trip-booking-packages-related.component';

@Component({
	selector   : 'trip-booking-resource',
	templateUrl: './trip-booking-resource.component.html',
	styleUrls: ['./trip-booking-resource.component.scss'],
	providers: [
		DatasetActionContainerService,
		DatasetRecordService,
		PageClosureContainerService
	]
})
export class TripBookingResourceComponent extends TripBookingPackageRelatedComponent implements OnInit, OnDestroy{

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedData){
			super(appService, datasetACS, datasetRS, matDialogRef, data);
		}
}
