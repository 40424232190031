import {FieldViews, DatasetActionOpenType, IDatasetConfig, ListViewItemTypes, ValueTypes, InputTypes} from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import {config as stepViewConfig} from './stepViewConfig';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import trashedField from 'app/configs/commons/shared-field-configs/trashed.field';
import { RequestPackagesDialogComponent } from 'app/main/components/dataset/dialogs/request-packages-dialog/dialog.component';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import makeRequestAction from './make-request.action';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { IRenderViewDialog } from 'app/main/components/dataset/dialogs/render-component-dialog/dialog.component';
import { RenderViewDialogComponent } from 'app/main/components/dataset/dialogs/render-component-dialog/dialog.component';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_packages/detail/:recordId',
};

export const PublicTripPackageConfig: IDatasetConfig = {
	name: 'public_trip_packages',
	title: 'Pacchetti',
	singleTitle: 'Pacchetto',
	ajaxDatasetCode: 'public_trip_packages',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true,
		restore: false,
		
	},
	formConfigsMap: {
		generalForm
	},
	stepViewConfig,
	defaultActions: {
		detail: {},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				showRequestPackage: false,
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
					},
					{
						title : 'Immagine',
						key: 'master_image',
					},
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes'
					}
				],
				actions: [],
				customActions: [
					{
						isGlobal: true,
						actionCode: 'massive_resell',
						buttonColor: 'accent',
						buttonIcon: 'add_shopping_cart',
						tooltip: 'Richiedi tutti i pacchetti',
						showIf(record: any, dataContainerService: IDataContainerService): boolean{
							return true;
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IFormDialogData>(FormDialogComponent, {
								title: 'Richiesta rivendita massiva',
								message: 'Rivendi per categoria o non selezionare niente per rivendere tutti i pacchetti del dominio di riferimento.',
								dataContainerService: dataContainerService,
								formConfig: {
									fields: [
										{
											key: 'trip_category_id',
											name: 'Categorie pacchetti',
											inputType: InputTypes.DATASET_SELECT,
											valueType: ValueTypes.ARRAY,
											datasetCode: 'trip_categories',
											dependOn: 'is_massive_by_category',
											remoteOptions: {
												limit: 50,
												getParams(dataContainerService: DatasetActionContainerService, record: any): any {
													const params = {
														only_with_reselled_packages: 1
													};
													const filters = dataContainerService.getValueFromKeyPath('public_trip_packages.filters');
													if (filters['reseller_domain_id']){
														params['domain_id'] = filters['reseller_domain_id'];
													}
													return params;
												},
												getLabel(option: any): string{
													if(!option) return;
													return option.description;
												},
												getValue(option): string{
													if (!option) return;
													return option.id;
												}
											}
										}
									]
								},
								positiveText: 'Richiedi',
								negativeText: 'Annulla',
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								// Hanlde process errors with LoadingDialog
								// If current user have multiple domains ask in what domain we want resell
								// the selected package
								result['reseller_domain_id'] = dataContainerService.getValueFromKeyPath('public_trip_packages.filters.reseller_domain_id');
								const action = '/dataset/domain_packages/command/massive_set_packages';
								makeRequestAction(action, result, dataContainerService, datasetNavigatorService)
								.then((resp) => {
									if (!resp) return;
									if (!resp.success) {
										dataContainerService.appService.showErrorMessage(resp.messagge);
									} else {
										dataContainerService.appService.showSuccessMessage(resp.messagge);
									}
									if(dataContainerService instanceof DatasetActionContainerService){
										dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
									}
								})
							});
						}
					},
					{
						actionCode: 'resell',
						buttonColor: 'accent',
						buttonIcon: 'add_shopping_cart',
						tooltip: 'Richied pacchetto',
						showIf(record: any, dataContainerService: IDataContainerService): boolean{
							return true; 
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
								title: 'Richiesta pacchetto ' + record.description,
								contentMessage: 'Vuoi formulare una richiesta di rivendita per il pacchetto ' + record.description + '?',
								positiveText: 'Si',
								negativeText: 'No',
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								// Hanlde process errors with LoadingDialog
								// If current user have multiple domains ask in what domain we want resell
								// the selected package
								const params = {
									trip_package_id: record.id,
									reseller_domain_id: dataContainerService.getValueFromKeyPath('public_trip_packages.filters.reseller_domain_id')
								};
								const action = '/dataset/domain_packages/command/set_packages';
								makeRequestAction(action, params, dataContainerService, datasetNavigatorService)
								.then((resp) => {
									if (!resp) return;
									if (!resp.success) {
										dataContainerService.appService.showErrorMessage(resp.messagge);
									} else {
										dataContainerService.appService.showSuccessMessage(resp.messagge);
									}
									if(dataContainerService instanceof DatasetActionContainerService){
										dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
									}
								})
								.catch(e => {
									console.error(e);
								});
							});
						}
					},
					{
						buttonColor: 'accent',
						buttonIcon: 'search',
						tooltip: 'Dettagli pacchetto',
						showIf(record: any, dataContainerService: IDataContainerService): boolean{
							return true; 
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigator: DatasetNavigatorService): void{
							datasetNavigator.openDialog<IRenderViewDialog>(RenderViewDialogComponent, {
								component: 'trip-package-overview',
								parentDatasetACS: dataContainerService,
								params: {
									recordId: record.id
								}
							});
						}
					}
				],
				filterForm: {
					fields: [
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('active_status_filter'),
								fieldsMap.get('publish_on_web_filter')
							]
						},
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								trashedField
							]
						}
					]
				}
			}
		}
	}
};
