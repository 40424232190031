import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { CalendarService } from './calendar.service';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { takeUntil } from 'rxjs/operators';
var ɵ0 = { useUtc: true }, ɵ1 = MAT_MOMENT_DATE_FORMATS;
var DatasetCalendarViewComponent = /** @class */ (function () {
    function DatasetCalendarViewComponent(_calendarService, datasetNavigatorService, datasetACS, matDialog) {
        this._calendarService = _calendarService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.datasetACS = datasetACS;
        this.matDialog = matDialog;
        this.selectable = false;
        this.conditionalShowPaginationToolbar = true;
        this.elevate = true;
        this.enableCreate = true;
        this.enableEdit = true;
        this.enableDelete = true;
        this.showPagination = true;
        this.enableActions = true;
        this.selected = new EventEmitter();
        this.pageChanged = new EventEmitter();
        this.emitAdd = false;
        this.addButtonText = 'Aggiungi';
        this.add = new EventEmitter();
        this.filtersData = {};
        // Set the defaults
        this.activeDayIsOpen = false;
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    DatasetCalendarViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._calendarService.calendarConfig = this.viewConfig.calendarConfig;
        this.onViewDateChanged(this._calendarService.viewDate);
        this.datasetACS.datasetDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (keys) {
            if (!keys || !keys.includes('filters'))
                return;
            _this.filtersData = _this.datasetACS.datasetData['filters'];
        });
    };
    DatasetCalendarViewComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    DatasetCalendarViewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewConfig) {
            this._calendarService.calendarConfig = this.viewConfig.calendarConfig;
        }
        if (changes.paginationResponse) {
            if (!this.paginationResponse) {
                this.events = [];
                return;
            }
            if (!this.viewConfig.calendarConfig.itemsToEvents) {
                console.warn('define calendarConfig.itemsToEvents for: ' + this.datasetACS.datasetCode);
                return;
            }
            this.events = this.viewConfig.calendarConfig.itemsToEvents(this.paginationResponse.items, this);
        }
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Before View Renderer
     *
     * @param {any} header
     * @param {any} body
     */
    DatasetCalendarViewComponent.prototype.beforeMonthViewRender = function (event) {
        var e_1, _a;
        var currentMonth = moment.utc(this._calendarService.viewDate).startOf('month');
        try {
            for (var _b = tslib_1.__values(event.body), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _day = _c.value;
                var dayTime = _day.date.getTime();
                if (dayTime <= currentMonth.valueOf()) {
                    _day.cssClass = 'not-in-month';
                }
                else {
                    if (!this._calendarService.selectedDateTimes.includes(dayTime))
                        continue;
                    _day.cssClass = 'cal-selected';
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DatasetCalendarViewComponent.prototype.onViewDateChanged = function (date) {
        this._calendarService.selectedDateTimes = [];
        var prevFilterData = this.datasetACS.getValueFromKeyPath('filters');
        var newFilterData = Object.assign({}, {
            from_date: moment.utc(date).startOf('month').toISOString(),
            to_date: moment.utc(date).endOf('month').toISOString(),
        }, prevFilterData);
        this.datasetACS.putDatasetData('filters', newFilterData);
    };
    /**
     * Day clicked
     *
     * @param {MonthViewDay} day
     */
    DatasetCalendarViewComponent.prototype.dayClicked = function (day) {
        var date = day.date;
        var events = day.events;
        if (isSameMonth(date, this._calendarService.viewDate)) {
            if ((isSameDay(this._calendarService.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                if (this.viewConfig.calendarConfig.openCellDetail)
                    this.activeDayIsOpen = true;
                this._calendarService.selectedDateTimes = [day.date];
                this._calendarService.viewDate = date;
            }
        }
        else {
            this.activeDayIsOpen = false;
        }
        /*if(this._calendarService.selectedDateTimes.includes(day.date.getTime())){
            const index = this._calendarService.selectedDateTimes.indexOf(day.date.getTime());
            this._calendarService.selectedDateTimes.splice(index, 1);
            day.cssClass = null;
        }else{
            this._calendarService.selectedDateTimes.push(day.date.getTime());
            day.cssClass = 'cal-selected';
        }*/
        if (this.viewConfig.calendarConfig.onDayClicked) {
            this.viewConfig.calendarConfig.onDayClicked(this, day);
        }
    };
    /**
     * Event times changed
     * Event dropped or resized
     *
     * @param {CalendarEvent} event
     * @param {Date} newStart
     * @param {Date} newEnd
     */
    DatasetCalendarViewComponent.prototype.eventTimesChanged = function () {
        // TODO
    };
    DatasetCalendarViewComponent.prototype.eventClick = function () {
        // TODO
    };
    DatasetCalendarViewComponent.prototype.onAdd = function () {
        if (!this.datasetACS.actionEnabled('create'))
            return;
        if (this.viewConfig.calendarConfig.onAdd) {
            this.viewConfig.calendarConfig.onAdd(this);
            return;
        }
        if (this.emitAdd) {
            this.add.emit();
        }
        else {
            this.datasetNavigatorService
                .onAdd(this.datasetACS);
        }
    };
    return DatasetCalendarViewComponent;
}());
export { DatasetCalendarViewComponent };
export { ɵ0, ɵ1 };
