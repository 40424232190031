import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
//import createForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_resource_meeting_point_tickets/detail/:recordId',
};

const beforeCallDetailMeetingPoint: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/meeting_points/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripResourceId = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
	if(tripResourceId){
		params['trip_resource_id'] = tripResourceId;
	}
	if (parentData) {
		params['meeting_point_id'] = parentData.id;
	}
	return params;
}

export const TripResourceMeetingPointTicketConfig: IDatasetConfig = {
	name: 'trip_resource_meeting_point_tickets',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'trip_resource_meeting_point_tickets',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		//createForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetailMeetingPoint ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: 'Ticket Disponibili',
						key: 'quantity_ticket'
					}
				],
				actions: ['detail', 'edit'],
			}
		}
	}
};
