<h1 mat-dialog-title>{{'Seleziona la data di ritiro' | translate}}</h1>
<mat-dialog-content>
	<mat-form-field class="px-16 w-100-p" appearance="fill">
		<mat-label>{{'Scegli la data di ritiro' | translate}}</mat-label>
		<input #inputDate matInput [matDatepicker]="picker" [min]="minDate" [matDatepickerFilter]="datesFilter" (click)="picker.open()" (dateChange)="dateChangeActualTrip($event)" (keydown)="false">
		<mat-hint>DD/MM/YYYY</mat-hint>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-content *ngIf="chosenDate">
	<mat-form-field class="px-16 w-100-p" appearance="fill">
		<mat-label>{{ 'Seleziona l\'orario di ritiro' | translate }}</mat-label>
		<input #inputDate matInput type="time" (input)="timeChangeActualTrip($event)" required>
		<span style="color: red;" *ngIf="status === false">{{'Non è un orario valido' | translate}}</span>
	</mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="status" align='end'>
	<button mat-button color='' (click)="saveDataTimeActualTrip()">{{'Salva' | translate}}</button>
</mat-dialog-actions>
<!--
<mat-dialog-actions align='end'>
	<button mat-button color='' (click)="onCloseClick()">{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
-->
