<h2>Info</h2>
<h3><strong>{{train.type}} {{train.name}}</strong></h3>
<div class="train-info flex-col flex-gap-8">
    <div *ngFor="let inf of infoServices" class="flex">
        <ng-container>
            <img
                *ngIf="inf.image"
                src="data:image/{{inf.image.type}};base64,{{inf.image.data}}"
                alt="{{inf.type}}"
                matTooltip="{{inf.type}}">
            <span> {{inf.description}}</span>
        </ng-container>
    </div>
    <h4 *ngIf="infoNotes.length"><strong>Note informative</strong></h4>
    <div *ngFor="let inf of infoNotes">
        - {{inf.description}}
    </div>
</div>
<h2 *ngIf="load || stops">Fermate</h2>
<div fxLayout="row wrap" fxLayoutAlign="start" fxFlexFill style="text-align: center" *ngIf="stops">
    <div fxFlex="15" *ngFor="let s of stops, index as i, first as isFirst, last as isLast" class="train-info-stops">
        <div fxLayout="row">
            <div fxFlex="95">
                <div class=" train-info-time" *ngIf="s.arrival">{{s.arrival}}</div>
                <div class=" train-info-time null" *ngIf="!s.arrival && !(isFirst && train.departureLocalDateStr)">null</div>
                <div class="train-info-time" *ngIf="isFirst && train.departureLocalDateStr">{{train.departureLocalDateStr}}</div>
                <div><span [ngClass]="i==0||i==stops.length-1?'ev':''">{{s.station}}</span></div>
                <div class=" train-info-time" *ngIf="s.departure">{{s.departure}}</div>
                <div class=" train-info-time null" *ngIf="!s.departure && !(isLast && train.arrivalLocalDateStr)">null</div>
                <div class="train-info-time" *ngIf="isLast && train.arrivalLocalDateStr">{{train.arrivalLocalDateStr}}</div>
            </div>
            <div fxFlex="5">
                <div *ngIf="i<stops.length-1" class="train-info-stops-ico">
                    <mat-icon id="arr">keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="load"></mat-progress-bar>
