import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome del pacchetto',
	titleIcon: 'public',
	inputRequired: true,
	formValidators: [ Validators.required ],
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('active_status', {
	key: 'active_status',
	name: 'Pubblico',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{
		return 0;
	},
	options: [
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Attivo',
			value: 1
		}
	],
	inputHint: 'Se questa traduzione è attiva, sarà pubblica per tale lingua',
});

fieldsMap.set('full_description', {
	key: 'full_description',
	name: 'Descrizione completa',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.PROPERTY,
	fieldView: FieldViews.HTML,
	inputHint: 'Testo completo di descrizione.',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('before_leaving', {
	key: 'before_leaving',
	name: 'Prima di partire',
	inputType: InputTypes.TEXT_LIST,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('included_services', {
	key: 'included_services',
	name: 'Servizi inclusi',
	inputType: InputTypes.TEXT_LIST,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('excluded_services', {
	key: 'excluded_services',
	name: 'Servizi esclusi',
	inputType: InputTypes.TEXT_LIST,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('schedule_info', {
	key: 'schedule_info',
	name: 'Note',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.PROPERTY,
	fieldView: FieldViews.HTML,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('tour_brief', {
	key: 'tour_brief',
	name: 'Breve Descrizione',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('voucher_text', {
	key: 'voucher_text',
	name: 'Testo Voucher PDF',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.PROPERTY,
	fieldView: FieldViews.HTML,
	inputHint: 'Questo testo verrà stampato sul voucher',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('voucher_text_ticketing', {
	key: 'voucher_text_ticketing',
	name: 'Testo Voucher Ticketing',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.PROPERTY,
	fieldView: FieldViews.HTML,
	inputHint: 'Questo testo verrà stampato sul voucher',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('priority_order', {
	key: 'priority_order',
	name: 'Priorità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('totem_priority', {
	key: 'totem_priority',
	name: 'Priorità Totem',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('confirm_pickup_point_email_info', {
	key: 'confirm_pickup_point_email_info',
	name: 'Testo Info Email Conferma punto di ritiro',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	inputHint: 'Questo testo verrà inserito sulla sezione info della mail',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sulla mail',
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const ticketing = dataContainerService.getValueFromKeyPath('domainModules').ticketing;
		if(ticketing && ticketing.enabled){
			return ticketing.enabled;
		}
		return false;
	}
});

fieldsMap.set('delivery_email_info', {
	key: 'delivery_email_info',
	name: 'Testo Info Email Consegna',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	inputHint: 'Questo testo verrà inserito sulla sezione info della mail',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sulla mail',
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const ticketing = dataContainerService.getValueFromKeyPath('domainModules').ticketing;
		if(ticketing && ticketing.enabled){
			return ticketing.enabled;
		}
		return false;
	}
});

fieldsMap.set('ribbon_sito_description', {
	key: 'ribbon_sito_description',
	name: 'Descrizione Ribbon Sito',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Descrizione Ribbon Sito',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('ribbon_sito_color', {
	key: 'ribbon_sito_color',
	name: 'Colore Ribbon Sito',
	inputType: InputTypes.COLOR,
	valueType: ValueTypes.STRING,
	inputHint: 'Colore Ribbon Sito',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito',
});

export {fieldsMap};
