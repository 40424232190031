import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { IPaginationResponse } from 'app/interfaces';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

export interface IBookingPreviewDialogData {
	recordId: string;
	parentDatasetACS?: DatasetActionContainerService;
}

@Component({
	selector: 'booking-preview-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: ['dialog.component.scss'],
	providers: [PageClosureContainerService, DatasetActionContainerService, DatasetRecordService]
})
export class BookingPreviewDialogComponent implements OnInit, OnDestroy{

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	get record(): any{ return this.datasetRS.record.value; }

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public appService: AppService,
		public datasetService: DatasetService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<BookingPreviewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IBookingPreviewDialogData,
		private fuseTranslationLoader: FuseTranslationLoaderService
	) {
		// carico traduzioni
		this.fuseTranslationLoader.loadTranslations(english, italiano);
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetRS.recordId = this.data.recordId;
		this.datasetRS.extraParams = {
			for_preview: 1
		};
		this.datasetACS.init({
			datasetCode: 'trip_bookings',
			actionCode: 'detail',
			parentDatasetACS: this.data.parentDatasetACS,
			fetchData: true,
			datasetRS: this.datasetRS
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe( ready => {
			if(!ready) return;
			this.datasetRS.init()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(readyRecordService => {
				if(!readyRecordService) return;
				this.datasetRS.reload();
			});
		});
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	idTrackBy(index: number, item: any): any{
		return item.id;
	}
}
