import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
var TextListInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TextListInputComponent, _super);
    function TextListInputComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.list = [];
        return _this;
    }
    TextListInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.list = _this.inputService.control.value;
        });
    };
    TextListInputComponent.prototype.addNewItem = function () {
        if (this.list == null) {
            this.list = [];
            this.inputService.control.setValue(this.list);
        }
        this.list.push('');
        this.inputService.formGroup.updateValueAndValidity();
    };
    TextListInputComponent.prototype.updateValue = function (i, event) {
        this.list[i] = event.target.value;
    };
    TextListInputComponent.prototype.removeItem = function (i) {
        this.list.splice(i, 1);
    };
    TextListInputComponent.prototype.trackByFn = function (index, item) {
        return index;
    };
    TextListInputComponent.prototype.ngOnChanges = function (changes) {
        if (this.inputService.control) {
            this.list = this.inputService.control.value;
        }
    };
    return TextListInputComponent;
}(DestroyableComponent));
export { TextListInputComponent };
