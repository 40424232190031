import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('title', {
	key: 'title',
	name: 'Titolo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

export {fieldsMap};
