import { Component, OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import createB2BCustomer from 'app/configs/datasets/entities/create-b2b-customer.action';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from '../i18n/en';
import { locale as italiano } from '../i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
	selector   : 'entity-b2b-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss']
})
export class EntityB2BStepComponent extends BaseStepViewComponent implements OnInit{
	
	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		private fuseTranslationLoader: FuseTranslationLoaderService
	){
		super(datasetACS, datasetNavigatorService);
		// carica traduzioni
		this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	ngOnInit(): void{
	}

	createB2BCustomer(): void{
		createB2BCustomer(this.datasetRS.record.value, this.datasetACS, this.datasetNavigatorService, null)
		.then(created => {
			if(created) this.datasetRS.reload();
		})
		.catch(e => {
			console.error(e);
		});
	}

	changeListMode(newMode): void{
		const loadingDialogRef = this.datasetNavigatorService.openDialog(LoadingDialogComponent, {
			title: 'Modifica in corso',
		});

		this.datasetNavigatorService.datasetService
		.post<any>('/dataset/entities/command/set_trip_package_list_mode', {
			entity_id: this.datasetRS.recordId,
			trip_package_list_mode: newMode
		}).pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetRS.reload();
				this.datasetNavigatorService.appService.showSuccessMessage(response.message || 'Modifica avvenuta con successo');
				loadingDialogRef.close();
			},
			error: (response) => {
				this.datasetNavigatorService.appService.showErrorMessage('Si è verificato un errore inaspettato');
				loadingDialogRef.close();
			}
		});
	}

	deleteUserB2b(): void {
		const loadingDialogRef = this.datasetNavigatorService.openDialog(LoadingDialogComponent, {
			title: 'Eliminazione in corso',
		});

		this.datasetNavigatorService.datasetService
		.post<any>('/dataset/entities/command/delete_site_user', {
			entity_id: this.datasetRS.recordId,
		}).pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetRS.reload();
				this.datasetNavigatorService.appService.showSuccessMessage(response.message || 'Eliminazione avvenuta con successo');
				loadingDialogRef.close();
			},
			error: (response) => {
				this.datasetNavigatorService.appService.showErrorMessage('Si è verificato un errore inaspettato');
				loadingDialogRef.close();
			}
		});
	}
}
