<div class="position-relative" [class.trip-booking-package-card-container]="!tripBookingPackage.parent_trip_booking_package_id">
	<div class="p-16">
		<ng-container *ngIf='!tripBookingPackage.parent_trip_booking_package_id'>
			<trip-booking-package-header
				icon="card_travel"
				[title]="tripBookingPackage.trip_package.description"
				[showInfo]="true"
				[canDelete]="canDelete"
				(infoClick)="viewTripPackage()"
				(deleteClick)='deleteTripBookingPackage()'
			></trip-booking-package-header>
		</ng-container>

		<div class="flex-row items-center justify-start flex-gap-16" *ngIf="tripBookingPackage.actual_trip">
			<div *ngIf='tripBookingPackage.actual_trip.type' class="icon-size-24">
				<ng-container *ngIf='["going", "single"].includes(tripBookingPackage.actual_trip.type)'>
					<span class="going_trip">
						<img src="assets/icons/material-icons/filled/md-pin.svg" />
					</span>
				</ng-container>
			</div>

			<div class="flex-row items-center justify-start flex-gap-16">
				<div class="date_times_trips">
					<ng-container *ngIf="tripBookingPackage.actual_trip.meeting_point_list_id">
						<span class='ml-4 mr-4'>{{'Ritiro' | translate}}: {{meetingPointDescription}}</span>
						<span><mat-spinner *ngIf='loadingMeetingPoint' diameter='20'></mat-spinner></span>
					</ng-container>
				</div>
			</div>
			<button mat-icon-button color='accent' *ngIf='!tripBookingPackage.tour_operator_booking_ref && !tripBookingPackage.has_resale_child' [matTooltip]='"Modifica punto di ritiro" | translate' (click)='editDefaultMeetingPoint()'>
				<mat-icon>edit</mat-icon>
			</button>
		</div>

		<div class="flex-row items-center justify-start flex-gap-16" *ngIf='tripBookingPackage.actual_trip && tripBookingPackage.actual_trip.meeting_point_list_id'>
			<div class="icon-size-24">
				<img src="assets/icons/material-icons/filled/going_trip_icon.svg"/>
			</div>

			<div class="meeting-point-description flex-row flex-wrap items-center justify-start flex-gap-4">
				<ng-container *ngIf='tripBookingPackage.actual_trip.start_day'>
					<span class="actual_trip_description" [matTooltip]='tripBookingPackage.actual_trip.display_name'>{{ tripBookingPackage.actual_trip.display_name }}</span>
					<span class="start_date_time">{{ 'da consegnare il:' | translate }} {{ tripBookingPackage.actual_trip.start_day | date }}</span>
				</ng-container>
				<span><mat-spinner *ngIf='loadingMeetingPoint' diameter='20'></mat-spinner></span>
			</div>

			<button mat-icon-button color='accent' *ngIf='!tripBookingPackage.tour_operator_booking_ref' [matTooltip]='"Modifica data di ritiro" | translate' (click)='openDialog()'>
				<mat-icon>edit</mat-icon>
			</button>
		</div>

		<ng-container *ngFor='let trip_booking_resource of tripBookingPackage.trip_booking_resources'>
			<ng-container *ngIf='trip_booking_resource.optional'>
				<trip-package-resource-booking
					[inputTripBookingResource]='trip_booking_resource'
				></trip-package-resource-booking>
			</ng-container>
		</ng-container>

		<p class="ml-4">{{'Stato Consegna:' | translate}} {{tripBookingPackage.delivery_status ? tripBookingPackage.delivery_status : 'standby'}}</p>
		<div class="trip_booking_package_actual_trip" *ngIf='tripBookingPackage.delivery_status'>
			<div div fxLayout="row">
				<div fxLayout="column" style="cursor: pointer ;">
					<div class="action-status-milano-card">
						<button class="mat-button ng-star-inserted" mat-flat-button *ngIf='tripBookingPackage.delivery_status === "standby"' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px" [style.margin-bottom]="'12px'">
							<span class="material-icons-outlined text-color-dark-blue">email</span>
							<span class="text-color-dark-blue ml-8" (click)="emailConfirmPlaceDate()">{{'Re-invia conferma luogo e data di ritiro' | translate}}</span>
						</button>
					</div>
					<div class="action-status-milano-card">
						<button class="mat-button ng-star-inserted" mat-flat-button *ngIf='tripBookingPackage.delivery_code' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" [style.margin-bottom]="'12px'">
							<span class="material-icons-outlined text-color-dark-blue">email</span>
							<span class="text-color-dark-blue ml-8" (click)="emailDeliveryCode()">{{'Re-invia codice: ' | translate}} {{ tripBookingPackage.delivery_code }}</span>
						</button>
					</div>
					<div class="action-status-milano-card">
						<button class="mat-button ng-star-inserted" mat-flat-button *ngIf='tripBookingPackage.delivery_status === "standby"' fxLayoutGap="8px">
							<span class="material-icons-outlined text-color-dark-blue">done</span>
							<span class="text-color-dark-blue ml-8" (click)="confirmPlaceDate()">{{'Cambia lo stato in: in consegna' | translate}}</span>
						</button>
					</div>
					<div class="action-status-milano-card">
						<button class="mat-button ng-star-inserted" mat-flat-button *ngIf='tripBookingPackage.delivery_status === "in consegna"' fxLayoutGap="8px">
							<span class="material-icons-outlined text-color-dark-blue">done</span>
							<span class="text-color-dark-blue ml-8" (click)="confirmDelivery()">{{'Cambia lo stato in: consegnato' | translate}}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<ng-container *ngFor="let childrenTripBookingPackage of tripBookingPackage.children_trip_booking_packages; let first = first; let last = last;">
		<hr/>
		<trip-booking-package 
			[tripBookingPackage]='childrenTripBookingPackage'
			[iterationData]="{is_first: first, is_last: last}"
		></trip-booking-package>
	</ng-container>

	<div *ngIf='!tripBookingPackage.parent_trip_booking_package_id' class="p-16">
		<trip-booking-package-related-buttons></trip-booking-package-related-buttons>
	</div>
</div>