import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('creation_mode', {
	key: 'creation_mode',
	name: 'Modalità creazione utente',
	inputType: InputTypes.RADIO,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Automatica',
			value: 0
		},
		{
			label: 'Manuale',
			value: 1
		}
	]
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	},
	defaultValue(dataContainerService: DatasetActionContainerService, options: any[], record: any): any{
		if (record && record.user){
			return record.user.name;
		}else{
			const parentDatasetACS = dataContainerService.parentDatasetACS;
			if (parentDatasetACS){
				const parentDatasetRS = parentDatasetACS.parentDatasetRS;
				if (parentDatasetRS){
					const parentRecordValue = parentDatasetRS.record.value;
					return parentRecordValue.description;
				}
			}
		}

		return '';
	}
});

fieldsMap.set('email', {
	key: 'email',
	name: 'Email',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	},
	defaultValue(dataContainerService: DatasetActionContainerService, options: any[], record: any): any{
		if (record && record.user){
			return record.user.email;
		}else{
			const parentDatasetACS = dataContainerService.parentDatasetACS;
			if (parentDatasetACS){
				const parentDatasetRS = parentDatasetACS.parentDatasetRS;
				if (parentDatasetRS){
					const parentRecordValue = parentDatasetRS.record.value;
					return parentRecordValue.primary_email;
				}
			}
		}

		return '';
	}
});

fieldsMap.set('access_username', {
	key: 'access_username',
	name: 'Nome Utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	}
});

fieldsMap.set('password', {
	key: 'password',
	name: 'Password',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING,
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	}
});

fieldsMap.set('password_confirm', {
	key: 'password_confirm',
	name: 'Conferma Password',
	inputType: InputTypes.PASSWORD,
	valueType: ValueTypes.STRING,
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	}
});

fieldsMap.set('branch.id', {
	key: 'branch.id',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	}
});

export {fieldsMap};
