import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from '../../../../services/app.service';

@Component({
	selector: 'trip-booking-summary',
	templateUrl: './trip-booking-summary.component.html',
	styleUrls: ['./trip-booking-summary.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class TripBookingSummaryComponent {
	@Input() record: any;
	@Input() extraParams: any;

	constructor(
		public datasetRS: DatasetRecordService,
		public appService: AppService
	) {}

	get tripBookingStatus(): string {
		return this.datasetRS.record.value.status;
	}

	get isMixBookingStatus(): boolean {
		return this.tripBookingStatus === 'mix';
	}

	get hasOptionalResourcesEnabled(): any {
		const optionalResources = {};
		for (const bookingPackage of this.datasetRS.record.value.packages) {
			const hasEnabledOptionalResource =
				bookingPackage.trip_booking_resources.filter(
					x => x.activated && x.optional
				).length > 0;
			if (hasEnabledOptionalResource) {
				optionalResources[bookingPackage.id] = true;
			}
		}

		return optionalResources;
	}
}
