import { Injectable } from '@angular/core';
import { DatasetActionContainerService } from './dataset-action-container.service';
import { DatasetService } from './dataset.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { IStepConfig } from 'app/interfaces';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IStepState{
	dataset_code: string;
	form_code: string;
	record_id: any;
	status: number;
}

@Injectable()
export class StepService{
	private LocalStorageOptionIdKey = 'stepService.optionId';
	
	public selectedOptionId: any;
	public selectedOption: BehaviorSubject<any>;
	public selectedAvailability: BehaviorSubject<any>;
	public ready: BehaviorSubject<boolean>;
	public stepsData: any = {};

	constructor(public datasetService: DatasetService, public datasetACS: DatasetActionContainerService){
		this.datasetACS.ready.subscribe(isReady => {
			if (isReady) this.init();
		});
	}

	init(): void{
		this.ready = new BehaviorSubject<boolean>(false);

		if (this.datasetACS.hasValueInKeyPath(this.datasetACS.datasetCode + '.recordId')){
			this.LocalStorageOptionIdKey = this.LocalStorageOptionIdKey + '.' + this.datasetACS.getValueFromKeyPath(this.datasetACS.datasetCode + '.recordId');
		}else if (this.datasetACS.parentDatasetACS){
			// tslint:disable-next-line: max-line-length
			this.LocalStorageOptionIdKey = this.LocalStorageOptionIdKey + '.' + this.datasetACS.parentDatasetACS.getValueFromKeyPath(this.datasetACS.parentDatasetACS.datasetCode + '.recordId');
		}
		// init option id from local storage
		this.selectedOptionId = localStorage.getItem(this.LocalStorageOptionIdKey);
		// instance selectedAvailability to store record
		this.selectedAvailability = new BehaviorSubject<any>(null);
		// instance selectedOption to store record
		this.selectedOption = new BehaviorSubject<any>(null);
	}

	reloadOption(): Observable<any>{
		if(!this.selectedOptionId) return of(null);
		
		// save into local storage
		localStorage.removeItem(this.LocalStorageOptionIdKey);
		localStorage.setItem(this.LocalStorageOptionIdKey, this.selectedOptionId);
		
		const result = new Subject();

		this.datasetACS.loading.next(true);
		//this.ready.next(false);
		this.datasetService.get<any>('/dataset/trip_options/detail/' + this.selectedOptionId, {with_relations: 'availability'})
		.subscribe({
			next: response => {
				this.selectedOption.next(response);
				result.next(response);
				result.complete();
				//this.ready.next(true);
				this.datasetACS.loading.next(false);
			},
			error: err => {
				this.selectedOption.next(null);
				result.next(null);
				result.complete();
				//this.ready.next(true);
				this.datasetACS.loading.next(false);
			}
		});
		return result;
	}

	initStepData(): BehaviorSubject<boolean>{
		if (this.datasetACS.actionCode === 'create'){
			this.ready.next(true);
			this.ready.complete();
			return this.ready;
		}

		const httpParams = {
			dataset_code: this.datasetACS.datasetCode,
			record_id: this.datasetACS.datasetRS.recordId
		};

		this.datasetService.get('/dataset/step_state', httpParams)
		.subscribe({
			next: (response) => {
				this.stepsData = response;
				this.ready.next(true);
				this.ready.complete();
			},
			error: (response) => {
				console.error(response);
				this.ready.next(true);
				this.ready.complete();
			}
		});
		return this.ready;
	}

	setStepState(data: IStepState, scenario: any = 'create'): void{
		const existingStep = this.stepsData[data.form_code];
		if (existingStep){
			if (existingStep.status === data.status) return;
			scenario = 'update/' + existingStep.id;
		}
		this.datasetService.post<any>('/dataset/step_state/' + scenario, data)
		.subscribe({
			next: (step) => {
				if (step) this.stepsData[step.form_code] = step;
			},
			error: (response) => {
				console.error(response);
			}
		});
	}

	isCompleted(form_code: string): boolean{
		if (this.stepsData[form_code]) return this.stepsData[form_code].status;
		return false;
	}
}
