<mat-card class='p-24' [class.mat-elevation-z0]='!elevate'>
	<mat-card-content>
		<data-container-viewer 
			[dataContainerService]='datasetACS' 
			[fields]='viewFormConfig.fields' 
			[record]='record'
		></data-container-viewer>
	</mat-card-content>
	<mat-card-actions [align]="'end'" *ngIf='showActions'>
		<button *ngIf='canEdit' (click)='onEdit()' mat-flat-button color='accent'>{{'Modifica' | translate}}</button>
	</mat-card-actions>
</mat-card>