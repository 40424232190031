<mat-card class='p-24' [class.mat-elevation-z0]='!elevate'>
	<mat-card-content>
		<data-container-viewer 
			[dataContainerService]='datACS' 
			[fields]='formConfig.fields' 
			[record]='record'
		></data-container-viewer>
	</mat-card-content>
	<mat-card-actions [align]="'end'">
		<button (click)='onPerformAction()' mat-flat-button color='accent'>{{btnText | translate}}</button>
	</mat-card-actions>
</mat-card>