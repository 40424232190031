import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { Subject } from 'rxjs';
import { WebSocketService } from 'app/services/websocket.service';
import { ISocketEvent } from 'app/interfaces';

export class BaseViewComponent implements OnInit, OnDestroy{

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected webSocketService?: WebSocketService
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		if (this.webSocketService && this.webSocketService.ioSocket){
			this.webSocketService.ioSocket.on('channel-event', this.onParticipantsUpdated.bind(this));
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		// remove socket listener
		if(this.webSocketService && this.webSocketService.ioSocket) this.webSocketService.ioSocket.off('actual_trip_participants');
	}

	onParticipantsUpdated(event: ISocketEvent): void{}
}
