import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';

@Component({
	selector   : 'not-found',
	templateUrl: './not-found.component.html',
	styleUrls  : ['./not-found.component.scss']
})
export class NotFoundComponent{
	constructor(
		private fuseTranslationLoader: FuseTranslationLoaderService,
		private _fuseSplashScreenService: FuseSplashScreenService,
		public router: Router
	){
		this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	ngOnInit(): void{
		//this._fuseSplashScreenService.hide();
		// hide template before hide the splash
	}
}
