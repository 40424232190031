import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

export {fieldsMap};
