import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('propagate_role_to_children', {
	key: 'propagate_role_to_children',
	name: 'Propagate role to children',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'NO',
			value: 0
		},
		{
			label: 'SI',
			value: 1
		}
	]
});

fieldsMap.set('domain_id', {
	key: 'domain_id',
	name: 'Dominio',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'domains',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.display_name;
		}
	}
});

fieldsMap.set('level', {
	key: 'level',
	name: 'Livello',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Un livello < 10 conferisce pieni poteri',
	defaultValue(): number{
		return 100;
	}
});

fieldsMap.set('role_type', {
	key: 'role_type',
	name: 'Tipologia ruolo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	options: [
		{
			label: 'Ruolo utente di dominio - Standard',
			value: 1
		},
		{
			label: 'Ruolo staff - con restrizioni da utente staff',
			value: 2
		}
	]
});

export {fieldsMap};
