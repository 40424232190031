<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field class='pb-4' [appearance]='inputService.inputConfig.appearence'>
		<mat-icon matPrefix class='mr-4' *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
		<mat-label>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<ng-container [formGroup]="inputService.formGroup">
			<input 
				matInput
				[required]='inputService.inputConfig.required'
				type='text'
				autocomplete="off"
				[name]='inputService.formControlKey'
				(focusout)='onFocusOut()'
				(blur)='onFocusOut()'
				[readonly]='inputService.inputConfig.readOnly || optionSelected' 
				[placeholder]="(inputService.inputConfig.placeholder || '') | translate" 
				[matAutocomplete]="auto"
				[formControl]='inputFormControl'
				[value]='displayValue'
				(input)='fetchFilteredOptions($event.target.value)'>
		</ng-container>
		<button mat-icon-button matSuffix *ngIf='optionSelected && !inputService.inputConfig.readOnly' (click)='selectDatasetOption(null); textSearch = null;'>
			<span class="material-icons">{{'close' | translate}}</span>
		</button>
		<mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<mat-hint *ngIf='creatingNew'>{{ (inputService.formInputDefinition.remoteOptions.createHint || "Verrà creato un nuovo record") | translate }}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
		<input type='hidden' [formControlName]='inputService.formControlKey' />
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)='selectDatasetOption($event.option.value)'>
			<mat-option *ngIf='supportCreate && textSearch' [value]="textSearch" (click)='emitEventOnSupportCreation($event)'>
				<mat-icon>add</mat-icon>
				<span>{{'Crea' | translate}}: {{textSearch}}</span>
			</mat-option>
			<mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByOptions" [value]="option">
				<mat-icon *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
				<span [innerHTML]='inputService.formInputDefinition.remoteOptions.getLabel(option)'></span>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</ng-container>