import { Component, OnInit, OnDestroy, ViewContainerRef, Output, EventEmitter } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ISelectDatasetRecordDialogData, SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { Observable, Subject } from 'rxjs';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
	selector   : 'dataset-search-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class DatasetSearchInputComponent extends DestroyableComponent implements OnInit, OnDestroy{
	public selectedRecord: any;
	public loading = false;
	public displayValue  = '';
	public previousValue: any = null;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		protected matDialog: MatDialog,
		public inputService: FormInputViewerService,
		private viewContainerRef: ViewContainerRef
	){
		super();
	}

	ngOnInit(): void{

		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.getSelectedRecord();

			this.previousValue = this.inputService.control.value;
			this.inputService.control.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				const currentValue = this.inputService.control.value;
				if(currentValue === this.previousValue) return;
				this.previousValue = currentValue;
				if(currentValue){
					if(!this.selectedRecord || currentValue !== this.selectedRecord.id){
						this.getSelectedRecord()
						.subscribe({
							next: () => {
								this.inputService.onSet(this.selectedRecord);
							}
						});
						return;
					}
				}
				this.inputService.onSet(this.selectedRecord);
			});
		});
	}

	getSelectedRecord(): Observable<any>{
		const resultSubject = new Subject<any>();
		const id = this.inputService.control.value;
		if(!id) {
			resultSubject.next(null);
			resultSubject.complete();
			return resultSubject;
		}
		let filters: any = null;
		if(this.inputService.formInputDefinition.remoteOptions && this.inputService.formInputDefinition.remoteOptions.getParams){
			filters = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
		}
		this.loading = true;
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.datasetService.get<any>('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + id, filters, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.selectedRecord = response;
				
				if(typeof(this.inputService.formInputDefinition.remoteOptions.getDisplay) === 'function'){
					this.displayValue = this.inputService.formInputDefinition.remoteOptions.getDisplay(response);
				}else{
					this.displayValue = this.inputService.formInputDefinition.remoteOptions.getLabel(response, this.inputService.dataContainerService);
				}
				
				this.loading = false;
				resultSubject.next(response);
				resultSubject.complete();
			},
			error: response => {
				this.loading = false;
				resultSubject.error(response);
				resultSubject.complete();
			}
		});
		return resultSubject;
	}

	openSelectionDialog(): void{
		let filters: any = null;
		if(this.inputService.formInputDefinition.remoteOptions && this.inputService.formInputDefinition.remoteOptions.getParams){
			filters = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
		}
		const data: ISelectDatasetRecordDialogData = {
			title: 'Seleziona',
			datasetCode: this.inputService.formInputDefinition.datasetCode,
			filters: filters,
			viewConfig: this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.listViewConfig,
			onFilersChanged: (data: any) => {
				this.inputService.emitCustomEvent('selectionFilterChanged', data);
			}
		};
		if (this.inputService.formInputDefinition && this.inputService.formInputDefinition.inputConfig && this.inputService.formInputDefinition.inputConfig.viewConfig){
			data['viewConfig'] = this.inputService.formInputDefinition.inputConfig.viewConfig;
		}
		if (_.has(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig.dialogConfig')){
			data['selectionBtnIcon'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.selectionBtnIcon;
			data['tableStyle'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.tableStyle;
			data['filterStyle'] = this.inputService.formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.filterStyle;
			data['selectionBtnTxt'] = _.get(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig.dialogConfig.selectionBtnTxt', undefined);
		}

		const params: MatDialogConfig = {data};

		const selectDialogConfig = _.get(this.inputService, 'formInputDefinition.inputConfig.selectDialogConfig');
		const selectDialogConfigMatDialogConfig = _.get(selectDialogConfig, 'matDialogConfig');
		if(selectDialogConfig && selectDialogConfig.passThrough){
			params.viewContainerRef = this.viewContainerRef;
		}

		if (selectDialogConfigMatDialogConfig){
			if(selectDialogConfigMatDialogConfig.width){
				params['width'] = selectDialogConfigMatDialogConfig.width.toString();
			}
			if(selectDialogConfigMatDialogConfig.panelClass){
				params['panelClass'] = selectDialogConfigMatDialogConfig.panelClass;
			}
			if(selectDialogConfigMatDialogConfig.minHeight){
				params['minHeight'] = selectDialogConfigMatDialogConfig.minHeight;
			}
			if(selectDialogConfigMatDialogConfig.id){
				params['id'] = selectDialogConfigMatDialogConfig.id;
			}
		}
		
		const dialogRef = this.matDialog.open(SelectDatasetRecordDialogComponent, params);

		dialogRef.afterClosed()
		.subscribe(result => {
			if(!result) return;
			this.selectedRecord = result;
			if(result){
				if(this.inputService.formInputDefinition.remoteOptions.getDisplay){
					this.displayValue = this.inputService.formInputDefinition.remoteOptions.getDisplay(result);
				}else if(this.inputService.formInputDefinition.remoteOptions.getLabel){
					this.displayValue = this.inputService.formInputDefinition.remoteOptions.getLabel(result, this.inputService.dataContainerService);
				}
			}
			this.inputService.control.setValue(result.id);
		});
	}

	unselect(): void{
		this.selectedRecord = null;
		this.displayValue = null;
		this.inputService.control.setValue(null);
	}
}
