import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackagesStepService } from "../trip-booking-packages-step.service";
import { takeUntil } from "rxjs/operators";
var NewTripBookingPackageSelectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NewTripBookingPackageSelectionComponent, _super);
    function NewTripBookingPackageSelectionComponent(tripBookingPackagesStepService) {
        var _this = _super.call(this) || this;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.selectionType = null;
        _this.selectionOptions = [];
        return _this;
    }
    NewTripBookingPackageSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tripBookingPackagesStepService.settingsUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.updateOptions();
        });
        this.updateOptions();
    };
    NewTripBookingPackageSelectionComponent.prototype.updateOptions = function () {
        var _this = this;
        var newSelectionOptions = [];
        if (this.tripBookingPackagesStepService.canAddTripBooking) {
            newSelectionOptions.push({
                value: 'trip_packages',
                icon: 'card_travel',
                label: 'Esperienze'
            });
        }
        if (this.tripBookingPackagesStepService.canAddProduct) {
            newSelectionOptions.push({
                value: 'products',
                icon: 'shopping_bag',
                label: 'Prodotti'
            });
        }
        if (this.tripBookingPackagesStepService.canAddTicket) {
            newSelectionOptions.push({
                value: 'milano_card',
                icon: 'card_giftcard',
                label: 'Milano Card'
            });
        }
        if (this.tripBookingPackagesStepService.canAddTrenitalia) {
            newSelectionOptions.push({
                value: 'trenitalia',
                icon: 'train',
                label: 'Biglietti Trenitalia'
            });
            newSelectionOptions.push({
                value: 'trenitalia-carnet',
                icon: 'confirmation_number',
                label: 'Carnet Trenitalia'
            });
        }
        this.selectionOptions = newSelectionOptions;
        if (this.selectionOptions.length == 1 && this.tripBookingPackagesStepService.hasParticipants) {
            this.selectionType = this.selectionOptions[0].value;
        }
        // check if selectionType exists in list
        if (this.selectionType && this.selectionOptions.findIndex(function (el) { return el.value == _this.selectionType; }) == -1 ||
            !this.tripBookingPackagesStepService.hasParticipants) {
            this.selectionType = null;
        }
    };
    return NewTripBookingPackageSelectionComponent;
}(DestroyableComponent));
export { NewTripBookingPackageSelectionComponent };
