import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { InConstructionComponent } from 'app/main/pages/in-construction/in-construction.component';
import { NotFoundComponent } from 'app/main/pages/not-found/not-found.component';
import { LoginComponent } from 'app/main/pages/authentication/login/login.component';
import { ForgotPasswordComponent } from 'app/main/pages/authentication/forgot-password/forgot-password.component';
import { CalendarComponent } from 'app/main/pages/calendar/calendar.component';
import { DocumentsPageComponent } from 'app/main/pages/documents/documents.component';
import { DefaultDatasetPageComponent } from 'app/main/pages/datasets/default-page/default-page.component';
import { DatasetResolver } from 'app/main/components/dataset/dataset.resolver';
import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { LoggedGuardService as LoggedGuard } from 'app/auth/logged-guard.service';
import { ResetPasswordComponent } from 'app/main/pages/authentication/reset-password/reset-password.component';
import { EditBookingPageComponent } from 'app/main/pages/edit-booking/edit-booking.component';
import { MarketplaceComponent } from 'app/main/pages/marketplace/marketplace.component';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { DashboardPageComponent } from 'app/main/pages/dashboard/dashboard.component';
import { SuperadminGuardService } from 'app/auth/superadmin-guard.service';
import { UtilitiesPageComponent } from 'app/main/pages/superadmin/utilities/utilities.component';
import { LogsPageComponent } from 'app/main/pages/superadmin/logs/logs.component';
import { DomainResolver } from 'app/resolvers/domain.resolver';
import { TourCMSMarketplacePageComponent } from 'app/main/pages/tourcms-marketplace/marketplace.component';
import { BokunMarketplacePageComponent } from 'app/main/pages/bokun-marketplace/marketplace.component';
import { PassportComponent } from 'app/main/pages/superadmin/passport/passport.component';
import { BIStatisticsPageComponent } from 'app/main/pages/bi-statistics/bi-statistics.component';
import { DashboardTotemPageComponent } from 'app/main/pages/dashboard-totem/dashboard-totem.component';
import {TicketlandiaMarketplacePageComponent} from "../main/pages/ticketlandia-marketplace/marketplace.component";

// disable reuse, we not need
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
	shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
	store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
	shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { return null; }
	shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		return curr.routeConfig === null && future.routeConfig === null;
	}
}

const routes: Routes = [
	{
		path: 'home',
		component: DashboardPageComponent,
		canActivate: [ AuthGuard ]
	},
	{ path: 'documents', component: DocumentsPageComponent, canActivate: [AuthGuard] },
	{ path: 'bi-statistics', component: BIStatisticsPageComponent, canActivate: [AuthGuard] },
	{ path: 'dashboard-totem', component: DashboardTotemPageComponent, canActivate: [AuthGuard] },
	{ path: 'auth/login', component: LoginComponent, canActivate: [LoggedGuard] },
	{ path: 'auth/logout', component: LoginComponent },
	{ path: 'auth/forgot-password', component: ForgotPasswordComponent },
	{ path: 'auth/reset-password', component: ResetPasswordComponent },
	{ path: 'calendar', component: CalendarComponent, resolve: {calendarData: CalendarService},  canActivate: [AuthGuard]},

	{ path: 'new-booking', component: EditBookingPageComponent, canActivate: [AuthGuard], resolve: { domainId: DomainResolver }, data: { is_new: true }},
	{ path: 'edit-booking/:id', component: EditBookingPageComponent, canActivate: [AuthGuard], data: { is_new: false }},

	{ path: 'marketplace', component: MarketplaceComponent, canActivate: [AuthGuard] },
	{ path: 'tourcms_marketplace', component: TourCMSMarketplacePageComponent, canActivate: [AuthGuard], resolve: {domainId: DomainResolver} },
	{ path: 'bokun_marketplace', component: BokunMarketplacePageComponent, canActivate: [AuthGuard], resolve: {domainId: DomainResolver} },
	{ path: 'ticketlandia_marketplace', component: TicketlandiaMarketplacePageComponent, canActivate: [AuthGuard], resolve: {domainId: DomainResolver} },

	
	{ path: 'dataset/list/:datasetCode', component: DefaultDatasetPageComponent, data: {action: 'list'}, resolve: {datasetData: DatasetResolver}, canActivate: [AuthGuard] },
	{ path: 'dataset/create/:datasetCode', component: DefaultDatasetPageComponent, data: {action: 'create'}, resolve: {datasetData: DatasetResolver}, canActivate: [AuthGuard] },
	{ path: 'dataset/edit/:datasetCode/:recordId', component: DefaultDatasetPageComponent, data: {action: 'edit'}, resolve: {datasetData: DatasetResolver}, canActivate: [AuthGuard] },
	{
		path: 'dataset/detail/:datasetCode/:recordId',
		component: DefaultDatasetPageComponent,
		data: {action: 'detail'},
		resolve: {datasetData: DatasetResolver},
		canActivate: [AuthGuard]
	},

	{ path: 'superadmin/logs', component: LogsPageComponent, canActivate: [AuthGuard, SuperadminGuardService] },
	{ path: 'superadmin/utilities', component: UtilitiesPageComponent, canActivate: [AuthGuard, SuperadminGuardService] },
	{ path: 'superadmin/passport', component: PassportComponent, canActivate: [AuthGuard, SuperadminGuardService] },

	{ path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'app', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthGuard] },
	{ path: 'incostruction', component: InConstructionComponent },
	{ path: 'notfound', component: NotFoundComponent },
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false,
			onSameUrlNavigation: 'ignore'
		})
	],
	providers: [{ provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }],
	exports: [RouterModule]
})
export class AppRoutingModule{}
