<h1 at-dialog-title>{{'Documentazione regole criteri' | translate}}</h1>
<mat-dialog-content>
	<h3>{{'VALORI' | translate}}</h3>
	<ul>
		<li>{{'ADT  numero di adult' | translate}}</li>
		<li>{{'CHD  numero di child' | translate}}</li>
		<li>{{'INF  numero di infant' | translate}}</li>
		<li>{{'SNR  numero di senior' | translate}}</li>
		<li>{{'PAX  numero di partecipanti della prenotazione' | translate}}</li>
		<li></li>
		<li>{{'DPR  data prenotazione' | translate}}</li>
		<li>{{'DCO  data conferma' | translate}}</li>
		<li>{{'DAN  data annullamento' | translate}}</li>
		<li>{{'DPA  data partenza/inizio' | translate}}</li>
		<li>{{'DRI  data ritorno/fine' | translate}}</li>
		<li>{{'OPA  ora inizio' | translate}}</li>
		<li>{{'ORI  ora fine' | translate}}</li>
		<li>{{'GIO  numero di giorni' | translate}}</li>
		<li>{{'NOT  totale di notti' | translate}}</li>
		<li></li>
		<!--<li>TPE  tipologia booking prenotazione (W=web, Z=interno)</li>-->
		<li>{{'CPA  codice pacchetto' | translate}}</li>
		
		<li>{{'LIS  codice listino del cliente permette di definire servizi diversi da addebitare in base al cliente' | translate}}</li>
		<li></li>
		<li>{{'TOT  totale/costo riga' | translate}}</li>
		<li>{{'PAC  totale/costo pacchetto' | translate}}</li>
		<li>{{'PRE  totale/costo prenotazione' | translate}}</li>
		<li></li>
		<li>{{'TRUE sempre vero' | translate}}</li>
		<li>{{'FALSE sempre falso' | translate}}</li>
	</ul>
	<mat-divider></mat-divider>
	<h3>{{'Funzioni' | translate}}</h3>
	<ul>
		<li>{{'date() data del sistema operativo' | translate}}</li>
		<li>{{'date(text) converte text in una data valida (es. 2019-01-01)' | translate}}</li>
		<li>{{'date_diff(d1, d2) ritorna la differenza in giorni tra la prima data (d1) e la seconda data (d2)' | translate}}</li>
	</ul>
	<h3>{{'Operatori aritmentici' | translate}}</h3>
	<ul>
		<li>{{'+ addizione' | translate}}</li>
		<li>{{'- sottrazione' | translate}}</li>
		<li>{{'* moltiplicazione' | translate}}</li>
		<li>{{'/ divisione' | translate}}</li>
		<li>{{'% modulo' | translate}}</li>
		<li>{{'** potenza' | translate}}</li>
	</ul>
	<h3>{{'Operatori logici' | translate}}</h3>
	<ul>
		<li>{{'AND oppure &&  per legare due condizioni che devono risultare vere' | translate}}</li>
		<li>{{'OR  oppure ||  per legare due condizioni di cui almeno una vera' | translate}}</li>
		<li>{{'NOT oppure !  per negare una condizione' | translate}}</li>
	</ul>
	<h3>{{'Operatori di comparazione' | translate}}</h3>
	<ul>
		<li>{{'a == b ritorna vero se a è uguale a b' | translate}}</li>
		<li>{{'a != b ritorna vero se a è diverso da b' | translate}}</li>
		<li>{{'a < b ritorna vero se a è minore a b' | translate}}</li>
		<li>{{'a > b ritorna vero se a è maggiore a b' | translate}}</li>
		<li>{{'a <= b ritorna vero se a è minore o uguale a b' | translate}}</li>
		<li>{{'a => b ritorna vero se a è maggiore o uguale a b' | translate}}</li>
		<li>{{'a matches b ritorna vero se a rispetta l\'espressione regolare b' | translate}}</li>
	</ul>
	<h3>{{'Operatori su array' | translate}}</h3>
	<ul>
		<li>{{'a in b  ritorna vero se a è contenuto nell\'array b' | translate}}</li>
		<li>{{'a notin b  ritorna vero se a non è contenuto nell\'array b' | translate}}</li>
	</ul>
	<mat-divider></mat-divider>
	<h3>{{'Esempi di confizioni' | translate}}</h3>
	<span class='example_rule mb-12'>
		<pre>CHD &#10095; 0</pre>
		{{'se nella prenotazione c\'è almeno un child' | translate}}
	</span>
	<span class='example_rule mb-12'>
		<pre>date_diff(DPA, DPR) &#10095;= 60</pre>
		{{'se fra la data partenza e la data di prenotazione ci sono almeno 60 giorni' | translate}}
	</span>
	<span class='example_rule mb-12'>
		<pre>GIO &#10095;= 7 AND GIO &#10094;= 15</pre>
		{{'se la durata della prenotazione è tra 7 e 15 giorni' | translate}}
	</span>
	<span class='example_rule mb-12'>
		<pre>DPA == date(2008-08-15)</pre>
		{{'se la data di partenza è nel griono 15/08/2008' | translate}}
	</span>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	
	<button mat-flat-button (click)='onYesClick()' color='accent' >{{'Chiudi' | translate}}</button>
</mat-dialog-actions>
