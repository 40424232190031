import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import {AuthService} from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

@Component({
	selector   : 'pratica-detail',
	templateUrl: './detail-view.component.html',
	// styleUrls  : ['./dataset-detail.component.scss'],
})
export class PraticaDetailComponent extends BaseViewComponent implements OnInit{

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService
	){
		super(datasetACS, datasetRS, datasetNavigatorService);
	}
}
