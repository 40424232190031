import { Component} from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'password-input',
	templateUrl: './input.component.html'
})
export class PasswordInputComponent{

	constructor(
		public inputService: FormInputViewerService
	){}
}
