import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, Injector } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription, Observable } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { AbstractControl } from '@angular/forms';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { MatSelect } from '@angular/material/select';
import { HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
	selector   : 'select-filterable-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class SelectFilterableInputComponent extends DestroyableComponent implements OnInit{
	public allSelectOptions: any[];
	public filteredSelectOptions: any[];
	public loading = false;
	public selectedItem: any;
	public intlCollator: Intl.Collator;
	public searchText: String;

	@ViewChild('myInput', { static: false }) 
	myInput: ElementRef;

	@ViewChild('matSelect', { static: false })
	matSelect: MatSelect;

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService,
		public injector: Injector
	){
		super();
		this.intlCollator = Intl.Collator(undefined, { sensitivity: 'base', usage: 'sort', ignorePunctuation: false});
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;

			if(_.has(this.inputService, 'formInputDefinition.extra.allOptions')){
				this.inputService.formInputDefinition.extra.allOptions(this.injector)
				.subscribe(options => {
					this.allSelectOptions = options;
					this.buildSelectResourceOptions();
					this.updateSelectedItem();
				});
			}else{
				console.warn('set formInputDefinition.extra.allOptions', this.inputService.formInputDefinition);
			}

			if(_.has(this.inputService, 'formInputDefinition.extra.loading')){
				this.inputService.formInputDefinition.extra.loading(this.injector)
				.subscribe(loading => {
					this.loading = loading;
				});
			}

			this.getFormControl().valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(value => {
				if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.onSet){
					this.inputService.formInputDefinition.extra.onSet(this.inputService.formGroup, this.getItemFromValue(value));
				}
				if(value){
					this.updateSelectedItem();
				}else{
					if(value === undefined){
						this.getFormControl().setValue(null);
						return;
					}
					this.updateSelectedItem();
				}	
			});
		});
	}

	updateSelectedItem(): void{
		const value = this.getFormControl().value;
		this.selectedItem = this.allSelectOptions.find(el => {
			return el.value === value;
		});
		this.buildSelectResourceOptions();
	}

	getFormControl(): AbstractControl{
		return this.inputService.control;
	}

	getItemFromValue(value: any): any{
		if(!this.allSelectOptions) return;
		const selectedOption = this.allSelectOptions.find(el => {
			return el.value === value;
		});
		return selectedOption;
	}

	buildSelectResourceOptions(): void{
		if(!this.allSelectOptions) return;
		this.loading = true;
		const searchLower = this.searchText && this.searchText.toLowerCase();
		const sortIndex = {};
		const options = this.allSelectOptions.filter((item) => {
			if(!searchLower) return true;
			if(this.selectedItem && this.selectedItem.value == item.value) return true;
			if(!item.label) return false;
			const nameIndex = item.label.toLowerCase().indexOf(searchLower);
			if(nameIndex != -1){
				sortIndex[item.value] = nameIndex;
				return true;
			}
			if(item.aliases && item.aliases.length){
				for (const alias of item.aliases) {
					if(alias.indexOf(searchLower) != -1){
						sortIndex[item.value] = -1;
						return true;
					}
				}
			}
			return false;
		}).sort((a, b) => {
			if(this.selectedItem){
				if(this.selectedItem.value == a.value) return -1;
				if(this.selectedItem.value == b.value) return 1;
			}
			if(!searchLower) return this.intlCollator.compare(a.label, b.label);
			const i = sortIndex[a.value];
			const j = sortIndex[b.value];
			if(i == j) return this.intlCollator.compare(a.label, b.label);
			return (i < j && i != -1) || j == -1 ? -1 : 1
		}).slice(0, 20);

		/*if(this.selectedItem && !options.find(o => o.value == this.selectedItem.value)){
			options.unshift(this.selectedItem);
		}*/
		this.filteredSelectOptions = options;
		this.loading = false;
	}

	search(text: string): void{
		this.searchText = text;
		this.buildSelectResourceOptions();
	}

	clear(): void{
		this.inputService.control.setValue(null);
		this.inputService.control.markAsDirty();
	}

	openedChange(isOpen: boolean): void {
		this.myInput.nativeElement.focus();
	}

	selectionChange(event: MatOptionSelectionChange) {
		this.appService.eventEmitter.emit({
			name: 'selected_label_' + this.inputService.formInputDefinition.key, 
			target: event.source['triggerValue']
		});
	}
}
