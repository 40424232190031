import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { appTemplateFieldsMap } from '../../fields/app/fields.template';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	appTemplateFieldsMap.get('domain_template'),
	appTemplateFieldsMap.get('scenario'),
];

const appTemplateForm: IFormConfig = { 
	editTitle: 'Modifica Configurazioni Template Dominio',
	fields: formFields 
};

export default appTemplateForm;
