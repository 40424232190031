<button class="btn-export" mat-raised-button color="accent" [matMenuTriggerFor]="appMenu">
	{{'Esportazione' | translate}}
</button>

<mat-menu #appMenu="matMenu">
	<button *ngFor="let btn of viewConfig.exportBtnActions"
		mat-menu-item
		class='mr-4'
		[matTooltip]='btn.tooltip' 
		(click)='downloadFile(btn)'>
		<mat-icon *ngIf='btn.buttonIcon'>{{btn.buttonIcon}}</mat-icon>
		<span *ngIf='btn.buttonText' [class.ml-4]='btn.buttonIcon'>{{btn.buttonText}}</span>
	</button>
</mat-menu>