<div class="page-layout p-24 blank" style="padding-bottom: 80px !important;">
	<mat-progress-bar *ngIf='loading || savingCredentials' class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
	<div *ngIf='!domainHasTourCMSAccount && !loading'>
		<h2>{{'Credenziali TourCMS' | translate}}</h2>
		<p>{{'Per rivendere i tour presenti su TourCMS è necessario un account Agente con accesso al marketplace' | translate}}.</p>
		<div class='tourcms-account-form'>
			<form-viewer
				#tourCMSAuthFormViewer
				[formConfig]='tourCMSAuthFormConfig'
			></form-viewer>
		
			<button mat-flat-button color='accent' [disabled]='savingCredentials' (click)='setTourCMSAccount()'>{{'Salva' | translate}}</button>
		</div>
	</div>
	<div *ngIf='domainHasTourCMSAccount && !loading'>
		<dataset-list
			datasetCode='tourcms_tours'
			tableStyle='style-1 selectable btn-outlined'
		></dataset-list>
	</div>
</div>