import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { ProductNameStepComponent } from '../product-name-step/step.component';
var ResellProductNameStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResellProductNameStepComponent, _super);
    function ResellProductNameStepComponent(parentDatasetRS, parentDatasetACS, datasetRS, datasetACS, appService, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService) {
        var _this = 
        /** init parent constructor */
        _super.call(this, appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService) || this;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this.formBuilder = formBuilder;
        _this.zone = zone;
        _this.stepService = stepService;
        _this.formViewerService = formViewerService;
        _this.datasetACS.init({
            datasetCode: 'trip_packages',
            actionCode: 'edit',
            parentDatasetACS: _this.parentDatasetACS,
            parentDatasetRS: _this.parentDatasetRS,
            datasetRS: _this.datasetRS,
            fetchData: false
        });
        _this.datasetACS.ready
            .pipe(takeUntil(_this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetRS.record.next(_this.parentDatasetACS.getValueFromKeyPath('reselledData'));
        });
        return _this;
    }
    return ResellProductNameStepComponent;
}(ProductNameStepComponent));
export { ResellProductNameStepComponent };
