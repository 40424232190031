/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../base-actions/list/dataset-list.component.ngfactory";
import * as i2 from "../../../../../../services/dataset-action-container.service";
import * as i3 from "../../../../../../../../../services/app.service";
import * as i4 from "../../../../../../../../pages/page-closure-container.service";
import * as i5 from "../../../../../../../../../services/auth.service";
import * as i6 from "../../../../../../services/dataset.service";
import * as i7 from "../../../../../../../../../services/domain-filter.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../../../base-actions/list/dataset-list.service";
import * as i10 from "../../../../../../base-actions/list/dataset-list.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../../../../services/dataset-navigator.service";
import * as i13 from "../../../../../../../../../../@fuse/services/translation-loader.service";
import * as i14 from "@angular/material/snack-bar";
import * as i15 from "../../../../../../../../../services/websocket.service";
import * as i16 from "../form-fixed-allotment/step.component.ngfactory";
import * as i17 from "../../../../../../../../../services/form-viewer.service";
import * as i18 from "@angular/forms";
import * as i19 from "../form-fixed-allotment/step.component";
import * as i20 from "../../../../../../services/dataset-record.service";
import * as i21 from "@angular/material/dialog";
import * as i22 from "../../../../../../services/step-state.service";
import * as i23 from "@angular/common";
import * as i24 from "./step.component";
var styles_ProductAdvancedBlockStepComponent = [];
var RenderType_ProductAdvancedBlockStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductAdvancedBlockStepComponent, data: {} });
export { RenderType_ProductAdvancedBlockStepComponent as RenderType_ProductAdvancedBlockStepComponent };
function View_ProductAdvancedBlockStepComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "dataset-list", [], null, [[null, "created"], [null, "datasetEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("created" === en)) {
        var pd_0 = (_co.onCreatedInBlock(_v.parent.context.blockConfig) !== false);
        ad = (pd_0 && ad);
    } if (("datasetEvent" === en)) {
        var pd_1 = (_co.onDatasetEvent($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DatasetListComponent_0, i1.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i2.DatasetActionContainerService, i2.DatasetActionContainerService, [i3.AppService, i4.PageClosureContainerService, i5.AuthService, i6.DatasetService, i7.DomainFilterService, i8.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i9.DatasetListService, [i2.DatasetActionContainerService, i6.DatasetService]), i0.ɵdid(3, 770048, [[1, 4], ["listBlocks", 4]], 0, i10.DatasetListComponent, [i11.ActivatedRoute, i6.DatasetService, i11.Router, i5.AuthService, i3.AppService, i2.DatasetActionContainerService, i12.DatasetNavigatorService, i7.DomainFilterService, "ListParamService", i13.FuseTranslationLoaderService, i14.MatSnackBar, i15.WebSocketService], { autoFetchData: [0, "autoFetchData"], showTitle: [1, "showTitle"], datasetCode: [2, "datasetCode"], parentDatasetACS: [3, "parentDatasetACS"], parentDatasetRS: [4, "parentDatasetRS"], filters: [5, "filters"], showSearchBar: [6, "showSearchBar"], elevate: [7, "elevate"], conditionalShowPaginationToolbar: [8, "conditionalShowPaginationToolbar"] }, { created: "created", datasetEvent: "datasetEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = (_co.enableBlocks.length > 1); var currVal_2 = _v.parent.context.blockConfig.datasetCode; var currVal_3 = _co.datasetACS; var currVal_4 = _co.datasetRS; var currVal_5 = _co.blockFilters[_v.parent.context.index]; var currVal_6 = false; var currVal_7 = false; var currVal_8 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_ProductAdvancedBlockStepComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "form-fixed-allotment", [], null, [[null, "nextStep"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextStep" === en)) {
        var pd_0 = (_co.stepper.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ProductFixedAllotmentComponent_0, i16.RenderType_ProductFixedAllotmentComponent)), i0.ɵprd(131584, null, i17.FormViewerService, i17.FormViewerService, [i3.AppService, i18.FormBuilder, i0.NgZone]), i0.ɵdid(2, 4440064, null, 0, i19.ProductFixedAllotmentComponent, [i3.AppService, i20.DatasetRecordService, i2.DatasetActionContainerService, i6.DatasetService, i12.DatasetNavigatorService, i21.MatDialog, i18.FormBuilder, i0.NgZone, i22.StepService, i17.FormViewerService], { stepConfig: [0, "stepConfig"], hasNext: [1, "hasNext"], hasPrevious: [2, "hasPrevious"] }, { nextStep: "nextStep" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProductAdvancedBlockStepComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ": ", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Blocco non configurato")); var currVal_1 = _v.parent.context.blockConfig.type; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ProductAdvancedBlockStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i23.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductAdvancedBlockStepComponent_2)), i0.ɵdid(3, 278528, null, 0, i23.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i23.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductAdvancedBlockStepComponent_3)), i0.ɵdid(5, 278528, null, 0, i23.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i23.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductAdvancedBlockStepComponent_4)), i0.ɵdid(7, 16384, null, 0, i23.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i23.NgSwitch], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.blockConfig.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "calendar"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "form"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_ProductAdvancedBlockStepComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i23.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { blockConfig: 0, inTab: 1, index: 2 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit, false, _v.parent.context.index); var currVal_1 = i0.ɵnov(_v.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProductAdvancedBlockStepComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductAdvancedBlockStepComponent_6)), i0.ɵdid(2, 16384, null, 0, i23.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableBlocks[_v.context.index]; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProductAdvancedBlockStepComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { listBlocks: 1 }), (_l()(), i0.ɵand(0, [["blockTemplate", 2]], null, 0, null, View_ProductAdvancedBlockStepComponent_1)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProductAdvancedBlockStepComponent_5)), i0.ɵdid(5, 278528, null, 0, i23.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig.options.blocks; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProductAdvancedBlockStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "product-advanced-blocks-step", [], null, null, null, View_ProductAdvancedBlockStepComponent_0, RenderType_ProductAdvancedBlockStepComponent)), i0.ɵdid(1, 4440064, null, 0, i24.ProductAdvancedBlockStepComponent, [i3.AppService, i20.DatasetRecordService, i2.DatasetActionContainerService, i12.DatasetNavigatorService, i6.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductAdvancedBlockStepComponentNgFactory = i0.ɵccf("product-advanced-blocks-step", i24.ProductAdvancedBlockStepComponent, View_ProductAdvancedBlockStepComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { ProductAdvancedBlockStepComponentNgFactory as ProductAdvancedBlockStepComponentNgFactory };
