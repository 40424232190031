import { Component, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil, finalize } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { IFieldDefinition } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import FilterFields from './form.fields';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import * as moment from 'moment';

export interface IpriceRule {
	price: any;
	option_id: any;
	valid_from: any;
	valid_to: any;
}

export interface IAge {
	field: string;
	from: number;
	to: number;
}

export interface IPriceServiceData {
	title: string;
	code: string;
	formArrayName: string;
	canBeRemoved: boolean;
	age: IAge;
	defaultPriceRule: IpriceRule;
	price_rules?: IpriceRule[];
}

export interface PriceServices {
	FIXED1: IPriceServiceData;
	ADT: IPriceServiceData;
	CHD: IPriceServiceData;
	INF: IPriceServiceData;
	SNR: IPriceServiceData;
}

@Component({
	selector   : 'price-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss']
})

export class PriceStepComponent extends BaseStepViewComponent implements OnInit{
	now = moment(new Date()).format('YYYY-MM-DD 00:00:00');

	public selectedTripServiceRule: any;
	public loadedTripServiceRule: any;

	public filterFormGroup: FormGroup;

	private priceServicesRemoved: IPriceServiceData[] = [];
	private priceRulesRemoved: IpriceRule[] = [];
	public priceServices: IPriceServiceData[] = [];
	public priceServicesCollection: any = {};
	public fileterFields = FilterFields;

	// definire servizio??
	mockServices: PriceServices = {
		FIXED1: {
			title: 'Prezzi gruppo',
			canBeRemoved: false,
			code: 'FIXED1',
			formArrayName: 'FIXED1',
			age: {field: 'age_adt', from: 0, to: 0},
			defaultPriceRule: {
				price: null, 
				option_id: null, 
				valid_from: this.now, 
				valid_to: null
			},
		},
		ADT: {
			title: 'Prezzo Adulto',
			canBeRemoved: false,
			code: 'ADT',
			formArrayName: 'ADT',
			age: {field: 'age_adt', from: 18, to: 64},
			defaultPriceRule: {
				price: null, 
				option_id: null, 
				valid_from: this.now, 
				valid_to: null
			},
		},
		CHD: {
			title: 'Prezzo Bambino',
			canBeRemoved: true,
			code: 'CHD',
			formArrayName: 'CHD',
			age: {field: 'age_chd', from: 2, to: 17},
			defaultPriceRule: {
				price: null, 
				option_id: null, 
				valid_from: this.now, 
				valid_to: null
			},
		},
		INF: {
			title: 'Prezzo Neonato',
			canBeRemoved: true,
			code: 'INF',
			formArrayName: 'INF',
			age: {field: 'age_inf', from: 0, to: 1},
			defaultPriceRule: {
				price: null, 
				option_id: null, 
				valid_from: this.now, 
				valid_to: null
			},
		},
		SNR: {
			title: 'Prezzo Senior',
			canBeRemoved: true,
			code: 'SNR',
			formArrayName: 'SNR',
			age: {field: 'age_snr', from: 65, to: 99},
			defaultPriceRule: {
				price: null, 
				option_id: null, 
				valid_from: this.now, 
				valid_to: null
			},
		}
	};

	buttons: any = {
		CHD: {title: 'CHILDREN'},
		INF: {title: 'INFANT'},
		SNR: {title: 'SENIOR'}
	};

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		protected formBuilder: FormBuilder,
		protected stepService: StepService,
	) {
		super(datasetACS, datasetNavigatorService);
	}

	getBaseRoute(): string{
		return '/dataset/gyg_based_trip_services';
	}

	getHttpHeaders(): HttpHeaders {
		return this.datasetACS.getContextualDomainHeaders();
	}

	canShowAge(): boolean{
		return Object.keys(this.priceServicesCollection).length > 1;
	}

	processResponse(response: PriceServices): void {
		const keys = Object.keys(response);

		keys.forEach((key) => {
			const obj: IPriceServiceData = response[key];
			this.buildForm(obj);
		});
	}

	initFormGroup(): void {
		this.filterFormGroup = this.formBuilder.group({
			age_adt: new FormControl(),
			age_chd: new FormControl(),
			age_inf: new FormControl(),
			age_snr: new FormControl(),
			ADT: this.formBuilder.array([]),
			CHD: this.formBuilder.array([]),
			INF: this.formBuilder.array([]),
			SNR: this.formBuilder.array([]),
			FIXED1: this.formBuilder.array([])
		});
	}

	setUpPriceTiers(group: string, priceRule?: IpriceRule): void {
		if (!priceRule) priceRule = this.mockServices[group].defaultPriceRule;
		this.priceRules(group).push(this.formBuilder.group({
			price: [priceRule.price, Validators.required],
			option_id: priceRule.option_id,
			valid_from: [priceRule.valid_from, Validators.required],
			valid_to: priceRule.valid_to
		}));
	}
	
	priceRules(group: string): FormArray {
		return <FormArray>this.filterFormGroup.get(group);
	}

	ngOnInit(): void {
		this.initFormGroup();
		this.loadPricing((this.datasetRS.record.value.trip_service_rule_type == '0') ? 'ADT' : 'FIXED1');
	}

	loadPricing(tripServiceRuleId: any): void{
		const httpParams: any = {trip_package_id: this.datasetRS.recordId};

		this.datasetACS.loading.next(true);
		this.datasetService.get<PriceServices>(this.getBaseRoute(), httpParams, this.getHttpHeaders())
		.pipe(takeUntil(this._unsubscribeAll),
			finalize(() => { 
				if (Object.keys(this.priceServicesCollection).length === 0) this.buildForm(tripServiceRuleId); 
			})
		).subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.processResponse(response);
			},
			error: () => {
				this.datasetACS.loading.next(false);
			}
		}); 
	}

	getPriceService(priceService: IPriceServiceData|string): IPriceServiceData {
		let code: string;
		let priceServ: IPriceServiceData;
		if (typeof priceService === 'object') {
			code = priceService.code;
			priceServ = priceService;
		} else {
			code = priceService;
			priceServ = this.mockServices[code];
		}
		return priceServ;
	}

	buildForm(priceService: IPriceServiceData|string): void {
		const priceServ: IPriceServiceData = this.getPriceService(priceService);

		if (!this.priceServicesCollection[priceServ.code]) {
			priceServ.age = {
				field: this.mockServices[priceServ.code].age.field,
				from: priceServ.age.from,
				to: priceServ.age.to
			};

			this.setAgeRange(priceServ);

			this.filterFormGroup.addControl(priceServ.age.field, new FormControl(priceServ.age.to));
			const ageFormField = this.filterFormGroup.get(priceServ.age.field);

			this.fixAgeField(this.filterFormGroup.get(priceServ.age.field).value, priceServ, true);

			ageFormField.valueChanges.subscribe(value => {
				this.fixAgeField(value, priceServ);
			});

			if (!priceServ.price_rules) {
				this.setUpPriceTiers(priceServ.code, priceServ.defaultPriceRule);
			} else {
				for (const priceRule of priceServ.price_rules) this.setUpPriceTiers(priceServ.code, priceRule);
			}
			
			this.priceServicesCollection[priceServ.code] = priceServ;
			delete this.buttons[priceServ.code];

			this.sortOrder();
		}
	}

	sortOrder(): void{
		this.priceServicesCollection = {
			...(this.priceServicesCollection['INF'] ? {'INF': this.priceServicesCollection['INF']} : null),
			...(this.priceServicesCollection['CHD'] ? {'CHD': this.priceServicesCollection['CHD']} : null),
			...(this.priceServicesCollection['ADT'] ? {'ADT': this.priceServicesCollection['ADT']} : null),
			...(this.priceServicesCollection['SNR'] ? {'SNR': this.priceServicesCollection['SNR']} : null),
			...(this.priceServicesCollection['FIXED1'] ? {'FIXED1': this.priceServicesCollection['FIXED1']} : null)
		};
	}

	formatLabel(value: number): string {
		if (value >= 99)  return '99+';
		return value.toString();
	}

	fixAgeField(value: number, priceService: IPriceServiceData, withSnr: boolean = false): void{
		if (value === 99) return;
		if (priceService.code === 'CHD'){
			this.priceServicesCollection['ADT'].age.from = (value + 1);

			if (value >= this.filterFormGroup.get('age_adt').value){
				this.filterFormGroup.get('age_adt').setValue(value + 1);
			}
		}
		if (priceService.code === 'INF' && this.priceServicesCollection['CHD']) {
			this.priceServicesCollection['CHD'].age.from = (value + 1);

			if (value >= this.filterFormGroup.get('age_chd').value){
				this.filterFormGroup.get('age_chd').setValue(value + 1);
			}
		} else if (priceService.code === 'INF' && !this.priceServicesCollection['CHD']){
			this.priceServicesCollection['ADT'].age.from = (value + 1);

			if (value >= this.filterFormGroup.get('age_adt').value){
				this.filterFormGroup.get('age_adt').setValue(value + 1);
			}
		}
		if (priceService.code === 'ADT' && this.priceServicesCollection['SNR']){
			this.priceServicesCollection['SNR'].age.from = (value + 1);

			if (value >= this.filterFormGroup.get('age_snr').value){
				this.filterFormGroup.get('age_snr').setValue(value + 1);
			}
		}
	}

	setAgeRange(priceServ: IPriceServiceData, withoutSetValue: boolean = false): void {
		const ageField: IFieldDefinition = this.fileterFields.get(priceServ.age.field);

		ageField.options = [];
		for (let i = priceServ.age.from; i <= 99; i++) {
			ageField.options.push({label: i.toString(), value: i});
		}
		if (!withoutSetValue) this.filterFormGroup.get(priceServ.age.field).setValue(priceServ.age.to);
	}

	isDisabledButton(): boolean {
		let isDisabledButton = true;
		if (this.priceServicesRemoved.length > 0 && this.filterFormGroup.valid) {
			isDisabledButton = false;
		} else if (this.priceRulesRemoved.length > 0 && this.filterFormGroup.valid) {
			isDisabledButton = false;
		} else if (this.filterFormGroup.valid && this.filterFormGroup.dirty) {
			isDisabledButton = false;
		}
		return isDisabledButton;
	}

	removePriceService(priceService: IPriceServiceData): void {
		this.priceServices = this.priceServices.filter((x: IPriceServiceData) => x.code !== priceService.code);
		delete this.priceServicesCollection[priceService.code]; // rimozione oggetto
		this.buttons[priceService.code] = priceService.title;

		this.clearFormArray(this.priceRules(priceService.code));
		this.filterFormGroup.get(priceService.age.field).setValue(null);

		if (priceService.code === 'SNR') this.filterFormGroup.get('age_adt').setValue(priceService.age.to);

		if (priceService.code === 'CHD' && this.priceServicesCollection['INF']) {
			this.priceServicesCollection['INF'].age.from = priceService.age.from;
		} else if (priceService.code === 'CHD' && !this.priceServicesCollection['INF']){
			this.priceServicesCollection['ADT'].age.from = priceService.age.from;
		}

		if (priceService.code === 'INF' && this.priceServicesCollection['CHD']){
			this.priceServicesCollection['CHD'].age.from = priceService.age.from;
		} else if (priceService.code === 'INF' && !this.priceServicesCollection['CHD']){
			this.priceServicesCollection['ADT'].age.from = priceService.age.from;
		}

		this.priceServicesRemoved.push(priceService);
	}

	removePriceRule(priceService: IPriceServiceData, i: number): void {
		this.priceRules(priceService.code).removeAt(i);
		this.priceRulesRemoved.push(this.filterFormGroup.get(priceService.code).value[i - 1]);
	}

	clearFormArray = (formArray: FormArray) => {
		while (formArray.length) {
			formArray.removeAt(formArray.length - 1);
		}
	}

	beforeSave(formData: any): any{
		if (this.priceServicesCollection['INF']) formData['age_from_inf'] = this.priceServicesCollection['INF'].age.from;
		if (this.priceServicesCollection['CHD']) formData['age_from_chd'] = this.priceServicesCollection['CHD'].age.from;
		if (this.priceServicesCollection['ADT']) formData['age_from_adt'] = this.priceServicesCollection['ADT'].age.from;
		if (this.priceServicesCollection['SNR']) formData['age_from_snr'] = this.priceServicesCollection['SNR'].age.from;
		return formData;
	}
	
	onSave(): void{
		this.filterFormGroup.disable();
		let formData = this.filterFormGroup.getRawValue();

		this.datasetACS.loading.next(true);
		const route = this.getBaseRoute() + '/create';

		formData['trip_package_id'] = this.datasetRS.recordId;
		formData = this.beforeSave(formData);

		this.datasetService.post<PriceServices>(route, formData, {headers: this.getHttpHeaders()})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				if(this.filterFormGroup){
					this.filterFormGroup.enable();
					this.filterFormGroup.reset();
				}

				this.priceServicesCollection = {};
				this.initFormGroup();
				this.processResponse(response);
				
				setTimeout(() => {
					this.stepService.setStepState({
						dataset_code: 'trip_packages',
						form_code: 'prices',
						record_id: this.datasetRS.recordId,
						status: 1
					});

					this.goNextStep();
				}, 200);
			},
			error: () => {
				this.datasetACS.loading.next(false);
				if(this.filterFormGroup){
					this.filterFormGroup.enable();
				}
			}
		});
	}
}
