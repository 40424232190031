import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		title: 'Orari apertura e chiusura del punto di ritiro',
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('time')
		]
	}
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;