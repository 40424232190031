<div class='language-select_container'>
	<span class='secondary-text language-select_label' *ngIf='label'>{{label}}</span>
	<div class='language-select_lang-container'>
		<span 
			[matTooltip]='appService.supportedLanguages[languageCode] && appService.supportedLanguages[languageCode].description'
			class='language-select_lang' 
			(click)='onClick(languageCode)' 
			[class.active]='activeLanguageCodes && activeLanguageCodes.includes(languageCode)' 
			[class.selected]='languageCode == selected' 
			*ngFor='let languageCode of languageCodes'>
			<img [src]='"assets/images/flags/4x3/" + languageCode.toLocaleLowerCase() + ".svg"' />
		</span>
	</div>
</div>