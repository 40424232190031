import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import fieldsMap from './filter.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('departure_date_from'),
	fieldsMap.get('departure_date_to'),
	fieldsMap.get('booking_date_from'),
	fieldsMap.get('booking_date_to'),
	fieldsMap.get('package_code'),
	fieldsMap.get('category_id'),
	fieldsMap.get('customer_code'),
	fieldsMap.get('branch_code'),
];

const config: IFormConfig = { 
	title: 'Filtra grafici',
	fields: formFields 
};

export default config;
