import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { GOOGLE_API_KEY } from 'app/app.module';
import { FormControl } from '@angular/forms';

@Component({
	selector   : 'common-map-step',
	templateUrl: './step.component.html',
	styleUrls: ['./step.component.scss']
})
export class CommonMapStepComponent extends BaseStepViewComponent implements OnInit{
	
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;
	public formErrors: any;
	public markers: IMapMarker[] = [];
	// google maps zoom level
	public zoom = 8;
	// initial center position for the map
	public lat = 45.468702;
	public lng = 9.190789;
	public mapReady = new BehaviorSubject<boolean>(false);
	public searchTextFormControl = new FormControl('');
	public selectedPositionInfo: google.maps.GeocoderResult;
	public geosearching = false;

	get record(): any{
		return this.datasetRS.record.value;
	}

	get staticMapUrl(): string{
		return this.record.point_map_url;
	}

	get savedCoords(): any{
		if(!this.record) return null;
		if(!this.record.properties) return null;
		if(!this.record.properties.geo_position || !this.record.properties.geo_position.value) return null;
		if(typeof(this.record.properties.geo_position.value) === 'string') return JSON.parse(this.record.properties.geo_position.value); 
		return this.record.properties.geo_position.value;
	}

	get datasetCode(): string{
		return this.datasetACS.datasetCode;
	}
	get address(): string{
		if(['meeting_points', 'trip_destinations'].includes(this.datasetCode)){
			return this.reduceAddress(this.record && this.record.address);
		}else{
			return null;
		}
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		protected matDialog: MatDialog,
		public mapAPILoader: MapsAPILoader
	){
		super(datasetACS, datasetNavigatorService);
	}

	reduceAddress(obj: any): string{
		let address = '';
		if(obj){
			if(obj.street) address += obj.street;
			if(obj.number) address += ' ' + obj.number;
			if(address && (obj.city || obj.province || obj.zip_code || obj.region || obj.nation_code)) address += ', ';
			if(obj.city) address += ' ' + obj.city;
			if(obj.province) address += ' (' + obj.province + ')';
			if(obj.zip_code) address += ' ' + obj.zip_code;
			if(obj.region) address += ' ' + obj.region;
			if(obj.nation_code) address += ' ' + obj.nation_code;
		}
		return address;
	}

	ngOnInit(): void{
		super.ngOnInit();

		this.initialLocation()
		.catch(e => {
			console.error(e);
		});
	}

	search(): void{
		if(!this.searchTextFormControl.value || this.searchTextFormControl.value.length < 2) return;
		this.geosearching = true;
		this.getAddressInfo(this.searchTextFormControl.value)
		.then(result => {
			this.setPosition(result);
			this.geosearching = false;
		}, error => {
			this.geosearching = false;
		});
	}

	async initialLocation(): Promise<void>{
		await this.mapAPILoader.load();

		if(this.savedCoords && this.savedCoords.lat && this.savedCoords.lng){
			this.setCoordinate(this.savedCoords.lat, this.savedCoords.lng);
			if(this.savedCoords.zoom) this.zoom = this.savedCoords.zoom;
		}else{
			this.getAddressInfo(this.address)
			.then(result => {
				this.setPosition(result);
			}, error => {

			});
		}
	}

	setMarker(data: any): void{
		if(this.markers.length){
			this.markers[0].lat = data.lat;
			this.markers[0].lng = data.lng;
			this.markers[0].label = data.label;
		}else{
			this.markers.push({
				lat: data.lat,
				lng: data.lng,
				draggable: true,
				label: data.label
			});
		}
		this.lat = this.markers[0].lat;
		this.lng = this.markers[0].lng;
		if(this.zoom < 12 )this.zoom = 16;
		this.fetchMarkerInfo(this.markers[0]);
	}

	async getAddressInfo(address): Promise<google.maps.GeocoderResult>{
		await this.mapAPILoader.load();
		const geocoder: google.maps.Geocoder = new window['google'].maps.Geocoder();
		return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
			geocoder.geocode({ 
				address
			}, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus): void => {
				if(status === google.maps.GeocoderStatus.OK){
					if(results.length > 0 && results[0]){
						return resolve(results[0]);
					}
				}
				reject();
			});
		});
	}

	async getCoordinateInfo(lat, lng): Promise<google.maps.GeocoderResult>{
		await this.mapAPILoader.load();
		const geocoder: google.maps.Geocoder = new window['google'].maps.Geocoder();
		return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
			geocoder.geocode({ 
				location: {
					lat, lng
				}
			}, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus): void => {
				if(status === google.maps.GeocoderStatus.OK){
					if(results.length > 0 && results[0]){
						return resolve(results[0]);
					}
				}
				reject();
			});
		});
	}

	async fetchSelectedPositionInfo(lat, lng): Promise<void>{
		this.selectedPositionInfo = await this.getCoordinateInfo(lat, lng);
	}

	async fetchMarkerInfo(marker: IMapMarker): Promise<void>{
		this.selectedPositionInfo = await this.getCoordinateInfo(marker.lat, marker.lng);
		marker.label = this.selectedPositionInfo.formatted_address;
		marker.meta = this.selectedPositionInfo;
	}

	mapClicked(event: MouseEvent): void{
		this.setCoordinate(event.coords.lat, event.coords.lng);
	}

	clickedMarker(label: string, index: number): void{
	}

	markerDragEnd(m: IMapMarker, $event: MouseEvent): void{
		this.setMarker({
			lat: $event.coords.lat,
			lng: $event.coords.lng
		});
	}

	generateStaticMap(): string{
		if(!this.selectedPositionInfo) return null;
		let url = 'https://maps.googleapis.com/maps/api/staticmap?';
		url += '&zoom=' + this.zoom;
		url += '&size=600x300';
		url += '&maptype=roadmap';
		if(this.markers && this.markers.length > 0){
			for (const marker of this.markers) {
				url += '&markers=color:red|' + marker.lat + ',' + marker.lng;
			}
		}else{
			url += '&center=' + this.selectedPositionInfo.geometry.location.lat() + ',' + this.selectedPositionInfo.geometry.location.lng();
		}
		
		url += '&key=' + GOOGLE_API_KEY;

		return url;
	}

	onSave(): void{
		if(!this.selectedPositionInfo) return;
		let message = 'Vuoi salvare solo la mappa o anche l\'indirizzo?';
		if(this.address) message += '<br>Indirizzo attuale: "' + this.address + '" ';
		const newAddressObj = this.componentsToAddressObject(this.selectedPositionInfo.address_components);
		message += '<br>Nuovo Indirizzo: "' + this.reduceAddress(newAddressObj) + '"';

		this.matDialog.open(ConfirmDialogComponent, {
			minWidth: '550px',
			data: {
				contentMessage: message,
				positiveText: 'Salva tutto',
				negativeText: 'Annulla',
				neutralText: 'Solo la mappa'
			}
		}).afterClosed()
		.subscribe(result => {
			if(result === false) return;
			this.execSave(result === true ? 'full' : 'only');
		});
		
	}

	componentsToAddressObject(components: google.maps.GeocoderAddressComponent[]): any{
		const addressObj: any = {};

		for (const component of components) {
			if(component.types.includes('street_number')){
				addressObj.number = component.long_name;
			}else if(component.types.includes('route')){
				addressObj.street = component.long_name;
			}else if(component.types.includes('administrative_area_level_3')){
				addressObj.city = component.long_name;
			}else if(component.types.includes('locality')){
				addressObj.locality = component.long_name;
			}else if(component.types.includes('administrative_area_level_2')){
				addressObj.province = component.short_name;
			}else if(component.types.includes('administrative_area_level_1')){
				addressObj.region = component.long_name;
			}else if(component.types.includes('country')){
				addressObj.nation_code = component.short_name;
			}else if(component.types.includes('postal_code')){
				addressObj.zip_code = component.long_name;
			}
		}

		return addressObj;
	}

	execSave(saveType: string): void{
		let formData = { 
			recordId: this.datasetRS.recordId, 
			saveType,
			geoPosition: {
				lat: this.selectedPositionInfo.geometry.location.lat(),
				lng: this.selectedPositionInfo.geometry.location.lng(),
				zoom: this.zoom
			},
			staticMapURL: this.generateStaticMap()
		};
		
		if(this.stepConfig.options && typeof(this.stepConfig.options.getCallParams) === 'function'){
			formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
		}
		formData['address'] = this.componentsToAddressObject(this.selectedPositionInfo.address_components);
		this.datasetACS.loading.next(true);
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		const route = baseRoute + '/command/set_map_position';
		this.datasetService.post<any>(route, formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: DatasetEvents.EDITED });
				
				this.datasetRS.reload()
				.subscribe(value => {
					this.goNextStep();
				});
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
			}
		});
	}

	setPosition(result: google.maps.GeocoderResult): void{
		if(this.datasetCode === 'meeting_points'){
			this.setMarker({
				lat: result.geometry.location.lat(),
				lng: result.geometry.location.lng()
			});
		}else{
			this.lat = result.geometry.location.lat();
			this.lng = result.geometry.location.lng();
			this.fetchSelectedPositionInfo(this.lat, this.lng);
		}
	}

	setCoordinate(lat, lng): void{
		if(this.datasetCode === 'meeting_points'){
			this.setMarker({
				lat,
				lng
			});
		}else{
			this.lat = lat;
			this.lng = lng;
			this.fetchSelectedPositionInfo(lat, lng);
		}
	}

	resetPosition(): void{
		this.markers = [];
		this.selectedPositionInfo = null;
		this.initialLocation();
	}
}

interface IMapMarker{
	lat: number;
	lng: number;
	label?: string;
	draggable?: boolean;
	meta?: any;
}
