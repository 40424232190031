import { NgModule } from '@angular/core';

import { SafeHtmlPipe } from './dom-sanitizer.pipe';
import { ApplyPipesPipe } from './apply-pipes.pipe';
import { GetDisplayFieldValuePipe } from './get-display-field-value.pipe';

@NgModule({
	declarations: [
		SafeHtmlPipe,
		ApplyPipesPipe,
		GetDisplayFieldValuePipe
	],
	imports     : [],
	exports     : [
		SafeHtmlPipe,
		ApplyPipesPipe,
		GetDisplayFieldValuePipe
	]
})
export class CustomPipesModule{}
