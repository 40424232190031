import { FuseNavigation } from '@fuse/types';

/**
 * @deprecated use menu-items for build menu with the user permission
 */
export const defaultNavigation: FuseNavigation[] = [
	{
		id       : 'home',
		title    : 'Dashboard',
		translate: 'NAV.HOME.TITLE',
		type     : 'item',
		icon     : 'home',
		url      : 'home',
	},
	{
		id       : 'reviews',
		title    : 'Recensioni',
		translate: 'NAV.REVIEWS.TITLE',
		type     : 'item',
		icon     : 'star_border',
		url      : 'dataset/list/reviews',
	},
	{
		id       : 'bookings',
		title    : 'Prenotazioni',
		translate: 'NAV.BOOKINGS.TITLE',
		type     : 'collapsable',
		icon     : 'event',
		children: [
			{
				id       : 'new_booking',
				title    : 'Nuova Prenotazioni',
				translate: 'NAV.BOOKINGS.NEWBOOKING.TITLE',
				type     : 'item',
				icon     : 'star_border',
				url      : 'new-booking',
			},
			{
				id       : 'booking_list',
				title    : 'Prenotazioni',
				translate: 'NAV.BOOKINGS.TITLE',
				type     : 'item',
				icon     : 'star_border',
				url      : 'dataset/list/trip_bookings',
			}
		]
	},
	{
		id       : 'resources',
		title    : 'Risorse',
		translate: 'NAV.RESOURCES.TITLE',
		type     : 'item',
		icon     : 'today',
		url      : 'dataset/list/trip_resources',
	},
	{
		id       : 'calendar',
		title    : 'Calendario',
		translate: 'NAV.CALENDAR.TITLE',
		type     : 'item',
		icon     : 'today',
		url      : 'calendar',
	}
];
