/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "../../../../../base-actions/list/dataset-list.component.ngfactory";
import * as i5 from "../../../../../services/dataset-action-container.service";
import * as i6 from "../../../../../../../../services/app.service";
import * as i7 from "../../../../../../../pages/page-closure-container.service";
import * as i8 from "../../../../../../../../services/auth.service";
import * as i9 from "../../../../../services/dataset.service";
import * as i10 from "../../../../../../../../services/domain-filter.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../base-actions/list/dataset-list.service";
import * as i13 from "../../../../../base-actions/list/dataset-list.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../services/dataset-navigator.service";
import * as i16 from "../../../../../../../../../@fuse/services/translation-loader.service";
import * as i17 from "@angular/material/snack-bar";
import * as i18 from "../../../../../../../../services/websocket.service";
import * as i19 from "@angular/common";
import * as i20 from "./step.component";
import * as i21 from "../../../../../services/dataset-record.service";
import * as i22 from "@angular/material/dialog";
var styles_TripPackageStep4Component = [];
var RenderType_TripPackageStep4Component = i0.ɵcrt({ encapsulation: 2, styles: styles_TripPackageStep4Component, data: {} });
export { RenderType_TripPackageStep4Component as RenderType_TripPackageStep4Component };
function View_TripPackageStep4Component_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TripPackageStep4Component_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mt-24"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "dataset-list", [["datasetCode", "actual_trips"]], null, [[null, "add"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("add" === en)) {
        var pd_0 = (_co.generate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_DatasetListComponent_0, i4.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i5.DatasetActionContainerService, i5.DatasetActionContainerService, [i6.AppService, i7.PageClosureContainerService, i8.AuthService, i9.DatasetService, i10.DomainFilterService, i11.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i12.DatasetListService, [i5.DatasetActionContainerService, i9.DatasetService]), i0.ɵdid(4, 770048, [[1, 4], ["tripList", 4]], 0, i13.DatasetListComponent, [i14.ActivatedRoute, i9.DatasetService, i14.Router, i8.AuthService, i6.AppService, i5.DatasetActionContainerService, i15.DatasetNavigatorService, i10.DomainFilterService, "ListParamService", i16.FuseTranslationLoaderService, i17.MatSnackBar, i18.WebSocketService], { datasetCode: [0, "datasetCode"], parentDatasetACS: [1, "parentDatasetACS"], parentDatasetRS: [2, "parentDatasetRS"], filters: [3, "filters"], showSearchBar: [4, "showSearchBar"], elevate: [5, "elevate"], emitAdd: [6, "emitAdd"], addButtonText: [7, "addButtonText"] }, { add: "add" }), i0.ɵpod(5, { trip_package_id: 0, start_day: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "actual_trips"; var currVal_1 = _co.datasetACS; var currVal_2 = _co.datasetRS; var currVal_3 = _ck(_v, 5, 0, _co.datasetRS.recordId, _co.todayFilter); var currVal_4 = true; var currVal_5 = false; var currVal_6 = true; var currVal_7 = "Genera"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_TripPackageStep4Component_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { tripList: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripPackageStep4Component_1)), i0.ɵdid(3, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TripPackageStep4Component_2)), i0.ɵdid(5, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.loading.value; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.datasetACS.ready.value; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TripPackageStep4Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "trip-package-step-4", [], null, null, null, View_TripPackageStep4Component_0, RenderType_TripPackageStep4Component)), i0.ɵdid(1, 4440064, null, 0, i20.TripPackageStep4Component, [i6.AppService, i21.DatasetRecordService, i5.DatasetActionContainerService, i15.DatasetNavigatorService, i9.DatasetService, i22.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TripPackageStep4ComponentNgFactory = i0.ɵccf("trip-package-step-4", i20.TripPackageStep4Component, View_TripPackageStep4Component_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { TripPackageStep4ComponentNgFactory as TripPackageStep4ComponentNgFactory };
