import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'service_id',
	name: 'Servizio',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'services',
	inputHint: 'Scrivi per cercare un servizio',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		}
	}
};
