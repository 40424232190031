<div fxLayout='row' fxLayoutAlign='start center' id="step-2-top">
	<h2>{{'Partenze' | translate}}</h2>
</div>

<div class='trip-booking-packages-list p-8'>
	
	<participants-form></participants-form>
	
	<div class="flex-row items-center justify-start flex-gap-4 mt-12 mb-8 cursor-pointer" (click)="itemsCollapsed = !itemsCollapsed">
		<span class="text-semibold">{{'Aggiunti alla prenotazione' | translate}} ({{tripBookingPackages.length}})</span>
		<mat-icon *ngIf="itemsCollapsed">expand_more</mat-icon>
		<mat-icon *ngIf="!itemsCollapsed">expand_less</mat-icon>
		<mat-progress-spinner *ngIf='loading || datasetACS.loading.value' mode="indeterminate" [diameter]="20"></mat-progress-spinner>
	</div>

	<div class='flex-col flex-gap-16 p-8' [style.height]="itemsCollapsed ? 0 : 'auto'" [style.opacity]="itemsCollapsed ? 0 : 1" [style.overflow]="itemsCollapsed ? 'hidden' : 'auto'">
		<ng-container *ngFor='let tripBookingPackage of tripBookingPackages; let first = first; let last = last;'>
			<trip-booking-package
				[tripBookingPackage]='tripBookingPackage'
				[iterationData]="{is_first: first, is_last: last}"
			></trip-booking-package>
		</ng-container>
	</div>

	<new-trip-booking-package-selection *ngIf="!datasetRS.record.value.ref_id"></new-trip-booking-package-selection>
</div>