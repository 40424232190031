import { ValueTypes, FieldViews, IColumnDefinition } from 'app/interfaces';
import * as _ from 'lodash';

enum TripBookingLogStatus {
	command_draft_booking = 1,
	event_update = 2,
	command_confirm_booking = 3,
	command_cancel_booking = 4,
	command_optional_booking = 5,
	command_suspend_booking = 6,
	command_send_voucher = 7,
	command_permanent_cancel_booking = 8,
	replace = 9,
	email = 10
}

function getStyle(status: number): string{
	switch(status){
		case TripBookingLogStatus.command_suspend_booking:
		case TripBookingLogStatus.command_draft_booking:
		case TripBookingLogStatus.email:
		case TripBookingLogStatus.command_send_voucher:
			return 'border-left: 3px solid #629cde; padding-left: 5px;';
		case TripBookingLogStatus.replace:
		case TripBookingLogStatus.event_update:
			return 'border-left: 3px solid #c6be17; padding-left: 5px;';
		case TripBookingLogStatus.command_confirm_booking:
			return 'border-left: 3px solid #5bbe29; padding-left: 5px;';
		case TripBookingLogStatus.command_permanent_cancel_booking:
		case TripBookingLogStatus.command_cancel_booking:
			return 'border-left: 3px solid #d62121; padding-left: 5px;';
		case TripBookingLogStatus.command_optional_booking:
			return 'border-left: 3px solid #f39200; padding-left: 5px;';
		default:
			return '';
	}
}

export const LIST_COLUMNS: IColumnDefinition[] = [
	{
		title: 'Data operazione',
		key: 'date'
	},
	{
		title: 'Descrizione operazione',
		key: 'message',
		width: '50%',
		fieldDefinition: {
			name: 'Messaggio',
			key: 'message',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				const label = record.message || '';
				let actionReason = '';
				if (record.user_action_reason){
					if(typeof(record.user_action_reason) == 'string') actionReason = record.user_action_reason;
					else actionReason = _.get(record, 'user_action_reason.message') || ''; // deprecated
				}
				
				let htmlResult = '<div style="display: flex; flex-direction: column; flex-wrap: wrap;">';
				htmlResult += '<span style="' + getStyle(record.status) + '">' + label + '</span>';
				if (actionReason){
					htmlResult += '<small style="padding-top: 3px; font-weight: bold;">Motivo: ' + actionReason + '</small>';
				}
				htmlResult += '</div>';
				return htmlResult;
			}
		}
	},
	{
		title: 'Canale/Utente',
		key: 'user_id',
		fieldDefinition: {
			name: 'Canale',
			key: 'user_id',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';

				let label = '';

				if(record.user_name){
					label = record.user_name;
				}else if(record.user && record.user.name){
					label = record.user.name;
					if(record.user.name == 'user_system') label = 'Tangerine';
					else if(record.user.name.toLowerCase() == 'super admin') label = 'Tangerine';
				}

				return label;
			}
		}
	}
];
