import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition } from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('adt_counter', {
	key: 'adt_counter',
	name: 'Adulti',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER
});


fieldsMap.set('chd_counter', {
	key: 'chd_counter',
	name: 'Bambini',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('inf_counter', {
	key: 'inf_counter',
	name: 'Neonati',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('snr_counter', {
	key: 'snr_counter',
	name: 'Anziani',
	inputType: InputTypes.BASE_TEXT,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('nation_code', cloneFieldDefinition(nationCodeField, {
	key: 'nation_code',
	inputRequired: true,
	formValidators: [Validators.required]
}));

export {fieldsMap};
