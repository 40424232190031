import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, SkipSelf, Self } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetService } from "app/main/components/dataset/services/dataset.service";
import { AppService } from "app/services/app.service";
import { DomainFilterService } from "app/services/domain-filter.service";
import { takeUntil } from "rxjs/operators";
import { BookingService } from "../../booking.service";
import { RELOAD_BOOKING_PACKAGE } from "../../edit-booking.component";
import { TripBookingPackagesStepService } from "../trip-booking-packages-step.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackageService } from "./trip-booking-package.service";

@Component({
	selector   : 'trip-booking-package',
	templateUrl: './trip-booking-package.component.html',
	styleUrls: ['./trip-booking-package.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService, TripBookingPackageService]
})
export class TripBookingPackageComponent extends DestroyableComponent implements OnInit, OnDestroy, OnChanges{

	@Input() tripBookingPackage: any;
	@Input() iterationData: any;

	constructor(
		public appService: AppService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public domainFilterService: DomainFilterService,
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		public bookingService: BookingService,
		public tripBookingPackageService: TripBookingPackageService
	){
		super();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_booking_packages',
			actionCode: 'detail',
			useBeforeCalls: false,
			parentDatasetRS: this.parentDatasetRS,
			parentDatasetACS: this.parentDatasetACS,
			datasetRS: this.datasetRS
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
		});

		this.appService.appEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(event => {
			if(event.name === RELOAD_BOOKING_PACKAGE && event.extra.trip_booking_package_id === this.tripBookingPackage.id){
				this.datasetRS.reload();
			}
		});

		this.tripBookingPackageService.iterationData = this.iterationData;
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.tripBookingPackage){
			if(!this.datasetRS.record.value){
				this.datasetRS.record.next(this.tripBookingPackage);
			}else if(this.tripBookingPackage && this.tripBookingPackage.updated_at > this.tripBookingPackage.updated_at){
				this.datasetRS.record.next(this.tripBookingPackage);
			}
		}

		if(changes.iterationData){
			this.tripBookingPackageService.iterationData = this.iterationData;
		}
	}
}
