import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { dialogDataFactory } from 'app/helpers';
var ɵ0 = dialogDataFactory;
var TrenitaliaDialogContainerComponent = /** @class */ (function () {
    function TrenitaliaDialogContainerComponent(dataD, dialogRef, matDialog) {
        this.dataD = dataD;
        this.dialogRef = dialogRef;
        this.matDialog = matDialog;
    }
    TrenitaliaDialogContainerComponent.prototype.ngOnInit = function () {
    };
    TrenitaliaDialogContainerComponent.prototype.onComplete = function () {
        this.matDialog.closeAll();
    };
    return TrenitaliaDialogContainerComponent;
}());
export { TrenitaliaDialogContainerComponent };
export { ɵ0 };
