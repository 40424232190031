import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('title', {
	key: 'title',
	name: 'Titolo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione completa',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('short_description', {
	key: 'short_description',
	name: 'Descrizione breve',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('things_to_do', {
	key: 'things_to_do',
	name: 'Cose da fare',
	inputType: InputTypes.TEXT_LIST2,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('master_image_id', {
	key: 'master_image_id',
	name: 'Immagine di copertina',
	inputType: InputTypes.FILE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('active_status', {
	key: 'active_status',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{
		return 0;
	},
	options: [
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Attivo',
			value: 1
		}
	],
	inputHint: 'Se questa traduzione è attiva, sarà pubblica per tale lingua',
});

export {fieldsMap};
