import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/facilities/dataset/trip_package_language_contents/:parentRecordId/detail/:recordId',
};

export const PivotTripPackagesTripFacilitiesConfig: IDatasetConfig = {
	name: 'pivot_trip_packages_trip_facilities',
	title: 'Informazioni Utili',
	singleTitle: 'Informazioni Utili',
	ajaxDatasetCode: 'trip_facilities',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
	},

	pivot: {
		pivotCode: 'facilities',
		foreignKey: 'trip_facility_id',
		parentDatasetCode: 'trip_package_language_contents',
		commandSync: 'tpl1_trip_packages_pivots'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Icona',
						key: 'facility.slug'
					},
					{
						title: 'Nome',
						key: 'facility.title'
					},
					{
						title: 'Traduzioni Atttive',
						key: 'facility.active_language_codes'
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	}
};
