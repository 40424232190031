<fuse-sidebar name="calendar-sidebar" position="right" class="calendar-fuse-sidebar"
	[folded]='false'
	[foldedAutoTriggerOnHover]='false'>
	<ng-container *ngIf='selectedDay'>
		<!-- HEADER -->
		<div class="header">
			<div class="day">{{DAYS[selectedDay.date.getDay()]}} {{selectedDay.date | date:'dd'}}</div>
			<div class="action" (click)='closeSidebar()'><span class="material-icons">cancel</span></div>
		</div>
		<!-- BODY -->
		<ng-container *ngFor='let dayPackage of groupedEvents[selectedDay.date | date:"ddMMyy"] | keyvalue'>
			<div class="trip_package_container">
				<div class="trip_package_title">
					<span class="title">
						<a [routerLink]='datasetNavigatorService.getDetailRoute("trip_packages", dayPackage.value.trip_package_id)'>{{dayPackage.value.trip_package_code}} - {{dayPackage.value.trip_package_description}}</a>
					</span>
				</div>

				<div class="trip_package_actual_trips">
					<ng-container *ngFor="let event of dayPackage.value.events;">
						<div class="actual_trip">
							<div class="actual_trip_header">
								<div class="arrow">
									<img *ngIf='event.meta.actual_trip.type != "return"' src="assets/icons/material-icons/filled/going_trip_icon.svg" />
									<img *ngIf='event.meta.actual_trip.type == "return"' src="assets/icons/material-icons/filled/return_trip_icon.svg" />
								</div>
								
								<div class="actual_trip_title">
									<div class="display_name">
										<img src="assets/icons/material-icons/filled/md-pin.svg" class="place" />
										<span class="title">
											<a [routerLink]="datasetNavigatorService.getDetailRoute('actual_trips', event.meta.actual_trip.id)">
												{{event.meta.actual_trip.display_name}}
											</a>
										</span>
									</div>
									<div class="start_time">
										<mat-icon>access_time</mat-icon> {{event.meta.actual_trip.start_time}}
									</div>
								</div>
								<!-- STAFF ELEMENTS AND GUIDE LANGUAGES-->
								<div class="staff_and_guieds">
									<div class="guides" *ngIf="event.meta.actual_trip_guide_languages.length">
										<img *ngFor="let lang of event.meta.actual_trip_guide_languages"
										width="24"
										class="flag-icon-sidebar"
										[src]="'assets/images/flags/4x3/' + lang + '.svg'"
										[alt]="lang"
										[title]="lang"/>
									</div>
									<div *ngIf='event.meta.staff_elements && event.meta.staff_elements.length' class="staff_elements">
										<ng-container *ngFor='let staffElement of event.meta.staff_elements'>
											<div class="staff_element">
												<span class="label" *ngIf='staffElement.entity_staff_element.properties.staff_type.value'>
													{{staffElement.entity_staff_element.properties.staff_type.value}}: 
												</span>
												<a [routerLink]="datasetNavigatorService.getDetailRoute('entities', staffElement.entity_staff_element.entity_id)" 
													class="description" *ngIf='staffElement.entity_staff_element.entity.description'>
													{{staffElement.entity_staff_element.entity.description}}
												</a>
											</div>
										</ng-container>
									</div>
								</div>
							</div>

							<div class="container_actual_trip_body">
								<div class="rettangolo" [ngClass]="event?.cssClass"></div>
								<div *ngIf='event.meta.actual_trip.pax_by_resource' class="actual_trip_body">
									<div class="pax">
										<span class="label">Pax punto partenza:</span> 
										<span class="badge">{{event.meta.actual_trip.pax_by_resource.pax_punto_partenza}}</span>
									</div>
	
									<div class="pax" *ngIf='event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>
										<span class="label">Pax prenotati risorsa:</span>
										<span class="badge">{{event.meta.actual_trip.pax_by_resource.pax_prenotati_risorsa}}</span>
									</div>
	
									<div class="pax" *ngIf='event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>
										<span class="label">Pax disponibili risorsa:</span>
										<span class="badge">{{ event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa === null ? '&#8734;' : event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa }}</span>
									</div>
	
									<div class="pax" *ngIf='!event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>
										<span class="label">Pax disponibili partenza:</span>
										<span class="badge">{{ event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa === null ? '&#8734;' : event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa }}</span>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>
</fuse-sidebar>