<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>
		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è ancora presente!' | translate}}</p>

			<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
				<button mat-flat-button (click)='goPrevStep()'>Indietro</button>
			</div>
		</ng-container>

		<ng-container *ngIf='datasetRS.record.value && datasetRS.record.value["lang"] === appService.language.value'>
			<mat-tab-group>
				<mat-tab [label]='"Informazioni Utili" | translate'>
					<ng-template matTabContent>
						<dataset-list
							datasetCode='pivot_trip_packages_trip_facilities'
							[parentDatasetRS]='datasetRS'
							[parentDatasetACS]='datasetACS'
							[showSearchBar]='false'
							[elevate]='false'
							[showTitle]='false'
						></dataset-list>
					</ng-template>
				</mat-tab>
				<mat-tab [label]='"Immagine di copertina" | translate'>
					<ng-template matTabContent>
						<document-file-block	
							[record]='datasetRS.record.value'
							[documentFileKey]='"master_image"'
							[imageOptions]='{minWidth: 1920, minHeight: 400, ratio: 1920 / 400}'
							[type]='"image"'
							[uploadRoute]='"/dataset/trip_package_language_contents/command/upload_master_image"'
							[uploadData]='{trip_package_language_content_id: datasetRS.record.value.id}'
							(changed)='datasetRS.reload()'
						></document-file-block>
					</ng-template>
				</mat-tab>
				<mat-tab [label]='"Immagini" | translate'>
					<ng-template matTabContent>
						<carousel-block
							[showTitle]='false'
							[parentDatasetACS]='datasetACS'
							[parentDatasetRS]='datasetRS'
							[configs]='carouselBlockConfigs'
						></carousel-block>
					</ng-template>
				</mat-tab>
			</mat-tab-group>
		</ng-container>

	</div>
</div>