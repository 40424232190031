import {Component, forwardRef, Inject, InjectionToken, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PageClosureContainerService} from '../../../page-closure-container.service';
import {TrenitaliaService} from '../trenitalia.service';
import { BookingService } from '../../booking.service';
import { dialogDataFactory, DialogDataKeyToken } from 'app/helpers';

@Component({
	selector: 'trenitalia-dialog-container',
	templateUrl: './trenitalia-dialog-container.component.html',
	styleUrls: ['./trenitalia-dialog-container.component.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService),
		TrenitaliaService,
		{
			provide: DialogDataKeyToken, useValue: 'bookingService'
		},
		{
			provide: BookingService, 
			useFactory: dialogDataFactory,
			deps: [Injector, DialogDataKeyToken]
		}
	]
})
export class TrenitaliaDialogContainerComponent implements OnInit {

	constructor(
		@Inject(MAT_DIALOG_DATA) public dataD: any,
		public dialogRef: MatDialogRef<TrenitaliaDialogContainerComponent>,
		public matDialog: MatDialog,
		//public datasetRs: DatasetRecordService
	) { }

	ngOnInit() {
	}

	onComplete() {
		this.matDialog.closeAll();
	}
}
