import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';


const beforeCallDetail = {
	name: 'record',
	path: '/pivot/meetingPoints/dataset/trip_resources/:parentRecordId/detail/:recordId',
};

const pivotProductsCarouselConfig: IDatasetConfig = {
	name: 'pivot_trip_resources_language_contents_carousel_images',
	title: 'Immagini',
	singleTitle: 'Immagine',
	ajaxDatasetCode: 'document_files',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
	},

	pivot: {
		pivotCode: 'carouselImages',
		foreignKey: 'image_id',
		parentDatasetCode: 'trip_resource_language_contents'
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			// beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'name',
						key: 'name',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Tipo File',
						key: 'fiel_type'
					},
					{
						title: 'Aggiornato il',
						key: 'updated_at'
					}
				],
				actions: ['delete']
			}
		}
	}
};

export const PivotProductsCarouselConfig = pivotProductsCarouselConfig;