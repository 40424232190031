/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pratica-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/material/card";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i10 from "../../../../components/data-viewer/container/data-container-viewer.component.ngfactory";
import * as i11 from "../../../../components/data-viewer/container/data-container-viewer.component";
import * as i12 from "./pratica-data.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../../services/dataset.service";
import * as i15 from "../../../../../../../services/auth.service";
import * as i16 from "../../../../services/dataset-action-container.service";
import * as i17 from "../../../../services/dataset-record.service";
import * as i18 from "../../../../services/dataset-navigator.service";
var styles_PraticaDataComponent = [i0.styles];
var RenderType_PraticaDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PraticaDataComponent, data: {} });
export { RenderType_PraticaDataComponent as RenderType_PraticaDataComponent };
function View_PraticaDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Modifica")); _ck(_v, 2, 0, currVal_3); }); }
function View_PraticaDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-card-actions", [["class", "mat-card-actions"]], [[2, "mat-card-actions-align-end", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i7.MatCardActions, [], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PraticaDataComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "end"; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.canEdit; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).align === "end"); _ck(_v, 0, 0, currVal_0); }); }
export function View_PraticaDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-card", [["class", "p-24 mat-elevation-z0 mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i9.View_MatCard_0, i9.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i7.MatCard, [[2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i7.MatCardContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "data-container-viewer", [], null, null, null, i10.View_DataContainerViewerComponent_0, i10.RenderType_DataContainerViewerComponent)), i1.ɵdid(5, 180224, null, 0, i11.DataContainerViewerComponent, [], { fields: [0, "fields"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PraticaDataComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.formConfig.fields; var currVal_2 = _co.datACS; var currVal_3 = _co.record; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.showActions; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_PraticaDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pratica-data", [], null, null, null, View_PraticaDataComponent_0, RenderType_PraticaDataComponent)), i1.ɵdid(1, 245760, null, 0, i12.PraticaDataComponent, [i13.ActivatedRoute, i14.DatasetService, i13.Router, i15.AuthService, i16.DatasetActionContainerService, i17.DatasetRecordService, i18.DatasetNavigatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PraticaDataComponentNgFactory = i1.ɵccf("pratica-data", i12.PraticaDataComponent, View_PraticaDataComponent_Host_0, { record: "record", index: "index", rowDb: "rowDb", datACS: "datACS", viewFormConfig: "viewFormConfig", showActions: "showActions" }, {}, []);
export { PraticaDataComponentNgFactory as PraticaDataComponentNgFactory };
