import { OnInit, OnDestroy, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { CalendarService } from '../calendar.service';
var ɵ0 = { useUtc: true }, ɵ1 = MAT_MOMENT_DATE_FORMATS;
var DatasetCalendarHeaderViewComponent = /** @class */ (function () {
    function DatasetCalendarHeaderViewComponent(calendarService, datasetNavigatorService, datasetACS, matDialog) {
        this.calendarService = calendarService;
        this.datasetNavigatorService = datasetNavigatorService;
        this.datasetACS = datasetACS;
        this.matDialog = matDialog;
        this.viewTypeChange = new EventEmitter();
        this.viewDateChange = new EventEmitter();
        this.addClicked = new EventEmitter();
        this.canCreate = false;
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    DatasetCalendarHeaderViewComponent.prototype.ngOnInit = function () {
        this.canCreate = this.datasetACS.actionEnabled('create');
    };
    DatasetCalendarHeaderViewComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    DatasetCalendarHeaderViewComponent.prototype.ngOnChanges = function (changes) { };
    DatasetCalendarHeaderViewComponent.prototype.onAddClick = function () {
        this.addClicked.emit();
    };
    return DatasetCalendarHeaderViewComponent;
}());
export { DatasetCalendarHeaderViewComponent };
export { ɵ0, ɵ1 };
