<mat-card class='ml-4 mt-35 trip-options-for-virtual-trips-list-item'>
	<div class="option-title flex-row justify-end items-center">
		
		<button class="action-menu-btn" mat-icon-button *ngIf='datasetACS.canEdit(item)' [matMenuTriggerFor]="actionMenu">
			<mat-icon color='primary'>more_vert</mat-icon>
		</button>
		<mat-menu #actionMenu="matMenu">
			<button class="edit-btn" mat-menu-item *ngIf='datasetACS.canEdit(item)' (click)='editOption()'>
				{{'Modifica' | translate}}
			</button>
			<button class="delete-btn" mat-menu-item *ngIf='datasetACS.canEdit(item)' (click)='deleteOption()'>
				{{'Elimina' | translate}}
			</button>
		</mat-menu>
	</div>

	<div>
		<div card-data-viewer
			[showActions]="false"
			[record]='datasetRS.record.value'
			[datasetACS]='datasetACS'
			[viewFormConfig]='datasetACS.datasetConfig.formConfigsMap.generalReadForm'
			[editFormConfig]='datasetACS.datasetConfig.defaultActions?.edit?.viewConfig?.formConfig'
		></div>
	</div>

	<mat-accordion class="mb-20">
		<mat-expansion-panel>

			<mat-expansion-panel-header>
				<mat-panel-title>{{ 'Disponibilità' | translate }}</mat-panel-title>
			</mat-expansion-panel-header>

			<ng-template matExpansionPanelContent>
				<dataset-list
					[autoFetchData]='true'
					[parentDatasetACS]='datasetACS'
					[showSearchBar]='false'
					[showTitle]='false'
					[elevate]='0'
					[enableCreate]='true'
					[enableEdit]='true'
					datasetCode='availabilities_for_virtual_trips'
					[filters]='{ option_id: item.id }'
					sortBy='created_at'
				></dataset-list>
			</ng-template>
		</mat-expansion-panel>
		<!--<mat-expansion-panel>

			<mat-expansion-panel-header>
				<mat-panel-title>{{ 'Punti di ritrovo' | translate }}</mat-panel-title>
			</mat-expansion-panel-header>

			<ng-template matExpansionPanelContent>
				<dataset-list
					[autoFetchData]='true'
					[parentDatasetACS]='datasetACS'
					[showSearchBar]='false'
					[showTitle]='false'
					[elevate]='0'
					datasetCode='meeting_point_unified_list_items'
					[filters]='{ list_id: item.meeting_point_list_id }'
					sortBy='created_at'
				></dataset-list>
			</ng-template>
		</mat-expansion-panel>-->
	</mat-accordion>
</mat-card>