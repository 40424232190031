<div class="recap-menu" *ngIf="['travel','travel-change'].includes(checkoutData.mode)">
    <div *ngIf="!['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="this.checkoutData.totalSelectedSolution"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">

        </trenitalia-recap>
    </div>
    <div class="flex-col flex-gap-8" *ngIf="['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.forward.selectedSolution.start.id"
            [end]="checkoutData.forward.selectedSolution.end.id"
            [departure]="checkoutData.forward.selectedSolution.departure"
            [arrive]="checkoutData.forward.selectedSolution.arrival"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.forward.total"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [fw]="true"
            [dataAv]="checkoutData.forward.dataAv?checkoutData.forward.dataAv:null"
            [trains]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution:null"
            [offeredServices]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.offeredServices:null">
        </trenitalia-recap>
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="total"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">
        </trenitalia-recap>

        <div >
            <span class="total text-color-dark-blue"
                  matTooltip="{{trnService.total.value['fee']>0?'Prezzo:'+(trnService.total.value['valueNet']| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia:'+(trnService.total.value['fee']| currency:'EUR':'symbol':'1.2-2'):''}}">
                Totale: {{trnService.total.value['value'] | currency:'EUR':'symbol':'1.2-2'}}
            </span>
        </div>
    </div>
</div>
<div class="flex-row flex-gap-8 justify-end button-action" >
    <button mat-raised-button
            *ngIf="prevTrainStep"
            (click)="changeStepTrain(-1)"
            [disabled]="trnService.loading.value">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Treno precedente
    </button>
    <button mat-raised-button
            *ngIf="nextTrainStep"
            [disabled]="trnService.loading.value"
            (click)="changeStepTrain(1)">
        Prossimo treno
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button mat-raised-button
            *ngIf="
                        !['edit-date-time','travel-change','carnet'].includes(trnService.checkoutData.value.mode) &&
                                        (!checkoutData.ar || checkoutData.direction =='RETURN')"
            [disabled]="!trnService.continue.value || trnService.loading.value"
            (click)="trnService.btnAction.next('conf-sel-seat')">
        Continua
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button mat-raised-button
            *ngIf="!['carnet'].includes(checkoutData.mode) &&
                    !nextTrainStep &&
                    checkoutData.ar &&
                    checkoutData.direction !== 'RETURN'"
            [disabled]="!trnService.continue.value || trnService.loading.value" (click)="trnService.btnAction.next('conf-sel-seat')">
        Ritorno
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>

<mat-tab-group  [selectedIndex]="trainStep" (selectedTabChange)="onTrainChanged($event)">
    <mat-tab *ngFor="let t of checkoutData.selectedSolution.trains">
        <ng-template mat-tab-label >
            <span [ngClass]="t.id==trainId?'material-icons-outlined train-selected':'material-icons-outlined'" >train</span>
            <span  [ngClass]="t.id==trainId?'train-title train-selected':'train-title'">{{t.type}} {{t.name}} </span>
            <span *ngIf="checkoutData.direction ==='FORWARD'"> (ANDATA) </span>
            <span *ngIf="checkoutData.direction ==='RETURN'"> (RITORNO) </span>
        </ng-template>
        <trenitalia-train-selection-seats
			*ngIf="t.id == trainId && seatsData"
			[train_info]=t
			[train_seat]="seatsData[t.id]"
			[travellers]="checkoutData.selectedSolution.travellers"
			[selectedOffers]="checkoutData.selectedSolution.selectedOffers"
			(wagon)="setWagon($event)"></trenitalia-train-selection-seats>
    </mat-tab>
</mat-tab-group>
