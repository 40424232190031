import { Injectable, Injector} from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { AppService } from 'app/services/app.service';

@Injectable()
export class PageClosureContainerService{

	public loading: BehaviorSubject<boolean>;
	public sharedData: BehaviorSubject<any>;
	public templatePage: BehaviorSubject<any>;

	constructor(
		private appService: AppService,
	){
		this.loading = new BehaviorSubject(false);
		this.sharedData = new BehaviorSubject({});
		this.templatePage = new BehaviorSubject('generic-page');
	}

	addToSharedData(data): void{
		const oldData = this.sharedData.value;
		const newData = Object.assign({}, data, oldData);
		this.sharedData.next(newData);
	}

	putToSharedData(data): void{
		const oldData = this.sharedData.value;
		const newData = Object.assign({}, oldData, data);
		this.sharedData.next(newData);
	}
	
	/**
	 * Return data shared from all components in this page
	 */
	getSharedData(): any{
		return this.sharedData.value;
	}

	clearToolbarPageActions(): void{
		this.appService.toolbarPageActions = [];
	}
}
