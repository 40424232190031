import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('trip_category_id', {
	key: 'trip_category_id',
	name: 'Categoria',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_categories',
	remoteOptions: {
		clearInputOnCreation: true,
		supportCreate: true,
		createHint: 'Scrivi per cercare una categoria',
		clearOnSelection: true,
		limit: 100,
		icon: 'search',
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

export default fieldsMap;
