import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, InputTypes, ValueTypes, IFieldDefinition, FieldViews } from 'app/interfaces';
import { fieldsMap } from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents, } from 'app/main/components/dataset/services/dataset-action-container.service';

export const AvailabilitiesConfig: IDatasetConfig = {
	name: 'avilabilities',
	title: 'Disponibilità',
	singleTitle: 'Disponibilità',
	ajaxDatasetCode: 'availability',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		delete: true,
		list: true
	},
	defaultActions: {
		list: {
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Dal',
						key: 'valid_from',
					},
					{
						title: 'Al',
						key: 'valid_to',
					}
				]
			},
		}
	}
};
