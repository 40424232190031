import {IDatasetPropertyDefinition, IFieldDefinition, InputTypes, IPaginationResponse, ValueTypes} from 'app/interfaces';
import {IDataContainerService} from 'app/interfaces/data-container.interface';
import {propertyTypeCodeToValueType} from './property-field.helper';
import {FormGroup} from '@angular/forms';
import { FieldHelper } from '.';
import * as moment from 'moment';

export function cloneFieldDefinition(fieldDefinition: IFieldDefinition, custom = {}): IFieldDefinition{
	const newFieldDefinition = Object.assign(Object.assign({}, fieldDefinition), custom);

	if(fieldDefinition.remoteOptions && !custom['remoteOptions']) newFieldDefinition.remoteOptions = Object.assign({}, fieldDefinition.remoteOptions);
	if(fieldDefinition.clickOptions && !custom['clickOptions']) newFieldDefinition.clickOptions = Object.assign({}, fieldDefinition.clickOptions);
	if(fieldDefinition.optionSelected && !custom['optionSelected']) newFieldDefinition.optionSelected = Object.assign({}, fieldDefinition.optionSelected);
	if(fieldDefinition.extra && !custom['extra']) newFieldDefinition.extra = Object.assign({}, fieldDefinition.extra);
	if(fieldDefinition.propertyDefinition && !custom['propertyDefinition']) newFieldDefinition.propertyDefinition = Object.assign({}, fieldDefinition.propertyDefinition);

	return newFieldDefinition;
}

export function convertValueType(value: any, valueType: ValueTypes): any{
	if(valueType === ValueTypes.NUMBER){
		if(value !== null){
			value = Number(value);
			if(isNaN(value)) value = 0;
		}
	}else if(valueType === ValueTypes.BOOLEAN){
		if(value === true || value === 1 || value === '1' || value === 'true'){
			value = true;
		}else if(value === false || value === 0 || value === '0' || value === 'false'){
			value = false;
		}else{
			value = null;
		}
	}else if(valueType === ValueTypes.JSON){
		if(value && typeof(value) === 'string') value = JSON.parse(value);
	}else if(valueType === ValueTypes.ARRAY){
		if(typeof(value) === 'string'){
			value = value.split(',');
		}
	}else if(valueType === ValueTypes.DATETIME){
		value = moment.utc(value);
	}else if(valueType === ValueTypes.PRETTY_JSON){
		if(typeof(value) === 'object'){
			value = JSON.stringify(value, null, 4);
		}
	}
	return value;
}

export function getPropertyDefinition(dataContainerService: IDataContainerService, code: string, datasetCode: string): IDatasetPropertyDefinition{
	const key = datasetCode + '.' + datasetCode + '_property_definitions';
	const propertyDefinitions: IPaginationResponse<IDatasetPropertyDefinition> = dataContainerService.getValueFromKeyPath(key);
	if(!propertyDefinitions){
		// console.warn('getPropertyDefinition not found', key, code, dataContainerService.getData(), dataContainerService);
		return;
	}
	const result = propertyDefinitions.items.find((candidate: IDatasetPropertyDefinition) => {
		return candidate.code === code;
	});
	if(!result){
		console.warn('property not found', code, dataContainerService, propertyDefinitions);
		return;
	}
	return result;
}

export function getFieldValue(fieldDefinition: IFieldDefinition, record: any, defaultValue?: any, dataContainerService?: IDataContainerService): any{
	let value: any;
	if(record){
		if(fieldDefinition.valueType === ValueTypes.PROPERTY){
			if(record.properties && record.properties[fieldDefinition.key]){
				value = record.properties[fieldDefinition.key].value;
			}
		}else{
			value = record;
			const keyParts = fieldDefinition.key.split('.');
			
			for (const keyPart of keyParts) {
				if(value === null) break;
				if(value[keyPart] === undefined){
					value = undefined;
					break;
				}
				value = value[keyPart];
			}
		}
	}

	if(typeof(value) === 'undefined'){
		if(typeof(defaultValue) !== 'undefined' ){
			value = defaultValue;
		}else if(typeof(fieldDefinition.defaultValue) === 'function'){
			value = fieldDefinition.defaultValue(dataContainerService);
		}
	}else if(fieldDefinition.disallowNull === true && value === null){
		if(typeof(defaultValue) !== 'undefined' && defaultValue !== null){
			value = defaultValue;
		}else if(typeof(fieldDefinition.defaultValue) === 'function'){
			value = fieldDefinition.defaultValue(dataContainerService);
		}
	}

	let valueType = fieldDefinition.valueType;
	if(fieldDefinition.propertyDefinition && valueType === ValueTypes.PROPERTY){
		valueType = propertyTypeCodeToValueType(fieldDefinition.propertyDefinition.value_type);
	}
	value = convertValueType(value, valueType);

	return value;
}

export function getDateString(value: string): string{
	const dt = new Date(value);
	if(value && dt && !isNaN(Number(dt))){
		return dt.toLocaleDateString();
	}else{
		return '--/--/----';
	}
}

export function getDateTimeString(value: string): string{
	const dt = new Date(value);
	if(value && dt && !isNaN(Number(dt))){
		const time = dt.toLocaleTimeString('it-IT', {
			hour: '2-digit', 
			minute: '2-digit'
		});
		let str = dt.toLocaleDateString();
		if(time !== '00:00'){
			str += ' ' + time; 
		}
		return str;
	}else{
		return '--/--/---- --:--';
	}
}

export function getDisplayFieldValue(fieldDefinition: IFieldDefinition, record: any, defaultValue?: any, dataContainerService?: IDataContainerService): string{
	let value: any = '';
	let propertyDefinition: any = null;
	let propertyData = null;
	if(record && typeof(record[fieldDefinition.key]) !== 'undefined'){
		value = record[fieldDefinition.key];
	}
	if(record && fieldDefinition.valueType === ValueTypes.PROPERTY){
		propertyDefinition = dataContainerService && FieldHelper.getPropertyDefinition(
			dataContainerService, 
			fieldDefinition.propertyCode || fieldDefinition.key,
			fieldDefinition.datasetCode || dataContainerService.getValueFromKeyPath('datasetCode')
		);
		// if(!propertyDefinition) console.warn('propertyDefinition not found');

		if(record.properties && record.properties[fieldDefinition.key]){
			value = record.properties[fieldDefinition.key].value;
			propertyData = record.properties[fieldDefinition.key];
		}
	}

	// @deprecated optionSource
	// Check if in dataContainerService there id the list of options
	if(fieldDefinition.optionSource && dataContainerService && dataContainerService.getValueFromKeyPath(fieldDefinition.optionSource)){
		const options = dataContainerService.getValueFromKeyPath(fieldDefinition.optionSource + '.' + fieldDefinition.optionSourceKey);
		if(options){
			for (const option of options) {
				if(option.id === value){
					return option.name;
				}
			}
		}
	}

	if(typeof(fieldDefinition.getDisplayValue) === 'function' && record){
		value = fieldDefinition.getDisplayValue(record);
	}else if(typeof(fieldDefinition.getDisplayValue) === 'string' && record){
		const keyParts = fieldDefinition.getDisplayValue.split('.');
		let keyValue = record;
		for (const keyPart of keyParts) {
			if(keyValue === null || keyValue[keyPart] === undefined){
				keyValue = undefined;
				break;
			}
			keyValue = keyValue[keyPart];
		}
		if(keyValue !== undefined){
			value = keyValue;
		}
	}else{
		value = getFieldValue(fieldDefinition, record, defaultValue);
	}
	// convert value
	if(typeof(fieldDefinition.printTransform) === 'function'){
		return fieldDefinition.printTransform(value);
	}else{ 
		let valueType = fieldDefinition.valueType;
		if(fieldDefinition.valueType === ValueTypes.PROPERTY && propertyDefinition){
			valueType = propertyTypeCodeToValueType(propertyDefinition.value_type);
		}
		if(valueType === ValueTypes.DATE){
			return getDateString(value);
		}else if(valueType === ValueTypes.DATETIME){
			return getDateTimeString(value);
		}else if(valueType === ValueTypes.BOOLEAN){
			if(value === true){
				return 'Si';
			}else if(value === false){
				return 'No';
			}
		}else if(valueType === ValueTypes.TIME){
			if(value && value.split(':').length > 1){
				value = value.split(':')[0] + ':' + value.split(':')[1];
			}
			return value;
		}
	}

	/*if(fieldDefinition.inputType === InputTypes.SELECT && fieldDefinition.options){
		for (const option of fieldDefinition.options) {
			if(option.value === value){
				return option.label;
			}
		}
	}*/
	if(fieldDefinition.valueType === ValueTypes.PROPERTY && propertyData){
		if(propertyData.type === 'option_value' && propertyData.label){
			value = propertyData.label;
		}
	}

	if([InputTypes.MULTI_SELECT, InputTypes.SELECT, InputTypes.PROPERTY].includes(fieldDefinition.inputType)){
		let options = fieldDefinition.options;
		if(fieldDefinition.valueType === ValueTypes.PROPERTY && propertyDefinition){
			options = propertyDefinition.property_value_options;
		}
		if(options){
			let label = '';
			let values = value;
			if(!(values instanceof Array)) values = [value];
			for(const v of values){
				for (const option of options) {
					if(option.value === v){
						if(!label) label = option.label;
						else label += ', ' + option.label;
					}
				}
			}
			if(label) value = label;
		}
	}

	return value;
}

export function isFieldValueSetted(fieldDefinition: IFieldDefinition, record: any, defaultValue?: any, dataContainerService?: IDataContainerService): boolean{
	let value: any = null;

	if(record && typeof(record[fieldDefinition.key]) !== 'undefined'){
		value = record[fieldDefinition.key];
	}
	if(record && fieldDefinition.valueType === ValueTypes.PROPERTY){
		if(record.properties && record.properties[fieldDefinition.key]){
			value = record.properties[fieldDefinition.key].value;
		}
	}

	if(typeof(fieldDefinition.getDisplayValue) === 'function' && record){
		value = fieldDefinition.getDisplayValue(record);
	}else if(typeof(fieldDefinition.getDisplayValue) === 'string' && record){
		const keyParts = fieldDefinition.getDisplayValue.split('.');
		let keyValue = record;
		for (const keyPart of keyParts) {
			if(keyValue === null || keyValue[keyPart] === undefined){
				keyValue = undefined;
				break;
			}
			keyValue = keyValue[keyPart];
		}
		if(keyValue !== undefined){
			value = keyValue;
		}
	}else{
		value = getFieldValue(fieldDefinition, record, defaultValue);
	}

	return Boolean(value);
}

export function formGroupToFormData(formGroup: FormGroup): FormData{
	const formData = new FormData();

	for (const key in formGroup.controls) {
		if(!Object.prototype.hasOwnProperty.call(formGroup.controls, key)) continue;
		const control = formGroup.controls[key];
		if (control.value instanceof FileList) {
			for(let i = 0; i < control.value.length; i++){
				formData.append(key+'[]', control.value[i]);
			}
		} else {
			formData.append(key, control.value);
		}
	}

	return formData;
}

export function currencyValuePrint(value): string{
	if(typeof(value) !== 'number' && typeof(value) !== 'string') return '-';
	if(typeof(value) === 'string'){
		const strVal = value;
		value = Number(value);
		if(isNaN(value)) return strVal;
	}
	return value.toLocaleString('it-IT', {
		style: 'currency',
		currency: 'EUR',
	});
}
