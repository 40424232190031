import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('enabled', {
	key: 'enabled',
	name: 'Attivo',
	inputType: InputTypes.TOGGLE,
	valueType: ValueTypes.NUMBER
});

export {fieldsMap};
