import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as _ from 'lodash';

import {TrenitaliaService} from "../../trenitalia.service";

@Component({
	selector: 'trenitalia-train-selection-seats',
	templateUrl: './train-selection-seats.component.html',
	styleUrls: ['./train-selection-seats.component.scss']
})
export class TrainSelectionSeatsComponent implements OnInit {
	public passengers;
	private maxSel;

	@Input('train_seat') trainSeat: any;
	@Input('train_info') trainInfo: any;
	@Input('travellers') travellers: any;
    @Input('selectedOffers') selectedOffers: any;
    @Output('wagon') wagon= new EventEmitter();
    // @ts-ignore
    @ViewChild('top') top: ElementRef;

	constructor(public trnService:TrenitaliaService) {}

	ngOnInit() {
		if (!Array.isArray(this.trainSeat.transportUnits)) {
			this.trainSeat.transportUnits = [this.trainSeat.transportUnits];
		}
        if (!Array.isArray(this.travellers)) {
            this.travellers = [this.travellers];
        }
		this.maxSel = this.travellers.length;
		// @ts-ignore
		window.maxsel = this.maxSel;
		// @ts-ignore
		window.normalNavigation = true;
		// @ts-ignore
		window.showMessage = () => {
			return
		};
	}
    ngAfterViewInit(){
        this.top.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

	markSeleableSeats (wagonId, attempts = 0) {
		const wagon = this.trainSeat.transportUnits.find(wagon => wagon.unitIndex == wagonId);
		const seats = _.get(wagon, 'seatsAvailability.seats');
		if(!seats || !Array.isArray(seats)) return;
		if(attempts == 20) return; // iframe not loaded
		const wagonIframe: any = window.document.getElementById("wagon-" + wagonId);
		if(!wagonIframe){
			return setTimeout(() => {
				this.markSeleableSeats(wagonId, attempts+1);
			}, 300);
		}
		if(!wagonIframe.contentWindow.document.querySelector('svg')){
			return setTimeout(() => {
				this.markSeleableSeats(wagonId, attempts+1);
			}, 300);
		}
		
		for (const seat of seats) {
			if(!seat.available) continue;
			const seatElement = wagonIframe.contentWindow.document.getElementById('seat_' + seat.number);
			const seatSelElement = wagonIframe.contentWindow.document.getElementById('seat_' + seat.number + '_sel');
			if(seatElement && seatElement.onclick == undefined){
				console.warn('seat available but no onclick', seat, wagonId);
				continue;
			}
			if(seatElement && !seatSelElement) seatElement.classList.add('seleable');
		}
	}

	/*markSelectable(wagonId,trainSeat) {
        setTimeout(()=>{
            // @ts-ignore
            let wagonIframe = document.getElementById('wagon-' + wagonId);
            // @ts-ignore
            if(!wagonIframe) return this.markSelectable(wagonId);
            // @ts-ignore
            wagonIframe = wagonIframe.contentWindow.document.querySelector('body');
            for (let i in trainSeat.transportUnits) {
                if (Object.prototype.hasOwnProperty.call(trainSeat.transportUnits, i)) {
                    let wagon = trainSeat.transportUnits[i];
                    if (wagon.unitIndex == wagonId) {
                        let seats = wagon.seatsAvailability.seats;
                        for (let ii in seats) {
                            if (Object.prototype.hasOwnProperty.call(seats, ii)) {
                                if (seats[ii]['available'] === true) {
                                    let seat = wagonIframe.querySelector(
                                        '#seat_' + seats[ii].number
                                    );
                                    seat.classList.add('seleable');
                                }
                            }
                        }
                        break;
                    }
                }
            }
        }, 1500)
	}*/

	selWagon(wId) {
		this.wagon.emit (wId);
	}
}
