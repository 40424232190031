import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import { fieldsMap } from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripBookingPackageId = dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId');
	// filter by default
	// if(tripBookingPackageId) params['trip_booking_package_id'] = tripBookingPackageId;

	return params;
}

export const TripBookingResourceConfig: IDatasetConfig = {
	name: 'trip_booking_resources',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'trip_booking_resources',
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: false,
		list: false,
		restore: false
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CUSTOM,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc'
			}
		}
	}
};
