/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/common";
import * as i10 from "./dialog.component";
import * as i11 from "../../../services/app.service";
var styles_ConfirmDialogComponent = [];
var RenderType_ConfirmDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ConfirmDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNeutralClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(1, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(2, 0, ["", ""])), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 1).disabled || null); var currVal_1 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.data.neutralText)); _ck(_v, 2, 0, currVal_2); }); }
export function View_ConfirmDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i8.MatDialogTitle, [[2, i8.MatDialogRef], i0.ElementRef, i8.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i8.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i0.ɵdid(6, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 11, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i8.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(12, 0, ["", ""])), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_2)), i0.ɵdid(15, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i0.ɵdid(17, 180224, null, 0, i5.MatButton, [i0.ElementRef, i6.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(18, 0, ["", ""])), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.loading; _ck(_v, 6, 0, currVal_2); var currVal_7 = _co.data.neutralText; _ck(_v, 15, 0, currVal_7); var currVal_10 = "accent"; _ck(_v, 17, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.data.title ? _co.data.title : "Conferma"); _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.data.contentMessage; _ck(_v, 7, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 11).disabled || null); var currVal_5 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (_co.data.negativeText ? _co.data.negativeText : i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("No"))); _ck(_v, 12, 0, currVal_6); var currVal_8 = (i0.ɵnov(_v, 17).disabled || null); var currVal_9 = (i0.ɵnov(_v, 17)._animationMode === "NoopAnimations"); _ck(_v, 16, 0, currVal_8, currVal_9); var currVal_11 = (_co.data.positiveText ? _co.data.positiveText : i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform("Si"))); _ck(_v, 18, 0, currVal_11); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i0.ɵdid(1, 245760, null, 0, i10.ConfirmDialogComponent, [i11.AppService, i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i0.ɵccf("confirm-dialog", i10.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
