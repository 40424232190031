<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence'>
		<mat-label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<input matInput readonly *ngIf='inputService.inputConfig.readOnly' [required]='inputService.inputConfig.required' [value]='selectedLabel' />
		<mat-select #matSelect *ngIf='!inputService.inputConfig.readOnly' [required]='inputService.inputConfig.required' [placeholder]="inputService.inputConfig.placeholder | translate" [formControlName]='inputService.formControlKey' (openedChange)="openedChange($event)">
			<input class="myInput" 
				#myInput 
				matInput 
				focused="'true'" 
				type="text"
				(input)="search($event.target.value)" 
				autocomplete="off" 
				[placeholder]="'Cerca' | translate">
			<mat-progress-bar *ngIf="loading" mode='indeterminate' ></mat-progress-bar>
			<mat-divider></mat-divider>
			<mat-option *ngFor='let selectOption of selectOptions' [value]='selectOption.value'>
				<mat-icon *ngIf='inputService.formInputDefinition.remoteOptions.icon'>{{inputService.formInputDefinition.remoteOptions.icon}}</mat-icon>
				<span [innerHTML]='selectOption.label'></span>
			</mat-option>
		</mat-select>
		<mat-spinner *ngIf="loading" diameter="20" matSuffix></mat-spinner>
		<mat-icon matSuffix style='cursor: pointer;' *ngIf='!loading && inputService.control.value && !inputService.inputConfig.readOnly' (click)='inputService.control.setValue(null)'>close</mat-icon>
		<!-- mat-hint here generate an error, need to investigate -->
		<!--<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint}}</mat-hint>-->
		<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>