import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('name'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('direct_payment'),
			fieldsMap.get('num_rate'),
		]
	},
	fieldsMap.get('descrizione')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
