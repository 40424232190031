import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import {
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
	MatMomentDateModule,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
	FuseProgressBarModule,
	FuseSidebarModule,
	FuseThemeOptionsModule,
} from '@fuse/components';

import { themeConfig } from 'app/configs/theme-layout-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { MainModule } from './main/main.module';
import { AppRoutingModule } from './configs/app-routing.module';

import { AuthGuardService as AuthGuard } from 'app/auth/auth-guard.service';
import { LoggedGuardService as LoggedGuard } from 'app/auth/logged-guard.service';

import { HeadersInterceptor } from 'app/auth/headers.interceptor';
import { UnauthorizeInterceptor } from 'app/auth/unauthorize.interceptor';
import { CommonDialogsModule } from './main/dialogs/dialogs.module';
import { APP_BASE_HREF, CurrencyPipe, DecimalPipe, LocationStrategy } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { WebSocketService } from './services/websocket.service';
import { appConfig } from './configs/app-config';
import { APP_CONFIG } from './services/app-config.token';
import { AgmCoreModule } from '@agm/core';
import { SuperadminGuardService } from './auth/superadmin-guard.service';
import { ActionDialogModule } from './main/pages/edit-booking/action-dialog/action-dialog.module';
import { AppService } from './services/app.service';
import { getItaPaginatorIntl } from './i18n/ita-paginator-intl';
import { AppLocationStrategy } from './app-location.strategy';
import { environment } from 'environments/environment';
import { AppServiceWorkerModule } from './service-worker/module';
import { DatasetModule } from './main/components/dataset/dataset.module';

//import Bugsnag from '@bugsnag/js';
//import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

// the second parameter 'it' is optional
registerLocaleData(localeIt, 'it');

/**
 * Initialize application
 * @param appService
 * @param websocket
 */
export function startupServiceFactory(
	appService: AppService,
	websocket: WebSocketService
): Function {
	return () => {
		appService.init();

		websocket.isAuthenticated.subscribe(isAuth => {
			if (!isAuth) {
				websocket.clear();
			} else {
				websocket.init();
			}
		});
	};
}

// create a factory which will return the Bugsnag error handler
/*export function errorHandlerFactory() {
	return new BugsnagErrorHandler();
}*/

export const GOOGLE_API_KEY = 'AIzaSyBvkK6SN669qyITSX4zFJLdC-cXV2fSEd0';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,
		AppRoutingModule,

		TranslateModule.forRoot(),

		// Material moment date module
		MatMomentDateModule,

		// Material
		MatButtonModule,
		MatIconModule,
		MatSnackBarModule,
		MatDialogModule,

		// Fuse modules
		FuseModule.forRoot(themeConfig),
		FuseProgressBarModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseThemeOptionsModule,

		// App modules
		LayoutModule,
		CommonDialogsModule,
		ActionDialogModule,
		DatasetModule,
		MainModule,
		AgmCoreModule.forRoot({
			apiKey: GOOGLE_API_KEY,
		}),
		AppServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.serviceWorker })
		//ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
	],
	bootstrap: [AppComponent],
	providers: [
		AuthGuard,
		LoggedGuard,
		SuperadminGuardService,
		{ 
			provide: APP_BASE_HREF,
			useFactory: () => {
				let basePath = null;
				if(location.pathname.startsWith('/app')) basePath = '/app/'
				else if(location.pathname.startsWith('/s3app')) basePath = '/s3app/';
				if(!basePath) return location.origin;
				return location.origin+basePath;
			}
		},
		{ provide: LOCALE_ID, useValue: 'it' },
		{
			provide: MAT_DATE_LOCALE,
			useValue: 'it-IT',
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HeadersInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizeInterceptor,
			multi: true,
		},

		CurrencyPipe,
		DecimalPipe,

		{
			provide: APP_CONFIG,
			useValue: appConfig,
		},
		{
			// Provider for APP_INITIALIZER
			provide: APP_INITIALIZER,
			useFactory: startupServiceFactory,
			deps: [AppService, WebSocketService],
			multi: true,
		},
		{ provide: LocationStrategy, useClass: AppLocationStrategy },
		/*{ 
			provide: ErrorHandler, 
			useFactory: errorHandlerFactory 
		}*/
		{ provide: MatPaginatorIntl, useValue: getItaPaginatorIntl() },
	],
})
export class AppModule {}
