import { Component, OnChanges, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';

import {Subscription, Subject} from 'rxjs';
import {FieldHelper} from 'app/helpers';

import { IFieldDefinition, FieldViews, ValueTypes} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { MatDialog } from '@angular/material/dialog';
import { DatasetDetailDialogComponent } from 'app/main/components/dataset/dialogs/core-actions/detail/dataset-detail-dialog.component';
import { DatasetService } from '../../../services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { getHrefEventToCalendar } from 'app/helpers/calendar-event.helper';

@Component({
	selector   : 'data-field-viewer',
	templateUrl: './data-field-viewer.component.html',
	styleUrls  : ['./data-field-viewer.component.scss'],
	animations : fuseAnimations
})
export class DataFieldViewerComponent implements OnDestroy, OnChanges{

	@Input() public fieldDefinition: IFieldDefinition; // (IFieldContainer|IFieldDefinition)
	@Input() dataContainerService: IDataContainerService;
	@Input() record: any;
	public loading = false;

	public fieldViews = FieldViews;
	public computedValue: any = null;
	public isSetted = false;

	private _unsubscribeAll: Subject<any>;

	constructor(
		protected dialog: MatDialog,
		public appService: AppService,
		public datasetService: DatasetService
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnChanges(changes: SimpleChanges): void{
		this.computeValue();
	}

	computeValue(): void{
		this.isSetted = FieldHelper.isFieldValueSetted(this.fieldDefinition, this.record, undefined, this.dataContainerService);
		if(this.fieldDefinition.valueType === ValueTypes.REFERENCE && typeof(this.fieldDefinition.getDisplayValue) === 'function'){
			this.computedValue = null;
			const value = FieldHelper.getFieldValue(this.fieldDefinition, this.record, null, this.dataContainerService);
			if(!value){
				this.computedValue = null;
				return;
			}
			this.loading = true;
			this.datasetService.get('/dataset/' + this.fieldDefinition.datasetCode + '/detail/' + value)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: referenceRecord => {
					if(typeof(this.fieldDefinition.getDisplayValue) === 'function'){
						this.computedValue = this.fieldDefinition.getDisplayValue(this.record, referenceRecord);
					}
					this.loading = false;
				},
				error: response => {
					this.loading = false;
				}
			});
		}else{
			this.computedValue = FieldHelper.getDisplayFieldValue(this.fieldDefinition, this.record, undefined, this.dataContainerService);
		}
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onClick(): void{
		if(!this.fieldDefinition.clickOptions) return;
		if(this.fieldDefinition.clickOptions.openDetailDatasetCode){
			this.dialog.open(DatasetDetailDialogComponent, {
				data: {
					datasetCode: this.fieldDefinition.clickOptions.openDetailDatasetCode,
					recordId: this.fieldDefinition.clickOptions.getDetailRecordId(this.record),
				}
			});
		}
	}

	getHrefEventToCalendar(event): string{
		return getHrefEventToCalendar(event);
	}
}
