import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('entity_staff_element_id'),
	fieldsMap.get('note')
];

const config: IFormConfig = { 
	title: 'Aggiungi Personale',
	fields: formFields 
};

export default config;
