import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import * as _ from 'lodash';
import {AppService} from '../../../../../services/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
	selector: 'trenitalia-seats',
	templateUrl: './trenitalia-seats.component.html',
	styleUrls: ['./trenitalia-seats.component.scss']
})
export class TrenitaliaSeatsComponent implements OnInit, OnDestroy {
	public checkoutData;
	public seatsData;
	public passengers;
	private wagonSel: any;
	public trainId:any;
    public trainStep:any;
    public nextTrainStep= false;
    public prevTrainStep = false;
    public total:any;

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public trnService: TrenitaliaService
	) {
		this._unsubscribeAll = new Subject;
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnInit() {
        this.trnService.scrollToTop();
        this.trnService.total
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(total => {
                this.total = total;

            })

		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
			if (this.checkoutData.selectedSolution && !Array.isArray(this.checkoutData.selectedSolution.travellers)) {
				this.checkoutData.selectedSolution.travellers = [this.checkoutData.selectedSolution.travellers];
			}
			this.passengers = this.checkoutData.travellers;
			if(!this.trainId) this.trainId = _.get(this.checkoutData,'selectedSolution.trains[0].id');

			if (this.checkoutData.seats && this.checkoutData.selection) {
				this.seatsData = this.checkoutData.seatMaps;

				this.checkoutData.selectedSolution.trains.forEach(t => {
					if(this.seatsData && !_.has(this.seatsData[t.id],'seats')) this.seatsData[t.id].seats=0;
				});
			}
            if(_.has(this.checkoutData,'selectedSolution')){
                this.nextTrainStep = this.checkoutData.selectedSolution.trains.length -1 > this.trainStep;
            }
            this.trnService.scrollToTop();
		});
        this.trnService.trainSeatStep
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(step =>{
            this.trainStep = step;
            this.trainId = _.get(this.checkoutData,['selectedSolution','trains',this.trainStep,'id']);
            this.nextTrainStep = this.checkoutData.selectedSolution.trains.length-1 > this.trainStep;
            this.prevTrainStep = this.trainStep > 0;
        });

		// @ts-ignore
		window.unselectSeat = seatNum => {
			const traveller = this.checkoutData.selectedSolution.travellers.find(t => {
				return _.get(this.seatsData, [this.trainId, 'travellers', t.xmlId, 'seat', 'seatNum']) == seatNum
				&& _.get(this.seatsData, [this.trainId, 'travellers', t.xmlId, 'seat', 'wagon']) == this.wagonSel;
			});
			if(!traveller){
				// refresh iframes?
				return;
			}
			delete this.seatsData[this.trainId].travellers[traveller.xmlId].seat;
			this.seatSelected({ value:- 1,pass:traveller.xmlId, train:this.trainId});
		};

		// @ts-ignore
		window.normalNavigation = true;
		// @ts-ignore
		window.selectSeat = (seatNum, url, seatToShow) => {
			// first traveller without a seat
			const traveller = this.checkoutData.selectedSolution.travellers.find(t => {
				return !_.has(this.seatsData, [this.trainId, 'travellers', t.xmlId, 'seat']);
			});
			if(!traveller){
				// no seat to set
				return;
			}
			this.seatsData[this.trainId].travellers[traveller.xmlId].seat = {
				seatNum: seatNum,
				seatNumToShow: seatToShow,
				wagon: this.wagonSel,
				train: this.trainId
			};
			this.seatSelected({ value: 1, pass: traveller.xmlId, train:this.trainId});
		};

		// @ts-ignore
		window.onWagonIframeLoaded = (wagonId, doc, highlight) => {
			for (const traveller of this.checkoutData.selectedSolution.travellers) {
				if(_.get(this.seatsData, [this.trainId, 'travellers', traveller.xmlId, 'seat', 'wagon']) == wagonId){
					highlight(_.get(this.seatsData, [this.trainId, 'travellers', traveller.xmlId, 'seat', 'seatNum']));
				}
			}
            this.trnService.scrollToTop();
		}



	}

	seatSelected(e:any) {

		// recalc seat selection counters
		let totalSeatSetted = 0;
		for (const train of this.checkoutData.selectedSolution.trains) {
			this.seatsData[train.id].seats = this.checkoutData.selectedSolution.travellers.filter(t => {
				return _.has(this.seatsData, [train.id, 'travellers', t.xmlId, 'seat', 'seatNum']);
			}).length;
			totalSeatSetted += this.seatsData[train.id].seats;
		}
		this.checkoutData.seatSel = totalSeatSetted;
		
		// todo: use a recalc methods
		if (
			_.has(this.seatsData,e.train+'.travellers.'+e.pass+'.prices.amount')
		) {
			/*let total =this.trnService.total.getValue();*/
			const price = +_.get(this.seatsData,e.train+'.travellers.'+e.pass+'.prices.amount')
			if(e.value==1) {
				if(this.total['fee']>0){this.total['valueNet']+=price}else{this.total['value']+=price}
			}else{
                if(this.total['fee']>0){this.total['valueNet']-=price}else{this.total['value']-=price}
			}
            if(this.total['fee']>0 && totalSeatSetted == this.checkoutData.seatsCount){

                const data = {
                    travellers: this.checkoutData.travellers,
                    solution: this.checkoutData.dataAv.travelSolutions,
                    type:this.checkoutData.mode=='travel'?'sale':'post-sale',
                    totalNet:this.total['valueNet']
                }
                this.trnService.getFee(data).subscribe(fee => {
                    this.total['valueNet'] = fee['valueNet'];
                    this.total['fee'] = fee['fee'];
                    this.total['value'] = fee['value'];
                    this.trnService.total.next(this.total);
                    this.checkoutData.totalSelectedSolution = this.total;
                    this.trnService.checkoutData.next(this.checkoutData);
                })
            }
			this.trnService.total.next(this.total);
		}
		this.trnService.checkoutData.next(this.checkoutData);
	}

	onTrainChanged(event){
		this.trnService.trainSeatStep.next(event.index);
	}
	setWagon(e){
		this.wagonSel = e;
	}

    changeStepTrain(num:number){
        this.trnService.trainSeatStep.next(this.trainStep + num);
    }
}
