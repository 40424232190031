import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';
var SourceMarketsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SourceMarketsComponent, _super);
    function SourceMarketsComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        /**
         * Chart SourceMarkets
         */
        // Pie
        _this.pieChartOptions = {
            responsive: true,
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true
                }
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(data.datasets[0].data[tooltipItem.index]);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            },
            plugins: {
                datalabels: {
                    formatter: function (value, ctx) {
                        var label = ctx.chart.data.labels[ctx.dataIndex];
                        return label;
                    }
                },
            }
        };
        _this.pieChartType = 'doughnut';
        _this.pieChartLegend = true;
        _this.pieChartColors = [
            {
                backgroundColor: [
                    'rgba(88,24,69,0,0.3)',
                    'rgba(245,45,10,0.3)',
                    'rgba(245,145,10,0.3)',
                    'rgba(245,238,10,0.3)',
                    'rgba(68,241,17,0.3)',
                    'rgba(17,241,41,0.3)',
                    'rgba(17,241,218,0.3)',
                    'rgba(17,204,241,0.3)',
                    'rgba(33,255,0,0.3)',
                    'rgba(11,0,255,0.3)',
                    'rgba(17,150,241,0.3)',
                    'rgba(17,92,241,0.3)',
                    'rgba(27,17,241,0.3)',
                    'rgba(150,17,241,0.3)',
                    'rgba(221, 17, 241,0.3)',
                    'rgba(241, 17, 201,0.3)',
                    'rgba(241, 17, 160,0.3)',
                    'rgba(241,17,105,0.3)',
                    'rgba(255,0,0,0.3)',
                    'rgba(0,255,0,0.3)',
                    'rgba(0,0,255,0.3)'
                ],
            },
        ];
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    SourceMarketsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardService.sourceMarketResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.onData(response);
            }
        });
    };
    // -----------------------------------------------------------------------------------------------------
    SourceMarketsComponent.prototype.onData = function (response) {
        var e_1, _a;
        this.showLoader = true;
        if (!response)
            return;
        else if (response.length > 0) {
            this.showLoader = false;
        }
        var data = [];
        try {
            for (var response_1 = tslib_1.__values(response), response_1_1 = response_1.next(); !response_1_1.done; response_1_1 = response_1.next()) {
                var item = response_1_1.value;
                data.push({
                    'value': Number(item.percent_pax).toFixed(2),
                    'label': item.nation_code
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (response_1_1 && !response_1_1.done && (_a = response_1.return)) _a.call(response_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        data.sort(function (a, b) {
            return b.value - b.value;
        });
        this.sourcemarkets = data.map(function (item) { return item.value; });
        this.sourcelabel = data.map(function (item) { return item.label; });
        var colors = [];
        for (var i = 0; i < data.length; i++) {
            colors.push(chartColors[i]);
        }
        this.pieChartColors[0].backgroundColor = colors;
    };
    return SourceMarketsComponent;
}(DestroyableComponent));
export { SourceMarketsComponent };
