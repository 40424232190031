import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { ActionDialogComponent, IActionDialogData } from "app/main/pages/edit-booking/action-dialog/action-dialog.component";

export default async function setPlafond(datasetNavigator: DatasetNavigatorService, data: any, record: any): Promise<any>{
	if (!data) return;

	const loadingDialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
		title: 'Attendere durante il completamento della richiesta...',
		contentMessage: ''
	});

	data['domainPackageId'] = record.id;

	try{
		const response = await datasetNavigator.datasetService.post<any>('/dataset/domain_packages/command/set_plafond', data)
		.toPromise();

		if (response){
			loadingDialogRef.componentInstance.loading = false;
			if (response.success){
				loadingDialogRef.componentInstance.resultStatus = 'success';
				loadingDialogRef.componentInstance.setTitle('Plafond impostato');
				loadingDialogRef.componentInstance.setMessage('Il plafond è stato associato correttamente alla richiesta. Vedi dettaglio richiesta.');
			}else{
				loadingDialogRef.componentInstance.resultStatus = 'error';
				loadingDialogRef.componentInstance.setTitle('Errore operazione');
				loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
			}
		}

		return response;
	} catch(e){
		loadingDialogRef.componentInstance.loading = false;
		loadingDialogRef.componentInstance.resultStatus = 'error';
		loadingDialogRef.componentInstance.setTitle('Errore operazione');
		loadingDialogRef.componentInstance.setMessage('Si è veriricato un errore. Contatta l\'amministratore.');
	}
}