<h1 mat-dialog-title>{{'Genera Partenze' | translate}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>
	<div class='full-form' [ngClass]='formConfig.formStyle'>
		<form-viewer #formViewer
			[dataContainerService]='datasetACS' 
			[formConfig]='formConfig'
		></form-viewer>
	</div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="onNoClick()">{{'Annulla' | translate}}</button>
	<button mat-flat-button [disabled]='loading' (click)='onSave()' color='accent' >{{'Genera' | translate}}</button>
</mat-dialog-actions>
