import * as tslib_1 from "tslib";
import { DatasetActionOpenType, ListViewItemTypes, ListViewExportTypes } from 'app/interfaces';
import { fieldsMap } from './fields';
import baseInfoFormConfig from './forms/base.form';
import listFilterForm from './forms/filter.form';
import { LIST_COLUMNS } from './list_columns';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
var callModule = {
    name: 'domainModules',
    path: '/dataset/domains/command/config',
    params: {
        key: 'modules'
    }
};
export var TripBookingReportViewConfig = {
    name: 'trip_booking_report_views',
    title: 'Prenotazioni',
    singleTitle: 'Prenotazione',
    ajaxDatasetCode: 'trip_booking_report_views',
    fieldsMap: fieldsMap,
    supportProperties: true,
    enabledActions: {
        detail: true,
        create: true,
        edit: true,
        list: true
    },
    formConfigsMap: {
        baseInfoFormConfig: baseInfoFormConfig
    },
    defaultActions: {
        detail: {
            beforeCalls: [callModule,
                {
                    name: 'domainContext',
                    path: '/dataset/trip_booking_report_views/beforeCreate/:recordId',
                }]
        },
        create: {
            beforeCalls: [callModule]
        },
        list: {
            beforeCalls: [callModule],
            openType: DatasetActionOpenType.PAGE,
            getCallParams: function (dataContainerService, parentData, params) {
                var e_1, _a;
                var moduleTicketingEnabled = dataContainerService && dataContainerService.getValueFromKeyPath('domainModules.ticketing.enabled');
                if (moduleTicketingEnabled) {
                    params['addColumn[]'] = ['email'];
                }
                var customFields = dataContainerService.getDomainSetting('custom_fields.report.add.field_list');
                if (customFields) {
                    params['addColumn[]'] = params['addColumn[]'] ? params['addColumn[]'] : [];
                    try {
                        for (var customFields_1 = tslib_1.__values(customFields), customFields_1_1 = customFields_1.next(); !customFields_1_1.done; customFields_1_1 = customFields_1.next()) {
                            var fields = customFields_1_1.value;
                            params['addColumn[]'].push(fields);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (customFields_1_1 && !customFields_1_1.done && (_a = customFields_1.return)) _a.call(customFields_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                params['with_presences'] = 1;
                return params;
            },
            viewConfig: {
                itemViewType: ListViewItemTypes.TABLE,
                showSearchBar: true,
                showExportBtn: true,
                exportBtnActions: [
                    {
                        typeFile: ListViewExportTypes.XLS,
                        buttonText: 'Esporta xls'
                    },
                    {
                        typeFile: ListViewExportTypes.CSV,
                        buttonText: 'Esporta csv'
                    },
                    {
                        typeFile: ListViewExportTypes.CSV_VIA_EMAIL,
                        buttonText: 'Esporta csv via EMail'
                    }
                ],
                defaultSortBy: 'trip_booking_booking_date',
                defaultSortDirection: 'desc',
                filterForm: listFilterForm,
                cacheDatasetListFilters: true,
                columns: LIST_COLUMNS,
                actions: [],
                customActions: [
                    {
                        buttonColor: 'primary',
                        buttonIcon: 'edit',
                        tooltip: 'Modifica',
                        makeRouteLink: function (record, dataContainerService, datasetNavigatorService) {
                            return '/edit-booking/' + record.trip_booking_id;
                        },
                        showIf: function () { return true; }
                    },
                    {
                        buttonColor: 'primary',
                        buttonIcon: 'done',
                        tooltip: 'Conferma Consegna',
                        onClick: function (event, record, dataContainerService, datasetNavigatorService) {
                            datasetNavigatorService.datasetService.post('/dataset/trip_bookings/command/confirm_delivery', {
                                trip_booking_package_id: record.trip_booking_package_id,
                                delivery_status: 'consegnato' // status = in consegna or consegnato
                            }).subscribe({
                                next: function (response) {
                                    if (dataContainerService instanceof DatasetActionContainerService) {
                                        dataContainerService.datasetEvent.next({
                                            eventName: DatasetEvents.ACTION_REALOAD_LIST
                                        });
                                    }
                                    datasetNavigatorService.appService.showSuccessMessage('Stato della consegna aggiornato.');
                                },
                                error: function (response) {
                                    datasetNavigatorService.datasetService.appService.showErrorMessage('Si è verificato un errore.');
                                }
                            });
                        },
                        showIf: function (record, dataContainerService) {
                            return record && record.delivery_status === 'in consegna';
                        }
                    }
                ]
            },
            webSocketConfig: {
                created: {
                    reloadDatasetList: true,
                    showToast: true,
                    dismissToastTxt: "Chiudi"
                },
                updated: {
                    reloadDatasetList: true,
                    showToast: true,
                    dismissToastTxt: "Chiudi"
                },
                command_executed: {
                    showToast: true,
                    dismissToastTxt: "Chiudi",
                    reloadDatasetListOnDismissToast: true,
                    composeToastMessage: function (socketEvent) {
                        if (socketEvent.data.payloadData.command && socketEvent.data.payloadData.record) {
                            switch (socketEvent.data.payloadData.command) {
                                case 'command_confirm_booking':
                                    return 'La prenotazione ' + socketEvent.data.payloadData.record.booking_code + ' è stata confermata';
                                case 'command_cancel_booking':
                                    return 'La prenotazione ' + socketEvent.data.payloadData.record.booking_code + ' è stata annullata';
                                default: return '';
                            }
                        }
                    },
                    highlightAffectedRow: function (record, socketEvent) {
                        return false;
                    }
                },
                composeGlobalToastMessage: function (socketEvent) {
                    if (socketEvent.data.eventName == 'created' && socketEvent.data.payloadData.multiple && socketEvent.data.payloadData.count) {
                        return 'Ci sono ' + socketEvent.data.payloadData.count + ' nuove prenotazioni';
                    }
                    else if (socketEvent.data.eventName == 'updated') {
                        return 'Ci sono ' + socketEvent.data.payloadData.count + ' prenotazioni aggiornate';
                    }
                    return 'Operazione eseguita tramite socket';
                },
                checkGloballyAffectedRow: function (record, socketEvent) {
                    if (socketEvent) {
                        if (socketEvent.data.payloadData.multiple && socketEvent.data.payloadData.records) {
                            return socketEvent.data.payloadData.records[record.id] || false;
                        }
                        else if (socketEvent.data.payloadData.record) {
                            return record.trip_booking_id == socketEvent.data.payloadData.record.id;
                        }
                    }
                    return false;
                }
            },
        }
    }
};
