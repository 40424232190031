import { Validators } from '@angular/forms';
import { 
	IFieldContainer, 
	IFieldDefinition, 
	IFormConfig, 
	InputTypes, 
	ValueTypes, 
	FieldViews 
} from 'app/interfaces';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		key: 'plafond_id',
		name: 'Plafond',
		inputType: InputTypes.DATASET_SELECT,
		valueType: ValueTypes.REFERENCE,
		datasetCode: 'plafond',
		remoteOptions: {
			supportCreate: true,
			limit: 100,
			getLabel(option): any{
				if(!option) return '';
				return '(' + option.code + ') ' + option.description;
			},
			getValue(option): any{
				if(!option) return;
				return option.id;
			},
			getDisplay(option): any{
				if(!option) return '';
				return '(' + option.code + ') ' + option.description;
			}
		},
		formValidators: [ Validators.required ]
	}
];

const config: IFormConfig = { 
	title: 'Modifica Conferma',
	editTitle: 'Modifica Conferma',
	fields: formFields 
};

export default config;
