import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export const MeetingPointListTOReferenceConfig: IDatasetConfig = {
	name: 'meeting_point_list_to_references',
	title: 'Map In Tour Operator',
	singleTitle: 'Map In Tour Operator',
	ajaxDatasetCode: 'meeting_point_list_to_references',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: false,
		edit: true,
		list: true
	},
	defaultActions: {
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['unified_list_id'] = parentData.id;
				params['tour_operator_reference_id'] = parentData.tour_operator_reference_id;
				params['tour_operator_id'] = parentData.tour_operator_id;
				return params;
			},
			viewConfig: {
				formConfig: generalForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Punto di ritrovo',
						key: 'description'
					},
					{
						title: 'Orario',
						key: 'time'
					},
					{
						title: 'Tour ID',
						key: 'to_ref'
					},
				],
				actions: ['edit']
			}
		}
	}
};
