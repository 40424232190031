import { Component, Input, OnInit, ViewChild, NgZone } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IFormConfig, IStepConfig, IFieldDefinition } from 'app/interfaces';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';

@Component({
	selector   : 'product-i18n-form-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ DatasetActionContainerService, DatasetRecordService, DatasetMultilanguageContainerService, FormViewerService]
})
export class ProductI18nFormStepComponent extends BaseStepViewComponent implements OnInit{
	
	@Input() isLastStep = true;
	@Input() stepConfig: IStepConfig;
	@Input() fileterFields: Map<string, IFieldDefinition>;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;
	formErrors: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService,
		public formBuilder: FormBuilder,
		protected zone: NgZone,
		public formViewerService: FormViewerService,
		protected stepService: StepService,
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.datasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentValueKey
			});

			this.formViewerService.init({
				errors: this.formErrors,
				dataContainerService: this.datasetACS,
				fields: Array.from(this.fileterFields.values())
			});
		});

		this.datasetRS.record.subscribe((record) => this.formViewerService.setRecord(record));

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
	}

	onSave(): void{
		this.formViewerService.formGroup.disable();
		let formData = this.formViewerService.formGroup.getRawValue();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		this.datasetMCS.save(formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				if(this.formViewerService){
					this.formViewerService.formGroup.enable();
				}
				// for reaload active language when a new one is created
				if(!this.datasetRS.recordId){
					this.datasetACS.parentDatasetRS.reload();
					this.datasetMCS.fetchAll();
				}
				setTimeout(() => {
					this.stepService.setStepState({
						dataset_code: 'products',
						form_code: 'product-i18n-form-step',
						record_id: this.parentDatasetRS.recordId,
						status: 1
					});

					this.goNextStep();
				}, 200);
			},
			error: response => {
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.formViewerService && this.formViewerService.formGroup){
					this.formViewerService.formGroup.enable();
					this.formViewerService.setErrors(this.formErrors);
				}
			}
		});
	}
}
