<h2>Informazioni generali</h2>
<mat-card class='p-24 mat-elevation-z0'>
	<mat-card-content>
		<data-container-viewer 
			[dataContainerService]='datasetACS' 
			[fields]='formConfig.fields' 
			[record]='datasetRS.record.value'
		></data-container-viewer>
	</mat-card-content>
</mat-card>

<h2>Invio dati server RT</h2>
<ng-container *ngFor='let row of datasetRS.record.value.request_document_RT.receipt.rows; let i = index;'>
	<mat-card class='p-24 mat-elevation-z0'>
		<mat-card-content>
			<h4 style="font-weight: bold !important; margin-bottom: 10px; text-transform: uppercase;">Riga numero {{i + 1}}</h4>
			<data-container-viewer 
				[dataContainerService]='datasetACS' 
				[fields]='request_document_RT_form.fields' 
				[record]='row'
			></data-container-viewer>
		</mat-card-content>
	</mat-card>
</ng-container>

<h2>Risposta dati server RT</h2>
<mat-card class='p-24 mat-elevation-z0'>
	<mat-card-content>
		<data-container-viewer 
			[dataContainerService]='datasetACS' 
			[fields]='response_document_RT_form.fields' 
			[record]='datasetRS.record.value.response_document_RT'
		></data-container-viewer>
	</mat-card-content>
</mat-card>