import { Component, Input, OnInit, OnDestroy, Injector, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DomainFilterService } from 'app/services/domain-filter.service';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { ITripBookingPackageRelatedDataDialogData } from '../related-data/abstract-trip-booking-package-related-data.component';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { ITripPackageOverviewDialogComponent, NewTripPackageOverviewDialogComponent } from 'app/main/components/dataset/dialogs/new-trip-package-overview/dialog.component';
import { ISelectDatasetRecordDialogData, SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { ActualTripDateComponent, IActualTripDateDialogData } from 'app/main/components/dataset/dialogs/actual-trip-date/dialog.component';
import { BookingStep2Service } from '../../../step-2/view.service';
import sendDeliveryAction from 'app/main/components/dataset/dialogs/delivery-email-dialog/delivery.email';
import sendDeliveryCodeAction from 'app/main/components/dataset/dialogs/delivery-email-dialog/delivery.code.email';

@Component({
	selector   : 'ticket-booking',
	templateUrl: './ticket-booking.component.html',
	styleUrls: ['./ticket-booking.component.scss'],
	providers: [BookingStep2Service]
})
export class TicketBookingComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public loadingMeetingPoint = false;
	public hasEditableData = false;
	public canEditData = false;

	@Input() iterationData: any;
	@Output() public changed = new EventEmitter<any>();

	get tripBookingPackage(): any{
		return this.datasetRS.record.value;
	}

	get isResaleCopy(): boolean{
		return Boolean(this.datasetRS.record.value.ref_id);
	}

	get tripBookingStatus(){
		return this.bookingStepService && this.bookingStepService.datasetRS.record.value && this.bookingStepService.datasetRS.record.value.status;
	}

	get tripBookingPackageRelatedDialogData(): ITripBookingPackageRelatedDataDialogData{
		return {
			datasetCode: '',
			actionCode: '',
			tripBookingPackage: this.tripBookingPackage,
		};
	}

	get canDelete(){
		return this.bookingService.canEdit && !this.tripBookingPackage.tour_operator_booking_ref && !this.tripBookingPackage.tour_operator_booking_commit_status;
	}

	get canEdit(){
		return this.hasEditableData && !this.canEditData;
	}

	get meetingPointDescription(): string{
		if(!this.tripBookingPackage || !this.tripBookingPackage.default_meeting_point_unified_list_item) return null;
		if(!this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point) return null;
		let description = this.tripBookingPackage.default_meeting_point_unified_list_item.meeting_point.description;
		let time: string = this.tripBookingPackage.default_meeting_point_unified_list_item.time;
		if(time){
			time = '(' + time.slice(0, 5) + ')';
			description += ' ' + time;
		}
		return description;
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public domainFilterService: DomainFilterService,
		public bookingStepService: BookingStep2Service,
		public tripBookingPackageService: TripBookingPackageService,
		public bookingService: BookingService,
		private injector: Injector,
		public viewContainerRef: ViewContainerRef,
	){
		super();
	}

	ngOnInit(): void{}


	deleteTripBookingPackage(): void{
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
		.afterClosed()
		.subscribe(result => {
			if(result){
				this.appService.appEvent.next({
					name: RELOAD_BOOKING_PACKAGES
				});
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			}
		});
	}

	trackTripBookingResourcesFun(index: number, item: any): any{
		return item.id;
	}

	reloadTripBooking(): void{
		this.appService.appEvent.next({ name: RELOAD_BOOKING });
	}

	viewTripPackage(): void{
		this.datasetNavigatorService.openDialog<ITripPackageOverviewDialogComponent>(NewTripPackageOverviewDialogComponent, {
			parentDatasetRS: this.datasetRS,
			parentDatasetACS: this.datasetACS,
			tripPackage: this.tripBookingPackage.trip_package
		}, {minWidth: 700});
	}

	editDefaultMeetingPoint(): void{
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'meeting_point_unified_list_items',
			filters: {
				list_id: this.tripBookingPackage.actual_trip.meeting_point_list_id,
				default_meeting_point_list_item_id: this.datasetRS.record.value.default_meeting_point_list_item_id
			},
			title: 'Punto di ritrovo',
			parentDatasetACS: this.datasetACS,
			parentDatasetRS: this.datasetRS
		};
		this.matDialog.open(SelectDatasetRecordDialogComponent, {
			data
		}).afterClosed()
		.subscribe(record => {
			if(!record) return;
			this.loadingMeetingPoint = true;
			this.datasetService.post(this.datasetACS.getUpdateRoute(this.datasetRS.record.value), {
				default_meeting_point_list_item_id: record.id
			}).pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: response => {
					this.datasetRS.reload().subscribe({
						next: () => {
							this.loadingMeetingPoint = false;
						},
						error: () => {
							this.loadingMeetingPoint = false;
						}
					});
				},
				error: response => {
					this.loadingMeetingPoint = false;
				}
			});
		});
	}

	openDialog() {
		const data: IActualTripDateDialogData = {
			parentDatasetACS: this.datasetACS,
			tripBookingPackage: this.tripBookingPackage,
			bookingStepService: this.bookingStepService,
		};

		this.matDialog.open(ActualTripDateComponent, {
			data: data
		}).afterClosed()
		.subscribe({
			next: response => {
				this.datasetRS.reload();
			},
		});
	}

	emailConfirmPlaceDate(): void {
		sendDeliveryAction({
			trip_booking_id: this.tripBookingPackage.trip_booking_id,
			trip_package_id: this.tripBookingPackage.trip_package_id
		}, this.datasetACS, this.datasetNavigatorService);
	}

	emailDeliveryCode(): void {
		sendDeliveryCodeAction({
			trip_booking_id: this.tripBookingPackage.trip_booking_id,
			trip_booking_package_id: this.tripBookingPackage.id
		}, this.datasetACS, this.datasetNavigatorService);
	}

	confirmPlaceDate(): void{
		this.bookingService.setDeliveryStatus(this.tripBookingPackage.id, 'in consegna')
		.subscribe(() => {
			this.datasetRS.reload();
		});
	}

	confirmDelivery(): void{
		this.bookingService.setDeliveryStatus(this.tripBookingPackage.id, 'consegnato')
		.subscribe(() => {
			this.datasetRS.reload();
		});
	}
}
