import { Component, Input, OnInit, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { CommonBlockStepComponent } from './step.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'common-sub-dataset-blocks-step',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class CommonSubDatasetBlocksStepComponent extends CommonBlockStepComponent implements OnInit{

	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: this.parentDatasetACS.actionCode,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: false
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(!record) return;
			this.datasetRS.recordId = this.stepConfig.options.getSubDatasetRecordId(record);
			this.datasetRS.reload();
		});

		super.ngOnInit();
	}
}