import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TripBookingPackageRelatedComponent } from '../trip-booking-packages-related.component';
var TripBookingServiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingServiceComponent, _super);
    function TripBookingServiceComponent(appService, datasetACS, datasetRS, datasetNavigatorService, matDialogRef, data) {
        var _this = _super.call(this, appService, datasetACS, datasetRS, matDialogRef, data) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialogRef = matDialogRef;
        _this.data = data;
        return _this;
    }
    TripBookingServiceComponent.prototype.addTripBookingService = function () {
        this.datasetNavigatorService.openCreateDialog(this.datasetACS);
    };
    return TripBookingServiceComponent;
}(TripBookingPackageRelatedComponent));
export { TripBookingServiceComponent };
