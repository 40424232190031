import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, } from '@angular/core';
import { DatasetActionContainerService, IDatasetEvent, } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { BookingService } from '../booking.service';
import { FormControl, Validators, } from '@angular/forms';
import { FormDialogComponent, } from 'app/main/dialogs/form-dialog/dialog.component';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import { BaseStepChangerComponent } from '../base-step-changer.component';
var EditBookingStep3Component = /** @class */ (function (_super) {
    tslib_1.__extends(EditBookingStep3Component, _super);
    function EditBookingStep3Component(appService, datasetRS, datasetACS, datasetNavigatorService, bookingService) {
        var _this = _super.call(this, bookingService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.bookingService = bookingService;
        _this.loading = false;
        _this.couponCodeFormControl = new FormControl('', [Validators.required]);
        return _this;
    }
    Object.defineProperty(EditBookingStep3Component.prototype, "record", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    EditBookingStep3Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        console.log(this.record);
    };
    EditBookingStep3Component.prototype.onDiscountDatasetEvent = function (event) {
        this.datasetRS.reload();
    };
    EditBookingStep3Component.prototype.openDiscountListForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.datasetNavigatorService
                            .openDialog(FormDialogComponent, {
                            title: 'Aggiungi sconto',
                            dataContainerService: this.datasetACS,
                            formConfig: {
                                fields: [
                                    {
                                        key: 'coupon_code',
                                        name: 'Sconto',
                                        valueType: ValueTypes.REFERENCE,
                                        inputType: InputTypes.DATASET_SELECT,
                                        datasetCode: 'discounts',
                                        remoteOptions: {
                                            limit: 100,
                                            getLabel: function (option) {
                                                if (!option)
                                                    return '';
                                                return option.description ? option.name + ' - ' + option.description : option.name;
                                            },
                                            getValue: function (option) {
                                                if (!option)
                                                    return;
                                                return option.coupon_code;
                                            },
                                            getDisplay: function (option) {
                                                if (!option)
                                                    return '';
                                                return option.description ? option.name + ' - ' + option.description : option.name;
                                            },
                                        },
                                    },
                                ],
                            },
                        }, { minWidth: 700 })
                            .afterClosed()
                            .toPromise()];
                    case 1:
                        dialogRef = _a.sent();
                        if (!dialogRef)
                            return [2 /*return*/]; // operazione annullata
                        if (dialogRef && !dialogRef.coupon_code)
                            return [2 /*return*/]; // operazione annullata
                        return [4 /*yield*/, this.addCouponCode(dialogRef.coupon_code)];
                    case 2: // operazione annullata
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EditBookingStep3Component.prototype.addCouponCode = function (couponCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loadingDialogRef2, response, responseMessage, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadingDialogRef2 = this.datasetNavigatorService.openDialog(LoadingDialogComponent, {
                            title: 'Aggiunta sconto in corso',
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.datasetNavigatorService.datasetService
                                .post('/dataset/trip_booking_discounts/command/add_coupon', {
                                trip_booking_id: this.datasetRS.recordId,
                                code: couponCode,
                            })
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        responseMessage = '';
                        if (response.message) {
                            responseMessage = response.message;
                        }
                        if (response.success) {
                            if (this.datasetNavigatorService) {
                                this.datasetNavigatorService.appService.showSuccessMessage(responseMessage || 'Aggiunta avventuta con successo');
                            }
                        }
                        else {
                            if (!responseMessage)
                                responseMessage =
                                    "Si è verificato un problema durante l'aggiunta del codice sconto.";
                            if (this.datasetNavigatorService) {
                                this.datasetNavigatorService.appService.showErrorMessage(responseMessage);
                            }
                        }
                        if (response.success) {
                            this.datasetRS.reload();
                            // reload dataset list discounts
                            this.datasetListComponent.reload();
                        }
                        return [2 /*return*/, response.success];
                    case 3:
                        e_1 = _a.sent();
                        if (this.datasetNavigatorService) {
                            this.datasetNavigatorService.appService.showErrorMessage(e_1.error.message);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        // reset coupon code input
                        this.couponCodeFormControl.reset();
                        // finally close loading dialog
                        loadingDialogRef2.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return EditBookingStep3Component;
}(BaseStepChangerComponent));
export { EditBookingStep3Component };
