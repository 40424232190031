import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, InputTypes, ValueTypes, IFieldDefinition, FieldViews } from 'app/interfaces';
import { fieldsMap } from './fields';

export const TripOptionsConfig: IDatasetConfig = {
	name: 'trip_options',
	title: 'Options setup',
	singleTitle: 'Options setup',
	ajaxDatasetCode: 'trip_options',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		delete: true,
		list: true
	},
	defaultActions: {
		list: {
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
					},
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Durata',
						key: 'duration',
					},
					{
						title: 'Traduzioni Attive',
						key: 'languages',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): string{
							let html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
					
							if(record.languages && record.languages.length > 0){
								const style = 'style="margin-right: 5px;"';
								for (const lang of record.languages) {
									const langVal = lang.value;
									html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + langVal + '.svg" alt="' + langVal + '" title="' + langVal + '" />';
								}
							}else{
								html += '<span>---</span>';
							}
					
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Lingue audio guide',
						key: 'audio_guide_languages',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): string{
							let html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
					
							if(record.audio_guide_languages && record.audio_guide_languages.length > 0){
								const style = 'style="margin-right: 5px;"';
								for (const lang of record.audio_guide_languages) {
									const langVal = lang.value;
									html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + langVal + '.svg" alt="' + langVal + '" title="' + langVal + '" />';
								}
							}else{
								html += '<span>---</span>';
							}
					
							html += '</div>';
							return html;
						}
					}
				]
			},
		}
	}
};
