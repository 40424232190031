import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap = new Map<string, IFieldDefinition>();

// #region age fields
fieldsMap.set('age_adt', {
	key: 'age_adt',
	name: 'Età',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

fieldsMap.set('age_chd', {
	key: 'age_chd',
	name: 'Età',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

fieldsMap.set('age_inf', {
	key: 'age_inf',
	name: 'Età',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

fieldsMap.set('age_snr', {
	key: 'age_snr',
	name: 'Età',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
});

fieldsMap.set('trip_service_rule_id', {
	key: 'trip_service_rule_id',
	name: 'Criterio calcolo prezzo',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	appearance: 'outline',
	inputRequired: true,
	formValidators: [Validators.required],
	options: [
		{
			label: 'Prezzo fisso',
			value: 0
		},
		{
			label: 'Prezzo per partecipante',
			value: 1
		}
	]
});

fieldsMap.set('price', {
	key: 'price',
	name: 'Prezzo',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	appearance: 'outline',
	inputRequired: true,
	formValidators: [Validators.required],
});

fieldsMap.set('option_id', {
	key: 'option_id',
	name: 'Lista opzioni',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_options',
	appearance: 'outline',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record?: any): any{
			const params = {};
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			if(tripPackageId){
				params['trip_package_id'] = tripPackageId;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('valid_from', {
	key: 'valid_from',
	name: 'Valido dal',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'outline'
});

fieldsMap.set('valid_to', {
	key: 'valid_to',
	name: 'Valido al',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'outline',
});

export default fieldsMap;
