import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { AppService } from 'app/services/app.service';
import { Router, UrlSerializer } from '@angular/router';
import { Location } from '@angular/common';

export interface ITripPackageOverviewDialogComponent {
	tripPackage: any;
	parentDatasetACS?: DatasetActionContainerService;
	parentDatasetRS?: DatasetRecordService;
}

@Component({
	selector: 'new-trip-package-overview',
	templateUrl: 'dialog.component.html',
	styleUrls: ['dialog.component.scss'],
	providers: [DatasetNavigatorService],
})
export class NewTripPackageOverviewDialogComponent implements OnDestroy, OnInit {

	protected _unsubscribeAll: Subject<any>;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<NewTripPackageOverviewDialogComponent>,
		public datasetNavigator: DatasetNavigatorService,
		public router: Router, 
		public location: Location,
		public urlSerialize: UrlSerializer,
		@Inject(MAT_DIALOG_DATA) public data: ITripPackageOverviewDialogComponent
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	navigateToPackageDetail(): void{
		let path = '/dataset/detail/trip_packages';
		const deatailRoute = this.router.createUrlTree([path, this.data.tripPackage.id]);
		this.dialogRef.afterClosed().subscribe(() => {
			window.open(this.location.prepareExternalUrl(this.urlSerialize.serialize(deatailRoute)), '_blank');
		});
		this.dialogRef.close(false);
	}
}
