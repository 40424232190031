import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('service_id'),
	fieldsMap.get('taxation'),
	fieldsMap.get('trip_service_rule_id')
];

const config: IFormConfig = { 
	title: 'Crea Servizio',
	editTitle: 'Modifica Servizio',
	fields: formFields 
};

export default config;
