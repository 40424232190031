
// http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
export const defaultSupportedLanguageCodes = [
	'it',
	'en',
	'es',
	'fr',
	'de',
	'ja',
	'zh',
	'ru',
	'pt',
	'kr'
];

export const defaultSupportedLanguages = {
	it: {
		code: 'it',
		description: 'Italiano'
	},
	en: {
		code: 'en',
		description: 'Inglese'
	},
	es: {
		code: 'es',
		description: 'Spagnolo'
	},
	fr: {
		code: 'fr',
		description: 'Francese'
	},
	de: {
		code: 'de',
		description: 'Tedesco'
	},
	ru: {
		code: 'ru',
		description: 'Russo'
	},
	ja: {
		code: 'ja',
		description: 'Giapponese'
	},
	zh: {
		code: 'zh',
		description: 'Cinese'
	},
	pt: {
		code: 'pt',
		description: 'Portoghese'
	},
	kr: {
		code: 'kr',
		description: 'Coreano'
	}
};
