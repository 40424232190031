import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('time', {
	key: 'time',
	name: 'Imposta gli orari della settimana',
	inputType: InputTypes.WEEK_HOURS,
	valueType: ValueTypes.JSON,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito',
	inputRequired: true
});

export {fieldsMap};
