<!--form tax code -->
<ng-container>
    <!--billing data-->
    <label>Dati Fatturazione</label>
    <div fxLayout="row wrap" fxLayoutGap="3px">
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_name")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_name"]'
            ></form-input-viewer>
        </div>

        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_surname")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_surname"]'
            ></form-input-viewer>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="3px">
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_tax_code")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_tax_code"]'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_country")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_country"]'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_province")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_province"]'
            ></form-input-viewer>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="3px">
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_city")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_city"]'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_cap")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_cap"]'
            ></form-input-viewer>
        </div>
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_address")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_address"]'
            ></form-input-viewer>
        </div>
    </div>
    <!--end billing data-->

    <!--mode of delivery-->
    <label>Modalità di consegna (opzionale)</label>
    <div fxLayout="row wrap" fxLayoutGap="3px">
        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_recipient_code")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_recipient_code"]'
            ></form-input-viewer>
        </div>

        <div fxFlex>
            <form-input-viewer
                [formInputDefinition]='fields.get("tc_pec")'
                [formGroup]='formViewerService.formGroup'
                [formControlKey]='formViewerService.controlKeyMap["tc_pec"]'
            ></form-input-viewer>
        </div>
    </div>
    <!--end mode of delivery-->
</ng-container>
<!--end form tax code -->