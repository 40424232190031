import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CurrencyPipe } from '@angular/common';
import { getDateString } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('departure_from', {
	key: 'departure_from',
	name: 'Partenze da',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return record.target !== 'products'
	}
});

fieldsMap.set('departure_to', {
	key: 'departure_to',
	name: 'Partenze fino a',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return record.target !== 'products'
	}
});

fieldsMap.set('booking_from', {
	key: 'booking_from',
	name: 'Prenotazioni da',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE
});

fieldsMap.set('booking_to', {
	key: 'booking_to',
	name: 'Prenotazioni fino a',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE
});

fieldsMap.set('discount_percent', {
	key: 'discount_percent',
	name: 'Sconto Percentuale',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			transform(value): any{
				if(!value) return value;
				return value + '%';
			}
		}
	]
});

fieldsMap.set('discount_amount', {
	key: 'discount_amount',
	name: 'Sconto',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('trip_category_id', {
	key: 'trip_category_id',
	name: 'Categoria',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_categories',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return record.target !== 'trenitalia'
	}
});

fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Pacchetto',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_packages',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return !['trenitalia','products'].includes(record.target)
	}
});

fieldsMap.set('trip_resource_id', {
	key: 'trip_resource_id',
	name: 'Risorsa',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_resources',
	inputHint: 'Scrivi per cercare una risorsa',
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {};
			if(record.trip_package_id){
				params['trip_package_id'] = record.trip_package_id;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return !['trenitalia','products'].includes(record.target)
	}
});

fieldsMap.set('product_id', {
	key: 'trip_resource_id',
	name: 'Prodotto',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'products',
	inputHint: 'Scrivi per cercare un prodotto',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		return record.target == 'products'
	}
});

fieldsMap.set('service_description', {
	key: 'service_description',
	name: 'Servizio',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		return record && record.service && record.service.description;
	}
});

fieldsMap.set('package_description', {
	key: 'package_description',
	name: 'Pacchetto',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		return record && record.trip_package && record.trip_package.description;
	}
});

fieldsMap.set('category_description', {
	key: 'category_description',
	name: 'Categoria',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		return record && record.trip_category && record.trip_category.description;
	}
});

fieldsMap.set('resource_description', {
	key: 'resource_description',
	name: 'Risorsa',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		return record && record.trip_resource && record.trip_resource.description;
	}
});

fieldsMap.set('service_id', {
	key: 'service_id',
	name: 'Servizio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'services',
	inputHint: 'Scrivi per cercare un servizio',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const discount = dataContainerService.getValueFromKeyPath('discounts.record');
		return !Boolean(discount) && !['trenitalia','products'].includes(record.target);
	}
});

fieldsMap.set('booking_from_to', {
	key: 'booking_from_to',
	name: 'Prenotazione da/a',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.booking_from){
			html += 'Dal: ' + getDateString(record.booking_from);
		}
		
		if(record.booking_to){
			if(record.booking_from){
				html += '<br>';
			}
			html += 'Fino al: ' + getDateString(record.booking_to);
		} 
		return html;
	}
});

fieldsMap.set('departure_from_to', {
	key: 'departure_from_to',
	name: 'Prenotazione dal/al',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.departure_from){
			html += 'Dal: ' + getDateString(record.departure_from);
		}
		
		if(record.departure_to){
			if(record.departure_from){
				html += '<br>';
			}
			html += 'Fino al: ' + getDateString(record.departure_to);
		} 
		return html;
	}
});

fieldsMap.set('discount_value', {
	key: 'discount_value',
	name: 'Sconto',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		if(record.discount_amount && Number(record.discount_amount) !== 0){
			html += record.discount_amount + '€';
		}else if(record.discount_percent && Number(record.discount_percent) !== 0){
			html += record.discount_percent + '%';
		}
		if(html === ''){
			html = '0';
		}
		return html;
	}
});

fieldsMap.set('target', {
	key: 'target',
	name: 'Tipologia',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return 'trip_packages'; },
	options: [
		{
			value: 'trip_packages',
			label: 'Pacchetto'
		},
		{
			value: 'products',
			label: 'Prodotti'
		},
		{
			value: 'trenitalia',
			label: 'Trenitalia'
		},
	],
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		// TODO: get list options from remote and check also products enabled
		return dataContainerService && dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.trenitalia.enabled') == true;
	}
});

export {fieldsMap};
