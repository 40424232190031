import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AppService} from './app.service';
//import Bugsnag from '@bugsnag/js';

@Injectable()
export abstract class AbstractService{

	constructor(
		protected http: HttpClient,
		protected appService: AppService
	){}

	private shouldShowErrorMessage(error: HttpErrorResponse): boolean{
		let show = true;
		if(error.status === 401) show = false;
		return show;
	}

	protected handleError(operations?: string, ignore: boolean = false): (HttpErrorResponse) => Observable<any>{
		return (errorResponse: HttpErrorResponse) => {
			/*try{
				Bugsnag.notify(errorResponse);
			}catch(e){
				console.error('Error log bugsnag!!');
			}*/

			if(ignore) return throwError(errorResponse);
			this.showErrorMessageFromResponse(errorResponse);
			
			return throwError(errorResponse);
		};
	}

	public getFirstValidationError(errors): string{
		for (const errorKey in errors) {
			if (!Object.prototype.hasOwnProperty.call(errors, errorKey)) continue;
			const element = errors[errorKey];
			if(typeof(element) === 'string' ) return element;
			if(element instanceof Array && element.length > 0 && typeof(element[0]) === 'string') return element[0];
		}
		return null;
	}

	public showErrorMessageFromResponse(response: HttpErrorResponse): void{
		if(!response) return;
		let message = response.message;
		console.log(response, this.shouldShowErrorMessage(response), response.error);
		if(this.shouldShowErrorMessage(response)){
			if(response.status === 422){
				let firstValidationError;
				if (response.error.errors){
					firstValidationError = this.getFirstValidationError(response.error.errors);
				}else if (response.error){
					firstValidationError = this.getFirstValidationError(response.error);
				}
				message = firstValidationError ? firstValidationError : 'Dati non validi.';
			}else if(response.error && response.error.message){
				message = response.error.message;
			}
		}else{
			message = null;
		}

		if(message){
			this.appService.showErrorMessage(message);
		}
	}
}
