<ng-template #trainNameTemplate let-train="train" let-last="last">
	<ng-container *ngIf="train.type == 'multi' && train.subSegments">
		<ng-container
			*ngFor="let t of train.subSegments;let last = last;"
			[ngTemplateOutlet]='trainNameTemplate' 
			[ngTemplateOutletContext]='{last:last, train:t}'
		></ng-container>
	</ng-container>
	<ng-container *ngIf="train.type != 'multi'">
		<div class="train-name-container">
			<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<img *ngIf="trainTypeImages[train.type]" [matTooltip]="train.type" [src]="trainTypeImages[train.type]" class="train-icon"/>
				<small class="train-title">{{train.name}}</small>
			</div>
			<span class="train-subtitle">{{subTitle(train)}}</span>
		</div>
	</ng-container>
	<div *ngIf="!last" class="train-separator"> + </div>
</ng-template>

<ng-template #gridCellBodyPrice let-isSelected="isSelected" let-price="price" let-totalPriceNet = "totalPriceNet" let-fee="fee" let-availableAmount="availableAmount" let-trainid="trainid" let-rowid="rowid" let-colid="colid">
	<div class="grid-cell grid-body-cell"
		(click)="availableAmount > 0 ? priceSelect(rowid, colid, trainid) : null;"
		[ngClass]="{'grid-body-cell-selected': isSelected, 'cursor-pointer': availableAmount>0}"
	>
		<span *ngIf="availableAmount != 0" class="font-size-12 font-weight-600"
              matTooltip="{{fee>0?'Prezzo:'+ (totalPriceNet| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia: '+(fee| currency:'EUR':'symbol':'1.2-2'):''}}">
			{{price | currency:'EUR':'symbol':'1.2-2'}}
		</span>
		<span *ngIf="availableAmount == 0" class="font-size-12 font-weight-600">Esaurito</span>
	</div>
</ng-template>

<div *ngFor="let train of travelSolution.trains; index as k;let last = last;trackBy: indexTrackBy">
    <!--se con cambio mostro le tratte-->
    <div fxLayout="row wrap" fxLayoutAlign="start center" style="gap:8px;margin-top:20px">
		<ng-container
			[ngTemplateOutlet]='trainNameTemplate' 
			[ngTemplateOutletContext]='{last:true, train:train}'
		></ng-container>
    </div>
    <div class="font-size-14 font-weight-600 text-color-black mb-4 mt-4">Seleziona servizio e offerta</div>
    <mat-progress-bar *ngIf="loading" mode='indeterminate'></mat-progress-bar>
    <div *ngIf="!otherOffer">
		<div class="flex-col flex-gap-2" *ngIf="templateGridData[train.id]">
			<div class="row flex-row flex-gap-2">
				<div style="flex-basis:16.666667%"></div>
				<div
					*ngFor="let colData of templateGridData[train.id].cols;index as c;trackBy: indexTrackBy"
					class="column-header grid-cell"
					[ngClass]="{'sel': colData.isSelected}"
				>
					{{colData.col.display_name}}
				</div>
				<br>
			</div>

			<div class="row flex-row flex-gap-2" *ngFor="let rowData of templateGridData[train.id].rows;index as r;trackBy: indexTrackBy">
				<div class="row-header" [ngClass]="{'sel-r': rowData.isSelected}">
					{{rowData.row.display_name}}
					<span class="info-icon" (click)="openInfo(rowData.row.display_name)">
						<mat-icon>info</mat-icon>
					</span>
				</div>
				<ng-container *ngFor="let colData of templateGridData[train.id].cols;index as c;trackBy: indexTrackBy">
					<ng-container *ngIf="templateGridData[train.id].matrix[rowData.id][colData.id].hasPrice">
						<ng-container 
							[ngTemplateOutlet]="gridCellBodyPrice" 
							[ngTemplateOutletContext]="templateGridData[train.id].matrix[rowData.id][colData.id]"></ng-container>
					</ng-container>
					<div class="grid-cell" *ngIf="!templateGridData[train.id].matrix[rowData.id][colData.id].hasPrice"></div>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="priceGrid[train.id] && priceGrid[train.id].priceSelected && priceGrid[train.id].priceSelected.row">
			<div class="row" class="description"
				*ngIf="_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].description">
				<h2>{{_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].display_name}}</h2>
				<span>{{_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].description}}</span>
			</div>
			<div class="row">
				<span class="mr-16">
					<span
                        style="color:#93B733"
						*ngIf="_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].can_change">
                        <mat-icon>check_circle</mat-icon> <strong>Cambio</strong></span>
					<span
                        style="color:red"
						*ngIf="!_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].can_change"><mat-icon
						color="warn">remove_circle</mat-icon> <strong>Cambio</strong></span>

				</span>
				<span >
					<span
						*ngIf="_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].can_refund"
                        style="color:#93B733"
                    ><mat-icon>check_circle</mat-icon> <strong>Rimborso</strong></span>
					<span
                        style="color:red"
						*ngIf="!_trnService.servicesMap.value.offers[priceGrid[train.id].priceSelected.row].can_refund">
                        <mat-icon>remove_circle</mat-icon> <strong>Rimborso</strong></span>

				</span>
			</div>
		</ng-container>

        <div class="row" fxLayout="row wrap" fxLayoutGap="8px" *ngIf="checkCF(priceGrid[train.id])">
            <div *ngFor="let s of travelSolution.travellers; index as i">
				<mat-form-field appearance="outline">
					<mat-label><span>CartaFRECCIA {{+i +1}}</span></mat-label>
					<input 
						matInput placeholder="CartaFRECCIA" autocomplete="off"
						type='text' [(ngModel)]="s.cartaFreccia" maxlength="30" required>
					<mat-hint>Obbligatoria</mat-hint>
				</mat-form-field>
            </div>
        </div>

		<div *ngIf="last" class="row other-offer">
            <button mat-stroked-button (click)="setOtherOffer()" >Vedi altre offerte</button>
        </div>
    </div>
    <div *ngIf="otherOffer && customTravelSolution">
        <trenitalia-other-solution
            [solution]="customTravelSolution"
            [train]="train"
            [errors]="errors[train.id]"
                                   (custom-offer-select-price)="onCustomOfferPriceSelected($event)"></trenitalia-other-solution>
		<div *ngIf="!travelSolution.require_offer_map && last" class="row" style="margin-top: 8px;margin-bottom: 16px;">
			<button mat-button (click)="backToGrid()" color="">Torna alla griglia</button>
		</div>
	</div>

	<mat-divider *ngIf="!last"></mat-divider>
</div>