import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { AppService } from 'app/services/app.service';
import { DatasetRecordService } from './dataset-record.service';
import { DatasetService } from './dataset.service';
import { DatasetActionContainerService, DatasetEvents } from './dataset-action-container.service';

export interface DatasetActionContainerServiceInitOptions{
	datasetACS: DatasetActionContainerService;
	datasetRS: DatasetRecordService;
	parentForeignKey: string;
	parentValueKey: string;
}

@Injectable()
export class DatasetMultilanguageContainerService{

	public datasetACS: DatasetActionContainerService;
	public datasetRS: DatasetRecordService;

	fetchRecordSubscription: Subscription;
	fetchAllSubscription: Subscription;
	languageSubscription: Subscription;

	public languageContents: any[];
	public activeLanguageCodes: string[] = [];
	public parentForeignKey: string;

	/**
	 * Used in this.datasetACS.getValueFromKeyPath(parentValueKey) for fetch the value 
	 */
	public parentValueKey: string;

	constructor(
		public appService: AppService,
		public datasetService: DatasetService
	){}

	init(options: DatasetActionContainerServiceInitOptions): void{
		this.datasetACS = options.datasetACS;
		this.datasetRS = options.datasetRS;
		this.parentForeignKey = options.parentForeignKey;
		this.parentValueKey = options.parentValueKey;

		if(this.languageSubscription) this.languageSubscription.unsubscribe();
		this.languageSubscription = this.appService.language
		.subscribe(() => {
			if(!this.datasetACS.ready.value) return;
			this.fetchRecord();
		});
	}

	fetchRecord(): Observable<any>{
		this.datasetACS.loading.next(true);
		if(this.fetchRecordSubscription) this.fetchRecordSubscription.unsubscribe();
		const params = {};
		params[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
		params['lang'] = this.appService.language.value;

		const result = new Subject();
		this.fetchRecordSubscription = this.datasetService.get<IPaginationResponse<any>>(this.datasetACS.getListRoute(), params)
		.subscribe({
			next: response => {
				this.datasetACS.loading.next(false);
				if(!response.items || !response.items.length || response.items.length > 1){
					console.warn('fetchRecord', response);
				}
				if(response.items && response.items.length > 0){
					this.datasetRS.record.next(response.items[0]);
					result.next(response.items[0]);
				}else{
					this.datasetRS.recordId = null;
					this.datasetRS.record.next(null);
					result.next(null);
				}
			},
			error: () => {
				this.datasetACS.loading.next(false);
			}
		});
		return result;
	}

	fetchAll(): void{
		if(this.fetchAllSubscription) this.fetchAllSubscription.unsubscribe();
		const params = {};
		params[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
		this.fetchAllSubscription = this.datasetService.get<IPaginationResponse<any>>(this.datasetACS.getListRoute(), params)
		.subscribe({
			next: response => {
				if(!response.items || !response.items.length || response.items.length > 1){
					console.warn('fetchAll', response);
				}
				this.languageContents = response.items;
				if(this.languageContents){
					this.activeLanguageCodes = [];
					for (const languageContent of this.languageContents) {
						if(this.activeLanguageCodes.includes(languageContent.lang)) continue;
						this.activeLanguageCodes.push(languageContent.lang);
					}
				}
			}
		});
	}

	save(formData: any): Subject<any>{
		this.datasetACS.loading.next(true);
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/create';
		if(this.datasetRS.record.value){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}else{
			if(formData instanceof FormData){
				formData.set('lang', this.appService.language.value);
				formData.set(this.parentForeignKey, this.datasetACS.getValueFromKeyPath(this.parentValueKey));
			}else{
				formData['lang'] = this.appService.language.value;
				formData[this.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.parentValueKey);
			}
		}
		const result = new Subject();
		this.datasetService.post(route, formData)
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				result.next(response);
			},
			error: response => {
				this.datasetACS.loading.next(false);
				result.error(response);
			}
		});

		return result;
	}
}