import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizine',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('74_ter', {
	key: '74_ter',
	name: '74_ter',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
});

fieldsMap.set('cee', {
	key: 'cee',
	name: 'cee',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('serie', {
	key: 'serie',
	name: 'serie',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('filiale', {
	key: 'filiale',
	name: 'Filiale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('commessa', {
	key: 'commessa',
	name: 'Commessa',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('return_date', {
	key: 'return_date',
	name: 'Data Ritorno',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: 'Scenario',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	defaultValue() {
		return 'pratica';
	}
});

export {fieldsMap};
