import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	ListViewItemTypes,
	FieldViews
} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('pass_first_name', {
	key: 'pass_first_name',
	name: 'Nome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('pass_last_name', {
	key: 'pass_last_name',
	name: 'Cognome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('pass_email', {
	key: 'pass_email',
	name: 'Email',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.email]
});

fieldsMap.set('pass_data_n', {
	key: 'pass_data_n',
	name: 'Data di nascita',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.STRING,
	formValidators: [],
	extra:{manualInput:true},
	placeholder:'dd/mm/aaaa'
});

fieldsMap.set('pass_phone', {
	key: 'pass_phone',
	name: 'Contatto telefonico',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.pattern(/^(?:[+]?[0-9]{1,4}[\ \.\-]?)?[0-9]{2,4}[\/\-\.\ ]?[0-9]{1,10}$/)]
});
fieldsMap.set('pass_cf', {
	key: 'pass_cf',
	name: 'Carta Freccia',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.pattern(/[0-9]{9,9}/)]
});

fieldsMap.set('contact_consent', {
	key: 'contact_consent',
	name: 'Consenso contatti',
	inputConfig: {
		type: InputTypes.TOGGLE
	},
	defaultValue(){
		return true;
	},
	valueType: ValueTypes.STRING
});

export { fieldsMap };
