import { Component, OnInit, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'compare-date-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class CompareDateInputComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public dateFilterBinded: (d: Date) => boolean;

	public customFormGroup: FormGroup;

	constructor(
		public inputService: FormInputViewerService
	){
		super();

		this.customFormGroup = new FormGroup({
			date: new FormControl(),
			compare: new FormControl('>=')
		});

		this.dateFilterBinded = this.dateFilter.bind(this);
	}

	ngOnInit(): void{
		
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;

			this.refreshInputData();

			this.customFormGroup.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				const compare = this.customFormGroup.controls.compare.value;
				const date = this.customFormGroup.controls.date.value;
				const decodedValue = [compare, date];
				this.inputService.control.setValue(JSON.stringify(decodedValue));
			});
		});
	}

	refreshInputData(): void{
		const jsonValue = this.inputService.control.value;
		if(!jsonValue) return;
		const decodedValue = JSON.parse(jsonValue);
		if(decodedValue instanceof Array && decodedValue.length === 2){
			this.customFormGroup.controls.compare.setValue(decodedValue[0]);
			this.customFormGroup.controls.date.setValue(decodedValue[1]);
		}
	}

	dateFilter(d: Date): boolean{
		if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter){
			return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
		}
		return true;
	}
}
