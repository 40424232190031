<div class="page-layout blank px-24 py-72" [class]="styleClassDataset" [ngClass]='pageClosureContainerService.templatePage.value' *ngIf='datasetCode' fusePerfectScrollbar>
	<ng-container [ngSwitch]='actionCode'>
		<ng-container *ngSwitchCase='"list"'>
			<dataset-list [autoFetchData]='true' [datasetCode]='datasetCode' [filters]='sharedData && sharedData.filters'></dataset-list>
		</ng-container>
		<ng-container *ngSwitchCase='"create"'>
			<dataset-create [datasetCode]='datasetCode'></dataset-create>
		</ng-container>
		<ng-container *ngSwitchCase='"detail"'>
			<dataset-detail [datasetCode]='datasetCode' [inputRecord]='sharedData && sharedData.record'></dataset-detail>
		</ng-container>
		<ng-container *ngSwitchCase='"edit"'>
			<dataset-edit [datasetCode]='datasetCode' [inputRecord]='sharedData && sharedData.record'></dataset-edit>
		</ng-container>

		<div *ngSwitchDefault>
			<p>Unsupported action code "{{actionCode}}"</p>
		</div>
	</ng-container>
</div>