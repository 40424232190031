import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('description'),
	fieldsMap.get('condition_rule'),
	fieldsMap.get('count_rule')
];

const config: IFormConfig = { 
	title: 'Crea Criterio',
	editTitle: 'Modifica Criterio',
	fields: formFields 
};

export default config;
