import {InputTypes, ValueTypes, IFieldDefinition} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('date', {
	key: 'date',
	name: 'Data operazione',
	inputType: InputTypes.DATETIME,
	valueType: ValueTypes.DATETIME,
});

fieldsMap.set('message', {
	key: 'message',
	name: 'Messaggio',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

fieldsMap.set('user_id', {
	key: 'user_id',
	name: 'Utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
});

export {fieldsMap};
