<ng-template #stationTemplate let-name="name" let-date="date">
    <div class="flex-col flex-gap-8 station">
        <span class="time">{{date| date:'HH:mm'}}</span>
        <span class="city">{{name}}</span>
    </div>
</ng-template>



<mat-expansion-panel class="mb-20" [expanded]="travelSolution.state == 'NEW' || (status=='confirmed' && travelSolution.state !== 'CLOSED')">
    <mat-expansion-panel-header>
        <div class="flex-row justify-between travel-header">
            <div class="flex-row">
                <div class="d-flex items-center">
                    <div class="flex-col date-title ">
                        <div class="text-center month">{{travelSolution.departure | date:'MMMM yyyy'}}</div>
                        <div class="text-center day">{{travelSolution.departure | date:'dd'}}</div>
                    </div>
                </div>
                <div class="text-left description items-center ml-4 flex-row flex-gap-8">
                    <div>
                        <span class="font-weight-500" *ngIf="travelSolution.state !== 'NEW'">{{travelSolution.description}} </span>
                        <span class="font-weight-500" *ngIf="travelSolution.state == 'NEW'">
                            {{travelSolution.start.stationName}} <span *ngIf="!isCarnet">({{travelSolution.departure | date: 'HH:mm'}})</span> -
                            {{travelSolution.end.stationName}} <span *ngIf="!isCarnet"> ({{travelSolution.arrival | date: 'HH:mm'}})</span>
                        </span>
                    </div>

                </div>
            </div>
            <div class="flex-row justify-end items-center flex-gap-8">


                    <div *ngIf="['confirmed'].includes(status)" (click)="$event.stopPropagation()">
                        <a class="download-ticket"
                           [href]='appService.getBaseServerAddressRoot() + "/download-ticket/by/id/" + trenitaliaPostSaleService.tripBookingPackage.id'
                           target="_blank"><mat-icon>picture_as_pdf</mat-icon></a>
                    </div>
                    <div class="travel-status">
                        <ng-container *ngIf="travelSolution.state == 'FINALIZED'">
                            <span *ngIf="status==='confirmed'" style="color:green"> CONFERMATO </span>
                            <span *ngIf="status==='canceled'" style="color:red"> CANCELLATO </span>
                            <span *ngIf="status==='refunded'" style="color:red"> RIMBORSATO </span>
                            <span *ngIf="status==='reserved'" style="color:red"> RISERVATO </span>
                        </ng-container>
                        <span *ngIf="travelSolution.state == 'CLOSED'" style="color:red"> <strong>CHIUSO </strong></span>
                        <span *ngIf="travelSolution.state == 'NEW'"> <strong>NUOVO </strong></span>
						<span *ngIf="travelSolution.state == 'CANCELLED'" style="color:red"> <strong>CANCELLED</strong></span>
						<span *ngIf="travelSolution.state == 'PENDING_FINALIZATION'" style="color:red"> <strong>PENDING_FINALIZATION</strong></span>
                    </div>
                    <div>
                        <span
                            id="total">{{travelSolution.original.totalPrice.amount | currency:'EUR':'symbol-narrow' : '1.2-2'}}</span>
                    </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <div class="container">
        <mat-card *ngIf="showCancelTimer" class='m-12 px-16 py-12 cancellation-timer'>
            <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex>
                        <span>Cancellazione possibile entro: </span>
                        <trenitalia-countdown [dataC]="trenitaliaPostSaleService.travelDetails.value.travel.original.lastUpdated || trenitaliaPostSaleService.travelDetails.value.travel.original.creationDate"
                                              (end)="endTimer($event)"></trenitalia-countdown>
                    </div>
                    <button mat-flat-button (click)="onDelete()">Cancella Prenotazione</button>
                </div>
            </mat-card-content>
        </mat-card>
        <div *ngIf="!isCarnet">
            <h4 class="mt-0 font-weight-500" >Viaggio
            <span *ngIf="ar=='forward'">di <span class="font-weight-700">Andata</span></span>
            <span *ngIf="ar=='return'">di <span class="font-weight-700">Ritorno</span></span>
            </h4>
        </div>
        <h4 class="mt-0 font-weight-500" *ngIf="isCarnet">Carnet</h4>
        <div class="flex-row flex-gap-8 mb-4">
            <div *ngIf="travelSolution.trains.length>1">
                {{travelSolution.trains.length - 1}} camb{{travelSolution.trains.length==2?'io':'i'}} - durata:
                <span>{{travelSolution.duration}}</span>

            </div>
            <ng-container *ngIf="travelSolution.state == 'FINALIZED'">
            <div *ngIf="isCarnet">
                <span class="label">Codice </span>
                <span class="font-weight-600">{{carnetData.id}}</span>
            </div>
            <div *ngIf="isCarnet">
                <span class="label">Viaggi utilizzati </span>
                <span>{{carnetData.currentUsages}}/{{carnetData.maxUsages}}</span>
            </div>
            <div *ngIf="isCarnet">
                <span class="label">Scadenza </span>
                <span>{{carnetExpire | date:'dd/MM/yyyy'}}</span>
            </div>
            </ng-container>
        </div>


        <mat-card class="card-train" *ngFor="let tr of travelSolution.trains">
                <div class="flex-row justify-between train-recap" *ngIf="!isCarnet">
                        <div class="flex-col train-desc">
                            <div>
                                <trenitalia-train-breadcrumbs [trains]="[tr]"></trenitalia-train-breadcrumbs>
                            </div>
                            <div class="flex-row justify-between" >
                                <ng-container [ngTemplateOutlet]="stationTemplate" [ngTemplateOutletContext]="{name: tr.start.stationName, date: tr.departure}"></ng-container>
                                <div class="flex text-center durations flex-row items-center flex-gap-8 px-8">
                                    <div class="duration-sign flex"></div>
                                    <div class="travel-time text-color-black font-size-14 ">
                                        <span class="text-color-black font-size-14 font-weight-600">{{tr.duration}}</span>
                                    </div>
                                    <div class="duration-sign flex"></div>
                                </div>
                                <ng-container [ngTemplateOutlet]="stationTemplate" [ngTemplateOutletContext]="{name: tr.end.stationName, date: tr.arrival}"></ng-container>

                            </div>
                        </div>



                        <div class="flex-row justify-end items-center ">
                            <span *ngIf="travelSolution.original.id.travelId"><span class="label"> Codice di recupero</span><br><span> {{travelSolution.original.id.travelId}}</span></span>
                        </div>
                </div>
                <hr class="train-divider" *ngIf="!isCarnet">
                <ng-container *ngIf="travelSolutionTicketsMap && travelSolutionTicketsMap[tr.id]">
                    <ng-container *ngFor="let trav of trenitaliaPostSaleService.travellers ; index as i">
                    <div class="flex-row justify-between passenger-recap items-center" *ngFor="let ticket of travelSolutionTicketsMap[tr.id].tickets[trav.xmlId] ">
                        <div class="flex-col passenger-desc">
                            <div class="flex-row justify-between">
                                <div *ngIf="ticket.traveller" class="traveller-details flex-basis-3">
                                    <div>Passeggero {{i+1}}</div>
                                    <div class="anag">{{ticket.traveller.name}} {{ticket.traveller.surname}}</div>
                                    <!--<div *ngIf="ticket.traveller && ticket.traveller.email">email: {{ticket.traveller.email}}</div>-->
                                    <div *ngIf="ticket.traveller">{{ticket.traveller.type}}</div>
                                    <div *ngIf="ticket.reservationCodes && ticket.reservationCodes.cpCode"><span class="label">Codice</span>: {{ticket.reservationCodes.cpCode}}</div>
                                </div>
                                <div class="offers-details flex-basis-2">
                                    <div>Offerta / Servizio</div>
                                    <div class="desc">{{ticket.offerName}} / {{ticket.catalogServiceName}}</div>
                                    <div class="pos"><span *ngIf="ticket.position"> - {{ticket.position}} </span></div>
                                </div>
                                <div class=" flex-row flex-gap-8 items-center flex-basis-5">
                                    <span *ngIf="ticket.seatInfo && ticket.seatInfo.seat !== '999'" matTooltip="Vagone" class="train-ticket-seat">
                                        <mat-icon>train</mat-icon>
                                        {{ticket.seatInfo.wagon}}
                                    </span>
                                    <span *ngIf="ticket.seatInfo && ticket.seatInfo.seat !== '999'" matTooltip="Posto" class="train-ticket-seat">
											<mat-icon>event_seat</mat-icon>
                                        {{ticket.seatInfo.seat}}
										</span>
                                </div>
                                <div class="train-ticket-price  d-flex items-center" >{{ticket.offeredService.price.amount | currency:'EUR':'symbol-narrow' : '1.2-2'}}</div>

                            </div>
                        </div>

                        <div class="flex-row justify-end ">
                            <div class="flex-col text-left" *ngIf="ticket.reservationCodes">
                                <span class="label">Codice Biglietto</span>
                                <span>{{ticket.reservationCodes.ticketCode}}</span>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                </ng-container>
        </mat-card>
    </div>
    <div class="action-footer flex-row justify-end">
        <div *ngIf="postActionButtons && postActionButtons.length" class="jus">
            <button mat-raised-button [matMenuTriggerFor]="actionMenu" [disabled]="trenitaliaPostSaleService.loading.value">
                <span>GESTISCI</span>
                <mat-icon class="s-16">keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu" [overlapTrigger]="false">
                <ng-container
                    *ngFor="let postActionButton of postActionButtons;"
                    [ngTemplateOutlet]='actionButtonItemTemplate'
                    [ngTemplateOutletContext]='{action:postActionButton}'
                ></ng-container>
            </mat-menu>
        </div>
    </div>
</mat-expansion-panel>
<ng-template #actionButtonItemTemplate let-action='action'>
    <button mat-menu-item
            [disabled]="!postActions[action.code].enabled || trenitaliaPostSaleService.loading.value"
            (click)="postSaleActionClick(action.code)">
        <mat-icon>{{action.icon}}</mat-icon>
        <span>{{action.label}}</span>
    </button>
</ng-template>
