import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { toursFieldsMap } from './fields';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

toursFieldsMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: value.key });
	fieldsMap.set(key, value);
});

fieldsMap.set('parameters.api_key', {
	key: 'parameters.api_key',
	name: 'Api Key',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('parameters.supplier_id', {
	key: 'parameters.supplier_id',
	name: 'Supplier ID',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'touroperators.viator';
	}
});

export {fieldsMap as viatorFieldsMap};
