import { Component, OnInit, ViewChild } from '@angular/core';
import { IListViewConfig, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { PublicDomainsConfig } from 'app/configs/datasets/public_domains/index';
import { TripPackageConfig } from 'app/configs/datasets/trip_packages/index';
import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IDomainChangeHandler } from 'app/interfaces/domain-change-handler.interface';

const tourCMSAuthFormConfig: IFormConfig = {
	fields: [
		{
			key: 'marketplace_account_id',
			name: 'Account ID',
			valueType: ValueTypes.STRING,
			inputConfig: {
				type: InputTypes.TEXT,
				required: true
			}
		},
		{
			key: 'marketplace_private_key',
			name: 'API Key',
			valueType: ValueTypes.STRING,
			inputConfig: {
				type: InputTypes.TEXT,
				required: true
			}
		}
	]
};

@Component({
	selector   : 'tourcms-marketplace-page',
	templateUrl: './marketplace.component.html',
	styleUrls  : ['./marketplace.component.scss'],
	providers: [PageClosureContainerService]
})
export class TourCMSMarketplacePageComponent implements OnInit, IDomainChangeHandler{
	public savingCredentials = false;
	public loading = true;
	public domainHasTourCMSAccount = false;
	protected _unsubscribeAll: Subject<any>;
	public tourCMSAuthFormConfig = tourCMSAuthFormConfig;
	@ViewChild('tourCMSAuthFormViewer', { static: false }) tourCMSAuthFormViewer: FormViewerComponent;

	constructor(
		protected appService: AppService,
		protected domainFilterService: DomainFilterService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected pageClosureContainerService: PageClosureContainerService,
		protected route: ActivatedRoute,
	) {
		this._unsubscribeAll = new Subject();
	}

	handleDomainChanges(): boolean {
		return true;
	}

	checkTourCMSAccount(): void{
		this.loading = true;
		this.datasetNavigatorService.datasetService
		.post<any>('/dataset/tourcms_tours/command/check_account')
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(response => {
			this.domainHasTourCMSAccount = Boolean(response.valid);
			this.loading = false;
		});
	}

	setTourCMSAccount(): void{
		const formData = this.tourCMSAuthFormViewer.getFormData();
		this.savingCredentials = true;
		this.tourCMSAuthFormViewer.formGroup.disable();
		this.datasetNavigatorService.datasetService
		.post<any>('/dataset/tourcms_tours/command/set_account', formData)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.domainHasTourCMSAccount = Boolean(response.valid);
				this.savingCredentials = false;
				this.tourCMSAuthFormViewer.formGroup.enable();
			},
			error: response => {
				this.savingCredentials = false;
				this.tourCMSAuthFormViewer.formGroup.enable();
				if(response && response.error && response.error.errors) this.tourCMSAuthFormViewer.setErrors(response.error.errors);
			}
		});
	}

	ngOnInit(): void {
		if(!this.domainFilterService.filterDomainId.value){
			this.domainFilterService.setFilterDomain(this.route.snapshot.data.domainId);
		}
		
		this.domainFilterService.filterDomainId
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(domainId => {
			this.checkTourCMSAccount();
		});
	}
}
