import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';
var TicketsYearComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsYearComponent, _super);
    function TicketsYearComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        /**
         * Chart Ticket Year
        */
        _this.barChartOptions = {
            responsive: true,
            onResize: function (chart) {
                chart.data.datasets.forEach(function (chartDataset) {
                    var bgColors = chartDataset.hoverBackgroundColor;
                    var gradients = [];
                    for (var i = 0; i < bgColors.length; i++) {
                        var bgColor = bgColors[i];
                        var gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom / 1.7, 0, chart.chartArea.bottom);
                        gradient.addColorStop(0, bgColor);
                        gradient.addColorStop(1, 'white');
                        gradients.push(gradient);
                    }
                    chartDataset.backgroundColor = gradients;
                });
                /**
                 * when data or style changes update chart
                 */
                chart.update();
            },
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false,
                        }
                    }
                ]
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(tooltipItem.yLabel);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            }
        };
        _this.chartPlugins = [makeDrawValuePlugin()];
        _this.barChartType = 'bar';
        _this.barChartLegend = false;
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    TicketsYearComponent.prototype.ngOnInit = function () {
        this.ticketsyear = [{ data: [] }];
    };
    TicketsYearComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dashboardService.checkoutDateResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.dashboardService.groupingBy.value !== 'checkout_date')
                    return;
                if (response)
                    _this.onData(response);
            }
        });
        this.dashboardService.travelDateResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                if (_this.dashboardService.groupingBy.value !== 'departure_date')
                    return;
                if (response)
                    _this.onData(response);
            }
        });
    };
    // -----------------------------------------------------------------------------------------------------
    TicketsYearComponent.prototype.onData = function (response) {
        var e_1, _a;
        if (!response) {
            this.showLoader = true;
            return;
        }
        var r = [];
        try {
            for (var response_1 = tslib_1.__values(response), response_1_1 = response_1.next(); !response_1_1.done; response_1_1 = response_1.next()) {
                var item = response_1_1.value;
                if (!item.year)
                    continue;
                var year = item.year;
                if (!r[year])
                    r[year] = 0;
                r[year] += Number(item.pax_count);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (response_1_1 && !response_1_1.done && (_a = response_1.return)) _a.call(response_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var values = [];
        var labels = [];
        var years = Object.keys(r);
        var colors = [];
        var gradients = [];
        for (var i = 0; i < years.length; i++) {
            var year = years[i];
            var colorIndex = years.length - i - 1;
            values.push(r[year]);
            labels.push(year.toString());
            colors.push(chartColors[colorIndex]);
            var chartArea = this.chartComponent.chart.chartArea;
            var gradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom / 1.7, 0, chartArea.bottom);
            gradient.addColorStop(0, chartColors[colorIndex]);
            gradient.addColorStop(1, 'white');
            gradients.push(gradient);
        }
        if (values.length === 0) {
            this.showLoader = true;
            this.ticketsyear = [{ data: [] }];
        }
        else {
            this.showLoader = false;
            this.ticketsyear = [
                {
                    data: values,
                    backgroundColor: gradients,
                    borderColor: colors,
                    hoverBackgroundColor: colors,
                    hoverBorderColor: colors
                }
            ];
        }
        this.barChartLabels = labels;
    };
    return TicketsYearComponent;
}(DestroyableComponent));
export { TicketsYearComponent };
