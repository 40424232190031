import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { externalsiteFieldsMap } from '../../fields/externalsite/fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	externalsiteFieldsMap.get('url'),
	externalsiteFieldsMap.get('scenario')
];

const appForm: IFormConfig = { 
	editTitle: 'Modifica Externalsite Configurazioni Dominio',
	fields: formFields 
};

export default appForm;
