import tripPackageIdField from "app/configs/commons/shared-field-configs/trip-package-id.field";
import { convertToUTCDay } from "app/helpers/date.helper";
import { cloneFieldDefinition } from "app/helpers/field.helper";
import { FieldViews, InputTypes, ListViewItemTypes, StyleListViewConfig, ValueTypes } from "app/interfaces";
import { IDataContainerService } from "app/interfaces/data-container.interface";
import {
	showFieldBasedOnConfig
} from "../../../../../../configs/commons/shared-field-configs/show-field-on-config.field";
import * as _ from "lodash";
import listFilterForm from './forms/filter.form';

/**
 * Used for search a trips to add in booking
 */
export const actualTripsListViewConfig = {
	itemViewType: ListViewItemTypes.TABLE,
	defaultSortBy: 'start_day|start_time',
	defaultSortDirection: 'asc',
	showSearchBar: false,
	filterForm: listFilterForm,
	columns: [
		{
			title: 'Esperienza',
			key: 'package_description',
			fieldDefinition: {
				name: 'Esperienza',
				key: 'package_description',
				valueType: ValueTypes.STRING,
				fieldView: FieldViews.HTML,
				getDisplayValue(record: any): string{
					if(!record || !record.package) return '---';
					let value = "<div class='trip_booking_trip_package_description'>";
					value += record.package.description;
					value += "</div>";
					return value;
				}
			}
		},
		{
			title: 'Tipo',
			key: 'icon_type',
			fieldDefinition: {
				name: 'Tipo',
				key: 'icon_type',
				valueType: ValueTypes.STRING,
				fieldView: FieldViews.HTML,
				getDisplayValue(record: any): any{
					let icon = "going_trip_icon.svg";
					const style = "border-radius: unset !important;";
					if(record.type == 'return'){
						icon = "return_trip_icon.svg";
					}
					return '<img src="assets/icons/material-icons/filled/'+icon+'" style="'+style+'" />';
				}
			}
		},
		{
			title: 'Partenza',
			key: 'display_name',
		},
		{
			title: 'Data Partenza',
			key: 'start_date_time'
		},
		{
			title: 'Disponibilità',
			key: 'availability'
		},
		{
			title: 'Prezzo',
			key: 'price',
			fieldDefinition: {
				name: 'Prezzo',
				key: 'price',
				valueType: ValueTypes.STRING,
				fieldView: FieldViews.HTML,
				getDisplayValue(record: any): any{
					const price = _.get(record, 'package.properties.price.value');
					if(!price) return '';
					return 'A partire da <br>€'+price
				}
			},
			showIf(dataContainerService: IDataContainerService):any{
				return showFieldBasedOnConfig(dataContainerService.getValueFromKeyPath('trip_bookings.record.backoffice_view_config'), 'packages.list_columns', 'price', false);
			}
		},
	]
};