import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { HttpHeaders } from '@angular/common/http';
import { locale as english } from '../i18n/en';
import { locale as italiano } from '../i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
var EntityTypesStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EntityTypesStepComponent, _super);
    function EntityTypesStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, fuseTranslationLoader) {
        var _this = 
        // parent
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.fuseTranslationLoader = fuseTranslationLoader;
        // carica traduzioni
        _this.fuseTranslationLoader.loadTranslations(english, italiano);
        return _this;
    }
    EntityTypesStepComponent.prototype.ngOnInit = function () { };
    EntityTypesStepComponent.prototype.createEntityType = function (type) {
        var _this = this;
        this.datasetACS.loading.next(true);
        var headers = new HttpHeaders({});
        if (this.datasetACS.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
        }
        this.datasetService.post('/dataset/entities/command/create_entity_type', {
            entity_type: type,
            entity_id: this.datasetRS.record.value.id
        }, {
            headers: headers
        })
            .subscribe({
            next: function () {
                _this.datasetACS.loading.next(false);
                _this.datasetRS.reload();
            },
            error: function () {
                _this.datasetACS.loading.next(false);
                _this.appService.showErrorMessage('Si è verificato un errore imprevisto.');
            }
        });
    };
    return EntityTypesStepComponent;
}(BaseStepViewComponent));
export { EntityTypesStepComponent };
