import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../../entity_staff_element_unified_list_items/fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('note')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
