<span class="material-icons-outlined dialog-close-icon" (click)='onCloseClick()'>close</span>

<h1 mat-dialog-title *ngIf="data.tripPackage">{{ data.tripPackage.description }}</h1>

<mat-dialog-content>
	<div fxLayout.xs="column" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
		<div class="trip_package_img" *ngIf='data.tripPackage.master_image'>
			<img class="package-img" [src]='appService.getBaseServerAddressRoot() + "/document_files/resize/224x157/" + data.tripPackage.master_image' />
		</div>
		<div class="trip_package_data" fxLayout="column" fxLayoutGap="8px" fxFlex="1 1 0">
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<div fxFlex="1 1 0">{{'Codice' | translate}}:</div>
				<div fxFlex="1 1 0">{{ data.tripPackage.code }}</div>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<div fxFlex="1 1 0">{{'Stato' | translate}}:</div>
				<div fxFlex="1 1 0" class="package_status" [ngClass]="data.tripPackage.active_status ? 'enabled' : 'disabled'">
					<span>{{ data.tripPackage.active_status ? 'Attivo' : 'Non attivo' }}</span>
				</div>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<div fxFlex="1 1 0">{{'Destinazioni' | translate}}:</div>
				<div fxFlex="1 1 0">
					<ng-container *ngFor="let destination of data.tripPackage.destinations; let isLast = last">
						<span>{{ destination.description }}{{isLast ? '' : ', '}}</span>
					</ng-container>
				</div>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<div fxFlex="1 1 0">{{'Traduzioni Attive' | translate}}:</div>
				<div fxFlex="1 1 0">
					<ng-container *ngFor="let lang of data.tripPackage.active_language_codes">
						<img class="flag-icon" [src]="'assets/images/flags/4x3/' + lang + '.svg'" />
					</ng-container>
				</div>
			</div>
			<div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<div fxFlex="1 1 0">{{'Prezzo' | translate}}:</div>
				<div fxFlex="1 1 0">{{ data.tripPackage.properties.price.value | currency:"EUR" }}</div>
			</div>
            <div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div fxFlex="1 1 0">{{ data.tripPackage.properties.adult_to.name | translate}}:</div>
                <div fxFlex="1 1 0">{{ data.tripPackage.properties.adult_to.value }}</div>
            </div>
            <div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div fxFlex="1 1 0">{{data.tripPackage.properties.child_from.name | translate}}:</div>
                <div fxFlex="1 1 0">{{ data.tripPackage.properties.child_from.value  }}</div>
            </div>
            <div class="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div fxFlex="1 1 0">{{data.tripPackage.properties.child_to.name | translate}}:</div>
                <div fxFlex="1 1 0">{{ data.tripPackage.properties.child_to.value  }}</div>
            </div>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
	<span fxLayout="row" fxLayoutAlign="start center" class="trip_package_detail" (click)="navigateToPackageDetail()">
		{{'Vai al dettaglio pacchetto' | translate}} <span class="material-icons-outlined">arrow_forward_ios</span>
	</span>
</mat-dialog-actions>