import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { getTrainBreadcrumbs, trainTypeImages } from "../../trenitalia.service";

@Component({
	selector: 'trenitalia-travel-solution-train-details',
	templateUrl: './travel-solution-train-details.component.html',
	//styleUrls: ['./trenitalia-travel-solution-train-details.component.scss']
})
export class TravelSolutionTrainDetailsComponent implements OnChanges{
	@Input() travelSolution: any;
	trains: any[] = [];

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.travelSolution){
			this.updateTrains();
		}
	}

	updateTrains(){
		if(!this.travelSolution){
			this.trains = [];
			return;
		}
		this.trains = getTrainBreadcrumbs(this.travelSolution.trains);
	}

	indexTrackBy(index, item): any{
		return index;
	}
}