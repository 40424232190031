import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators, FormGroup } from '@angular/forms';
import { fieldsMap as staffFieldMap} from '../entity_staff_elements/fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import entityStaffElementIdField from 'app/configs/commons/shared-field-configs/entity-staff-element-id.field';

const fieldsMap: Map<string, IFieldDefinition> = new Map();


fieldsMap.set('note', {
	key: 'note',
	name: 'Nota',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('entity_staff_element_id', cloneFieldDefinition(entityStaffElementIdField, { 
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
}));

fieldsMap.set('list_id', {
	key: 'list_id',
	name: 'Lista',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'unified_lists'
});

fieldsMap.set('entity_staff_element_description', cloneFieldDefinition(staffFieldMap.get('entity.description'), {
	key: 'entity_staff_element.entity.description'
}));

fieldsMap.set('entity_staff_element_code', cloneFieldDefinition(staffFieldMap.get('code'), {
	key: 'entity_staff_element.code'
}));

fieldsMap.set('entity_staff_element_staff_type', cloneFieldDefinition(staffFieldMap.get('staff_type'), {
	key: 'entity_staff_elements.staff_type',
	propertyCode: 'staff_type',
	getDisplayValue: 'entity_staff_element.properties.staff_type.value',
	datasetCode: 'entity_staff_elements'
}));

export {fieldsMap};
