/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/material/dialog";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../form-viewer/form-viewer.component.ngfactory";
import * as i8 from "../../../../../services/form-viewer.service";
import * as i9 from "../../../../../services/app.service";
import * as i10 from "@angular/forms";
import * as i11 from "../../../form-viewer/form-viewer.component";
import * as i12 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i13 from "@angular/material/button";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "./dialog.component";
var styles_UnifiedListDialogComponent = [];
var RenderType_UnifiedListDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UnifiedListDialogComponent, data: {} });
export { RenderType_UnifiedListDialogComponent as RenderType_UnifiedListDialogComponent };
function View_UnifiedListDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UnifiedListDialogComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { formViewer: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(2, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i0.ElementRef, i4.MatDialog], null, null), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 6, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UnifiedListDialogComponent_1)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "form-viewer", [], null, null, null, i7.View_FormViewerComponent_0, i7.RenderType_FormViewerComponent)), i0.ɵprd(131584, null, i8.FormViewerService, i8.FormViewerService, [i9.AppService, i10.FormBuilder, i0.NgZone]), i0.ɵdid(11, 770048, [[1, 4], ["formViewer", 4]], 0, i11.FormViewerComponent, [i8.FormViewerService, i0.ChangeDetectorRef], { formConfig: [0, "formConfig"], dataContainerService: [1, "dataContainerService"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i13.MatButton, [i0.ElementRef, i14.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(16, 0, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(18, 0, null, null, 3, "button", [["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_MatButton_0, i12.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i13.MatButton, [i0.ElementRef, i14.FocusMonitor, [2, i3.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(20, 0, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.loading; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.data.formConfig; var currVal_4 = _co.data.datasetACS; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_10 = "accent"; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.data.title ? _co.data.title : i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Conferma"))); _ck(_v, 3, 0, currVal_1); var currVal_5 = (i0.ɵnov(_v, 15).disabled || null); var currVal_6 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_7 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform("Chiudi")); _ck(_v, 16, 0, currVal_7); var currVal_8 = (i0.ɵnov(_v, 19).disabled || null); var currVal_9 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_8, currVal_9); var currVal_11 = i0.ɵunv(_v, 20, 0, i0.ɵnov(_v, 21).transform("Aggiungi")); _ck(_v, 20, 0, currVal_11); }); }
export function View_UnifiedListDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "unified-list-dialog", [], null, null, null, View_UnifiedListDialogComponent_0, RenderType_UnifiedListDialogComponent)), i0.ɵdid(1, 114688, null, 0, i15.UnifiedListDialogComponent, [i9.AppService, i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnifiedListDialogComponentNgFactory = i0.ɵccf("unified-list-dialog", i15.UnifiedListDialogComponent, View_UnifiedListDialogComponent_Host_0, {}, {}, []);
export { UnifiedListDialogComponentNgFactory as UnifiedListDialogComponentNgFactory };
