import * as tslib_1 from "tslib";
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from '../../../../services/app.service';
var TripBookingSummaryComponent = /** @class */ (function () {
    function TripBookingSummaryComponent(datasetRS, appService) {
        this.datasetRS = datasetRS;
        this.appService = appService;
    }
    Object.defineProperty(TripBookingSummaryComponent.prototype, "tripBookingStatus", {
        get: function () {
            return this.datasetRS.record.value.status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripBookingSummaryComponent.prototype, "isMixBookingStatus", {
        get: function () {
            return this.tripBookingStatus === 'mix';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripBookingSummaryComponent.prototype, "hasOptionalResourcesEnabled", {
        get: function () {
            var e_1, _a;
            var optionalResources = {};
            try {
                for (var _b = tslib_1.__values(this.datasetRS.record.value.packages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingPackage = _c.value;
                    var hasEnabledOptionalResource = bookingPackage.trip_booking_resources.filter(function (x) { return x.activated && x.optional; }).length > 0;
                    if (hasEnabledOptionalResource) {
                        optionalResources[bookingPackage.id] = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return optionalResources;
        },
        enumerable: true,
        configurable: true
    });
    return TripBookingSummaryComponent;
}());
export { TripBookingSummaryComponent };
