import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('name'),
		]
	},
	fieldsMap.get('meeting_point_list_id'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('week_days'),
			fieldsMap.get('guide_languages')
		]
	}
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
