import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as resourceFieldMap} from '../trip_resources/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_resource_id', {
	key: 'trip_resource_id',
	name: 'Risorse',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_resources',
	remoteOptions: {
		limit: 50,
		getParams(dataContainerService: IDataContainerService, data: any): any{
			const params = {};
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			if(tripPackageId){
				params['trip_package_id'] = tripPackageId;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

export {fieldsMap};
