<div class="page-layout blank p-24" fusePerfectScrollbar>
	<mat-card>
		<mat-card-content>
			<dataset-filter 
				[showSearchText]='false'
				[formConfig]='filterFormConfig'
				[loading]='logsService.loading'
				[filtersData]='logsService.filterData'
				(filterChange)='logsService.onFilterDataChange($event)'
			></dataset-filter>
			<div fxLayout='row'>
				<logs-list></logs-list>
				<logs-detail fxFlex></logs-detail>
			</div>
		</mat-card-content>
	</mat-card>
</div>