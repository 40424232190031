import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';

@Injectable()
export class SuperadminGuardService implements CanActivate {

	constructor(
		public auth: AuthService,
		private appService: AppService,
		public router: Router){}

	async canActivate(): Promise<boolean> {
		if (!this.auth.isAuthenticated()) return false;
		const userData = this.auth.userDataObserver.value;
		if(!userData || !userData.user || !userData.user.role) return false;
		if(userData.user.role.level !== 0) return false;
		return true;
	}
}
