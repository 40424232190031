import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { wrapArray } from "app/helpers";
import * as _ from "lodash";

@Component({
	selector: 'trenitalia-post-sale-change-confirm-dialog',
	templateUrl: 'trenitalia-post-sale-change-confirm-dialog.component.html',
	styleUrls: ['trenitalia-post-sale-change-confirm-dialog.component.scss']
})
export class TrenitaliaPostSaleChangeConfirmDialog {

	get totalPenalitiesAmount(): Number{
		return Number(_.get(this.data, 'postSaleData.totalPenalties.amount', 0));
	}

	get totalValueAmount(): Number{
		return Number(_.get(this.data, 'postSaleData.totalValue.amount'));
	}

	get missingAmount(): Number{
		return Number(_.get(this.data, 'postSaleData.missingAmount.amount', 0));
	}

	get hasReversedPaymentRecords(): Boolean{
		const reversedPaymentRecords = wrapArray(_.get(this.data, 'postSaleData.reversedPaymentRecords'));
		return reversedPaymentRecords && reversedPaymentRecords.length > 0;
	}

    get postSaleFee(): Number{
        return Number(_.get(this.data, 'postSaleData.postSaleFee', 0));
    }

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}