<div class="style-1">
    <div mat-dialog-title>
        <h1 style="margin: 0;display:inline-block;">
            <span>Accesso richiesto</span>
        </h1>
        <span class="material-icons-outlined dialog-close-icon" style="float:right;"
              (click)='onCloseClick()'>close</span>
    </div>
    <mat-dialog-content>
        <mat-progress-bar class='mt-24 mb-24' style='width: 100%' mode="indeterminate" *ngIf='loading'></mat-progress-bar>
        <div fxLayout="column" fxLayoutAlign="space-between center">
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("name")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["name"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("surname")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["surname"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
            <div fxFlex>
                <form-input-viewer
                    [formInputDefinition]='formFields.get("cartafreccia")'
                    [formGroup]='formViewerService.formGroup'
                    [formControlKey]='formViewerService.controlKeyMap["cartafreccia"]'
                    [dataContainerService]='datasetACS'
                ></form-input-viewer>
            </div>
            <button mat-raised-button (click)="login()" [disabled]="!formViewerService.formGroup.valid">Accedi</button>
            <div *ngIf="error" class="alert-danger">{{error.message}}</div>
        </div>
    </mat-dialog-content>
</div>