<div class="flex-row-desktop flex-col-mobile items-start justify-between mb-4">
	<div class="flex flex-col">
		<div class="flex-row flex-gap-4 items-center justify-start">
			<mat-icon class="text-color-light-blue">{{icon}}</mat-icon>
			<span class="font-size-14 font-weight-500 text-color-black" *ngIf="title">{{title}}</span>
			<ng-content select="[title]"></ng-content>
		</div>
		<ng-content select="[sub-title-1]"></ng-content>
		<span class='font-size-14 secondary-text' *ngIf='tripBookingPackage.properties?.pratica_code?.value'>{{'Codice Pratica' | translate}}: {{tripBookingPackage.properties.pratica_code.value}}</span>
		<ng-container *ngIf="bookingService.statusText[tripBookingPackage.booking_status]">
			<span *ngIf="tripBookingStatus == 'mix'" [class]="bookingService.statusTextColor[tripBookingPackage.booking_status] || 'orange-fg'">{{bookingService.statusText[tripBookingPackage.booking_status]}}</span>
		</ng-container>
		<ng-content select="[sub-title-2]"></ng-content>
	</div>
	<div class="flex-row flex-gap-4 items-center justify-end">
		<strong class="font-size-12 font-weight-500 text-color-black" [matTooltip]='"lordo" | translate' *ngIf="!hidePrice">{{tripBookingPackage.total_amount | currency:'EUR' }}</strong>
		<strong class="font-size-12 font-weight-500 text-color-black" [matTooltip]='"netto" | translate' *ngIf='!hidePrice && tripBookingPackage.total_amount_net != tripBookingPackage.total_amount'>/ {{tripBookingPackage.total_amount_net | currency:'EUR' }}</strong>
		<a *ngIf="showInfo" mat-icon-button color='accent' [matTooltip]='"Apri dettagli" | translate' (click)="infoClick.emit();$event.stopPropagation();">
			<mat-icon>info</mat-icon>
		</a>
		<button *ngIf="showConfirm" [disabled]="bookingService.loading.value" mat-icon-button (click)='confirmClick.emit();$event.stopPropagation();' [matTooltip]='"Conferma" | translate'>
			<mat-icon color='primary'>done</mat-icon>
		</button>
		<button *ngIf="showEdit" mat-icon-button color='accent' [disabled]="bookingService.loading.value" [matTooltip]='"Modifica" | translate' (click)='editClick.emit();$event.stopPropagation();'>
			<mat-icon>edit</mat-icon>
		</button>
		
		<button class="delete-trip-btn" mat-icon-button [disabled]="bookingService.loading.value" *ngIf='canDelete' (click)='deleteClick.emit();$event.stopPropagation();' matTooltip="Elimina">
			<mat-icon color='warn'>delete</mat-icon>
		</button>
		<button class="delete-trip-btn" mat-icon-button [disabled]="bookingService.loading.value" *ngIf='canCancel' (click)='deleteClick.emit();$event.stopPropagation();' matTooltip="Annulla">
			<mat-icon color='warn'>delete</mat-icon>
		</button>
	</div>
</div>