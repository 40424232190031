import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'trip-package-overview',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.scss'],
})
export class TripPackageOverviewComponent implements OnInit, OnDestroy {
	public get record(): any {
		return this.datasetRS.record.value;
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService
	) {}

	protected _unsubscribeAll: Subject<any>;

	ngOnInit(): void {
		this._unsubscribeAll = new Subject();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	trackImageBy(index): any {
		return index;
	}
}
