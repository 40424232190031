import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'meeting_point_id',
	name: 'Punto di ritrovo',
	inputType: InputTypes.DATASET_SEARCH,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_points',
	inputHint: 'Scrivi per cercare un punto di ritrovo',
	remoteOptions: {
		limit: 100,
		getParams(): any{
			const params = {};
			params['target'] = 'package';

			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			/*if(option.default_time){
				label += ' ' + option.default_time;
			}*/
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			let label = option.description;
			if(option.default_time){
				label += ' ' + option.default_time;
			}
			return label;
		}
	},
	defaultValue(datasetContainerService, options, record): any{
		if(!record) return null;
		return record.id;
	}
};
