/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/cdk/stepper";
import * as i6 from "./view.component";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/router";
import * as i9 from "../../../services/step-state.service";
var styles_CustomStepperComponent = [i0.styles];
var RenderType_CustomStepperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomStepperComponent, data: {} });
export { RenderType_CustomStepperComponent as RenderType_CustomStepperComponent };
function View_CustomStepperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h6", [["class", "nav-section-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.computedStep == null) ? null : _v.parent.context.$implicit.computedStep.title); _ck(_v, 0, 0, currVal_0); }); }
function View_CustomStepperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["done"]))], null, null); }
function View_CustomStepperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "nav-item nav-section-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0, "disabled": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "active": 0, "disabled": 1 }), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomStepperComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "nav-item nav-section-title"; var currVal_1 = _ck(_v, 3, 0, (_co.selectedIndex === _v.parent.context.index), !_v.parent.context.$implicit.computedStep.completed); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.selectedIndex === _v.parent.context.index), !_v.parent.context.$implicit.computedStep.completed); var currVal_3 = "nav-item nav-section-title"; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.stepService.isCompleted(((_v.parent.context.$implicit.computedStep.config == null) ? null : _v.parent.context.$implicit.computedStep.config.id)); _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.computedStep.title; _ck(_v, 6, 0, currVal_4); }); }
function View_CustomStepperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomStepperComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomStepperComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.computedStep == null) ? null : _v.context.$implicit.computedStep.config.isTitle); _ck(_v, 2, 0, currVal_0); var currVal_1 = !((_v.context.$implicit.computedStep == null) ? null : _v.context.$implicit.computedStep.config.isTitle); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CustomStepperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav-section-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomStepperComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._steps; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomStepperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-custom-stepper", [], null, null, null, View_CustomStepperComponent_0, RenderType_CustomStepperComponent)), i1.ɵprd(6144, null, i5.CdkStepper, null, [i6.CustomStepperComponent]), i1.ɵdid(2, 4440064, null, 2, i6.CustomStepperComponent, [i7.Directionality, i1.ChangeDetectorRef, i8.Router, i8.ActivatedRoute, i9.StepService], null, null), i1.ɵqud(603979776, 1, { _steps: 1 }), i1.ɵqud(603979776, 2, { _stepHeader: 1 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomStepperComponentNgFactory = i1.ɵccf("app-custom-stepper", i6.CustomStepperComponent, View_CustomStepperComponent_Host_0, { linear: "linear", selectedIndex: "selectedIndex", selected: "selected" }, { selectionChange: "selectionChange" }, []);
export { CustomStepperComponentNgFactory as CustomStepperComponentNgFactory };
