import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {TripServiceTaxation} from '../trip_services/taxation.enum';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Nominativo',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('count', {
	key: 'count',
	name: 'Numero',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{
		return 1;
	}
});

fieldsMap.set('price', {
	key: 'price',
	name: 'Prezzo Unitario',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('price_base', {
	key: 'price_base',
	name: 'Prezzo Unitario Lordo',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('total_amount', {
	key: 'total_amount',
	name: 'Prezzo Tot.',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('total_amount_lordo', {
	key: 'total_amount_lordo',
	name: 'Prezzo Lordo Tot.',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	getDisplayValue(record): string{
		if(!record) return '';
		return (Number(record.count) * Number(record.price_base)).toFixed(2);
	},
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('commission_discount_percent', {
	key: 'commission_discount_percent',
	name: 'Sconto Commissioni %',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	placeholder: '0'
});

fieldsMap.set('date_from', {
	key: 'date_from',
	name: 'Inizia il',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('date_to', {
	key: 'date_to',
	name: 'Finisce il',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
});

fieldsMap.set('fee_included', {
	key: 'fee_included',
	name: 'Costo Incluso',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	showIf(record: any): any{
		return !record.trip_booking_package_id;
	},
	options: [
		{
			label: 'Si',
			value: 1
		},
		{
			label: 'No',
			value: 0
		}
	]
});

fieldsMap.set('trip_service_description', {
	key: 'trip_service_description',
	name: 'Servizio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	showIf(record): boolean{
		return record.trip_service_id && record.trip_service;
	},
	getDisplayValue(record): string{
		if(!record || !record.trip_service) return '';
		return record.trip_service.description;
	}
});

fieldsMap.set('trip_service_id', {
	key: 'trip_service_id',
	name: 'Servizio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_services',
	remoteOptions: {
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {
				trip_booking_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId'),
				trip_booking_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.recordId'),
				trip_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.record.trip_package_id'),
				trip_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.record.trip_resource_id'),
				trip_booking_id: dataContainerService.getValueFromKeyPath('trip_bookings.recordId'),
				action: 'add' // filter only missing services
			};

			for (const key in params) {
				if(params[key] === null || params[key] === undefined){
					delete params[key];
				}
			}

			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

fieldsMap.set('discount_id', {
	key: 'discount_id',
	name: 'Sconto',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'discounts',
	remoteOptions: {
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {
				trip_booking_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId'),
				trip_booking_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.recordId'),
				trip_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.record.trip_package_id'),
				trip_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.record.trip_resource_id'),
				trip_booking_id: dataContainerService.getValueFromKeyPath('trip_bookings.recordId')
			};

			for (const key in params) {
				if(params[key] === null || params[key] === undefined){
					delete params[key];
				}
			}

			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.name;
		}
	}
});

fieldsMap.set('trip_service_for_booking_id', {
	key: 'trip_service_for_booking_id',
	name: 'Servizio',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'trip_service_for_bookings',
	remoteOptions: {
		getParams(dataContainerService: IDataContainerService, record: any): any{
			const params = {
				trip_booking_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId'),
				trip_booking_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.recordId'),
				trip_package_id: dataContainerService.getValueFromKeyPath('trip_booking_packages.record.trip_package_id'),
				trip_resource_id: dataContainerService.getValueFromKeyPath('trip_booking_resources.record.trip_resource_id'),
				trip_booking_id: dataContainerService.getValueFromKeyPath('trip_bookings.recordId')
			};

			for (const key in params) {
				if(params[key] === null || params[key] === undefined){
					delete params[key];
				}
			}

			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.TEXT
});

fieldsMap.set('trip_service_description', {
	key: 'trip_service_description',
	name: 'Servizio',
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		if(!record || !record.trip_service) return '';
		return record.trip_service.description;
	}
});

fieldsMap.set('supplier_id', {
	key: 'supplier_id',
	name: 'Fornitore',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'entity_suppliers',
	getDisplayValue(record: any): string{
		if(!record || !record.entity) return '';
		return record.entity.description;
	},
	remoteOptions: {
		icon: 'person',
		getLabel(option): any{
			if(!option || !option.entity) return '';
			return option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.entity.description;
		}
	}
});

fieldsMap.set('tour_operator_id', {
	key: 'tour_operator_id',
	name: 'Tour Operator',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'entity_tour_operators',
	getDisplayValue(record: any): string{
		if(!record || !record.entity) return '';
		return record.entity.description;
	},
	remoteOptions: {
		icon: 'person',
		getLabel(option): any{
			if(!option) return '';
			return option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option || !option.entity) return '';
			return option.entity.description;
		}
	}
});

fieldsMap.set('taxation', {
	key: 'taxation',
	name: 'Fiscalità',
	valueType: ValueTypes.STRING,	
	getDisplayValue(record: any): string{
		if(!record) return '';
		if(Object.keys(TripServiceTaxation).some((v) => v === record.taxation))
				return TripServiceTaxation[record.taxation];
			else
				return record.taxation;
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const tripBookingPackage = dataContainerService.getValueFromKeyPath('trip_booking_packages.record');
		return Boolean(tripBookingPackage && tripBookingPackage.trip_package && tripBookingPackage.trip_package.target == 'ticket');
	}
});

export {fieldsMap};
