<ng-template #blockTemplate let-blockConfig='blockConfig' let-inTab='inTab' let-index='index'>
	<ng-container [ngSwitch]='blockConfig.type'>
		<dataset-list
			*ngSwitchCase='"list"'
			#listBlocks
			[showTitle]='enableBlocks.length > 1'
			[autoFetchData]='true'
			[datasetCode]='blockConfig.datasetCode'
			[filters]='blockFilters[index]'
			[parentDatasetRS]='blockDatasetRecordServices[index] || datasetRS'
			[parentDatasetACS]='blockDatasetActionContainerServices[index] || datasetACS'
			[conditionalShowPaginationToolbar]='true'
			[showSearchBar]='false'
			[elevate]='false'
			[showTitle]='!inTab'
			(created)='onCreatedInBlock(blockConfig)'
			(datasetEvent)='onDatasetEvent($event)'
		></dataset-list>
		<document-file-block
			*ngSwitchCase='"document_file"'
			[title]='!inTab && blockConfig.title'
			[record]='datasetRS.record.value'
			[documentFileKey]='blockConfig.documentFileKey'
			[imageOptions]='blockConfig.imageOptions'
			[type]='"image"'
			[uploadRoute]='blockConfig.uploadRoute'
			[getUploadDataFun]='blockConfig.getUploadData'
			(changed)='datasetRS.reload()'
		></document-file-block>
        <resource-inputs *ngSwitchCase='"resource-input"' [data] = datasetRS.record.value.data (changed)='datasetRS.reload()'>

        </resource-inputs>

		<div *ngSwitchDefault>
			<p>{{'Blocco non configurato' | translate}}: {{blockConfig.type}}</p>
		</div>
	</ng-container>
</ng-template>

<div class='full-form'>

	<mat-tab-group *ngIf='enableBlocks.length > 1'>
		<ng-container *ngFor='let blockConfig of stepConfig.options.blocks;let i = index;'>
			<ng-container *ngIf='enableBlocks[i]'>
				<mat-tab>
					<ng-template mat-tab-label>{{blockConfig.title | translate}}</ng-template>
					<ng-template matTabContent>
						<ng-container
							[ngTemplateOutlet]='blockTemplate'
							[ngTemplateOutletContext]='{blockConfig: blockConfig, inTab: true, index: i}'
						></ng-container>
					</ng-template>
				</mat-tab>
			</ng-container>
		</ng-container>
	</mat-tab-group>

	<ng-container *ngIf='enableBlocks.length === 1'>
		<ng-container *ngFor='let blockConfig of stepConfig.options.blocks;let i = index;'>
			<ng-container *ngIf='enableBlocks[i]'>
				<ng-container
					[ngTemplateOutlet]='blockTemplate'
					[ngTemplateOutletContext]='{blockConfig: blockConfig, inTab: false, index: i}'
				></ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</div>
