import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { supportedLanguages } from 'app/configs/datasets/actual_trips';
import { Subject } from 'rxjs';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import * as _ from 'lodash';

const fieldsMap = new Map<string, IFieldDefinition>();

const lengthOptions = [
	{
		label: 'Minuti',
		value: 'minutes',
	},
	{
		label: 'Ore',
		value: 'hours',
	},
	{
		label: 'Giorni',
		value: 'days',
	},
	{
		label: 'Mesi',
		value: 'months'
	}
];

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	readonlyIf(record: any): boolean {
		return record === null;
	},
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.',
	appearance: 'outline',
});

fieldsMap.set('read_code', {
	key: 'code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		readOnly: true
	},
	getDisplayValue(record){
		return '('+record.code +') '+ record.description;
	}
});

fieldsMap.set('read_duration', {
	key: 'duration',
	name: 'Durata',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		readOnly: true
	},
	getDisplayValue(record){
		let str = String(record.duration);

		const lengthType = lengthOptions.find(o => o.value == record.length_type);
		if(lengthType) str += ' ' +lengthType.label;
		return str;
	}
});

fieldsMap.set('read_note', {
	key: 'note',
	name: 'Note',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.READONLY,
		readOnly: true
	},
	skipIf(record){
		return !record.note
	}
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
	appearance: 'outline',
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	appearance: 'outline',
});

fieldsMap.set('languages', {
	key: 'languages',
	name: 'Languages',
	inputType: InputTypes.MULTI_SELECT,
	valueType: ValueTypes.JSON,
	options: supportedLanguages,
	appearance: 'outline',
});

fieldsMap.set('audio_guide_languages', {
	key: 'audio_guide_languages',
	name: 'Audio guide languages',
	inputType: InputTypes.MULTI_SELECT,
	valueType: ValueTypes.JSON,
	options: supportedLanguages,
	appearance: 'outline',
});

fieldsMap.set('duration', {
	key: 'duration',
	name: 'Durata',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
	appearance: 'outline',
});

fieldsMap.set('meeting_point_id', cloneFieldDefinition(meetingPointIdField, { 
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	getDisplayValue(data, record){
		return _.get(record, 'description');
	}
}));

fieldsMap.set('length_type', {
	key: 'length_type',
	name: 'Tipologia durata',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputRequired: true,
	formValidators: [ Validators.required ],
	options: lengthOptions,
	appearance: 'outline',
});

export { fieldsMap };