import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { FakeDashboardPageComponent } from 'app/main/pages/fake-dashboard/fake-dashboard.component';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatasetListWidgetModule } from 'app/main/widgets/dataset-list/widget.module';
import { ChartsModule } from 'ng2-charts';

@NgModule({
	declarations: [
		FakeDashboardPageComponent
	],
	imports     : [
		MatButtonModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,

		ChartsModule,
		NgxChartsModule,

		FuseSharedModule,
		FuseSidebarModule,
		FuseWidgetModule,

		DatasetModule,
		DatasetListWidgetModule,
		TranslateModule.forChild(),
	]
})
export class FakeDashbaordPageModule{}
