import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents, IDatasetEvent} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AppService} from 'app/services/app.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { Subject } from 'rxjs';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { IPaginationResponse } from 'app/interfaces';

export interface IPriceRulesDialogData {
	listId: string;
	parentDatasetACS: DatasetActionContainerService;
	parentRecordId: string;
	parentFieldKey: string;
}

@Component({
	selector: 'price-rules-dialog',
	templateUrl: 'dialog.component.html',
	// styleUrls: ['dialog.component.scss'],
	providers: [PageClosureContainerService, DatasetActionContainerService, DatasetRecordService]
})
export class PriceRulesDialogComponent implements OnInit, OnDestroy{

	loading = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public appService: AppService,
		public datasetService: DatasetService,
		protected dialog: MatDialog,
		public dialogRef: MatDialogRef<PriceRulesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IPriceRulesDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.data.parentDatasetACS.datasetCode,
			actionCode: 'detail',
			parentDatasetACS: this.data.parentDatasetACS,
			fetchData: false,
			parentDatasetRS: this.datasetRS
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe( ready => {
			if(!ready) return;
			this.datasetRS.recordId = this.data.parentRecordId;
			this.datasetRS.init()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(readyRecordService => {
				if(!readyRecordService) return;
				this.datasetRS.reload();
			});
		});
		this.loadLastCreated();
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onCloseClick(): void{
		this.dialogRef.close(false);
	}

	onDatasetEvent(event: IDatasetEvent): void{
		if(!this.data.listId){
			if(event.eventName === DatasetEvents.CREATED && event.data && event.data.price_rule_list_id){
				this.data.listId = event.data.price_rule_list_id;
			}
		}
		this.loadLastCreated();
	}

	loadLastCreated(): void{
		if(!this.data.listId) return;
		this.datasetService.get<IPaginationResponse<any>>('/dataset/price_rules', {
			price_rule_list_id: this.data.listId,
			foreign_parent_record_id: this.data.parentRecordId,
			foreign_parent_dataset_code: this.data.parentDatasetACS.datasetCode,
			foreign_parent_field_key: this.data.parentFieldKey,
			sortBy: 'created_at',
			sortDirection: 'desc',
			perPage: 1,
		}).subscribe(result => {
			if(result && result.items && result.items.length){
				this.datasetACS.putDatasetData('last_created', result.items[0]);
			}else{
				this.datasetACS.putDatasetData('last_created', null);
			}
		});
	}
}
