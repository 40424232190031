export const locale = {
	lang: 'en',
	data: {
		'Codice': 'Code',
		'Data Prenotazione': 'Booking Date',
		'Andata': 'Going',
		'Ritorno': 'Return',
		'Risorse': 'Resources',
		'Chiudi': 'Close',
	}
};
