import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';

export interface IActionDialogData {
	title: string;
	contentMessage: string;
	positiveText?: string;
	negativeText?: string;
}

@Component({
	selector: 'action-dialog',
	templateUrl: 'action-dialog.component.html',
	styleUrls: ['action-dialog.component.scss']
})
export class ActionDialogComponent implements OnDestroy, OnInit {

	public loading = true;
	protected _unsubscribeAll: Subject<any>;
	public resultStatus: ('success' | 'error' | null) = null;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<ActionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IActionDialogData
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onYesClick(): void {
		this.dialogRef.close(true);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	setMessage(text: string, title?: string): void{
		this.data.contentMessage = text;

		if(title !== null && title !== undefined){
			this.data.title = title;
		}
	}

	setTitle(title: string): void{
		this.data.title = title;
	}
}
