import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	ListViewItemTypes,
	FieldViews
} from 'app/interfaces';
import { Validators } from '@angular/forms';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

/**
 * Fields Fattura con Partita IVA
 */

fieldsMap.set('id_invoice_profile', {
	key: 'id_invoice_profile',
	name: 'ID Invoice Profile',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [],
});

fieldsMap.set('person_type', {
	key: 'person_type',
	name: 'Person Type',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required],
	defaultValue() {
		return 'LEGAL';
	}
});

fieldsMap.set('p_iva_search', {
	key: 'p_iva_search',
	name: 'P.IVA',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

fieldsMap.set('create_new_profile', {
	key: 'create_new_profile',
	name: 'Crea nuovo profilo',
	inputConfig: {
		type: InputTypes.TOGGLE
	},
	defaultValue(){
		return true;
	},
	valueType: ValueTypes.STRING
});

fieldsMap.set('profile_name', {
	key: 'profile_name',
	name: 'Nome Profilo*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('business_name', {
	key: 'business_name',
	name: 'Ragione Sociale*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('p_iva', {
	key: 'p_iva',
	name: 'P.IVA*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('corporate_tax_code', {
	key: 'corporate_tax_code',
	name: 'Codice Fiscale Aziendale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

fieldsMap.set('country_select', cloneFieldDefinition(nationCodeField, {
	key: 'country',
	name: 'Nazione*',
	formValidators: [Validators.required]
}));

/*fieldsMap.set('country', {
	key: 'country',
	name: 'Nazione*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});*/

fieldsMap.set('province', {
	key: 'province',
	name: 'Provincia*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('city', {
	key: 'city',
	name: 'Città*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('cap', {
	key: 'cap',
	name: 'CAP*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('address', {
	key: 'address',
	name: 'Indirizzo*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('recipient_code', {
	key: 'recipient_code',
	name: 'Codice Destinatario',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

fieldsMap.set('pec', {
	key: 'pec',
	name: 'PEC',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

export { fieldsMap };
