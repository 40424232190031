<h1 class="title">{{data.title?data.title:'Inserisci i dati'}}</h1>
<div class="content" (keydown.enter)="submit($event)">
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>
	<p *ngIf='data.message' [innerHTML]="data.message"></p>
	<form-viewer 
		#formViewer
		[record]='data.formData'
		[formConfig]='data.formConfig'
		[dataContainerService]='data.dataContainerService'
	></form-viewer>
</div>
<div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px">
	<button class="no-btn" (click)="onNoClick()">{{data.negativeText?data.negativeText:'Annulla' | translate}}</button>
	<button class="yes-btn" [disabled]='!formViewer.formGroup.valid' (click)='onYesClick()'>
		{{data.positiveText?data.positiveText:'Ok' | translate}}
	</button>
</div>
