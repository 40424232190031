<div class='full-form'>
	<form autocomplete="off"
		name='form' 
		*ngIf='filterFormGroup' 
		[formGroup]="filterFormGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'>

		<div class="pricing-categories">
			<div class="instruction">
				<p class="instruction">
					Prezzo per gruppo significa che i clienti pagano un prezzo per l'intero gruppo. Questo è spesso per attività private. Il prezzo a persona ti consente di offrire prezzi diversi per adulti, adolescenti, bambini, ecc. Utilizzalo per attività pubbliche. Se hai bisogno sia del Prezzo per gruppo che del Prezzo per persona, inizia con uno di questi, invia la tua attività affinché il nostro team possa rivedere, quindi crea una nuova opzione.
				</p>
			</div>
		</div>

		<div *ngIf='(datasetRS.record.value.trip_service_rule_type == "0")' class="base-dropdown age-group-selection-menu">
			<button class="age-range-btn" mat-button [matMenuTriggerFor]="menu">
				Aggiungi fascia d'età 
				<mat-icon>keyboard_arrow_down</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button *ngIf='buttons["CHD"]' mat-menu-item (click)='buildForm("CHD")'>CHILDREN</button>
				<button *ngIf='buttons["INF"]' mat-menu-item (click)='buildForm("INF")'>INFANT</button>
				<button *ngIf='buttons["SNR"]' mat-menu-item (click)='buildForm("SNR")'>SENIOR</button>
			</mat-menu>
		</div>

		<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

		<section class="price-block-category" *ngFor='let key of priceServicesCollection | customKeys'>
			<h3 class="price-block-title">
				<span class="title">{{priceServicesCollection[key].title}}</span>
				<div class="price-block-actions">
					<button *ngIf='priceServicesCollection[key].canBeRemoved' class="gyg-button gyg-button-medium gyg-button-link-error remove-pricing-category-btn" (click)='removePriceService(priceServicesCollection[key])'>
						Rimuovi
					</button>
				</div>
			</h3>

			<div *ngIf='canShowAge()' class="age-range">
				<div class="age-from">
					{{priceServicesCollection[key].age.from}}
				</div>
				<div class="separator">-</div>
				<div class="age-to">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get(priceServicesCollection[key].age.field)'
						[formGroup]='filterFormGroup'
						[formControlKey]='priceServicesCollection[key].age.field'
					></form-input-viewer>
				</div>
			</div>
			
			<div [formArrayName]='priceServicesCollection[key].formArrayName'>
				<ng-container *ngFor="let controlFormGroup of filterFormGroup.get(priceServicesCollection[key].formArrayName)['controls']; let first = first; index as i">
					<div class="row">
						<div class="price-block-cols">
							<div class="price-block-age">
								<form-input-viewer
									[formInputDefinition]='fileterFields.get("price")'
									[formGroup]='controlFormGroup'
									[formControlKey]='"price"'
								></form-input-viewer>
							</div>
	
							<div class="price-block-pricing">
								<form-input-viewer
									[formInputDefinition]='fileterFields.get("option_id")'
									[formGroup]='controlFormGroup'
									[formControlKey]='"option_id"'
									[dataContainerService]='datasetACS'
								></form-input-viewer>
							</div>
						</div>

						<div class="dates-block-cols">
							<div class="valid_from">
								<form-input-viewer
									[formInputDefinition]='fileterFields.get("valid_from")'
									[formGroup]='controlFormGroup'
									[formControlKey]='"valid_from"'
								></form-input-viewer>
							</div>
	
							<div class="valid_to">
								<form-input-viewer
									[formInputDefinition]='fileterFields.get("valid_to")'
									[formGroup]='controlFormGroup'
									[formControlKey]='"valid_to"'
								></form-input-viewer>
							</div>
						</div>

						<div *ngIf='!first' class="delete-block">
							<mat-icon (click)='removePriceRule(priceServicesCollection[key], i)'>delete</mat-icon>
						</div>
					</div>
				</ng-container>
			</div>

			<div class="set-up-price-tiers" (click)='setUpPriceTiers(priceServicesCollection[key].code)'>
				<b>Aggiungi prezzo</b>
			</div>
		</section>
	
		<div class="save-button-container">
			<button 
				[disabled]='isDisabledButton()' 
				class="gyg-button gyg-button-medium gyg-button-cta-light" 
				(click)='onSave()'>
				<span>Salva e continua</span>
			</button>
		</div>
	</form>

</div>