import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		title: 'Tour operators',
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('enable_gyg'),
			fieldsMap.get('enable_viator'),
			fieldsMap.get('enable_tour_cms'),
			fieldsMap.get('enable_bokun'),
		]
	},
	{
		title: 'Modules',
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('enable_kiara_cloud'),
			fieldsMap.get('enable_eagency'),
			fieldsMap.get('enable_fatture_in_cloud'),
			fieldsMap.get('enable_ticketing'),
		]
	},
];

const config: IFormConfig = { 
	editTitle: 'Filtri',
	fields: formFields 
};

export default config;
