import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMapPraticheServer } from '../../fields/filesystems/fields.pratiche-server';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMapPraticheServer.get('host'),
	fieldsMapPraticheServer.get('username'),
	fieldsMapPraticheServer.get('password'),
	fieldsMapPraticheServer.get('root'),
	fieldsMapPraticheServer.get('protocol'),
	fieldsMapPraticheServer.get('scenario'),
];

const praticheServerForm: IFormConfig = { 
	editTitle: 'Modifica Configurazioni FTP',
	fields: formFields 
};

export default praticheServerForm;
