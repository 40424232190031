import { EventEmitter, Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { catchError, filter, map, skip, take } from 'rxjs/operators';
import { IAppEvent, IDomainPermission, IMeResponse, ISocketEvent } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import saveAs from 'file-saver';
import { APP_CONFIG } from './app-config.token';
import * as semver from 'semver';
import { defaultSupportedLanguageCodes, defaultSupportedLanguages } from 'app/helpers/i18n.helper';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
//import Bugsnag from '@bugsnag/js';

// Define the default config
const DEFAULT_APP_CONFIG = {
	serverAddress: null,
	serverPort: null,
	apiRoutePrefix: null,
	disableInMemoryAPI: null
};

export interface IToolbarActionConfig{
	title?: string;
	icon?: string;
	onClick: () => void;
	classes?: string;
}

@Injectable({
	providedIn: 'root'
})
export class AppService{

	public searchBarText: BehaviorSubject<string> = new BehaviorSubject(null);

	public showAppInterface: BehaviorSubject<boolean> = new BehaviorSubject(true);

	public configObserver: BehaviorSubject<any>;

	public eventEmitter: EventEmitter<any> = new EventEmitter<any>();

	public appEvent: Subject<IAppEvent> = new Subject();

	public socketEvent: Subject<ISocketEvent> = new Subject();

	public layoutFusePerefctScrollBar: FusePerfectScrollbarDirective;

	public baseDefault: any;

	public LogoImg: any;

	public me: IMeResponse;

	/**
	 * Configured by the page
	 */
	public toolbarPageActions: IToolbarActionConfig[] = [];

	public language: BehaviorSubject<string> = new BehaviorSubject('it');

	// http://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
	public supportedLanguageCodes = defaultSupportedLanguageCodes;
	public supportedLanguages = defaultSupportedLanguages;

	public appVersion: string = null;
	public hasNewVersion = false;
	public currentRoutedComponent: any;

	constructor(
		@Inject(APP_CONFIG) @Optional() config: any,
		protected snackBar: MatSnackBar,
		protected http: HttpClient,
		protected matDialog: MatDialog,
	){
		if(config){
			this.baseDefault = Object.assign({}, DEFAULT_APP_CONFIG, config);
		}else{
			this.baseDefault = Object.assign({}, DEFAULT_APP_CONFIG);
		}
		this.configObserver = new BehaviorSubject(this.baseDefault);
	}

	getBaseServerAddressRoot(defaultValue: string = ''): string{
		let address = this.baseDefault.serverAddress;
		if(this.baseDefault.serverPort && address){
			address += ':' + this.baseDefault.serverPort;
		}
		address = address || defaultValue;

		return address;
	}

	getBaseServerAddress(defaultValue: string = ''): string{
		let address = this.baseDefault.serverAddress;
		if(this.baseDefault.serverPort && address){
			address += ':' + this.baseDefault.serverPort;
		}
		address = address || defaultValue;
		if(this.baseDefault.apiRoutePrefix){
			address += this.baseDefault.apiRoutePrefix;
		}
		return address;
	}

	setLogo(response): void {
		if (response && response.logo) {
			this.LogoImg = 'data:image/' + response.type + ';charset=utf-8;base64,' + response.logo;
		} else {
			this.LogoImg = null;
		}
	}

	showErrorMessage(message): void{
		const snackBarRef = this.snackBar.open(message, 'DISMISS', {
			duration: 4500,
			panelClass: ['danger-snackbar', 'red-bg']
		});
		snackBarRef.onAction().subscribe(() => {
			snackBarRef.dismiss();
		});
	}

	showSuccessMessage(message): void{
		const snackBarRef = this.snackBar.open(message, 'DISMISS', {
			duration: 3500,
			panelClass: ['danger-snackbar', 'green-bg']
		});
		snackBarRef.onAction().subscribe(() => {
			snackBarRef.dismiss();
		});
	}

	emitEvent(name: string): void{
		this.appEvent.next({name});
	}

	setCookie(key: string, value: string, ageInSeconds?: number): void{
		let newCookie = String(key) + '=' + String(value);
		if (ageInSeconds) {
			const date = new Date();
			date.setTime(date.getTime() + (ageInSeconds * 1000));
			newCookie += '; max-age=' + ageInSeconds;
			newCookie += '; expires=' + date.toUTCString();
		}
		document.cookie = newCookie;
	}

	getCookie(key): any{
		const ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i++) {
			const pair = ca[i].trim().split('=');
			if(pair[0].trim() === key) return pair[1];
		}
		return null;
	}

	removeCookie(key): void{
		document.cookie = key + '=; max-age=-99999999; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	getAbsolutePath(path: string): string{
		if(!path) return path;
		if(!path.startsWith('/')) return path;
		return this.getBaseServerAddressRoot() + path;
	}

	downloadFile(fileId): void{
		this.http.get(this.getBaseServerAddressRoot() + '/document/download/' + fileId, {
			responseType: 'blob',
			observe: 'response'
		})
		.subscribe({
			next: (response: HttpResponse<Blob>) => {
				const filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
				// const blob = new Blob([response.body], { type: response.body.type });
				saveAs(response.body, filename.replace(/\"/g, ''));
			},
			error: (error) => {
				this.showErrorMessage(error.message);
			}
		});
	}

	downloadUrl(url: string, params?: any, defaultFileName?: string): Subject<any>{
		const result = new Subject();
		this.http.get(url, {
			responseType: 'blob',
			observe: 'response',
			params
		})
		.subscribe({
			next: (response: HttpResponse<Blob>) => {
				const filename = (response.headers.get('Content-Disposition') || 'filename=' + defaultFileName).split('filename=')[1];
				saveAs(response.body, filename.replace(/\"/g, ''));
				result.next(response);
				result.complete();
			},
			error: (error) => {
				this.showErrorMessage(error.message);
				result.error(error);
				result.complete();
			}
		});
		return result;
	}

	openFile(fileId): void{
		this.http.get(this.getBaseServerAddressRoot() + '/document/download/' + fileId, {
			responseType: 'blob',
			observe: 'response'
		})
		.subscribe({
			next: (response: HttpResponse<Blob>) => {
				const filename = (response.headers.get('Content-Disposition') || 'filename=documento').split('filename=')[1];
				const blob = new Blob([response.body], { type: response.body.type });
				const fileURL = URL.createObjectURL(blob);
				window.open(fileURL, '_blank');
			},
			error: (error) => {
				this.showErrorMessage(error.message);
			}
		});
	}

	checkAppVersions(): Observable<boolean>{
		const loaded = new BehaviorSubject<boolean>(false);
		this.http.get<any>(this.getBaseServerAddressRoot() + '/versions')
		.subscribe({
			next: (response) => {
				if(response && response.web_app && response.web_app.version){
					if(!this.appVersion){
						this.appVersion = response.web_app.version;
						this.hasNewVersion = false;
					}else if(semver.gt(response.web_app.version, this.appVersion)){
						this.hasNewVersion = true;
					}else{
						this.hasNewVersion = false;
					}
				}
				//if (response.version) Bugsnag.setContext('App version ' + response.version);
				
				loaded.next(true);
				loaded.complete();
			},
			error: response => {
				loaded.next(false);
				loaded.complete();
			}
		});

		return loaded;
	}

	init(): void{

		this.checkAppVersions()
		.subscribe(isLoaded => {
			if (isLoaded) return;
			//this.startBugsnag();
		});
		// setInterval??
		setTimeout(() => {
			this.checkAppVersions();
		}, 10 * 60 * 1000); // every 10 minutes
		
	}

	/**
	 * Initialize bugsnug
	 * @param config
	 */
	startBugsnag(): void{
		/*Bugsnag.start({
			apiKey: this.baseDefault.BUGSNAG_API_KEY,
			appVersion: this.appVersion || '0.0.1'
		});*/
	}
}
