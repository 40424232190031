<!-- loading overlay -->
<div class="loading-overlay" *ngIf='_loading.value'>
	<mat-spinner></mat-spinner>
</div>
<!-- content -->
<div class="page-layout blank p-24" fusePerfectScrollbar>
	<!-- container -->
	<div class="container">
		<form [formGroup]='filterFormGroup'>
			<div class="main-widget">
				<div class="doc_title position-relative mat-blue-600-bg" fxLayout="row" fxLayoutAlign="space-between center">
					<div fxLayout="column" fxLayoutAlign="start start">
						<span class="h2">{{'Seleziona il periodo di confronto' | translate}}</span>
					</div>
				</div>
			</div>
			<div class="ranges">
				<div class="input-group" fxLayout='row wrap' fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutAlign="space-between" fxLayoutGap="30px">
					<div class="col" fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutGap="30px">
						<!-- Comparison type -->
						<form-input-viewer
							fxFlex
							[formInputDefinition]='fileterFields.get("comparison_type")'
							[formGroup]='filterFormGroup'
							formControlKey='comparison_type'
						></form-input-viewer>
						<!-- Filter type -->
						<form-input-viewer
							fxFlex
							[formInputDefinition]='fileterFields.get("filter_type")'
							[formGroup]='filterFormGroup'
							formControlKey='filter_type'
						></form-input-viewer>
						<!-- Tipo fatturato -->
						<form-input-viewer
							fxFlex
							[formInputDefinition]='fileterFields.get("tipo_fatturato")'
							[formGroup]='filterFormGroup'
							formControlKey='tipo_fatturato'
						></form-input-viewer>
						<!-- Sort type -->
						<form-input-viewer
							fxFlex
							[formInputDefinition]='fileterFields.get("sort_type")'
							[formGroup]='filterFormGroup'
							formControlKey='sort_type'
						></form-input-viewer>
					</div>
					<!-- col dates -->
					<div class="col" fxLayout.sm="column wrap" fxLayout.xs="column wrap">
						<div fxLayoutGap="30px" fxLayout='row wrap' fxLayout.sm="row wrap" fxLayout.xs="row wrap">
							<!-- Start Month -->
							<mat-form-field>
								<mat-label>Choose a date</mat-label>
								<input matInput [matDatepicker]="picker1" [formControlName]="'from_date'" (dateChange)='onChangeDateFrom($event, "to_date")'>
								<mat-datepicker-toggle matSuffix [for]="picker1">
									<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
								</mat-datepicker-toggle>
								<mat-datepicker #picker1></mat-datepicker>
							</mat-form-field>
							<!-- End Month -->
							<mat-form-field>
								<mat-label>Choose a date</mat-label>
								<input matInput [matDatepicker]="picker2" [max]='max' [min]='min' [formControlName]="'to_date'">
								<mat-datepicker-toggle matSuffix [for]="picker2">
									<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
								</mat-datepicker-toggle>
								<mat-datepicker #picker2></mat-datepicker>
							</mat-form-field>
						</div>


						<ng-container *ngIf='isCustomComparison'>
							<div fxLayoutGap="30px" fxLayout='row wrap' fxLayout.sm="row wrap" fxLayout.xs="row wrap">
								<!-- Start Month -->
								<mat-form-field>
									<mat-label>Choose a date</mat-label>
									<input matInput [matDatepicker]="picker3" [formControlName]="'from_date_comparison'" (dateChange)='onChangeDateFrom($event, "to_date_comparison")'>
									<mat-datepicker-toggle matSuffix [for]="picker3">
										<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
									</mat-datepicker-toggle>
									<mat-datepicker #picker3></mat-datepicker>
								</mat-form-field>
								<!-- End Month -->
								<mat-form-field>
									<mat-label>Choose a date</mat-label>
									<input matInput [matDatepicker]="picker4" [max]='maxComparison' [min]='minComparison' [formControlName]="'to_date_comparison'">
									<mat-datepicker-toggle matSuffix [for]="picker4">
										<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
									</mat-datepicker-toggle>
									<mat-datepicker #picker4></mat-datepicker>
								</mat-form-field>
							</div>
						</ng-container>
					</div>
				</div>

				<!-- advanced filter fields -->
				<div class="dialog-filters" (click)='openFilters()'>Ricerca avanzata <span class="num_filters" *ngIf='numFilters'>({{numFilters}} {{numFilters > 1 ? 'Filtri Attivi' : 'Filtro Attivo'}})</span></div>
				<!-- enabled filters -->
				<div class="enabled_filters">
					<mat-chip-list>
						<mat-chip *ngFor='let enabledFilter of enabledFilters'>
							{{ enabledFilter.label }} <mat-icon (click)='removeFilter(enabledFilter.value)'>cancel</mat-icon>
						</mat-chip>
					</mat-chip-list>
				</div>
                <div class="input-groups" fxLayoutAlign="start center" *ngIf="_showCostSwitch">
                    <mat-slide-toggle (toggleChange)="viewCost()">{{'Mostra costi partenze' | translate}}</mat-slide-toggle>
                </div>
				<div class="main-widget" style="margin-top: 40px;">
					<div class="doc_title position-relative mat-blue-600-bg" fxLayout="row" fxLayoutAlign="space-between center">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="h3">{{'Seleziona i campi da raggruppare' | translate}}</span>
						</div>
					</div>
				</div>
				<div class="raggruppamenti" >
					<div class="selections-container">
						<h2>Raggruppamenti disponibili</h2>
						<div class="input-groups" >
							<form-input-viewer #groupsInput
								fxFlex
								[formInputDefinition]='fileterFields.get("groups")'
								[formGroup]='filterFormGroup'
								formControlKey='groups'
							></form-input-viewer>
						</div>
					</div>
					<!-- chip list -->
					<ng-container *ngIf='selectedGroups.length > 0'>
						<div cdkDropList class="chip-list" cdkDropListOrientation="horizontal" (cdkDropListDropped)='drop($event)' [cdkDropListDisabled]="viewCostStatus">
							<div class="chip-item" *ngFor='let group of selectedGroups' cdkDrag>
								{{ group && group.label }}
								<mat-icon (click)='remove(group)' *ngIf="!viewCostStatus">cancel</mat-icon>
							</div>
						</div>
					</ng-container>
				</div>
				<!-- action -->
				<div class="action">
					<!-- REPORT -->
					<button [disabled]='(selectedGroups.length == 0) || !filterFormGroup.valid' mat-button color="accent" (click)='loadFirstGroup()'>
						<mat-icon>launch</mat-icon>
						{{'Genera report' | translate}}
					</button>
					<!-- FILE EXPORT -->
					<button [disabled]='(selectedGroups.length == 0) || !filterFormGroup.valid' class="file_btn" mat-button [matMenuTriggerFor]="menu">
						<mat-icon>get_app</mat-icon>
						Esporta su file
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item (click)='generaFile("xls")'>Xls</button>
						<button mat-menu-item (click)='generaFile("csv")'>Csv</button>
					</mat-menu>
				</div>
			</div>
		</form>
        <!-- standard-->
		<ng-container *ngIf='!_loading.value && selectedGroups.length > 0 && report && report[selectedGroups[0].value] && !viewCostStatus'>
			<!-- header -->
			<div class="header-bi">
				<div class="column first">
					<div class="empty_space"></div>
					<div class="row">
						<div class="counter"></div>
						<div class="item">Item</div>
					</div>
					<div class="row">
						<div class="counter"></div>
						<div class="code">Codice</div>
						<div class="descr">Descrizione</div>
					</div>
				</div>
				<div class="column second">
					<div class="row">
						<span>{{ fromDate }} - {{ toDate }}</span>
					</div>
					<div class="row">
						<div class="num_participants">Numero partecipanti</div>
						<div class="totale_pratica">Totale pratica</div>
					</div>
					<div class="row">
						<div class="assoluto">Assoulto (Quantità)</div>
						<div class="assoluto">Assoluto (Euro)</div>
					</div>
					<div class="row totals">
						<div class="totale_pratica">{{ _totals['default_range']['total_participants'] || 0 }}</div>
						<div class="assoluto">{{ _totals['default_range']['total_amount'] || 0 | number:'1.2-2':'it-IT' }}</div>
					</div>
				</div>
				<div class="column third">
					<div class="row">
						<ng-container *ngIf='filterFormGroup.get("comparison_type").value != "none"'>
							<span>{{ fromDateComparison }} - {{ toDateComparison }}</span>
						</ng-container>
					</div>
					<div class="row">
						<div class="num_participants">Numero partecipanti</div>
						<div class="totale_pratica">Totale pratica</div>
					</div>
					<div class="row">
						<div class="assoluto">Assoulto (Quantità)</div>
						<div class="assoluto">Assoluto (Euro)</div>
					</div>
					<div class="row totals">
						<div class="totale_pratica">{{ _totals['comparison_range']['total_participants'] || 0 }}</div>
						<div class="assoluto">{{ _totals['comparison_range']['total_amount'] || 0 | number:'1.2-2':'it-IT' }}</div>
					</div>
				</div>
				<div class="column fourth">
					<div class="row">
						<span>Confronto</span>
					</div>
					<div class="row">
						<div class="num_participants">Numero partecipanti</div>
						<div class="totale_pratica">Totale pratica</div>
					</div>
					<div class="row">
						<div class="subrow">
							<div class="assoluto">Assoluto (assoluto)</div>
							<div class="percentuale">Percentuale<br/>(percentuale)</div>
						</div>
						<div class="subrow">
							<div class="assoluto">Assoluto (assoluto)</div>
							<div class="percentuale">Percentuale<br/>(percentuale)</div>
						</div>
					</div>

					<div class="row">
						<div class="subrow totals">
							<div class="assoluto">{{ (_totals['default_range']['total_participants'] || 0) - (_totals['comparison_range']['total_participants'] || 0) }}</div>
							<div class="percentuale">{{ participantPercentage | number:'1.2-2':'it-IT' }}</div>
						</div>
						<div class="subrow totals">
							<div class="assoluto">{{ (_totals['default_range']['total_amount'] || 0) - (_totals['comparison_range']['total_amount'] || 0) | number:'1.2-2':'it-IT' }}</div>
							<div class="percentuale">{{ totalAmountPercentage | number:'1.2-2':'it-IT' }}</div>
						</div>
					</div>
				</div>
			</div>

			<!-- body -->
			<ng-container
				[ngTemplateOutlet]='groupsTemplate'
				[ngTemplateOutletContext]='{
				dataset: report[selectedGroups[0].value]["dataset"]["items"],
				currentGroup: report[selectedGroups[0].value]["currentGroup"],
				nextGroup: report[selectedGroups[0].value]["nextGroup"],
				prevGroup: null}'
			></ng-container>
		</ng-container>

		<!-- template-->
		<ng-template #groupsTemplate let-dataset='dataset' let-currentGroup='currentGroup' let-prevGroup='prevGroup' let-nextGroup='nextGroup'>
			<ng-container *ngIf='!dataset || dataset && !dataset.length'>
				<div class="no-data">Nessun risultato trovato</div>
			</ng-container>
			<!-- body -->
			<div [ngClass]='["body", (prevGroup != null) ? "with_prev_group" : "", currentGroup]'>
				<ng-container *ngIf='(prevGroup == null)'>
					<div class="group_divider" [class.is_first]='(prevGroup == null)'></div>
				</ng-container>
				<ng-container *ngFor='let item of dataset; let i = index; let first = first'>
					<ng-container *ngIf='item'>
						<ng-container *ngIf='nextGroup !== null && prevGroup == null'>
							<mat-icon (click)='expand(i, item, nextGroup, currentGroup, prevGroup, 1)' [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
								expand_more
							</mat-icon>
						</ng-container>
						<!-- if is first group -->
						<ng-container *ngIf='(prevGroup == null)'>
							<div class="action">
								<div class="_loadingNext" *ngIf='_loadingNext.value'></div>
								<div class="column first">
									<div class="row">
										<div class="counter">{{ item['count'] }}</div>
										<div class="code">{{ item | biextradecode }}</div>
										<div class="descr" [matTooltip]='item | biextradecode:"description"'>
											<span class="item_description">{{ item | biextradecode:'description' }}</span>
										</div>
									</div>
								</div>
								<div class="column second">
									<div class="row">
										<div class="num_participants">
											{{ item['total_participants'] }}
										</div>
										<div class="totale_pratica">
											{{ item['total_amount'] | currency:'EUR' }}
										</div>
									</div>
								</div>
								<div class="column third">
									<div class="row">
										<div class="num_participants">
											<ng-container *ngIf='item["total_participants_comparison"]'>
												{{ item['total_participants_comparison'] }}
											</ng-container>
										</div>
										<div class="totale_pratica">
											{{ item['total_amount_comparison'] | currency:'EUR' }}
										</div>
									</div>
								</div>
								<div class="column fourth">
									<div class="row">
										<div class="subrow">
											<div class="assoluto">{{ (item['total_participants'] - item['total_participants_comparison'] || 0) }}</div>
											<div class="percentuale">
												<ng-container *ngIf="item['total_participants_comparison'] && item['total_participants_comparison'] > 0">
													{{ item | bipercentage:'participants' | number:'1.2-2':'it-IT' }}
												</ng-container>
											</div>
										</div>
										<div class="subrow">
											<div class="assoluto">{{ (item['total_amount'] - item['total_amount_comparison'] || 0) | currency:'EUR' }}</div>
											<div class="percentuale">
												<ng-container *ngIf="item['total_amount_comparison'] > 0">
													{{ item | bipercentage:'amount' | number:'1.2-2':'it-IT' }}
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf='(prevGroup != null)'>
							<div class="row" [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
								<div class="sub-header" *ngIf='first'>
									<div class="column first">
										<div class="counter"></div>
										<div class="code">Codice</div>
										<div class="descr">Descrizione</div>
									</div>
									<div class="column second">
										<div class="num_participants">Num. partecipanti</div>
										<div class="totale_pratica">Tot. pratica</div>
									</div>
									<div class="column third">
										<div class="num_participants">Num. partecipanti</div>
										<div class="totale_pratica">Tot. pratica</div>
									</div>
									<div class="column fourth">
										<div class="subrow">
											<div class="assoluto">Ass. part.</div>
											<div class="percentuale">Perc. part.</div>
										</div>
										<div class="subrow">
											<div class="assoluto">Ass. prat.</div>
											<div class="percentuale">Perc. prat</div>
										</div>
									</div>
								</div>
								<div class="sub-data" [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
									<div class="_loadingNext" *ngIf='_loadingNext.value'></div>
									<ng-container *ngIf='nextGroup !== null'>
										<mat-icon (click)='expand(i, item, nextGroup, currentGroup, prevGroup)'>
											expand_more
										</mat-icon>
									</ng-container>
									<div class="column first">
										<div class="counter">{{ item['count'] }}</div>
										<div class="code">{{ item | biextradecode }}</div>
										<div class="descr" [matTooltip]='item | biextradecode:"description"'>
											<span class="item_description">{{ item | biextradecode:'description' }}</span>
										</div>
									</div>
									<div class="column second">
										<div class="num_participants">{{ item['total_participants'] }}</div>
										<div class="totale_pratica">{{ item['total_amount'] | currency:'EUR' }}</div>
									</div>
									<div class="column third">
										<div class="num_participants">
											<ng-container *ngIf='item["total_participants_comparison"]'>
												{{ item['total_participants_comparison'] }}
											</ng-container>
										</div>
										<div class="totale_pratica">{{ item['total_amount_comparison'] | currency:'EUR' }}</div>
									</div>
									<div class="column fourth">
										<div class="row">
											<div class="subrow">
												<div class="assoluto">{{ (item['total_participants'] - item['total_participants_comparison'] || 0) }}</div>
												<div class="percentuale">
													<ng-container *ngIf="item['total_participants_comparison'] && item['total_participants_comparison'] > 0">
														{{ item | bipercentage:'participants' | number:'1.2-2':'it-IT' }}
													</ng-container>
												</div>
											</div>
											<div class="subrow">
												<div class="assoluto">{{ (item['total_amount'] - item['total_amount_comparison'] || 0) | currency:'EUR' }}</div>
												<div class="percentuale">
													<ng-container *ngIf="item['total_amount_comparison'] && item['total_amount_comparison'] > 0">
														{{ item | bipercentage:'amount' | number:'1.2-2':'it-IT' }}
													</ng-container>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
					<!-- if next group -->
					<ng-container *ngIf='report && report[nextGroup] && report[nextGroup]["dataset"] && report[nextGroup]["dataset"][item["record_id"]] && item["record_id"] && expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
						<ng-container
							[ngTemplateOutlet]='groupsTemplate'
							[ngTemplateOutletContext]='{
							dataset: report[nextGroup]["dataset"][item["record_id"]],
							currentGroup: report[nextGroup]["currentGroup"],
							nextGroup: report[nextGroup]["nextGroup"],
							prevGroup: report[nextGroup]["prevGroup"]}'
						></ng-container>
					</ng-container>
				</ng-container>
			</div>
		</ng-template>
        <!-- END standard-->

        <!-- width cost-->
        <ng-container *ngIf='!_loading.value && selectedGroups.length > 0 && report && report[selectedGroups[0].value] && viewCostStatus'>
            <!-- header -->
            <div class="header-bi-cost">
                <div class="column first">
                    <div class="empty_space"></div>
                    <div class="row">
                        <div class="counter"></div>
                        <div class="item">Item</div>
                    </div>
                    <div class="row">
                        <div class="counter"></div>
                        <div class="code">Codice</div>
                        <div class="descr">Descrizione</div>
                    </div>
                </div>
                <div class="column second">
                    <div class="row">
                        <span>{{ fromDate }} - {{ toDate }}</span>
                    </div>
                    <div class="row">
                        <div class="num_participants" matTooltip="{{'Numero Partecipanti' | translate}}">#</div>
                        <div class="totale_pratica">Totale</div>
                        <div class="cost">Costo</div>
                        <div class="margin">Margine</div>
                        <div class="margin-perc">%</div>
                    </div>
                    <div class="row">
                        <div class="assoluto">Qty</div>
                        <div class="totale_pratica">(Euro)</div>
                        <div class="cost">(Euro)</div>
                        <div class="margin">(Euro)</div>
                        <div class="margin-perc">%</div>
                    </div>
                    <div class="row totals">
                        <div class="num_participants" [matTooltip]="_totals['default_range']['total_participants']" >{{ _totals['default_range']['total_participants'] || 0 }}</div>
                        <div class="totale_pratica" [matTooltip]="_totals['default_range']['total_amount']">{{ _totals['default_range']['total_amount'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="cost" [matTooltip]="_totals['default_range']['total_supplier_price']">{{ _totals['default_range']['total_supplier_price'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="margin" [matTooltip]="_totals['default_range']['total_profit']">{{ _totals['default_range']['total_profit'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="margin-perc" [matTooltip]="_totals['default_range']['total_profit_percent']">{{ _totals['default_range']['total_profit_percent'] || 0 | percent:'1.2-2':'it-IT' }}</div>

                    </div>
                </div>
                <div class="column third">
                    <div class="row">
                        <ng-container *ngIf='filterFormGroup.get("comparison_type").value != "none"'>
                            <span>{{ fromDateComparison }} - {{ toDateComparison }}</span>
                        </ng-container>
                    </div>
                    <div class="row">
                        <div class="num_participants" matTooltip="{{'Numero Partecipanti' | translate}}">#</div>
                        <div class="totale_pratica">Totale</div>
                        <div class="cost">Costo</div>
                        <div class="margin">Margine</div>
                        <div class="margin-perc">%</div>
                    </div>
                    <div class="row">
                        <div class="assoluto">Qty</div>
                        <div class="totale_pratica">(Euro)</div>
                        <div class="cost">(Euro)</div>
                        <div class="margin">(Euro)</div>
                        <div class="margin-perc">%</div>
                    </div>
                    <div class="row totals">
                        <div class="num_participants">{{ _totals['comparison_range']['total_participants'] || 0 }}</div>
                        <div class="totale_pratica" [matTooltip]="_totals['comparison_range']['total_amount']" >{{ _totals['comparison_range']['total_amount'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="costo" [matTooltip]="_totals['comparison_range']['total_supplier_price']" >{{ _totals['comparison_range']['total_supplier_price'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="margine" [matTooltip]="_totals['comparison_range']['total_profit']" >{{ _totals['comparison_range']['total_profit'] || 0 | number:'1.2-2':'it-IT' }}</div>
                        <div class="margin-percent" [matTooltip]="_totals['comparison_range']['total_profit_percent']" >{{ _totals['comparison_range']['total_profit_percent'] || 0 | percent:'1.2-2':'it-IT' }}</div>
                    </div>
                </div>
                <div class="column fourth">
                    <div class="row">
                        <span>Confronto</span>
                    </div>
                    <div class="row ">
                        <div class="num_participants">Partecipanti</div>
                        <div class="totale_pratica">Totale</div>
                        <div class="totale_pratica">Costo</div>
                        <div class="totale_pratica">Margine</div>
                        <!--<div class="totale_pratica">%</div>-->
                    </div>
                    <div class="row">
                        <div class="subrow">
                            <div class="assoluto">Assoluto (Euro)</div>
                            <div class="percentuale">%</div>
                        </div>
                        <div class="subrow">
                            <div class="assoluto">Assoluto (Euro)</div>
                            <div class="percentuale">%</div>
                        </div>
                        <div class="subrow">
                            <div class="assoluto">Assoluto (Euro)</div>
                            <div class="percentuale">%</div>
                        </div>
                        <div class="subrow">
                            <div class="assoluto">Assoluto (Euro)</div>
                            <div class="percentuale">%</div>
                        </div>
                        <!--<div class="subrow">
                            <div class="percentuale">%</div>
                        </div>-->
                    </div>

                    <div class="row">
                        <div class="subrow totals">
                            <div class="assoluto">{{ (_totals['default_range']['total_participants'] || 0) - (_totals['comparison_range']['total_participants'] || 0) }}</div>
                            <div class="percentuale">{{ participantPercentage | number:'1.2-2':'it-IT' }}</div>
                        </div>
                        <div class="subrow totals">
                            <div class="assoluto">{{ (_totals['default_range']['total_amount'] || 0) - (_totals['comparison_range']['total_amount'] || 0) | number:'1.2-2':'it-IT' }}</div>
                            <div class="percentuale">{{ totalAmountPercentage | number:'1.2-2':'it-IT' }}</div>
                        </div>
                        <div class="subrow totals">
                            <div class="assoluto">{{ (_totals['default_range']['total_supplier_price'] || 0) - (_totals['comparison_range']['total_supplier_price'] || 0) | number:'1.2-2':'it-IT' }}</div>
                            <div class="percentuale">{{ totalCostPercentage | number:'1.2-2':'it-IT' }}</div>
                        </div>
                        <div class="subrow totals">
                            <div class="assoluto">{{ (_totals['default_range']['total_profit'] || 0) - (_totals['comparison_range']['total_profit'] || 0) | number:'1.2-2':'it-IT' }}</div>
                            <div class="percentuale">{{ totalMarginPercentage | number:'1.2-2':'it-IT' }}</div>
                        </div>
                        <!--<div class="subrow totals">
                            <div class="percentuale">{{ (_totals['default_range']['total_profit_percent'] || 0) - (_totals['comparison_range']['total_profit_percent'] || 0) | percent:'1.2-2':'it-IT' }}</div>
                        </div>-->
                    </div>
                </div>
            </div>

            <!-- body -->
            <ng-container
                    [ngTemplateOutlet]='groupsCostTemplate'
                    [ngTemplateOutletContext]='{
				dataset: report[selectedGroups[0].value]["dataset"]["items"],
				currentGroup: report[selectedGroups[0].value]["currentGroup"],
				nextGroup: report[selectedGroups[0].value]["nextGroup"],
				prevGroup: null}'
            ></ng-container>
        </ng-container>

        <!-- template-->
        <ng-template #groupsCostTemplate let-dataset='dataset' let-currentGroup='currentGroup' let-prevGroup='prevGroup' let-nextGroup='nextGroup'>
            <ng-container *ngIf='!dataset || dataset && !dataset.length'>
                <div class="no-data">Nessun risultato trovato</div>
            </ng-container>
            <!-- body -->
            <div [ngClass]='["body-cost", (prevGroup != null) ? "with_prev_group" : "", currentGroup]'>
                <ng-container *ngIf='(prevGroup == null)'>
                    <div class="group_divider" [class.is_first]='(prevGroup == null)'></div>
                </ng-container>
                <ng-container *ngFor='let item of dataset; let i = index; let first = first'>
                    <ng-container *ngIf='item'>
                        <ng-container *ngIf='nextGroup !== null && prevGroup == null'>
                            <mat-icon (click)='expand(i, item, nextGroup, currentGroup, prevGroup, 1)' [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
                                expand_more
                            </mat-icon>
                        </ng-container>
                        <!-- if is first group -->
                        <ng-container *ngIf='(prevGroup == null)'>
                            <div class="action">
                                <div class="_loadingNext" *ngIf='_loadingNext.value'></div>
                                <div class="column first">
                                    <div class="row">
                                        <div class="counter">{{ item['count'] }}</div>
                                        <div class="code">{{ item | biextradecode }}</div>
                                        <div class="descr" [matTooltip]='item | biextradecode:"description"'>
                                            <span class="item_description">{{ item | biextradecode:'description' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="column second">
                                    <div class="row">
                                        <div class="num_participants" [matTooltip]="item['total_participants']">{{ item['total_participants'] }}</div>
                                        <div class="totale_pratica" [matTooltip]="item['total_amount']| currency:'EUR'">{{ item['total_amount'] | currency:'EUR' }}</div>
                                        <div class="cost" [matTooltip]="item['total_supplier_price'] | currency:'EUR'">{{ item['total_supplier_price'] | currency:'EUR' }}</div>
                                        <div class="margin" [matTooltip]="item['total_profit'] | currency:'EUR'">{{ item['total_profit'] | currency:'EUR' }}</div>
                                        <div class="margin-perc" [matTooltip]="item['total_profit_percent'] | percent:'1.2-2':'it-IT'">{{ item['total_profit_percent'] | percent:'1.2-2':'it-IT' }}</div>
                                    </div>
                                </div>
                                <div class="column third">
                                    <div class="row">
                                        <div class="num_participants">
                                            <ng-container *ngIf='item["total_participants_comparison"]'>{{ item['total_participants_comparison'] }}</ng-container>
                                        </div>
                                        <div class="totale_pratica" [matTooltip]="item['total_amount_comparison']">{{ item['total_amount_comparison'] | currency:'EUR' }}</div>
                                        <div class="cost" [matTooltip]="item['total_supplier_price_comparison'] | currency:'EUR'">{{ item['total_supplier_price_comparison'] | currency:'EUR' }}</div>
                                        <div class="margin" [matTooltip]="item['total_profit_comparison'] | currency:'EUR'">{{ item['total_profit_comparison'] | currency:'EUR' }}</div>
                                        <div class="margin-perc" [matTooltip]="item['total_profit_percent_comparison'] | percent:'1.2-2':'it-IT'">{{ item['total_profit_percent_comparison'] | percent:'1.2-2':'it-IT' }}</div>
                                    </div>
                                </div>
                                <div class="column fourth">
                                    <div class="row">
                                        <div class="subrow">
                                            <div class="assoluto">{{ (item['total_participants'] - item['total_participants_comparison'] || 0) }}</div>
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_participants_comparison'] && item['total_participants_comparison'] > 0">
                                                    {{ item | bipercentage:'participants' | number:'1.2-2':'it-IT' }}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">{{ (item['total_amount'] - item['total_amount_comparison'] || 0) | currency:'EUR' }}</div>
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_amount_comparison'] > 0">
                                                    {{ item | bipercentage:'amount' | number:'1.2-2':'it-IT' }}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">{{ (item['total_supplier_price'] - item['total_supplier_price_comparison'] || 0) | currency:'EUR' }}</div>
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_supplier_price_comparison'] > 0">
                                                    {{ item | bipercentage:'supplier_price' | number:'1.2-2':'it-IT' }}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">{{ (item['total_profit'] - item['total_profit_comparison'] || 0) | currency:'EUR' }}</div>
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_profit_comparison'] > 0">
                                                    {{ item | bipercentage:'profit' | number:'1.2-2':'it-IT' }}
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!--<div class="subrow">
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_profit_perc_comparison'] > 0">
                                                    {{ item | bipercentage:'percent' | number:'1.2-2':'it-IT' }}                                                </ng-container>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf='(prevGroup != null)'>
                            <div class="row" [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
                                <div class="sub-header" *ngIf='first'>
                                    <div class="column first">
                                        <div class="counter"></div>
                                        <div class="code">Data</div>
                                        <div class="descr">Descrizione</div>
                                    </div>
                                    <div class="column second">
                                        <div class="num_participants" matTooltip="{{'Numero Partecipanti' | translate}}">#</div>
                                        <div class="totale_pratica">Totale</div>
                                        <div class="cost">Costo</div>
                                        <div class="margin">Margine</div>
                                        <div class="margin-perc">%</div>
                                    </div>
                                    <div class="column third">
                                        <div class="num_participants" matTooltip="{{'Numero Partecipanti' | translate}}">#</div>
                                        <div class="totale_pratica">Totale</div>
                                        <div class="cost">Costo</div>
                                        <div class="margin">Margine</div>
                                        <div class="margin-perc">%</div>
                                    </div>
                                    <div class="column fourth">
                                        <div class="subrow">
                                            <div class="assoluto">Assoluto</div>
                                            <div class="percentuale">%</div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">Assoluto</div>
                                            <div class="percentuale">%</div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">Assoluto</div>
                                            <div class="percentuale">%</div>
                                        </div>
                                        <div class="subrow">
                                            <div class="assoluto">Assoluto</div>
                                            <div class="percentuale">%</div>
                                        </div>
                                        <!--<div class="subrow">
                                            <div class="percentuale">%</div>
                                        </div>-->
                                    </div>
                                </div>
                                <div class="sub-data" [class.expanded]='expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
                                    <div class="_loadingNext" *ngIf='_loadingNext.value'></div>
                                    <ng-container *ngIf='nextGroup !== null'>
                                        <mat-icon (click)='expand(i, item, nextGroup, currentGroup, prevGroup)'>
                                            expand_more
                                        </mat-icon>
                                    </ng-container>
                                    <div class="column first">
                                        <div class="counter">{{ item['count'] }}</div>
                                        <div class="code">{{ item | biextradecode }}</div>
                                        <div class="descr" [matTooltip]='item | biextradecode:"description"'>
                                            <span class="item_description">{{ item | biextradecode:'description' }}</span>
                                        </div>
                                    </div>
                                    <div class="column second">
                                        <div class="num_participants" [matTooltip]="item['total_participants']" >{{ item['total_participants'] }}</div>
                                        <div class="totale_pratica" [matTooltip]="item['total_amount'] | currency:'EUR'">{{ item['total_amount'] | currency:'EUR' }}</div>
                                        <div class="cost" [matTooltip]="item['total_supplier_price'] | currency:'EUR'">{{ item['total_supplier_price'] | currency:'EUR' }}</div>
                                        <div class="margin" [matTooltip]="item['total_profit'] | currency:'EUR'">{{ item['total_profit'] | currency:'EUR' }}</div>
                                        <div class="margin-perc" [matTooltip]="item['total_profit_percent'] | percent:'1.2-2':'it-IT'">{{ item['total_profit_percent'] | percent:'1.2-2':'it-IT' }}</div>
                                    </div>
                                    <div class="column third">
                                        <div class="num_participants">
                                            <ng-container *ngIf='item["total_participants_comparison"]' [matTooltip]="item['total_participants_comparison']">
                                                {{ item['total_participants_comparison'] }}
                                            </ng-container>
                                        </div>
                                        <div class="totale_pratica" [matTooltip]="item['total_amount_comparison']">{{ item['total_amount_comparison'] | currency:'EUR' }}</div>
                                        <div class="cost" [matTooltip]="item['total_supplier_price_comparison']">{{ item['total_supplier_price_comparison'] | currency:'EUR' }}</div>
                                        <div class="margin" [matTooltip]="item['total_profit_comparison']">{{ item['total_profit_comparison'] | currency:'EUR' }}</div>
                                        <div class="margin-perc" [matTooltip]="item['total_profit_perc_comparison']">{{ item['total_profit_perc_comparison'] | percent:'1.2-2':'it-IT' }}</div>
                                    </div>
                                    <div class="column fourth">
                                            <div class="subrow">
                                                <div class="assoluto">{{ (item['total_participants'] - item['total_participants_comparison'] || 0) }}</div>
                                                <div class="percentuale">
                                                    <ng-container *ngIf="item['total_participants_comparison'] && item['total_participants_comparison'] > 0">
                                                        {{ item | bipercentage:'participants' | number:'1.2-2':'it-IT' }}
                                                    </ng-container>
                                                </div>
                                            </div>
                                        <div class="subrow">
                                            <div class="assoluto">{{ (item['total_amount'] - item['total_amount_comparison'] || 0) }}</div>
                                            <div class="percentuale">
                                                <ng-container *ngIf="item['total_amount_comparison'] && item['total_amount_comparison'] > 0">
                                                    {{ item | bipercentage:'amount' | number:'1.2-2':'it-IT' }}
                                                </ng-container>
                                            </div>
                                        </div>
                                            <div class="subrow">
                                                <div class="assoluto">{{ (item['total_supplier_price'] - item['total_supplier_price_comparison'] || 0) | currency:'EUR' }}</div>
                                                <div class="percentuale">
                                                    <ng-container *ngIf="item['total_supplier_price_comparison'] && item['total_supplier_price_comparison'] > 0">
                                                        {{ item | bipercentage:'supplier_price' | number:'1.2-2':'it-IT' }}
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="subrow">
                                                <div class="assoluto">{{ (item['total_profit'] - item['total_profit_comparison'] || 0) | currency:'EUR' }}</div>
                                                <div class="percentuale">
                                                    <ng-container *ngIf="item['total_profit_comparison'] && item['total_profit_comparison'] > 0">
                                                        {{ item | bipercentage:'profit' | number:'1.2-2':'it-IT' }}
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <!--<div class="subrow">
                                                <div class="percentuale">
                                                    <ng-container *ngIf="item['total_marigin_perc_comparison'] && item['total_profit_perc_comparison'] > 0">
                                                        {{ item | bipercentage:'percent' | number:'1.2-2':'it-IT' }}                                                    </ng-container>
                                                </div>
                                            </div>-->
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- if next group -->
                    <ng-container *ngIf='report && report[nextGroup] && report[nextGroup]["dataset"] && report[nextGroup]["dataset"][item["record_id"]] && item["record_id"] && expandedRecordGroups[currentGroup] && expandedRecordGroups[currentGroup][i] && expandedRecordGroups[currentGroup][i][item["record_id"]]'>
                        <ng-container
                                [ngTemplateOutlet]='groupsCostTemplate'
                                [ngTemplateOutletContext]='{
							dataset: report[nextGroup]["dataset"][item["record_id"]],
							currentGroup: report[nextGroup]["currentGroup"],
							nextGroup: report[nextGroup]["nextGroup"],
							prevGroup: report[nextGroup]["prevGroup"]}'
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-template>
        <!-- END width cost-->



		<!-- paginator -->
		<ng-container *ngIf='!_loading.value && selectedGroups.length > 0 && report && report[selectedGroups[0].value] && report[selectedGroups[0].value]["dataset"] && report[selectedGroups[0].value]["dataset"]["meta"] && report[selectedGroups[0].value]["dataset"]["meta"]["total"]'>
			<mat-paginator
				[pageSizeOptions]='[5, 10, 20, 50, 100, 200]'
				showFirstLastButtons
				[length]='report[selectedGroups[0].value]["dataset"]["meta"]["total"]'
				[pageSize]='report[selectedGroups[0].value]["dataset"]["meta"]["perPage"]'
				[pageIndex]='_pageIndex'
				(page)='onPageChanged($event)'
			></mat-paginator>
		</ng-container>
	</div>
</div>
