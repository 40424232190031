import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
	selector   : 'language-select',
	templateUrl: './language-select.component.html',
	styleUrls  : ['./language-select.component.scss'],
	animations : fuseAnimations
})
export class LanguageSelectComponent implements OnInit{

	@Input() languageCodes: string[] = [];
	@Input() activeLanguageCodes: string[] = [];
	@Input() label = 'Lingua:';
	@Output() changed = new EventEmitter<string>();
	@Input() selected: string;

	constructor(
		public appService: AppService
	){}

	ngOnInit(): void{

	}

	onClick(lang): void{
		this.selected = lang;
		this.changed.emit(lang);
	}

}