import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, InputTypes, ValueTypes, IFieldDefinition, FieldViews, IDatasetCallDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import generalForm from './forms/general.form';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/availability/detail/:recordId'
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	const optionId = dataContainerService.getValueFromKeyPath('trip_options_for_virtual_trips.recordId');
	if(optionId){
		params['option_id'] = optionId;
	}else{
		console.warn('no option found', dataContainerService);
	}
	return params;
}

export const AvailabilitiesForVirtualTripsConfig: IDatasetConfig = {
	name: 'avilabilities_for_virtual_trips',
	title: 'Disponibilità',
	singleTitle: 'Disponibilità',
	ajaxDatasetCode: 'availability',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		delete: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		list: {
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Descrizione',
						key: 'description',
					},
					{
						title: 'Dal/al',
						key: 'valid_column',
					},
					{
						title: 'Dalle/alle',
						key: 'time_column',
					}
				],
				actions: ['create', 'edit', 'delete']
			},
		},
		edit: {
			getCallParams,
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		create: {
			getCallParams,
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
	}
};
