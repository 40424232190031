import { Component, Inject, ViewChild, forwardRef, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import formConfig from './form.config';

export interface IinjectedData{
	title?: string;
	selectedDomain?: any;
}

@Component({
	selector: 'select-domain-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService), 
		forwardRef(() => DatasetActionContainerService),
	]
})

export class SelectDomainDialogComponent implements AfterViewInit{

	public loading: boolean;
	public formConfig = formConfig;

	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;

	constructor(
		@Inject(MAT_DIALOG_DATA) public injectedData: IinjectedData, 
		protected dialogRef: MatDialogRef<SelectDomainDialogComponent>,
		@Inject(forwardRef(() => PageClosureContainerService)) public pageClosureContainerPage: PageClosureContainerService,
		@Inject(forwardRef(() => DatasetActionContainerService)) public datasetACS: DatasetActionContainerService,
	){}

	onConfirmClick(): void {
		this.loading = true;
		this.formViewer.formGroup.disable();
		const domainForm = this.formViewer.getFormData();

		if (domainForm.domain_id === null) {
			this.loading = false;
			this.formViewer.formGroup.enable();
			return;
		}

		this.dialogRef.close(domainForm.domain_id);
	}

	close(): void {
		this.dialogRef.close(false);
	}

	ngAfterViewInit(): void{
		if (this.injectedData && this.injectedData.selectedDomain){
			this.formViewer.formGroup.get('domain_id').setValue(this.injectedData.selectedDomain);
		}
	}
}
