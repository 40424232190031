/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./trip-booking-package.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./trip-package/trip-package-booking.component.ngfactory";
import * as i3 from "./trip-package/trip-package-booking.component";
import * as i4 from "../../../../../services/app.service";
import * as i5 from "../../../../components/dataset/services/dataset-record.service";
import * as i6 from "../../../../components/dataset/services/dataset-action-container.service";
import * as i7 from "../../../../components/dataset/services/dataset-navigator.service";
import * as i8 from "../../../../components/dataset/services/dataset.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../../../services/domain-filter.service";
import * as i11 from "./trip-booking-package.service";
import * as i12 from "../trip-booking-packages-step.service";
import * as i13 from "../../booking.service";
import * as i14 from "@angular/common";
import * as i15 from "./product/product-booking.component.ngfactory";
import * as i16 from "./product/product-booking.component";
import * as i17 from "./trenitalia/trenitalia-booking.component.ngfactory";
import * as i18 from "./trenitalia/trenitalia-booking.component";
import * as i19 from "./ticket/ticket-booking.component.ngfactory";
import * as i20 from "../../step-2/view.service";
import * as i21 from "@angular/common/http";
import * as i22 from "./ticket/ticket-booking.component";
import * as i23 from "../../../page-closure-container.service";
import * as i24 from "../../../../../services/auth.service";
import * as i25 from "@ngx-translate/core";
import * as i26 from "./trip-booking-package.component";
var styles_TripBookingPackageComponent = [i0.styles];
var RenderType_TripBookingPackageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripBookingPackageComponent, data: {} });
export { RenderType_TripBookingPackageComponent as RenderType_TripBookingPackageComponent };
function View_TripBookingPackageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "trip-package-booking", [], null, null, null, i2.View_TripPackageBookingComponent_0, i2.RenderType_TripPackageBookingComponent)), i1.ɵdid(2, 245760, null, 0, i3.TripPackageBookingComponent, [i4.AppService, i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i8.DatasetService, i9.MatDialog, i10.DomainFilterService, i11.TripBookingPackageService, i12.TripBookingPackagesStepService, i13.BookingService, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_TripBookingPackageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_2)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tripBookingPackage.target == "trip_packages") || !_co.tripBookingPackage.target); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TripBookingPackageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "product-booking", [], null, null, null, i15.View_ProductBookingComponent_0, i15.RenderType_ProductBookingComponent)), i1.ɵdid(2, 245760, null, 0, i16.ProductBookingComponent, [i4.AppService, i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i8.DatasetService, i9.MatDialog, i10.DomainFilterService, i12.TripBookingPackagesStepService, i11.TripBookingPackageService, i13.BookingService, i1.Injector, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_TripBookingPackageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "trenitalia-booking", [], null, null, null, i17.View_TrenitaliaBookingComponent_0, i17.RenderType_TrenitaliaBookingComponent)), i1.ɵdid(1, 245760, null, 0, i18.TrenitaliaBookingComponent, [i4.AppService, i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i8.DatasetService, i9.MatDialog, i10.DomainFilterService, i13.BookingService, i1.Injector, i12.TripBookingPackagesStepService, i1.ViewContainerRef, i11.TripBookingPackageService], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_TripBookingPackageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_5)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.tripBookingPackage.parent_trip_booking_package_relation_type; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TripBookingPackageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 3, "ticket-booking", [], null, null, null, i19.View_TicketBookingComponent_0, i19.RenderType_TicketBookingComponent)), i1.ɵprd(512, null, i20.BookingStep2Service, i20.BookingStep2Service, [i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i21.HttpClient, i4.AppService, i8.DatasetService, i9.MatDialog]), i1.ɵdid(3, 245760, null, 0, i22.TicketBookingComponent, [i4.AppService, i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i8.DatasetService, i9.MatDialog, i10.DomainFilterService, i20.BookingStep2Service, i11.TripBookingPackageService, i13.BookingService, i1.Injector, i1.ViewContainerRef], { iterationData: [0, "iterationData"] }, null), i1.ɵpod(4, { is_first: 0, is_last: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.first, _co.last); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TripBookingPackageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_3)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_4)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripBookingPackageComponent_6)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tripBookingPackage && _co.tripBookingPackage.trip_package); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.tripBookingPackage.target == "product"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.tripBookingPackage.target == "trenitalia"); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.tripBookingPackage && (_co.tripBookingPackage.target == "milano_card")) || (_co.tripBookingPackage.target == "milano_card_offline")); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_TripBookingPackageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "trip-booking-package", [], null, null, null, View_TripBookingPackageComponent_0, RenderType_TripBookingPackageComponent)), i1.ɵprd(512, null, i6.DatasetActionContainerService, i6.DatasetActionContainerService, [i4.AppService, i23.PageClosureContainerService, i24.AuthService, i8.DatasetService, i10.DomainFilterService, i25.TranslateService, i1.Injector]), i1.ɵprd(512, null, i5.DatasetRecordService, i5.DatasetRecordService, [i6.DatasetActionContainerService, i8.DatasetService]), i1.ɵprd(512, null, i11.TripBookingPackageService, i11.TripBookingPackageService, [i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i21.HttpClient, i4.AppService, i8.DatasetService, i9.MatDialog, i13.BookingService]), i1.ɵdid(4, 770048, null, 0, i26.TripBookingPackageComponent, [i4.AppService, [4, i5.DatasetRecordService], [4, i6.DatasetActionContainerService], [1, i5.DatasetRecordService], [1, i6.DatasetActionContainerService], i7.DatasetNavigatorService, i8.DatasetService, i9.MatDialog, i10.DomainFilterService, i12.TripBookingPackagesStepService, i13.BookingService, i11.TripBookingPackageService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var TripBookingPackageComponentNgFactory = i1.ɵccf("trip-booking-package", i26.TripBookingPackageComponent, View_TripBookingPackageComponent_Host_0, { tripBookingPackage: "tripBookingPackage", iterationData: "iterationData" }, {}, []);
export { TripBookingPackageComponentNgFactory as TripBookingPackageComponentNgFactory };
