import { Component, Input, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { IListViewConfig } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { fieldsMap } from 'app/configs/datasets/availabilities/fields';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { CdkStepper } from '@angular/cdk/stepper';
import { BehaviorSubject } from 'rxjs';
import { AvailabilitiesConfig } from 'app/configs/datasets/availabilities';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { HttpHeaders } from '@angular/common/http';

export interface Day{
	code: string;
	day: string;
}

@Component({
	selector   : 'availability-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [DatasetActionContainerService, DatasetRecordService]
})

export class AvailabilityStepComponent extends BaseStepViewComponent implements OnInit{
	DAYS: Day[] = [
		{code: 'monday', day: 'Monday'}, 
		{code: 'tuesday', day: 'Tuesday'},
		{code: 'wednesday', day: 'Wednesday'},
		{code: 'thursday', day: 'Thursday'},
		{code: 'friday', day: 'Friday'},
		{code: 'saturday', day: 'Saturday'},
		{code: 'sunday', day: 'Sunday'}
	];

	showForm = false;
	showCreatedAlert = false;

	scenario: BehaviorSubject<any>;

	avilabilitiesViewConfig: IListViewConfig;

	public formGroup: FormGroup;

	@Input() public parentDatasetACS: DatasetActionContainerService;
	@Input() public parentDatasetRS: DatasetRecordService;

	public fileterFields = fieldsMap;
	protected formErrors: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		public stepService: StepService,
		protected formBuilder: FormBuilder,
	) {
		super(datasetACS, datasetNavigatorService);
		this.scenario = new BehaviorSubject<any>('go-to-dataset');
	}

	ngOnInit(): void {
		this.avilabilitiesViewConfig = AvailabilitiesConfig.defaultActions.list.viewConfig;
		this.initFormGroup();

		this.datasetACS.init({
			datasetCode: 'availability',
			actionCode: 'list',
			datasetRS: this.datasetRS,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: false
		}).subscribe(ready => {
			if (!ready) return;
			this.stepService.reloadOption()
			.subscribe((record) => {
				if (record && record.availability){
					this.onSelected(record.availability);
				} else this.showForm = true;
			});
		});
	}

	resetForm(scenario: any): void{
		this.scenario.next(scenario);
		this.showForm = true;
		this.datasetRS.recordId = null;
		this.datasetRS.record.next(null);
		this.stepService.selectedAvailability.next(null);
		this.initFormGroup();
	}
	
	initFormGroup(): void{
		this.formGroup = this.formBuilder.group({
			description: new FormControl('', Validators.required),
			valid_from: new FormControl(''),
			valid_to: new FormControl(''),
			selected_option_id: new FormControl(this.stepService.selectedOptionId, Validators.required),
			max_allotment: new FormControl(''),
			monday: this.formBuilder.array([]),
			tuesday: this.formBuilder.array([]),
			wednesday: this.formBuilder.array([]),
			thursday: this.formBuilder.array([]),
			friday: this.formBuilder.array([]),
			saturday: this.formBuilder.array([]),
			sunday: this.formBuilder.array([]),
			spec: this.formBuilder.array([])
		});

		this.formGroup.get('selected_option_id').valueChanges
		.subscribe((newVal) => {
			if (!newVal || newVal && newVal == this.stepService.selectedOptionId) return;
			/** else load */
			this.showForm = false;
			this.stepService.selectedOptionId = newVal;
			this.stepService.reloadOption()
			.subscribe((record) => {
				if (record && record.availability){
					this.onSelected(record.availability)
				} else this.resetForm('');
			});
		});
	}

	onSelected(selectedRecord: any): void{
		this.scenario.next('edit');
		this.datasetRS.recordId = selectedRecord.id;
		this.stepService.selectedAvailability.next(selectedRecord);

		this.showForm = false;
		this.datasetACS.loading.next(true);
		this.datasetRS.reload()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (record) => {
				this.formGroup.get('description').setValue(record.description);
				this.formGroup.get('valid_from').setValue(record.valid_from);
				this.formGroup.get('valid_to').setValue(record.valid_to);
				this.formGroup.get('max_allotment').setValue(record.max_allotment);

				const days = Object.keys(record.days);

				days.forEach((value) => {
					const dayTimes = record.days[value];
					const dayForm: FormArray = this.days(value);

					for (const dayTime in dayTimes){
						if(!Object.prototype.hasOwnProperty.call(dayTimes, dayTime)) continue;
						const timeObj = dayTimes[dayTime];
						const startingTimeFormGroup: FormGroup = this.formBuilder.group({
							starting_time: new FormControl(timeObj.starting_time, Validators.required)
						});

						dayForm.push(startingTimeFormGroup);
					}
				});

				for (const specialDate in record.special_date_times){
					if(!Object.prototype.hasOwnProperty.call(record.special_date_times, specialDate)) continue;
					const formArray: FormArray = <FormArray>this.formGroup.get('spec');
					const formGroupArray: FormGroup[] = [];
					const specialDateTime = record.special_date_times[specialDate];

					for (const startingTime in specialDateTime.special_date_starting_time){
						if(!Object.prototype.hasOwnProperty.call(specialDateTime.special_date_starting_time, startingTime)) continue;
						const time = specialDateTime.special_date_starting_time[startingTime];

						let starting_time: any = null;
						if (time.starting_time) starting_time = time.starting_time;

						const fgItem: FormGroup = this.formBuilder.group({
							starting_time: new FormControl(starting_time, Validators.required)
						});

						formGroupArray.push(fgItem);
					}
	
					formArray.push(this.formBuilder.group({
						special_date: new FormControl(specialDateTime.special_date, Validators.required),
						special_date_starting_time: this.formBuilder.array(formGroupArray),
					}));
				}

				this.showForm = true;
				this.datasetACS.loading.next(false);
			},
			error: (response) => {
				console.error(response);
				this.datasetACS.loading.next(false);
				this.showForm = true;
			}
		});
	}

	addSpecialDate(): void{
		const specialDates: FormArray = <FormArray>this.formGroup.get('spec');

		const specialDateFormGroup: FormGroup = this.formBuilder.group({
			special_date: new FormControl('', Validators.required),
			special_date_starting_time: this.formBuilder.array([
				this.formBuilder.group({
					starting_time: new FormControl('', Validators.required)
				})
			]),
		});
		specialDates.push(specialDateFormGroup);
	}

	addSpecialDateStartingTime(formArray: FormArray): void{
		const specialTimeFormGroup: FormGroup = this.formBuilder.group({starting_time: new FormControl('', Validators.required)});
		formArray.push(specialTimeFormGroup);
	}

	removeSpecialTime(formArray: FormArray, i: number): void{
		formArray.removeAt(i);
	}

	removeBlockSpecialDates(i: number): void{
		const specialDates: FormArray = <FormArray>this.formGroup.get('spec');
		specialDates.removeAt(i);
	}

	addStartingTime(day: string): void {
		const dayForm: FormArray = this.days(day);
		const startingTimeFormGroup: FormGroup = this.formBuilder.group({starting_time: new FormControl('', Validators.required)});
		dayForm.push(startingTimeFormGroup);
	}

	copyToWholeWeek(day: string): void{
		const currentDayForm: FormArray = this.days(day);

		this.DAYS.forEach((value) => {
			let cleared = false;
			if (day === value.code) return;
			const dayForm: FormArray = this.days(value.code);

			if (!dayForm.length) cleared = true;

			try {
				while (dayForm.length) {
					dayForm.removeAt(dayForm.length - 1);
					cleared = true;
				}
			} catch (Error) {
				cleared = false;
			}

			if (cleared) {
				currentDayForm.controls.forEach((formGroup: FormGroup) => {
					dayForm.push(this.formBuilder.group({starting_time: formGroup.controls.starting_time.value}));
				});
			}
		});
	}

	removeAt(day: string, index: number): void{
		this.days(day).removeAt(index);
	}

	days(day: string): FormArray {
		return <FormArray>this.formGroup.get(day);
	}

	delete(): void{
		this.scrollToTop();
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.stepService.selectedAvailability.value);
		this.datasetACS.datasetEvent.subscribe((event) => {
			if (event.eventName !== DatasetEvents.DELETED) return;
			this.stepService.selectedAvailability.next(null);
			this.scenario.next('go-to-dataset');
			this.initFormGroup();
			
			this.appService.appEvent.subscribe(appEvent => {
				if (appEvent.name !== 'total_dataset_items') return;
				if (appEvent.extra.paginationResponse.meta.total) return;

				this.stepService.setStepState({
					dataset_code: 'trip_packages',
					form_code: 'avilabilities',
					record_id: this.parentDatasetRS.recordId,
					status: 0
				});
			});
		});
	}
	
	onSave(): void{
		this.datasetACS.loading.next(true);
		
		this.formGroup.disable();

		const formData = this.formGroup.getRawValue();

		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/create';
		if(this.datasetRS.record.value){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}else formData['option_id'] = this.stepService.selectedOptionId;

		let headers = new HttpHeaders({});
		if(this.datasetACS.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
		}
		this.datasetService.post(route, formData, {headers})
		.subscribe({
			next: (record) => {
				this.appService.showSuccessMessage('operazione effettuata con successo');
				this.scrollToTop();

				if(this.formGroup){
					this.formGroup.enable();
				}
				if (record){
					this.scenario.next('go-to-dataset');
					this.datasetRS.record.next(record);
					this.datasetACS.loading.next(false);
					//this.initFormGroup();
					this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				}
				this.stepService.setStepState({
					dataset_code: 'trip_packages',
					form_code: 'avilabilities',
					record_id: this.parentDatasetRS.recordId,
					status: 1
				});
			},
			error: response => {
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				
				if(this.formGroup){
					this.formGroup.enable();
					for (const error in this.formErrors){
						if(!Object.prototype.hasOwnProperty.call(this.formErrors, error)) continue;
						const fieldError = this.formErrors[error];
						const formControl: FormControl = <FormControl>this.formGroup.get(error);
						formControl.setErrors(fieldError[0]);
						formControl.markAsTouched();
					}
				}
				this.datasetACS.loading.next(false);
			}
		});
	}
}
