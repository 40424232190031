import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { BaseChartDirective } from 'ng2-charts';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { makeDrawValuePlugin } from '../chartjs.plugins';
var ReviewsYStatComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ReviewsYStatComponent, _super);
    function ReviewsYStatComponent(dashboardService) {
        var _this = _super.call(this) || this;
        _this.dashboardService = dashboardService;
        /**
         * ReviewsYStat
        */
        _this.barChartOptions = {
            responsive: true,
            scales: {
                xAxes: [{}],
                yAxes: [
                    {
                        id: 'total',
                        type: 'linear',
                        position: 'left',
                        gridLines: {
                            display: false,
                        }
                    },
                    {
                        id: 'avg',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            max: 5,
                            min: 0
                        },
                        gridLines: {
                            display: false,
                        }
                    }
                ]
            },
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true
                }
            },
            tooltips: {
                cornerRadius: 8,
                xPadding: 8,
                yPadding: 6,
                caretSize: 0,
                caretPadding: 10,
                backgroundColor: '#b5b5b5',
                custom: function (tooltipModel) {
                    if (!tooltipModel)
                        return;
                    // disable displaying the color box;
                    tooltipModel.displayColors = false;
                },
                callbacks: {
                    label: function (tooltipItem, data) {
                        var n = Number(tooltipItem.yLabel);
                        if (!n || isNaN(n))
                            return '';
                        return n.toLocaleString('US');
                    },
                    title: function (item, data) {
                        return;
                    },
                },
            }
        };
        _this.chartPlugins = [makeDrawValuePlugin()];
        _this.barChartType = 'bar';
        _this.barChartLegend = false;
        return _this;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ReviewsYStatComponent.prototype.ngOnInit = function () {
        this.reviewsystat = [{ data: [] }];
    };
    ReviewsYStatComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dashboardService.reviewResponse
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.onData(response);
            }
        });
    };
    ReviewsYStatComponent.prototype.onData = function (response) {
        var e_1, _a, e_2, _b;
        this.showLoader = true;
        if (!response) {
            this.reviewsystat = [{ data: [] }];
            return;
        }
        var chartArea = this.chartComponent.chart.chartArea;
        var gradient1 = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom / 1.7, 0, chartArea.bottom);
        gradient1.addColorStop(0, '#ff6384');
        gradient1.addColorStop(1, 'white');
        var gradient2 = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom / 1.7, 0, chartArea.bottom);
        gradient2.addColorStop(0, '#4bc0c0');
        gradient2.addColorStop(1, 'white');
        var data = [
            {
                data: [],
                label: 'Negative Point',
                yAxisID: 'total',
                type: 'bar',
                backgroundColor: gradient1,
                hoverBackgroundColor: '#ff6384'
            },
            /*{
                data: [],
                label: 'Average Rating',
                lineTension: 0,
                yAxisID: 'avg',
                type: 'line',
                fill: false
            },*/
            {
                data: [],
                label: 'Total Reviews',
                yAxisID: 'total',
                type: 'bar',
                backgroundColor: gradient2,
                hoverBackgroundColor: '#4bc0c0'
            }
        ];
        if (response.length > 0) {
            this.showLoader = false;
            var years = [];
            try {
                for (var response_1 = tslib_1.__values(response), response_1_1 = response_1.next(); !response_1_1.done; response_1_1 = response_1.next()) {
                    var item = response_1_1.value;
                    if (years.includes(item.year))
                        continue;
                    years.push(item.year);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (response_1_1 && !response_1_1.done && (_a = response_1.return)) _a.call(response_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            years.sort();
            this.barChartLabels = years.map(function (el) {
                return String(el);
            });
            var yearRecordCount = [];
            try {
                for (var response_2 = tslib_1.__values(response), response_2_1 = response_2.next(); !response_2_1.done; response_2_1 = response_2.next()) {
                    var item = response_2_1.value;
                    var yearIndex = years.indexOf(item.year);
                    if (isNaN(Number(data[0].data[yearIndex]))) {
                        data[0].data[yearIndex] = 0;
                        //data[1].data[yearIndex] = 0;
                        data[1].data[yearIndex] = 0;
                        yearRecordCount[yearIndex] = 0;
                    }
                    //data[1].data[yearIndex] = Number(item.avg_points) + Number(data[1].data[yearIndex]);
                    data[0].data[yearIndex] += item.negative_points;
                    data[1].data[yearIndex] += item.total;
                    yearRecordCount[yearIndex]++;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (response_2_1 && !response_2_1.done && (_b = response_2.return)) _b.call(response_2);
                }
                finally { if (e_2) throw e_2.error; }
            }
            /*for (let index = 0; index < years.length; index++) {
                const avg = Number(data[1].data[index]);
                data[1].data[index] =  Math.round((avg / yearRecordCount[index]) * 100) / 100;
            }*/
        }
        this.reviewsystat = data;
    };
    return ReviewsYStatComponent;
}(DestroyableComponent));
export { ReviewsYStatComponent };
