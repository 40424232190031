import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { FormViewerService } from "app/services/form-viewer.service";
import { fieldsMap as filterFields } from './filter.fields';
import { DatasetListComponent } from "app/main/components/dataset/base-actions/list/dataset-list.component";
import { convertToUTCDay } from "app/helpers/date.helper";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { IListViewConfig } from "app/interfaces";
import { TripBookingPackagesStepService } from "../../trip-booking-packages-step.service";
import { takeUntil } from "rxjs/operators";
import { ActionDialogComponent } from "../../../action-dialog/action-dialog.component";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { actualTripsListViewConfig } from "./actual-trips-list-view-config";
import { DomainFilterService } from "app/services/domain-filter.service";
import * as _ from "lodash";
var PackageTripSelectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PackageTripSelectionComponent, _super);
    function PackageTripSelectionComponent(formViewerService, datasetRS, datasetACS, tripBookingPackagesStepService, datasetNavigator, domainFilterService) {
        var _this = _super.call(this) || this;
        _this.formViewerService = formViewerService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.datasetNavigator = datasetNavigator;
        _this.domainFilterService = domainFilterService;
        _this.filterFields = filterFields;
        _this.filters = {
            start_day: JSON.stringify(['>=', convertToUTCDay().toISOString()]),
            only_bookable: 1
        };
        _this.showList = false;
        return _this;
    }
    Object.defineProperty(PackageTripSelectionComponent.prototype, "actualTripsListViewConfig", {
        get: function () {
            return actualTripsListViewConfig;
        },
        enumerable: true,
        configurable: true
    });
    PackageTripSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formViewerService.init({
            fields: Array.from(filterFields.values())
        });
        this.showList = this.domainFilterService.getDomainSetting('current', 'backoffice.booking.prevent_autoload_trips') != true;
        this.formViewerService.formGroup.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (filterFields) {
            var startDay = filterFields.start_day;
            if (typeof (startDay) == 'object' && typeof (startDay.toJSON) == 'function')
                startDay = startDay.toJSON();
            if (!startDay)
                startDay = convertToUTCDay().toJSON();
            if (_this.changeStartDayFilter) {
                _this.formViewerService.setValues({ start_day: _this.changeStartDayFilter }, { setValueOptions: { emitEvent: false } });
                startDay = _this.changeStartDayFilter;
            }
            _this.changeStartDayFilter = null;
            var newFilters = Object.assign({}, _this.filters, {
                start_from: startDay,
                trip_package_id: filterFields.trip_package_id ? filterFields.trip_package_id : null
            });
            if (!newFilters.trip_package_id)
                delete newFilters.trip_package_id;
            _this.filters = newFilters;
            if (JSON.stringify(_this.filters) == JSON.stringify(newFilters))
                _this.showList = true;
        });
    };
    PackageTripSelectionComponent.prototype.addTripBookingPackage = function (actualTrip) {
        var _this = this;
        var dialogRef = this.datasetNavigator.openDialog(ActionDialogComponent, {
            title: 'Attendere',
            contentMessage: ''
        });
        var postData = {
            trip_booking_id: this.datasetRS.recordId,
            actual_trip_id: actualTrip.id,
        };
        if (actualTrip.is_virtual) {
            delete postData.actual_trip_id;
            postData['trip_package_id'] = actualTrip.trip_package_id;
            _.set(postData, 'availability', {
                meeting_point_list_id: actualTrip.meeting_point_list_id,
                start_day: actualTrip.start_day,
                start_time: actualTrip.start_time
            });
        }
        var actionEndPoint = '/dataset/trip_booking_packages/create';
        this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
            .subscribe({
            next: function () {
                _this.datasetRS.reload()
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe(function () {
                    dialogRef.close();
                });
            },
            error: function (errorResult) {
                dialogRef.componentInstance.resultStatus = 'error';
                dialogRef.componentInstance.loading = false;
                dialogRef.componentInstance.setMessage('Si è verificato un errore');
                dialogRef.componentInstance.setTitle(null);
            },
            complete: function () {
                dialogRef.close();
            }
        });
    };
    PackageTripSelectionComponent.prototype.onTripPackageIdInputCustomEvent = function (event) {
        if (_.get(event, 'code') == 'selectionFilterChanged') {
            var dateFilterRaw = _.get(event, 'data.departure_date_filter');
            if (dateFilterRaw) {
                var dateFilter = JSON.parse(dateFilterRaw);
                if (Array.isArray(dateFilter) && dateFilter.length == 2) {
                    this.changeStartDayFilter = dateFilter[1].substring(0, 10);
                }
                else {
                    this.changeStartDayFilter = null;
                }
            }
        }
    };
    return PackageTripSelectionComponent;
}(DestroyableComponent));
export { PackageTripSelectionComponent };
