import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('read_code'),
	fieldsMap.get('read_duration'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('audio_guide_languages'),
			fieldsMap.get('languages')
		]
	},
	fieldsMap.get('meeting_point_id'),
	fieldsMap.get('read_note'),
];

const config: IFormConfig = { 
	title: 'Crea Opzione',
	editTitle: 'Modifica Opzione',
	fields: formFields 
};

export default config;