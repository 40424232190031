import { Component, OnInit, Input, Inject } from '@angular/core';
import { IListViewConfig, IExportAction, IListFilterParam, IFormConfig, ValueTypes, InputTypes, ListViewExportTypes } from 'app/interfaces';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { Validators } from '@angular/forms';

@Component({
	selector: 'export-data-tool',
	templateUrl: './export-data-tool.component.html',
	styleUrls: ['./export-data-tool.component.scss']
})
export class ExportDataToolComponent {

	@Input() public viewConfig: IListViewConfig;
	@Input() public datasetACS: DatasetActionContainerService;

	constructor(
		@Inject('ListParamService') public listFilterParam: IListFilterParam,
		private http: HttpClient,
		private appService: AppService,
		private matDialog: MatDialog
	){}

	getPartialUrl(typeFile: any): string {
		return getBaseServerAddressRoot() + '/ajax/datasets/' + this.datasetACS.datasetCode + '/export/' + typeFile;
	}

	getUrl(typeFile: any, withQueryParams: boolean): string {
		let url = this.getPartialUrl(typeFile);

		if(withQueryParams){
			const params = this.listFilterParam.getParams();
			let httpParams = new HttpParams();
			for (const key in params) {
				if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
				const element = params[key];

				httpParams = httpParams.set(key, element);
			}
			url += '?' + httpParams.toString();
		}

		return url;
	}

	onClick(exportAction: IExportAction): void{
		const url = this.getUrl(exportAction.typeFile, true);
		console.log('onClick url', url);
		// window.open(url);
	}

	openFile(exportAction: IExportAction): void{
		const newWindow = window.open(); // OPEN WINDOW FIRST ON SUBMIT THEN POPULATE PDF
		const url = this.getUrl(exportAction.typeFile, false);
		this.http.get(url, {
			params: this.listFilterParam.getParams(),
			responseType: 'blob' 
		}).subscribe((blob: Blob) => {
			const urlObject = URL.createObjectURL(blob);
			newWindow.location.href = urlObject; // POPULATING window 
		});
	}

	downloadFile(exportAction: IExportAction): void{
		if(exportAction.typeFile == ListViewExportTypes.CSV_VIA_EMAIL){
			this.onSendFile();
			return;
		}
		const loadingDialog = this.matDialog.open(LoadingDialogComponent, {
			minWidth: 400,
			data: {
				title: 'Generazione file in corso'
			}
		});
		const url = this.getUrl(exportAction.typeFile, false);
		this.appService.downloadUrl(url, this.listFilterParam.getParams())
		.subscribe({
			next: () => {
				loadingDialog.close();
			},
			error: () => {
				loadingDialog.close();
			}
		});
	}

	onSendFile(): void{
		const formConfig: IFormConfig = {
			formStyle: '',
			fields: [
				{
					key: 'send_mail',
					name: 'Invia mail a',
					valueType: ValueTypes.STRING,
					formValidators: [ Validators.required, Validators.email ],
					inputConfig: {
						appearence: 'legacy',
						type: InputTypes.EMAIL,
						clearable: true,
						placeholder: 'example@mail.com',
						required: true
					}
				}
			]
		};
		this.matDialog.open(FormDialogComponent, {
			data:{
				title: "Invio file CSV",
				message: "Ti verrà inviato un'email con il link per scaricare il file quando sarà pronto. Vuoi procedere?",
				formConfig,
				positiveText: 'Invia'
			}
		})
			.afterClosed()
			.subscribe(result => {
				if (!result) return;
				const dialogRef = this.matDialog.open(ActionDialogComponent, {
					width: '550px',
					data: {
						title: 'Avvio operazione in corso'
					}
				});
				this.http.post(this.appService.getBaseServerAddress() + '/dataset/trip_booking_report_views/command/send_export_file', {
					email: result.send_mail,
					domain_id: this.datasetACS.domainFilterService.filterDomainId.value,
					params: this.listFilterParam.getParams()
				})
				.subscribe({
					next: () => {
						dialogRef.componentInstance.resultStatus = 'success';
						dialogRef.componentInstance.loading = false;
						dialogRef.componentInstance.setMessage('Operazione avviata con successo, a breve riceverai la mail con il link.');
						this.appService.showSuccessMessage('Operazione avviata');
					},
					error: response => {
						dialogRef.componentInstance.resultStatus = 'error';
						dialogRef.componentInstance.loading = false;
						dialogRef.componentInstance.setMessage('Si è verificato un errore, riprova');
					}
				});
			});
	}
}
