import { Component, ViewChild, Output, EventEmitter, Input, OnInit } from '@angular/core';
import {DatasetActionContainerService, DatasetEvents} from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import {BaseDatasetComponent} from './../base-dataset.component';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from 'app/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IFormConfig, IStepViewConfig } from 'app/interfaces';
import { InvalidAllotmentsDialogComponent } from '../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HttpHeaders } from '@angular/common/http';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';

@Component({
	selector   : 'dataset-create',
	templateUrl: './dataset-create.component.html',
	styleUrls  : ['./dataset-create.component.scss'],
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class DatasetCreateComponent extends BaseDatasetComponent implements OnInit{
	
	stepViewConfig: IStepViewConfig = TripPackageStepConfigSmartInterface;
	productStepViewConfig: IStepViewConfig = ProductStepViewConfig;

	actionCode = 'create';
	protected lastErrorResponse: any;

	formConfig: IFormConfig = null;
	formErrors: any;

	@Input() showHeader = true;
	@Output() created: EventEmitter<any>;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;

	constructor(
		protected route: ActivatedRoute,
		protected resourceService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		protected matDialog: MatDialog,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService,
		protected webSocketService: WebSocketService
	){
		super(route, resourceService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService);
		this.created = new EventEmitter();
	}


	ngOnInit(): void{
		super.ngOnInit();

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			if(this.datasetACS.actionConfig){
				this.formConfig = this.datasetACS.actionConfig.viewConfig.formConfig;
			}else{
				this.formConfig = null;
			}
		});
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		let callMethod = this.resourceService.post.bind(this.resourceService);
		const headers: HttpHeaders = this.datasetACS.getContextualDomainHeaders();
		this.datasetACS.loading.next(true);
		callMethod('/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode + '/create', formData, {
			headers: headers
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: DatasetEvents.CREATED, data: response});
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.clearForm();
				}
				this.created.emit();
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}
				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			}
		});
	}
}
