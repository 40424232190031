import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'number-input',
	templateUrl: './input.component.html',
	animations : fuseAnimations
})
export class NumberInputComponent{

	constructor(
		public inputService: FormInputViewerService
	){}
}
