import { Component, Input, QueryList, ViewChildren, OnInit, Injector } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, IDatasetEvent, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { DatasetListComponent } from '../../../base-actions/list/dataset-list.component';
import { takeUntil } from 'rxjs/operators';
import { StepService } from '../../../services/step-state.service';

@Component({
	selector   : 'common-blocks-step',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
})
export class CommonBlockStepComponent extends BaseStepViewComponent implements OnInit{
	
	enableBlocks: any[];
	blockFilters: any[];
	blockDatasetRecordServices: DatasetRecordService[] = [];
	blockDatasetActionContainerServices: DatasetActionContainerService[] = [];
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;
	@ViewChildren('listBlocks') listBlocks: QueryList<DatasetListComponent>;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		private injector: Injector,
		protected stepService: StepService,
		public datasetService: DatasetService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			this.blockFilters = [];
			this.enableBlocks = [];
			for (const blockConfig of this.stepConfig.options.blocks) {
				let blockFilter = {};
				if(blockConfig.filters){
					blockFilter = blockConfig.filters(this, this.datasetRS.record.value);
				}
				this.blockFilters.push(blockFilter);

				

				if(blockConfig.recordAs && this.datasetACS.hasValueInKeyPath(blockConfig.recordAs)){
					const injector = Injector.create({
						parent: this.injector, 
						providers: [{
							provide: DatasetActionContainerService,
							deps: []
						},{
							provide: DatasetRecordService,
							deps: []
						}]
					});
					const recordService: DatasetRecordService = injector.get(DatasetRecordService);
					const containerService: DatasetActionContainerService = injector.get(DatasetActionContainerService);
					recordService.datasetACS = containerService;
					recordService.record.next(this.datasetACS.getValueFromKeyPath(blockConfig.recordAs));
					containerService.init({
						datasetCode: this.datasetACS.datasetCode,
						actionCode: 'edit',
						parentDatasetRS: this.datasetRS,
						parentDatasetACS: this.datasetACS,
						datasetRS: recordService,
						fetchData: false
					});
					this.blockDatasetRecordServices.push(recordService);
					this.blockDatasetActionContainerServices.push(containerService);
				}else{
					this.blockDatasetRecordServices.push(null);
					this.blockDatasetActionContainerServices.push(null);
				}

				if(blockConfig.showIf){
					this.enableBlocks.push(blockConfig.showIf(record));
				}else{
					this.enableBlocks.push(true);
				}
			}
			// if step state active
			if (this.stepConfig.options.stepService){
				this.stepService.setStepState({
					dataset_code: 'products',
					form_code: 'prices',
					record_id: record.id,
					status: 1
				});
			}
		});
	}

	onCreatedInBlock(blockConfig: any): void{
		if(blockConfig.updateParent){
			this.datasetRS.reload()
			.subscribe(() => {
				const block = this.listBlocks.find( elementRef => {
					return elementRef.datasetCode === blockConfig.datasetCode;
				});
				if(block) block.reload();
			});
		}
	}

	onDatasetEvent(event: IDatasetEvent): void{
		if(event.eventName === DatasetEvents.CREATED) return;
		this.datasetRS.reload();
	}
}
