import { Component, Inject, Input, OnInit, Self, SkipSelf, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';

@Component({
	selector   : 'trip-package-reselled-step-3',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ 
		DatasetActionContainerService, 
		{ 
			provide: 'originalDatasetACS', 
			useClass: DatasetActionContainerService
		},
		{ 
			provide: 'reselledDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'originalDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'reselledMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		},
		{ 
			provide: 'originalMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		} 
	]
})
export class TripPackageReselledStep3Component extends BaseStepViewComponent implements OnInit{
	
	carouselBlockConfigs: any;

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		@Inject('reselledDatasetRS')
		public reselledDatasetRS: DatasetRecordService,
		@Self()
		@Inject('originalDatasetRS')
		public originalDatasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Self()
		@Inject('originalDatasetACS')
		public originalDatasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		@Self()
		@Inject('reselledMultilanguageService')
		public reselledDatasetMCS: DatasetMultilanguageContainerService,
		@Self()
		@Inject('originalMultilanguageService')
		public originalDatasetMCS: DatasetMultilanguageContainerService
	){
		// parent constructor
		super(datasetACS, datasetNavigatorService);
		/**
		 * carousel block configs
		 */
		this.carouselBlockConfigs = {
			datasetCode: 'trip_package_language_contents_carousel_images',
			parentDatasetCode: 'trip_package_language_contents',
			parentForeignKey: 'trip_package_language_content_id'
		};
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.reselledDatasetRS,
			fetchData: true
		});
		this.originalDatasetACS.init({
			datasetCode: 'trip_package_language_contents',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.originalDatasetRS,
			fetchData: true
		});
		this.reselledDatasetRS.datasetACS = this.datasetACS;
		this.originalDatasetRS.datasetACS = this.originalDatasetACS;

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.reselledDatasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.reselledDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'reselledData.id'
			});
			this.reselledDatasetMCS.fetchRecord();
		});

		this.originalDatasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.originalDatasetMCS.init({
				datasetACS: this.originalDatasetACS,
				datasetRS: this.originalDatasetRS,
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			});
			this.originalDatasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record){
				this.reselledDatasetMCS.activeLanguageCodes = record.active_language_codes;
				this.originalDatasetMCS.activeLanguageCodes = record.active_language_codes;
			}
		});
		
	}
}
