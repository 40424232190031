<ng-container *ngIf='inputService.show' [formGroup]="customFormGroup">
	<div fxLayout='row' fxLayoutAlign='start center' fxLayoutGap="50px" class="compare-input">
		<mat-form-field>
			<input matInput *ngIf='inputService.inputConfig.readOnly' readonly type='text' formControlName='compare' />
			<mat-select *ngIf='!inputService.inputConfig.readOnly' [placeholder]="inputService.inputConfig.placeholder | translate" formControlName='compare'>
				<mat-option value='>'>
					<span>></span>
				</mat-option>
				<mat-option value='>='>
					<span>>=</span>
				</mat-option>
				<mat-option value='='>
					<span>=</span>
				</mat-option>
				<mat-option value='<'>
					<span><</span>
				</mat-option>
				<mat-option value='<='>
					<span><=</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field fxFlex="1 1 0">
			<mat-label>{{inputService.computedTitle | translate}}</mat-label>
			<input [required]='inputService.inputConfig.required' [readonly]='inputService.inputConfig.readOnly' matInput [matDatepickerFilter]="dateFilterBinded" [matDatepicker]="dp3" [placeholder]="inputService.inputConfig.placeholder | translate" formControlName='date' (click)='dp3.open()' readonly>
			<mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
			<mat-datepicker  #dp3></mat-datepicker>
			<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
		</mat-form-field>
	</div>
</ng-container>