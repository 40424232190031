import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import {BaseDatasetComponent} from '../base-dataset.component';
import {AuthService} from 'app/services/auth.service';
import { Observable, of } from 'rxjs';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IStepViewConfig } from 'app/interfaces';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';

@Component({
	selector   : 'dataset-detail',
	templateUrl: './dataset-detail.component.html',
	styleUrls  : ['./dataset-detail.component.scss'],
	providers  : [ DatasetActionContainerService, DatasetRecordService ]
})
export class DatasetDetailComponent extends BaseDatasetComponent implements OnInit{

	stepViewConfig: IStepViewConfig = TripPackageStepConfigSmartInterface;
	productStepViewConfig: IStepViewConfig = ProductStepViewConfig;

	public actionCode = 'detail';

	@Input() inputRecord: any;
	@Input() inputRecordId: any;
	@Input() showActions = true;

	public backRouteLink: string;
	public backTitle = 'Torna';
	public backQueryParams: any;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public pageClosureContainer: PageClosureContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService,
		protected webSocketService: WebSocketService
	){
		super(route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService);
	}

	ngOnInit(): void{
		if(this.inputRecordId) this.datasetRS.recordId = this.inputRecordId;
		if(this.inputRecord) this.datasetRS.record.next(this.inputRecord);
		super.ngOnInit();
		this.datasetACS.datasetRS = this.datasetRS;

		this.route.queryParamMap
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(queryParamMap => {
			if(queryParamMap.has('back_title')){
				this.backTitle = queryParamMap.get('back_title');
			}else{
				this.backTitle = 'Torna';
			}
			if(queryParamMap.has('back_to_dataset')){
				if(queryParamMap.has('back_to_record_id')){
					this.backRouteLink = this.datasetNavigatorService.getDetailRoute(queryParamMap.get('back_to_dataset'), queryParamMap.get('back_to_record_id'));
				}else{
					this.backRouteLink = this.datasetNavigatorService.getListRoute(queryParamMap.get('back_to_dataset'));
				}
			}else{
				this.backRouteLink = null;
			}
			if(queryParamMap.has('back_query_params')){
				this.backQueryParams = JSON.parse(queryParamMap.get('back_query_params'));
			}else{
				this.backQueryParams = null;
			}
		});
	}

	protected load(): Observable<any>{
		if(this.firstLoad && this.datasetRS.record.value){
			return of(this.datasetRS.record.value);
		}else{
			return this.datasetRS.reload();
		}
	}
}
