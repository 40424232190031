import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { Subject } from 'rxjs';
import { BookingService } from './booking.service';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import sendVoucherAction from 'app/main/components/dataset/dialogs/voucher-email-dialog/voucher.email';
import * as moment from 'moment';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationItaliano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import { ConfirmWithReasonDialogComponent } from 'app/main/dialogs/confirm-with-reason-dialog/dialog.component';
import { DatasetListDialogComponent } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
export var RELOAD_BOOKING = 'reload-booking';
export var RELOAD_BOOKING_PACKAGES = 'reload-booking-packages';
export var RELOAD_BOOKING_PACKAGE = 'reload-booking-package';
var EditBookingPageComponent = /** @class */ (function () {
    function EditBookingPageComponent(appService, datasetRS, datasetACS, datasetNavigatorService, _fuseSidebarService, router, route, location, bookingService, matDialog, domainFilterService, _fuseTranslationLoaderService) {
        this.appService = appService;
        this.datasetRS = datasetRS;
        this.datasetACS = datasetACS;
        this.datasetNavigatorService = datasetNavigatorService;
        this._fuseSidebarService = _fuseSidebarService;
        this.router = router;
        this.route = route;
        this.location = location;
        this.bookingService = bookingService;
        this.matDialog = matDialog;
        this.domainFilterService = domainFilterService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.initStep = false;
        this.stepIndex = 0;
        this.isStepLinear = true;
        this.loading = false;
        this.now = moment();
        this.channelValue = '';
        this.creatorName = '';
        this.enabledNextBookingStep = true;
        this.confirmTooltip = '';
        // traduzioni
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationItaliano);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(EditBookingPageComponent.prototype, "record", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditBookingPageComponent.prototype, "isDraft", {
        get: function () {
            return this.record && this.record.status === 'draft';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditBookingPageComponent.prototype, "deadlineDate", {
        get: function () {
            if (!this.record ||
                !this.record.properties.optional_deadline ||
                !this.record.properties.optional_deadline.value) {
                return;
            }
            return moment(this.record.properties.optional_deadline.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditBookingPageComponent.prototype, "canConfirm", {
        get: function () {
            return (this.record &&
                this.record.status != 'confirmed' &&
                this.record.packages &&
                this.record.packages.length > 0 &&
                this.record.entity &&
                this.record.customer &&
                this.record.participant_count > 0);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Update scrollbars on window resize
     *
     * @private
     */
    EditBookingPageComponent.prototype._updateOnResize = function (event) {
        if (event.target.innerWidth > 959 &&
            this.appService.layoutFusePerefctScrollBar) {
            this.bottomBarState = 'false';
            this.appService.layoutFusePerefctScrollBar._init();
            this.appService.layoutFusePerefctScrollBar.update();
        }
        if (this.stepIndex == 2) {
            var pageLayout = document.querySelector('.booking-page-content');
            if (event.target.innerWidth > 959) {
                pageLayout.classList.add('p-16');
            }
            else {
                pageLayout.classList.remove('p-16');
            }
        }
    };
    EditBookingPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.domainFilterService.filterDomainId.value &&
            this.route.snapshot.data.domainId) {
            this.domainFilterService.setFilterDomain(this.route.snapshot.data.domainId);
        }
        this.bottomBarState = 'false';
        this.now = moment();
        this.datasetACS
            .init({
            datasetCode: 'trip_bookings',
            actionCode: 'edit',
            fetchData: true,
            datasetRS: this.datasetRS
        })
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetRS.recordId = _this.route.snapshot.paramMap.get('id') || _this.route.snapshot.queryParamMap.get('id');
            if (_this.datasetRS.recordId) {
                _this.bookingService.useLastDraft = _this.route.snapshot.queryParamMap.get('useLastDraft') === '1';
                _this.datasetRS.reload().subscribe({
                    error: function () {
                        _this.router.navigate(['new-booking']);
                    }
                });
            }
            else {
                // search last draft is no exists, create new draft
                _this.datasetACS.loading.next(true);
                _this.bookingService
                    .loadLastDraft()
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe({
                    next: function (lastTripBooking) {
                        if (lastTripBooking) {
                            _this.bookingService.useLastDraft = true;
                            // this.datasetRS.record.next(lastTripBooking);
                            _this.datasetACS.loading.next(false);
                            _this.router.navigate(['edit-booking', lastTripBooking.id], {
                                queryParams: {
                                    useLastDraft: '1'
                                }
                            });
                        }
                        else {
                            _this.bookingService
                                .newDraftBooking()
                                .pipe(takeUntil(_this._unsubscribeAll))
                                .subscribe(function (newDraftTripBooking) {
                                _this.bookingService.useLastDraft = false;
                                // this.datasetRS.record.next(newDraftTripBooking);
                                _this.datasetACS.loading.next(false);
                                _this.router.navigate([
                                    'edit-booking',
                                    newDraftTripBooking.id
                                ]);
                            });
                        }
                    },
                    error: function (e) {
                        _this.datasetACS.loading.next(false);
                        console.error(e);
                    }
                });
            }
        });
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (tripBooking) {
            _this.confirmTooltip = _this.computeConfirmTooltip(tripBooking);
            _this.getChannelDisplayValue(tripBooking);
            _this.getCreatorName(tripBooking);
            _this.setHasPackages(tripBooking);
            _this.bookingService.canEdit = Boolean(tripBooking);
            _this.getFee(tripBooking);
        });
        this.appService.appEvent
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (event) {
            if (event.name === RELOAD_BOOKING || event.name === RELOAD_BOOKING_PACKAGES) {
                _this.datasetRS.reload();
            }
            else if (event.name === 'DISABLE_NEXT_BOOKING_STEP') {
                _this.enabledNextBookingStep = false;
            }
            else if (event.name === 'ACTIVE_NEXT_BOOKING_STEP') {
                _this.enabledNextBookingStep = true;
            }
        });
    };
    EditBookingPageComponent.prototype.toggleSidebar = function (name) {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    };
    EditBookingPageComponent.prototype.stepChange = function (stepChange) {
        this.stepIndex = stepChange.selectedIndex;
        var urlTree = this.router.createUrlTree([], {
            queryParams: { step: this.stepIndex },
            queryParamsHandling: 'merge'
        });
        this.location.go(urlTree.toString());
    };
    EditBookingPageComponent.prototype.ngOnDestroy = function () {
        if (this.appService.layoutFusePerefctScrollBar) {
            this.appService.layoutFusePerefctScrollBar._init();
        }
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    EditBookingPageComponent.prototype.suspendBooking = function () {
        var _this = this;
        this.bookingService.suspendBooking(this.record.id).subscribe(function () {
            _this.datasetRS.reload();
        });
    };
    EditBookingPageComponent.prototype.cancelBooking = function () {
        var _this = this;
        this.matDialog
            .open(ConfirmWithReasonDialogComponent, {
            width: '550px',
            data: {
                title: 'Conferma',
                reasonLabel: 'Vuoi annullare questa prenotazione?',
                reasonRequired: false
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.bookingService
                .cancelBooking(_this.record.id, result)
                .subscribe(function () {
                _this.datasetRS.reload();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            });
        });
    };
    EditBookingPageComponent.prototype.cancelPermanentBooking = function () {
        var _this = this;
        if (this.isDraft !== true)
            return;
        this.matDialog
            .open(ConfirmDialogComponent, {
            width: '550px',
            data: {
                title: 'Conferma',
                contentMessage: 'Vuoi scartare questa prenotazione?<br>Non sarà più possibile recuperarla.'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (!result)
                return;
            _this.bookingService
                .cancelPermanentBooking(_this.record.id)
                .subscribe(function () {
                _this.datasetRS.record.next(null);
                _this.datasetACS.loading.next(true);
                _this.bookingService
                    .newDraftBooking()
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe({
                    next: function (newDraftTripBooking) {
                        _this.bookingService.useLastDraft = false;
                        //this.datasetRS.record.next(newDraftTripBooking);
                        _this.datasetACS.loading.next(false);
                        _this.router.navigate([
                            'edit-booking',
                            newDraftTripBooking.id
                        ]);
                    },
                    error: function () {
                        _this.datasetACS.loading.next(false);
                    }
                });
            });
        });
    };
    EditBookingPageComponent.prototype.getChannelDisplayValue = function (record) {
        if (!record)
            return '';
        var value = record.properties.origin_channel_code.value;
        var label = 'Back Office';
        if (value) {
            if (['direct_online', 'zvsite'].includes(value)) {
                label = 'Direct Online';
            }
            else if (['api'].includes(value)) {
                label = 'API';
            }
            else if (['direct_offline'].includes(value)) {
                label = 'Direct Offline';
            }
            else if (['ticketing_mobile'].includes(value))
                label = 'Mobile';
        }
        this.channelValue = label;
    };
    EditBookingPageComponent.prototype.getCreatorName = function (record) {
        if (!record)
            return '';
        this.creatorName = record.creator ? record.creator.name : '';
    };
    EditBookingPageComponent.prototype.confirmBooking = function () {
        var _this = this;
        this.bookingService.confirmBooking(this.record.id).subscribe(function () {
            _this.datasetRS.reload();
            _this.appService.appEvent.next({
                name: RELOAD_BOOKING_PACKAGES
            });
        });
    };
    EditBookingPageComponent.prototype.optionalBooking = function () {
        var _this = this;
        var initial_date = moment.utc().add(5, 'days');
        this.matDialog
            .open(FormDialogComponent, {
            width: '700px',
            data: {
                title: 'Imposta',
                formData: {
                    optional_deadline: initial_date
                },
                formConfig: {
                    fields: [
                        {
                            key: 'optional_deadline',
                            name: 'Data scadenza',
                            inputType: InputTypes.DATETIME,
                            valueType: ValueTypes.DATETIME
                        }
                    ]
                },
                dataContainerService: this.datasetACS,
                positiveText: 'Conferma'
            }
        })
            .afterClosed()
            .subscribe(function (result) {
            if (result) {
                _this.bookingService
                    .optionalBooking(_this.record.id, result)
                    .subscribe(function () {
                    _this.datasetRS.reload();
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGES
                    });
                });
            }
        });
    };
    EditBookingPageComponent.prototype.emailVoucher = function () {
        sendVoucherAction({ trip_booking_id: this.record.id }, this.datasetACS, this.datasetNavigatorService);
    };
    EditBookingPageComponent.prototype.bookingReviewBarToggle = function () {
        if (this.appService.layoutFusePerefctScrollBar) {
            var templateHeaderPosition = this.mobileHeader.nativeElement.getBoundingClientRect();
            var templateRefFixedBar = this.bottomFixedBar.nativeElement.getBoundingClientRect();
            this.bottom = templateRefFixedBar.height;
            this.bookingReviewHeight =
                templateRefFixedBar.top - templateHeaderPosition.bottom;
            this.bottomBarState =
                this.bottomBarState === 'false' ? 'true' : 'false';
            var container3 = document.querySelector('#container-3');
            if (this.bottomBarState === 'true') {
                container3.style.overflowY = 'hidden';
                this.appService.layoutFusePerefctScrollBar._destroy();
            }
            else {
                container3.style.overflowY = 'scroll';
                this.appService.layoutFusePerefctScrollBar._init();
            }
        }
    };
    Object.defineProperty(EditBookingPageComponent.prototype, "statusClass", {
        get: function () {
            if (this.record.status == 'confirmed') {
                return 'green-bg';
            }
            else if (this.record.status == 'canceled') {
                return 'red-bg';
            }
            return 'orange-bg';
        },
        enumerable: true,
        configurable: true
    });
    EditBookingPageComponent.prototype.setHasPackages = function (record) {
        if (!record)
            return;
        var even = function (item) { return !item.delivery_status; };
        this.hasPackages = record.packages ? record.packages.some(even) : false;
    };
    EditBookingPageComponent.prototype.onViewLogs = function () {
        if (this.record.allowed_actions.includes('view_logs')) {
            this.datasetNavigatorService.openDialog(DatasetListDialogComponent, {
                title: 'Lista logs prenotazione',
                datasetCode: 'trip_booking_logs',
                parentDatasetACS: this.datasetACS,
                domainId: _.get(this.record, 'domain.id'),
                filters: {
                    trip_booking_id: this.record.id
                }
            });
        }
    };
    EditBookingPageComponent.prototype.getFee = function (record) {
        var e_1, _a;
        var fee = 0;
        if (this.hasPackages) {
            try {
                for (var _b = tslib_1.__values(record.packages), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var tripPackage = _c.value;
                    if (tripPackage.fee)
                        fee += parseFloat(tripPackage.fee);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.fees = fee;
    };
    EditBookingPageComponent.prototype.computeConfirmTooltip = function (tripBooking) {
        if (!tripBooking)
            return '';
        if (tripBooking.status == 'confirmed')
            return 'Gia confermata';
        if (tripBooking.participant_count == 0)
            return 'Inserire almeno un partecipante';
        if (!tripBooking.packages || tripBooking.packages.length == 0)
            return 'Inserire almeno una prenotazione';
        if (!tripBooking.entity)
            return 'Intestatario obbligatorio';
        if (!tripBooking.customer)
            return 'Cliente obbligatorio';
        return 'Conferma';
    };
    return EditBookingPageComponent;
}());
export { EditBookingPageComponent };
