import {InputTypes, ValueTypes, IFieldDefinition} from '../../../interfaces';
import { supportedLanguages } from '../actual_trips';
import { meetingPointRemoteOptions } from '../../../main/components/dataset/dialogs/generate-actual-trip-dialog/form.config';
import { IDataContainerService } from '../../../interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		hint: 'Opzionale, se non inserito verrà generato automaticamente.'
	}
});

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	}
});

fieldsMap.set('meeting_point_list_id', {
	key: 'meeting_point_list_id',
	name: 'Lista punti di ritrovo',
	valueType: ValueTypes.REFERENCE,
	inputConfig: {
		type: InputTypes.DATASET_SELECT
	},
	datasetCode: 'meeting_point_unified_lists',
	remoteOptions: meetingPointRemoteOptions
});

fieldsMap.set('guide_languages', {
	key: 'guide_languages',
	name: 'Lingue',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.MULTI_SELECT
	},
	options: supportedLanguages
});

fieldsMap.set('guide_languages_match_mode', {
	key: 'guide_languages_match_mode',
	name: 'Match lingue',
	valueType: ValueTypes.STRING,
	defaultValue(): any{ return 'strict'; },
	inputConfig: {
		type: InputTypes.SELECT
	},
	options: [{label: 'Tutte', value: 'strict'}, {label: 'Almeno una', value: 'not_strict'}]
});

fieldsMap.set('week_days', {
	key: 'week_days',
	name: 'Giorni della settimana',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.MULTI_SELECT
	},
	options: [
		{
			label: 'Lunedì',
			value: 0
		},
		{
			label: 'Martedì',
			value: 1
		},
		{
			label: 'Mercoledì',
			value: 2
		},
		{
			label: 'Giovedì',
			value: 3
		},
		{
			label: 'Venerdì',
			value: 4
		},
		{
			label: 'Sabato',
			value: 5
		},
		{
			label: 'Domenica',
			value: 6
		}
	]
});

fieldsMap.set('special_group', {
	key: 'special_group',
	name: 'Opzione gruppo',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.SELECT
	},
	options: [{label: 'Gruppo standard', value: 0}, {label: 'Capo Gruppi', value: 1}]
});

fieldsMap.set('start_time', {
	key: 'start_time',
	name: 'Orario di partenza',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TIME,
		clearable: true
	},
	width: 'auto',
});

fieldsMap.set('included_trip_resources', {
	key: 'included_trip_resources',
	name: 'Risorse',
	inputType: InputTypes.DATASET_MULTISELECT,
	valueType: ValueTypes.ARRAY,
	datasetCode: 'trip_resources',
	remoteOptions: {
		limit: 50,
		getParams(dataContainerService: IDataContainerService, data: any): any{
			const params = {
				only_optionals: 1
			};
			const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
			if(tripPackageId){
				params['trip_package_id'] = tripPackageId;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

export {fieldsMap};
