<div id="login" class="primary-bg" fxLayout="column" fusePerfectScrollbar>
	<div
		id="login-form-wrapper"
		fxLayout="column"
		fxLayoutAlign="center center"
	>
		<div class="logo mb-32">
			<img src="assets/images/logos/tangerine_logo.png" />
		</div>

		<div
			id="login-form"
			*fuseIfOnDom
			[@animate]="{
				value: '*',
				params: { duration: '300ms', y: '100px' }
			}"
		>
			<div class="title">Imposta una nuova password</div>

			<form name="resetForm" [formGroup]="resetForm" novalidate>
				<mat-form-field appearance="outline">
					<mat-label>Password</mat-label>
					<input
						matInput
						type="password"
						placeholder="Password"
						formControlName="password"
					/>
					<mat-icon matSuffix class="secondary-text"
						>vpn_key</mat-icon
					>
					<mat-error
						translate="LOGIN.ERROR.PASSWORD_REQUIRED"
						*ngIf="resetFormErrors.password.required"
					>
						Password è obbligatoria
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Conferma Password</mat-label>
					<input
						matInput
						type="password"
						placeholder="Conferma Password"
						formControlName="password_confirmation"
					/>
					<mat-icon matSuffix class="secondary-text"
						>vpn_key</mat-icon
					>
				</mat-form-field>

				<button
					mat-flat-button
					(click)="onSubmitClick()"
					color="accent"
					class="submit-button"
					[disabled]="resetForm.invalid"
				>
					Salva
				</button>
			</form>

			<div class="login" fxLayout="row" fxLayoutAlign="center center">
				<a class="link" [routerLink]="'/auth/login'">Torna al login</a>
			</div>
		</div>
	</div>
</div>
