import {
	Component, ElementRef, EventEmitter,
	forwardRef, Input,
	OnInit, Optional, Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { PageClosureContainerService } from '../../page-closure-container.service';
import { DatasetActionContainerService } from '../../../components/dataset/services/dataset-action-container.service';
import { TrenitaliaService } from './trenitalia.service';
import * as moment from 'moment';
import { DatasetRecordService } from '../../../components/dataset/services/dataset-record.service';
import { DatasetService } from '../../../components/dataset/services/dataset.service';
// @ts-ignore
import * as _ from 'lodash';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/dialog.component';
import { wrapArray } from 'app/helpers';
import { AppService } from 'app/services/app.service';
import { catchError, exhaustMap, finalize, first, take, takeUntil } from 'rxjs/operators';
import { EMPTY, of, Subject } from 'rxjs';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { TrenitaliaPostSaleChangeConfirmDialog } from './trenitalia-details/trenitalia-post-sale-change-confirm-dialog/trenitalia-post-sale-change-confirm-dialog.component';
import { RELOAD_BOOKING_PACKAGES} from '../edit-booking.component';
import {LoadingDialogComponent} from "../../../dialogs/loading-dialog/dialog.component";
import {environment} from "../../../../../environments/environment";
import { BookingService } from '../booking.service';
import {TripBookingPackagesStepService} from "../trip-booking-packages-step/trip-booking-packages-step.service";

@Component({
	selector: 'app-trenitalia-dialog',
	templateUrl: './trenitalia-dialog.component.html',
	styleUrls: ['./trenitalia-dialog.component.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService),
		forwardRef(() => DatasetActionContainerService),
		TrenitaliaService
	]
})
export class TrenitaliaDialogComponent implements OnInit {
	public loading = false;
	public tab = 0;
	public total;
	public checkoutData;
	public seats = false;
	public seatsSel = false;
	public continue = false;
	public confirm = false;
	private buyer: any;
	public showedStep = [];
	private mode;
	private errors:any = {};
	public seatsEnabled= false;
	public trainSeatStep:any;
	public nextTrainStep= false;
	public prevTrainStep = false;
	public dataDialog = undefined;
	public isConfirmed = false;
	private loadingDialog: MatDialogRef<LoadingDialogComponent, any>;

	protected _unsubscribeAll: Subject<any>;
	private participants:any;
	@Input ('dataDialog') dataD: any;
	@Output('complete') complete = new EventEmitter();

	get step() {
		return this.trnService.step.value;
	}

	constructor(
		public trnService: TrenitaliaService,
		private _snackBar: MatSnackBar,
		private datasetService: DatasetService,
		public appService: AppService,
		public matDialog: MatDialog,
		public bookingService: BookingService,
		@Optional()
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		@Optional()
		public datasetRs?: DatasetRecordService,

	) {
		this._unsubscribeAll = new Subject<any>();
	}

	backToStart(){
		this.checkoutData = _.omit(this.checkoutData,[
			'searchSolutions','returnSolution','forward','seatSel',
			'selectedSolution','dataAv','seats','seatsSel','seatsCount',
			'totalSelectedSolution','seatsRequested','selection','seatMaps','seatSel','returnSearch','rebuild'
		])
		this.seatsSel = false;
		this.trnService.step.next(1);
		this.trnService.checkoutData.next(this.checkoutData);
	}

	ngOnInit() {
		this.isConfirmed = this.bookingService.getTripBooking().status === 'confirmed';

		if(this.tripBookingPackagesStepService) {
			this.tripBookingPackagesStepService.settingsUpdated
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(() => {
					const tripBooking = this.bookingService.getTripBooking();
					this.isConfirmed = tripBooking && tripBooking.status === 'confirmed';
					if (!_.isEqual(this.dataDialog.participant, tripBooking.participant_counters)) {
						this.checkoutData.adult = this.checkoutData.mode == 'carnet' ? 1 : _.get(tripBooking.participant_counters, 'ADT', 0) + _.get(tripBooking.participant_counters, 'SNR', 0);
						this.checkoutData.children = _.get(tripBooking.participant_counters, 'CHD', 0);
						this.checkoutData.alterPass = true;
						this.backToStart();
						this.dataDialog = {
							participant: tripBooking.participant_counters,
							trip_booking_id: tripBooking.id,
						};
					}
				});
		}

		this.trnService.errors
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(error =>{
			if (!_.isEmpty(error)){
				/*if(error.msg){
					msg
				}*/
				let msg = '';
				for(const train of this.checkoutData.selectedSolution.trains){
					if(_.get(error,train.xmlId)){
						for (const passenger of this.checkoutData.travellers){
							if(_.get(error,[train.xmlId,passenger.xmlId])){
								for(const message of error.train.xmlId.passenger.xmlId.messages){
									msg = msg + message +"\n";
								}

							}
						}
					}
				}
				if(msg!==''){
					this.matDialog.open(TextDialogComponent, {
						minWidth: '550px',
						data: {
							contentMessage: msg
						}
					});
				}
			}
		});
		if(this.dataD) this.trnService.isDialog.next(true);
		if(_.has(this.dataD, 'participant')){
			this.dataDialog = this.dataD
		}else if(this.bookingService){
			const tripBooking = this.bookingService.getTripBooking();
			this.dataDialog = {
				participant: tripBooking.participant_counters,
				trip_booking_id: tripBooking.id,
			};
		}

		this.trnService.btnAction
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(action =>{
			   switch (action) {
				   case '':return;
				   case 'conf-sel': this.confirmSel(this.step==3);break;
				   case 'travel-change': this.changeTravel();break;
				   case 'seat-sel': this.checkSeats();break;
				   case 'confirm-carnet': this.sendConfirm();break;
				   case 'conf-sel-seat': this.confirmSel(true);break;
				   case 'back':this.back();break;
				   case 'back-fw':this.back(true);break;
				   case 'back-start':this.backToStart();break;
			   }
			});

		this.trnService.loading
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(l => {
			this.loading = l
			if(l && this.step!=1){
				this.loadingDialog = this.matDialog.open(LoadingDialogComponent, {
					minWidth: 400,
					data:{
						title: 'Attendere',
						contentMessage: 'Operazione in corso...'
					}
				});
			}else{
				if(this.loadingDialog)this.loadingDialog.close();
			}
		});

		this.trnService.step
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(s => {
			this.tab = Math.min(Math.max(0, s - 1), 4);
			//this.stepper.selectedIndex = this.tab;
			// timeout need for wait completed step status to change
			/*setTimeout(() => {
				console.log('newTabIndex', newTabIndex);
				if(newTabIndex > this.stepper.selectedIndex) this.stepper.selectedIndex = newTabIndex;
			}, 100);*/
			this.checkContinue()
		});

		/*this.trnService.trainSeatStep
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(trainStep =>{
				this.trainSeatStep =  trainStep
				if(_.has(this.checkoutData,'selectedSolution')){
					this.nextTrainStep = this.checkoutData.selectedSolution.trains.length-1 > this.trainSeatStep;
					this.prevTrainStep = this.trainSeatStep > 0;
				}
			});*/
		this.trnService.continue
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(c => {
			this.continue = c;
		});
		this.trnService.total
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.total = data;
			if (this.step == 2 && this.total > 0) {
				this.trnService.continue.next(true);
			}
		});
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
			if(_.get(environment,'trenitalia.debug',false)) console.log("Checkout data", this.checkoutData)
			if (this.checkoutData.selectedSolution) {
				/*this.seatsSel = false;*/
				this.seats = this.checkoutData.seats;
			}
			this.checkContinue();
			this.checkConfirm();
			// @ts-ignore
			this.seatsEnabled = this.checkSeatsEnable();
			this.trnService.seatsEnabled.next(this.seatsEnabled);
			if(_.has(this.checkoutData,'selectedSolution')){
				this.nextTrainStep = this.checkoutData.selectedSolution.trains.length -1 > this.trainSeatStep;
			}
			if(this.checkoutData.mode == 'carnet'){
				this.showedStep=[1, 2, 3];
			}
		});

		if (!_.has(this.dataDialog,'mode')) {
			this.showedStep = [1, 2, 3, 4];
			this.checkoutData.mode = this.checkoutData.mode=='carnet'?'carnet':'travel';
			if(_.has(this.dataDialog,'trip_booking_package')){//edit mode
				this.checkoutData.mode = 'edit';
				this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
				this.checkoutData.editPassengers = _.get(this.dataDialog,'trip_booking_package.target_info.travellers');
				this.checkoutData.buyer = _.get(this.dataDialog,'trip_booking_package.target_info.buyer');
				if( _.has(this.dataDialog,'trip_booking_package.target_info.solution.returnTravelSolution')){ //caso A/R
					const returnTrip =_.get(this.dataDialog,'trip_booking_package.target_info.solution.returnTravelSolution');
					this.checkoutData.return = {
						start : _.get(returnTrip,'context.searchCriteria.departureTimesStart'),
						from:_.get(returnTrip,'context.searchCriteria.departure.id'),
						to:_.get(returnTrip,'context.searchCriteria.arrival.id')
					}
					this.checkoutData.ar = true;
					this.checkoutData.direction = 'FORWARD';
				}
			}
		} else {
			this.checkoutData.mode = this.dataDialog.mode;
			this.checkoutData.reopen = this.dataDialog.data.reopen;
			switch (this.checkoutData.mode) {
				case 'edit-traveller-pre-sale':{
					this.trnService.step.next(4);
					this.checkoutData.editPassengers =this.checkoutData.travellers= _.get(this.dataDialog,'data.travellers');
					this.checkoutData.tripBookingId = _.get(this.dataDialog,'trip_booking_id');
					this.checkoutData.buyer = _.get(this.dataDialog,'data.buyer');
					this.checkoutData.invoiceRequested = _.get(this.dataDialog,'data.invoice');
					this.checkoutData.invoiceData = this.checkoutData.invoiceProfileData = _.get(this.dataDialog,'data.invoiceData');
					this.checkoutData.selectedSolution =this.checkoutData.selection= _.get(this.dataDialog,'data.solution');
					this.showedStep = [4];
					break;
				}
				case 'edit-travellers': {
					this.trnService.step.next(4);
					let passenger = [];
					for (let traveller of this.dataDialog.data.travellers ){
						for(let key in this.dataDialog.data.travelSolution.offeredServices){
							if(_.get(this.dataDialog,['data','travelSolution','offeredServices',key,'travellerXmlId'])===traveller.xmlId &&
							   _.get(this.dataDialog,['data','travelSolution','offeredServices',key,'status'])==='PURCHASED'){
								passenger.push(traveller);
							}
						}
					}
					this.checkoutData.editPassengers = passenger;
					this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
					this.showedStep = [4];
					break;
				}
				case 'edit-date-time':{
					this.trnService.step.next(1);
					this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
					this.checkoutData.from = parseInt(_.get(this.dataDialog.data,'travelSolution.start.id'));
					this.checkoutData.to = parseInt(_.get(this.dataDialog.data,'travelSolution.end.id'));
					this.checkoutData.start = _.get(this.dataDialog.data,'travelSolution.departure');
					console.log(this.dataDialog.data);
					/*if( _.has(this.dataDialog.data,'travelSolution.returnTravelSolution')){ //caso A/R
						const returnTrip =_.get(this.dataDialog.data,'travelSolution.returnTravelSolution');
						this.checkoutData.return = {
							start : _.get(returnTrip,'context.searchCriteria.departureTimesStart'),
							from:_.get(returnTrip,'context.searchCriteria.departure.id'),
							to:_.get(returnTrip,'context.searchCriteria.arrival.id'),
							solution: returnTrip
						}
						this.checkoutData.direction = 'FORWARD';
					}*/
					this.showedStep = [1, 2, 3];
					break;
				}
				case 'travel-change':{
					this.checkoutData.postSaleDatails = _.get(this.dataDialog,'data.postAction.postSaleDetails');
					this.trnService.step.next(1);
					let passenger = [];
					for (let traveller of this.dataDialog.data.travellers ){
						for(let key in this.dataDialog.data.travelSolution.offeredServices){
							if(_.get(this.dataDialog,['data','travelSolution','offeredServices',key,'travellerXmlId'])===traveller.xmlId &&
								_.get(this.dataDialog,['data','travelSolution','offeredServices',key,'status'])==='PURCHASED'){
								passenger.push(traveller);
							}
						}
					}
					this.checkoutData.editPassengers= this.checkoutData.passengers = passenger;
					this.checkoutData.tripBookingId = this.dataDialog.trip_booking_id;
					this.checkoutData.from = parseInt(_.get(this.dataDialog.data,'travelSolution.start.id'));
					this.checkoutData.to = parseInt(_.get(this.dataDialog.data,'travelSolution.end.id'));
					this.checkoutData.start = _.get(this.dataDialog.data,'travelSolution.departure');
					/*if( _.has(this.dataDialog.data,'travelSolution.returnTravelSolution')){ //caso A/R
					   const returnTrip =_.get(this.dataDialog.data,'travelSolution.returnTravelSolution');
					   this.checkoutData.return = {
						   start : _.get(returnTrip,'context.searchCriteria.departureTimesStart'),
						   from:_.get(returnTrip,'context.searchCriteria.departure.id'),
						   to:_.get(returnTrip,'context.searchCriteria.arrival.id')
					   }
					   this.checkoutData.ar = true;
					   this.checkoutData.direction = 'FORWARD';
					}*/
					this.showedStep = [1, 2, 3];
					break;
				}
			}
		}
		let initData:any;
		if (this.dataDialog.data || this.dataDialog.trip_booking_package) {
			initData = {
				tripBookingData: _.get(this.dataDialog,'trip_booking_package'),
				mode: this.checkoutData.mode ? this.checkoutData.mode : 'travel',
				reopen: _.get(this.dataDialog,'reopen')
			};
		}
		this.trnService.init(initData,this.checkoutData);
		/*this.trnService.checkoutData.next(this.checkoutData);*/

	}

	onCloseClick(): void {
		this.matDialog.closeAll();
		/*this.dialogRef.close(false);*/
		this.trnService.reset();
	}

	confirmSel(seats = false) {
		this.trnService.loading.next(true);
		/*if(_.get(this.checkoutData,'notSeleable')){
			this.matDialog.open(TextDialogComponent, {
				minWidth: '550px',
				data: {
					contentMessage: "Soluzione di viaggio con una o più tratte non acquistabili su questo canale"
				}
			});
		}*/
		if (this.seatsSel) {
			this.checkoutData.dataAv.withSeatsSelection = true;
		}else{
			this.checkoutData.dataAv.withSeatsSelection = false;
		}
		if (seats) {
			this.setSeats()
		}
		if(this.checkoutData.direction==='RETURN' && (seats || !seats && !this.seatsSel)){
			/*this.checkoutData.forward.solution['returnTravelSolution'] = this.checkoutData.selectedSolution.original;*/
			const sol = this.checkoutData.returnSearchSolutions.find(s=>s.returnTravelSolution.solutionId==this.checkoutData.dataAv.travelSolutions.solutionId)
			this.checkoutData.dataAv.travelSolutions = sol.original;
		}
		/*if(seats){
			this.checkoutData.dataAv.withSeatsSelection = false;
		}*/

		this.trnService.getAvailability(this.checkoutData.dataAv)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(
			data => {
				if (data.validSelection === 'true') {
					this.trnService.loading.next(false);
					this.checkoutData.selection = this.checkoutData.dataAv.travelSolutions = data['travelSolutions'];
					this.checkoutData.travellers = this.checkoutData.dataAv.travellers = Array.isArray(
						data['travellers']
					)
						? data['travellers']
						: [data['travellers']];

					this.checkoutData.selectedSolution.selectedOffers = {};
					this.checkoutData.travellers.forEach(pass =>{
						this.checkoutData.selectedSolution.selectedOffers[pass.xmlId]={}
						this.checkoutData.selectedSolution.trains.forEach(train=>{
							this.checkoutData.selectedSolution.selectedOffers[pass.xmlId][train.id]={}
							const offer = this.checkoutData.dataAv.selectedOffer.find(off =>
								_.get(this.checkoutData.selectedSolution.offeredServices,off.id).nodeId == train.id &&
								_.get(this.checkoutData.selectedSolution.offeredServices,off.id).travellerXmlId == pass.xmlId);
							const offerId = _.get(this.checkoutData.selectedSolution.offeredServices,offer.id).offerId;
							const catalogServiceId = _.get(this.checkoutData.selectedSolution.offeredServices,offer.id).catalogServiceId;
							this.checkoutData.selectedSolution.selectedOffers[pass.xmlId][train.id] =
								{
									offer:{
										id:offerId,
										name: _.get(this.checkoutData.selectedSolution.servicesMap.offers,offerId+'.display_name')
									},
									service: {
										id:offer.catalogServiceId,
										name: _.get(this.checkoutData.selectedSolution.servicesMap.catalog_services,catalogServiceId+'.display_name')
									}
								};
						})
					});
					
					if(data['additional_fee_on_total']){
						this.total['fee'] = this.total['fee'] + data['additional_fee_on_total'] - _.get(this.total, 'additional_fee_on_total', 0);
						this.total['valueNet'] = this.total['valueNet'];
						this.total['value'] = this.total['value'] + data['additional_fee_on_total'] - _.get(this.total, 'additional_fee_on_total', 0);
						this.total['additional_fee_on_total'] = data['additional_fee_on_total'];
						this.trnService.total.next(this.total)
					}

					if (this.seatsSel && !_.has(this.checkoutData,'seatSel')) {
						this.checkoutData.seatMaps = data['trainSeatMaps'];
						this.trnService.step.next(3);
						this.trnService.checkoutData.next(this.checkoutData);
					} else {
						if(this.checkoutData.direction ==='RETURN'){
							this.checkoutData.returnSolution = this.checkoutData.selectedSolution;
						}
						if(this.checkoutData.ar && !_.has(this.checkoutData,'returnSolution')){
							this.trnService.loading.next(true);
							this.checkoutData.forward = {
								solution:this.checkoutData.selection,
								searchedSolutions:this.checkoutData.searchSolutions,
								selectedSolution: this.checkoutData.selectedSolution,
								dataAv:this.checkoutData.dataAv,
								seatMaps: _.get(this.checkoutData,'seatMaps'),
								seatSel: _.get(this.checkoutData,'seatSel'),
								total: this.total
							}

							/*this.checkoutData.forwardSolution = this.checkoutData.selection;
							this.checkoutData.forwardSearchedSolution = this.checkoutData.searchSolutions;
							if(this.checkoutData.dataAv.seatMap){
								this.checkoutData.forwardSeatmap = this.checkoutData.dataAv.seatMap;
							}*/

							
							const departureArrival = moment(_.get(this.checkoutData, 'arrival'));
							const returnStart = departureArrival.isSameOrBefore(moment(_.get(this.checkoutData, 'return.start'))) ? _.get(this.checkoutData, 'return.start') : departureArrival.add(10, 'minutes');
							this.checkoutData.return.start = returnStart;
							let sol = {
								adult: this.checkoutData.adult || 0,
								children: this.checkoutData.children,
								start: returnStart,
								ar: this.checkoutData.ar,
								from: this.checkoutData.return.from,
								to: this.checkoutData.return.to,
								direction: 'RETURN',
								returnTripDate: returnStart,
								roundTrip: true,
								travellers: this.checkoutData.travellers,
								forwardTripDate:_.get(this.checkoutData,'selectedSolution.departure'),
								forwardTrip:this.checkoutData.selection,
								seatMap: _.get(this.checkoutData,'dataAv.seatMap')
							};
							this.trnService.getSolutions(sol)
								.pipe(takeUntil(this._unsubscribeAll))
								.subscribe({
									next: (data:any) => {
										if(_.isEmpty(data.travelSolutions)){
											this.trnService.checkoutData.next(this.checkoutData);
											this.appService.showErrorMessage("Nessuna soluzione trovata!");
										}else{
											this.trnService.loading.next(false);
											this.checkoutData.searchSolutions = data;
											this.checkoutData.returnSearchSolutions = data.travelSolutions;
											this.checkoutData.searchSolutions.travelSolutions =  this.checkoutData.searchSolutions.travelSolutions.map((s: { returnTravelSolution: any; })=>{return s.returnTravelSolution})
											this.checkoutData.seatsRequested = false;
											this.checkoutData.returnSearch = true;
											this.seatsSel=false;
											this.checkoutData.direction = 'RETURN';
											this.checkoutData = _.omit(this.checkoutData,['selectedSolution','selection','dataAv','seatMaps','seatsCount','seatSel']);
											this.trnService.updateServicesMap(data.serviceMap||data.servicesMap);
											this.trnService.checkoutData.next(this.checkoutData);
											this.trnService.step.next(2);
										}
									},
									error: error => {
										this.trnService.loading.next(false);
									}
								});
						}else{
							if(this.checkoutData.dataAv.seatMap && this.checkoutData.direction ==='RETURN')this.checkoutData.return.seatMap = this.checkoutData.dataAv.seatMap;
							if(_.has(this.checkoutData,'forward.dataAv.seatMap')){
								if(_.has(this.checkoutData,'return.seatMap')){
									this.checkoutData.dataAv.seatMap = [...this.checkoutData.return.seatMap,...this.checkoutData.forward.dataAv.seatMap];
								}else{
									this.checkoutData.dataAv.seatMap = wrapArray(this.checkoutData.forward.dataAv.seatMap);
								}
								this.seatsSel = true;

							}
							this.trnService.checkoutData.next(this.checkoutData);
							this.trnService.step.next(4);
						}
					}
				} else {
					let message = 'Soluzione non più disponibile';
					let travelSolutions = _.get(data, 'travelSolutions');
					// TODO: check also saleabilityMessages
					if(travelSolutions){
						this.errors = {}
						if(!Array.isArray(travelSolutions)) travelSolutions = [travelSolutions];
						let offeredServices = _.get(travelSolutions, [0, 'offeredServices']);
						if(offeredServices && !Array.isArray(offeredServices)) offeredServices = [offeredServices];
						if(offeredServices && _.has(travelSolutions, [0, 'returnTravelSolution', 'offeredServices'])){
							const returnOfferedServices = _.get(travelSolutions, [0, 'returnTravelSolution', 'offeredServices']);
							if(Array.isArray(returnOfferedServices)) offeredServices.push(...returnOfferedServices);
							else offeredServices.push(returnOfferedServices);
						}
						if(offeredServices){
							for (const offeredService of offeredServices) {
								if(_.get(offeredService, 'status') != 'SELECTED') continue;
								let messages = _.get(offeredService, 'bookingInfo.reportItem.messages');
								if(messages && !Array.isArray(messages)) messages = [messages];
								const train = _.get(offeredService,'solutionNodeRef');
								const traveller = _.get(offeredService,'bookingInfo.traveller');
								if(!_.has(this.errors,train)) this.errors[train] = {}
								if(!_.has(this.errors,[train,traveller])) this.errors[train][traveller] = {}
								this.errors[train][traveller].messages = messages;
								// take last message to show
								if(!_.isEmpty(messages)) message = messages[0];
							}
							if(!_.isEmpty(this.errors)) this.trnService.errors.next(this.errors);
						}
					}
					message = _.get(data, '_invalid_messages.0.message', message);

					this.matDialog
					.open(TextDialogComponent, {
						data: {
							title: 'Attenzione',
							contentMessage: message
						}
					});

					this.seatsSel = false;
					this.seats = false;
					this.trnService.loading.next(false);
				}
			},
			err => {
				if (err.error.details[0].code == '451') {
					this._snackBar.open(
						'Impossibile selezionare i posti! Selezionare un altra soluzione',
						null,
						{
							duration: 5000
						}
					);
					this.seatsSel = false;
					this.seats = false;
				}

				this.trnService.loading.next(false);
			}
		);
		this.trnService.scrollToTop();
	}

	sendConfirm(edit = false) {
		this.trnService.loading.next(true);
		let travellersData = this.mapTravellers();

		let res: any = {
			travellers: travellersData,
			solution: this.checkoutData.selection?this.checkoutData.selection:this.checkoutData.selectedSolution.original,
			buyer: this.checkoutData.buyer,
			withInvoice: _.get(this.checkoutData, 'invoiceRequested', false)
		};


		let dataTravel={};
		if(this.checkoutData.mode!='carnet'){
			dataTravel = {
				travelSolutions: this.checkoutData.selection,
				travellers: travellersData,
				mode: 'travellers'
			};
		}else{

			this.checkoutData.dataAv.travelSolutions.offeredServices = _.isArray(this.checkoutData.dataAv.travelSolutions.offeredServices)?this.checkoutData.dataAv.travelSolutions.offeredServices:[this.checkoutData.dataAv.travelSolutions.offeredServices];
			this.checkoutData.dataAv.travellers = _.isArray(this.checkoutData.dataAv.travellers)?this.checkoutData.dataAv.travellers:[this.checkoutData.dataAv.travellers];
			this.checkoutData.dataAv.selectedOffer = [{ id: this.checkoutData.carnetData.selectedService }];
			this.checkoutData.dataAv.customerKey = this.checkoutData.travellers.customerKey;
			if(this.seats){
				this.setSeats()
			}
			dataTravel = this.checkoutData.dataAv
		}


		this.trnService.getAvailability(dataTravel)
		.pipe(takeUntil(this._unsubscribeAll))
		.pipe(
			first(),
			exhaustMap((resp) => {
				if (!_.isEmpty(resp._invalid_traveller_parameters)) {
					this.checkoutData.invalid_traveller_parameters = resp._invalid_traveller_parameters;
					this.trnService.checkoutData.next(this.checkoutData);
					const messages = Object.entries(resp._invalid_traveller_parameters).reduce((res, [travellerXmlId, params]) => {

						return Object.entries(params).reduce((result, [paramKey, paramData]) => {
							for (const message of paramData.validationMessages) {
								if(result.includes(message)) continue;
								result.push(message);
							}
							return result;
						}, res);
					}, []);

					if(!_.isEmpty(messages)) this.appService.showErrorMessage(messages.join('\n'));
					else this.appService.showErrorMessage('Dati non validi');
					this.trnService.loading.next(false);
					return EMPTY;
				}
				
				if (!resp.validSelection) {
					const message = _.get(resp, '_invalid_messages.0.message', 'La soluzione o i dati inseriti non sono validi');
					this.appService.showErrorMessage(message);
					this.trnService.loading.next(false);
					return EMPTY;
				}
				
				return of(resp);
			}),
			exhaustMap((resp) => {
				const invoiceProfileId = this.checkoutData.billingFormData && this.checkoutData.billingFormData.id_invoice_profile;
				if (invoiceProfileId) {
					this.checkoutData.invoiceProfile = res.invoiceProfile = {id: invoiceProfileId};
					res.invoiceProfileData = this.mapBillingData();
					res.invoiceProfileData.id = invoiceProfileId;
				}

				if(this.checkoutData.invoiceRequested && !_.has(this.checkoutData, 'invoiceProfile.id') && !invoiceProfileId){
					// TODO: call server for create invoiceProfile if needed /command/create_invoice_profiles
					// set res.invoiceProfile
					// ATTENTION return the observable after the http request
					return this.datasetService.post('/dataset/trenitalia/command/create_invoice_profiles', this.mapBillingData())
					.pipe(takeUntil(this._unsubscribeAll))
					.pipe(
						catchError((err) => {
							let message = err && err.message;
							if(err && err.error && err.error.message){
								message = err.error.message;
							}
							this.matDialog
							.open(TextDialogComponent, {
									data: {
										title: 'Attenzione',
										contentMessage: message
									}
							});
							this.trnService.loading.next(false);
							return EMPTY;
						}),
						exhaustMap((creationResponse: any) => {
							const invoiceProfileId = creationResponse.invoiceProfileId;
							if (!invoiceProfileId) {
								this.matDialog
								.open(TextDialogComponent, {
										data: {
											title: 'Attenzione',
											contentMessage: "ID invoiceProfile non presente"
										}
								});
								this.trnService.loading.next(false);
								return EMPTY;
							}
							this.checkoutData.invoiceProfile = res.invoiceProfile = {id: invoiceProfileId}
							this.checkoutData.invoiceProfileData = res.invoiceProfileData = this.mapBillingData();
							res.invoiceProfileData.id = this.checkoutData.invoiceProfile.id;
							return of(creationResponse)
						})
					);
				}
				return of(resp);
			})
		)
		.subscribe({
			next: resp => {
				if(this.checkoutData.mode=='carnet'){
					 res = {
						travellers: _.get(resp,'travellers'),
						solution: _.get(resp,'travelSolutions')
					};
					 if(this.checkoutData.invoiceProfile){
						 res.invoiceProfile = this.checkoutData.invoiceProfile;
						 res.invoiceProfileData = this.checkoutData.invoiceProfileData;
						 res.invoiceProfileData.id = this.checkoutData.invoiceProfile.id;
					 }
				}
				if (this.seatsSel && this.checkoutData.dataAv.seatMap) {
					res.seatMap = this.checkoutData.dataAv.seatMap;
				}
				/*this.dialogRef.close(res);*/

				const postData = {
					trip_booking_id: this.bookingService.getTripBooking().id,
					target: 'trenitalia',
					target_info: res
				};
				let actionEndPoint =
					'/dataset/trip_booking_packages/create';
				if (this.dataD && this.dataD.trip_booking_package && this.checkoutData.mode=='edit') {
					postData['trip_booking_package_id_to_replace'] =
						this.dataD.trip_booking_package.id;
					actionEndPoint =
						'/dataset/trip_bookings/command/replace_trip_external_service';
				}
				this.datasetService.post<any>(actionEndPoint, postData).subscribe({
					next: response => {
						/*this.reloadTripBookingPackages();*/
						this.appService.appEvent.next({
							name: RELOAD_BOOKING_PACKAGES,
						});
						this.complete.emit();
						this.loading = false;
						this.trnService.reset();
						this.trnService.loading.next(false);
					},
					error: (err) => {
						this.loading = false;
					}
				})


			},
			error: response => {
				// todo: error handler
				this.trnService.loading.next(false);
				console.log(response);
			}
		});
		this.trnService.scrollToTop(true)
	}

	back(fw=false) {
		switch (this.step) {
			case 4:
			case 3:{
				if(fw){
					this.checkoutData.direction = 'FORWARD';
					this.checkoutData.searchSolutions = this.checkoutData.forward.searchedSolutions;
					/*this.checkoutData.selection  = this.checkoutData.forward.solution;
					this.checkoutData.selectedSolution = this.checkoutData.forward.selectedSolution;*/
					this.checkoutData.dataAv = _.get(this.checkoutData,'forward.dataAv');
					this.checkoutData.returnSearch = false;
					this.total = this.checkoutData.forward.total;
					this.checkoutData = _.omit(this.checkoutData,['returnSolution','forward'])
					this.checkoutData.rebuild = true;
				}else{
					this.checkoutData.returnSearch = true;
				}
				this.seatsSel = false;
				this.checkoutData.dataAv = _.omit(this.checkoutData.dataAv,'seatMap');
				this.checkoutData = _.omit(this.checkoutData,['seatSel','selectedSolution','dataAv','selection'])
				this.checkoutData.seatsRequested = false;
				this.trnService.checkoutData.next(this.checkoutData);
				this.trnService.step.next(2);
				break;
			}
			case 2:{
				if(this.checkoutData.direction==='RETURN'){
					this.checkoutData.direction = 'FORWARD';
					this.checkoutData.searchSolutions = this.checkoutData.forward.searchedSolutions;
					this.checkoutData.selection  = this.checkoutData.forward.solution;
					this.checkoutData.selectedSolution = this.checkoutData.forward.selectedSolution;
					this.checkoutData.dataAv = _.get(this.checkoutData,'forward.dataAv');
					this.checkoutData.dataAv = _.omit(this.checkoutData.dataAv,'seatMap');
					this.checkoutData.seatsRequested = false;
					this.checkoutData.returnSearch = false;
					this.total = this.checkoutData.forward.total;
					this.checkoutData = _.omit(this.checkoutData,['returnSolution','forward','seatSel','selectedSolution','dataAv'])
					this.checkoutData.rebuild = true;

				}
				this.trnService.checkoutData.next(this.checkoutData);
				break;
			}
			/*default:
				this.trnService.step.next(this.step-1);*/
		}
		this.checkConfirm()
	}

	checkSeats() {
		this.seatsSel = this.seatsSel != true;
		this.checkoutData.seatsRequested = this.seatsSel;
		this.trnService.checkoutData.next(this.checkoutData);

		/*this.confirmSel();*/
	}

	checkSeatsEnable(){
		if(!this.checkoutData.dataAv) return false;
		if(!_.get(this.checkoutData, 'selectedSolution.trains')) return false;
		let offered :any = [];
		for (let offer of this.checkoutData.dataAv.selectedOffer){
			offered.push(_.get(this.checkoutData,['selectedSolution','offeredServices',offer.id]));
		}
		
		for(let train of this.checkoutData.selectedSolution.trains){
		   const trainOff = offered.filter(off=>off.nodeId==train.id);
		   let service =_.get(trainOff,[0,'catalogServiceId']);
		   for( let offtr of trainOff){
			   if(offtr.catalogServiceId!=service) {
				   return false;
			   }
		   }
		}
		return this.seats;
	}

	editTravellerPreSale(){
		let travellersData = this.mapTravellers();
		let res = _.get(this.dataDialog,'trip_booking_package.target_info');
		res.travellers = travellersData;
		if(this.checkoutData.invoiceRequested && this.checkoutData.billingFormValid){

		}

	}
	editTravellers() {
		this.trnService.loading.next(true);
		let newTravellers = this.mapTravellers();
		newTravellers.forEach((tr,k)=>{
			newTravellers[k]=_.omit(tr,['id']);
		})

		this.dataDialog.data.travellers.forEach((tr, k) => {
			this.dataDialog.data.travellers[k] = _.omit(tr, ['dataPass', 'valid', 'id']);
		});

		let travellersToChange = [];
		const postSaleAction = this.dataDialog.data.postAction;
		const purchasedOfferMap = new Map();
		const newTravellerXmlIdMap = new Map();

		this.dataDialog.data.travel.travelSolutions.forEach(travelSolution => {
			if(travelSolution.xmlId!==_.get(this.dataDialog.data,'travelSolution.xmlId') &&
				_.get(travelSolution,'returnTravelSolution')
			){
				travelSolution = _.get(this.dataDialog.data,'travelSolution')
			}
			if (!travelSolution.purchased_offers) return;
			Object.entries(travelSolution.offeredServices).forEach(
				([offerId, purchasedService]):any => {
					// @ts-ignore
					if (purchasedService.status != 'PURCHASED')return;
					purchasedOfferMap.set(
						[
							purchasedService['original'].id.offeredServiceId,
							purchasedService['original'].id.travelId,
							purchasedService['original'].id.travelSolutionId
						].join(':'),
						purchasedService['travellerXmlId']
					);
				});
		});
		/* post sale details*/
		for (const postSaleDetail of postSaleAction.postSaleDetails) {
			const key = [
				postSaleDetail.offeredServiceId.offeredServiceId,
				postSaleDetail.offeredServiceId.travelId,
				postSaleDetail.offeredServiceId.travelSolutionId
			].join(':');
			const travellerXmlId = purchasedOfferMap.get(key);
			if (!travellerXmlId) continue;
			if (!newTravellers.find(t => t.xmlId == travellerXmlId)) continue;

			travellersToChange.push({
				traveller: travellerXmlId,
				postSaleDetail
			});
		}

		const data = {
			travel: this.dataDialog.data.reopen.travel,
			travellersToChange,
			newTravellers: newTravellers,
			trip_booking_package_id: this.dataDialog.trip_booking_package.id
		};

		this.trnService.changeTravellers(data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((change: any) => {
			let pay =
				change.missingAmount.amount > 0.0
					? change.missingAmount.amount
					: 'Gratis';
			if(change.fee>0) {
				pay += " Diritti agenzia € " + change.fee
			}
			this.matDialog
				.open(ConfirmDialogComponent, {
					data: {
						contentMessage:
							'Vuoi modificare i dati dei passeggeri? Costo: ' +
							pay
					},
					panelClass: 'create-dialog-container'
				})
				.afterClosed()
				.subscribe({
					next: result => {
						if (result) {
							let data = {
								fromTravellersTo: change.fromTravellersTo,
								travel: change.travel,
								type: 'traveller_change'
							};
							data['postSaleData'] = _.omit(change, ['travel', 'fromTravellersTo']);
							this.trnService.updateAndConfirm(data).subscribe(s => {
								this.trnService.loading.next(false);
								/*this.matDialog.closeAll();*/
								this.complete.emit();
								this._snackBar.open(
									'Cambio dati passeggeri effettuato',
									'',
									{
										duration: 5000
									}
								);
							});
						} else {
							this.loading = false;
						}
					},
					error: () => {
						this.loading = false;
					},
					complete: () => {
						this.loading = false;
					}
				});
		});
	}

	editDateTime() {
		this.matDialog
			.open(TrenitaliaPostSaleChangeConfirmDialog, {
				minWidth: '300px',
				panelClass: 'create-dialog-container',
				data: {
					postSaleData: Object.assign({}, this.checkoutData.selectedSolution.validatedPostSaleRecord,
						{
						missingAmount: _.get(this.checkoutData.selectedSolution,'missingAmount'),
						reversedPaymentRecords: wrapArray(_.get(this.checkoutData.selectedSolution,'reversedPaymentRecords')),
						reusedPaymentRecords: wrapArray(_.get(this.checkoutData.selectedSolution,'reusedPaymentRecords')),
						additionalCharges: _.get(this.checkoutData.selectedSolution, 'additionalCharges'),
						postSaleFee: _.get(this.checkoutData.searchSolutions, 'fee'),
					}),
					type: 'edit-date-time',
					title: 'Cambio data e orario',
					message: 'Vuoi cambiare data ora viaggio?',
					icon: 'event'
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (!result) return;
				this.loading = true;
				const travelSolutions = wrapArray(this.checkoutData.reopen.travel.travelSolutions);
				const changedTravelSolution = travelSolutions.find(travelSolution => {
					return travelSolution; // TODO: check not deleted or other check for take only the changed
				});
				const oldDate = {
					departure: changedTravelSolution.departure,
					arrive: changedTravelSolution.arrival
				};
				const newDate = {
					departure: this.checkoutData.selectedSolution.departure,
					arrive: this.checkoutData.selectedSolution.arrival
				};
				const remapDateTime = [oldDate, newDate];

				const data = {
					travel: this.checkoutData.reopen.travel,
					travellers: this.checkoutData.selectedSolution
						.travellers,
					postSaleData: {
						postSaleRecord: this.checkoutData.selectedSolution.validatedPostSaleRecord,
						reusedPaymentRecords: this.checkoutData.selectedSolution.reusedPaymentRecords,
						reversedPaymentRecords: this.checkoutData.selectedSolution.reversedPaymentRecords,
						missingAmount: this.checkoutData.selectedSolution.missingAmount,
						additionalCharges: this.checkoutData.selectedSolution.additionalCharges
					},
					newTravelSolution: this.checkoutData.selectedSolution.original,
					type: 'edit-date-time',
					fromDateTo: remapDateTime
				};

				this.trnService.updateAndConfirm(data)
				.pipe(
					take(1),
					finalize(() => {
						this.loading = false;
					})
				)
				.subscribe({
					next: s => {
						this.trnService.loading.next(false);
						/*this.matDialog.closeAll();*/
						this.complete.emit();
						this._snackBar.open(
							'Cambio data e ora effettuato',
							'',
							{
								duration: 5000
							}
						);
					}
				});
			});
	}

	changeTravel(){
		this.loading = true;
		const oldOffer = _.get(this.checkoutData.reopen,'travel.travelSolutions.offeredServices');

		if(this.seats){
			this.setSeats()
		}
		if(this.checkoutData.direction==='RETURN'){
			this.checkoutData.forward.solution['returnTravelSolution'] = this.checkoutData.selectedSolution.original;
			this.checkoutData.selectedSolution.original = this.checkoutData.forward.solution;
		}

			const data = {
				travel: this.checkoutData.reopen.travel,
				selectedPostSaleDetails: _.get(this.dataDialog.data.postAction, 'postSaleDetails'),
				oldOfferedServices: oldOffer,
				selectedOfferedServices: this.checkoutData.dataAv.selectedOffer,
				newTravelSolutionSelected:this.checkoutData.selectedSolution.original,
			};
			this.trnService.evaluateTravelPostSale(data)
			.subscribe(res =>{
				this.loading = false;
				if(res.validatedPostSaleRecord.allowed=='false'){
					this._snackBar.open(
						res.validatedPostSaleRecord.messages,
						'',
						{
							duration: 5000
						}
					);
				}else{
					this.matDialog
						.open(TrenitaliaPostSaleChangeConfirmDialog, {
							minWidth: '300px',
							panelClass: 'create-dialog-container',
							data: {
								postSaleData: Object.assign({}, res.validatedPostSaleRecord, {
									missingAmount: res.missingAmount,
									reversedPaymentRecords: wrapArray(res.reversedPaymentRecords),
									reusedPaymentRecords: wrapArray(res.reusedPaymentRecords),
									additionalCharges: res.additionalCharges,
									postSaleFee:res.fee
								}),
								type: 'travel-change',
								title: 'Cambio Biglietto',
								message: 'Vuoi cambiare il viaggio?',
								icon: 'train'
							}
						})
						.afterClosed()
						.subscribe(result => {
							if (!result) return;

							const confirmData: any = {
								travel: res.travel,
								type: 'travel-change',
								postSaleData: {
									postSaleRecord: res.validatedPostSaleRecord,
									missingAmount: res.missingAmount,
									reversedPaymentRecords: res.reversedPaymentRecords,
									reusedPaymentRecords: res.reusedPaymentRecords,
									additionalCharges: res.additionalCharges
								}
							};

							if (this.seatsSel && this.checkoutData.dataAv.seatMap) {
								confirmData.seatMap = this.checkoutData.dataAv.seatMap;
							}
							this.loading = true;
							this.trnService
							.updateAndConfirm(confirmData)
							.subscribe({
								next: s => {
									this.loading = false;
									this.trnService.loading.next(false);
									/*this.matDialog.closeAll();*/
									this.complete.emit();
									this._snackBar.open(
										'Cambio viaggio effettuato',
										'',
										{
											duration: 5000
										}
									);
								},
								error: error => {
									this.loading = false;
									this._snackBar.open(
										'Errore nella procedura del cambio!',
										'',
										{
											duration: 5000
										}
									);
								},
								complete: () => {
									this.loading = false;
								}
							});
						});
				}
			},
			error => {
				this.loading = false;
				this._snackBar.open(
					'Errore nella procedura del cambio!',
					'',
					{
						duration: 5000
					}
				);
			}
		);
	}

	mapTravellers() {
		if(this.checkoutData.mode =='carnet') return this.checkoutData.travellers;
		let travellers = this.checkoutData.travellers || this.checkoutData.editPassengers;
		if(!travellers){
			console.warn('no travellers found', this.checkoutData);
			return;
		}
		return travellers.map(tr => {
			const data = this.checkoutData.finalTravellers.find(final => {
				return final.travellerXmlId == tr.xmlId;
			});
			const newTr = Object.assign({}, tr);
			newTr.name = _.get(data, 'dataPass.pass_first_name', tr.name);
			newTr.surname = _.get(data, 'dataPass.pass_last_name', tr.surname);
			newTr.email = _.get(data, 'dataPass.pass_email', tr.email);
			newTr.cell = _.get(data, 'dataPass.pass_phone', tr.cell || tr.phoneNumber);
			newTr.cartaFreccia = _.get(data, 'dataPass.pass_cf', tr.cartaFreccia);
			newTr.contact_consent = _.get(data, 'dataPass.contact_consent', tr.contact_consent);
			// reverse value, if true is false and viceversa for trenitalia
			newTr.contact_consent = !Boolean(newTr.contact_consent);
			if (_.get(data, 'dataPass.pass_data_n')) {
				const birthDate = moment(_.get(data, 'dataPass.pass_data_n'));
				if (birthDate.isValid()) {
					newTr.birthDate = birthDate.format('DD/MM/YYYY');
					//newTr.age = Math.abs(moment().diff(birthDate), 'years'));
				}
			}

			return newTr;
		}).filter(traveller =>{
			if(['edit-travellers','edit'].includes(this.checkoutData.mode )) return true;
			// TODO: check if traveller data is changed
			return true;
		});
	}

	mapBillingData() {
		let billingData = this.checkoutData.billingFormData;
		if(!billingData){
			console.warn('no billing data found', this.checkoutData);
			return;
		}
		let newBillingData = null;
		if (billingData.person_type === 'LEGAL') {
			newBillingData = {
				name: _.get(billingData, 'profile_name'),
				personType: _.get(billingData, 'person_type'),
				vatNumber: _.get(billingData, 'p_iva'),
				businessName: _.get(billingData, 'business_name'),
				recipientCode: _.get(billingData, 'recipient_code'),
				certifiedMail: _.get(billingData, 'pec'),
				address: {
					country: _.get(billingData, 'country'),
					province: _.get(billingData, 'province'),
					city: _.get(billingData, 'city'),
					address: _.get(billingData, 'address'),
					postalCode: _.get(billingData, 'cap'),
				}
			}
		} else if(billingData.person_type === 'INDIVIDUAL') {
			newBillingData = {
				//name: _.get(billingData, 'profile_name'),
				firstName: _.get(billingData, 'tc_name'),
				lastName: _.get(billingData, 'tc_surname'),
				personType: _.get(billingData, 'person_type'),
				fiscalCode: _.get(billingData, 'tc_tax_code'),
				recipientCode: _.get(billingData, 'tc_recipient_code'),
				certifiedMail: _.get(billingData, 'tc_pec'),
				address: {
					country: _.get(billingData, 'tc_country'),
					province: _.get(billingData, 'tc_province'),
					city: _.get(billingData, 'tc_city'),
					address: _.get(billingData, 'tc_address'),
					postalCode: _.get(billingData, 'tc_cap'),
				}
			}
		}
		return newBillingData
	}

	checkContinue(){
		if(this.step == 2){
			if( _.get(this.checkoutData,'selectedSolution') && _.get(this.checkoutData,'dataAv.selectedOffer')){
				this.trnService.continue.next(true);
			}else {
				this.trnService.continue.next(false);
			}
		}else if(this.step == 3){
			if(_.get(this.checkoutData,'seatSel') == _.get(this.checkoutData,'seatsCount')){
				this.trnService.continue.next(true);
			}else{
				this.trnService.continue.next(false);
			}
		}
	}

	checkConfirm(){
		if(this.checkoutData.mode == 'carnet'){
			if(this.seatsSel){
				this.confirm = _.get(this.checkoutData,'selectedSolution') &&
					_.get(this.checkoutData.carnetData,'selectedService') &&
					(_.get(this.checkoutData,'seatSel')=== _.get(this.checkoutData,'seatsCount'));
			}else{
				this.confirm = Boolean(_.get(this.checkoutData,'selectedSolution') &&
					_.get(this.checkoutData.carnetData,'selectedService'));
			}
		}else{
			this.confirm =
				_.get(this.checkoutData,'finalTravellers')
				&& this.checkoutData.finalTravellersValid
				&& (!this.checkoutData.invoiceRequested || this.checkoutData.billingFormValid);
		}
	}

	setSeats(){
		let seatMaps = this.checkoutData.seatMaps;
		this.checkoutData.dataAv.seatMap = [];
		this.checkoutData.selectedSolution.trains.forEach(t => {
			t.seats = [];
			wrapArray(this.checkoutData.travellers).forEach((pass,k) => {
				if(_.get(this.checkoutData.seatMaps,[t.id,'travellers',pass.xmlId,'seat'])){
					const seatData:any = _.get(this.checkoutData.seatMaps,[t.id,'travellers',pass.xmlId,'seat']);
					let offer:any;
					for(const offered of this.checkoutData.dataAv.selectedOffer ) {
						const off: any = _.get(this.checkoutData, ['selectedSolution', 'offeredServices', offered.id]);
						if (off && off.nodeId == t.id && off.travellerXmlId == pass.xmlId) {
							offer = off;
						}
					}

					this.checkoutData.dataAv.seatMap.push({
						seatNum: seatData.seatNum,
						wagon: seatData.wagon,
						airCraftNumber:seatData.seatNumToShow,
						isFree:offer.is_seat_selection_free,
						offeredServicesId: offer.id
					});
					t.seats.push({
						type: t.type,
						seat: seatData.seatNumToShow
					});
				}

			});
		});
	}

	changeStepTrain(num:number){
		this.trnService.trainSeatStep.next(this.trainSeatStep + num);
	}

	protected readonly undefined = undefined;
}