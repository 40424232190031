import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { gygFieldsMap } from '../../fields/touroperators/gyg.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	gygFieldsMap.get('enabled'),
	/*{
		config: {
			flexLayout: 'row'
		},
		fields: [
			gygFieldsMap.get('parameters.username'),
			gygFieldsMap.get('parameters.password'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			gygFieldsMap.get('parameters.supplier_username'),
			gygFieldsMap.get('parameters.supplier_password'),
		]
	},*/
	gygFieldsMap.get('entity_customer.entity_customer_id'),
	gygFieldsMap.get('entity_customer.description'),
	gygFieldsMap.get('branch.branch_id'),
	gygFieldsMap.get('branch.code'),
	gygFieldsMap.get('scenario'),
];

const gygForm: IFormConfig = {
	editTitle: 'Modifica Configurazioni Get Your Guide',
	fields: formFields 
};

export default gygForm;
