import { Injectable } from '@angular/core';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { DatasetService } from '../../services/dataset.service';
import { ListViewItemTypes, IListViewConfig, IListFilterParam } from 'app/interfaces';
import { DatasetRecordService } from '../../services/dataset-record.service';

@Injectable()
export class DatasetListService implements IListFilterParam{

	public currentPage = 1;
	public perPage = 10;
	public viewConfig: IListViewConfig;
	public sortBy: string;
	public sortDirection: string;
	public parentDatasetRS: DatasetRecordService;
	public itemViewType: ListViewItemTypes;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService
	){}

	public getParams(): any{
		let params: any = Object.assign({}, {
			page: this.currentPage,
			perPage: this.perPage
		}, this.datasetACS.datasetData['filters']);
		if (this.viewConfig){
			if(!params.sortBy) params.sortBy = this.viewConfig.defaultSortBy;
			if(!params.sortDirection) params.sortDirection = this.viewConfig.defaultSortDirection;
		}
		if(this.sortBy) params.sortBy = this.sortBy;
		if(this.sortDirection) params.sortBy = this.sortDirection;
		const actionConfig = this.datasetACS.actionConfig;
		if(actionConfig){
			if(typeof( actionConfig.getCallParams) === 'function'){
				params = actionConfig.getCallParams(this.datasetACS, this.parentDatasetRS && this.parentDatasetRS.record.value, params);
			}
		}

		for (const key in params) {
			if (!Object.prototype.hasOwnProperty.call(params, key)) continue;
			const element = params[key];
			if(element instanceof Array){
				params[key] = element.join(',');
			}
		}

		if(this.itemViewType === ListViewItemTypes.CALENDAR){
			params['for_calendar'] = 1;
			params['perPage'] = 200;
		}
		
		return params;
	}
}
