import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
	selector: 'invalid-allotements-dialog',
	templateUrl: 'dialog.component.html'
})
export class InvalidAllotmentsDialogComponent implements OnDestroy, OnInit {

	loading = false;
	protected _unsubscribeAll: Subject<any>;
	public datasetACS: DatasetActionContainerService;
	public invalidItems: any[];
	public displayedColumns = ['day', 'consumed', 'allotment_max', 'details'];
	public hasMore = false;

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<InvalidAllotmentsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fuseTranslationLoader: FuseTranslationLoaderService
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{
		this.datasetACS = this.data.datasetACS;
		this.invalidItems = this.data.items;
		if(this.data.count && this.data.items){
			this.hasMore = this.data.count > this.data.items.length;
		}else {
			this.hasMore = false;
		}
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
