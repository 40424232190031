import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { toursFieldsMap } from './fields';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

toursFieldsMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: value.key });
	fieldsMap.set(key, value);
});

fieldsMap.set('parameters.username', {
	key: 'parameters.username',
	name: 'Inbound Username',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	formValidators: [ Validators.required ]
});

fieldsMap.set('parameters.password', {
	key: 'parameters.password',
	name: 'Inbound Password',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.PASSWORD,
		required: true
	}
});

fieldsMap.set('parameters.supplier_username', {
	key: 'parameters.supplier_username',
	name: 'Outbound Username',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT
	}
});

fieldsMap.set('parameters.supplier_password', {
	key: 'parameters.supplier_password',
	name: 'Outbound Password',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.PASSWORD
	}
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService): string{
		return 'touroperators.gyg';
	}
});

export {fieldsMap as gygFieldsMap};
