import * as tslib_1 from "tslib";
import { OnInit, QueryList } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
var CommonI18nBlocksStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonI18nBlocksStepComponent, _super);
    function CommonI18nBlocksStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        return _this;
    }
    CommonI18nBlocksStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.datasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentValueKey
            });
            _this.datasetMCS.fetchRecord();
        });
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            var e_1, _a;
            _this.blockFilters = [];
            try {
                for (var _b = tslib_1.__values(_this.stepConfig.options.blocks), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var blockConfig = _c.value;
                    var blockFilter = {};
                    if (blockConfig.filters) {
                        blockFilter = blockConfig.filters(_this, _this.datasetRS.record.value);
                    }
                    _this.blockFilters.push(blockFilter);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    CommonI18nBlocksStepComponent.prototype.onCreatedInBlock = function (blockConfig) {
        var _this = this;
        if (blockConfig.updateParent) {
            this.datasetRS.reload()
                .subscribe(function () {
                var block = _this.listBlocks.find(function (elementRef) {
                    return elementRef.datasetCode === blockConfig.datasetCode;
                });
                if (block)
                    block.reload();
            });
        }
    };
    return CommonI18nBlocksStepComponent;
}(BaseStepViewComponent));
export { CommonI18nBlocksStepComponent };
