import { InputTypes, ValueTypes, IFormConfig } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { supportedLanguages } from 'app/configs/datasets/actual_trips';

import {fieldsMap } from 'app/configs/datasets/actual_trips/fields';
import { Validators } from '@angular/forms';

export const meetingPointRemoteOptions = {
	limit: 50,
	getParams(dataContainerService: IDataContainerService, data: any): any{
		const params = {
			properties_parent_dataset_code: 'trip_packages',
			with_items: 1
		};
		// console.log('meeting_point_list_id.getParams', dataContainerService);
		const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
		if(tripPackageId){
			params['properties_parent_id'] = tripPackageId;
		}
		return params;
	},
	getLabel(option: any): string{
		if(!option) return '---';
		let label = option.description;
		if(option.properties && option.properties.first_time && option.properties.first_time.value && option.properties.first_time.value !== '-'){
			label += ' (' + option.properties.first_time.value.substring(0, 5) + ')';
		}else if(option.items_count > 0){

		}
		return label;
	},
	getValue(option): string{
		return option.id;
	}
};

export default {
	fields: [
		{
			key: 'meeting_point_list_id',
			name: 'Lista punti di ritrovo',
			inputType: InputTypes.DATASET_MULTISELECT,
			valueType: ValueTypes.ARRAY,
			datasetCode: 'meeting_point_unified_lists',
			remoteOptions: meetingPointRemoteOptions
		},
		{
			key: 'return_meeting_point_list_ids',
			name: 'Lista punti di ritorno',
			inputType: InputTypes.DATASET_MULTISELECT,
			valueType: ValueTypes.ARRAY,
			datasetCode: 'meeting_point_unified_lists',
			inputHint: 'Se impostati verranno generati i ritorni',
			remoteOptions: meetingPointRemoteOptions
		},
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'start_day',
					name: 'Data inizio partenze',
					inputType: InputTypes.DATE,
					valueType: ValueTypes.DATE
				},
				{
					key: 'end_day',
					name: 'Data fine partenze',
					inputType: InputTypes.DATE,
					valueType: ValueTypes.DATE
				}
			]
		},
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'length',
					name: 'Durata in ore',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER,
					disallowNull: false,
					defaultValue(): any{
						return null;
					}
				},
				{
					key: '_length_type', // not use for now
					name: 'Tipo durata',
					inputConfig: {
						type: InputTypes.HIDDEN
					},
					valueType: ValueTypes.STRING
				}
			]
		},
		{
			key: 'week_day',
			name: 'Giorno della settimana',
			inputType: InputTypes.MULTI_SELECT,
			valueType: ValueTypes.ARRAY,
			options: [
				{
					label: 'Lunedì',
					value: 'monday'
				},
				{
					label: 'Martedì',
					value: 'tuesday'
				},
				{
					label: 'Mercoledì',
					value: 'wednesday'
				},
				{
					label: 'Giovedì',
					value: 'thursday'
				},
				{
					label: 'Venerdì',
					value: 'friday'
				},
				{
					label: 'Sabato',
					value: 'saturday'
				},
				{
					label: 'Domenica',
					value: 'sunday'
				}
			],
			defaultValue(): any{
				return ['monday'];
			}
		},
		{
			config: {
				flexLayout: 'row'
			},
			fields: [
				{
					key: 'min_allotment',
					name: 'Allottment minimo',
					inputType: InputTypes.NUMBER,
					valueType: ValueTypes.NUMBER,
					defaultValue(): any{
						return 0;
					}
				},
				{
					key: 'properties_default_max_allotment',
					name: 'Allotment specifico',
					valueType: ValueTypes.NUMBER,
					inputConfig: {
						type: InputTypes.NUMBER,
						clearable: true,
					},
					defaultValue(): any{ return null; }
				}
			]
		},
		fieldsMap.get('create_start_status'),
		{
			config: {
				flexLayout: 'row',
			},
			fields: [
				{
					key: 'actual_trip_guide_languages',
					name: 'Lingua',
					inputType: InputTypes.MULTI_SELECT,
					valueType: ValueTypes.ARRAY,
					options: supportedLanguages,
					width: '250px'
				},
				{
					key: 'actual_trip_audioguide_languages',
					name: 'Lingua con audioguida',
					inputType: InputTypes.MULTI_SELECT,
					valueType: ValueTypes.ARRAY,
					options: supportedLanguages,
					width: '250px'
				}
			]
		},
		{
			key: 'note',
			name: 'Note',
			inputType: InputTypes.TEXTAREA,
			valueType: ValueTypes.STRING
		}
	]
} as IFormConfig;
