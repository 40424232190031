import { InputTypes, ValueTypes, IFormConfig } from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';


export default {
	fields: [
		{
            key: 'email',
            name: 'Email',
            inputType: InputTypes.EMAIL,
            valueType: ValueTypes.STRING,
            formValidators: [ Validators.required ],
        },
	]
} as IFormConfig;
