<div class="page-layout blank simple left-sidebar common-stepper" [ngClass]="stepViewConfig.tangerineBgColor" fxFlexFill>
	<div class="top-bg accent"></div>
	
	<fuse-sidebar class="sidebar" name="page-sidebar" position="left" lockedOpen="gt-md">
		<div class="header p-24" [ngClass]="stepViewConfig.tangerineBgColor || 'accent'">
			<h2 class="m-0">{{stepViewConfig.title | translate}}</h2>
			<span *ngIf='record && subtitle' class='mt-0 h4 secondary-text'>{{subtitle | translate}}</span>
		</div>
		<div class="content" [class.overview-visible]='showOverview'>
			<div *ngIf='stepViewConfig.showOverview && record' class='m-4' fxLayout='row' fxLayoutAlign='center'>
				<button [ngClass]="stepViewConfig.tangerineBgColor" *ngIf='showOverview' mat-flat-button color='primary' style='width:160px;'>
					<span>Overview</span>
				</button>
				<button *ngIf='!showOverview' mat-stroked-button [color]='stepViewConfig.tangerineBgColor || "primary"' style='width:160px;' (click)='onClickOverview()'>
					<span>Overview</span>
				</button>
			</div>
			
			<mat-vertical-stepper [selectedIndex]='stepperIndex' #stepper [linear]="isStepLinear" (click)='showOverview = false;buildSteps();' (selectionChange)='stepChange($event)'>
				<ng-container *ngFor='let computedStep of computedSteps;trackBy: trackStepBy;'>
					<mat-step [completed]='computedStep.completed'>
						<ng-template matStepLabel>
							<span [innerHTML]='computedStep.title | translate'></span>
						</ng-template>
					</mat-step>
				</ng-container>
			</mat-vertical-stepper>
		</div>

	</fuse-sidebar>
	
	<div class="center">
		<div class="header accent p-24 header-mobile" [ngClass]="stepViewConfig.tangerineBgColor" fxLayout="row" fxLayoutAlign="start center" fxHide.gt-md>
			<button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
					(click)="toggleSidebar('page-sidebar')">
				<mat-icon>menu</mat-icon>
			</button>
			<div>
				<h2 class="m-0">{{stepViewConfig.title | translate}}</h2>
				<span *ngIf='record && subtitle' class='mt-0 h4 secondary-text'>{{subtitle | translate}}</span>
			</div>
		</div>
		<div class="content p-24">
			<ng-container *ngIf='showOverview'>
				<ng-container [ngSwitch]='datasetACS.datasetCode'>
					<trip-package-overview *ngSwitchCase='"trip_packages"'></trip-package-overview>
				</ng-container>
			</ng-container>
			<mat-tab-group *ngIf='!showOverview' class='tab-hidden-header' [selectedIndex]='stepIndex'>
				<ng-container *ngFor='let stepConfig of steps;let i = index;'>
					<mat-tab>
						<ng-template matTabContent>
							<h2><button class='circular-flat-button only_tangerine_bg' mat-flat-button [color]="stepViewConfig.tangerineBgColor || 'accent'">{{i + 1}}</button> {{stepConfig.title | translate}}</h2>
							<ng-container [ngSwitch]='stepConfig.component'>

								<!-- ENTITY STEPS -->
								<entity-types-step
									*ngSwitchCase='"entity-types-step"'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></entity-types-step>
								<entity-b2b-step
									*ngSwitchCase='"entity-b2b-step"'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></entity-b2b-step>

								<!-- TRIP PACKAGES STEPS -->
								<trip-package-step-1
									*ngSwitchCase='"trip-package-step-1"' 
									[parentDatasetRS]='datasetRS' 
									(nextStep)='stepper.next()'>
								</trip-package-step-1>
								<trip-package-step-2
									*ngSwitchCase='"trip-package-step-2"'
									[parentDatasetRS]='datasetRS'
									[parentDatasetACS]='datasetACS'
								></trip-package-step-2>
								<trip-package-step-3
									*ngSwitchCase='"trip-package-step-3"'
									[parentDatasetRS]='datasetRS'
									[parentDatasetACS]='datasetACS'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></trip-package-step-3>
								<trip-package-step-4 
									*ngSwitchCase='"trip-package-step-4"' 
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></trip-package-step-4>
								<integrations-step
									*ngSwitchCase='"trip-package-integrations-step"' 
									[parentDatasetACS]='datasetACS'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0' 
									[datasetCode]='"trip_packages"'
									[integrationsDatasetCode]='"trip_package_integrations"'
								></integrations-step>

								<trip-package-reselled-step-0
									*ngSwitchCase='"trip-package-reselled-step-0"'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[stepConfig]='stepConfig'
								>
								</trip-package-reselled-step-0>

								<trip-package-reselled-step-2
									*ngSwitchCase='"trip-package-reselled-step-2"'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[stepConfig]='stepConfig'
									[formConfig]='stepConfig.options.formConfig'
								></trip-package-reselled-step-2>
								<trip-package-reselled-step-3
									*ngSwitchCase='"trip-package-reselled-step-3"'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></trip-package-reselled-step-3>

								<!-- USER ROLE PERMISSION STEP -->
								<user-role-permission-step
									*ngSwitchCase='"user-role-permission-step"' 
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></user-role-permission-step>

								<!-- DOMAIN USER ROLE PERMISSION STEP -->
								<domain-user-role-permission-step
									*ngSwitchCase='"domain-user-role-permission-step"' 
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></domain-user-role-permission-step>

								<!-- GENERIC STEPS -->
								<common-form-step 
									*ngSwitchCase='"common-form-step"' 
									[stepConfig]='stepConfig'
									[stepIndex]='i' 
									(nextStep)='stepper.next()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-form-step>
								<common-date-step 
									*ngSwitchCase='"common-date-step"' 
									[stepConfig]='stepConfig'
									[stepIndex]='i' 
									(nextStep)='stepper.next()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-date-step>
								<common-sub-dataset-form-step 
									*ngSwitchCase='"common-sub-dataset-form-step"'
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[stepConfig]='stepConfig'
									[stepIndex]='i'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='stepper.next()'>
								</common-sub-dataset-form-step>
								<common-data-viewer-step
									*ngSwitchCase='"common-data-viewer-step"'
									[stepConfig]='stepConfig'
									[stepIndex]='i'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='stepper.next()'
								></common-data-viewer-step>
								<common-blocks-step 
									*ngSwitchCase='"common-blocks-step"' 
									[stepConfig]='stepConfig' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[stepIndex]='i' 
									(nextStep)='stepper.next()'
								></common-blocks-step>
								<common-sub-dataset-blocks-step 
									*ngSwitchCase='"common-sub-dataset-blocks-step"' 
									[stepConfig]='stepConfig'
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									(nextStep)='stepper.next()'
									[stepIndex]='i'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-sub-dataset-blocks-step>
								<common-i18n-form-step 
									*ngSwitchCase='"common-i18n-form-step"' 
									[stepConfig]='stepConfig' 
									[formConfig]='stepConfig.options.formConfig' 
									(nextStep)='stepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-i18n-form-step>
								<common-i18n-data-viewer-step
									*ngSwitchCase='"common-i18n-data-viewer-step"' 
									[stepConfig]='stepConfig' 
									[formConfig]='stepConfig.options.formConfig' 
									(nextStep)='stepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-i18n-data-viewer-step>
								<common-i18n-blocks-step 
									*ngSwitchCase='"common-i18n-blocks-step"' 
									[stepConfig]='stepConfig' 
									[stepIndex]='i'
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-i18n-blocks-step>
								<common-map-step 
									*ngSwitchCase='"common-map-step"' 
									[stepConfig]='stepConfig'
									[stepIndex]='i' 
									(nextStep)='stepper.next()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-map-step>

								<!-- TRIP CATEGORY STEP -->
								<integrations-step
									*ngSwitchCase='"trip-category-integrations-step"' 
									[parentDatasetACS]='datasetACS'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0' 
									[datasetCode]='"trip_categories"'
									[integrationsDatasetCode]='"trip_category_integrations"'
								></integrations-step>
								<div *ngSwitchDefault>
									<p>{{'Componente non configurato' | translate}} {{stepConfig.component}}</p>
								</div>
							</ng-container>
						</ng-template>
					</mat-tab>
				</ng-container>
			</mat-tab-group>
		</div>

	</div>

</div>