import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('domain_id', {
	key: 'domain_id',
	name: '',
	appearance: 'legacy',
	valueType: ValueTypes.STRING,
	customActionCode: '/auth/listFilterableDomains',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	datasetCode: 'domains',
	remoteOptions: {
		limit: 5,
		getLabel(option): any{
			if(!option) return '';
			return option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

export default fieldsMap;
