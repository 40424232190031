import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FieldViews, ListViewItemTypes } from 'app/interfaces';
import requirePlafondData from 'app/main/actions/require-plafond.action';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { DatasetListDialogComponent } from 'app/main/components/dataset/dialogs/dataset-list-dialog/dialog.component';
import editPlafondForm from 'app/configs/datasets/plafond/forms/edit.form';
var DomainPackagesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DomainPackagesComponent, _super);
    function DomainPackagesComponent(datasetACS, datasetRS, datasetNavigatorService) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        return _this;
    }
    DomainPackagesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    DomainPackagesComponent.prototype.addPlafond = function () {
        var _this = this;
        requirePlafondData(null, this.datasetACS, this.datasetNavigatorService, null)
            .then(function (data) {
            if (!data)
                return;
            var loadingDialogRef = _this.datasetNavigatorService.openDialog(ActionDialogComponent, {
                title: 'Attendere durante il completamento della richiesta...',
                contentMessage: ''
            });
            data['domainPackageId'] = _this.datasetRS.record.value.id;
            try {
                _this.datasetNavigatorService.datasetService.post('/dataset/domain_packages/command/set_plafond', data)
                    .toPromise()
                    .then(function () {
                    _this.datasetRS.reload()
                        .subscribe(function () {
                        loadingDialogRef.close();
                    });
                }, function (error) {
                    loadingDialogRef.close();
                });
            }
            catch (e) {
                throw e;
            }
        }, function (error) {
            // ignore
        });
    };
    DomainPackagesComponent.prototype.detailPlafond = function (plafond) {
        this.datasetNavigatorService.openDialog(DatasetListDialogComponent, {
            title: 'Transizioni',
            datasetCode: 'plafond_drop_items',
            parentDatasetACS: this.datasetACS,
            parentDatasetRS: this.datasetRS,
            filters: {
                plafond_id: plafond.id
            },
            viewConfig: {
                itemViewType: ListViewItemTypes.TABLE,
                defaultSortBy: 'created_at',
                defaultSortDirection: 'asc',
                showSearchBar: false,
                columns: [
                    {
                        title: 'Codice',
                        key: 'code'
                    },
                    {
                        title: 'Data',
                        key: 'date'
                    },
                    {
                        title: 'Importo',
                        key: 'amount',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record) {
                            return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(record.amount);
                        }
                    },
                    {
                        title: 'Numero Partecipanti',
                        key: 'participant_count'
                    }
                ]
            }
        });
    };
    DomainPackagesComponent.prototype.editPlafond = function () {
        var _this = this;
        requirePlafondData(this.datasetRS.record.value.plafond, this.datasetACS, this.datasetNavigatorService, editPlafondForm)
            .then(function (data) {
            if (!data)
                return;
            data['plafond_id'] = _this.datasetRS.record.value.plafond_id;
            var loadingDialogRef = _this.datasetNavigatorService.openDialog(ActionDialogComponent, {
                title: 'Attendere durante il completamento della richiesta...',
                contentMessage: ''
            });
            try {
                _this.datasetNavigatorService.datasetService.post('/dataset/domain_packages/command/update_plafond', data)
                    .toPromise()
                    .then(function () {
                    _this.datasetRS.reload()
                        .subscribe(function () {
                        loadingDialogRef.close();
                    });
                }, function (error) {
                    loadingDialogRef.close();
                });
            }
            catch (e) {
                throw e;
            }
        }, function (error) {
            // ignore
        });
    };
    DomainPackagesComponent.prototype.deletePlafond = function () {
        var _this = this;
        this.datasetNavigatorService.openDialog(ConfirmDialogComponent, {
            title: 'Vuoi rimuovere il plafond dalla richiesta?',
            contentMessage: 'Rimuovendo Il plafond, non verrà eliminato e potrai associarlo in un secondo momento.'
        })
            .afterClosed()
            .subscribe({
            next: function (confirmed) {
                if (!confirmed)
                    return;
                var data = {
                    recordId: _this.datasetRS.record.value.id
                };
                var loadingDialogRef = _this.datasetNavigatorService.openDialog(ActionDialogComponent, {
                    title: 'Attendere durante il completamento della richiesta...',
                    contentMessage: ''
                });
                _this.datasetNavigatorService.datasetService.post('/dataset/domain_packages/command/unset_plafond', data)
                    .toPromise()
                    .then(function () {
                    _this.datasetRS.reload()
                        .subscribe(function () {
                        loadingDialogRef.close();
                    });
                }, function (error) {
                    loadingDialogRef.close();
                });
            },
            error: function (excep) {
                // ignore
            }
        });
    };
    return DomainPackagesComponent;
}(BaseViewComponent));
export { DomainPackagesComponent };
