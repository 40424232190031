import {Component, EventEmitter, forwardRef,  OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DatasetActionContainerService} from '../../../../components/dataset/services/dataset-action-container.service';
import {TrenitaliaService} from '../trenitalia.service';
import {DatasetRecordService} from '../../../../components/dataset/services/dataset-record.service';
import {DatasetService} from '../../../../components/dataset/services/dataset.service';
import {PageClosureContainerService} from '../../../page-closure-container.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {AppService} from '../../../../../services/app.service';
import {catchError, exhaustMap, first, takeUntil} from 'rxjs/operators';
import {TextDialogComponent} from '../../../../dialogs/text-dialog/dialog.component';
import {EMPTY, of, Subject} from 'rxjs';
import {RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES} from '../../edit-booking.component';

@Component({
  selector: 'app-trenitalia-carnet',
  templateUrl: './trenitalia-carnet.component.html',
  styleUrls: ['../trenitalia-dialog.component.scss','./trenitalia-carnet.component.scss'],
    providers: [
        forwardRef(() => PageClosureContainerService),
        forwardRef(() => DatasetActionContainerService),
		TrenitaliaService
    ]
})
export class TrenitaliaCarnetComponent implements OnInit {
    public loading=false;
    private checkoutData;
    public total;
    public step;
    public tab;
    protected continue;
    public confirm=false;
    protected _unsubscribeAll: Subject<any>;
    private data;

	@Output('complete') complete = new EventEmitter();
  constructor(
              public appService:AppService,
              public datasetACS: DatasetActionContainerService,
              public trnService: TrenitaliaService,
              private _snackBar: MatSnackBar,
              public datasetRs: DatasetRecordService,
              public matDialog: MatDialog,
              private datasetService: DatasetService,
              /*@Inject(MAT_DIALOG_DATA) public data: any*/
  ) {
                this._unsubscribeAll = new Subject<any>();
  }

  ngOnInit() {
      this.data = {
          participant: this.datasetRs.record.value.participant_counters,
          trip_booking_id: this.datasetRs.record.value.id,
      }
      this.trnService.init();
      this.checkoutData=this.trnService.checkoutData.getValue();
      this.checkoutData.tripBookingId = this.data.trip_booking_id;
      this.checkoutData.adult = 1;
      this.checkoutData.children =  0;
      this.trnService.checkoutData.next(this.checkoutData);
      this.trnService.checkoutData.subscribe(checkout=>{
          this.checkoutData = checkout;
          if(this.checkoutData.user && (!this.checkoutData.invoiceRequested || this.checkoutData.billingFormValid))this.confirm=true;
      })
      this.trnService.total.subscribe(total =>{
          this.total = total;
      })
      this.trnService.step.subscribe(step =>{
          this.step = step;
          this.tab = Math.min(Math.max(0, step - 1), 3);
      })
      this.trnService.continue.subscribe(cont =>{
          this.continue = cont;
      })
  }

  search(){
      this.loading=true;
      const data = {
          adult:this.checkoutData.adult,
          children:this.checkoutData.children,
          direction :'ticket',
          start: this.checkoutData.start,
          from: this.checkoutData.from,
          to:this.checkoutData.to,
          carnetId: this.checkoutData.carnetId
      }
      this.trnService.searchAvailablesCarnet(data).subscribe(carnets=>{
          this.loading = false;
          if(_.isEmpty(carnets)){
              this._snackBar.open('Nessuna soluzione trovata!', null, {
                  duration: 5000
              });
          }else{
              this.checkoutData.searchSolutions = carnets
              this.trnService.checkoutData.next(this.checkoutData);
              this.trnService.step.next(2);
          }
      })
  }
    confirmBooking(){
      this.loading = true;
        let travellersData = this.mapTravellers();
      const data = {
          travelSolutions: _.get(this.checkoutData,'searchSolutions.travelSolutions[0].original'),
          travellers:travellersData,
          selectedOffer: _.get(this.checkoutData,'selectedOffers'),
          withSeatsSelection: false,
          evaluationType: 'carnet'
      }
      this.trnService.getAvailability(data)
          .pipe(takeUntil(this._unsubscribeAll))
          .pipe(
              first(),
              exhaustMap((resp) => {
                  this.checkoutData.evaluate= resp;
                  const invoiceProfileId = this.checkoutData.billingFormData && this.checkoutData.billingFormData.id_invoice_profile;
                  if (invoiceProfileId) this.checkoutData.invoiceProfile = {id: invoiceProfileId};

                  if(this.checkoutData.invoiceRequested && !_.has(this.checkoutData, 'invoiceProfile.id') && !invoiceProfileId){
                      // TODO: call server for create invoiceProfile if needed /command/create_invoice_profiles
                      // set res.invoiceProfile
                      // ATTENTION return the observable after the http request
                      return this.datasetService.post('/dataset/trenitalia/command/create_invoice_profiles', this.mapBillingData())
                          .pipe(takeUntil(this._unsubscribeAll))
                          .pipe(
                              catchError((err) => {
                                  this.matDialog
                                      .open(TextDialogComponent, {
                                          data: {
                                              title: 'Attenzione',
                                              contentMessage: err.message
                                          }
                                      });
                                  return EMPTY;
                              }),
                              exhaustMap((creationResponse: any) => {
                                  const invoiceProfileId = creationResponse.invoiceProfileId;
                                  if (!invoiceProfileId) {
                                      this.matDialog
                                          .open(TextDialogComponent, {
                                              data: {
                                                  title: 'Attenzione',
                                                  contentMessage: "ID invoiceProfile non presente"
                                              }
                                          });
                                      return EMPTY
                                  }
                                  this.checkoutData.invoiceProfile = {id: invoiceProfileId}
                                  return of(creationResponse)
                              })
                          );
                  }
                  return of(resp);
              })
          ).subscribe(resp=>{
          if (!this.checkoutData.evaluate.validSelection) {
              this.appService.showErrorMessage('Dati non validi');
              return;
          }
          let res:any = {
              travellers: _.get(this.checkoutData.evaluate,'travellers'),
              solution: _.get(this.checkoutData.evaluate,'travelSolutions'),
              orderType: 'carnet'
          };
          if(this.checkoutData.invoiceRequested && this.checkoutData.invoiceProfile) {
                res.invoiceProfile = this.checkoutData.invoiceProfile;
                res.withInvoice = true;
		    }
          const postData = {
              trip_booking_id: this.datasetRs.recordId,
              target: 'trenitalia',
              target_info: res
          };
          let actionEndPoint =
              '/dataset/trip_booking_packages/create';
          /*if (tripBookingPackageToReplace) {
              postData['trip_booking_package_id_to_replace'] =
                  tripBookingPackageToReplace.id;
              actionEndPoint =
                  '/dataset/trip_bookings/command/replace_trip_external_service';
          }*/
          return this.datasetService.post<any>(actionEndPoint, postData).subscribe({
              next: response => {
                  this.appService.appEvent.next({
                      name: RELOAD_BOOKING_PACKAGES
                  });
                  this.loading = false;
				  this.complete.emit();
                  this.trnService.reset();
                  this.trnService.loading.next(false);
              },
              error: (err) => {
                  this.loading = false;
              }
          });
      })
    }
    mapTravellers() {
        let travellers = this.checkoutData.searchSolutions.travellers;
        if(!travellers){
            console.warn('no travellers found', this.checkoutData);
            return;
        }
        return travellers.map(tr => {
            const data = this.checkoutData.finalTravellers;
            const newTr = Object.assign({}, tr);
            newTr.name = _.get(data, 'first_name', tr.name);
            newTr.surname = _.get(data, 'last_name', tr.surname);
            newTr.email = _.get(data, 'email', tr.email);
            newTr.cell = _.get(data, 'phone', tr.cell || tr.phoneNumber);
            newTr.cartaFreccia = _.get(data, 'cartafreccia', tr.cartaFreccia);
            if (_.has(data, 'dataPass.data_n')) {
                const birthDate = moment(_.get(data, 'data_n'));
                if (birthDate.isValid()) {
                    newTr.birthDate = birthDate.format('DD/MM/YYYY');
                    //newTr.age = Math.abs(moment().diff(birthDate), 'years'));
                }
            }
            return newTr;
        }).filter(traveller =>{
            if(this.checkoutData.mode != 'edit-travellers') return true;
            // TODO: check if traveller data is changed
            return true;
        });
    }

    back() {
        if(this.step==2){
            this.checkoutData.travelSolutions=[];
            this.trnService.step.next(1);
            this.trnService.checkoutData.next(this.checkoutData);
        }else if(this.step == 3){
			this.trnService.step.next(2);
		}
    }

    mapBillingData() {
        let billingData = this.checkoutData.billingFormData;
        if(!billingData){
            console.warn('no billing data found', this.checkoutData);
            return;
        }
        let newBillingData = null;
        if (billingData.person_type === 'LEGAL') {
            newBillingData = {
                name: _.get(billingData, 'profile_name'),
                personType: _.get(billingData, 'person_type'),
                vatNumber: _.get(billingData, 'p_iva'),
                businessName: _.get(billingData, 'business_name'),
                recipientCode: _.get(billingData, 'recipient_code'),
                certifiedMail: _.get(billingData, 'pec'),
                address: {
                    country: _.get(billingData, 'country'),
                    province: _.get(billingData, 'province'),
                    city: _.get(billingData, 'city'),
                    address: _.get(billingData, 'address'),
                    postalCode: _.get(billingData, 'cap'),
                }
            }
        } else if(billingData.person_type === 'INDIVIDUAL') {
            newBillingData = {
                //name: _.get(billingData, 'profile_name'),
                firstName: _.get(billingData, 'tc_name'),
                lastName: _.get(billingData, 'tc_surname'),
                personType: _.get(billingData, 'person_type'),
                fiscalCode: _.get(billingData, 'tc_tax_code'),
                recipientCode: _.get(billingData, 'tc_recipient_code'),
                certifiedMail: _.get(billingData, 'tc_pec'),
                address: {
                    country: _.get(billingData, 'tc_country'),
                    province: _.get(billingData, 'tc_province'),
                    city: _.get(billingData, 'tc_city'),
                    address: _.get(billingData, 'tc_address'),
                    postalCode: _.get(billingData, 'tc_cap'),
                }
            }
        }
        return newBillingData
    }

}
