import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from '../fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import userActionReasonField from 'app/configs/commons/shared-field-configs/user-action-reason.field';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('description'),
	fieldsMap.get('trip_service_id'),
	fieldsMap.get('count'),
	fieldsMap.get('price'),
	fieldsMap.get('commission_discount_percent'),
	{
		key: 'manual',
		name: '',
		valueType: ValueTypes.STRING,
		inputConfig: {
			type: InputTypes.HIDDEN
		},
		defaultValue(): any{ 
			return 1;
		}
	},
	cloneFieldDefinition(userActionReasonField, {
		inputHint: 'Motiva questa modifica'
	})
];

const baseInfoFormConfig: IFormConfig = {
	fields
};

export default baseInfoFormConfig;
