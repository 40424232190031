<h1 *ngIf='data.title' mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<div [innerHTML]="data.contentMessage"></div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	
	<button mat-flat-button (click)='onYesClick()' color='accent' >{{data.positiveText?data.positiveText:'chiudi' | translate}}</button>
</mat-dialog-actions>
