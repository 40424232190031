import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import {AuthService, CheckUserStatues} from 'app/services/auth.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';

import {Subject} from 'rxjs';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'app-login',
	templateUrl: './login.component.html',
	styleUrls  : ['./login.component.scss'],
	animations : fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy
{
	loginForm: FormGroup;
	public loginFormErrors: any = { 
		access_username: {},
		password: {}
	};
	lastLoginErrorMessage: string;
	private _unsubscribeAll: Subject<any>;
	public navigating = false;
	public logging = false;

	constructor(
		private router: Router,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private _fuseConfigService: FuseConfigService,
		private _fuseSplashScreenService: FuseSplashScreenService
	){
		this.loginFormErrors = {
			access_username: {},
			password: {}
		};
		this._fuseConfigService.config = {
			layout: {
				navbar   : {
					hidden: true
				},
				toolbar  : {
					hidden: true
				},
				footer   : {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{

		this.loginForm = this.formBuilder.group({
			access_username: new FormControl('', Validators.required),
			password:  new FormControl('', Validators.required),
			rememberMe:  new FormControl(true, [])
		});

		this.loginForm.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.onLoginFormValuesChanged();
		});

		if(this.authService.chekcUserStatus.value === CheckUserStatues.CHECKED){
			this._fuseSplashScreenService.hide();
		}

		this.authService.chekcUserStatus
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(status => {
			if(status === CheckUserStatues.CHECKED){
				this._fuseSplashScreenService.hide();
			}
		});

		this.authService.userDataObserver
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			if(!data) return;

			const navigatePath = this.authService.desiredPath || 'home';
			let queryParams = null;
			if(this.authService.desiredPath){
				queryParams = Object.assign({}, this.authService.desiredQueryParams);
				this.authService.desiredQueryParams = null;
			}
			this.authService.desiredPath = null;
			this.router.navigate([navigatePath], {
				replaceUrl: true,
				queryParams: queryParams
			});
		});

		this.router.events
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((event: any): void => {
			this.navigationInterceptor(event);
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
	
	navigationInterceptor(event): void {
		if(event instanceof NavigationStart) {
			this.navigating = true;
		}
		if(event instanceof NavigationEnd) {
			this.navigating = false;
		}
	}
	onLoginFormValuesChanged(): void{
		for ( const field in this.loginFormErrors ){
			if ( !this.loginFormErrors.hasOwnProperty(field) ){
				continue;
			}

			// Clear previous errors
			this.loginFormErrors[field] = {};

			// Get the control
			const control = this.loginForm.get(field);

			if ( control && control.dirty && !control.valid ){
				this.loginFormErrors[field] = control.errors;
			}
		}
	}

	onLoginClick(): void{
		this.lastLoginErrorMessage = null;
		this.logging = true;
		this.loginForm.disable();
		this.authService.login({
			access_username: this.loginForm.value.access_username,
			password: this.loginForm.value.password,
			remember: this.loginForm.value.rememberMe
		}).then(() => {
			// this.router.navigate(['home']);
			this.logging = false;
			this.loginForm.enable();
		}, response => {
			this.logging = false;
			this.loginForm.enable();
			this.lastLoginErrorMessage = 'Credenziali non corrette';
			if (response instanceof HttpErrorResponse && response.error && response.error.message) {
				this.lastLoginErrorMessage = response.error.message;
				if(response.error.errors){
					// add field error
				}
			}
		});
	}
}
