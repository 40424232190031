import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectComponent } from './language-select.component';

@NgModule({
	declarations: [
		LanguageSelectComponent
	],
	imports     : [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatCardModule,
		MatTooltipModule,

		FuseSharedModule,
		TranslateModule.forChild()
	],
	exports: [
		LanguageSelectComponent
	]
})
export class LanguageSelectModule{}
