<div class="style-1">
    <div mat-dialog-title>
        <!--<span class="material-icons-outlined dialog-close-icon" style="float:right;" (click)='onCloseClick()'>close</span>-->
        <div>
            <mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || trnService.loading.value'></mat-progress-bar>
        </div>
    </div>

    <mat-dialog-content>
        <!--<div class="step-header-container">
            <mat-step-header
                [tabIndex]="0"
                [state]="step>1 ? 'done' : 'number'"
                [index]="0"
                label="Ricerca"
                [selected]="step == 1"
                [active]="step>=1"
                id="step-header-1"
            ></mat-step-header>
            <hr>
            <mat-step-header
                [tabIndex]="1"
                [state]="step>2 ? 'done' : 'number'"
                [index]="1"
                label="Scelta Carnet"
                [selected]="step == 2"
                [active]="step>=2"
                id="step-header-2"
            ></mat-step-header>
            <hr>
            <mat-step-header
                [tabIndex]="2"
                [state]="step>3 ? 'done' : 'number'"
                [index]="2"
                label="Dati"
                [selected]="step == 3"
                [active]="step>=3"
                id="step-header-3"
            ></mat-step-header>
        </div>-->
        <mat-tab-group class='tab-hidden-header' [(selectedIndex)]="tab">
            <mat-tab disabled>
                <div *ngIf="step>=1">
                    <trenitalia-search-carnet></trenitalia-search-carnet>
                </div>
            </mat-tab>
            <mat-tab disabled>
                <div *ngIf="step>=2">
                    <trenitalia-search-carnet></trenitalia-search-carnet>
                    <trenitalia-select-carnet></trenitalia-select-carnet>
                </div>
            </mat-tab>
            <mat-tab disabled>
                <div *ngIf="step>=3">
                    <trenitalia-travellers-carnet></trenitalia-travellers-carnet>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>
    <div style="text-align: right" >
        <!--<button mat-raised-button (click)="back()" *ngIf="step>1" >Indietro</button>-->
        <button mat-raised-button (click)="confirmBooking()" *ngIf="step==3" [disabled]="!confirm">Conferma</button>
    </div>
</div>