import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './../fields';

const detailFormFields: (IFieldContainer|IFieldDefinition)[] = [
	
	fieldsMap.get('access_username'),
	fieldsMap.get('name'),
	fieldsMap.get('email'),
	fieldsMap.get('role_id'),
	fieldsMap.get('password_input'),
	fieldsMap.get('password_confirm')
];

export default {
	fields: detailFormFields 
};
