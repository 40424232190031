import { PathLocationStrategy, PlatformLocation, APP_BASE_HREF, Location } from "@angular/common";
import { Inject, Injectable, Optional } from "@angular/core";

@Injectable()
export class AppLocationStrategy extends PathLocationStrategy{

	private basePath: string = null;

	constructor(
		private __platformLocation: PlatformLocation,
		@Optional() @Inject(APP_BASE_HREF) href?: string
	) {
		super(__platformLocation, href);

		if(href && href.startsWith('http')){
			let url = new URL(href);
			if(url.pathname != '/'){
				this.basePath = url.pathname.replace(/\/$/, '');
			}
		}
	}

	path(includeHash: boolean = false): string {
		let pathname = super.path(includeHash);
		
		if(this.basePath && pathname.startsWith(this.basePath)){
			return pathname.replace(this.basePath, '');
		}
		return pathname;
	}
}