import { Component, ViewChild, Input } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { IStepConfig } from 'app/interfaces';
import { InvalidAllotmentsDialogComponent } from '../../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'common-date-step',
	templateUrl: './step.component.html'
})
export class CommonDateStepComponent extends BaseStepViewComponent{
	
	@Input() public stepConfig: IStepConfig;
	@Input() public stepIndex: number;
	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;
	public formErrors: any;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		protected matDialog: MatDialog
	){
		super(datasetACS, datasetNavigatorService);

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(this.datasetRS.recordId){
				this.canEdit = this.datasetACS.canEdit(this.datasetRS.record.value);
			}else{
				// is a new record
				this.canEdit = true;
			}
		});
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = {...this.formViewer.getFormData(), recordId: this.datasetRS.recordId };
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof( actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		if(this.stepConfig.options && typeof(this.stepConfig.options.getCallParams) === 'function'){
			formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
		}
		this.datasetACS.loading.next(true);
		//const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/command/set_time_meeting_point';
		/*
		if(this.datasetRS.recordId){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}else{
			formData['lang'] = this.appService.language.value;
		}
		*/
		const headers: HttpHeaders = this.datasetACS.getContextualDomainHeaders();
		this.datasetService.post<any>(route, formData, {headers: headers})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.clearForm();
				}
				if(!this.datasetRS.record.value){
					this.datasetNavigatorService.router.navigate(['dataset/edit/' + this.datasetACS.datasetCode, response.id], { queryParams: { step: 1 } });
				}else{
					this.datasetRS.recordId = response.id;
					this.datasetRS.reload()
					.subscribe(value => {
						if(value){
							setTimeout(() => {
								this.goNextStep();
							}, 200);
						}else this.datasetNavigatorService.onEdit(this.datasetACS, response);
					});
				}
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}
				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			}
		});
	}
}
