import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../../base-view.component';
import { AuthService } from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';

import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { IFormConfig } from 'app/interfaces';

import { DomainConfigs } from 'app/configs/datasets/domain_configs';

@Component({
	selector: 'domain-configs',
	templateUrl: './domain-configs.component.html',
	styleUrls: ['./domain-configs.component.scss']
})
export class DomainConfigsComponent extends BaseViewComponent implements OnInit {

	@Input() public record: any;
	@Input() public index: any;
	@Input() public rowDb: any;
	@Input() public datACS: IDataContainerService;
	@Input() public viewFormConfig: any;
	@Input() public showActions = true;
	@Input() public elevate = true;
	
	public formConfig: IFormConfig;
	customActionCode: any;
	btnText = 'Modifica';
	canEdit = true;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService
	)
	{
		super(datasetACS, datasetRS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.formConfig = DomainConfigs.formConfigsMap[this.viewFormConfig];
		this.customActionCode = 'domain_configs';

		/*if (this.isNew()){
			this.btnText = 'Crea';
		}*/
	}

	computeCanEdit(): void {
		this.canEdit = this.datasetACS.canEdit(this.rowDb);
	}

	isNew(): boolean {
		return this.rowDb.has_domain_configs === 0;
	}

	onPerformAction(): void {
		/*if (this.isNew()) {
			this.onCreate();
		} else {
			this.onEdit();
		}*/
		this.onEdit();
	}

	getMethod(): string {
		if (this.isNew()) {
			return 'create';
		}

		return 'update';
	}

	onEdit(): void {
		this.computeCanEdit();

		if (this.record) {
			this.record.id = this.rowDb.id_domain_configs;
		}

		this.datasetNavigatorService.openEditDialog(this.datasetACS, this.record, {
			formConfig: this.formConfig,
			recordId: this.rowDb.id_domain_configs,
			customActionCode: this.customActionCode,
			method: this.getMethod(),
		});
	}

	onCreate(): void {
		this.datasetNavigatorService.openCreateDialog(this.datasetACS, {
			formConfig: this.formConfig,
			customActionCode: this.customActionCode
		});
	}
}
