import { HttpClient } from "@angular/common/http";
import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetService } from "app/main/components/dataset/services/dataset.service";
import { AppService } from "app/services/app.service";
import { Subscription } from "rxjs";
import { ITripBookingPackageRelatedDataDialogData } from "./related-data/abstract-trip-booking-package-related-data.component";
import { TripBookingResourceComponent } from "./related-data/trip-booking-resource/trip-booking-resource.component";
import { TripBookingServiceComponent } from "./related-data/trip-booking-services/trip-booking-service.component";
import { TripBookingParticipantComponent } from "./related-data/trip-booking-participants/trip-booking-participant.component";
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../edit-booking.component";
import { ConfirmDialogComponent } from "app/main/dialogs/confirm-dialog/dialog.component";
import { BookingService } from "../../booking.service";

@Injectable()
export class TripBookingPackageService{

	public fetchTripBookingPackageSubcription: Subscription;
	
	public iterationData: any;

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected http: HttpClient,
		public appService: AppService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public bookingService: BookingService
	){
		
	}

	openTripBookingRelatedDialog(datasetCode: string, tripBookingPackageRelatedDialogData: ITripBookingPackageRelatedDataDialogData, viewContainerRef: ViewContainerRef): void{
		const components = {
			'trip_booking_resources': TripBookingResourceComponent,
			'trip_booking_services': TripBookingServiceComponent,
			'trip_booking_participant_extras': TripBookingParticipantComponent
		}
		if(!components[datasetCode]) return;

		const data = {
			...tripBookingPackageRelatedDialogData,
			...{datasetCode: datasetCode, actionCode: 'list'}
		};
		this.datasetNavigatorService.openDialog<ITripBookingPackageRelatedDataDialogData>(components[datasetCode], data, {
			panelClass: 'trip-booking-related-dialog-container', 
			minWidth: 700,
			viewContainerRef: viewContainerRef // share dependency injector, allow inject provided services
		}).afterClosed()
		.subscribe(() => {
			this.appService.appEvent.next({ name: RELOAD_BOOKING });
		});
	}

	confirmTripBookingPackage(){
		const tripBookingPackage = this.datasetRS.record.value;

		let confirmMessage = 'Vuoi confermare questo viaggio?'
		if(tripBookingPackage.target == 'product'){
			confirmMessage = 'Vuoi confermare questo prodotto?'
		}
	
		this.matDialog
		.open(ConfirmDialogComponent, {
			width: '300px',
			data: {
				title: 'Conferma',
				contentMessage: confirmMessage
			}
		})
		.afterClosed()
		.subscribe(result => {
			if(!result) return;

			this.bookingService.confirmBooking(tripBookingPackage.trip_booking_id, [tripBookingPackage.id])
			.subscribe({
				next: () => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
				},
				error: response => {
					this.datasetACS.loading.next(false);
				},
				complete: () => {
					this.datasetACS.loading.next(false);
					this.datasetRS.reload(); // reload any way
				}
			});
		});
	}
}