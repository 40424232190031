import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer | IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('payment_type_id')
];

const config: IFormConfig = {
	fields: formFields,
};

export default config;
