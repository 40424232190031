import * as tslib_1 from "tslib";
import { allMenuItems } from 'app/configs/navigations/menu-items';
import * as _ from 'lodash';
export function buildUserNavigationFromDomainSetting(data, menuItems, domainSetting) {
    var e_1, _a;
    var menuGroups = _.get(domainSetting, 'interface_settings.main_menu_enabled_groups');
    var navigations = [];
    try {
        for (var menuItems_1 = tslib_1.__values(menuItems), menuItems_1_1 = menuItems_1.next(); !menuItems_1_1.done; menuItems_1_1 = menuItems_1.next()) {
            var menuItem = menuItems_1_1.value;
            if (menuItem.hidden)
                continue;
            if (domainSetting.is_smart_interface && menuItem.hiddenWithSmartInterface)
                continue;
            if (menuItem.group && (!menuGroups || !menuGroups.includes(menuItem.group)))
                continue;
            var userNavigation = void 0;
            if (!menuItem.fuseNavigation) {
                userNavigation = menuItem;
            }
            else {
                userNavigation = Object.assign({}, menuItem.fuseNavigation);
            }
            // TODO: use domainSetting badges
            if (data.badge && data.badge.count > 0) {
                if (userNavigation.id === data.badge.id) {
                    userNavigation.badge = {};
                    userNavigation.badge.title = data.badge.count.toString();
                    userNavigation.badge.bg = data.badge.bg;
                }
            }
            if (userNavigation.id === 'domains' && !data.showFilterBar && data.single_domain_id) {
                userNavigation.url = 'dataset/detail/domains/' + data.single_domain_id;
            }
            if (menuItem.children) {
                userNavigation.children = buildUserNavigationFromDomainSetting(data, menuItem.children, domainSetting);
            }
            else if (userNavigation.children) {
                userNavigation.children = buildUserNavigationFromDomainSetting(data, userNavigation.children, domainSetting);
            }
            navigations.push(userNavigation);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (menuItems_1_1 && !menuItems_1_1.done && (_a = menuItems_1.return)) _a.call(menuItems_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return navigations;
}
export function buildUserNavigation(data, menuItems, domainSetting) {
    var e_2, _a, e_3, _b;
    var userPermission = data.permissions;
    var user = data.user;
    var specialGroups = [
        'admin_group',
        'super_admin_group'
    ];
    if (!userPermission)
        return [];
    var groupMap = new Map();
    try {
        for (var userPermission_1 = tslib_1.__values(userPermission), userPermission_1_1 = userPermission_1.next(); !userPermission_1_1.done; userPermission_1_1 = userPermission_1.next()) {
            var group = userPermission_1_1.value;
            // groupMap.set(group.code, group.access_level);
            if (group.actions && group.actions.length > 0) {
                groupMap.set(group.code, group.actions.some(function (action) { return action.active == true; }));
            }
            else if (specialGroups.includes(group.code)) {
                groupMap.set(group.code, true);
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (userPermission_1_1 && !userPermission_1_1.done && (_a = userPermission_1.return)) _a.call(userPermission_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    var navigations = [];
    try {
        for (var menuItems_2 = tslib_1.__values(menuItems), menuItems_2_1 = menuItems_2.next(); !menuItems_2_1.done; menuItems_2_1 = menuItems_2.next()) {
            var menuItem = menuItems_2_1.value;
            if (menuItem.hidden)
                continue;
            if (data.is_smart_interface) {
                if (menuItem.hiddenWithSmartInterface)
                    continue;
            }
            if (menuItem.group) {
                if (!groupMap.get(menuItem.group))
                    continue;
                if (groupMap.get(menuItem.group) <= 0)
                    continue;
            }
            var userNavigation = void 0;
            if (!menuItem.fuseNavigation && menuItem) {
                userNavigation = menuItem;
            }
            else {
                userNavigation = Object.assign({}, menuItem.fuseNavigation);
            }
            if (data.badge && data.badge.count > 0) {
                if (userNavigation.id === data.badge.id) {
                    userNavigation.badge = {};
                    userNavigation.badge.title = data.badge.count.toString();
                    userNavigation.badge.bg = data.badge.bg;
                }
            }
            if (userNavigation.id === 'domains' && data.user && data.user.role && data.user.role.level > 10 && !data.showFilterBar) {
                userNavigation.url = 'dataset/detail/domains/' + data.single_domain_id;
            }
            if (menuItem.children) {
                userNavigation.children = buildUserNavigation(data, menuItem.children);
            }
            else if (userNavigation.children) {
                buildUserNavigation(data, userNavigation.children);
            }
            navigations.push(userNavigation);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (menuItems_2_1 && !menuItems_2_1.done && (_b = menuItems_2.return)) _b.call(menuItems_2);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return navigations;
}
export function setUserNavigation(fuseNavigationService, data, domainSetting) {
    fuseNavigationService.unregister('user');
    fuseNavigationService.register('user', domainSetting ? buildUserNavigationFromDomainSetting(data, allMenuItems, domainSetting) : buildUserNavigation(data, allMenuItems, domainSetting));
    fuseNavigationService.setCurrentNavigation('user');
}
