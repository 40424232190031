import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartData, ChartOptions, ChartTooltipItem, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';

@Component({
	selector   : 'sourcemarkets',
	templateUrl: './sourcemarkets.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class SourceMarketsComponent extends DestroyableComponent implements OnInit{
	public sourcemarkets: number[];
	public sourcelabel: Label[];
	public showLoader: boolean;
	
	/**
	 * Chart SourceMarkets
	 */
	// Pie
	public pieChartOptions: ChartOptions = {
		responsive: true,
		legend: {
				position: 'left',
				labels: {
					usePointStyle: true
				}
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem: ChartTooltipItem, data: ChartData): string {
					const n = Number(data.datasets[0].data[tooltipItem.index]);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		},
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					const label = ctx.chart.data.labels[ctx.dataIndex];
					return label;
				}
			},
		}
	};
	public pieChartType: ChartType = 'doughnut';
	public pieChartLegend = true;

	public pieChartColors = [
		{
			backgroundColor: [
				'rgba(88,24,69,0,0.3)',
				'rgba(245,45,10,0.3)',
				'rgba(245,145,10,0.3)',
				'rgba(245,238,10,0.3)',
				'rgba(68,241,17,0.3)',
				'rgba(17,241,41,0.3)',
				'rgba(17,241,218,0.3)',
				'rgba(17,204,241,0.3)', 
				'rgba(33,255,0,0.3)',
				'rgba(11,0,255,0.3)',
				'rgba(17,150,241,0.3)',
				'rgba(17,92,241,0.3)',
				'rgba(27,17,241,0.3)',
				'rgba(150,17,241,0.3)',
				'rgba(221, 17, 241,0.3)',
				'rgba(241, 17, 201,0.3)',
				'rgba(241, 17, 160,0.3)',
				'rgba(241,17,105,0.3)',
				'rgba(255,0,0,0.3)',
				'rgba(0,255,0,0.3)',
				'rgba(0,0,255,0.3)'
			],
		},
	];

	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	public ngOnInit(): void{
		this.dashboardService.sourceMarketResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				this.onData(response);
			}
		});
	}
	// -----------------------------------------------------------------------------------------------------

	public onData(response: any): void{
		this.showLoader = true;

		if(!response) return;
		else if (response.length > 0){
			this.showLoader = false;
		}

		const data = [];
		
		for (const item of response) {
			data.push({
				'value': Number(item.percent_pax).toFixed(2),
				'label': item.nation_code
			});
		}

		data.sort((a, b) => {
			return b.value - b.value;
		});

		this.sourcemarkets = data.map(item => item.value);
		this.sourcelabel = data.map(item => item.label);
		const colors = [];
		for (let i = 0; i < data.length; i++) {
			colors.push(chartColors[i]);
		}
		this.pieChartColors[0].backgroundColor = colors;
	}
	
}
