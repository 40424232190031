import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { I } from '@angular/cdk/keycodes';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome del pacchetto',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('full_description', {
	key: 'full_description',
	name: 'Descrizione completa',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.STRING,
	inputHint: 'Testo completo di descrizione.',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('public_product_on_web', {
	key: 'public_product_on_web',
	name: 'Pubblica sul sito',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se pubblico, questa traduzione sarà visibile sul sito',
	disallowNull: true,
	defaultValue(){return 0},
	onValueChanged(form){
		if(!form) return;
		if(form.get('public_product_on_web') && form.get('public_product_on_web').value === null){
			form.get('public_product_on_web').setValue(0);
		}
	},
	options: [
		{
			label: 'No',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	]
});

export {fieldsMap};
