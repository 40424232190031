import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
var CounterInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CounterInputComponent, _super);
    function CounterInputComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.canAdd = true;
        _this.canSub = true;
        _this.minValue = null;
        return _this;
    }
    CounterInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            var control = _this.getControl();
            if (control) {
                control.valueChanges
                    .pipe(takeUntil(_this._unsubscribeAll))
                    .subscribe(function (val) {
                    _this.updateStatus();
                });
                _this.updateStatus();
            }
            _this.minValue = _.get(_this.inputService, 'formInputDefinition.extra.minValue');
        });
    };
    CounterInputComponent.prototype.add = function () {
        if (!this.canAdd)
            return;
        var control = this.getControl();
        if (control)
            control.setValue(control.value + 1);
    };
    CounterInputComponent.prototype.sub = function () {
        if (!this.canSub)
            return;
        var control = this.getControl();
        if (control)
            control.setValue(control.value - 1);
    };
    CounterInputComponent.prototype.getControl = function () {
        return this.inputService.formGroup.controls[this.inputService.formControlKey];
    };
    CounterInputComponent.prototype.getCurrentValue = function () {
        var control = this.getControl();
        if (!control)
            return null;
        return control.value;
    };
    CounterInputComponent.prototype.updateStatus = function () {
        var control = this.getControl();
        if (!control)
            return;
        if (control.disabled) {
            this.canAdd = false;
            this.canSub = false;
            return;
        }
        var minValue = 0;
        if (typeof (this.minValue) === 'number')
            minValue = this.minValue;
        this.canSub = control.value > minValue;
        this.canAdd = true;
        // TODO: configurable max value
    };
    return CounterInputComponent;
}(DestroyableComponent));
export { CounterInputComponent };
