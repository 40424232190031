import * as tslib_1 from "tslib";
import { ViewContainerRef } from "@angular/core";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DestroyableComponent } from "app/main/destroyable.component";
import { TripBookingPackageService } from "../../trip-booking-package.service";
var TripBookingPackageRelatedButtonsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripBookingPackageRelatedButtonsComponent, _super);
    function TripBookingPackageRelatedButtonsComponent(datasetRS, tripBookingPackageService, viewContainerRef) {
        var _this = _super.call(this) || this;
        _this.datasetRS = datasetRS;
        _this.tripBookingPackageService = tripBookingPackageService;
        _this.viewContainerRef = viewContainerRef;
        _this.showResources = true;
        _this.showServices = true;
        _this.showParticipants = true;
        return _this;
    }
    Object.defineProperty(TripBookingPackageRelatedButtonsComponent.prototype, "tripBookingPackageRelatedDialogData", {
        get: function () {
            return {
                datasetCode: '',
                actionCode: '',
                tripBookingPackage: this.datasetRS.record.value
            };
        },
        enumerable: true,
        configurable: true
    });
    return TripBookingPackageRelatedButtonsComponent;
}(DestroyableComponent));
export { TripBookingPackageRelatedButtonsComponent };
