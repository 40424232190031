import { IDataContainerService } from "app/interfaces/data-container.interface";

export function showIfEAgency(record: any, dataActionContainerService: IDataContainerService): boolean{
	let modules = dataActionContainerService.getValueFromKeyPath('domainModules');
    if(!modules){
        modules = dataActionContainerService.getValueFromKeyPath(dataActionContainerService.datasetCode + '.domainModules');
    }
	if(modules && modules.eagency){
		const eagency = modules.eagency;
		return eagency.enabled; 
	}
	return false;
}

export function skipIfNotEAgency(record: any, dataActionContainerService: IDataContainerService): boolean{
	let modules = dataActionContainerService.getValueFromKeyPath('domainModules');
    if(!modules){
        modules = dataActionContainerService.getValueFromKeyPath(dataActionContainerService.datasetCode + '.domainModules');
    }
	if(modules && modules.eagency){
		const eagency = modules.eagency;
		return !eagency.enabled; 
	}
	return true;
}