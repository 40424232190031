import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { IDomainChangeHandler } from 'app/interfaces/domain-change-handler.interface';
import { MatDialog } from '@angular/material/dialog';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { WebSocketService } from './websocket.service';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

export const LocalStorageFilterDomainIdKey = 'DomainFilterService.filterDomainId';

interface IDomainData{
	id: string;
	code: string;
	display_name: string;
	domain_path: string;
	logo: string;
	enabled: number;
}

export interface IDomainSetttings{
	is_smart_interface?: boolean;
	enabled_modules?: any;
	user_permissions?: any[];
	interface_settings?: {
		show_advanced_domain_config?: boolean,
		main_menu_enabled_groups?: string[],
		user_permissions?: any[]
	};
}

@Injectable({
	providedIn: 'root'
})
export class DomainFilterService{
	public filterDomainId: BehaviorSubject<string>;
	public domainData: BehaviorSubject<IDomainData>;
	public refreshDomainDataSubscription: Subscription;
	public loadingLogo = false;
	public showFilterBar: boolean;
	public domainSettings: Map<string, IDomainSetttings> = new Map();
	public domainSettingsLoadSubscriptions: Map<string, Subscription> = new Map();
	public domainSettingChangeEvent: Subject<any>;
	public authLoaded = false;

	constructor(
		protected appService: AppService,
		private router: Router,
		private http: HttpClient,
		private webSocketService: WebSocketService,
		protected matDialog: MatDialog
	) {
		this.domainSettingChangeEvent = new Subject();
		this.filterDomainId = new BehaviorSubject(localStorage.getItem(LocalStorageFilterDomainIdKey));
		this.domainData = new BehaviorSubject(null);
		this.filterDomainId
		.pipe(distinctUntilChanged())
		.subscribe(newValue => {
			if(newValue) localStorage.setItem(LocalStorageFilterDomainIdKey, newValue);
			else localStorage.removeItem(LocalStorageFilterDomainIdKey);
			// set filter domain id to web socket for join room
			this.webSocketService.setFilterDomain(newValue);
			this.loadDomainSettings(newValue);
		});

		this.domainData.subscribe(domainData => {
			if(typeof(window['FreshworksWidget']) === 'function'){
				const custom_fields = {
					cf_domain_id: '',
					cf_domain_name: ''
				};
				if(domainData){
					custom_fields.cf_domain_id = domainData.id;
					custom_fields.cf_domain_name = domainData.display_name;
				}
				window['FreshworksWidget']('prefill', 'ticketForm', {
					priority: 1,
					status: 2,
					custom_fields: custom_fields
				});
			}
		});
	}

	implementDomainChangeHanlder(component: any): component is IDomainChangeHandler{
		return component && typeof(component.handleDomainChanges) === 'function';
	}

	refreshPageOnFilterChanged(): void {
		const currentComponent = this.appService.currentRoutedComponent;
		if(currentComponent 
			&& this.implementDomainChangeHanlder(currentComponent) 
			&& currentComponent.handleDomainChanges()
		){
			return; // prevent de default handle
		}
		
		this.router.navigate(['/home']);
	}

	setFilterDomain(domainId: any, refresh?: boolean): void {
		if(domainId && domainId !== 'null') this.filterDomainId.next(domainId);
		else this.filterDomainId.next(null);
		if(refresh !== false) this.refreshPageOnFilterChanged();
		this.refreshDomainData();
	}

	unsetFilterDomain(): void {
		this.setFilterDomain(null);
	}

	wrapInDomain(
		dialogComponent,
		callback: (domainId: string) => void,
		dataContainerService?: IDataContainerService,
		returnEmpty = false
	): void{
		
		if(this.filterDomainId.value){
			callback(this.filterDomainId.value);
		}else if(dataContainerService && dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			callback(dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}else{
			this.matDialog.open(dialogComponent, {
				disableClose: true
			}).afterClosed()
			.subscribe(selection => {
				if(!selection){
					if(returnEmpty) callback(null);
					return;
				}
				callback(selection);
			});
		}
	}

	loadDomainSettings(domainId: string){
		if(!domainId) domainId = 'null';
		if(this.domainSettings.has(domainId)){
			this.domainSettingChangeEvent.next({
				domain_id: domainId,
				settings: this.domainSettings.get(domainId)
			});
			return;
		}
		if(this.domainSettingsLoadSubscriptions.has(domainId)) return;
		if(!this.authLoaded) return;

		const sub = this.http.get<IDomainSetttings>(
			this.appService.getBaseServerAddress() + '/auth/domain/settings/' + domainId
		).subscribe({
			next: (response) => {
				if(response){
					this.domainSettings.set(domainId, response);

					this.domainSettingChangeEvent.next({
						domain_id: domainId,
						settings: response
					});
				}
				this.domainSettingsLoadSubscriptions.delete(domainId);
			}, 
			error: (e) => {
				this.domainSettingsLoadSubscriptions.delete(domainId);
			}
		});

		this.domainSettingsLoadSubscriptions.set(domainId, sub);
	}

	getDomainSetting(domainId?: string, path?: string){
		if(domainId == 'current') domainId = this.filterDomainId.value;
		const settings = this.domainSettings.get(domainId || this.filterDomainId.value || 'null');

		if(path) return _.get(settings, path);
		return settings;
	}


	refreshDomainData(): void{
		if(this.refreshDomainDataSubscription){
			this.refreshDomainDataSubscription.unsubscribe();
		}
		if(!this.filterDomainId.value){
			this.domainData.next(null);
			return;
		}
		const cacheKey = 'DomainFilterService.domain.' + this.filterDomainId.value;
		try{
			const cachedDomainData = JSON.parse(localStorage.getItem(cacheKey));
			if(cachedDomainData){
				this.domainData.next(cachedDomainData);
				return;
			}
		}catch(e){
			// ignored
		}

		this.refreshDomainDataSubscription = this.http.get<IDomainData>(
			this.appService.getBaseServerAddress() + '/dataset/domains/detail/' + this.filterDomainId.value
		).subscribe({
			next: (response) => {
				if(response){
					this.domainData.next({
						id: response.id,
						code: response.code,
						display_name: response.display_name,
						domain_path: response.domain_path,
						logo: response.logo,
						enabled: response.enabled
					});
					localStorage.setItem(cacheKey, JSON.stringify(this.domainData.value));
				}
			}, 
			error: () => {
				this.domainData.next(null);
			}
		});
	}

	/**
	 * called on logout
	 */
	clear(){
		this.authLoaded = false;
		this.domainSettings.clear();
		this.filterDomainId.next(null);
		if(this.refreshDomainDataSubscription) this.refreshDomainDataSubscription.unsubscribe();
		this.refreshDomainDataSubscription = null;
		for (const [key, sub] of Array.from(this.domainSettingsLoadSubscriptions.entries())) {
			if(sub) sub.unsubscribe();
			this.domainSettingsLoadSubscriptions.delete(key);
		}
	}
}
