import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { IPaginationResponse, ListViewItemTypes, ValueTypes, InputTypes, FieldViews, IListViewConfig, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { SelectDatasetRecordDialogComponent, ISelectDatasetRecordDialogData } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { fieldsMap as ActualTripFieldsMap } from 'app/configs/datasets/actual_trips/fields';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from '../edit-booking.component';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { Subscription, Observable, Subject, of, EMPTY, concat } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { defaultIfEmpty, exhaustMap, filter, finalize, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { IFormDialogData, FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { BookingService } from '../booking.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { TrenitaliaCarnetComponent } from '../trenitalia-dialog/trenitalia-carnet/trenitalia-carnet.component';
import { TrenitaliaDialogContainerComponent } from '../trenitalia-dialog/trenitalia-dialog-container/trenitalia-dialog-container.component';

@Injectable()
export class TripBookingPackagesStepService{

	public tripBookingPackagesPage: IPaginationResponse<any>;
	public loading = false;
	public fetchTripBookingPackageSubcription: Subscription;
	public loadPickupMeetingPoint = false;
	public meetingPointPickupResource: any;
	public ticketingEnabled = false;
	public canAddTicket = false;
	public canAddProduct = false;
	public canAddTrenitalia = false;
	public canAddTripBooking = false;
	public hasParticipants = false;
	public addMode: string = null;
	public pageItemsUpdated = new Subject();
	public settingsUpdated = new Subject();

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected http: HttpClient,
		public appService: AppService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public bookingService: BookingService
	){}

	reloadTripBookingPackages(): void{
		if(this.fetchTripBookingPackageSubcription){
			this.fetchTripBookingPackageSubcription.unsubscribe();
		}
		const params = {
			perPage: 100,
			sortBy: 'created_at',
			sortDirection: 'asc',
			trip_booking_id: this.datasetRS.recordId,
			only_parents: 1,
			with_attributes: 'total_amount_net',
			with_relations: 'tripPackage.destinations,product'
		};
		this.loading = true;
		this.fetchTripBookingPackageSubcription = this.datasetService.get<IPaginationResponse<any>>('/dataset/trip_booking_packages', params)
		.subscribe({
			next: response => {
				this.tripBookingPackagesPage = response;
				this.pageItemsUpdated.next();
				this.loading = false;
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	updateActualTrip(postData: any) {
		const created = new Subject<boolean>();
		this.loading = true;
		let actionEndPoint = '/dataset/actual_trips/command/update_date';
		this.datasetService.post<any>(actionEndPoint, postData)
		.subscribe({
			next: response => {
				this.loading = false;
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			}
		})
	}

	createTripBookingPackage(actionEndPoint: string, postData: any): Observable<boolean>{
		return this.bookingService.execPreAction('trip_booking_packages:create')
		.pipe(
			exhaustMap(() => {
				this.loading = true;
				return this.datasetService.post<any>(actionEndPoint, postData)
			}),
			exhaustMap((response) => {
				this.loading = false;
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});

				if(response && response.return_trip_count > 0){
					const confirmData: IConfirmDialogData = {
						title: 'Aggiungi',
						contentMessage: 'Questa partenza ha dei possibili ritorni, vuoi scegliere quale aggiungere?',
						positiveText: 'Si',
						negativeText: 'No'
					};
					return this.matDialog.open(ConfirmDialogComponent, {data: confirmData, panelClass: 'create-dialog-container'})
					.afterClosed()
					.pipe(
						exhaustMap((result) => {
							if(!result) return of(true);
							return this.selectReturnTrip(response.actual_trip_id);
						}),
						defaultIfEmpty(true)
					);
				}

				return of(true);
			}),
			take(1),
			finalize(() => {
				this.loading = false;
			})
		);
	}

	addTicket(): void{
		const data: ISelectDatasetRecordDialogData = {
			title: 'Pacchetti Milano Card',
			datasetCode: 'trip_packages',
			filters: {
				start_day: JSON.stringify(['>=', convertToUTCDay().toISOString()]),
				only_bookable: 1,
				target: 'ticket'
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'start_day|start_time',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				filterForm: {
					fields: [
						cloneFieldDefinition(ActualTripFieldsMap.get('start_day'), {
							formValidators: [],
							inputConfig: {
								type: InputTypes.COMPARE_DATE,
								clearable: true
							},
						}),
						cloneFieldDefinition(tripPackageIdField)
					]
				},
				columns: [
					{
						title: 'Codice',
						key: 'code',
						fieldDefinition: {
							name: 'Pacchetto',
							key: 'code',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								if(!record) return '---';
								return record.code;
							}
						}
					},
					{
						title: 'Stato',
						key: 'active_status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(record.active_status === 1){
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
								if(record.publish_on_web === 1){
									text = '<span class="status">' + translateService.instant('Attivo e pubblicato') + '</span>';
								}else{
									colorClass = 'orange-bg';
								}
							}

							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title : 'Immagine',
						key: 'master_image',
						fieldView: FieldViews.IMAGE,
						routerLink(record: any): string{
							return datasetDetailPage('trip_packages', record.id);
						}
					},
					{
						title: 'Descrizione',
						key: 'description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							let html = '<span style=\'display: block;\'>' + record.description + '</span>';

							const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
							const isFiltered = filteredDomainId && filteredDomainId !== 'null';
							
							if(record.domain){
								if (record.reseller_domain_package && record.reseller_domain_package.reseller_domain){
									// tslint:disable-next-line: max-line-length
									html += '<span style=\'font-size: 11px;\'>' + translateService.instant('Proprietario') + ':</span> <label style=\'display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;\'>' + record.reseller_domain_package.reseller_domain.display_name + '</label>';
								}else if ((!isFiltered && !record.is_owner) || (isFiltered && filteredDomainId !== record.domain.id)) {
									// tslint:disable-next-line: max-line-length
									html += '<span style=\'font-size: 11px;\'>' + translateService.instant('Proprietario') + ':</span> <label style=\'display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;\'>' + record.domain.display_name + '</label>';
								}
								// aggiungere logo tour operator nel caso di pacchetto rivenduto
								if ((!isFiltered && record.is_owner) || (isFiltered && filteredDomainId === record.domain.id)){
									if (record.tourcms_channel_id_tour_id){
										// tslint:disable-next-line: max-line-length
										html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px;">Proprietario:</label> <img src="assets/images/tour-operators/tour-cms.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
									} else if (record.bokun_vendor_id_tour_id){
										// tslint:disable-next-line: max-line-length
										html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/bokun-logo.svg" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
									} else if (record.ticketlandia_museum_id_event_id){
										// tslint:disable-next-line: max-line-length
										html += '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 90px; margin-left: 10px;" /></span>';
									}
								}
							}
							return html;
						},
					},
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes'
					}
				]
			}
		};
		
		this.matDialog.open(SelectDatasetRecordDialogComponent, {
			panelClass: 'create-dialog-container',
			data
		}).afterClosed()
		.subscribe(tripPackage => {
			if(!tripPackage) return;
			this.loading = true;
			const postData = {
				trip_booking_id: this.datasetRS.recordId,
				trip_package_id: tripPackage.id,
				target: 'milano_card'
			};
			this.datasetService.post<any>('/dataset/trip_booking_packages/create', postData)
			.subscribe({
				next: response => {
					this.loading = false;
					this.reloadTripBookingPackages();
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
				},
				error: response => {
					this.loading = false;
				}
			});
		});
	}

	/**
	 * Return null if not additional info needed
	 */
	getAditionalForm(item: any, target: string, mode = 'create'){
		if(!item) return null;
		const formConfig: IFormConfig = {
			fields: []
		};

		if(target == 'product'){
			if(item.data && item.data.need_activation_date){
				formConfig.fields.push({
					key: 'date',
					name: 'Data Attivazione',
					valueType: ValueTypes.DATE,
					inputConfig: {
						type: InputTypes.DATE,
						required: true
					}
				});
			}
		}

		if(!formConfig.fields.length) return null;

		return formConfig;
	}

	public requestBookingData(item: any, target: string, mode = 'create', initialFormData?: any): Observable<any>{
		if(!item) return of(null);
		const requestData: any = {};
		if(mode == 'create'){
			requestData.trip_booking_id = this.datasetRS.recordId;
			// support other target ??
			if(target != 'product') return of(requestData);
			requestData.target = 'product';
			requestData.product_id = item.id;
		}
		const formConfig = this.getAditionalForm(item, target, mode)
		if(!formConfig) return of(mode == 'create' ? requestData : null);
		
		return this.matDialog.open<FormDialogComponent, IFormDialogData, any>(FormDialogComponent, {
			data: {
				formData: initialFormData,
				formConfig
			}
		}).afterClosed()
		.pipe(switchMap(data => {
			if(mode == 'create'){
				requestData.target_info = data;
				return of(requestData);
			}else{
				return of(data);
			}
		}));
	}

	selectReturnTrip(going_trip_id: string): Observable<any>{
		const dialogData: ISelectDatasetRecordDialogData = {
			title: 'Seleziona Ritorno',
			datasetCode: 'going_and_returns',
			filters: { going_trip_id },
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				columns: [
					{
						title: 'Descrizione',
						key: 'return_description'
					},
					{
						title: 'Orario',
						key: 'return_start_time'
					}
				]
			}
		};
		return this.matDialog.open(SelectDatasetRecordDialogComponent, {
			panelClass: 'create-dialog-container',
			data: dialogData
		})
		.afterClosed()
		.pipe(
			exhaustMap(result => {
				if(!result) return EMPTY;
				this.loading = true;
				return this.datasetService.post<any>('/dataset/trip_booking_packages/create', {
					trip_booking_id: this.datasetRS.recordId,
					actual_trip_id: result.return_trip_id
				})
			}),
			tap(() => {
				this.loading = false;
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			}, () => {
				this.loading = false;
			})
		);
	}

	reloadTripBooking(): void{
		this.appService.appEvent.next({ name: RELOAD_BOOKING });
	}

	updateSettings() {
		this.ticketingEnabled = this.datasetACS.getDomainSetting('enabled_modules.ticketing.enabled') == true;
		const productsEnabled = this.datasetACS.getDomainSetting('enabled_modules.products.enabled') == true;
		const trenitaliaEnabled = this.datasetACS.getDomainSetting('enabled_modules.trenitalia.enabled') == true;
		const tripBookingEnabled = this.datasetACS.getDomainSetting('disabled_modules.trip_bookings.disabled') != true;
		this.canAddTripBooking = this.bookingService.canEdit
			&& this.datasetRS.record.value
			&& !this.datasetRS.record.value.ref_id
			&& this.datasetRS.record.value.booking_type != 1
			&& tripBookingEnabled;

		this.canAddTicket = this.bookingService.canEdit
		&& this.datasetRS.record.value
		&& !this.datasetRS.record.value.ref_id
		&& this.datasetRS.record.value.booking_type != 1
		&& this.ticketingEnabled;

		this.canAddProduct = this.bookingService.canEdit
		&& this.datasetRS.record.value
		&& !this.datasetRS.record.value.ref_id
		&& this.datasetRS.record.value.booking_type != 1
		&& productsEnabled;

		this.canAddTrenitalia =
			this.bookingService.canEdit &&
			this.datasetRS.record.value &&
			!this.datasetRS.record.value.ref_id &&
			this.datasetRS.record.value.booking_type != 1 &&
			//!['confirmed', 'canceled', 'expired'].includes(this.datasetRS.record.value.status) &&
			trenitaliaEnabled;

		this.hasParticipants = this.datasetRS.record.value?this.datasetRS.record.value.participant_count > 0:false;

		if(this.datasetRS.record.value && this.datasetRS.record.value.ref_id){
			this.addMode = null;
		}else{
			if(!this.canAddProduct && !this.canAddTicket && !this.canAddTrenitalia){
				this.addMode = 'packages';
			}else{
				this.addMode = 'multi';
			}
		}

		this.settingsUpdated.next();
	}

	addTrenitaliaService(tripBookingPackageToReplace?: any,type?:string): void {
		if(this.datasetRS.record.value.participant_count==0 && type!='carnet'){
			this.matDialog.open(TextDialogComponent, {
				minWidth: '550px',
				data: {
					title: 'Attenzione',
					contentMessage: 'Aggiungere prima i partecipanti!',
					positiveText: 'OK'
				}
			});
			return;
		}
		let component: any = type=='carnet' ? TrenitaliaCarnetComponent : TrenitaliaDialogContainerComponent;
		
		this.matDialog
		.open(component, {
			minWidth: '800px',
			panelClass: 'create-dialog-container',
			data: {
				participant: this.datasetRS.record.value.participant_counters,
				trip_booking_id: this.datasetRS.record.value.id,
				trip_booking_package: tripBookingPackageToReplace,
				bookingService: this.bookingService
			}
		})
		.afterClosed()
		.pipe(
			filter(res => res),
			exhaustMap(res => {
				this.loading = true;
				const postData = {
					trip_booking_id: this.datasetRS.recordId,
					target: 'trenitalia',
					target_info: res
				};
				let actionEndPoint =
					'/dataset/trip_booking_packages/create';
				if (tripBookingPackageToReplace) {
					postData['trip_booking_package_id_to_replace'] =
						tripBookingPackageToReplace.id;
					actionEndPoint =
						'/dataset/trip_bookings/command/replace_trip_external_service';
				}
				return this.datasetService.post<any>(actionEndPoint, postData)
			})
		).subscribe({
			next: response => {
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
				this.loading = false;
			},
			error: (err) => {
				this.loading = false;
			}
		});
	}
}
