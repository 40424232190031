<ng-template *ngTemplateOutlet='headerTemplate'></ng-template>
<div class="segment" *ngFor='let segment of hourSegments'>
	<span class="hour">{{segment}}</span>
	<ng-container *ngIf='groupedEventsByHour[viewDate | date:"ddMMyy"] && groupedEventsByHour[viewDate | date:"ddMMyy"][segment]'>
		<ng-container *ngFor='let event of groupedEventsByHour[viewDate | date:"ddMMyy"][segment]'>
			<div class="event-container">
				<div class="col trip_data">
					<div class="actual_trip_data">
						<div class="start_time"><mat-icon>access_time</mat-icon> {{event.meta.start_time}}</div>
						<div class="meeting_point">
							<img src="assets/icons/material-icons/filled/md-pin.svg" />
							<span class="display_name">
								<a [routerLink]='datasetNavigatorService.getDetailRoute("actual_trips", event.meta.actual_trip.id)'>
									{{event.meta.actual_trip.display_name}}
								</a>
							</span>
						</div>
					</div>
					<div class="trip_package_title">
						<a [routerLink]='datasetNavigatorService.getDetailRoute("trip_packages", event.package.id)'>
							{{event.package.code}} - {{event.package.description}}
						</a>
					</div>
				</div>

				<div class="col participants_data">
					<div *ngIf='event.meta.actual_trip.pax_by_resource' class="slots" [ngClass]='event?.cssClass'>
						<span>{{event.meta.actual_trip.pax_by_resource.pax_punto_partenza}} / </span>
						<span *ngIf='event.meta.actual_trip.properties.allotment_blocked_by_trip_resource_id.value'>{{event.meta.actual_trip.pax_by_resource.pax_prenotati_risorsa}} / </span>
						<span>{{event.meta.actual_trip.pax_by_resource.pax_disponibili_risorsa}}</span>
					</div>

					<mat-progress-bar *ngIf='isLoadingParticipants[event.meta.actual_trip.id]' mode="indeterminate"></mat-progress-bar>

					<div class="participants">
						<div class="first_row">
							<span class="ADT"><b>ADT</b> {{event.meta.participants.adt}}</span>
							<span class="SNR"><b>SNR</b> {{event.meta.participants.snr}}</span>
						</div>
						<div class="second_row">
							<span class="CHD"><b>CHD</b> {{event.meta.participants.chd}}</span>
							<span class="INF"><b>INF</b> {{event.meta.participants.inf}}</span>
						</div>
					</div>
				</div>

				<div class="col resources_data">
					<div class="guide_languages" *ngIf="event.meta.actual_trip_guide_languages.length">
						<img *ngFor="let lang of event.meta.actual_trip_guide_languages"
						width="24"
						class="flag-icon-sidebar"
						[src]="'assets/images/flags/4x3/' + lang + '.svg'"
						[alt]="lang"
						[title]="lang"/>
					</div>
					<div *ngIf='event.meta.staff_elements && event.meta.staff_elements.length' class="trip_resources">
						<ng-container *ngFor='let staffElement of event.meta.staff_elements'>
							<div class="staff_element">
								<span class="label" *ngIf='staffElement.entity_staff_element.properties.staff_type.value'>
									{{staffElement.entity_staff_element.properties.staff_type.value}}: 
								</span>
								<a [routerLink]="datasetNavigatorService.getDetailRoute('entities', staffElement.entity_staff_element.entity_id)" 
									class="description" *ngIf='staffElement.entity_staff_element.entity.description'>
									{{staffElement.entity_staff_element.entity.description}}
								</a>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>