<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
	<div class="description" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
		<button class="back" mat-icon-button (click)="datasetRS.record.next(null);" *ngIf="datasetRS.record.value">
			<mat-icon>arrow_back_ios</mat-icon>
		</button>
		<span class="material-icons-outlined">directions_bus_filled</span>
		<span class="label" *ngIf="!datasetRS.record.value">{{'Risorse' | translate}}</span>
		<span class="label" *ngIf="datasetRS.record.value">{{ datasetRS.record.value.trip_resource.full_description || datasetRS.record.value.trip_resource.description }}</span>
	</div>

	<div class="close-icon" fxLayout="row" fxLayoutAlign="end center">
		<span class="material-icons-outlined dialog-close-icon" (click)="onCloseClick()">close</span>
	</div>
</div>

<div class="trip-booking-resource-container">
	<ng-container *ngIf='!datasetRS.record.value'>
		<ng-container *ngFor='let tripBookingPackage of tripBookingPackages'>
			<div *ngIf="tripBookingPackage.actual_trip" class="actual_trip" fxLayout='row' fxLayoutAlign="start center" fxLayoutGap="8px">
				<ng-container *ngIf='tripBookingPackage.actual_trip.type'>
					<ng-container *ngIf='["going", "single"].includes(tripBookingPackage.actual_trip.type)'>
						<span class="going_trip">
							<img src="assets/icons/material-icons/filled/going_trip_icon.svg" />
						</span>
					</ng-container>
					<ng-container *ngIf='["return"].includes(tripBookingPackage.actual_trip.type)'>
						<span class="return_trip">
							<img src="assets/icons/material-icons/filled/return_trip_icon.svg" />
						</span>
					</ng-container>
				</ng-container>
				<span class="display_name">{{ tripBookingPackage.actual_trip.display_name }}</span>
				<span class="date">{{ tripBookingPackage.actual_trip.start_day | date:'dd/MM/yyyy' }}</span>
				<span class="hour" *ngIf='tripBookingPackage.actual_trip.start_time'>{{ tripBookingPackage.actual_trip.start_time }}</span>
			</div>
			<div *ngIf="!tripBookingPackage.actual_trip" class="actual_trip" fxLayout='row' fxLayoutAlign="start center" fxLayoutGap="8px">
				<span class="going_trip">
					<img src="assets/icons/material-icons/filled/going_trip_icon.svg" />
				</span>
			</div>
			<!-- trip_booking_resources by trip_booking_package -->
			<dataset-list
				[enableCreate]='bookingService.canEdit'
				[elevate]='false'
				[showSearchBar]='false'
				[showTitle]='false'
				datasetCode='trip_booking_resources'
				[parentDatasetACS]='parentDatasetACS'
				[parentDatasetRS]='parentDatasetRS'
				[filters]='{ trip_booking_package_id: tripBookingPackage.id }'
				[selectable]='true'
				(selected)='onSelected($event)'
				tableStyle="style-1"
			></dataset-list>
		</ng-container>
	</ng-container>
		
	<ng-container *ngIf="datasetRS.record.value">
		<div class="trip_booking_resource_services" style="padding: 24px;">
			<dataset-list
				[enableCreate]='bookingService.canEdit && datasetRS.record.value.optional == 1'
				[elevate]='false'
				[showSearchBar]='false'
				[showTitle]='false'
				datasetCode='trip_booking_services'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[filters]='{ trip_booking_resource_id: datasetRS.recordId }'
				tableStyle="style-1"
			></dataset-list>
		</div>
	</ng-container>
</div>

<!-- footer -->
<div class="footer"></div>