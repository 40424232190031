import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	IPaginationResponse,
	ListViewItemTypes,
	ValueTypes,
	InputTypes,
	FieldViews,
	IListViewConfig,
	IFieldDefinition,
	IFormConfig
} from 'app/interfaces';
import {
	SelectDatasetRecordDialogComponent,
	ISelectDatasetRecordDialogData
} from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { fieldsMap as ActualTripFieldsMap } from 'app/configs/datasets/actual_trips/fields';
import {
	RELOAD_BOOKING,
	RELOAD_BOOKING_PACKAGE,
	RELOAD_BOOKING_PACKAGES
} from '../edit-booking.component';
import { convertToUTCDay } from 'app/helpers/date.helper';
import {
	ConfirmDialogComponent,
	IConfirmDialogData
} from 'app/main/dialogs/confirm-dialog/dialog.component';
import { Subscription, Observable, Subject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { TrenitaliaDialogComponent } from '../trenitalia-dialog/trenitalia-dialog.component';
import { config as productSelectionViewConfig } from 'app/configs/datasets/products/selectionViewConfig';
import { exhaustMap, filter, mergeMap, switchMap } from 'rxjs/operators';
import {
	IFormDialogData,
	FormDialogComponent
} from 'app/main/dialogs/form-dialog/dialog.component';
import { TripBookingResourceComponent } from './trip-booking-resources/trip-booking-resource.component';
import { TripBookingServiceComponent } from './trip-booking-services/trip-booking-service.component';
import { TripBookingParticipantComponent } from './trip-booking-participants/trip-booking-participant.component';
import { ITripBookingPackageRelatedData } from './trip-booking-packages-related.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import {TrenitaliaCarnetComponent} from '../trenitalia-dialog/trenitalia-carnet/trenitalia-carnet.component';
import { TrenitaliaDialogContainerComponent } from '../trenitalia-dialog/trenitalia-dialog-container/trenitalia-dialog-container.component';

@Injectable()
/**
 * @deptrecated use TripBookingPackagesStepService or TripBookingPackageService
 */
export class BookingStep2Service {
	public tripBookingPackagesPage: IPaginationResponse<any>;
	public trenitaliaPage: IPaginationResponse<any>;
	public loading = false;
	public fetchTripBookingPackageSubcription: Subscription;
	public fetchTrenitaliaServiceSubcription: Subscription;
	public loadPickupMeetingPoint = false;
	public meetingPointPickupResource: any;

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected http: HttpClient,
		public appService: AppService,
		public datasetService: DatasetService,
		public matDialog: MatDialog
	) {}

	reloadTripBookingPackages(): void {
		if (this.fetchTripBookingPackageSubcription) {
			this.fetchTripBookingPackageSubcription.unsubscribe();
		}
		const params = {
			perPage: 100,
			sortBy: 'created_at',
			sortDirection: 'asc',
			trip_booking_id: this.datasetRS.recordId,
			with_attributes: 'total_amount_net',
			with_relations: 'tripPackage.destinations,product'
		};
		this.loading = true;
		this.fetchTripBookingPackageSubcription = this.datasetService
			.get<IPaginationResponse<any>>(
				'/dataset/trip_booking_packages',
				params
			)
			.subscribe({
				next: response => {
					this.tripBookingPackagesPage = response;
					this.loading = false;
				},
				error: response => {
					this.loading = false;
				}
			});
	}

	get startDay(): string {
		return JSON.stringify(['>=', convertToUTCDay().toISOString()]);
	}

	get actualTripsListViewConfig(): IListViewConfig {
		return {
			itemViewType: ListViewItemTypes.TABLE,
			defaultSortBy: 'start_day|start_time',
			defaultSortDirection: 'asc',
			showSearchBar: false,
			filterForm: {
				fields: [
					{
						key: 'start_day',
						name: 'Data Partenza',
						valueType: ValueTypes.DATE,
						inputConfig: {
							type: InputTypes.COMPARE_DATE,
							clearable: true
						},
						defaultValue(
							dataContainerService: IDataContainerService
						): any {
							return JSON.stringify([
								'>=',
								convertToUTCDay().toISOString()
							]);
						}
					},
					cloneFieldDefinition(tripPackageIdField)
				]
			},
			columns: [
				{
					title: 'Pacchetto',
					key: 'package_description',
					fieldDefinition: {
						name: 'Pacchetto',
						key: 'package_description',
						valueType: ValueTypes.STRING,
						fieldView: FieldViews.HTML,
						getDisplayValue(record: any): string {
							if (!record || !record.package) return '---';
							let value =
								"<div class='trip_booking_trip_package_description'>";
							value += record.package.description;
							value += '</div>';
							return value;
						}
					}
				},
				{
					title: 'Tipo',
					key: 'icon_type',
					fieldDefinition: {
						name: 'Tipo',
						key: 'icon_type',
						valueType: ValueTypes.STRING,
						fieldView: FieldViews.HTML,
						getDisplayValue(record: any): any {
							let icon = 'going_trip_icon.svg';
							const style = 'border-radius: unset !important;';
							if (record.type == 'return') {
								icon = 'return_trip_icon.svg';
							}
							return (
								'<img src="assets/icons/material-icons/filled/' +
								icon +
								'" style="' +
								style +
								'" />'
							);
						}
					}
				},
				{
					title: 'Nome',
					key: 'display_name'
				},
				{
					title: 'Data Partenza',
					key: 'start_date_time'
				},
				{
					title: 'Disponibilità',
					key: 'availability'
				}
			]
		};
	}

	addTripBookingPackage(tripBookingPackageToReplace: any): void {
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'actual_trips',
			filters: { start_day: this.startDay, only_bookable: 1 },
			viewConfig: this.actualTripsListViewConfig
		};
		if (tripBookingPackageToReplace) {
			data.filters.trip_package_id =
				tripBookingPackageToReplace.trip_package_id;
			//data.filters.start_day = tripBookingPackageToReplace.actual_trip.start_day.substring(0, 10);
			data.filters.exclude_ids =
				tripBookingPackageToReplace.actual_trip_id;
			if (
				tripBookingPackageToReplace.properties
					.going_trip_booking_package_id &&
				tripBookingPackageToReplace.properties
					.going_trip_booking_package_id.value
			) {
				const goingPackageId =
					tripBookingPackageToReplace.properties
						.going_trip_booking_package_id.value;
				const bookingPackages =
					this.tripBookingPackagesPage.items || [];
				const goingBookingPackage = bookingPackages.find(
					bookingPackage => bookingPackage.id == goingPackageId
				);
				if (goingBookingPackage) {
					data.filters.going_trip_id =
						goingBookingPackage.actual_trip_id;
				}
			}
		}
		this.matDialog
			.open(SelectDatasetRecordDialogComponent, {
				panelClass: 'create-dialog-container',
				data
			})
			.afterClosed()
			.subscribe(actualTrip => {
				if (!actualTrip) return;
				const postData = {
					trip_booking_id: this.datasetRS.recordId,
					actual_trip_id: actualTrip.id
				};
				let actionEndPoint = '/dataset/trip_booking_packages/create';
				if (tripBookingPackageToReplace) {
					postData['trip_booking_package_id_to_replace'] =
						tripBookingPackageToReplace.id;
					actionEndPoint =
						'/dataset/trip_bookings/command/replace_trip';
				}
				this.createTripBookingPackage(actionEndPoint, postData);
			});
	}

	updateActualTrip(postData: any) {
		const created = new Subject<boolean>();
		this.loading = true;
		let actionEndPoint = '/dataset/actual_trips/command/update_date';
		this.datasetService.post<any>(actionEndPoint, postData).subscribe({
			next: response => {
				this.loading = false;
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			}
		});
	}

	createTripBookingPackage(
		actionEndPoint: string,
		postData: any
	): Observable<boolean> {
		const created = new Subject<boolean>();
		this.loading = true;
		this.datasetService.post<any>(actionEndPoint, postData).subscribe({
			next: response => {
				this.loading = false;
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});

				if (response && response.return_trip_count > 0) {
					const confirmData: IConfirmDialogData = {
						title: 'Aggiungi',
						contentMessage:
							'Questa partenza ha dei possibili ritorni, vuoi scegliere quale aggiungere?',
						positiveText: 'Si',
						negativeText: 'No'
					};
					this.matDialog
						.open(ConfirmDialogComponent, {
							data: confirmData,
							panelClass: 'create-dialog-container'
						})
						.afterClosed()
						.subscribe(result => {
							if (result) {
								this.selectReturnTrip(response.actual_trip_id);
							}
						});
				}

				created.next(true);
			},
			error: response => {
				this.loading = false;
				created.next(false);
			}
		});

		return created;
	}

	addTicket(): void {
		const data: ISelectDatasetRecordDialogData = {
			title: 'Pacchetti Milano Card',
			datasetCode: 'trip_packages',
			filters: {
				start_day: JSON.stringify([
					'>=',
					convertToUTCDay().toISOString()
				]),
				only_bookable: 1,
				target: 'ticket'
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'start_day|start_time',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				filterForm: {
					fields: [
						cloneFieldDefinition(
							ActualTripFieldsMap.get('start_day'),
							{
								formValidators: [],
								inputConfig: {
									type: InputTypes.COMPARE_DATE,
									clearable: true
								}
							}
						),
						cloneFieldDefinition(tripPackageIdField)
					]
				},
				columns: [
					{
						title: 'Codice',
						key: 'code',
						fieldDefinition: {
							name: 'Pacchetto',
							key: 'code',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string {
								if (!record) return '---';
								return record.code;
							}
						}
					},
					{
						title: 'Stato',
						key: 'active_status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(
							record: any,
							value: any,
							translateService: TranslateService
						): any {
							let colorClass = 'red-bg';
							let text =
								'<span class="status">' +
								translateService.instant('Non Attivo') +
								'</span>';
							if (record.active_status === 1) {
								colorClass = 'green-bg';
								text =
									'<span class="status">' +
									translateService.instant('Attivo') +
									'</span>';
								if (record.publish_on_web === 1) {
									text =
										'<span class="status">' +
										translateService.instant(
											'Attivo e pubblicato'
										) +
										'</span>';
								} else {
									colorClass = 'orange-bg';
								}
							}

							let html =
								'<div class="package_status" style="display:flex;align-items: center;">';
							html +=
								'<span class="' +
								colorClass +
								' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Immagine',
						key: 'master_image',
						fieldView: FieldViews.IMAGE,
						routerLink(record: any): string {
							return datasetDetailPage(
								'trip_packages',
								record.id
							);
						}
					},
					{
						title: 'Descrizione',
						key: 'description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(
							record: any,
							value: any,
							translateService: TranslateService
						): any {
							let html =
								"<span style='display: block;'>" +
								record.description +
								'</span>';

							const filteredDomainId = localStorage.getItem(
								LocalStorageFilterDomainIdKey
							);
							const isFiltered =
								filteredDomainId && filteredDomainId !== 'null';

							if (record.domain) {
								if (
									record.reseller_domain_package &&
									record.reseller_domain_package
										.reseller_domain
								) {
									// tslint:disable-next-line: max-line-length
									html +=
										"<span style='font-size: 11px;'>" +
										translateService.instant(
											'Proprietario'
										) +
										":</span> <label style='display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;'>" +
										record.reseller_domain_package
											.reseller_domain.display_name +
										'</label>';
								} else if (
									(!isFiltered && !record.is_owner) ||
									(isFiltered &&
										filteredDomainId !== record.domain.id)
								) {
									// tslint:disable-next-line: max-line-length
									html +=
										"<span style='font-size: 11px;'>" +
										translateService.instant(
											'Proprietario'
										) +
										":</span> <label style='display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;'>" +
										record.domain.display_name +
										'</label>';
								}
								// aggiungere logo tour operator nel caso di pacchetto rivenduto
								if (
									(!isFiltered && record.is_owner) ||
									(isFiltered &&
										filteredDomainId === record.domain.id)
								) {
									if (record.tourcms_channel_id_tour_id) {
										// tslint:disable-next-line: max-line-length
										html +=
											'<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px;">Proprietario:</label> <img src="assets/images/tour-operators/tour-cms.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
									} else if (record.bokun_vendor_id_tour_id) {
										// tslint:disable-next-line: max-line-length
										html +=
											'<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
									}else if (record.ticketlandia_museum_id_event_id) {
										// tslint:disable-next-line: max-line-length
										html +=
											'<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 90px; margin-left: 10px;" /></span>';
									}
								}
							}
							return html;
						}
					},
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes'
					}
				]
			}
		};

		this.matDialog
			.open(SelectDatasetRecordDialogComponent, {
				panelClass: 'create-dialog-container',
				data
			})
			.afterClosed()
			.subscribe(tripPackage => {
				if (!tripPackage) return;
				this.loading = true;
				const postData = {
					trip_booking_id: this.datasetRS.recordId,
					trip_package_id: tripPackage.id,
					target: 'milano_card'
				};
				this.datasetService
					.post<any>(
						'/dataset/trip_booking_packages/create',
						postData
					)
					.subscribe({
						next: response => {
							this.loading = false;
							this.reloadTripBookingPackages();
							this.appService.appEvent.next({
								name: RELOAD_BOOKING
							});
						},
						error: response => {
							this.loading = false;
						}
					});
			});
	}

	/**
	 * Return null if not additional info needed
	 */
	getAditionalForm(item: any, target: string, mode = 'create') {
		if (!item) return null;
		const formConfig: IFormConfig = {
			fields: []
		};

		if (target == 'product') {
			if (item.data && item.data.need_activation_date) {
				formConfig.fields.push({
					key: 'date',
					name: 'Data Attivazione',
					valueType: ValueTypes.DATE,
					inputConfig: {
						type: InputTypes.DATE,
						required: true
					}
				});
			}
		}

		if (!formConfig.fields.length) return null;

		return formConfig;
	}

	public requestBookingData(
		item: any,
		target: string,
		mode = 'create',
		initialFormData?: any
	): Observable<any> {
		if (!item) return of(null);
		const requestData: any = {};
		if (mode == 'create') {
			requestData.trip_booking_id = this.datasetRS.recordId;
			// support other target ??
			if (target != 'product') return of(requestData);
			requestData.target = 'product';
			requestData.product_id = item.id;
		}
		const formConfig = this.getAditionalForm(item, target, mode);
		if (!formConfig) return of(mode == 'create' ? requestData : null);

		return this.matDialog
			.open<FormDialogComponent, IFormDialogData, any>(
				FormDialogComponent,
				{
					data: {
						formData: initialFormData,
						formConfig
					}
				}
			)
			.afterClosed()
			.pipe(
				switchMap(data => {
					if (mode == 'create') {
						requestData.target_info = data;
						return of(requestData);
					} else {
						return of(data);
					}
				})
			);
	}

	addProduct(): void {
		this.matDialog
			.open(SelectDatasetRecordDialogComponent, {
				panelClass: 'create-dialog-container',
				data: productSelectionViewConfig
			})
			.afterClosed()
			.pipe(
				switchMap(product => {
					return this.requestBookingData(product, 'product');
				})
			)
			.subscribe(postData => {
				if (!postData) return;
				this.loading = true;
				this.datasetService
					.post<any>(
						'/dataset/trip_booking_packages/create',
						postData
					)
					.subscribe({
						next: response => {
							this.loading = false;
							this.reloadTripBookingPackages();
							this.appService.appEvent.next({
								name: RELOAD_BOOKING
							});
						},
						error: response => {
							this.loading = false;
						}
					});
			});
	}

	selectReturnTrip(going_trip_id: string): void {
		const dialogData: ISelectDatasetRecordDialogData = {
			title: 'Seleziona Ritorno',
			datasetCode: 'going_and_returns',
			filters: { going_trip_id },
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				showSearchBar: false,
				columns: [
					{
						title: 'Descrizione',
						key: 'return_description'
					},
					{
						title: 'Orario',
						key: 'return_start_time'
					}
				]
			}
		};
		this.matDialog
			.open(SelectDatasetRecordDialogComponent, {
				panelClass: 'create-dialog-container',
				data: dialogData
			})
			.afterClosed()
			.subscribe(result => {
				this.loading = true;
				this.datasetService
					.post<any>('/dataset/trip_booking_packages/create', {
						trip_booking_id: this.datasetRS.recordId,
						actual_trip_id: result.return_trip_id
					})
					.subscribe({
						next: response => {
							this.loading = false;
							this.reloadTripBookingPackages();
							this.appService.appEvent.next({
								name: RELOAD_BOOKING
							});
						},
						error: response => {
							this.loading = false;
						}
					});
			});
	}

	activateResource(
		bookingResource: any
	): MatDialogRef<ActionDialogComponent> {
		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			data: {
				title: 'Azione in corso'
			}
		});

		this.datasetService
			.post<any>('/dataset/trip_booking_resources/command/activate', {
				trip_booking_resource_id: bookingResource.id
			})
			.subscribe({
				next: response => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGE,
						extra: {
							trip_booking_package_id:
								bookingResource.trip_booking_package_id
						}
					});
					dialogRef.close();
				},
				error: response => {
					dialogRef.componentInstance.resultStatus = 'error';
					dialogRef.componentInstance.loading = false;
					dialogRef.componentInstance.setMessage(
						'Si è verificato un errore'
					);
				}
			});

		return dialogRef;
	}

	deactivateResource(
		bookingResource: any
	): MatDialogRef<ActionDialogComponent> {
		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			data: {
				title: 'Azione in corso'
			}
		});

		this.datasetService
			.post<any>('/dataset/trip_booking_resources/command/deactivate', {
				trip_booking_resource_id: bookingResource.id
			})
			.subscribe({
				next: response => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGE,
						extra: {
							trip_booking_package_id:
								bookingResource.trip_booking_package_id
						}
					});
					dialogRef.close();
				},
				error: response => {
					dialogRef.componentInstance.resultStatus = 'error';
					dialogRef.componentInstance.loading = false;
					dialogRef.componentInstance.setMessage(
						'Si è verificato un errore'
					);
				}
			});

		return dialogRef;
	}

	makeNotActivableReason(bookingResource: any): string {
		if (!bookingResource || !bookingResource.optional) return null;
		if (typeof bookingResource.max_allotment === 'undefined') return null;
		if (bookingResource.max_allotment === null) {
			return 'Questa risorsa non ha una regola di allotments';
		}
		if (bookingResource.max_allotment === 0) {
			return 'Questa risorsa non ha allotment';
		}
		return null;
	}

	editMeetingPoint(bookingResource: any): void {
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'pivot_trip_resources_trip_meeting_points',
			filters: {
				trip_resource_id: bookingResource.trip_resource_id,
				parentRecordId: bookingResource.trip_resource_id
			},
			title: 'Punto di ritrovo'
		};
		this.matDialog
			.open(SelectDatasetRecordDialogComponent, {
				data
			})
			.afterClosed()
			.subscribe(record => {
				if (!record) return;
				this.loadPickupMeetingPoint = true;
				this.datasetService
					.post(
						this.datasetACS.getUpdatePropertiesRoute(
							bookingResource
						),
						{
							properties_meeting_point_id: record.id
						}
					)
					.subscribe({
						next: response => {
							this.loadMeetingPoint(bookingResource, record);
							this.appService.appEvent.next({
								name: RELOAD_BOOKING_PACKAGE,
								extra: {
									trip_booking_package_id:
										bookingResource.trip_booking_package_id
								}
							});
						},
						error: response => {
							console.warn(response);
						}
					});
			});
	}

	loadMeetingPoint(bookingResource: any, record?: any): void {
		let meeting_point_id =
			bookingResource &&
			bookingResource.properties.meeting_point_id.value;
		if (record) {
			meeting_point_id = record.id;
		}
		if (!meeting_point_id) {
			return;
		}
		this.loadPickupMeetingPoint = true;
		this.datasetService
			.get<any>('/dataset/meeting_points/detail/' + meeting_point_id)
			.subscribe({
				next: response => {
					this.meetingPointPickupResource = response;
					this.loadPickupMeetingPoint = false;
				},
				error: response => {
					this.loadPickupMeetingPoint = false;
				}
			});
	}

	addTrenitaliaService(tripBookingPackageToReplace?: any,type?:string): void {
		if(this.datasetRS.record.value.participant_count==0 && type!='carnet'){
			this.matDialog.open(TextDialogComponent, {
				minWidth: '550px',
				data: {
					title: 'Attenzione',
					contentMessage: 'Aggiungere prima i partecipanti!',
					positiveText: 'OK'
				}
			});
			return;
		}
        let component;
        switch (type) {
            case 'carnet': component = TrenitaliaCarnetComponent;break;
            default: component = TrenitaliaDialogContainerComponent;break;
        }
        this.matDialog
		.open(component, {
			minWidth: '800px',
			panelClass: 'create-dialog-container',
			data: {
				participant: this.datasetRS.record.value.participant_counters,
				trip_booking_id: this.datasetRS.record.value.id,
				trip_booking_package: tripBookingPackageToReplace
			}
		})
		.afterClosed()
		.pipe(
			filter(res => res),
			exhaustMap(res => {
				this.loading = true;
				const postData = {
					trip_booking_id: this.datasetRS.recordId,
					target: 'trenitalia',
					target_info: res
				};
				let actionEndPoint =
					'/dataset/trip_booking_packages/create';
				if (tripBookingPackageToReplace) {
					postData['trip_booking_package_id_to_replace'] =
						tripBookingPackageToReplace.id;
					actionEndPoint =
						'/dataset/trip_bookings/command/replace_trip_external_service';
				}
				return this.datasetService.post<any>(actionEndPoint, postData)
			})
		).subscribe({
			next: response => {
				this.reloadTripBookingPackages();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
				this.loading = false;
			},
			error: (err) => {
				this.loading = false;
			}
		});
	}

	openTripBookingRelatedDialog(
		datasetCode: string,
		tripBookingPackageRelatedDialogData: any
	): void {
		const components = {
			trip_booking_resources: TripBookingResourceComponent,
			trip_booking_services: TripBookingServiceComponent,
			trip_booking_participant_extras: TripBookingParticipantComponent
		};
		if (!components[datasetCode]) return;

		const data = {
			...tripBookingPackageRelatedDialogData,
			...{ datasetCode: datasetCode, actionCode: 'list' }
		};
		this.datasetNavigatorService
			.openDialog<ITripBookingPackageRelatedData>(
				components[datasetCode],
				data,
				{
					panelClass: 'trip-booking-related-dialog-container',
					minWidth: 700
				}
			)
			.afterClosed()
			.subscribe(() => {
				this.reloadTripBooking();
			});
	}

	reloadTripBooking(): void {
		this.appService.appEvent.next({ name: RELOAD_BOOKING });
	}
}
