import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WebSocketService } from './websocket.service';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
import * as i4 from "./websocket.service";
import * as i5 from "@angular/material/dialog";
export var LocalStorageFilterDomainIdKey = 'DomainFilterService.filterDomainId';
var DomainFilterService = /** @class */ (function () {
    function DomainFilterService(appService, router, http, webSocketService, matDialog) {
        var _this = this;
        this.appService = appService;
        this.router = router;
        this.http = http;
        this.webSocketService = webSocketService;
        this.matDialog = matDialog;
        this.loadingLogo = false;
        this.domainSettings = new Map();
        this.domainSettingsLoadSubscriptions = new Map();
        this.authLoaded = false;
        this.domainSettingChangeEvent = new Subject();
        this.filterDomainId = new BehaviorSubject(localStorage.getItem(LocalStorageFilterDomainIdKey));
        this.domainData = new BehaviorSubject(null);
        this.filterDomainId
            .pipe(distinctUntilChanged())
            .subscribe(function (newValue) {
            if (newValue)
                localStorage.setItem(LocalStorageFilterDomainIdKey, newValue);
            else
                localStorage.removeItem(LocalStorageFilterDomainIdKey);
            // set filter domain id to web socket for join room
            _this.webSocketService.setFilterDomain(newValue);
            _this.loadDomainSettings(newValue);
        });
        this.domainData.subscribe(function (domainData) {
            if (typeof (window['FreshworksWidget']) === 'function') {
                var custom_fields = {
                    cf_domain_id: '',
                    cf_domain_name: ''
                };
                if (domainData) {
                    custom_fields.cf_domain_id = domainData.id;
                    custom_fields.cf_domain_name = domainData.display_name;
                }
                window['FreshworksWidget']('prefill', 'ticketForm', {
                    priority: 1,
                    status: 2,
                    custom_fields: custom_fields
                });
            }
        });
    }
    DomainFilterService.prototype.implementDomainChangeHanlder = function (component) {
        return component && typeof (component.handleDomainChanges) === 'function';
    };
    DomainFilterService.prototype.refreshPageOnFilterChanged = function () {
        var currentComponent = this.appService.currentRoutedComponent;
        if (currentComponent
            && this.implementDomainChangeHanlder(currentComponent)
            && currentComponent.handleDomainChanges()) {
            return; // prevent de default handle
        }
        this.router.navigate(['/home']);
    };
    DomainFilterService.prototype.setFilterDomain = function (domainId, refresh) {
        if (domainId && domainId !== 'null')
            this.filterDomainId.next(domainId);
        else
            this.filterDomainId.next(null);
        if (refresh !== false)
            this.refreshPageOnFilterChanged();
        this.refreshDomainData();
    };
    DomainFilterService.prototype.unsetFilterDomain = function () {
        this.setFilterDomain(null);
    };
    DomainFilterService.prototype.wrapInDomain = function (dialogComponent, callback, dataContainerService, returnEmpty) {
        if (returnEmpty === void 0) { returnEmpty = false; }
        if (this.filterDomainId.value) {
            callback(this.filterDomainId.value);
        }
        else if (dataContainerService && dataContainerService.hasValueInKeyPath('contextual_domain_id')) {
            callback(dataContainerService.getValueFromKeyPath('contextual_domain_id'));
        }
        else {
            this.matDialog.open(dialogComponent, {
                disableClose: true
            }).afterClosed()
                .subscribe(function (selection) {
                if (!selection) {
                    if (returnEmpty)
                        callback(null);
                    return;
                }
                callback(selection);
            });
        }
    };
    DomainFilterService.prototype.loadDomainSettings = function (domainId) {
        var _this = this;
        if (!domainId)
            domainId = 'null';
        if (this.domainSettings.has(domainId)) {
            this.domainSettingChangeEvent.next({
                domain_id: domainId,
                settings: this.domainSettings.get(domainId)
            });
            return;
        }
        if (this.domainSettingsLoadSubscriptions.has(domainId))
            return;
        if (!this.authLoaded)
            return;
        var sub = this.http.get(this.appService.getBaseServerAddress() + '/auth/domain/settings/' + domainId).subscribe({
            next: function (response) {
                if (response) {
                    _this.domainSettings.set(domainId, response);
                    _this.domainSettingChangeEvent.next({
                        domain_id: domainId,
                        settings: response
                    });
                }
                _this.domainSettingsLoadSubscriptions.delete(domainId);
            },
            error: function (e) {
                _this.domainSettingsLoadSubscriptions.delete(domainId);
            }
        });
        this.domainSettingsLoadSubscriptions.set(domainId, sub);
    };
    DomainFilterService.prototype.getDomainSetting = function (domainId, path) {
        if (domainId == 'current')
            domainId = this.filterDomainId.value;
        var settings = this.domainSettings.get(domainId || this.filterDomainId.value || 'null');
        if (path)
            return _.get(settings, path);
        return settings;
    };
    DomainFilterService.prototype.refreshDomainData = function () {
        var _this = this;
        if (this.refreshDomainDataSubscription) {
            this.refreshDomainDataSubscription.unsubscribe();
        }
        if (!this.filterDomainId.value) {
            this.domainData.next(null);
            return;
        }
        var cacheKey = 'DomainFilterService.domain.' + this.filterDomainId.value;
        try {
            var cachedDomainData = JSON.parse(localStorage.getItem(cacheKey));
            if (cachedDomainData) {
                this.domainData.next(cachedDomainData);
                return;
            }
        }
        catch (e) {
            // ignored
        }
        this.refreshDomainDataSubscription = this.http.get(this.appService.getBaseServerAddress() + '/dataset/domains/detail/' + this.filterDomainId.value).subscribe({
            next: function (response) {
                if (response) {
                    _this.domainData.next({
                        id: response.id,
                        code: response.code,
                        display_name: response.display_name,
                        domain_path: response.domain_path,
                        logo: response.logo,
                        enabled: response.enabled
                    });
                    localStorage.setItem(cacheKey, JSON.stringify(_this.domainData.value));
                }
            },
            error: function () {
                _this.domainData.next(null);
            }
        });
    };
    /**
     * called on logout
     */
    DomainFilterService.prototype.clear = function () {
        var e_1, _a;
        this.authLoaded = false;
        this.domainSettings.clear();
        this.filterDomainId.next(null);
        if (this.refreshDomainDataSubscription)
            this.refreshDomainDataSubscription.unsubscribe();
        this.refreshDomainDataSubscription = null;
        try {
            for (var _b = tslib_1.__values(Array.from(this.domainSettingsLoadSubscriptions.entries())), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], sub = _d[1];
                if (sub)
                    sub.unsubscribe();
                this.domainSettingsLoadSubscriptions.delete(key);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    DomainFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainFilterService_Factory() { return new DomainFilterService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.WebSocketService), i0.ɵɵinject(i5.MatDialog)); }, token: DomainFilterService, providedIn: "root" });
    return DomainFilterService;
}());
export { DomainFilterService };
