import { ValueTypes, FieldViews, IColumnDefinition } from 'app/interfaces';

export const LIST_COLUMNS: IColumnDefinition[] = [
	{
		title: 'Data opreazione',
		key: 'date_log'
	},
	{
		title: 'Descrizione opreazione',
		key: 'message',
		fieldDefinition: {
			name: 'Messaggio',
			key: 'message',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				return record.message || '';
			}
		}
	},
	{
		title: 'Canale/Utente',
		key: 'user_id',
		fieldDefinition: {
			name: 'Canale',
			key: 'user_id',
			valueType: ValueTypes.STRING,
			fieldView: FieldViews.HTML,
			getDisplayValue(record: any): string{
				if(!record) return '';

				let label = '';

				if(record.username){
					label = record.username
				}

				return label;
			}
		}
	}
];
