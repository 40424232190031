import { IStepViewConfig } from 'app/interfaces';
import generalForm from './forms/general.form';
import generalViewModeForm from './forms/general-view-mode.form';
import { TripPackageLanguageContentConfig } from '../trip_package_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { TripServiceConfig } from '../trip_services';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';

export const config: IStepViewConfig = {
	title: 'Pacchetto',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	showOverview: true,
	steps: [
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-form-step',
			options: {
				formConfig: generalForm
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Informazioni Base',
			stepperTitle: 'Informazioni <br>Base',
			component: 'common-data-viewer-step',
			options: {
				fields: generalViewModeForm.fields
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Avanzate',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_package_resources',
						title: 'Risorse'
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_destinations',
						title: 'Destinazioni'
					},
					{
						type: 'list',
						datasetCode: 'meeting_point_unified_list_items',
						title: 'Punti di ritrovo'
					},
					{
						type: 'list',
						datasetCode: 'pivot_trip_packages_trip_categories',
						title: 'Categorie'
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-form-step',
			options: {
				formConfig: TripPackageLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-data-viewer-step',
			options: {
				fields: TripPackageLanguageContentConfig.formConfigsMap['textForm'].fields,
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			title: 'Servizi',
			stepperTitle: 'Servizi',
			component: 'trip-package-step-3',
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Partenze',
			component: 'trip-package-step-4',
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Servizi e prezzi',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_services',
						updateParent: true,
						title: TripServiceConfig.title
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			title: 'Integrazioni',
			component: 'trip-package-integrations-step',
			showIf(container: CommonStepViewContainerComponent): boolean{
				const record = container.record;
				
				if(!record) return true; // show while create, mean user is the owner anyway
				return isRecordInFilteredDomain(record, record.is_owner);
				/*const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
				const isFiltered = filteredDomainId && filteredDomainId !== 'null';
				
				return (!isFiltered && record.is_owner) || (isFiltered && filteredDomainId === record.domain.id);*/
			}
		}
		/*{
			title: 'Recensioni',
			component: 'common-blocks-step',
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_package_reviews',
						filters(component, record): any{
							return { trip_package_id: record.id };
						}
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		}*/
	]
};
