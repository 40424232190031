/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "../../../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "../../../mat-table/mat-table.component.ngfactory";
import * as i12 from "../../../../../../services/dataset-navigator.service";
import * as i13 from "../../../../../../../../../services/app.service";
import * as i14 from "@angular/material/dialog";
import * as i15 from "@angular/router";
import * as i16 from "../../../../../../services/dataset.service";
import * as i17 from "../../../mat-table/mat-table.component";
import * as i18 from "../../../../../../services/step-state.service";
import * as i19 from "@angular/common";
import * as i20 from "../../../../../../../language-select/language-select.component.ngfactory";
import * as i21 from "../../../../../../../language-select/language-select.component";
import * as i22 from "../../../../../../services/dataset-action-container.service";
import * as i23 from "../../../../../../../../pages/page-closure-container.service";
import * as i24 from "../../../../../../../../../services/auth.service";
import * as i25 from "../../../../../../../../../services/domain-filter.service";
import * as i26 from "@ngx-translate/core";
import * as i27 from "../../../../../../services/dataset-record.service";
import * as i28 from "../../../../../../services/dataset-multilanguage-container.service";
import * as i29 from "./step.component";
import * as i30 from "@angular/forms";
var styles_TripPackageTripFacilitiesComponent = [i0.styles];
var RenderType_TripPackageTripFacilitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TripPackageTripFacilitiesComponent, data: {} });
export { RenderType_TripPackageTripFacilitiesComponent as RenderType_TripPackageTripFacilitiesComponent };
function View_TripPackageTripFacilitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TripPackageTripFacilitiesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Questa traduzione non \u00E8 ancora presente!"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "mt-24 mb-24"], ["fxLayout", "row"], ["fxLayoutAlign", "end center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 1720320, null, 0, i5.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i7.Directionality, i6.StyleUtils, [2, i5.LayoutGapStyleBuilder], i6.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(6, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goPrevStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Indietro"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "10px"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "end center"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 8).disabled || null); var currVal_4 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4); }); }
function View_TripPackageTripFacilitiesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "custom-mat-table", [], null, [[null, "nextStep"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextStep" === en)) {
        var pd_0 = (_co.goNextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_CustomMatTableComponent_0, i11.RenderType_CustomMatTableComponent)), i1.ɵprd(512, null, i12.DatasetNavigatorService, i12.DatasetNavigatorService, [i13.AppService, i14.MatDialog, i15.Router, i1.Injector, i16.DatasetService, i1.NgZone]), i1.ɵdid(3, 245760, null, 0, i17.CustomMatTableComponent, [i13.AppService, i16.DatasetService, i12.DatasetNavigatorService, i18.StepService], { relation: [0, "relation"], displayedColumns: [1, "displayedColumns"], filterFormGroup: [2, "filterFormGroup"], field: [3, "field"], datasetACS: [4, "datasetACS"], datasetRS: [5, "datasetRS"], dynamCols: [6, "dynamCols"], stepName: [7, "stepName"], datasetConfig: [8, "datasetConfig"], formConfigMap: [9, "formConfigMap"], datasetCode: [10, "datasetCode"], initialValueKey: [11, "initialValueKey"] }, { nextStep: "nextStep" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "facilities"; var currVal_1 = _co.displayedColumns; var currVal_2 = _co.filterFormGroup; var currVal_3 = _co.field; var currVal_4 = _co.parentDatasetACS; var currVal_5 = _co.datasetRS; var currVal_6 = _co.dynamCols; var currVal_7 = "trip_package_facilities"; var currVal_8 = _co.datasetConfig; var currVal_9 = _co.formConfig; var currVal_10 = "trip_packages"; var currVal_11 = "title"; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_TripPackageTripFacilitiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mt-24"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripPackageTripFacilitiesComponent_3)), i1.ɵdid(2, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripPackageTripFacilitiesComponent_4)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.datasetRS.record.value && !_co.datasetACS.loading.value); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.datasetRS.record.value && (_co.datasetRS.record.value["lang"] === _co.appService.language.value)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TripPackageTripFacilitiesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "full-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "language-select", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.appService.language.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i20.View_LanguageSelectComponent_0, i20.RenderType_LanguageSelectComponent)), i1.ɵdid(2, 114688, null, 0, i21.LanguageSelectComponent, [i13.AppService], { languageCodes: [0, "languageCodes"], activeLanguageCodes: [1, "activeLanguageCodes"], selected: [2, "selected"] }, { changed: "changed" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripPackageTripFacilitiesComponent_1)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TripPackageTripFacilitiesComponent_2)), i1.ɵdid(6, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appService.supportedLanguageCodes; var currVal_1 = _co.datasetMCS.activeLanguageCodes; var currVal_2 = _co.appService.language.value; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.datasetACS.loading.value; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.datasetACS.ready.value; _ck(_v, 6, 0, currVal_4); }, null); }
export function View_TripPackageTripFacilitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "trip-package-trip-facilities", [], null, null, null, View_TripPackageTripFacilitiesComponent_0, RenderType_TripPackageTripFacilitiesComponent)), i1.ɵprd(512, null, i22.DatasetActionContainerService, i22.DatasetActionContainerService, [i13.AppService, i23.PageClosureContainerService, i24.AuthService, i16.DatasetService, i25.DomainFilterService, i26.TranslateService, i1.Injector]), i1.ɵprd(512, null, i27.DatasetRecordService, i27.DatasetRecordService, [i22.DatasetActionContainerService, i16.DatasetService]), i1.ɵprd(512, null, i28.DatasetMultilanguageContainerService, i28.DatasetMultilanguageContainerService, [i13.AppService, i16.DatasetService]), i1.ɵdid(4, 4440064, null, 0, i29.TripPackageTripFacilitiesComponent, [i13.AppService, i27.DatasetRecordService, i22.DatasetActionContainerService, i12.DatasetNavigatorService, i16.DatasetService, i28.DatasetMultilanguageContainerService, i30.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var TripPackageTripFacilitiesComponentNgFactory = i1.ɵccf("trip-package-trip-facilities", i29.TripPackageTripFacilitiesComponent, View_TripPackageTripFacilitiesComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious", parentDatasetACS: "parentDatasetACS", parentDatasetRS: "parentDatasetRS", datasetConfig: "datasetConfig" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { TripPackageTripFacilitiesComponentNgFactory as TripPackageTripFacilitiesComponentNgFactory };
