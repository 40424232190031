<div class="recap-menu" *ngIf="['travel','travel-change'].includes(checkoutData.mode)">
    <div *ngIf="!['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="trnService.total.value"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">

        </trenitalia-recap>
    </div>
    <div class="flex-col flex-gap-8" *ngIf="['RETURN'].includes(checkoutData.direction)">
        <trenitalia-recap
            [start]="checkoutData.forward.selectedSolution.start.id"
            [end]="checkoutData.forward.selectedSolution.end.id"
            [departure]="checkoutData.forward.selectedSolution.departure"
            [arrive]="checkoutData.forward.selectedSolution.arrival"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.forward.total"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [fw]="true"
            [dataAv]="checkoutData.forward.dataAv?checkoutData.forward.dataAv:null"
            [trains]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution:null"
            [offeredServices]="checkoutData.forward.selectedSolution?checkoutData.forward.selectedSolution.offeredServices:null">
        </trenitalia-recap>
        <trenitalia-recap
            [start]="checkoutData.selectedSolution?checkoutData.selectedSolution.start.id:checkoutData.from"
            [end]="checkoutData.selectedSolution?checkoutData.selectedSolution.end.id:checkoutData.to"
            [departure]="checkoutData.selectedSolution?checkoutData.selectedSolution.departure:checkoutData.start"
            [arrive]="checkoutData.selectedSolution?checkoutData.selectedSolution.arrival:null"
            [direction]="checkoutData.direction"
            [tot]="checkoutData.selectedSolution?checkoutData.totalSelectedSolution:0"
            [adult]="checkoutData.adult"
            [children]="checkoutData.children"
            [dataAv]="checkoutData.dataAv?checkoutData.dataAv:null"
            [trains]="checkoutData.selectedSolution?checkoutData.selectedSolution.trains:null"
            [selectedSolution]="checkoutData.selectedSolution?checkoutData.selectedSolution:null"
            [offeredServices]="checkoutData.selectedSolution?checkoutData.selectedSolution.offeredServices:null">

        </trenitalia-recap>

        <div >
            <span class="total text-color-dark-blue" matTooltip="{{trnService.total.value['fee']>0?'Prezzo:'+(trnService.total.value['valueNet']| currency:'EUR':'symbol':'1.2-2')+' + Diritti agenzia: '+(trnService.total.value['fee']| currency:'EUR':'symbol':'1.2-2'):''}}">
                Totale: {{trnService.total.value['value'] | currency:'EUR':'symbol':'1.2-2'}}
            </span>
        </div>
    </div>
</div>

<div class="container">
    <form *ngIf='formViewerService.initialized && formViewerService.formGroup && !load'
          [formGroup]="formViewerService.formGroup">
        <div *ngIf="!checkoutData.editPassengers || ['edit','edit-traveller-pre-sale'].includes(checkoutData.mode)">
            <h2>Contatti acquirente</h2>
            <mat-card class="card-buyer">
                <mat-card-content>
                    <div fxLayout="row wrap" fxLayoutGap="3px">
                        <div fxFlex>
                            <form-input-viewer
                                [formInputDefinition]='formFields.get("first_name")'
                                [formGroup]='formViewerService.formGroup'
                                [formControlKey]='formViewerService.controlKeyMap["first_name"]'
                                [dataContainerService]='datasetACS'
                            ></form-input-viewer>
                        </div>

                        <div fxFlex>
                            <form-input-viewer
                                [formInputDefinition]='formFields.get("last_name")'
                                [formGroup]='formViewerService.formGroup'
                                [formControlKey]='formViewerService.controlKeyMap["last_name"]'
                                [dataContainerService]='datasetACS'
                            ></form-input-viewer>
                        </div>
						<div fxFlex></div>
					</div>
					<div fxLayout="row wrap" fxLayoutGap="3px">
                        <div fxFlex>
                            <form-input-viewer
                                [formInputDefinition]='formFields.get("email")'
                                [formGroup]='formViewerService.formGroup'
                                [formControlKey]='formViewerService.controlKeyMap["email"]'
                                [dataContainerService]='datasetACS'
                            ></form-input-viewer>
                        </div>
                        <!--<div fxFlex>
                            <form-input-viewer
                                [formInputDefinition]='formFields.get("confirm_email")'
                                [formGroup]='formViewerService.formGroup'
                                [formControlKey]='formViewerService.controlKeyMap["confirm_email"]'
                                [dataContainerService]='datasetACS'
                            ></form-input-viewer>
                        </div>-->
                        <div fxFlex>
                            <form-input-viewer
                                [formInputDefinition]='formFields.get("phone")'
                                [formGroup]='formViewerService.formGroup'
                                [formControlKey]='formViewerService.controlKeyMap["phone"]'
                                [dataContainerService]='datasetACS'
                            ></form-input-viewer>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="start">
                    <mat-slide-toggle
                        class="example-margin"
                        color="#F39200"
                        [ngModel]="checkPass"
						(toggleChange)="togglePassengerBuyer()"
						[ngModelOptions]="{standalone: true}"
                    >
                        L’acquirente corrisponde al passeggero 1
                    </mat-slide-toggle>
                </mat-card-actions>
            </mat-card>
        </div>
        <h2>Passeggeri</h2>
        <div class="alert" *ngIf="checkoutData.mode != 'edit-travellers'">
            <mat-icon aria-hidden="false">warning</mat-icon>
            I biglietti sono nominativi. Lascia anche l'email e/o il telefono in caso dovessimo contattarti
        </div>

        <div *ngIf="!load">
            <div *ngFor="let passenger of passengers; index as i;trackBy: indexTrackBy">
                <trenitalia-passenger-form 
					[passenger]="passenger"
					[copyContactData]="checkPass && i == 0"
					[index]="i" [contactData]="passenger1"
                    (validChild)="getValid($event)"
				></trenitalia-passenger-form>
            </div>
        </div>
		<ng-container *ngIf="!checkoutData.mode || ['travel','edit','edit-traveller-pre-sale'].includes(checkoutData.mode)">
			<!-- Billing Form -->
			<div fxLayout="row wrap" fxLayoutGap="3px">
				<form-input-viewer
					[formInputDefinition]='formFields.get("request_invoice")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["request_invoice"]'
				></form-input-viewer>
			</div>
			<trenitalia-billing-form *ngIf="requestInvoice" [billingData]="checkoutData.invoiceData"></trenitalia-billing-form>
		</ng-container>
    </form>
</div>