import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/discount_rules/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	params['discount_rule_list_id'] = dataContainerService.getValueFromKeyPath('discount_rule_lists.record.id');
	if(!params['discount_rule_list_id']){
		params['discount_rule_list_id'] = dataContainerService.getValueFromKeyPath('discounts.record.discount_rule_list_id');
	}
	return params;
}

export const DiscountRuleConfig: IDatasetConfig = {
	name: 'discount_rules',
	title: 'Listini',
	singleTitle: 'Listino',
	ajaxDatasetCode: 'discount_rules',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: editForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Servizio',
						key: 'service_description'
					},
					{
						title: 'Pacchetto',
						key: 'package_description'
					},
					{
						title: 'Risorsa',
						key: 'resource_description'
					},
					{
						title: 'Categoria',
						key: 'category_description'
					},
					{
						title: 'Partenze da/a',
						key: 'departure_from_to'
					},
					{
						title: 'Prenotazioni da/a',
						key: 'booking_from_to',
					},
					{
						title: 'Sconto',
						key: 'discount_value'
					},
					{
						title: 'Tipologia',
						key: 'target',
						showIf(dataContainerService){
							return dataContainerService && dataContainerService.domainFilterService.getDomainSetting('current', 'enabled_modules.trenitalia.enabled') == true;
						}
					}
				],
				actions: ['create', 'edit', 'delete']
			}
		}
	}
};
