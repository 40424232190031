import { Component, Input, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IStepConfig } from 'app/interfaces';
import { DatasetListComponent } from '../../../base-actions/list/dataset-list.component';

@Component({
	selector   : 'common-i18n-blocks-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers  : [ DatasetActionContainerService, DatasetRecordService, DatasetMultilanguageContainerService ]
})
export class CommonI18nBlocksStepComponent extends BaseStepViewComponent implements OnInit{
	
	blockFilters: any[];
	@Input() public stepIndex: number;
	@Input() stepConfig: IStepConfig;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;
	@ViewChildren('listBlocks') listBlocks: QueryList<DatasetListComponent>;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public datasetMCS: DatasetMultilanguageContainerService
	){
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.datasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentValueKey
			});
			this.datasetMCS.fetchRecord();
		});

		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe( record => {
			this.blockFilters = [];
			for (const blockConfig of this.stepConfig.options.blocks) {
				let blockFilter = {};
				if(blockConfig.filters){
					blockFilter = blockConfig.filters(this, this.datasetRS.record.value);
				}
				this.blockFilters.push(blockFilter);
			}
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
	}

	onCreatedInBlock(blockConfig: any): void{
		if(blockConfig.updateParent){
			this.datasetRS.reload()
			.subscribe(() => {
				const block = this.listBlocks.find( elementRef => {
					return elementRef.datasetCode === blockConfig.datasetCode;
				});
				if(block) block.reload();
			});
		}
	}
}
