import * as SocketIO from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import * as i0 from "@angular/core";
import * as i1 from "./app-config.token";
import * as i2 from "./app.service";
var WebSocketService = /** @class */ (function () {
    function WebSocketService(config, appService) {
        this.config = config;
        this.appService = appService;
        this.domainFilterId = new BehaviorSubject(localStorage.getItem('DomainFilterService.filterDomainId'));
        this.isAuthenticated = new BehaviorSubject(false);
    }
    Object.defineProperty(WebSocketService.prototype, "enabled", {
        get: function () { return this.config && this.config.websocket && this.config.websocket.enabled; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebSocketService.prototype, "port", {
        get: function () { return this.config && this.config.websocket && this.config.websocket.port; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebSocketService.prototype, "serverAddress", {
        get: function () {
            if (!this.config)
                return null;
            if (this.config.websocket.url) {
                return this.config.websocket.url;
            }
            else {
                return this.config.serverAddress;
            }
        },
        enumerable: true,
        configurable: true
    });
    WebSocketService.prototype.setFilterDomain = function (domainId) {
        this.domainFilterId.next(domainId);
    };
    WebSocketService.prototype.joinChannels = function () {
        this.ioSocket.emit('join', 'private-message-channel');
        this.ioSocket.emit('join', 'private-datasets-channel');
        this.ioSocket.emit('join', 'private-booking-channel');
    };
    WebSocketService.prototype.clear = function () {
        if (this.ioSocket && !this.ioSocket.disconnected) {
            this.ioSocket.close();
        }
        window['ioSocket'] = null;
        this.ioSocket = null;
    };
    WebSocketService.prototype.init = function () {
        var _this = this;
        if (!this.enabled)
            return;
        // disable websocket connection from staging host to production
        if (window.location.host.includes('zverp') && this.serverAddress && this.serverAddress.includes('tangerine.theorangeseed')) {
            return;
        }
        if (!window['ioSocket']) {
            var socketAddress = this.serverAddress;
            if (this.port) {
                socketAddress += ':' + this.port;
            }
            window['ioSocket'] = SocketIO(socketAddress, {
                // port mi sa che non è un opzione supportata
                port: this.port,
                secure: true
            });
            this.ioSocket = window['ioSocket'];
            this.ioSocket.on('connect', function () {
                console.log('creating websocket service');
                if (!_this.ioSocket) {
                    // why this.ioSocket was null ??
                    if (window['ioSocket']) {
                        _this.ioSocket = window['ioSocket'];
                    }
                    else {
                        console.warn('connect with no socket', _this, window);
                        return;
                    }
                }
                console.log('socket io connected', _this.ioSocket.id);
                // prevent multiple subscription
                if (_this.domainSubscription)
                    _this.domainSubscription.unsubscribe();
                // join channels
                _this.domainSubscription = _this.domainFilterId.subscribe(function (domainId) {
                    if (!domainId)
                        return;
                    // try join room
                    _this.ioSocket.emit('join', {
                        'channel': 'private-' + domainId + '-channel',
                        'domain_id': domainId
                    });
                });
            });
            this.ioSocket.on('error', function (err) {
                console.log('ioSocket.error', err);
            });
            this.ioSocket.on('test-event', this.onTestEvent.bind(this));
        }
        else {
            this.ioSocket = window['ioSocket'];
        }
        /*if(!window['echo']){
            window['echo'] = new Echo({
                broadcaster: 'socket.io',
                host: this.serverAddress + ':' + this.port
            });
            this.echo = window['echo'];
            this.echo.private('datasets')
            .listen('datasetEvent.updated', (data) => {
                console.log('websocket updated event', data);
            }).listen('datasetEvent.created', (data) => {
                console.log('websocket created event', data);
            }).listen('datasetEvent.deleted', (data) => {
                console.log('websocket deleted event', data);
            });

            this.echo.private('messages')
            .listen('messageEvent', (data) => {
                console.log('websocket updated event', data);
            });
        }*/
    };
    WebSocketService.prototype.onTestEvent = function (testEvent) {
        console.log(testEvent);
    };
    WebSocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebSocketService_Factory() { return new WebSocketService(i0.ɵɵinject(i1.APP_CONFIG, 8), i0.ɵɵinject(i2.AppService)); }, token: WebSocketService, providedIn: "root" });
    return WebSocketService;
}());
export { WebSocketService };
