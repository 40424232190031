import { Component, OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { HttpHeaders } from '@angular/common/http';
import { locale as english } from '../i18n/en';
import { locale as italiano } from '../i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
	selector   : 'entity-types-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss']
})
export class EntityTypesStepComponent extends BaseStepViewComponent implements OnInit{
	
	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		private fuseTranslationLoader: FuseTranslationLoaderService){
			// parent
			super(datasetACS, datasetNavigatorService);
			// carica traduzioni
			this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	ngOnInit(): void{}

	createEntityType(type: string): void{
		this.datasetACS.loading.next(true);
		let headers = new HttpHeaders({});
		if(this.datasetACS.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
		}
		this.datasetService.post('/dataset/entities/command/create_entity_type', {
			entity_type: type,
			entity_id: this.datasetRS.record.value.id
		}, {
			headers
		})
		.subscribe({
			next: () => {
				this.datasetACS.loading.next(false);
				this.datasetRS.reload();
			},
			error: () => {
				this.datasetACS.loading.next(false);
				this.appService.showErrorMessage('Si è verificato un errore imprevisto.');
			}
		});
	}
}
