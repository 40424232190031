import { Component, OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
	selector   : 'date-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
})
export class DateInputComponent extends DestroyableComponent implements OnInit{

	public dateFilterBinded: (d: Date) => boolean;
	public manualInput:  boolean;

	constructor(
		public inputService: FormInputViewerService
	){
		super();
		this.manualInput =_.get(this.inputService.formInputDefinition,'extra.manualInput',false);
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			this.dateFilterBinded = this.dateFilter.bind(this);
		});
	}

	dateFilter(d: Date): boolean{
		if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter){
			return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
		}
		return true;
	}
}
