import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('date', {
	key: 'date',
	name: 'Data',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	formValidators: [ Validators.required ]
});

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('total', {
	key: 'total',
	name: 'Totale',
	valueType: ValueTypes.NUMBER,
});

export {fieldsMap};
