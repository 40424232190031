import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('tour_operator', {
	key: 'tour_operator',
	name: 'Tour Operator',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('phone_number', {
	key: 'phone_number',
	name: 'Tel.',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('email', {
	key: 'email',
	name: 'Email',
	inputType: InputTypes.EMAIL,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('info_meeting', {
	key: 'info_meeting',
	name: 'Info Meeting',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('customer_service', {
	key: 'customer_service',
	name: 'Servizio Clienti',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('terms_of_use', {
	key: 'terms_of_use',
	name: 'Termini di utilizzo',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

export {fieldsMap};
