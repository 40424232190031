<div class="flex">
	<div class="flex-item">
		<div class="loading-div" *ngIf='dashboardService.loadingCheckoutDate || showLoader'>
			<mat-spinner *ngIf='dashboardService.loadingCheckoutDate' diameter="80" class='full-center-spinner'></mat-spinner>
		</div>
		<div style="display: block;">
			<canvas baseChart width="400" height="400" *ngIf='revenue'
			[datasets]="revenue"
			[labels]="barChartLabels" 
			[options]="barChartOptions"
			[legend]="barChartLegend" 
			[chartType]="barChartType"
			></canvas>
		</div>
	</div>
</div>