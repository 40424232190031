import * as tslib_1 from "tslib";
import { OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrenitaliaService, trainTypeImages } from '../../trenitalia.service';
import { AppService } from '../../../../../../services/app.service';
import { BookingService } from '../../../booking.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { supportedPostSaleActions, TrenitaliaPostSaleService } from '../trenitalia-post-sale.service';
var TrenitaliaSolutionDetailsComponent = /** @class */ (function () {
    function TrenitaliaSolutionDetailsComponent(appService, matDialog, trnService, mastSnackBar, bookingService, trenitaliaPostSaleService, viewContainerRef) {
        this.appService = appService;
        this.matDialog = matDialog;
        this.trnService = trnService;
        this.mastSnackBar = mastSnackBar;
        this.bookingService = bookingService;
        this.trenitaliaPostSaleService = trenitaliaPostSaleService;
        this.viewContainerRef = viewContainerRef;
        this.cancelTimeEnded = false;
        this.showCancelTimer = false;
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(TrenitaliaSolutionDetailsComponent.prototype, "isCarnet", {
        get: function () {
            return this.trenitaliaPostSaleService.isCarnet;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrenitaliaSolutionDetailsComponent.prototype, "trainTypeImages", {
        get: function () {
            return trainTypeImages;
        },
        enumerable: true,
        configurable: true
    });
    TrenitaliaSolutionDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trenitaliaPostSaleService.travelDetails
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (travelDetails) {
            // map post sale actions
            _this.updatePostActionButtons();
            if (_this.isCarnet) {
                _this.carnetData = _.get(travelDetails, 'travel.original.entitlements');
                _this.carnetExpire = _.get(travelDetails, 'travel.original.travelSolutions.0.offeredServices.endValidity');
            }
            _this.status = _.get(_this.trenitaliaPostSaleService.tripBookingPackage, 'tour_operator_booking_status');
        });
    };
    TrenitaliaSolutionDetailsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TrenitaliaSolutionDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.travelSolution) {
            this.updateTravelSolutionData();
        }
    };
    TrenitaliaSolutionDetailsComponent.prototype.updateTravelSolutionData = function () {
        var e_1, _a;
        var travellersWithOfferedService = new Set();
        var travelSolutionTickets = {};
        var _loop_1 = function (offeredServiceXmlId, offeredService) {
            if (!['SELECTED', 'PURCHASED'].includes(offeredService.status))
                return "continue";
            if (!_.has(travelSolutionTickets, offeredService.nodeId)) {
                travelSolutionTickets[offeredService.nodeId] = { tickets: [] };
            }
            var seatInfo = null;
            var reservationCodes = null;
            var reservation = _.get(offeredService, 'original.bookingInfo.reservation');
            if (reservation) {
                reservationCodes = { cpCode: reservation.cpCode, pnrCode: reservation.pnrCode, referenceNumber: reservation.referenceNumber, ticketCode: _.get(offeredService, 'original.entitlementId') };
            }
            if (reservation && reservation.showSeat == 'true') {
                seatInfo = { wagon: reservation.wagon, seat: reservation.seat };
            }
            if (!reservation && _.has(this_1.travelSolution, 'seatMap')) {
                var travellerSeat = this_1.travelSolution.seatMap.find(function (s) { return s.offeredServicesId == offeredServiceXmlId; });
                seatInfo = { wagon: travellerSeat.wagon, seat: travellerSeat.airCraftNumber };
            }
            var traveller = this_1.trenitaliaPostSaleService.travellers.find(function (t) { return t.xmlId == offeredService.travellerXmlId; });
            var param = _.get(offeredService, 'original.parameters', []);
            var position = void 0;
            if (_.isArray(param)) {
                var parameters = param.find(function (p) { return p.typeId == 2; });
                position = parameters ? parameters.value : null;
            }
            if (!_.has(travelSolutionTickets, [offeredService.nodeId, 'tickets', traveller.xmlId])) {
                travelSolutionTickets[offeredService.nodeId].tickets[traveller.xmlId] = [];
            }
            travelSolutionTickets[offeredService.nodeId].tickets[traveller.xmlId].push({
                traveller: traveller,
                catalogServiceName: _.get(this_1.trenitaliaPostSaleService.serviceMap, ['catalog_services', offeredService.catalogServiceId, 'display_name']),
                offerName: _.get(this_1.trenitaliaPostSaleService.serviceMap, ['offers', offeredService.offerId, 'display_name']),
                position: position,
                offeredService: offeredService,
                seatInfo: seatInfo,
                reservationCodes: reservationCodes
            });
            if (traveller)
                travellersWithOfferedService.add(traveller.xmlId);
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(Object.entries(this.travelSolution.offeredServices)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), offeredServiceXmlId = _d[0], offeredService = _d[1];
                _loop_1(offeredServiceXmlId, offeredService);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.travelSolutionTicketsMap = travelSolutionTickets;
        this.travellers = this.trenitaliaPostSaleService.travellers.filter(function (t) { return travellersWithOfferedService.has(t.xmlId); });
        this.postActions = _.get(this.trenitaliaPostSaleService, ['postActions', this.travelSolution.xmlId]);
        this.updatePostActionButtons();
    };
    /**
     * update post action buttons
     * @returns
     */
    TrenitaliaSolutionDetailsComponent.prototype.updatePostActionButtons = function () {
        var e_2, _a;
        var result = [];
        if (!this.postActions) {
            this.postActionButtons = result;
            return;
        }
        var showCancelTimer = false;
        try {
            for (var supportedPostSaleActions_1 = tslib_1.__values(supportedPostSaleActions), supportedPostSaleActions_1_1 = supportedPostSaleActions_1.next(); !supportedPostSaleActions_1_1.done; supportedPostSaleActions_1_1 = supportedPostSaleActions_1.next()) {
                var postAction = supportedPostSaleActions_1_1.value;
                if (!this.postActions[postAction.code])
                    continue;
                // only global cancellation at moment
                if (postAction.code == 'cancellation_before_departure') {
                    showCancelTimer = _.get(this.postActions, 'cancellation_before_departure.enabled', false);
                    continue;
                }
                var action = Object.assign({}, postAction);
                action.postAction = this.postActions[postAction.code];
                action.messages = this.getMessage(_.get(this.postActions[postAction.code], 'messages'));
                result.push(action);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (supportedPostSaleActions_1_1 && !supportedPostSaleActions_1_1.done && (_a = supportedPostSaleActions_1.return)) _a.call(supportedPostSaleActions_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.postActionButtons = result;
        this.cancelTimeEnded = this.showCancelTimer != showCancelTimer;
        this.showCancelTimer = showCancelTimer;
    };
    TrenitaliaSolutionDetailsComponent.prototype.postSaleActionClick = function (postSaleActionCode, postSaleDetails) {
        if (!_.has(this.postActions, postSaleActionCode))
            return;
        if (postSaleActionCode == 'traveller_change') {
            this.trenitaliaPostSaleService.edit('edit-travellers', this.travelSolution, this.postActions[postSaleActionCode]);
        }
        else if (postSaleActionCode == 'booking_change_before_departure') {
            this.trenitaliaPostSaleService.edit('edit-date-time', this.travelSolution, this.postActions[postSaleActionCode]);
        }
        else if (postSaleActionCode == 'travel_change_before_departure') {
            this.trenitaliaPostSaleService.edit('travel-change', this.travelSolution, this.postActions[postSaleActionCode]);
        }
        else if (postSaleActionCode == 'cancellation_before_departure') {
            this.trenitaliaPostSaleService.onDelete();
        }
        else if (postSaleActionCode == 'change_class') {
            this.trenitaliaPostSaleService.changeClass(this.postActions.change_class.postSaleDetails, this.viewContainerRef);
        }
        else if (postSaleActionCode == 'refund_before_departure') {
            this.trenitaliaPostSaleService.onRefund(postSaleDetails || _.get(this.postActions, 'refund_before_departure.postSaleDetails'));
        }
        else if (postSaleActionCode == 'carnet_refund') {
            this.trenitaliaPostSaleService.onRefund(postSaleDetails || _.get(this.postActions, 'carnet_refund.postSaleDetails'));
        }
        else {
            console.warn('action not supported', postSaleActionCode);
        }
    };
    TrenitaliaSolutionDetailsComponent.prototype.onDelete = function () {
        this.trenitaliaPostSaleService.onDelete();
    };
    TrenitaliaSolutionDetailsComponent.prototype.endTimer = function (e) {
        this.showCancelTimer = false;
        this.cancelTimeEnded = true;
    };
    TrenitaliaSolutionDetailsComponent.prototype.getMessage = function (messages) {
        var msg = '';
        if (messages !== undefined) {
            messages.forEach(function (s) {
                msg += s + '\n';
            });
        }
        return msg;
    };
    return TrenitaliaSolutionDetailsComponent;
}());
export { TrenitaliaSolutionDetailsComponent };
