import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
var TripPackageStep3Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageStep3Component, _super);
    function TripPackageStep3Component(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS) {
        var _this = 
        // parent constructor
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        /**
         * carousel block configs
         */
        _this.carouselBlockConfigs = {
            datasetCode: 'trip_package_language_contents_carousel_images',
            parentDatasetCode: 'trip_package_language_contents',
            parentForeignKey: 'trip_package_language_content_id'
        };
        return _this;
    }
    TripPackageStep3Component.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: 'trip_package_language_contents',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.datasetRS,
                parentForeignKey: 'trip_package_id',
                parentValueKey: 'trip_packages.recordId'
            });
            _this.datasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    return TripPackageStep3Component;
}(BaseStepViewComponent));
export { TripPackageStep3Component };
