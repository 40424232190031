import { Component, Input, OnDestroy } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';

import {Subject} from 'rxjs';
import {FieldHelper} from 'app/helpers';

import {IFieldDefinition} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

@Component({
	selector   : 'data-container-viewer',
	templateUrl: './data-container-viewer.component.html',
	styleUrls  : ['./data-container-viewer.component.scss'],
	animations : fuseAnimations
})
export class DataContainerViewerComponent implements OnDestroy{

	@Input() public fields: any[]; // (IFieldContainer|IFieldDefinition)
	@Input() dataContainerService: IDataContainerService;
	@Input() record: any;

	private _unsubscribeAll: Subject<any>;

	constructor(
	){
		this._unsubscribeAll = new Subject();
	}

	computeValue(fieldDefinition: IFieldDefinition): string{
		return FieldHelper.getDisplayFieldValue(fieldDefinition, this.record, undefined, this.dataContainerService);
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
