<section *ngIf='stepService.ready.value; else loader;' class="container">
	
	<fuse-sidebar class="sidebar side-navigation" name="page-sidebar" position="left" lockedOpen="gt-md">
		<nav>
			<!-- disattivato momentaneamente su richiesta di Francesco
				<ng-container *ngIf='record && record.active_status && record.publish_on_web'>
					<a class="page-product-btn" mat-flat-button [href]='appService.getBaseServerAddressRoot() + "/preview/d/"+record.domain.id+"/products/"+record.code+"/"+record.id' target="_blank">Pagina prodotto</a>
				</ng-container>
			-->
	
			<app-custom-stepper [selectedIndex]='stepperIndex' #customStepper (selectionChange)='stepChange($event)'>
				<ng-container *ngFor='let computedStep of computedSteps;trackBy: trackStepBy;'>
					<div customCdkStep
						[completed]='record !== null' 
						[computedStep]='computedStep'
					></div>
				</ng-container>
			</app-custom-stepper>
		</nav>
	</fuse-sidebar>

	<div class="step-container step-location">
		<div class="header accent p-24" fxLayout="row" fxLayoutAlign="start center" fxHide.gt-md>
			<button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
					(click)="toggleSidebar('page-sidebar')">
				<mat-icon>menu</mat-icon>
			</button>
			<div>
				<h2 class="m-0">{{stepViewConfig.title | translate}}</h2>
				<span *ngIf='record && subtitle' class='mt-0 h4 secondary-text'>{{subtitle | translate}}</span>
			</div>
		</div>
		<div class="content p-24">
			<mat-tab-group class='tab-hidden-header' [selectedIndex]='stepIndex'>
				<ng-container *ngFor='let stepConfig of steps;let i = index;'>
					<mat-tab>
						<ng-template matTabContent>
							<h2 class="step-title">{{stepConfig.title}}</h2>
							<ng-container [ngSwitch]='stepConfig?.component'>
								<!-- TRIP PACKAGES SMART INTERFACE STEPS -->
								<product-name-step *ngSwitchCase='"product-name-step"'
									[stepConfig]='stepConfig'
									[stepper]='customStepper'
									(nextStep)='customStepper.next()' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></product-name-step>
								<resell-product-name-step *ngSwitchCase='"resell-product-name-step"'
									[stepConfig]='stepConfig'
									[stepper]='customStepper'
									(nextStep)='customStepper.next()' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></resell-product-name-step>
								<trip-destination-step *ngSwitchCase='"trip-destination-step"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></trip-destination-step>
								<resell-trip-destination-step *ngSwitchCase='"resell-trip-destination-step"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></resell-trip-destination-step>
								<smart-interface-i18n-form-step 
									*ngSwitchCase='"smart-interface-i18n-form-step"'
									[stepConfig]='stepConfig' 
									[fileterFields]='stepConfig.options.fieldsMap' 
									(nextStep)='customStepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></smart-interface-i18n-form-step>
								<resell-smart-interface-i18n-form-step
									*ngSwitchCase='"resell-smart-interface-i18n-form-step"'
									[stepConfig]='stepConfig' 
									[fileterFields]='stepConfig.options.fieldsMap' 
									(nextStep)='customStepper.next()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></resell-smart-interface-i18n-form-step>
								<common-i18n-data-viewer-step
									*ngSwitchCase='"common-i18n-data-viewer-step"' 
									[stepConfig]='stepConfig' 
									[formConfig]='stepConfig.options.formConfig' 
									(nextStep)='stepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></common-i18n-data-viewer-step>
								<trip-package-trip-facilities
									*ngSwitchCase='"trip-package-trip-facilities"'
									[parentDatasetRS]='datasetRS'
									[parentDatasetACS]='datasetACS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
									(nextStep)='customStepper.next()' 
								></trip-package-trip-facilities>
								<resell-trip-package-trip-facilities
									*ngSwitchCase='"resell-trip-package-trip-facilities"'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
									(nextStep)='customStepper.next()' 
								></resell-trip-package-trip-facilities>
								<trip-package-trip-categories
									*ngSwitchCase='"trip-package-trip-categories"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[stepper]='customStepper'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></trip-package-trip-categories>
								<resell-trip-package-trip-categories
									*ngSwitchCase='"resell-trip-package-trip-categories"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[stepper]='customStepper'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></resell-trip-package-trip-categories>
								<trip-package-smart-interface-media
									*ngSwitchCase='"trip-package-smart-interface-media"'
									[parentDatasetRS]='datasetRS'
									[parentDatasetACS]='datasetACS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='customStepper.next()'>
								</trip-package-smart-interface-media>
								<resell-trip-package-smart-interface-media
									*ngSwitchCase='"resell-trip-package-smart-interface-media"'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='customStepper.next()'>
								</resell-trip-package-smart-interface-media>
								<price-step
									*ngSwitchCase='"price-step"'
									[stepConfig]='stepConfig'
									[stepper]='customStepper'
									[stepIndex]='stepIndex'
									(nextStep)='customStepper.next()' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></price-step>
								<common-data-viewer-step
									*ngSwitchCase='"common-data-viewer-step"'
									[stepConfig]='stepConfig'
									[stepIndex]='i'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='stepper.next()'
								></common-data-viewer-step>
								<common-blocks-step 
									*ngSwitchCase='"common-blocks-step"' 
									[stepConfig]='stepConfig' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[stepIndex]='i' 
									(nextStep)='stepper.next()'
								></common-blocks-step>
								<trip-booking-options
									*ngSwitchCase='"trip-booking-options"'
									[stepConfig]='stepConfig' 
									(nextStep)='customStepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></trip-booking-options>
								<meeting_point_unified_list_items
									*ngSwitchCase='"meeting-point-unified-list-items"'
									[stepConfig]='stepConfig' 
									(nextStep)='customStepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></meeting_point_unified_list_items>
								<availability-step
									*ngSwitchCase='"availability-step"'
									[stepConfig]='stepConfig'
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[stepper]='customStepper'
									[stepIndex]='stepIndex'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></availability-step>
								<integrations-step
									*ngSwitchCase='"trip-package-integrations-step-smart-interface"' 
									[parentDatasetACS]='datasetACS'
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[hasStepState]='true' 
									[datasetCode]='"trip_packages"'
									[integrationsDatasetCode]='"trip_package_integrations"'
								></integrations-step>
								<actual-trips-smart-interface
									*ngSwitchCase='"actual-trips-smart-interface"' 
									(nextStep)='stepper.next()'
									(prevStep)='stepper.previous()'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></actual-trips-smart-interface>
								<!-- PRODUCTS SMART INTERFACE STEPS -->
								<product-base-info-step *ngSwitchCase='"product-base-info-step"'
									[stepConfig]='stepConfig'
									[stepper]='customStepper'
									(nextStep)='customStepper.next()' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></product-base-info-step>
								<product-trip-categories *ngSwitchCase='"product-trip-categories"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[stepper]='customStepper'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></product-trip-categories>
								<product-bundle *ngSwitchCase='"product-bundle"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[stepper]='customStepper'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></product-bundle>
								<product-trip-destinations *ngSwitchCase='"product-trip-destinations"'
									[stepConfig]='stepConfig'
									[stepIndex]='stepIndex'
									[stepper]='customStepper'
									[hasNext]='i + 1 < steps.length'
									(nextStep)='customStepper.next()' 
									[hasPrevious]='i > 0'
									[datasetConfig]='stepConfig.options.datasetConfig'
								></product-trip-destinations>
								<product-i18n-form-step 
									*ngSwitchCase='"product-i18n-form-step"'
									[stepConfig]='stepConfig' 
									[fileterFields]='stepConfig.options.fieldsMap' 
									(nextStep)='customStepper.next()' 
									[parentDatasetACS]='datasetACS'
									[parentDatasetRS]='datasetRS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
								></product-i18n-form-step>
								<product-media
									*ngSwitchCase='"product-media"'
									[parentDatasetRS]='datasetRS'
									[parentDatasetACS]='datasetACS'
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									(nextStep)='customStepper.next()'>
								</product-media>
								<product-advanced-blocks-step 
									*ngSwitchCase='"product-advanced-blocks-step"' 
									[stepConfig]='stepConfig' 
									[hasNext]='i + 1 < steps.length'
									[hasPrevious]='i > 0'
									[stepIndex]='i' 
									[stepper]='customStepper'
									(nextStep)='stepper.next()'
								></product-advanced-blocks-step>
								<div *ngSwitchDefault>
									<p>Componente non configurato {{stepConfig?.component}}</p>
								</div>
							</ng-container>
						</ng-template>
					</mat-tab>
				</ng-container>
			</mat-tab-group>
		</div>
	</div>
</section>

<ng-template #loader>
	<div class="white-overlay">
		<mat-spinner></mat-spinner>
	</div>
</ng-template>