import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import participantFormConfig from './forms/participant.from';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_booking_participant_extras/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripBookingPackageId = dataContainerService.getValueFromKeyPath('trip_booking_packages.recordId');
	const tripBookingResourceId = dataContainerService.getValueFromKeyPath('trip_booking_resources.recordId');
	const tripBookingId = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
	const tripBookingParticipantId = (parentData && parentData.participant_id) || dataContainerService.getValueFromKeyPath('trip_booking_participant_extras.recordId');

	if(tripBookingPackageId) params['trip_booking_package_id'] = tripBookingPackageId;
	if(tripBookingResourceId) params['trip_booking_resource_id'] = tripBookingResourceId;
	if(tripBookingId) params['trip_booking_id'] = tripBookingId;
	if(tripBookingParticipantId) params['trip_booking_participant_id'] = tripBookingParticipantId;

	return params;
}

export const TripBookingParticipantExtraConfig: IDatasetConfig = {
	name: 'trip_booking_participant_extras',
	title: 'Partecipanti',
	singleTitle: 'Partecipante',
	ajaxDatasetCode: 'trip_booking_participant_extras',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		participantFormConfig
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: participantFormConfig,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: participantFormConfig
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: participantFormConfig
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CUSTOM,
				showSearchBar: true,
				defaultSortBy: 'participants.description',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nominativo',
						key: 'participant_description'
					},
					{
						title: 'Età',
						key: 'age_range_code'
					},
					{
						title: 'Escluso',
						key: 'exclude'
					}
				],
				actions: ['edit']
			}
		}
	}
};
