import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from './../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
var CommonI18nDataViewerStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonI18nDataViewerStepComponent, _super);
    function CommonI18nDataViewerStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        _this.isLastStep = true;
        return _this;
    }
    CommonI18nDataViewerStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetMCS.init({
                datasetACS: _this.datasetACS,
                datasetRS: _this.datasetRS,
                parentForeignKey: _this.stepConfig.options.parentForeignKey,
                parentValueKey: _this.stepConfig.options.parentValueKey
            });
            _this.datasetMCS.fetchRecord();
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (record)
                _this.datasetMCS.activeLanguageCodes = record.active_language_codes;
        });
    };
    return CommonI18nDataViewerStepComponent;
}(BaseStepViewComponent));
export { CommonI18nDataViewerStepComponent };
