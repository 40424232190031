import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'trip_resource_id',
	name: 'Risorsa',
	inputType: InputTypes.DATASET_SEARCH,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_resources',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
	}
};
