import { BillingFormComponent } from './billing-form/billing-form.component';
import { BillingFormIndividualComponent } from './billing-form-individual/billing-form-individual.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassengerFormComponent } from './passenger-form/passenger-form.component';
import { SearchTravellersComponent } from './search-travellers.component';
import { TrenitaliaService } from '../trenitalia.service';
import { FormViewerService } from '../../../../../services/form-viewer.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TrenitaliaDialogModule } from '../trenitalia-dialog.module';
import { FormInputViewerModule } from '../../../../components/form-input-viewer/form-input-viewer.module';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownComponent } from '.././countdown/countdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TrenitaliaRecapModule} from '../trenitalia-recap/trenitalia-recap.module';

@NgModule({
	declarations: [
		PassengerFormComponent,
		SearchTravellersComponent,
		CountdownComponent,
		BillingFormComponent,
		BillingFormIndividualComponent
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		FormInputViewerModule,
		FlexModule,
		MatCardModule,
		ReactiveFormsModule,
		MatSlideToggleModule,
		FormsModule,
		TranslateModule,
		MatProgressBarModule,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		MatListModule,
		MatMenuModule,
		MatTooltipModule,
		MatSelectModule,
		MatProgressSpinnerModule,
        TrenitaliaRecapModule
	],
	entryComponents: [SearchTravellersComponent],
	exports: [
		SearchTravellersComponent,
		PassengerFormComponent,
		CountdownComponent,
		BillingFormComponent
	]
})
export class SearchTravellersModule {}
