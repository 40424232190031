import { InputTypes, ValueTypes } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';


export default {
	key: 'user_id',
	name: 'Utente',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'users',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.access_id + ') ' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean {
		return !dataContainerService.appService.me.is_staff_user;
	}
};
