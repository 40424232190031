import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('store_code', {
	key: 'store_code',
	name: 'Cloud Service',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('updated_at', {
	key: 'updated_at',
	name: 'Aggiornato il',
	valueType: ValueTypes.DATETIME
});

fieldsMap.set('file_type', {
	key: 'file_type',
	name: 'Tipo File',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: [
		{
			label: 'JPEG',
			value: 'image/jpeg'
		},
		{
			label: 'PNG',
			value: 'image/png'
		},
		{
			label: 'PDF',
			value: 'application/pdf'
		},
		{
			label: 'CSV',
			value: 'text/csv'
		},
		{
			label: 'AUDIO',
			value: 'audio/mpeg'
		},
		{
			label: 'MP4',
			value: 'video/mp4'
		}
	]
});

fieldsMap.set('image', {
	key: 'image',
	name: 'Image',
	fieldView: FieldViews.HTML,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		return '';
	}
});


export {fieldsMap};
