<h1 mat-dialog-title *ngIf='datasetACS.datasetConfig'>{{'Ripristina' | translate}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading || datasetACS.loading.value'></mat-progress-bar>

	<p>{{message}}</p>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button  color='' (click)="onNoClick()">{{'Annulla' | translate}}</button>
	<button class='ml-4' mat-flat-button [disabled]='loading' color='accent' (click)="onRestore()">{{'Si' | translate}}</button>
</mat-dialog-actions>
