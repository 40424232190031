import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('generate_pratiche_from_date', {
	key: 'generate_pratiche_from_date',
	name: 'Inizio periodo pratiche',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	formValidators: [ Validators.required ]
});

fieldsMap.set('generate_pratiche_record', {
	key: 'generate_pratiche_record',
	name: 'Abilita generazione pratiche',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('enable_export_zucchetti', {
	key: 'enable_export_zucchetti',
	name: 'Abilita export pratiche',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.BOOLEAN,
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitato',
			value: false
		},
		{
			label: 'Abilitato',
			value: true
		}
	]
});

fieldsMap.set('email_send_from', {
	key: 'email_send_from',
	name: 'Email send from',
	inputType: InputTypes.EMAIL,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'app';
	}
});

fieldsMap.set('email_send_from_name', {
	key: 'email_send_from_name',
	name: 'Email send from name',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'app';
	}
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService) {
		return 'app';
	}
});

export {fieldsMap as appFieldsMap};
