<ng-template #cellTemplate let-column='column' let-element='element' let-index='index' let-value='value' let-tooltip='tooltip'>
	<ng-container [ngSwitch]='column.fieldView || (column.fieldDefinition && column.fieldDefinition.fieldView)'>
		<ng-container *ngSwitchCase='fieldViewType.CUSTOM_ICON'>
			<ng-container *ngIf='tooltip'>
				<i [matTooltip]='tooltip' [class]='value'></i>
			</ng-container>
			<ng-container *ngIf='!tooltip'>
				<i [class]='value'></i>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.HTML'>
			<ng-container *ngIf='tooltip'>
				<span [matTooltip]='tooltip' [innerHtml]='value | safeHtml' (click)='column.onClick && column.onClick(element, datasetACS, datasetNavigatorService);$event.preventDefault();$event.stopPropagation()'></span>
			</ng-container>
			<ng-container *ngIf='!tooltip'>
				<span [innerHtml]='value | safeHtml' (click)='column.onClick && column.onClick(element, datasetACS, datasetNavigatorService);$event.preventDefault();$event.stopPropagation()'></span>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.MIXED_COLUMN'>
			<ng-container *ngIf='tableValues[element.id] && tableValues[element.id][column.key + "-cell-configs"]'>
				<ng-container *ngFor="let cellConfig of tableValues[element.id][column.key + '-cell-configs']; index as cellIndex;" >
					<ng-container
						[ngTemplateOutlet]='cellTemplate'
						[ngTemplateOutletContext]='{column:cellConfig, element:element, index:i, value: tableValues[element.id] && tableValues[element.id][column.key], tooltip: tableValues[element.id] && tableValues[element.id][column.key + "-mat-tooltip"]}'
					></ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.BUTTON'>
			<ng-container *ngIf='column.actionButton'>
				<ng-container
					[ngTemplateOutlet]='actionButtonTemplate'
					[ngTemplateOutletContext]='{action:column.actionButton, element:element}'
				></ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.IMAGE'>
			<img *ngIf='value && !column.fieldDefinition.resize' class="package-img" [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + value'/>
			<img *ngIf='value && column.fieldDefinition.resize' class="package-img" [src]='appService.getBaseServerAddressRoot() + "/document_files/resize/" + column.fieldDefinition.resize.width + "x" + column.fieldDefinition.resize.height + "/" + value'/>
			<span *ngIf='!value'>{{'Nessuna immagine' | translate}}</span>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.BG_IMAGE'>
			<div *ngIf='value' class="package-bg-img" [style.background-image]='"url(" + appService.getBaseServerAddressRoot() + "/document_files/download/" + value + ")"'></div>
			<span *ngIf='!value'>{{'Nessuna immagine' | translate}}</span>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.DIALOG_TEXT'>
			<button *ngIf='value && column.fieldDefinition.prefixIcon' mat-icon-button (click)='openTextDialog(value, column.fieldDefinition.name)'>
				<mat-icon *ngIf='column.fieldDefinition.prefixIcon'>{{column.fieldDefinition.prefixIcon}}</mat-icon>
			</button>
			<button *ngIf='value && !column.fieldDefinition.prefixIcon' mat-flat-button (click)='openTextDialog(value, column.fieldDefinition.name)'>
				<span>{{column.fieldDefinition.name | translate}}</span>
			</button>
		</ng-container>
		<ng-container *ngSwitchCase='fieldViewType.MENU'>
			<button *ngIf='column.menu && column.menu.items && column.menu.icon' mat-icon-button [matMenuTriggerFor]='menu'>
				<mat-icon>{{column.menu.icon}}</mat-icon>
			</button>
			<button *ngIf='column.menu && column.menu.items && !column.menu.icon && column.menu.text' mat-stroked-button [matMenuTriggerFor]='menu'>
				<span>{{column.menu.text | translate}}</span>
			</button>
			<mat-menu #menu='matMenu'>
				<ng-container *ngFor='let item of column.menu.items'>
					<ng-container *ngIf='!item.showIf || item.showIf(element, datasetACS)'>
						<button *ngIf='!item.makeLink' mat-menu-item (click)='item.onClick && item.onClick(element, datasetACS, datasetNavigatorService)'>
							<mat-icon *ngIf='item.icon'>{{item.icon}}</mat-icon>
							<span *ngIf='item.text'>{{item.text | translate}}</span>
						</button>
						<a *ngIf='item.makeLink' mat-menu-item [href]='item.makeLink(element)' target="_blank">
							<mat-icon *ngIf='item.icon'>{{item.icon}}</mat-icon>
							<span *ngIf='item.text'>{{item.text | translate}}</span>
						</a>
					</ng-container>
				</ng-container>
			</mat-menu>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<ng-container *ngIf='tooltip'>
				<span [matTooltip]='tooltip'>{{value}}</span>
			</ng-container>
			<ng-container *ngIf='!tooltip'>
				<span>{{value}}</span>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #actionButtonTemplate let-action='action' let-element='element'>
	<ng-container *ngIf='!action.routerLink && action.onClick'>
		<button *ngIf='!action.buttonText && action.buttonIcon' (click)='action.onClick($event, element, datasetACS, datasetNavigatorService)' [matTooltip]='action.tooltip | translate' mat-icon-button [color]='action.buttonColor'>
			<mat-icon>{{action.buttonIcon}}</mat-icon>
		</button>
		<button *ngIf='action.buttonText && !action.buttonIcon' (click)='action.onClick($event, element, datasetACS, datasetNavigatorService)' [matTooltip]='action.tooltip | translate' mat-flat-button [color]='action.buttonColor'>
			{{action.buttonText | translate}}
		</button>
		<button *ngIf='action.buttonText && action.buttonIcon' (click)='action.onClick($event, element, datasetACS, datasetNavigatorService)' [matTooltip]='action.tooltip | translate' mat-flat-button [color]='action.buttonColor'>
			<mat-icon>{{action.buttonIcon}}</mat-icon>
			<span>{{action.buttonText | translate}}</span>
		</button>
	</ng-container>
	<ng-container *ngIf='action.routerLink'>
		<a *ngIf='!action.buttonText && action.buttonIcon' [routerLink]='action.routerLink' [queryParams]='action.queryParams' [matTooltip]='action.tooltip | translate' mat-icon-button [color]='action.buttonColor'>
			<mat-icon>{{action.buttonIcon}}</mat-icon>
		</a>
		<a *ngIf='action.buttonText && !action.buttonIcon' [routerLink]='action.routerLink' [queryParams]='action.queryParams' [matTooltip]='action.tooltip | translate' mat-flat-button [color]='action.buttonColor'>
			{{action.buttonText | translate}}
		</a>
	</ng-container>
	<ng-container *ngIf='!action.routerLink && !action.onClick'>
		<button *ngIf='!action.buttonText && action.buttonIcon' [matTooltip]='action.tooltip | translate' mat-icon-button [color]='action.buttonColor'>
			<mat-icon>{{action.buttonIcon}}</mat-icon>
		</button>
		<button *ngIf='action.buttonText && !action.buttonIcon' [matTooltip]='action.tooltip | translate' mat-flat-button [color]='action.buttonColor'>
			{{action.buttonText | translate}}
		</button>
	</ng-container>
</ng-template>

<mat-card [class.mat-elevation-z0]='!elevate' class='no-card-box' [ngClass]='tableStyle || viewConfig?.tableStyle'>
	<mat-card-content>
		<ng-container *ngIf='showHeaderToolbar'>
			<dataset-filter
				[loading]='datasetACS.loading.value'
				[actionButtons]='globalActionButtons'
				[showSearchText]='showSearchBar && viewConfig.showSearchBar'
				[filtersData]='filtersData'
				(filterChange)='filterChange.emit($event)'
				[formConfig]="filterForm"
				[dataContainerService]='datasetACS'
				[filterStyle]='filterStyle || viewConfig?.filterStyle'
			></dataset-filter>
		</ng-container>
		<mat-progress-bar *ngIf='!showHeaderToolbar && datasetACS.loading.value' mode='indeterminate'></mat-progress-bar>

		<div class="custom-table-responsive">
			<table *ngIf='!datasetACS.loading.value && paginationResponse' mat-table [dataSource]="paginationResponse.items" [ngClass]='tableStyle || viewConfig?.tableStyle'>

				<!-- Generic column definition -->
				<ng-container *ngFor="let column of viewConfig.columns;" [matColumnDef]="column.key">
					<th mat-header-cell *matHeaderCellDef [width]='column.width||""'> {{column.title | translate | titlecase}} </th>
					<td [ngStyle]='column.style' [attr.data-label]='column.title | translate' mat-cell *matCellDef="let element; let i = index;">
						<ng-container *ngIf='!selectable && (column.onClick || column.routerLink) && (column.actionCode === "noaction" || (tableValues[element.id] && tableValues[element.id].allowed_actions[column.actionCode || "view_detail"])); else defaultDisplayColumn'>
							<a
								*ngIf='column.onClick'
								style='cursor: pointer;'
								(click)='column.onClick(element, datasetACS, datasetNavigatorService)'>
								<ng-container
									[ngTemplateOutlet]='cellTemplate'
									[ngTemplateOutletContext]='{column:column, element:element, index:i, value: tableValues[element.id] && tableValues[element.id][column.key], tooltip: tableValues[element.id] && tableValues[element.id][column.key + "-mat-tooltip"]}'
								></ng-container>
							</a>
							<a *ngIf='column.routerLink'
								[routerLink]="column.routerLink(element)">
								<ng-container
									[ngTemplateOutlet]='cellTemplate'
									[ngTemplateOutletContext]='{column:column, element:element, index:i, value: tableValues[element.id] && tableValues[element.id][column.key], tooltip: tableValues[element.id] && tableValues[element.id][column.key + "-mat-tooltip"]}'
								></ng-container>
							</a>
						</ng-container>
						<ng-template #defaultDisplayColumn>
							<ng-container
								[ngTemplateOutlet]='cellTemplate'
								[ngTemplateOutletContext]='{column:column, element:element, index:i, value: tableValues[element.id] && tableValues[element.id][column.key], tooltip: tableValues[element.id] && tableValues[element.id][column.key + "-mat-tooltip"]}'
							></ng-container>
						</ng-template>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef> {{selectable?"Seleziona":"Azioni" | translate}} </th>
					<td class="actions-column" mat-cell *matCellDef="let element; let i = index;">
						<div *ngIf='!selectable'>
							<ng-container *ngIf='tableValues[element.id]'>
								<ng-container *ngFor='let action of tableValues[element.id]["_column_actions"]'>
									<ng-container
										[ngTemplateOutlet]='actionButtonTemplate'
										[ngTemplateOutletContext]='{action:action, element:element, index:i}'
									></ng-container>
								</ng-container>
							</ng-container>
						</div>
						<div *ngIf='selectable'>
							<!-- if dataset model return selectable property -->
							<ng-container *ngIf="element && element.hasOwnProperty('selectable')">
								<ng-container *ngIf="element.selectable">
									<button mat-flat-button color='accent' (click)='select(element)'>
										<span *ngIf='selectionBtnIcon' class="material-icons-outlined btn-icon">{{ selectionBtnIcon }}</span>
										<span *ngIf='selectionBtnTxt' class="btn-txt" [innerHtml]='selectionBtnTxt | translate'></span>
									</button>
								</ng-container>
								<ng-container *ngIf="!element.selectable">
									<button [disabled]='!element.selectable' mat-flat-button color='primary'>
										<span class="btn-txt">{{'Non selezionabile' | translate}}</span>
									</button>
								</ng-container>
							</ng-container>
							<!-- if selectable enabled from dataset input property -->
							<ng-container *ngIf="element && !element.hasOwnProperty('selectable')">
								<button mat-flat-button color='accent' (click)='select(element)'>
									<span *ngIf='selectionBtnIcon' class="material-icons-outlined btn-icon">{{ selectionBtnIcon }}</span>
									<span *ngIf='selectionBtnTxt' class="btn-txt" [innerHtml]='selectionBtnTxt | translate'></span>
								</button>
							</ng-container>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="massselect">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="$event ? masterToggle() : null"
									[checked]="isAllMassiveSelected"
									[indeterminate]="massSelection.size>0 && !isAllMassiveSelected">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let element; let i = index;">
						<mat-checkbox (click)="$event.stopPropagation()"
									(change)="$event ? toggleRow(tableValues[element.id]) : null"
									[checked]="massSelection.has(tableValues[element.id].record.id)">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="mass-selection-description">
					<td mat-footer-cell *matFooterCellDef [colSpan]="displayedColumns.length - 1">
						<span class='ml-4'>{{massSelection.size}} {{'elementi selezionati' | translate}}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="mass-actions">
					<td mat-footer-cell *matFooterCellDef>
						<ng-container *ngFor='let action of massActionButtons'>
							<button mat-icon-button [matTooltip]='action.tooltip' [color]='action.buttonColor' (click)='clickMassAction(action)'>
								<mat-icon>{{action.buttonIcon}}</mat-icon>
							</button>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef='riepilogo'>
					<td mat-footer-cell *matFooterCellDef [colSpan]='displayedColumns.length'>
						<ng-container *ngFor='let metaReview of viewConfig.metaReviews'>
							<div class="inner_footer_review" [innerHTML]='viewConfig.getMetaReview(paginationResponse, metaReview)'></div>
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					[class.deleted-row]='tableValues[row.id] && tableValues[row.id].isDeleted'
					[class.is-new-row]='tableValues[row.id] && tableValues[row.id].isNewRow'
					[class.status-active-bar-mobile]="row.active_status && !row.publish_on_web"
					[class.status-active-published-bar-mobile]="row.active_status && row.publish_on_web"
					[class.status-disabled-bar-mobile]="!row.active_status && !row.publish_on_web || !row.active_status && row.publish_on_web"
				></tr>
				<tr mat-footer-row
					[hidden]='massSelection.size === 0'
					*matFooterRowDef="['mass-selection-description', 'mass-actions']; sticky: true; when: "
					class='grey-200'
				></tr>
				<!-- riepilogo totali -->
				<tr mat-footer-row
					[hidden]='paginationResponse && !viewConfig.metaReviews'
					*matFooterRowDef="['riepilogo']; sticky: true;"
					class='grey-200'
				></tr>
			</table>
		</div>
		<div *ngIf='!datasetACS.loading.value && paginationResponse && paginationResponse.items && paginationResponse.items.length == 0'>
			<p class='pl-16'>{{'Nessun elemento presente' | translate}}</p>
		</div>
	</mat-card-content>
	<mat-card-footer *ngIf='canCreate || hasGlobalButtons || showPaginationComponent'>
		<mat-toolbar>
			<span fxFlex></span>
			<mat-paginator
				*ngIf='showPaginationComponent'
				[pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
				showFirstLastButtons
				[length]="paginationResponse.meta.total"
				[pageSize]="paginationResponse.meta.perPage"
				(page)="onPaginatorChange($event)">
			</mat-paginator>
		</mat-toolbar>
	</mat-card-footer>
</mat-card>
