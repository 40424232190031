import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import filterForm from './forms/filter.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/plafond_drop_items/detail/:recordId',
};

export const PlafondDropItemConfig: IDatasetConfig = {
	name: 'plafond_drop_items',
	title: 'Plafond Drop Items',
	singleTitle: 'Plafond Drop Item',
	ajaxDatasetCode: 'plafond_drop_items',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_relations = 'tripBooking';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				//filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice Prenotazione',
						key: 'trip_booking.booking_code'
					},
					{
						title: 'Data',
						key: 'date'
					},
					{
						title: 'Importo',
						key: 'amount',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(record.amount);
						}
					},
					{
						title: 'Numero Partecipanti',
						key: 'participant_count'
					}
				],
				//actions: ['create', 'detail', 'edit', 'delete'],
			}
		}
	}
};
