import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialog } from '@angular/material/dialog';
import { DataFilterComponent } from '../data-filter.component';
import { PopoverService } from 'app/main/dialogs/popover/popover.service';
import { IFormDialogData, FormPopoverComponent } from 'app/main/dialogs/form-popover/popover.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';

@Component({
	selector   : 'style-1-filter-component',
	templateUrl: './data-filter.component.html',
	styleUrls  : ['./data-filter.component.scss'],
	/*host: {
		'(document:click)': 'onClick($event)',
	}*/
})
export class Style1FilterComponent extends DataFilterComponent{

	isFiltersOpen = false;

	constructor(
		public matDialog: MatDialog, 
		public datasetNavigatorService: DatasetNavigatorService,
		private _eref: ElementRef,
		public popoverService: PopoverService,
		protected viewContainerRef: ViewContainerRef
	){
		super(matDialog, datasetNavigatorService, viewContainerRef);
	}

	onClick(event) {
		if (!this._eref.nativeElement.contains(event.target)) this.isFiltersOpen = false;
	}

	toggleFilters() {
		this.isFiltersOpen = !this.isFiltersOpen;
	}

	showPopover(target: HTMLElement): void{
		this.popoverService.open<IFormDialogData>(FormPopoverComponent, target, {
			hasBackdrop: true,
			panelClass: 'custom-dialog-container',
			data: {
				title: 'Filtri ricerca',
				formData: this.filtersData,
				formConfig: this.formConfig,
				dataContainerService: this.dataContainerService,
				positiveText: 'Applica'
			}
		})
		.afterClosed().subscribe(result => {
			if(result){
				this.applyFilter(result);
			}
		});
	}

	showMobileFilters(): void{
		this.datasetNavigatorService.openDialog<IFormDialogData>(FormDialogComponent, {
			title: 'Filtri ricerca',
			formData: this.filtersData,
			formConfig: this.formConfig,
			dataContainerService: this.dataContainerService,
			positiveText: 'Applica'
		})
		.afterClosed().subscribe(result => {
			if(result){
				this.applyFilter(result);
			}
		});
	}
}