import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { VERSION } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatasetActionContainerService } from '../../services/dataset-action-container.service';
import { DatasetService } from '../../services/dataset.service';
import { IFormConfig } from 'app/interfaces';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IDataParticipantList } from 'app/interfaces/participant-list-email.interface';
import formConfig from './form.config';

@Component({
	selector: 'app-participant-list-email-dialog',
	templateUrl: './participant-list-email-dialog.component.html',
	styleUrls: ['./participant-list-email-dialog.component.scss']
})

export class ParticipantListEmailDialogComponent implements OnInit {

	loading: boolean = false;

	public formConfig = formConfig;
	public datasetACS: DatasetActionContainerService;
	@ViewChild('formViewer', { static: true }) formViewer: FormViewerComponent;

	constructor(
		@Inject(MAT_DIALOG_DATA) private injectedData: IDataParticipantList, 
		private dialogRef: MatDialogRef<ParticipantListEmailDialogComponent>,
		public datasetService: DatasetService ) {
	}

	onConfirmClick(): void {
		this.loading = true;
		this.formViewer.formGroup.disable();
		const formData = this.formViewer.getFormData();

		if (formData.email === '') return;
		
		const data = {
			email: formData.email, 
			postData: this.injectedData.postData,
			typeList: this.injectedData.typeList,
		};

		this.datasetService.post('/dataset/trip_booking_participants/command/send_participants_list', data)
		.subscribe({
			next: () => {
				this.dialogRef.close(true);
			},
			error: response => {
				if(response.error && response.error.errors){
					this.formViewer.setErrors(response.error.errors);
				}
				this.loading = false;
			}
		});
	}

	ngOnInit() {
		this.datasetACS = this.injectedData.DatACS;
	}

}
