import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AppService } from './app.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectDomainDialogComponent } from 'app/main/components/dataset/dialogs/select-domain-dialog/dialog.component';
import { DomainFilterService } from './domain-filter.service';

@Injectable({
	providedIn: 'root'
})
export class DomainInstanceService{
	
	private recordId: any;

	constructor(
		protected http: HttpClient, 
		protected appService: AppService,
		protected matDialog: MatDialog,
		protected domainFilterService: DomainFilterService
	) {}

	openDomainsDialog(): MatDialogRef<SelectDomainDialogComponent, any>{
		const dialogRef = this.matDialog.open(SelectDomainDialogComponent, {
			disableClose: true
		});

		return dialogRef;
	}

	setDomainId(domainId: any): void {
		this.recordId = domainId;
	}

	getRecordId(): string {
		if (this.domainFilterService.filterDomainId.value) {
			return this.domainFilterService.filterDomainId.value;
		}
		if (!this.recordId) throwError('Valid domain not returned');
		return this.recordId;
	}
}
