<div class='mt-8'>
	<div fxLayout='column' class='mb-4'>
		<span *ngIf='showTitle' class='h2'>{{'Immagini' | translate}}</span>
		<span class='h5 secondary-text' *ngIf='parentDatasetRS.record.value && parentDatasetRS.record.value.allowed_actions && parentDatasetRS.record.value.allowed_actions.includes("edit")'>{{'Carica più immagini per migliorare la visibilità' | translate}}</span>
	</div>
	<div fxLayout='row' fxLayout.sm='column' fxLayout.xs='column'>
		<div class='drop-file-box' *ngIf='parentDatasetRS.record.value && parentDatasetRS.record.value.allowed_actions && parentDatasetRS.record.value.allowed_actions.includes("edit")'
			[class.dragging]='dragging' 
			(dragleave)='onFileDragLeave($event)'
			(dragstart)='onFileDragStart($event)'
			(dragover)='onFileDragOver($event)'
			(drop)='onFileDrop($event)'>
			<ng-container *ngIf='uploading'>
				<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
				<p>{{'Caricamento in corso...' | translate}}</p>
			</ng-container>
			<ng-container *ngIf='!uploading && !inputFile'>
				<p><strong>{{'Trascina i file qui per caricarlo' | translate}}</strong></p>
				<p>{{'Sono supportati i file: .jpeg, .png' | translate}}</p>
				<span>{{'Formato' | translate}}: {{imageWidth}} x {{imageHeight}}</span>
				<div class='box-file-input'>
					<mat-icon>image</mat-icon>
					<span class='ml-4'>{{'Cerca nel computer' | translate}}</span>
					<input type='file' #fileInput (change)="onFileChange($event)"/>
				</div>
			</ng-container>
			<image-cropper
				*ngIf='inputFile && !uploading'
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
				[aspectRatio]="imageRatio"
				[resizeToWidth]="imageWidth"
				[resizeToHeight]="imageHeight"
				[cropperMinWidth]="imageWidth"
				[cropperMinHeight]="imageHeight"
				[onlyScaleDown]="true"
				[roundCropper]="false"
				[autoCrop]="true"
				format="png"
				outputType="file"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
			<button mat-flat-button color='accent' *ngIf='croppedFile && !uploading' (click)='uploadFile()'>Carica</button>
		</div>
		<div class="thumbs_result" fxFlex>
			<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
			<div cdkDropList 
				[cdkDropListData]="dataList" 
				(cdkDropListDropped)='onDropList($event)'
				fxLayout='row wrap'
				fxLayoutAlign='start center'
				class='carousel-images'>
				<div class='carousel-image-item' cdkDrag *ngFor='let document of dataList; trackBy: trackImageBy; let i =index;' >
					<div class='carousel-image-container'>
						<button mat-icon-button color='warn' 
							*ngIf='parentDatasetRS.record.value && parentDatasetRS.record.value.allowed_actions && parentDatasetRS.record.value.allowed_actions.includes("edit")'
							(click)='removeImage(document)' [matTooltip]='"Elimina immagine" | translate'>
							<mat-icon>delete</mat-icon>
						</button>
						<span class='carousel-image-index'>{{i + 1}}</span>
						<img [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + document.id'>
					</div>
					<img *cdkDragPreview [alt]="'Immagine ' + (i + 1)" width='120' [src]='appService.getBaseServerAddressRoot() + "/document_files/download/" + document.id'>
				</div>
			</div>
		</div>
	</div>
</div>