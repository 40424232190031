
<div class="style-1">
	<div class="row" fxLayoutAlign="space-between center" fxLayout.sm="row wrap" fxLayout.xs="column wrap" fxLayoutGap="30px">
		<form-input-viewer
			[dataContainerService]="datasetACS"
			[formInputDefinition]='filterFields.get("start_day")'
			[formGroup]='formViewerService.formGroup'
			[formControlKey]='formViewerService.controlKeyMap["start_day"]'
			[style.width]='"auto"'
		></form-input-viewer>
		<form-input-viewer
			[dataContainerService]="datasetACS"
			[formInputDefinition]='filterFields.get("trip_package_id")'
			[formGroup]='formViewerService.formGroup'
			[formControlKey]='formViewerService.controlKeyMap["trip_package_id"]'
			[style.width]='"auto"'
			(customEvent)="onTripPackageIdInputCustomEvent($event)"
		></form-input-viewer>
	</div>
</div>

<dataset-list
	#datasetListActualTrips
	*ngIf="showList"
	[emitAdd]='true'
	[elevate]='false'
	[showSearchBar]='false' 
	[showHeaderToolbar]='false'
	[showTitle]='false'
	[selectable]='true'
	datasetCode='actual_trips'
	[parentDatasetACS]='datasetACS'
	[parentDatasetRS]='datasetRS'
	[filters]='filters'
	[cacheDatasetListFilters]="false"
	[enableCreate]='false'
	[enableEdit]='false'
	[enableDelete]='false'
	[viewConfig]='actualTripsListViewConfig'
	[selectionBtnIcon]="'add'"
	[tableStyle]='"style-2 selectable thead-border-bottom"'
	(selected)='addTripBookingPackage($event)'
></dataset-list>