import * as tslib_1 from "tslib";
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { InvalidAllotmentsDialogComponent } from 'app/main/components/dataset/dialogs/invalid-allotments-dialog/dialog.component';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
var TripPackageReselledStep0Component = /** @class */ (function (_super) {
    tslib_1.__extends(TripPackageReselledStep0Component, _super);
    function TripPackageReselledStep0Component(appService, parentDatasetRS, parentDatasetACS, datasetRS, datasetACS, datasetNavigatorService, datasetService, matDialog) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.parentDatasetRS = parentDatasetRS;
        _this.parentDatasetACS = parentDatasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        return _this;
    }
    TripPackageReselledStep0Component.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: 'trip_packages',
            actionCode: 'edit',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            datasetRS: this.datasetRS,
            fetchData: false
        });
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetRS.record.next(_this.parentDatasetACS.getValueFromKeyPath('reselledData'));
        });
    };
    TripPackageReselledStep0Component.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        if (this.stepConfig.options && typeof (this.stepConfig.options.getCallParams) === 'function') {
            formData = this.stepConfig.options.getCallParams(this.datasetACS, this.datasetRS.record.value, formData);
        }
        this.datasetACS.loading.next(true);
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/update/' + this.datasetRS.recordId;
        var headers = this.datasetACS.getContextualDomainHeaders();
        this.datasetService.post(route, formData, { headers: headers })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.clearForm();
                }
                _this.datasetRS.recordId = response.id;
                _this.datasetRS.reload()
                    .subscribe(function (value) {
                    if (value) {
                        _this.parentDatasetACS.pageClosureContainer.putToSharedData({ reselledData: value });
                        setTimeout(function () {
                            _this.goNextStep();
                        }, 200);
                    }
                    ;
                });
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            }
        });
    };
    return TripPackageReselledStep0Component;
}(BaseStepViewComponent));
export { TripPackageReselledStep0Component };
