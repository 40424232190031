<div class="position-relative p-16 trip-booking-package-card-container">
	<trip-booking-package-header
		icon="shopping_bag"
		[title]="tripBookingPackage.product.description"
		[showInfo]="false"
		[canDelete]="canDelete"
        [canCancel]="canCancel"
		[showEdit]="canEdit"
		[showConfirm]="canSingleConfirm"
		(confirmClick)="tripBookingPackageService.confirmTripBookingPackage()"
		(deleteClick)='deleteTripBookingPackage()'
		(editClick)='editData()'
	>
		<ng-container ngProjectAs="[sub-title-2]">
			<span *ngIf="tripBookingPackage.tour_operator_booking_data?.status_error" class="red-fg" >{{tripBookingPackage.tour_operator_booking_data?.status_error}}</span>
		</ng-container>
	</trip-booking-package-header>

	<div class="trip_booking_package_info ml-8 flex-col flex-wrap flex-gap-44">
		<div *ngIf="tripBookingPackage.product.product_type == 'esim-manet'" class="flex-row flex-gap-16 items-center justify-start">
			<div *ngIf="tripBookingPackage.product.data.provider" class="trip_booking_package_info_attribute">
				<span class='ml-4 mr-4'>{{'Provider' | translate}}: {{tripBookingPackage.product.data.provider}}</span>
			</div>
			<div *ngIf="tripBookingPackage.product.data.need_activation_date" class="ml-8 trip_booking_package_info_attribute" >
				<div>{{'Data di attivazione' | translate }}: {{ tripBookingPackage?.target_info?.date | date }}</div>
			</div>

		</div>
        <div *ngIf="tripBookingPackage.product.product_type == 'esim-manet'" style="margin-top: 20px">
            <a class="link-phone" href="https://www.forexchange.it/prodotto/local-italy-15-days-2-gb-2" target="_blank">
                <span class="material-icons-outlined" style="vertical-align: middle">phone_iphone</span>
                {{'Verifica compatibilità smartphone'|translate}}</a>
        </div>
	</div>

	<trip-booking-package-related-buttons
		[showResources]="false"
	></trip-booking-package-related-buttons>
</div>