<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<div class='date-time-input-field' fxLayout='row' fxLayoutAlign='start center'>
		<mat-form-field [appearance]='inputService.inputConfig.appearence' [ngClass]='inputService?.formInputDefinition?.inputConfig?.customCssClass'>
			<mat-label>
				<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
				<span>{{inputService.computedTitle | translate}}</span>
			</mat-label>
			<input *ngIf='inputService.inputConfig.readOnly' readonly matInput 
			[formControlName]='inputService.formControlKey'
			[matDatepickerFilter]="dateFilterBinded" 
			[matDatepicker]="dp3" >
			<input *ngIf='!inputService.inputConfig.readOnly' 
			[required]='inputService.inputConfig.required' 
			matInput 
			[matDatepicker]="dp3" 
			[placeholder]="inputService.inputConfig.placeholder | translate" 
			(click)='dp3.open()'
			[formControlName]='inputService.formControlKey' readonly>
			<mat-datepicker-toggle *ngIf='!inputService.inputConfig.readOnly' matSuffix [for]="dp3">
				<mat-icon matDatepickerToggleIcon>event</mat-icon>
			</mat-datepicker-toggle>
			<mat-datepicker #dp3></mat-datepicker>
			<mat-error *ngIf="!inputService.control.valid && inputService.errorMessage">{{inputService.errorMessage | translate}}</mat-error>
		</mat-form-field>
		<div class='time-form-field' [ngClass]='inputService?.formInputDefinition?.inputConfig?.customCssClass'>
			<input [readonly]='inputService.inputConfig.readOnly' *ngIf='inputService.control.value' autocomplete="off" type='time' [value]='initialTimeValue' (change)='onTimeChanged($event)'>
		</div>
	</div>
</ng-container>