import { OnInit, OnDestroy, Inject } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';

export interface ITripBookingPackageRelatedDataDialogData{
	datasetCode: string;
	actionCode: string;
	tripBookingPackage: any;
}

export abstract class ATripBookingPackageRelatedComponent extends DestroyableComponent implements OnInit{

	public tripBookingPackage: any;

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public parentDatasetACS: DatasetActionContainerService,
		public parentDatasetRS: DatasetRecordService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedDataDialogData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedDataDialogData
	){
		super();
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.data.datasetCode,
			actionCode: this.data.actionCode,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			useBeforeCalls: false,
			fetchData: false
		});

		if(this.parentDatasetRS.getDatasetCode() == 'trip_booking_packages'){
			this.parentDatasetRS.record
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(val => {
				this.tripBookingPackage = val
			});

			this.tripBookingPackage = this.parentDatasetRS.record.value;
		}
	}
	
	onSelected(selectedOption: any): void{
		this.datasetRS.record.next(selectedOption);
	}

	onCloseClick(): void{
		this.matDialogRef.close();
	}

	get isResaleCopy(): boolean{
		return Boolean(this.data.tripBookingPackage.ref_id);
	}
}
