<div class="trip_booking_package_info flex-col flex-gap-16 mt-16 mb-16">
    <div class="flex-row flex-gap-4 items-center justify-start">
        <mat-icon class="text-color-light-blue">shopping_bag</mat-icon>
        <span class="font-size-14 font-weight-500 text-color-black" *ngIf="productType">{{productType}} - </span>
        <span class="font-size-14 font-weight-500 text-color-black" *ngIf="inputTripBookingPackage.product.description">{{inputTripBookingPackage.product.description}}</span>
    </div>
    <div class="trip_booking_package_info flex-col flex-wrap flex-gap-44">
        <div *ngIf="inputTripBookingPackage.product.product_type == 'esim-manet'" class="flex-row flex-gap-16 items-center justify-start">
            <div *ngIf="inputTripBookingPackage.product.data.provider" class="trip_booking_package_info_attribute">
                <span class='ml-4 mr-4'>{{'Provider' | translate}}: {{inputTripBookingPackage.product.data.provider}}</span>
            </div>
            <div *ngIf="inputTripBookingPackage.product.data.need_activation_date" class="ml-8 trip_booking_package_info_attribute" >
                <div>{{'Data di attivazione' | translate }}: {{ inputTripBookingPackage?.target_info?.date | date }}</div>
            </div>
        </div>
        <ng-container *ngIf="mix">
            <div *ngIf="inputTripBookingPackage.booking_status === 'canceled'">
                <span class="red-fg">{{'Cancellata' | translate}}</span>
            </div>
            <div *ngIf="inputTripBookingPackage.booking_status === 'confirmed'">
                <span class="green-fg">{{'Confermata' | translate}}</span>
            </div>
        </ng-container>
        <div class=" flex-row justify-between font-weight-700">
            <span class=" value price font-size-14 text-color-black">{{totalRowLordo | currency:'EUR' }}</span>
        </div>
    </div>
</div>
