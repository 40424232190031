import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { fieldsMap } from './fields';

const generalFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		fields: [
			fieldsMap.get('name')
		]
	},
	{
		fields: [
			fieldsMap.get('description')
		]
	}
];

export default {
	fields: generalFormFields 
};
