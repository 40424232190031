import { Validators } from '@angular/forms';
import { FieldViews, IFieldContainer, IFieldDefinition, IFormConfig, InputTypes, ValueTypes } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { channelDefaultCustomerFields } from '../../fields/channels/default-customer.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	channelDefaultCustomerFields.get('entity_customer.entity_customer_id'),
	channelDefaultCustomerFields.get('entity_customer.description'),
	{
		key: 'scenario',
		name: '',
		inputType: InputTypes.HIDDEN,
		valueType: ValueTypes.STRING,
		fieldView: FieldViews.HIDDEN,
		formValidators: [ Validators.required ],
		defaultValue(dataContainerService: IDataContainerService) {
			return 'channels.website';
		}
	}
];

const websiteForm: IFormConfig = {
	editTitle: 'Modifica Configurazioni Sito Web',
	fields: formFields 
};

export default websiteForm;
