import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	ListViewItemTypes,
	FieldViews,
} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('first_name', {
	key: 'first_name',
	name: 'Nome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('last_name', {
	key: 'last_name',
	name: 'Cognome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('email', {
	key: 'email',
	name: 'Email',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('phone', {
	key: 'phone',
	name: 'Contatto telefonico',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required],
});

fieldsMap.set('data_n', {
    key: 'data_n',
    name: 'Data di nascita',
    inputType: InputTypes.DATE,
    valueType: ValueTypes.DATE,
    formValidators: [Validators.required],
});

fieldsMap.set('cartafreccia', {
    key: 'cartafreccia',
    name: 'CartaFRECCIA',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
});

fieldsMap.set('request_invoice', {
    key: 'request_invoice',
    name: 'Richiedi fattura',
    inputConfig: {
        type: InputTypes.TOGGLE
    },
    defaultValue(){
        return false;
    },
    valueType: ValueTypes.BOOLEAN
});
export { fieldsMap };
