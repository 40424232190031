import { Component, NgZone, Self, SkipSelf } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { fieldsMap } from '../product-name-step/form.fields';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { ProductNameStepComponent } from '../product-name-step/step.component';

@Component({
	selector   : 'resell-product-name-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [FormViewerService, DatasetRecordService, DatasetActionContainerService]
})
export class ResellProductNameStepComponent extends ProductNameStepComponent {

	constructor(
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		protected formBuilder: FormBuilder,
		protected zone: NgZone,
		protected stepService: StepService,
		public formViewerService: FormViewerService){
		/** init parent constructor */
		super(appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService);

		this.datasetACS.init({
			datasetCode: 'trip_packages',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.datasetRS,
			fetchData: false
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetRS.record.next(this.parentDatasetACS.getValueFromKeyPath('reselledData'));
		});

		
	}
}
