import * as tslib_1 from "tslib";
import { OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService, DatasetEvents, getDatasetConfigAction } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DomainsConfig } from 'app/configs/datasets/domains';
import { VoucherSettingLangContentsConfig } from 'app/configs/datasets/voucher_setting_language_contents';
import { takeUntil } from 'rxjs/operators';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
var DomainUserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DomainUserComponent, _super);
    function DomainUserComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService, appService, domainFilterService, viewContainerRef) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.appService = appService;
        _this.domainFilterService = domainFilterService;
        _this.viewContainerRef = viewContainerRef;
        _this.userIsAdmin = false;
        _this.stepperIndex = 0;
        _this.domainConfigRawFormViewer = {
            fields: [
                {
                    name: '',
                    key: 'configs',
                    valueType: ValueTypes.PRETTY_JSON,
                    inputConfig: {
                        type: InputTypes.TEXTAREA
                    }
                }
            ]
        };
        return _this;
    }
    DomainUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.userIsAdmin = this.authService.isAdministrator();
        this.customImageOptions = { minWidth: 324, minHeight: 52, ratio: 324 / 52 };
        this.stepConfig = {
            title: 'Traduzioni Voucher',
            stepperTitle: 'Traduzioni Voucher',
            component: 'common-i18n-form-step',
            options: {
                formConfig: 'generalForm',
                datasetCode: 'voucher_setting_language_contents',
                parentForeignKey: 'domain_id',
                parentValueKey: 'domains.recordId',
            },
        };
        this.infoFormConfig = DomainsConfig.formConfigsMap['infoForm'];
        this.voucherLangFormConfig = VoucherSettingLangContentsConfig.formConfigsMap['generalForm'];
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (domain) {
            if (domain)
                _this.datasetACS.putContextData('contextual_domain_id', domain.id);
            else
                _this.datasetACS.removeContextData('contextual_domain_id');
            if (domain && _this.domainFilterService.filterDomainId.value && _this.domainFilterService.filterDomainId.value !== domain.id) {
                _this.domainFilterService.setFilterDomain(domain.id);
            }
        });
        this.stepperIndex = Number(this.route.snapshot.queryParams['step']);
    };
    DomainUserComponent.prototype.reload = function () {
        var _this = this;
        this.datasetRS.reload()
            .subscribe(function () { return _this.appService.setLogo(_this.datasetRS.record.value.logo_img); });
    };
    DomainUserComponent.prototype.saveRawConfig = function () {
        var _this = this;
        this.configFormViewer.formGroup.disable();
        var formData = this.configFormViewer.getFormData();
        this.datasetACS.loading.next(true);
        this.datasetService.post(this.datasetACS.getUpdateRoute(null, 'domain_configs', this.datasetRS.record.value.id_domain_configs), formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (nextResponse) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: DatasetEvents.EDITED, data: nextResponse });
                if (_this.configFormViewer) {
                    _this.configFormViewer.formGroup.enable();
                    _this.configFormViewer.clearForm();
                }
                _this.datasetRS.reload();
                var editConfig = getDatasetConfigAction(_this.datasetACS.datasetCode, 'edit');
                if (editConfig && typeof (editConfig.afterSave) === 'function') {
                    editConfig.afterSave(_this.datasetACS, nextResponse, _this.datasetRS.record.value, _this.viewContainerRef);
                }
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.configFormViewer) {
                    _this.configFormViewer.formGroup.enable();
                    _this.configFormViewer.setErrors(_this.formErrors);
                }
            }
        });
    };
    DomainUserComponent.prototype.onSave = function () {
        var _this = this;
        this.entityFormViewer.formGroup.disable();
        var formData = this.entityFormViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        this.datasetACS.loading.next(true);
        this.datasetService.post(this.datasetACS.getUpdateRoute(this.datasetRS.record.value), formData)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (nextResponse) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: DatasetEvents.EDITED, data: nextResponse });
                if (_this.entityFormViewer) {
                    _this.entityFormViewer.formGroup.enable();
                    _this.entityFormViewer.clearForm();
                }
                _this.datasetRS.reload();
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.entityFormViewer) {
                    _this.entityFormViewer.formGroup.enable();
                    _this.entityFormViewer.setErrors(_this.formErrors);
                }
            }
        });
    };
    Object.defineProperty(DomainUserComponent.prototype, "baseUrl", {
        get: function () {
            return window.location.origin;
        },
        enumerable: true,
        configurable: true
    });
    return DomainUserComponent;
}(BaseViewComponent));
export { DomainUserComponent };
