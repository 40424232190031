import { Component, OnInit, AfterViewInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { Subject } from 'rxjs';
import { IStepConfig } from 'app/interfaces';
import { CdkStepper } from '@angular/cdk/stepper';

export class BaseStepViewComponent implements OnInit, AfterViewInit, OnDestroy{
	
	protected _unsubscribeAll: Subject<any>;
	@Input() stepper: CdkStepper;
	@Input() stepIndex: number;
	@Output() nextStep = new EventEmitter<void>();
	@Output() prevStep = new EventEmitter<void>();
	@Input() stepConfig: IStepConfig;
	@Input() hasNext = false;
	@Input() hasPrevious = false;
	public canEdit = true;

	constructor(
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService
	){
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{}

	ngAfterViewInit(): void{
		this.scrollToTop();
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	public goNextStep(): void{
		this.nextStep.emit();
	}

	public goPrevStep(): void{
		this.prevStep.emit();
	}

	scrollToTop(): void{
		const container = document.querySelector('#container-3');
		container.scrollTop = 0;
	}
}
