import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil, finalize } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import FilterFields from './form.fields';
import { Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import * as moment from 'moment';
var PriceStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PriceStepComponent, _super);
    function PriceStepComponent(appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, stepService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this.formBuilder = formBuilder;
        _this.stepService = stepService;
        _this.now = moment(new Date()).format('YYYY-MM-DD 00:00:00');
        _this.priceServicesRemoved = [];
        _this.priceRulesRemoved = [];
        _this.priceServices = [];
        _this.priceServicesCollection = {};
        _this.fileterFields = FilterFields;
        // definire servizio??
        _this.mockServices = {
            FIXED1: {
                title: 'Prezzi gruppo',
                canBeRemoved: false,
                code: 'FIXED1',
                formArrayName: 'FIXED1',
                age: { field: 'age_adt', from: 0, to: 0 },
                defaultPriceRule: {
                    price: null,
                    option_id: null,
                    valid_from: _this.now,
                    valid_to: null
                },
            },
            ADT: {
                title: 'Prezzo Adulto',
                canBeRemoved: false,
                code: 'ADT',
                formArrayName: 'ADT',
                age: { field: 'age_adt', from: 18, to: 64 },
                defaultPriceRule: {
                    price: null,
                    option_id: null,
                    valid_from: _this.now,
                    valid_to: null
                },
            },
            CHD: {
                title: 'Prezzo Bambino',
                canBeRemoved: true,
                code: 'CHD',
                formArrayName: 'CHD',
                age: { field: 'age_chd', from: 2, to: 17 },
                defaultPriceRule: {
                    price: null,
                    option_id: null,
                    valid_from: _this.now,
                    valid_to: null
                },
            },
            INF: {
                title: 'Prezzo Neonato',
                canBeRemoved: true,
                code: 'INF',
                formArrayName: 'INF',
                age: { field: 'age_inf', from: 0, to: 1 },
                defaultPriceRule: {
                    price: null,
                    option_id: null,
                    valid_from: _this.now,
                    valid_to: null
                },
            },
            SNR: {
                title: 'Prezzo Senior',
                canBeRemoved: true,
                code: 'SNR',
                formArrayName: 'SNR',
                age: { field: 'age_snr', from: 65, to: 99 },
                defaultPriceRule: {
                    price: null,
                    option_id: null,
                    valid_from: _this.now,
                    valid_to: null
                },
            }
        };
        _this.buttons = {
            CHD: { title: 'CHILDREN' },
            INF: { title: 'INFANT' },
            SNR: { title: 'SENIOR' }
        };
        _this.clearFormArray = function (formArray) {
            while (formArray.length) {
                formArray.removeAt(formArray.length - 1);
            }
        };
        return _this;
    }
    PriceStepComponent.prototype.getBaseRoute = function () {
        return '/dataset/gyg_based_trip_services';
    };
    PriceStepComponent.prototype.getHttpHeaders = function () {
        return this.datasetACS.getContextualDomainHeaders();
    };
    PriceStepComponent.prototype.canShowAge = function () {
        return Object.keys(this.priceServicesCollection).length > 1;
    };
    PriceStepComponent.prototype.processResponse = function (response) {
        var _this = this;
        var keys = Object.keys(response);
        keys.forEach(function (key) {
            var obj = response[key];
            _this.buildForm(obj);
        });
    };
    PriceStepComponent.prototype.initFormGroup = function () {
        this.filterFormGroup = this.formBuilder.group({
            age_adt: new FormControl(),
            age_chd: new FormControl(),
            age_inf: new FormControl(),
            age_snr: new FormControl(),
            ADT: this.formBuilder.array([]),
            CHD: this.formBuilder.array([]),
            INF: this.formBuilder.array([]),
            SNR: this.formBuilder.array([]),
            FIXED1: this.formBuilder.array([])
        });
    };
    PriceStepComponent.prototype.setUpPriceTiers = function (group, priceRule) {
        if (!priceRule)
            priceRule = this.mockServices[group].defaultPriceRule;
        this.priceRules(group).push(this.formBuilder.group({
            price: [priceRule.price, Validators.required],
            option_id: priceRule.option_id,
            valid_from: [priceRule.valid_from, Validators.required],
            valid_to: priceRule.valid_to
        }));
    };
    PriceStepComponent.prototype.priceRules = function (group) {
        return this.filterFormGroup.get(group);
    };
    PriceStepComponent.prototype.ngOnInit = function () {
        this.initFormGroup();
        this.loadPricing((this.datasetRS.record.value.trip_service_rule_type == '0') ? 'ADT' : 'FIXED1');
    };
    PriceStepComponent.prototype.loadPricing = function (tripServiceRuleId) {
        var _this = this;
        var httpParams = { trip_package_id: this.datasetRS.recordId };
        this.datasetACS.loading.next(true);
        this.datasetService.get(this.getBaseRoute(), httpParams, this.getHttpHeaders())
            .pipe(takeUntil(this._unsubscribeAll), finalize(function () {
            if (Object.keys(_this.priceServicesCollection).length === 0)
                _this.buildForm(tripServiceRuleId);
        })).subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.processResponse(response);
            },
            error: function () {
                _this.datasetACS.loading.next(false);
            }
        });
    };
    PriceStepComponent.prototype.getPriceService = function (priceService) {
        var code;
        var priceServ;
        if (typeof priceService === 'object') {
            code = priceService.code;
            priceServ = priceService;
        }
        else {
            code = priceService;
            priceServ = this.mockServices[code];
        }
        return priceServ;
    };
    PriceStepComponent.prototype.buildForm = function (priceService) {
        var e_1, _a;
        var _this = this;
        var priceServ = this.getPriceService(priceService);
        if (!this.priceServicesCollection[priceServ.code]) {
            priceServ.age = {
                field: this.mockServices[priceServ.code].age.field,
                from: priceServ.age.from,
                to: priceServ.age.to
            };
            this.setAgeRange(priceServ);
            this.filterFormGroup.addControl(priceServ.age.field, new FormControl(priceServ.age.to));
            var ageFormField = this.filterFormGroup.get(priceServ.age.field);
            this.fixAgeField(this.filterFormGroup.get(priceServ.age.field).value, priceServ, true);
            ageFormField.valueChanges.subscribe(function (value) {
                _this.fixAgeField(value, priceServ);
            });
            if (!priceServ.price_rules) {
                this.setUpPriceTiers(priceServ.code, priceServ.defaultPriceRule);
            }
            else {
                try {
                    for (var _b = tslib_1.__values(priceServ.price_rules), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var priceRule = _c.value;
                        this.setUpPriceTiers(priceServ.code, priceRule);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            this.priceServicesCollection[priceServ.code] = priceServ;
            delete this.buttons[priceServ.code];
            this.sortOrder();
        }
    };
    PriceStepComponent.prototype.sortOrder = function () {
        this.priceServicesCollection = tslib_1.__assign({}, (this.priceServicesCollection['INF'] ? { 'INF': this.priceServicesCollection['INF'] } : null), (this.priceServicesCollection['CHD'] ? { 'CHD': this.priceServicesCollection['CHD'] } : null), (this.priceServicesCollection['ADT'] ? { 'ADT': this.priceServicesCollection['ADT'] } : null), (this.priceServicesCollection['SNR'] ? { 'SNR': this.priceServicesCollection['SNR'] } : null), (this.priceServicesCollection['FIXED1'] ? { 'FIXED1': this.priceServicesCollection['FIXED1'] } : null));
    };
    PriceStepComponent.prototype.formatLabel = function (value) {
        if (value >= 99)
            return '99+';
        return value.toString();
    };
    PriceStepComponent.prototype.fixAgeField = function (value, priceService, withSnr) {
        if (withSnr === void 0) { withSnr = false; }
        if (value === 99)
            return;
        if (priceService.code === 'CHD') {
            this.priceServicesCollection['ADT'].age.from = (value + 1);
            if (value >= this.filterFormGroup.get('age_adt').value) {
                this.filterFormGroup.get('age_adt').setValue(value + 1);
            }
        }
        if (priceService.code === 'INF' && this.priceServicesCollection['CHD']) {
            this.priceServicesCollection['CHD'].age.from = (value + 1);
            if (value >= this.filterFormGroup.get('age_chd').value) {
                this.filterFormGroup.get('age_chd').setValue(value + 1);
            }
        }
        else if (priceService.code === 'INF' && !this.priceServicesCollection['CHD']) {
            this.priceServicesCollection['ADT'].age.from = (value + 1);
            if (value >= this.filterFormGroup.get('age_adt').value) {
                this.filterFormGroup.get('age_adt').setValue(value + 1);
            }
        }
        if (priceService.code === 'ADT' && this.priceServicesCollection['SNR']) {
            this.priceServicesCollection['SNR'].age.from = (value + 1);
            if (value >= this.filterFormGroup.get('age_snr').value) {
                this.filterFormGroup.get('age_snr').setValue(value + 1);
            }
        }
    };
    PriceStepComponent.prototype.setAgeRange = function (priceServ, withoutSetValue) {
        if (withoutSetValue === void 0) { withoutSetValue = false; }
        var ageField = this.fileterFields.get(priceServ.age.field);
        ageField.options = [];
        for (var i = priceServ.age.from; i <= 99; i++) {
            ageField.options.push({ label: i.toString(), value: i });
        }
        if (!withoutSetValue)
            this.filterFormGroup.get(priceServ.age.field).setValue(priceServ.age.to);
    };
    PriceStepComponent.prototype.isDisabledButton = function () {
        var isDisabledButton = true;
        if (this.priceServicesRemoved.length > 0 && this.filterFormGroup.valid) {
            isDisabledButton = false;
        }
        else if (this.priceRulesRemoved.length > 0 && this.filterFormGroup.valid) {
            isDisabledButton = false;
        }
        else if (this.filterFormGroup.valid && this.filterFormGroup.dirty) {
            isDisabledButton = false;
        }
        return isDisabledButton;
    };
    PriceStepComponent.prototype.removePriceService = function (priceService) {
        this.priceServices = this.priceServices.filter(function (x) { return x.code !== priceService.code; });
        delete this.priceServicesCollection[priceService.code]; // rimozione oggetto
        this.buttons[priceService.code] = priceService.title;
        this.clearFormArray(this.priceRules(priceService.code));
        this.filterFormGroup.get(priceService.age.field).setValue(null);
        if (priceService.code === 'SNR')
            this.filterFormGroup.get('age_adt').setValue(priceService.age.to);
        if (priceService.code === 'CHD' && this.priceServicesCollection['INF']) {
            this.priceServicesCollection['INF'].age.from = priceService.age.from;
        }
        else if (priceService.code === 'CHD' && !this.priceServicesCollection['INF']) {
            this.priceServicesCollection['ADT'].age.from = priceService.age.from;
        }
        if (priceService.code === 'INF' && this.priceServicesCollection['CHD']) {
            this.priceServicesCollection['CHD'].age.from = priceService.age.from;
        }
        else if (priceService.code === 'INF' && !this.priceServicesCollection['CHD']) {
            this.priceServicesCollection['ADT'].age.from = priceService.age.from;
        }
        this.priceServicesRemoved.push(priceService);
    };
    PriceStepComponent.prototype.removePriceRule = function (priceService, i) {
        this.priceRules(priceService.code).removeAt(i);
        this.priceRulesRemoved.push(this.filterFormGroup.get(priceService.code).value[i - 1]);
    };
    PriceStepComponent.prototype.beforeSave = function (formData) {
        if (this.priceServicesCollection['INF'])
            formData['age_from_inf'] = this.priceServicesCollection['INF'].age.from;
        if (this.priceServicesCollection['CHD'])
            formData['age_from_chd'] = this.priceServicesCollection['CHD'].age.from;
        if (this.priceServicesCollection['ADT'])
            formData['age_from_adt'] = this.priceServicesCollection['ADT'].age.from;
        if (this.priceServicesCollection['SNR'])
            formData['age_from_snr'] = this.priceServicesCollection['SNR'].age.from;
        return formData;
    };
    PriceStepComponent.prototype.onSave = function () {
        var _this = this;
        this.filterFormGroup.disable();
        var formData = this.filterFormGroup.getRawValue();
        this.datasetACS.loading.next(true);
        var route = this.getBaseRoute() + '/create';
        formData['trip_package_id'] = this.datasetRS.recordId;
        formData = this.beforeSave(formData);
        this.datasetService.post(route, formData, { headers: this.getHttpHeaders() })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                if (_this.filterFormGroup) {
                    _this.filterFormGroup.enable();
                    _this.filterFormGroup.reset();
                }
                _this.priceServicesCollection = {};
                _this.initFormGroup();
                _this.processResponse(response);
                setTimeout(function () {
                    _this.stepService.setStepState({
                        dataset_code: 'trip_packages',
                        form_code: 'prices',
                        record_id: _this.datasetRS.recordId,
                        status: 1
                    });
                    _this.goNextStep();
                }, 200);
            },
            error: function () {
                _this.datasetACS.loading.next(false);
                if (_this.filterFormGroup) {
                    _this.filterFormGroup.enable();
                }
            }
        });
    };
    return PriceStepComponent;
}(BaseStepViewComponent));
export { PriceStepComponent };
