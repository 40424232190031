import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DatasetActionContainerService, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { SelectDatasetRecordDialogComponent, ISelectDatasetRecordDialogData } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { ITripPackageOverviewDialogComponent, NewTripPackageOverviewDialogComponent } from 'app/main/components/dataset/dialogs/new-trip-package-overview/dialog.component';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ConfirmWithReasonDialogComponent } from 'app/main/dialogs/confirm-with-reason-dialog/dialog.component';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { BookingService } from '../../../booking.service';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import * as moment from 'moment';
import { actualTripsListViewConfig } from '../../new-trip-booking-package-selection/package-trip-selection/actual-trips-list-view-config';
import { RELOAD_BOOKING_PACKAGES, RELOAD_BOOKING } from '../../../edit-booking.component';
import { TripBookingParticipantComponent } from '../../../step-2/trip-booking-participants/trip-booking-participant.component';
import { TripBookingResourceComponent } from '../../../step-2/trip-booking-resources/trip-booking-resource.component';
import { TripBookingServiceComponent } from '../../../step-2/trip-booking-services/trip-booking-service.component';
import { ITripBookingPackageRelatedDataDialogData } from '../related-data/abstract-trip-booking-package-related-data.component';
import { isDatasetActionAllowed } from 'app/helpers';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import * as _ from 'lodash';

@Component({
	selector   : 'trip-package-booking',
	templateUrl: './trip-package-booking.component.html',
	styleUrls: ['./trip-package-booking.component.scss'],
	providers: []
})
export class TripPackageBookingComponent extends DestroyableComponent implements OnInit{

	protected _unsubscribeAll: Subject<any>;

	public loadingMeetingPoint = false;
	public canDelete = false;
	public canCancel = false;
	public isResaleCopy = false;
	public tripBookingPackage = null;
	public meetingPointDescription = '';

	get iterationData(){
		return this.tripBookingPackageService.iterationData;
	}

	get tripBookingPackageRelatedDialogData(): ITripBookingPackageRelatedDataDialogData{
		return {
			datasetCode: '',
			actionCode: '',
			tripBookingPackage: this.tripBookingPackage
		};
	}

	get tripBookingStatus(){
		return this.datasetACS.getValueFromKeyPath('trip_bookings.record.status');
	}

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public domainFilterService: DomainFilterService,
		public tripBookingPackageService: TripBookingPackageService,
		public tripBookingPackagesStepService: TripBookingPackagesStepService,
		public bookingService: BookingService,
		public viewContainerRef: ViewContainerRef
	){
		super();
	}

	ngOnInit(): void{
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((record) => {
			this.tripBookingPackage = record;
			this.canDelete = this.bookingService.canEdit && isDatasetActionAllowed(record, 'delete');
			this.canCancel = !this.canDelete && this.bookingService.canEdit && isDatasetActionAllowed(record, 'cancel');
			this.isResaleCopy = Boolean(record.ref_id);
			this.meetingPointDescription = this.computeMeetingPointDescription();
		});
	}

	computeMeetingPointDescription(){
		let description = _.get(this.tripBookingPackage, 'default_meeting_point_unified_list_item.meeting_point.description');
		let time = _.get(this.tripBookingPackage, 'default_meeting_point_unified_list_item.time');
		if(!time) time = _.get(this.tripBookingPackage, 'actual_trip.start_time');
		if(time){
			time = time.slice(0, 5);
			if(description) description += ' (' + time + ')';
			else description = time;
		}
		return description;
	}

	deleteTripBookingPackage(): void{
		if(this.canCancel){
			this.matDialog.open(ConfirmWithReasonDialogComponent, {
				width: '550px',
				data: {
					title: 'Conferma',
					reasonLabel: 'Vuoi annullare questa partenza?',
					reasonRequired: false
				}
			})
			.afterClosed()
			.subscribe(result => {
				if(!result) return;
				this.bookingService.cancelBooking(this.tripBookingPackage.trip_booking_id, Object.assign(result, {
					trip_booking_package_ids: [this.tripBookingPackage.id]
				}))
				.subscribe(() => {
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGES
					});
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
				});
			});
		}else if(this.canDelete){
			this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
			.afterClosed()
			.subscribe(result => {
				if(!result) return;
				this.appService.appEvent.next({
					name: RELOAD_BOOKING_PACKAGES
				});
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			});
		}else{
			this.datasetNavigatorService.openDialog(TextDialogComponent, {
				title: 'Attenzione',
				contentMessage: 'Operazione non permessa'
			});
		}
	}

	trackTripBookingResourcesFun(index: number, item: any): any{
		return item.id;
	}

	editDefaultMeetingPoint(): void{
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'meeting_point_unified_list_items',
			filters: {
				list_id: this.tripBookingPackage.actual_trip.meeting_point_list_id,
				default_meeting_point_list_item_id: this.datasetRS.record.value.default_meeting_point_list_item_id
			},
			title: 'Punto di ritrovo',
			parentDatasetACS: this.datasetACS,
			parentDatasetRS: this.datasetRS
		};
		this.matDialog.open(SelectDatasetRecordDialogComponent, {
			data
		}).afterClosed()
		.subscribe(record => {
			if(!record) return;
			this.loadingMeetingPoint = true;
			this.datasetService.post(this.datasetACS.getUpdateRoute(this.datasetRS.record.value), {
				default_meeting_point_list_item_id: record.id
			}).pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: response => {
					this.datasetRS.reload().subscribe({
						next: () => {
							this.loadingMeetingPoint = false;
						},
						error: () => {
							this.loadingMeetingPoint = false;
						}
					});
				},
				error: response => {
					this.loadingMeetingPoint = false;
				}
			});
		});
	}

	reloadTripBooking(): void{
		this.appService.appEvent.next({ name: RELOAD_BOOKING });
	}

	editTrip(): void{
		this.addTripBookingPackage(this.tripBookingPackage);
	}

	/**
	 * 
	 * @param tripBookingPackageToReplace optional
	 */
	addTripBookingPackage(tripBookingPackageToReplace: any): void{
		const tripBooking = this.datasetACS.getValueFromKeyPath('trip_bookings.record');
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'actual_trips',
			filters: { start_from: tripBooking.booking_date, only_bookable: 1 },
			viewConfig: actualTripsListViewConfig
		};
		if(tripBookingPackageToReplace){
			data.filters.trip_package_id = tripBookingPackageToReplace.trip_package_id;
			//data.filters.start_day = tripBookingPackageToReplace.actual_trip.start_day.substring(0, 10);
			data.filters.exclude_ids = tripBookingPackageToReplace.actual_trip_id;
			if (tripBookingPackageToReplace.properties.going_trip_booking_package_id && tripBookingPackageToReplace.properties.going_trip_booking_package_id.value){
				const goingPackageId = tripBookingPackageToReplace.properties.going_trip_booking_package_id.value;
				const bookingPackages = this.tripBookingPackagesStepService.tripBookingPackagesPage.items || [];
				const goingBookingPackage = bookingPackages.find(bookingPackage => bookingPackage.id == goingPackageId);
				if (goingBookingPackage){
					data.filters.going_trip_id = goingBookingPackage.actual_trip_id;
				}
			}
		}
		this.matDialog.open(SelectDatasetRecordDialogComponent, {
			panelClass: 'create-dialog-container',
			data
		}).afterClosed()
		.subscribe(actualTrip => {
			if(!actualTrip) return;
			const postData = {
				trip_booking_id: this.datasetRS.recordId,
				actual_trip_id: actualTrip.id,
			};
			let actionEndPoint = '/dataset/trip_booking_packages/create';
			if(tripBookingPackageToReplace){
				postData['trip_booking_package_id_to_replace'] = tripBookingPackageToReplace.id;
				actionEndPoint = '/dataset/trip_bookings/command/replace_trip';
			}
			this.tripBookingPackagesStepService.createTripBookingPackage(actionEndPoint, postData)
			.subscribe(() => {
				// without a subscribe observer not run
			});
		});
	}

	viewTripPackage(): void{
		this.datasetNavigatorService.openDialog<ITripPackageOverviewDialogComponent>(NewTripPackageOverviewDialogComponent, {
			parentDatasetRS: this.datasetRS,
			parentDatasetACS: this.datasetACS,
			tripPackage: this.tripBookingPackage.trip_package
		}, {minWidth: 700});
	}

	getRelatedDialogComponent(datasetCode: string): any{
		switch(datasetCode){
			case 'trip_booking_resources':
				return TripBookingResourceComponent;
			case 'trip_booking_services':
				return TripBookingServiceComponent;
			case 'trip_booking_participant_extras':
				return TripBookingParticipantComponent;
			default:
				return null;
		}
	}
}
