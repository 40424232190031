import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { toursFieldsMap } from './fields';
import * as moment from 'moment';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

toursFieldsMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: value.key });
	fieldsMap.set(key, value);
});

fieldsMap.set('parameters.tangerine_auth', {
	key: 'parameters.tangerine_auth',
	name: 'Tangerine Auth Plugin',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputReadOnly: true,
	inputHint: 'Verrà generato automaticamente'
});

fieldsMap.set('parameters.endpoint', {
	key: 'parameters.endpoint',
	name: 'Endpoint',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('parameters.api_key', {
	key: 'parameters.api_key',
	name: 'Chiave pubblica',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('parameters.private_key', {
	key: 'parameters.private_key',
	name: 'Chiave segreta',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('scenario', {
	key: 'scenario',
	name: '',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	defaultValue(dataContainerService: IDataContainerService): any {
		return 'touroperators.bokun';
	}
});

export {fieldsMap as bokunFieldsMap};
