import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardPageComponent } from 'app/main/pages/dashboard/dashboard.component';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatasetListWidgetModule } from 'app/main/widgets/dataset-list/widget.module';
import { ChartsModule } from 'ng2-charts';
import { TravelDateComponent } from './traveldate/traveldate.component';
import { CheckoutDateComponent } from './checkoutdate/checkoutdate.component';
import {TicketComponent} from './ticket/ticket.component';
import {TicketsYearComponent} from './ticketsyear/ticketsyear.component';
import { SourceMarketsComponent } from './sourcemarkets/sourcemarkets.component';
import {RevenueComponent} from './revenue/revenue.component';
import {ReviewsComponent} from './reviews/reviews.component';
import {ReviewsYStatComponent} from './reviewsy/reviewsystat.component';
import {BookingWindowComponent} from './bookingwindow/bookingwindow.component';
import { DashBoardService } from './dashboard.services';
import { FormInputViewerModule } from 'app/main/components/form-input-viewer/form-input-viewer.module';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
	declarations: [
		DashboardPageComponent,
		TravelDateComponent,
		CheckoutDateComponent,
		TicketComponent,
		TicketsYearComponent,
		SourceMarketsComponent,
		RevenueComponent,
		ReviewsComponent,
		BookingWindowComponent,
		ReviewsYStatComponent
	],
	imports     : [
		MatButtonModule,
		MatProgressSpinnerModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatSelectModule,
		MatTableModule,
		MatTabsModule,
		MatChipsModule,
		MatAutocompleteModule,
		ChartsModule,
		NgxChartsModule,
		MatDatepickerModule,
		FuseSharedModule,
		FuseSidebarModule,
		FuseWidgetModule,
		MatInputModule,
		DatasetModule,
		DatasetListWidgetModule,
		FormInputViewerModule,
		FlexLayoutModule,
		TranslateModule.forChild(),
	],
	providers: [
		DashBoardService
	]
})
export class DashbaordPageModule{}
