import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
var SuperadminGuardService = /** @class */ (function () {
    function SuperadminGuardService(auth, appService, router) {
        this.auth = auth;
        this.appService = appService;
        this.router = router;
    }
    SuperadminGuardService.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userData;
            return tslib_1.__generator(this, function (_a) {
                if (!this.auth.isAuthenticated())
                    return [2 /*return*/, false];
                userData = this.auth.userDataObserver.value;
                if (!userData || !userData.user || !userData.user.role)
                    return [2 /*return*/, false];
                if (userData.user.role.level !== 0)
                    return [2 /*return*/, false];
                return [2 /*return*/, true];
            });
        });
    };
    return SuperadminGuardService;
}());
export { SuperadminGuardService };
