import { Component, Input, OnInit, OnDestroy, Injector, ViewContainerRef } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { DomainFilterService } from 'app/services/domain-filter.service';
import * as _ from 'lodash';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { ITripBookingPackageRelatedDataDialogData } from '../related-data/abstract-trip-booking-package-related-data.component';
import { TripBookingPackagesStepService } from '../../trip-booking-packages-step.service';
import { BookingService } from '../../../booking.service';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from '../../../edit-booking.component';
import { TripBookingPackageService } from '../trip-booking-package.service';
import { isDatasetActionAllowed } from 'app/helpers';
import {ConfirmWithReasonDialogComponent} from "../../../../../dialogs/confirm-with-reason-dialog/dialog.component";

@Component({
	selector   : 'product-booking',
	templateUrl: './product-booking.component.html',
	styleUrls: ['./product-booking.component.scss'],
	providers: []
})
export class ProductBookingComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public loadingMeetingPoint = false;
	public hasEditableData = false;
	public canEditData = false;
	public canSingleConfirm = false;

	get iterationData(){
		return this.tripBookingPackageService.iterationData;
	}

	public tripBookingPackage: any;

	public isResaleCopy: boolean = false;

	get tripBookingStatus(){
		return this.bookingStepService && this.bookingStepService.datasetRS.record.value && this.bookingStepService.datasetRS.record.value.status;
	}

	get tripBookingPackageRelatedDialogData(): ITripBookingPackageRelatedDataDialogData{
		return {
			datasetCode: '',
			actionCode: '',
			tripBookingPackage: this.tripBookingPackage,
		};
	}

	public canDelete = false;
	public canCancel = false;
	public canEdit = false;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public matDialog: MatDialog,
		public domainFilterService: DomainFilterService,
		public bookingStepService: TripBookingPackagesStepService,
		public tripBookingPackageService: TripBookingPackageService,
		public bookingService: BookingService,
		private injector: Injector,
		public viewContainerRef: ViewContainerRef
	){
		super();
	}

	ngOnInit(): void{
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((record) => {
			this.tripBookingPackage = record;
			this.refreshData();
			this.canEdit = this.hasEditableData && !this.canEditData;
			const canDelete = this.bookingService.canEdit && !this.tripBookingPackage.tour_operator_booking_ref && !this.tripBookingPackage.tour_operator_booking_commit_status;
			this.canDelete = canDelete && this.bookingService.canEdit && isDatasetActionAllowed(record, 'delete');
			this.canCancel = !this.canDelete && this.bookingService.canEdit && isDatasetActionAllowed(record, 'cancel');
			this.isResaleCopy = Boolean(record.ref_id);
			this.canSingleConfirm = this.tripBookingStatus == 'mix' && !['confirmed','canceled'].includes(this.tripBookingPackage.booking_status) && !this.tripBookingPackage.tour_operator_booking_commit_status;
		});
	}


	deleteTripBookingPackage(): void{
		if(this.canCancel){
			this.matDialog.open(ConfirmWithReasonDialogComponent, {
				width: '550px',
				data: {
					title: 'Conferma',
					reasonLabel: 'Vuoi annullare questo prodotto?',
					reasonRequired: false
				}
			})
				.afterClosed()
				.subscribe(result => {
					if(!result) return;
					this.bookingService.cancelBooking(this.tripBookingPackage.trip_booking_id, Object.assign(result, {
						trip_booking_package_ids: [this.tripBookingPackage.id]
					}))
						.subscribe(() => {
							this.appService.appEvent.next({
								name: RELOAD_BOOKING_PACKAGES
							});
							this.appService.appEvent.next({
								name: RELOAD_BOOKING
							});
						});
				});
		}else if(this.canDelete){
			this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.tripBookingPackage)
				.afterClosed()
				.subscribe(result => {
					if(result){
						this.appService.appEvent.next({
							name: RELOAD_BOOKING_PACKAGES
						});
						this.appService.appEvent.next({
							name: RELOAD_BOOKING
						});
					}
				});
		}
		
	}

	trackTripBookingResourcesFun(index: number, item: any): any{
		return item.id;
	}

	reloadTripBooking(): void{
		this.appService.appEvent.next({ name: RELOAD_BOOKING });
	}

	viewProduct(): void{
		this.datasetNavigatorService.showDatasetEdit('products', {}, this.tripBookingPackage.product_id);
	}

	refreshData(){
		const formConfig = this.bookingStepService.getAditionalForm(this.tripBookingPackage.product, 'product', 'edit');
		this.hasEditableData = Boolean(formConfig);

		let canEditData = Boolean(this.tripBookingPackage);
		if(this.tripBookingPackage && this.tripBookingPackage.tour_operator_booking_commit_status > 0){
			canEditData = false;
		}
		this.canEditData = canEditData;
	}

	editData(){
		if(!this.canEditData) return;

		this.bookingStepService.requestBookingData(this.tripBookingPackage.product, 'product', 'edit', this.tripBookingPackage.target_info)
		.subscribe(data => {
			if(_.isEmpty(data)) return;
			this.bookingStepService.loading = true;
			this.datasetService.post(this.datasetACS.getUpdateRoute(this.datasetRS.record.value), {
				target_info: data
			}).pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: response => {
					this.bookingStepService.loading = false;
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGES
					});
					this.appService.appEvent.next({
						name: RELOAD_BOOKING
					});
				},
				error: response => {
					this.bookingStepService.loading = false;
				}
			});
		});
	}
}
