import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('start_day'),
			fieldsMap.get('end_day'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('start_time'),
			fieldsMap.get('end_time'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('min_allotment'),
			fieldsMap.get('default_max_allotment'),
		]
	},
	fieldsMap.get('start_status'),
	fieldsMap.get('note')
];

const config: IFormConfig = { 
	title: 'Crea Partenza',
	editTitle: 'Modifica Partenza',
	fields: formFields 
};

export default config;
