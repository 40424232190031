import {Component, forwardRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import formConfig from './form.config';
import { PageClosureContainerService } from '../../../page-closure-container.service';
import { DatasetActionContainerService } from '../../../../components/dataset/services/dataset-action-container.service';
import { TrenitaliaService } from '../trenitalia.service';
import { FormViewerService } from '../../../../../services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import * as moment from 'moment';
import { BookingService } from '../../booking.service';
import { DatasetRecordService } from '../../../../components/dataset/services/dataset-record.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import {AppService} from '../../../../../services/app.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from "../../../../../../environments/environment";

@Component({
	selector: 'trenitalia-search-form',
	templateUrl: './search-form.component.html',
	styleUrls: ['./search-form.component.scss'],
	providers: [
		forwardRef(() => PageClosureContainerService),
		forwardRef(() => DatasetActionContainerService),
		FormViewerService
	]
})
export class SearchFormComponent implements OnInit, OnDestroy {
	public formConfig = formConfig;
	public formFields = formFields;
	public checkoutData;
	public ar = false;
	public carnet = false;
	protected _unsubscribeAll: Subject<any>;
    public enableFormControl:any;
    @Input('data') data:any;
    public arShow:any;

	constructor(
		public appService: AppService,
		public pageClosureContainerPage: PageClosureContainerService,
		public datasetACS: DatasetActionContainerService,
		public trnService: TrenitaliaService,
		public formViewerService: FormViewerService,
		public matDialog: MatDialog,
		/*@Inject(MAT_DIALOG_DATA) public data: any*/
	) {
		this._unsubscribeAll = new Subject();
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		});
        this.arShow = _.get(environment,'trenitalia.ar',false)
	}

	ngOnInit() {
        this.enableFormControl = {from:true,to:true,start:true,start_time:true,ar:true}
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
            this.ar = this.checkoutData.ar;
			this.checkoutData.tripBookingId = _.get(this.data,'trip_booking_id');
            if(!_.get(this.checkoutData,'alterPass',false)){
                this.checkoutData.adult = this.checkoutData.mode == 'carnet'?1:_.get(this.data.participant,'ADT',0) + _.get(this.data.participant,'SNR',0);
                this.checkoutData.children = _.get(this.data.participant,'CHD',0);
            }
			this.formViewerService.formGroup.get('start_station').setValue(this.checkoutData.from);
			this.formViewerService.formGroup.get('end_station').setValue(this.checkoutData.to);
			this.formViewerService.formGroup.get('date').setValue(moment(this.checkoutData.start, moment.defaultFormat));
			this.formViewerService.formGroup.get('time_d').setValue(moment(this.checkoutData.start).format('HH:mm'));
			/*this.ar = this.checkoutData.direction != 'ONE_WAY';*/
			this.formViewerService.formGroup.get('ar').setValue(this.ar);
			if(this.checkoutData.carnetData && this.carnet)this.carnet=false;
            if(this.checkoutData.return){
                this.formViewerService.formGroup.get('date_r').setValue(moment(this.checkoutData.return.start, moment.defaultFormat));
                this.formViewerService.formGroup.get('time_r').setValue(moment(this.checkoutData.return.start).format('HH:mm'));
            }
			if(this.checkoutData.mode=='edit'&&!_.has(this.checkoutData,'searchSolutions')) this.onSearch();
            if(this.checkoutData.mode =='travel-change' && !_.has(this.checkoutData,'searchSolutions')){
                this.trnService.loading.next(true);
                const body = {
                    travel: _.get(this.checkoutData,'reopen.travel'),
                    postSaleDetails: _.get(this.checkoutData,'postSaleDatails')
                }
                this.trnService.checkTravelChangePossibilities(body)
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((possibilities:any) => {
                        this.trnService.loading.next(false);
                        this.setEnabledForm(possibilities);
                    });
            }
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	getCarnet(){
		this.carnet = !this.carnet;
	}

	getDateSearch(ar=false){
        let total_date = [];
        if(!this.formViewerService.formGroup.get('date').value) return null;
        if(!this.formViewerService.formGroup.get('time_d').value) return null;
        if(ar){
            if(!this.formViewerService.formGroup.get('date_r').value) return null;
            if(!this.formViewerService.formGroup.get('time_r').value) return null;
        }
        let date = this.formViewerService.formGroup.get('date').value.format('YYYY-MM-DD');
        let time = this.formViewerService.formGroup.get('time_d').value;
        total_date.push( moment(date+'T'+time, 'YYYY-MM-DDTHH:mm').toJSON());
        if(ar){
            date = this.formViewerService.formGroup.get('date_r').value.format('YYYY-MM-DD');
            time = this.formViewerService.formGroup.get('time_r').value;
            total_date.push( moment(date+'T'+time, 'YYYY-MM-DDTHH:mm').toJSON());
        }
        return total_date;
	}

	onSearch(similar = false) {
        this.checkoutData = _.omit(this.checkoutData,'selectedSolution')
		const formData = this.formViewerService.getFormData();
		const startDate = this.getDateSearch(this.ar);
        if(this.trnService.step.value==2)this.checkoutData.rebuild = true;

		if(this.data.mode != 'edit-date-time' && (!formData.start_station || !formData.end_station || !startDate)){
			// todo: set invalid input, create a validator for this form
			return;
		}
		this.trnService.loading.next(true);
	   /* this.checkoutData.selectedSolution = null;
		this.checkoutData.dataAv = null;*/
		this.checkoutData.start = startDate[0];
		this.checkoutData.ar = this.ar;
		this.checkoutData.from = this.formViewerService.formGroup.get('start_station').value;
		this.checkoutData.to = this.formViewerService.formGroup.get('end_station').value;
        if(this.ar) {
            this.checkoutData.return = {
                start :startDate[1],
                from: this.checkoutData.to,
                to: this.checkoutData.from,
            }
            this.checkoutData.direction = 'FORWARD';
        }
		if (this.data.mode == 'edit-date-time') {
			const sol = {
				reopen: this.data.data.reopen,
				new_date: moment(this.checkoutData.start).toJSON(),
				postSaleDetails: this.data.data.postAction.postSaleDetails,
                direction: this.checkoutData.direction
			};
			this.trnService.similarSolutions(sol)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: data => {
					this.trnService.loading.next(false);
					if(_.isEmpty(data.searchSimilarRecords)){
						this.matDialog.open(TextDialogComponent, {
							minWidth: '550px',
							data: {
								title: 'Attenzione',
								contentMessage: 'Nessuna soluzione trovata per questa data!',
								positiveText: 'OK'
							}
						});
						return;
					}
					this.checkoutData.searchSolutions = data;
					this.checkoutData.travellers = data.travellers;
					this.trnService.step.next(2);
					this.trnService.checkoutData.next(this.checkoutData);
				},
				error: error => {
					this.trnService.loading.next(false);
				}
			});
		} else{
			let sol = {
				adult: this.checkoutData.adult || 0,
				children: this.checkoutData.children,
				start: this.checkoutData.start,
				ar: this.checkoutData.ar,
				from: this.checkoutData.from,
				to: this.checkoutData.to,
				direction: this.checkoutData.direction
			};
			if(this.data.mode == 'travel-change'){
				sol['selectedPostSaleDetails'] =_.get(this.data.data.postAction,'postSaleDetails');
				sol['travelSolutions'] = _.get(this.data.data.reopen,'travel.travelSolutions');
				sol['travellers']=_.get(this.data.data.reopen,'travel.travellers');
			}
            if(this.checkoutData.ar){
                sol['returnTripDate'] = this.checkoutData.return.start;
                sol['roundTrip'] = true;
            }
			this.trnService.getSolutions(sol)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: (data:any) => {
					this.trnService.loading.next(false);
					if(_.isEmpty(data.travelSolutions)){
						this.appService.showErrorMessage("Nessuna soluzione trovata!")
					}else{
						this.checkoutData.searchSolutions = data;
                        const servicesMap = _.get(this.checkoutData, 'searchSolutions.servicesMap') || _.get(this.checkoutData, 'searchSolutions.serviceMap');
                        this.trnService.updateServicesMap(servicesMap);
                        this.checkoutData.rebuild = true;
                        this.checkoutData = _.omit(this.checkoutData,[
                            'selectedSolution','selection','seatMaps',
                            'seatsCount','seatsSel', 'dataAv'
                        ]);
                        this.trnService.total.next(0);
						this.trnService.step.next(2);

						this.trnService.checkoutData.next(this.checkoutData);
					}
				},
				error: error => {
					this.trnService.loading.next(false);
				}
			});
		}
	}

	/**
	 * Solution search with carnet
	 */
	onSearchCarnet(){
		const formData = this.formViewerService.getFormData();
		const startDate = this.getDateSearch();
		if(!formData.start_station || !formData.end_station || !startDate){
			// todo: set invalid input, create a validator for this form
			return;
		}
		this.trnService.loading.next(true);
		this.checkoutData.start = startDate[0];
		/*this.checkoutData.selectedSolution = null;
		this.checkoutData.dataAv = null;*/
		this.checkoutData.ar = this.ar;
		this.checkoutData.from = this.formViewerService.formGroup.get(
			'start_station'
		).value;
		this.checkoutData.to = this.formViewerService.formGroup.get(
			'end_station'
		).value;
		const data = {
			searchCriteria:{
				startDate:  this.checkoutData.start,
				departureStation: _.get(this.checkoutData.carnetData,'station_from'),
				arrivalStation: _.get(this.checkoutData.carnetData,'station_to'),
				direction:_.get(this.checkoutData,'direction'),
			},
			carnetEntitlementId:_.get(this.checkoutData.carnetData,'entitlements.id'),
			traveller: _.get(this.checkoutData,'travellers'),
			offersAvailable: _.get(this.checkoutData.carnetData,'offersAvailable')
		}

		this.trnService.searchCarnetSolutions(data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe( solutions =>{
			this.checkoutData.searchSolutions = solutions;
            this.checkoutData.rebuild = true;
            this.checkoutData = _.omit(this.checkoutData,[
                'selectedSolution','selection','seatMaps',
                'seatsCount','seatsSel', 'forward','return', 'dataAv'
            ]);
            this.trnService.total.next(0);
            this.trnService.updateServicesMap(_.get(solutions,'serviceMap'));
			this.trnService.checkoutData.next(this.checkoutData);

			this.trnService.step.next(2);
			this.trnService.loading.next(false);
		}, error => {
			this.trnService.loading.next(false);
			this.appService.showErrorMessage('Errore nella ricerca delle soluzioni');
		});
	}

    setAr(){
        this.checkoutData.direction=!this.ar?'FORWARD':'ONE_WAY';
        this.checkoutData.ar = this.ar = !this.ar;
        this.formViewerService.formGroup.get('ar').setValue(this.ar);
        this.formViewerService.formGroup.get('date_r').setValue(this.formViewerService.formGroup.get('date').value);
        this.formViewerService.formGroup.get('time_r').setValue(this.formViewerService.formGroup.get('time_d').value);
        this.checkoutData.from = this.formViewerService.formGroup.get('start_station').value;
        this.checkoutData.to = this.formViewerService.formGroup.get('end_station').value;
        this.trnService.checkoutData.next(this.checkoutData);
        if(this.trnService.step.value == 2){
            this.trnService.btnAction.next('back-start');
        }
    }
    swap(){
        this.checkoutData.from = this.formViewerService.formGroup.get('end_station').value;
        this.checkoutData.to = this.formViewerService.formGroup.get('start_station').value;
        this.trnService.checkoutData.next(this.checkoutData);
    }

    private setEnabledForm(possibilities: any) {
        possibilities.forEach(poss=>{
            switch (poss.travelSearchOptionType){
                case 'ORIGIN_DESTINATION': this.enableFormControl.from=poss.allowed=="true";this.enableFormControl.to=poss.allowed=="true";break;
                case 'DATE': this.enableFormControl.start=poss.allowed=="true";break;
                case 'ROUND_TRIP': this.enableFormControl.ar=poss.allowed=="true";break;
                case 'TIME': this.enableFormControl.start_time=poss.allowed=="true";break;
            }
        })
    }
}
