<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
	<div class="description" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
		<span class="material-icons-outlined">fact_check</span>
		<span class="label">{{'Servizi' | translate}}</span>
	</div>

	<div class="close-icon" fxLayout="row" fxLayoutAlign="end center">
		<span class="material-icons-outlined dialog-close-icon" (click)="onCloseClick()">close</span>
	</div>
</div>

<div class="trip-booking-services-container">
	<dataset-list
		[elevate]='false'
		[showSearchBar]='false'
		[showCreateForm]='false'
		[enableCreate]='false'
		[showTitle]='false'
		datasetCode='trip_booking_services'
		[parentDatasetACS]='parentDatasetACS'
		[parentDatasetRS]='parentDatasetRS'
		[filters]='{ trip_booking_package_id: tripBookingPackage.id }'
		[enableActions]='canEdit'
		[enableDelete]='canEdit'
		[enableCreate]='canEdit'
		[enableEdit]='canEdit'
		tableStyle="style-3"
	></dataset-list>
</div>
<!-- footer -->
<div class="footer" fxLayout="row" fxLayoutAlign="space-between center">
	<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
		<span class="label">{{'Totale' | translate}}:</span>
		<span class="value">{{ tripBookingPackage.total_amount | currency:"EUR" }}</span>
	</div>
	<div fxLayout="row" fxLayoutAlign="start center">
		<button class="add-service-btn mr-24" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" (click)="onRecalculateService()">
			<span class="material-icons-outlined">update</span> 
			<span class="btn-label">{{'Ricalcola costi' | translate}}</span>
		</button>
		<button class="add-service-btn" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" (click)="addTripBookingService()">
			<span class="material-icons-outlined">add</span> 
			<span class="btn-label">{{'Aggiungi servizio' | translate}}</span>
		</button>
	</div>
</div>