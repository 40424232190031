import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval, Subject } from 'rxjs';
import { startOfDay, isSameDay, isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '@fuse/animations';

import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { CalendarEventModel } from 'app/main/pages/calendar/event.model';
import { CalendarEventFormDialogComponent } from 'app/main/pages/calendar/event-form/event-form.component';
import { PageClosureContainerService } from '../page-closure-container.service';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { IPaginationResponse, IFilterFieldDefinition, IFieldDefinition, ValueTypes, InputTypes, ISocketEvent } from 'app/interfaces';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { convertToUTCDay, forEachDay } from 'app/helpers/date.helper';
import { AppService } from 'app/services/app.service';
import { WebSocketService } from 'app/services/websocket.service';

const FilterCategoriesFieldDefinition: IFieldDefinition = {
	key: 'filter_categories',
	name: 'Categorie',
	appearance: 'legacy',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.DATASET_MULTISELECT,
		clearable: true
	},
	datasetCode: 'trip_categories',
	remoteOptions: {
		getLabel(item): string{
			return item.description;
		},
		getValue(item): string{
			return item.id;
		}
	}
};

const FilterPackagesFieldDefinition: IFieldDefinition = {
	key: 'filter_packages',
	name: 'Pacchetti',
	appearance: 'legacy',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.DATASET_MULTISELECT,
		clearable: true
	},
	datasetCode: 'trip_packages',
	remoteOptions: {
		getLabel(item): string{
			return item.description;
		},
		getValue(item): string{
			return item.id;
		}
	}
};

@Component({
	selector     : 'calendar',
	templateUrl  : './calendar.component.html',
	styleUrls    : ['./calendar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
	providers: [
		PageClosureContainerService,
		{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class CalendarComponent implements OnInit, OnDestroy{

	isMobile: boolean;
	activeDayIsOpen: boolean;
	confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
	dialogRef: any;
	events: CalendarEvent[];
	groupedEvents: any[];
	groupedEventsByHour: any[];
	refresh: Subject<any> = new Subject();
	selectedDay: any;
	view: string;
	viewDate: Date;
	_unsubscribeAll: Subject<any>;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	@ViewChild('filterInput', { static: false }) filterInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

	public filterFormGroup: FormGroup;
	public filterCategoriesFieldDefinition = FilterCategoriesFieldDefinition;
	public filterPackagesFieldDefinition = FilterPackagesFieldDefinition;
	public get localeId() {
		return this.translate.currentLang;
	}

	constructor(
		private _matDialog: MatDialog,
		public _calendarService: CalendarService,
		private _datasetService: DatasetService,
		private appService: AppService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected fb: FormBuilder,
		protected webSocketService: WebSocketService,
		public translate: TranslateService
	){
		// Set the defaults
		this.view = 'month';
		this.viewDate = new Date();
		this.activeDayIsOpen = false;
		this._unsubscribeAll = new Subject();

		/**
		 * Get events from service/server
		 */
		this.setEvents();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		/**
		 * on screen size < 992 redirect to view day calendar
		 */
		if (window.innerWidth < 992) this.view = 'day';

		if(this.isMobileOperatingSystem()) {
			this.isMobile = true;
		}

		/**
		 * Watch re-render-refresh for updating db
		 */
		this.refresh.subscribe(updateDB => {
			
		});
		
		/**
		 * listen on web socket event
		 */
		 if (this.webSocketService.ioSocket) this.webSocketService.ioSocket.on('channel-event', this.onChannelEvent.bind(this));

		this._calendarService.onEventsUpdated
		.subscribe(events => {
			this.setEvents();
			this.refresh.next();
		});

		this.filterFormGroup = this.fb.group({
			filter_trip_categories: [this._calendarService.filterParams.filter_trip_categories],
			filter_trip_packages: [this._calendarService.filterParams.filter_trip_packages]
		});

		this.filterFormGroup
		.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.pipe(distinctUntilChanged())
		.subscribe(values => {
			this._calendarService.filterParams = Object.assign(this._calendarService.filterParams, values);
			this._calendarService.getAllDaysEvents({
				start: moment.utc(this.viewDate).startOf('month'),
				end: moment.utc(this.viewDate).endOf('month'),
			}).subscribe(
				() => this._calendarService._viewDateChanged.next(this.viewDate)
			);
		});

		// initial load
		setTimeout(() => {
			this._calendarService.getAllDaysEvents();
		}, 500);
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
		// remove socket listener
		if (this.webSocketService.ioSocket) this.webSocketService.ioSocket.off('channel-event');
	}

	onChannelEvent(broadcastEvent: ISocketEvent): void{
		if(broadcastEvent.data.datasetCode != 'calendar' && broadcastEvent.data.eventName != 'change_allotment') return;
		// socket actual trip
		const actualTripEvent = broadcastEvent.data.payloadData;
		const tripPackageId = actualTripEvent.trip_package_id || null;
		const momentDate = moment(actualTripEvent.start_day);
		const dateStart = momentDate.format('DDMMYY');

		const date = new Date(actualTripEvent.start_day);
		const day = convertToUTCDay(date).format('YYYY-MM-DD');
		this._calendarService.dayLoading[day] = { loading: true };
		this.isDayLoading(date);

		// refresh allotment actual trips
		if (this.groupedEvents[dateStart] && this.groupedEvents[dateStart][tripPackageId]){
			const events = <CalendarEventModel[]>this.groupedEvents[dateStart][tripPackageId].events;
			const intrestedTrip = <CalendarEventModel>events.find(trip => trip.meta.actual_trip.id == actualTripEvent.actual_trip_id);
			const indexOf = events.indexOf(intrestedTrip);
			if (indexOf !== -1 && intrestedTrip.meta.actual_trip.pax_by_resource){
				// update properties
				intrestedTrip.meta.actual_trip.pax_by_resource = actualTripEvent.pax_by_resource || null;
				intrestedTrip.meta.actual_trip.consumed_allotment = actualTripEvent.consumed_allotment || 0;
				intrestedTrip.meta.actual_trip.max_allotment = actualTripEvent.max_allotment || 0;
				intrestedTrip.cssClass = intrestedTrip.computeClass();
				// update event
				this.groupedEvents[dateStart][tripPackageId].events[indexOf] = intrestedTrip;
			}
		}

		setTimeout(() => {
			this._calendarService.dayLoading[day] = { loading: false };
			this.isDayLoading(date);
		}, 3000);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Determine the mobile operating system.
	 * This function returns one of 'iOS', 'Android', 'Windows Phone'.
	 *
	 * @returns {boolean}
	 */
	isMobileOperatingSystem(): boolean{
		const userAgent = navigator.userAgent || navigator.vendor;

		if (/windows phone/i.test(userAgent)) {
			return true;
		}else if (/android/i.test(userAgent)) {
			return true;
		}else if (/iPad|iPhone|iPod/.test(userAgent)) {
			return true;
		}
	
		return false;
	}

	/**
	 * Set events
	 */
	setEvents(): void{
		this.events = this._calendarService.events || [];
		this.groupedEvents = this._calendarService.groupedEvents || {};
		this.groupedEventsByHour = this._calendarService.groupedEventsByHour || [];
	}

	/**
	 * Before View Renderer
	 *
	 * @param {any} header
	 * @param {any} body
	 */
	beforeMonthViewRender(event: CalendarMonthViewBeforeRenderEvent): void{
		/**
		 * Get the selected day
		 */
		const _selectedDay = event.body.find((_day) => {
			if(!this.selectedDay) return false;
			return _day.date.getTime() === this.selectedDay.date.getTime();
		});

		if ( _selectedDay ){
			/**
			 * Set selected day style
			 * @type {string}
			 */
			_selectedDay.cssClass = 'cal-selected';
		}
	}

	onViewDateChanged(date: Date): void{
		this.selectedDay = {date};
		/**
		 * check cached data
		 */
		const dateIdx = moment(date).format('DDMMYY');
		if (this.view === 'month' || this.view === 'week'){
			if (this._calendarService.groupedEvents[dateIdx]) return;
		} else if (this.view === 'day' && this._calendarService.groupedEventsByHour[dateIdx]){
			this._calendarService._viewDateChanged.next(date);
			return;
		}
		this._calendarService.getAllDaysEvents({
			start: moment.utc(this.viewDate).startOf('month'),
			end: moment.utc(this.viewDate).endOf('month'),
		});
	}

	/**
	 * Day clicked
	 *
	 * @param {MonthViewDay} day
	 */
	dayClicked(day: CalendarMonthViewDay): void{
		const date: Date = day.date;
		const events: CalendarEvent[] = day.events;

		if ( isSameMonth(date, this.viewDate) ){
			if ( (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0 ){
				this.activeDayIsOpen = false;
			}else{
				this.activeDayIsOpen = true;
				this.viewDate = date;
			}
		}else{
			this.activeDayIsOpen = false;
		}
		this.selectedDay = day;
		this._calendarService.activeDayIsOpenSubscriber.next({opened: this.activeDayIsOpen});
		this.refresh.next();
	}
	
	sidebarClosed(event: any): void{
		this.activeDayIsOpen = false;
	}

	/**
	 * Event times changed
	 * Event dropped or resized
	 *
	 * @param {CalendarEvent} event
	 * @param {Date} newStart
	 * @param {Date} newEnd
	 */
	eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void{
		// TODO
	}

	eventClick(event): void{
		// TODO
	}

	/**
	 * Delete Event
	 *
	 * @param event
	 */
	deleteEvent(event): void{
		this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
			disableClose: false
		});

		this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

		this.confirmDialogRef.afterClosed().subscribe(result => {
			if ( result )
			{
				const eventIndex = this.events.indexOf(event);
				this.events.splice(eventIndex, 1);
				this.refresh.next(true);
			}
			this.confirmDialogRef = null;
		});

	}

	/**
	 * Edit Event
	 *
	 * @param {string} action
	 * @param {CalendarEvent} event
	 */
	editEvent(action: string, event: CalendarEvent): void{
		const eventIndex = this.events.indexOf(event);

		this.dialogRef = this._matDialog.open(CalendarEventFormDialogComponent, {
			panelClass: 'event-form-dialog',
			data      : {
				event : event,
				action: action
			}
		});

		this.dialogRef.afterClosed()
			.subscribe(response => {
				if ( !response )
				{
					return;
				}
				const actionType: string = response[0];
				const formData: FormGroup = response[1];
				switch ( actionType )
				{
					/**
					 * Save
					 */
					case 'save':

						this.events[eventIndex] = Object.assign(this.events[eventIndex], formData.getRawValue());
						this.refresh.next(true);

						break;
					/**
					 * Delete
					 */
					case 'delete':

						this.deleteEvent(event);

						break;
				}
			});
	}

	openInfo(text): void{
		this._matDialog.open(TextDialogComponent, {
			minWidth: '550px',
			data: {
				contentMessage: text
			}
		});
	}

	isDayLoading(date: Date): boolean{
		if(!date) return false;
		const day = convertToUTCDay(date).format('YYYY-MM-DD');
		if(!this._calendarService.dayLoading[day]) return false;
		return this._calendarService.dayLoading[day].loading;
	}
}


