import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { makeDrawValuePlugin } from '../chartjs.plugins';

@Component({
	selector   : 'reviewsystat',
	templateUrl: './reviewsystat.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class ReviewsYStatComponent extends DestroyableComponent implements OnInit, AfterViewInit{
	public showLoader: boolean;

	@ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;

	/**
	 * ReviewsYStat
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		scales: { 
			xAxes: [{}], 
			yAxes: [
				{
					id: 'total',
					type: 'linear',
					position: 'left',
					gridLines: {
						display: false,
					}
				}, 
				{
					id: 'avg',
					type: 'linear',
					position: 'right',
					ticks: {
						max: 5,
						min: 0
					},
					gridLines: {
						display: false,
					}
				}
			] 
		},
		legend: {
			position: 'bottom',
			labels: {
				usePointStyle: true
			}
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		}
	};
	public chartPlugins = [makeDrawValuePlugin()];

	public barChartType: ChartType = 'bar';
	public barChartLegend = false;
	public reviewsystat: ChartDataSets[];
	public barChartLabels: Label[];
	
	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.reviewsystat = [{data: []}];
	}

	ngAfterViewInit(): void{
		this.dashboardService.reviewResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				this.onData(response);
			}
		});
	}

	public onData(response: any): void{
		this.showLoader = true;
		
		if(!response) {
			this.reviewsystat = [{data: []}];
			return;
		}
		const chartArea = this.chartComponent.chart.chartArea;
		const gradient1: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.7, 0, chartArea.bottom);
		gradient1.addColorStop(0, '#ff6384');
		gradient1.addColorStop(1, 'white');

		const gradient2: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.7, 0, chartArea.bottom);
		gradient2.addColorStop(0, '#4bc0c0');
		gradient2.addColorStop(1, 'white');

		const data: ChartDataSets[] = [
			{ 
				data: [], 
				label: 'Negative Point',
				yAxisID: 'total',
				type: 'bar',
				backgroundColor: gradient1,
				hoverBackgroundColor: '#ff6384'
			},
			/*{ 
				data: [], 
				label: 'Average Rating', 
				lineTension: 0, 
				yAxisID: 'avg',
				type: 'line',
				fill: false
			},*/
			{ 
				data: [], 
				label: 'Total Reviews',
				yAxisID: 'total',
				type: 'bar',
				backgroundColor: gradient2,
				hoverBackgroundColor: '#4bc0c0'
			}
		];

		if(response.length > 0){
			this.showLoader = false;
			const years = [];
			for (const item of response) {
				if(years.includes(item.year)) continue;
				years.push(item.year);
			}
			years.sort();
			this.barChartLabels = years.map(el => { 
				return String(el); 
			});
			const yearRecordCount = [];
			
			for (const item of response) {
				const yearIndex = years.indexOf(item.year);
				if(isNaN(Number(data[0].data[yearIndex]))){
					data[0].data[yearIndex] = 0;
					//data[1].data[yearIndex] = 0;
					data[1].data[yearIndex] = 0;
					yearRecordCount[yearIndex] = 0;
				}
				//data[1].data[yearIndex] = Number(item.avg_points) + Number(data[1].data[yearIndex]);
				data[0].data[yearIndex] += item.negative_points;
				data[1].data[yearIndex] += item.total;
				yearRecordCount[yearIndex]++;
			}

			/*for (let index = 0; index < years.length; index++) {
				const avg = Number(data[1].data[index]);
				data[1].data[index] =  Math.round((avg / yearRecordCount[index]) * 100) / 100;
			}*/
		}

		this.reviewsystat = data;
	}
	// -----------------------------------------------------------------------------------------------------
	
}
