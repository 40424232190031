
<div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between start'>
	<h1 style="margin:0">Dettaglio
        <span *ngIf="trenitaliaPostSaleService.isCarnet">Carnet</span>
        <span *ngIf="!trenitaliaPostSaleService.isCarnet">Viaggio</span>
    </h1>
	<button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="trenitaliaPostSaleService.loading.value"></mat-progress-bar>
<!--<ng-template #trainBradcrumbs let-train="train" let-last="last">
	<ng-container *ngIf="train.type == 'multi' && train.subSegments">
		<ng-container
			*ngFor="let t of train.subSegments;let last = last;"
			[ngTemplateOutlet]='trainBradcrumbs' 
			[ngTemplateOutletContext]='{last:last, train:t}'
		></ng-container>
	</ng-container>
	<ng-container *ngIf="train.type != 'multi'">
		<img *ngIf="trainTypeImages[train.type]" [matTooltip]="train.type" [src]="trainTypeImages[train.type]" class="train-icon"/>
		<small class="train-name"> {{train.name}}</small>
	</ng-container>
	<span *ngIf="!last"> / </span>
</ng-template>-->
<mat-dialog-content>

	<div *ngIf="trenitaliaPostSaleService.travelDetails.value">
        <div class="mb-8 text-right">
            <button *ngIf="dataDialog.tripBookingPackage.tour_operator_booking_status =='confirmed'" mat-raised-button [disabled]="load" (click)="sendMail()">
                <mat-icon class="s-16">email</mat-icon><span class="font-weight-600">Invia via Mail</span>
            </button>
        </div>

		<ng-container *ngFor="let travelSolution of travelSolutions;let last = last;">
            <mat-accordion [multi]="true">
                <trenitalia-solution-details [travelSolution]="travelSolution" [ar]="travelSolution.returnTravelSolution?'forward':'one_way'"></trenitalia-solution-details>
                <trenitalia-solution-details *ngIf="travelSolution.returnTravelSolution" [travelSolution]="travelSolution.returnTravelSolution" [ar]="'return'" ></trenitalia-solution-details>
            </mat-accordion>
		</ng-container>
	</div>

</mat-dialog-content>