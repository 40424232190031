import { InputTypes, ValueTypes } from 'app/interfaces';


export default {
	key: 'tour_operator_id',
	name: 'Tour Operator',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_tour_operators',
	inputHint: 'Scrivi per cercare un tour operator',
	remoteOptions: {
		limit: 100,
		icon: 'person',
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		}
	}
};
