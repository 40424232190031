/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "../../../../../../services/dataset-action-container.service";
import * as i9 from "../../../../../../../../../services/app.service";
import * as i10 from "../../../../../../../../pages/page-closure-container.service";
import * as i11 from "../../../../../../../../../services/auth.service";
import * as i12 from "../../../../../../services/dataset.service";
import * as i13 from "../../../../../../../../../services/domain-filter.service";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../../../../../services/dataset-record.service";
import * as i16 from "./item.component";
import * as i17 from "../../../../../../services/dataset-navigator.service";
var styles_ActualTripGroupTripResourceItemViewComponent = [i0.styles];
var RenderType_ActualTripGroupTripResourceItemViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActualTripGroupTripResourceItemViewComponent, data: {} });
export { RenderType_ActualTripGroupTripResourceItemViewComponent as RenderType_ActualTripGroupTripResourceItemViewComponent };
export function View_ActualTripGroupTripResourceItemViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "mt-16 p-16 actual-trip-group-item mat-elevation-z2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["color", "warn"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"]))], function (_ck, _v) { var currVal_3 = "warn"; _ck(_v, 5, 0, currVal_3); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.trip_resource.description; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 7).inline; var currVal_5 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_4, currVal_5); }); }
export function View_ActualTripGroupTripResourceItemViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "actual-trip-group-trip-resources-item-view", [], null, null, null, View_ActualTripGroupTripResourceItemViewComponent_0, RenderType_ActualTripGroupTripResourceItemViewComponent)), i1.ɵprd(512, null, i8.DatasetActionContainerService, i8.DatasetActionContainerService, [i9.AppService, i10.PageClosureContainerService, i11.AuthService, i12.DatasetService, i13.DomainFilterService, i14.TranslateService, i1.Injector]), i1.ɵprd(512, null, i15.DatasetRecordService, i15.DatasetRecordService, [i8.DatasetActionContainerService, i12.DatasetService]), i1.ɵdid(3, 638976, null, 0, i16.ActualTripGroupTripResourceItemViewComponent, [i12.DatasetService, i9.AppService, i8.DatasetActionContainerService, i15.DatasetRecordService, i17.DatasetNavigatorService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ActualTripGroupTripResourceItemViewComponentNgFactory = i1.ɵccf("actual-trip-group-trip-resources-item-view", i16.ActualTripGroupTripResourceItemViewComponent, View_ActualTripGroupTripResourceItemViewComponent_Host_0, { parentDatasetACS: "parentDatasetACS", item: "item" }, { delete: "delete", addItem: "addItem" }, []);
export { ActualTripGroupTripResourceItemViewComponentNgFactory as ActualTripGroupTripResourceItemViewComponentNgFactory };
