<button mat-flat-button class="mr-4 my-8" color="accent" (click)="onAdd()">{{ 'Aggiungi' | translate  }}</button>
<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Tipo </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef> Etichetta </th>
        <td mat-cell *matCellDef="let element"> {{element.label}} </td>
    </ng-container>

    <ng-container matColumnDef="min">
        <th mat-header-cell *matHeaderCellDef> Min </th>
        <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.type=='select'?element.min:'--'}} </td>
    </ng-container>
    <ng-container matColumnDef="max">
        <th mat-header-cell *matHeaderCellDef> Max </th>
        <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.type=='select'?element.max:'--'}} </td>
    </ng-container>
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Valore </th>
        <td mat-cell *matCellDef="let element" style="text-align: center"> {{element.type=='select'?element.value:'--'}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let element; let i = index">
            <div class="action-button">
                <button mat-icon-button color='accent' (click)="onEdit(element,i)"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button *ngIf="element.name!=='participants'" (click)="onDelete(element)"><mat-icon color='warn'>delete</mat-icon></button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


