import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { TrenitaliaService } from "../../trenitalia.service";
import * as _ from 'lodash';
import { DestroyableComponent } from "app/main/destroyable.component";
import { takeUntil } from "rxjs/operators";
var TravelSolutionListItemComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TravelSolutionListItemComponent, _super);
    function TravelSolutionListItemComponent(trnService, elRef) {
        var _this = _super.call(this) || this;
        _this.trnService = trnService;
        _this.elRef = elRef;
        _this.isSelected = false;
        _this.isDetailsOpen = false;
        _this.onSelect = new EventEmitter();
        _this.onDetails = new EventEmitter();
        _this.onPriceSelected = new EventEmitter();
        _this.onCarnetChange = new EventEmitter();
        _this.checkoutDataMode = null;
        _this.isCarnet = false;
        _this.isAr = false;
        _this.isReturn = false;
        _this.isSeats = false;
        _this.loading = false;
        return _this;
    }
    TravelSolutionListItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.updateFromCheckoutData();
        });
        this.trnService.loading
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (load) {
            _this.loading = load;
        });
    };
    TravelSolutionListItemComponent.prototype.scrollIn = function () {
        var _this = this;
        // TODO: copy animation and scroll from cdkAccordion, material/expansion/expansion-aimations.ts
        setTimeout(function () {
            _this.elRef.nativeElement.scrollIntoView({ block: 'start' });
        }, 600);
    };
    TravelSolutionListItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.travelSolution) {
            //this.updateData();
        }
        if (changes.isSelected && changes.isSelected.currentValue && !changes.isSelected.previousValue) {
            this.scrollIn();
        }
        if (changes.isDetailsOpen && changes.isDetailsOpen.currentValue && !changes.isDetailsOpen.previousValue) {
            this.scrollIn();
        }
    };
    TravelSolutionListItemComponent.prototype.updateFromCheckoutData = function () {
        var checkoutData = this.trnService.checkoutData.value;
        this.checkoutDataMode = checkoutData.mode;
        this.isCarnet = Boolean(checkoutData.carnetData);
        this.selectedServiceCarnet = _.get(checkoutData, 'carnetData.selectedService');
        this.isAr = Boolean(checkoutData.ar);
        this.isSeats = checkoutData.seatsRequested;
        this.isReturn = checkoutData.direction == 'RETURN';
    };
    TravelSolutionListItemComponent.prototype.indexTrackBy = function (index, item) {
        return index;
    };
    return TravelSolutionListItemComponent;
}(DestroyableComponent));
export { TravelSolutionListItemComponent };
