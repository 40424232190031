var ɵ0 = {
    // edit-booking.component
    'Prenotazione': 'Trip Booking',
    'Confermata': 'Confirmed',
    'Cancellata': 'Canceled',
    'Opzionale (in scadenza)': 'Optional (expiring)',
    'Scaduta': 'Expired',
    'Non Confermata': 'Not Confirmed',
    'Preventivo': 'Price quotation',
    'Informazioni': 'Information',
    'Base': 'Basic',
    'Partecipanti': 'Participants',
    'Partenze': 'Departures',
    'Sconti': 'Discounts',
    'Bozza': 'Draft',
    'Sospendi Prenotazione': 'Suspend Reservation',
    'Bloccal il preventivo fino a una scadenza (Mantiene i posti riservati)': 'Block the quote until a deadline (Keeps the places reserved)',
    'Blocca preventivo': 'Block quote',
    'Annulla Prenotazione': 'Cancel Reservation',
    'Voucher': 'Voucher',
    'Invia via mail': 'Send by mail',
    'Stampa': 'Print',
    'Stai modificando una prenotazione non terminata': 'You are modifying an unfinished booking',
    'Chiudi messaggio': 'Close message',
    'Cancella Permanentemente': 'Permanently delete',
    'Questa prenotazione se non confermata scadrà il': 'This reservation if not confirmed will expire on',
    'Questa prenotazione è scaduta il': 'This reservation has expired on',
    'Salva e vai avanti': 'Save and move on',
    'Vai avanti': 'Go ahead',
    'Indietro': 'Go back',
    'Andata': 'Gone',
    'Ritorno': 'Return',
    'Apri dettagli pacchetto': 'Open package details',
    'lordo': 'gross',
    'netto': 'net',
    'Apri dettaglio partenza': 'Open departure detail',
    'Modifica partenza': 'Change departure',
    'Punto di ritrovo': 'Meeting point',
    'Modifica punto di ritrovo': 'Edit meeting point',
    'Risorse': 'Resources',
    'Servizi': 'Services',
    'Risorsa opzionale NON attivata': 'Optional resource NOT activated',
    'Risorsa opzionale attivata': 'Optional resource activated',
    'Inclusa': 'Included',
    'Opzionale': 'Optional',
    'Dettaglio Risorsa': 'Resource Detail',
    'Informazioni Base': 'Basic Information',
    'Intestatario': 'Accountholder',
    'Email Intestatario': 'Owner email',
    'Telefono': 'Phone number',
    'Cliente': 'Customer',
    'Tipo Di Pagamento': 'Method payment',
    'Listino': 'Customer price list',
    'Numero Voucher Operatore': 'Operator voucher number',
    'Id Ordine Online': 'Online Order Id',
    'Data Prenotazione': 'Booking date',
    'Pacchetto': 'Package',
    'Partenza': 'Departure',
    'Totale': 'Total',
    'Conferma prenotazione': 'Confirmation of reservation',
    'Annulla prenotazione': 'Cancel reservation',
    'La prenotazione è stata saldata tramite il seguente metodo di pagamento': 'The reservation was paid with the following payment method',
    'Data e Ora documento': 'Document date and time',
    'Matricola Cassa': 'Cash register number',
    'Matricola Server': 'Server serial number',
    'Adulti': 'Adults',
    'Bambini': 'Children',
    'Neonati': 'Infants',
    'Anziani': 'Seniors',
    'Nazione': 'Nation',
    'Nominativo': 'Description',
    'Anagrafica': 'Entity',
    'Età': 'Age',
    'Nazionalità': 'Nationality',
    'Aggiungi': 'Add',
    'Data Partenza': 'Departure date',
    'Aggiungi sconto': 'Add coupon',
    'Aggiungi servizio': 'Add booking service',
    'Inserisci coupon': 'Add coupon code',
    'Invia': 'Send',
    'Nessun elemento presente': 'No results found',
    'Prezzo': 'Price',
    'Vai al dettaglio pacchetto': 'View package detail',
    'Escluso': 'Excluded',
    'Azioni': 'Actions',
    'Stato': 'Status',
    'Canale': 'Channel',
    'Creatore': 'Creator',
    'Utente': 'User',
    'Codice Pratica': 'Code of Practice',
    'Voucher prenotazione': 'Trip booking Voucher'
};
export var locale = {
    lang: 'en',
    data: ɵ0
};
export { ɵ0 };
