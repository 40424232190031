<div *ngIf='contentErrorMessage' mat-dialog-title fxLayout='row' fxLayoutAlign='start center'>
	<mat-icon color='warn'>error_outline</mat-icon>
	<span class='h3 ml-4 warn-fg' [innerHTML]='contentErrorMessage'></span>
</div>
<div mat-dialog-title *ngIf='!loading && !contentErrorMessage && participantsList'>
	<div class="column">
		<div class="row actions">
			<!-- switch -->
			<button (click)='switchTpl()' class="switch-data" mat-fab color="warn" aria-label="switch data">
				<ng-container [ngSwitch]='switchTemplate'>
					<ng-container *ngSwitchCase='"bookingGroupedParticipantsTemplate"'>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="auto" viewBox="0 0 40 30" matTooltip='lista partecipanti'>
							<g>
								<path fill="#fff" fill-rule="evenodd" d="M2.5 34.5A2.448 2.448 0 0 1 0 32c0-2.5 2.5-10 15-10s15 7.5 15 10a2.448 2.448 0 0 1-2.5 2.5zm12.5-15A7.5 7.5 0 1 0 7.5 12a7.5 7.5 0 0 0 7.5 7.5zm17.5 3.75A1.25 1.25 0 0 1 33.75 22h5a1.25 1.25 0 1 1 0 2.5h-5a1.25 1.25 0 0 1-1.25-1.25zm-5-7.5a1.25 1.25 0 0 1 1.25-1.25h10a1.25 1.25 0 1 1 0 2.5h-10a1.25 1.25 0 0 1-1.25-1.25zm0-7.5A1.25 1.25 0 0 1 28.75 7h10a1.25 1.25 0 1 1 0 2.5h-10a1.25 1.25 0 0 1-1.25-1.25zm5 22.5a1.25 1.25 0 0 1 1.25-1.25h5a1.25 1.25 0 1 1 0 2.5h-5a1.25 1.25 0 0 1-1.25-1.25z" transform="translate(0 -4.5)"/>
							</g>
						</svg>
					</ng-container>
					<ng-container *ngSwitchCase='"participantsListTemplate"'>
						<svg xmlns="http://www.w3.org/2000/svg" width="30" height="auto" viewBox="0 0 40 28.571" matTooltip='lista prenotazioni'>
							<g id="ticket" transform="translate(-2.25 -6.75)">
								<path id="Tracciato_3190" data-name="Tracciato 3190" d="M40.821,18.179A1.429,1.429,0,0,0,42.25,16.75V9.607A2.857,2.857,0,0,0,39.393,6.75H5.107A2.857,2.857,0,0,0,2.25,9.607V16.75a1.429,1.429,0,0,0,1.429,1.429,2.857,2.857,0,0,1,0,5.714A1.429,1.429,0,0,0,2.25,25.321v7.143a2.857,2.857,0,0,0,2.857,2.857H39.393a2.857,2.857,0,0,0,2.857-2.857V25.321a1.429,1.429,0,0,0-1.429-1.429,2.857,2.857,0,0,1,0-5.714Zm-1.429,8.386v5.9h-10V28.179H26.536v4.286H5.107v-5.9a5.714,5.714,0,0,0,0-11.057v-5.9H26.536v4.286h2.857V9.607h10v5.9a5.714,5.714,0,0,0,0,11.057Z" transform="translate(0)" fill="#fff"/>
								<path id="Tracciato_3191" data-name="Tracciato 3191" d="M21.375,14.625h2.649v7.947H21.375Z" transform="translate(5.297 2.437)" fill="#fff"/>
							</g>
						</svg>
					</ng-container>
					<ng-container *ngSwitchDefault>Cambia visualizzazione</ng-container>
				</ng-container>
			</button>
			<!-- close -->
			<button mat-mini-fab color="accent" mat-dialog-close>
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="row tour_code">
			<span>CODICE TOUR <b>{{datasetRS.record.value.package.code}}</b></span>
			<img *ngIf='datasetRS.record.value.type != "return"' src="assets/icons/material-icons/filled/going_trip_icon.svg" />
			<img *ngIf='datasetRS.record.value.type == "return"' src="assets/icons/material-icons/filled/return_trip_icon.svg" />
		</div>
		<div class="row description">
			<a [routerLink]="datasetNavigator.getDetailRoute('trip_packages', datasetRS.record.value.package.id)">
				{{datasetRS.record.value.package.description}}
			</a>
		</div>
		<div class="row address">
			<img src="assets/icons/material-icons/filled/md-pin.svg" class="place" />
			{{datasetRS.record.value.display_name}}
		</div>
		<div class="row start-departure">
			<span class="start">
				<mat-icon>access_time</mat-icon> {{datasetRS.record.value.start_date_time | date:'dd/MM/yyyy hh:mm'}}
			</span>
			<span class="participants" [class.notall]='datasetRS.record.value.participant_overall.total != datasetRS.record.value.total_participants_confirmed'>
				<span class="material-icons participants-group">group</span>
				{{datasetRS.record.value.total_participants_confirmed}}/{{datasetRS.record.value.participant_overall.total}}
			</span>
		</div>
	</div>
</div>
<mat-dialog-content>
	<ng-container [ngSwitch]='switchTemplate'>
		<ng-container *ngSwitchCase='"participantsListTemplate"'
			[ngTemplateOutlet]='participantsListTemplate'
			[ngTemplateOutletContext]='{
				loading: loading, 
				contentErrorMessage: contentErrorMessage, 
				participantsList: participantsList
			}'
		></ng-container>

		<ng-container *ngSwitchCase='"bookingGroupedParticipantsTemplate"'
			[ngTemplateOutlet]='bookingGroupedParticipantsTemplate'
			[ngTemplateOutletContext]='{
				bookings: bookings,
				contentErrorMessage: contentErrorMessage, 
				participantsList: participantsList
			}'
		></ng-container>

		<ng-container *ngSwitchDefault>Nessun template selezionato</ng-container>
	</ng-container>

	<div class="loader" *ngIf='loading'>
		<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
		<p>{{'Azione in corso...' | translate}}</p>
	</div>
</mat-dialog-content>

<ng-template #participantsListTemplate
let-loading='loading' 
let-contentErrorMessage='contentErrorMessage'
let-participantsList='participantsList'>
	<ng-container *ngIf='!loading && !contentErrorMessage && participantsList'>
		<div class="passengers">
			<div class="heading">PASSEGGERI</div>
			<ng-container *ngFor='let participant of participantsList'>
				<div class="passenger" [class.absent]='!participant.presence'>
					<div class="description_container">
						<span class="dot" [ngClass]='{
							"present": participant.presence, "absent": !participant.presence}'></span>
						<span class="description">{{participant.description}}</span>
					</div>
					<div class="booking_code_container">
						<span class="booking_code"><small>COD. PRENOTAZIONE</small> {{participant.booking_code}}</span>
						<span class="material-icons icon-passenger" [ngClass]='{
							"present": participant.presence, "absent": !participant.presence}'>person</span>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-template #bookingGroupedParticipantsTemplate>
	<ng-container *ngIf='!loading && !contentErrorMessage'>
		<div class="bookings-container">
			<ng-container *ngFor='let booking of bookings'>
				<div class="booking">
					<div class="booking-data">
						<div class="row booking-code-date">
							<div class="booking-code">COD. PRENOTAZIONE <b>{{booking.booking_code}}</b></div>
							<div class="booking_date"><mat-icon>access_time</mat-icon> {{booking.booking_date | date:'dd/MM/yyyy hh:mm'}}</div>
						</div>
	
						<div class="row generality">
							<div class="name">{{booking.intestatario.split(' ')[0]}}</div>
							<div class="surname">{{booking.intestatario.split(' ')[1]}}</div>
						</div>
	
						<div class="row contacts">
							<div class="email">{{booking.primary_email}}</div>
							<div class="phone">{{booking.phone}}</div>
						</div>
					</div>

					<div class="participants" [class.notall]='booking.presences != booking.participant_count'>
						<div *ngIf='booking.adults' class="part adults">ADT {{booking.adults}}</div>
						<div *ngIf='booking.children' class="part children">CHD {{booking.children}}</div>
						<div *ngIf='booking.seniors' class="part seniors">SNR {{booking.seniors}}</div>
						<div *ngIf='booking.infants' class="part infants">INF {{booking.infants}}</div>
						<div class="presences" [class.notall]='booking.presences != booking.participant_count'>
							<span class="material-icons participants-group">group</span>
							{{booking.presences}}/{{booking.participant_count}}
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</ng-template>
