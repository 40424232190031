import { IFieldDefinition, ValueTypes, InputTypes, FieldViews } from 'app/interfaces';
import branchesField from 'app/configs/commons/shared-field-configs/branches.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('booking_code', {
	key: 'booking_code',
	name: 'Codice Prenotazione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('receipt', {
	key: 'receipt',
	name: 'Pacchetti trasmessi',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let fieldTitle: string = "";
		if (record.transmitted_packages){
			const packages = record.transmitted_packages.split(',');
			for (const bookingPackage of packages){
				// concat package data
				fieldTitle += bookingPackage + '<br/>';
			}
		}
		return fieldTitle;
	}
});

fieldsMap.set('created_at', {
	key: 'created_at',
	name: 'Data',
	inputType: InputTypes.DATETIME,
	valueType: ValueTypes.DATETIME
});

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato transazione',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): any{
		let status = "<b>Inviato</b>";
		if (record.status == 0){
			status = "<b>Annullato</b>";
		}
		return status;
	}
});

fieldsMap.set('total', {
	key: 'total',
	name: 'Totale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): any{
		return new Intl.NumberFormat('it-IT', {style: 'currency', currency: 'EUR'}).format(record.total);
	}
});

fieldsMap.set('branch_code', cloneFieldDefinition(branchesField));

export { fieldsMap };