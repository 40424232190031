import { IFieldContainer, IFieldDefinition, IFormConfig, InputTypes, ValueTypes } from 'app/interfaces';
import { fieldsMap } from '../fields';
import { supportedLanguages } from '..';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			cloneFieldDefinition(fieldsMap.get('min_allotment'), {
				showIf(formData, dataContainerService: IDataContainerService): boolean{
					const record = dataContainerService.getValueFromKeyPath('actual_trips.record');
					return !record || record.external_synced_type !== 1;
				}
			}),
			cloneFieldDefinition(fieldsMap.get('default_max_allotment'), {
				showIf(formData, dataContainerService: IDataContainerService): boolean{
					const record = dataContainerService.getValueFromKeyPath('actual_trips.record');
					return !record || record.external_synced_type !== 1;
				}
			})
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				key: 'length',
				name: 'Durata in ore',
				inputType: InputTypes.NUMBER,
				valueType: ValueTypes.NUMBER,
				disallowNull: false,
				defaultValue(): any{
					return null;
				},
				showIf(formData, dataContainerService: IDataContainerService): boolean{
					const record = dataContainerService.getValueFromKeyPath('actual_trips.record');
					return !record || record.external_synced_type !== 1;
				}
			},
			{
				key: '_length_type', // not use for now
				name: 'Tipo durata',
				inputConfig: {
					type: InputTypes.HIDDEN
				},
				valueType: ValueTypes.STRING,
				showIf(record, dataContainerService: IDataContainerService): boolean{
					return !record || record.external_synced_type !== 1;
				}
			}
		]
	},
	cloneFieldDefinition(fieldsMap.get('start_status'), {
		showIf(formData, dataContainerService: IDataContainerService): boolean{
			const record = dataContainerService.getValueFromKeyPath('actual_trips.record');
			return !record || record.external_synced_type !== 1;
		}
	}),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				key: 'actual_trip_guide_languages',
				name: 'Lingua',
				inputType: InputTypes.MULTI_SELECT,
				valueType: ValueTypes.PROPERTY,
				options: supportedLanguages,
				width: '250px'
			},
			{
				key: 'actual_trip_audioguide_languages',
				name: 'Lingua con audioguida',
				inputType: InputTypes.MULTI_SELECT,
				valueType: ValueTypes.PROPERTY,
				options: supportedLanguages,
				width: '250px'
			}
		]
	},
	cloneFieldDefinition(fieldsMap.get('note'), {
		showIf(formData, dataContainerService: IDataContainerService): boolean{
			const record = dataContainerService.getValueFromKeyPath('actual_trips.record');
			return !record || record.external_synced_type !== 1;
		}
	})
];

const config: IFormConfig = { 
	editTitle: 'Modifica Partenza',
	fields: formFields 
};

export default config;
