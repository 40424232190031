import { Component, Input, OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { takeUntil } from 'rxjs/operators';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IFormConfig, IDatasetConfig, IStepConfig } from 'app/interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import FilterFields from './form.fields';
import { ProductsConfig } from 'app/configs/datasets/products';

@Component({
	selector   : 'product-bundle',
	templateUrl: './step.component.html',
	styleUrls  : ['../../../common.style.scss']
})
export class TripResourceTripResourcesComponent extends BaseStepViewComponent implements OnInit{

	public dynamCols: any[] = [
		{
			name: 'code',
			title: 'Codice',
			primaryKey: true,
			show: true,
		},
		{
			name: 'description',
			title: 'Descrizione',
			primaryKey: false,
			show: true,
		}
	];

	displayedColumns: string[] = ['code', 'description', 'action'];

	formConfig: IFormConfig;

	@Input() public datasetConfig: IDatasetConfig;

	public filterFormGroup: FormGroup;
	public field = FilterFields.get('product_bundle_id');

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		private formBuilder: FormBuilder){
		super(datasetACS, datasetNavigatorService);

		this.filterFormGroup = this.formBuilder.group({
			product_bundle_id: null,
		});
	}

	ngOnInit(): void {
		this.formConfig = ProductsConfig.formConfigsMap['generalForm'];
	}
}
