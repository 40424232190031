import {Component, OnInit, OnDestroy, Input, Injector, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import {FormControl} from '@angular/forms';
import {IListViewConfig, IFormConfig, IFieldContainer, IFieldDefinition, isFieldContainer, ICustomAction} from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatasetActionContainerService } from '../dataset/services/dataset-action-container.service';

@Component({
	selector   : 'dataset-filter',
	templateUrl: './data-filter.component.html',
	styleUrls  : ['./data-filter.component.scss']
})
export class DataFilterComponent implements OnInit, OnDestroy, OnChanges{

	@Input() public filtersData: any;
	@Input() public dataContainerService: DatasetActionContainerService;
	@Input() public formConfig: IFormConfig;
	@Output() public filterChange = new EventEmitter<any>();
	@Input() public showSearchText = true;
	@Input() public actionButtons: ICustomAction[];
	@Input() public loading = false;
	@Input() public filterStyle: any;

	public defaultSearchControl: FormControl;
	public filterCount = 0;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		public matDialog: MatDialog,
		public datasetNavigatorService: DatasetNavigatorService,
		protected viewContainerRef: ViewContainerRef
	){}

	ngOnInit(): void {
		this._unsubscribeAll = new Subject();
		this.defaultSearchControl = new FormControl('');

		this.defaultSearchControl.valueChanges
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {
			this.applyFilter(null);
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.filtersData && this.formConfig){
			this.filterCount = 0;
			for (const key in this.filtersData) {
				if(!Object.prototype.hasOwnProperty.call(this.filtersData, key)) continue;
				if(key === 'text-search') continue;
				if(!this.isFieldInConfig(key, this.formConfig.fields)) continue;
				const element = this.filtersData[key];
				if(element !== null && element !== undefined && element !== ''){
					this.filterCount++;
				}
			}
		}
	}

	isFieldInConfig(key: string, fields: (IFieldDefinition | IFieldContainer)[]): boolean{
		for (const field of fields) {
			if(isFieldContainer(field)){
				if(this.isFieldInConfig(key, (<IFieldContainer>field).fields)) return true;
			}else{
				if(field.key === key) return true;
			}
		}
		return false;
	}

	applyFilter(filters): void{
		const oldFilters = this.filtersData;
		let newFilters = {
			'text-search': this.defaultSearchControl.value
		};
		if (filters) {
			newFilters = Object.assign(newFilters, JSON.parse(JSON.stringify(filters)));
		}

		newFilters = Object.assign({}, oldFilters, newFilters);
		for (const key in newFilters) {
			if(!Object.prototype.hasOwnProperty.call(newFilters, key)) continue;
			if(newFilters[key] === null || newFilters[key] === undefined || newFilters[key] === ''){
				delete newFilters[key];
			}
		}
		this.filterChange.emit(newFilters);
	}

	openFilters(): void{
		this.matDialog.open(FormDialogComponent, {
			width: '700px',
			data: {
				title: 'Filtri ricerca',
				formData: this.filtersData,
				formConfig: this.formConfig,
				dataContainerService: this.dataContainerService,
				positiveText: 'Applica'
			},
			viewContainerRef: this.viewContainerRef
		})
		.afterClosed().subscribe(result => {
			if(result){
				this.applyFilter(result);
			}
		});
	}

	clearFilters(): void{
		const emptyFilters = {};
		if(this.formConfig){
			for (const key in this.filtersData) {
				if(!Object.prototype.hasOwnProperty.call(this.filtersData, key)) continue;
				if(key === 'text-search') continue;
				if(!this.isFieldInConfig(key, this.formConfig.fields)) continue;
				emptyFilters[key] = null;
			}
		}
		this.defaultSearchControl.setValue(null);
		this.applyFilter(emptyFilters);
	}

	indexTrackBy(index, item): any{
		return index;
	}
}
