import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

function showProvinceSelect(record: any, dataContainerService?: IDataContainerService){
	if(!record) return false;
	if(record['primary_address.nation_code'] !== 'IT') return false;
	if(record['primary_address.province'] && record['primary_address.province'].length > 2) return false;
	if(dataContainerService && dataContainerService.getValueFromKeyPath('entities.record.primary_address')) return false;
	return true;
}

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('primary_address.street'),
			cloneFieldDefinition(fieldsMap.get('primary_address.number'), {
				width: '100px'
			}),
		]
	},
	
	fieldsMap.get('primary_address.locality'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			cloneFieldDefinition(fieldsMap.get('primary_address.zip_code'), {
				width: '100px'
			}),
			fieldsMap.get('primary_address.city'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			cloneFieldDefinition(fieldsMap.get('primary_address.province'), {
				computedDefinition(formRawData: any, dataContainerService?: IDataContainerService){
					if(showProvinceSelect(formRawData, dataContainerService)){
						return fieldsMap.get('primary_address.province_select');
					}else{
						return fieldsMap.get('primary_address.province');
					}
				}
			}),
			fieldsMap.get('primary_address.region'),
		]
	},
	fieldsMap.get('primary_address.nation_code'),
	fieldsMap.get('primary_address.note'),
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
