import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import * as _ from 'lodash';
import { AppService } from '../../../../../services/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var TrenitaliaSeatsComponent = /** @class */ (function () {
    function TrenitaliaSeatsComponent(appService, trnService) {
        this.appService = appService;
        this.trnService = trnService;
        this.nextTrainStep = false;
        this.prevTrainStep = false;
        this._unsubscribeAll = new Subject;
    }
    TrenitaliaSeatsComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TrenitaliaSeatsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trnService.scrollToTop();
        this.trnService.total
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (total) {
            _this.total = total;
        });
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.checkoutData = data;
            if (_this.checkoutData.selectedSolution && !Array.isArray(_this.checkoutData.selectedSolution.travellers)) {
                _this.checkoutData.selectedSolution.travellers = [_this.checkoutData.selectedSolution.travellers];
            }
            _this.passengers = _this.checkoutData.travellers;
            if (!_this.trainId)
                _this.trainId = _.get(_this.checkoutData, 'selectedSolution.trains[0].id');
            if (_this.checkoutData.seats && _this.checkoutData.selection) {
                _this.seatsData = _this.checkoutData.seatMaps;
                _this.checkoutData.selectedSolution.trains.forEach(function (t) {
                    if (_this.seatsData && !_.has(_this.seatsData[t.id], 'seats'))
                        _this.seatsData[t.id].seats = 0;
                });
            }
            if (_.has(_this.checkoutData, 'selectedSolution')) {
                _this.nextTrainStep = _this.checkoutData.selectedSolution.trains.length - 1 > _this.trainStep;
            }
            _this.trnService.scrollToTop();
        });
        this.trnService.trainSeatStep
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (step) {
            _this.trainStep = step;
            _this.trainId = _.get(_this.checkoutData, ['selectedSolution', 'trains', _this.trainStep, 'id']);
            _this.nextTrainStep = _this.checkoutData.selectedSolution.trains.length - 1 > _this.trainStep;
            _this.prevTrainStep = _this.trainStep > 0;
        });
        // @ts-ignore
        window.unselectSeat = function (seatNum) {
            var traveller = _this.checkoutData.selectedSolution.travellers.find(function (t) {
                return _.get(_this.seatsData, [_this.trainId, 'travellers', t.xmlId, 'seat', 'seatNum']) == seatNum
                    && _.get(_this.seatsData, [_this.trainId, 'travellers', t.xmlId, 'seat', 'wagon']) == _this.wagonSel;
            });
            if (!traveller) {
                // refresh iframes?
                return;
            }
            delete _this.seatsData[_this.trainId].travellers[traveller.xmlId].seat;
            _this.seatSelected({ value: -1, pass: traveller.xmlId, train: _this.trainId });
        };
        // @ts-ignore
        window.normalNavigation = true;
        // @ts-ignore
        window.selectSeat = function (seatNum, url, seatToShow) {
            // first traveller without a seat
            var traveller = _this.checkoutData.selectedSolution.travellers.find(function (t) {
                return !_.has(_this.seatsData, [_this.trainId, 'travellers', t.xmlId, 'seat']);
            });
            if (!traveller) {
                // no seat to set
                return;
            }
            _this.seatsData[_this.trainId].travellers[traveller.xmlId].seat = {
                seatNum: seatNum,
                seatNumToShow: seatToShow,
                wagon: _this.wagonSel,
                train: _this.trainId
            };
            _this.seatSelected({ value: 1, pass: traveller.xmlId, train: _this.trainId });
        };
        // @ts-ignore
        window.onWagonIframeLoaded = function (wagonId, doc, highlight) {
            var e_1, _a;
            try {
                for (var _b = tslib_1.__values(_this.checkoutData.selectedSolution.travellers), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var traveller = _c.value;
                    if (_.get(_this.seatsData, [_this.trainId, 'travellers', traveller.xmlId, 'seat', 'wagon']) == wagonId) {
                        highlight(_.get(_this.seatsData, [_this.trainId, 'travellers', traveller.xmlId, 'seat', 'seatNum']));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.trnService.scrollToTop();
        };
    };
    TrenitaliaSeatsComponent.prototype.seatSelected = function (e) {
        var e_2, _a;
        var _this = this;
        // recalc seat selection counters
        var totalSeatSetted = 0;
        var _loop_1 = function (train) {
            this_1.seatsData[train.id].seats = this_1.checkoutData.selectedSolution.travellers.filter(function (t) {
                return _.has(_this.seatsData, [train.id, 'travellers', t.xmlId, 'seat', 'seatNum']);
            }).length;
            totalSeatSetted += this_1.seatsData[train.id].seats;
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.checkoutData.selectedSolution.trains), _c = _b.next(); !_c.done; _c = _b.next()) {
                var train = _c.value;
                _loop_1(train);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.checkoutData.seatSel = totalSeatSetted;
        // todo: use a recalc methods
        if (_.has(this.seatsData, e.train + '.travellers.' + e.pass + '.prices.amount')) {
            /*let total =this.trnService.total.getValue();*/
            var price = +_.get(this.seatsData, e.train + '.travellers.' + e.pass + '.prices.amount');
            if (e.value == 1) {
                if (this.total['fee'] > 0) {
                    this.total['valueNet'] += price;
                }
                else {
                    this.total['value'] += price;
                }
            }
            else {
                if (this.total['fee'] > 0) {
                    this.total['valueNet'] -= price;
                }
                else {
                    this.total['value'] -= price;
                }
            }
            if (this.total['fee'] > 0 && totalSeatSetted == this.checkoutData.seatsCount) {
                var data = {
                    travellers: this.checkoutData.travellers,
                    solution: this.checkoutData.dataAv.travelSolutions,
                    type: this.checkoutData.mode == 'travel' ? 'sale' : 'post-sale',
                    totalNet: this.total['valueNet']
                };
                this.trnService.getFee(data).subscribe(function (fee) {
                    _this.total['valueNet'] = fee['valueNet'];
                    _this.total['fee'] = fee['fee'];
                    _this.total['value'] = fee['value'];
                    _this.trnService.total.next(_this.total);
                    _this.checkoutData.totalSelectedSolution = _this.total;
                    _this.trnService.checkoutData.next(_this.checkoutData);
                });
            }
            this.trnService.total.next(this.total);
        }
        this.trnService.checkoutData.next(this.checkoutData);
    };
    TrenitaliaSeatsComponent.prototype.onTrainChanged = function (event) {
        this.trnService.trainSeatStep.next(event.index);
    };
    TrenitaliaSeatsComponent.prototype.setWagon = function (e) {
        this.wagonSel = e;
    };
    TrenitaliaSeatsComponent.prototype.changeStepTrain = function (num) {
        this.trnService.trainSeatStep.next(this.trainStep + num);
    };
    return TrenitaliaSeatsComponent;
}());
export { TrenitaliaSeatsComponent };
