<h1 mat-dialog-title>{{data.title?data.title:'Conferma'}}</h1>
<mat-dialog-content>
	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>

	<div [innerHTML]="data.contentMessage"></div>

	<form [formGroup]="form">
		<mat-form-field style="display:block;">
			<mat-label>{{(data.reasonLabel || "Motivazione") | translate}}</mat-label>
			<textarea matInput formControlName="reason"></textarea>
			<mat-hint>{{(data.reasonHint || "Motiva questa azione") | translate}}</mat-hint>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions align='end'>
	<button mat-button (click)="onNoClick()">{{data.negativeText?data.negativeText:'No' | translate}}</button>
	<button mat-button *ngIf='data.neutralText' (click)="onNeutralClick()">{{data.neutralText | translate}}</button>
	<button mat-flat-button [disabled]='!form.valid' (click)='onYesClick()' color='accent' >{{data.positiveText?data.positiveText:'Si' | translate}}</button>
</mat-dialog-actions>
