/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../data-viewer/container/data-container-viewer.component.ngfactory";
import * as i2 from "../../data-viewer/container/data-container-viewer.component";
import * as i3 from "./step.component";
import * as i4 from "../../../../../../services/app.service";
import * as i5 from "../../../services/dataset-record.service";
import * as i6 from "../../../services/dataset-action-container.service";
import * as i7 from "../../../services/dataset-navigator.service";
import * as i8 from "../../../services/dataset.service";
var styles_CommonDataViewerComponent = [];
var RenderType_CommonDataViewerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommonDataViewerComponent, data: {} });
export { RenderType_CommonDataViewerComponent as RenderType_CommonDataViewerComponent };
export function View_CommonDataViewerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "data-container-viewer", [], null, null, null, i1.View_DataContainerViewerComponent_0, i1.RenderType_DataContainerViewerComponent)), i0.ɵdid(1, 180224, null, 0, i2.DataContainerViewerComponent, [], { fields: [0, "fields"], dataContainerService: [1, "dataContainerService"], record: [2, "record"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepConfig.options.fields; var currVal_1 = _co.datasetACS; var currVal_2 = _co.datasetRS.record.value; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CommonDataViewerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "common-data-viewer-step", [], null, null, null, View_CommonDataViewerComponent_0, RenderType_CommonDataViewerComponent)), i0.ɵdid(1, 4440064, null, 0, i3.CommonDataViewerComponent, [i4.AppService, i5.DatasetRecordService, i6.DatasetActionContainerService, i7.DatasetNavigatorService, i8.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommonDataViewerComponentNgFactory = i0.ɵccf("common-data-viewer-step", i3.CommonDataViewerComponent, View_CommonDataViewerComponent_Host_0, { stepper: "stepper", stepIndex: "stepIndex", stepConfig: "stepConfig", hasNext: "hasNext", hasPrevious: "hasPrevious" }, { nextStep: "nextStep", prevStep: "prevStep" }, []);
export { CommonDataViewerComponentNgFactory as CommonDataViewerComponentNgFactory };
