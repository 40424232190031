/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/tooltip";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/common";
import * as i10 from "./input.component";
import * as i11 from "../../form-input-viewer.service";
import * as i12 from "../../../dataset/services/dataset.service";
var styles_ReadOnlyInputComponent = [];
var RenderType_ReadOnlyInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReadOnlyInputComponent, data: {} });
export { RenderType_ReadOnlyInputComponent as RenderType_ReadOnlyInputComponent };
function View_ReadOnlyInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "info-value"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.computedValue; _ck(_v, 1, 0, currVal_0); }); }
function View_ReadOnlyInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "info-field readonly-field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 3, "span", [["class", "info-head"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 212992, null, 0, i1.MatTooltip, [i2.Overlay, i0.ElementRef, i3.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i1.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i6.Directionality], [2, i1.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i7.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReadOnlyInputComponent_2)), i0.ɵdid(8, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputService.formInputDefinition.titleTooltip; _ck(_v, 3, 0, currVal_0); var currVal_2 = !_co.loading; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.inputService.computedTitle)); _ck(_v, 4, 0, currVal_1); }); }
export function View_ReadOnlyInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReadOnlyInputComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.inputService.hidden && _co.inputService.formInputDefinition) && _co.show); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ReadOnlyInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "readonly-input", [], null, null, null, View_ReadOnlyInputComponent_0, RenderType_ReadOnlyInputComponent)), i0.ɵdid(1, 245760, null, 0, i10.ReadOnlyInputComponent, [i11.FormInputViewerService, i12.DatasetService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReadOnlyInputComponentNgFactory = i0.ɵccf("readonly-input", i10.ReadOnlyInputComponent, View_ReadOnlyInputComponent_Host_0, {}, {}, []);
export { ReadOnlyInputComponentNgFactory as ReadOnlyInputComponentNgFactory };
