import { FuseNavigation } from '@fuse/types';

/**
 * @deprecated use menu-items for build menu with the user permission
 */
export const adminNavigation: FuseNavigation[] = [
	{
		id       : 'home',
		title    : 'Dashboard',
		translate: 'NAV.HOME.TITLE',
		type     : 'item',
		icon     : 'home',
		url      : 'home',
	},
	{
		id       : 'reviews',
		title    : 'Recensioni',
		translate: 'NAV.REVIEWS.TITLE',
		type     : 'item',
		icon     : 'star_border',
		url      : 'dataset/list/reviews',
	},
	{
		id       : 'bookings',
		title    : 'Prenotazioni',
		translate: 'NAV.BOOKINGS.TITLE',
		type     : 'collapsable',
		icon     : 'book',
		children: [
			{
				id       : 'new_booking',
				title    : 'Nuova Prenotazione',
				translate: 'NAV.BOOKINGS.NEWBOOKING.TITLE',
				type     : 'item',
				icon     : 'add',
				url      : 'new-booking',
			},
			{
				id       : 'booking_list',
				title    : 'Prenotazioni',
				translate: 'NAV.BOOKINGS.TITLE',
				type     : 'item',
				icon     : 'view_list',
				url      : 'dataset/list/trip_bookings',
			}
		]
	},
	{
		id       : 'calendar',
		title    : 'Calendario',
		translate: 'NAV.CALENDAR.TITLE',
		type     : 'item',
		icon     : 'today',
		url      : 'calendar',
	},
	{
		id       : 'users_and_permissions',
		title    : 'Definizioni',
		type     : 'group',
		icon     : 'assignment_ind',
		children: [
			{
				id       : 'packages',
				title    : 'Pacchetti',
				type     : 'item',
				icon     : 'pages',
				url      : 'dataset/list/trip_packages',
			},
			{
				id       : 'resources',
				title    : 'Risorse',
				translate: 'NAV.RESOURCES.TITLE',
				type     : 'item',
				icon     : 'directions_bus',
				url      : 'dataset/list/trip_resources',
			},
			{
				id       : 'meeting_points',
				title    : 'Punti di ritrovo',
				type     : 'item',
				icon     : 'place',
				url      : 'dataset/list/meeting_points',
			},
			{
				id       : 'trip_destinations',
				title    : 'Destinazioni',
				type     : 'item',
				icon     : 'explore',
				url      : 'dataset/list/trip_destinations',
			},
			{
				id       : 'trip_categories',
				title    : 'Categorie',
				type     : 'item',
				icon     : 'place',
				url      : 'dataset/list/trip_categories',
			},
			{
				id       : 'trip_facilities',
				title    : 'Servizi Pacchetto',
				type     : 'item',
				icon     : 'view_agenda',
				url      : 'dataset/list/trip_facilities',
			},
			/*{
				id       : 'trip_structures',
				title    : 'Strutture',
				type     : 'item',
				icon     : 'business',
				url      : 'dataset/list/trip_structures',
			}*/
		]
	},
	{
		id       : 'services_and_prices',
		title    : 'Servizi & Prezzi',
		type     : 'collapsable',
		icon     : 'event',
		children: [
			{
				id       : 'trip_service_rules',
				title    : 'Criteri',
				type     : 'item',
				// icon     : 'star_border',
				url      : 'dataset/list/trip_service_rules',
			},
			{
				id       : 'services',
				title    : 'Servizi',
				type     : 'item',
				// icon     : 'star_border',
				url      : 'dataset/list/services',
			},
			{
				id       : 'discount_rule_lists',
				title    : 'Listini Sconti',
				type     : 'item',
				// icon     : 'star_border',
				url      : 'dataset/list/discount_rule_lists',
			}
		]
	},
	{
		id       : 'anagrafiche',
		title    : 'Anagrafiche',
		type     : 'collapsable',
		icon     : 'group',
		children: [
			{
				id       : 'entity_customers',
				title    : 'Clienti',
				type     : 'item',
				icon     : 'person',
				url      : 'dataset/list/entity_customers',
			},
			{
				id       : 'entity_suppliers',
				title    : 'Fornitori',
				type     : 'item',
				icon     : 'person',
				url      : 'dataset/list/entity_suppliers',
			},
			{
				id       : 'entity_tour_operators',
				title    : 'Tour Operator',
				type     : 'item',
				icon     : 'person',
				url      : 'dataset/list/entity_tour_operators',
			},
			{
				id       : 'entity_staff_elements',
				title    : 'Staff',
				type     : 'item',
				icon     : 'person',
				url      : 'dataset/list/entity_staff_elements',
			},
			{
				id       : 'entities',
				title    : 'Tutte',
				type     : 'item',
				icon     : 'person',
				url      : 'dataset/list/entities',
			},
		]
	},
	{
		id       : 'users_and_permissions',
		title    : 'Utenti & Permessi',
		type     : 'group',
		icon     : 'assignment_ind',
		children: [
			{
				id       : 'users',
				title    : 'Utenti',
				type     : 'item',
				icon     : 'group',
				url      : 'dataset/list/users',
			},
			{
				id       : 'user_roles',
				title    : 'Ruoli',
				type     : 'item',
				icon     : 'recent_actors',
				url      : 'dataset/list/user_roles',
			}
		]
	},
];
