import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as addressFieldMap} from '../unified_addresses/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { showIfEAgency } from 'app/configs/commons/shared-field-configs/show-if-eagency.field';

function exportEAgency(record: any, dataContainerService: IDataContainerService): boolean{
	if(!record) return false;
	if(!record.customer_data) return false;
	return showIfEAgency(record, dataContainerService);
}

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Ragione Sociale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'Ragione sociale'
});

fieldsMap.set('primary_email', {
	key: 'primary_email',
	name: 'Email',
	inputType: InputTypes.EMAIL,
	valueType: ValueTypes.STRING,
	inputHint: 'Email di riferimento',
	exportEAgency: exportEAgency
});

fieldsMap.set('pec', {
	key: 'pec',
	name: 'PEC',
	inputType: InputTypes.EMAIL,
	valueType: ValueTypes.STRING,
	inputHint: 'PEC di riferimento'
});

fieldsMap.set('sdi', {
	key: 'sdi',
	name: 'SDI',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Codice Sistema di Interscambio'
});

fieldsMap.set('accounting_email', {
	key: 'accounting_email',
	name: 'Email Contabile',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.TEXT_LIST,
		hint: 'Se vuota sarà usata l\'email principale'
	},
	showIf: showIfEAgency,
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('operational_email', {
	key: 'operational_email',
	name: 'Email operativa',
	valueType: ValueTypes.ARRAY,
	inputConfig: {
		type: InputTypes.TEXT_LIST,
		hint: 'Se vuota sarà usata l\'email principale'
	},
	showIf: showIfEAgency,
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('website_url', {
	key: 'website_url',
	name: 'Sito Web',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('piva', {
	key: 'piva',
	name: 'Partita IVA',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale',
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('fiscal_code', {
	key: 'fiscal_code',
	name: 'Codice Fiscale',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale',
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('table_view_code', {
	key: 'table_view_code',
	name: 'Codici',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record: any): string{
		let html = '';
		const count = Number(Boolean(record.customer_data)) + Number(Boolean(record.supplier_data)) 
		+ Number(Boolean(record.staff_element_data)) + Number(Boolean(record.tour_operator_data));
		if(count === 0){
			html = record.code;
		}else{
			if(record.customer_data){
				if(count > 1){
					html += '<span>Cliente: ' + record.customer_data.code + '</span><br>';
				}else{
					html = record.customer_data.code;
				}
			}
			if(record.supplier_data){
				if(count > 1){
					html += '<span>Fornitore: ' + record.supplier_data.code + '</span><br>';
				}else{
					html = record.supplier_data.code;
				}
			}
			if(record.staff_element_data){
				if(count > 1){
					html += '<span>Staff: ' + record.staff_element_data.code + '</span><br>';
				}else{
					html = record.staff_element_data.code;
				}
			}
			if(record.tour_operator_data){
				if(count > 1){
					html += '<span>Tour Operator: ' + record.tour_operator_data.code + '</span><br>';
				}else{
					html = record.tour_operator_data.code;
				}
			}
		}
		return html;
	}
});

fieldsMap.set('natural_person', {
	key: 'natural_person',
	name: 'Persona fisica',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	inputHint: 'Se è una persona fisica',
	defaultValue: () => 0,
	options: [
		{
			label: 'No',
			value: 0
		},
		{
			label: 'Si',
			value: 1
		}
	],
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('firstname', {
	key: 'firstname',
	name: 'Nome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome persona fisica',
	showIf(record): boolean{
		return record && record.natural_person === 1;
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('lastname', {
	key: 'lastname',
	name: 'Cognome',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Cognome persona fisica',
	showIf(record): boolean{
		return record && record.natural_person === 1;
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('birth_day', {
	key: 'birth_day',
	name: 'Data di nascita',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	inputHint: 'Data di nascita persona fisica',
	showIf(record): boolean{
		return record && record.natural_person === 1;
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('birth_location', {
	key: 'birth_location',
	name: 'Luogo di nascita',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Luogo di nascita persona fisica',
	showIf(record): boolean{
		return record && record.natural_person === 1;
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('gender', {
	key: 'gender',
	name: 'Sesso',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputHint: 'Sesso persona fisica',
	options: [
		{
			label: 'Maschio',
			value: 'M'
		},
		{
			label: 'Femmina',
			value: 'F'
		},
		{
			label: 'Non specificato',
			value: '-'
		}
	],
	showIf(record): boolean{
		return record && record.natural_person === 1;
	},
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});



fieldsMap.set('create_user', {
	key: 'create_user',
	name: 'Crea utente',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputHint: 'Crea un utente con credenziali, associato a questa anagrafica',
	defaultValue: () => 0,
	options: [
		{
			label: 'No',
			value: 0,
		},
		{
			label: 'Si',
			value: 1
		}
	]
});
fieldsMap.set('user.username', {
	key: 'user.username',
	name: 'Nome utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome utente visualizzato'
});

fieldsMap.set('user.password', {
	key: 'user.password',
	name: 'Luogo di nascita',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Luogo di nascita persona fisica'
});

fieldsMap.set('phone_number', {
	key: 'phone_number',
	name: 'Numero telefonico',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	exportEAgency(record, dataContainerService): boolean{
		return showIfEAgency(record, dataContainerService);
	}
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.PROPERTY
});

addressFieldMap.forEach(function(value, key, map): void{
	value = Object.assign({}, value, { key: 'primary_address.' + value.key });
	fieldsMap.set('primary_address.' + key, value);
});

fieldsMap.set('primary_address.province_select', {
	key: 'primary_address.province',
	name: 'Provincia',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		hint: 'Opzionale'
	},
	datasetCode: 'select_options',
	remoteOptions: {
		supportCreate: false,
		limit: 20,
		getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
			return {
				options_code: 'provinces',
				column_name: 'code',
				nation_code: record['primary_address.nation_code']
			};	
		},
		getLabel(option): string{
			if(!option) return '';
			return '(' + option.value + ')' + option.label;
		},
		getValue(option): string{
			if(!option) return '';
			return option.value;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.value + ')' + option.label;
		}
	}
});

fieldsMap.set('identity_document_type', {
	key: 'identity_document_type',
	name: 'Documento di Identità',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	inputHint: 'Documento identità',
	options: [
		{
			label: 'Carta di Identità',
			value: 'identity_card'
		},
		{
			label: 'Passaporto',
			value: 'passport'
		},
		{
			label: 'Patente',
			value: 'driving_license'
		}
	],
	showIf(record): boolean{
		return record && record.natural_person === 1;
	}
});

fieldsMap.set('identity_document_number', {
	key: 'identity_document_number',
	name: 'Numero documento di identità',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	showIf(record): boolean{
		return record && record.natural_person === 1;
	}
});

fieldsMap.set('country_doc_issue', {
	key: 'country_doc_issue',
	name: 'Paese di emissione del documento',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'nations',
	inputHint: 'Seleziona la nazione',
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	},
	showIf(record): boolean{
		return record && record.natural_person === 1;
	}
});

fieldsMap.set('doc_issue_date', {
	key: 'doc_issue_date',
	name: 'Data emissione documento',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.STRING,
	showIf(record): boolean{
		return record && record.natural_person === 1;
	}
});

fieldsMap.set('identity_document_expiration_date', {
	key: 'identity_document_expiration_date',
	name: 'Scadenza documento di identità',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.STRING,
	showIf(record): boolean{
		return record && record.natural_person === 1;
	}
});

export {fieldsMap};
