<!--<h2 class="addNew">
	Cerca una destinazione o aggiungine una nuova 
	<span class="material-icons" matTooltip="Aggiungi destinazione" (click)='createNewDatasetRow()'>add</span>
</h2>-->

<form autocomplete="off"
	name='form' 
	*ngIf='filterFormGroup' 
	[formGroup]="filterFormGroup" 
	fxLayout="column" 
	fxLayoutAlign="start"
	fxLayoutAlign.lt-lg='stretch'>

	<div *ngIf='!resellView' class="base-textfield base-textfield-large">
		<form-input-viewer
			[formInputDefinition]='field'
			[formGroup]='filterFormGroup'
			[dataContainerService]='datasetACS'
			[formControlKey]='field.key'
		></form-input-viewer>
	</div>

	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<mat-table #mytable [hidden]='dataSource.data.length === 0' [dataSource]="dataSource" class="mat-elevation-z8">
		<ng-container *ngFor='let col of dynamCols'>
			<ng-container [matColumnDef]='col.name'>
				<mat-header-cell *matHeaderCellDef [class.hide]='!col.show'> 
					{{col.title}} 
				</mat-header-cell>
				<mat-cell *matCellDef="let row" [class.hide]='!col.show' [innerHtml]='row[col.name] | renderColumn:col.valueType'></mat-cell>
			</ng-container>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
			<mat-cell *matCellDef="let row" class="align-end">
				<span *ngIf='!resellView' class="material-icons" (click)='remove(row)'>
					close
				</span>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
	</mat-table>

	<div *ngIf='dataSource.data.length == 0 && resellView'>
		<h3>Nessun record presente!</h3>
	</div>

	<div class="save-button-container" *ngIf='!resellView'>
		<button 
			[disabled]='rowsAdded.length === 0 && rowsRemoved.length === 0' 
			class="gyg-button gyg-button-medium gyg-button-cta-light" 
			(click)='onSave()'
		>
			<span>Salva e continua</span>
		</button>
	</div>
</form>