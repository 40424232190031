import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import participantFormConfig from './forms/participant.from';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { BOOKING_SERVICE_TOKEN } from 'app/configs/commons/provide-tokens';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_booking_participants/:recordId',
};

export const TripBookingParticipantConfig: IDatasetConfig = {
	name: 'trip_booking_participants',
	title: 'Partecipanti',
	singleTitle: 'Partecipante',
	ajaxDatasetCode: 'trip_booking_participants',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		participantFormConfig
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: participantFormConfig,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.trip_booking_id = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
				
				return params;
			},
			viewConfig: {
				formConfig: participantFormConfig
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: participantFormConfig
			},
			beforeSave(dataContainerService: IDataContainerService, recordId: string, data: any){
				const bookingService: any = dataContainerService.injector.get(BOOKING_SERVICE_TOKEN, null);
				if(!bookingService) return of(data);
				return bookingService.execPreAction('trip_booking_participants:update').pipe(
					map(() => {
						return data
					})
				);
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'description',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nominativo',
						key: 'description'
					},
					{
						title: 'Contatti',
						key: 'entity_id',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							if (record.entity){
								let content = "<div style='display: flex; flex-direction: column; gap: 3px;'>";
								if(record.entity.phone_number){
									content += "<span>" + record.entity.phone_number + "</span>";
								}
								if(record.entity.primary_email){
									content += "<span>" + record.entity.primary_email + "</span>";
								}
								content += "</div>";
								return content;
							}
							return '';
						}
					},
					{
						title: 'Età',
						key: 'age_range_code'
					},
					{
						title: 'Nazionalità',
						key: 'nation_code',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record, value: string): string{
							let html = '';
							if(value){
								const alt = record.nation && record.nation.description || value;
								html += '<img width="24" class="flag-icon" src="assets/images/flags/4x3/' + value.toLowerCase() + '.svg" alt="' + alt + '" title="' + alt + '" />';
								if(record.nation && record.nation.description) html += '&nbsp;' + record.nation.description;
							}
							return html;
						}
					}
					/*{
						title: 'Contatti',
						key: 'contatti',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record, value: string): string{
							if(!record || !record.entity) return '';
							let html = record.entity.primary_email;
							if(record.entity.phone_number){
								html += '<br/>' + record.entity.phone_number;
							}
							return html;
						}
					}*/
				],
				actions: ['create', 'edit', 'delete'],
				customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'pageview',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							if(!record || !record.entity_id) return;
							datasetNavigatorService.showDatasetDetail('entities', null, record.entity_id);
						},
						showIf(record: any): boolean{ return record.entity_id != null }
					}
				]
			}
		}
	}
};
