import {Component, Inject, ViewChild, ViewContainerRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {exhaustMap, takeUntil, tap} from 'rxjs/operators';

import {AppService} from 'app/services/app.service';
import {BaseDatasetActionDialog, IDialogData} from './../../base-action-dialog.component';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService, getDatasetConfigAction } from 'app/main/components/dataset/services/dataset-action-container.service';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';

export interface IDeleteDialogData extends IDialogData{
	recordId: string;
	record: any;
	message?: string;
}

@Component({
	selector: 'dataset-delete-dialog',
	templateUrl: 'dataset-delete-dialog.component.html',
	styleUrls: ['dataset-delete-dialog.component.scss'],
	providers: [DatasetActionContainerService, PageClosureContainerService]
})
export class DatasetDeleteDialogComponent extends BaseDatasetActionDialog{

	actionCode = 'delete';
	message = 'Sei sicuro di voler cancellare questo record?';
	deleteError: any;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public appService: AppService,
		public datasetService: DatasetService,
		public dialogRef: MatDialogRef<DatasetDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDeleteDialogData,
		protected viewContainerRef: ViewContainerRef
	) {
		super(datasetACS, appService, datasetService, dialogRef, data);
		if(data.message){
			this.message = data.message;
		}
	}

	execDelete(data?: any){
		this.loading = true;

		return this.datasetService.delete(this.datasetACS.getDeleteRoute(this.data.record), data)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response) => {
				this.loading = false;
				this.appService.showSuccessMessage('Eliminato con successo');
				this.dialogRef.close(true);
			},
			error: err => {
				this.deleteError = err;
				this.loading = false;
			}
		});
	}

	onDelete(): void{
		this.loading = true;
		const actionConfig = getDatasetConfigAction(this.datasetACS.datasetCode, 'edit');
		if(actionConfig && typeof(actionConfig.beforeDelete) === 'function'){
			actionConfig.beforeDelete(this.datasetACS, this.data.record, this.viewContainerRef)
			.pipe(takeUntil(this._unsubscribeAll))
			.pipe(exhaustMap((data) => {
				return this.datasetService.delete(this.datasetACS.getDeleteRoute(this.data.record), data)
			}))
			.subscribe({
				next: (response) => {
					this.loading = false;
					this.appService.showSuccessMessage('Eliminato con successo');
					this.dialogRef.close(true);
				},
				error: err => {
					this.deleteError = err;
					this.loading = false;
				},
				complete: () => {
					this.loading = false;
					this.dialogRef.close(true);
				}
			});
		}else{
			this.datasetService.delete(this.datasetACS.getDeleteRoute(this.data.record))
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: (response) => {
					this.loading = false;
					this.appService.showSuccessMessage('Eliminato con successo');
					this.dialogRef.close(true);
				},
				error: err => {
					this.deleteError = err;
					this.loading = false;
				}
			});
		}
	}
}
