import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import infoForm from './forms/info.forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { TranslateService } from '@ngx-translate/core';
import filterForm from './forms/filter.form';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domains/detail/:recordId',
	params: {
		with_attributes: 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client,oauth_clients_count',
		with_relations: 'entity'
	}
};

export const DomainsInfoConfig: IDatasetConfig = {
	name: 'domains',
	title: 'Domini',
	singleTitle: 'Dominio',
	ajaxDatasetCode: 'domains',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: function(data: any): boolean {
			return data && data.user && data.user.role && data.user.role.level < 10;
		},
		edit: true,
		list: true
	},
	fetchConfig: {
		with_attributes: 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client',
		with_relations: 'entity'
	},
	formConfigsMap: {
		generalForm,
		editForm,
		detailForm,
		infoForm,
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			},
			getCallParams(dataContainerService: DatasetActionContainerService, parentData: any, params: any): any{
				dataContainerService.putDatasetData('domainId', dataContainerService.getValueFromKeyPath('domains.recordId'));
				return params;
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			}
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_attributes = 'domain_conf,has_domain_configs,id_domain_configs,father,logo_img,expanson_panel_permission,client,oauth_clients_count';
				params.with_relations = 'entity';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				filterForm: filterForm,
				defaultSortDirection: 'asc',
				columns: [
					/*
					{
						title: 'Codice',
						key: 'code'
					},
					*/
					{
						title: 'Nome',
						key: 'display_name'
					},
					{
						title: 'Gerarchia',
						key: 'domain_path'
					},
					{
						title: 'Stato',
						key: 'status',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(record.enabled === 1){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Generazione Pratiche',
						key: 'generate_pratiche',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.app && record.domain_conf.app.generate_pratiche_record;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Export Zucchetti',
						key: 'enable_export_zucchetti',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.app && record.domain_conf.app.enable_export_zucchetti;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'GYG',
						key: 'enable_gyg',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.touroperators && record.domain_conf.touroperators.gyg.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Viator',
						key: 'enable_viator',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.touroperators && record.domain_conf.touroperators.viator.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Tour CMS',
						key: 'enable_tour_cms',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.touroperators && record.domain_conf.touroperators['tour-cms'].enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Bokun',
						key: 'enable_bokun',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.touroperators && record.domain_conf.touroperators.bokun.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Kiara Cloud',
						key: 'enable_kiara_cloud',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.modules && record.domain_conf.modules.kiara_cloud_api && record.domain_conf.modules.kiara_cloud_api.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Eagency',
						key: 'enable_eagency',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.modules && record.domain_conf.modules.eagency && record.domain_conf.modules.eagency.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Fatture Cloud',
						key: 'enable_fatture_in_cloud',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.modules && record.domain_conf.modules.fatture_in_cloud && record.domain_conf.modules.fatture_in_cloud.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Ticketing',
						key: 'enable_ticketing',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							const status = record.domain_conf && record.domain_conf.modules && record.domain_conf.modules.ticketing && record.domain_conf.modules.ticketing.enabled;
							let colorClass = 'red-bg';
							let text = '<span class="status">' +  translateService.instant('Non Attivo') + '</span>';
							if(status){ 
								colorClass = 'green-bg';
								text = '<span class="status">' + translateService.instant('Attivo') + '</span>';
							}
							let html = '<div class="package_status" style="display:flex;align-items: center;">';
							html += '<span class="' + colorClass + ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
							html += text;
							html += '</div>';
							return html;
						}
					},
					{
						title: 'Oauth clients',
						key: 'oauth_clients',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any, value: any, translateService: TranslateService): any{
							return record.oauth_clients_count;
						}
					},
				],
				actions: ['detail'],
			}
		}
	}
};
