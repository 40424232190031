import { NgModule } from '@angular/core';

import { EditBookingPageComponent } from './edit-booking.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EditBookingStep0Component } from './step-0/view.component';
import { FormViewerModule } from 'app/main/components/form-viewer/form-viewer.module';
import { EditBookingStep1Component } from './step-1/view.component';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { DatasetModule } from 'app/main/components/dataset/dataset.module';
import { EditBookingStep2Component } from './step-2/view.component';
import { TripBookingPackageComponent } from './step-2/trip-booking-packages/trip-booking-package.component';
import { TripBookingTicketComponent } from './step-2/trip-booking-tickets/trip-booking-package.component';
import { TripBookingResourceComponent } from './step-2/trip-booking-resources/trip-booking-resource.component';
import { EditBookingStep3Component } from './step-3/view.component';
import { ActionDialogModule } from './action-dialog/action-dialog.module';
import { FormDialogsModule } from 'app/main/dialogs/form-dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverModule } from 'app/main/dialogs/popover/popover.module';
import { TripBookingServiceComponent } from './step-2/trip-booking-services/trip-booking-service.component';
import { TripBookingParticipantComponent } from './step-2/trip-booking-participants/trip-booking-participant.component';
import { TripBookingStepperComponent } from './trip-booking-stepper/trip-booking-stepper.component';
import { TripBookingSummaryComponent } from './trip-booking-summary/trip-booking-summary.component';
import { TripBookingPackageResourcesComponent } from './step-2/trip-booking-packages/trip-booking-package-resources/trip-booking-package-resources.component';
import { TripBookingTicketResourcesComponent } from './step-2/trip-booking-tickets/trip-booking-ticket-resources/trip-booking-ticket-resources.component';
import { TrenitaliaDialogModule } from './trenitalia-dialog/trenitalia-dialog.module';
import { TripBookingPackageProductComponent } from './step-2/trip-booking-package-product/trip-booking-package-product.component';
import { TripBookingPackageExternalServiceComponent } from './step-2/trip-booking-packages-external-service/trip-booking-package-external-service.component';
import { TrenitaliaPackageComponent } from './trip-booking-summary/trenitalia-package/trenitalia-package.component';
import { TripBookingPackageTrenitaliaPostSaleComponent } from './step-2/trip-booking-packages-external-service/trip-booking-package-trenitalia-post-sale/trip-booking-package-trenitalia-post-sale.component';
import {TrenitaliaCarnetModule} from './trenitalia-dialog/trenitalia-carnet/trenitalia-carnet.module';
import { TripBookingPackagesStepModule } from './trip-booking-packages-step/trip-booking-packages-step.module';
import { ProductPackageComponent } from './trip-booking-summary/product-package/product-package.component';

@NgModule({
	declarations: [
		EditBookingPageComponent,
		EditBookingStep0Component,
		EditBookingStep1Component,
		EditBookingStep2Component,
		EditBookingStep3Component,

		TripBookingPackageComponent,
		TripBookingTicketComponent,
		TripBookingResourceComponent,
		TripBookingServiceComponent,
		TripBookingParticipantComponent,

		TripBookingStepperComponent,
		TripBookingSummaryComponent,

		TripBookingPackageResourcesComponent,
		TripBookingTicketResourcesComponent,
		TripBookingPackageProductComponent,
		TripBookingPackageExternalServiceComponent,
		TripBookingPackageTrenitaliaPostSaleComponent,
		TrenitaliaPackageComponent,
		ProductPackageComponent,
	],
	imports: [
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatStepperModule,
		MatProgressBarModule,
		FormViewerModule,
		FuseSharedModule,
		FuseSidebarModule,
		MatTabsModule,
		MatTableModule,
		MatListModule,
		RouterModule,
		DatasetModule,
		MatToolbarModule,
		MatCardModule,
		MatTooltipModule,
		MatBadgeModule,
		MatChipsModule,
		MatMenuModule,
		MatDatepickerModule,
		ActionDialogModule,
		MatProgressSpinnerModule,
		FormDialogsModule,
		OverlayModule,
		PopoverModule,
		TranslateModule.forChild(),
		MatDialogModule,
		TrenitaliaDialogModule,
		MatRippleModule,
		TrenitaliaCarnetModule,
		TripBookingPackagesStepModule
	],
	exports: [EditBookingPageComponent],
	entryComponents: [
		TripBookingResourceComponent,
		TripBookingServiceComponent,
		TripBookingParticipantComponent,
	],
})
export class EditBookingModule {}
