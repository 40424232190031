import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('trip_resource_id'),
];

const config: IFormConfig = { 
	title: 'Inserisci Risorsa Opzione di Partenza',
	editTitle: 'Modifica Risora Opzione di Partenza',
	fields: formFields 
};

export default config;
