<div class="flex-col" style="width: 100%;">
	<!-- mobile header -->
	<ng-container *ngIf='record'>
		<div #mobileHeader [ngClass]='statusClass' class="trip-booking-mobile-header" fxLayout="row"
			 fxLayoutAlign="center center" fxLayoutGap="12px">
			<span class="material-icons-outlined">confirmation_number</span>
			<span class="booking-code">{{'Prenotazione' | translate}} {{record.booking_code}}</span>
			<ng-container *ngIf='record'>
				<ng-container [ngSwitch]='record.status'>
					<span *ngSwitchCase='"confirmed"' class='h4 secondary-text'>{{'Confermata' | translate}}</span>
					<span *ngSwitchCase='"mix"' class='h4 secondary-text'>{{'Mista' | translate}}</span>
					<span *ngSwitchCase='"canceled"' class='h4 secondary-text'>{{'Cancellata' | translate}}</span>
					<span *ngSwitchCase='"optional"'
						  class='h4 secondary-text '>{{'Opzionale (in scadenza)' | translate}}</span>
					<span *ngSwitchCase='"expired"' class='h4 secondary-text'>{{'Scaduta' | translate}}</span>
					<span *ngSwitchCase='"unconfirmed"'
						  class='h4 secondary-text'>{{'Non Confermata' | translate}}</span>
					<span *ngSwitchCase='"draft"' class='h4 secondary-text'>{{'Preventivo' | translate}}</span>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>

	<!-- mobile horizontal stepper -->
	<trip-booking-stepper #bookingStepperComponent
						  fxHide.gt-md
						  layout="horizontal"
						  [record]="record"
						  (stepChange)="stepChange($event)"
	></trip-booking-stepper>

	<div class="page-layout p-16 blank simple left-sidebar booking-page">
		<div class="top-bg accent"></div>
		<ng-container *ngIf='!record'>
			<div class="progressbar" fxLayout="row" style="width: 100%">
				<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"></mat-progress-bar>
			</div>
		</ng-container>
		<ng-container *ngIf='record'>
			<!-- fuse sidebar vertical stepper -->
			<trip-booking-stepper #bookingStepperComponent
								  fxHide.lt-md
								  layout="vertical"
								  [record]="record"
								  (stepChange)="stepChange($event)"
			></trip-booking-stepper>

			<div class="center">
				<div class="content booking-page-content p-16">
					<mat-card class='m-12 p-4' *ngIf='bookingService.useLastDraft && record && isDraft' class='primary pending-booking-msg'>
						<mat-card-content>
							<p>{{'Stai modificando una prenotazione non terminata' | translate}}.</p>
						</mat-card-content>
						<mat-action-list>
							<button mat-flat-button (click)='bookingService.useLastDraft = false'>{{'Chiudi messaggio' | translate}}</button>
							<button mat-flat-button color='warn' (click)='cancelPermanentBooking()'>{{'Cancella Permanentemente' | translate}}</button>
						</mat-action-list>
					</mat-card>
					<mat-card class='m-12 p-4' style='max-width:500px' *ngIf='record.status === "optional" && deadlineDate' class='orange'>
						<mat-card-content>
							<p *ngIf='deadlineDate > now' class='orange-50-fg'>{{'Questa prenotazione se non confermata scadrà il' | translate}} {{deadlineDate.format('DD/MM/YYYY HH:mm')}}</p>
							<p *ngIf='deadlineDate <= now' class='orange-50-fg'>{{'Questa prenotazione è scaduta il' | translate}} {{deadlineDate.format('DD/MM/YYYY HH:mm')}}</p>
						</mat-card-content>
					</mat-card>
					<!-- payment -->
					<mat-card class='m-12 p-4' *ngIf='record.method_payment && record.method_payment_status != "refunded"' class='primary'>
						<mat-card-content>
							<p>{{'La prenotazione è stata saldata tramite il seguente metodo di pagamento' | translate}}: <b>{{record.method_payment}}</b></p>
						</mat-card-content>
					</mat-card>
					<mat-card class='m-12 p-4' *ngIf='record.method_payment && record.method_payment_status == "refunded"' class='red'>
						<mat-card-content>
							<p>{{'La prenotazione è stata rimborsata tramite il seguente metodo di pagamento' | translate}}: <b>{{record.method_payment}}</b></p>
						</mat-card-content>
					</mat-card>
					<!-- document rt -->
					<mat-card *ngIf='record.document_rt' [ngClass]='record.status == "canceled" ? "warn" : "success"' style="margin-top: 12px;">
						<mat-card-content>
							<span><b>{{'Data e Ora documento' | translate}}</b>: {{ record.document_rt.data_scontrino }}</span> | 
							<span><b>{{'Numero Documento' | translate}}</b>: {{ record.document_rt.ClosureNumber }} / {{ record.document_rt.DocumentNumber }}</span> | 
							<span><b>{{'Matricola Cassa' | translate}}</b>: {{ record.document_rt.ServerRtSerialNumber }}</span> | 
							<span><b>{{'Matricola Server' | translate}}</b>: {{ record.document_rt.Till_ID }}</span> 
						</mat-card-content>
					</mat-card>
					<mat-tab-group class='tab-hidden-header' [selectedIndex]='stepIndex'>
						<mat-tab>
							<ng-template matTabContent>
								<trip-booking-packages-step></trip-booking-packages-step>
							</ng-template>
						</mat-tab>
						<mat-tab>
							<ng-template matTabContent>
								<edit-booking-step-0></edit-booking-step-0>
							</ng-template>
						</mat-tab>
						<mat-tab>
							<ng-template matTabContent>
								<edit-booking-step-1></edit-booking-step-1>
							</ng-template>
						</mat-tab>
						<mat-tab>
							<ng-template matTabContent>
								<edit-booking-step-3></edit-booking-step-3>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
				<!-- navigation on desktop -->
				<ng-container *ngIf='!datasetACS.loading.value'>
					<hr class="divider desktop">
					<div class="navigation desktop">
						<ng-container *ngIf="stepIndex > 0">
							<div class='mt-24 mb-24 navigate_prev' *ngIf='bookingService.canEdit'>
								<div style="display: flex; align-items: center;" (click)="bookingStepperComponent.previous()">
									<span class="material-icons-outlined round_next">arrow_back_ios_new</span>
									<a mat-flat-button>{{'Indietro' | translate}}</a>
								</div>
							</div>
						</ng-container>
				
						<ng-container *ngIf="stepIndex < 3">
							<div class='mt-24 mb-24 navigate_next' *ngIf='bookingService.canEdit'>
								<div	style="display: flex; 
											align-items: center;" 
											[ngClass]="{'disabled_next_booking_step': !enabledNextBookingStep}"
											(click)="bookingStepperComponent.next()">
									<a mat-flat-button>{{ (stepIndex == 0 ? 'Vai avanti' : 'Salva e vai avanti') | translate}}</a>
									<span class="material-icons-outlined round_next">arrow_forward_ios</span>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>

			<!-- right sidebar -->
			<fuse-sidebar class="right" name="page-right-sidebar" position="right" lockedOpen="gt-sm">
				<div class="overlay" *ngIf="datasetRS.loading.value"></div>
				<div fxLayout="column" fxLayoutGap="24px" fxFlex="1 1 0">
					<div [ngClass]='statusClass' class="header p-16 accent">
						<!-- booking code -->
						<h2 class="m-0 booking_title">
							<span class="material-icons-outlined icon">confirmation_number</span> {{'Prenotazione' | translate}} 
							<span (click)="onViewLogs()" class="booking_code" *ngIf='record.booking_code'>{{record.booking_code}}</span>
						</h2>
						<!-- status -->
						<ng-container *ngIf='record'>
							<ng-container [ngSwitch]='record.status'>
								<span *ngSwitchCase='"mix"' class='h4 secondary-text'>{{'Mista' | translate}}</span>
								<span *ngSwitchCase='"confirmed"' class='h4 secondary-text'>{{'Confermata' | translate}}</span>
								<span *ngSwitchCase='"canceled"' class='h4 secondary-text'>{{'Cancellata' | translate}}</span>
								<span *ngSwitchCase='"optional"' class='h4 secondary-text '>{{'Opzionale (in scadenza)' | translate}}</span>
								<span *ngSwitchCase='"expired"' class='h4 secondary-text'>{{'Scaduta' | translate}}</span>
								<span *ngSwitchCase='"unconfirmed"' class='h4 secondary-text'>{{'Non Confermata' | translate}}</span>
								<span *ngSwitchCase='"draft"' class='h4 secondary-text'>{{'Preventivo' | translate}}</span>
							</ng-container>
						</ng-container>
					</div>
					
					<div class="actions">
						<div class="voucher-actions" *ngIf='["confirmed", "mix"].includes(record.status) && hasPackages' fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
							<h4 style="margin-top: 0;">{{'Voucher prenotazione' | translate}}</h4>
							<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
								<i class="material-icons-outlined">print</i>
								<a class="print-voucher"
								   [href]='appService.getBaseServerAddressRoot() + "/voucher/download/by/id/" + datasetRS.recordId'
								   target="_blank">{{'Stampa' | translate}}</a>
							</span>
							<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
								<i class="material-icons-outlined">email</i>
								<span (click)="emailVoucher()">{{'Invia via mail' | translate}}</span>
							</span>
						</div>

						<div class="suspend" *ngIf='["draft", "unconfirmed"].includes(record.status)'
							 (click)='suspendBooking()'>
							<span
								class="material-icons-outlined">pause_circle</span> {{ 'Sospendi prenotazione' | translate}}
						</div>

						<div class="block"
							 *ngIf='!["confirmed", "optional", "mix"].includes(record.status) && (record.booking_type != 1 || !["expired", "canceled"].includes(record.status))'
							 (click)='optionalBooking()'
							 [matTooltip]='"Bloccal il preventivo fino a una scadenza (Mantiene i posti riservati)" | translate'>
							<span class="material-icons-outlined">push_pin</span> {{ 'Blocca preventivo' | translate}}
						</div>

						<ng-container *ngIf="record.allowed_actions.includes('delete') && record.domain.code!=='forexchange'">
							<div class="annulla" *ngIf='["confirmed", "optional", "mix"].includes(record.status)'
								 (click)='cancelBooking()'>
								<span
									class="material-icons-outlined">cancel</span> {{ 'Annulla prenotazione' | translate}}
							</div>
						</ng-container>

					</div>

					<trip-booking-summary
						[record]='record'
						[extraParams]='{channel_value: channelValue, user_name: creatorName}'
					></trip-booking-summary>
				</div>
				<div class="total" fxLayout="row" fxLayoutAlign="space-around center"
					 *ngIf="datasetRS.record.value.participant_count > 0 && datasetRS.record.value.packages.length > 0 && fees>0">
					<span class="totale">{{'Diritti Agenzia' | translate}}:</span>
					<span class="importo">{{ fees | currency:"EUR" }}</span>
				</div>
				<!-- TODO: verificare che ci sia un intestatario e un cliente anche -->
				<div class="total" fxLayout="row" fxLayoutAlign="space-around center"
					 *ngIf="datasetRS.record.value.participant_count > 0 && datasetRS.record.value.packages.length > 0">
					<span class="totale">{{'Totale' | translate}}:</span>
					<span class="importo">{{ datasetRS.record.value.total_amount | currency:"EUR" }}</span>
				</div>

				<div class="footer" fxLayout="row" fxLayoutAlign="center center">
					<div [matTooltip]="confirmTooltip">
						<button [ngClass]="{'disabled': !canConfirm, 'confirm': canConfirm}" [disabled]="!canConfirm"
								(click)="confirmBooking()">
							{{ 'Conferma prenotazione' | translate}}
						</button>
					</div>
				</div>
			</fuse-sidebar>
		</ng-container>
	</div>
</div>

<!-- navigation mobile -->
<!-- TODO: creare componente -->

<ng-container *ngIf="record">
	<div class="grow"
		 [ngClass]='{"expanded": bottomBarState !== "false"}'
		 [@grow]='{value: bottomBarState, params: {bottomBarHeight: "67px", bottom: bottom, bookingReviewHeight: bookingReviewHeight}}'>

		<div [ngClass]='{"no-shadow": bottomBarState !== "false"}' class="tab" fxLayout="row"
			 fxLayoutAlign="start center" fxLayoutGap="6px" (click)='bookingReviewBarToggle()'>
			<span class="material-icons-outlined">{{ bottomBarState == 'false' ? 'expand_less' : 'expand_more' }}</span>
			<span class="tab-label">{{ 'Riepilogo' | translate }}</span>
		</div>

		<div class="mobile-booking-review" fxLayout="column" fxLayoutAlign="space-between flex-start" fxFlex="1 1 auto"
			 fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">
			<div class="overlay" *ngIf="datasetRS.loading.value"></div>

			<div fxLayout="column" fxLayoutGap="24px" fxFlex="1 1 0" style="width: 100%;">
				<trip-booking-summary
					[record]='record'
					[extraParams]='{channel_value: channelValue, user_name: creatorName}'
				></trip-booking-summary>
			</div>

			<div class="footer" fxLayout="column">
				<div class="actions" fxLayout="column">
					<div class="voucher-actions" *ngIf='["confirmed"].includes(record.status)' fxLayout="column"
						 fxLayoutAlign="start" fxLayoutGap="10px">
						<h4 style="margin-top: 0;">{{'Voucher prenotazione' | translate}}</h4>
						<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
							<i class="material-icons-outlined">print</i>
							<a class="print-voucher"
							   [href]='appService.getBaseServerAddressRoot() + "/voucher/download/by/id/" + datasetRS.recordId'
							   target="_blank">{{'Stampa' | translate}}</a>
						</span>
						<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
							<i class="material-icons-outlined">email</i>
							<span (click)="emailVoucher()">{{'Invia via mail' | translate}}</span>
						</span>
					</div>

					<div class="suspend" *ngIf='["draft", "unconfirmed"].includes(record.status)'
						 (click)='suspendBooking()'>
						<span
							class="material-icons-outlined">pause_circle</span> {{ 'Sospendi prenotazione' | translate}}
					</div>

					<div class="block"
						 *ngIf='!["confirmed", "optional"].includes(record.status) && (record.booking_type != 1 || !["expired", "canceled"].includes(record.status))'
						 (click)='optionalBooking()'
						 [matTooltip]='"Bloccal il preventivo fino a una scadenza (Mantiene i posti riservati)" | translate'>
						<span class="material-icons-outlined">push_pin</span> {{ 'Blocca preventivo' | translate}}
					</div>

					<ng-container *ngIf='record.allowed_actions.includes("delete")&& record.domain.code!=="forexchange"'>
						<div class="annulla" *ngIf='["confirmed", "optional"].includes(record.status)'
							 (click)='cancelBooking()'>
							<span class="material-icons-outlined">cancel</span> {{ 'Annulla prenotazione' | translate}}
						</div>
					</ng-container>

					<div class="confirm" [matTooltip]="confirmTooltip">
						<button [ngClass]="{'disabled': !canConfirm, 'confirm': canConfirm}"  [disabled]="!canConfirm"
								(click)="confirmBooking()">
							{{ 'Conferma prenotazione' | translate}}
						</button>
					</div>

				</div>
			</div>
		</div>
	</div>

	<!-- bottom fixed bar -->
	<div #bottomFixedBar class="trip-booking-bottom-fixed-bar" fxLayout="row" fxLayoutAlign="space-between center">
		<div class="total-mobile-review" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px">
			<span class="label">{{'Totale' | translate}}:</span>
			<span>{{ record.total_amount | currency:"EUR" }}</span>
		</div>

		<div class="navigation-pager" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="1 1 auto">
			<ng-container *ngIf="stepIndex > 0">
				<div fxLayout="row" fxLayoutAlign="start center" (click)="bookingStepperComponent.previous()">
					<button class="prev-step" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
						<span class="material-icons-outlined">arrow_back_ios</span>
						<span>{{ 'Indietro' | translate }}</span>
					</button>
				</div>
			</ng-container>

			<ng-container *ngIf="stepIndex < 3">
				<div fxLayout="row" fxLayoutAlign="end center" fxFlex="1 1 auto">
					<button class="next-step" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
							(click)="bookingStepperComponent.next()">
						<span>{{ 'Salva' | translate }}</span>
						<span class="material-icons-outlined">arrow_forward_ios</span>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>