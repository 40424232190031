import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import userDomainEditForm from './forms/user-domain-edit.form';
import userCreateFormFields from './forms/user-create.form';
import detailForm from './forms/detail.form';
import { userCommonfieldsMap } from './common.fields';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { action_edit_password } from 'app/main/actions/edit-password.action';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ISelectDatasetRecordDialogData, SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { ILoadingDialogData, LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/domain_users/detail/:recordId',
	params: {
		with_relations: 'user,domainUserRole'
	}
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const domainIdDetail = dataContainerService.getValueFromKeyPath('domains.recordId');
	if (domainIdDetail) {
		params['domain_id'] = domainIdDetail;
	} else {
		const domainId = dataContainerService.domainFilterService.filterDomainId.value;
		if(domainId) {
			params['domain_id'] = domainId;
		}
	}
	return params;
}

export const DomainUsersConfig: IDatasetConfig = {
	name: 'domain_users',
	title: 'Domini utenti',
	singleTitle: 'Dominio Utente',
	ajaxDatasetCode: 'domain_users',
	fieldsMap: userCommonfieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	fetchConfig: {
		with_relations: 'domain,user,domainUserRole',
		with_attributes: 'branch_name,branch_code'
	},
	formConfigsMap: {},
	defaultActions: {
		detail: {
			beforeCalls: [beforeCallDetail],
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: userCreateFormFields
			},
			getCallParams
		},
		edit: {
			viewConfig: {
				formConfig: userDomainEditForm
			},
			openType: DatasetActionOpenType.DIALOG,
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params['with_relations'] = 'domain,user,domainUserRole,entityStaffElement';
				params['with_attributes'] = 'branch_name,branch_code';
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nome',
						key: 'user.name',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record: any): any{
							let displayName = '<div>';
							if (record.user){
								displayName = '<span>' + record.user.name + '</span>';
							}
							if (record.entity_staff_element){
								displayName += '<br/>';
								displayName += '<span> Anagrafica staff associata: ';
								displayName += '<b>' + record.entity_staff_element.description + '</b></span>';
							}
							displayName += '</div>';

							return displayName;
						}
					},
					{
						title: 'Username',
						key: 'user.access_username'
					},
					{
						title: 'Dominio',
						key: 'domain.display_name',
						showIf(): boolean{
							const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
							return !filteredDomainId;
						}
					},
					{
						title: 'Ruolo',
						key: 'domain_user_role.name'
					},
					{
						title: 'Filiale',
						key: 'branch_code'
					}
				],
				actions: ['detail', 'create', 'edit', 'delete'],
				itemActionsOrder: ['create', 'detail', 'edit', 'custom_0', 'custom_1', 'delete'],
				customActions: [
					{
						buttonIcon: 'settings',
						buttonColor: 'accent',
						tooltip: 'Modifica password',
						showIf(): boolean {
							return true;
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							action_edit_password(record, datasetNavigatorService.datasetService);
						},
					},
					{
						buttonIcon: 'person_add',
						buttonColor: 'accent',
						tooltip: 'Assegna staff',
						showIf(record: any, dataContainerService: IDataContainerService): boolean {
							// 1 domain user
							// 2 staff user
							if (record.domain_user_role.role_type == 2 && !record.entity_staff_element_id) return true;
							return false;
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							const selectDatasetRecord = datasetNavigatorService.openDialog<ISelectDatasetRecordDialogData>(SelectDatasetRecordDialogComponent, {
								title: 'Seleziona anagarafica staff',
								datasetCode: 'entities',
								filters: {entity_types: 'staff_element', exclude_entity_staff_elements_with_users: 1},
								parentDatasetACS: dataContainerService,
								parentDatasetRS: dataContainerService.datasetRS
							});
							selectDatasetRecord.afterClosed().subscribe({
								next: (selectedRecord: any) => {
									if (!selectedRecord) return;
									const loadingDialogRef = datasetNavigatorService.openDialog<ILoadingDialogData>(LoadingDialogComponent, {
										title: 'Creazione in corso'
									});
									// format data for entity staff call
									const data = {
										user_domain_id: record.id, 
										entity_staff_id: selectedRecord.staff_element_data.id,
										user_id: record.user_id,
										entity_id: selectedRecord.id
									};
									// make request
									datasetNavigatorService.datasetService.post<any>('/dataset/domain_users/command/associate_staff_to_user', data)
									.subscribe({
										next: (response: any) => {
											if (response.success){
												loadingDialogRef.close();
												datasetNavigatorService.datasetService.appService.showSuccessMessage('Modifica avventuta con successo');
												// reload list
												dataContainerService.datasetEvent.next({
													eventName: DatasetEvents.ACTION_REALOAD_LIST,
												});
											}
										},
										error: (exception) => {
											console.error(exception);
											loadingDialogRef.close();
											datasetNavigatorService.datasetService.appService.showErrorMessage('Si è verificato un errore.');
										}
									});
								},
								error: (e) => {
									console.error(e);
									datasetNavigatorService.datasetService.appService.showSuccessMessage(e.message);
								}
							});
						}
					}
				]
			}
		}
	}
};
