import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('user.access_username', {
	key: 'user.access_username',
	name: 'Nome utente',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('user.name', {
	key: 'user.name',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('user.email', {
	key: 'user.email',
	name: 'Email',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('branch.code', {
	key: 'branch.code',
	name: 'Filiale',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.code;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	showIf(record: string){
		return true;
		//return (record['creation_mode'] == 1);
	}
});

export {fieldsMap};
