import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general-form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import {config as StepViewConfig} from './step-view-config';
import activeLanguageCodesField from 'app/configs/commons/shared-field-configs/active-language-codes.field';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/meeting_points/detail/:recordId',
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	params: {
		key: 'modules'
	}
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	if(params instanceof FormData){

	}else{
		const addressData = {};
		for (const key in params) {
			if(!Object.prototype.hasOwnProperty.call(params, key)) continue;
			if(!key.startsWith('address.')) continue;
			const newKey = key.replace('address.', '');
			const value = params[key];
			addressData[newKey] = value;
			delete params[key];
		}
		params.address = addressData;
	}
	return params;
}

export const MeetingPointConfig: IDatasetConfig = {
	name: 'meeting_points',
	title: 'Punti di ritrovo',
	singleTitle: 'Punto di ritrovo',
	ajaxDatasetCode: 'meeting_points',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail, callModule ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm
			},
			beforeCalls: [ callModule ],
			getCallParams
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm
			},
			getCallParams
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(a, b, params: any): any{
				params['with_attributes'] = ['active_language_codes'];
				return params;
			},
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					},
					{
						title: 'Traduzioni Atttive',
						key: 'active_language_codes',
						fieldDefinition: activeLanguageCodesField
					}
				],
				actions: ['create', 'detail', 'delete']
			}
		}
	},
	stepViewConfig: StepViewConfig
};
