import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';

@Component({
	selector     : 'bookingwindow',
	templateUrl  : './bookingwindow.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations   : fuseAnimations,
})
export class BookingWindowComponent extends DestroyableComponent implements OnInit, AfterViewInit{
	public bookingwindow: ChartDataSets[];
	public showLoader: boolean;

	@ViewChild(BaseChartDirective, { static: false }) chartComponent: BaseChartDirective;

	/**
	 * statistics
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		onResize(chart: Chart): void{
			chart.data.datasets.forEach((chartDataset: ChartDataSets) => {
				if (chartDataset.hoverBackgroundColor === "#36a2eb"){
					const gradient: CanvasGradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom/1.7, 0, chart.chartArea.bottom);
					gradient.addColorStop(0, '#36a2eb');
					gradient.addColorStop(1, 'white');
					chartDataset.backgroundColor = gradient;
				}
			});
			/**
			 * when data or style changes update chart
			 */
			chart.update();
		},
		legend: {
			position: 'bottom',
			labels: {
				usePointStyle: true
			}
		},
		scales: {
			yAxes: [
				{
					gridLines: {
						display: false,
					}
				}
			]
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		}
	};
	public chartPlugins = [
		makeDrawValuePlugin({
			textPosition: {
				align: 'center',
				baseline: 'top'
			},
			formatDataFun(value): string{
				if(value === null || value === undefined) return '';
				const n = Number(value);
				if(isNaN(n) || n <= 0) return '';
				return n.toFixed(2);
			}
		})
	];
	public barChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
	public barChartType: ChartType = 'bar';
	public barChartLegend = true;

	constructor(public dashboardService: DashBoardService){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	public ngOnInit(): void{
		this.bookingwindow = [{data: []}];
	}

	public ngAfterViewInit(): void{
		this.dashboardService.bookingWidowResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				this.onData(response);
			}
		});
	}
	// -----------------------------------------------------------------------------------------------------
	
	public onData(response: any): void{
		if(!response){
			this.showLoader = true;
			this.bookingwindow = [{data: []}];
			return;
		}
		if(!response.Data1){
			response = {
				Data1: [],
				Data2: [],
				Data3: [],
				Data4: [],
				Data5: [],
				Data6: [],
			};
		}

		const chartArea = this.chartComponent.chart.chartArea;
		const gradient: CanvasGradient = this.chartComponent.chart.ctx.createLinearGradient(0, chartArea.bottom/1.7, 0, chartArea.bottom);
		gradient.addColorStop(0, '#36a2eb');
		gradient.addColorStop(1, 'white');

		const data: ChartDataSets[] = [
			{ 
				data: response.Data1, 
				label: '1 g prima', 
				stack: 'a',
				backgroundColor: gradient,
				hoverBackgroundColor: '#36a2eb',
				borderColor: '#36a2eb',
				hoverBorderColor: '#36a2eb'
			},
			{ 
				data: response.Data2, 
				label: 'da 2 a 3 g', 
				stack: 'a',
				backgroundColor: '#666666',
				hoverBackgroundColor: '#666666',
				borderColor: '#666666',
				hoverBorderColor: '#666666'
			},
			{ 
				data: response.Data3, 
				label: 'da 3 a 7 g', 
				stack: 'a',
				backgroundColor: '#c9cbcf',
				hoverBackgroundColor: '#c9cbcf',
				borderColor: '#c9cbcf',
				hoverBorderColor: '#c9cbcf'
			},
			{ 
				data: response.Data4, 
				label: 'da 7 a 15 g', 
				stack: 'a',
				backgroundColor: '#9966ff',
				hoverBackgroundColor: '#9966ff',
				borderColor: '#9966ff',
				hoverBorderColor: '#9966ff'
			},
			{ 
				data: response.Data5, 
				label: 'da 15 a 31 g', 
				stack: 'a',
				backgroundColor: '#4bc0c0',
				hoverBackgroundColor: '#4bc0c0',
				borderColor: '#4bc0c0',
				hoverBorderColor: '#4bc0c0'
			},
			{ 
				data: response.Data6, 
				label: '31 o più g', 
				stack: 'a',
				backgroundColor: '#ff6384',
				hoverBackgroundColor: '#ff6384',
				borderColor: '#ff6384',
				hoverBorderColor: '#ff6384'
			}
		];
		if (!response.Empty){
			this.showLoader = false;
			this.bookingwindow = data;
		} else {
			this.showLoader = true;
			this.bookingwindow = [{data: []}];
		}
	}
}
