import {IDatasetConfig, DatasetActionOpenType } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_package_integrations/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(!params) params = {};
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	params['with_attributes'] = 'tour_operator_code';
	return params;
}

export const TripPackageIntegrationsConfig: IDatasetConfig = {
	name: 'trip_package_integrations',
	title: 'Integrazioni',
	singleTitle: 'Integrazione',
	ajaxDatasetCode: 'trip_package_integrations',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: false,
		edit: true,
		list: false,
		delete: false
	},
	fetchConfig: {
		with_attributes: 'tour_operator_code'
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			},
		}
	}
};
