import {
	InputTypes,
	ValueTypes,
	IFormConfig,
	IFieldDefinition,
} from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();
fieldsMap.set('name', {
	key: 'name',
	name: 'Nome',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
});
fieldsMap.set('surname', {
    key: 'surname',
    name: 'Cognome',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
});

fieldsMap.set('cartafreccia', {
    key: 'cartafreccia',
    name: 'Cartafreccia',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
});



export { fieldsMap };
