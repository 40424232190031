<mat-card class='p-24'>
	<mat-card-header>
		<mat-card-title>Seeding</mat-card-title>
	</mat-card-header>
	<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>
	<mat-card-content>
		<select [(ngModel)]='selectedSeeder'>
			<option value='PropertiesSeeder'>PropertiesSeeder</option>
			<option value='NationSeeder'>NationSeeder</option>
			<option value='LocalizationSeeder'>LocalizationSeeder</option>
			<option value='DatasetsDefinitionTableSeeder'>DatasetsDefinitionTableSeeder</option>
			<option value='DatasetActionDefinitionTableSeeder'>DatasetActionDefinitionTableSeeder</option>
		</select>
		
	</mat-card-content>
	<mat-card-actions>
		<button [disabled]='!selectedSeeder || loading' mat-flat-button color='accent' (click)='startSeeding()'>{{'Avvia' | translate}}</button>
	</mat-card-actions>
</mat-card>