import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { takeUntil } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { IPaginationResponse } from 'app/interfaces';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'dataset-combobox-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class DatasetComboboxInputComponent extends DestroyableComponent implements OnInit, OnDestroy{

	public filteredOptions: Subject<any>;
	public loading = true;
	protected filterOptionsSubscription: Subscription;
	protected initRemoteValueSubscription: Subscription;
	public textSearch: string;
	public supportCreate = false;

	public selectedItems: any[] = [];
	public separatorKeysCodes: number[] = [ENTER, COMMA];
	@ViewChild(MatAutocomplete, { static: false }) matAutocomplete: MatAutocomplete;
	@ViewChild('filterInput', { static: false }) filterInput: ElementRef<HTMLInputElement>;

	public inputFormControl = new FormControl();

	constructor(
		protected appService: AppService,
		protected datasetService: DatasetService,
		public inputService: FormInputViewerService
	){
		super();
		this.filteredOptions = new Subject();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;

			if(this.inputService.formInputDefinition.remoteOptions){
				this.supportCreate = Boolean(this.inputService.formInputDefinition.remoteOptions.supportCreate);
			}
			this.fetchFilteredOptions('');
			this.initRemoteValue();

			this.inputService.control
			.statusChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(status => {
				if(status === 'INVALID'){
					this.inputFormControl.setErrors({invalid: this.inputService.errorMessage});
				}else{
					this.inputFormControl.setErrors(null);
				}
				this.inputFormControl.markAsTouched();
			});

			this.inputService.control.valueChanges
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				const currentValue = this.inputService.control.value;
				if(currentValue){
					// todo check if all items are presents
				}
			});
		});
	}

	initRemoteValue(withLoading?: boolean): Observable<any> {
		const resultSubject = new Subject<any>();
		const control = this.inputService.control;
		if(!control){
			resultSubject.complete();
			return resultSubject;
		}
		const currentValue = control.value;
		if(!currentValue || currentValue === '[]'){
			resultSubject.complete();
			return resultSubject;
		}
		if(this.initRemoteValueSubscription){
			this.initRemoteValueSubscription.unsubscribe();
		}
		let params = {};
		if(this.inputService.formInputDefinition.remoteOptions && typeof(this.inputService.formInputDefinition.remoteOptions.getParams) === 'function'){
			params = this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
		}
		params['perPage'] = 20;
		params['column_multipe_json_values'] = currentValue;
		if(!params['column_name']) params['column_name'] = 'id';
		params['sortBy'] = 'created_at';

		if(withLoading) this.loading = true;
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		this.initRemoteValueSubscription = this.datasetService.get<IPaginationResponse<any>>(this.getListRoute(), params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				if(response.items && response.items.length > 0){
					this.selectedItems = response.items;
					resultSubject.next(response.items);
					resultSubject.complete();
					if(this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.onInit){
						this.inputService.formInputDefinition.extra.onInit(this.inputService.formGroup, this.selectedItems);
					}
				}else{
					resultSubject.error(response);
					resultSubject.complete();
				}
				if(withLoading) this.loading = false;
			},
			error: response => {
				resultSubject.error(response);
				resultSubject.complete();
				if(withLoading) this.loading = false;
			}
		});

		return resultSubject;
	}

	onMatChipInputTokenEnd(event: MatChipInputEvent): void{
		// Add package only when MatAutocomplete is not open
		// To make sure this does not conflict with OptionSelected Event
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const item = event.value;
	
			// Add our item
			if (item && (typeof(item) !== 'string' || this.supportCreate)) {
				this.selectedItems.push(item);
				this.inputService.onSet(item);
				this.onSelectionChanged();
			}
	
			// Reset the input value
			if (input) {
				input.value = '';
			}
			this.inputFormControl.setValue(null);
		}
		if(this.filterOptionsSubscription){
			this.filterOptionsSubscription.unsubscribe();
			this.filterOptionsSubscription = null;
			this.loading = false;
		}
	}

	onMatAutocompleteSelectedEvent(event: MatAutocompleteSelectedEvent): void{
		this.selectedItems.push(event.option.value);
		this.inputService.onSet(event.option.value);
		this.onSelectionChanged();
		this.filterInput.nativeElement.value = '';
		this.inputFormControl.setValue(null);
		if(this.filterOptionsSubscription){
			this.filterOptionsSubscription.unsubscribe();
			this.filterOptionsSubscription = null;
			this.loading = false;
		}
	}

	removeItem(item): void{
		const index = this.selectedItems.indexOf(item);
		if(index !== -1){
			this.selectedItems.splice(index, 1);
			this.onSelectionChanged();
		}
	}

	onSelectionChanged(): void{
		let val = null;
		if(this.selectedItems){
			const elements = this.selectedItems.map(el => {
				if(typeof(el) === 'string') return el;
				return this.inputService.formInputDefinition.remoteOptions.getValue(el);
			});
			if(elements && elements.length > 0) val = JSON.stringify(elements);
		}
		this.inputService.control.setValue(val);
	}

	getListRoute(): string{
		let route = '/dataset/' + this.inputService.formInputDefinition.datasetCode;
		if(this.inputService.formInputDefinition.remoteOptions.getPivotConfig){
			const pivotConfig = this.inputService.formInputDefinition.remoteOptions.getPivotConfig(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue());
			const parentId = this.inputService.dataContainerService.getValueFromKeyPath(pivotConfig.parentDatasetCode + '.recordId');
			route = '/pivot/' + pivotConfig.pivotCode  + '/dataset/' + pivotConfig.parentDatasetCode + '/' + parentId;
		}
		return route;
	}

	fetchFilteredOptions(textSearch): void{
		if(!this.inputService.formInputDefinition.remoteOptions){
			console.warn('remoteOptions not setted in fieldDefinition', this.inputService.formInputDefinition);
			return;
		}
		if(this.filterOptionsSubscription) this.filterOptionsSubscription.unsubscribe();
		let params = {
			'text-search': textSearch,
			perPage: this.inputService.formInputDefinition.remoteOptions.limit || 5
		};
		if(this.inputService.formInputDefinition.remoteOptions.getParams){
			params = Object.assign(params, this.inputService.formInputDefinition.remoteOptions.getParams(this.inputService.dataContainerService, this.inputService.formGroup.getRawValue()));
		}
		let headers = new HttpHeaders({});
		if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
		}
		const route = this.getListRoute();
		this.textSearch = textSearch;
		this.loading = true;
		this.filterOptionsSubscription = this.datasetService.get<IPaginationResponse<any>>(route, params, headers)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: result => {
				this.filteredOptions.next(result.items);
				this.loading = false;
			},
			error: () => {
				this.loading = false;
			}
		});
	}

	displayFun(record: any): any{
		if(typeof(record) === 'string' && this.supportCreate) return record;
		if(!this.inputService.formInputDefinition || !this.inputService.formInputDefinition.remoteOptions) return '---';
		if(this.inputService.formInputDefinition.remoteOptions.getDisplay){
			return this.inputService.formInputDefinition.remoteOptions.getDisplay(record);
		}else if(this.inputService.formInputDefinition.remoteOptions.getLabel){
			return this.inputService.formInputDefinition.remoteOptions.getLabel(record);
		}
	}

	trackByOptions(index): number{
		return index;
	}
}
