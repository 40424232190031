import { IDatasetPropertyDefinition, IFormConfig, IFieldDefinition, InputTypes, ValueTypes } from "app/interfaces";

export interface IBuildOptions{
	only?: string[];
	exclude?: string[];
}

export function propertyTypeCodeToValueType(code: string): ValueTypes{
	if(code === 'boolean') return ValueTypes.BOOLEAN;
	if(code === 'number') return ValueTypes.NUMBER;
	if(code === 'string[]' || code === 'string[][]') return ValueTypes.JSON;
	if(code === 'multiple_option_value') return ValueTypes.JSON;
	return ValueTypes.STRING;
}

export function shouldShowProperty(propertyDefinition: IDatasetPropertyDefinition, options: IBuildOptions): boolean{
	if(!propertyDefinition) return false;
	if(options.only && options.only.length > 0) {
		const index = options.only.indexOf(propertyDefinition.code);
		if(index !== -1) return true;
	}
	if(options.exclude && options.exclude.length > 0) {
		const index = options.exclude.indexOf(propertyDefinition.code);
		if(index !== -1) return false;
	}
	return true;
}

export function filterProperties(targets: IDatasetPropertyDefinition[], record: any): any[]{
	if(!record) return targets;
	if(!targets) return null;
	return targets.filter( target => {
		const filter = JSON.parse(target.target_filters);
		if(!filter) return true;
		for (const key in filter) {
			if (!Object.prototype.hasOwnProperty.call(filter, key)) continue;
			const value = filter[key];
			const splittendKeys = key.split('.');
			let recordValue = record;
			for (const sKey of splittendKeys) {
				if(!recordValue) break;
				recordValue = recordValue[sKey];
			}
			if(value !== recordValue) return false;
		}
		return true;
	});
}

export function buildPropertyFieldFrom(propertyDefinition: IDatasetPropertyDefinition): IFieldDefinition{
	const fieldDefinition: IFieldDefinition = {
		key: propertyDefinition.code,
		name: propertyDefinition.name,
		description: propertyDefinition.description,
		inputType: InputTypes.PROPERTY,
		valueType: ValueTypes.PROPERTY,
		options: propertyDefinition.property_value_options,
		propertyDefinition
	};
	return fieldDefinition;
}

export function buildPropertyFormFields(propertyDefinitions: IDatasetPropertyDefinition[], record: any, options: IBuildOptions): IFormConfig{
	const fields = [];
	if(!propertyDefinitions) return {fields: []};
	propertyDefinitions = filterProperties(propertyDefinitions, record);
	let fieldContainer;
	for (let index = 0; index < propertyDefinitions.length; index++) {
		if(index % 4 === 0){
			fieldContainer = {fields:[]};
			fields.push(fieldContainer);
		}
		const propertyDefinitionTarget =  propertyDefinitions[index];
		if(!shouldShowProperty(propertyDefinitionTarget, options)) continue;
		const fieldDefinition = buildPropertyFieldFrom(propertyDefinitionTarget);
		if(fieldDefinition) fieldContainer.fields.push(fieldDefinition);
		
	}
	return {fields};
}