import * as tslib_1 from "tslib";
import { NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { InputTypes, ValueTypes, isFieldContainer } from 'app/interfaces';
import { FormBuilder, FormControl } from '@angular/forms';
import { FieldHelper } from 'app/helpers';
import { formGroupToFormData } from 'app/helpers/field.helper';
import * as _ from 'lodash';
var FormViewerService = /** @class */ (function () {
    function FormViewerService(appService, formBuilder, zone) {
        var _this = this;
        this.appService = appService;
        this.formBuilder = formBuilder;
        this.zone = zone;
        this.useMultipart = false;
        this.inputTypes = InputTypes;
        this.controlKeyMap = {};
        this.readOnly = false;
        this._unsubscribeAll = new Subject();
        this.valueChanges = new Subject();
        this.initialized = new BehaviorSubject(false);
        this.formBuilded = new Subject();
        this.formBuilded
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this.valueChangesSubscription)
                _this.valueChangesSubscription.unsubscribe();
            _this.valueChangesSubscription = _this.formGroup.valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .pipe(distinctUntilChanged(_.isEqual))
                .subscribe(function (value) {
                _this.valueChanges.next(value);
            });
        });
    }
    FormViewerService.prototype.init = function (config) {
        var _this = this;
        if (config.dataContainerService)
            this.dataContainerService = config.dataContainerService;
        if (config.record)
            this.record = config.record;
        if (config.fields)
            this.fields = config.fields;
        this.buildForm();
        if (config.errors) {
            this.setErrors(config.errors);
        }
        if (this.dataContainerService) {
            this.dataContainerService.datasetDataChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function () {
                _this.buildForm();
            });
        }
        setTimeout(function () {
            _this.initialized.next(true);
            _this.initialized.complete();
        }, 50);
        return this.initialized;
    };
    FormViewerService.prototype.getFieldControl = function (key) {
        var controlKey = _.get(this.controlKeyMap, key);
        if (!controlKey)
            return;
        var control = _.get(this.formGroup.controls, controlKey);
        return control;
    };
    FormViewerService.prototype.getFieldControlKey = function (field) {
        if (!field)
            return null;
        var fieldName = field.key;
        if (field.valueType === ValueTypes.PROPERTY) {
            if (this.record && this.record.properties && this.record.properties[field.key]) {
                fieldName = this.record.properties[field.key].property_definition_id;
            }
            else {
                var propertyDefinition = FieldHelper.getPropertyDefinition(this.dataContainerService, field.key, this.dataContainerService.getValueFromKeyPath('datasetCode'));
                if (propertyDefinition) {
                    fieldName = propertyDefinition.id;
                }
            }
        }
        return fieldName;
    };
    FormViewerService.prototype.buildForm = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            var formGroup = _this.formBuilder.group({});
            _this.useMultipart = false;
            _this.addGroupConfig(formGroup, _this.fields);
            _this.zone.run(function () {
                _this.formGroup = formGroup;
                _this.formBuilded.next(_this.formGroup);
            });
        });
    };
    FormViewerService.prototype.addGroupConfig = function (formGroup, fields, noDuplicateField) {
        var e_1, _a;
        if (noDuplicateField === void 0) { noDuplicateField = false; }
        if (!fields)
            return;
        var _loop_1 = function (field) {
            if (!field) {
                console.warn('undefined field in form config');
                return "continue";
            }
            if (isFieldContainer(field)) {
                this_1.addGroupConfig(formGroup, field.fields);
                return "continue";
            }
            else {
                if (field && field.inputConfig && field.inputConfig.type === InputTypes.READONLY)
                    return "continue";
                if (typeof (field.skipIf) == 'function' && field.skipIf(this_1.record, this_1.dataContainerService))
                    return "continue";
                var fieldName_1 = this_1.getFieldControlKey(field);
                if (formGroup.controls[fieldName_1]) {
                    if (noDuplicateField)
                        return "continue";
                    console.warn('form controll just exist with this name', fieldName_1);
                }
                var defaultValue = void 0;
                if (field.defaultValue) {
                    if (field.optionSource && this_1.dataContainerService.hasValueInKeyPath(field.optionSource)) {
                        defaultValue = field.defaultValue(this_1.dataContainerService, this_1.dataContainerService.getValueFromKeyPath(field.optionSource + '.' + field.optionSourceKey), this_1.record);
                    }
                    else {
                        defaultValue = field.defaultValue(this_1.dataContainerService, null, this_1.record);
                    }
                }
                var value = FieldHelper.getFieldValue(field, this_1.record, defaultValue, this_1.dataContainerService);
                var control = new FormControl(value, field.formValidators);
                this_1.controlKeyMap[field.key] = fieldName_1;
                if (field.inputType === InputTypes.FILE || field.inputType === InputTypes.MULTIPLE_FILE) {
                    this_1.useMultipart = true;
                }
                if (field.onValueChanged) {
                    var subscription_1;
                    subscription_1 = control.valueChanges
                        .pipe(takeUntil(this_1._unsubscribeAll))
                        .subscribe(function () {
                        if (!formGroup.controls[fieldName_1]) {
                            subscription_1.unsubscribe();
                            return;
                        }
                        field.onValueChanged(formGroup);
                    });
                }
                formGroup.addControl(fieldName_1, control);
            }
        };
        var this_1 = this;
        try {
            for (var fields_1 = tslib_1.__values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                var field = fields_1_1.value;
                _loop_1(field);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     * If use multipart return FormData else an object
     */
    FormViewerService.prototype.getFormData = function () {
        if (this.useMultipart) {
            var formData = formGroupToFormData(this.formGroup);
            return formData;
        }
        else {
            return this.formGroup.getRawValue();
        }
    };
    FormViewerService.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    FormViewerService.prototype.clearForm = function (value) {
        if (this.formGroup)
            this.formGroup.reset(value);
    };
    FormViewerService.prototype.getFieldError = function (fieldDefinition, errors) {
        var fieldKey = this.getFieldControlKey(fieldDefinition);
        var error = errors && errors[fieldKey] && errors[fieldKey][0];
        if (error)
            return error;
        // check if there is an error with property code
        if (fieldDefinition.valueType === ValueTypes.PROPERTY) {
            var propError = errors && errors[fieldDefinition.key] && errors[fieldDefinition.key][0];
            if (propError)
                return propError;
        }
    };
    FormViewerService.prototype.setFieldsErrors = function (fields, errors) {
        var e_2, _a;
        if (!fields)
            return;
        try {
            for (var fields_2 = tslib_1.__values(fields), fields_2_1 = fields_2.next(); !fields_2_1.done; fields_2_1 = fields_2.next()) {
                var field = fields_2_1.value;
                if (isFieldContainer(field)) {
                    this.setFieldsErrors(field.fields, errors);
                    continue;
                }
                else {
                    var fieldKey = this.getFieldControlKey(field);
                    var control = this.formGroup.controls[fieldKey];
                    if (!control) {
                        continue;
                    }
                    var error = this.getFieldError(field, errors);
                    if (!error) {
                        control.setErrors(null);
                    }
                    else {
                        control.setErrors({ invalid: error });
                    }
                    control.markAsTouched();
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (fields_2_1 && !fields_2_1.done && (_a = fields_2.return)) _a.call(fields_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    FormViewerService.prototype.getFormErrors = function () {
        return Object.entries(this.formGroup.controls).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], control = _b[1];
            return {
                key: key,
                errors: control.errors
            };
        });
    };
    FormViewerService.prototype.setErrors = function (errors) {
        if (!this.fields)
            return;
        this.setFieldsErrors(this.fields, errors);
        this.formGroup.markAsTouched();
    };
    FormViewerService.prototype.setRecord = function (record) {
        this.record = record;
        if (record) {
            this.buildForm();
        }
        else
            this.clearForm();
    };
    FormViewerService.prototype._getFieldDefinition = function (key, fields) {
        var e_3, _a;
        if (!key || !fields)
            return null;
        try {
            for (var fields_3 = tslib_1.__values(fields), fields_3_1 = fields_3.next(); !fields_3_1.done; fields_3_1 = fields_3.next()) {
                var field = fields_3_1.value;
                if (isFieldContainer(field)) {
                    var found = this._getFieldDefinition(key, field.fields);
                    if (found)
                        return found;
                }
                else {
                    if (field.key === key)
                        return field;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (fields_3_1 && !fields_3_1.done && (_a = fields_3.return)) _a.call(fields_3);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return null;
    };
    FormViewerService.prototype.getFieldDefinition = function (key) {
        return this._getFieldDefinition(key, this.fields);
    };
    FormViewerService.prototype.setInputValue = function (key, value, options) {
        var controlKey = _.get(this.controlKeyMap, key);
        if (!controlKey)
            return;
        var control = _.get(this.formGroup.controls, controlKey);
        if (!control)
            return;
        // do same for boolean? maybe need a test
        if (typeof (value) == 'number')
            control.setValue(value, _.get(options, 'setValueOptions'));
        else
            control.setValue(value || null, _.get(options, 'setValueOptions')); // null prevet set undefined
        if (options && options.touch)
            control.markAsTouched();
    };
    FormViewerService.prototype.setValues = function (values, options) {
        var e_4, _a;
        try {
            for (var _b = tslib_1.__values(Object.entries(values)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                this.setInputValue(key, value, options);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    return FormViewerService;
}());
export { FormViewerService };
