import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FlexModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OtherSolutionComponent } from './other-solution.component';
import { FormInputViewerModule } from 'app/main/components/form-input-viewer/form-input-viewer.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormViewerModule } from 'app/main/components/form-viewer/form-viewer.module';
import { SearchTravellersModule } from '../../search-travellers/search-travellers.module';


@NgModule({
	declarations: [
		OtherSolutionComponent
	],
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressBarModule,
		FormViewerModule,
		MatButtonModule,
		TranslateModule,
		MatExpansionModule,
		MatIconModule,
		MatDividerModule,
		MatTableModule,
		FlexModule,
		MatTooltipModule,
		MatCardModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatTabsModule,
		SearchTravellersModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatSelectModule,
		MatMenuModule,
		MatStepperModule,
		MatDividerModule,
	],
	exports: [OtherSolutionComponent],
	
})
export class TrenitaliaOtherSolutionModule {}
