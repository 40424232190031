import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseDatasetComponent } from '../base-dataset.component';
import { AuthService } from 'app/services/auth.service';
import { of } from 'rxjs';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { takeUntil } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';
var DatasetDetailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetDetailComponent, _super);
    function DatasetDetailComponent(route, datasetService, router, authService, datasetACS, datasetRS, pageClosureContainer, datasetNavigatorService, _fuseTranslationLoaderService, webSocketService) {
        var _this = _super.call(this, route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.pageClosureContainer = pageClosureContainer;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.webSocketService = webSocketService;
        _this.stepViewConfig = TripPackageStepConfigSmartInterface;
        _this.productStepViewConfig = ProductStepViewConfig;
        _this.actionCode = 'detail';
        _this.showActions = true;
        _this.backTitle = 'Torna';
        return _this;
    }
    DatasetDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.inputRecordId)
            this.datasetRS.recordId = this.inputRecordId;
        if (this.inputRecord)
            this.datasetRS.record.next(this.inputRecord);
        _super.prototype.ngOnInit.call(this);
        this.datasetACS.datasetRS = this.datasetRS;
        this.route.queryParamMap
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (queryParamMap) {
            if (queryParamMap.has('back_title')) {
                _this.backTitle = queryParamMap.get('back_title');
            }
            else {
                _this.backTitle = 'Torna';
            }
            if (queryParamMap.has('back_to_dataset')) {
                if (queryParamMap.has('back_to_record_id')) {
                    _this.backRouteLink = _this.datasetNavigatorService.getDetailRoute(queryParamMap.get('back_to_dataset'), queryParamMap.get('back_to_record_id'));
                }
                else {
                    _this.backRouteLink = _this.datasetNavigatorService.getListRoute(queryParamMap.get('back_to_dataset'));
                }
            }
            else {
                _this.backRouteLink = null;
            }
            if (queryParamMap.has('back_query_params')) {
                _this.backQueryParams = JSON.parse(queryParamMap.get('back_query_params'));
            }
            else {
                _this.backQueryParams = null;
            }
        });
    };
    DatasetDetailComponent.prototype.load = function () {
        if (this.firstLoad && this.datasetRS.record.value) {
            return of(this.datasetRS.record.value);
        }
        else {
            return this.datasetRS.reload();
        }
    };
    return DatasetDetailComponent;
}(BaseDatasetComponent));
export { DatasetDetailComponent };
