import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	//fieldsMap.get('code'),
	fieldsMap.get('display_name'),
	fieldsMap.get('status'),
	fieldsMap.get('stripe_fee')
];

const config: IFormConfig = { 
	title: 'Modifica Dominio',
	editTitle: 'Modifica Dominio',
	fields: formFields 
};

export default config;
