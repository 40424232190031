import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('slug'),
	fieldsMap.get('title'),
	// fieldsMap.get('description')
];

const config: IFormConfig = { 
	title: 'Crea Servizio',
	editTitle: 'Modifica Servizio',
	fields: formFields,
};

export default config;
