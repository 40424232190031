import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as navigationEnglish } from './i18n/en';
import { locale as navigationItaliano } from './i18n/it';

import { WebSocketService } from './services/websocket.service';
import { defaultNavigation } from './configs/navigations/default.navigation';
import { adminNavigation } from './configs/navigations/admin.navigation';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth.service';

@Component({
	selector   : 'app',
	templateUrl: './app.component.html',
	styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
	fuseConfig: any;
	/**
	 * @deprecated
	 */
	navigation: any;
	/**
	 * @deprecated
	 */
	adminNavigation: any;

	// Private
	private _unsubscribeAll: Subject<any>;
	private navigationSubscription: Subscription;

	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseNavigationService} _fuseNavigationService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {FuseSplashScreenService} _fuseSplashScreenService
	 * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
	 * @param {Platform} _platform
	 * @param {TranslateService} _translateService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService,
		private _fuseSidebarService: FuseSidebarService,
		private _fuseSplashScreenService: FuseSplashScreenService,
		private _fuseTranslationLoaderService: FuseTranslationLoaderService,
		private _translateService: TranslateService,
		private _platform: Platform,
		private router: Router,
		private appService: AppService,
		private authService: AuthService,
		private webSocketService: WebSocketService
	)
	{
		// Get default navigation
		this.navigation = defaultNavigation;
		this.adminNavigation = adminNavigation;

		// Register the navigation to the service
		// this._fuseNavigationService.register('admin', this.adminNavigation);
		// this._fuseNavigationService.register('main', this.navigation);
		this._fuseNavigationService.register('user', []);

		// Set the main navigation as our current navigation
		this._fuseNavigationService.setCurrentNavigation('user');
		
		// Add languages
		this._translateService.addLangs(['en', 'it']);

		// Set the default language
		this._translateService.setDefaultLang('it');

		// Set the navigation translations
		this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationItaliano);

		// Use a language
		let lang = localStorage.getItem('locale');
		if (!lang) lang = 'it';
		this._translateService.use(lang);

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix Start
		 * ----------------------------------------------------------------------------------------------------
		 */

		/**
		 * If you are using a language other than the default one, i.e. Turkish in this case,
		 * you may encounter an issue where some of the components are not actually being
		 * translated when your app first initialized.
		 *
		 * This is related to ngxTranslate module and below there is a temporary fix while we
		 * are moving the multi language implementation over to the Angular's core language
		 * service.
		 **/

		// Set the default language to 'en' and then back to 'tr'.
		// '.use' cannot be used here as ngxTranslate won't switch to a language that's already
		// been selected and there is no way to force it, so we overcome the issue by switching
		// the default language back and forth.
		/**
		 setTimeout(() => {
			this._translateService.setDefaultLang('en');
			this._translateService.setDefaultLang('tr');
		 });
		 */

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix End
		 * ----------------------------------------------------------------------------------------------------
		 */

		// Add is-mobile class to the body if the platform is mobile
		if ( this._platform.ANDROID || this._platform.IOS )
		{
			this.document.body.classList.add('is-mobile');
		}

		this.navigationSubscription = this.router.events.subscribe(event => {
			this.appService.toolbarPageActions = [];
			if( event instanceof NavigationStart ){
				if(event.url === 'auth/logout'){
					this.authService.logout();
					this.router.navigate(['auth/login']);
					return false;
				}
				this.appService.showAppInterface.next(true);
			}
		});

		this.authService.init();
		this.authService.check();

		this.authService.userDataObserver.subscribe(data => {
			if(!data){
				this.webSocketService.clear();
			}
		});

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{

		window['tinyMCE'].overrideDefaults({
			base_url: '/tinymce/',  // Base for assets such as skins, themes and plugins
			suffix: '.min'          // This will make Tiny load minified versions of all its assets
		});

		// Subscribe to config changes
		this._fuseConfigService.config
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((config) => {

			this.fuseConfig = config;

			// Boxed
			if ( this.fuseConfig.layout.width === 'boxed' ){
				this.document.body.classList.add('boxed');
			}else{
				this.document.body.classList.remove('boxed');
			}

			// Color theme - Use normal for loop for IE11 compatibility
			for ( let i = 0; i < this.document.body.classList.length; i++ ){
				const className = this.document.body.classList[i];

				if ( className.startsWith('theme-') ){
					this.document.body.classList.remove(className);
				}
			}

			this.document.body.classList.add(this.fuseConfig.colorTheme);
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void
	{
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}
}
