import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('code'),
			fieldsMap.get('description')
		]
	}
];

const config: IFormConfig = { 
	editTitle: 'Filtri',
	fields: formFields 
};

export default config;
