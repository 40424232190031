import {Component, Input, OnChanges,OnInit, SimpleChanges} from '@angular/core';
import {TrenitaliaService} from '../trenitalia.service';
import {Subject} from 'rxjs';
import * as _ from "lodash";

@Component({
  selector: 'trenitalia-recap',
  templateUrl: './trenitalia-recap.component.html',
  styleUrls: ['./trenitalia-recap.component.scss']
})
export class TrenitaliaRecapComponent implements OnInit,OnChanges{
    protected _unsubscribeAll: Subject<any>;
    @Input()start:any;
    @Input()end:any;
    @Input()departure:any;
    @Input()arrive:any;
    @Input()tot:any;
    @Input()direction:any;
    @Input()adult:any;
    @Input()children:any;
    @Input()fw=false;
    @Input()dataAv:any;
    @Input()servicesMap:any;
    @Input()trains:any
    @Input()offeredServices:any;
    @Input()selectedSolution:any;
    public dataRecap:any;
    public showDetails=false;

  constructor(public trnService:TrenitaliaService) {
      this._unsubscribeAll = new Subject();
  }

  ngOnInit(){
  }

    ngOnChanges(changes: SimpleChanges) {
      if(changes){

          this.dataRecap = {
              start: changes.start?this.trnService.getStationDataFromId(parseInt(changes.start.currentValue)):this.dataRecap.start,
              end: changes.end?this.trnService.getStationDataFromId(parseInt(changes.end.currentValue)):this.dataRecap.end,
              departure:changes.departure?changes.departure.currentValue:this.dataRecap.departure,
              arrive: changes.arrive?changes.arrive.currentValue:this.dataRecap.arrive,
              tot: changes.tot?changes.tot.currentValue.value:this.dataRecap.tot?this.dataRecap.tot.value:0,
              totNet:changes.tot?changes.tot.currentValue.valueNet:this.dataRecap.tot?this.dataRecap.tot.valueNet:0,
              fee:changes.tot?changes.tot.currentValue.fee:this.dataRecap.tot?this.dataRecap.tot.fee:0,
              direction: this.direction,
              adult: this.adult,
              children: this.children,
              details: this.updateDetails()
          }

      }
    }

    updateDetails(){

      if(!this.dataAv || !this.offeredServices)return null;
        const travelSolutionTickets = {};
        for(const off of this.dataAv.selectedOffer){
            const id = off.id || off.xmlId;
            const offeredService = _.get(this.offeredServices,id);
            if(!_.has(travelSolutionTickets, offeredService.nodeId)){
                travelSolutionTickets[offeredService.nodeId] = {tickets: []};
            }
            let seatInfo = null;
            if(this.dataAv.seatMap){
                let s = this.dataAv.seatMap.find((s: { offeredServicesId: any; })=>s.offeredServicesId==offeredService.id);
                if(s){
                    seatInfo = {wagon:s.wagon,seat:s.airCraftNumber}
                }
            }
            const traveller = this.dataAv.travellers.find((t: { xmlId: any; })=>t.xmlId==offeredService.travellerXmlId);
            const param = _.get(offeredService, 'original.parameters',[]);
            let position :any;
            if(_.isArray(param)) {
                const parameters = param.find(p => p.typeId == 2);
                position = parameters?parameters.value:null;
            }

            travelSolutionTickets[offeredService.nodeId].tickets.push({
                traveller,
                catalogServiceName: _.get(this.trnService.servicesMap.value, ['catalog_services',offeredService.catalogServiceId,'display_name']),
                offerName: _.get(this.trnService.servicesMap.value, ['offers',offeredService.offerId,'display_name']),
                position: position,
                offeredService,
                prices: this.getPrices(offeredService.offerId,offeredService.catalogServiceId,traveller.xmlId,offeredService.nodeId),
                seatInfo,
            });
        }
        return travelSolutionTickets;

    }

    onShowDetails(){
      this.showDetails= this.showDetails!==true;
    }

    getPrices(offerId,serviceId,traveller,train){
      if(_.get(this.selectedSolution,'offers_map')){
          const services:any = _.get(this.selectedSolution,['offers_map',train,'travellers',traveller]);
          if(services){
              const service = services.find((s: { id: any; })=>s.id==serviceId);
              if (service){
                  const prices = service['offers'].find(s=>s.offerId == offerId);
                  return {
                      price:prices.totalPrice,
                      netPrice:prices.totalPriceNet,
                      fee:prices.fee
                  }
              }
          }
      }else{
          const prices:any = _.get(this.selectedSolution,['priceGrid',train,'matrix',offerId,serviceId])
          if(!prices) return null;
          const travellers = this.selectedSolution.travellers.length;
          return {
              price:prices.totalPrice/travellers,
              netPrice:prices.totalPriceNet/travellers,
              fee:prices.fee/travellers
          }
      }
    }
}
