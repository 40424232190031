import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { CalendarEventModel } from 'app/main/pages/calendar/event.model';
import { DatasetCalendarViewComponent } from 'app/main/components/dataset/base-actions/list/views/calendar/calendar.component';
import { DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetCommandDialogComponent, IDatasetCommandDialogData } from 'app/main/components/dataset/dialogs/dataset-command-dialog/dialog.component';
import commandAssignFormConfig from './command-assign-form.config';
import { CalendarMonthViewDay, CalendarEvent } from 'angular-calendar';
import { convertToUTCDay } from 'app/helpers/date.helper';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_resource_staff_assignments/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	if(!params.trip_resource_id) params.trip_resource_id = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
	
	return params;
}

function calendarOnAdd(calendarComponent: DatasetCalendarViewComponent, date?: Date): void{
	const commandDialogdata: IDatasetCommandDialogData = {
		datasetACS: calendarComponent.datasetACS,
		postData: {
			trip_resource_id: calendarComponent.datasetACS.getValueFromKeyPath('trip_resources.recordId')
		},
		formConfig: commandAssignFormConfig,
		url: '/dataset/trip_resource_staff_assignments/command/assign_in_range'
	};
	if(date){
		commandDialogdata['initialValues'] = {
			from: date,
			to: date
		};
	}
	calendarComponent.matDialog.open(DatasetCommandDialogComponent, {
		data: commandDialogdata
	}).afterClosed()
	.subscribe(result => {
		if(result){
			calendarComponent.datasetACS.datasetEvent.next({
				eventName: DatasetEvents.ACTION_REALOAD_LIST
			});
		}
	});
}

export const TripResourceStaffAssignmentsConfig: IDatasetConfig = {
	name: 'trip_resource_staff_assignments',
	title: 'Calendario Staff',
	singleTitle: 'Calendario Staff',
	ajaxDatasetCode: 'trip_resource_staff_assignments',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: false,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.CALENDAR,
				showSearchBar: true,
				calendarConfig: {
					itemsToEvents(items: any[], component: DatasetCalendarViewComponent): CalendarEventModel[]{
						if(!items) return [];
						return items.map(item => {
							const calendarEvent = new CalendarEventModel(undefined, component.datasetNavigatorService, component);
							calendarEvent.fromTripResourceStaffAssignment(item);
							return calendarEvent;
						});
					},
					onDayClicked(calendarComponent: DatasetCalendarViewComponent, day: CalendarMonthViewDay): void{
						const date: Date = day.date;
						const utcDate = convertToUTCDay(date);

						calendarOnAdd(calendarComponent, utcDate.toISOString());
					},
					onAdd(calendarComponent: DatasetCalendarViewComponent): void{
						calendarOnAdd(calendarComponent);
					},
					openCellDetail: true,
					templateCodes: {
						eventTitleTemplate: 'simple-text',
						eventActionsTemplate: 'default_actions',
						monthCellTemplate: 'text',
						// monthOpenDayEventsTemplate: 'empty'
					}
				}
			}
		}
	}
};
