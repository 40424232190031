<div class='trip-booking-participant-column' fxLayout="column">
	<ng-container *ngIf='item'>
		<div class="participant-row" fxLayout="row" fxLayoutAlign="space-between center" (click)='select()'>
			<div fxLayout="row" fxLayoutAlign="start center">
				<span class='ml-4 info_participant' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
					<span class="participant_description">{{item.participant_description || item.age_range_code}}</span>
				</span>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button class="page_view_btn" mat-icon-button color='accent' matTooltip='Dettaglio Partecipante'>
					<mat-icon>arrow_forward_ios</mat-icon>
				</button>
			</div>
		</div>
	</ng-container>
</div>