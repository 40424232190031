import { TripResourceConfig } from './trip_resources';
import { ReviewConfig } from './reviews';
import { TripPackageConfig } from './trip_packages';
import { ProductsConfig } from './products';
import { PublicTripPackageConfig } from './public_trip_packages';
import { TripDestinationConfig } from './trip_destinations';
import { MeetingPointConfig } from './meeting_points';
import { UnifiedAddressConfig } from './unified_addresses';
import { UserConfig } from './users';
import { DomainUsersConfig } from './domain_users';
import { DomainConfigs } from './domain_configs';
import { UserRoleConfig } from './user_roles';
import { DomainsUserRolesConfig } from './domain_user_roles';
import { EntityConfig } from './entities';
import { TripCategoryConfig } from './trip_categories';
import { SaleOperationConfig } from './sale_operations';
import { TripFacilityConfig } from './trip_facilities';
import { TripServiceRuleConfig } from './trip_service_rules';
import { TripMeetingPointConfig } from './trip_meeting_points';
import { TripPackageLanguageContentConfig } from './trip_package_language_contents';
import { PivotTripPackagesTripFacilitiesConfig } from './pivot_trip_packages_trip_facilities';
import { ActualTripConfig } from './actual_trips';
import { PivotTripPackagesTripCategoriesConfig } from './pivot_trip_packages_trip_categories';
import { TripPackageResourcesConfig } from './trip_package_resources';
import { PivotTripResourcesTripMeetingPointConfig } from './pivot_trip_resources_trip_meeting_points';
import { TripResourceAllotmentConfig } from './trip_resource_allotments';
import { TripResourceLanguageContentConfig } from './trip_resource_language_contents';
import { TripServiceConfig } from './trip_services';
import { ServiceConfig } from './services';
import { BranchesConfig } from './branches';
import { EntityCustomerConfig } from './entity_customers';
import { PriceRuleConfig } from './price_rules';
import { TripCategoryLanguageContentConfig } from './trip_category_language_contents';
import { TripDestinationLanguageContentConfig } from './trip_destination_language_contents';
import { DiscountRuleListConfig } from './discount_rule_lists';
import { DiscountRuleConfig } from './discount_rules';
import { EntityStaffElementConfig } from './entity_staff_elements';
import { EntitySupplierConfig } from './entity_suppliers';
import { EntityTourOperatorConfig } from './entity_tour_operators';
import { TripBookingConfig } from './trip_bookings';
import { TripBookingParticipantConfig } from './trip_booking_participants';
import { TripBookingPackageConfig } from './trip_booking_packages';
import { TripBookingResourceConfig } from './trip_booking_resources';
import { TripBookingServiceConfig } from './trip_booking_services';
import { PivotCarouselConfig } from './pivot_trip_package_language_contents_carousel_images';
import { PivotProductsCarouselConfig } from './pivot_trip_resource_language_contents_carousel_images';
import { TripBookingResourceParticipantExtraConfig } from './trip_booking_resource_participant_extras';
import { TripStructureConfig } from './trip_structures';
import { TripBookingParticipantExtraConfig } from './trip_booking_participant_extras';
import { MeetingPointListConfig } from './meeting_point_lists';
import { MeetingPointUnifiedListItemsConfig } from './meeting_point_unified_list_items';
import { MeetingPointTripPackageConfig } from './meeting_point_trip_packages';
import { TripFacilityLanguageContentConfig } from './trip_facility_language_contents';
import { PivotTripPackagesTripDestinationsConfig } from './pivot_trip_package_trip_destinations';
import { PivotTripRelatedPackagesConfig } from './pivot_trip_related_packages';
import { ActualTripResourcesConfig } from './actual_trip_resources';
import { EntityStaffElementUnifiedListItemsConfig } from './entity_staff_element_unified_list_items';
import { EntityStaffElementUnifiedListItemsForActualTripsConfig } from './entity_staff_element_unified_list_items_for_actual_trips';
import { GoingAndReturnConfig } from './going_and_returns';
import { TripResourceStaffAssignmentsConfig } from './trip_resource_staff_assignments';
import { EntityCalendarEventConfig } from './entity_calendar_events';
import { MeetingPointLanguageContentConfig } from './meeting_point_language_contents';
import { TripBookingReportConfig } from './trip_booking_reports';
import { DiscountConfig } from './discounts';
import { DomainsConfig } from './domains';
import { DomainsInfoConfig } from './domains_info';
import { TurnoverReportConfig } from './turnover_reports';
import { PublicDomainsConfig } from './public_domains';
import { TripBookingDiscountConfig } from './trip_booking_discounts';
import { TripOptionsConfig } from './trip_options';
import { AvailabilitiesConfig } from './availabilities';
import { TripPackageIntegrationsConfig } from './trip_package_integrations';
import { TripCategoryIntegrationsConfig } from './trip_category_integrations';
import { MeetingPointListTOReferenceConfig } from './meeting_point_list_to_references';
import { EntityCustomerTripPackageConfig } from './entity_customer_trip_packages';
import { EntityCustomerTripCategoryConfig } from './entity_customer_trip_categories';
import { VoucherSettingLangContentsConfig } from './voucher_setting_language_contents';
import { DomainPackages } from './domain_packages';
import { DomainsPackagesLog } from './domains_packages_log';
import { PaymentTypeConfig } from './payment_types';
import { TourCMSTourConfig } from './tourcms_tours';
import { BokunTourConfig } from './bokun_tours';
import { ActualTripGroupConfig } from './actual_trip_groups';
import { ActualTripGroupResourcesConfig } from './actual_trip_group_resources';
import { PlafondConfig } from './plafond';
import { PlafondDropItemConfig } from './plafond_drop_items';
import { TripBookingLogConfig } from './trip_booking_logs';
import { PlafondLogConfig } from './plafond_logs';
import { PassportConfig } from './passport';
import { TripBookingReportViewConfig } from './trip_booking_report_views';
import { TransmissionOperationConfig } from './transmission_operations';
import { EntityStaffUserConfiguration } from './entity_staff_users';
import { TripPackageCustomerSettings } from './trip_package_customer_settings';
import { TripResourceMeetingPointTicketConfig } from './trip_resource_meeting_point_tickets';
import { TripResourceTicketFileConfig } from './trip_resource_ticket_files';
import { BulkDeliveryCodeConfig } from './bulk_delivery_code';
import { PivotTripResourcesTripDestinationsConfig } from './pivot_trip_resources_trip_destinations';
import { TripOptionsForVirtualTripsConfig } from './trip_options_for_virtual_trips';
import { AvailabilitiesForVirtualTripsConfig } from './availabilities_for_virtual_trips';
import {TicketlandiaTourConfig} from "./ticketlandia_tours";
import {PivotTripResourceTripResourcesConfig} from "./pivot_trip_resource_trip_resources";

export default {
	'actual_trip_group_trip_resources': ActualTripGroupResourcesConfig,
	'actual_trip_groups': ActualTripGroupConfig,
	'actual_trip_resources': ActualTripResourcesConfig,
	'actual_trips': ActualTripConfig,
	'availability': AvailabilitiesConfig,
	'availabilities_for_virtual_trips': AvailabilitiesForVirtualTripsConfig,
	'branches': BranchesConfig,
	'discounts': DiscountConfig,
	'domains': DomainsConfig,
	'domains_info': DomainsInfoConfig,
	'turnover_reports': TurnoverReportConfig,
	'public_domains': PublicDomainsConfig,
	'discount_rule_lists': DiscountRuleListConfig,
	'discount_rules': DiscountRuleConfig,
	'entities': EntityConfig,
	'entity_calendar_events': EntityCalendarEventConfig,
	'entity_customer_trip_packages': EntityCustomerTripPackageConfig,
	'entity_customer_trip_categories': EntityCustomerTripCategoryConfig,
	'entity_customers': EntityCustomerConfig,
	'entity_staff_elements': EntityStaffElementConfig,
	'entity_staff_element_unified_list_items': EntityStaffElementUnifiedListItemsConfig,
	'entity_staff_element_unified_list_items_for_actual_trips': EntityStaffElementUnifiedListItemsForActualTripsConfig,
	'entity_suppliers': EntitySupplierConfig,
	'entity_tour_operators': EntityTourOperatorConfig,
	'going_and_returns': GoingAndReturnConfig,
	'meeting_point_language_contents': MeetingPointLanguageContentConfig,
	'meeting_point_list_to_references': MeetingPointListTOReferenceConfig,
	'meeting_point_unified_lists': MeetingPointListConfig,
	'meeting_point_unified_list_items': MeetingPointUnifiedListItemsConfig,
	'meeting_point_trip_packages': MeetingPointTripPackageConfig,
	'meeting_points': MeetingPointConfig,
	'payment_types': PaymentTypeConfig,
	'pivot_trip_packages_trip_destinations': PivotTripPackagesTripDestinationsConfig,
	'pivot_trip_packages_trip_facilities': PivotTripPackagesTripFacilitiesConfig,
	'pivot_trip_packages_trip_categories': PivotTripPackagesTripCategoriesConfig,
	'pivot_trip_related_packages': PivotTripRelatedPackagesConfig,
	'pivot_trip_resources_trip_meeting_points': PivotTripResourcesTripMeetingPointConfig,
	'pivot_trip_resources_trip_destinations': PivotTripResourcesTripDestinationsConfig,
	'pivot_trip_resource_trip_resources':PivotTripResourceTripResourcesConfig,
	'price_rules': PriceRuleConfig,
	'services': ServiceConfig,
	'trip_bookings': TripBookingConfig,
	'trip_booking_discounts': TripBookingDiscountConfig,
	'trip_booking_report_views': TripBookingReportViewConfig,
	//'trip_booking_reports': TripBookingReportConfig,
	'trip_booking_participants': TripBookingParticipantConfig,
	'trip_booking_resource_participant_extras': TripBookingResourceParticipantExtraConfig,
	'trip_booking_participant_extras': TripBookingParticipantExtraConfig,
	'trip_booking_packages': TripBookingPackageConfig,
	'trip_booking_resources': TripBookingResourceConfig,
	'trip_booking_services': TripBookingServiceConfig,
	'trip_categories': TripCategoryConfig,
	'sale_operations': SaleOperationConfig,
	'trip_category_language_contents': TripCategoryLanguageContentConfig,
	'trip_destinations': TripDestinationConfig,
	'trip_destination_language_contents': TripDestinationLanguageContentConfig,
	'trip_facilities': TripFacilityConfig,
	'trip_facility_language_contents': TripFacilityLanguageContentConfig,
	'trip_meeting_points': TripMeetingPointConfig,
	'trip_resources': TripResourceConfig,
	'trip_resource_allotments': TripResourceAllotmentConfig,
	'trip_resource_language_contents': TripResourceLanguageContentConfig,
	'trip_resource_staff_assignments': TripResourceStaffAssignmentsConfig,
	'trip_options': TripOptionsConfig,
	'trip_options_for_virtual_trips': TripOptionsForVirtualTripsConfig,
	'trip_package_language_contents': TripPackageLanguageContentConfig,
	'trip_package_language_contents_carousel_images': PivotCarouselConfig,
	'trip_resource_language_contents_carousel_images': PivotProductsCarouselConfig,
	'trip_packages': TripPackageConfig,
	'trip_package_customer_settings': TripPackageCustomerSettings,
	'products': ProductsConfig,
	'public_trip_packages': PublicTripPackageConfig,
	'reviews': ReviewConfig,
	'trip_package_resources': TripPackageResourcesConfig,
	'trip_package_integrations': TripPackageIntegrationsConfig,
	'trip_category_integrations': TripCategoryIntegrationsConfig,
	'trip_service_rules': TripServiceRuleConfig,
	'trip_services': TripServiceConfig,
	'trip_structures': TripStructureConfig,
	'tourcms_tours': TourCMSTourConfig,
	'bokun_tours': BokunTourConfig,
	'ticketlandia_tours': TicketlandiaTourConfig,
	'unified_addresses': UnifiedAddressConfig,
	'user_roles': UserRoleConfig,
	'domain_user_roles': DomainsUserRolesConfig,
	'users': UserConfig,
	'domain_users': DomainUsersConfig,
	'domain_configs': DomainConfigs,
	'voucher_setting_language_contents': VoucherSettingLangContentsConfig,
	'domain_packages': DomainPackages,
	'domains_packages_log': DomainsPackagesLog,
	'plafond': PlafondConfig,
	'plafond_drop_items': PlafondDropItemConfig,
	'trip_booking_logs': TripBookingLogConfig,
	'plafond_logs': PlafondLogConfig,
	'passport': PassportConfig,
	'transmission_operations': TransmissionOperationConfig,
	'entity_staff_users': EntityStaffUserConfiguration,
	'trip_resource_meeting_point_tickets' : TripResourceMeetingPointTicketConfig,
	'trip_resource_ticket_files': TripResourceTicketFileConfig,
	'bulk_delivery_codes': BulkDeliveryCodeConfig,
};
