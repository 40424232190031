import * as tslib_1 from "tslib";
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';
function sendAddCommand(dataContainerService, datasetNavigator, code) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef, tripBookingId;
        return tslib_1.__generator(this, function (_a) {
            dialogRef = datasetNavigator.openDialog(ActionDialogComponent, {
                title: 'Attendere',
                contentMessage: ''
            });
            console.log('sendAddCommand > dataContainerService Data: ', dataContainerService.getData(), dataContainerService.getValueFromKeyPath('trip_bookings.recordId'));
            tripBookingId = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    datasetNavigator.datasetService.post('/dataset/trip_booking_discounts/command/add_coupon', { code: code, trip_booking_id: tripBookingId })
                        .subscribe({
                        next: function (response) {
                            dialogRef.close();
                            if (dataContainerService instanceof DatasetActionContainerService) {
                                dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
                            }
                            resolve();
                        },
                        error: function (response) {
                            console.log('sendAddCommand response', response);
                            var dialogInstance = dialogRef.componentInstance;
                            if (dialogInstance instanceof ActionDialogComponent) {
                                dialogInstance.resultStatus = 'error';
                                dialogInstance.loading = false;
                                var message = 'Si è verificato un errore imprevisto.';
                                if (response && response.error && response.error.message && typeof (response.error.success) === 'boolean') {
                                    message = response.error.message;
                                }
                                dialogInstance.setMessage(message);
                            }
                            reject(response);
                        }
                    });
                })];
        });
    });
}
function getCouponCode(dataContainerService, datasetNavigator) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef;
        return tslib_1.__generator(this, function (_a) {
            dialogRef = datasetNavigator.openDialog(FormDialogComponent, {
                title: 'Inserisci il Coupon',
                formConfig: {
                    fields: [
                        {
                            name: 'Codice',
                            key: 'code',
                            valueType: ValueTypes.STRING,
                            inputConfig: {
                                type: InputTypes.TEXT
                            }
                        }
                    ]
                },
                positiveText: 'Aggiungi',
                negativeText: 'Annulla'
            });
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    dialogRef.afterClosed()
                        .subscribe(function (value) {
                        resolve(value && value.code);
                    });
                })];
        });
    });
}
export default function (dataContainerService, datasetNavigator) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var couponCode;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCouponCode(dataContainerService, datasetNavigator)];
                case 1:
                    couponCode = _a.sent();
                    if (!couponCode)
                        return [2 /*return*/, null];
                    return [2 /*return*/, sendAddCommand(dataContainerService, datasetNavigator, couponCode)];
            }
        });
    });
}
