<div>
	<div class="loading-div" *ngIf='dashboardService.loadingTravelDate || showLoader'>
		<mat-spinner *ngIf='dashboardService.loadingTravelDate' diameter="80" class='full-center-spinner'></mat-spinner>
	</div>
	<div>
		<canvas baseChart *ngIf='tickets'
			[datasets]="tickets"
			[labels]="barChartLabels" 
			[options]="barChartOptions"
			[legend]="barChartLegend" 
			[chartType]="barChartType"
		></canvas>
	</div>
</div>