import Echo from 'laravel-echo';
import * as SocketIO from 'socket.io-client';
import { Injectable, Inject, Optional } from '@angular/core';
import { APP_CONFIG } from './app-config.token';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppService } from './app.service';
import { ISocketEvent } from 'app/interfaces';

@Injectable({
	providedIn: 'root'
})
export class WebSocketService{

	public echo: Echo;
	public ioSocket: SocketIOClient.Socket;
	public isAuthenticated: BehaviorSubject<boolean>;

	private domainFilterId: BehaviorSubject<any>;
	private domainSubscription: Subscription;

	get enabled(): boolean{ return this.config && this.config.websocket && this.config.websocket.enabled; }
	get port(): string{  return this.config && this.config.websocket && this.config.websocket.port; }
	get serverAddress(): string {
		if(!this.config) return null;

		if(this.config.websocket.url){
			return this.config.websocket.url;
		}else{
			return this.config.serverAddress;
		}
	}

	constructor(
		@Optional() @Inject(APP_CONFIG) public config: any, 
		private appService: AppService
	){
		this.domainFilterId = new BehaviorSubject<any>(localStorage.getItem('DomainFilterService.filterDomainId'));
		this.isAuthenticated = new BehaviorSubject<boolean>(false);
	}

	setFilterDomain(domainId?: any): void{
		this.domainFilterId.next(domainId);
	}

	joinChannels(): void{
		this.ioSocket.emit('join', 'private-message-channel');
		this.ioSocket.emit('join', 'private-datasets-channel');
		this.ioSocket.emit('join', 'private-booking-channel');
	}

	clear(): void{
		if(this.ioSocket && !this.ioSocket.disconnected){
			this.ioSocket.close();
		}
		window['ioSocket'] = null;
		this.ioSocket = null;
	}

	init(): void{
		if(!this.enabled) return;
		// disable websocket connection from staging host to production
		if(window.location.host.includes('zverp') && this.serverAddress && this.serverAddress.includes('tangerine.theorangeseed')){
			return;
		}
		if(!window['ioSocket']){
			let socketAddress = this.serverAddress;
			if(this.port){
				socketAddress += ':' + this.port;
			}
			window['ioSocket'] = SocketIO(socketAddress, {
				// port mi sa che non è un opzione supportata
				port: this.port,
				secure: true
			});
			
			this.ioSocket = window['ioSocket'];

			this.ioSocket.on('connect', () => {
				console.log('creating websocket service');
				if(!this.ioSocket){
					// why this.ioSocket was null ??
					if(window['ioSocket']){
						this.ioSocket = window['ioSocket'];
					}else{
						console.warn('connect with no socket', this, window);
						return;
					}
				}
				console.log('socket io connected', this.ioSocket.id);
				// prevent multiple subscription
				if (this.domainSubscription) this.domainSubscription.unsubscribe();
				// join channels
				this.domainSubscription = this.domainFilterId.subscribe((domainId) => {
					if (!domainId) return;
					// try join room
					this.ioSocket.emit('join', {
						'channel': 'private-' + domainId + '-channel',
						'domain_id': domainId
					});
				});
			});

			this.ioSocket.on('error', err => {
				console.log('ioSocket.error', err);
			});

			this.ioSocket.on('test-event', this.onTestEvent.bind(this));
		}else{
			this.ioSocket = window['ioSocket'];
		}

		/*if(!window['echo']){
			window['echo'] = new Echo({
				broadcaster: 'socket.io',
				host: this.serverAddress + ':' + this.port
			});
			this.echo = window['echo'];
			this.echo.private('datasets')
			.listen('datasetEvent.updated', (data) => {
				console.log('websocket updated event', data);
			}).listen('datasetEvent.created', (data) => {
				console.log('websocket created event', data);
			}).listen('datasetEvent.deleted', (data) => {
				console.log('websocket deleted event', data);
			});

			this.echo.private('messages')
			.listen('messageEvent', (data) => {
				console.log('websocket updated event', data);
			});
		}*/
	}

	onTestEvent(testEvent: any): void{
		console.log(testEvent);
	}
}
