<mat-toolbar class="p-0 mat-elevation-z1">
	<div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
		<div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
			<button
				mat-icon-button
				class="navbar-toggle-button"
				*ngIf="!hiddenNavbar"
				fxHide.gt-md
				(click)="toggleSidebarOpen('navbar')"
			>
				<mat-icon class="secondary-text">menu</mat-icon>
			</button>
			<div class="logo logo-desktop">
				<img *ngIf="selectedDomainId" class="logo-icon" [src]='appService.getBaseServerAddressRoot() + "/domain/logo?domain_id="+selectedDomainId' alt=""/>
			</div>
		</div>

		<div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
			<!--<ng-container
				*ngFor="let buttonConfig of appService.toolbarPageActions"
			>
				<button
					mat-button
					class="toolbar-button accent"
					[ngClass]="buttonConfig.classes"
					(click)="buttonConfig.onClick()"
				>
					<span *ngIf="buttonConfig.title">{{
						buttonConfig.title
					}}</span>
					<mat-icon *ngIf="buttonConfig.icon">{{
						buttonConfig.icon
					}}</mat-icon>
				</button>
			</ng-container>-->
			<div class='pr-30 pl-30 instance-form filter_domain'>
				<form-input-viewer *ngIf="domainFilterService.showFilterBar"
					[formInputDefinition]='fileterFields.get("domain_id")'
					[formGroup]='filterFormGroup'
					formControlKey='domain_id'
				></form-input-viewer>
			</div>

			<div class="toolbar-separator filter_domain"></div>

			<div class="h3 mat-button current_date_time pr-30 pl-30">
				{{ dateNow | date: 'd MMMM y, HH:mm:ss' }}
			</div>

			<div class="toolbar-separator filter_domain_mobile"></div>

			<button (click)='filterDomainDialog()' mat-button class="filter_domain_mobile" *ngIf="domainFilterService.showFilterBar">
				<mat-icon>public</mat-icon>
			</button>

			<div class="toolbar-separator"></div>

			<button *ngIf="user" mat-button [matMenuTriggerFor]="userMenu" class="user-button pr-30 pl-30">
				<div fxLayout="row" fxLayoutAlign="center center">
					<img class="avatar mr-0 mr-sm-16" [src]='userImage' />
					<span class="username mr-12" fxHide fxShow.gt-sm>{{user.name}}</span>
					<mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
				</div>
			</button>

			<mat-menu #userMenu="matMenu" [overlapTrigger]="false">
				<!-- edit profile -->
				<button mat-menu-item class="" (click)="editProfile()">
					<mat-icon>person</mat-icon>
					<span>{{'Modifica Profilo' | translate}}</span>
				</button>
				<!-- edit password -->
				<button mat-menu-item class="" (click)="resetPassword()">
					<mat-icon>lock</mat-icon>
					<span>{{'Modifica Password' | translate}}</span>
				</button>
				<button mat-menu-item *ngIf="isAdministrator && domainFilterService.filterDomainId.value" [routerLink]="'/dataset/detail/domains/'+domainFilterService.filterDomainId.value">
					<mat-icon>settings</mat-icon>
					<span>{{'Impostazioni dominio' | translate}}</span>
				</button>
				<!-- logout -->
				<button mat-menu-item class="" (click)="logout()">
					<mat-icon>exit_to_app</mat-icon>
					<span>{{'Logout' | translate}}</span>
				</button>
			</mat-menu>

			<!--<fuse-search-bar (input)="search($event)"></fuse-search-bar>-->

			<div class="toolbar-separator"></div>

			<button mat-button class="language-button pr-30 pl-30" [matMenuTriggerFor]="languageMenu">
				<div fxLayout="row" fxLayoutAlign="center center">
					<img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.svg'">
					<span class="iso text-uppercase">{{selectedLanguage.id}}</span>
				</div>
			</button>

			<mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
				<button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
					<span fxLayout="row" fxLayoutAlign="start center">
						<img class="flag flag-menu mr-16" [src]="'assets/icons/flags/'+lang.flag+'.svg'">
						<span class="iso">{{lang.title}}</span>
					</span>
				</button>
			</mat-menu>
<!--
			<div class="toolbar-separator" fxHide fxShow.gt-xs></div>

			<button mat-icon-button
					class="quick-panel-toggle-button"
					(click)="toggleSidebarOpen('quickPanel')"
					aria-label="Toggle quick panel">
				<mat-icon class="secondary-text">format_list_bulleted</mat-icon>
			</button>

			<div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
-->
			<!--
			<button mat-icon-button class="navbar-toggle-button"
					*ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
				<mat-icon class="secondary-text">menu</mat-icon>
			</button>
-->
		</div>
	</div>
</mat-toolbar>
