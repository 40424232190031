import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/price_rules/detail/:recordId',
};

export const PriceRuleConfig: IDatasetConfig = {
	name: 'price_rules',
	title: 'Regola Prezzi',
	singleTitle: 'Regola Prezzo',
	ajaxDatasetCode: 'price_rules',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: editForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				const filters = dataContainerService.getValueFromKeyPath('price_rules.filters');
				params = Object.assign(params, filters);
				for (const propName in params) { 
					if (params[propName] === null || params[propName] === undefined) {
						delete params[propName];
					}
				}
				return params;
			},
			viewConfig: {
				formConfig: editForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Validità',
						key: 'validity_table_view'
					},
					{
						title: 'Prenotato',
						key: 'booking_table_view'
					},
					/*{
						title: 'Valido da',
						key: 'valid_from',
						width: '60'
					},
					{
						title: 'Valido fino a',
						key: 'valid_to',
						width: '60'
					},*/
					/*{
						title: 'Prenotato da',
						key: 'booking_from',
						width: '60'
					},
					{
						title: 'Prenotato fino a',
						key: 'booking_to',
						width: '60'
					},*/
					{
						title: 'Lista punti di ritrovo',
						key: 'meeting_point_list_description',
						showIf(dataContainerService: IDataContainerService): boolean{
							const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
							return Boolean(tripPackageId);
						}
					},
					{
						title: 'Ritorni',
						key: 'return_meeting_point_list_descriptions',
						showIf(dataContainerService: IDataContainerService): boolean{
							const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
							return Boolean(tripPackageId);
						}
					},
					{
						title: 'Pickup',
						key: 'meeting_point_id',
						fieldView: FieldViews.HTML,
						showIf(dataContainerService: IDataContainerService): boolean{
							const isPickup = dataContainerService.getValueFromKeyPath('trip_resources.record.properties.resource_sub_types.value') == 'PICKUP'
							return isPickup;
						},
						getDisplayHTML(record: any){
							if(!record || !record.meeting_point) return '';
							return record.meeting_point.description;
						}
					},
					/*{
						title: 'Prezzo Fornitore',
						key: 'supplier_price',
						width: '60'
					},
					{
						title: 'Mark Up',
						key: 'mark_up_percent',
						width: '60'
					},*/
					/*{
						title: 'Commissioni Attive',
						key: 'active_commission_percent',
						width: '60'
					},*/
					{
						title: 'Prezzi Fornitore',
						key: 'supplier_table_view',
						showIf(dataContainerService: IDataContainerService): boolean{
							const tripPackage = dataContainerService.getValueFromKeyPath('trip_packages.record');
							const tripResource = dataContainerService.getValueFromKeyPath('trip_resources.record');
							const record = tripPackage || tripResource;
							return !record || isRecordInFilteredDomain(record, record.is_owner);
						}
					},
					{
						title: 'Prezzo al pubblico',
						key: 'price',
						width: '60'
					}
				],
				actions: ['create', 'edit', 'delete']
			}
		}
	}
};
