import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TrenitaliaService } from '../../trenitalia.service';
import * as _ from 'lodash';
var OtherSolutionComponent = /** @class */ (function () {
    function OtherSolutionComponent(_trnService) {
        this._trnService = _trnService;
        this.onPriceSelected = new EventEmitter();
        this.offers = [];
        this.services = [];
    }
    OtherSolutionComponent.prototype.ngOnInit = function () {
        this.servicesMap = this.travelSolution.servicesMap || this.travelSolution.serviceMap;
        this.customTravelSolution = this.travelSolution.travelSolutions[0];
        this.offersMap = _.get(this.customTravelSolution, ['offers_map', this.train.id]);
        this.selectCustomizedOffers();
        this.initSelect();
        this.sendSelection();
    };
    OtherSolutionComponent.prototype.selectCustomizedOffers = function () {
        var e_1, _a, e_2, _b;
        this.selectedCustomizedOffers = {};
        try {
            for (var _c = tslib_1.__values(this.travelSolution.travellers), _d = _c.next(); !_d.done; _d = _c.next()) {
                var passenger = _d.value;
                var catalogServices = _.get(this.offersMap, ['travellers', passenger.xmlId], []);
                if (_.isEmpty(catalogServices)) {
                    console.warn('no catalogServices found for traveller', passenger, this.offersMap);
                    continue;
                }
                this.selectedCustomizedOffers[passenger.xmlId] = {
                    catalogServiceId: null,
                    offerId: null,
                    offered: null,
                    price: null,
                    netPrice: null,
                    fee: null,
                    cartaFrecciaRequired: false,
                    parameters: null
                };
                try {
                    // TODO: select best price service
                    /*if(_.has(this.customTravelSolution,'priceSelected')){
                        const sel:any = _.get(this.customTravelSolution,['priceSelected',this.train.id])
                        console.log(sel);
                        this.setSelection(passenger.xmlId, _.get(sel,[passenger.xmlId,'service']), _.get(sel,[passenger.xmlId,'offer']), false);
                    }else {*/
                    for (var catalogServices_1 = (e_2 = void 0, tslib_1.__values(catalogServices)), catalogServices_1_1 = catalogServices_1.next(); !catalogServices_1_1.done; catalogServices_1_1 = catalogServices_1.next()) {
                        var catalogService = catalogServices_1_1.value;
                        var offer = catalogService.offers.find(function (s) {
                            return s.selectable;
                        });
                        if (!offer)
                            continue;
                        this.setSelection(passenger.xmlId, catalogService.id, offer.offerId, false);
                        break;
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (catalogServices_1_1 && !catalogServices_1_1.done && (_b = catalogServices_1.return)) _b.call(catalogServices_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                /*}*/
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    OtherSolutionComponent.prototype.initSelect = function () {
        var e_3, _a, e_4, _b;
        var services = {};
        try {
            for (var _c = tslib_1.__values(this.travelSolution.travellers), _d = _c.next(); !_d.done; _d = _c.next()) {
                var passenger = _d.value;
                var catalogServices = _.get(this.offersMap, ['travellers', passenger.xmlId], []);
                if (_.isEmpty(catalogServices)) {
                    console.warn('no catalogServices found for traveller', passenger, this.offersMap);
                    continue;
                }
                try {
                    for (var catalogServices_2 = (e_4 = void 0, tslib_1.__values(catalogServices)), catalogServices_2_1 = catalogServices_2.next(); !catalogServices_2_1.done; catalogServices_2_1 = catalogServices_2.next()) {
                        var catalogService = catalogServices_2_1.value;
                        var offer = catalogService.offers.find(function (s) {
                            return s.selectable;
                        });
                        if (!offer)
                            continue; // not has a selectable offer
                        if (!services[passenger.xmlId]) {
                            services[passenger.xmlId] = [];
                        }
                        services[passenger.xmlId].push({
                            id: catalogService.id,
                            label: this.servicesMap.catalog_services[catalogService.id].display_name,
                            offers: offer
                        });
                        if (_.get(this.selectedCustomizedOffers, [passenger.xmlId, 'catalogServiceId']) == catalogService.id)
                            this.initOffer(catalogService.offers.filter(function (s) { return s.selectable; }), passenger.xmlId);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (catalogServices_2_1 && !catalogServices_2_1.done && (_b = catalogServices_2.return)) _b.call(catalogServices_2);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.services = services;
    };
    OtherSolutionComponent.prototype.initOffer = function (offers, travellerXmlId) {
        var _this = this;
        this.offers[travellerXmlId] = offers.filter(function (o) { return o.selectable; })
            .map(function (offer) {
            return {
                id: offer.offerId,
                label: _.get(_this.servicesMap, ['offers', offer.offerId, 'display_name']),
                price: offer.totalPrice,
                description: _.get(_this.servicesMap, ['offers', offer.offerId, 'description']),
                offered: offer.offers
            };
        });
    };
    OtherSolutionComponent.prototype.changeService = function (e, travellerXmlId) {
        var offServ = this.offersMap.travellers[travellerXmlId].find(function (s) { return s.id === e.value; });
        if (!offServ) {
            console.warn('catalog service not found', e);
            return;
        }
        var off = offServ.offers.filter(function (s) { return s.selectable; });
        this.initOffer(off, travellerXmlId);
        this.setSelection(travellerXmlId, e.value, this.offers[travellerXmlId][0].id);
    };
    OtherSolutionComponent.prototype.changeOffer = function (e, travellerXmlId) {
        var off = this.offers[travellerXmlId].find(function (o) { return o.id === e.value; });
        if (!off) {
            console.warn('offer not found', this.offers, travellerXmlId, e);
            return;
        }
        this.setSelection(travellerXmlId, this.selectedCustomizedOffers[travellerXmlId].catalogServiceId, off.id);
    };
    OtherSolutionComponent.prototype.setSelection = function (travellerXmlId, catalogServiceId, offerId, send) {
        var e_5, _a;
        if (send === void 0) { send = true; }
        var catalogService = this.offersMap.travellers[travellerXmlId].find(function (s) { return s.id == catalogServiceId; });
        if (!catalogService) {
            console.warn('invalid catalog service selected', travellerXmlId, catalogServiceId, offerId);
            return;
        }
        var offer = catalogService.offers.find(function (o) { return o.offerId == offerId; });
        if (!offer) {
            console.warn('invalid offer selected', travellerXmlId, catalogServiceId, offerId);
            return;
        }
        var selectionServiceParameters = _.get(this.servicesMap, 'catalog_services.' + catalogServiceId + '.selection_service_parameters');
        var parameters = [];
        if (selectionServiceParameters && Array.isArray(selectionServiceParameters)) {
            try {
                for (var selectionServiceParameters_1 = tslib_1.__values(selectionServiceParameters), selectionServiceParameters_1_1 = selectionServiceParameters_1.next(); !selectionServiceParameters_1_1.done; selectionServiceParameters_1_1 = selectionServiceParameters_1.next()) {
                    var parameter = selectionServiceParameters_1_1.value;
                    if (!_.has(parameter, 'typeDefinition.enumeration'))
                        continue;
                    parameters.push({
                        service_id: parameter.service_id,
                        values: parameter.typeDefinition.enumeration,
                        name: parameter.name,
                        value: parameter.typeDefinition.defaultValue
                    });
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (selectionServiceParameters_1_1 && !selectionServiceParameters_1_1.done && (_a = selectionServiceParameters_1.return)) _a.call(selectionServiceParameters_1);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        this.selectedCustomizedOffers[travellerXmlId] = {
            catalogServiceId: catalogServiceId,
            offerId: offer.offerId,
            offered: offer.offers,
            price: offer.totalPrice,
            netPrice: offer.totalPriceNet,
            fee: offer.fee,
            offerData: this.servicesMap.offers[offer.offerId],
            parameters: parameters,
            cartaFrecciaRequired: _.has(this.servicesMap, ['offers', offer.offerId, 'selection_traveller_parameters_map', 'cartaFreccia'])
        };
        if (send)
            this.sendSelection();
    };
    OtherSolutionComponent.prototype.sendSelection = function () {
        var _this = this;
        this.travelSolution.travelSolutions[0].custom_off[this.train.id] = {};
        this.travelSolution.travellers.forEach(function (t) {
            var e_6, _a;
            var parameters = null;
            var serviceParameters = _.get(_this.selectedCustomizedOffers, [t.xmlId, 'parameters']);
            if (serviceParameters && Array.isArray(serviceParameters) && serviceParameters.length) {
                parameters = {};
                try {
                    for (var _b = tslib_1.__values(_.get(_this.selectedCustomizedOffers, [t.xmlId, 'parameters'])), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var offerParameter = _c.value;
                        parameters[offerParameter.service_id] = offerParameter.value;
                    }
                }
                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_6) throw e_6.error; }
                }
            }
            if (!_this.selectedCustomizedOffers[t.xmlId]) {
                _this.travelSolution.travelSolutions[0].custom_off[_this.train.id][t.xmlId] = null;
            }
            else {
                _this.travelSolution.travelSolutions[0].custom_off[_this.train.id][t.xmlId] = {
                    offer: _this.selectedCustomizedOffers[t.xmlId].offered,
                    service: _this.selectedCustomizedOffers[t.xmlId].catalogServiceId,
                    price: _this.selectedCustomizedOffers[t.xmlId].price,
                    fee: _this.selectedCustomizedOffers[t.xmlId].fee ? _this.selectedCustomizedOffers[t.xmlId].fee : 0,
                    netPrice: _this.selectedCustomizedOffers[t.xmlId].netPrice ? _this.selectedCustomizedOffers[t.xmlId].netPrice : 0,
                    parameter: parameters
                };
            }
        });
        this.onPriceSelected.emit();
    };
    return OtherSolutionComponent;
}());
export { OtherSolutionComponent };
