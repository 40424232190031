import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('participant_description', {
	key: 'participant_description',
	name: 'Nominativo',
	valueType: ValueTypes.STRING
});

fieldsMap.set('age_range_code', {
	key: 'age_range_code',
	name: 'Età',
	valueType: ValueTypes.STRING,
	optionSelected: 'ADT',
	defaultValue(): string{
		return 'ADT';
	},
	options: [
		{
			value: 'SNR',
			label: '(SNR) Senior'
		},
		{
			value: 'ADT',
			label: '(ADT) Adulto',
		},
		{
			value: 'CHD',
			label: '(CHD) Bambino'
		},
		{
			value: 'INF',
			label: '(INF) Neonato'
		},
	]
});

fieldsMap.set('note', {
	key: 'note',
	name: 'Note',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING
});

fieldsMap.set('exclude', {
	key: 'exclude',
	name: 'Escluso',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{
		return 0;
	},
	options: [
		{
			value: 0,
			label: 'No'
		},
		{
			value: 1,
			label: 'Si'
		}
	]
});

fieldsMap.set('meeting_point_id', {
	key: 'meeting_point_id',
	name: 'Punto di ritrovo',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_point_unified_list_items',
	remoteOptions: {
		limit: 100,
		getParams(datasetContainerService: IDataContainerService): any{
			const params = {};
			const meetingPointListId = datasetContainerService.getValueFromKeyPath('trip_booking_packages.actual_trip.meeting_point_list_id');
			if(meetingPointListId){
				params['list_id'] = meetingPointListId;
			}
			console.log('getParams', meetingPointListId, datasetContainerService);
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			if(option.time){
				const time = option.time.slice(0, 5);
				label += ' ' + time;
			}
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(dataContainerService: IDataContainerService, options, record: any): any{
		if(!record) return null;
		return record.meeting_point_id;
	}
});

fieldsMap.set('return_point_id', {
	key: 'return_point_id',
	name: 'Punto di ritorno',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'meeting_point_unified_list_items',
	remoteOptions: {
		limit: 100,
		getParams(): any{

		},
		getLabel(option): any{
			if(!option) return '';
			let label = option.description;
			if(option.time){
				label += ' ' + option.time;
			}
			return label;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	defaultValue(dataContainerService: IDataContainerService, options: any[], record: any): any{
		if(!record) return null;
		return record.return_point_id;
	}
});

export {fieldsMap};
