import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import fieldsMap from './filter.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('trip_package_id'),
	fieldsMap.get('start_station_id'),
	fieldsMap.get('actual_trip_id'),
	fieldsMap.get('product_id'),
	fieldsMap.get('trip_category_id'),
	fieldsMap.get('trip_resource_id'),
	fieldsMap.get('entity_customer_id'),
	fieldsMap.get('entity_id'),
	fieldsMap.get('user_id'),
	fieldsMap.get('coupon_code'),
	fieldsMap.get('origin_channel'),
	fieldsMap.get('status'),
	fieldsMap.get('branch_code'),
	fieldsMap.get('delivery_status_milano_card'),
	fieldsMap.get('trip_booking_package_target_milano_card')
];

const config: IFormConfig = { 
	title: 'Filtra risultati',
	fields: formFields 
};

export default config;
