import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { BaseViewComponent } from '../base-view.component';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
var CommonStepViewContainerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonStepViewContainerComponent, _super);
    function CommonStepViewContainerComponent(appService, datasetRS, datasetACS, datasetNavigatorService, _fuseSidebarService, router, route, location, stepService) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this._fuseSidebarService = _fuseSidebarService;
        _this.router = router;
        _this.route = route;
        _this.location = location;
        _this.stepService = stepService;
        _this.initStep = false;
        _this.stepIndex = 0;
        _this.stepperIndex = 0;
        _this.isStepLinear = true;
        _this.computedSteps = [];
        _this.showOverview = true;
        return _this;
    }
    Object.defineProperty(CommonStepViewContainerComponent.prototype, "record", {
        /*@Input()
        set record(record: any){
            this.datasetRS.record.next(record);
        }*/
        get: function () { return this.datasetRS.record.value; },
        enumerable: true,
        configurable: true
    });
    CommonStepViewContainerComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.datasetRS.init();
        this.showOverview = Boolean(this.stepViewConfig.showOverview);
        this.isStepLinear = this.stepViewConfig.isLinear(this);
        // take from beforeCreate
        // in first case take from dataset.resolver call
        this.datasetStepViewConfigs = this.datasetACS.getValueFromKeyPath('stepViewConfig.' + this.datasetACS.datasetCode);
        // if not in creation then load from dataset callModules
        if (!this.datasetStepViewConfigs) {
            this.datasetStepViewConfigs = this.datasetACS.getValueFromKeyPath('domainContext.stepViewConfig.' + this.datasetACS.datasetCode);
        }
        this.buildSteps();
        this.datasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(this.onRecordChanged.bind(this));
    };
    CommonStepViewContainerComponent.prototype.buildSteps = function () {
        var e_1, _a;
        var newStepConfigs = [];
        var newComputedSteps = [];
        try {
            for (var _b = tslib_1.__values(this.stepViewConfig.steps), _c = _b.next(); !_c.done; _c = _b.next()) {
                var stepConfig = _c.value;
                if (this.datasetStepViewConfigs && stepConfig.id && this.datasetStepViewConfigs[stepConfig.id]) {
                    var datasetStepConfig = this.datasetStepViewConfigs[stepConfig.id];
                    //check config domains
                    if (!datasetStepConfig.display)
                        continue;
                }
                if (stepConfig.showIf && !stepConfig.showIf(this))
                    continue;
                newStepConfigs.push(stepConfig);
                newComputedSteps.push({
                    config: stepConfig,
                    title: stepConfig.stepperTitle || stepConfig.title,
                    completed: this.computeStepCompleted(stepConfig),
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.steps = newStepConfigs;
        this.computedSteps = newComputedSteps;
        if (this.tabGroup) {
            this.tabGroup.selectedIndex = this.stepIndex;
        }
    };
    CommonStepViewContainerComponent.prototype.computeStepCompleted = function (stepConfig) {
        if (this.showOverview)
            return false;
        if (!stepConfig.completed) {
            return Boolean(this.record);
        }
        else {
            return stepConfig.completed(this);
        }
    };
    CommonStepViewContainerComponent.prototype.trackStepBy = function (index, item) {
        return index;
    };
    CommonStepViewContainerComponent.prototype.onRecordChanged = function (record) {
        this.isStepLinear = this.stepViewConfig.isLinear(this);
        if (!this.initStep) {
            if (this.route.snapshot.queryParams['step']) {
                this.showOverview = false;
                if (!this.isStepLinear) {
                    this.stepperIndex = Number(this.route.snapshot.queryParams['step']);
                    // this.stepper.selectedIndex = this.route.snapshot.queryParams['step'];
                }
                else {
                    this.stepperIndex = 0;
                    // this.stepper.selectedIndex = 0;
                }
            }
            else {
                this.showOverview = true && this.stepViewConfig.showOverview;
            }
            if (!record)
                this.showOverview = false;
            this.initStep = true;
            this.stepIndex = this.stepperIndex;
            if (this.tabGroup)
                this.tabGroup.selectedIndex = this.stepperIndex;
        }
        if (this.stepViewConfig.subtitle) {
            this.subtitle = this.stepViewConfig.subtitle(this);
        }
        this.buildSteps();
    };
    CommonStepViewContainerComponent.prototype.toggleSidebar = function (name) {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    };
    CommonStepViewContainerComponent.prototype.stepChange = function (stepChange) {
        this.showOverview = false;
        this.stepIndex = stepChange.selectedIndex;
        this.buildSteps();
        var urlTree = this.router.createUrlTree([], {
            queryParams: { step: this.stepIndex },
            queryParamsHandling: 'merge'
        });
        this.location.go(urlTree.toString());
    };
    CommonStepViewContainerComponent.prototype.onClickOverview = function () {
        this.showOverview = true;
        this.buildSteps();
        // this.stepper.selectedIndex = 0;
        var urlTree = this.router.createUrlTree([], {
            queryParams: { step: null },
            queryParamsHandling: 'merge'
        });
        this.location.go(urlTree.toString());
    };
    return CommonStepViewContainerComponent;
}(BaseViewComponent));
export { CommonStepViewContainerComponent };
