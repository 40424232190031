<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence'>
		<mat-label [matTooltip]='inputService.formInputDefinition.titleTooltip'>
			<img matTooltip='Esportato in eAgency' *ngIf='inputService.exportEAgency' class='title-input-eagency' src='assets/images/logos/logo_eagency.png'/>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' >{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<input 
			(input)='onSearchChange($event.target.value)'
			[readonly]='inputService.inputConfig.readOnly' 
			[required]='inputService.inputConfig.required'
			[name]='inputService.formControlKey'
			autocomplete="off" 
			type='text' 
			matInput 
			[placeholder]="(inputService.inputConfig.placeholder || '') | translate" 
			[formControlName]='inputService.formControlKey'>
		<mat-icon *ngFor='let inputIcon of inputService.inputConfig.suffixs'
			(click)='inputIcon.onClick(inputService.formGroup, inputService.datasetNavigationService)'
			[color]='inputIcon.color' matSuffix>
			{{inputIcon.icon}}
		</mat-icon>
		<mat-icon matSuffix style='cursor: pointer;' *ngIf='!inputService.inputConfig.readOnly && inputService.inputConfig.clearable && inputService.control.value' (click)='inputService.control.setValue(inputService.formInputDefinition.disallowNull?"":null)'>close</mat-icon>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>