import { Component, Input, OnInit, Self, SkipSelf } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IFormConfig, IDatasetConfig } from 'app/interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import FilterFields from '../pivot_trip_packages_trip_categories/form.fields';
import { TripCategoryConfig } from 'app/configs/datasets/trip_categories';
import { TripPackageTripCategoriesComponent } from '../pivot_trip_packages_trip_categories/step.component';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'resell-trip-package-trip-categories',
	templateUrl: './step.component.html',
	styleUrls  : ['../../../common.style.scss'],
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class ResellTripPackageTripCategoriesComponent extends TripPackageTripCategoriesComponent{

	constructor(
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected formBuilder: FormBuilder
	){
		super(datasetRS, datasetACS, datasetNavigatorService, formBuilder);

		this.datasetACS.init({
			datasetCode: 'trip_packages',
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.datasetRS,
			fetchData: false
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetRS.record.next(this.parentDatasetACS.getValueFromKeyPath('reselledData'));
		});
	}
}
