<ng-container *ngIf="selectionOptions.length > 1">
	<mat-button-toggle-group 
		[(ngModel)]="selectionType" 
		class="mb-12 mt-24"
		id="button-group-action-package"
		[disabled]="!tripBookingPackagesStepService.hasParticipants"
		[matTooltip]="tripBookingPackagesStepService.hasParticipants ? null : 'Inserire almeno un partecipante'"
	>
		<mat-button-toggle *ngFor="let item of selectionOptions" [value]="item.value">
			<mat-icon>{{item.icon}}</mat-icon>
			<span class="ml-4 text-semibold">{{item.label}}</span>
		</mat-button-toggle>
	</mat-button-toggle-group>
</ng-container>

<ng-container *ngIf="selectionOptions.length == 1">
	<h2>{{selectionOptions[0].label}}</h2>

	<p *ngIf="!tripBookingPackagesStepService.hasParticipants" style="color: #8f8f8f">Inserire almeno un partecipante</p>
</ng-container>

<package-trip-selection *ngIf="selectionType == 'trip_packages'"></package-trip-selection>
<product-selection *ngIf="selectionType == 'products'"></product-selection>
<app-trenitalia-dialog *ngIf="selectionType == 'trenitalia'" (complete)="selectionType = null"></app-trenitalia-dialog>
<app-trenitalia-carnet *ngIf="selectionType == 'trenitalia-carnet'" (complete)="selectionType = null"></app-trenitalia-carnet>
<milano-card-selection *ngIf="selectionType == 'milano_card'"></milano-card-selection>
