import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('enabled'),
	fieldsMap.get('external_ref')
];

const config: IFormConfig = {
	title: 'Aggiungi integrazione',
	fields: formFields 
};

export default config;
