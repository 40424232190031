import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import tripStructureIdField from 'app/configs/commons/shared-field-configs/trip-structure-id.field';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'Nome del servizio'
});

// fieldsMap.set('trip_structure_id', cloneFieldDefinition(tripStructureIdField));

fieldsMap.set('entity_supplier_id', cloneFieldDefinition(entitySupplierIdField));

fieldsMap.set('supplier.code', {
	key: 'supplier.code',
	name: 'Codice Fornitore',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('supplier.description', {
	key: 'supplier.description',
	name: 'Description Fornitore',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('trip_structure.code', {
	key: 'trip_structure.code',
	name: 'Codice Struttura',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('trip_structure.description', {
	key: 'trip_structure.description',
	name: 'Descrizione Struttura',
	valueType: ValueTypes.STRING,
});


export {fieldsMap};
