import {
	Component,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Self,
	SimpleChanges,
	SkipSelf
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AppService } from 'app/services/app.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { TripBookingPackagesStepService } from '../../../trip-booking-packages-step.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
import { DomainFilterService } from 'app/services/domain-filter.service';

@Component({
	selector: 'trip-booking-package-trenitalia-post-sale',
	templateUrl: './trip-booking-package-trenitalia-post-sale.component.html',
	styleUrls: ['./trip-booking-package-trenitalia-post-sale.component.scss'],
	providers: [DatasetRecordService, DatasetActionContainerService]
})
export class TripBookingPackageTrenitaliaPostSaleBookingComponent implements OnInit, OnDestroy, OnChanges {
	protected _unsubscribeAll: Subject<any>;

	@Input('tripBookingPackage') inputTripBookingPackage: any;

	get tripBookingPackage(): any {
		return this.datasetRS.record.value;
	}

	get tripBookingStatus() {
		return (
			this.bookingStepService &&
			this.bookingStepService.datasetRS.record.value &&
			this.bookingStepService.datasetRS.record.value.status
		);
	}

	constructor(
		public appService: AppService,
		@Self()
		public datasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public domainFilterService: DomainFilterService,
		public bookingService: BookingService,
		public bookingStepService: TripBookingPackagesStepService,
	) {}

	ngOnInit() {
		this._unsubscribeAll = new Subject();
		this.datasetACS.init({
			datasetCode: 'trip_booking_packages',
			actionCode: 'detail',
			useBeforeCalls: false,
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if (!ready) return;
		});

	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.inputTripBookingPackage) {
			if (!this.datasetRS.record.value) {
				this.datasetRS.record.next(this.inputTripBookingPackage);
			} else if (
				this.inputTripBookingPackage &&
				this.inputTripBookingPackage.updated_at >
					this.tripBookingPackage.updated_at
			) {
				this.datasetRS.record.next(this.inputTripBookingPackage);
			}
		}
	}
}
