import * as tslib_1 from "tslib";
import { FormDialogComponent } from '../dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';
import { LoadingDialogComponent } from '../dialogs/loading-dialog/dialog.component';
export function action_edit_password(record, datasetService, data) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var dialogRef, formResult, loadingDialogRef2, response, responseMessage, e_1, errorMessage, keyIndex, keys, key;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dialogRef = datasetService.matDialog.open(FormDialogComponent, {
                        panelClass: 'custom-dialog-container',
                        minWidth: 400,
                        data: {
                            formData: data || {
                                old_password: null,
                                password: null,
                                confirmation_password: null
                            },
                            title: 'Modifica password',
                            formConfig: {
                                fields: [
                                    {
                                        key: 'old_password',
                                        name: 'Password corrente',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.PASSWORD
                                        },
                                    },
                                    {
                                        key: 'password',
                                        name: 'Nuova password',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.PASSWORD
                                        },
                                    },
                                    {
                                        key: 'password_confirmation',
                                        name: 'Conferma password',
                                        valueType: ValueTypes.STRING,
                                        inputRequired: true,
                                        inputConfig: {
                                            type: InputTypes.PASSWORD
                                        },
                                    }
                                ]
                            },
                        }
                    });
                    return [4 /*yield*/, dialogRef.afterClosed().toPromise()];
                case 1:
                    formResult = _a.sent();
                    if (!formResult)
                        return [2 /*return*/];
                    if (!formResult.old_password || !formResult.password || !formResult.password_confirmation) {
                        datasetService.appService.showErrorMessage('Dati insufficienti.');
                        // reopen dialog
                        return [2 /*return*/, action_edit_password(record, datasetService)];
                    }
                    loadingDialogRef2 = datasetService.matDialog.open(LoadingDialogComponent, {
                        data: {
                            title: 'Creazione in corso'
                        }
                    });
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, datasetService.post('/auth/me/edit-password', {
                            id: record.id,
                            old_password: formResult.old_password,
                            password: formResult.password,
                            password_confirmation: formResult.password_confirmation
                        }).toPromise()];
                case 3:
                    response = _a.sent();
                    responseMessage = '';
                    if (response.message) {
                        responseMessage = response.message;
                    }
                    if (response.success) {
                        datasetService.appService.showSuccessMessage(responseMessage || 'Modifica avventuta con successo');
                    }
                    else {
                        if (!responseMessage)
                            responseMessage = 'Si è verificato un problema durante la richiesta dell\'utente.';
                        datasetService.appService.showErrorMessage(responseMessage);
                    }
                    loadingDialogRef2.close();
                    return [2 /*return*/, response.success];
                case 4:
                    e_1 = _a.sent();
                    errorMessage = 'Si è verificato un errore';
                    if (e_1.error && e_1.error.errors) {
                        keyIndex = 0;
                        keys = Object.keys(e_1.error.errors);
                        key = keys[keyIndex];
                        while (keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e_1.error.errors, key)) {
                            keyIndex++;
                            key = keys[keyIndex];
                        }
                        if (e_1.error.errors[key]) {
                            if (Array.isArray(e_1.error.errors[key]))
                                errorMessage = e_1.error.errors[key][0];
                            else if (typeof (e_1.error.errors[key]) === 'string')
                                errorMessage = e_1.error.errors[key];
                        }
                    }
                    datasetService.appService.showErrorMessage(errorMessage);
                    loadingDialogRef2.close();
                    return [2 /*return*/, action_edit_password(record, datasetService, {
                            old_password: formResult.old_password,
                            password: formResult.password,
                            password_confirmation: formResult.password_confirmation
                        })];
                case 5: return [2 /*return*/];
            }
        });
    });
}
