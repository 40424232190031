import { OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
var BookingPreviewDialogComponent = /** @class */ (function () {
    function BookingPreviewDialogComponent(datasetACS, datasetRS, appService, datasetService, dialog, dialogRef, data, fuseTranslationLoader) {
        this.datasetACS = datasetACS;
        this.datasetRS = datasetRS;
        this.appService = appService;
        this.datasetService = datasetService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.fuseTranslationLoader = fuseTranslationLoader;
        this.loading = false;
        // carico traduzioni
        this.fuseTranslationLoader.loadTranslations(english, italiano);
        this._unsubscribeAll = new Subject();
    }
    Object.defineProperty(BookingPreviewDialogComponent.prototype, "record", {
        get: function () { return this.datasetRS.record.value; },
        enumerable: true,
        configurable: true
    });
    BookingPreviewDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetRS.recordId = this.data.recordId;
        this.datasetRS.extraParams = {
            for_preview: 1
        };
        this.datasetACS.init({
            datasetCode: 'trip_bookings',
            actionCode: 'detail',
            parentDatasetACS: this.data.parentDatasetACS,
            fetchData: true,
            datasetRS: this.datasetRS
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            _this.datasetRS.init()
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function (readyRecordService) {
                if (!readyRecordService)
                    return;
                _this.datasetRS.reload();
            });
        });
    };
    BookingPreviewDialogComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    BookingPreviewDialogComponent.prototype.onCloseClick = function () {
        this.dialogRef.close(false);
    };
    BookingPreviewDialogComponent.prototype.idTrackBy = function (index, item) {
        return item.id;
    };
    return BookingPreviewDialogComponent;
}());
export { BookingPreviewDialogComponent };
