import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { fieldsMap } from './form.fields';
import { FormBuilder } from '@angular/forms';
import { FormViewerService } from 'app/services/form-viewer.service';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { ProductBaseInfoStepComponent } from '../product-base-info-step/step.component';
var ProductFixedAllotmentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductFixedAllotmentComponent, _super);
    function ProductFixedAllotmentComponent(appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService) {
        var _this = 
        /** init parent constructor */
        _super.call(this, appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, formBuilder, zone, stepService, formViewerService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this.formBuilder = formBuilder;
        _this.zone = zone;
        _this.stepService = stepService;
        _this.formViewerService = formViewerService;
        // @ViewChild('formViewer') formViewer: FormViewerComponent;
        _this.fileterFields = fieldsMap;
        return _this;
    }
    ProductFixedAllotmentComponent.prototype.onSave = function () {
        _super.prototype.onSave.call(this, false);
    };
    ProductFixedAllotmentComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.stepService.setStepState({
            dataset_code: 'products',
            form_code: 'product-advanced-blocks-step',
            record_id: this.datasetRS.recordId,
            status: 1
        });
    };
    return ProductFixedAllotmentComponent;
}(ProductBaseInfoStepComponent));
export { ProductFixedAllotmentComponent };
