import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseDatasetComponent } from './../base-dataset.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { InvalidAllotmentsDialogComponent } from '../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';
var DatasetCreateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetCreateComponent, _super);
    function DatasetCreateComponent(route, resourceService, router, authService, datasetACS, matDialog, _fuseTranslationLoaderService, webSocketService) {
        var _this = _super.call(this, route, resourceService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService) || this;
        _this.route = route;
        _this.resourceService = resourceService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.webSocketService = webSocketService;
        _this.stepViewConfig = TripPackageStepConfigSmartInterface;
        _this.productStepViewConfig = ProductStepViewConfig;
        _this.actionCode = 'create';
        _this.formConfig = null;
        _this.showHeader = true;
        _this.created = new EventEmitter();
        return _this;
    }
    DatasetCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.datasetACS.ready
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
            if (_this.datasetACS.actionConfig) {
                _this.formConfig = _this.datasetACS.actionConfig.viewConfig.formConfig;
            }
            else {
                _this.formConfig = null;
            }
        });
    };
    DatasetCreateComponent.prototype.onSave = function () {
        var _this = this;
        this.formViewer.formGroup.disable();
        var formData = this.formViewer.getFormData();
        var actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
        if (actionConfig && typeof (actionConfig.getCallParams) === 'function') {
            formData = actionConfig.getCallParams(this.datasetACS, null, formData);
        }
        var callMethod = this.resourceService.post.bind(this.resourceService);
        var headers = this.datasetACS.getContextualDomainHeaders();
        this.datasetACS.loading.next(true);
        callMethod('/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode + '/create', formData, {
            headers: headers
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetACS.loading.next(false);
                _this.datasetACS.datasetEvent.next({ eventName: DatasetEvents.CREATED, data: response });
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.clearForm();
                }
                _this.created.emit();
            },
            error: function (response) {
                _this.datasetACS.loading.next(false);
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewer) {
                    _this.formViewer.formGroup.enable();
                    _this.formViewer.setErrors(_this.formErrors);
                }
                if (response.status === 400 && response.error.code === 'invalid_allotments') {
                    _this.matDialog.open(InvalidAllotmentsDialogComponent, {
                        data: {
                            items: response.error.payload.items,
                            datasetCode: _this.datasetACS.datasetCode,
                            actionCode: _this.datasetACS.actionCode,
                            count: response.error.payload.count,
                            tripResource: response.error.payload.trip_resource
                        }
                    });
                }
            }
        });
    };
    return DatasetCreateComponent;
}(BaseDatasetComponent));
export { DatasetCreateComponent };
