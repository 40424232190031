import { Component, OnInit, OnDestroy, Input, Injector, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IFormConfig } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : '[card-data-viewer]',
	templateUrl: './view.component.html',
	styleUrls  : ['./view.component.scss']
})
export class CardDataViewComponent implements OnInit, OnDestroy, OnChanges{
	@Input() public record;
	@Input() public datasetACS: DatasetActionContainerService;
	@Input() public viewFormConfig: IFormConfig;
	@Input() public editFormConfig: IFormConfig;
	@Input() public createWithDatasetCode;
	@Input() public createWithParentDatasetACS: DatasetActionContainerService;
	@Input() public createWithParentDatasetRS: DatasetRecordService;
	@Input() public elevate = false;
	@Input() public showActions = true;

	protected _unsubscribeAll: Subject<any>;
	canEdit = false;

	constructor(
		private datasetNavigatorService: DatasetNavigatorService,
		private injector: Injector,
		private viewContainerRef: ViewContainerRef
	){}

	ngOnInit(): void{
		this._unsubscribeAll = new Subject();
		if(!this.datasetACS && this.createWithDatasetCode){
			const injector = Injector.create({
				parent: this.injector, 
				providers: [{
					provide: DatasetActionContainerService,
					deps: []
				}]
			});
			const containerService: DatasetActionContainerService = injector.get(DatasetActionContainerService);
			containerService.init({
				datasetCode: this.createWithDatasetCode,
				actionCode: 'detail',
				parentDatasetRS: this.createWithParentDatasetRS,
				parentDatasetACS: this.createWithParentDatasetACS,
				fetchData: false
			});
			this.datasetACS = containerService;
		}

		this.datasetACS.datasetDataChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(keys => {
			if(keys.includes('staticAllowedActionCodes')){
				this.computeCanEdit();
			}
		});
		this.computeCanEdit();
	}

	computeCanEdit(): void{
		this.canEdit = this.datasetACS.canEdit(this.record) && this.datasetACS.actionEnabled('edit');
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.record){
			this.computeCanEdit();
		}
	}

	onEdit(): void{
		this.datasetNavigatorService
		.onEdit(this.datasetACS, this.record, {
			formConfig: this.editFormConfig || this.viewFormConfig,
			viewContainerRef: this.viewContainerRef
		});
	}
}
