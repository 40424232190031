import { NgModule } from "@angular/core";
import { TrainBreadcrumbsComponent } from "./train-breadcrumbs.component";
import { MatTooltipModule } from "@angular/material";
import { CommonModule } from "@angular/common";

@NgModule({
	declarations: [TrainBreadcrumbsComponent],
	imports: [
		CommonModule,
		MatTooltipModule
	],
	exports: [TrainBreadcrumbsComponent]
})
export class TrainBreadcrumbsModule{}