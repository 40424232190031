import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { DestroyableComponent } from "app/main/destroyable.component";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { IFieldDefinition, InputTypes, ValueTypes } from "app/interfaces";
import { FormViewerComponent } from "app/main/components/form-viewer/form-viewer.component";
import { BookingService } from "../../booking.service";
import { AppService } from "app/services/app.service";
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGES } from "../../edit-booking.component";
import { MatDialog } from "@angular/material";
import { ActionDialogComponent } from "../../action-dialog/action-dialog.component";
import { cloneFieldDefinition } from "app/helpers/field.helper";
import nationCodeField from "app/configs/commons/shared-field-configs/nation-code.field";
import * as _ from "lodash";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { TripBookingPackagesStepService } from "../trip-booking-packages-step.service";
var inputCounterConfig = {
    key: 'adt',
    name: 'Adulto',
    valueType: ValueTypes.NUMBER,
    inputConfig: {
        type: InputTypes.COUNTER,
    },
    extra: {
        minValue: 0
    },
    maxWidth: '140px',
    width: '140px'
};
var ParticipantsFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ParticipantsFormComponent, _super);
    function ParticipantsFormComponent(appService, datasetACS, datasetRS, bookingService, matDialog, tripBookingPackagesStepService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.bookingService = bookingService;
        _this.matDialog = matDialog;
        _this.tripBookingPackagesStepService = tripBookingPackagesStepService;
        _this.formConfig = {
            fields: [
                {
                    config: {
                        fxLayoutGap: '10px',
                        flexLayout: 'row'
                    },
                    fields: [
                        Object.assign({}, inputCounterConfig, { key: 'ADT', name: 'Adulti' }),
                        Object.assign({}, inputCounterConfig, { key: 'CHD', name: 'Bambini' }),
                        Object.assign({}, inputCounterConfig, { key: 'INF', name: 'Neonati' }),
                        Object.assign({}, inputCounterConfig, { key: 'SNR', name: 'Anziani' }),
                        cloneFieldDefinition(nationCodeField, {
                            maxWidth: '160px',
                            defaultValue: function (dataContainerService, options, record) {
                                return dataContainerService.getValueFromKeyPath('trip_bookings.record.last_nation_code', 'IT') || 'IT';
                            }
                        })
                    ]
                }
            ]
        };
        return _this;
    }
    ParticipantsFormComponent.prototype.ngOnInit = function () {
    };
    ParticipantsFormComponent.prototype.isChanged = function (data) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(['ADT', 'SNR', 'CHD', 'INF']), _c = _b.next(); !_c.done; _c = _b.next()) {
                var cat = _c.value;
                var currentVal = _.get(this.datasetRS.record.value.participant_counters, cat) || 0;
                var formVal = _.get(data, cat) || 0;
                if (currentVal != formVal)
                    return true;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    ParticipantsFormComponent.prototype.onValueChanges = function (formViewerService) {
        var _this = this;
        var formValues = formViewerService.getFormData();
        if (!this.isChanged(formValues))
            return;
        var formGroup = this.formViewer.formViewerService.formGroup;
        formGroup.disable();
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            width: '550px',
            data: {
                title: 'Aggiornamento partecipanti'
            }
        });
        this.bookingService.execBookingCommand('update_participants', {
            trip_booking_id: this.datasetRS.recordId,
            participants: formValues
        }, false)
            .subscribe({
            next: function () {
                _this.appService.appEvent.next({ name: RELOAD_BOOKING });
                _this.appService.appEvent.next({ name: RELOAD_BOOKING_PACKAGES });
            },
            error: function (response) {
                _this.bookingService.onErrorActionResponse(response, dialogRef);
                // reset
                formViewerService.setValues({
                    ADT: _.get(_this.datasetRS.record.value.participant_counters, 'ADT', 0) || 0,
                    CHD: _.get(_this.datasetRS.record.value.participant_counters, 'CHD', 0) || 0,
                    SNR: _.get(_this.datasetRS.record.value.participant_counters, 'SNR', 0) || 0,
                    INF: _.get(_this.datasetRS.record.value.participant_counters, 'INF', 0) || 0
                });
                formGroup.enable();
            },
            complete: function () {
                // reset
                formViewerService.setValues({
                    ADT: _.get(_this.datasetRS.record.value.participant_counters, 'ADT', 0) || 0,
                    CHD: _.get(_this.datasetRS.record.value.participant_counters, 'CHD', 0) || 0,
                    SNR: _.get(_this.datasetRS.record.value.participant_counters, 'SNR', 0) || 0,
                    INF: _.get(_this.datasetRS.record.value.participant_counters, 'INF', 0) || 0
                });
                dialogRef.close();
                formGroup.enable();
            }
        });
    };
    ParticipantsFormComponent.prototype.openInfoTrenitalia = function () {
        var url = "https://www.trenitalia.com/it/html/trenitalia/Info_regionale.html";
        window.open(url, '_blank', 'width:500,height=500');
        /*this.dialog.open(InfoDialog, {
            data: row
        });*/
    };
    return ParticipantsFormComponent;
}(DestroyableComponent));
export { ParticipantsFormComponent };
