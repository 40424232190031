import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { MatDialog } from '@angular/material/dialog';
import { IPaginationResponse, ListViewItemTypes, ValueTypes, InputTypes, FieldViews, IListViewConfig, IFormConfig } from 'app/interfaces';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { fieldsMap as ActualTripFieldsMap } from 'app/configs/datasets/actual_trips/fields';
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from '../edit-booking.component';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { ConfirmDialogComponent } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { Subject, of } from 'rxjs';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { config as productSelectionViewConfig } from 'app/configs/datasets/products/selectionViewConfig';
import { exhaustMap, filter, switchMap } from 'rxjs/operators';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { TripBookingResourceComponent } from './trip-booking-resources/trip-booking-resource.component';
import { TripBookingServiceComponent } from './trip-booking-services/trip-booking-service.component';
import { TripBookingParticipantComponent } from './trip-booking-participants/trip-booking-participant.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { TextDialogComponent } from 'app/main/dialogs/text-dialog/dialog.component';
import { TrenitaliaCarnetComponent } from '../trenitalia-dialog/trenitalia-carnet/trenitalia-carnet.component';
import { TrenitaliaDialogContainerComponent } from '../trenitalia-dialog/trenitalia-dialog-container/trenitalia-dialog-container.component';
var BookingStep2Service = /** @class */ (function () {
    function BookingStep2Service(datasetRS, datasetACS, datasetNavigatorService, http, appService, datasetService, matDialog) {
        this.datasetRS = datasetRS;
        this.datasetACS = datasetACS;
        this.datasetNavigatorService = datasetNavigatorService;
        this.http = http;
        this.appService = appService;
        this.datasetService = datasetService;
        this.matDialog = matDialog;
        this.loading = false;
        this.loadPickupMeetingPoint = false;
    }
    BookingStep2Service.prototype.reloadTripBookingPackages = function () {
        var _this = this;
        if (this.fetchTripBookingPackageSubcription) {
            this.fetchTripBookingPackageSubcription.unsubscribe();
        }
        var params = {
            perPage: 100,
            sortBy: 'created_at',
            sortDirection: 'asc',
            trip_booking_id: this.datasetRS.recordId,
            with_attributes: 'total_amount_net',
            with_relations: 'tripPackage.destinations,product'
        };
        this.loading = true;
        this.fetchTripBookingPackageSubcription = this.datasetService
            .get('/dataset/trip_booking_packages', params)
            .subscribe({
            next: function (response) {
                _this.tripBookingPackagesPage = response;
                _this.loading = false;
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    Object.defineProperty(BookingStep2Service.prototype, "startDay", {
        get: function () {
            return JSON.stringify(['>=', convertToUTCDay().toISOString()]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingStep2Service.prototype, "actualTripsListViewConfig", {
        get: function () {
            return {
                itemViewType: ListViewItemTypes.TABLE,
                defaultSortBy: 'start_day|start_time',
                defaultSortDirection: 'asc',
                showSearchBar: false,
                filterForm: {
                    fields: [
                        {
                            key: 'start_day',
                            name: 'Data Partenza',
                            valueType: ValueTypes.DATE,
                            inputConfig: {
                                type: InputTypes.COMPARE_DATE,
                                clearable: true
                            },
                            defaultValue: function (dataContainerService) {
                                return JSON.stringify([
                                    '>=',
                                    convertToUTCDay().toISOString()
                                ]);
                            }
                        },
                        cloneFieldDefinition(tripPackageIdField)
                    ]
                },
                columns: [
                    {
                        title: 'Pacchetto',
                        key: 'package_description',
                        fieldDefinition: {
                            name: 'Pacchetto',
                            key: 'package_description',
                            valueType: ValueTypes.STRING,
                            fieldView: FieldViews.HTML,
                            getDisplayValue: function (record) {
                                if (!record || !record.package)
                                    return '---';
                                var value = "<div class='trip_booking_trip_package_description'>";
                                value += record.package.description;
                                value += '</div>';
                                return value;
                            }
                        }
                    },
                    {
                        title: 'Tipo',
                        key: 'icon_type',
                        fieldDefinition: {
                            name: 'Tipo',
                            key: 'icon_type',
                            valueType: ValueTypes.STRING,
                            fieldView: FieldViews.HTML,
                            getDisplayValue: function (record) {
                                var icon = 'going_trip_icon.svg';
                                var style = 'border-radius: unset !important;';
                                if (record.type == 'return') {
                                    icon = 'return_trip_icon.svg';
                                }
                                return ('<img src="assets/icons/material-icons/filled/' +
                                    icon +
                                    '" style="' +
                                    style +
                                    '" />');
                            }
                        }
                    },
                    {
                        title: 'Nome',
                        key: 'display_name'
                    },
                    {
                        title: 'Data Partenza',
                        key: 'start_date_time'
                    },
                    {
                        title: 'Disponibilità',
                        key: 'availability'
                    }
                ]
            };
        },
        enumerable: true,
        configurable: true
    });
    BookingStep2Service.prototype.addTripBookingPackage = function (tripBookingPackageToReplace) {
        var _this = this;
        var data = {
            datasetCode: 'actual_trips',
            filters: { start_day: this.startDay, only_bookable: 1 },
            viewConfig: this.actualTripsListViewConfig
        };
        if (tripBookingPackageToReplace) {
            data.filters.trip_package_id =
                tripBookingPackageToReplace.trip_package_id;
            //data.filters.start_day = tripBookingPackageToReplace.actual_trip.start_day.substring(0, 10);
            data.filters.exclude_ids =
                tripBookingPackageToReplace.actual_trip_id;
            if (tripBookingPackageToReplace.properties
                .going_trip_booking_package_id &&
                tripBookingPackageToReplace.properties
                    .going_trip_booking_package_id.value) {
                var goingPackageId_1 = tripBookingPackageToReplace.properties
                    .going_trip_booking_package_id.value;
                var bookingPackages = this.tripBookingPackagesPage.items || [];
                var goingBookingPackage = bookingPackages.find(function (bookingPackage) { return bookingPackage.id == goingPackageId_1; });
                if (goingBookingPackage) {
                    data.filters.going_trip_id =
                        goingBookingPackage.actual_trip_id;
                }
            }
        }
        this.matDialog
            .open(SelectDatasetRecordDialogComponent, {
            panelClass: 'create-dialog-container',
            data: data
        })
            .afterClosed()
            .subscribe(function (actualTrip) {
            if (!actualTrip)
                return;
            var postData = {
                trip_booking_id: _this.datasetRS.recordId,
                actual_trip_id: actualTrip.id
            };
            var actionEndPoint = '/dataset/trip_booking_packages/create';
            if (tripBookingPackageToReplace) {
                postData['trip_booking_package_id_to_replace'] =
                    tripBookingPackageToReplace.id;
                actionEndPoint =
                    '/dataset/trip_bookings/command/replace_trip';
            }
            _this.createTripBookingPackage(actionEndPoint, postData);
        });
    };
    BookingStep2Service.prototype.updateActualTrip = function (postData) {
        var _this = this;
        var created = new Subject();
        this.loading = true;
        var actionEndPoint = '/dataset/actual_trips/command/update_date';
        this.datasetService.post(actionEndPoint, postData).subscribe({
            next: function (response) {
                _this.loading = false;
                _this.reloadTripBookingPackages();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            }
        });
    };
    BookingStep2Service.prototype.createTripBookingPackage = function (actionEndPoint, postData) {
        var _this = this;
        var created = new Subject();
        this.loading = true;
        this.datasetService.post(actionEndPoint, postData).subscribe({
            next: function (response) {
                _this.loading = false;
                _this.reloadTripBookingPackages();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
                if (response && response.return_trip_count > 0) {
                    var confirmData = {
                        title: 'Aggiungi',
                        contentMessage: 'Questa partenza ha dei possibili ritorni, vuoi scegliere quale aggiungere?',
                        positiveText: 'Si',
                        negativeText: 'No'
                    };
                    _this.matDialog
                        .open(ConfirmDialogComponent, {
                        data: confirmData,
                        panelClass: 'create-dialog-container'
                    })
                        .afterClosed()
                        .subscribe(function (result) {
                        if (result) {
                            _this.selectReturnTrip(response.actual_trip_id);
                        }
                    });
                }
                created.next(true);
            },
            error: function (response) {
                _this.loading = false;
                created.next(false);
            }
        });
        return created;
    };
    BookingStep2Service.prototype.addTicket = function () {
        var _this = this;
        var data = {
            title: 'Pacchetti Milano Card',
            datasetCode: 'trip_packages',
            filters: {
                start_day: JSON.stringify([
                    '>=',
                    convertToUTCDay().toISOString()
                ]),
                only_bookable: 1,
                target: 'ticket'
            },
            viewConfig: {
                itemViewType: ListViewItemTypes.TABLE,
                defaultSortBy: 'start_day|start_time',
                defaultSortDirection: 'asc',
                showSearchBar: false,
                filterForm: {
                    fields: [
                        cloneFieldDefinition(ActualTripFieldsMap.get('start_day'), {
                            formValidators: [],
                            inputConfig: {
                                type: InputTypes.COMPARE_DATE,
                                clearable: true
                            }
                        }),
                        cloneFieldDefinition(tripPackageIdField)
                    ]
                },
                columns: [
                    {
                        title: 'Codice',
                        key: 'code',
                        fieldDefinition: {
                            name: 'Pacchetto',
                            key: 'code',
                            valueType: ValueTypes.STRING,
                            getDisplayValue: function (record) {
                                if (!record)
                                    return '---';
                                return record.code;
                            }
                        }
                    },
                    {
                        title: 'Stato',
                        key: 'active_status',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value, translateService) {
                            var colorClass = 'red-bg';
                            var text = '<span class="status">' +
                                translateService.instant('Non Attivo') +
                                '</span>';
                            if (record.active_status === 1) {
                                colorClass = 'green-bg';
                                text =
                                    '<span class="status">' +
                                        translateService.instant('Attivo') +
                                        '</span>';
                                if (record.publish_on_web === 1) {
                                    text =
                                        '<span class="status">' +
                                            translateService.instant('Attivo e pubblicato') +
                                            '</span>';
                                }
                                else {
                                    colorClass = 'orange-bg';
                                }
                            }
                            var html = '<div class="package_status" style="display:flex;align-items: center;">';
                            html +=
                                '<span class="' +
                                    colorClass +
                                    ' record-status-bar" style="width:6px;height:24px;display:inline-block;"></span>';
                            html += text;
                            html += '</div>';
                            return html;
                        }
                    },
                    {
                        title: 'Immagine',
                        key: 'master_image',
                        fieldView: FieldViews.IMAGE,
                        routerLink: function (record) {
                            return datasetDetailPage('trip_packages', record.id);
                        }
                    },
                    {
                        title: 'Descrizione',
                        key: 'description',
                        fieldView: FieldViews.HTML,
                        getDisplayHTML: function (record, value, translateService) {
                            var html = "<span style='display: block;'>" +
                                record.description +
                                '</span>';
                            var filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
                            var isFiltered = filteredDomainId && filteredDomainId !== 'null';
                            if (record.domain) {
                                if (record.reseller_domain_package &&
                                    record.reseller_domain_package
                                        .reseller_domain) {
                                    // tslint:disable-next-line: max-line-length
                                    html +=
                                        "<span style='font-size: 11px;'>" +
                                            translateService.instant('Proprietario') +
                                            ":</span> <label style='display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;'>" +
                                            record.reseller_domain_package
                                                .reseller_domain.display_name +
                                            '</label>';
                                }
                                else if ((!isFiltered && !record.is_owner) ||
                                    (isFiltered &&
                                        filteredDomainId !== record.domain.id)) {
                                    // tslint:disable-next-line: max-line-length
                                    html +=
                                        "<span style='font-size: 11px;'>" +
                                            translateService.instant('Proprietario') +
                                            ":</span> <label style='display: inline-block; background: orange; border-radius: 15px; padding: 1px 3px; margin: 0 3px 0 0; font-size: 10px; color: white;'>" +
                                            record.domain.display_name +
                                            '</label>';
                                }
                                // aggiungere logo tour operator nel caso di pacchetto rivenduto
                                if ((!isFiltered && record.is_owner) ||
                                    (isFiltered &&
                                        filteredDomainId === record.domain.id)) {
                                    if (record.tourcms_channel_id_tour_id) {
                                        // tslint:disable-next-line: max-line-length
                                        html +=
                                            '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px;">Proprietario:</label> <img src="assets/images/tour-operators/tour-cms.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
                                    }
                                    else if (record.bokun_vendor_id_tour_id) {
                                        // tslint:disable-next-line: max-line-length
                                        html +=
                                            '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 70px; margin-left: 10px;" /></span>';
                                    }
                                    else if (record.ticketlandia_museum_id_event_id) {
                                        // tslint:disable-next-line: max-line-length
                                        html +=
                                            '<span style="display: flex; align-items: center; padding-left: 3px;"><label style="font-size: 11px; position: relative; top: 7px;">Proprietario:</label> <img src="assets/images/tour-operators/ticketlandia.png" class="tour-operator-logo" style="border-radius: 0 !important; max-width: 90px; margin-left: 10px;" /></span>';
                                    }
                                }
                            }
                            return html;
                        }
                    },
                    {
                        title: 'Traduzioni Attive',
                        key: 'active_language_codes'
                    }
                ]
            }
        };
        this.matDialog
            .open(SelectDatasetRecordDialogComponent, {
            panelClass: 'create-dialog-container',
            data: data
        })
            .afterClosed()
            .subscribe(function (tripPackage) {
            if (!tripPackage)
                return;
            _this.loading = true;
            var postData = {
                trip_booking_id: _this.datasetRS.recordId,
                trip_package_id: tripPackage.id,
                target: 'milano_card'
            };
            _this.datasetService
                .post('/dataset/trip_booking_packages/create', postData)
                .subscribe({
                next: function (response) {
                    _this.loading = false;
                    _this.reloadTripBookingPackages();
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                },
                error: function (response) {
                    _this.loading = false;
                }
            });
        });
    };
    /**
     * Return null if not additional info needed
     */
    BookingStep2Service.prototype.getAditionalForm = function (item, target, mode) {
        if (mode === void 0) { mode = 'create'; }
        if (!item)
            return null;
        var formConfig = {
            fields: []
        };
        if (target == 'product') {
            if (item.data && item.data.need_activation_date) {
                formConfig.fields.push({
                    key: 'date',
                    name: 'Data Attivazione',
                    valueType: ValueTypes.DATE,
                    inputConfig: {
                        type: InputTypes.DATE,
                        required: true
                    }
                });
            }
        }
        if (!formConfig.fields.length)
            return null;
        return formConfig;
    };
    BookingStep2Service.prototype.requestBookingData = function (item, target, mode, initialFormData) {
        if (mode === void 0) { mode = 'create'; }
        if (!item)
            return of(null);
        var requestData = {};
        if (mode == 'create') {
            requestData.trip_booking_id = this.datasetRS.recordId;
            // support other target ??
            if (target != 'product')
                return of(requestData);
            requestData.target = 'product';
            requestData.product_id = item.id;
        }
        var formConfig = this.getAditionalForm(item, target, mode);
        if (!formConfig)
            return of(mode == 'create' ? requestData : null);
        return this.matDialog
            .open(FormDialogComponent, {
            data: {
                formData: initialFormData,
                formConfig: formConfig
            }
        })
            .afterClosed()
            .pipe(switchMap(function (data) {
            if (mode == 'create') {
                requestData.target_info = data;
                return of(requestData);
            }
            else {
                return of(data);
            }
        }));
    };
    BookingStep2Service.prototype.addProduct = function () {
        var _this = this;
        this.matDialog
            .open(SelectDatasetRecordDialogComponent, {
            panelClass: 'create-dialog-container',
            data: productSelectionViewConfig
        })
            .afterClosed()
            .pipe(switchMap(function (product) {
            return _this.requestBookingData(product, 'product');
        }))
            .subscribe(function (postData) {
            if (!postData)
                return;
            _this.loading = true;
            _this.datasetService
                .post('/dataset/trip_booking_packages/create', postData)
                .subscribe({
                next: function (response) {
                    _this.loading = false;
                    _this.reloadTripBookingPackages();
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                },
                error: function (response) {
                    _this.loading = false;
                }
            });
        });
    };
    BookingStep2Service.prototype.selectReturnTrip = function (going_trip_id) {
        var _this = this;
        var dialogData = {
            title: 'Seleziona Ritorno',
            datasetCode: 'going_and_returns',
            filters: { going_trip_id: going_trip_id },
            viewConfig: {
                itemViewType: ListViewItemTypes.TABLE,
                defaultSortBy: 'created_at',
                defaultSortDirection: 'asc',
                showSearchBar: false,
                columns: [
                    {
                        title: 'Descrizione',
                        key: 'return_description'
                    },
                    {
                        title: 'Orario',
                        key: 'return_start_time'
                    }
                ]
            }
        };
        this.matDialog
            .open(SelectDatasetRecordDialogComponent, {
            panelClass: 'create-dialog-container',
            data: dialogData
        })
            .afterClosed()
            .subscribe(function (result) {
            _this.loading = true;
            _this.datasetService
                .post('/dataset/trip_booking_packages/create', {
                trip_booking_id: _this.datasetRS.recordId,
                actual_trip_id: result.return_trip_id
            })
                .subscribe({
                next: function (response) {
                    _this.loading = false;
                    _this.reloadTripBookingPackages();
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING
                    });
                },
                error: function (response) {
                    _this.loading = false;
                }
            });
        });
    };
    BookingStep2Service.prototype.activateResource = function (bookingResource) {
        var _this = this;
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            data: {
                title: 'Azione in corso'
            }
        });
        this.datasetService
            .post('/dataset/trip_booking_resources/command/activate', {
            trip_booking_resource_id: bookingResource.id
        })
            .subscribe({
            next: function (response) {
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGE,
                    extra: {
                        trip_booking_package_id: bookingResource.trip_booking_package_id
                    }
                });
                dialogRef.close();
            },
            error: function (response) {
                dialogRef.componentInstance.resultStatus = 'error';
                dialogRef.componentInstance.loading = false;
                dialogRef.componentInstance.setMessage('Si è verificato un errore');
            }
        });
        return dialogRef;
    };
    BookingStep2Service.prototype.deactivateResource = function (bookingResource) {
        var _this = this;
        var dialogRef = this.matDialog.open(ActionDialogComponent, {
            data: {
                title: 'Azione in corso'
            }
        });
        this.datasetService
            .post('/dataset/trip_booking_resources/command/deactivate', {
            trip_booking_resource_id: bookingResource.id
        })
            .subscribe({
            next: function (response) {
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING_PACKAGE,
                    extra: {
                        trip_booking_package_id: bookingResource.trip_booking_package_id
                    }
                });
                dialogRef.close();
            },
            error: function (response) {
                dialogRef.componentInstance.resultStatus = 'error';
                dialogRef.componentInstance.loading = false;
                dialogRef.componentInstance.setMessage('Si è verificato un errore');
            }
        });
        return dialogRef;
    };
    BookingStep2Service.prototype.makeNotActivableReason = function (bookingResource) {
        if (!bookingResource || !bookingResource.optional)
            return null;
        if (typeof bookingResource.max_allotment === 'undefined')
            return null;
        if (bookingResource.max_allotment === null) {
            return 'Questa risorsa non ha una regola di allotments';
        }
        if (bookingResource.max_allotment === 0) {
            return 'Questa risorsa non ha allotment';
        }
        return null;
    };
    BookingStep2Service.prototype.editMeetingPoint = function (bookingResource) {
        var _this = this;
        var data = {
            datasetCode: 'pivot_trip_resources_trip_meeting_points',
            filters: {
                trip_resource_id: bookingResource.trip_resource_id,
                parentRecordId: bookingResource.trip_resource_id
            },
            title: 'Punto di ritrovo'
        };
        this.matDialog
            .open(SelectDatasetRecordDialogComponent, {
            data: data
        })
            .afterClosed()
            .subscribe(function (record) {
            if (!record)
                return;
            _this.loadPickupMeetingPoint = true;
            _this.datasetService
                .post(_this.datasetACS.getUpdatePropertiesRoute(bookingResource), {
                properties_meeting_point_id: record.id
            })
                .subscribe({
                next: function (response) {
                    _this.loadMeetingPoint(bookingResource, record);
                    _this.appService.appEvent.next({
                        name: RELOAD_BOOKING_PACKAGE,
                        extra: {
                            trip_booking_package_id: bookingResource.trip_booking_package_id
                        }
                    });
                },
                error: function (response) {
                    console.warn(response);
                }
            });
        });
    };
    BookingStep2Service.prototype.loadMeetingPoint = function (bookingResource, record) {
        var _this = this;
        var meeting_point_id = bookingResource &&
            bookingResource.properties.meeting_point_id.value;
        if (record) {
            meeting_point_id = record.id;
        }
        if (!meeting_point_id) {
            return;
        }
        this.loadPickupMeetingPoint = true;
        this.datasetService
            .get('/dataset/meeting_points/detail/' + meeting_point_id)
            .subscribe({
            next: function (response) {
                _this.meetingPointPickupResource = response;
                _this.loadPickupMeetingPoint = false;
            },
            error: function (response) {
                _this.loadPickupMeetingPoint = false;
            }
        });
    };
    BookingStep2Service.prototype.addTrenitaliaService = function (tripBookingPackageToReplace, type) {
        var _this = this;
        if (this.datasetRS.record.value.participant_count == 0 && type != 'carnet') {
            this.matDialog.open(TextDialogComponent, {
                minWidth: '550px',
                data: {
                    title: 'Attenzione',
                    contentMessage: 'Aggiungere prima i partecipanti!',
                    positiveText: 'OK'
                }
            });
            return;
        }
        var component;
        switch (type) {
            case 'carnet':
                component = TrenitaliaCarnetComponent;
                break;
            default:
                component = TrenitaliaDialogContainerComponent;
                break;
        }
        this.matDialog
            .open(component, {
            minWidth: '800px',
            panelClass: 'create-dialog-container',
            data: {
                participant: this.datasetRS.record.value.participant_counters,
                trip_booking_id: this.datasetRS.record.value.id,
                trip_booking_package: tripBookingPackageToReplace
            }
        })
            .afterClosed()
            .pipe(filter(function (res) { return res; }), exhaustMap(function (res) {
            _this.loading = true;
            var postData = {
                trip_booking_id: _this.datasetRS.recordId,
                target: 'trenitalia',
                target_info: res
            };
            var actionEndPoint = '/dataset/trip_booking_packages/create';
            if (tripBookingPackageToReplace) {
                postData['trip_booking_package_id_to_replace'] =
                    tripBookingPackageToReplace.id;
                actionEndPoint =
                    '/dataset/trip_bookings/command/replace_trip_external_service';
            }
            return _this.datasetService.post(actionEndPoint, postData);
        })).subscribe({
            next: function (response) {
                _this.reloadTripBookingPackages();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
                _this.loading = false;
            },
            error: function (err) {
                _this.loading = false;
            }
        });
    };
    BookingStep2Service.prototype.openTripBookingRelatedDialog = function (datasetCode, tripBookingPackageRelatedDialogData) {
        var _this = this;
        var components = {
            trip_booking_resources: TripBookingResourceComponent,
            trip_booking_services: TripBookingServiceComponent,
            trip_booking_participant_extras: TripBookingParticipantComponent
        };
        if (!components[datasetCode])
            return;
        var data = tslib_1.__assign({}, tripBookingPackageRelatedDialogData, { datasetCode: datasetCode, actionCode: 'list' });
        this.datasetNavigatorService
            .openDialog(components[datasetCode], data, {
            panelClass: 'trip-booking-related-dialog-container',
            minWidth: 700
        })
            .afterClosed()
            .subscribe(function () {
            _this.reloadTripBooking();
        });
    };
    BookingStep2Service.prototype.reloadTripBooking = function () {
        this.appService.appEvent.next({ name: RELOAD_BOOKING });
    };
    return BookingStep2Service;
}());
export { BookingStep2Service };
