import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { IPaginationResponse } from 'app/interfaces';
import { Subject, Subscription } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {DragDropModule, CdkDragSortEvent, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IUploadFileDialogDataImageOptions } from '../../dialogs/upload-file-dialog/dialog.component';

interface IConfigs{
	datasetCode: string;
	parentDatasetCode: string;
	parentForeignKey: string;
}

@Component({
	selector   : 'carousel-block',
	templateUrl: './view.component.html',
	styleUrls  : ['./view.component.scss'],
	providers  : [ DatasetActionContainerService ]
})

export class CarouselBlockComponent implements OnInit, OnDestroy{

	@Input() showTitle = true;
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() parentDatasetRS: DatasetRecordService;
	@Input() configs: IConfigs;
	@Input() imageOptions: IUploadFileDialogDataImageOptions;

	protected _unsubscribeAll: Subject<any>;

	public dragging = true;
	public imagesPage: IPaginationResponse<any>;
	public inputFile: any;
	public uploading = false;
	public dataList = [];
	public reorderSubscription: Subscription;
	public imageChangedEvent: any;
	public croppedFile: any;
	public imageRatio = 1920 / 1080;
	public imageWidth = 1920;
	public imageHeight = 1080;

	constructor(
		public appService: AppService,
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService,
		public datasetNavigatorService: DatasetNavigatorService,
		private fuseTranslationLoader: FuseTranslationLoaderService){
			// load translations
			this.fuseTranslationLoader.loadTranslations(english, italiano);
	}

	ngOnInit(): void{
		if(this.imageOptions){
			if(this.imageOptions.ratio){
				this.imageRatio = this.imageOptions.ratio;
			}
			if(this.imageOptions.minWidth){
				this.imageWidth = this.imageOptions.minWidth;
			}
			if(this.imageOptions.minHeight){
				this.imageHeight = this.imageOptions.minHeight;
			}
		}

		this._unsubscribeAll = new Subject();
		this.datasetACS.init({
			datasetCode: this.configs.datasetCode,
			actionCode: 'list',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			fetchData: false,
			useBeforeCalls: false
		})
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.loadImages();
		});

		this.datasetACS.datasetEvent
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			this.loadImages();
		});
	}

	loadImages(): void{
		this.datasetACS.loading.next(true);
		this.datasetService.get<any>(this.datasetACS.getListRoute())
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.datasetACS.loading.next(false);
				this.imagesPage = response;
				if(this.imagesPage && this.imagesPage.items){
					this.dataList = this.imagesPage.items.concat([]);
				}else{
					this.dataList = [];
				}
			},
			error: () => {
				this.datasetACS.loading.next(false);
			}
		});
	}

	ngOnDestroy(): void{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onFileDragStart(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDragLeave(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}

	onFileDragOver(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDrop(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
		let file = null;
		if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
			file = event.dataTransfer.items[0].getAsFile();
		}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
			file = event.dataTransfer.files[0];
		}
		if(file) this.imageChangedEvent = { target: { files: [file]} };
		else this.imageChangedEvent = null;
		this.inputFile = file;
	}

	onFileChange(event): void{
		if(event.target.files.length > 0) {
			const file = event.target.files[0];
			this.imageChangedEvent = event;
			this.inputFile = file;
			this.uploadFile();
		}else{
			this.inputFile = null;
			this.imageChangedEvent = null;
		}
		
	}

	trackImageBy(index: number, item: any): any{
		return index;
	}

	uploadFile(): void{
		const formData = new FormData();
		if(!this.croppedFile) return;
		formData.append(this.configs.parentForeignKey, this.parentDatasetRS.recordId);
		formData.append('file', this.croppedFile);
		this.uploading = true;
		this.datasetService.post<any>('/dataset/' + this.configs.parentDatasetCode + '/command/upload_carousel_image', formData)
		.subscribe({
			next: response => {
				this.inputFile = null;
				this.imageChangedEvent = null;
				this.croppedFile = null;
				this.uploading = false;
				this.loadImages();
			},
			error: response => {
				this.uploading = false;
			}
		});
	}

	imageCropped(event: ImageCroppedEvent): void {
		let invalidImage = null;
		if(event.file){
			if(this.imageOptions){
				if(this.imageOptions.minWidth > event.width){
					invalidImage = 'Dimensioni immagine non supportate';
				}else if(this.imageOptions.minHeight > event.height){
					invalidImage = 'Dimensioni immagine non supportate';
				}
			}
		}
		if(invalidImage){
			this.inputFile = null;
			this.croppedFile = null;
			this.imageChangedEvent = null;
			this.appService.showErrorMessage(invalidImage);
			return;
		}
		this.croppedFile = event.file;
	}
	imageLoaded(): void{
		// show cropper
	}
	cropperReady(): void{
		// cropper ready
	}
	loadImageFailed(): void{
		this.appService.showErrorMessage('Impossibile caricare l\'immagine');
	}

	onDropList(event: CdkDragDrop<any[]>): void{
		if(this.parentDatasetRS.record.value && 
			this.parentDatasetRS.record.value.allowed_actions && 
			this.parentDatasetRS.record.value.allowed_actions.includes('edit')
		){
			moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);
			this.updateOrder();
		}
	}

	removeImage(document): void{
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, document);
	}

	updateOrder(): void{
		const params = {};
		params[this.configs.parentForeignKey] = this.datasetACS.getValueFromKeyPath(this.configs.parentDatasetCode + '.record.id');
		params['carousel_images'] = {};

		let i = 0;
		for (const item of this.dataList) {
			params['carousel_images'][item.id] = i;
			i++;
		}

		if(this.reorderSubscription && !this.reorderSubscription.closed){
			this.reorderSubscription.unsubscribe();
		}

		this.reorderSubscription = this.datasetService.post<any>('/dataset/' + this.configs.parentDatasetCode + '/command/reorder_carousel_images', params)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.appService.showSuccessMessage('Ordine immagini modificato.');
			},
			error: response => {

			}
		});
	}
}
