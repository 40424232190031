/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./export-data-tool.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i5 from "@angular/material/menu";
import * as i6 from "@angular/common";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/material/tooltip";
import * as i9 from "@angular/cdk/overlay";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i15 from "@angular/material/button";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "@ngx-translate/core";
import * as i18 from "./export-data-tool.component";
import * as i19 from "@angular/common/http";
import * as i20 from "../../../../../services/app.service";
import * as i21 from "@angular/material/dialog";
var styles_ExportDataToolComponent = [i0.styles];
var RenderType_ExportDataToolComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportDataToolComponent, data: {} });
export { RenderType_ExportDataToolComponent as RenderType_ExportDataToolComponent };
function View_ExportDataToolComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.buttonIcon; _ck(_v, 2, 0, currVal_2); }); }
function View_ExportDataToolComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[2, "ml-4", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.buttonIcon; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.buttonText; _ck(_v, 1, 0, currVal_1); }); }
function View_ExportDataToolComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "button", [["class", "mr-4 mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("longpress" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.downloadFile(_v.context.$implicit) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i4.View_MatMenuItem_0, i4.RenderType_MatMenuItem)), i1.ɵdid(1, 180224, [[1, 4], [2, 4]], 0, i5.MatMenuItem, [i1.ElementRef, i6.DOCUMENT, i7.FocusMonitor, [2, i5.MAT_MENU_PANEL]], null, null), i1.ɵdid(2, 212992, null, 0, i8.MatTooltip, [i9.Overlay, i1.ElementRef, i10.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i11.Platform, i7.AriaDescriber, i7.FocusMonitor, i8.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i12.Directionality], [2, i8.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i13.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExportDataToolComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExportDataToolComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_6 = _v.context.$implicit.tooltip; _ck(_v, 2, 0, currVal_6); var currVal_7 = _v.context.$implicit.buttonIcon; _ck(_v, 4, 0, currVal_7); var currVal_8 = _v.context.$implicit.buttonText; _ck(_v, 6, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).role; var currVal_1 = i1.ɵnov(_v, 1)._highlighted; var currVal_2 = i1.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_ExportDataToolComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["aria-haspopup", "true"], ["class", "btn-export mat-menu-trigger"], ["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i14.View_MatButton_0, i14.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i15.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i16.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 1196032, null, 0, i5.MatMenuTrigger, [i9.Overlay, i1.ElementRef, i1.ViewContainerRef, i5.MAT_MENU_SCROLL_STRATEGY, [2, i5.MatMenu], [8, null], [2, i12.Directionality], i7.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(3, 0, [" ", "\n"])), i1.ɵpid(131072, i17.TranslatePipe, [i17.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 8, "mat-menu", [], null, null, null, i4.View__MatMenu_0, i4.RenderType__MatMenu)), i1.ɵprd(6144, null, i5.MatMenu, null, [i5._MatMenu]), i1.ɵprd(6144, null, i5.MAT_MENU_PANEL, null, [i5.MatMenu]), i1.ɵdid(8, 1294336, [["appMenu", 4]], 3, i5._MatMenu, [i1.ElementRef, i1.NgZone, i5.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 1, { _allItems: 1 }), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(603979776, 3, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExportDataToolComponent_1)), i1.ɵdid(13, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 8); _ck(_v, 2, 0, currVal_4); _ck(_v, 8, 0); var currVal_6 = _co.viewConfig.exportBtnActions; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).menuOpen || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("Esportazione")); _ck(_v, 3, 0, currVal_5); }); }
export function View_ExportDataToolComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "export-data-tool", [], null, null, null, View_ExportDataToolComponent_0, RenderType_ExportDataToolComponent)), i1.ɵdid(1, 49152, null, 0, i18.ExportDataToolComponent, ["ListParamService", i19.HttpClient, i20.AppService, i21.MatDialog], null, null)], null, null); }
var ExportDataToolComponentNgFactory = i1.ɵccf("export-data-tool", i18.ExportDataToolComponent, View_ExportDataToolComponent_Host_0, { viewConfig: "viewConfig", datasetACS: "datasetACS" }, {}, []);
export { ExportDataToolComponentNgFactory as ExportDataToolComponentNgFactory };
