import { Component, OnInit, OnDestroy, Inject, Self, SkipSelf } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ATripBookingPackageRelatedComponent, ITripBookingPackageRelatedDataDialogData } from '../abstract-trip-booking-package-related-data.component';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';

@Component({
	selector   : 'trip-booking-participant',
	templateUrl: './trip-booking-participant.component.html',
	styleUrls: ['./trip-booking-participant.component.scss'],
	providers: [
		DatasetActionContainerService,
		PageClosureContainerService,
		DatasetRecordService
	]
})
export class TripBookingParticipantComponent extends ATripBookingPackageRelatedComponent implements OnInit{

	constructor(
		public appService: AppService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		public bookingService: BookingService,
		public matDialog: MatDialog,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedDataDialogData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedDataDialogData
	){
		super(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data);
	}

	onEdit(): void{
		this.datasetNavigatorService
		.openEditDialog(this.datasetACS, this.datasetRS.record.value)
		.afterClosed().subscribe(response => {
			this.datasetRS.reloadWithParams({
				trip_booking_participant_id: this.datasetRS.record.value.participant_id, 
				trip_booking_package_id: this.data.tripBookingPackage.id
			});
		});
	}

	trash(): void{
		this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.datasetRS.record.value)
		.beforeClosed()
		.subscribe(() => {
			this.datasetRS.record.next(null);
		});
	}
}
