import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('code'),
	fieldsMap.get('name'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('meeting_point_list_id'),
			fieldsMap.get('start_time'),
		]
	},
	fieldsMap.get('week_days'),
	fieldsMap.get('guide_languages'),
	fieldsMap.get('guide_languages_match_mode'),
	fieldsMap.get('special_group')
];

const config: IFormConfig = { 
	editTitle: 'Modifica Opzione Partenza',
	fields: formFields 
};

export default config;
