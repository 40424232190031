<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
	<button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
		<mat-icon>menu</mat-icon>
	</button>

	<div class="logo"><img src="assets/images/logos/tangerine_logo_white.svg" /></div>

	<button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
		<mat-icon>arrow_back</mat-icon>
	</button>
</div>

<div *ngIf="domainLogoId" fxLayout='column' class="logo-mobile fuse-navy-900">
	<img class="logo-icon" [src]='_appService.getBaseServerAddressRoot() + "/document_files/download/" + domainLogoId' alt="Logo Img" />
</div>

<div class="navbar-content" fusePerfectScrollbar
	[fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
	[ngClass]="fuseConfig.layout.navbar.primaryBackground">
	<fuse-navigation layout="vertical"></fuse-navigation>
</div>
