
	<h1 mat-dialog-title>{{data.title?data.title:'Carica pdf' | translate}}</h1>
	<mat-dialog-content>
		<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='loading'></mat-progress-bar>
		<div [innerHTML]="data.message"></div>
		<ng-container *ngIf='data.fileType==="application/pdf"'>
			<div class='file-input-controller'>
				<div class='file-drop-zone' [class.dragging]='dragging' (dragleave)='onFileDragLeave($event)' (dragstart)='onFileDragStart($event)' (dragover)='onFileDragOver($event)' (drop)='onFileDrop($event)' *ngIf='!formGroup.get("file").value'>
					<div fxLayout='column' fxLayoutAlign='center center'>
						<mat-icon color='accent'>cloud_download</mat-icon>
						<span><strong>{{'Trascina il file qui per caricarlo' | translate}}</strong></span>
						<span>{{'oppure clicca per cercare nel computer' | translate}}</span>
						<span>{{'Sono supportati i file: pdf' | translate}}</span>
					</div>
					<input #fileInput (change)="onFileChange($event)" type='file' accept="application/pdf"/>
				</div>
				<div class='file-loaded' *ngIf='pdfChangedEvent'>
					<div *ngIf='fileType === "application/pdf"' fxLayout='column' fxLayoutAlign='center center'>
						<mat-icon color='accent'>insert_drive_file</mat-icon>
						<span *ngIf='selectedFileName'>{{selectedFileName}}</span>
					</div>
					<img *ngIf='pdfPreviewSrc' class='file-loaded-preview' [src]="pdfPreviewSrc" alt='preview' />
					<button class='file-loaded-remove-btn' mat-icon-button (click)='clearFile()'>
						<mat-icon>close</mat-icon>
					</button>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf='data.fileType==="text"'>
				<textarea #textInput (input)="onTextChange($event)" matInput placeholder="Inserire testo" rows="5" cols="50">{{ (data.record.value.note|| "") | translate }}</textarea>
		</ng-container>
	</mat-dialog-content>
	<mat-dialog-actions align='end'>
		<button mat-button (click)="onNoClick()">{{data.negativeText?data.negativeText:'Annulla' | translate}}</button>
		<button mat-flat-button (click)='onConfirmUpload()' [disabled]='loading' color='accent' >{{data.positiveText?data.positiveText:'Carica' | translate}}</button>
	</mat-dialog-actions>
