/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "../popover-arrow.directive";
import * as i4 from "../popover-ref";
import * as i5 from "./popover-container.component";
var styles_PopoverComponent = [i0.styles];
var RenderType_PopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverComponent, data: {} });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
export function View_PopoverComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { portalOutlet: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(2, 212992, [[1, 4]], 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "arrow"], ["popoverArrow", ""]], [[4, "width", "px"], [4, "height", "px"], [4, "top", "px"], [4, "right", "px"], [4, "bottom", "px"], [4, "left", "px"]], null, null, null, null)), i1.ɵdid(4, 147456, null, 0, i3.PopoverArrowDirective, [i4.PopoverRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).arrowSize; var currVal_2 = i1.ɵnov(_v, 4).arrowSize; var currVal_3 = i1.ɵnov(_v, 4).offsetTop; var currVal_4 = i1.ɵnov(_v, 4).offsetRight; var currVal_5 = i1.ɵnov(_v, 4).offsetBottom; var currVal_6 = i1.ɵnov(_v, 4).offsetLeft; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_PopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p1-popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i1.ɵdid(1, 49152, null, 0, i5.PopoverComponent, [], null, null)], null, null); }
var PopoverComponentNgFactory = i1.ɵccf("p1-popover", i5.PopoverComponent, View_PopoverComponent_Host_0, {}, {}, []);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };
