import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields/service-fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
    fieldsMap.get('original_id'),
    fieldsMap.get('code'),
    fieldsMap.get('description'),
    fieldsMap.get('price_base'),
    fieldsMap.get('price_u'),
    fieldsMap.get('type'),
    fieldsMap.get('id_ext'),
    fieldsMap.get('index'),
    fieldsMap.get('scenario'),
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
