import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			{
				name: 'Da',
				key: 'start_from',
				valueType: ValueTypes.DATE,
				inputConfig: {
					type: InputTypes.DATE,
					clearable: true
				}
			},
			{
				key: 'start_to',
				name: 'Fino a',
				valueType: ValueTypes.DATE,
				inputConfig: {
					type: InputTypes.DATE,
					clearable: true
				}
			}
		]
	},
	fieldsMap.get('meeting_point_list_id')
];

const config: IFormConfig = { 
	editTitle: 'Filtri',
	fields: formFields 
};

export default config;
