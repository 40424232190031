import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/services/app.service';
import { Subject, Subscription, Observable, BehaviorSubject, of } from 'rxjs';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { exhaustMap } from 'rxjs/operators';
import * as _ from 'lodash';

const supportedStats = [
	'traveldate',
	'checkoutdate',
	'ticket',
	'ticketsyear',
	'revenue',
	'bookingwindow',
	'sourcemarkets',
	'reviews',
	'reviewsstat'
];

@Injectable()
export class DashBoardService{
	events: any;
	onEventsUpdated: Subject<any>;
	
	filterResource: any[] = [];
	filterCustomerCodes: any;
	filterPackageCodes: any;
	filterCategoryIds: any;
	filterDataS = '';
	filterDataE = '';
	filterBranch = null;
	filterBookingDateFrom: string;
	filterBookingDateTo: string;
	loadingEvents = false;
	refreshEvent: Subject<void>;

	loadingTravelDate = false;
	travelDateSubscription: Subscription;
	travelDateResponse: BehaviorSubject<any>;

	loadingCheckoutDate = false;
	checkoutDateSubscription: Subscription;
	checkoutDateResponse: BehaviorSubject<any>;

	loadingBookingWidow = false;
	bookingWidowSubscription: Subscription;
	bookingWidowResponse: BehaviorSubject<any>;

	loadingSourceMarket = false;
	sourceMarketSubscription: Subscription;
	sourceMarketResponse: BehaviorSubject<any>;

	loadingReviewsStats = false;
	reviewSubscription: Subscription;
	reviewResponse: BehaviorSubject<any>;

	groupingBy: BehaviorSubject<string>;

	public enabledStats = supportedStats;

	public dashboardEnabled = false;
	
	constructor(
		public http: HttpClient,
		public appService: AppService,
		private domainFilterService: DomainFilterService
	){
		this.onEventsUpdated = new Subject();
		this.refreshEvent = new Subject();
		this.travelDateResponse = new BehaviorSubject(null);
		this.checkoutDateResponse = new BehaviorSubject(null);
		this.bookingWidowResponse = new BehaviorSubject(null);
		this.sourceMarketResponse = new BehaviorSubject(null);
		this.reviewResponse = new BehaviorSubject(null);
		this.groupingBy = new BehaviorSubject('checkout_date');

		this.refreshEvent
		.subscribe(
			() => {
				this.dashboardEnabled = this.getViewDashboardPermission();
				this.refreshEnabledStats();
				this.refreshTravelDate();
				this.refreshCheckoutDate();
				this.refreshBookingWidow();
				this.refreshSourceMarkets();
				this.refreshReviewStats();
			},
			(error: any) => console.log(error)
		);
	}

	public getViewDashboardPermission(){
		const userPermissions: any[] = this.domainFilterService.getDomainSetting('current', 'user_permissions');
		if(!userPermissions || !Array.isArray(userPermissions)){
			return false;
		}
		const dashboardGroup = userPermissions.find(p => p.code == 'dashboard');
		if(!dashboardGroup || !Array.isArray(dashboardGroup.actions)){
			return false;
		}
		const viewDashboardAction = dashboardGroup.actions.find(a => a.code == 'view_dashboard');
		if(!viewDashboardAction || !viewDashboardAction.active){
			return false;
		}
		return true;
	}

	public refreshEnabledStats(){
		const configDashboardStats = this.domainFilterService.getDomainSetting('current', 'backoffice.dashboard_stats');
		if(configDashboardStats && Array.isArray(configDashboardStats)){
			this.enabledStats = supportedStats.filter((val) => configDashboardStats.includes(val));
		}else{
			this.enabledStats = supportedStats;
		}
	}

	public refreshGroupingDate(): void{
		if(!this.filterBookingDateTo && !this.filterBookingDateFrom && (this.filterDataS || this.filterDataE)){
			this.groupingBy.next('departure_date');
		}else{
			this.groupingBy.next('checkout_date');
		}
	}

	public getData(route: any): Observable<any>{
		this.loadingEvents = true;
		return this.http.post(this.appService.getBaseServerAddress() + route, {
			params: {
				package: this.filterPackageCodes,
				resource: this.filterResource.map(el => el.id),
				category: this.filterCategoryIds,
				client: this.filterCustomerCodes,
				start: this.filterDataS,
				end: this.filterDataE,
				branches: this.filterBranch,
				start_booking: this.filterBookingDateFrom,
				end_booking: this.filterBookingDateTo,
				filteredDomainId: this.domainFilterService.filterDomainId.value,
			}
		});
	}

	public refreshTravelDate(): void{
		if(this.travelDateSubscription){
			this.travelDateSubscription.unsubscribe();
		}
		if(!this.dashboardEnabled) return;
		this.loadingTravelDate = true;
		this.travelDateSubscription = this.getData('/chartbar/TravelDate')
		.subscribe({
			next: (response) => {
				this.travelDateResponse.next(response);
				this.loadingTravelDate = false;
			},
			error: () => {
				this.loadingTravelDate = false;
			}
		});
	}

	public refreshCheckoutDate(): void{
		if(this.checkoutDateSubscription){
			this.checkoutDateSubscription.unsubscribe();
		}
		if(!this.dashboardEnabled) return;
		this.loadingCheckoutDate = true;
		this.checkoutDateSubscription = this.getData('/chartbar/CheckoutDate')
		.subscribe({
			next: (response) => {
				this.checkoutDateResponse.next(response);
				this.loadingCheckoutDate = false;
			},
			error: () => {
				this.loadingCheckoutDate = false;
			}
		});
	}

	public refreshReviewStats(): void{
		if(this.reviewSubscription){
			this.reviewSubscription.unsubscribe();
		}
		if(!this.dashboardEnabled) return;
		if(this.enabledStats.findIndex(val => ['reviews', 'reviewsstat'].includes(val)) == -1) return;
		this.loadingReviewsStats = true;
		this.reviewSubscription = this.getData('/chartbar/ReviewsStats')
		.subscribe({
			next: (response) => {
				this.reviewResponse.next(response);
				this.loadingReviewsStats = false;
			},
			error: () => {
				this.loadingReviewsStats = false;
			}
		});
	}

	public refreshSourceMarkets(): void{
		if(this.sourceMarketSubscription){
			this.sourceMarketSubscription.unsubscribe();
		}
		if(!this.dashboardEnabled) return;
		if(!this.enabledStats.includes('sourcemarkets')) return;
		this.loadingSourceMarket = true;
		this.sourceMarketSubscription = this.getData('/chartbar/SourceMarkets')
		.subscribe({
			next: (response) => {
				this.sourceMarketResponse.next(response);
				this.loadingSourceMarket = false;
			},
			error: () => {
				this.loadingSourceMarket = false;
			}
		});
	}

	public refreshBookingWidow(): void{
		if(this.bookingWidowSubscription){
			this.bookingWidowSubscription.unsubscribe();
		}
		if(!this.dashboardEnabled) return;
		if(!this.enabledStats.includes('bookingwindow')) return;
		this.loadingBookingWidow = true;
		this.bookingWidowSubscription = this.getData('/chartbar/BookingWindow')
		.subscribe({
			next: (response) => {
				this.bookingWidowResponse.next(response);
				this.loadingBookingWidow = false;
			},
			error: () => {
				this.loadingBookingWidow = false;
			}
		});
	}
}
