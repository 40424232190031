import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome del punto di ritrovo',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

export {fieldsMap};
