import { OnDestroy, OnInit, } from '@angular/core';
import { TrenitaliaService } from '../trenitalia.service';
import { FormViewerService } from 'app/services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetService } from '../../../../components/dataset/services/dataset.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
var SearchTravellersComponent = /** @class */ (function () {
    function SearchTravellersComponent(trnService, formViewerService, datasetACS, datasetService) {
        var _this = this;
        this.trnService = trnService;
        this.formViewerService = formViewerService;
        this.datasetACS = datasetACS;
        this.datasetService = datasetService;
        this.formFields = formFields;
        this.checkPass = true;
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values())
        });
        this._unsubscribeAll = new Subject();
        this.trnService.checkoutData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (data) {
            _this.checkoutData = data;
        });
        this.passenger1 = new BehaviorSubject({});
    }
    Object.defineProperty(SearchTravellersComponent.prototype, "requestInvoice", {
        get: function () {
            var request_invoice = this.formViewerService.formGroup.get('request_invoice');
            return request_invoice ? request_invoice.value : false;
        },
        enumerable: true,
        configurable: true
    });
    SearchTravellersComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    SearchTravellersComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (['edit-travellers', 'edit', 'edit-traveller-pre-sale'].includes(this.checkoutData.mode)) {
            this.checkPass = false;
            this.passengers = this.checkoutData.editPassengers.map(function (traveller, k) {
                var passenger = {
                    type: traveller.type,
                    num: 0,
                    valid: false,
                    dataPass: {
                        pass_first_name: traveller.name,
                        pass_last_name: traveller.surname,
                        pass_email: traveller.email,
                        pass_phone: traveller.phoneNumber,
                        pass_cf: traveller.cartaFreccia,
                        contact_consent: Boolean(traveller.contact_consent || true)
                    },
                    travellerXmlId: ['edit-travellers', 'edit-traveller-pre-sale'].includes(_this.checkoutData.mode) ? traveller.xmlId : _.get(_this.checkoutData, ['travellers', k, 'xmlId'])
                };
                if (traveller.birthDate) {
                    passenger.dataPass.pass_data_n = moment(traveller.birthDate, 'DD/MM/YYYY');
                }
                return passenger;
            });
            if (['edit', 'edit-traveller-pre-sale'].includes(this.checkoutData.mode)) {
                this.formViewerService.setValues({
                    first_name: this.checkoutData.buyer.name,
                    last_name: this.checkoutData.buyer.surname,
                    email: this.checkoutData.buyer.email,
                    //confirm_email: this.checkoutData.buyer.email,
                    phone: this.checkoutData.buyer.phoneNumber
                });
            }
            if (this.checkoutData.invoiceRequested)
                this.formViewerService.formGroup.get('request_invoice').setValue(true);
        }
        else {
            this.load = true;
            this.trnService.loading.next(true);
            this.datasetService
                .get('/dataset/trip_booking_participants', {
                trip_booking_id: this.checkoutData.tripBookingId,
                sortBy: 'created_at',
                sortDirection: 'desc',
                perPage: 50,
                page: 1
            }).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function (result) {
                if (result.items && result.items.length) {
                    _this.passengers = [];
                    if (_this.checkoutData.editPassengers) {
                        _this.oldPassengers = _this.checkoutData.editPassengers;
                    }
                    _this.initContactData(result.items);
                    if (_this.checkoutData.editPassengers) {
                        _this.passengers = _this.checkoutData.editPassengers;
                    }
                    else {
                        var usedTangerinePassengerIds_1 = new Set();
                        _this.passengers = _this.checkoutData.travellers.map(function (t) {
                            var candidateTripBookingParticipant = result.items.find(function (p) {
                                if (usedTangerinePassengerIds_1.has(p.id))
                                    return false;
                                if (['ADT', 'SNR'].includes(p.age_range_code) && t.type != 'ADULT')
                                    return false;
                                return true;
                            });
                            if (_.has(candidateTripBookingParticipant, 'id'))
                                usedTangerinePassengerIds_1.add(_.get(candidateTripBookingParticipant, 'id'));
                            var data = _this.getPassengerDataFromTripBookingParticipant(candidateTripBookingParticipant);
                            return {
                                type: t.type,
                                num: 0,
                                valid: false,
                                dataPass: {
                                    pass_first_name: data.name,
                                    pass_last_name: data.surname,
                                    pass_email: data.email,
                                    pass_phone: data.phone
                                },
                                tangerine_id: _.get(candidateTripBookingParticipant, 'id'),
                                travellerXmlId: t.xmlId
                            };
                        });
                    }
                }
                _this.trnService.loading.next(false);
                _this.load = false;
                _this.checkoutData.passengers = _this.passengers;
                _this.trnService.checkoutData.next(_this.checkoutData);
            });
        }
        this.formViewerService.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (s) {
            _this.passenger1.next(_this.formViewerService.getFormData());
            _this.checkValidate();
        });
    };
    SearchTravellersComponent.prototype.togglePassengerBuyer = function () {
        this.checkPass = !this.checkPass;
        this.passenger1.next(this.formViewerService.getFormData());
    };
    SearchTravellersComponent.prototype.indexTrackBy = function (index, item) {
        return index;
    };
    SearchTravellersComponent.prototype.getPassengerDataFromTripBookingParticipant = function (tripBookingParticipant) {
        var name = null;
        var lastname = null;
        var descParts = [null, null];
        if (_.get(tripBookingParticipant, 'description')) {
            descParts = _.get(tripBookingParticipant, 'description').split(' ');
        }
        name = _.defaultTo(_.get(tripBookingParticipant, 'entity.firstname'), descParts[0]);
        lastname = _.defaultTo(_.get(tripBookingParticipant, 'entity.lastname'), _.get(descParts, '1'));
        return {
            name: name,
            surname: lastname,
            email: _.get(tripBookingParticipant, 'entity.primary_email'),
            phone: _.get(tripBookingParticipant, 'entity.phone_number')
        };
    };
    SearchTravellersComponent.prototype.initContactData = function (tripBookingParticipants) {
        if (!tripBookingParticipants || tripBookingParticipants.length == 0)
            return;
        // get first entity adult
        var candidateContact = tripBookingParticipants.sort(function (a, b) {
            if (['ADT', 'SNR'].includes(a.age_range_code)) {
                if (a.entity || !['ADT', 'SNR'].includes(b.age_range_code))
                    return -1;
                return 1;
            }
            ;
            return 1;
        })[0];
        if (!candidateContact) {
            console.log('no valid candidate found', tripBookingParticipants);
            this.formViewerService.formGroup.reset();
            return;
        }
        var data = this.getPassengerDataFromTripBookingParticipant(candidateContact);
        this.formViewerService.setValues({
            first_name: data.name,
            last_name: data.surname,
            email: data.email,
            confirm_email: data.email,
            phone: data.phone
        });
    };
    /**
     * Prende il numero per il form
     * @param e
     */
    SearchTravellersComponent.prototype.getValid = function (e) {
        this.passengers[e.index].valid = e.valid;
        this.passengers[e.index].dataPass = e.data;
        _.unset(this.checkoutData, 'invalid_traveller_parameters.' + this.passengers[e.index].travellerXmlId);
        this.checkValidate();
    };
    SearchTravellersComponent.prototype.checkValidate = function () {
        var validate = this.passengers.every(function (p) { return p.valid; });
        var buyerValid = this.checkoutData.mode == 'edit-travellers' || this.formViewerService.formGroup.valid;
        this.checkoutData.finalTravellersValid = validate && buyerValid;
        if (this.checkoutData.mode != 'edit-travellers') {
            var dataBuyer = this.formViewerService.getFormData();
            this.checkoutData.buyer = {
                name: dataBuyer.first_name,
                surname: dataBuyer.last_name,
                email: dataBuyer.email,
                phoneNumber: dataBuyer.phone
            };
            this.checkoutData.invoiceRequested = dataBuyer.request_invoice;
        }
        this.checkoutData.finalTravellers = this.passengers;
        this.checkoutData.finalTravellersValid = validate;
        this.trnService.checkoutData.next(this.checkoutData);
    };
    SearchTravellersComponent.prototype.checkInvoiceHolder = function () { };
    return SearchTravellersComponent;
}());
export { SearchTravellersComponent };
