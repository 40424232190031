import { OnInit } from '@angular/core';
import { FormViewerService } from '../../../../../services/form-viewer.service';
import { DatasetActionContainerService } from '../../../../components/dataset/services/dataset-action-container.service';
import { fieldsMap as formFields } from './form.fields';
import { MatDialogRef } from '@angular/material/dialog';
import { TrenitaliaService } from '../trenitalia.service';
import { dialogDataFactory } from 'app/helpers';
var ɵ0 = dialogDataFactory;
var TrenitaliaAuthComponent = /** @class */ (function () {
    function TrenitaliaAuthComponent(datasetACS, formViewerService, dialogRef, trnService) {
        this.datasetACS = datasetACS;
        this.formViewerService = formViewerService;
        this.dialogRef = dialogRef;
        this.trnService = trnService;
        this.formFields = formFields;
        this.loading = false;
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values())
        });
    }
    TrenitaliaAuthComponent.prototype.ngOnInit = function () {
    };
    TrenitaliaAuthComponent.prototype.login = function () {
        var _this = this;
        this.loading = true;
        var data = this.formViewerService.getFormData();
        this.trnService.login(data).subscribe(function (user) {
            _this.loading = false;
            _this.dialogRef.close(user);
        }, function (error) {
            _this.loading = false;
            _this.error = {
                message: "Credenziali errate riprovare"
            };
        });
    };
    TrenitaliaAuthComponent.prototype.onCloseClick = function () {
        this.dialogRef.close(false);
    };
    return TrenitaliaAuthComponent;
}());
export { TrenitaliaAuthComponent };
export { ɵ0 };
