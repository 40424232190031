import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseDatasetPageComponent } from '../base-dataset-page.component';
import { PageClosureContainerService } from '../../page-closure-container.service';
import { takeUntil } from 'rxjs/operators';
var DefaultDatasetPageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DefaultDatasetPageComponent, _super);
    function DefaultDatasetPageComponent(route, datasetService, dialog, router, pageClosureContainerService) {
        var _this = _super.call(this, route, datasetService, dialog, router, pageClosureContainerService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.dialog = dialog;
        _this.router = router;
        _this.pageClosureContainerService = pageClosureContainerService;
        return _this;
    }
    DefaultDatasetPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.pageClosureContainerService.sharedData
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (sharedData) {
            _this.sharedData = sharedData;
        });
    };
    DefaultDatasetPageComponent.prototype.handleDomainChanges = function () {
        return this.actionCode === 'list';
    };
    Object.defineProperty(DefaultDatasetPageComponent.prototype, "styleClassDataset", {
        get: function () {
            var datasetCodes = ['trip_packages'];
            return datasetCodes.includes(this.datasetCode) ? 'style-01' : '';
        },
        enumerable: true,
        configurable: true
    });
    return DefaultDatasetPageComponent;
}(BaseDatasetPageComponent));
export { DefaultDatasetPageComponent };
