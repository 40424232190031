import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('trip_package_id', cloneFieldDefinition(tripPackageIdField, {
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		required: false
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return record.trip_package.description;
	}
}));

fieldsMap.set('entity_customer_id', {
	key: 'entity_customer_id',
	name: 'Cliente',
	valueType: ValueTypes.REFERENCE,
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		required: true
	},
	datasetCode: 'entity_customers',
	remoteOptions: {
		limit: 5,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ') ' + option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	getDisplayValue(record, referenceRecord: any): string{
		if(!referenceRecord) return '---';
		return referenceRecord.entity.description;
	}
});

fieldsMap.set('disable_export_sale_operation', {
	key: 'disable_export_sale_operation',
	name: 'Esportazione Pratiche',
	valueType: ValueTypes.NUMBER,
	inputConfig: {
		type: InputTypes.SELECT,
		required: false
	},
	formValidators: [ Validators.required ],
	options: [
		{
			label: 'Disabilitate',
			value: 1
		},
		{
			label: 'Abilitate',
			value: 0
		}
	]
});

export {fieldsMap};
