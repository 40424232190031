import { IFieldContainer, IFieldDefinition, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('id', {
	key: 'id',
	name: 'ID',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('ClosureNumber', {
	key: 'ClosureNumber',
	name: 'ClosureNumber',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('DocumentNumber', {
	key: 'DocumentNumber',
	name: 'DocumentNumber',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER
});

fieldsMap.set('ServerRtSerialNumber', {
	key: 'ServerRtSerialNumber',
	name: 'ServerRtSerialNumber',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('QRCodeRT', {
	key: 'QRCodeRT',
	name: 'QRCodeRT',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

fieldsMap.set('messaggio', {
	key: 'messaggio',
	name: 'messaggio',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING
});

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('id'),
	fieldsMap.get('ClosureNumber'),
	fieldsMap.get('DocumentNumber'),
	fieldsMap.get('ServerRtSerialNumber'),
	fieldsMap.get('QRCodeRT'),
	fieldsMap.get('messaggio')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;