import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, Output } from '@angular/core';

import {BaseDatasetComponent} from '../base-dataset.component';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetService} from 'app/main/components/dataset/services/dataset.service';
import {DatasetActionContainerService, DatasetEvents} from 'app/main/components/dataset/services/dataset-action-container.service';
import {AuthService} from 'app/services/auth.service';
import { DatasetRecordService } from '../../services/dataset-record.service';
import { Observable, isObservable, of } from 'rxjs';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { takeUntil, take, exhaustMap } from 'rxjs/operators';
import { DatasetNavigatorService } from '../../services/dataset-navigator.service';
import { InvalidAllotmentsDialogComponent } from '../../dialogs/invalid-allotments-dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IStepViewConfig } from 'app/interfaces';
import { config as TripPackageStepConfigSmartInterface } from 'app/configs/datasets/trip_packages/stepViewConfigSmartInterface';
import { IFormConfig } from 'app/interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { config as ProductStepViewConfig } from 'app/configs/datasets/products/stepViewConfig';
import { WebSocketService } from 'app/services/websocket.service';

@Component({
	selector   : 'dataset-edit',
	templateUrl: './dataset-update.component.html',
	styleUrls  : ['./dataset-update.component.scss'],
	providers: [DatasetActionContainerService, DatasetRecordService]
})
export class DatasetUpdateComponent extends BaseDatasetComponent implements OnInit{

	stepViewConfig: IStepViewConfig = TripPackageStepConfigSmartInterface;
	productStepViewConfig: IStepViewConfig = ProductStepViewConfig;

	actionCode = 'edit';
	@Input() inputRecord: any;
	@Input() inputRecordId: any;
	@Input() inputFormConfig: IFormConfig;
	@Input() inputActionRoute: string;
	@Input() routed = true;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;
	public formErrors: any;
	@Output() public updated = new EventEmitter<any>();

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected matDialog: MatDialog,
		protected _fuseTranslationLoaderService: FuseTranslationLoaderService,
		protected webSocketService: WebSocketService
	){
		super(route, datasetService, router, authService, datasetACS, _fuseTranslationLoaderService, webSocketService);
	}

	ngOnInit(): void{
		this.datasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			this.updated.emit(record);
		});
		if(this.inputRecordId) this.datasetRS.recordId = this.inputRecordId;
		if(this.inputRecord) this.datasetRS.record.next(this.inputRecord);
		super.ngOnInit();
		this.datasetACS.datasetRS = this.datasetRS;
	}

	protected load(): Observable<any>{
		if(this.firstLoad && this.datasetRS.record.value){
			return of(this.datasetRS.record.value);
		}else{
			return this.datasetRS.reload();
		}
	}

	onSave(): void{
		this.formViewer.formGroup.disable();
		let formData = this.formViewer.getFormData();
		const actionConfig = this.datasetACS.datasetConfig.defaultActions.create;
		if(actionConfig && typeof(actionConfig.getCallParams) === 'function'){
			formData = actionConfig.getCallParams(this.datasetACS, null, formData);
		}
		this.datasetACS.loading.next(true);
		const baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
		let route = baseRoute + '/create';
		if(this.datasetRS.recordId){
			route = baseRoute + '/update/' + this.datasetRS.recordId;
		}
		if(this.inputActionRoute){
			route = this.inputActionRoute;
		}
		of(formData)
		.pipe(
			exhaustMap(formData => {
				if(actionConfig && typeof(actionConfig.beforeSave) === 'function'){
					return actionConfig.beforeSave(this.datasetACS, this.datasetRS.recordId, formData, this.formViewer.record);
				}
				return of(formData);
			}),
			takeUntil(this._unsubscribeAll),
			take(1),
			exhaustMap(data => {
				return this.datasetService.post<any>(route, data);
			})
		)
		.subscribe({
			next: (response) => {
				this.datasetACS.loading.next(false);
				this.datasetACS.datasetEvent.next({eventName: this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
				
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.clearForm();
				}
				if(!this.datasetRS.record.value && this.routed){
					this.datasetNavigatorService.router.navigate(['dataset/edit/' + this.datasetACS.datasetCode, response.id], { queryParams: { step: 1 } });
				}else{
					this.datasetRS.recordId = response.id;
					this.datasetRS.reload();
				}
			},
			error: response => {
				this.datasetACS.loading.next(false);
				if(response && response.error && response.error.errors) this.formErrors = response.error.errors;
				else this.formErrors = null;
				if(this.formViewer){
					this.formViewer.formGroup.enable();
					this.formViewer.setErrors(this.formErrors);
				}

				if(response.status === 400 && response.error.code === 'invalid_allotments'){
					this.matDialog.open(InvalidAllotmentsDialogComponent, {
						data: {
							items: response.error.payload.items,
							datasetCode: this.datasetACS.datasetCode,
							actionCode: this.datasetACS.actionCode,
							count: response.error.payload.count,
							tripResource: response.error.payload.trip_resource
						}
					});
				}
			}
		});
	}
}
