
<!--<div class="style-1">
	<div class="row" fxLayoutAlign="space-between center" fxLayout.sm="row wrap" fxLayout.xs="column wrap" fxLayoutGap="30px">
		<form-input-viewer
			[formInputDefinition]='filterFields.get("start_day")'
			[formGroup]='formViewerService.formGroup'
			[formControlKey]='formViewerService.controlKeyMap["start_day"]'
			[style.width]='"auto"'
		></form-input-viewer>
		<form-input-viewer
			[formInputDefinition]='filterFields.get("trip_package_id")'
			[formGroup]='formViewerService.formGroup'
			[formControlKey]='formViewerService.controlKeyMap["trip_package_id"]'
			[style.width]='"auto"'
		></form-input-viewer>
	</div>
</div>-->

<dataset-list
	#datasetList
	[emitAdd]='true'
	[elevate]='false'	
	[showSearchBar]='true' 
	[showHeaderToolbar]='true'
	[showTitle]='false'
	[selectable]='true'
	datasetCode='products'
	[parentDatasetACS]='datasetACS'
	[parentDatasetRS]='datasetRS'
	[filters]='filters'
	[enableCreate]='false'
	[enableEdit]='false'
	[enableDelete]='false'
	[viewConfig]='productSelectionViewConfig'
	[selectionBtnIcon]="'add'"
	[tableStyle]='"style-2 selectable thead-border-bottom"'
	(selected)='addTripBookingPackage($event)'
></dataset-list>