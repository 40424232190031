import { InputTypes, ValueTypes, IFormConfig, IFieldDefinition, IFieldContainer } from 'app/interfaces';
import {fieldsMap} from './../fields';

const fields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('entity_id'),
	fieldsMap.get('description'),
	fieldsMap.get('age_range_code'),
	fieldsMap.get('nation_code')
];

const baseInfoFormConfig: IFormConfig = {
	fields
};

export default baseInfoFormConfig;
