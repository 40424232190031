import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

//#region entity customer
fieldsMap.set('entity_customer.entity_customer_id', {
	key: 'entity_customer.entity_customer_id',
	name: 'Cliente',
	valueType: ValueTypes.REFERENCE,
	fieldView: FieldViews.HIDDEN,
	formValidators: [ Validators.required ],
	datasetCode: 'entity_customers',
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any {
			if(!option) return '';
			return '(' + option.code + ') ' + option.description;
		},
		getValue(option): any {
			if(!option) return;
			return option.id;
		},
	}	
});

fieldsMap.set('entity_customer.description', {
	key: 'entity_customer.description',
	name: 'Cliente',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});
//#endregion entity customer

export {fieldsMap as channelDefaultCustomerFields};
