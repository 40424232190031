import * as tslib_1 from "tslib";
import { ValueTypes, FieldViews } from 'app/interfaces';
export default {
    key: 'active_language_codes',
    name: 'Traduzioni attive',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
        if (record.active_language_codes && record.active_language_codes.length > 0) {
            var style = 'style="margin-right: 5px;"';
            try {
                for (var _b = tslib_1.__values(record.active_language_codes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var lang = _c.value;
                    html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            html += '<span>---</span>';
        }
        html += '</div>';
        return html;
    }
};
