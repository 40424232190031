import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { FormInputViewerService } from '../../form-input-viewer.service';

@Component({
	selector   : 'multiple-file-input',
	templateUrl: './input.component.html'
})
export class MultipleFileInputComponent{

	public dragging = false;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;

	constructor(
		public inputService: FormInputViewerService
	){}

	onFileDragStart(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDragLeave(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}

	onFileDragOver(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDrop(event): void{
		event.preventDefault();
		event.stopPropagation();
		let file = null;
		if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
			file = event.dataTransfer.items[0].getAsFile();
		}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
			file = event.dataTransfer.files[0];
		}
		this.inputService.control.setValue(file);
	}

	onFileChange(event): void{
		if(event.target.files.length > 0) {
			const files = event.target.files;
			this.inputService.control.setValue(files);
		}else{
			this.inputService.control.setValue(null);
		}
	}

	clearFile(): void{
		this.inputService.control.setValue(null);
		if(this.fileInput){
			this.fileInput.nativeElement.value = null;
		}
	}
}
