import { IListViewConfig, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';


const viewConfig: IListViewConfig = {
	itemViewType: ListViewItemTypes.TABLE,
	showSearchBar: false,
	defaultSortBy: 'created_at',
	defaultSortDirection: 'desc',
	columns: [
		{
			title: 'Descrizione',
			key: 'description',
			fieldDefinition: {
				key: 'discount_ref',
				name: 'Descrizione',
				valueType: ValueTypes.STRING,
				fieldView: FieldViews.HTML,
				getDisplayValue(record: any): string{
					let html = record.description;
					
					if(record.trip_booking_package && record.trip_booking_package.trip_package){
						html = 'Pacchetto: ' + record.trip_booking_package.trip_package.description;
						if(record.trip_booking_resource && record.trip_booking_resource.trip_resource){
							html += '<br>';
							html += 'Risorsa: ' + record.trip_booking_resource.trip_resource.description;
						}
					}else if(record.trip_booking_resource && record.trip_booking_resource.trip_resource){
						html += 'Risorsa: ' + record.trip_booking_resource.trip_resource.description;
					}

					if(record.discounted_trip_booking_service){
						html += '<br>';
						html += 'Servizio: ' + record.discounted_trip_booking_service.description;
					}
					
					return html;
				}
			}
		},
		{
			title: 'Sconto U. Lordo',
			key: 'price_base'
		},
		{
			title: 'Sconto U.',
			key: 'price'
		},
		{
			title: 'Sconto Lordo',
			key: 'total_amount_lordo'
		},
		{
			title: 'Sconto Netto',
			key: 'total_amount'
		}
	],
	actions: [ 'restore', 'delete']
};

export default viewConfig;
