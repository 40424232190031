import {
	InputTypes,
	ValueTypes,
	IFieldDefinition,
	ListViewItemTypes,
	FieldViews
} from 'app/interfaces';
import { Validators } from '@angular/forms';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

/**
 * Fields Fattura INDIVIDUAL
 */

fieldsMap.set('person_type', {
	key: 'person_type',
	name: 'Person Type',
	inputType: InputTypes.HIDDEN,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required],
	defaultValue() {
		return 'INDIVIDUAL';
	}
});

fieldsMap.set('tc_name', {
	key: 'tc_name',
	name: 'Nome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_surname', {
	key: 'tc_surname',
	name: 'Cognome*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_tax_code', {
	key: 'tc_tax_code',
	name: 'Codice Fiscale*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_country', cloneFieldDefinition(nationCodeField, {
	key: 'tc_country',
	name: 'Nazione*',
	formValidators: [Validators.required]
}));

fieldsMap.set('tc_province', {
	key: 'tc_province',
	name: 'Provincia*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_city', {
	key: 'tc_city',
	name: 'Città*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_cap', {
	key: 'tc_cap',
	name: 'CAP*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_address', {
	key: 'tc_address',
	name: 'Indirizzo*',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [Validators.required]
});

fieldsMap.set('tc_recipient_code', {
	key: 'tc_recipient_code',
	name: 'Codice Destinatario',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

fieldsMap.set('tc_pec', {
	key: 'tc_pec',
	name: 'PEC',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: []
});

export { fieldsMap };
