import * as tslib_1 from "tslib";
import { ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { exhaustMap, takeUntil } from 'rxjs/operators';
import { AppService } from 'app/services/app.service';
import { BaseDatasetActionDialog } from './../../base-action-dialog.component';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { DatasetActionContainerService, getDatasetConfigAction } from 'app/main/components/dataset/services/dataset-action-container.service';
var DatasetDeleteDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetDeleteDialogComponent, _super);
    function DatasetDeleteDialogComponent(datasetACS, appService, datasetService, dialogRef, data, viewContainerRef) {
        var _this = _super.call(this, datasetACS, appService, datasetService, dialogRef, data) || this;
        _this.datasetACS = datasetACS;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.viewContainerRef = viewContainerRef;
        _this.actionCode = 'delete';
        _this.message = 'Sei sicuro di voler cancellare questo record?';
        if (data.message) {
            _this.message = data.message;
        }
        return _this;
    }
    DatasetDeleteDialogComponent.prototype.execDelete = function (data) {
        var _this = this;
        this.loading = true;
        return this.datasetService.delete(this.datasetACS.getDeleteRoute(this.data.record), data)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.loading = false;
                _this.appService.showSuccessMessage('Eliminato con successo');
                _this.dialogRef.close(true);
            },
            error: function (err) {
                _this.deleteError = err;
                _this.loading = false;
            }
        });
    };
    DatasetDeleteDialogComponent.prototype.onDelete = function () {
        var _this = this;
        this.loading = true;
        var actionConfig = getDatasetConfigAction(this.datasetACS.datasetCode, 'edit');
        if (actionConfig && typeof (actionConfig.beforeDelete) === 'function') {
            actionConfig.beforeDelete(this.datasetACS, this.data.record, this.viewContainerRef)
                .pipe(takeUntil(this._unsubscribeAll))
                .pipe(exhaustMap(function (data) {
                return _this.datasetService.delete(_this.datasetACS.getDeleteRoute(_this.data.record), data);
            }))
                .subscribe({
                next: function (response) {
                    _this.loading = false;
                    _this.appService.showSuccessMessage('Eliminato con successo');
                    _this.dialogRef.close(true);
                },
                error: function (err) {
                    _this.deleteError = err;
                    _this.loading = false;
                },
                complete: function () {
                    _this.loading = false;
                    _this.dialogRef.close(true);
                }
            });
        }
        else {
            this.datasetService.delete(this.datasetACS.getDeleteRoute(this.data.record))
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                next: function (response) {
                    _this.loading = false;
                    _this.appService.showSuccessMessage('Eliminato con successo');
                    _this.dialogRef.close(true);
                },
                error: function (err) {
                    _this.deleteError = err;
                    _this.loading = false;
                }
            });
        }
    };
    return DatasetDeleteDialogComponent;
}(BaseDatasetActionDialog));
export { DatasetDeleteDialogComponent };
