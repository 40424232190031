import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IActionDialogData, ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';
import { ValueTypes, InputTypes } from 'app/interfaces';

async function sendAddCommand(dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService, code: string): Promise<any>{
	const dialogRef = datasetNavigator.openDialog<IActionDialogData>(ActionDialogComponent, {
		title: 'Attendere',
		contentMessage: ''
	});
	console.log('sendAddCommand > dataContainerService Data: ', dataContainerService.getData(), dataContainerService.getValueFromKeyPath('trip_bookings.recordId'));
	const tripBookingId = dataContainerService.getValueFromKeyPath('trip_bookings.recordId');
	return new Promise<any>(function(resolve, reject): void{
		datasetNavigator.datasetService.post<any>('/dataset/trip_booking_discounts/command/add_coupon', {code, trip_booking_id: tripBookingId})
		.subscribe({
			next: response => {
				dialogRef.close();
				if(dataContainerService instanceof DatasetActionContainerService){
					dataContainerService.datasetEvent.next({ eventName: DatasetEvents.ACTION_REALOAD_LIST });
				}
				resolve();
			},
			error: response => {
				console.log('sendAddCommand response', response);
				const dialogInstance = dialogRef.componentInstance;
				if(dialogInstance instanceof ActionDialogComponent){
					dialogInstance.resultStatus = 'error';
					dialogInstance.loading = false;
					let message = 'Si è verificato un errore imprevisto.';
					if(response && response.error && response.error.message && typeof(response.error.success) === 'boolean'){
						message = response.error.message;
					}
					dialogInstance.setMessage(message);
				}
				reject(response);
			}
		});
	});
}

async function getCouponCode(dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): Promise<string>{
	const dialogRef = datasetNavigator.openDialog<IFormDialogData>(FormDialogComponent, {
		title: 'Inserisci il Coupon',
		formConfig: {
			fields: [
				{
					name: 'Codice',
					key: 'code',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.TEXT
					}
				}
			]
		},
		positiveText: 'Aggiungi',
		negativeText: 'Annulla'
	});

	return new Promise<string>(function(resolve, reject): void{
		dialogRef.afterClosed()
		.subscribe(value => {
			resolve(value && value.code);
		});
	});
}

export default async function(dataContainerService: IDataContainerService, datasetNavigator: DatasetNavigatorService): Promise<any>{
	const couponCode = await getCouponCode(dataContainerService, datasetNavigator);
	if(!couponCode) return null;
	return sendAddCommand(dataContainerService, datasetNavigator, couponCode);
}
