<div class='mt-35 trip-booking-discounts-item'>
	<div class="discount_name" fxLayout="row" fxLayoutAlign="space-between center">
		<span>{{ item.discount.name }}</span>

		<button class="delete-trip-btn" mat-icon-button *ngIf='datasetACS.canEdit(item)' (click)='trashTripBookingDiscount()'>
			<mat-icon color='warn'>delete</mat-icon>
		</button>
	</div>

	<mat-expansion-panel>

		<mat-expansion-panel-header>
			<mat-panel-title>{{ item.discount.description }}</mat-panel-title>
		</mat-expansion-panel-header>

		<dataset-list
			[autoFetchData]='true'
			[parentDatasetACS]='datasetACS'
			[showSearchBar]='false'
			[showTitle]='false'
			[elevate]='0'
			[enableCreate]='false'
			[enableEdit]='false'
			[viewConfig]='viewConfig'
			datasetCode='trip_booking_services'
			[filters]='{ 
				trip_booking_id: item.trip_booking_id,
				trip_booking_discount_id: item.id,
				with_trashed: 1,
				with_relations: ["tripBookingPackage.tripPackage", "tripBookingResource.tripResource", "discountedTripBookingService"] 
			}'
			sortBy='trip_booking_resource_id|created_at'
			tableStyle = 'style-1'
			(datasetEvent)='tripBookingDiscountServiceDatasetEvent($event)'
		></dataset-list>
		
	</mat-expansion-panel>
</div>