import { InputTypes, ValueTypes, IFormConfig } from 'app/interfaces';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import nationCodeField from 'app/configs/commons/shared-field-configs/nation-code.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { Validators } from '@angular/forms';


export default {
	fields: [
		{
			key: 'trip_packages_id',
			name: 'Pacchetti',
			inputType: InputTypes.DATASET_MULTISELECT,
			valueType: ValueTypes.STRING,
			formValidators: [ Validators.required ],
			datasetCode: 'trip_packages',
			remoteOptions: {
				getParams(dataContainerService: IDataContainerService, record: any): any{
					return {tour_operator: 'tangerine'};
				},
				getLabel(option): any{
					if(!option) return '';
					return option.description;
				},
				getValue(option): any{
					if(!option) return null;
					return option.id;
				},
				getDisplay(option): any{
					if(!option) return '';
					return option.description;
				}
			}
		},
		{
			key: 'domain_id',
			name: 'Dominio',
			inputType: InputTypes.DATASET_SELECT,
			valueType: ValueTypes.STRING,
			formValidators: [ Validators.required ],
			datasetCode: 'domains',
			remoteOptions: {
				getParams(dataContainerService: IDataContainerService, record: any): any{
					return {enabled: 1};
				},
				getLabel(option): any{
					if(!option) return '';
					return option.display_name;
				},
				getValue(option): any{
					if(!option) return null;
					return option.id;
				},
				getDisplay(option): any{
					if(!option) return '';
					return option.display_name;
				}
			},
			showIf(record: any, dataContainerService: IDataContainerService): boolean{
				return !dataContainerService.domainFilterService.filterDomainId.value;
			},
			defaultValue(dataContainerService: IDataContainerService): any{
				return dataContainerService.domainFilterService.filterDomainId.value;
			}
		},
	]
} as IFormConfig;
