import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import {DatasetRecordService} from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { ParticipantListEmailDialogComponent } from 'app/main/components/dataset/dialogs/participant-list-email-dialog/participant-list-email-dialog.component';
import { IDataActualTripParticipantList, IDataParticipantList } from 'app/interfaces/participant-list-email.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ActualTripConfig } from 'app/configs/datasets/actual_trips';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { IPaginationResponse, ISocketEvent } from 'app/interfaces';
import TripResourceStaffAssignmentsTableViewConfig from 'app/configs/datasets/trip_resource_staff_assignments/trip-resource-staff-assignments-table-view.viewConfig';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import { AuthService } from 'app/services/auth.service';
import { AppService } from 'app/services/app.service';
import { ActualTripParticipantsListComponent } from 'app/main/components/dataset/dialogs/actual-trip-participants-list/participant-list-dialog.component';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { WebSocketService } from 'app/services/websocket.service';
import { MatDialog } from '@angular/material';

@Component({
	selector   : 'actual-trip-detail',
	templateUrl: './detail-view.component.html',
	styleUrls  : ['./detail-view.component.scss'],
})
export class ActualTripDetailComponent extends BaseViewComponent implements OnInit{

	public editFormConfig = ActualTripConfig.formConfigsMap['editForm'];
	public emailFormConfig = ActualTripConfig.formConfigsMap['emailForm'];
	public package: any = null;
	public loadingResources = false;
	public tripResources: any[];
	public tripResourceWithStaff: any[];
	public tripResourceStaffAssignmentsTableView = TripResourceStaffAssignmentsTableViewConfig;
	public resellView = false;
	public showBooking = false;
	public contextualDomainId: any;
	public externalSyncedType = 0;

	debounceTimeReload: Subject<any>;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected router: Router,
		protected authService: AuthService,
		public datasetACS: DatasetActionContainerService,
		public datasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public appService: AppService,
		public domainFilterService: DomainFilterService, 
		public webSocketService: WebSocketService,
		protected dialog: MatDialog,
	){
		super(datasetACS, datasetRS, datasetNavigatorService);

		this.debounceTimeReload = new Subject();
	}

	ngOnInit(): void{
		super.ngOnInit();

		this.datasetRS
		.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(!record){
				this.package = null;
				this.externalSyncedType = 0;
			}else{
				this.package = record.package;
				this.externalSyncedType = record.external_synced_type;
			}

			this.resellView = !isRecordInFilteredDomain(record, record.is_owner);
			this.contextualDomainId = record.domain.id || null;
			if (this.resellView && this.domainFilterService.filterDomainId.value){
				this.contextualDomainId = this.domainFilterService.filterDomainId.value;
			}
		});

		this.getResources()
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(() => {});

		this.debounceTimeReload
		.pipe(takeUntil(this._unsubscribeAll), debounceTime(60*1000))
		.subscribe(() => {
			this.datasetRS.reload();
		});

		this.showBooking = this.authService.isActionPermitted('view', 'booking_group');
	}

	onParticipantsUpdated(event: ISocketEvent): void{
		if((event.data.datasetCode == 'actual_trip_participants') && (event.data.payloadData.actual_trip_id == this.datasetRS.recordId)){
			this.debounceTimeReload.next(true);
		}
	}

	getResources(): Observable<any>{
		const result = new Subject();
		this.loadingResources = true;
		this.datasetService.get<IPaginationResponse<any>>('/dataset/actual_trip_resources', {
			actual_trip_id: this.datasetRS.recordId,
			perPage: 50
		}).subscribe({
			next: response => {
				this.tripResources = response.items;
				
				if(this.tripResources){
					this.tripResourceWithStaff = this.tripResources.filter(el => {
						return el.staff_elements_count;
					});
				}else{
					this.tripResourceWithStaff = [];
				}
				result.next(response);
				this.loadingResources = false;
			},
			error: err => {
				this.loadingResources = false;
				result.error(err);
			}
		});
		return result;
	}

	trackById(index, item): any{
		return item.id;
	}

	viewEmailInput(): void {
		const data: IDataParticipantList = {
			postData: {actual_trip_id: this.datasetRS.recordId},
			DatACS: this.datasetACS,
			typeList: 'actual_trip',
		};

		this.datasetNavigatorService.openDialog(ParticipantListEmailDialogComponent, data);
	}

	openList(): void{
		this.datasetNavigatorService.openDialog<IDataActualTripParticipantList>(ActualTripParticipantsListComponent, {
			datasetACS: this.datasetACS,
			datasetRS: this.datasetRS
		}, {minWidth: 600});
	}
}
