import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TrenitaliaService, trainTypeImages } from '../trenitalia.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { wrapArray } from 'app/helpers';

@Component({
	selector: 'trenitalia-search-results',
	templateUrl: './search-results.component.html',
	styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, OnDestroy {
	public validSolution;
	public originalSolution;
	public priceTable = [];
	public selectedSolution;
	public checkoutData;
	private currentPrice;
	public loading;
	private departure;
	private arrival;
	public solutions;
	public selectedSol;
	public selectedLoad;
	private priceSelected;
	public details;
	public stationSearch;
	private total = {};
    private fee = 0;
	private selectOffer;
	public travellers: any;
    public selectCarnet = [];
    public selectedServiceCarnet: any;
	protected _unsubscribeAll: Subject<any>;
	public showMore = false;
	
	get trainTypeImages(){
		return trainTypeImages;
	}

	constructor(
		public trnService: TrenitaliaService,
		private _snackBar: MatSnackBar,
		public dialog: MatDialog
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit() {
		this.trnService.checkoutData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(data => {
			this.checkoutData = data;
            if(this.checkoutData.returnSearch) this.resetResults();
            if(this.checkoutData.rebuild){
                this.validSolution = undefined;
                this.checkoutData.seats=false;
                this.checkoutData.rebuild = false;
                this.selectedSol = -1;
                this.details = -1;
            }
			this.solutions = [];
			if (this.validSolution === undefined) {
				const servicesMap = this.trnService.servicesMap.value;
				if(_.has(this.checkoutData, 'searchSolutions.searchSimilarRecords')){
					this.solutions = this.checkoutData.searchSolutions.searchSimilarRecords.map(sol => {
						const view = sol.travelSolution;
						view.travellers = this.checkoutData.travellers;
						view.servicesMap = servicesMap;
						view.validatedPostSaleRecord = sol.validatedPostSaleRecord;
						view.reusedPaymentRecords = sol.reusedPaymentRecords;
						view.missingAmount = sol.missingAmount;
						return view;
					});
				}else if(_.has(this.checkoutData, 'searchSolutions.travelSolutions')){
					this.solutions = this.checkoutData.searchSolutions.travelSolutions.map(view => {
						view.travellers = this.checkoutData.searchSolutions.travellers;
						view.servicesMap = servicesMap;
						return view;
					})

				}else {


				}

				if (!this.checkoutData.dataAv) {

					this.solutions.forEach(s => {
						s.trains.forEach(t => {
							if (!_.has(s, 'priceGrid.'+t.id+'.best_price.row_key') || !s.bestPrice) {
								if(_.has(s, 'priceGrid.'+t.id)) s.priceGrid[t.id].priceSelected = {};
								// warn only if train not found not if the priceGrid is empty
								if(s.priceGrid) console.warn('not preselect price for train', t.id, s.bestPrice, _.get(s, 'priceGrid.'+t.id+'.best_price.row_key'));
								return;
							}
							s.priceGrid[t.id].priceSelected = {
								row: s.priceGrid[t.id].best_price.row_key,
								col: s.priceGrid[t.id].best_price.col_key
							};
						});
					});
				}
				this.validSolution = this.solutions;
				this.travellers = this.checkoutData.searchSolutions.travellers;
				this.showMore = this.checkoutData.mode != 'edit-date-time';
			}
            /*if(_.has(this.checkoutData,'selectedSolution'))
                this.selectedSol = this.validSolution.findIndex((sol: { xmlId: any; })=>sol.xmlId == this.checkoutData.selectedSolution.xmlId)*/

            this.stationSearch = {
                start: this.trnService.getStationDataFromId(_.get(this.checkoutData,'from')),
                end: this.trnService.getStationDataFromId(_.get(this.checkoutData,'to'))
            }
            if(this.checkoutData.ar){
                this.stationSearch.return = {
                    start: this.trnService.getStationDataFromId(_.get(this.checkoutData,'return.from')),
                    end: this.trnService.getStationDataFromId(_.get(this.checkoutData,'return.to'))
                }
            }
		});
		this.trnService.loading
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(load => {
			this.loading = load;
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	indexTrackBy(index, item): any{
		if(_.has(item, 'id')) return item.id;
		return index;
	}

	onServiceSelected(i) {
		if(i == this.selectedSol){
			// toggle?
			// return?
		}
		/*reset valori*/
        this.total = {
            value:0,
            valueNet:0,
            fee:0
        };
		this.selectedLoad = i;
		this.details = -1;
		this.selectedSol = i;
        this.checkoutData.seatsSel = 0;
		this.selectedSolution = this.validSolution[i].original;
		this.checkoutData.selectedSolution = this.validSolution[i];
		this.departure = this.validSolution[i].departure;
		this.arrival = this.validSolution[i].arrival;
		this.checkoutData.stations = {
			start: this.validSolution[i].start,
			end: this.validSolution[i].end
		};
        this.checkoutData.travellers = this.travellers = wrapArray(this.checkoutData.selectedSolution.travellers);

        if(this.checkoutData.mode=='carnet'){
            _.keys(this.checkoutData.selectedSolution.offeredServices).forEach(offer=>{
                const off = _.get(this.checkoutData.selectedSolution.offeredServices,offer);
                this.selectCarnet.push( {id:offer,value:_.get(this.trnService.servicesMap.value,'catalog_services.'+off.catalogServiceId+'.display_name')});
            })
            this.changeServiceCarnet({value:this.selectCarnet[0].id});
            if(this.selectedSolution.offeredServices && !_.isArray(this.selectedSolution.offeredServices)){
                this.selectedSolution.offeredServices = [this.selectedSolution.offeredServices];
            }
        }
        this.updateTotal();
		this.trnService.loading.next(false);
		this.selectedSol = i;
	}

	onPriceSelected(e, i) {
		if (
			_.has(e, ['sol', 'travelSolutions', 0, 'custom_off']) &&
			Object.keys(e.sol.travelSolutions[0].custom_off).length ===
				e.sol.travelSolutions[0].trains.length
		) {
			this.checkoutData.selectedSolution = e.sol.travelSolutions[0];
            if(!_.has(this.checkoutData.selectedSolution,'servicesMap')){
                this.checkoutData.selectedSolution.servicesMap = e.sol.servicesMap;
            }else{
                this.checkoutData.selectedSolution.servicesMap.catalog_services = _.merge(this.checkoutData.selectedSolution.servicesMap.custom_services,e.sol.travelSolutions[0].servicesMap.catalog_services);
                this.checkoutData.selectedSolution.servicesMap.offers = _.merge(this.checkoutData.selectedSolution.servicesMap.offers,e.sol.travelSolutions[0].servicesMap.offers);
            }
            this.selectedSolution = e.sol.travelSolutions[0].original;
            this.travellers = e.sol.travellers
		}else if(e.travelSolution){
			this.checkoutData.selectedSolution = e.travelSolution;
			this.selectedSolution = e.travelSolution.original;
		}
		this.updateTotal();
	}

	getDiffDays(date) {
		let startDay = this.checkoutData.direction==='RETURN'?moment(
            moment(this.checkoutData.return.start).format('YYYY-MM-DD')
        ):moment(
			moment(this.checkoutData.start).format('YYYY-MM-DD')
		);
		let endDay = moment(date);
		return Math.abs(endDay.diff(startDay, 'days'));
	}

	editSearch() {
		this.trnService.step.next(1);
        this.checkoutData.dataAv = null;
        this.checkoutData.selectedSolution = null;
        this.trnService.checkoutData.next(this.checkoutData);
	}

	selectDetails(i) {
		this.details = i;
		this.selectedSol = -1;
	}

	updateTotal() {
		this.selectOffer = [];
		this.total = {
            value:0,
            valueNet:0,
            fee:0
        };
        this.fee = 0;
		this.checkoutData.seats = false;
        this.checkoutData.seatsCount = 0;
		this.checkoutData.selectedSolution.trains.forEach(d => {
			if (!this.checkoutData.selectedSolution.custom_off && this.checkoutData.mode !=='carnet') {
                    let row = _.get(this.checkoutData, 'selectedSolution.priceGrid.'+d.id+'.priceSelected.row');
                    let col = _.get(this.checkoutData, 'selectedSolution.priceGrid.'+d.id+'.priceSelected.col');
                    if(!row || !col){
                        console.warn('price not selected for train', d, this.checkoutData.selectedSolution);
                        return;
                    }

                    this.total['value'] += this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].totalPrice;
                    this.total['valueNet'] += this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].totalPriceNet;
                    this.total['fee'] += this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].fee;

                    this.checkoutData.selectedSolution.priceGrid[d.id].matrix[row][col].offers.forEach(o => {
                        if (
                            this.checkoutData.selectedSolution.offeredServices[o]
                                .can_select_seatmap
                        ) {
                            this.checkoutData.seats = true;
                            this.checkoutData.seatsCount++;
                        }
                        this.selectOffer.push(this.checkoutData.selectedSolution.offeredServices[o]);
                    });
			} else if(this.checkoutData.mode=='carnet') {
                this.selectOffer.push({id:this.selectedServiceCarnet});
                this.checkoutData.seats = _.get(this.checkoutData.selectedSolution,'offeredServices.'+this.selectedServiceCarnet+'.can_select_seatmap');
                if(this.checkoutData.seats)this.checkoutData.seatsCount++;
            }else{
				this.travellers.forEach(tr => {
                    if(this.checkoutData.selectedSolution.custom_off[d.id][tr.xmlId]) {
                        let sel = this.checkoutData.selectedSolution.custom_off[d.id][tr.xmlId];
                        let offer:any = {id:sel.offer[0]};
                        if(sel.parameter)offer.parameters = sel.parameter
                        this.selectOffer.push(offer);
                        this.total['value'] += sel.price;
                        this.total['valueNet'] += sel.netPrice;
                        this.total['fee'] += sel.fee;
                        if (
                            _.get(this.checkoutData, ['selectedSolution', 'offeredServices', offer.id, 'can_select_seatmap'], false)
                        ) {
                            this.checkoutData.seats = true;
                            this.checkoutData.seatsCount++;
                        }
                    }
				});

				/*this.total = this.checkoutData.selectedSolution;*/
			}
		});

		this.checkoutData.dataAv = {
			travellers:this.travellers,
			selectedOffer: this.selectOffer,
			travelSolutions: this.selectedSolution
		};
		this.checkoutData.arrival = this.arrival;
		this.checkoutData.departure = this.departure;
        this.checkoutData.totalSelectedSolution= {value:this.total['value'],valueNet:this.total['valueNet'],fee:this.total['fee']};
		this.trnService.checkoutData.next(this.checkoutData);
        this.total['value']=this.checkoutData.direction!=='RETURN'?this.total['value']:this.checkoutData.forward.total['value']+this.total['value'];
        this.total['valueNet']=this.checkoutData.direction!=='RETURN'?this.total['valueNet']:this.checkoutData.forward.total['valueNet']+this.total['valueNet'];
        this.total['fee']=this.checkoutData.direction!=='RETURN'?this.total['fee']:this.checkoutData.forward.total['fee']+this.total['fee'];

        this.trnService.total.next(this.total);
	}

	moreRes() {
		this.trnService.loading.next(true);
		let s = this.validSolution[this.validSolution.length - 1];
		let date = s.departure;
		let r: any = {
			adult: this.checkoutData.adult || 0,
			children: this.checkoutData.children,
			from: this.checkoutData.from,
			to: this.checkoutData.to,
			direction: this.checkoutData.direction,
			start: date,
			/*mode: 'enhanced'*/
		};
		if(this.checkoutData.return) {
			r.from = this.checkoutData.return.from;
			r.to = this.checkoutData.return.to;
			r.start = _.get(this.validSolution,[this.validSolution.length - 1,'departure']);
			r.returnTripDate = r.start;
			r.roundTrip = true;
			r.ar = this.checkoutData.ar;
			r.travellers = this.checkoutData.travellers;
			r.forwardTripDate= _.get(this.checkoutData,'forward.selectedSolution.departure');
			r.forwardTrip= _.get(this.checkoutData,'forward.solution');
		}

		this.trnService.getSolutions(r)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: sol => {

				if(_.isEmpty(sol.travelSolutions)){
					this.showMore = false;
					return; // no other solution found
				}

				if(this.checkoutData.return){
					this.checkoutData.returnSearchSolutions = [...this.checkoutData.returnSearchSolutions,...sol['travelSolutions']];
					sol['travelSolutions'] = sol['travelSolutions'].map((s: { returnTravelSolution: any; })=>{return s.returnTravelSolution});
				}
				// check duplicate
				const lastSolutionIndex = sol.travelSolutions.findIndex(travelSolution => {
					return s && travelSolution.solutionId == s.solutionId;
				});
				if(lastSolutionIndex >= 0) sol.travelSolutions.splice(0, lastSolutionIndex + 1);
				this.checkoutData.searchSolutions.travelSolutions = [...this.checkoutData.searchSolutions.travelSolutions,...sol['travelSolutions']]
				// @ts-ignore
				this.trnService.updateServicesMap(sol['servicesMap'] || sol['serviceMap']);
				const servicesMap = this.trnService.servicesMap.value;
				this.checkoutData.searchSolutions.travelSolutions.forEach(s => {
					s.trains.forEach(t => {
						if (
							s.priceGrid[t.id] !== undefined &&
							s.priceGrid[t.id].best_price !== undefined &&
							s.bestPrice
						) {
							s.priceGrid[t.id].priceSelected = {
								row: s.priceGrid[t.id].best_price.row_key,
								col: s.priceGrid[t.id].best_price.col_key
							};
						}
					});
				});
				this.validSolution = [];
				let newViewItems = this.checkoutData.searchSolutions.travelSolutions.map(view => {
					view.travellers = sol['travellers'];
					view.servicesMap = servicesMap;
					return view;
				});
				// @ts-ignore
				this.validSolution = [...this.validSolution, ...newViewItems];

			},
			error: () => {
				this.trnService.loading.next(false);
			},
			complete: () => {
				this.trnService.loading.next(false);
			}
		});
	}

    changeServiceCarnet(event) {
        this.selectedServiceCarnet = event.value;
        this.checkoutData.carnetData.selectedService = this.selectedServiceCarnet;
        this.updateTotal()
    }

    resetResults() {
        this.solutions = [];
        this.validSolution = undefined;
        this.selectedSol = -1;
        this.details = -1;
        this.checkoutData = _.omit(this.checkoutData,['selectedSolution','selection','dataAv','seatMaps','seatsCount','seatsSel']);
        this.checkoutData.seats=false;
        this.checkoutData.returnSearch = false;
    }
}
