import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews, IDatasetCallDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/meeting_point_trip_packages/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	return params;
}

export const MeetingPointTripPackageConfig: IDatasetConfig = {
	name: 'meeting_point_trip_packages',
	title: 'Punti di ritiro',
	singleTitle: 'Punto di ritiro',
	ajaxDatasetCode: 'meeting_point_trip_packages',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm,
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description',
						fieldDefinition: fieldsMap.get('description')
					}
				],
				actions: ['create', 'detail', 'delete'],
			}
		}
	}
};
