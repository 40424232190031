import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'safe_content',
})
export class SafeContentPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(content) {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}
}
