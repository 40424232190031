import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector   : 'meeting_point_unified_list_items',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ 
		DatasetActionContainerService, 
		DatasetRecordService
	]
})
export class MeetingPointUnifiedListItemsComponent extends BaseStepViewComponent{

	@Input() parentDatasetRS: DatasetRecordService;
	@Input() parentDatasetACS: DatasetActionContainerService;

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService
	){
		super(datasetACS, datasetNavigatorService);
	}
}
