import { Component, OnInit, ViewChild } from '@angular/core';
import { IListViewConfig, IFormConfig, ValueTypes, InputTypes } from 'app/interfaces';
import { PublicDomainsConfig } from 'app/configs/datasets/public_domains/index';
import { TripPackageConfig } from 'app/configs/datasets/trip_packages/index';
import { PageClosureContainerService } from '../page-closure-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'app/services/app.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { IDomainChangeHandler } from 'app/interfaces/domain-change-handler.interface';

@Component({
	selector   : 'bokun-marketplace-page',
	templateUrl: './marketplace.component.html',
	styleUrls  : ['./marketplace.component.scss'],
	providers: [PageClosureContainerService]
})
export class BokunMarketplacePageComponent implements OnInit, IDomainChangeHandler{
	public loading = true;
	public domainHasBokunAccount = false;
	protected _unsubscribeAll: Subject<any>;

	constructor(
		protected appService: AppService,
		protected domainFilterService: DomainFilterService,
		protected datasetNavigatorService: DatasetNavigatorService,
		protected pageClosureContainerService: PageClosureContainerService,
		protected route: ActivatedRoute,
	) {
		this._unsubscribeAll = new Subject();
	}

	handleDomainChanges(): boolean {
		return true;
	}

	checkBokunAccount(): void{
		this.loading = true;
		this.datasetNavigatorService.datasetService
		.post<any>('/dataset/bokun_tours/command/check_account')
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(response => {
			this.domainHasBokunAccount = Boolean(response.valid);
			this.loading = false;
		});
	}

	ngOnInit(): void {
		if(!this.domainFilterService.filterDomainId.value){
			this.domainFilterService.setFilterDomain(this.route.snapshot.data.domainId);
		}

		this.domainFilterService.filterDomainId
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(domainId => {
			this.checkBokunAccount();
		});
	}
}
