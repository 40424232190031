export const locale = {
	lang: 'en',
	data: {
		'Carica file': 'Upload file',
		'Trascina il file qui per caricarlo': 'drop file here',
		'oppure clicca per cercare nel computer': 'or search file in pc',
		'Sono supportati i file: .jpeg, .png': 'supported formats: .jpeg, .png',
		'Formato': 'Format',
		'Annulla': 'Cancel',
		'Carica': 'Upload'
	}
};
