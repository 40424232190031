import * as tslib_1 from "tslib";
import { concat, defer, fromEvent, of, throwError } from 'rxjs';
import { filter, map, publish, switchMap, take, tap } from 'rxjs/operators';
export var ERR_SW_NOT_SUPPORTED = 'Service workers are disabled or not supported by this browser';
function errorObservable(message) {
    return defer(function () { return throwError(new Error(message)); });
}
var NgswCommChannel = /** @class */ (function () {
    function NgswCommChannel(serviceWorker) {
        this.serviceWorker = serviceWorker;
        if (!serviceWorker) {
            this.worker = this.events = this.registration = errorObservable(ERR_SW_NOT_SUPPORTED);
        }
        else {
            var controllerChangeEvents = fromEvent(serviceWorker, 'controllerchange');
            var controllerChanges = controllerChangeEvents.pipe(map(function () { return serviceWorker.controller; }));
            var currentController = defer(function () { return of(serviceWorker.controller); });
            var controllerWithChanges = concat(currentController, controllerChanges);
            this.worker = controllerWithChanges.pipe(filter(function (c) { return !!c; }));
            this.registration = (this.worker.pipe(switchMap(function () { return serviceWorker.getRegistration(); })));
            var rawEvents = fromEvent(serviceWorker, 'message');
            var rawEventPayload = rawEvents.pipe(map(function (event) { return event.data; }));
            var eventsUnconnected = rawEventPayload.pipe(filter(function (event) { return event && event.type; }));
            var events = eventsUnconnected.pipe(publish());
            events.connect();
            this.events = events;
        }
    }
    NgswCommChannel.prototype.postMessage = function (action, payload) {
        return this.worker
            .pipe(take(1), tap(function (sw) {
            sw.postMessage(tslib_1.__assign({ action: action }, payload));
        }))
            .toPromise()
            .then(function () { return undefined; });
    };
    NgswCommChannel.prototype.postMessageWithStatus = function (type, payload, nonce) {
        var waitForStatus = this.waitForStatus(nonce);
        var postMessage = this.postMessage(type, payload);
        return Promise.all([waitForStatus, postMessage]).then(function () { return undefined; });
    };
    NgswCommChannel.prototype.generateNonce = function () { return Math.round(Math.random() * 10000000); };
    NgswCommChannel.prototype.eventsOfType = function (type) {
        var filterFn = function (event) { return event.type === type; };
        return this.events.pipe(filter(filterFn));
    };
    NgswCommChannel.prototype.nextEventOfType = function (type) {
        return this.eventsOfType(type).pipe(take(1));
    };
    NgswCommChannel.prototype.waitForStatus = function (nonce) {
        return this.eventsOfType('STATUS')
            .pipe(filter(function (event) { return event.nonce === nonce; }), take(1), map(function (event) {
            if (event.status) {
                return undefined;
            }
            throw new Error(event.error);
        }))
            .toPromise();
    };
    Object.defineProperty(NgswCommChannel.prototype, "isEnabled", {
        get: function () { return !!this.serviceWorker; },
        enumerable: true,
        configurable: true
    });
    return NgswCommChannel;
}());
export { NgswCommChannel };
