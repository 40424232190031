import { IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import { fieldsMap } from './fields';
import { LIST_COLUMNS } from './list_columns';

export const PlafondLogConfig: IDatasetConfig = {
	name: 'plafond_logs',
	title: 'Plafond Logs',
	singleTitle: 'Plafond Log',
	ajaxDatasetCode: 'plafond_logs',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: false,
		list: true
	},
	fetchConfig: {
		// ignore
	},
	formConfigsMap: {
		// ignore
	},
	defaultActions: {
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: LIST_COLUMNS,
				actions: [],
			}
		}
	}
};
