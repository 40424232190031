import * as tslib_1 from "tslib";
import { FieldViews, InputTypes, ValueTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import tourOperatorIdField from 'app/configs/commons/shared-field-configs/tour-operator-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
var fieldsMap = new Map();
fieldsMap.set('master_image', {
    key: 'master_image',
    name: 'Immagine',
    valueType: ValueTypes.FILE,
    fieldView: FieldViews.IMAGE
});
fieldsMap.set('duration_standard_in_hours', {
    key: 'duration_standard_in_hours',
    name: 'Durata standard in ore',
    valueType: ValueTypes.NUMBER,
    inputConfig: {
        type: InputTypes.NUMBER,
    },
    defaultValue: function () {
        return null;
    }
});
fieldsMap.set('code', {
    key: 'code',
    name: 'Codice',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    inputHint: 'Opzionale, se non inserito verrà generato automaticamente.'
});
fieldsMap.set('description', {
    key: 'description',
    name: 'Descrizione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    formValidators: [Validators.required],
    inputHint: 'Nome del pacchetto'
});
fieldsMap.set('publish_on_web', {
    key: 'publish_on_web',
    name: 'Pubblica sul sito',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.NUMBER,
    inputHint: 'Se pubblico, il pacchetto sarà visibile anche sul sito',
    options: [
        {
            label: 'NON pubblico',
            value: 0
        },
        {
            label: 'Pubblico',
            value: 1
        }
    ]
});
fieldsMap.set('active_status', {
    key: 'active_status',
    name: 'Attivo',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.NUMBER,
    inputHint: 'Se disattivato, non sarà possibile prenotare partenze',
    options: [
        {
            label: 'Disattivo',
            value: 0
        },
        {
            label: 'Si',
            value: 1
        }
    ]
});
fieldsMap.set('active_status_filter', {
    key: 'active_status',
    name: 'Attivo',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return ''; },
    options: [
        {
            label: 'Tutti',
            value: ''
        },
        {
            label: 'Disattivo',
            value: 0
        },
        {
            label: 'Si',
            value: 1
        }
    ]
});
fieldsMap.set('publish_on_web_filter', {
    key: 'publish_on_web',
    name: 'Pubblico sul sito',
    inputType: InputTypes.SELECT,
    valueType: ValueTypes.STRING,
    defaultValue: function () { return ''; },
    options: [
        {
            label: 'Tutti',
            value: ''
        },
        {
            label: 'NON pubblico',
            value: 0
        },
        {
            label: 'Pubblico',
            value: 1
        }
    ]
});
fieldsMap.set('note_booking', {
    key: 'note_booking',
    name: 'Note',
    inputType: InputTypes.TEXTAREA,
    valueType: ValueTypes.PROPERTY,
    inputHint: 'Note',
    showIf: function (record, dataContainerService) {
        return true;
    }
});
fieldsMap.set('tour_operator_id', cloneFieldDefinition(tourOperatorIdField));
fieldsMap.set('supplier_id', cloneFieldDefinition(entitySupplierIdField, { key: 'supplier_id' }));
fieldsMap.set('active_language_codes', {
    key: 'active_language_codes',
    name: 'Traduzioni attive',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var html = '<div class="active_langs" style="display:flex;flex-direction:row;flex-wrap: wrap;align-items: center;">';
        if (record.active_language_codes && record.active_language_codes.length > 0) {
            var style = 'style="margin-right: 5px;"';
            try {
                for (var _b = tslib_1.__values(record.active_language_codes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var lang = _c.value;
                    html += '<img width="24" ' + style + ' class="flag-icon" src="assets/images/flags/4x3/' + lang + '.svg" alt="' + lang + '" title="' + lang + '" />';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        else {
            html += '<span>---</span>';
        }
        html += '</div>';
        return html;
    }
});
fieldsMap.set('priority_order', {
    key: 'priority_order',
    name: 'Priorità',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    defaultValue: function () { return 0; }
});
fieldsMap.set('active_commission_percent', {
    key: 'active_commission_percent',
    name: 'Commissioni %',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER
});
fieldsMap.set('taxation', {
    key: 'taxation',
    name: 'Fiscalità',
    valueType: ValueTypes.PROPERTY,
    inputConfig: {
        type: InputTypes.SELECT,
        required: true
    },
    formValidators: [Validators.required],
    disallowNull: true,
    showIf: function (formData, dataContainerService) {
        var record = dataContainerService.getValueFromKeyPath('trip_packages.record');
        return !record || !record.domain || isRecordInFilteredDomain(record, record.is_owner);
    }
});
fieldsMap.set('child_from', {
    key: 'child_from',
    name: 'Bambini da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '4',
    defaultValue: function () { return 4; }
});
fieldsMap.set('child_to', {
    key: 'child_to',
    name: 'Adulto da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '11',
    defaultValue: function () { return 11; }
});
fieldsMap.set('adult_to', {
    key: 'adult_to',
    name: 'Senior da',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.PROPERTY,
    placeholder: '64',
    defaultValue: function () { return 64; }
});
fieldsMap.set('commessa_id', {
    key: 'commessa_id',
    name: 'Commessa',
    valueType: ValueTypes.REFERENCE,
    datasetCode: 'commesse',
    inputConfig: {
        clearable: true,
        type: InputTypes.DATASET_AUTOCOMPLETE,
        hint: 'Scrivi per cercare una commessa'
    },
    remoteOptions: {
        limit: 100,
        getLabel: function (option) {
            if (!option)
                return '';
            return option.code;
        },
        getValue: function (option) {
            if (!option)
                return;
            return option.id;
        },
        supportCreate: true
    }
});
export { fieldsMap };
