<ng-container *ngIf='inputService.show' [formGroup]="inputService.formGroup">
	<div class='file-input-controller'>
		<label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</label>
		<div class='file-drop-zone' 
			[class.dragging]='dragging' 
			(dragleave)='onFileDragLeave($event)' 
			(dragstart)='onFileDragStart($event)' 
			(dragover)='onFileDragOver($event)' 
			(drop)='onFileDrop($event)' 
			*ngIf='!inputService.inputConfig.readOnly && !inputService.control.value'>
			<mat-icon>cloud_download</mat-icon>
			<span>{{'Carica uno o più file' | translate}}</span>
			<input #fileInput (change)="onFileChange($event)" type='file' multiple/>
		</div>
		<div class='file-loaded' *ngIf='inputService.control.value'>
			<mat-icon>insert_drive_file</mat-icon>
			<button *ngIf='!inputService.inputConfig.readOnly' mat-icon-button (click)='clearFile()'>
				<span class="material-icons">close</span>
			</button>
		</div>
	</div>
</ng-container>