import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { FormDialogComponent, IFormDialogData } from 'app/main/dialogs/form-dialog/dialog.component';
import { InputTypes, ValueTypes } from 'app/interfaces';
import { Validators } from '@angular/forms';
import { isArray } from 'util';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export default async function action(record: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService, data: any): Promise<any>{
	const formResult = await datasetNavigatorService.openDialog<IFormDialogData>(FormDialogComponent, {
		title: 'Credenziali B2B',
		formData: data || {
			username: null,
			password: null,
			password_confirm: null
		},
		formConfig: {
			fields: [
				{
					key: 'mode',
					name: 'Modalità di creazione',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.RADIO
					},
					defaultValue(): any{
						return 'manual';
					},
					options: [
						{
							label: 'Manuale',
							value: 'manual'
						},
						{
							label: 'Automatica',
							value: 'auto'
						}
					]
				},
				{
					key: 'username',
					name: 'Username',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.TEXT,
						required: true,
					},
					showIf(formValue: any, dataContainerService: IDataContainerService): boolean{
						return formValue.mode === 'manual';
					},
					formValidators: [Validators.required]
				},
				{
					key: 'password',
					name: 'Password',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.PASSWORD,
						required: true
					},
					showIf(formValue: any, dataContainerService: IDataContainerService): boolean{
						return formValue.mode === 'manual';
					},
					formValidators: [Validators.required]
				},
				{
					key: 'password_confirm',
					name: 'Conferma Password',
					valueType: ValueTypes.STRING,
					inputConfig: {
						type: InputTypes.PASSWORD,
						required: true
					},
					showIf(formValue: any, dataContainerService: IDataContainerService): boolean{
						return formValue.mode === 'manual';
					},
					formValidators: [Validators.required]
				}
			]
		},
		dataContainerService: datasetACS,
		positiveText: 'Crea',
		negativeText: 'Annulla'
	}).afterClosed().toPromise();
	if(!formResult) return; // operazione annullata
	if(formResult.mode !== 'auto'){
		if(!formResult.username || !formResult.password){
			if(datasetNavigatorService){
				datasetNavigatorService.appService.showErrorMessage('Username e password sono obbligatori');
			}
			return action(record, datasetACS, datasetNavigatorService, data);
		}
	}else{
		formResult.username = null;
		formResult.password = null;
	}
	
	if(!formResult) return;
	const loadingDialogRef2 = datasetNavigatorService.openDialog(LoadingDialogComponent, {
		title: 'Creazione in corso',
	});
	try{
		const response = await datasetNavigatorService.datasetService
		.post<any>('/dataset/entities/command/create_site_user', {
			entity_id: record.id,
			mode: formResult.mode,
			username: formResult.username,
			password: formResult.password,
			password_confirm: formResult.password_confirm
		}).toPromise();

		let responseMessage = '';
		if(response.message){
			responseMessage = response.message;
		}
		if(response.success){
			if(datasetNavigatorService){
				datasetNavigatorService.appService.showSuccessMessage(responseMessage || 'Creazione avventuta con successo');
			}
			
		}else{
			if(!responseMessage) responseMessage = 'Si è verificato un problema durante la creazione dell\'utente.';
			if(datasetNavigatorService){
				datasetNavigatorService.appService.showErrorMessage(responseMessage);
			}
		}
		loadingDialogRef2.close();
		return response.success;
	}catch(e){
		let errorMessage = 'Si è verificato un errore';
		if(e.error && e.error.errors){
			let keyIndex = 0;
			const keys = Object.keys(e.error.errors);
			let key = keys[keyIndex];
			while(keys.length > keyIndex && !Object.prototype.hasOwnProperty.call(e.error.errors, key)){
				keyIndex++;
				key = keys[keyIndex];
			}
			if(e.error.errors[key]){
				if(isArray(e.error.errors[key])) errorMessage = e.error.errors[key][0];
				else if(typeof(e.error.errors[key]) === 'string') errorMessage = e.error.errors[key];
			}
		}
		if(datasetNavigatorService){
			datasetNavigatorService.appService.showErrorMessage(errorMessage);
		}
		loadingDialogRef2.close();
		return action(record, datasetACS, datasetNavigatorService, {
			username: formResult.username
		});
	}
}
