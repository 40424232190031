import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { kiaraCloudFields } from '../../fields/accounting/kiara-cloud-server-rt.fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	kiaraCloudFields.get('enabled'),
	kiaraCloudFields.get('company_code'),
	kiaraCloudFields.get('endpoint'),
	kiaraCloudFields.get('username'),
	kiaraCloudFields.get('password'),
	kiaraCloudFields.get('till_id'),
	kiaraCloudFields.get('scenario')
];

const appForm: IFormConfig = { 
	editTitle: 'Modifica Configurazione Kiara Cloud',
	fields: formFields 
};

export default appForm;
