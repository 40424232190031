import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';

@Component({
    selector   : 'in-construction',
    templateUrl: './in-construction.component.html',
    styleUrls  : ['./in-construction.component.scss']
})
export class InConstructionComponent{
    constructor(private fuseTranslationLoader: FuseTranslationLoaderService){
        this.fuseTranslationLoader.loadTranslations(english, italiano);
    }
}
