<div class="style-1">
	<div mat-dialog-title>
		<h1 *ngIf="dataD" style="margin: 0;display:inline-block;">
			<span *ngIf="!dataDialog.data">Aggiungi soluzione Trenitalia</span>
			<span *ngIf="dataDialog.data && dataDialog.mode=='edit-travellers'">Modifica Dati Passeggeri</span>
			<span *ngIf="dataDialog.data && dataDialog.mode=='edit-date-time'">Modifica Data Ora soluzione</span>
            <span *ngIf="dataDialog.data && dataDialog.mode=='travel-change'">Modifica Biglietto</span>
		</h1>
		<span *ngIf="dataD" class="material-icons-outlined dialog-close-icon" style="float:right;" (click)='onCloseClick()'>close</span>
	</div>
    <mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate"
                      *ngIf='loading'></mat-progress-bar>

    <div [ngClass]="dataD?'dataDialog-width mat-dialog-content':''">
		<!--<div class="step-header-container" *ngIf="!['edit-travellers','edit-traveller-pre-sale'].includes(dataDialog.mode)">
			<mat-step-header
				[tabIndex]="0"
				[state]="step>1 ? 'done' : 'number'"
				[index]="0"
				label="Ricerca"
				[selected]="step == 1"
				[active]="step>=1"
				id="step-header-1"
			></mat-step-header>
			<hr *ngIf="showedStep.includes(1) && showedStep.length > 1">
			<mat-step-header
				[tabIndex]="1"
				[state]="step>2 ? 'done' : 'number'"
				[index]="1"
				label="Soluzioni"
				[selected]="step == 2"
				[active]="step>=2"
				id="step-header-2"
				*ngIf="showedStep.includes(2)"
			></mat-step-header>
			<hr *ngIf="step == 3 && showedStep.includes(2) && showedStep.includes(3)">
			<mat-step-header
				[tabIndex]="2"
				[state]="step>2 ? 'done' : 'number'"
				[index]="2"
				label="Posti"
				[selected]="step == 3"
				[active]="step>=3"
				id="step-header-3"
				*ngIf="step == 3 && showedStep.includes(3)"
			></mat-step-header>
			<hr *ngIf="showedStep.includes(4) && showedStep.length > 1">
			<mat-step-header
				[tabIndex]="3"
				[state]="'number'"
				[index]="step==3 && showedStep.includes(3) ? 3 : 2"
				label="Dati Passeggeri"
				[selected]="step == 4"
				[active]="step>=4"
				id="step-header-4"
				*ngIf="showedStep.includes(4)"
			></mat-step-header>
		</div>-->
		<mat-tab-group class='tab-hidden-header' [(selectedIndex)]="tab">
            <mat-tab disabled *ngIf="showedStep.includes(1)">
                <div *ngIf="step>=1">
                    <trenitalia-search-form [data]="dataDialog"></trenitalia-search-form>
                </div>
            </mat-tab>
            <mat-tab disabled *ngIf="showedStep.includes(2)">
                <div *ngIf="step>=2">
                    <trenitalia-search-form *ngIf="['travel','carnet','travel-change','edit-date-time','edit'].includes(checkoutData.mode)" [data]="dataDialog"></trenitalia-search-form>
                    <trenitalia-search-results></trenitalia-search-results>
                </div>
            </mat-tab>
            <mat-tab disabled *ngIf="step==3 && showedStep.includes(3)">
                <div *ngIf="step==3">
                    <trenitalia-seats *ngIf="step==3 && showedStep.includes(3)"></trenitalia-seats>
                </div>
            </mat-tab>
            <mat-tab disabled *ngIf="showedStep.includes(4)">
                <div *ngIf="step==4">
                    <trenitalia-search-travellers></trenitalia-search-travellers>
                </div>
            </mat-tab>
        </mat-tab-group>
		

		<!--<mat-horizontal-stepper [selectedIndex]='tab' #stepper (selectionChange)="stepperSelectionChange($event)">
			<mat-step [editable]="false" label="Ricerca" [completed]="step>1" *ngIf="showedStep.includes(1)">
				<trenitalia-search-form></trenitalia-search-form>
			</mat-step>
			<mat-step [editable]="false" label="Soluzioni" [completed]="step>2" *ngIf="showedStep.includes(2)">
				<trenitalia-search-results *ngIf="step >= 2"></trenitalia-search-results>
			</mat-step>
			<mat-step [editable]="false" label="Posti" [completed]="step>3" *ngIf="step==3 && showedStep.includes(3)">
				<trenitalia-seats *ngIf="step >= 3"></trenitalia-seats>
			</mat-step>
			<mat-step [editable]="false" label="Dati Passeggeri" *ngIf="showedStep.includes(4)">
				<trenitalia-search-travellers *ngIf="step >= 4"></trenitalia-search-travellers>
			</mat-step>
		</mat-horizontal-stepper>-->
    </div>
    <mat-dialog-actions align="end" *ngIf="step>=2 && dataD">
        <div *ngIf="step>=2" fxLayout="row" fxLayoutAlign="start center" style="gap:8px;width: 100%;">
            <!--<mat-slide-toggle *ngIf="step==2 && (!checkoutData.mode || ['travel','travel-change','carnet'].includes(checkoutData.mode))" [(ngModel)]="seatsSel" [disabled]="!seats || loading" (change)="checkSeats()">
				Scegli i posti
            </mat-slide-toggle>-->
			<div fxFlex></div>
            <span class="total-train" *ngIf="total>0"> Tot €{{total | number : '1.2-2'}} </span>
            <!--<button mat-raised-button color="accent" *ngIf="step >=2 && !['edit-travellers','edit-traveller-pre-sale','travel'].includes(checkoutData.mode)"
                    (click)="back()" [disabled]="loading">
                <mat-icon id="arr">keyboard_arrow_left</mat-icon>
                Indietro
            </button>-->
            <!--<button mat-raised-button color="accent"
                    *ngIf="step==2 && (!checkoutData.mode || ['travel','travel-change','carnet','edit'].includes(checkoutData.mode))"
                    [disabled]="!seatsEnabled || loading"
                    (click)="checkSeats()">
                Seleziona posti
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->
            <button mat-raised-button color="accent"
                    *ngIf="step==2 &&
                    !['edit-date-time','travel-change','carnet'].includes(checkoutData.mode)&&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading"
                    (click)="confirmSel(false)">
                Continua
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <!--caso A/R-->
            <!--<button mat-raised-button color="accent"
                    *ngIf="step==2 &&
                    !['carnet'].includes(checkoutData.mode) &&
                    checkoutData.ar &&
                    checkoutData.direction !== 'RETURN'"
                    [disabled]="!continue || loading"
                    (click)="confirmSel(false)">
                Ritorno
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
-->
            <button mat-raised-button color="accent"
                    *ngIf="step==3 &&
                    !['carnet'].includes(checkoutData.mode) &&
                    !nextTrainStep &&
                    checkoutData.ar &&
                    checkoutData.direction !== 'RETURN'"
                    [disabled]="!continue || loading" (click)="confirmSel(true)">
                Ritorno
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <!--Fine caso A/R-->
            <!--continue next train-->
            <button mat-raised-button color="accent"
                    *ngIf="step==3 && prevTrainStep"
                    (click)="changeStepTrain(-1)"
                    [disabled]="loading">
                <mat-icon>keyboard_arrow_left</mat-icon>
                Treno precedente
            </button>
            <button mat-raised-button color="accent"
                    *ngIf="step==3 && nextTrainStep"
                    [disabled]="loading"
                    (click)="changeStepTrain(1)">
                Prossimo treno
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button mat-raised-button color="accent"
                    *ngIf="step==3 &&
                    !['edit-date-time','travel-change','carnet'].includes(checkoutData.mode) &&
                    !nextTrainStep &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading" (click)="confirmSel(true)">
                Continua
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <button mat-flat-button color="primary"
                    *ngIf="(step==4 && !['edit-travellers','travel-change'].includes(checkoutData.mode))||
                    (step==2||step==3) && ['carnet'].includes(checkoutData.mode)"
                    style="margin-left: 8px"
                    [disabled]="!confirm || loading"
                    (click)="sendConfirm()">CONFERMA
            </button>
            <button mat-flat-button color="primary"
                    *ngIf="step==4 && this.dataDialog.mode == 'edit-travellers' && checkoutData.editPassengers.length>0"
                    [disabled]="!confirm || loading"
                    (click)="editTravellers()">AGGIORNA
            </button>
            <button mat-flat-button color="primary"
                    *ngIf="step==2 &&
                    checkoutData.mode == 'edit-date-time' &&
                    checkoutData.selectedSolution &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading"
                    (click)="editDateTime()">AGGIORNA
            </button>
            <button mat-flat-button color="primary"
                    *ngIf="(step ==3) &&
                    checkoutData.mode == 'travel-change' &&
                    checkoutData.selectedSolution &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading"
                    (click)="changeTravel()">AGGIORNA TRATTA
            </button>
        </div>

    </mat-dialog-actions>
    <div align="end" *ngIf=" !dataD">
        <div *ngIf="step>=2" fxLayout="row" fxLayoutAlign="start center" style="gap:8px;width: 100%;">
            <!--<mat-slide-toggle *ngIf="step==2 && (!checkoutData.mode || ['travel','travel-change','carnet'].includes(checkoutData.mode))" [(ngModel)]="seatsSel" [disabled]="!seats || loading" (change)="checkSeats()">
				Scegli i posti
            </mat-slide-toggle>-->
            <div fxFlex></div>
            <!--<span class="total-train" *ngIf="total>0"> Tot €{{total | number : '1.2-2'}} </span>-->
            <!--<button mat-raised-button color="accent" *ngIf="step >=2 && !['edit-travellers','edit-traveller-pre-sale'].includes(checkoutData.mode)"
                    (click)="back()" [disabled]="loading">
                <mat-icon id="arr">keyboard_arrow_left</mat-icon>
                Indietro
            </button>-->
            <!--<button mat-raised-button color="accent"
                    *ngIf="step==2 && (!checkoutData.mode || ['travel','travel-change','carnet','edit'].includes(checkoutData.mode))"
                    [disabled]="!seatsEnabled || loading"
                    (click)="checkSeats()">
                Seleziona posti
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->

            <!--caso A/R-->

            <!--<button mat-raised-button color="accent"
                    *ngIf="step==3 &&
                    !['carnet'].includes(checkoutData.mode) &&
                    !nextTrainStep &&
                    checkoutData.ar &&
                    checkoutData.direction !== 'RETURN'"
                    [disabled]="!continue || loading" (click)="confirmSel(true)">
                Ritorno
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->

            <!--Fine caso A/R-->
            <!--continue next train-->
            <!--<button mat-raised-button color="accent"
                    *ngIf="step==3 && prevTrainStep"
                    (click)="changeStepTrain(-1)"
                    [disabled]="loading">
                <mat-icon>keyboard_arrow_left</mat-icon>
                Treno precedente
            </button>
            <button mat-raised-button color="accent"
                    *ngIf="step==3 && nextTrainStep"
                    [disabled]="loading"
                    (click)="changeStepTrain(1)">
                Prossimo treno
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button mat-raised-button color="accent"
                    *ngIf="step==3 &&
                    !['edit-date-time','travel-change','carnet'].includes(checkoutData.mode) &&
                    !nextTrainStep &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading" (click)="confirmSel(true)">
                Continua
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->

            <button mat-flat-button
                    *ngIf="(step==4 && !['edit-travellers','travel-change'].includes(checkoutData.mode))||
                        (step==3) && ['carnet'].includes(checkoutData.mode)"
                    style="margin-left: 8px"
                    [ngClass]="{'confirm': !isConfirmed, 'buy': isConfirmed}"
                    [disabled]="!confirm || loading"
                    (click)="sendConfirm()">{{ isConfirmed?'ACQUISTA BIGLIETTO':'CONFERMA' }}
            </button>
            <button mat-flat-button color="primary"
                    *ngIf="step==4 && this.dataDialog.mode == 'edit-travellers' && checkoutData.editPassengers.length>0"
                    [disabled]="!confirm || loading"
                    (click)="editTravellers()">AGGIORNA
            </button>
            <button mat-flat-button color="primary"
                    *ngIf="step==2 &&
                    checkoutData.mode == 'edit-date-time' &&
                    checkoutData.selectedSolution &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading"
                    (click)="editDateTime()">AGGIORNA
            </button>
            <!--<button mat-flat-button color="primary"
                    *ngIf="(step ==3) &&
                    checkoutData.mode == 'travel-change' &&
                    checkoutData.selectedSolution &&
                    (!checkoutData.ar || checkoutData.direction =='RETURN')"
                    [disabled]="!continue || loading"
                    (click)="changeTravel()">AGGIORNA TRATTA
            </button>-->
        </div>

    </div>
</div>