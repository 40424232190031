/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i6 from "@angular/material/paginator";
import * as i7 from "./list.component";
import * as i8 from "../../../../../../services/app.service";
import * as i9 from "@angular/common/http";
import * as i10 from "../../../../../components/dataset/services/dataset.service";
import * as i11 from "../../logs.service";
var styles_LogsListComponent = [i0.styles];
var RenderType_LogsListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LogsListComponent, data: {} });
export { RenderType_LogsListComponent as RenderType_LogsListComponent };
function View_LogsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["...(", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.log_types.length; _ck(_v, 1, 0, currVal_0); }); }
function View_LogsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "log-list-item_types"]], [[2, "warn-fg", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogsListComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (_v.parent.context.$implicit.log_types.length > 10); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.log_types.indexOf("exception") >= 0); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.log_types.slice(0, 10).join(", "); _ck(_v, 1, 0, currVal_1); }); }
function View_LogsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "log-list-item"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logsService.selectedBulkId.next(_v.context.$implicit.bulk_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogsListComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "log-list-item_date secondary-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 2)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.log_types; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.logsService.selectedBulkId.value && (_co.logsService.selectedBulkId.value == _v.context.$implicit.bulk_id)); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.created_at, "HH:mm:ss d/M/yy")); _ck(_v, 4, 0, currVal_2); }); }
export function View_LogsListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i3.DefaultFlexDirective, [i1.ElementRef, i4.StyleUtils, i4.LAYOUT_CONFIG, i3.FlexStyleBuilder, i4.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogsListComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-paginator", [["class", "mat-paginator"], ["showFirstLastButtons", ""]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatPaginator_0, i5.RenderType_MatPaginator)), i1.ɵdid(8, 245760, null, 0, i6.MatPaginator, [i6.MatPaginatorIntl, i1.ChangeDetectorRef], { length: [0, "length"], pageSize: [1, "pageSize"], pageSizeOptions: [2, "pageSizeOptions"], showFirstLastButtons: [3, "showFirstLastButtons"] }, { page: "page" }), i1.ɵpad(9, 6)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.logsService.listItems; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.logsService.listPaginationResponse && _co.logsService.listPaginationResponse.meta.total); var currVal_4 = ((_co.logsService.listPaginationResponse && _co.logsService.listPaginationResponse.meta.perPage) || _co.logsService.perPage); var currVal_5 = _ck(_v, 9, 0, 5, 10, 20, 50, 100, 200); var currVal_6 = ""; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_LogsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "logs-list", [], null, null, null, View_LogsListComponent_0, RenderType_LogsListComponent)), i1.ɵdid(1, 114688, null, 0, i7.LogsListComponent, [i8.AppService, i9.HttpClient, i10.DatasetService, i11.LogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogsListComponentNgFactory = i1.ɵccf("logs-list", i7.LogsListComponent, View_LogsListComponent_Host_0, {}, {}, []);
export { LogsListComponentNgFactory as LogsListComponentNgFactory };
