import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { CommonBlockStepComponent } from './step.component';
import { takeUntil } from 'rxjs/operators';
var CommonSubDatasetBlocksStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonSubDatasetBlocksStepComponent, _super);
    function CommonSubDatasetBlocksStepComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CommonSubDatasetBlocksStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: this.parentDatasetACS.actionCode,
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: false
        });
        this.parentDatasetRS.record
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (record) {
            if (!record)
                return;
            _this.datasetRS.recordId = _this.stepConfig.options.getSubDatasetRecordId(record);
            _this.datasetRS.reload();
        });
        _super.prototype.ngOnInit.call(this);
    };
    return CommonSubDatasetBlocksStepComponent;
}(CommonBlockStepComponent));
export { CommonSubDatasetBlocksStepComponent };
