<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	
	<form autocomplete="off"
		name='form' 
		*ngIf='formViewerService.initialized && formViewerService.formGroup' 
		[formGroup]="formViewerService.formGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'>

		<div class="product-publish-on-web">
			<label class="input-label section-title"><b>Pubblico</b></label>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("public_product_on_web")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["public_product_on_web"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<div class="section product-reference">
			<label class="input-label section-title"><b>Codice prodotto</b></label>
			<p class="instruction">Per aiutarti a tenere traccia dei tuoi prodotti su Tangerine, aggiungi il tuo codice di riferimento o nome.</p>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("code")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["code"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<div class="section title">	
			<div class="instruction">
				<p class="instruction">
					Scrivi un breve titolo descrittivo per aiutare i clienti a comprendere il tuo prodotto. Può includere la posizione, il tipo di attività, la durata e i siti da visitare.
				</p>
				<p class="instruction">
					Usa questo formato: <em>Località: Tipo di attività/Info extra</em>
				</p>
			</div>
	
			<div class="examples">
				<h3 class="examples-title"><b>Esempio</b></h3>
	
				<div class="good-examples">
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Teatro: Biglietto per lo spettacolo sei personaggi in cerca d'autore</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Borsa: Borsa targata gucci</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Cena: Cena romantica per 2 a lume di candela</span>
					</div>
				</div>
	
				<div class="bad-examples">
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> LE MIGLIORI VISTE DI PARIGI CON UNA GUIDA TURISTICA INCREDIBILE</span>
					</div>
					
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Prenota un amico locale con Companyy.com</span>
					</div>
				</div>
			</div>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
			<div class="content-taxation">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("taxation")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["taxation"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
				<div class="base-textfield base-textfield-large" *ngIf="formViewerService.controlKeyMap['rt_transmission_mode']">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("rt_transmission_mode")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["rt_transmission_mode"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>

			<div class="content-mac" *ngIf="formViewerService.controlKeyMap['mac_category']">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("mac_causal")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["mac_causal"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("mac_category")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["mac_category"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
		</div>

		<div class="section product-allotemtn-type">
			<label class="input-label section-title"><b>Tipo prodotto</b></label>
	
			<div class="base-textfield base-textfield-large" *ngIf="!['travelmate', 'esim-manet'].includes(productType)">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("product_type_filter")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["product_type"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
			<div class="base-textfield base-textfield-large" *ngIf="'travelmate' == productType">
				<mat-form-field appearance="outline">
					<input 
						[readonly]='true'
						autocomplete="off" 
						type='text' 
						matInput value="Audioguida">
				</mat-form-field>
			</div>
			<div class="base-textfield base-textfield-large" *ngIf="'esim-manet' == productType">
				<mat-form-field appearance="outline">
					<input 
						[readonly]='true'
						autocomplete="off" 
						type='text' 
						matInput value="ESim">
				</mat-form-field>
			</div>
		</div>

		<!--<ng-container *ngIf="['travelmate', 'esim-manet'].includes(productType)">
			<div class="section product-external-id" >
				<label class="input-label section-title"><b>ID Esterno</b></label>
		
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("external_id")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["external_id"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
		</ng-container>
		-->

		<!--
		<div class="section product-allotemtn-type">
			<label class="input-label section-title"><b>Tipologia disponibilità prodotto</b></label>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("allotment_type")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["allotment_type"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>
		-->
	
		<div class="save-button-container">
			<button [disabled]='formViewerService.formGroup.invalid && !formErrors || datasetACS.loading.value' class="gyg-button gyg-button-medium gyg-button-cta-light" (click)='onSave()'>
				<span>Salva e continua</span>
			</button>
		</div>
	</form>

</div>