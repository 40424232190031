import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import { config as stepViewConfig } from './step-view-config';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/discounts/detail/:recordId',
};

export const DiscountConfig: IDatasetConfig = {
	name: 'discounts',
	title: 'Sconti',
	singleTitle: 'Sconto',
	ajaxDatasetCode: 'discounts',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'name',
				defaultSortDirection: 'desc',
				columns: [
					{
						title: 'Codice',
						key: 'code',
						routerLink(record): string{
							return datasetDetailPage('discounts', record.id);
						}
					},
					{
						title: 'Nome',
						key: 'name'
					},
					{
						title: 'Codice Coupon',
						key: 'coupon_code'
					}
				],
				actions: ['create', 'edit', 'delete'],
				itemActionsOrder: ['create', 'edit', 'custom_0', 'delete'],
				customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'file_copy',
						tooltip: 'Duplica',
						showIf(record: any): boolean{ return true; },
						onClick(event, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
								contentMessage: 'Vuoi duplicare questo coupon sconto?'
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									minWidth: 550,
									data: {
										title: 'Duplicazione in corso'
									}
								});
						
								datasetNavigatorService.datasetService.post<any>('/dataset/discounts/command/duplicate', {
									record_id: record.id
								})
								.subscribe({
									next: () => {
										dialogRef.close();
										if(dataContainerService instanceof DatasetActionContainerService){
											dataContainerService.datasetEvent.next({ 
												eventName: DatasetEvents.ACTION_REALOAD_LIST 
											});
										}
										datasetNavigatorService.appService.showSuccessMessage('Coupon duplicato con successo.');
									},
									error: () => {
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.setMessage('Si è verificato un errore');
									}
								});
							});
						}
					}
				]
			}
		}
	},
	stepViewConfig: stepViewConfig
};
