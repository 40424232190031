import { InputTypes, ValueTypes } from 'app/interfaces';

export default {
	key: 'trip_destination_id',
	name: 'Destinazione',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_destinations',
	inputHint: 'Scrivi per cercare una destinazione',
	remoteOptions: {
		limit: 100,
		icon: 'explore',
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.description;
		}
	}
};
