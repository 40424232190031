<ng-container *ngIf='!inputService.hidden && inputService.formInputDefinition && inputService.control' [formGroup]="inputService.formGroup">
	<mat-form-field [appearance]='inputService.inputConfig.appearence' [ngClass]='inputService?.formInputDefinition?.inputConfig?.customCssClass'>
		<mat-label>
			<mat-icon class='title-input-icon' *ngIf='inputService.formInputDefinition.titleIcon' [matTooltip]='inputService.formInputDefinition.titleTooltip'>{{inputService.formInputDefinition.titleIcon}}</mat-icon>
			<span>{{inputService.computedTitle | translate}}</span>
		</mat-label>
		<input *ngIf='inputService.inputConfig.readOnly' readonly matInput 
			[formControlName]='inputService.formControlKey'
			[matDatepickerFilter]="dateFilterBinded" 
			[matDatepicker]="dp3" >
		<input *ngIf='!inputService.inputConfig.readOnly && !manualInput'
			[required]='inputService.inputConfig.required' 
			matInput 
			[matDatepickerFilter]="dateFilterBinded" 
			[matDatepicker]="dp3" 
			[placeholder]="inputService.inputConfig.placeholder | translate" 
			[formControlName]='inputService.formControlKey' 
			(click)='dp3.open()' readonly>
        <input *ngIf='!inputService.inputConfig.readOnly && manualInput'
               [required]='inputService.inputConfig.required'
               matInput
               [matDatepickerFilter]="dateFilterBinded"
               [matDatepicker]="dp3"
               [placeholder]="inputService.inputConfig.placeholder | translate"
               [formControlName]='inputService.formControlKey'>
		<mat-datepicker-toggle *ngIf='!inputService.inputConfig.readOnly' matSuffix [for]="dp3">
			<mat-icon matDatepickerToggleIcon>event</mat-icon>
		</mat-datepicker-toggle>
		<mat-datepicker #dp3></mat-datepicker>
		<mat-icon matSuffix *ngIf='inputService.control.value && inputService.inputConfig.clearable' (click)='inputService.control.setValue(null)'>close</mat-icon>
		<mat-hint *ngIf='inputService.inputConfig.hint'>{{inputService.inputConfig.hint | translate}}</mat-hint>
		<mat-error *ngIf="!inputService.control.valid">{{inputService.errorMessage | translate}}</mat-error>
	</mat-form-field>
</ng-container>