import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { FormInputViewerService } from '../../form-input-viewer.service';
var CompareDateInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompareDateInputComponent, _super);
    function CompareDateInputComponent(inputService) {
        var _this = _super.call(this) || this;
        _this.inputService = inputService;
        _this.customFormGroup = new FormGroup({
            date: new FormControl(),
            compare: new FormControl('>=')
        });
        _this.dateFilterBinded = _this.dateFilter.bind(_this);
        return _this;
    }
    CompareDateInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputService.initialized
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (initialized) {
            if (!initialized)
                return;
            _this.refreshInputData();
            _this.customFormGroup.valueChanges
                .pipe(takeUntil(_this._unsubscribeAll))
                .subscribe(function () {
                var compare = _this.customFormGroup.controls.compare.value;
                var date = _this.customFormGroup.controls.date.value;
                var decodedValue = [compare, date];
                _this.inputService.control.setValue(JSON.stringify(decodedValue));
            });
        });
    };
    CompareDateInputComponent.prototype.refreshInputData = function () {
        var jsonValue = this.inputService.control.value;
        if (!jsonValue)
            return;
        var decodedValue = JSON.parse(jsonValue);
        if (decodedValue instanceof Array && decodedValue.length === 2) {
            this.customFormGroup.controls.compare.setValue(decodedValue[0]);
            this.customFormGroup.controls.date.setValue(decodedValue[1]);
        }
    };
    CompareDateInputComponent.prototype.dateFilter = function (d) {
        if (this.inputService.formInputDefinition.extra && this.inputService.formInputDefinition.extra.dateFilter) {
            return this.inputService.formInputDefinition.extra.dateFilter(this.inputService.formGroup.getRawValue(), d);
        }
        return true;
    };
    return CompareDateInputComponent;
}(DestroyableComponent));
export { CompareDateInputComponent };
