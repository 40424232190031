import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { FormViewerService } from 'app/services/form-viewer.service';
import FormFields from './form.fields';
import { supportedLanguages } from 'app/configs/datasets/actual_trips';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { TripOptionsConfig } from 'app/configs/datasets/trip_options';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
var OptionSetupStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OptionSetupStepComponent, _super);
    function OptionSetupStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, datasetMCS, formViewerService, stepService) {
        var _this = 
        /** init */
        _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.datasetMCS = datasetMCS;
        _this.formViewerService = formViewerService;
        _this.stepService = stepService;
        _this.selectedLanguages = [];
        _this.selectedAudioGuideLanguages = [];
        _this.fileterFields = FormFields;
        _this.isLastStep = false;
        _this.scenario = new BehaviorSubject('go-to-dataset');
        return _this;
    }
    OptionSetupStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.datasetACS.init({
            datasetCode: this.stepConfig.options.datasetCode,
            actionCode: 'list',
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: true
        }).subscribe(function () {
            _this.formViewerService.init({
                errors: _this.formErrors,
                dataContainerService: _this.datasetACS,
                fields: Array.from(_this.fileterFields.values())
            });
        });
        this.setLanguages('languages');
        this.setLanguages('audio_guide_languages');
        this.tripOptionsViewConfig = TripOptionsConfig.defaultActions.list.viewConfig;
        this.scenario.subscribe(function (res) {
            if (res === 'go-to-dataset')
                _this.scrollToTop();
        });
    };
    OptionSetupStepComponent.prototype.resetForm = function (action) {
        this.scenario.next(action);
        this.datasetRS.record.next(null);
        this.datasetRS.recordId = null;
        this.formViewerService.clearForm();
        this.selectedLanguages = [];
        this.selectedAudioGuideLanguages = [];
    };
    OptionSetupStepComponent.prototype.onSelected = function (event) {
        var _this = this;
        this.datasetRS.recordId = event.id;
        //this.stepService.selectedOption.next(event);
        this.scenario.next('edit');
        this.datasetACS.loading.next(true);
        this.datasetRS.reload()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (record) {
                _this.formViewerService.setRecord(record);
                _this.formViewerService.formGroup.get('languages').setValue(null);
                _this.formViewerService.formGroup.get('audio_guide_languages').setValue(null);
                _this.selectedLanguages = record.languages;
                _this.selectedAudioGuideLanguages = record.audio_guide_languages;
                _this.datasetACS.loading.next(false);
            },
            error: function (response) {
                console.error(response);
                _this.datasetACS.loading.next(false);
            }
        });
    };
    OptionSetupStepComponent.prototype.setLanguages = function (input) {
        var _this = this;
        this.formViewerService.formBuilded.subscribe(function (formGroup) {
            formGroup.get(input).valueChanges.subscribe(function (newVal) {
                if (newVal !== null) {
                    var obj = supportedLanguages.find(function (x) { return x.value === newVal; });
                    if (input === 'languages') {
                        if (!_this.selectedLanguages.includes(obj))
                            _this.selectedLanguages.push(obj);
                    }
                    else {
                        if (!_this.selectedAudioGuideLanguages.includes(obj))
                            _this.selectedAudioGuideLanguages.push(obj);
                    }
                }
            });
        });
    };
    OptionSetupStepComponent.prototype.removeLang = function (language, type) {
        if (!type) {
            this.selectedLanguages = this.selectedLanguages.filter(function (x) { return x.value !== language.value; });
        }
        else if (type === 'audio_guide') {
            this.selectedAudioGuideLanguages = this.selectedAudioGuideLanguages.filter(function (x) { return x.value !== language.value; });
        }
    };
    OptionSetupStepComponent.prototype.beforeSave = function (formData) {
        formData['languages'] = this.selectedLanguages;
        formData['audio_guide_languages'] = this.selectedAudioGuideLanguages;
        return formData;
    };
    OptionSetupStepComponent.prototype.delete = function () {
        var _this = this;
        this.scrollToTop();
        this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.datasetRS.record.value);
        this.datasetACS.datasetEvent.subscribe(function (event) {
            if (event.eventName !== DatasetEvents.DELETED)
                return;
            _this.resetForm('go-to-dataset');
            if (_this.tripOtions) {
                _this.tripOtions.reload();
            }
            _this.appService.appEvent.subscribe(function (appEvent) {
                if (appEvent.name !== 'total_dataset_items')
                    return;
                if (appEvent.extra.datasetCode !== _this.datasetACS.datasetCode)
                    return;
                if (appEvent.extra.paginationResponse.meta.total)
                    return;
                _this.stepService.setStepState({
                    dataset_code: 'trip_packages',
                    form_code: 'trip_options_form',
                    record_id: _this.parentDatasetRS.recordId,
                    status: 0
                });
            });
        });
    };
    OptionSetupStepComponent.prototype.onSave = function () {
        var _this = this;
        this.datasetACS.loading.next(true);
        this.formViewerService.formGroup.disable();
        var formData = this.formViewerService.getFormData();
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.record.value) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        else
            formData['trip_package_id'] = this.datasetACS.getValueFromKeyPath('trip_packages.recordId');
        formData = this.beforeSave(formData);
        this.datasetService.post(route, formData)
            .subscribe({
            next: function (record) {
                if (_this.formViewerService) {
                    _this.formViewerService.formGroup.enable();
                }
                if (record) {
                    _this.scenario.next('go-to-dataset');
                    //this.stepService.selectedOption.next(record);
                    _this.datasetACS.loading.next(false);
                }
                _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                setTimeout(function () {
                    _this.stepService.setStepState({
                        dataset_code: 'trip_packages',
                        form_code: 'trip_options_form',
                        record_id: _this.parentDatasetRS.recordId,
                        status: 1
                    });
                    _this.goNextStep();
                }, 200);
            },
            error: function (response) {
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formViewerService && _this.formViewerService.formGroup) {
                    _this.formViewerService.formGroup.enable();
                    _this.formViewerService.setErrors(_this.formErrors);
                }
                _this.datasetACS.loading.next(false);
            }
        });
    };
    return OptionSetupStepComponent;
}(BaseStepViewComponent));
export { OptionSetupStepComponent };
