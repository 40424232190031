<span *ngIf='logsService.detailItems && logsService.detailItems.length' class='h3'>Eventi</span>
<mat-paginator
	[pageSizeOptions]="[5, 10, 20, 50, 100, 200]" 
	showFirstLastButtons
	[length]="logsService.detailPaginationResponse && logsService.detailPaginationResponse.meta.total"
	[pageSize]="(logsService.detailPaginationResponse && logsService.detailPaginationResponse.meta.perPage) || logsService.detailPerPage"
	(page)="onPageChanged($event)">
</mat-paginator>
<mat-card class='mb-8' *ngFor='let log of logsService.detailItems'>
	<mat-card-content>
		<p>Date: {{log.created_at | date:'yyyy-MM-dd HH:mm:ss'}}</p>
		<p>Type: {{log.log_type}}</p>
		<p *ngIf='log.user'>
			Utente: {{log.user.name}} 
			<span class='ml-4 secondary-text' *ngIf='log.user.role'>
				({{log.user.role.name}})
			</span>
		</p>
		<p>Message: {{log.message}}</p>
		<mat-accordion *ngIf='log.extra'>
			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Extra
					</mat-panel-title>
				</mat-expansion-panel-header>
				<pre *ngIf='!log.extra_json && log.extra'>{{ log.extra | json}}</pre>
				<pre *ngIf='log.extra_json'>{{ log.extra_json | json}}</pre>
			</mat-expansion-panel>
			<mat-expansion-panel *ngIf='log.extra_json && log.extra_json.request'>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Richiesta' | translate}}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p *ngIf='log.extra_json.request.url'>
					[{{log.extra_json.request.method || '-'}}]: {{log.extra_json.request.url}}
				</p>
				<p *ngIf='log.extra_json.request.ip'>IP: {{log.extra_json.request.ip}}</p>
				<pre *ngIf='log.extra_json.request.inputs'>{{ log.extra_json.request.inputs | json }}</pre>
			</mat-expansion-panel>
			<mat-expansion-panel *ngIf='log.extra_json && (log.extra_json.trace || log.extra_json.exception)'>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Stack Trace
					</mat-panel-title>
				</mat-expansion-panel-header>
				<ng-container *ngIf='log.extra_json.exception'>
					<p>Exception: {{log.extra_json.exception.trace | json}}</p>
					<p *ngIf='log.extra_json.exception.file'>
						File: {{log.extra_json.exception.file}} <span *ngIf='log.extra_json.exception.line'>({{log.extra_json.exception.line}})</span>
					</p>
				</ng-container>
				<p *ngIf='log.extra_json.file'>
					File: {{log.extra_json.file}} <span *ngIf='log.extra_json.line'>({{log.extra_json.line}})</span>
				</p>
				<pre *ngIf='log.extra_json.trace'>{{ log.extra_json.trace }}</pre>
			</mat-expansion-panel>
			<mat-expansion-panel *ngIf='log.target_table'>
				<mat-expansion-panel-header>
					<mat-panel-title>
						Target Record
					</mat-panel-title>
				</mat-expansion-panel-header>
				<p>Table: {{log.target_table}}</p>
				<p>RecordId: {{log.target_id}}</p>
				<a [routerLink]='"/dataset/detail/" + log.target_table + "/" + log.target_id' >GO</a>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-card-content>
</mat-card>