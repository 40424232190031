<ng-container *ngIf='backRouteLink'>
	<div class='mb-4'>
		<a mat-button color='accent' [routerLink]='backRouteLink' [queryParams]='backQueryParams'>
			<mat-icon>keyboard_arrow_left</mat-icon>
			<span>{{backTitle}}</span>
		</a>
	</div>
</ng-container>
<ng-container *ngIf='datasetRS.record.value'>
	<ng-container *ngIf='datasetACS.datasetConfig.stepViewConfig'>
		<ng-container [ngSwitch]='datasetACS.datasetCode'>
			<ng-container *ngSwitchCase='"trip_packages"'>
				<ng-container [ngSwitch]='datasetACS.getValueFromKeyPath("domainContext.domainTpl")'>
					<ng-container *ngSwitchCase='1'>
						<simp-temp-view-container
							[stepViewConfig]='stepViewConfig'
						></simp-temp-view-container>
					</ng-container>
					<ng-container *ngSwitchDefault>
						<common-step-view-container
							[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
						></common-step-view-container>
					</ng-container>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase='"products"'>
				<simp-temp-view-container
					[stepViewConfig]='productStepViewConfig'
				></simp-temp-view-container>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<common-step-view-container
					[stepViewConfig]='datasetACS.datasetConfig.stepViewConfig'
				></common-step-view-container>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf='!datasetACS.datasetConfig.stepViewConfig'>
		<ng-container [ngSwitch]='datasetACS.datasetConfig.name'>
			<ng-container *ngSwitchCase="'actual_trips'">
				<actual-trip-detail></actual-trip-detail>
			</ng-container>
			<ng-container *ngSwitchCase="'discount_rule_lists'">
				<discount-rule-list-detail></discount-rule-list-detail>
			</ng-container>
			<ng-container *ngSwitchCase="'domains'">
				<domain-user></domain-user>
			</ng-container>
			<ng-container *ngSwitchCase="'domain_packages'">
				<domain-packages></domain-packages>
			</ng-container>
			<ng-container *ngSwitchCase="'plafond'">
				<plafond></plafond>
			</ng-container>
			<ng-container *ngSwitchCase="'domain_user_roles'">
				<domain-user-role-permission-step></domain-user-role-permission-step>
			</ng-container>
			<ng-container *ngSwitchCase="'sale_operations'">
				<pratica-detail></pratica-detail>
			</ng-container>
			<ng-container *ngSwitchCase="'transmission_operations'">
				<transmission-data></transmission-data>
			</ng-container>

			<ng-container *ngSwitchDefault>
				<h2 *ngIf='showTitle'>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>
				<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start center">
					<ng-container *ngIf='datasetACS.actionConfig && datasetACS.actionConfig.viewConfig.formConfig'>
						<div class="card" card-data-viewer
							[showActions]='showActions'
							[record]='datasetRS.record.value'
							[datasetACS]='datasetACS'
							[viewFormConfig]='datasetACS.actionConfig.viewConfig.formConfig'
							[editFormConfig]='datasetACS.datasetConfig.defaultActions?.edit?.viewConfig?.formConfig'
						></div>
					</ng-container>
				</div>
				<ng-container *ngIf='!datasetACS.actionConfig || !datasetACS.actionConfig.viewConfig.formConfig'>
					<p>TODO</p>
					<pre>{{inputRecord | json}}</pre>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>