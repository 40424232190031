<ng-container *ngIf='!datasetACS.loading.value'>
	<div class="resource-row" [ngClass]="is_pickup && activated ? 'pickup' : ''" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
		<div (click)='changeStatus()' [matTooltip]='not_activable_reason' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
			<button class="checkbox" [disabled]='not_activable_reason' mat-icon-button *ngIf='!activated' [matTooltip]='"Risorsa opzionale NON attivata" | translate'>
				<mat-icon>check_box_outline_blank</mat-icon>
			</button>
			<button class="checkbox" [disabled]='not_activable_reason' mat-icon-button *ngIf='activated' [matTooltip]='"Risorsa opzionale attivata" | translate'>
				<mat-icon>check_box</mat-icon>
			</button>
			<div fxLayout="row" fxLayoutAlign="space-between center" style="cursor: pointer;">
				<span class='ml-4 info_resource' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
					<span class="resource_description">{{trip_resource.description}}</span>
					<span class="text-color-black font-size-14">{{datasetRS.record.value.total_amount | currency:'EUR' }}</span>
					<ng-container *ngIf="is_pickup">
						<span class="pickup">PICK-UP</span>
					</ng-container>
					<span class='secondary-text mat-caption ml-4'>({{ 'Opzionale' | translate}})</span>
				</span>
			</div>
		</div>
		<ng-container *ngIf='is_pickup && activated'>
			<div class='resource_meeting_point' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<mat-icon>place</mat-icon>
				<span class='ml-4 mr-4'>{{'Punto di ritrovo' | translate}}: <span class='meeting_point_description' [matTooltip]='tripBookingResourceService.meetingPointPickupResource.description' *ngIf='tripBookingResourceService.meetingPointPickupResource'>{{tripBookingResourceService.meetingPointPickupResource.description}}</span></span>
				<span><mat-spinner *ngIf='tripBookingResourceService.loadPickupMeetingPoint' diameter='20'></mat-spinner></span>
				<button *ngIf='!tripBookingResourceService.loadPickupMeetingPoint' mat-icon-button color='accent' [matTooltip]='"Modifica punto di ritrovo" | translate' (click)='tripBookingResourceService.editMeetingPoint(datasetRS.record.value)'>
					<mat-icon>edit</mat-icon>
				</button>
			</div>
		</ng-container>
	</div>
</ng-container>