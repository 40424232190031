<div class='full-form'>
	<language-select 
		[selected]='appService.language.value'
		(changed)='appService.language.next($event)'
		[languageCodes]='appService.supportedLanguageCodes' 
		[activeLanguageCodes]='datasetMCS.activeLanguageCodes'></language-select>

	<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>

	<div class='mt-24' *ngIf='datasetACS.ready.value'>

		<ng-container *ngIf='!datasetRS.record.value && !datasetACS.loading.value'>
			<p>{{'Questa traduzione non è presente!' | translate}}</p>
		</ng-container>

		<data-container-viewer
			*ngIf='datasetRS.record.value'
			[dataContainerService]='datasetACS'
			[fields]='stepConfig.options.fields' 
			[record]='datasetRS.record.value'
		></data-container-viewer>

	</div>
</div>