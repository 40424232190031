import * as tslib_1 from "tslib";
import { PipeTransform, Injector } from '@angular/core';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { FieldHelper } from 'app/helpers';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
var GetDisplayFieldValuePipe = /** @class */ (function () {
    function GetDisplayFieldValuePipe(injector, datasetService, datasetACS) {
        this.injector = injector;
        this.datasetService = datasetService;
        this.datasetACS = datasetACS;
    }
    GetDisplayFieldValuePipe.prototype.transform = function (record, fieldDefinition, defaultValue) {
        var e_1, _a;
        if (defaultValue === void 0) { defaultValue = ''; }
        if (!fieldDefinition) {
            console.log('GetDisplayFieldValuePipe > fieldDefinition invalid');
            return defaultValue;
        }
        if (!record) {
            console.log('GetDisplayFieldValuePipe > record invalid');
            return defaultValue;
        }
        var value = FieldHelper.getDisplayFieldValue(fieldDefinition, record, defaultValue, this.datasetACS);
        if (!fieldDefinition.transforms)
            return value;
        try {
            for (var _b = tslib_1.__values(fieldDefinition.transforms), _c = _b.next(); !_c.done; _c = _b.next()) {
                var transformConfig = _c.value;
                var args = transformConfig.args || [];
                if (transformConfig.pipeClass) {
                    var pipe = this.injector.get(transformConfig.pipeClass);
                    value = pipe.transform.apply(pipe, tslib_1.__spread([value], args));
                }
                else if (transformConfig.transform) {
                    value = transformConfig.transform.apply(transformConfig, tslib_1.__spread([value], args));
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return value;
    };
    return GetDisplayFieldValuePipe;
}());
export { GetDisplayFieldValuePipe };
