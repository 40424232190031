import { Component, Inject, Input, OnInit, Self, SkipSelf, ViewChild } from '@angular/core';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { FormViewerComponent } from 'app/main/components/form-viewer/form-viewer.component';
import { DatasetMultilanguageContainerService } from 'app/main/components/dataset/services/dataset-multilanguage-container.service';
import { IFormConfig, IStepConfig } from 'app/interfaces';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { CommonI18nFormStepComponent } from 'app/main/components/dataset/components/common-step/common-i18n-form-step/step.component';

@Component({
	selector   : 'trip-package-reselled-step-2',
	templateUrl: './step.component.html',
	// styleUrls  : ['./step.component.scss']
	providers  : [ 
		DatasetActionContainerService, 
		DatasetRecordService,
		{ 
			provide: 'originalDatasetRS', 
			useClass: DatasetRecordService
		},
		{ 
			provide: 'reselledMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		},
		{ 
			provide: 'originalMultilanguageService', 
			useClass: DatasetMultilanguageContainerService
		} 
	]
})
export class TripPackageReselledStep2Component extends CommonI18nFormStepComponent implements OnInit{
	
	@Input() isLastStep = true;
	@Input() stepConfig: IStepConfig;
	@Input() formConfig: IFormConfig;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;
	formErrors: any;

	constructor(
		public appService: AppService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@Self()
		public reselledDatasetRS: DatasetRecordService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Inject('originalDatasetRS')
		@Self()
		public originalDatasetRS: DatasetRecordService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		@Inject('reselledMultilanguageService') 
		@Self()
		public reselledDatasetMCS: DatasetMultilanguageContainerService,
		@Inject('originalMultilanguageService') 
		@Self()
		public originalDatasetMCS: DatasetMultilanguageContainerService
	){
		super(appService, reselledDatasetRS, datasetACS, datasetNavigatorService, datasetService, reselledDatasetMCS);
	}

	ngOnInit(): void{
		this.datasetACS.init({
			datasetCode: this.stepConfig.options.datasetCode,
			actionCode: 'edit',
			parentDatasetACS: this.parentDatasetACS,
			parentDatasetRS: this.parentDatasetRS,
			datasetRS: this.reselledDatasetRS,
			fetchData: true
		});

		this.datasetACS.ready
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(ready => {
			if(!ready) return;
			this.datasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.reselledDatasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentReselledValueKey
			});
			this.datasetMCS.fetchRecord();
			this.originalDatasetMCS.init({
				datasetACS: this.datasetACS,
				datasetRS: this.originalDatasetRS,
				parentForeignKey: this.stepConfig.options.parentForeignKey,
				parentValueKey: this.stepConfig.options.parentValueKey
			});
			this.originalDatasetMCS.fetchRecord();
		});

		this.parentDatasetRS.record
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(record => {
			if(record) this.datasetMCS.activeLanguageCodes = record.active_language_codes;
		});
	}
}
