import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { IFormConfig, IDatasetConfig } from 'app/interfaces';
import { FormBuilder } from '@angular/forms';
import FilterFields from './form.fields';
import { TripDestinationConfig } from 'app/configs/datasets/trip_destinations';
var TripResourceTripDestinationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TripResourceTripDestinationsComponent, _super);
    function TripResourceTripDestinationsComponent(datasetRS, datasetACS, datasetNavigatorService, formBuilder) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.formBuilder = formBuilder;
        _this.dynamCols = [
            {
                name: 'code',
                title: 'Codice',
                primaryKey: true,
                show: true,
            },
            {
                name: 'description',
                title: 'Descrizione',
                primaryKey: false,
                show: true,
            }
        ];
        _this.displayedColumns = ['code', 'description', 'action'];
        _this.field = FilterFields.get('trip_destination_id');
        _this.filterFormGroup = _this.formBuilder.group({
            trip_destination_id: null,
        });
        return _this;
    }
    TripResourceTripDestinationsComponent.prototype.ngOnInit = function () {
        this.formConfig = TripDestinationConfig.formConfigsMap['generalForm'];
    };
    return TripResourceTripDestinationsComponent;
}(BaseStepViewComponent));
export { TripResourceTripDestinationsComponent };
