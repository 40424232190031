import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, throwError, forkJoin, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DomainInstanceService } from 'app/services/domain-instance.service';
import { AbstractService } from 'app/services/abstract.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { MatDialog } from '@angular/material/dialog';
import { action_join_domain } from 'app/main/actions/join-domain.action';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth.service';
var DatasetService = /** @class */ (function (_super) {
    tslib_1.__extends(DatasetService, _super);
    function DatasetService(http, appService, authService, domainInstanceService, domainFilterService, matDialog) {
        var _this = _super.call(this, http, appService) || this;
        _this.http = http;
        _this.appService = appService;
        _this.authService = authService;
        _this.domainInstanceService = domainInstanceService;
        _this.domainFilterService = domainFilterService;
        _this.matDialog = matDialog;
        return _this;
    }
    DatasetService.prototype.appendDomainIdHeaders = function (value, headers) {
        if (headers)
            return headers.append('X-Domain-Id', value);
        return new HttpHeaders({
            'X-Domain-Id': value
        });
    };
    DatasetService.prototype.appendFilterHeaders = function (headers, onlyWithFilterDomain) {
        if (!headers)
            headers = new HttpHeaders;
        // not override if just setted
        if (!headers.has('X-Domain-Id')) {
            if (onlyWithFilterDomain || (this.domainFilterService.filterDomainId.value && this.domainFilterService.showFilterBar)) {
                headers = this.appendDomainIdHeaders(this.domainFilterService.filterDomainId.value, headers);
            }
        }
        return headers;
    };
    DatasetService.prototype.get = function (path, httpParams, headers) {
        if (!path)
            return throwError('no path defined');
        var requestData = {
            path: path,
            data: httpParams,
            options: {},
            typeCall: 'get'
        };
        return this.http.get(this.appService.getBaseServerAddress() + path, {
            headers: this.appendFilterHeaders(headers),
            params: httpParams,
        }).pipe(catchError(this.handleError('dataset/get :> ' + path, false, requestData)));
    };
    DatasetService.prototype.post = function (path, data, options) {
        if (this.domainFilterService.filterDomainId.value) {
            // copy previous headers
            var newHeaders = this.appendFilterHeaders(options && options['headers'], true);
            options = Object.assign({}, options, { headers: newHeaders });
        }
        var requestData = { path: path, data: data, options: options };
        return this.http.post(this.appService.getBaseServerAddress() + path, data, options).pipe(catchError(this.handleError('dataset/post :> ' + path, false, requestData)));
    };
    DatasetService.prototype.put = function (path, data) {
        return this.http.put(this.appService.getBaseServerAddress() + path, data)
            .pipe(catchError(this.handleError('dataset/put :> ' + path)));
    };
    DatasetService.prototype.delete = function (path, data) {
        if (data) {
            return this.http.patch(this.appService.getBaseServerAddress() + path, data)
                .pipe(catchError(this.handleError('dataset/delete :> ' + path)));
        }
        return this.http.delete(this.appService.getBaseServerAddress() + path)
            .pipe(catchError(this.handleError('dataset/delete :> ' + path)));
    };
    DatasetService.prototype.getPropertyDefinitions = function (datasetCode) {
        return this.http.get(this.appService.getBaseServerAddress() + '/properties/definitions', {
            params: {
                dataset_code: datasetCode
            }
        }).pipe(catchError(this.handleError('GET /properties/definitions')));
    };
    DatasetService.prototype.fetchSourceData = function (resourceConfig, beforeCalls, paramMap, options) {
        var e_1, _a, e_2, _b;
        // console.log('fetchSourceData.options', options, new Error().stack);
        var calls = [];
        var callsMap = [];
        if (beforeCalls) {
            var _loop_1 = function (routeResolverCall) {
                var e_3, _a;
                var params = routeResolverCall.params || {};
                var callPath = routeResolverCall.path;
                if (paramMap) {
                    if (routeResolverCall.routeParamMap) {
                        try {
                            for (var _b = (e_3 = void 0, tslib_1.__values(routeResolverCall.routeParamMap)), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var elMap = _c.value;
                                params[elMap.callParam] = paramMap.get(elMap.routeParam);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                    paramMap.forEach(function (value, key) {
                        callPath = callPath.replace(':' + key, value);
                    });
                }
                if (routeResolverCall.method === 'post') {
                    calls.push(this_1.post(callPath, params));
                }
                else {
                    calls.push(this_1.get(callPath, params));
                }
                callsMap.push(routeResolverCall);
            };
            var this_1 = this;
            try {
                for (var beforeCalls_1 = tslib_1.__values(beforeCalls), beforeCalls_1_1 = beforeCalls_1.next(); !beforeCalls_1_1.done; beforeCalls_1_1 = beforeCalls_1.next()) {
                    var routeResolverCall = beforeCalls_1_1.value;
                    _loop_1(routeResolverCall);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (beforeCalls_1_1 && !beforeCalls_1_1.done && (_a = beforeCalls_1.return)) _a.call(beforeCalls_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (resourceConfig.supportProperties && (!options || !options.excludeProperties)) {
            calls.push(this.getPropertyDefinitions(resourceConfig.name));
            callsMap.push({
                name: resourceConfig.name + '.' + resourceConfig.name + '_property_definitions',
                path: '/properties/definitions'
            });
        }
        if (resourceConfig.nestedProperties && (!options || !options.excludeProperties)) {
            try {
                for (var _c = tslib_1.__values(resourceConfig.nestedProperties), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var nestedDatasetCode = _d.value;
                    calls.push(this.getPropertyDefinitions(nestedDatasetCode));
                    callsMap.push({
                        name: nestedDatasetCode + '.' + nestedDatasetCode + '_property_definitions',
                        path: '/properties/definitions'
                    });
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        if (calls.length === 0) {
            return of({});
        }
        return forkJoin(calls)
            .pipe(map(function (result) {
            var mappedResult = {};
            for (var i = 0; i < result.length; i++) {
                var name_1 = callsMap[i].name;
                var keys = name_1.split('.');
                if (keys.length == 1) {
                    mappedResult[name_1] = result[i];
                }
                else {
                    var container = mappedResult;
                    for (var j = 0; j < keys.length - 1; j++) {
                        var key = keys[j];
                        if (!container[key])
                            container[key] = {};
                        container = container[key];
                    }
                    container[keys[keys.length - 1]] = result[i];
                }
            }
            return mappedResult;
        }));
    };
    DatasetService.prototype.fetchPropertyDefinitions = function (resourceConfig) {
        if (resourceConfig.supportProperties) {
            return this.getPropertyDefinitions(resourceConfig.name)
                .pipe(map(function (result) {
                var _a;
                var mapped = {};
                mapped[resourceConfig.name] = (_a = {}, _a[resourceConfig.name + '_property_definitions'] = result, _a);
                return mapped;
            }));
        }
        return of({});
    };
    DatasetService.prototype.handleError = function (operations, ignore, requestData) {
        var _this = this;
        if (ignore === void 0) { ignore = false; }
        var baseHanlder = _super.prototype.handleError.call(this, operations, ignore);
        return function (errorResponse) {
            if (errorResponse.status >= 400 && errorResponse.status < 500) {
                if (errorResponse.error) {
                    if (errorResponse.error.code === 'invalid_allotments') {
                        return throwError(errorResponse);
                    }
                    else if (errorResponse.error.code === 'ambiguity') {
                        var observer_1 = new Subject();
                        var dialogRef = _this.domainInstanceService.openDomainsDialog();
                        dialogRef.afterClosed().subscribe(function (selectResult) {
                            if (!selectResult) {
                                observer_1.error(errorResponse);
                                observer_1.complete();
                                return observer_1;
                            }
                            if (errorResponse.error.setFilter)
                                _this.domainFilterService.setFilterDomain(selectResult, false);
                            var newHeaders = _this.appendDomainIdHeaders(selectResult, requestData.options && requestData.options['headers']);
                            var newOptions = Object.assign({}, requestData.options, { headers: newHeaders });
                            var callMyMethod = _this.post.bind(_this);
                            if (requestData.typeCall === 'get') {
                                callMyMethod = _this.get.bind(_this);
                                newOptions = newHeaders;
                            }
                            callMyMethod(requestData.path, requestData.data, newOptions)
                                .pipe(catchError(_this.handleError(operations, ignore, requestData)))
                                .subscribe({
                                next: function (response) {
                                    observer_1.next(response);
                                    observer_1.complete();
                                },
                                error: function (response) {
                                    observer_1.error(response);
                                    observer_1.complete();
                                }
                            });
                        });
                        return observer_1;
                    }
                    else if (errorResponse.error.action_required) {
                        if (errorResponse.error.action_required.code === 'join_in_domain') {
                            return action_join_domain(_this, errorResponse.error.action_required);
                            // return new Subject<any>(); // never complete, stop the action without error
                        }
                    }
                    else {
                        console.error('unhandled error', errorResponse.error);
                    }
                }
            }
            return baseHanlder(errorResponse);
        };
    };
    return DatasetService;
}(AbstractService));
export { DatasetService };
