import { Component, OnInit, OnDestroy } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
	selector   : 'counter-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class CounterInputComponent extends DestroyableComponent implements OnInit{

	public canAdd = true;
	public canSub = true;

	public minValue = null;

	constructor(
		public inputService: FormInputViewerService
	){
		super();
	}

	ngOnInit(): void {
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			const control = this.getControl();

			if(control){
				control.valueChanges
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(val => {
					this.updateStatus();
				});
				this.updateStatus();
			}

			this.minValue = _.get(this.inputService, 'formInputDefinition.extra.minValue');
		});
	}

	add(){
		if(!this.canAdd) return;
		const control = this.getControl();
		if(control) control.setValue(control.value + 1);
	}

	sub(){
		if(!this.canSub) return;
		const control = this.getControl();
		if(control) control.setValue(control.value - 1);
	}

	getControl(){
		return this.inputService.formGroup.controls[this.inputService.formControlKey]
	}

	getCurrentValue(){
		const control = this.getControl();
		if(!control) return null;
		return control.value;
	}

	updateStatus(){
		const control = this.getControl();
		if(!control) return;
		if(control.disabled){
			this.canAdd = false;
			this.canSub = false;
			return;
		}
		let minValue = 0;
		if(typeof(this.minValue) === 'number') minValue = this.minValue;
		this.canSub = control.value > minValue;
		this.canAdd = true;
		// TODO: configurable max value
	}
}
