import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { CustomKeysPipe } from './customKeysPipe.pipe';
import { RenderColumnPipe } from './renderColumnPipe.pipe';

@NgModule({
	declarations: [
		KeysPipe,
		GetByIdPipe,
		HtmlToPlaintextPipe,
		FilterPipe,
		CamelCaseToDashPipe,
		CustomKeysPipe,
		RenderColumnPipe
	],
	imports     : [],
	exports     : [
		KeysPipe,
		GetByIdPipe,
		HtmlToPlaintextPipe,
		FilterPipe,
		CamelCaseToDashPipe,
		CustomKeysPipe,
		RenderColumnPipe
	]
})
export class FusePipesModule
{
}
