import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';
var TimeInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeInputComponent, _super);
    function TimeInputComponent(appService, datasetService, inputService) {
        var _this = _super.call(this) || this;
        _this.appService = appService;
        _this.datasetService = datasetService;
        _this.inputService = inputService;
        return _this;
    }
    TimeInputComponent.prototype.ngOnInit = function () {
    };
    return TimeInputComponent;
}(DestroyableComponent));
export { TimeInputComponent };
