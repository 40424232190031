import {FieldViews, DatasetActionOpenType, IDatasetConfig, ListViewItemTypes, ValueTypes, InputTypes, IDatasetCallDefinition, StyleListViewConfig} from 'app/interfaces';
import {columnsMap, fieldsMap} from './fields';
import generalForm from './forms/general.form';
import {config as stepViewConfig} from './stepViewConfig';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { ConfirmDialogComponent, IConfirmDialogData } from 'app/main/dialogs/confirm-dialog/dialog.component';
import { ActionDialogComponent } from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import trashedField from 'app/configs/commons/shared-field-configs/trashed.field';
import { SelectDatasetRecordDialogComponent } from 'app/main/components/dataset/dialogs/select-dataset-record/dialog.component';
import makeRequestAction from './make-request.action';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import meetingPointIdField from 'app/configs/commons/shared-field-configs/meeting-point-id.field';
import tripCategoryIdField from 'app/configs/commons/shared-field-configs/trip-category-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripResourceIdField from 'app/configs/commons/shared-field-configs/trip-resource-id.field';
import { TranslateService } from '@ngx-translate/core';
import { getBaseServerAddressRoot } from 'app/configs/app-config';
import tripDestinationIdField from 'app/configs/commons/shared-field-configs/trip-destination-id.field';

const beforeCallDetail: IDatasetCallDefinition = {
	name: 'record',
	path: '/dataset/trip_packages/detail/:recordId',
	params: {
		with_relations: 'domain',
		with_attributes: 'is_owner,can_enable_for_marketplace,trip_service_rule_type,'
	}
};

const beforeCallDetailGetReselledData: IDatasetCallDefinition = {
	name: 'reselledData',
	path: '/dataset/trip_packages/command/reselled_data',
	params: {
		with_relations: 'domain',
		with_attributes: 'is_owner,can_enable_for_marketplace'
	},
	routeParamMap: [{
		routeParam: 'recordId',
		callParam: 'trip_package_id'
	}]
};

const callModule: IDatasetCallDefinition = {
	name: 'domainModules',
	path: '/dataset/domains/command/config',
	//method: 'post',
	params: {
		key: 'modules'
	}
};

export const TripPackageConfig: IDatasetConfig = {
	name: 'trip_packages',
	title: 'Pacchetti',
	singleTitle: 'Pacchetto',
	ajaxDatasetCode: 'trip_packages',
	fieldsMap: fieldsMap,
	supportProperties: true,
	supportTemplates: true,
	datasetListStyle: StyleListViewConfig.S1,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true,
		restore: true
	},
	formConfigsMap: {
		generalForm
	},
	fetchConfig: {
		with_relations: 'domain',
		with_attributes: 'is_owner,can_enable_for_marketplace,trip_service_rule_type'
	},
	stepViewConfig,
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ 
				beforeCallDetail,
				callModule,
				{
					name: 'domainContext',
					path: '/dataset/trip_packages/beforeCreate/:recordId',
				},
				beforeCallDetailGetReselledData
			],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [callModule],
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ 
				beforeCallDetail,
				callModule,
				{
					name: 'domainContext',
					path: '/dataset/trip_packages/beforeCreate/:recordId',
				},
				beforeCallDetailGetReselledData
			],
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams(dataContainerService: IDataContainerService, parent: any, params: any): any{
				params.with_relations = 'domain';
				params.with_attributes = 'is_owner,reseller_domain_package,current_resell_trip_package';
				return params;
			},
			viewConfig: {
				showRequestPackage: false,
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				filterStyle: StyleListViewConfig.S1,
				tableStyle: StyleListViewConfig.S1,
				columns: [
					columnsMap.get('active_status'),
					columnsMap.get('code'),
					columnsMap.get('name'),
					columnsMap.get('image'),
					columnsMap.get('owner'),
					{
						title: 'Traduzioni Attive',
						key: 'active_language_codes'
					}
				],
				actions: ['create', 'detail', 'delete', 'restore'],
				itemActionsOrder: ['detail', 'custom_0', 'delete', 'restore'],
				customActions: [
					
					/*{
						isGlobal: true,
						actionCode: 'resell',
						buttonColor: 'accent',
						buttonText: 'Rivendi',
						tooltip: 'Rivendi pacchetto',
						showIf(): boolean{
							return true; 
						},
						onClick(event: any, record: any, dataContainerService: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.dialog.open(SelectDatasetRecordDialogComponent, {
								data: {
									title: 'Seleziona Pacchetto',
									datasetCode: 'trip_packages',
									filters: {
										domain_resell_enabled: 1 // filter only package enabled for resell
									},
									// viewConfig: customView // you can specify a custom list view for the selection
								}
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								// Hanlde process errors with LoadingDialog
								// If current user have multiple domains ask in what domain we want resell
								// the selected package
								makeRequestAction(result, dataContainerService, datasetNavigatorService)
								.then((resp) => {
									if (!resp.success) {
										dataContainerService.appService.showErrorMessage(resp.messagge);
									} else {
										dataContainerService.appService.showSuccessMessage(resp.messagge);
									}
								})
								.catch(e => {
									console.error(e);
								});
							});
						}
					},*/
					{
						actionCode: 'create',
						buttonColor: 'accent',
						buttonIcon: 'file_copy',
						tooltip: 'Duplica',
						showIf(record: any): boolean{ 
							const filteredDomainId = localStorage.getItem(LocalStorageFilterDomainIdKey);
							const isFiltered = filteredDomainId && filteredDomainId !== 'null';
							if(record.domain){
								if ((!isFiltered && !record.is_owner) || (isFiltered && filteredDomainId !== record.domain.id)) {
									return false;
								}
							}
							return Boolean(record) && !Boolean(record.deleted_at) && !Boolean(record.tour_operator_id);
						},
						onClick(event, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void{
							datasetNavigatorService.openDialog<IConfirmDialogData>(ConfirmDialogComponent, {
								contentMessage: 'Vuoi duplicare questo pacchetto?'
							}).afterClosed()
							.subscribe(result => {
								if(!result) return;
								const dialogRef = datasetNavigatorService.dialog.open(ActionDialogComponent, {
									minWidth: 550,
									data: {
										title: 'Duplicazione in corso'
									}
								});
						
								datasetNavigatorService.datasetService.post<any>('/dataset/trip_packages/command/duplicate', {
									record_id: record.id
								})
								.subscribe({
									next: () => {
										dialogRef.close();
										if(dataContainerService instanceof DatasetActionContainerService){
											dataContainerService.datasetEvent.next({ 
												eventName: DatasetEvents.ACTION_REALOAD_LIST 
											});
										}
										datasetNavigatorService.appService.showSuccessMessage('Pacchetto duplicato con successo.');
									},
									error: () => {
										dialogRef.componentInstance.resultStatus = 'error';
										dialogRef.componentInstance.loading = false;
										dialogRef.componentInstance.setMessage('Si è verificato un errore');
									}
								});
							});
						}
					}
				],
				filterForm: {
					fields: [
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('active_status_filter'),
								fieldsMap.get('publish_on_web_filter')
							]
						},
						cloneFieldDefinition(meetingPointIdField, {
							inputType: InputTypes.DATASET_SELECT
						}),
						tripCategoryIdField,
						tripDestinationIdField,
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								cloneFieldDefinition(tripResourceIdField, {
									inputType: InputTypes.DATASET_SELECT
								}),
								{
									key: 'resource_sub_type',
									name: 'Tipologia Risorsa',
									inputType: InputTypes.DATASET_SELECT,
									valueType: ValueTypes.PROPERTY,
									datasetCode: 'trip_resource_sub_types',
									remoteOptions: {
										/*getParams(dataContainerService: IDataContainerService, record: any): any{
											const propertyDefinitions = dataContainerService.getValueFromKeyPath('trip_resources.trip_resources_property_definitions');
											const params = {
												resource_type: record.resource_type
											};
								
											if(propertyDefinitions && propertyDefinitions.items){
												for (const propertyDefinition of propertyDefinitions.items) {
													if(propertyDefinition.code === 'resource_type'){
														params.resource_type = record[propertyDefinition.id];
													}
												}
											}
											console.log('resource_sub_types.getParams', params, propertyDefinitions, record, dataContainerService);
											return params;
										},*/
										getValue(record: any): string{
											return record.code;
										},
										getLabel(record: any): string{
											return record.description;
										}
									}
								}
							]
						},
						{
							config: {
								flexLayout: 'row',
							},
							fields: [
								fieldsMap.get('only_resells'),
								trashedField
							]
						}
					]
				}
			}
		}
	}
};
