import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DatasetActionContainerService } from './dataset-action-container.service';
import { DatasetService } from './dataset.service';


@Injectable()
export class DatasetRecordService{

	public record: BehaviorSubject<any>;
	public loading: BehaviorSubject<boolean>;
	public ready: BehaviorSubject<boolean>;
	public recordId: any;
	public extraParams: any;

	constructor(
		public datasetACS: DatasetActionContainerService,
		public datasetService: DatasetService
	){
		this.record = new BehaviorSubject(null);
		this.loading = new BehaviorSubject(false);
		this.ready = new BehaviorSubject(false);

		this.record.subscribe(record => {
			if(record){
				this.recordId = record.id;
			}
		});
	}

	init(options?: any): BehaviorSubject<Boolean>{
		this.datasetACS.ready.subscribe(ready => {
			if(!ready){
				this.ready.next(false);
				return;
			}
			this.ready.next(true);
		});

		return this.ready;
	}

	getDatasetCode(): string{
		return this.datasetACS.datasetCode;
	}

	canEdit(): boolean{
		if(!this.record.value) return false;
		return this.datasetACS.canEdit(this.record.value);
	}

	canDelete(): boolean{
		if(!this.record.value) return false;
		return this.datasetACS.canDelete(this.record.value);
	}

	reloadWithParams(params: any){
		this.extraParams = params;
		this.reload();
	}

	reload(): Observable<any>{
		if(!this.recordId) return of(null);
		const result = new Subject();
		this.loading.next(true);
		const params = Object.assign({}, this.extraParams);
		if(this.datasetACS && this.datasetACS.datasetConfig && this.datasetACS.datasetConfig.fetchConfig){
			if(this.datasetACS.datasetConfig.fetchConfig.with_attributes){
				params['with_attributes'] = this.datasetACS.datasetConfig.fetchConfig.with_attributes;
			}
			if(this.datasetACS.datasetConfig.fetchConfig.with_relations){
				params['with_relations'] = this.datasetACS.datasetConfig.fetchConfig.with_relations;
			}
		}
		this.datasetService.get<any>('/dataset/' + this.getDatasetCode() + '/detail/' + this.recordId, params).subscribe({
			next: response => {
				this.record.next(response);
				result.next(response);
				result.complete();
				this.loading.next(false);
			},
			error: err => {
				result.error(err);
				result.complete();
				this.loading.next(false);
				console.error('dataset-record.service.ts', err);
				// result.error(err);
			}
		});
		return result;
	}
}
