import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { BookingService } from '../booking.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBookingParticipantsDialogComponent } from 'app/main/components/dataset/dialogs/add-booking-participants-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import { RELOAD_BOOKING } from '../edit-booking.component';
import { FormViewerService } from 'app/services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepChangerComponent } from '../base-step-changer.component';
var EditBookingStep1Component = /** @class */ (function (_super) {
    tslib_1.__extends(EditBookingStep1Component, _super);
    function EditBookingStep1Component(appService, datasetRS, datasetACS, formViewerService, datasetNavigatorService, bookingService, datasetService, matDialog) {
        var _this = _super.call(this, bookingService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.formViewerService = formViewerService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.bookingService = bookingService;
        _this.datasetService = datasetService;
        _this.matDialog = matDialog;
        _this.filterFields = formFields;
        _this.loading = false;
        return _this;
    }
    Object.defineProperty(EditBookingStep1Component.prototype, "record", {
        get: function () { return this.datasetRS.record.value; },
        enumerable: true,
        configurable: true
    });
    EditBookingStep1Component.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        // init form service
        this.formViewerService.init({
            errors: null,
            dataContainerService: this.datasetACS,
            fields: Array.from(formFields.values())
        });
    };
    EditBookingStep1Component.prototype.onListDatasetEvent = function (event) {
        //this.datasetRS.reload();
        this.appService.appEvent.next({
            name: RELOAD_BOOKING
        });
    };
    EditBookingStep1Component.prototype.onAdd = function () {
        var _this = this;
        this.matDialog.open(AddBookingParticipantsDialogComponent, {
            panelClass: 'create-dialog-container',
            data: {
                datasetACS: this.datasetACS,
                postData: {
                    trip_booking_id: this.datasetRS.recordId
                }
            }
        }).afterClosed()
            .subscribe(function (result) {
            _this.datasetListComponent.reload();
            _this.appService.appEvent.next({
                name: RELOAD_BOOKING
            });
        });
    };
    EditBookingStep1Component.prototype.decrement = function (age_cat) {
        var formControl = this.formViewerService.formGroup.get(age_cat);
        // some checks
        if (formControl.value <= 0) {
            return;
        }
        formControl.setValue(formControl.value - 1);
    };
    EditBookingStep1Component.prototype.increment = function (age_cat) {
        var formControl = this.formViewerService.formGroup.get(age_cat);
        formControl.setValue(formControl.value + 1);
    };
    EditBookingStep1Component.prototype.onAddParticipants = function () {
        var _this = this;
        var formData = this.formViewerService.getFormData();
        formData['trip_booking_id'] = this.datasetRS.recordId;
        this.loading = true;
        this.datasetService.post('/dataset/trip_booking_participants/command/generate_participants', formData)
            .subscribe({
            next: function (response) {
                _this.loading = false;
                _this.formViewerService.clearForm({
                    adt_counter: 0,
                    chd_counter: 0,
                    inf_counter: 0,
                    snr_counter: 0
                });
                _this.datasetListComponent.reload();
                _this.appService.appEvent.next({
                    name: RELOAD_BOOKING
                });
            },
            error: function (response) {
                _this.loading = false;
            }
        });
    };
    return EditBookingStep1Component;
}(BaseStepChangerComponent));
export { EditBookingStep1Component };
