import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { fieldsMap as resourceFieldMap} from '../trip_resources/fields';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';

const fieldsMap: Map<string, IFieldDefinition> = new Map();


fieldsMap.set('pivot_optional_resource', {
	key: 'optional_resource',
	name: 'Opzionale',
	valueType: ValueTypes.STRING,
	getDisplayValue(record): string{
		if(!record || !record.pivot) return '-';
		return Number(record.pivot.optional_resource) === 1 ? 'Si' : 'No'; 
	}
});

fieldsMap.set('resource_code', cloneFieldDefinition(resourceFieldMap.get('code'), {key: 'code', onClick: false}));
fieldsMap.set('resource_description', cloneFieldDefinition(resourceFieldMap.get('description'), {key: 'description'}));
fieldsMap.set('resource_allotment_type', cloneFieldDefinition(resourceFieldMap.get('allotment_type'), {key: 'allotment_type'}));

fieldsMap.set('availability', {
	key: 'availability',
	name: 'Disponibilità',
	valueType: ValueTypes.STRING,
	fieldView: FieldViews.HTML,
	getDisplayValue(record): string{
		if(!record) return '';
		const consumed = record.allotment_consumed || 0;
		if(record.allotment_type === 'free') return '[' + consumed + ']';
		if(!record.allotment_rule) return '[' + consumed + '/-]';
		if(record.allotment_booking_mode == 'exclusive' && consumed > 0) return '[' + consumed + '/Prenotata]';
		let total = 0;
		if(!isNaN(Number(record.allotment_rule.quantity))){
			total = record.allotment_rule.quantity;
		}
		if(total < 0) return '[' + consumed + '/&infin;]';
		return '[' + consumed + '/' + total + ']';
	}
});

export {fieldsMap};
