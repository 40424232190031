import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { AuthService } from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { TransmissionOperationConfig } from 'app/configs/datasets/transmission_operations';
var TransmissionDataComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TransmissionDataComponent, _super);
    function TransmissionDataComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        return _this;
    }
    TransmissionDataComponent.prototype.ngOnInit = function () {
        this.formConfig = TransmissionOperationConfig.formConfigsMap['detailForm'];
        this.request_document_RT_form = TransmissionOperationConfig.formConfigsMap['request_document_RT_form'];
        this.response_document_RT_form = TransmissionOperationConfig.formConfigsMap['response_document_RT_form'];
    };
    return TransmissionDataComponent;
}(BaseViewComponent));
export { TransmissionDataComponent };
