import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrenitaliaService } from '../../trenitalia.service';
import * as _ from 'lodash';

@Component({
	selector: 'trenitalia-other-solution',
	templateUrl: './other-solution.component.html',
	styleUrls: ['./other-solution.component.scss']
})
export class OtherSolutionComponent implements OnInit {
	@Input('solution') travelSolution: any;
	@Input('train') train: any;
    @Input('errors') errors: any;
	@Output('custom-offer-select-price') onPriceSelected = new EventEmitter();
	offers: any = [];
	services: any = [];
	price: any;
	offersMap: any;
	selectedCustomizedOffers: {};
	requestSeatSelection: boolean;
	servicesMap: any;
	customTravelSolution: any;

	constructor(private _trnService: TrenitaliaService) {}

	ngOnInit() {
		this.servicesMap = this.travelSolution.servicesMap||this.travelSolution.serviceMap;
		this.customTravelSolution = this.travelSolution.travelSolutions[0];
		this.offersMap = _.get(this.customTravelSolution, ['offers_map', this.train.id]);
		
		this.selectCustomizedOffers();
		this.initSelect();
		this.sendSelection();
	}

	selectCustomizedOffers() {
		this.selectedCustomizedOffers = {};
		for (const passenger of this.travelSolution.travellers) {
			const catalogServices = _.get(this.offersMap, ['travellers', passenger.xmlId], []);
			if (_.isEmpty(catalogServices)){
				console.warn('no catalogServices found for traveller', passenger, this.offersMap);
				continue;
			}

                this.selectedCustomizedOffers[passenger.xmlId] = {
                    catalogServiceId: null,
                    offerId: null,
                    offered: null,
                    price: null,
                    netPrice:null,
                    fee:null,
                    cartaFrecciaRequired: false,
                    parameters:null
                };

			// TODO: select best price service
            /*if(_.has(this.customTravelSolution,'priceSelected')){
                const sel:any = _.get(this.customTravelSolution,['priceSelected',this.train.id])
                console.log(sel);
                this.setSelection(passenger.xmlId, _.get(sel,[passenger.xmlId,'service']), _.get(sel,[passenger.xmlId,'offer']), false);
            }else {*/
                for (const catalogService of catalogServices) {
                    const offer = catalogService.offers.find(s => {
                        return s.selectable;
                    });
                    if (!offer) continue;
                    this.setSelection(passenger.xmlId, catalogService.id, offer.offerId, false);
                    break;
                }
            /*}*/
		}
	}

	initSelect() {
		const services = {};
		for (const passenger of this.travelSolution.travellers) {
			const catalogServices = _.get(this.offersMap, ['travellers', passenger.xmlId], []);
			if (_.isEmpty(catalogServices)){
				console.warn('no catalogServices found for traveller', passenger, this.offersMap);
				continue;
			}
			for (const catalogService of catalogServices) {
				const offer = catalogService.offers.find(s => {
					return s.selectable;
				});
				if(!offer) continue; // not has a selectable offer
				if (!services[passenger.xmlId]) {
					services[passenger.xmlId] = [];
				}
				services[passenger.xmlId].push({
					id: catalogService.id,
					label: this.servicesMap.catalog_services[catalogService.id].display_name,
					offers: offer
				});
				if(_.get(this.selectedCustomizedOffers, [passenger.xmlId, 'catalogServiceId']) == catalogService.id) this.initOffer(catalogService.offers.filter(s => s.selectable), passenger.xmlId);
			}
		}
		this.services = services;
	}

	initOffer(offers: any[], travellerXmlId: string){
		this.offers[travellerXmlId] = offers.filter(o => o.selectable)
		.map(offer => {
			return {
				id: offer.offerId,
				label: _.get(this.servicesMap, ['offers',offer.offerId,'display_name']),
				price: offer.totalPrice,
				description: _.get(this.servicesMap, ['offers',offer.offerId,'description']),
				offered: offer.offers
			};
		});
	}

	changeService(e: any, travellerXmlId: string) {
		const offServ = this.offersMap.travellers[travellerXmlId].find(
			s => s.id === e.value
		);
		if(!offServ){
			console.warn('catalog service not found', e);
			return;
		}
		let off = offServ.offers.filter(s => s.selectable);
		this.initOffer(off, travellerXmlId);
		this.setSelection(travellerXmlId, e.value, this.offers[travellerXmlId][0].id);
	}

	changeOffer(e: any, travellerXmlId: string) {
		const off = this.offers[travellerXmlId].find(o => o.id === e.value);
		if(!off){
			console.warn('offer not found', this.offers, travellerXmlId, e);
			return;
		}
		this.setSelection(travellerXmlId, this.selectedCustomizedOffers[travellerXmlId].catalogServiceId, off.id);
	}

	setSelection(travellerXmlId: string, catalogServiceId, offerId, send = true){
		const catalogService = this.offersMap.travellers[travellerXmlId].find(s => s.id == catalogServiceId);
		if(!catalogService){
			console.warn('invalid catalog service selected', travellerXmlId, catalogServiceId, offerId);
			return;
		}
		const offer = catalogService.offers.find(o => o.offerId == offerId);
		if(!offer){
			console.warn('invalid offer selected', travellerXmlId, catalogServiceId, offerId);
			return;
		}

        const selectionServiceParameters = _.get(this.servicesMap,'catalog_services.'+catalogServiceId+'.selection_service_parameters');
        let parameters:any = [];
        if(selectionServiceParameters && Array.isArray(selectionServiceParameters)){
            for (let parameter of selectionServiceParameters){
				if(!_.has(parameter, 'typeDefinition.enumeration'))continue;
				parameters.push({
					service_id: parameter.service_id,
					values: parameter.typeDefinition.enumeration,
					name: parameter.name,
					value: parameter.typeDefinition.defaultValue
				});
			}
		}
		this.selectedCustomizedOffers[travellerXmlId] = {
			catalogServiceId: catalogServiceId,
			offerId: offer.offerId,
			offered: offer.offers,
			price: offer.totalPrice,
            netPrice: offer.totalPriceNet,
            fee: offer.fee,
			offerData: this.servicesMap.offers[offer.offerId],
            parameters,
			cartaFrecciaRequired: _.has(this.servicesMap, ['offers', offer.offerId, 'selection_traveller_parameters_map', 'cartaFreccia'])
		};

		if(send) this.sendSelection();
	}

	sendSelection() {
		this.travelSolution.travelSolutions[0].custom_off[this.train.id] = {};

		this.travelSolution.travellers.forEach(t => {
            let parameters:any = null;
			const serviceParameters = _.get(this.selectedCustomizedOffers, [t.xmlId, 'parameters']);
            if(serviceParameters && Array.isArray(serviceParameters) && serviceParameters.length){
				parameters = {};
				for (const offerParameter of _.get(this.selectedCustomizedOffers, [t.xmlId, 'parameters'])) {
					parameters[offerParameter.service_id] = offerParameter.value;
				}
            }
            if(!this.selectedCustomizedOffers[t.xmlId]) {
                this.travelSolution.travelSolutions[0].custom_off[this.train.id][t.xmlId] = null;
            }else{
                this.travelSolution.travelSolutions[0].custom_off[this.train.id][t.xmlId] = {
                    offer: this.selectedCustomizedOffers[t.xmlId].offered,
                    service: this.selectedCustomizedOffers[t.xmlId].catalogServiceId,
                    price: this.selectedCustomizedOffers[t.xmlId].price,
                    fee: this.selectedCustomizedOffers[t.xmlId].fee?this.selectedCustomizedOffers[t.xmlId].fee:0,
                    netPrice: this.selectedCustomizedOffers[t.xmlId].netPrice?this.selectedCustomizedOffers[t.xmlId].netPrice:0,
                    parameter: parameters
                };
            }

		});
		this.onPriceSelected.emit();
	}

    
}
