import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { DatasetActionContainerService, IDatasetEvent } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { Subject } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { BookingService } from '../booking.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBookingParticipantsDialogComponent } from 'app/main/components/dataset/dialogs/add-booking-participants-dialog/dialog.component';
import { DatasetListComponent } from 'app/main/components/dataset/base-actions/list/dataset-list.component';
import { RELOAD_BOOKING } from '../edit-booking.component';
import { FormViewerService } from 'app/services/form-viewer.service';
import { fieldsMap as formFields } from './form.fields';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { takeUntil } from 'rxjs/operators';
import { BaseStepChangerComponent } from '../base-step-changer.component';

@Component({
	selector   : 'edit-booking-step-1',
	templateUrl: './view.component.html',
	styleUrls: [ './view.component.scss' ],
	providers: [ FormViewerService ],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	]
})
export class EditBookingStep1Component extends BaseStepChangerComponent implements OnInit, OnDestroy{

	public filterFields = formFields;
	public loading = false;
	@ViewChild(DatasetListComponent, { static: true }) datasetListComponent: DatasetListComponent;

	get record(): any{ return this.datasetRS.record.value; }

	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public formViewerService: FormViewerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public bookingService: BookingService,
		public datasetService: DatasetService,
		protected matDialog: MatDialog){
			super(bookingService);	
		}

	ngOnInit(): void{
		super.ngOnInit();

		// init form service
		this.formViewerService.init({
			errors: null,
			dataContainerService: this.datasetACS,
			fields: Array.from(formFields.values())
		});
	}

	onListDatasetEvent(event: IDatasetEvent): void{
		//this.datasetRS.reload();
		this.appService.appEvent.next({
			name: RELOAD_BOOKING
		});
	}

	onAdd(): void{
		this.matDialog.open(AddBookingParticipantsDialogComponent, {
			panelClass: 'create-dialog-container',
			data: {
				datasetACS: this.datasetACS,
				postData: {
					trip_booking_id: this.datasetRS.recordId
				}
			}
		}).afterClosed()
		.subscribe(result => {
			this.datasetListComponent.reload();
			this.appService.appEvent.next({
				name: RELOAD_BOOKING
			});
		});
	}

	decrement(age_cat: string): void{
		const formControl = this.formViewerService.formGroup.get(age_cat);
		// some checks
		if(formControl.value <= 0){
			return;
		}
		formControl.setValue(formControl.value - 1);
	}

	increment(age_cat): void{
		const formControl = this.formViewerService.formGroup.get(age_cat);
		formControl.setValue(formControl.value + 1);
	}

	onAddParticipants(): void {
		const formData = this.formViewerService.getFormData();
		formData['trip_booking_id'] = this.datasetRS.recordId;
		this.loading = true;
		this.datasetService.post('/dataset/trip_booking_participants/command/generate_participants', formData)
		.subscribe({
			next: (response: any) => {
				this.loading = false;
				this.formViewerService.clearForm({
					adt_counter: 0,
					chd_counter: 0,
					inf_counter: 0,
					snr_counter: 0
				});
				this.datasetListComponent.reload();
				this.appService.appEvent.next({
					name: RELOAD_BOOKING
				});
			},
			error: response => {
				this.loading = false;
			}
		});
		
	}
}
