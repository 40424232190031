import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from './../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('target'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('departure_from'),
			fieldsMap.get('departure_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('booking_from'),
			fieldsMap.get('booking_to'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			fieldsMap.get('discount_percent'),
			fieldsMap.get('discount_amount'),
		]
	},
	fieldsMap.get('trip_category_id'),
	fieldsMap.get('trip_package_id'),
	fieldsMap.get('trip_resource_id'),
	fieldsMap.get('product_id'),
	fieldsMap.get('service_id'),
];

const config: IFormConfig = {
	fields: formFields 
};

export default config;
