import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import serviceIdField from 'app/configs/commons/shared-field-configs/service-id.field';
import tripServiceRuleIdField from 'app/configs/commons/shared-field-configs/trip-service-rule-id.field';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'Nome del servizio'
});

fieldsMap.set('trip_service_rule_id', cloneFieldDefinition(tripServiceRuleIdField));

fieldsMap.set('service_id', cloneFieldDefinition(serviceIdField));

fieldsMap.set('service.code', {
	key: 'service.code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('service.description', {
	key: 'service.description',
	name: 'Description',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('trip_service_rule.code', {
	key: 'trip_service_rule.code',
	name: 'Codice',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('trip_service_rule.description', {
	key: 'trip_service_rule.description',
	name: 'Description',
	valueType: ValueTypes.STRING,
});

fieldsMap.set('price', {
	key: 'price',
	name: 'Prezzo',
	valueType: ValueTypes.NUMBER,
	transforms: [
		{
			pipeClass: CurrencyPipe,
			args: ['EUR']
		}
	]
});

fieldsMap.set('taxation', {
	key: 'taxation',
	name: 'Fiscalità',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'select_options',
	inputHint: 'Seleziona la fiscalità',	
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const domainModules = dataContainerService.getValueFromKeyPath('domainModules');
		if(domainModules && domainModules.ticketing && domainModules.ticketing.enabled){
			return domainModules.ticketing.enabled;
		}
		return false;
	},
	remoteOptions: {
		limit: 100,
		getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
			return {
				options_code : 'taxation',
				target: 'trip_services'
			};	
		},
		getLabel(option): string{
			if(!option) return '';
			return option.label;
		},
		getValue(option): string{
			if(!option) return '';
			return option.value;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.label;
		}
	}	
});


export {fieldsMap};
