<div class="position-relative" [class.trip-booking-package-card-container]="!tripBookingPackage.parent_trip_booking_package_id">
	<div class="p-16">
		<ng-container *ngIf='!tripBookingPackage.parent_trip_booking_package_id'>
			<trip-booking-package-header
				icon="card_travel"
				[title]="tripBookingPackage.trip_package.description"
				[showInfo]="true"
				[canDelete]="canDelete"
				[canCancel]="canCancel"
				(infoClick)="viewTripPackage()"
				(deleteClick)='deleteTripBookingPackage()'
			></trip-booking-package-header>
		</ng-container>

		<div class="flex-row items-center justify-start flex-gap-16" *ngIf="tripBookingPackage.actual_trip">
			<div *ngIf='tripBookingPackage.actual_trip.type' class="icon-size-24">
				<ng-container *ngIf='["going", "single"].includes(tripBookingPackage.actual_trip.type)'>
					<span class="going_trip">
						<img src="assets/icons/material-icons/filled/going_trip_icon.svg" />
					</span>
				</ng-container>
				<ng-container *ngIf='["return"].includes(tripBookingPackage.actual_trip.type)'>
					<span class="return_trip">
						<img src="assets/icons/material-icons/filled/return_trip_icon.svg" />
					</span>
				</ng-container>
			</div>

			<div class="flex-row items-center justify-start flex-gap-16">
				<div class="actual_trip_description" [matTooltip]='tripBookingPackage.actual_trip.display_name'>{{ tripBookingPackage.actual_trip.display_name }}</div>
				<div class="date_times_trips">
					<ng-container *ngIf="tripBookingPackage.actual_trip.start_day == tripBookingPackage.actual_trip.end_day">
						<span class="start_date_time">{{ 'il' | translate }} {{ tripBookingPackage.actual_trip.start_day | date }}</span>
					</ng-container>
					<ng-container *ngIf="tripBookingPackage.actual_trip.start_day != tripBookingPackage.actual_trip.end_day">
						<span class="start_date_time">{{ 'dal' | translate }} {{ tripBookingPackage.actual_trip.start_day | date }}</span>
						<ng-container *ngIf='tripBookingPackage.actual_trip.end_day'>
							<span class="end_date_time">{{ 'al' | translate }} {{ tripBookingPackage.actual_trip.end_day | date }}</span>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<button mat-icon-button color='accent' *ngIf='!tripBookingPackage.tour_operator_booking_ref && !tripBookingPackage.has_resale_child' [matTooltip]='"Modifica partenza" | translate' (click)='editTrip()'>
				<mat-icon>edit</mat-icon>
			</button>
		</div>

		<div class="flex-row items-center justify-start flex-gap-16" *ngIf='tripBookingPackage.actual_trip && tripBookingPackage.actual_trip.meeting_point_list_id'>
			<div class="icon-size-24">
				<img src="assets/icons/material-icons/filled/md-pin.svg"/>
			</div>

			<div class="meeting-point-description flex-row flex-wrap items-center justify-start flex-gap-4">
				<span>{{'Punto di ritrovo' | translate}}: {{meetingPointDescription}}</span>
				<span><mat-spinner *ngIf='loadingMeetingPoint' diameter='20'></mat-spinner></span>
			</div>

			<button *ngIf='!loadingMeetingPoint' mat-icon-button color='accent' [matTooltip]='"Modifica punto di ritrovo" | translate' (click)='editDefaultMeetingPoint()'>
				<mat-icon>edit</mat-icon>
			</button>
		</div>

		<ng-container *ngFor='let trip_booking_resource of tripBookingPackage.trip_booking_resources'>
			<ng-container *ngIf='trip_booking_resource.optional'>
				<trip-package-resource-booking
					[inputTripBookingResource]='trip_booking_resource'
				></trip-package-resource-booking>
			</ng-container>
		</ng-container>
	</div>
	
	<ng-container *ngFor="let childrenTripBookingPackage of tripBookingPackage.children_trip_booking_packages; let first = first; let last = last;">
		<hr/>
		<trip-booking-package 
			[tripBookingPackage]='childrenTripBookingPackage'
			[iterationData]="{is_first: first, is_last: last}"
		></trip-booking-package>
	</ng-container>

	<div *ngIf='!tripBookingPackage.parent_trip_booking_package_id' class="p-16">
		<trip-booking-package-related-buttons></trip-booking-package-related-buttons>
	</div>
</div>