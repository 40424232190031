import * as tslib_1 from "tslib";
import { ValueTypes, InputTypes, FieldViews } from 'app/interfaces';
import * as moment from 'moment';
var weekDays = [
    {
        label: 'Lunedì',
        value: 1
    },
    {
        label: 'Martedì',
        value: 2
    },
    {
        label: 'Mercoledì',
        value: 3
    },
    {
        label: 'Giovedì',
        value: 4
    },
    {
        label: 'Venerdì',
        value: 5
    },
    {
        label: 'Sabato',
        value: 6
    },
    {
        label: 'Domenica',
        value: 7
    }
];
var fieldsMap = new Map();
fieldsMap.set('selected_option_id', {
    key: 'selected_option_id',
    name: 'Opzione',
    inputType: InputTypes.DATASET_SELECT,
    valueType: ValueTypes.REFERENCE,
    appearance: 'outline',
    inputHint: 'Filtra per opzione',
    datasetCode: 'trip_options',
    remoteOptions: {
        limit: 100,
        getParams: function (dataContainerService, record) {
            var tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
            var params = {};
            if (tripPackageId)
                params = { trip_package_id: tripPackageId };
            return params;
        },
        getLabel: function (option) {
            if (!option)
                return '';
            return option.description;
        },
        getValue: function (option) {
            if (!option)
                return;
            return option.id;
        },
        getDisplay: function (option) {
            if (!option)
                return '';
            return option.description;
        }
    }
});
fieldsMap.set('description', {
    key: 'description',
    name: 'Descrizione',
    inputType: InputTypes.TEXT,
    valueType: ValueTypes.STRING,
    appearance: 'outline',
    inputHint: '(e.g. Mattino Feriali... )'
});
fieldsMap.set('valid_column', {
    key: 'valid_from',
    name: 'dal/al',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var e_1, _a;
        var val = 'dal ';
        if (record.valid_from) {
            val += moment(record.valid_from).toDate().toLocaleDateString();
        }
        else {
            val += '---';
        }
        val += '<br>al ';
        if (record.valid_to) {
            val += moment(record.valid_to).toDate().toLocaleDateString();
        }
        else {
            val += '---';
        }
        val += '<br>';
        if (record.week_days && Array.isArray(record.week_days)) {
            var weekDayLabels = [];
            var _loop_1 = function (weekDay) {
                var o = weekDays.find(function (wd) { return wd.value == weekDay; });
                if (!o)
                    return "continue";
                weekDayLabels.push(o.label);
            };
            try {
                for (var _b = tslib_1.__values(record.week_days), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var weekDay = _c.value;
                    _loop_1(weekDay);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (weekDayLabels.length)
                val += 'di ' + weekDayLabels.join(',');
        }
        return val;
    }
});
fieldsMap.set('time_column', {
    key: 'date_from',
    name: 'dalle/alle',
    valueType: ValueTypes.STRING,
    fieldView: FieldViews.HTML,
    getDisplayValue: function (record) {
        var val = '';
        val += record.time_from || '---';
        val += ' / ';
        val += record.time_to || '---';
        val += '<br>';
        if (record.time_interval_minutes)
            val += 'ogni ' + record.time_interval_minutes + ' min';
        return val;
    }
});
fieldsMap.set('valid_from', {
    key: 'valid_from',
    name: 'dal',
    inputType: InputTypes.DATE,
    valueType: ValueTypes.DATE,
    appearance: 'outline',
});
fieldsMap.set('valid_to', {
    key: 'valid_to',
    name: 'al',
    inputType: InputTypes.DATE,
    valueType: ValueTypes.DATE,
    appearance: 'outline',
});
fieldsMap.set('time_from', {
    key: 'time_from',
    name: 'Dalle ore',
    inputType: InputTypes.TIME,
    valueType: ValueTypes.TIME
});
fieldsMap.set('time_to', {
    key: 'time_to',
    name: 'Alle ore',
    inputType: InputTypes.TIME,
    valueType: ValueTypes.TIME
});
fieldsMap.set('time_interval_minutes', {
    key: 'time_interval_minutes',
    name: 'Intevallo in minuti',
    inputType: InputTypes.NUMBER,
    valueType: ValueTypes.NUMBER,
    defaultValue: function () {
        return 60;
    }
});
fieldsMap.set('week_days', {
    key: 'week_days',
    name: 'Giorni della settimana',
    valueType: ValueTypes.ARRAY,
    inputConfig: {
        type: InputTypes.MULTI_SELECT
    },
    options: weekDays
});
export { fieldsMap };
