import {Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatasetService } from '../../services/dataset.service';
import { formGroupToFormData } from 'app/helpers/field.helper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as italiano } from './i18n/it';

export interface IUploadFileDialogDataImageOptions{
	minWidth: number;
	minHeight: number;
	ratio: number;
}
export interface IUploadFileDialogData {
	title?: string;
	message?: string;
	postData?: any;
	route: string;
	fileType: string;
	negativeText?: string;
	positiveText?: string;
	svgEnabled?: boolean;
	imageOptions?: IUploadFileDialogDataImageOptions;
}

@Component({
	selector: 'upload-file-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})
export class UploadFileDialogComponent implements OnDestroy, OnInit {

	public svgEnablded: boolean;

	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public originalFile: any;

	public loading = false;
	protected _unsubscribeAll: Subject<any>;
	public formGroup: FormGroup;
	protected dragging = false;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	public selectedFileName: string;
	public imagePreviewSrc;

	public imageRatio = 1920 / 400;
	public imageWidth = 1920;
	public imageHeight = 400;

	public fileType = 'image';

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<UploadFileDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IUploadFileDialogData,
		public datasetService: DatasetService,
		public sanitizer: DomSanitizer,
		private fuseTranslationLoader: FuseTranslationLoaderService) {
		// carica traduzioni
		this.fuseTranslationLoader.loadTranslations(english, italiano);
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void{

		if(this.data.imageOptions){
			if(this.data.imageOptions.ratio){
				this.imageRatio = this.data.imageOptions.ratio;
			}
			if(this.data.imageOptions.minWidth){
				this.imageWidth = this.data.imageOptions.minWidth;
			}
			if(this.data.imageOptions.minHeight){
				this.imageHeight = this.data.imageOptions.minHeight;
			}
		}

		this.svgEnablded = this.data.svgEnabled;
		this.fileType = this.data.fileType || 'image';

		this.formGroup = new FormGroup({
			file: new FormControl(null)
		});
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onUpload(): void {
		this.loading = true;
		const formData = formGroupToFormData(this.formGroup);
		if(this.data.postData){
			for (const key in this.data.postData) {
				if (!Object.prototype.hasOwnProperty.call(this.data.postData, key)) continue;
				const value = this.data.postData[key];
				formData.set(key, value);
			}
		}
		this.datasetService.post(this.data.route, formData)
		.subscribe({
			next: response => {
				this.dialogRef.close(response);
			},
			error: response => {
				this.loading = false;
			}
		});
		
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onFileDragStart(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDragLeave(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}

	onFileDragOver(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDrop(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
		let file = null;
		if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
			file = event.dataTransfer.items[0].getAsFile();
		}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
			file = event.dataTransfer.files[0];
		}
		if(file){
			this.selectedFileName = file.name;
		}else{
			this.selectedFileName = null;
		}
		this.originalFile = file;
		if(file) this.imageChangedEvent = { target: { files: [file]} };
		else this.imageChangedEvent = null;
		
		this.readURL(event, true);
	}

	onFileChange(event): void{
		if(event.target.files.length > 0) {
			const file = event.target.files[0];
			if(file){
				this.selectedFileName = file.name;
			}else{
				this.selectedFileName = null;
			}
			this.originalFile = file;
			this.imageChangedEvent = file ? {target: {files: [file]}} : null;
			// this.formGroup.get('file').setValue(file);
		}else{
			this.imageChangedEvent = null;
			this.originalFile = null;
			this.selectedFileName = null;
			// this.formGroup.get('file').setValue(null);
		}
		this.readURL(event);
	}

	clearFile(): void{
		this.formGroup.get('file').setValue(null);
		if(this.fileInput){
			this.fileInput.nativeElement.value = null;
		}
		this.selectedFileName = null;
		this.imagePreviewSrc = null;
	}

	readURL(event, drop?: boolean): void {
		if (!drop) {
			if (event.target.files && event.target.files[0]) {
				const file = event.target.files[0];
				const type = file.type;
		
				const reader = new FileReader();

				if (type === 'image/svg+xml') {
					reader.onload = e => this.imagePreviewSrc = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
				} else {
					reader.onload = e => this.imagePreviewSrc = reader.result;
				}

				reader.readAsDataURL(file);
				this.formGroup.get('file').setValue(file);
			}else{
				this.imagePreviewSrc = null;
			}
		} else {
			let file = null;
			if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
				file = event.dataTransfer.items[0].getAsFile();
			}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
				file = event.dataTransfer.files[0];
			}
			if(file){
				const reader = new FileReader();
				const type = file.type;

				if (type === 'image/svg+xml') {
					reader.onload = e => this.imagePreviewSrc = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
				} else {
					reader.onload = e => this.imagePreviewSrc = reader.result;
				}
		
				reader.readAsDataURL(file);
				this.formGroup.get('file').setValue(file);
			}else{
				this.imagePreviewSrc = null;
			}
		}
	}

	// ImageCropper methods
	imageCropped(event: ImageCroppedEvent): void {
		let invalidImage = null;
		if(event.file){
			if(this.data.imageOptions){
				// console.log(event);
				if(this.data.imageOptions.minWidth > event.width){
					invalidImage = 'Dimensioni immagine non supportate';
				}else if(this.data.imageOptions.minHeight > event.height){
					invalidImage = 'Dimensioni immagine non supportate';
				}
			}
		}
		if(invalidImage){
			this.originalFile = null;
			this.croppedImage = null;
			this.imageChangedEvent = null;
			this.formGroup.get('file').setValue(null);
			this.appService.showErrorMessage(invalidImage);
			return;
		}
		this.croppedImage = event.file;
		this.formGroup.get('file').setValue(event.file);
	}
	imageLoaded(): void{
		// show cropper
	}
	cropperReady(): void{
		// cropper ready
	}
	loadImageFailed(): void{
		this.appService.showErrorMessage('Impossibile caricare l\'immagine');
	}
}
