/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-bar";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../base-actions/list/dataset-list.component.ngfactory";
import * as i7 from "../../services/dataset-action-container.service";
import * as i8 from "../../../../../services/app.service";
import * as i9 from "../../../../pages/page-closure-container.service";
import * as i10 from "../../../../../services/auth.service";
import * as i11 from "../../services/dataset.service";
import * as i12 from "../../../../../services/domain-filter.service";
import * as i13 from "../../base-actions/list/dataset-list.service";
import * as i14 from "../../base-actions/list/dataset-list.component";
import * as i15 from "@angular/router";
import * as i16 from "../../services/dataset-navigator.service";
import * as i17 from "../../../../../../@fuse/services/translation-loader.service";
import * as i18 from "@angular/material/snack-bar";
import * as i19 from "../../../../../services/websocket.service";
import * as i20 from "@angular/common";
import * as i21 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i22 from "@angular/material/button";
import * as i23 from "@angular/cdk/a11y";
import * as i24 from "../../services/dataset-record.service";
import * as i25 from "./dialog.component";
var styles_MeetingPointListItemsDialogComponent = [];
var RenderType_MeetingPointListItemsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MeetingPointListItemsDialogComponent, data: {} });
export { RenderType_MeetingPointListItemsDialogComponent as RenderType_MeetingPointListItemsDialogComponent };
function View_MeetingPointListItemsDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Lista di punti di ritrovo")); _ck(_v, 2, 0, currVal_1); }); }
function View_MeetingPointListItemsDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mt-24 mb-24 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "min-width:300px"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i3.View_MatProgressBar_0, i3.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i4.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i5.ANIMATION_MODULE_TYPE], [2, i4.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_MeetingPointListItemsDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mb-24"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "dataset-list", [["datasetCode", "meeting_point_unified_list_items"]], null, [[null, "datasetEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("datasetEvent" === en)) {
        var pd_0 = (_co.onDatasetEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DatasetListComponent_0, i6.RenderType_DatasetListComponent)), i0.ɵprd(512, null, i7.DatasetActionContainerService, i7.DatasetActionContainerService, [i8.AppService, i9.PageClosureContainerService, i10.AuthService, i11.DatasetService, i12.DomainFilterService, i2.TranslateService, i0.Injector]), i0.ɵprd(512, null, "ListParamService", i13.DatasetListService, [i7.DatasetActionContainerService, i11.DatasetService]), i0.ɵdid(4, 770048, null, 0, i14.DatasetListComponent, [i15.ActivatedRoute, i11.DatasetService, i15.Router, i10.AuthService, i8.AppService, i7.DatasetActionContainerService, i16.DatasetNavigatorService, i12.DomainFilterService, "ListParamService", i17.FuseTranslationLoaderService, i18.MatSnackBar, i19.WebSocketService], { showTitle: [0, "showTitle"], datasetCode: [1, "datasetCode"], parentDatasetACS: [2, "parentDatasetACS"], parentDatasetRS: [3, "parentDatasetRS"], filters: [4, "filters"], showSearchBar: [5, "showSearchBar"], elevate: [6, "elevate"] }, { datasetEvent: "datasetEvent" }), i0.ɵpod(5, { list_id: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = "meeting_point_unified_list_items"; var currVal_2 = _co.datasetACS; var currVal_3 = _co.datasetRS; var currVal_4 = _ck(_v, 5, 0, _co.data.listId); var currVal_5 = false; var currVal_6 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_MeetingPointListItemsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MeetingPointListItemsDialogComponent_1)), i0.ɵdid(1, 16384, null, 0, i20.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MeetingPointListItemsDialogComponent_2)), i0.ɵdid(5, 16384, null, 0, i20.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MeetingPointListItemsDialogComponent_3)), i0.ɵdid(7, 16384, null, 0, i20.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 5, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["color", ""], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_MatButton_0, i21.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i22.MatButton, [i0.ElementRef, i23.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(12, 0, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasetACS.datasetConfig; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.loading || _co.datasetRS.loading.value); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.datasetRS.loading.value; _ck(_v, 7, 0, currVal_2); var currVal_5 = ""; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = (i0.ɵnov(_v, 11).disabled || null); var currVal_4 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("Chiudi")); _ck(_v, 12, 0, currVal_6); }); }
export function View_MeetingPointListItemsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "meeting-point-list-items-dialog", [], null, null, null, View_MeetingPointListItemsDialogComponent_0, RenderType_MeetingPointListItemsDialogComponent)), i0.ɵprd(512, null, i9.PageClosureContainerService, i9.PageClosureContainerService, [i8.AppService]), i0.ɵprd(512, null, i7.DatasetActionContainerService, i7.DatasetActionContainerService, [i8.AppService, i9.PageClosureContainerService, i10.AuthService, i11.DatasetService, i12.DomainFilterService, i2.TranslateService, i0.Injector]), i0.ɵprd(512, null, i24.DatasetRecordService, i24.DatasetRecordService, [i7.DatasetActionContainerService, i11.DatasetService]), i0.ɵdid(4, 245760, null, 0, i25.MeetingPointListItemsDialogComponent, [i7.DatasetActionContainerService, i24.DatasetRecordService, i8.AppService, i11.DatasetService, i1.MatDialog, i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var MeetingPointListItemsDialogComponentNgFactory = i0.ɵccf("meeting-point-list-items-dialog", i25.MeetingPointListItemsDialogComponent, View_MeetingPointListItemsDialogComponent_Host_0, {}, {}, []);
export { MeetingPointListItemsDialogComponentNgFactory as MeetingPointListItemsDialogComponentNgFactory };
