import { IFieldContainer, IFieldDefinition } from 'app/interfaces';
import { infoFieldsMap } from './../info.fields';

const infoFormFields: (IFieldContainer|IFieldDefinition)[] = [
	// infoFieldsMap.get('entity.code'),
	/*infoFieldsMap.get('entity.firstname'),
	infoFieldsMap.get('entity.lastname'),
	infoFieldsMap.get('entity.gender'),
	infoFieldsMap.get('entity.birth_day'),
	infoFieldsMap.get('entity.birth_location'),*/
	infoFieldsMap.get('description'),
	infoFieldsMap.get('primary_email'),
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			infoFieldsMap.get('piva'),
			infoFieldsMap.get('fiscal_code'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			infoFieldsMap.get('primary_address.street'),
			infoFieldsMap.get('primary_address.number'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			infoFieldsMap.get('primary_address.locality'),
			infoFieldsMap.get('primary_address.city'),
		]
	},
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			infoFieldsMap.get('primary_address.province'),
			infoFieldsMap.get('primary_address.region'),
			infoFieldsMap.get('primary_address.nation_code'),
		]
	},
	infoFieldsMap.get('scenario'),
];

export default {
	fields: infoFormFields 
};
