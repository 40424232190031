<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	
	<form autocomplete="off"
		name='form' 
		*ngIf='formViewerService.initialized && formViewerService.formGroup' 
		[formGroup]="formViewerService.formGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'>

		<div class="section title">
			<label class="input-label section-question">
				Qual'è il titolo del tuo pacchetto?
			</label>
	
			<div class="instruction">
				<p class="instruction">
					Scrivi un breve titolo descrittivo per aiutare i clienti a comprendere il tuo prodotto. Può includere la posizione, il tipo di attività, la durata e i siti da visitare.
				</p>
				<p class="instruction">
					Usa questo formato: <em>Località: Tipo di attività/Info extra</em>
				</p>
			</div>
	
			<div class="examples">
				<h3 class="examples-title"><b>Esempio</b></h3>
	
				<div class="good-examples">
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Parigi: pass vip per il Louvre</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> La grande muraglia cinese: Tour da Pechino con pranzo</span>
					</div>
	
					<div class="example">
						<span class="text"><i class="material-icons">check_circle</i> Roma: Vaticano, Cappella Sistina, Tour di 2 ore di San Pietro</span>
					</div>
				</div>
	
				<div class="bad-examples">
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> LE MIGLIORI VISTE DI PARIGI CON UNA GUIDA TURISTICA INCREDIBILE</span>
					</div>
					
					<div class="example">
						<span class="text"><i class="material-icons">cancel</i> Prenota un amico locale con Companyy.com</span>
					</div>
				</div>
			</div>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("description")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["description"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>
	
		<div class="section product-reference">
			<label class="input-label section-title"><b>Codice prodotto</b></label>
			<p class="instruction">Per aiutarti a tenere traccia dei tuoi prodotti su Tangerine, aggiungi il tuo codice di riferimento o nome.</p>
	
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("code")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["code"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<label style="padding-left: 10px;" class="input-label section-title"><b>Stato pacchetto</b></label>
		<div class="section status-package">
			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("active_status")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["active_status"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("publish_on_web")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["publish_on_web"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<!-- trip_service_rule_type -->
		<!-- <label style="padding-left: 10px;" class="input-label section-title"><b>Tipologia calcolo prezzo</b></label> -->
		<div style="padding-left: 15px;" class="base-textfield base-textfield-large">
			<form-input-viewer
				[formInputDefinition]='fileterFields.get("trip_service_rule_type")'
				[formGroup]='formViewerService.formGroup'
				[formControlKey]='formViewerService.controlKeyMap["trip_service_rule_type"]'
				[dataContainerService]='formViewerService.dataContainerService'
				[data]='formViewerService.record'
			></form-input-viewer>
		</div>

		<!-- eAgency -->
		<ng-container *ngIf='showIfeAgency'>
			<label style="padding-left: 10px;" class="input-label section-title"><b>export eAgency dati</b></label>
			<div class="section status-package">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("supplier_id")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["supplier_id"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
	
				<div class="base-textfield base-textfield-large taxation">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("taxation")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["taxation"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
	
			<div class="section status-package">
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("commessa_id")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["commessa_id"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
	
				<div class="base-textfield base-textfield-large">
					<form-input-viewer
						[formInputDefinition]='fileterFields.get("active_commission_percent")'
						[formGroup]='formViewerService.formGroup'
						[formControlKey]='formViewerService.controlKeyMap["active_commission_percent"]'
						[dataContainerService]='formViewerService.dataContainerService'
						[data]='formViewerService.record'
					></form-input-viewer>
				</div>
			</div>
		</ng-container>
	
		<div class="save-button-container">
			<button [disabled]='formViewerService.formGroup.invalid && !formErrors || datasetACS.loading.value' class="gyg-button gyg-button-medium gyg-button-cta-light" (click)='onSave()'>
				<span>Salva e continua</span>
			</button>
		</div>
	</form>

</div>