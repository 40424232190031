import { IFieldDefinition, ValueTypes, InputTypes } from 'app/interfaces';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('branch_code', {
	key: 'branch_code',
	name: 'Filiale',
	appearance: 'legacy',
	valueType: ValueTypes.STRING,
	datasetCode: 'branches',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 100,
		getLabel(option): any{
			if(!option) return '';
			return option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		}
	}
});

fieldsMap.set('customer_code', {
	key: 'customer_code',
	name: 'Filtro Clienti...',
	appearance: 'legacy',
	valueType: ValueTypes.ARRAY,
	datasetCode: 'entity_customers',
	inputConfig: {
		type: InputTypes.DATASET_MULTISELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getParams(): any{
			return {column_name: 'code'};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		}
	}
});

fieldsMap.set('package_code', {
	key: 'package_code',
	name: 'Filtro Pacchetti...',
	appearance: 'legacy',
	valueType: ValueTypes.ARRAY,
	datasetCode: 'trip_packages',
	inputConfig: {
		type: InputTypes.DATASET_MULTISELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getParams(): any{
			return {column_name: 'code'};
		},
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		}
	}
});

fieldsMap.set('category_id', {
	key: 'category_id',
	name: 'Filtro Categoria...',
	appearance: 'legacy',
	valueType: ValueTypes.ARRAY,
	datasetCode: 'trip_categories',
	inputConfig: {
		type: InputTypes.DATASET_MULTISELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('booking_date_from', {
	key: 'booking_date_from',
	name: 'Prenotazioni dal',
	appearance: 'legacy',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE,
		clearable: true
	}
});

fieldsMap.set('booking_date_to', {
	key: 'booking_date_to',
	name: 'Prenotazioni fino al',
	appearance: 'legacy',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE,
		clearable: true
	}
});

fieldsMap.set('departure_date_from', {
	key: 'departure_date_from',
	name: 'Partenze dal',
	appearance: 'legacy',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE,
		clearable: true
	}
});

fieldsMap.set('departure_date_to', {
	key: 'departure_date_to',
	name: 'Partenze fino al',
	appearance: 'legacy',
	valueType: ValueTypes.DATE,
	inputConfig: {
		type: InputTypes.DATE,
		clearable: true
	}
});

export default fieldsMap;
