import {Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef, Input} from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import {DatasetActionContainerService} from 'app/main/components/dataset/services/dataset-action-container.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subject, Observable} from 'rxjs';
import {AppService} from 'app/services/app.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DatasetService } from '../../services/dataset.service';
import { formGroupToFormData } from 'app/helpers/field.helper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

export interface IUploadPdfeDialogDataOptions{

}
export interface IUploadPdfDialogData {
	title?: string;
	message?: string;
	postData?: any;
	record?: any;
	route: string;
	fileType: string;
	negativeText?: string;
	positiveText?: string;
	pdfOptions?: IUploadPdfeDialogDataOptions;
}

@Component({
	selector: 'upload-pdf-dialog',
	templateUrl: 'dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})

export class UploadPdfDialogComponent implements OnDestroy, OnInit {

	public pdfChangedEvent: any = '';
	public originalFile: any = null;
	public uploadRoute: any = '';
	public ticketText: string = null;
	public fileType : string = null;
	public loading = false;
	protected _unsubscribeAll: Subject<any>;
	public formGroup: FormGroup;
	protected dragging = false;
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	@ViewChild('textInput', { static: false }) textInput: string;
	@Input() public selectedFileName: string;
	@Input() public route;

	

	

	constructor(
		public appService: AppService,
		public dialogRef: MatDialogRef<UploadPdfDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IUploadPdfDialogData,
		public datasetService: DatasetService,
		public sanitizer: DomSanitizer,
		private fuseTranslationLoader: FuseTranslationLoaderService,
		) {
		// carica traduzioni
		//this.fuseTranslationLoader.loadTranslations(english, italiano);
		this._unsubscribeAll = new Subject();

	}

	ngOnInit(): void{
		this.fileType = this.data.fileType || 'application/pdf';

		this.formGroup = new FormGroup({
			file: new FormControl(null)
		});
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	onUpload(): void {
		this.loading = true;
		const formData = formGroupToFormData(this.formGroup);
		if(this.data.postData){
			for (const key in this.data.postData) {
				if (!Object.prototype.hasOwnProperty.call(this.data.postData, key)) continue;
				const value = this.data.postData[key];
				formData.set(key, value);
			}
		}
		this.datasetService.post(this.data.route, formData)
		.subscribe({
			next: response => {
				this.dialogRef.close(response);
			},
			error: response => {
				this.loading = false;
			}
		});
		
	}
	
	onConfirmUpload(): void {
		if(this.fileType === 'application/pdf'){
			if(this.originalFile !== null)
			{
				this.loading = true;
				const formData = formGroupToFormData(this.formGroup);
				this.data.postData.fileType = this.fileType;
				if(this.data.postData){
					for (const key in this.data.postData) {
						if (!Object.prototype.hasOwnProperty.call(this.data.postData, key)) continue;
						const value = this.data.postData[key];
						formData.set(key, value);
					}
				}
				this.datasetService.post(this.data.route, formData)
				.subscribe({
					next: response => {
						this.dialogRef.close(response);
						this.appService.showSuccessMessage('File caricato correttamente.');
					},
					error: response => {
						this.loading = false;
						this.appService.showErrorMessage('Problema nel caricamento del file.');
					}
				});
			}
			else{
				this.appService.showErrorMessage('Nessun file caricato.');
			}		
		}
		if(this.fileType === 'text'){
			if(this.ticketText !== null && this.ticketText!== ''){
				this.loading = true;
				const formData = formGroupToFormData(this.formGroup);
				this.data.postData.text = this.ticketText;
				this.data.postData.fileType = this.fileType;
				if(this.data.postData){
					for (const key in this.data.postData) {
						if (!Object.prototype.hasOwnProperty.call(this.data.postData, key)) continue;
						const value = this.data.postData[key];
						formData.set(key, value);
					}
				}
				this.datasetService.post(this.data.route, formData)
				.subscribe({
					next: response => {
						this.dialogRef.close(response);
						this.data.record.value.note = this.ticketText;
						this.appService.showSuccessMessage('Testo caricato correttamente.');
					},
					error: response => {
						this.loading = false;						
						this.appService.showErrorMessage('Testo non aggiornato.');
					}
				});
			}
			else{
				this.appService.showErrorMessage('Testo non valido.');
			}
		
		}
					
	}

	ngOnDestroy(): void{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	onFileDragStart(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDragLeave(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
	}

	onFileDragOver(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = true;
	}

	onFileDrop(event): void{
		event.preventDefault();
		event.stopPropagation();
		this.dragging = false;
		let file = null;
		if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
			file = event.dataTransfer.items[0].getAsFile();
		}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
			file = event.dataTransfer.files[0];
		}
		if(file){
			this.selectedFileName = file.name;
			
		}else{
			this.selectedFileName = null;
		}

		this.originalFile = file;
		if(file) this.pdfChangedEvent = { target: { files: [file]} };
		else this.pdfChangedEvent = null;
		
		this.readURL(event, true);
	}

	onFileChange(event): void{
		if(event.target.files.length > 0) {
			const file = event.target.files[0];
			if(file){
				this.selectedFileName = file.name;
			}else{
				this.selectedFileName = null;
			}

			this.originalFile = file;
			this.pdfChangedEvent = file ? {target: {files: [file]}} : null;
			// this.formGroup.get('file').setValue(file);
		}else{
			this.pdfChangedEvent = null;
			this.originalFile = null;
			this.selectedFileName = null;
			// this.formGroup.get('file').setValue(null);
		}
		this.readURL(event);
	}

	onTextChange(event): void{
		const target = event.target as HTMLInputElement;
		this.ticketText = target.value;

	}

	clearFile(): void{
		this.formGroup.get('file').setValue(null);
		if(this.fileInput){
			this.fileInput.nativeElement.value = null;
		}
		this.originalFile = null;
		this.selectedFileName = null;
		this.pdfChangedEvent = null;
	}

	readURL(event, drop?: boolean): void {
		if (!drop) {
			if (event.target.files && event.target.files[0]) {
				const file = event.target.files[0];
				const type = file.type;
		
				const reader = new FileReader();

				reader.readAsDataURL(file);
				this.formGroup.get('file').setValue(file);
			}else{
				this.formGroup.get('file').setValue(null);
			}
		} else {
			let file = null;
			if(event.dataTransfer.items && event.dataTransfer.items.length > 0){
				file = event.dataTransfer.items[0].getAsFile();
			}else if(event.dataTransfer.files && event.dataTransfer.files.length > 0){
				file = event.dataTransfer.files[0];
			}
			if(file){
				const reader = new FileReader();
				const type = file.type;

				reader.readAsDataURL(file);
				this.formGroup.get('file').setValue(file);
			}else{
				this.formGroup.get('file').setValue(null);
			}
		}		
	}

	loadPdfFailed(): void{
		this.appService.showErrorMessage('Impossibile caricare il pdf');
	}

	
}
