/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passport.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i3 from "../../../../../@fuse/services/config.service";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/router";
import * as i6 from "../../../components/dataset/base-actions/list/dataset-list.component.ngfactory";
import * as i7 from "../../../components/dataset/services/dataset-action-container.service";
import * as i8 from "../../../../services/app.service";
import * as i9 from "../../page-closure-container.service";
import * as i10 from "../../../../services/auth.service";
import * as i11 from "../../../components/dataset/services/dataset.service";
import * as i12 from "../../../../services/domain-filter.service";
import * as i13 from "@ngx-translate/core";
import * as i14 from "../../../components/dataset/base-actions/list/dataset-list.service";
import * as i15 from "../../../components/dataset/base-actions/list/dataset-list.component";
import * as i16 from "../../../components/dataset/services/dataset-navigator.service";
import * as i17 from "../../../../../@fuse/services/translation-loader.service";
import * as i18 from "@angular/material/snack-bar";
import * as i19 from "../../../../services/websocket.service";
import * as i20 from "./passport.component";
var styles_PassportComponent = [i0.styles];
var RenderType_PassportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassportComponent, data: {} });
export { RenderType_PassportComponent as RenderType_PassportComponent };
export function View_PassportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-layout blank p-24"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.FusePerfectScrollbarDirective, [i1.ElementRef, i3.FuseConfigService, i4.Platform, i5.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "dataset-list", [], null, null, null, i6.View_DatasetListComponent_0, i6.RenderType_DatasetListComponent)), i1.ɵprd(512, null, i7.DatasetActionContainerService, i7.DatasetActionContainerService, [i8.AppService, i9.PageClosureContainerService, i10.AuthService, i11.DatasetService, i12.DomainFilterService, i13.TranslateService, i1.Injector]), i1.ɵprd(512, null, "ListParamService", i14.DatasetListService, [i7.DatasetActionContainerService, i11.DatasetService]), i1.ɵdid(5, 770048, null, 0, i15.DatasetListComponent, [i5.ActivatedRoute, i11.DatasetService, i5.Router, i10.AuthService, i8.AppService, i7.DatasetActionContainerService, i16.DatasetNavigatorService, i12.DomainFilterService, "ListParamService", i17.FuseTranslationLoaderService, i18.MatSnackBar, i19.WebSocketService], { autoFetchData: [0, "autoFetchData"], showTitle: [1, "showTitle"], datasetCode: [2, "datasetCode"], enableCreate: [3, "enableCreate"], elevate: [4, "elevate"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = false; var currVal_2 = true; var currVal_3 = "passport"; var currVal_4 = true; var currVal_5 = false; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PassportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "passport-page", [], null, null, null, View_PassportComponent_0, RenderType_PassportComponent)), i1.ɵprd(512, null, i9.PageClosureContainerService, i9.PageClosureContainerService, [i8.AppService]), i1.ɵdid(2, 114688, null, 0, i20.PassportComponent, [i9.PageClosureContainerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PassportComponentNgFactory = i1.ɵccf("passport-page", i20.PassportComponent, View_PassportComponent_Host_0, {}, {}, []);
export { PassportComponentNgFactory as PassportComponentNgFactory };
