<div class='full-form'>
	<mat-progress-bar class='mt-24 mb-24' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
	
	<form autocomplete="off"
		name='form' 
		*ngIf='formViewerService.initialized && formViewerService.formGroup' 
		[formGroup]="formViewerService.formGroup" 
		fxLayout="column" 
		fxLayoutAlign="start"
		fxLayoutAlign.lt-lg='stretch'>

		<div class="section product-reference">
			<label class="input-label section-title"><b>Quantità prodotto</b></label>

			<div class="base-textfield base-textfield-large">
				<form-input-viewer
					[formInputDefinition]='fileterFields.get("quantity")'
					[formGroup]='formViewerService.formGroup'
					[formControlKey]='formViewerService.controlKeyMap["quantity"]'
					[dataContainerService]='formViewerService.dataContainerService'
					[data]='formViewerService.record'
				></form-input-viewer>
			</div>
		</div>

		<div class="save-button-container">
			<button [disabled]='formViewerService.formGroup.invalid && !formErrors || datasetACS.loading.value' class="gyg-button gyg-button-medium gyg-button-cta-light" (click)='onSave()'>
				<span>Salva e continua</span>
			</button>
		</div>
	</form>

</div>