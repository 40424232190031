import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import {BaseDatasetPageComponent} from '../base-dataset-page.component';
import { PageClosureContainerService } from '../../page-closure-container.service';
import { takeUntil } from 'rxjs/operators';
import { IDomainChangeHandler } from 'app/interfaces/domain-change-handler.interface';

@Component({
	selector   : 'default-resource-page',
	templateUrl: './default-page.component.html',
	styleUrls  : ['./default-page.component.scss'],
	providers : [ PageClosureContainerService ]
})
export class DefaultDatasetPageComponent extends BaseDatasetPageComponent implements OnInit, IDomainChangeHandler{

	public sharedData: any;

	constructor(
		protected route: ActivatedRoute,
		protected datasetService: DatasetService,
		protected dialog: MatDialog,
		protected router: Router,
		protected pageClosureContainerService: PageClosureContainerService
	){
		super(route, datasetService, dialog, router, pageClosureContainerService);
	}

	ngOnInit(): void{
		super.ngOnInit();

		this.pageClosureContainerService.sharedData
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(sharedData => {
			this.sharedData = sharedData;
		});
	}

	handleDomainChanges(): boolean{
		return this.actionCode === 'list';
	}

	get styleClassDataset() : string {
		const datasetCodes = ['trip_packages'];
		return datasetCodes.includes(this.datasetCode) ? 'style-01' : '';
	}
}
