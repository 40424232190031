<div class="mx-16">
	<mat-progress-bar
		class="mt-24 mb-24"
		style="min-width:300px"
		mode="indeterminate"
		*ngIf="datasetACS.loading.value || datasetRS.loading.value"
	></mat-progress-bar>

	<div *ngIf='!datasetRS.record.value.external_site_user_reference' class='mt-16' fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap='10px'>
		<mat-card fxFlex class="mat-elevation-z0">
			<mat-card-content>
				<span class='h3'>{{'Utente B2B' | translate}}</span>
				<br>
				<button mat-button mat-flat-button color='accent' (click)='createB2BCustomer()'>Crea utente B2B</button>
			</mat-card-content>
		</mat-card>
	</div>
	<div *ngIf='datasetRS.record.value.external_site_user_reference' class='mt-16' fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap='10px'>
		<mat-card fxFlex class="mat-elevation-z0">
			<mat-card-content>
				<span class='h3'>{{'Utente B2B' | translate}}</span>
				<br>
				<table class='text-left'>
					<tr>
						<th><strong>{{'Id utente' | translate}}: </strong></th>
						<td>{{datasetRS.record.value.external_site_user_reference}}</td>
					</tr>
					<tr>
						<th><strong>Username: </strong></th>
						<td>{{datasetRS.record.value.external_site_user_name}}</td>
					</tr>
					<tr>
						<th><strong>{{'Creato il' | translate}}: </strong></th>
						<td>{{datasetRS.record.value.external_site_user_created_at | date}}</td>
					</tr>
				</table>
				<div class="mb-24">
					<!-- delete user b2b from WordPress-->
					<button mat-button mat-flat-button color='warn' (click)='deleteUserB2b()'>Elimina utente B2B</button>
				</div>

				<div fxLayout='row' fxLayoutAlign="start start" fxLayoutGap='10px'>
					<button mat-button mat-flat-button color='accent' [matMenuTriggerFor]="menu">Cambia modalità pacchetti</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item (click)='changeListMode(0)'>Disabilita tutti</button>
						<button mat-menu-item (click)='changeListMode(2)'>Abilità tutti</button>
						<button mat-menu-item (click)='changeListMode(1)'>Filtra pacchetti</button>
						<button mat-menu-item (click)='changeListMode(3)'>Filtra per categoria</button>
					</mat-menu>
				</div>
				
				<ng-container *ngIf='datasetRS.record.value.customer_data && datasetRS.record.value.customer_data.trip_package_list_mode == 0'>
					<p>{{'Tutti i pacchetti sono disabilitati per questo cliente' | translate}}</p>
				</ng-container>

				<ng-container *ngIf='datasetRS.record.value.customer_data && datasetRS.record.value.customer_data.trip_package_list_mode == 2'>
					<p>{{'Tutti i pacchetti sono abilitati per questo cliente' | translate}}</p>
				</ng-container>

				<ng-container *ngIf='datasetRS.record.value.customer_data && datasetRS.record.value.customer_data.trip_package_list_mode == 1'>
					<dataset-list
						datasetCode='entity_customer_trip_packages'
						[parentDatasetACS]='datasetACS'
						[parentDatasetRS]='datasetRS'
						[showTitle]="false"
						[autoFetchData]="true"
						[filters]='{entity_customer_id: datasetRS.record.value.entities_data.customer}'
					></dataset-list>
				</ng-container>

				<ng-container *ngIf='datasetRS.record.value.customer_data && datasetRS.record.value.customer_data.trip_package_list_mode == 3'>
					<dataset-list
						datasetCode='entity_customer_trip_categories'
						[parentDatasetACS]='datasetACS'
						[parentDatasetRS]='datasetRS'
						[showTitle]="false"
						[autoFetchData]="true"
						[filters]='{entity_customer_id: datasetRS.record.value.entities_data.customer}'
					></dataset-list>
				</ng-container>

			</mat-card-content>
		</mat-card>
	</div>
</div>
