import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { FormControl } from '@angular/forms';
import { IFormConfig, IFieldContainer, IFieldDefinition, isFieldContainer, ICustomAction } from 'app/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'app/main/dialogs/form-dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatasetActionContainerService } from '../dataset/services/dataset-action-container.service';
var DataFilterComponent = /** @class */ (function () {
    function DataFilterComponent(matDialog, datasetNavigatorService, viewContainerRef) {
        this.matDialog = matDialog;
        this.datasetNavigatorService = datasetNavigatorService;
        this.viewContainerRef = viewContainerRef;
        this.filterChange = new EventEmitter();
        this.showSearchText = true;
        this.loading = false;
        this.filterCount = 0;
    }
    DataFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._unsubscribeAll = new Subject();
        this.defaultSearchControl = new FormControl('');
        this.defaultSearchControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.applyFilter(null);
        });
    };
    DataFilterComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    DataFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filtersData && this.formConfig) {
            this.filterCount = 0;
            for (var key in this.filtersData) {
                if (!Object.prototype.hasOwnProperty.call(this.filtersData, key))
                    continue;
                if (key === 'text-search')
                    continue;
                if (!this.isFieldInConfig(key, this.formConfig.fields))
                    continue;
                var element = this.filtersData[key];
                if (element !== null && element !== undefined && element !== '') {
                    this.filterCount++;
                }
            }
        }
    };
    DataFilterComponent.prototype.isFieldInConfig = function (key, fields) {
        var e_1, _a;
        try {
            for (var fields_1 = tslib_1.__values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                var field = fields_1_1.value;
                if (isFieldContainer(field)) {
                    if (this.isFieldInConfig(key, field.fields))
                        return true;
                }
                else {
                    if (field.key === key)
                        return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    DataFilterComponent.prototype.applyFilter = function (filters) {
        var oldFilters = this.filtersData;
        var newFilters = {
            'text-search': this.defaultSearchControl.value
        };
        if (filters) {
            newFilters = Object.assign(newFilters, JSON.parse(JSON.stringify(filters)));
        }
        newFilters = Object.assign({}, oldFilters, newFilters);
        for (var key in newFilters) {
            if (!Object.prototype.hasOwnProperty.call(newFilters, key))
                continue;
            if (newFilters[key] === null || newFilters[key] === undefined || newFilters[key] === '') {
                delete newFilters[key];
            }
        }
        this.filterChange.emit(newFilters);
    };
    DataFilterComponent.prototype.openFilters = function () {
        var _this = this;
        this.matDialog.open(FormDialogComponent, {
            width: '700px',
            data: {
                title: 'Filtri ricerca',
                formData: this.filtersData,
                formConfig: this.formConfig,
                dataContainerService: this.dataContainerService,
                positiveText: 'Applica'
            },
            viewContainerRef: this.viewContainerRef
        })
            .afterClosed().subscribe(function (result) {
            if (result) {
                _this.applyFilter(result);
            }
        });
    };
    DataFilterComponent.prototype.clearFilters = function () {
        var emptyFilters = {};
        if (this.formConfig) {
            for (var key in this.filtersData) {
                if (!Object.prototype.hasOwnProperty.call(this.filtersData, key))
                    continue;
                if (key === 'text-search')
                    continue;
                if (!this.isFieldInConfig(key, this.formConfig.fields))
                    continue;
                emptyFilters[key] = null;
            }
        }
        this.defaultSearchControl.setValue(null);
        this.applyFilter(emptyFilters);
    };
    DataFilterComponent.prototype.indexTrackBy = function (index, item) {
        return index;
    };
    return DataFilterComponent;
}());
export { DataFilterComponent };
