import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseStepViewComponent } from '../../../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import createB2BCustomer from 'app/configs/datasets/entities/create-b2b-customer.action';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from '../i18n/en';
import { locale as italiano } from '../i18n/it';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
var EntityB2BStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EntityB2BStepComponent, _super);
    function EntityB2BStepComponent(appService, datasetRS, datasetACS, datasetNavigatorService, datasetService, fuseTranslationLoader) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.datasetService = datasetService;
        _this.fuseTranslationLoader = fuseTranslationLoader;
        // carica traduzioni
        _this.fuseTranslationLoader.loadTranslations(english, italiano);
        return _this;
    }
    EntityB2BStepComponent.prototype.ngOnInit = function () {
    };
    EntityB2BStepComponent.prototype.createB2BCustomer = function () {
        var _this = this;
        createB2BCustomer(this.datasetRS.record.value, this.datasetACS, this.datasetNavigatorService, null)
            .then(function (created) {
            if (created)
                _this.datasetRS.reload();
        })
            .catch(function (e) {
            console.error(e);
        });
    };
    EntityB2BStepComponent.prototype.changeListMode = function (newMode) {
        var _this = this;
        var loadingDialogRef = this.datasetNavigatorService.openDialog(LoadingDialogComponent, {
            title: 'Modifica in corso',
        });
        this.datasetNavigatorService.datasetService
            .post('/dataset/entities/command/set_trip_package_list_mode', {
            entity_id: this.datasetRS.recordId,
            trip_package_list_mode: newMode
        }).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetRS.reload();
                _this.datasetNavigatorService.appService.showSuccessMessage(response.message || 'Modifica avvenuta con successo');
                loadingDialogRef.close();
            },
            error: function (response) {
                _this.datasetNavigatorService.appService.showErrorMessage('Si è verificato un errore inaspettato');
                loadingDialogRef.close();
            }
        });
    };
    EntityB2BStepComponent.prototype.deleteUserB2b = function () {
        var _this = this;
        var loadingDialogRef = this.datasetNavigatorService.openDialog(LoadingDialogComponent, {
            title: 'Eliminazione in corso',
        });
        this.datasetNavigatorService.datasetService
            .post('/dataset/entities/command/delete_site_user', {
            entity_id: this.datasetRS.recordId,
        }).pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (response) {
                _this.datasetRS.reload();
                _this.datasetNavigatorService.appService.showSuccessMessage(response.message || 'Eliminazione avvenuta con successo');
                loadingDialogRef.close();
            },
            error: function (response) {
                _this.datasetNavigatorService.appService.showErrorMessage('Si è verificato un errore inaspettato');
                loadingDialogRef.close();
            }
        });
    };
    return EntityB2BStepComponent;
}(BaseStepViewComponent));
export { EntityB2BStepComponent };
