import { Component, OnInit } from '@angular/core';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { FieldHelper } from 'app/helpers';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { ValueTypes } from 'app/interfaces';
import { DestroyableComponent } from 'app/main/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Component({
	selector   : 'readonly-input',
	templateUrl: './input.component.html',
})
export class ReadOnlyInputComponent extends DestroyableComponent implements OnInit{

	public loading = false;
	public computedValue: string;
	public show = true;

	constructor(
		public inputService: FormInputViewerService,
		public datasetService: DatasetService
	){
		super();
	}

	ngOnInit(): void{
		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(value => {
			this.computeValue();
		});
	}

	computeValue(): void{
		if(typeof(this.inputService.formInputDefinition.skipIf) == 'function' && this.inputService.formInputDefinition.skipIf(this.inputService.data, this.inputService.dataContainerService)){
			this.show = false;
		}else{
			this.show = true;
		}

		if(this.inputService.formInputDefinition.valueType === ValueTypes.REFERENCE && typeof(this.inputService.formInputDefinition.getDisplayValue) === 'function'){
			this.computedValue = null;
			const value = FieldHelper.getFieldValue(this.inputService.formInputDefinition, this.inputService.data, null, this.inputService.dataContainerService);
			if(!value){
				this.computedValue = null;
				return;
			}
			this.loading = true;
			let headers = new HttpHeaders({});
			if(this.inputService.dataContainerService && this.inputService.dataContainerService.hasValueInKeyPath('contextual_domain_id')){
				headers = headers.append('X-Domain-Id', this.inputService.dataContainerService.getValueFromKeyPath('contextual_domain_id'));
			}
			this.datasetService.get('/dataset/' + this.inputService.formInputDefinition.datasetCode + '/detail/' + value, {}, headers)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe({
				next: referenceRecord => {
					if(typeof(this.inputService.formInputDefinition.getDisplayValue) === 'function'){
						this.computedValue = this.inputService.formInputDefinition.getDisplayValue(this.inputService.data, referenceRecord);
					}
					this.loading = false;
				},
				error: response => {
					this.loading = false;
				}
			});
		}else{
			this.computedValue = FieldHelper.getDisplayFieldValue(this.inputService.formInputDefinition, this.inputService.data, undefined, this.inputService.dataContainerService);
		}
	}

}
