import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DatasetActionContainerService, DatasetEvents } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseStepViewComponent } from 'app/main/components/dataset/components/common-step/base-step-view.class';
import { takeUntil } from 'rxjs/operators';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { fieldsMap } from 'app/configs/datasets/availabilities/fields';
import { Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AvailabilitiesConfig } from 'app/configs/datasets/availabilities';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import { HttpHeaders } from '@angular/common/http';
var AvailabilityStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AvailabilityStepComponent, _super);
    function AvailabilityStepComponent(appService, datasetRS, datasetACS, datasetService, datasetNavigatorService, matDialog, stepService, formBuilder) {
        var _this = _super.call(this, datasetACS, datasetNavigatorService) || this;
        _this.appService = appService;
        _this.datasetRS = datasetRS;
        _this.datasetACS = datasetACS;
        _this.datasetService = datasetService;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.matDialog = matDialog;
        _this.stepService = stepService;
        _this.formBuilder = formBuilder;
        _this.DAYS = [
            { code: 'monday', day: 'Monday' },
            { code: 'tuesday', day: 'Tuesday' },
            { code: 'wednesday', day: 'Wednesday' },
            { code: 'thursday', day: 'Thursday' },
            { code: 'friday', day: 'Friday' },
            { code: 'saturday', day: 'Saturday' },
            { code: 'sunday', day: 'Sunday' }
        ];
        _this.showForm = false;
        _this.showCreatedAlert = false;
        _this.fileterFields = fieldsMap;
        _this.scenario = new BehaviorSubject('go-to-dataset');
        return _this;
    }
    AvailabilityStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.avilabilitiesViewConfig = AvailabilitiesConfig.defaultActions.list.viewConfig;
        this.initFormGroup();
        this.datasetACS.init({
            datasetCode: 'availability',
            actionCode: 'list',
            datasetRS: this.datasetRS,
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
            fetchData: false
        }).subscribe(function (ready) {
            if (!ready)
                return;
            _this.stepService.reloadOption()
                .subscribe(function (record) {
                if (record && record.availability) {
                    _this.onSelected(record.availability);
                }
                else
                    _this.showForm = true;
            });
        });
    };
    AvailabilityStepComponent.prototype.resetForm = function (scenario) {
        this.scenario.next(scenario);
        this.showForm = true;
        this.datasetRS.recordId = null;
        this.datasetRS.record.next(null);
        this.stepService.selectedAvailability.next(null);
        this.initFormGroup();
    };
    AvailabilityStepComponent.prototype.initFormGroup = function () {
        var _this = this;
        this.formGroup = this.formBuilder.group({
            description: new FormControl('', Validators.required),
            valid_from: new FormControl(''),
            valid_to: new FormControl(''),
            selected_option_id: new FormControl(this.stepService.selectedOptionId, Validators.required),
            max_allotment: new FormControl(''),
            monday: this.formBuilder.array([]),
            tuesday: this.formBuilder.array([]),
            wednesday: this.formBuilder.array([]),
            thursday: this.formBuilder.array([]),
            friday: this.formBuilder.array([]),
            saturday: this.formBuilder.array([]),
            sunday: this.formBuilder.array([]),
            spec: this.formBuilder.array([])
        });
        this.formGroup.get('selected_option_id').valueChanges
            .subscribe(function (newVal) {
            if (!newVal || newVal && newVal == _this.stepService.selectedOptionId)
                return;
            /** else load */
            _this.showForm = false;
            _this.stepService.selectedOptionId = newVal;
            _this.stepService.reloadOption()
                .subscribe(function (record) {
                if (record && record.availability) {
                    _this.onSelected(record.availability);
                }
                else
                    _this.resetForm('');
            });
        });
    };
    AvailabilityStepComponent.prototype.onSelected = function (selectedRecord) {
        var _this = this;
        this.scenario.next('edit');
        this.datasetRS.recordId = selectedRecord.id;
        this.stepService.selectedAvailability.next(selectedRecord);
        this.showForm = false;
        this.datasetACS.loading.next(true);
        this.datasetRS.reload()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: function (record) {
                _this.formGroup.get('description').setValue(record.description);
                _this.formGroup.get('valid_from').setValue(record.valid_from);
                _this.formGroup.get('valid_to').setValue(record.valid_to);
                _this.formGroup.get('max_allotment').setValue(record.max_allotment);
                var days = Object.keys(record.days);
                days.forEach(function (value) {
                    var dayTimes = record.days[value];
                    var dayForm = _this.days(value);
                    for (var dayTime in dayTimes) {
                        if (!Object.prototype.hasOwnProperty.call(dayTimes, dayTime))
                            continue;
                        var timeObj = dayTimes[dayTime];
                        var startingTimeFormGroup = _this.formBuilder.group({
                            starting_time: new FormControl(timeObj.starting_time, Validators.required)
                        });
                        dayForm.push(startingTimeFormGroup);
                    }
                });
                for (var specialDate in record.special_date_times) {
                    if (!Object.prototype.hasOwnProperty.call(record.special_date_times, specialDate))
                        continue;
                    var formArray = _this.formGroup.get('spec');
                    var formGroupArray = [];
                    var specialDateTime = record.special_date_times[specialDate];
                    for (var startingTime in specialDateTime.special_date_starting_time) {
                        if (!Object.prototype.hasOwnProperty.call(specialDateTime.special_date_starting_time, startingTime))
                            continue;
                        var time = specialDateTime.special_date_starting_time[startingTime];
                        var starting_time = null;
                        if (time.starting_time)
                            starting_time = time.starting_time;
                        var fgItem = _this.formBuilder.group({
                            starting_time: new FormControl(starting_time, Validators.required)
                        });
                        formGroupArray.push(fgItem);
                    }
                    formArray.push(_this.formBuilder.group({
                        special_date: new FormControl(specialDateTime.special_date, Validators.required),
                        special_date_starting_time: _this.formBuilder.array(formGroupArray),
                    }));
                }
                _this.showForm = true;
                _this.datasetACS.loading.next(false);
            },
            error: function (response) {
                console.error(response);
                _this.datasetACS.loading.next(false);
                _this.showForm = true;
            }
        });
    };
    AvailabilityStepComponent.prototype.addSpecialDate = function () {
        var specialDates = this.formGroup.get('spec');
        var specialDateFormGroup = this.formBuilder.group({
            special_date: new FormControl('', Validators.required),
            special_date_starting_time: this.formBuilder.array([
                this.formBuilder.group({
                    starting_time: new FormControl('', Validators.required)
                })
            ]),
        });
        specialDates.push(specialDateFormGroup);
    };
    AvailabilityStepComponent.prototype.addSpecialDateStartingTime = function (formArray) {
        var specialTimeFormGroup = this.formBuilder.group({ starting_time: new FormControl('', Validators.required) });
        formArray.push(specialTimeFormGroup);
    };
    AvailabilityStepComponent.prototype.removeSpecialTime = function (formArray, i) {
        formArray.removeAt(i);
    };
    AvailabilityStepComponent.prototype.removeBlockSpecialDates = function (i) {
        var specialDates = this.formGroup.get('spec');
        specialDates.removeAt(i);
    };
    AvailabilityStepComponent.prototype.addStartingTime = function (day) {
        var dayForm = this.days(day);
        var startingTimeFormGroup = this.formBuilder.group({ starting_time: new FormControl('', Validators.required) });
        dayForm.push(startingTimeFormGroup);
    };
    AvailabilityStepComponent.prototype.copyToWholeWeek = function (day) {
        var _this = this;
        var currentDayForm = this.days(day);
        this.DAYS.forEach(function (value) {
            var cleared = false;
            if (day === value.code)
                return;
            var dayForm = _this.days(value.code);
            if (!dayForm.length)
                cleared = true;
            try {
                while (dayForm.length) {
                    dayForm.removeAt(dayForm.length - 1);
                    cleared = true;
                }
            }
            catch (Error) {
                cleared = false;
            }
            if (cleared) {
                currentDayForm.controls.forEach(function (formGroup) {
                    dayForm.push(_this.formBuilder.group({ starting_time: formGroup.controls.starting_time.value }));
                });
            }
        });
    };
    AvailabilityStepComponent.prototype.removeAt = function (day, index) {
        this.days(day).removeAt(index);
    };
    AvailabilityStepComponent.prototype.days = function (day) {
        return this.formGroup.get(day);
    };
    AvailabilityStepComponent.prototype.delete = function () {
        var _this = this;
        this.scrollToTop();
        this.datasetNavigatorService.openDeleteDialog(this.datasetACS, this.stepService.selectedAvailability.value);
        this.datasetACS.datasetEvent.subscribe(function (event) {
            if (event.eventName !== DatasetEvents.DELETED)
                return;
            _this.stepService.selectedAvailability.next(null);
            _this.scenario.next('go-to-dataset');
            _this.initFormGroup();
            _this.appService.appEvent.subscribe(function (appEvent) {
                if (appEvent.name !== 'total_dataset_items')
                    return;
                if (appEvent.extra.paginationResponse.meta.total)
                    return;
                _this.stepService.setStepState({
                    dataset_code: 'trip_packages',
                    form_code: 'avilabilities',
                    record_id: _this.parentDatasetRS.recordId,
                    status: 0
                });
            });
        });
    };
    AvailabilityStepComponent.prototype.onSave = function () {
        var _this = this;
        this.datasetACS.loading.next(true);
        this.formGroup.disable();
        var formData = this.formGroup.getRawValue();
        var baseRoute = '/dataset/' + this.datasetACS.datasetConfig.ajaxDatasetCode;
        var route = baseRoute + '/create';
        if (this.datasetRS.record.value) {
            route = baseRoute + '/update/' + this.datasetRS.recordId;
        }
        else
            formData['option_id'] = this.stepService.selectedOptionId;
        var headers = new HttpHeaders({});
        if (this.datasetACS.hasValueInKeyPath('contextual_domain_id')) {
            headers = headers.append('X-Domain-Id', this.datasetACS.getValueFromKeyPath('contextual_domain_id'));
        }
        this.datasetService.post(route, formData, { headers: headers })
            .subscribe({
            next: function (record) {
                _this.appService.showSuccessMessage('operazione effettuata con successo');
                _this.scrollToTop();
                if (_this.formGroup) {
                    _this.formGroup.enable();
                }
                if (record) {
                    _this.scenario.next('go-to-dataset');
                    _this.datasetRS.record.next(record);
                    _this.datasetACS.loading.next(false);
                    //this.initFormGroup();
                    _this.datasetACS.datasetEvent.next({ eventName: _this.datasetRS.recordId ? DatasetEvents.EDITED : DatasetEvents.CREATED });
                }
                _this.stepService.setStepState({
                    dataset_code: 'trip_packages',
                    form_code: 'avilabilities',
                    record_id: _this.parentDatasetRS.recordId,
                    status: 1
                });
            },
            error: function (response) {
                if (response && response.error && response.error.errors)
                    _this.formErrors = response.error.errors;
                else
                    _this.formErrors = null;
                if (_this.formGroup) {
                    _this.formGroup.enable();
                    for (var error in _this.formErrors) {
                        if (!Object.prototype.hasOwnProperty.call(_this.formErrors, error))
                            continue;
                        var fieldError = _this.formErrors[error];
                        var formControl = _this.formGroup.get(error);
                        formControl.setErrors(fieldError[0]);
                        formControl.markAsTouched();
                    }
                }
                _this.datasetACS.loading.next(false);
            }
        });
    };
    return AvailabilityStepComponent;
}(BaseStepViewComponent));
export { AvailabilityStepComponent };
