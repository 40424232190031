import { Component, OnInit, OnDestroy, Inject, SkipSelf, Self } from '@angular/core';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { AppService } from 'app/services/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ATripBookingPackageRelatedComponent, ITripBookingPackageRelatedDataDialogData } from '../abstract-trip-booking-package-related-data.component';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';

@Component({
	selector   : 'trip-booking-resource',
	templateUrl: './trip-booking-resource.component.html',
	styleUrls: ['./trip-booking-resource.component.scss'],
	providers: [
		DatasetActionContainerService,
		DatasetRecordService,
		//PageClosureContainerService
	]
})
export class TripBookingResourceComponent extends ATripBookingPackageRelatedComponent implements OnInit{

	constructor(
		public appService: AppService,
		@Self()
		public datasetACS: DatasetActionContainerService,
		@Self()
		public datasetRS: DatasetRecordService,
		@SkipSelf()
		public parentDatasetACS: DatasetActionContainerService,
		@SkipSelf()
		public parentDatasetRS: DatasetRecordService,
		public bookingService: BookingService,
		public matDialogRef: MatDialogRef<ITripBookingPackageRelatedDataDialogData>,
		@Inject(MAT_DIALOG_DATA) public data: ITripBookingPackageRelatedDataDialogData
	){
		super(appService, datasetACS, datasetRS, parentDatasetACS, parentDatasetRS, matDialogRef, data);
	}

	get tripBookingPackages(): any[]{
		let tripBookingPackages = [this.tripBookingPackage];

		if(this.tripBookingPackage.children_trip_booking_packages){
			tripBookingPackages = tripBookingPackages.concat(this.tripBookingPackage.children_trip_booking_packages);
		}

		return tripBookingPackages;
	}
}
