import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('meeting_point.code'),
	fieldsMap.get('meeting_point.description'),
	fieldsMap.get('pivot.time')
];

const config: IFormConfig = { 
	fields: formFields 
};

export default config;
