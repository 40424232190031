import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('name'),
	fieldsMap.get('redirect'),
	fieldsMap.get('personal_access_client'),
	fieldsMap.get('password_client'),
	fieldsMap.get('revoked'),
	fieldsMap.get('user_id'),
	fieldsMap.get('domain_id')
];

const config: IFormConfig = { 
	title: 'Modifica Client',
	editTitle: 'Modifica Client',
	fields: formFields 
};

export default config;
