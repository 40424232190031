import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { LoadingDialogComponent } from 'app/main/dialogs/loading-dialog/dialog.component';
import {ActionDialogComponent} from 'app/main/pages/edit-booking/action-dialog/action-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { IDataContainerService } from 'app/interfaces/data-container.interface';

export default async function action(action: string, params: any, datasetACS: DatasetActionContainerService, datasetNavigatorService: DatasetNavigatorService): Promise<any>{
	const loadingDialogRef: MatDialogRef<ActionDialogComponent, any> = datasetNavigatorService.openDialog(ActionDialogComponent, {
		title: 'Recupero dati in corso',
	});

	try{
		const response = await datasetNavigatorService.datasetService.post<any>(action, params).toPromise();

		if(response){
			loadingDialogRef.componentInstance.loading = false;
			if (response.success){
				loadingDialogRef.componentInstance.resultStatus = 'success';
				loadingDialogRef.componentInstance.setTitle('Richiesta inviata');
				loadingDialogRef.componentInstance.setMessage('Puoi vedere lo stato delle tue richieste nella sezione apposita che puoi trovare nel menu laterale.');
			}else{
				loadingDialogRef.componentInstance.resultStatus = 'error';
				loadingDialogRef.componentInstance.setTitle('Errore');
				loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
			}
		}else{
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore');
			loadingDialogRef.componentInstance.setMessage(response.message || 'Errore imprevisto');
		}
		return response;
	}catch(e){
		const action_required = e && e.error && e.error.action_required;
		if (action_required && e.error.action_required.code === 'new-customer-data'){
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Dati dominio mancanti');
			// tslint:disable-next-line: max-line-length
			loadingDialogRef.componentInstance.setMessage('Inserisci dati del dominio: <a style="color: #f44336 !important;" href="' + document.baseURI + 'dataset/detail/domains/' + datasetACS.domainFilterService.filterDomainId.value + '?step=5" target="_blank"><b>clicca qui per accedere alla pagina di modifica dati</b></a>');
		} else if (action_required && e.error.action_required.code === 'deny-global-request'){
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore durante la richiesta globale');
			// tslint:disable-next-line: max-line-length
			loadingDialogRef.componentInstance.setMessage('Esistono già richieste in corso. Perciò non è possibile effettuare una richiesta globale.');
		} else if (action_required && e.error.action_required.code === 'deny-single-category-request' || action_required && e.error.action_required.code === 'deny-category-request'){
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore durante la richiesta');
			// tslint:disable-next-line: max-line-length
			if (e.error.action_required.code === 'deny-category-request'){
				loadingDialogRef.componentInstance.setMessage('Esiste già una richiesta per la categoria. Perciò non è possibile effettuare un\'altra richiesta per la stessa categoria.');
			} else {
				loadingDialogRef.componentInstance.setMessage('Esiste già una richiesta globale. Perciò non è possibile effettuare una richiesta singola o per categoria.');
			}
		} else {
			loadingDialogRef.componentInstance.loading = false;
			loadingDialogRef.componentInstance.resultStatus = 'error';
			loadingDialogRef.componentInstance.setTitle('Errore');
			loadingDialogRef.componentInstance.setMessage('Errore imprevisto');
		}
	}
}
