import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AppService } from './app.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectDomainDialogComponent } from 'app/main/components/dataset/dialogs/select-domain-dialog/dialog.component';
import { DomainFilterService } from './domain-filter.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app.service";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./domain-filter.service";
var DomainInstanceService = /** @class */ (function () {
    function DomainInstanceService(http, appService, matDialog, domainFilterService) {
        this.http = http;
        this.appService = appService;
        this.matDialog = matDialog;
        this.domainFilterService = domainFilterService;
    }
    DomainInstanceService.prototype.openDomainsDialog = function () {
        var dialogRef = this.matDialog.open(SelectDomainDialogComponent, {
            disableClose: true
        });
        return dialogRef;
    };
    DomainInstanceService.prototype.setDomainId = function (domainId) {
        this.recordId = domainId;
    };
    DomainInstanceService.prototype.getRecordId = function () {
        if (this.domainFilterService.filterDomainId.value) {
            return this.domainFilterService.filterDomainId.value;
        }
        if (!this.recordId)
            throwError('Valid domain not returned');
        return this.recordId;
    };
    DomainInstanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainInstanceService_Factory() { return new DomainInstanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.MatDialog), i0.ɵɵinject(i4.DomainFilterService)); }, token: DomainInstanceService, providedIn: "root" });
    return DomainInstanceService;
}());
export { DomainInstanceService };
