import { Component, OnInit, Pipe, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashBoardService } from 'app/main/pages/dashboard/dashboard.services';
import { Response } from 'selenium-webdriver/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { makeDrawValuePlugin } from '../chartjs.plugins';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from 'app/main/destroyable.component';
import chartColors from './../chart-colors';

@Component({
	selector   : 'traveldate',
	templateUrl: './traveldate.component.html',
	styleUrls: ['../dashboard.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations : fuseAnimations
})
export class TravelDateComponent extends DestroyableComponent implements OnInit{

	public travels: ChartDataSets[];
	public chartPlugins = [makeDrawValuePlugin()];
	public showLoader: boolean;

	/**
	 * Chart Travel Date
	*/
	public barChartOptions: ChartOptions = {
		responsive: true,
		scales: { 
			xAxes: [{}],
			yAxes: [
				{
					ticks: {
						callback: function (value): string{
							return Number(value).toLocaleString('US');
						}
					}
				}
			]
		},
		legend: {
			position: 'bottom',
			fullWidth: true,
			labels: {
				usePointStyle: true,
				padding: 10,
			}
		},
		tooltips: {
			cornerRadius: 8,
			xPadding: 8,
			yPadding: 6,
			caretSize: 0,
			caretPadding: 10,
			backgroundColor: '#b5b5b5',
			custom: function(tooltipModel){
				if (!tooltipModel) return;
				// disable displaying the color box;
				tooltipModel.displayColors = false;
			},
			callbacks: {
				label: function(tooltipItem, data): string {
					const n = Number(tooltipItem.yLabel);
					if(!n || isNaN(n)) return '';
					return n.toLocaleString('US');
				},
				title: function(item, data): string{
					return;
				},
			},
		},
	};
	public barChartLabels: Label[] = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
	public barChartType: ChartType = 'line';
	public barChartLegend = true;
	constructor(
		public dashboardService: DashBoardService
	){
		super();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void{
		this.dashboardService.travelDateResponse
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: (response: any) => {
				this.onData(response);
			}
		});
	}

	public onData(response: any): void{
		if(!response){
			this.showLoader = true;
			return;
		}
		const r = [];
		for(let i = 0; i < response.length; i++){
			const year = response[i].year;
			const month = response[i].month - 1;
			if (!r[year]) r[year] = [];
			if (!r[year][month]) r[year][month] = 0;
			r[year][month] += response[i].total_amount;
		}
			
		for (let y = 0; y < Object.keys(r).length; y++){
			const year = Object.keys(r)[y];
			for (let m = 0; m < 12; m++){
				if (!r[year][m]) r[year][m] = 0;
			}
		}
			
		const data: ChartDataSets[] = [];
		const years = Object.keys(r);
		for (let y = 0; y < years.length; y++){
			const year = years[y];
			if(!r[year]) continue;
			const colorIndex = years.length - y - 1;
			data.push({ 
				data: r[year], 
				label: year.toString(), 
				lineTension: 0, 
				fill: false,
				backgroundColor: chartColors[colorIndex],
				borderColor: chartColors[colorIndex],
				hoverBackgroundColor: chartColors[colorIndex],
				pointBackgroundColor: chartColors[colorIndex],
				pointHoverBackgroundColor: chartColors[colorIndex],
				pointHoverBorderColor: chartColors[colorIndex],
				pointRadius: 5,
			});
		}
		if(!data || data.length === 0){
			this.showLoader = true;
			this.travels = null;
		} else {
			this.showLoader = false;
			this.travels = data;
		}
	}
	// -----------------------------------------------------------------------------------------------------
	
}
