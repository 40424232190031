import { Directive, Input, OnInit, OnChanges } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { CdkStepper } from '@angular/cdk/stepper';

@Directive({
	selector: '[customCdkStep]',
	providers: [{ provide: CdkStep, useExisting: CdkStepDirective }]
})
export class CdkStepDirective extends CdkStep{

	@Input() public computedStep: any;

	constructor(_stepper: CdkStepper){
		super(_stepper);
	}
}
