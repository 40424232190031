import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes } from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './general-form';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/user_roles/detail/:recordId',
};

export const UserRoleConfig: IDatasetConfig = {
	name: 'user_roles',
	title: 'Ruoli',
	singleTitle: 'Ruolo',
	ajaxDatasetCode: 'user_roles',
	fieldsMap: fieldsMap,
	supportProperties: true,
	enabledActions: {
		detail: false,
		create: false,
		edit: true,
		list: true
	},
	formConfigsMap: {
		generalForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.PAGE,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: generalForm,
				actions: {
					edit: true,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: false,
				defaultSortBy: 'level',
				defaultSortDirection: 'asc',
				columns: [
					{
						title: 'Nome',
						key: 'name',
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
					},
					{
						title: 'Descrizione',
						key: 'description'
					}
				],
				actions: ['edit']
			}
		}
	},
	/*stepViewConfig: {
		isLinear: () => false,
		title: 'Permessi',
		subtitle(component: CommonStepViewContainerComponent): string{
			if(!component.record) return '';
			return component.record.description;
		},
		steps: [
			{
				title: 'Informazioni Base',
				stepperTitle: 'Informazioni <br>Base',
				component: 'common-form-step',
				options: {
					formConfig: generalForm
				}
			},
			{
				title: 'Permessi',
				stepperTitle: 'Permessi',
				component: 'user-role-permission-step',
			}
		]
	}*/
};
