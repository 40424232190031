import { InputTypes, ValueTypes, IFieldDefinition } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';


export default {
	key: 'entity_staff_element_id',
	name: 'Staff',
	inputType: InputTypes.DATASET_SEARCH,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_staff_elements',
	remoteOptions: {
		limit: 100,
		icon: 'person',
		getParams(dataContainerService: IDataContainerService, record?: any): any{
			const params = {};
			const actualTripId = dataContainerService.getValueFromKeyPath('actual_trips.recordId');
			if(actualTripId){
				params['actual_trip_id'] = actualTripId;
			}
			const listProperties = dataContainerService.getValueFromKeyPath('entity_staff_element_unified_list_items_for_actual_trips.filters.list_properties');
			if(listProperties){
				params['trip_resource_id'] = listProperties['trip_resource_id'];
			}else{
				console.log('no list', dataContainerService, params);
			}

			const tripResourceId = dataContainerService.getValueFromKeyPath('trip_resources.recordId');
			if(tripResourceId){
				params['exclude_just_in_trip_resource_id'] = tripResourceId;
			}
			return params;
		},
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		}
	}
} as IFieldDefinition;
