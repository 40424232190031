import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('lang', {
	key: 'lang',
	name: 'Lingua',
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Nome del categoria',
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('full_description', {
	key: 'full_description',
	name: 'Descrizione completa',
	inputType: InputTypes.TINY_MCE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('small_title', {
	key: 'small_title',
	name: 'Titolo Corto',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('small_text', {
	key: 'small_text',
	name: 'Descrizione Breve',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('things_to_do', {
	key: 'things_to_do',
	name: 'Cose da fare',
	inputType: InputTypes.TEXT_LIST2,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('master_image_id', {
	key: 'master_image_id',
	name: 'Immagine',
	inputType: InputTypes.FILE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('properties_master_image_id', {
	key: 'master_image_id',
	name: 'Immagine',
	inputType: InputTypes.FILE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('things_to_do_image_id', {
	key: 'things_to_do_image_id',
	name: 'Immagine Cose da fare',
	inputType: InputTypes.FILE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('properties_things_to_do_image_id', {
	key: 'things_to_do_image_id',
	name: 'Immagine Cose da fare',
	inputType: InputTypes.FILE,
	valueType: ValueTypes.PROPERTY,
	titleIcon: 'public',
	titleTooltip: 'Questo contenuto sarà visibile sul sito'
});

fieldsMap.set('priority_order', {
	key: 'priority_order',
	name: 'Priorità',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('totem_priority', {
	key: 'totem_priority',
	name: 'Priorità Totem',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.NUMBER,
	defaultValue(): number{ return 0; }
});

fieldsMap.set('active_status', {
	key: 'active_status',
	name: 'Stato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.NUMBER,
	defaultValue(): any{
		return 0;
	},
	options: [
		{
			label: 'Disattivo',
			value: 0
		},
		{
			label: 'Attivo',
			value: 1
		}
	],
	inputHint: 'Se questa traduzione è attiva, sarà pubblica per tale lingua',
});

export {fieldsMap};
