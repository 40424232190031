import { ValueTypes, FieldViews, IColumnDefinition } from 'app/interfaces';
import {fieldsMap} from './fields';

export const LIST_COLUMNS: IColumnDefinition[] = [
	{
		title: 'Nome',
		key: 'display_name',
		fieldDefinition: fieldsMap.get('display_name')
	},
	{
		title: 'Fatturato',
		key: 'turnover',
		fieldDefinition: fieldsMap.get('turnover')
	},
	{
		title: 'Fatturato netto',
		key: 'turnover_net',
		fieldDefinition: fieldsMap.get('turnover_net')
	},
	{
		title: 'Tickets',
		key: 'tickets',
		fieldDefinition: fieldsMap.get('tickets')
	},
];
