import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppService } from 'app/services/app.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { TripBookingPackagesStepService } from '../../../trip-booking-packages-step.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BookingService } from 'app/main/pages/edit-booking/booking.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
var TripBookingPackageTrenitaliaPostSaleBookingComponent = /** @class */ (function () {
    function TripBookingPackageTrenitaliaPostSaleBookingComponent(appService, datasetRS, datasetACS, parentDatasetRS, parentDatasetACS, datasetNavigatorService, datasetService, domainFilterService, bookingService, bookingStepService) {
        this.appService = appService;
        this.datasetRS = datasetRS;
        this.datasetACS = datasetACS;
        this.parentDatasetRS = parentDatasetRS;
        this.parentDatasetACS = parentDatasetACS;
        this.datasetNavigatorService = datasetNavigatorService;
        this.datasetService = datasetService;
        this.domainFilterService = domainFilterService;
        this.bookingService = bookingService;
        this.bookingStepService = bookingStepService;
    }
    Object.defineProperty(TripBookingPackageTrenitaliaPostSaleBookingComponent.prototype, "tripBookingPackage", {
        get: function () {
            return this.datasetRS.record.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TripBookingPackageTrenitaliaPostSaleBookingComponent.prototype, "tripBookingStatus", {
        get: function () {
            return (this.bookingStepService &&
                this.bookingStepService.datasetRS.record.value &&
                this.bookingStepService.datasetRS.record.value.status);
        },
        enumerable: true,
        configurable: true
    });
    TripBookingPackageTrenitaliaPostSaleBookingComponent.prototype.ngOnInit = function () {
        this._unsubscribeAll = new Subject();
        this.datasetACS.init({
            datasetCode: 'trip_booking_packages',
            actionCode: 'detail',
            useBeforeCalls: false,
            parentDatasetACS: this.parentDatasetACS,
            parentDatasetRS: this.parentDatasetRS,
        })
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (ready) {
            if (!ready)
                return;
        });
    };
    TripBookingPackageTrenitaliaPostSaleBookingComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    TripBookingPackageTrenitaliaPostSaleBookingComponent.prototype.ngOnChanges = function (changes) {
        if (changes.inputTripBookingPackage) {
            if (!this.datasetRS.record.value) {
                this.datasetRS.record.next(this.inputTripBookingPackage);
            }
            else if (this.inputTripBookingPackage &&
                this.inputTripBookingPackage.updated_at >
                    this.tripBookingPackage.updated_at) {
                this.datasetRS.record.next(this.inputTripBookingPackage);
            }
        }
    };
    return TripBookingPackageTrenitaliaPostSaleBookingComponent;
}());
export { TripBookingPackageTrenitaliaPostSaleBookingComponent };
