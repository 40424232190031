import { Component, OnInit, OnDestroy, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

import { AppService } from 'app/services/app.service';
import { fuseAnimations } from '@fuse/animations';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';

import { BaseInputComponent } from '../base-input.component';
import { takeUntil } from 'rxjs/operators';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { ValueTypes } from 'app/interfaces';
import { FieldHelper } from 'app/helpers';
import { FormControl, AbstractControl } from '@angular/forms';
import { FormInputViewerService } from '../../form-input-viewer.service';
import { DestroyableComponent } from 'app/main/destroyable.component';

@Component({
	selector   : 'multiselect-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	animations : fuseAnimations
})
export class MultiselectInputComponent extends DestroyableComponent implements OnInit, OnDestroy, OnChanges{
	public selectOptions: any[];
	public sourceOptions: any[] = [];
	public selectedLabel: string;

	constructor(
		public inputService: FormInputViewerService,
		public appService: AppService,
	){
		super();
	}

	ngOnInit(): void{

		this.inputService.initialized
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(initialized => {
			if(!initialized) return;
			// only one build will write correct selectOptions
			if(this.inputService.formInputDefinition.options){
				this.selectOptions = this.inputService.formInputDefinition.options;
				this.sourceOptions = this.inputService.formInputDefinition.options;
			}else{
				this.buildSelectPropertyOptions();
				this.buildSelectOptions();
			}

			if(this.getFormControl()){
				this.getFormControl().valueChanges
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(() => {
					this.updateSelectedLabel();
				});
			}
			this.updateSelectedLabel();
		});
	}

	getFormControl(): AbstractControl{
		if(!this.inputService.control){
			console.warn('controll not found for ', this.inputService.formControlKey, this.inputService.formGroup.controls);
			return null;
		}
		return this.inputService.control;
	}

	updateSelectedLabel(): void{
		const value = this.inputService.control.value;
		const labels = [];
		if(value && value instanceof Array){
			for (const option of this.sourceOptions) {
				if(!value.includes(option.value)) continue;
				labels.push(option.label);
			}
		}

		this.selectedLabel = labels.length ? labels.join(', ') : null;
	}

	buildSelectPropertyOptions(): void{
		if(!this.inputService.propertyDefinition) return;
		this.selectOptions = this.inputService.propertyDefinition.property_value_options;
		this.sourceOptions = this.inputService.propertyDefinition.property_value_options;
	}

	buildSelectOptions(): void{
		if(!this.inputService.formInputDefinition || !this.inputService.dataContainerService || !this.inputService.formInputDefinition.optionSource) return;
		this.selectOptions = [];
		const source = this.inputService.dataContainerService.getValueFromKeyPath(this.inputService.formInputDefinition.optionSource);
		if(!source){
			console.warn('source', this.inputService.formInputDefinition.optionSource, this.inputService.dataContainerService);
			return;
		}
		const options = source[this.inputService.formInputDefinition.optionSourceKey];
		if(!options){
			console.warn('options', this.inputService.formInputDefinition.optionSourceKey, 'not defined in', source);
			return;
		}

		this.sourceOptions = options.map(item => {
			return {
				value: item.id,
				label: item.name
			};
		});

		this.selectOptions = this.sourceOptions.map(item => item);
	}

	search(query: string): void{
		this.selectOptions = this.select(query);
	}

	select(query: string): string[]{
		const result: string[] = [];
		for(const option of this.sourceOptions){
			if(option.label.toLowerCase().indexOf(query) > -1){
				result.push(option);
			}
		}
		return result;
	}

	selectAll(): void {
		if(!this.selectOptions) return;
		this.inputService.control
			.patchValue([
				...this.selectOptions.map(item => item.value)
			]);
	}

	selectionChange(event: MatOptionSelectionChange) {
		this.appService.eventEmitter.emit({
			name: 'selected_label_' + this.inputService.formInputDefinition.key, 
			target: event.source['triggerValue']
		});
	}

	deselectAll(): void{
		this.inputService.control.patchValue([]);
	}

	ngOnChanges(changes: SimpleChanges): void{
		if(changes.propertyDefinition && !changes.propertyDefinition.isFirstChange()){
			this.buildSelectPropertyOptions();
		}
	}
}
