import { IStepViewConfig } from 'app/interfaces';
import generalForm from './forms/general.form';
import { TripPackageLanguageContentConfig } from '../trip_package_language_contents';
import { CommonStepViewContainerComponent } from 'app/main/components/dataset/components/common-step/common-step-view-container/view.component';
import { TripServiceConfig } from '../trip_services/index';
import { LocalStorageFilterDomainIdKey } from 'app/services/domain-filter.service';
import { PivotTripPackagesTripDestinationsConfig } from '../pivot_trip_package_trip_destinations/index';
import { PivotTripPackagesTripCategoriesConfig } from '../pivot_trip_packages_trip_categories';
import { PivotTripPackagesTripFacilitiesConfig } from '../pivot_trip_packages_trip_facilities';
import { isRecordInFilteredDomain } from 'app/helpers/is-record-in-filtered-domain.helper';
import generalViewModeForm from './forms/smart-interface-general-view-mode.form';
import originalResellDataFrom from './forms/smart-interface-general-resell-view.form';

export const config: IStepViewConfig = {
	title: 'Pacchetto',
	subtitle(component: CommonStepViewContainerComponent): string{
		if(!component.record) return;
		return component.record.description;
	},
	isLinear(component): boolean{
		return !Boolean(component.record);
	},
	showOverview: true,
	steps: [
		{
			isTitle: true,
			title: 'Informazioni Base'
		},
		{
			id: 'product_name',
			title: 'Informazioni pacchetto',
			stepperTitle: 'Informazioni pacchetto',
			component: 'product-name-step',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			id: 'product_name',
			title: 'Informazioni pacchetto',
			stepperTitle: 'Informazioni pacchetto',
			component: 'common-data-viewer-step',
			options: {
				fields: generalViewModeForm.fields
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			id: 'product_name',
			title: 'Informazioni pacchetto rivendita',
			stepperTitle: 'Informazioni pacchetto',
			component: 'resell-product-name-step',
			options: {
				originalDataFields: originalResellDataFrom.fields,
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_trip_destinations',
			title: 'Destinazioni',
			stepperTitle: 'Destinazioni',
			component: 'trip-destination-step',
			options: {
				datasetConfig: PivotTripPackagesTripDestinationsConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_trip_destinations',
			title: 'Destinazioni',
			stepperTitle: 'Destinazioni',
			component: 'resell-trip-destination-step',
			options: {
				datasetConfig: PivotTripPackagesTripDestinationsConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_trip_categories',
			title: 'Categorie',
			stepperTitle: 'Categorie',
			component: 'trip-package-trip-categories',
			options: {
				datasetConfig: PivotTripPackagesTripCategoriesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_trip_categories',
			title: 'Categorie',
			stepperTitle: 'Categorie',
			component: 'resell-trip-package-trip-categories',
			options: {
				datasetConfig: PivotTripPackagesTripCategoriesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			isTitle: true,
			title: 'Descrizione attività'
		},
		{
			id: 'smart_interface_i18n_form_step',
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'smart-interface-i18n-form-step',
			options: {
				fieldsMap: TripPackageLanguageContentConfig.fieldsMap,
				formConfig: TripPackageLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			id: 'smart_interface_i18n_form_step',
			title: 'Descrizioni',
			stepperTitle: 'Descrizioni',
			component: 'common-i18n-data-viewer-step',
			options: {
				fields: TripPackageLanguageContentConfig.formConfigsMap['textForm'].fields,
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return !component.datasetACS.hasValueInKeyPath('reselledData') && !isRecordInFilteredDomain(record, record.is_owner);
			}
		},
		{
			id: 'smart_interface_i18n_form_step',
			title: 'Descrizioni Rivendita',
			stepperTitle: 'Descrizioni',
			component: 'resell-smart-interface-i18n-form-step',
			options: {
				fieldsMap: TripPackageLanguageContentConfig.fieldsMap,
				formConfig: TripPackageLanguageContentConfig.formConfigsMap['textForm'],
				datasetCode: 'trip_package_language_contents',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId',
				parentReselledValueKey: 'reselledData.id'
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_facilities',
			title: 'Servizi',
			stepperTitle: 'Servizi',
			component: 'trip-package-trip-facilities',
			options: {
				datasetConfig: PivotTripPackagesTripFacilitiesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				// show while create, mean user is the owner anyway
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'trip_package_facilities',
			title: 'Servizi Rivendita',
			stepperTitle: 'Servizi',
			component: 'resell-trip-package-trip-facilities',
			options: {
				datasetConfig: PivotTripPackagesTripFacilitiesConfig
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'media',
			title: 'Media',
			stepperTitle: 'Media',
			component: 'trip-package-smart-interface-media',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return true;
				return !component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			id: 'media',
			title: 'Media Rivendita',
			stepperTitle: 'Media',
			component: 'resell-trip-package-smart-interface-media',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(component): boolean{
				const record = component.record;
				if(!record) return false;
				return component.datasetACS.hasValueInKeyPath('reselledData');
			}
		},
		{
			isTitle: true,
			title: 'Configurazioni'
		},
		{
			id: 'trip_options_form',
			title: 'Configura opzioni',
			component: 'trip-booking-options',
			options: {
				datasetCode: 'trip_options',
				parentForeignKey: 'trip_package_id',
				parentValueKey: 'trip_packages.recordId'
			},
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (!Boolean(container.record)){
					return true;
				}
				if (!isRecordInFilteredDomain(container.record, container.record.is_owner)){
					return false;
				}
				return Boolean(container.record) && !Boolean(container.record.tour_operator_id);
			}
		},
		{
			id: 'prices',
			title: 'Prezzi',
			component: 'price-step',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (!Boolean(container.record)){
					return true;
				}
				if (!isRecordInFilteredDomain(container.record, container.record.is_owner)){
					return false;
				}
				return Boolean(container.record) && !Boolean(container.record.tour_operator_id);
			}
		},
		{
			id: 'prices',
			title: 'Prezzi',
			component: 'common-blocks-step',
			showIf(component): boolean{
				if (!Boolean(component.record)){
					return false;
				}
				return !isRecordInFilteredDomain(component.record, component.record.is_owner);
			},
			options: {
				blocks: [
					{
						type: 'list',
						datasetCode: 'trip_services',
						updateParent: true,
						title: TripServiceConfig.title
					}
				]
			},
			completed(component): boolean{
				return Boolean(component.record);
			}
		},
		{
			id: 'avilabilities',
			title: 'Disponibilità',
			component: 'availability-step',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (!Boolean(container.record)){
					return true;
				}
				if (!isRecordInFilteredDomain(container.record, container.record.is_owner)){
					return false;
				}
				return Boolean(container.record) && !Boolean(container.record.tour_operator_id);
			}
		},
		{
			id: 'actual_trips',
			title: 'Partenze',
			component: 'actual-trips-smart-interface',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (!Boolean(container.record)){
					return false;
				}
				if (!isRecordInFilteredDomain(container.record, container.record.is_owner)){
					return true;
				}
				return Boolean(container.record) && Boolean(container.record.tour_operator_id);
			}
		},
		{
			id: 'integrations',
			title: 'Integrazioni',
			component: 'trip-package-integrations-step-smart-interface',
			completed(component): boolean{
				return Boolean(component.record);
			},
			showIf(container: CommonStepViewContainerComponent): boolean{
				if (!Boolean(container.record)){
					return true;
				}
				return true;
				// return Boolean(container.record) && !Boolean(container.record.tour_operator_id);
			}
		}
	]
};
