<ng-container *ngIf='datasetACS.ready.value'>
	<div id="customStyle" class="{{datasetACS.datasetConfig.datasetListStyle}}">
		<div class="content-header">
			<h2 *ngIf='showTitle'>
				<span>{{datasetACS.datasetConfig.title | translate | titlecase}}</span>
				<!--
				<button class='ml-24' mat-icon-button color='accent' (click)='onAdd()' *ngIf='resourceACS.canCreate() && enableCreate'>
					<mat-icon>add</mat-icon>
				</button>
				-->
			</h2>

			<div class="dividerLine"></div>
		</div>

		<div *ngIf='showCreateForm'  class='m-24' fxLayout='row' fxLayoutAlign='center start'>
			<dataset-create
				(created)='reload();'
				[datasetCode]='datasetACS.datasetCode'
				[parentDatasetACS]='datasetACS'
			></dataset-create>
		</div>

		<div *ngIf="viewConfig.showExportBtn && showExportBtn">
			<export-data-tool 
				[viewConfig]="viewConfig"
				[datasetACS]="datasetACS"
			></export-data-tool>
		</div>

		<div *ngIf="viewConfig.showRequestPackage">
			<request-package-tool 
				[viewConfig]="viewConfig"
				[datasetACS]="datasetACS"
			></request-package-tool>
		</div>

		<ng-container [ngSwitch]='itemViewType'>
			<ng-container *ngSwitchCase='ItemViewTypes.TABLE'>
				<list-table-view 
					[enableCreate]='enableCreate'
					[enableEdit]='enableEdit'
					[enableDelete]='enableDelete'
					[enableActions]='enableActions'
					[elevate]='elevate'
					(pageChanged)='onPageChanged($event)'
					(filterChange)='onFilterChange($event)'
					[selectable]='selectable' 
					[selection]='selection' 
					[selectionBtnTxt]='selectionBtnTxt'
					[selectionBtnIcon]='selectionBtnIcon'
					(selected)='selectRecord($event)'
					[viewConfig]='viewConfig'
					[showPagination]='showPagination'
					[conditionalShowPaginationToolbar]='conditionalShowPaginationToolbar'
					[showSearchBar]='showSearchBar'
					[showHeaderToolbar]='showHeaderToolbar'
					[unsetParamFilters]='unsetParamFilters'
					[emitAdd]='emitAdd'
					[addButtonText]='addButtonText'
					(add)='onAdd()'
					[paginationResponse]='lastPaginationResponse'
					[socketEvent]='socketEvent'
					[tableStyle]='tableStyle'
					[filterStyle]='filterStyle'>
				</list-table-view>
			</ng-container>
			<ng-container *ngSwitchCase='ItemViewTypes.CUSTOM'>
				<list-custom-view 
					[enableCreate]='enableCreate'
					[enableEdit]='enableEdit'
					[enableDelete]='enableDelete'
					[enableActions]='enableActions'
					[elevate]='elevate'
					(pageChanged)='onPageChanged($event)'
					[selectable]='selectable' 
					[selection]='selection' 
					(selected)='selectRecord($event)'
					[viewConfig]='viewConfig'
					[showPagination]='showPagination'
					[conditionalShowPaginationToolbar]='conditionalShowPaginationToolbar'
					[showSearchBar]='showSearchBar'
					[emitAdd]='emitAdd'
					[addButtonText]='addButtonText'
					(add)='onAdd()'
					(reloadDatasetList)='reload()'
					[paginationResponse]='lastPaginationResponse'>
				</list-custom-view>
			</ng-container>
			<ng-container *ngSwitchCase='ItemViewTypes.CALENDAR'>
				<dataset-calendar-view
					[enableCreate]='enableCreate'
					[enableEdit]='enableEdit'
					[enableDelete]='enableDelete'
					[enableActions]='enableActions'
					[elevate]='elevate'
					(pageChanged)='onPageChanged($event)'
					[selectable]='selectable' 
					[selection]='selection' 
					(selected)='selectRecord($event)'
					[viewConfig]='viewConfig'
					[showPagination]='showPagination'
					[conditionalShowPaginationToolbar]='conditionalShowPaginationToolbar'
					[showSearchBar]='showSearchBar'
					[emitAdd]='emitAdd'
					[addButtonText]='addButtonText'
					(add)='onAdd()'
					[paginationResponse]='lastPaginationResponse'>
				</dataset-calendar-view>
			</ng-container>

			<div *ngSwitchDefault>
				<mat-progress-bar *ngIf='datasetACS.loading.value' color="accent" mode="indeterminate"></mat-progress-bar>

				<ng-container *ngIf='!datasetACS.loading.value && items'>
					<div *ngFor='let item of items'>
						<pre>{{item | json}}</pre>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</div>

</ng-container>
