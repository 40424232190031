import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { ServiceRuleDocumentationDialogComponent } from 'app/main/dialogs/service-rule-documentation-dialog/dialog.component';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice',
	inputConfig: {
		type: InputTypes.TEXT,
		required: true
	},
	valueType: ValueTypes.STRING
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	inputHint: 'Nome del pacchetto'
});

fieldsMap.set('condition_rule', {
	key: 'condition_rule',
	name: 'Condizione',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		suffixs: [
			{
				icon: 'info',
				color: 'accent',
				onClick(formGroup, datasetNavigationService): void{
					datasetNavigationService.dialog
					.open(ServiceRuleDocumentationDialogComponent);
				}
			}
		]
	}
});

fieldsMap.set('count_rule', {
	key: 'count_rule',
	name: 'Calcolo',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.TEXT,
		suffixs: [
			{
				icon: 'info',
				color: 'accent',
				onClick(formGroup, datasetNavigationService): void{
					datasetNavigationService.dialog
					.open(ServiceRuleDocumentationDialogComponent);
				}
			}
		]
	}
});


export {fieldsMap};
