import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { BaseViewComponent } from '../base-view.component';
import { AuthService } from 'app/services/auth.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { SaleOperationConfig } from 'app/configs/datasets/sale_operations';
var PraticaDataComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PraticaDataComponent, _super);
    function PraticaDataComponent(route, datasetService, router, authService, datasetACS, datasetRS, datasetNavigatorService) {
        var _this = _super.call(this, datasetACS, datasetRS, datasetNavigatorService) || this;
        _this.route = route;
        _this.datasetService = datasetService;
        _this.router = router;
        _this.authService = authService;
        _this.datasetACS = datasetACS;
        _this.datasetRS = datasetRS;
        _this.datasetNavigatorService = datasetNavigatorService;
        _this.showActions = true;
        _this.canEdit = false;
        return _this;
    }
    PraticaDataComponent.prototype.ngOnInit = function () {
        this.formConfig = SaleOperationConfig.formConfigsMap[this.viewFormConfig];
        this.computeCanEdit();
    };
    PraticaDataComponent.prototype.computeCanEdit = function () {
        this.canEdit = this.datasetACS.canEdit(this.rowDb);
    };
    PraticaDataComponent.prototype.setOriginalId = function () {
        if (Object.prototype.hasOwnProperty.call(this.record, 'id')) {
            this.record.original_id = this.record.id;
        }
    };
    PraticaDataComponent.prototype.onEdit = function () {
        this.computeCanEdit();
        this.setOriginalId();
        this.record.id = this.rowDb.id;
        this.record.index = this.index;
        this.datasetNavigatorService.openEditDialog(this.datasetACS, this.record, { formConfig: this.formConfig });
    };
    return PraticaDataComponent;
}(BaseViewComponent));
export { PraticaDataComponent };
