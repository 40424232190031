import { Component, OnInit, AfterViewInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CalendarMonthViewDay } from 'angular-calendar';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { CalendarService } from 'app/main/pages/calendar/calendar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector     : 'calendar-sidebar',
	templateUrl  : './sidebar.component.html',
	styleUrls    : ['./sidebar.component.scss'],
})

export class CalendarSidebarComponent implements OnInit, AfterViewInit, OnDestroy {

	private _unsubscribeAll: Subject<any>;

	DAYS = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

	@Input() selectedDay: CalendarMonthViewDay;
	@Input() groupedEvents: any;
	@Output() opened: EventEmitter<any> = new EventEmitter<any>();
	@Output() closed: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		private _fuseSidebarService: FuseSidebarService, 
		private _calendarService: CalendarService,
		public datasetNavigatorService: DatasetNavigatorService){
			/** mi piaciono i commenti */
			this._unsubscribeAll = new Subject<any>();
		}

	ngOnInit(): void{}

	ngAfterViewInit(): void{
		/**
		 * on click on day month
		 */
		this._calendarService.activeDayIsOpenSubscriber
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(sidebarStatus => {
			if (sidebarStatus.opened){
				this.openSidebar(sidebarStatus);
			} else this.closeSidebar(sidebarStatus);
		});
		/**
		 * sidebar detector
		 */
		this._fuseSidebarService.getSidebar('calendar-sidebar').openedChanged
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(opened => {
			if (opened){
				this.opened.emit(this);
			} else this.closed.emit(this);
		});
	}

	ngOnDestroy(): void{
		this._calendarService.activeDayIsOpenSubscriber.next({opened: false, onDestroy: true});
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	scrollToTop(): void{
		const container = document.querySelector('#container-3');
		container.scrollTop = 0;
	}

	openSidebar(sidebarStatus?: any): void{
		this.scrollToTop();
		this._fuseSidebarService.getSidebar('calendar-sidebar').open();
		this.opened.emit(this);
	}

	closeSidebar(sidebarStatus?: any): void{
		if (sidebarStatus && !sidebarStatus.opened && sidebarStatus.onDestroy){
			this.closed.emit(this);
		} else {
			this._fuseSidebarService.getSidebar('calendar-sidebar').close();
			this.closed.emit(this);
		}
	}
}
